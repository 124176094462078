import React, { useState, useEffect } from "react";
import { useApi, reportsApi, useFormControl, AgGridTable, useAuth } from "component-library";
import { schema, initialData } from "../schema/timecardSummary";
import ToFromFields from "../ToFromFields";
import useForms from "./useForms";
import { Button, Alert } from "react-bootstrap";
import geofenceReport from "../AdminReports/geofenceReport";

const GeofenceReport = ({ setLoading, setError, setData, setShowExport }) => {
  const {
    request: runReportRequest,
    data: runReportData,
    loading: runReportLoading,
    error: runReportError,
  } = useApi(reportsApi.getGeofenceReport);

  const { onSubmit } = useForms({
    runReportRequest,
    runReportData,
    runReportLoading,
    runReportError,
    setLoading,
    setError,
    setData,
  });

  const { handleSubmit, renderTextField, renderSubmitButton, data } = useFormControl({
    schema,
    initialData,
    onSubmit,
  });

  const [gridData, setGridData] = useState();
  const [exportURL, setExportURL] = useState();
  const { user } = useAuth();

  useEffect(() => {
    if (!!runReportData?.gridData) {
      setGridData(runReportData.gridData);
    }
    if (!!runReportData?.url) {
      setExportURL(runReportData.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runReportData]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <ToFromFields renderTextField={renderTextField} data={data} />
        </div>
        <div className="d-flex align-items-center justify-content-end">
          {!!exportURL && (
            <Button
              className="form-control-submit med btn btn-primary mb-2 me-2"
              onClick={() => {
                setShowExport(true);
              }}
            >
              Export File
            </Button>
          )}
          <div className="mb-2">{renderSubmitButton("Run Report")}</div>
        </div>
      </form>
      {!!runReportError && <Alert variant="danger">{runReportError}</Alert>}
      {!!gridData && (
        <AgGridTable
          gridData={gridData}
          gridConfig={geofenceReport}
          id={`${user.id}_geofence_report`}
          suppressSizeToFit
        />
      )}
    </>
  );
};

export default GeofenceReport;
