/**
 * Formats a decimal value provided as table data to match expected standards
 * https://tractics.atlassian.net/wiki/spaces/TENG/pages/126156808/UI+UX+Standards
 * @param  { Number | String } gridValue raw table decimal value to format
 */
 const formatGridValues = (gridValue) => {
  //TODO: add date formatting to this func

  if (gridValue === "" || gridValue === null) {
    return gridValue;
  }

  const numericValue = Number(gridValue);

  if (isNaN(numericValue)) {
    return gridValue;
  }

  return numericValue % 1 === 0 ? parseInt(numericValue) : numericValue.toFixed(2);
};

export default formatGridValues;