/**
 * Creates a queue to handle processing multiple tasks
 * taken from: https://krasimirtsonev.com/blog/article/implementing-an-async-queue-in-23-lines-of-code
 * @param  {Array} tasks array of functions that each return a promise
 * @param  {Number} maxNumOfWorkers Count to limit the number of concurrent tasks running
 * @param  {Function} handledCompletedTasks callback function to handle the returned data
 * @return {Promise}
 */

const createQueue = (tasks, maxNumOfWorkers = 5, handledCompletedTasks) => {
  var numOfWorkers = 0;
  var taskIndex = 0;

  return new Promise((done) => {
    const handleResult = (index) => (result) => {
      tasks[index] = result;
      numOfWorkers--;
      handledCompletedTasks(result);
      getNextTask();
    };
    const getNextTask = () => {
      if (numOfWorkers < maxNumOfWorkers && taskIndex < tasks.length) {
        tasks[taskIndex]().then(handleResult(taskIndex)).catch(handleResult(taskIndex));
        taskIndex++;
        numOfWorkers++;
        getNextTask();
      } else if (numOfWorkers === 0 && taskIndex === tasks.length) {
        done(tasks);
      }
    };
    getNextTask();
  });
};

export default createQueue;
