import React, { useState, useEffect } from "react";
import { useApi, Loader, bidApi } from "component-library";
import { useParams } from "react-router";
import TaskAccordion from "./TaskAccordion";
import useProject from "../../../../hooks/useProject";
import styles from "./TakeoffDetail.module.scss";
import QuotesTable from "./QuotesTable";
import { Link } from "react-router-dom";
import InteriorPage from "../../../../../components/pageLayout/InteriorPage";
import { Briefcase } from "react-feather";

const TakeoffDetail = () => {
  const { error, request, data, loading } = useApi(bidApi.getTakeoff);
  const params = useParams();
  const { project, PageTag, pageTitle } = useProject();
  const [takeoff, setTakeoff] = useState([]);

  useEffect(() => {
    request(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!data && !!data.takeoff) {
      setTakeoff(data.takeoff);
    }
  }, [data]);

  return (
    <InteriorPage
      navTitle={pageTitle}
      pageTitle="Estimate Conversion"
      navIcon={<Briefcase color="#fff" size={14} />}
      NavContent={PageTag}
    >
      {!!loading ? (
        <Loader />
      ) : (
        <>
          {!!takeoff && (
            <>
              <div className="d-flex justify-content-between">
                {!!project && (
                  <div>
                    <Link to={`/project/${project.id}/estimate-conversion/takeoffs`} className="u dark-text">
                      Return to Takeoff List
                    </Link>
                  </div>
                )}
              </div>
              {!!error && <span className="error me-2">{error}</span>}
              <div className="d-flex justify-content-between w-100 mt-4">
                <h2 className="mb-0 page-title font-weight-bold text-gray2">{takeoff.name}</h2>
                <h2 className="mb-0 lg text-gray2">
                  {takeoff.pm_quantity} {takeoff.unit_measure}
                </h2>
              </div>
              {!!takeoff && !!takeoff.quotes && !!takeoff.quotes.length ? (
                <QuotesTable quotes={takeoff.quotes} />
              ) : (
                <div className={`${styles.quoteTableWrapper} med text-gray2 px-20 pt-3 pb-3 px-3`}>
                  No attached quotes
                </div>
              )}
              <div>
                <h2 className="mb-0 page-title font-weight-bold text-gray2">Attached Tasks</h2>
                {!!takeoff && !!takeoff.tasks && !!takeoff.tasks.length ? (
                  <div className={styles.tasksWrapper}>
                    {takeoff.tasks.map((task) => (
                      <TaskAccordion key={task.id} task={task} />
                    ))}
                  </div>
                ) : (
                  <div className={`${styles.quoteTableWrapper} med text-gray2 px-20 pt-3 pb-3 px-3`}>
                    No attached tasks
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </InteriorPage>
  );
};

export default TakeoffDetail;
