import React from "react";
import { Table } from "react-bootstrap";
import styles from "../styles.module.scss";
import NeededPermitsItem from "./NeededPermitsItem";

const NeededPermitsList = ({ permits, setEditPermit }) => {
  return (
    <div className="text-start">
      <div className="lg font-weight-bold">Needed Permits</div>
      <Table className={styles.table}>
        <thead className={styles.tableHead}>
          <tr>
            <th style={{ width: "8.5rem" }}>Needed By</th>
            <th>Description</th>
            <th style={{ width: "15rem" }}>Cost</th>
            <th style={{ width: "10rem" }}>Status</th>
            <th style={{ width: "2.5rem" }}>File</th>
          </tr>
        </thead>
        <tbody>
          {permits.map((permit) => (
            <NeededPermitsItem key={permit.id} permit={permit} setEditPermit={setEditPermit} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default NeededPermitsList;
