export const createInitialState = () => {
  return {
    photoModalOpen: false,
    updatingFieldNotes: false,
    photos: [],
  };
};

export const useReportFieldNotesReducer = (state, action) => {
  switch (action.type) {
    case "toggle_photo_modal":
      return {
        ...state,
        photoModalOpen: !state.photoModalOpen,
      };
    case "toggle_update_notes_modal":
      return {
        ...state,
        updatingFieldNotes: !state.updatingFieldNotes,
      };
    case "set_photos_for_modal_viewer":
      return {
        ...state,
        photos: action.photos,
      };
    default:
      return state;
  }
};
