import React from "react";
import { Modal } from "react-bootstrap";
import useAddDriveTime from "./hooks/useAddDriveTime";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const DriveTimeModal = ({ close, timesheet, projectsList, setTimesheet }) => {
  const { fields } = useAddDriveTime({
    setTimesheet,
    timesheet,
    close,
    projectsList,
  });

  return (
    <Modal show={true} onHide={close} className={styles.driveTimeModal} centered>
      <Modal.Header closeButton className={styles.driveTimeHeader}>
        Add Drive Time
      </Modal.Header>
      {fields}
    </Modal>
  );
};

DriveTimeModal.propTypes = {
  close: PropTypes.func.isRequired,
  timesheet: PropTypes.object.isRequired,
  projectsList: PropTypes.array.isRequired,
};

export default DriveTimeModal;
