import React from "react";
import { Briefcase } from "react-feather";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import useProject from "../../../hooks/useProject";
import BackToPOsLink from "../BackToPOsLink";
import PurchaseOrderControls from "./PurchaseOrderControls";
import usePurchaseOrder from "./context/usePurchaseOrder";
import PurchaseOrderDate from "./PurchaseOrderDate";
import { RiseLoader } from "react-spinners";
import styles from "../styles.module.scss";
import Status from "./Status";
import Notes from "./Notes";
import Scope from "./Scope";
import { PO } from "component-library";
import Sum from "./Sum";
import { Navigate } from "react-router-dom";
import SignPurchaseOrder from "./SignPurchaseOrder";
import { Quote as Q } from "component-library";
const PurchaseOrder = () => {
  const { pageTitle, project, PageTag } = useProject();
  const {
    loading,
    error,
    purchaseOrder,
    updatePurchaseOrder,
    updateError,
    setPurchaseOrder,
    deletePurchaseOrder,
    deleteLoading,
    deleteData,
    setLoading,
    getLoading,
    cancelSignatureRequestError,
  } = usePurchaseOrder();

  return (
    <InteriorPage
      loading={getLoading}
      error={error || updateError || cancelSignatureRequestError}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle={`Purchase Order ${!!purchaseOrder ? `#${purchaseOrder.id}` : ""}`}
      NavContent={PageTag}
    >
      <div className="position-relative">
        {loading && (
          <div className={styles.loaderWrapper}>
            <RiseLoader color={"#0071ce"} className={styles.loader} />
          </div>
        )}
        {!!deleteData && <Navigate to={`/project/${!!project && project.id}/purchase-orders`} />}
        <div className={styles.nav}>
          <BackToPOsLink />
          {!!purchaseOrder && (
            <PurchaseOrderControls
              purchaseOrder={purchaseOrder}
              deletePurchaseOrder={deletePurchaseOrder}
              deleteData={deleteData}
              deleteLoading={deleteLoading}
              updatePurchaseOrder={updatePurchaseOrder}
            />
          )}
        </div>
        {!!purchaseOrder && (
          <>
            <div className={styles.secondary}>
              <Status status={purchaseOrder.status} />
              <PurchaseOrderDate updatePurchaseOrder={updatePurchaseOrder} purchaseOrder={purchaseOrder} />
            </div>
            <div className={styles.contactSection}>
              {!!purchaseOrder.quote && !!purchaseOrder.quote.supplier && <Q.Contacts quote={purchaseOrder.quote} />}
              <PO.File
                purchaseOrder={purchaseOrder}
                url={`/project/${!!project && project.id}/purchase-orders/${!!purchaseOrder && purchaseOrder.id}/pdf`}
              />
            </div>
            {purchaseOrder.status === 0 ? (
              <Scope
                purchaseOrder={purchaseOrder}
                updatePurchaseOrder={updatePurchaseOrder}
                setPurchaseOrder={setPurchaseOrder}
              />
            ) : (
              <PO.DisplayScope purchaseOrder={purchaseOrder} />
            )}
            <Notes purchaseOrder={purchaseOrder} setPurchaseOrder={setPurchaseOrder} setLoading={setLoading} />
            <Sum purchaseOrder={purchaseOrder} updatePurchaseOrder={updatePurchaseOrder} />
            <PO.Signatures purchaseOrder={purchaseOrder} />
            <div className={styles.section}>
              {!!project && !!purchaseOrder && !!purchaseOrder.quote && (
                <PO.AttachedQuote
                  quote={purchaseOrder.quote}
                  quotePath={`/project/${project.id}/quotes/${purchaseOrder.quote.id}`}
                />
              )}
              {!!purchaseOrder && !!purchaseOrder.versions && !!purchaseOrder.versions.length && (
                <PO.VersionHistory history={purchaseOrder.versions} />
              )}
            </div>
            <SignPurchaseOrder />
          </>
        )}
      </div>
    </InteriorPage>
  );
};

export default PurchaseOrder;
