import React, { useState, useEffect } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import styles from "./styles.module.scss";
import TasksTable from "./TasksTable";
import useEstimateConversion from "../../useEstimateConversion";
import { Loader, useApi, bidApi } from "component-library";

const AddTasksModal = ({ material, setNewMaterials, modalOpen, setModalOpen, newMaterials }) => {
  const { upbLoading } = useEstimateConversion();
  const [selectedTaskBidItems, setSelectedTaskBidItems] = useState(
    material?.tasks?.length ? material.tasks.map((task) => task.id) : []
  );
  const {
    request: updateTakeoffRequest,
    data: updateTakeoffData,
    loading: updateTakeoffLoading,
    error: updateTakeoffError,
  } = useApi(bidApi.updateTakeoff);

  const toggleSelectedBidItem = (taskId) => {
    let updatedSelectedTasks;
    if (selectedTaskBidItems.includes(taskId)) {
      updatedSelectedTasks = selectedTaskBidItems.filter((id) => id !== taskId);
    } else {
      updatedSelectedTasks = [...selectedTaskBidItems, taskId];
    }
    setSelectedTaskBidItems(updatedSelectedTasks);
  };

  const handleAddTasks = () => {
    let deleteTasks = [];
    const currentTaskIds = material.tasks.map((task) => task.id);
    const newTasks = selectedTaskBidItems.filter((item) => !currentTaskIds.includes(item));
    if (!!currentTaskIds?.length) {
      currentTaskIds.forEach((itemId) => {
        if (!selectedTaskBidItems.includes(itemId)) {
          deleteTasks = [...deleteTasks, itemId];
        }
      });
    }
    updateTakeoffRequest({
      id: material.id,
      name: material.name,
      tasks: material.tasks.map((task) => {
        return { id: task.id, pm_quantity: task.pm_quantity };
      }),
      new_tasks: newTasks,
      delete_tasks: deleteTasks,
      pm_quantity: !deleteTasks?.length
        ? material.pm_quantity
        : material.tasks.reduce((p, c) => (deleteTasks.includes(c.id) ? p + 0 : p + parseInt(c.pm_quantity)), 0),
    });
  };

  useEffect(() => {
    if (!!updateTakeoffData) {
      setNewMaterials(
        newMaterials.map((mat) => {
          if (mat.id === material.id) {
            return updateTakeoffData.takeoffs[0];
          } else {
            return mat;
          }
        })
      );
      setModalOpen(false);
      setSelectedTaskBidItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTakeoffData]);

  useEffect(() => {
    setSelectedTaskBidItems(material?.tasks?.length ? material.tasks.map((task) => task.id) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!material && material.tasks]);

  return (
    <Modal
      animation={false}
      show={!!modalOpen}
      onHide={() => {
        setModalOpen(false);
      }}
      dialogClassName="modal-lg"
      className="p-absolute text-gray3"
    >
      <div className="position-relative">
        <Loader loading={!!upbLoading || !!updateTakeoffLoading} />
        <Modal.Header closeButton className={styles.modalHeader}>
          <div className={styles.modalTitle}>Attach Bid Item</div>
        </Modal.Header>
        <Modal.Body>
          {!!updateTakeoffError && <Alert variant="danger">{updateTakeoffError}</Alert>}
          <TasksTable
            material={material}
            toggleSelectedBidItem={toggleSelectedBidItem}
            selectedTaskBidItems={selectedTaskBidItems}
            setSelectedTaskBidItems={setSelectedTaskBidItems}
          />
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button variant="light-gray" className="me-2" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
            <Button disabled={!selectedTaskBidItems?.length} variant="orange" onClick={() => handleAddTasks()}>
              {`Attach Bid item${!!selectedTaskBidItems && selectedTaskBidItems.length > 1 ? "s" : ""}`}
            </Button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default AddTasksModal;
