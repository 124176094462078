import React from "react";
import useEstimateConversion from "../useEstimateConversion";
import styles from "./styles.module.scss";
import PhaseBadge from "./Phases/PhaseBadge";
import { getPhaseColor } from "../../../../config/phaseColors";
import { ChevronLeft, ChevronRight, Crosshair, EyeOff, MinusCircle } from "react-feather";

const BidItemsHeader = ({ fixedColumns = false }) => {
  const { viewPhases, phases, editTasks } = useEstimateConversion();

  return (
    <thead className="border-bottom-0">
      <>
        {!!fixedColumns ? (
          <>
            {!editTasks && !!viewPhases && (
              <tr className={styles.phaseHeaderRow}>
                <th colSpan="4" className="p-0 m-0"></th>
              </tr>
            )}
            <tr className={styles.topRowBorder}>
              <th
                className={`
                ${styles.smallColumn} 
                ${styles.tableHeader}
                text-center text-nowrap border-top-0`}
              >
                <span>#</span>
              </th>
              <th className={`${styles.tableHeader} text-start text-nowrap border-top-0`}>
                <span>Items</span>
              </th>
              <th className={`${styles.tableHeader} text-nowrap ps-1 border-top-0`}>
                <span>Code</span>
              </th>
              <th className={` ${styles.tableHeader} text-nowrap ps-1 border-top-0`}>
                <span>Prod Rate</span>
              </th>
            </tr>
          </>
        ) : (
          <>
            {!editTasks && !!viewPhases && (
              <tr className={styles.phaseHeaderRow}>
                <th className={styles.phaseHeader} colSpan={phases.length}>
                  <div className={styles.phaseHeaderInner}>
                    <span>Phase Qty</span>
                    <span>
                      <ChevronLeft size={12} color="#C4C4C4" />
                      <ChevronRight size={12} color="#C4C4C4" />
                    </span>
                  </div>
                </th>
              </tr>
            )}
            <tr className={styles.topRowBorder}>
              {!editTasks && !!viewPhases && (
                <>
                  {!!phases &&
                    !!phases.length &&
                    phases.map((phase, index) => (
                      <th
                        className={`${styles.tableHeader} ${styles.phaseCell} text-center text-nowrap border-top-0`}
                        key={index}
                      >
                        <PhaseBadge
                          key={phase.id}
                          phase={phase}
                          phaseName={phase.name}
                          phaseColor={getPhaseColor(phase, phases)}
                        />
                      </th>
                    ))}
                </>
              )}
              <th className={`${styles.medColumn} ${styles.tableHeader} border-top-0 text-center`}>
                <span>
                  <Crosshair color="#7B7B7B" size={16} />
                </span>
              </th>
              <th className={`${styles.tableHeader} text-nowrap ps-1 border-top-0`}>
                <span>Total Qty</span>
              </th>
              <th className={`${styles.lgColumn}  ${styles.tableHeader} text-nowrap ps-1 border-top-0`}>
                <span>Unit</span>
              </th>
              <th className={`${styles.medColumn}  ${styles.tableHeader} text-nowrap ps-1 border-top-0 text-center`}>
                <span>Est. Hours</span>
              </th>
              <th className={`${styles.medColumn}  ${styles.tableHeader} text-nowrap ps-1 border-top-0 text-center`}>
                <span>Forced Qty</span>
              </th>
              <th className={`${styles.medColumn}  ${styles.tableHeader} text-nowrap ps-1 border-top-0 text-center`}>
                {!!editTasks ? "Active" : <MinusCircle size={19} color="#7b7b7b" />}
              </th>
              <th className={`${styles.medColumn}  ${styles.tableHeader} text-nowrap ps-1 border-top-0 text-center`}>
                <span>{!!editTasks ? "Hide" : <EyeOff size={19} color="#7b7b7b" />}</span>
              </th>
            </tr>
          </>
        )}
      </>
    </thead>
  );
};

export default BidItemsHeader;
