import React from "react";
import { Alert, Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import { Loader } from "component-library";
import CustomScroll from "react-custom-scroll";
import EquipmentDetails from "./EquipmentDetails";
import ReportDetails from "./ReportDetails";
import TrailerDetails from "./TrailerDetails";
import { ReactComponent as Satisfactory } from "../../../../assets/inspection-complete.svg";
import { ReactComponent as Unsatisfactory } from "../../../../assets/inspection-incomplete.svg";
import SubHeader from "./SubHeader";
import { DateTime } from "luxon";

const Detail = ({
  data,
  loading,
  error,
  trailerDefectTypes,
  trailerDefects,
  truckDefectTypes,
  truckDefects,
  setSelectedEquipment,
  setSelectedPhotos,
  photos,
}) => {
  return (
    <>
      <SubHeader data={data} loading={loading} />
      <CustomScroll heightRelativeToParent="100%">
        {!!error && <Alert variant="danger">{error}</Alert>}
        {!!loading ? (
          <Loader />
        ) : (
          <div className="w-100 pb-5">
            <div className={styles.subDetails}>
              <div>{data?.data?.pre_or_post === 0 ? "Pre-Trip Inspection" : "Post-Trip Inspection"}</div>
              <div className={styles.detailsDate}>
                {DateTime.fromSQL(data?.data?.created_at).toFormat("MM/dd/yy @ t")}
              </div>
            </div>
            <div className="d-flex align-items-center pt-4">
              {data?.data?.overall_condition_satisfactory === 1 ? (
                <div className="d-flex align-items-center mx-auto">
                  <Satisfactory width="1.6rem" height="1.6rem" />
                  <div className={`${styles.satisfactory} xl ms-2`}>Satisfactory</div>
                </div>
              ) : (
                <div className="d-flex align-items-center mx-auto">
                  <Unsatisfactory width="1.6rem" height="1.6rem" />
                  <div className={`${styles.warning} xl ms-2`}>Unsatisfactory</div>
                </div>
              )}
            </div>
            <div className={styles.body}>
              <article className="flex-1 w-100 text-gray2">
                <hr />
                <ReportDetails data={data} />
                <hr />
                <EquipmentDetails
                  data={data}
                  truckDefectTypes={truckDefectTypes}
                  truckDefects={truckDefects}
                  setSelectedEquipment={setSelectedEquipment}
                />
                <hr />
                <TrailerDetails
                  data={data}
                  trailerDefectTypes={trailerDefectTypes}
                  trailerDefects={trailerDefects}
                  setSelectedEquipment={setSelectedEquipment}
                />
                <hr />
                <section className="mb-4">
                  <h3 className={styles.sectionTitle}>
                    Comments: <span className={styles.note}>({data?.data?.notes ? 1 : "None"})</span>
                  </h3>
                  <div className={styles.commentText}>{data?.data?.notes}</div>
                </section>
                <hr />
                <section className="mb-2">
                  <h3 className={styles.sectionTitle}>
                    Photos: {!photos.length && <span className={styles.note}>(None)</span>}
                  </h3>
                  <div className={styles.photoGrid}>
                    {!!photos.length &&
                      photos.map((photo, index) => (
                        <Button
                          variant="link"
                          onClick={() => {
                            setSelectedPhotos(data);
                          }}
                        >
                          <img key={index} alt="" src={photo} className={styles.inspectionPhoto} />
                        </Button>
                      ))}
                  </div>
                </section>
                <hr />
                <section className="mb-4">
                  <h3 className={styles.sectionTitle}>Driver Signature</h3>
                  <div>
                    <i className="lg">{data?.data?.driver_name} - </i>
                    {new Date(data?.data?.created_at).toLocaleString("en-US", {
                      dateStyle: "short",
                      timeStyle: "short",
                    })}
                  </div>
                </section>
              </article>
            </div>
          </div>
        )}
      </CustomScroll>
    </>
  );
};

export default Detail;
