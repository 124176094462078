import React from "react";
import { NavLink } from "react-router-dom";

const ConfigurationMenu = () => {
  return (
    <nav className="headerTabs">
      <NavLink className="headerTab" to={`/fleet-management/configuration/users`}>
        Users
      </NavLink>
      <NavLink className="headerTab" to={`/fleet-management/configuration/beacons`}>
        Beacons
      </NavLink>
      <NavLink className="headerTab" to={`/fleet-management/configuration/telematics`}>
        Telematics
      </NavLink>
    </nav>
  );
};

export default ConfigurationMenu;
