import React from "react";
import CompanyContact from "./CompanyContact";

const CompanyContacts = ({ location, contacts, setCompany, setEditingContact, setArchivingContact, setArchiveContact }) => {
  return (
    <>
      {!!contacts && !!contacts.length && contacts.map((contact) => (
        <div
          className="p-0"
          key={contact.id}
        >
          <CompanyContact
            setCompany={setCompany}
            location={location}
            contact={contact}
            setEditingContact={setEditingContact}
            setArchivingContact={setArchivingContact}
            setArchiveContact={setArchiveContact}
          />
        </div>
    ))} 
    </>
  );
};

export default CompanyContacts;
