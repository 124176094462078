import React from "react";
import styles from "../styles.module.scss";
import PropTypes from "prop-types";

const ProductionRate = ({ item }) => {
  return (
    <td className="sm-med text-end">
      <span className={styles.rateWrapper}>{item?.entity_type === "task" && <>{item?.production_rate || "-"}</>}</span>
    </td>
  );
};

ProductionRate.propTypes = {
  editTasks: PropTypes.bool,
  item: PropTypes.object.isRequired,
  updateItem: PropTypes.func,
};

export default ProductionRate;
