import { useEffect, useState } from "react";
import { useAuth } from "component-library";

const useUpdateTodos = (vendors, setVendors) => {
  const { config } = useAuth();
  const [addNewContact, setAddNewContact] = useState();
  const [newVendorContact, setNewVendorContact] = useState();

  const getCompanyCategory = (catString) => {
    if (!!catString && !!catString[0]) {
      const categories = JSON.parse(catString[0]);
      const cats = categories.map((id) => {
        const cat = config.company_categories.find((c) => c.id === id);
        return cat;
      });
      return cats;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!!newVendorContact && !!newVendorContact.id) {
      // data sync issue
      // when pulling latest list of vendors,
      // the new vendor is not in the list
      const newVendor = {
        categories: getCompanyCategory(newVendorContact.locations[0].company_categories),
        certifications: [],
        contact: newVendorContact,
        company: {
          id: newVendorContact.locations[0].company_id,
          name: newVendorContact.locations[0].company_name[0],
        },
        id: `${newVendorContact.id}:${newVendorContact.locations[0].company_id}`,
      };
      setVendors([newVendor, ...vendors]);

      setNewVendorContact(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newVendorContact]);

  return {
    addNewContact,
    setAddNewContact,
    newVendorContact,
    setNewVendorContact,
  };
};

export default useUpdateTodos;
