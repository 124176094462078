import React from "react";
import { Button } from "react-bootstrap";
import { FilterSearch } from "component-library";
import useEstimateConversion from "../useEstimateConversion";
import PropTypes from "prop-types";
import { PlusCircle } from "react-feather";

const TakeoffControls = ({ filterTakeoffs, handleAddTakeoff }) => {
  const { poCreated, canEdit } = useEstimateConversion();
  return (
    <div className="pb-2 position-relative">
      <div className="d-flex justify-content-between align-items-center mt-2 ">
        <div className="d-flex justify-content-between w-100 align-items-center">
          <div className="d-flex align-items-center">
            <Button
              className="text-gray2 med ps-0"
              variant="link"
              disabled={!canEdit || !!poCreated}
              onClick={() => handleAddTakeoff()}
            >
              <div className="d-flex align-items-center text-gray2">
                <PlusCircle className="me-1" size={14} color={!canEdit ? "#6c757d" : "#0071ce"} />
                Add Material
              </div>
            </Button>
          </div>
          <div>
            <FilterSearch searchFunction={filterTakeoffs} placeholder="Search" loading={false} inline />
          </div>
        </div>
      </div>
    </div>
  );
};

TakeoffControls.propTypes = {
  filterTakeoffs: PropTypes.func,
  handleAddTakeoff: PropTypes.func,
};

export default TakeoffControls;
