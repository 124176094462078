import React, { useState } from "react";
import TaskHeader from "./TaskHeader";
import styles from "./styles.module.scss";
import TaskSubheader from "./TaskSubheader";
import TimeEntry from "./TimeEntry";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import useTask from "./useTask";
import NotesModal from "../NotesModal";

const Task = ({ task, toggleTasksOpen, openTasks, tasks, setTasks }) => {
  const { active, setActive, quantity, setQuantity, updateError, updateLoading, updateTasks } = useTask({
    task,
    tasks,
    setTasks,
    openTasks,
  });
  const [comments, setComments] = useState();
  const handleUpdateNoteReportStatus = (updatedNote) => {
    const updatedTask = {
      ...task,
      task_comments: task?.task_comments?.map((note) => (note?.id === updatedNote?.id ? updatedNote : note)),
    };

    const updatedTasks = tasks.map((t) => (t?.id === task.id ? updatedTask : t));

    setTasks(updatedTasks);
  };

  return (
    <>
      {!!updateError && <Alert variant="danger">{updateError}</Alert>}
      <div className={styles.task}>
        <TaskHeader
          task={task}
          setActive={setActive}
          active={active}
          toggleTasksOpen={toggleTasksOpen}
          setComments={setComments}
        />
        <TaskSubheader
          task={task}
          updateTasks={updateTasks}
          updateLoading={updateLoading}
          quantity={quantity}
          setQuantity={setQuantity}
        />
        {!!active && (
          <>
            <div className={styles.entries}>
              {task?.time_entries?.length ? (
                task.time_entries.map((entry, index) => <TimeEntry key={index} entry={entry} />)
              ) : (
                <div className={styles.entryHours}>No Time Entries</div>
              )}
            </div>
          </>
        )}
      </div>
      {!!comments && (
        <NotesModal
          handleUpdateNoteReportStatus={handleUpdateNoteReportStatus}
          setTasks={setTasks}
          openNotes={comments}
          setOpenNotes={setComments}
        />
      )}
    </>
  );
};

Task.propTypes = {
  task: PropTypes.object,
  setComments: PropTypes.func,
  toggleTasksOpen: PropTypes.func,
  openTasks: PropTypes.array,
  updateTasks: PropTypes.func,
};

export default Task;
