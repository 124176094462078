import React from "react";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import { ChevronLeft, MoreHorizontal } from "react-feather";
import { ReactComponent as Logo } from "../../../../assets/tractics-hdr-mobile.svg";

const MobileHeader = ({ backUrl }) => {
  return (
    <>
      <nav className={styles.navContainer}>
        <a href={backUrl} className={styles.backButton}>
          <ChevronLeft />
        </a>
        <Logo />
        <Button className={styles.otherActions}>
          <MoreHorizontal color="#66AAE2" size={25} />
        </Button>
      </nav>
    </>
  );
};

export default MobileHeader;
