import { useEffect, useState } from "react";
import { useApi, quoteApi, useAuth } from "component-library";
import { useParams } from "react-router-dom";
import useAddPurchaseOrder from "../../PurchaseOrders/useAddPurchaseOrder";
import { Save } from "react-feather";
import { HeaderButton } from "component-library";

const useQuote = () => {
  const { user } = useAuth();
  const { quoteId } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [quote, setQuote] = useState();
  const [used, setUsed] = useState(!!quote && !!quote.used ? true : false);
  const [redirect, setRedirect] = useState(null);

  const { addPurchaseOrder, loading: addPOLoading, error: addPOError, data: addPOData } = useAddPurchaseOrder();

  const {
    data: cancelRequoteData,
    loading: cancelRequoteLoading,
    error: cancelRequoteError,
    request: cancelRequoteRequest,
  } = useApi(quoteApi.cancelRequote);

  const {
    data: sendRequoteData,
    loading: sendRequoteLoading,
    error: sendRequoteError,
    request: sendRequoteRequest,
  } = useApi(quoteApi.sendRequoteRequest);

  const {
    data: createRequoteData,
    loading: createRequoteLoading,
    error: createRequoteError,
    request: createRequoteRequest,
  } = useApi(quoteApi.createRequote);

  const { data, loading: getLoading, error: getError, request } = useApi(quoteApi.getQuote);

  const {
    data: updateData,
    loading: updateLoading,
    error: updateError,
    request: updateRequest,
  } = useApi(quoteApi.updateQuote);

  const {
    data: deleteQuoteData,
    loading: deleteQuoteLoading,
    error: deleteQuoteError,
    request: deleteQuoteRequest,
  } = useApi(quoteApi.archiveQuote);

  const updateQuote = (q) => {
    updateRequest(q);
  };

  const requote = () => {
    createRequoteRequest(quote.id, user.id);
  };

  const cancelRequote = () => {
    cancelRequoteRequest(quote.requote.id, user.id);
  };

  const sendRequote = () => {
    sendRequoteRequest(quote.requote.id, user.id);
  };

  const handleDeleteQuote = () => {
    deleteQuoteRequest(quote.id);
  };

  const updateTerms = (value, field) => {
    updateQuote({
      id: quote.id,
      [field]: value,
    });
  };

  const SaveQuoteButton = () => (
    <>
      {!!quote && (
        <HeaderButton
          linkTo={`/project/${quote.project_id}/quotes/${quote.id}`}
          icon={<Save size="16" color="#0071ce" />}
          text="Save Quote"
        />
      )}
    </>
  );

  useEffect(() => {
    if (!!createRequoteData) {
      setQuote(createRequoteData.quote);
    }
  }, [createRequoteData]);

  useEffect(() => {
    if (!!sendRequoteData) {
      setQuote(sendRequoteData.quote);
    }
  }, [sendRequoteData]);

  useEffect(() => {
    if (!!cancelRequoteData) {
      setQuote(cancelRequoteData.quote);
    }
  }, [cancelRequoteData]);

  useEffect(() => {
    if (quoteId) request(quoteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId]);

  useEffect(() => {
    if (!!data && !!data.quote) {
      setQuote(data.quote);
      setUsed(!!data.quote.used ? true : false);
    }
  }, [data]);

  useEffect(() => {
    if (!!updateData && !!updateData.quote) {
      setQuote(updateData.quote);
      setUsed(!!updateData.quote.used ? true : false);
    }
  }, [updateData]);

  useEffect(() => {
    if (!!deleteQuoteData) {
      setRedirect(`/project/${quote.project_id}/quotes`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteQuoteData]);

  useEffect(() => {
    if (!!addPOLoading || !!updateLoading || !!cancelRequoteLoading || !!sendRequoteLoading || !!createRequoteLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [addPOLoading, updateLoading, cancelRequoteLoading, sendRequoteLoading, createRequoteLoading]);

  useEffect(() => {
    if (
      !!getError ||
      !!addPOError ||
      !!updateError ||
      !!cancelRequoteError ||
      sendRequoteError ||
      !!createRequoteError
    ) {
      setError(getError || addPOError || updateError || cancelRequoteError || sendRequoteError || createRequoteError);
    } else {
      setError();
    }
  }, [getError, addPOError, updateError, cancelRequoteError, sendRequoteError, createRequoteError]);

  return {
    quote,
    setQuote,
    error,
    getLoading,
    loading,
    setLoading,
    addPurchaseOrder,
    addPOError,
    addPOData,
    updateQuote,
    requote,
    cancelRequote,
    sendRequote,
    used,
    handleDeleteQuote,
    redirect,
    deleteQuoteLoading,
    deleteQuoteError,
    SaveQuoteButton,
    updateData,
    updateTerms,
  };
};

export default useQuote;
