import { useEffect, useState } from 'react';

const useUpdateTodos = (updateData, setTodos, setBid) => {
  const [dataError, setDataError] = useState();

  useEffect(() => {
    if (!!updateData) {
      if (!!updateData.project) {
        setTodos(updateData.project.bid_todos);
        setBid(updateData.project);
      } else {
        setDataError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  return {
    dataError, setDataError
  }

}

export default useUpdateTodos;