import styles from "./styles.module.scss";
import CurrentItem from "./CurrentItem";
import Carousel from "./Carousel";
import Title from "./Title";
import useGalleryView from "./hooks/useGalleryView";
import { Alert } from "react-bootstrap";
import { BeatLoader } from "react-spinners";

const GalleryView = ({
  project,
  photosList,
  photosError,
  photosLoading,
  albumsList,
  albumsError,
  albumsLoading,
  handlePin,
  refreshLists,
  selectMode,
  selectedItems,
  setSelectedItems,
}) => {
  const { combinedList, currentView, setCurrentView } = useGalleryView({ photosList, albumsList });

  return (
    <div className="d-flex flex-column align-items-center gap-5 flex-grow-1">
      {(photosLoading || albumsLoading) && (
        <div className="loaderWrapper">
          <BeatLoader color="#0071ce" />
        </div>
      )}
      <div className="d-flex flex-column">
        {photosError && <Alert variant="danger">{photosError}</Alert>}
        {albumsError && <Alert variant="danger">{albumsError}</Alert>}
      </div>
      {combinedList.length ? (
        <>
          <div className="d-flex flex-column align-items-center w-100 flex-grow-1">
            <Title combinedList={combinedList} currentView={currentView} />
            <div className={styles.sliderSection}>
              <CurrentItem
                project={project}
                combinedList={combinedList}
                currentView={currentView}
                setCurrentView={setCurrentView}
                handlePin={handlePin}
                refreshLists={refreshLists}
                selectMode={selectMode}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
              />
            </div>
          </div>
          <div className={styles.thumbSection}>
            <Carousel
              combinedList={combinedList}
              currentView={currentView}
              setCurrentView={setCurrentView}
              selectMode={selectMode}
              selectedItems={selectedItems}
            />
          </div>
        </>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center w-100 flex-grow-1">
          No items found.
        </div>
      )}
    </div>
  );
};

export default GalleryView;
