import Requirements from "../columnRenders/Requirements";

const requirements = {
  headerName: "Req",
  field: "requirements",
  minWidth: 300,
  wrapText: true,
  flex: 1,
  cellRenderer: Requirements
};

export default requirements;
