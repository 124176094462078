import { useState, useEffect } from "react";
import { useApi, dailyFieldlogsApi } from "component-library";
import PropTypes from "prop-types";
import useDailyFieldLogs from "../context/useDailyFieldLogs";
import useProject from "../../../../../hooks/useProject";

const useStats = () => {
  const { fieldMgmtDateRange } = useProject();
  const { updateRequests, getRequestParams } = useDailyFieldLogs();

  const [stats, setStats] = useState();

  const {
    request: statsRequest,
    data: statsData,
    error: statsError,
    loading: statsLoading,
  } = useApi(dailyFieldlogsApi.getStats);

  useEffect(() => {
    if (!!updateRequests && fieldMgmtDateRange[0] && !statsLoading) {
      const requestParams = getRequestParams(true);
      statsRequest(requestParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRequests]);

  useEffect(() => {
    if (statsData?.stats) {
      setStats(statsData.stats);
    }
  }, [statsData?.stats]);

  return {
    stats,
    statsLoading,
    statsError,
  };
};

useStats.propTypes = {
  currentSelectedDate: PropTypes.string,
};

export default useStats;
