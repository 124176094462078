import React from "react";
import DashboardSection from "./DashboardSection";
import MilestonesList from "./MilestonesList";
import MissingProjectDates from "./MissingProjectDates";

const Milestones = () => {
  return (
    <DashboardSection title="Upcoming Milestones">
      <MissingProjectDates />
      <MilestonesList />
    </DashboardSection>
  );
};

export default Milestones;
