import React from "react";
import { File } from "react-feather";
import { useParams, Link } from "react-router-dom";
import styles from "./styles.module.scss";

const DocName = ({ data }) => {
  const { id } = useParams();

  return (
    <div className="position-relative d-flex align-items-center justify-content-between" style={{ color: "#1980d4" }}>
      <File className="me-2" size={16} />
      <Link to={`/project/${id}/documents/asset/${data?.id}`} className="text-start p-0 med">
        <span className="text-limiter ">{data?.file_name}</span>
      </Link>
      {data.versions[data.versions.length - 1]?.version > 1 && (
        <span className={styles.version}>V{data.versions[data.versions.length - 1]?.version}</span>
      )}
    </div>
  );
};

export default DocName;
