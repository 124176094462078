import { useState, useEffect } from "react";
import InteriorPage from "../../components/pageLayout/InteriorPage";
import { Users, ArrowRight } from "react-feather";
import CrewDetailHeader from "./CrewDetailHeader";
import useCrewLogsDetail from "./useCrewLogsDetail";
import CrewDetailFilters from "./CrewDetailFilters";
import ReportingConsole from "../ReportingConsole/ReportingConsole";
import { Link } from "react-router-dom";
import useResourceManagement from "../context/useResourceManagement";
import CrewLogDetailListing from "./CrewLogDetailListing";
import Pagination from "../../components/Pagination";
import TwoWeekMessage from "../../_projects/project/FieldManagement/FieldLogs/TwoWeekMessage";

const CrewLogDetail = ({ projectId }) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const { dateRange, setDateRange, contextInitialLoad } = useResourceManagement();
  const {
    selectedProject,
    setSelectedProject,
    loading,
    projectsLoading,
    projectsList,
    selectedForeman,
    setSelectedForeman,
    selectedCrewMember,
    gridData,
    crewId,
    foremenList,
    consoleData,
    error,
    page,
    setPage,
    total,
  } = useCrewLogsDetail({ projectId, dateRange, initialLoad });

  useEffect(() => {
    if ((!!initialLoad, !contextInitialLoad)) {
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, contextInitialLoad]);

  return (
    <InteriorPage
      error={error}
      navTitle={"Field Management"}
      navIcon={<Users color="#fff" size={14} />}
      pageTitle={
        <div className="d-flex align-items-center">
          <Link to={`/field-management/crew-logs`}>Crew Timesheets</Link>
          <ArrowRight className="mx-4" size={12} color="#7B7B7B" />
          Crew Detail
        </div>
      }
    >
      <TwoWeekMessage dateRange={dateRange} />
      <CrewDetailHeader selectedCrewMember={selectedCrewMember} loading={loading} />
      <CrewDetailFilters
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        projectsLoading={projectsLoading}
        projectsList={projectsList}
        selectedForeman={selectedForeman}
        setSelectedForeman={setSelectedForeman}
        foremenList={foremenList}
        loading={loading}
        dateRange={dateRange}
        setDateRange={setDateRange}
        projectId={projectId}
      />
      <div className="position-relative">
        <ReportingConsole
          projectId={selectedProject}
          foremanId={selectedForeman}
          crewId={crewId}
          error={error}
          loading={loading}
          consoleData={consoleData}
        />
        <CrewLogDetailListing gridData={gridData} loading={loading} />
        <Pagination items={gridData} setCurrentPage={setPage} currentPage={page} total={total} itemsPerPage={25} />
      </div>
    </InteriorPage>
  );
};

export default CrewLogDetail;
