import React, { useState } from "react";
import { Edit3 } from "react-feather";
import styles from "../styles.module.scss";
import { currency } from "component-library";
import EditSum from "./EditSum";

const Sum = ({ purchaseOrder, updatePurchaseOrder }) => {
  const [editing, setEditing] = useState(false);

  return (
    <div className={`${styles.section} ${styles.sumSection}`}>
      {!editing && !!purchaseOrder.sum ? (
        <>
          <div className={styles.sumDisplay}>
            <label className={styles.label}>
              For the Sum of {currency(purchaseOrder.sum)}
            </label>
            {purchaseOrder.status === 0 && (
              <button
                onClick={() => setEditing(true)}
                className={styles.iconButton}
                aria-label="edit purchase order sum"
              >
                <Edit3 size={18} color="#0071ce" />
              </button>
            )}
          </div>
          {!!purchaseOrder.edited_sum_reason && (
            <div className={styles.sumReason}>
              <label className={styles.label}>
                Original Sum: {currency(purchaseOrder.quote.amount)}
              </label>
              <p>{purchaseOrder.edited_sum_reason}</p>
            </div>
          )}
        </>
      ) : (
        <EditSum
          purchaseOrder={purchaseOrder}
          setEditing={setEditing}
          updatePurchaseOrder={updatePurchaseOrder}
        />
      )}
    </div>
  );
};

export default Sum;
