import React from "react";
import { Table } from "react-bootstrap";
import styles from "./styles.module.scss";

const InvoiceChart = ({ lg, invoiceTotals }) => {
  return (
    <>
      {!!invoiceTotals && (
        <div className="d-sm-flex flex-sm-row justify-content-between mt-4">
          <div className={`${styles.invoicePrint} me-4`}>
            <Table className="mb-lg-0">
              <tbody>
                <tr className={styles.invoiceRow}>
                  <th className={styles.invoiceTh}>Original Contract Value</th>
                  <td
                    className={styles.invoiceTd}
                  >{`$${new Intl.NumberFormat().format(
                    invoiceTotals.original_contract
                  )}`}</td>
                </tr>
                <tr className={styles.invoiceRow}>
                  <th className={styles.invoiceTh}>Approved Change Order</th>
                  <td
                    className={styles.invoiceTd}
                  >{`$${new Intl.NumberFormat().format(
                    invoiceTotals.approved_change_order
                  )}`}</td>
                </tr>
                <tr className={styles.invoiceRow}>
                  <th className={styles.invoiceTh}>Total Contract Value</th>
                  <td
                    className={`${styles.invoiceTd} ${styles.invoiceTotal}`}
                  >{`$${new Intl.NumberFormat().format(
                    invoiceTotals.total_contract_value
                  )}`}</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className={styles.invoicePrint}>
            <Table className="mb-0">
              <tbody>
                <tr className={styles.invoiceRow}>
                  <th className={styles.invoiceTh}>Total Billed</th>
                  <td
                    className={styles.invoiceTd}
                  >{`$${new Intl.NumberFormat().format(
                    invoiceTotals.total_billed
                  )}`}</td>
                </tr>
                <tr className={styles.invoiceRow}>
                  <th className={styles.invoiceTh}>Total Paid</th>
                  <td
                    className={`${styles.invoiceTd} ${styles.invoiceTotal}`}
                  >{`$${new Intl.NumberFormat().format(
                    invoiceTotals.paid
                  )}`}</td>
                </tr>
                <tr className={styles.invoiceRow}>
                  <th className={styles.invoiceTh}>
                    Balance Due <span>(less retainage)</span>
                  </th>
                  <td
                    className={`${styles.invoiceTd} ${styles.due}`}
                  >{`$${new Intl.NumberFormat().format(
                    invoiceTotals.outstanding
                  )}`}</td>
                </tr>
                <tr className={styles.invoiceRow}>
                  <th className={styles.invoiceTh}>Retainage</th>
                  <td
                    className={`${styles.invoiceTd} ${styles.retainage}`}
                  >{`$${new Intl.NumberFormat().format(
                    invoiceTotals.retainage
                  )}`}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </>
  );
};

export default InvoiceChart;
