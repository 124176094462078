import React from "react";
import { quoteStatuses } from "component-library";

const colors = {
  0: "#FFA400",
  1: "#F0C808",
  2: "#F0C808",
  3: "#F0C808",
  4: "#f5f5f5",
  5: "#7DDB2D",
};

const Status = ({ data }) => {
  const getStatus = () => {
    if (data.status === 1 && !!data.used) return "Apparent Low";
    const statusObj = quoteStatuses.find((qs) => qs.id === data.status);
    return statusObj.name;
  };

  return (
    <div className="d-flex">
      <div
        className="q-statusPill"
        style={{ backgroundColor: colors[data.adjusted_status] }}
      >
        {getStatus()}
      </div>
    </div>
  );
};

export default Status;
