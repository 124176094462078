import React from "react";
import ColumnHeader from "../../ColumnHeader";
import fieldLogWidths from "../../gridWidths/fieldLogWidths";
import PWPill from "../../../../components/PWPill";
import { MessageSquare } from "react-feather";
import styles from "../../styles.module.scss"

const ColumnHeaders = () => {
  return (
    <div className={styles.headerWrapper}>
      <ColumnHeader text="Date" width={fieldLogWidths.date} />
      <ColumnHeader icon={<PWPill />} />
      <ColumnHeader text="Job #" width={fieldLogWidths.job_number} />
      <ColumnHeader text="Project" width={fieldLogWidths.project_name} />
      <ColumnHeader text="Foreman" width={fieldLogWidths.foreman} />
      <ColumnHeader text="Project Managers" width={fieldLogWidths.project_managers} />
      <ColumnHeader text="Hours" width={fieldLogWidths.hours} />
      <ColumnHeader text="Reporting" width={fieldLogWidths.alerts} />
      <ColumnHeader text="Status" width={fieldLogWidths.status} />
      <ColumnHeader icon={<MessageSquare size="16" color="#A7A7A7" />} />
    </div>
  );
};

export default ColumnHeaders;
