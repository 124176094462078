import React from "react";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import styles from "./styles.module.scss";
import { setHours, setMinutes } from "date-fns";
import TimePicker from "../../../../../components/TimePicker";
import { BeatLoader } from "react-spinners";
import InterfaceIcon from "../../../../../components/InterfaceIcon";
import { Button } from "react-bootstrap";

const EditTime = ({
  loading,
  timeIn,
  timeOut,
  setTimeIn,
  setTimeOut,
  updateTimeIn,
  updateTimeOut,
  timesheetLocked,
  timesheet,
  project,
  setFocusMarker,
}) => {
  return (
    <div className={styles.clockWrapper}>
      <div className={styles.times}>
        <span className={styles.timesTitle}>Clock In / Clock Out</span>
        <div className="position-relative">
          {!!loading && (
            <div className="loaderWrapper">
              <BeatLoader color={"#0071ce"} />
            </div>
          )}
          <div className={`${styles.innerWrapper} ${!!timesheetLocked ? styles.locked : ""}`}>
            <ArrowRightCircle color="#259FAD" />
            <TimePicker
              minTime={setHours(setMinutes(new Date(), 0), 0)}
              maxTime={setHours(
                setMinutes(new Date(), parseInt(new Date(timeOut).getMinutes())),
                parseInt(new Date(timeOut).getHours())
              )}
              selected={timeIn}
              setSelected={setTimeIn}
              sendRequest={(date) => updateTimeIn(Date.parse(date))}
              canEdit={!!loading || !timesheetLocked}
              style={styles.timePicker}
              placement="bottom"
            />
          </div>
          {!!project && !!parseFloat(timesheet?.clockin_lat) ? (
            <div className="d-flex flex-row align-items-center my-2">
              <InterfaceIcon
                isWithinGeofence={timesheet?.within_geofence}
                interfaceUsed={timesheet?.interface}
                size={24}
              />
              <div className={styles.location}>
                <Button
                  variant="link"
                  className="p-0 text-gray2"
                  onClick={() => setFocusMarker({ lat: timesheet?.clockin_lat, lng: timesheet?.clockin_lng })}
                >
                  {timesheet?.clockin_lat},{timesheet?.clockin_lng}
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex flex-row align-items-center my-2">
                <InterfaceIcon
                  isWithinGeofence={timesheet?.within_geofence}
                  interfaceUsed={timesheet?.interface}
                  size={24}
                />
                <div className={styles.location}>n/a</div>
              </div>
            </>
          )}
          <div className={`${styles.innerWrapper} ${!!timesheetLocked ? styles.locked : ""}`}>
            <ArrowLeftCircle color="#FF6700" />
            <TimePicker
              minTime={setHours(
                setMinutes(new Date(), parseInt(new Date(timeIn).getMinutes())),
                parseInt(new Date(timeIn).getHours())
              )}
              maxTime={setHours(setMinutes(new Date(), 45), 23)}
              selected={timeOut}
              setSelected={setTimeOut}
              sendRequest={(date) => updateTimeOut(Date.parse(date))}
              canEdit={!!loading || !timesheetLocked}
              style={styles.timePicker}
              placement="bottom"
            />
          </div>
          {!!project && !!parseFloat(timesheet?.clockin_lat) && !!parseFloat(timesheet?.clockout_lat) ? (
            <div className="d-flex flex-row align-items-center mb-2">
              <InterfaceIcon
                isWithinGeofence={timesheet?.within_geofence_clock_out}
                interfaceUsed={timesheet?.interface_clock_out}
                size={24}
              />
              <div className={styles.location}>
                <Button
                  variant="link"
                  className="p-0 text-gray2"
                  onClick={() => setFocusMarker({ lat: timesheet?.clockout_lat, lng: timesheet?.clockout_lng })}
                >
                  {timesheet?.clockout_lat},{timesheet?.clockout_lng}
                </Button>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-row align-items-center mb-2">
              <InterfaceIcon
                isWithinGeofence={timesheet?.within_geofence_clock_out}
                interfaceUsed={timesheet?.interface_clock_out}
                size={24}
              />
              <div className={styles.location}>n/a</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditTime;
