import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import Tasks from "./Tasks";
import useTasks from "./useTasks";
import { Alert } from "react-bootstrap";
import { BoomSelector, FilterSearch, displayDate, Loader } from "component-library";
import { Calendar } from "react-feather";
import useProject from "../../../../hooks/useProject";

const ProjectTasks = ({ dateRange, setDateRange }) => {
  const {
    tasksLoading,
    error,
    taskCount,
    selectedTask,
    setSelectedTask,
    handleSearch,
    taskOptions,
    filteredTasks,
    selectedFilterOption,
    refreshColumns,
    setRefreshColumns,
  } = useTasks({ dateRange, setDateRange });

  const { reportingExpanded, setReportingExpanded } = useProject();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!!filteredTasks?.length) {
      setTimeout(() => {
        setReportingExpanded(true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTasks, currentPage]);

  return (
    <>
      <Loader loading={tasksLoading} size={16} />
      <div className={styles.printOnlyDate}>
        <Calendar size={18} color="#0071ce" className="me-2" />
        <h2>{dateRange.length ? `${displayDate(dateRange[0])} - ${displayDate(dateRange[1])}` : "All Dates"}</h2>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <h2 className={styles.tasksTitle}>Tasks</h2>
          <span className={styles.tasksLength}>({taskCount || 0})</span>
        </div>
        <div className="d-flex align-items-center">
          <div className="hiddenPrint">
            <BoomSelector
              selected={selectedTask}
              setSelected={setSelectedTask}
              options={taskOptions}
              loading={tasksLoading}
              placeholder="Filter by..."
            />
          </div>
          <div className={styles.filterWrap}>
            <FilterSearch inline searchFunction={handleSearch} />
          </div>
        </div>
      </div>
      {!!error && <Alert variant="danger">{error}</Alert>}
      {!!filteredTasks?.length ? (
        <Tasks
          tasks={filteredTasks}
          selectedFilterOption={selectedFilterOption}
          refreshColumns={refreshColumns}
          setRefreshColumns={setRefreshColumns}
          expandAll={reportingExpanded}
          setExpandAll={setReportingExpanded}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowIdField="item_id"
        />
      ) : (
        <>No tasks found</>
      )}
    </>
  );
};

export default ProjectTasks;
