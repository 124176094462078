import { useEffect, useState, useMemo } from "react";
import { FolderPlus, Upload, Share2, XCircle, Download, Link, Trash2, Move } from "react-feather";
import { HeaderButton, useAuth, useApi, photoLibraryApi } from "component-library";
import useProject from "../../../hooks/useProject";
import { ReactComponent as Unpinned } from "../../../../assets/photoUnpinned.svg";
import useDownloadPhotos from "./../hooks/useDownloadPhotos";
import styles from "./styles.module.scss";

/**
 * Custom React hook for managing library view functionality.
 * @param {Function} props.refreshLists - Function to hard refresh photo and album lists.
 * @param {boolean} props.isAlbumView - Boolean indicating whether the current view is an album view.
 * @returns {Object} An object containing various states for managing views, modal visibility, select mode, and select mode action buttons.
 */

const useLibraryView = ({ refreshLists, isAlbumView }) => {
  const { userCan } = useAuth();
  const { project } = useProject();
  const [activeView, setActiveView] = useState("grid-view");
  const [showCreateAlbumModal, setShowCreateAlbumModal] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [showPinUnpinModal, setShowPinUnpinModal] = useState(false);
  const [showAddToAlbumModal, setShowAddToAlbumModal] = useState(false);
  const [pinUnpinError, setPinUnpinError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [selectMode, setSelectMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { downloadPhotos, downloadLoading } = useDownloadPhotos(selectedItems, project);
  const albumAddDisabled =
    !userCan("photo-library-edit") ||
    !project ||
    selectedItems.length === 0 ||
    selectedItems.some((item) => item.type === "album");
  const isNoSelection = !userCan("photo-library-edit") || !project || !selectedItems.length;
  const deleteDisabled = !userCan("photo-library-delete") || !project || !selectedItems.length;
  const isPinAction = useMemo(() => {
    return selectedItems && selectedItems.length > 0 && selectedItems.some((item) => !item.pinned);
  }, [selectedItems]);

  const {
    request: photoBatchPinUnpinRequest,
    data: photoBatchPinUnpinData,
    loading: photoBatchPinUnpinLoading,
    error: photoBatchPinUnpinError,
  } = useApi(photoLibraryApi.pinBatchPhotos);

  const {
    request: albumBatchPinUnpinRequest,
    data: albumBatchPinUnpinData,
    loading: albumBatchPinUnpinLoading,
    error: albumBatchPinUnpinError,
  } = useApi(photoLibraryApi.pinBatchAlbums);

  const {
    request: photoBatchDeleteRequest,
    data: photoBatchDeleteData,
    loading: photoBatchDeleteLoading,
    error: photoBatchDeleteError,
  } = useApi(photoLibraryApi.deleteBatchPhotos);

  const {
    request: albumBatchDeleteRequest,
    data: albumBatchDeleteData,
    loading: albumBatchDeleteLoading,
    error: albumBatchDeleteError,
  } = useApi(photoLibraryApi.deleteBatchAlbums);

  useEffect(() => {
    if (photoBatchPinUnpinData?.success) {
      setSelectMode(false);
      setSelectedItems([]);
      refreshLists();
    }
    setShowPinUnpinModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoBatchPinUnpinData]);

  useEffect(() => {
    if (albumBatchPinUnpinData?.success) {
      setSelectMode(false);
      setSelectedItems([]);
      refreshLists();
    }
    setShowPinUnpinModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [albumBatchPinUnpinData]);

  useEffect(() => {
    setPinUnpinError(photoBatchPinUnpinError || albumBatchPinUnpinError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoBatchPinUnpinError, albumBatchPinUnpinError]);

  useEffect(() => {
    if (photoBatchDeleteData?.success) {
      setSelectMode(false);
      setSelectedItems([]);
      refreshLists();
    }
    setShowDeleteModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoBatchDeleteData]);

  useEffect(() => {
    if (albumBatchDeleteData?.success) {
      setSelectMode(false);
      setSelectedItems([]);
      refreshLists();
    }
    setShowDeleteModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [albumBatchDeleteData]);

  useEffect(() => {
    setDeleteError(photoBatchDeleteError || albumBatchDeleteError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoBatchDeleteError, albumBatchDeleteError]);

  const ItemSelection = () => <div className={styles.selectText}>{selectedItems.length} Selected</div>;

  const PhotoActionButtons = () => (
    <div className="d-flex align-items-center gap-3">
      <HeaderButton
        icon={<FolderPlus size={16} color="#0071ce" />}
        ariaLabel="Select photos"
        text="Select Photos"
        onClick={() => setSelectMode(true)}
        disabled={!project}
      />
      <HeaderButton
        icon={<FolderPlus size={16} color="#0071ce" />}
        ariaLabel="Create project photo album"
        text="Create Album"
        onClick={() => setShowCreateAlbumModal(true)}
        disabled={!userCan("photo-library-edit") || !project}
      />
      <HeaderButton
        icon={<Upload size={16} color="#0071ce" />}
        ariaLabel="Upload photo to project"
        text="Upload Photo(s)"
        onClick={() => setShowUploadFileModal(true)}
        disabled={!userCan("photo-library-edit") || !project}
      />
    </div>
  );

  const SelectActionButtons = () => (
    <div className="d-flex align-items-center gap-3">
      {/* hidden for the initial release of the photo library, active again when implementing the feature
        <HeaderButton
          icon={<Share2 size={16} color="#0071ce" />}
          ariaLabel="Share photos and albums"
          text="Share"
          disabled={!project}
        />
      */}
      <HeaderButton
        icon={isAlbumView ? <Move color="#0071ce" size={16} /> : <FolderPlus color="#0071ce" size={16} />}
        ariaLabel="Add photo to album"
        text={isAlbumView ? "Move to Album" : "Add to Album"}
        onClick={() => setShowAddToAlbumModal(true)}
        disabled={albumAddDisabled || isNoSelection}
      />
      {/* hidden for the initial release of the photo library, active again when implementing the feature
        <HeaderButton
          icon={<Link size={16} color="#0071ce" />}
          ariaLabel="Attach photos and albums"
          text="Attach"
          disabled={!project}
        />
      */}
      <HeaderButton
        icon={<Download size={16} color="#0071ce" />}
        ariaLabel="Download photos"
        text="Download"
        disabled={!project || downloadLoading || selectedItems.length === 0}
        onClick={() => downloadPhotos()}
      />
      <HeaderButton
        icon={<Unpinned className={styles.pinIcon} />}
        ariaLabel="Pin or unpin photos"
        text="Pin"
        onClick={() => setShowPinUnpinModal(true)}
        disabled={isNoSelection}
      />
      <HeaderButton
        icon={<Trash2 size={16} color="#0071ce" />}
        ariaLabel="Delete photo or album"
        text="Delete"
        onClick={() => setShowDeleteModal(true)}
        disabled={deleteDisabled}
      />
      <HeaderButton
        icon={<XCircle size={16} color="#0071ce" />}
        ariaLabel="Cancel select photos"
        text="Cancel"
        onClick={() => {
          setSelectMode(false);
          setSelectedItems([]);
        }}
        disabled={!userCan("photo-library-edit") || !project}
      />
    </div>
  );

  const pinUnpinItems = (isPinAction, items) => {
    const photoIds = items.filter((item) => item.type === "photo").map((item) => item.id);
    const albumIds = items.filter((item) => item.type === "album").map((item) => item.id);
    if (photoIds.length) {
      photoBatchPinUnpinRequest({ projectId: project.id, pinned: isPinAction, photoIds });
    }
    if (albumIds.length) {
      albumBatchPinUnpinRequest({ projectId: project.id, pinned: isPinAction, albumIds });
    }
  };

  const deleteItems = (items) => {
    const photoIds = items.filter((item) => item.type === "photo").map((item) => item.id);
    const albumIds = items.filter((item) => item.type === "album").map((item) => item.id);
    if (photoIds.length) {
      photoBatchDeleteRequest({ projectId: project.id, photoIds });
    }
    if (albumIds.length) {
      albumBatchDeleteRequest({ projectId: project.id, albumIds });
    }
  };

  return {
    activeView,
    setActiveView,
    PhotoActionButtons,
    showCreateAlbumModal,
    setShowCreateAlbumModal,
    showUploadFileModal,
    SelectActionButtons,
    setShowUploadFileModal,
    selectMode,
    setSelectMode,
    selectedItems,
    setSelectedItems,
    ItemSelection,
    showPinUnpinModal,
    setShowPinUnpinModal,
    pinUnpinItems,
    photoBatchPinUnpinLoading,
    albumBatchPinUnpinLoading,
    pinUnpinError,
    setPinUnpinError,
    showDeleteModal,
    setShowDeleteModal,
    deleteItems,
    photoBatchDeleteLoading,
    albumBatchDeleteLoading,
    deleteError,
    setDeleteError,
    showAddToAlbumModal,
    setShowAddToAlbumModal,
    isPinAction,
  };
};

export default useLibraryView;
