import React from "react";
import { dropdownStyles, useAuth } from "component-library";

const SubmittalDate = ({ renderTextField, renderSelectField, formData, mainField, typeField, customField }) => {
  const { config } = useAuth();

  const responseOptions = config.maintenance_period_config.map((option) => ({
    value: option.id,
    label: option.value,
  }));

  const customVal =
    !!responseOptions && responseOptions.find((option) => option.label.toLowerCase() === "custom").value;

  return (
    <div className="d-flex align-items-end">
      <div className="col-md-4 me-3">
        {renderTextField({
          name: mainField.name,
          label: mainField.label,
        })}
      </div>
      <div className="col-md-3 me-3">
        {renderSelectField({
          name: typeField,
          options: responseOptions,
          styles: dropdownStyles,
        })}
      </div>
      {formData[typeField] === customVal && (
        <div className="col-md-3">
          {renderTextField({
            name: customField,
            label: `Custom`,
          })}
        </div>
      )}
    </div>
  );
};

export default SubmittalDate;
