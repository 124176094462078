import React, { useEffect, useState } from "react";
import { useAuth } from "component-library";
import { GoogleMapsProvider } from "./GoogleMapsProvider";
import Routes from "./Routes";

const googleMapsLibraries = ["drawing", "places"];

function AppLoader() {
  const [ready, setReady] = useState(false);
  const { refreshUser, config } = useAuth();
  const loadApp = async () => {
    await refreshUser();
    setReady(true);
  };

  useEffect(() => {
    loadApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ready) return null;

  return (
    <>
      {!!config ? (
        <GoogleMapsProvider
          googleMapsApiKey={config.tenant?.find((tenConfig) => tenConfig.key === "TenantConfig::google_api_key").value}
          language="en"
          libraries={googleMapsLibraries}
        >
          <Routes />
        </GoogleMapsProvider>
      ) : (
        <Routes />
      )}
    </>
  );
}

export default AppLoader;
