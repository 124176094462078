import React from "react";
import IframePage from "./IframePage";
import ConfigurationMenu from "./submenus/ConfigurationMenu";

const ConfigurationBeaconsIframe = () => {
  return (
    <IframePage srcName="manage_configuration_beacons" pageTitle="Configure Beacons" Submenu={ConfigurationMenu} />
  );
};

export default ConfigurationBeaconsIframe;
