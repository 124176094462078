/* global google */
import React from "react";
import styles from "./styles.module.scss";
import { GoogleMap, DrawingManager, InfoWindow } from "@react-google-maps/api";
import { mapStyles } from "../../utils/mapStyles";
import useProjectsMap from "./useProjectsMap";
import useGeofences from "../../hooks/useGeofences";
import { BeatLoader } from "react-spinners";
import hqCoordinates from "../../_fleetManagement/Map/hqCoordinates";
import ProjectsList from "./ProjectsList";
import ProjectListItem from "./ProjectListItem";
import MapSettingsCheck from "../../components/MapSettingsCheck";

const ProjectsMap = () => {
  const { projects, loading, geofenceLocations, handleUserSearch } = useProjectsMap();
  const {
    onLoadDrawingManager,
    polyOptions,
    onLoadMap,
    setSelectedPolygon,
    selectedPolygon,
    getSelectedCenter,
    setActiveMarker,
  } = useGeofences({
    geofenceLocations,
    showGeofences: !!geofenceLocations?.length ? true : false,
  });

  const containerStyle = {
    width: "100%",
    height: "35.7rem",
  };

  return (
    <>
      <h2 className={styles.header}>Projects Overview</h2>
      <div className={`${styles.mapContainer} position-relative d-flex`}>
        {!!loading && (
          <div className="loaderWrapper">
            <BeatLoader color="#0017CE" />
          </div>
        )}
        <div className="col-8">
          <figure>
            <div className={`${styles.map} position-relative`}>
              {!!projects?.length && (
                <MapSettingsCheck>
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={hqCoordinates}
                    onClick={() => {
                      setActiveMarker();
                      setSelectedPolygon();
                    }}
                    zoom={7}
                    onLoad={onLoadMap}
                    options={{
                      styles: mapStyles,
                      fullscreenControl: false,
                    }}
                  >
                    {!!projects?.length && !loading && !!projects?.length && !!geofenceLocations?.length && (
                      <>
                        <DrawingManager
                          onLoad={onLoadDrawingManager}
                          options={{
                            drawingControlOptions: {
                              position: google.maps.ControlPosition.TOP_CENTER,
                              drawingModes: [null],
                            },
                            polygonOptions: {
                              ...polyOptions,
                            },
                          }}
                        />
                      </>
                    )}

                    {!!selectedPolygon?.geofence?.length && !!selectedPolygon?.name && (
                      <InfoWindow
                        onCloseClick={() => {
                          setSelectedPolygon({});
                        }}
                        position={getSelectedCenter()}
                      >
                        <div className="d-flex align-items-stretch justify-content-center px-2 font-weight-bold">
                          <ProjectListItem project={selectedPolygon.project} infoWindow />
                        </div>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                </MapSettingsCheck>
              )}
            </div>
          </figure>
        </div>
        <ProjectsList projects={projects} handleUserSearch={handleUserSearch} />
      </div>
    </>
  );
};

export default ProjectsMap;
