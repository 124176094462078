import { useEffect } from "react";
import { useFormControl, useApi, projectApi, dropdownStyles, Loader } from "component-library";
import { schema, initialData } from "./bidItem";
import useProject from "../../../../hooks/useProject";
import useEstimateConversion from "../../useEstimateConversion";
import { Alert, Button } from "react-bootstrap";
import styles from "./styles.module.scss";

const useAddBidItemForm = ({ setAddingBidItem, setMessage }) => {
  const { upbRequest, itemsPerPage, currentPage, unitsRatesMeasure, unitsOfMeasure } = useEstimateConversion();
  const { project, refreshProject } = useProject();

  const { request, data: updateData, loading, error } = useApi(projectApi.addBidItem);

  const onSubmit = async (formData, setLoading) => {
    setLoading(true);
    const defaultMeasure = unitsRatesMeasure.find((option) => option.label === "UM/Man Hour");
    try {
      await request({
        ...formData,
        entity_type: "item",
        project_id: project.id,
        rate_um: defaultMeasure.value,
      });
      setLoading(false);
    } catch (message) {
      console.error(message);
      setLoading(false);
    }
  };

  const { handleSubmit, renderSubmitButton, renderTextField, renderSelectField } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  useEffect(() => {
    if (updateData) {
      setAddingBidItem(false);
      setMessage("New task added.");
      upbRequest({
        q: "",
        id: project.id,
        page: currentPage,
        items_per_page: itemsPerPage,
      });
      if (project.upb_import_status !== 1) {
        refreshProject();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  const fields = (
    <>
      <section className="mb-5 p-3">
        <Loader loading={!!loading} />
        {!!error && <Alert variant="danger">{error}</Alert>}
        <div className="row mb-5">
          <div className="col-md-4">
            {renderTextField({
              name: "item_number",
              label: "Item Number",
              placeholder: "Item Number",
            })}
          </div>
          <div className="col-md-6">
            {renderTextField({
              name: "name",
              label: "Bid Item Name",
              autoFocus: true,
              placeholder: "Bid Item Name",
            })}
          </div>
        </div>
        <div className="row">
          <div className="row">
            <div className="col-md-5">
              {renderTextField({
                name: "bid_quantity",
                label: "Bid Quantity",
                placeholder: "Bid Quantity",
              })}
            </div>
            <div className="col-md-3">
              {renderSelectField({
                name: "unit_measure",
                label: "Unit",
                options: unitsOfMeasure,
                styles: dropdownStyles,
              })}
            </div>
          </div>
        </div>
      </section>
      <footer className={styles.footer}>
        <Button
          className={`med me-3 text-white font-weight-bold`}
          variant="gray5"
          onClick={() => setAddingBidItem(false)}
        >
          Cancel
        </Button>
        {renderSubmitButton("Add Bid Item", !!loading, styles.submitButton)}
      </footer>
    </>
  );

  return {
    handleSubmit,
    loading,
    error,
    renderTextField,
    renderSelectField,
    unitsOfMeasure,
    renderSubmitButton,
    fields,
  };
};

export default useAddBidItemForm;
