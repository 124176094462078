import React from "react";
import styles from "../styles.module.scss";

const AssetToggler = ({ data, handleChange, checked }) => {
  return (
    <div className={styles.checkWrapper}>
      <div className="form-group form-check">
        <input
          type="checkbox"
          className="form-check-input"
          name={data.id}
          id={data.id}
          onChange={handleChange}
          value={checked}
          checked={checked}
          aria-label="select row"
        />
        <label className="form-check-label" htmlFor={data.id}></label>
      </div>
    </div>
  );
};

export default AssetToggler;
