import React from "react";
import Chiclet from "./Chiclet";
import ManHoursItemChart from "./ManHoursItemChart";
import getJobName from "./getJobName";
import FooterLinkToProject from "./FooterLinkToProject";

const ManHoursItem = ({ job }) => {
  return (
    <Chiclet className="homeChartWrap">
      <div>
        <h1 className="med">{getJobName(job)}</h1>
        <ManHoursItemChart job={job} />
      </div>
      <FooterLinkToProject job={job} />
    </Chiclet>
  );
};

export default ManHoursItem;
