import React, { useState, useEffect } from "react";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";
import { Plus } from "react-feather";
import styles from "./styles.module.scss";
import TasksContainer from "./TasksContainer";
import { useAuth } from "component-library";
import { Search } from "react-feather";
import getTaskNumber from "../getTaskNumber";

const TaskPopover = ({ tasks, fieldLog, setFieldLog, foremanId, setOverAllocatedTimeSheets, editing }) => {
  const [q, setQ] = useState("");
  const [filteredTasks, setFilteredTasks] = useState(tasks);
  const { userCan } = useAuth();

  const filterTasks = (value) => {
    setQ(value);
  };

  const sortTasks = (tasks) => {
    const sortedTasks = tasks.map((phase) => {
      return {
        ...phase,
        tasks: phase.tasks.sort((a, b) => {
          const aItemNumber =
            a?.item_number || a?.bid_item_number || a?.parent_bid_item_number
              ? parseInt(a.item_number || a.bid_item_number)
              : Number.MAX_SAFE_INTEGER;

          const bItemNumber =
            b?.item_number || b?.bid_item_number || b?.parent_bid_item_number
              ? parseInt(b.item_number || b.bid_item_number)
              : Number.MAX_SAFE_INTEGER;

          const aCostCode = a.cost_code ? parseInt(a.cost_code) : Number.MAX_SAFE_INTEGER;

          const bCostCode = b.cost_code ? parseInt(b.cost_code) : Number.MAX_SAFE_INTEGER;

          if (aItemNumber < bItemNumber) {
            return -1;
          } else if (aItemNumber > bItemNumber) {
            return 1;
          } else {
            if (aCostCode < bCostCode) {
              return -1;
            } else if (aCostCode > bCostCode) {
              return 1;
            } else {
              if (a.task_name < b.task_name) {
                return -1;
              } else if (a.task_name > b.task_name) {
                return 1;
              } else {
                return 0;
              }
            }
          }
        }),
      };
    });
    return sortedTasks;
  };

  useEffect(() => {
    if (!!q && Object.keys(tasks).length !== 0) {
      const localFilteredTasks = tasks.map((phase) => {
        return {
          ...phase,
          tasks: phase.tasks.filter((t) => `${getTaskNumber(t)} ${t?.name}`.toLowerCase().includes(q?.toLowerCase())),
        };
      });
      const sortedTasks = sortTasks(localFilteredTasks);
      setFilteredTasks(sortedTasks);
    } else if (Object.keys(tasks).length !== 0) {
      const sortedTasks = sortTasks(tasks);
      setFilteredTasks(sortedTasks);
    }
  }, [q, tasks]);

  const popover = (
    <Popover placement="bottom" id="popover-tasks" positionbottom={-50}>
      <Popover.Body>
        <div className={styles.header}>
          <div className="position-relative d-flex align-items-center">
            <input
              className="form-control border-bottom form-control-has-icon"
              placeholder="Search Tasks"
              onChange={(e) => {
                filterTasks(e.target.value);
              }}
              value={q}
            />
            <div className={styles.formControlIcon}>
              <Search size="18" />
            </div>
          </div>
        </div>
        <div className={styles.taskPopover}>
          {!!filteredTasks &&
            !!filteredTasks.length &&
            filteredTasks.map((phase, index) => {
              return (
                <TasksContainer
                  key={index}
                  phase={phase}
                  fieldLog={fieldLog}
                  setFieldLog={setFieldLog}
                  foremanId={foremanId}
                  setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
                />
              );
            })}
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {editing && !["Exported", "PM Approved"].includes(fieldLog?.status?.status) && userCan("pm-review-time") && (
        <OverlayTrigger trigger="click" placement="bottom-start" transition={false} overlay={popover} rootClose>
          <Button variant="link d-flex align-items-center med">
            Add Task <Plus size="14" color="#0071ce" />
          </Button>
        </OverlayTrigger>
      )}
    </>
  );
};

export default TaskPopover;
