import React from "react";
import { Briefcase } from "react-feather";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import useProject from "../../hooks/useProject";
import usePurchaseOrders from "./usePurchaseOrders";
import config from "./poListConfig";
import { FilterSearch, SortableTable } from "component-library";
import statuses from "./config";
import { HeaderButton } from "component-library";

const PurchaseOrders = () => {
  const { loading, error, pageTitle, project, PageTag } = useProject();
  const {
    filteredPOs,
    loading: poLoading,
    error: poError,
    filterPOs,
  } = usePurchaseOrders();
  
  const AddButton = () => (
    <HeaderButton
      linkTo = {`/project/${!!project && project.id}/purchase-orders/add`}
      ariaLabel="Add New Purchase Order"
    />
  );

  return (
    <InteriorPage
      loading={loading || poLoading}
      error={error || poError}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle="Purchase Orders"
      pageTitleSecondaryContent={AddButton}
      NavContent={PageTag}
    >
      <div className="d-flex justify-content-end align-items-center mb-3">
        <FilterSearch
          searchFunction={filterPOs}
          inline
          placeholder="Search Purchase Orders"
          style={{ minWidth: 300 }}
        />
      </div>
      <SortableTable
        items={filteredPOs.map((po) => ({
          ...po,
          remaining: parseFloat(po.sum) - parseFloat(po.invoiced_amount),
          statusName: statuses[po.status] || "",
        }))}
        config={config}
        fallbackSort="supplier"
        url={(item) => `/project/${project.id}/purchase-orders/${item.id}`}
        sticky
      />
    </InteriorPage>
  );
};

export default PurchaseOrders;
