import React from "react";
import styles from "./styles.module.scss";
import useCreateAccount from "./hooks/useCreateAccount";

const CreateAccount = ({ registerUser, setRegistrationAlert }) => {
  const { fields, renderSubmitButton, handleSubmit } = useCreateAccount({
    registerUser,
    setRegistrationAlert,
  });

  return (
    <>
      <div>
        <form onSubmit={handleSubmit}>
          {fields}
          <div className={styles.footer}>{renderSubmitButton("Create Account")}</div>
        </form>
      </div>
    </>
  );
};

export default CreateAccount;
