import React from 'react';
import AdminIcon from "../../icons/AdminIcon";
import AdminDashboard from "../../_admin/pages/AdminDashboard";
import InteriorPage from "../pageLayout/InteriorPage";

const Admin = () => {
  return (
    <InteriorPage
      pageTitle="Dashboard"
      navTitle="Admin"
      navIcon={<AdminIcon color="#fff" size={14} />}
    >
      <AdminDashboard />
    </InteriorPage>
  );
}

export default Admin;