import { useEffect, useState } from "react";
import { useApi, projectApi, useAuth } from "component-library";

const useProvideLocates = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [locates, setLocates] = useState([]);
  const [projects, setProjects] = useState([]);
  const { userIs, user } = useAuth();
  const [failedLocates, setFailedLocates] = useState([]);
  const [error, setError] = useState();
  const userName = `${user.first_name} ${user.last_name}`;
  const isPM = userIs("project-manager");
  const isPC = userIs("project-coordinator");

  const {
    request: searchRequest,
    data: searchData,
    loading: searchLoading,
    error: searchError,
  } = useApi(projectApi.searchLocates);

  const { loading, request, error: projectError, data } = useApi(projectApi.getProjectsList);

  const { loading: updateLoading, request: updateRequest, error: updateError } = useApi(projectApi.saveLocates);

  const updateRelocates = async () => {
    setError();
    try {
      const newLocates = locates.filter((locate) => {
        return !!locate.new_number;
      });

      const formattedNewLocates = newLocates.map((locate) => {
        return {
          id: locate.id,
          number: locate.new_number,
          description: locate.description || null,
          type: locate.type || null,
          sb: locate.sb || 0,
          ds: locate.ds || 0,
          sb_required: locate.sb_required || 0,
          ds_required: locate.ds_required || 0,
          active_date: locate.active_date || null,
          expiration: locate.expiration || null,
          project_id: locate.project_id || null,
          status: "active",
          user: userName,
        };
      });

      const { success, responseData } = await updateRequest({
        locates: formattedNewLocates,
      });

      if (!!responseData && !!responseData.failed.length) {
        setFailedLocates(responseData.failed);
        setError(`Failed to update ${responseData.failed.length} locates`);
      } else {
        setFailedLocates([]);
        setError();
      }

      if (success) {
        searchRequest({
          q: "",
          project_id: null,
          from_date: null,
          to_date: null,
          status: "requested",
        });
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (data && data.projects) {
      setProjects(
        data.projects.map((project) => {
          return {
            value: project.id,
            label: project.name,
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchData && searchData.locates) {
      setSearchResults(searchData.locates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  return {
    searchResults,
    locates,
    setLocates,
    projects,
    searchLoading,
    searchError,
    searchRequest,
    loading,
    projectError,
    updateError,
    isPM,
    isPC,
    updateRelocates,
    updateLoading,
    error,
    failedLocates,
  };
};

export default useProvideLocates;
