import React, {useState, useEffect} from "react";
import { Button } from "react-bootstrap";
import { Bell } from "react-feather";
import styles from "./styles.module.scss";
import { fieldLogsApi, useApi, ConfirmModal } from "component-library";

const NoTasks = ({foremanId, crew}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [foreman, setForeman] = useState();
  const { loading, error, request, data } = useApi(fieldLogsApi.remindForemen);
  
  const sendReminders = () => {
    request([parseInt(foremanId)]);
  };

  useEffect(() => {
    if (!!foremanId && !!crew) {
      const fieldLogForeman = crew.find((c) => c.id === parseInt(foremanId));
      setForeman(`${fieldLogForeman.first_name} ${fieldLogForeman.last_name}`)
    }
  }, [crew, foremanId]);

  return (
    <div className={styles.noTasks}>
      <div>No tasks have been added for today</div>
      <Button onClick={() => setShowConfirm(true)} variant="link" className="med text-gray3 align-items-center d-flex">
        <Bell className="me-2" size="18" color="#0071ce" />
        Remind Foreman
      </Button>
      <ConfirmModal
        showConfirmation={showConfirm}
        setShowConfirmation={setShowConfirm}
        confirmFunction={sendReminders}
        loading={loading}
        error={error}
        name="Send Reminder"
        successMessage={!!data && !!data.success && "Reminder sent."}
        message={`Are you sure you want send a reminder to ${foreman}?`}
      />
    </div>
  );
};

export default NoTasks;
