import React from "react";
import FieldlogConsole from "./FieldlogConsole";
import TimesheetConsole from "./TimesheetConsole";

const ReportingConsole = ({
  projectId,
  foremanId,
  q,
  statuses,
  pm,
  crewId,
  isProject,
  isFieldlog,
  error,
  loading,
  consoleData,
}) => {
  return (
    <>
      {!!isFieldlog ? (
        <FieldlogConsole
          projectId={projectId}
          isProject={isProject}
          error={error}
          loading={loading}
          consoleData={consoleData}
        />
      ) : (
        <TimesheetConsole
          projectId={projectId}
          foremanId={foremanId}
          q={q}
          statuses={statuses}
          pm={pm}
          crewId={crewId}
          consoleData={consoleData}
          loading={loading}
          error={error}
        />
      )}
    </>
  );
};

export default ReportingConsole;
