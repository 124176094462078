import { useState, useEffect } from "react";
import { timesheetApi, useApi, projectApi, fieldLogsApi, useToasts, useAuth } from "component-library";
import { useParams, useNavigate } from "react-router-dom";
import formatProjectTitle from "../../../../utils/formatProjectTitle";
import useProject from "../../../hooks/useProject";

const useTimesheetDetail = () => {
  const { triggerToast } = useToasts();

  const history = useNavigate();
  const { project } = useProject();
  const { user, userCan } = useAuth();
  const { id, timesheetId, foremanId } = useParams();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [timesheet, setTimesheet] = useState();
  const [editing, setEditing] = useState(false);
  const [config, setConfig] = useState();
  const [pageTitle, setPageTitle] = useState();
  const [workDay, setWorkDay] = useState();
  const [projectsList, setProjectsList] = useState([]);
  const [unAssignedHours, setUnassignedHours] = useState(0);
  const [timesheetLocked, setTimesheetLocked] = useState(true);
  const [canDeleteTimesheet, setCanDeleteTimesheet] = useState();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");

  const { request: projectsRequest, data: projectsData } = useApi(projectApi.getProjectsList);

  const {
    request: timeSheetRequest,
    data: timeSheetData,
    loading: timeSheetLoading,
    error: timeSheetError,
  } = useApi(timesheetApi.getTimesheetDetail);

  const {
    request: timeSheetRequestByForeman,
    data: timeSheetDataByForeman,
    loading: timeSheetLoadingByForeman,
    error: timeSheetErrorByForeman,
  } = useApi(timesheetApi.getTimesheetDetailByForeman);
  const { request: configRequest, data: configData, loading: configLoading } = useApi(projectApi.getProjectConfig);

  const {
    request: timeSheetDeleteRequest,
    data: timeSheetDeleteData,
    loading: timeSheetDeleteLoading,
    error: timeSheetDeleteError,
  } = useApi(timesheetApi.deleteUserTimesheet);

  const {
    request: getWorkDayRequest,
    data: workDayData,
    loading: workDayLoading,
    error: workDayError,
  } = useApi(fieldLogsApi.getWorkdayDetails);

  const handleDeleteTimesheet = () => {
    timeSheetDeleteRequest(timesheet.id, deleteReason);
  };

  const handleSettingUnassignedHoursTotal = () => {
    let entry_time = timesheet?.time_entries?.reduce((acc, curr) => {
      return acc + parseInt(curr.minutes, 10);
    }, 0);
    if (timesheet?.all_time_entries?.length) {
      entry_time = timesheet?.all_time_entries?.reduce((acc, curr) => {
        return acc + parseInt(curr.minutes, 10);
      }, 0);
    }
    setUnassignedHours(parseInt(timesheet?.adjusted_minutes, 10) - entry_time);
  };

  useEffect(() => {
    if (!!timesheetId) {
      if (!!foremanId) {
        timeSheetRequestByForeman({ timesheetId, foremanId });
      } else {
        timeSheetRequest(timesheetId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheetId, foremanId]);

  useEffect(() => {
    if (!!timeSheetData) {
      setTimesheet(timeSheetData.time_sheet);
    }
    if (!!timeSheetDataByForeman?.time_sheet) {
      setTimesheet(timeSheetDataByForeman.time_sheet);
    }
  }, [timeSheetData, timeSheetDataByForeman]);

  useEffect(() => {
    if (!!timesheet) {
      getWorkDayRequest(timesheet.work_day_id);
      if (!config && !configLoading) {
        configRequest(timesheet.project_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheet?.project_id]);

  useEffect(() => {
    if (!!timesheet) {
      handleSettingUnassignedHoursTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheet?.time_entries, timesheet?.all_time_entries]);

  useEffect(() => {
    if (configData && configData.config) {
      setConfig(configData.config);
    }
  }, [configData]);

  useEffect(() => {
    if (project) {
      setPageTitle(formatProjectTitle(project, loading, error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, loading, error]);

  useEffect(() => {
    const apiLoading = !!timeSheetLoading || !!timeSheetLoadingByForeman;
    if (!!apiLoading) {
      setLoading(apiLoading);
    } else {
      setLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSheetLoading, timeSheetLoadingByForeman]);

  useEffect(() => {
    const apiError = timeSheetError;
    if (!!apiError) {
      setError(apiError);
    } else {
      setError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSheetError, timeSheetErrorByForeman]);

  useEffect(() => {
    if (workDayData) {
      setWorkDay(workDayData.work_day);
    }
  }, [workDayData]);

  useEffect(() => {
    projectsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!projectsData && !!projectsData.projects) {
      setProjectsList(
        projectsData.projects.map((project) => {
          return {
            value: project.id,
            label: project.name,
          };
        })
      );
    }
  }, [projectsData]);

  useEffect(() => {
    if (!!timesheet) {
      timesheet?.time_entries?.length &&
        timesheet?.time_entries?.forEach((entry) => {
          if (!["Exported", "In Progress"].includes(entry.task_data.work_day_status)) {
            setTimesheetLocked(false);
          }
        });

      if (!timesheet?.time_entries?.length) setTimesheetLocked(false);
      if (!userCan("payroll-review-time")) {
        setTimesheetLocked(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheet]);

  useEffect(() => {
    const canDeleteTimesheet =
      !!userCan("timesheet-delete") &&
      !timeSheetLoadingByForeman &&
      !timeSheetLoading &&
      !workDayLoading &&
      !timeSheetDeleteLoading &&
      !timesheet?.time_entries?.length;
    setCanDeleteTimesheet(canDeleteTimesheet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    timesheet?.time_entries,
    timeSheetLoadingByForeman,
    timeSheetLoading,
    workDayLoading,
    user,
    timeSheetDeleteLoading,
  ]);

  useEffect(() => {
    if (!!timeSheetDeleteData?.success) {
      triggerToast(
        "Timesheet Deleted Successfully",
        `Timesheet ${timesheet.id} for ${timesheet?.user?.first_name} ${timesheet?.user?.last_name} deleted.`,
        `timesheet-delete-${timesheet?.id}`,
        "success"
      );
      setTimeout(() => history(`/project/${id}/field-management/crew-log/${timesheet?.user_id}`), 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSheetDeleteData]);

  return {
    project,
    pageTitle,
    timesheet,
    setTimesheet,
    error,
    editing,
    setEditing,
    workDay,
    workDayError,
    projectsList,
    unAssignedHours,
    setUnassignedHours,
    timesheetLocked,
    loading,
    workDayLoading,
    config,
    foremanId,
    timeSheetError,
    canDeleteTimesheet,
    confirmModalOpen,
    setConfirmModalOpen,
    handleDeleteTimesheet,
    timeSheetDeleteLoading,
    deleteReason,
    setDeleteReason,
    timeSheetDeleteError,
  };
};

export default useTimesheetDetail;
