import React from "react";
import styles from "./styles.module.scss";
import TimesheetRow from "../../PrintableGrids/rows/Timesheets/TimesheetRow";
import TaskRow from "../../PrintableGrids/rows/Tasks/TaskRow";
import TimesheetHeaders from "../../PrintableGrids/rows/Timesheets/TimesheetHeaders";
import TaskHeaders from "../../PrintableGrids/rows/Tasks/TaskHeaders";

const PrintableCrewLogDetail = ({ data }) => {
  return (
    <div className={styles.container}>
      <TimesheetHeaders isCrewLog />
      {data.map((timesheet) => {
        return (
          <>
            <TimesheetRow timesheet={timesheet} isCrewLog />
            {!!timesheet.time_entries.length && (
              <div className="mt-2 mb-2">
                <TaskHeaders />
                {timesheet.time_entries.map((task) => {
                  return <TaskRow task={task} />;
                })}
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};
export default PrintableCrewLogDetail;
