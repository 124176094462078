import { useEffect, useState } from "react";
import companiesApi from "../api/companies";
import { useApi } from "component-library";

const useCompaniesAdmin = () => {
  const [companies, setCompanies] = useState();
  const [successMessage, setSuccessMessage] = useState();

  const { request, loading, error, data } = useApi(
    companiesApi.searchCompanies
  );

  useEffect(() => {
    if (!!data?.companies) {
      setCompanies(data.companies);
    }
  }, [data]);

  const {
    request: searchRequest,
    data: searchData,
    loading: searchLoading,
  } = useApi(companiesApi.searchCompanies);

  useEffect(() => {
    if (searchData) {
      setCompanies(searchData.companies);
    }
  }, [searchData]);

  const searchCompanies = async (q) => {
    if (q) {
      searchRequest(q);
    } else {
      request("");
    }
  };

  return {
    request,
    companies,
    error,
    successMessage,
    setSuccessMessage,
    setCompanies,
    loading,
    searchCompanies,
    searchLoading,
  };
};

export default useCompaniesAdmin;
