import ExpandToggle from "../../sharedColumns/columnRenders/ExpandToggle";
import useResourceManagement from "../../../_resourceManagement/context/useResourceManagement";

const ExpandAll = () => {
  const { crewDetailExpanded, setCrewDetailExpanded } = useResourceManagement();

  return <ExpandToggle expandState={crewDetailExpanded} setExpandState={setCrewDetailExpanded} />;
};

export default ExpandAll;
