import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { Joi } from "component-library";
import { useFormControl, useAuth } from "component-library";

const useLogin = () => {
  const [error, setError] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const { login, user } = useAuth();
  const initialData = { email: "", password: "" };
  const [showPassword, setShowPassword] = useState(false);

  const schema = {
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email Address"),
    password: Joi.string().min(8).required().label("Password"),
  };

  useEffect(() => {
    if (user) setLoggedIn(true);
  }, [user]);

  const onSubmit = async (formData, setLoading) => {
    setError(false);
    try {
      await login(formData);
    } catch (message) {
      setLoading(false);
      setError(
        message === "non_500"
          ? "Username or password incorrect, please try again."
          : message === "CLIENT_ERROR"
            ? "Please check your email and password and try again."
            : "Something went wrong, please try again."
      );
      return Promise.reject(message);
    }
  };

  const { handleSubmit, renderSubmitButton, renderTextField, data } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const fields = (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="row">
        <div className="col">
          {renderTextField({
            name: "email",
            label: "Email",
            autoFocus: true,
            placeholder: "Email Address",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {renderTextField({
            name: "password",
            label: "Password",
            autoComplete: "current-password",
            showPassword: showPassword,
            setShowPassword: setShowPassword,
            type: "password",
            hasIcon: true,
          })}
        </div>
      </div>
    </>
  );
  return {
    initialData,
    schema,
    fields,
    handleSubmit,
    renderSubmitButton,
    loggedIn,
    data,
  };
};

export default useLogin;
