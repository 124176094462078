import React from "react";
import { MilestoneFields, useAuth } from "component-library";
import PropTypes from "prop-types";
import styles from "../styles.module.scss";

const DateMilestones = ({ project, setIsEditing }) => {
  const { config, userCan } = useAuth();
  const canEdit = userCan("project-edit");

  return (
    <div className={`${styles.section} row py-3`}>
      <div className={`${styles.sectionTitleWrapper} col-12`}>
        <h2 className={`${styles.sectionTitle}`}>Project Milestones</h2>

        <em>{project && project.milestones.length} total</em>
      </div>
      <div className={`col-12`}>
        {project && config && (
          <>
            <MilestoneFields
              milestones={project.milestones}
              config={config}
              canEdit={canEdit}
              editMilestone={() => {
                setIsEditing(true);
              }}
              deleteMilestone={() => {
                setIsEditing(true);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

DateMilestones.propTypes = {
  project: PropTypes.object,
  setIsEditing: PropTypes.func,
};

export default DateMilestones;
