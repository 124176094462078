import React from "react";
import styles from "./styles.module.scss";
import { ArrowDown, ArrowUp } from "react-feather";
import PropTypes from "prop-types";
import useWeather from "./useWeather";
import { Link } from "react-router-dom";
import { AlertPopup } from "component-library";
import { BeatLoader } from "react-spinners";
import useProject from "../../../../../hooks/useProject";
import { Sun } from "react-feather";
import { DateTime } from "luxon";

const Weather = () => {
  const { project, fieldMgmtDateRange } = useProject();
  const { conditions, error, loading, icon, center } = useWeather();

  const formatDate = (date) => {
    return DateTime.fromJSDate(new Date(date)).toFormat("yyyy-MM-dd");
  };

  return (
    <div>
      {!fieldMgmtDateRange[0] && (
        <div className={`${styles.weatherBlock} ${styles.weatherBlockMulti}`}>
          <div className="me-5">
            <Sun size={38} color="#ff6700" />
          </div>
          <div>
            <div className={styles.multi}>No dates selected</div>
            <Link to={`/weather?projectId=${project?.id}`}>Visit the weather archive</Link>
          </div>
        </div>
      )}
      {((!!fieldMgmtDateRange[0] && !fieldMgmtDateRange[1]) ||
        Date.parse(fieldMgmtDateRange[0]) === Date.parse(fieldMgmtDateRange[1])) && (
        <Link
          to={`/weather?projectId=${project?.id}&date=${formatDate(fieldMgmtDateRange[0])}`}
          variant="link"
          className={styles.weatherBlock}
        >
          {!!loading && (
            <div className="loaderWrapper">
              <BeatLoader color={"#0071ce"} />
            </div>
          )}
          {!!conditions ? (
            <>
              <span className={`${styles.icon} me-4`}>{icon}</span>
              <div className={styles.tempsBlock}>
                <div className={styles.conditions}>
                  <div className="text-center">
                    <span className={styles.value}>{parseInt(conditions.high_temperature)}°</span>
                    <ArrowUp color="#A7A7A7" size={12} />
                    <ArrowDown color="#A7A7A7" size={12} />
                    <span className={styles.value}>{parseInt(conditions.low_temperature)}°</span>
                  </div>
                  <div className={styles.description}>{conditions.current_conditions}</div>
                </div>
                <div className={styles.precip}>
                  <div className={styles.value}>{conditions?.precipitation_accumulation || 0}"</div>
                  <div className={styles.description}>Precipitation</div>
                </div>
              </div>
            </>
          ) : (
            <>
              {!loading && !error && (
                <div>
                  <div>No weather conditions found.</div>
                  <div className={styles.noLocation}>
                    {center?.lat === 0 && center?.lng === 0 && <>No project location found</>}
                  </div>
                </div>
              )}
            </>
          )}

          {!!error && (
            <span className="ms-2">
              <AlertPopup error={error} />
            </span>
          )}
        </Link>
      )}

      {!!fieldMgmtDateRange[1] && Date.parse(fieldMgmtDateRange[0]) !== Date.parse(fieldMgmtDateRange[1]) && (
        <div className={`${styles.weatherBlock} ${styles.weatherBlockMulti}`}>
          <div className="me-5">
            <Sun size={38} color="#ff6700" />
          </div>
          <div>
            <div className={styles.multi}>Multiple dates selected</div>
            <Link to={`/weather?projectId=${project?.id}`}>Visit the weather archive</Link>
          </div>
        </div>
      )}
    </div>
  );
};

Weather.propTypes = {
  currentSelectedDate: PropTypes.string,
};

export default Weather;
