import { useState, useEffect } from "react";
import { useApi, dailyFieldlogsApi } from "component-library";
import PropTypes from "prop-types";
import useDailyFieldLogs from "../context/useDailyFieldLogs";

const useTasks = ({ selectedForemenIds }) => {
  const { getRequestParams, updateRequests } = useDailyFieldLogs();
  const [active, setActive] = useState(true);
  const [activeTask, setActiveTask] = useState();
  const [tasks, setTasks] = useState();
  const [filteredTasks, setFilteredTasks] = useState();

  const {
    request: tasksRequest,
    data: tasksData,
    error: tasksError,
    loading: tasksLoading,
  } = useApi(dailyFieldlogsApi.getTasks);

  useEffect(() => {
    if (!!updateRequests) {
      const requestParams = getRequestParams();
      tasksRequest(requestParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRequests]);

  useEffect(() => {
    if (!!tasksData?.work_day_tasks?.length) {
      setTasks(tasksData.work_day_tasks);
    } else {
      setTasks();
    }
  }, [tasksData?.work_day_tasks]);

  useEffect(() => {
    if (tasks?.length && selectedForemenIds?.length) {
      setFilteredTasks(tasks.filter((task) => selectedForemenIds?.includes(task.foreman_id)));
    } else {
      setFilteredTasks([]);
    }
  }, [tasks, selectedForemenIds]);

  return {
    tasks,
    active,
    setActive,
    filteredTasks,
    tasksLoading,
    tasksError,
    setTasks,
    activeTask,
    setActiveTask,
  };
};

useTasks.propTypes = {
  selectedForemenIds: PropTypes.array,
};

export default useTasks;
