import React, { useState, useEffect } from "react";
import styles from "../styles.module.scss";
import { Folder } from "react-feather";
import { displayDate, useApi, photoLibraryApi, ConfirmModal } from "component-library";
import PhotosList from "../Lists/PhotosList";
import Accordion from "./Accordion";
import PinAndDelete from "./PinAndDelete";
import { Link } from "react-router-dom";
import Toggler from "./AssetToggler";
import { toggleItem } from "../../hooks/toggleItem";

const SingleAlbum = ({ album, params, handlePin, handleDelete, selectMode, selectedItems, setSelectedItems }) => {
  const [isOpen, setIsOpen] = useState();
  const [photos, setPhotos] = useState([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const {
    request: albumPhotosRequest,
    loading: albumPhotosLoading,
    error: albumPhotosError,
    data: albumPhotosData,
  } = useApi(photoLibraryApi.getAlbumPhotosWithParams);

  const {
    request: albumDeleteRequest,
    loading: albumDeleteLoading,
    error: albumDeleteError,
    data: albumDeleteData,
  } = useApi(photoLibraryApi.deleteAlbum);

  const { request: albumPinRequest, loading: albumPinLoading, data: albumPinData } = useApi(photoLibraryApi.pinAlbum);

  const handlePinAlbum = () => {
    albumPinRequest({ projectId: params.project_id, albumId: album.id, pinned: !album.pinned });
  };

  const handleDeleteAlbum = () => {
    albumDeleteRequest(album.id);
  };

  useEffect(() => {
    if (!!albumDeleteData?.success) {
      handleDelete(album.id, "album");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [albumDeleteData]);

  useEffect(() => {
    if (!!albumPinData?.success) {
      handlePin(album.id, "album");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [albumPinData]);

  useEffect(() => {
    if (!!albumPhotosData?.photos) {
      setPhotos(albumPhotosData.photos.data);
    }
  }, [albumPhotosData]);

  const handleToggler = (item) => {
    toggleItem(item, selectedItems, setSelectedItems);
  };

  const isChecked = (storedItems, data) => {
    return storedItems.some((storedItem) => storedItem.type === "album" && storedItem.id === data.id);
  };

  const addCounts = (notes1, notes2) => {
    const noteCount = (parseInt(notes1) || 0) + (parseInt(notes2) || 0);
    if (noteCount === 0 || isNaN(noteCount)) {
      return "-";
    }
    return noteCount;
  };

  const HeaderContent = () => (
    <>
      {!!selectMode && (
        <Toggler data={album} checked={isChecked(selectedItems, album)} handleChange={() => handleToggler(album)} />
      )}
      <Link className={styles.albumName} to={`/project/${params?.project_id}/photo-library/album/${album.id}`}>
        <Folder size="13" color="#0071ce" className="me-2" />
        <div className={styles.albumName}>{album.name}</div>
      </Link>
      <div className={`${styles.rowItem} ${styles.date}`}>{displayDate(album.updated_at)}</div>
      <div className={`${styles.rowItemSmall}`}>{addCounts(album.album_note_count, album.photo_note_count)}</div>
      <PinAndDelete
        loading={albumDeleteLoading || albumPinLoading}
        isPinned={!!album.pinned}
        handlePin={handlePinAlbum}
        setShowConfirmModal={setShowConfirmDeleteModal}
        selectMode={selectMode}
      />
    </>
  );

  const photoHandlePin = (id) => {
    setPhotos(photos.map((photo) => (photo.id === id ? { ...photo, pinned: !photo.pinned } : photo)));
    handlePin("photo", id);
  };

  const photoHandleDelete = (id) => {
    setPhotos(photos.filter((photo) => photo.id !== id));
    handleDelete("photo", id);
  };

  const BodyContent = () => (
    <PhotosList
      photosList={photos}
      handlePin={photoHandlePin}
      handleDelete={photoHandleDelete}
      params={params}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      selectMode={selectMode}
      isAccordion
    />
  );

  return (
    <>
      <Accordion
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        request={albumPhotosRequest}
        loading={albumPhotosLoading}
        error={albumPhotosError}
        data={albumPhotosData}
        selected={selectMode && isChecked(selectedItems, album)}
        params={{
          projectId: params?.project_id,
          albumId: album.id,
        }}
        headerContent={<HeaderContent />}
        bodyContent={<BodyContent />}
        selectMode={selectMode}
      />
      <ConfirmModal
        showConfirmation={showConfirmDeleteModal}
        setShowConfirmation={setShowConfirmDeleteModal}
        confirmFunction={handleDeleteAlbum}
        loading={albumDeleteLoading}
        error={albumDeleteError}
        name={"Confirm Delete Album"}
        message={"Are you sure you want to delete this album?"}
      />
    </>
  );
};

export default SingleAlbum;
