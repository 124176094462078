import React from "react";
import { Routes, Route } from "react-router";
import Dashboard from "./Dashboard";
import PrivateRoute from "../../components/PrivateRoute";
import ProjectSettings from "./ProjectSettings";
import ProjectTasks from "./EstimateConversion/ProjectTasks";
import TakeoffDetail from "./EstimateConversion/Takeoffs/Takeoff";
import SiteManagement from "../../components/pages/SiteManagement";
import PermitsListing from "../../components/pages/PermitsListing";
import ManageChecklist from "./ManageChecklist";
import ProjectDirectory from "./ProjectDirectory";
import ScheduleRoutes from "./Schedule/ScheduleRoutes";
import FieldManagementRoutes from "./FieldManagement/FieldManagementRoutes";
import QuoteRoutes from "./Quotes/QuoteRoutes";
import SubmittalRoutes from "../../_submittals/SubmittalRoutes";
import PurchaseOrderRoutes from "./PurchaseOrders/PurchaseOrderRoutes";
import ProjectReporting from "./Reporting/ReportingRoutes";
import PhotoLibraryRoutes from "./PhotoLibrary/PhotoLibraryRoutes";
import ProjectDocumentRoutes from "./ProjectDocuments/ProjectDocumentRoutes";

const ProjectRoutes = () => {
  return (
    <Routes>
      <Route index element={<PrivateRoute module="ProjectManagement" Component={<Dashboard />} />} />
      <Route path="reporting/*" element={<ProjectReporting />} />
      <Route path="schedule/*" element={<ScheduleRoutes />} />
      <Route path="field-management/*" element={<FieldManagementRoutes />} />
      <Route path="quotes/*" element={<QuoteRoutes />} />
      <Route path="photo-library/*" element={<PhotoLibraryRoutes />} />
      <Route path="documents/*" element={<ProjectDocumentRoutes />} />

      <Route path="dashboard" element={<PrivateRoute module="ProjectManagement" Component={<Dashboard />} />} />
      <Route path="purchase-orders/*" element={<PurchaseOrderRoutes />} />
      <Route path="submittals/*" element={<PrivateRoute module="Submittals" Component={<SubmittalRoutes />} />} />

      <Route
        path="manage-checklist"
        element={<PrivateRoute module="ProjectManagement" Component={<ManageChecklist />} />}
      />

      <Route
        path="estimate-conversion/:view"
        element={
          <PrivateRoute
            viewPermission="estimate-conversion-view"
            module="ProjectManagement"
            Component={<ProjectTasks />}
          />
        }
      />
      <Route path="takeoff/:id" element={<PrivateRoute module="ProjectManagement" Component={<TakeoffDetail />} />} />
      <Route
        path="sitemanagement/permits"
        element={<PrivateRoute module="ProjectManagement" Component={<PermitsListing />} />}
      />
      <Route
        path="sitemanagement"
        element={
          <PrivateRoute module="ProjectManagement" viewPermission="project-edit" Component={<SiteManagement />} />
        }
      />
      <Route
        path="settings"
        element={
          <PrivateRoute module="ProjectManagement" viewPermission="project-edit" Component={<ProjectSettings />} />
        }
      />
      <Route path="directory" element={<PrivateRoute module="ProjectManagement" Component={<ProjectDirectory />} />} />
    </Routes>
  );
};

export default ProjectRoutes;
