import React, { useEffect } from "react";
import PropTypes from "prop-types";
import NavLinks from "./NavLinks";
import { InteriorPage, useAuth } from "component-library";
import { ReactComponent as Tractics } from "../../../assets/tractics-hdr-mobile.svg";

const BoomPage = ({
  children,
  pageTitle,
  navTitle,
  subtitle,
  loading,
  error,
  navIcon,
  pageTitlePrimaryContent,
  pageTitleSecondaryContent,
  pageSubtitlePrimaryContent,
  pageSubtitleSecondaryContent,
  fillScreen,
  flexContent,
  NavContent,
  NavSecondary,
  titleTag,
}) => {
  const { user, logout } = useAuth();

  useEffect(() => {
    if (!!titleTag) {
      document.title = titleTag;
    } else if (!!navTitle) {
      document.title = navTitle;
    } else if (!!subtitle) {
      document.title = `${pageTitle} - ${subtitle}`;
    } else {
      document.title = !!pageTitle ? pageTitle : "Tractics";
    }
  }, [pageTitle, subtitle, titleTag, navTitle]);
  return (
    <InteriorPage
      pageTitle={pageTitle}
      navTitle={navTitle}
      subtitle={subtitle}
      NavLinks={NavLinks}
      user={user}
      logout={logout}
      navHomeTitle={<Tractics width={100} />}
      loading={loading}
      error={error}
      navIcon={navIcon}
      pageTitlePrimaryContent={pageTitlePrimaryContent}
      pageTitleSecondaryContent={pageTitleSecondaryContent}
      pageSubtitlePrimaryContent={pageSubtitlePrimaryContent}
      pageSubtitleSecondaryContent={!!pageSubtitleSecondaryContent ? pageSubtitleSecondaryContent : ""}
      fillScreen={fillScreen}
      flexContent={flexContent}
      NavContent={NavContent}
      NavSecondary={NavSecondary}
    >
      {(showMenu) => <>{typeof children === "function" ? children(showMenu) : children}</>}
    </InteriorPage>
  );
};

BoomPage.propTypes = {
  children: PropTypes.any,
  pageTitle: PropTypes.string,
  navTitle: PropTypes.string,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.any,
  navIcon: PropTypes.any,
  pageTitlePrimaryContent: PropTypes.any,
  pageTitleSecondaryContent: PropTypes.any,
  pageSubtitlePrimaryContent: PropTypes.any,
  pageSubtitleSecondaryContent: PropTypes.any,
  flexContent: PropTypes.bool,
  NavContent: PropTypes.any,
  NavSecondary: PropTypes.any,
};

export default BoomPage;
