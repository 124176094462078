import React from "react";
import styles from "./styles.module.scss";

const StatusItemChart = ({ status, compact }) => {
  if (!status) return null;

  return (
    <div className={`${!!compact ? styles.compact : {}} d-flex align-items-center flex-column`}>
      <figure className="d-flex align-items-center flex-column mb-1">
        <h2
          className={`${styles.percent} ${
            parseFloat(status.thirty) > 0 ? styles.bad : ""
          }
          ${parseFloat(status.thirty) < 0 ? styles.good : ""}`}
        >
          {new Intl.NumberFormat().format(
            Math.round(parseFloat(status.life) + Number.EPSILON)
          )}
          <span className={styles.units}>%</span>
        </h2>
        <figcaption className={styles.label}>Job lifetime</figcaption>
      </figure>

      <figure className={`${styles.secondary} mb-1`}>
        <span
          className={`${styles.secondaryFigure} ${
            parseFloat(status.thirty) > 0 ? styles.bad : ""
          }
          ${parseFloat(status.thirty) < 0 ? styles.good : ""}`}
        >
          {`${new Intl.NumberFormat().format(
            Math.round(parseFloat(status.thirty) + Number.EPSILON)
          )}% `}
        </span>
        <figcaption className={styles.label}> Last 30 days</figcaption>
      </figure>
    </div>
  );
};

export default StatusItemChart;
