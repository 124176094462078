import React from "react";
import PropTypes from "prop-types";

const Title = ({ item, handleChange,  focusAdd }) => {
  return (
    <div className="row py-3">
    <div className="col">
      <label
        htmlFor={`milestone_title_${item.key ? item.key : item.id}`}
      >
        Milestone Title
      </label>
      <input
        id={`milestone_title_${item.key ? item.key : item.id}`}
        className="form-control"
        name="name"
        onChange={(e) => {
          handleChange(
            "milestones",
            item.key,
            "title",
            e.target.value
          );
        }}
        onFocus={focusAdd}
        value={!item.title ? "" : item.title}
      />
    </div>
  </div>
  );
};

Title.propTypes = {
  item: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  focusAdd: PropTypes.func,
};

export default Title;
