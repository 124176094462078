import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import styles from "../styles.module.scss";
import PropTypes from "prop-types";
import { useAuth } from "component-library";

const useAddPerDiem = ({ timesheet, setTimesheet, close }) => {
  const { user, getCompanyName } = useAuth();
  const [perdiem, setPerDiem] = useState(0);
  const [distance, setDistance] = useState(false);
  const [night, setNight] = useState(false);
  const [hotel, setHotel] = useState(true);

  const identifier = user.id === timesheet.user.id ? "you" : timesheet.user.first_name;
  const pronoun = user.id === timesheet.user.id ? "your" : `${timesheet.user.first_name}'s`;

  useEffect(() => {
    if (distance) {
      let total = 25;
      if (night) {
        total = 75;
        if (hotel) total = 35;
      }
      setPerDiem(total);
    } else {
      setPerDiem(0);
    }
  }, [distance, night, hotel]);

  const question1 = (
    <div className={styles.question}>
      Did {identifier} work at least <em>75 miles</em> away from {pronoun} home?
    </div>
  );

  const question2 = (
    <div className={styles.question}>
      Did {identifier} <em>spend the night?</em>
    </div>
  );

  const question3 = (
    <div className={styles.question}>
      Did {getCompanyName()} provide {pronoun} <em>hotel room?</em>
    </div>
  );

  const confirmation = <div className={styles.question}>{`${pronoun} per diem rate is $${perdiem}`}</div>;

  const questions = [question1, question2, question3, confirmation];
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const answerNo = () => {
    if (currentQuestion === questions.length - 1) {
      close();
    } else {
      switch (currentQuestion) {
        case 0:
          setDistance(false);
          setCurrentQuestion(currentQuestion + 3);
          break;
        case 1:
          setNight(false);
          setCurrentQuestion(currentQuestion + 2);
          break;
        case 2:
          setHotel(false);
          setCurrentQuestion(currentQuestion + 1);
          break;
        default:
          break;
      }
    }
  };

  const answerYes = () => {
    switch (currentQuestion) {
      case 0:
        setDistance(true);
        break;
      case 1:
        setNight(true);
        break;
      case 2:
        setHotel(true);
        break;
      default:
        break;
    }
    if (currentQuestion === questions.length - 1) {
      setTimesheet({
        ...timesheet,
        perdiem,
      });
      close();
    } else {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const content = (
    <div className={styles.questionContent}>
      <div className={styles.questionTitle}>Add Per Diem</div>
      <div>{questions[currentQuestion]} </div>

      <div className={styles.questionButtons}>
        <Button onClick={answerNo} variant="light-gray" size="lg">
          {currentQuestion === questions.length - 1 ? "Cancel" : "No"}
        </Button>
        <Button onClick={answerYes} variant="tertiary" size="lg">
          {currentQuestion === questions.length - 1 ? "Confirm" : "Yes"}
        </Button>
      </div>
    </div>
  );

  return {
    content,
  };
};

useAddPerDiem.propTypes = {
  timesheet: PropTypes.object.isRequired,
  setTimesheet: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default useAddPerDiem;
