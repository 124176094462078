import React, { useState } from "react";
import { dropdownStylesWithWidth } from "component-library";
import { FileText } from "react-feather";
import InteriorPage from "../pageLayout/InteriorPage";
import Select from "react-select";
import Reports from "../../_reports/Reports";

const AdminReports = () => {
  const [selectedReport, setSelectedReport] = useState();

  const reportOptions = [
    {
      label: "Foreman Summary",
      value: "export_foreman_summary",
    },
    {
      label: "Employee Time Report",
      value: "export_time_report",
    },
    {
      label: "Equipment Timecard Report",
      value: "equipment_timecard_report",
    },
    {
      label: "Cross Job Report",
      value: "cross_job_report",
    },
    {
      label: "Job Tracking Labor Summary Report",
      value: "labor_summary_report",
    },
    {
      label: "Unallocated Time Report",
      value: "unallocated_time_report",
    },
    {
      label: "Geofence Report",
      value: "geofence_report",
    },
    {
      label: "Employee Attendence Report",
      value: "employee_attendence_report",
    },
    {
      label: "Employee Man Hours Report",
      value: "man_hours_report",
    },
    {
      label: "Divisional Labor Report",
      value: "division_labor_report",
    },
    {
      label: "Division By Project Report",
      value: "division_by_project_report",
    },
    {
      label: "Project By Division Report",
      value: "project_by_division_report",
    },
  ];

  const handleReportSelection = (value) => {
    setSelectedReport(value);
  };

  return (
    <InteriorPage pageTitle="Admin Reports" navIcon={<FileText color="#fff" size={14} />} navTitle="Reports">
      <div className="form-group">
        <label htmlFor="report_type">Select Report Type</label>
        <div className="row">
          <div className="col-6">
            <Select
              options={reportOptions}
              name="report_type"
              id="report_type"
              className="mb-3"
              onChange={({ value }) => handleReportSelection(value)}
              styles={dropdownStylesWithWidth}
              value={reportOptions.find((opt) => opt.value === selectedReport)}
              aria-label="Select Report Type dropdown"
            />
          </div>
        </div>
      </div>
      <Reports selectedReport={selectedReport} setSelectedReport={setSelectedReport}/>
    </InteriorPage>
  );
};

export default AdminReports;
