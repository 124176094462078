import React from "react";
import { Modal } from "react-bootstrap";

const AbsentModal = ({ openNote, setOpenNote }) => {
  return (
    <Modal
      animation={false}
      show={!!openNote}
      onHide={() => {
        setOpenNote();
      }}
      dialogClassName="modal-sm"
      className={`text-gray3`}
      centered
    >
      <div className="position-relative">
        <Modal.Header closeButton>
          <div className="xl">Absent Note</div>
        </Modal.Header>
        <Modal.Body>
          <div>{openNote}</div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default AbsentModal;
