import React from "react";
import { Modal } from "react-bootstrap";
import AddNoteForm from "./AddNoteForm";
import PropTypes from "prop-types";
import { Loader } from "component-library";

const AddNote = ({ submittal, setSubmittal, updateLoading }) => {
  return (
    <Modal
      animation={false}
      show={!!submittal && [5, 6, 7].indexOf(submittal.status_id) > -1}
      onHide={() => {
        setSubmittal();
      }}
      dialogClassName="modal-lg"
    >
      <Modal.Header closeButton>
        <h4 className="mb-0">Add Note</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="position-relative">
          <Loader loading={!!updateLoading} />
          <AddNoteForm submittal={submittal} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

AddNote.propTypes = {
  submittal: PropTypes.object,
  setSubmittal: PropTypes.func.isRequired,
  updateLoading: PropTypes.bool,
};

export default AddNote;
