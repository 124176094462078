import ProvideNotesAdmin from "./context/ProvideNotesAdmin";
import FieldNotesAdmin from "./FieldNotesAdmin";

const FieldNotesAdminPage = () => {
  return (
    <ProvideNotesAdmin>
      <FieldNotesAdmin />
    </ProvideNotesAdmin>
  );
};

export default FieldNotesAdminPage;
