import React from "react";
import ModalWrapper from "../ModalWrapper";
import PropTypes from "prop-types";
import useRenamePhoto from "./useRenamePhoto";

const RenamePhotoModal = ({ showModal, setShowModal, photoDetails, setPhotoDetails }) => {
  const { headerContent, bodyContent, footerContent, onHide } = useRenamePhoto({
    photoDetails,
    setPhotoDetails,
    setShowModal,
  });

  return (
    <ModalWrapper
      showModal={showModal}
      setShowModal={setShowModal}
      headerContent={headerContent}
      bodyContent={bodyContent}
      footerContent={footerContent}
      onHide={() => onHide()}
    />
  );
};

RenamePhotoModal.propTypes = {
  photoDetails: PropTypes.object,
  setPhotoDetails: PropTypes.func,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default RenamePhotoModal;
