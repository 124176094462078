import { Avatar } from "component-library";
import React from "react";
import styles from "./styles.module.scss";
import useForemen from "./useForemen";
import { BeatLoader } from "react-spinners";
import { Alert } from "react-bootstrap";

const Foremen = () => {
  const { foremen, foremenLoading, foremenError } = useForemen();

  return (
    <section className="mb-5">
      {!!foremenError && <Alert variant="danger">{foremenError}</Alert>}
      <div className="d-flex align-items-center mt-5 mb-3">
        <h3 className={styles.foremenTitle}>Foremen</h3>
        <span className={styles.foremenCount}>({foremen?.length || 0})</span>
      </div>
      {!!foremenLoading && <BeatLoader color="#0071CE" size="14" />}
      <div className={styles.foremanGrid}>
        {!!foremen?.length &&
          foremen.map((foreman) => (
            <div key={foreman.id}>
              <Avatar user={foreman} hasColor={true} />
            </div>
          ))}
      </div>
    </section>
  );
};

export default Foremen;
