import CompanyName from "../companiesAdminColumns/CompanyName";

const company_name = {
  headerName: "Company",
  field: "company_name",
  flex: 1,
  maxWidth: 400,
  resizable: true,
  cellRenderer: CompanyName,
};

export default company_name;
