import Status from "./Status";

const poListConfig = [
  {
    name: "id",
    label: "PO #",
    sort: "numeric",
    width: "15%",
    ariaLabel: "purchase order number"
  },
  {
    name: "supplier",
    label: "Supplier",
    sort: "alpha",
    width: "34%",
    isTitle: true,
  },
  {
    name: "statusName",
    label: "Status",
    width: "13%",
    sort: "alpha",
    render: Status,
  },
  {
    name: "sum",
    label: "Amount",
    sort: "numeric",
    width: "16%",
    isCurrency: true,
  },
  {
    name: "invoiced_amount",
    label: "Invoiced",
    sort: "numeric",
    width: "16%",
    isCurrency: true,
  },
  {
    name: "remaining",
    label: "Remaining",
    width: "16%",
    sort: "numeric",
    isCurrency: true,
  },
];

export default poListConfig;
