import React from "react";
import styles from "./styles.module.scss";

const TaskHeader = ({ task }) => {
  return (
    <div className={styles.taskHeader}>
      <div className={styles.taskNum}>{task.bid_item_number}</div>
      <span>{task.bid_item_name}</span>
      <div className={styles.numTasks}>{!!task.tasks.length ? task.tasks.length : "0"} tasks</div>
    </div>
  );
};

export default TaskHeader;
