import {
  email,
  roles,
  user,
  edit,
  deactivate,
} from "./columnDefs";

const columnDefs = [
  user,
  roles,
  email,
  edit,
  deactivate,
];

export default columnDefs;