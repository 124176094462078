import React from 'react';
import styles from "./PreconChecklist.module.scss";

const ChecklistCheckbox = ({ updateItem, todo }) => {
  const handleCheckboxChange = () => {
    updateItem({ ...todo, complete: todo.complete === 0 ? 1 : 0 });
  };

  return (
    <div className={`form-group form-check`}>
      <input
        type="checkbox"
        name={todo.precon_todo.label}
        id={todo.precon_todo.label}
        onChange={handleCheckboxChange}
        value={todo.complete}
        checked={todo.complete === 1}
        className={`form-check-input`}
      />
      <label className={`${!todo.complete ? styles.checklistCheckbox : undefined} form-check-label`} htmlFor={todo.precon_todo.label}>
        {todo.precon_todo.label}
      </label>
    </div>
  );
}

export default ChecklistCheckbox;