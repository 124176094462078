import React from "react";
import { Link } from "react-router-dom";
import { ArrowRightCircle } from "react-feather";

const ArrowLink = ({ url, text = "View by Project", label = "View by project", className }) => {
  return (
    <div className={`d-flex justify-content-center align-items-center mb-1 ${className}`}>
      <Link to={url} className="sm btn btn-link" aria-label={label}>
        {text}
        <ArrowRightCircle className="ms-2 mb-1" color="#0d6efd" size={16} />
      </Link>
    </div>
  );
};

export default ArrowLink;
