import React from 'react';
import ProvideBid from '../../_bidManagement/hooks/ProvideBid';
import BidRoutes from '../../_bidManagement/BidRoutes';

const Bid = () => {
  return (
    <ProvideBid>
      <BidRoutes />
    </ProvideBid>
  );
}

export default Bid;