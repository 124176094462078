import { task_name, role, equipment, minutes } from "./columnDefs";
//TODO: get this to use the shared columns
const columnDefs = [task_name, role, equipment, minutes];

const nestedDetailGridOptions = {
  rowSelection: "multiple",
  pagination: false,
  columnDefs: columnDefs,
  rowHeight: 42,
  defaultColDef: {
    sortable: true,
  },
};

export default nestedDetailGridOptions;
