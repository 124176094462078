import React, { useMemo } from "react";
import { Briefcase, Grid, Folder, List, Monitor } from "react-feather";
import { Alert } from "react-bootstrap";
import useProject from "../../../hooks/useProject";
import useAlbumDetail from "../../hooks/useAlbumDetail";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import styles from "./styles.module.scss";
import EmptyAlbum from "./EmptyAlbum";
import BatchDeleteModal from "../Modals/BatchDeleteModal";
import UploadPhotoModal from "../Modals/UploadPhotoModal";
import DeleteAlbumModal from "../Modals/DeleteAlbumModal";
import RenameAlbumModal from "../Modals/RenameAlbumModal";
import MultiSelectModal from "../Modals/MultiSelectModal";
import usePhotoLibrary from "../context/usePhotoLibrary";
import { useParams } from "react-router-dom";
import ViewSwitcher from "../../../../components/PhotoLibrary/ViewSwitcher";
import { DateRangeSelector, FilterSearch, ConfirmModal } from "component-library";
import AlbumViews from "./AlbumViews";
import UserToggler from "../../../../components/PhotoLibrary/UserToggler";
import getPinUnpinMessage from "../PhotoLibrary/getPinUnpinMessage";
import { ReactComponent as Pinned } from "../../../../assets/photoPinned.svg";
import { ReactComponent as Unpinned } from "../../../../assets/photoUnpinned.svg";

const AlbumDetail = () => {
  const { project, pageTitle, loading, error } = useProject();
  const { albumId } = useParams();

  const {
    album,
    setAlbum,
    dateRange,
    setDateRange,
    albumPhotosList,
    setAlbumPhotosList,
    AlbumActionButtons,
    showDeleteAlbumModal,
    setShowDeleteAlbumModal,
    showRenameAlbumModal,
    setShowRenameAlbumModal,
    showUploadFileModal,
    setShowUploadFileModal,
    pinnedPhotos,
    albumDetailsLoading,
    albumDetailsError,
    albumPhotosLoading,
    albumPhotosError,
    albumPhotosWithParamsError,
    albumPhotosWithParamsLoading,
    handleSearch,
    showMultiSelect,
    setShowMultiSelect,
    onlyMyPhotos,
    setOnlyMyPhotos,
    refreshLists,
    activeView,
    setActiveView,
    q,
    selectMode,
    selectedItems,
    setSelectedItems,
    SelectActionButtons,
    ItemSelection,
    showPinUnpinModal,
    setShowPinUnpinModal,
    pinUnpinItems,
    photoBatchPinUnpinLoading,
    albumBatchPinUnpinLoading,
    pinUnpinError,
    isPinAction,
    showDeleteModal,
    setShowDeleteModal,
    deleteItems,
    deleteError,
    photoBatchDeleteLoading,
  } = useAlbumDetail({ albumId, project });

  const { message, setMessage, setPhotosList, photosList } = usePhotoLibrary();

  const viewOptions = [
    { value: "grid-view", icon: Grid },
    { value: "list-view", icon: List },
    { value: "gallery-view", icon: Monitor },
  ];
  const isGalleryView = activeView === "gallery-view";
  const hasSearched = useMemo(() => !!q || dateRange?.length === 2 || !!onlyMyPhotos, [q, dateRange, onlyMyPhotos]);

  const pageSubtitleContent = () => (
    <>
      <div className="d-flex align-items-center">
        <span className={styles.albumName}>
          <Folder size="16" color="#0071ce" className="me-3" />
          {album?.name}
        </span>
        <span className={styles.photoCount}>
          {albumPhotosList?.length || 0} Photo{albumPhotosList !== 1 && "s"}
        </span>
      </div>
    </>
  );

  const handlePin = (id) => {
    const updatedPhotosList = albumPhotosList.map((photo) => {
      if (photo.id === id) {
        return { ...photo, pinned: photo.pinned ? 0 : 1 };
      } else {
        return photo;
      }
    });
    setAlbumPhotosList(updatedPhotosList);
  };

  const handleDelete = (id) => {
    const updatedPhotosList = albumPhotosList.filter((photo) => photo.id !== id);
    setAlbumPhotosList(updatedPhotosList);
  };

  const disabledState =
    loading || albumDetailsLoading || albumPhotosWithParamsLoading || albumPhotosLoading || !albumPhotosList?.length;

  return (
    <InteriorPage
      loading={loading || albumDetailsLoading}
      error={error}
      navTitle={pageTitle}
      pageTitle="Photo Library"
      pageSubtitlePrimaryContent={pageSubtitleContent}
      pageSubtitleSecondaryContent={selectMode ? SelectActionButtons : AlbumActionButtons}
      flexContent={isGalleryView}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitlePrimaryContent={!!selectMode && ItemSelection}
    >
      <div className="container-fluid">
        <div className={`row ${isGalleryView ? "h-100 d-flex flex-column align-items-start" : ""}`}>
          {!!message && (
            <Alert variant="success" dismissible onClick={() => setMessage()}>
              {message}
            </Alert>
          )}
          <div className={styles.header}>
            <DateRangeSelector
              dateRange={dateRange}
              setDateRange={setDateRange}
              maxDate={Date.now()}
              disabled={disabledState}
            />
            <header className="d-flex align-items-center">
              <UserToggler onlyMyPhotos={onlyMyPhotos} setOnlyMyPhotos={setOnlyMyPhotos} disabled={disabledState} />
              <FilterSearch inline searchFunction={handleSearch} disabled={disabledState} />
              <ViewSwitcher
                activeColor={"#FFF"}
                deactivateColor={"#D3D3D3"}
                options={viewOptions}
                activeView={activeView}
                setActiveView={setActiveView}
              />
            </header>
          </div>
          <div className="position-relative">
            {!!albumDetailsError && <Alert variant="danger">{albumDetailsError}</Alert>}
            {!!albumPhotosError && <Alert variant="danger">{albumPhotosError}</Alert>}
            {!!albumPhotosWithParamsError && <Alert variant="danger">{albumPhotosWithParamsError}</Alert>}
          </div>
          {!albumPhotosList?.length && (
            <div className={styles.emptyWrapper}>
              <EmptyAlbum setShowModal={setShowUploadFileModal} setShowMultiSelect={setShowMultiSelect} />
            </div>
          )}

          <AlbumViews
            isVisible={!!albumPhotosList?.length && !hasSearched && !albumPhotosLoading}
            activeView={activeView}
            pinnedPhotos={pinnedPhotos}
            project={project}
            albumPhotosList={albumPhotosList}
            dateRange={dateRange}
            q={q}
            handlePin={handlePin}
            handleDelete={handleDelete}
            albumPhotosLoading={albumPhotosLoading}
            albumPhotosWithParamsLoading={albumPhotosWithParamsLoading}
            selectMode={selectMode}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            refreshLists={refreshLists}
            albumPhotosWithParamsError={albumPhotosWithParamsError}
            albumPhotosError={albumPhotosError}
            isGalleryView={isGalleryView}
          />
        </div>
      </div>
      <DeleteAlbumModal showModal={showDeleteAlbumModal} setShowModal={setShowDeleteAlbumModal} album={album} />
      <UploadPhotoModal
        project={project}
        albumId={albumId}
        showModal={showUploadFileModal}
        setShowModal={setShowUploadFileModal}
        albumPhotosList={albumPhotosList}
        setAlbumPhotosList={setAlbumPhotosList}
        photosList={photosList}
        setPhotosList={setPhotosList}
        setMessage={setMessage}
      />
      <RenameAlbumModal
        showModal={showRenameAlbumModal}
        setShowModal={setShowRenameAlbumModal}
        album={{
          ...album,
          photos: albumPhotosList,
        }}
        setAlbum={setAlbum}
      />
      <MultiSelectModal
        showModal={showMultiSelect}
        setShowModal={setShowMultiSelect}
        album={album}
        setAlbum={setAlbumPhotosList}
      />
      <ConfirmModal
        showConfirmation={showPinUnpinModal}
        setShowConfirmation={setShowPinUnpinModal}
        confirmFunction={() => {
          pinUnpinItems(isPinAction, selectedItems);
        }}
        Icon={() => (isPinAction ? <Unpinned className={styles.pinIcon} /> : <Pinned className={styles.pinIcon} />)}
        colorVariant={"pinUnpin"}
        warningColor={"blue"}
        cancelColor={"blue"}
        confirmColor={"tertiary"}
        loading={photoBatchPinUnpinLoading || albumBatchPinUnpinLoading}
        error={pinUnpinError}
        name={isPinAction ? "Pin Item(s)" : "Unpin Item(s)"}
        message={getPinUnpinMessage(selectedItems, isPinAction)}
        buttonText={isPinAction ? "Pin Item(s)" : "Unpin Item(s)"}
      />
      <BatchDeleteModal
        isAlbumView={true}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteItems={deleteItems}
        deleteError={deleteError}
        photoBatchDeleteLoading={photoBatchDeleteLoading}
        albumBatchDeleteLoading={false}
        selectedItems={selectedItems}
      />
    </InteriorPage>
  );
};

export default AlbumDetail;
