import React from "react";
import { Tablet, User } from "react-feather";
import { ReactComponent as Smartphone } from "../assets/smartphone.svg";

const InterfaceIcon = ({ isWithinGeofence, interfaceUsed, spacing = 0, size = 20 }) => {
  return (
    <>
      {!!interfaceUsed ? (
        <>
          {interfaceUsed === 2 && (
            <Tablet
              size={size}
              color={!!isWithinGeofence ? "#7DDB2D" : "#ff6700"}
              className={`mx-${spacing}`}
            />
          )}
          {interfaceUsed === 1 && (
            <Smartphone
              width={size}
              color={!!isWithinGeofence ? "#7DDB2D" : "#ff6700"}
              className={`mx-${spacing}`}
            />
          )}
          {interfaceUsed !== 1 && interfaceUsed !== 2 && (
            <User size={size} color={"#0071ce"} className={`mx-${spacing}`} />
          )}
        </>
      ) : (
        <User size={size} color={"#0071ce"} className={`mx-${spacing}`} />
      )}
    </>
  );
};

export default InterfaceIcon;
