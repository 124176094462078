import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useApi, bidApi } from "component-library";
import AddTasksModal from "./AddTasksModal";
import TaskRows from "../Material/Tasks/TaskRows";
import { AlertCircle, ChevronDown, ChevronRight, PlusCircle } from "react-feather";
import { BeatLoader } from "react-spinners";
import styles from "../../TaskManagment/styles.module.scss";
import PropTypes from "prop-types";

const NewMaterial = ({ material, setNewMaterials, newMaterials, setNewMaterialError }) => {
  const {
    request: updateRequest,
    data: updateData,
    loading: updateLoading,
    error: updateError,
  } = useApi(bidApi.updateTakeoff);
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalValue, setTotalValue] = useState();

  const handleUpdate = (field, value) => {
    if (value !== material[field]) {
      const updatedMaterial = {
        id: material.id,
        [field]: value,
        tasks: material.tasks.map((task) => {
          return { id: task.id, pm_quantity: task.pm_quantity };
        }),
        new_tasks: [],
        delete_tasks: [],
      };
      updateRequest({
        ...updatedMaterial,
        pm_quantity: !!updatedMaterial?.tasks?.length
          ? updatedMaterial.tasks.reduce((p, c) => p + c.pm_quantity, 0)
          : updatedMaterial.pm_quantity,
      });
    }
  };

  useEffect(() => {
    if (!!updateData?.takeoffs?.length) {
      setNewMaterials(newMaterials.map((mat) => (mat.id === material.id ? updateData.takeoffs[0] : mat)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    if (updateError) {
      setNewMaterialError(updateError);
    } else {
      setNewMaterialError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  useEffect(() => {
    setTotalValue(material.pm_quantity);
  }, [material]);

  return (
    <>
      <tr className="position-relative">
        <td>
          <div className="form-group mb-0">
            {!!updateLoading && (
              <div className="loaderWrapper">
                <BeatLoader color={"#0071ce"} size={10} />
              </div>
            )}
            <input
              defaultValue={material.material_code || 0}
              onBlur={(e) => {
                handleUpdate("material_code", e.target.value);
              }}
              className="form-control"
              placeholder="Code"
            />
          </div>
        </td>
        <td>
          <div className="d-flex align-items-center justify-content-center form-group mb-0">
            {!!material?.tasks?.length &&
              (open ? (
                <ChevronDown color={"#7b7b7b"} size={15} onClick={() => setOpen(!open)} className="me-1" />
              ) : (
                <ChevronRight color={"#7b7b7b"} size={15} onClick={() => setOpen(!open)} className="me-1" />
              ))}
            <AlertCircle className="mx-1" color="#ff6700" size={16} />
            <input
              onBlur={(e) => {
                handleUpdate("name", e.target.value);
              }}
              className="form-control"
              placeholder="Material Name"
              defaultValue={material.name}
            />
          </div>
        </td>
        <td></td>
        <td className="text-end">
          <div className="d-flex align-items-center justify-content-end form-group mb-0">
            <div className="me-0 w-50">
              <input
                type="number"
                onBlur={(e) => {
                  handleUpdate("pm_quantity", e.target.value === "" ? 0 : parseInt(e.target.value));
                }}
                onChange={(e) => {
                  setTotalValue(parseInt(e.target.value));
                }}
                value={totalValue}
                placeholder="Qty"
                className={`${styles.leftInput} form-control mb-0`}
                disabled={!!material?.tasks?.length ? true : false}
              />
            </div>
            <div className={`${styles.smInput}`}>
              <input
                onBlur={(e) => {
                  handleUpdate("unit_measure", e.target.value);
                }}
                defaultValue={material.unit_measure}
                placeholder="Unit"
                type="text"
                className={`${styles.rightInput} form-control mb-0 thirteen`}
              />
            </div>
          </div>
        </td>
        <td className="text-end">
          {0 - parseInt(material.pm_quantity)} {material.unit_measure || "-"}
        </td>
      </tr>
      {!!open && !!material?.tasks.length && (
        <TaskRows
          setTakeoffs={setNewMaterials}
          takeoffs={newMaterials}
          material={material}
          setNewMaterialError={setNewMaterialError}
        />
      )}
      <tr className={styles.whiteBg}>
        <td></td>
        <td className="p-0 ps-3">
          <Button className="med p-0" variant="link" onClick={() => setModalOpen(true)}>
            <div className="d-flex align-items-center text-gray2">
              <PlusCircle className="me-1" size={14} color={"#0071ce"} />
              Attach bid item or task
            </div>
          </Button>
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <AddTasksModal
        newMaterials={newMaterials}
        material={material}
        setNewMaterials={setNewMaterials}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  );
};

NewMaterial.propTypes = {
  material: PropTypes.object.isRequired,
  setNewMaterials: PropTypes.func,
  newMaterials: PropTypes.array,
  setNewMaterialError: PropTypes.func,
};

export default NewMaterial;
