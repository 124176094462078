import React from "react";
import InteriorPage from "../../pageLayout/InteriorPage";
import { Loader, AgGridTable, useAuth } from "component-library";
import Pagination from "../../Pagination";
import LocatesListingHeader from "./LocatesListingHeader";
import useLocatesListing from "./hooks/useLocatesListing";
import styles from "./styles.module.scss";
import gridRowHeights from "../../../utils/gridRowHeights";
import locatesConfig from "../../../config/locatesConfig";
import locatesPCConfig from "../../../config/locatesPCConfig";
import { Briefcase } from "react-feather";

const Locates = () => {
  const {
    locates,
    paginatedLocates,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    selectedProject,
    setSelectedProject,
    setPaginatedLocates,
    showSearch,
    setShowSearch,
    searchQuery,
    setSearchQuery,
    loading,
    error,
    handleSearch,
    isPC,
    searchLoading,
  } = useLocatesListing();

  const { user } = useAuth();

  return (
    <InteriorPage
      loading={loading}
      navTitle="Field Management"
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle={"Locates"}
      error={error}
    >
      <LocatesListingHeader
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setPaginatedLocates={setPaginatedLocates}
        setShowSearch={setShowSearch}
        showSearch={showSearch}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleSearch={handleSearch}
      />
      <hr />
      <div className={styles.wrapper}>
        <AgGridTable
          gridData={paginatedLocates}
          gridConfig={isPC ? locatesPCConfig : locatesConfig}
          loading={loading || searchLoading}
          rowHeight={gridRowHeights.medium}
          id={`${user.id}_locates_listing`}
          presetStyle="sortableTable"
        />
        <Pagination
          items={locates}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </InteriorPage>
  );
};

export default Locates;
