import React from "react";
import styles from "./styles.module.scss";

const Takeoff = ({ takeoff }) => {
  return (
    <div className={styles.takeoff}>
      <div>
        <span className={styles.code}>{takeoff?.material_code}</span>{" "}
        <span className={styles.name}>{takeoff?.name}</span>
      </div>
      <div>
        <span className={styles.qty}>{takeoff?.pm_quantity}</span>
        <span className={styles.uom}>{takeoff?.unit_measure}</span>
      </div>
    </div>
  );
};

export default Takeoff;
