import React from "react";
import useUserAdmin from "../../../_admin/pages/UserAdmin/hooks/useUserAdmin";

const Deactivate = ({ data }) => {
  const { setConfirmDeactivate, setUserToDeactivate, setUserToActivate, setConfirmActivate } = useUserAdmin();
  
  const handleUpdate = () => {
    if (data.active) {
      setConfirmDeactivate(true);
      setUserToDeactivate(data);
    } else {
      setUserToActivate(data);
      setConfirmActivate(true);
    }
  };

  return (
    <button className="btn btn-primary" onClick={handleUpdate}>
      {data.active ? "Deactivate" : "Activate"}
    </button>
  );
};

export default Deactivate;
