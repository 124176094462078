import React, { useEffect, useState } from "react";
import ContactsAdmin from "./ContactsAdmin";
import CompaniesAdmin from "./CompaniesAdmin";
import EditContactForm from "../Contacts/EditContactForm";
import useCompaniesAdmin from "../../hooks/useCompaniesAdmin";
import useContactsAdmin from "../../hooks/useContactsAdmin";
import AddCompany from "./AddCompany";
import { Loader } from "component-library";
import { Alert } from "react-bootstrap";

const ContactsAdminNav = ({ byCompany, addingContact, setAddingContact, addingCompany, setAddingCompany }) => {
  const [newContact, setNewContact] = useState();
  const [newCompany, setNewCompany] = useState();
  const [addedMessage, setAddedMessage] = useState();

  const {
    companies,
    error: companiesError,
    loading: companiesLoading,
    successMessage: companiesSuccessMessage,
    setSuccessMessage: companiesSetSuccessMessage,
    setError: companiesSetError,
    searchCompanies,
    searchLoading: companiesSearchLoading,
    setCompanies,
    request: requestCompanies,
  } = useCompaniesAdmin();

  const {
    contacts,
    error,
    loading,
    successMessage,
    setSuccessMessage,
    setError,
    searchContacts,
    searchLoading,
    setContacts,
    setCurrentPage,
    currentPage,
    resultsTotal,
    itemsPerPage,
    request: requestContacts,
  } = useContactsAdmin();

  useEffect(() => {
    if (newContact) {
      setAddedMessage(`New contact successfully added: ${newContact.last_name}, ${newContact.first_name}.`);
      requestContacts({ q: "", page: currentPage, archived: 1, items_per_page: itemsPerPage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newContact]);

  useEffect(() => {
    if (newCompany) {
      setAddedMessage(`New company successfully added: ${newCompany.name}.`);

      const newLocations = newCompany.locations.map((loc) => {
        return {
          location_id: loc.id,
          location_name: loc.name,
          company_name: loc.company_name[0],
          company_id: loc.company_id,
          location_phone: loc.phone,
          company_categories: newCompany.company_categories,
          address1: loc.address.address1,
          address2: loc.address.address2,
          city: loc.address.city,
          state: loc.address.state,
          zip: loc.address.zip,
        };
      });
      const updatedLocations = [...newLocations, ...companies];
      setCompanies(updatedLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCompany]);

  useEffect(() => {
    if (!!byCompany) {
      setContacts();
      requestCompanies("");
      setAddedMessage();
    } else {
      setCompanies();
      requestContacts({ q: "", page: 1, archived: 1, items_per_page: itemsPerPage });
      setAddedMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [byCompany]);

  return (
    <>
      {!!addedMessage && (
        <Alert
          variant="success"
          onClick={() => {
            setAddedMessage();
          }}
          dismissible
        >
          {addedMessage}
        </Alert>
      )}
      {byCompany ? (
        <>
          {!!loading ? (
            <Loader />
          ) : (
            <CompaniesAdmin
              companies={
                !!companies &&
                companies
                  .sort((a, b) => {
                    return a.company_name.localeCompare(b.company_name);
                  })
                  .sort((a, b) => {
                    return a.location_archived - b.location_archived;
                  })
              }
              error={companiesError}
              loading={companiesLoading}
              successMessage={companiesSuccessMessage}
              setSuccessMessage={companiesSetSuccessMessage}
              setError={companiesSetError}
              searchCompanies={searchCompanies}
              searchLoading={companiesSearchLoading}
            />
          )}
        </>
      ) : (
        <>
          <ContactsAdmin
            contacts={
              !!contacts &&
              contacts.sort((a, b) => {
                return a.contact_archived - b.contact_archived;
              })
            }
            error={error}
            loading={loading}
            successMessage={successMessage}
            setSuccessMessage={setSuccessMessage}
            setError={setError}
            searchContacts={searchContacts}
            searchLoading={searchLoading}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            resultsTotal={resultsTotal}
            itemsPerPage={itemsPerPage}
          />
        </>
      )}
      {!!addingCompany && <AddCompany setAddingCompany={setAddingCompany} setCompany={setNewCompany} isSearch={true} />}
      {!!addingContact && <EditContactForm setAddingContact={setAddingContact} setEditedContact={setNewContact} />}
    </>
  );
};

export default ContactsAdminNav;
