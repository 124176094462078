import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import { ChevronLeft, ChevronRight, Eye, Trash2, Link as LinkIcon } from "react-feather";
import { ConfirmModal } from "component-library";
import useCurrentView from "../hooks/useCurrentView";
import { useAuth } from "component-library";
import GallerySelector from "../GallerySelector";
import { ReactComponent as Unpinned } from "../../../../assets/photoUnpinned.svg";
import { ReactComponent as Pinned } from "../../../../assets/photoPinned.svg";
import { toggleItem } from "../../hooks/toggleItem";

const CurrentItem = ({
  project,
  combinedList,
  currentView,
  setCurrentView,
  handlePin,
  refreshLists,
  selectMode,
  selectedItems,
  setSelectedItems,
}) => {
  const {
    isPhoto,
    imgUrl,
    title,
    timestamp,
    attachedTo,
    photoCount,
    viewUrl,
    isPinned,
    updatePin,
    updatePhotoDetailLoading,
    pinAlbumLoading,
    deleteAsset,
    deleteAlbumLoading,
    deleteAlbumError,
    deletePhotoLoading,
    deletePhotoError,
    showConfirmDeleteModal,
    setShowConfirmDeleteModal,
    selected,
    storeItem,
  } = useCurrentView({
    project,
    combinedList,
    currentView,
    handlePin,
    refreshLists,
    selectedItems,
  });
  const { userCan } = useAuth();

  const showPrevious = () => {
    if (currentView > 0) {
      setCurrentView(currentView - 1);
    } else {
      setCurrentView(0);
    }
  };

  const showNext = () => {
    if (combinedList.length > 0) {
      if (currentView < combinedList.length - 1) {
        setCurrentView(currentView + 1);
      } else {
        setCurrentView(combinedList.length - 1);
      }
    }
  };

  const handleClickPin = () => {
    updatePin();
  };

  const handleClickDelete = () => {
    deleteAsset();
  };

  const handleChangeSelection = () => {
    if (storeItem) {
      toggleItem(storeItem, selectedItems, setSelectedItems);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <div className={styles.navArrow} onClick={showPrevious}>
          {combinedList.length > 0 && currentView > 0 ? (
            <ChevronLeft color="#4F4F4F" size={"3rem"} />
          ) : (
            <ChevronLeft color="#D4D4D4" size={"3rem"} />
          )}
        </div>
      </div>
      <div className="d-flex flex-column flex-grow-1 gap-2">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-row align-items-center gap-3">
            <div className={styles.author}>{title}</div>
            <div className={styles.timestamp}>{timestamp}</div>
          </div>
          {!selectMode && (
            <div className="d-flex flex-row align-items-center gap-3">
              <Link to={viewUrl} className="position-relative">
                <div className={styles.actionIcon}>
                  <Eye color="#0071CE" size={"2rem"} />
                </div>
              </Link>
              {updatePhotoDetailLoading || pinAlbumLoading ? (
                <div className="d-flex justify-content-center">
                  <ClipLoader color="#0017CE" size={"2rem"} />
                </div>
              ) : (
                <div className={styles.actionIcon} onClick={handleClickPin}>
                  {isPinned ? <Pinned className={styles.pinIcon} /> : <Unpinned className={styles.pinIcon} />}
                </div>
              )}
              {userCan("photo-library-delete") && (
                <div className={styles.actionIcon} onClick={() => setShowConfirmDeleteModal(true)}>
                  <Trash2 color="#0071CE" size={"2rem"} />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="position-relative d-flex w-100 h-100 overflow-hidden">
          {isPhoto ? (
            imgUrl && (
              <>
                <img alt="" src={imgUrl} className={styles.photoBlur} loading="lazy" />
                <img alt="" src={imgUrl} className={styles.photo} loading="lazy" />
                {selectMode && (
                  <div className={styles.selectorWrap}>
                    <GallerySelector selected={selected} selectable={true} onSelectionChange={handleChangeSelection} />
                  </div>
                )}
              </>
            )
          ) : (
            <div className={styles.albumContainer}>
              <img alt="" src={imgUrl} className={styles.albumImage} loading="lazy" />
              <div className={styles.albumTitle}>{title}</div>
              <div className={styles.albumPhotoCount}>{photoCount}</div>
              <Link to={viewUrl} className="position-relative">
                <Button
                  variant="orange"
                  className="d-flex align-items-center px-5 py-3 mt-5 rounded-4"
                  aria-label="View album"
                >
                  <div className={styles.viewAlbum}>View Album</div>
                </Button>
              </Link>
              {selectMode && (
                <div className={styles.selectorWrap}>
                  <GallerySelector selected={selected} selectable={true} onSelectionChange={handleChangeSelection} />
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className={`d-flex flex-row w-100 align-items-center justify-content-center gap-2 ${
            attachedTo ? "visible" : "invisible"
          }`}
        >
          <LinkIcon color="#0071CE" size={"1.5rem"} />
          <div className={styles.attach}>Attached to {attachedTo} items</div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className={styles.navArrow} onClick={showNext}>
          {combinedList.length > 0 && currentView < combinedList.length - 1 ? (
            <ChevronRight color="#4F4F4F" size={"3rem"} />
          ) : (
            <ChevronRight color="#D4D4D4" size={"3rem"} />
          )}
        </div>
      </div>
      <ConfirmModal
        showConfirmation={showConfirmDeleteModal}
        setShowConfirmation={setShowConfirmDeleteModal}
        confirmFunction={handleClickDelete}
        loading={deleteAlbumLoading || deletePhotoLoading}
        error={deleteAlbumError || deletePhotoError}
        name={`Confirm Delete ${isPhoto ? "Photo" : "Album"}`}
        message={`Are you sure you want to delete this ${isPhoto ? "photo" : "album"}?`}
      />
    </>
  );
};

CurrentItem.propTypes = {};

export default CurrentItem;
