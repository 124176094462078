import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import { AlertCircle, ExternalLink } from "react-feather";
import useDetailsBar from "./useDetailsBar";
import { useAuth } from "component-library";
import { Table } from "react-bootstrap";
import convertMinutesToTime from "../../../../../utils/convertMinutesToTime";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const DetailsBar = ({ project, timesheet, foremanId, workDay, unAssignedHours, overAllocatedTimeSheets }) => {
  const { id } = useParams();
  const { config } = useAuth();
  const { foremen } = useDetailsBar({ timesheet, foremanId, workDay });
  return (
    <>
      <Table striped hover className={`${styles.table}  text-gray2`}>
        <thead>
          <tr>
            <th>Job #</th>
            <th>Project</th>
            <th>Foreman</th>
            <th>
              <span className="d-flex align-items-center">
                {unAssignedHours !== 0 && <AlertCircle className="me-2" color="#E70000" size={16} />}Unallocated
              </span>
            </th>
            <th>Hours</th>
            <th>
              <ExternalLink size={16} color="#A7A7A7" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{project?.job_number || "-"}</td>
            <td>
              <span className={styles.projectName}>{project?.name || "-"}</span>
            </td>
            <td>
              {!!config && !!foremen && (
                <>
                  {foremen?.first_name} {foremen?.last_name}
                </>
              )}
            </td>
            <td>{convertMinutesToTime(unAssignedHours) || "-"}</td>
            <td>
              <span className={(unAssignedHours < 0 || !!overAllocatedTimeSheets.length) && styles.warning}>
                {!!timesheet?.total_minutes ? convertMinutesToTime(timesheet?.total_minutes) : "00:00"}
              </span>
            </td>
            {foremanId && (
              <td>
                <Link to={`/project/${id}/field-management/field-logs/${timesheet?.work_day_id}/foreman/${foremanId}`}>
                  <ExternalLink size={16} color="#0071ce" />
                </Link>
              </td>
            )}
          </tr>
        </tbody>
      </Table>
    </>
  );
};

DetailsBar.propTypes = {
  project: PropTypes.object,
  fieldLog: PropTypes.object,
  foremanId: PropTypes.any,
};

export default DetailsBar;
