import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { ChevronRight, ChevronLeft } from "react-feather";
import PropTypes from "prop-types";

const Pagination = ({
  items,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  total = items.length,
  disableReset = false,
}) => {
  const [pageTotal, setPageTotal] = useState(Math.ceil(total / itemsPerPage));
  const [pageArray, setPageArray] = useState([]);
  const [pageArrayIndex, setPageArrayIndex] = useState(0);

  useEffect(() => {
    if (!disableReset) {
      setCurrentPage(1);
      setPageArrayIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  useEffect(() => {
    setPageTotal(Math.ceil(total / itemsPerPage));
  }, [itemsPerPage, total]);

  useEffect(() => {
    if (!!pageTotal) {
      setPageArray(
        Array.from(new Array(pageTotal).slice(pageArrayIndex, pageArrayIndex + 10), (v, index) => (
          <Button
            key={index + pageArrayIndex}
            variant={currentPage === index + pageArrayIndex + 1 ? "light" : "link"}
            onClick={() => setCurrentPage(index + pageArrayIndex + 1)}
            aria-label={
              currentPage === index + pageArrayIndex + 1
                ? `page number ${index + pageArrayIndex + 1}, current page`
                : `page number ${index + pageArrayIndex + 1}`
            }
          >
            {index + pageArrayIndex + 1}
          </Button>
        ))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTotal, pageArrayIndex, currentPage]);

  useEffect(() => {
    if (currentPage > pageArrayIndex + 10) {
      setPageArrayIndex(pageArrayIndex + 10);
    } else if (currentPage <= pageArrayIndex) {
      setPageArrayIndex(pageArrayIndex - 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div className="hiddenPrint">
      {!!items && !!items.length && total > itemsPerPage && (
        <nav className="d-flex justify-content-center" role="navigation">
          <Button
            variant="link"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            style={{ color: "#4F4F4F" }}
            aria-label="previous page"
          >
            <ChevronLeft size={14} color="#4F4F4F" />
            Previous
          </Button>
          {!!pageArrayIndex && pageArrayIndex >= 10 && (
            <Button variant="link" onClick={() => setPageArrayIndex(pageArrayIndex - 10)}>
              ...
            </Button>
          )}
          {pageArray}
          {!!pageArray && pageArray.length === 10 && pageArray.length < pageTotal && (
            <Button variant="link" onClick={() => setPageArrayIndex(pageArrayIndex + 10)}>
              ...
            </Button>
          )}
          <Button
            className="mx-2"
            variant="link"
            disabled={currentPage === pageTotal}
            onClick={() => setCurrentPage(currentPage + 1)}
            style={{ color: "#4F4F4F" }}
            aria-label="next page"
          >
            Next
            <ChevronRight size={14} color="#4F4F4F" />
          </Button>
        </nav>
      )}
    </div>
  );
};

Pagination.propTypes = {
  items: PropTypes.array.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  total: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

export default Pagination;
