import React from "react";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const Tabs = ({ taskView, setTaskView }) => {
  return (
    <div role="tablist">
      <Button
        onClick={() => setTaskView(true)}
        variant="link"
        className={`text-decoration-none med p-0 me-2 pb-1 ${styles.button} ${
          !!taskView ? styles.activeView : undefined
        }`}
        role="tab"
        aria-selected={taskView ? "true" : "false"}
      >
        Task View
      </Button>
      <Button
        onClick={() => setTaskView(false)}
        variant="link"
        className={`text-decoration-none med p-0 mx-2 pb-1 ${styles.button} ${
          !taskView ? styles.activeView : undefined
        }`}
        role="tab"
        aria-selected={taskView ? "false" : "true"}
      >
        Timesheet View
      </Button>
    </div>
  );
};

Tabs.propTypes = {
  taskView: PropTypes.bool.isRequired,
  setTaskView: PropTypes.func.isRequired,
};

export default Tabs;
