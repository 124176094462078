import { fieldLogsApi, useApi, ConfirmModal } from "component-library";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Bell, Circle } from "react-feather";

const colors = ["#0071CE", "#FFA400", "#ff0000", "#FF6700", "#7DDB2D"];

const Status = ({ data }) => {
  const itemColor = colors[parseInt(data.work_day_status_id - 1)];
  const [showConfirm, setShowConfirm] = useState(false);
  const { loading, error, request, data: requestData } = useApi(fieldLogsApi.remindForemen);

  const sendReminders = () => {
    request([data.foreman_id]);
  };

  return (
    <div className="d-flex align-items-center">
      <Circle color={itemColor} fill={itemColor} size="0.8rem" className="me-1" />
      {data.work_day_status_name}
      {data.work_day_status_id === 4 && (
        <Button variant="link med light-text" aria-label="Send Field Log Status Reminder">
          <Bell color="#0071ce" size={16} onClick={() => setShowConfirm(true)} />
        </Button>
      )}
      <ConfirmModal
        showConfirmation={showConfirm}
        setShowConfirmation={setShowConfirm}
        confirmFunction={sendReminders}
        loading={loading}
        error={error}
        name="Send Reminder"
        successMessage={!!requestData && !!requestData.success && "Reminder sent."}
        message={`Are you sure you want send a reminder to ${data.foreman_first_name} ${data.foreman_last_name}?`}
      />
    </div>
  );
};

export default Status;
