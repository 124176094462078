import React from "react";
import styles from "../../styles/Login.module.scss";
import { ReactComponent as Background } from "../../assets/bg.svg";
import { ForgotPasswordForm, useAuth } from "component-library";

const ForgotPassword = () => {
  const { forgotPassword } = useAuth();

  return (
    <>
      <div className={styles.bgWrapper}>
        <Background className={styles.background} />
      </div>
      <ForgotPasswordForm forgotPassword={forgotPassword} />
    </>
  );
};

export default ForgotPassword;
