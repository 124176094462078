import { useState, useEffect } from "react";
import { HeaderButton, Loader, ConfirmModal } from "component-library";
import { ArrowLeftCircle } from "react-feather";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import { geofenceApi, useApi } from "component-library";
import { useParams } from "react-router-dom";
import POIGeofence from "../POIGeofence";
import { Briefcase, XCircle } from "react-feather";
import styles from "../styles.module.scss";
import { Alert } from "react-bootstrap";

const SinglePOI = () => {
  const { request: getRequest, data: getData, loading: getLoading, error: getError } = useApi(geofenceApi.getPOI);
  const {
    request: updateRequest,
    data: updateData,
    loading: updateLoading,
    error: updateError,
  } = useApi(geofenceApi.updatePOI);

  const { poiId } = useParams();

  const [poi, setPoi] = useState();
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);

  const handleSubmit = () => {
    updateRequest({
      ...poi,
      active: !poi.active,
    });
  };

  useEffect(() => {
    getRequest(poiId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!getData?.pois) {
      setPoi(getData.pois);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  useEffect(() => {
    if (!!updateData?.poi) {
      setPoi(updateData.poi);
      setArchiveModalVisible(false);
    }
  }, [updateData]);

  const HeaderButtons = () => (
    <div className="d-flex align-items-center">
      <HeaderButton
        onClick={() => setArchiveModalVisible(true)}
        text={!!poi?.active ? "Archive POI" : "Unarchive POI"}
        icon={<XCircle size={16} color="#0071ce" />}
        ariaLabel="archive "
      />
      <HeaderButton
        icon={<ArrowLeftCircle size={16} color="#0071ce" />}
        text="Back to Listing"
        linkTo={`/fleet-management/pois`}
        ariaLabel="back to fleet mgmt"
      />
    </div>
  );

  return (
    <InteriorPage
      navTitle={"Field Management"}
      pageTitle="Points of Interest"
      pageTitleSecondaryContent={HeaderButtons}
      navIcon={<Briefcase color="#fff" size={14} />}
    >
      {!!getLoading && <Loader />}
      {!!getError && <Alert variant="danger">{getError}</Alert>}
      {!!poi && (
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column mb-2">
            <div className="d-flex align-items-center">
              <div className={styles.circle} style={{ backgroundColor: !!poi.active ? "#34d27c" : "#7b7b7b" }}></div>
              <div className={styles.poiName}>{poi.name}</div>
            </div>
            <div className={styles.poiType}>{poi.geofence_type}</div>
          </div>
        </div>
      )}
      <POIGeofence poi={poi} />
      <ConfirmModal
        showConfirmation={!!archiveModalVisible && !!poi}
        setShowConfirmation={setArchiveModalVisible}
        message={`Are you sure you want to ${poi?.active ? "archive" : "unarchive"} ${poi?.name}?`}
        confirmFunction={handleSubmit}
        loading={updateLoading}
        error={updateError}
        name={`${poi?.active ? "Archive" : "Unarchive"} POI?`}
      />
    </InteriorPage>
  );
};

export default SinglePOI;
