import React from "react";
import styles from "./styles.module.scss";

const Number = ({ number, color, label }) => {
  return (
    <div className={styles.number}>
      <div className={styles.numberNumber} style={{ color: color }}>
        {number}
      </div>
      <div className={styles.numberLabel}>{label}</div>
    </div>
  );
};

export default Number;
