import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import companies from "../../api/companies";
import { useApi } from "component-library";
import { useNavigate } from "react-router-dom";

const ArchiveCompany = ({ setArchiving, company }) => {
  const { request, data } = useApi(companies.archiveCompany);
  const history = useNavigate();

  const archiveCompany = () => {
    request(company.id);
  };

  useEffect(() => {
    if (data && !!data.success) history("/directory");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Modal dialogClassName="modal-lg" centered animation={false} show={true} onHide={() => setArchiving(false)}>
      <Modal.Header closeButton>
        <h5 className="mb-0 med">{!!company && `Archive Company ${company && company.name}`}</h5>
      </Modal.Header>
      <Modal.Body>Are you sure you want to archive this company?</Modal.Body>
      <Modal.Footer>
        <Button className="mt-2" onClick={() => setArchiving(false)}>
          Cancel
        </Button>
        <Button variant="tertiary" className="mt-2" onClick={() => archiveCompany()}>
          Confirm Archive
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArchiveCompany;
