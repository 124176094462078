import React from "react";
import RowContent from "../../RowContent";
import RowItemContent from "../../RowItemContent";
import crewLogWidths from "../../gridWidths/crewLogWidths";
import Phone from "../../../../config/sharedColumns/columnRenders/Phone";
import Alerts from "../../../../config/crewLogConfig/columnRenders/Alerts";
import Hours from "../../../../config/crewLogConfig/columnRenders/Hours";

const CrewLogRow = ({ crewLog, columns }) => {
  return (
    <RowContent>
      <RowItemContent width={crewLogWidths.last_name}>{`${crewLog.first_name} ${crewLog.last_name}`}</RowItemContent>
      <RowItemContent width={crewLogWidths.phone}>
        <Phone data={crewLog} />
      </RowItemContent>
      {columns.map(
        (column) =>
          !["last_name", "phone", "hours", "alerts", "expand"].includes(column.field) && (
            <RowItemContent width={crewLogWidths[column.field] || "7rem"}>
              {Number.isFinite(crewLog[column.field])}
            </RowItemContent>
          )
      )}
      <RowItemContent width={crewLogWidths.hours}>
        <Hours data={crewLog} />
      </RowItemContent>
      <RowItemContent width={crewLogWidths.alerts}>
        <Alerts data={crewLog} />
      </RowItemContent>
    </RowContent>
  );
};

export default CrewLogRow;
