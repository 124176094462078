import React from "react";
import { DateTime } from "luxon";
import { AlertTriangle } from "react-feather";
import { Button } from "react-bootstrap";
import styles from "../styles.module.scss";
import PropTypes from "prop-types";
import { displayDate } from "component-library";

const ActivePermitsItem = ({ permit, setEditPermit }) => {
  return (
    <tr className="list-row">
      <td>{!!permit.issue_date && displayDate(permit.issue_date)}</td>
      <td className="font-weight-bold">
        <Button className={`${styles.number}`} variant="link" onClick={() => setEditPermit(permit)}>
          #{permit.number}
        </Button>
      </td>
      <td className={styles.descColumn}>{permit.description}</td>
      <td>
        $
        {parseFloat(permit.cost).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}
      </td>
      <td>
        {!!permit.expiration && DateTime.fromISO(permit.expiration).diffNow().as("days") < 3 && (
          <AlertTriangle size={14} color="#ffa400" />
        )}
        {!!permit.expiration && displayDate(permit.expiration)}
      </td>
      <td className="font-italic text-capitalize">{permit.status}</td>
      <td>
        {!!permit.file && (
          <a href={permit.file.url} target="_blank" rel="noreferrer">
            {permit.file.file_name}
          </a>
        )}
      </td>
    </tr>
  );
};

ActivePermitsItem.propTypes = {
  permit: PropTypes.object.isRequired,
  setEditPermit: PropTypes.func.isRequired,
};

export default ActivePermitsItem;
