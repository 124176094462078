import React from "react";
import { Button, Modal } from "react-bootstrap";
import useAddContact from "../../hooks/useAddContact";
import PropTypes from "prop-types";
import AddCompanyForm from "../Company/AddCompanyForm";
import AddLocation from "../ContactsAdmin/AddLocation";

const EditContactForm = ({ initialCompany, contact, setAddingContact, setEditedContact, contactType }) => {
  const {
    handleSubmit,
    fields,
    renderSubmitButton,
    addingCompany,
    setAddingCompany,
    setCompany,
    addingLocation,
    setAddingLocation,
    selectedOption,
  } = useAddContact({
    contact,
    setContact: setEditedContact,
    initialContact: contact,
    setAddNew: setAddingContact,
    initialCompany,
    contactType,
  });

  return (
    <Modal dialogClassName="modal-lg" centered animation={false} show={true} onHide={() => setAddingContact()}>
      {!!addingLocation && (
        <AddLocation setAddingLocation={setAddingLocation} company={selectedOption} setLocation={setCompany} />
      )}
      {addingCompany && (
        <AddCompanyForm
          initialCompany={!!contact && contact.company}
          setCompany={setCompany}
          setAddingCompany={setAddingCompany}
        />
      )}
      {!addingLocation && !addingCompany && (
        <>
          <Modal.Header closeButton closeLabel="close add contact modal">
            <h5 className="mb-0 med">
              {!!contact && !!contact.id
                ? `Edit Contact ${contact && contact.first_name} ${contact && contact.last_name}`
                : "Add Contact"}
            </h5>
          </Modal.Header>
          <form onSubmit={handleSubmit}>
            <Modal.Body>{fields}</Modal.Body>
            <Modal.Footer>
              <Button className="mt-2" onClick={() => setAddingContact()}>
                Cancel
              </Button>
              {renderSubmitButton(!!contact ? "Save Edits" : "Add Contact")}
            </Modal.Footer>
          </form>
        </>
      )}
    </Modal>
  );
};

EditContactForm.propTypes = {
  contact: PropTypes.object,
  setAddingContact: PropTypes.func.isRequired,
  setEditedContact: PropTypes.func.isRequired,
};

export default EditContactForm;
