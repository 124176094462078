import React from "react";
import { ArrowRightCircle } from "react-feather";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

const CalloutLink = ({ children, url }) => {
  return (
    <Link to={url} className={styles.calloutLink}>
      <ArrowRightCircle size="16" />
      {children}
    </Link>
  );
}

export default CalloutLink;