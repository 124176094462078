import React from "react";
import styles from "./styles.module.scss";
import CostCode from "../../../../../../config/taskReportingConfig/taskDetailConfig/columnRenders/CostCode";
import HoursEstRmn from "../../../../../../config/taskReportingConfig/taskDetailConfig/columnRenders/HoursEstRmn";
import HoursRepErnd from "../../../../../../config/taskReportingConfig/taskDetailConfig/columnRenders/HoursRepErnd";
import Name from "../../../../../../config/taskReportingConfig/taskDetailConfig/columnRenders/Name";
import Pacing from "../../../../../../config/taskReportingConfig/taskDetailConfig/columnRenders/Pacing";
import Percentage from "../../../../../../config/taskReportingConfig/taskDetailConfig/columnRenders/Percentage";
import QtyRepEst from "../../../../../../config/taskReportingConfig/taskDetailConfig/columnRenders/QtyRepEst";
import TaskContent from "./taskColumns/TaskContent";

const TaskItem = ({ task }) => {
  return (
    <div className={styles.taskItem}>
      <TaskContent headerText="Code" size="small">
        <CostCode data={task} />
      </TaskContent>
      <TaskContent headerText="Title">
        <div className={styles.wrapper}>
          <Name data={task} />
        </div>
      </TaskContent>
      <TaskContent headerText="Qty Rep/Est">
        <div className={styles.wrapper}>
          <QtyRepEst data={task} />
        </div>
      </TaskContent>
      <TaskContent headerText="Hours Rep/Ernd">
        <div className={styles.wrapper}>
          <HoursRepErnd data={task} />
        </div>
      </TaskContent>
      <TaskContent headerText="Hours Est/Rmn">
        <div className={styles.wrapper}>
          <HoursEstRmn data={task} />
        </div>
      </TaskContent>
      <TaskContent headerText="Pacing">
        <div className={styles.wrapper}>
          <Pacing data={task} />
        </div>
      </TaskContent>
      <TaskContent headerText="%">
        <div className={styles.wrapperSmall}>
          <Percentage data={task} />
        </div>
      </TaskContent>
    </div>
  );
};

export default TaskItem;
