import React from 'react';
import { BeatLoader } from "react-spinners";
import CalloutLink from "../../../components/CalloutLink";
import ManHoursItemChart from "../../../_dashboard/ManHoursItemChart";
import PerJobItemChart from "../../../_dashboard/PerJobItemChart";
import StatusItemChart from "../../../_dashboard/StatusItemChart";
import useProject from "../../hooks/useProject";
import DashboardSection from "./DashboardSection";
import styles from "./styles.module.scss";

const Reporting = () => {
  const { reporting, reportingLoading, project } = useProject();
  return (
    <DashboardSection title="Reporting">
      <div className={styles.reporting}>
        <div className={styles.reportingCharts}>
          {reportingLoading ? (
            <div className={styles.reportingLoading}>
              <BeatLoader size={10} color={"#0071ce"} />
            </div>
          ) : (
            <>
              {!!reporting ? (
                <>
                  {!!reporting.project_status && (
                    <div className={styles.report}>
                      <h3 className="med">BPR - % over/under</h3>
                      <StatusItemChart status={reporting.project_status} compact />
                    </div>
                  )}
                  <div className={styles.report}>
                    <h3 className="med">Man Hours</h3>
                    <ManHoursItemChart job={reporting} compact colors={["#0071CE", "#66AAE2"]} />
                  </div>
                  <div className={styles.report}>
                    <h3 className="med">Job Cost</h3>
                    <PerJobItemChart job={reporting} compact colors={["#0071CE", "#A7A7A7", "#345D9D"]} />
                  </div>
                </>
              ) : (
                <p className="ms-1">Reporting not available.</p>
              )}
            </>
          )}
        </div>
        <div className={styles.pageLinkWrap}>
          <CalloutLink url={`/project/${project.id}/reporting`}>Project Reporting</CalloutLink>
        </div>
      </div>
    </DashboardSection>
  );
}

export default Reporting;