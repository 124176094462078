import React from "react";
import styles from "./styles.module.scss";
import Comment from "./Comment/Comment";

const PreviousComments = ({
  comments,
  setComments,
  deleteComment,
  editComment,
  commentBeingEdited,
  setCommentBeingEdited,
  projectId,
  task,
  canEdit,
  handleUpdate,
}) => {
  return (
    <div className={styles.previousComments}>
      {comments &&
        !!comments.length &&
        comments.map((comment) => (
          <Comment
            key={`${comment.id}`}
            comment={comment}
            comments={comments}
            setComments={setComments}
            deleteComment={deleteComment}
            editComment={editComment}
            commentBeingEdited={commentBeingEdited}
            setCommentBeingEdited={setCommentBeingEdited}
            projectId={projectId}
            handleUpdate={handleUpdate}
            task={task}
            canEdit={canEdit}
          />
        ))}

      {comments.length === 0 && <div className="mt-3">No previous comments</div>}
    </div>
  );
};

export default PreviousComments;
