import React from "react";
import { Badge } from "react-bootstrap";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import Category from "../CardView/Card/Category";
import Favorite from "../Favorite";
import OnSite from "../CardView/Card/OnSite";

const ProjectListItem = ({ project, infoWindow = false }) => {
  return (
    <div className={`${styles.listItem} ${infoWindow ? styles.infoWindow : ""}`}>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column justify-content-between align-items-start">
          <div>
            <div>
              <Link to={`/project/${project?.id}`}>
                <span className={styles.jobNumber}>#{project?.job_number}</span>
              </Link>
            </div>
            <div className={styles.projectName}>
              {!!project?.certified_payroll && (
                <div className={styles.badgeWrapper}>
                  <Badge pill className="pill" bg="redOrange">
                    PW
                  </Badge>
                </div>
              )}
              <Link to={`/project/${project?.id}`}>
                <span>{project?.name}</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-end justify-content-between">
          <Favorite data={project} />
          <div className="py-2">
            <OnSite data={project} />
          </div>
          <div className={styles.projectCategories}>
            <Category data={project} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectListItem;
