import React from "react";
import styles from "./styles.module.scss";

const BidInformation = ({ bid, estimatorName, formatDate }) => {
  const today = formatDate(new Date().toISOString());

  return (
    <div className="pb-3">
      <div className="font-weight-bold lg my-2">Quotes Recieved</div>
      <div className="font-weight-bold lg my-2">{today} Report</div>
      <div className="pb-2">
        <div className="xxxl font-weight-bold">
          #{bid.id} {bid.name}
        </div>
        <div className={styles.lightText}>{bid.bid_location}</div>
      </div>
      <div>
        <span className="font-weight-bold">Estimator:</span> {estimatorName}
      </div>
      <div>
        <span className="font-weight-bold">Bid Due:</span> {formatDate(bid.bid_date)} at {bid.bid_time}
      </div>
    </div>
  );
};

export default BidInformation;
