import React from "react";
import { useFormControl, useApi, bidApi, Loader } from "component-library";
import { schema, initialData } from "../../schema/bidTask";
import { Alert } from "react-bootstrap";
import useUpdateTodos from "./useUpdateTodos";
import TodoAttachment from "../TodoAttachment";

const useBidTask = (task, setTodos, setBid, bid, setDocLoading) => {
  const { error, request, data, loading } = useApi(bidApi.completeToDo);

  const { dataError, setDataError } = useUpdateTodos(data, setTodos, setBid);

  const onSubmit = async (setLoading) => {
    setDataError(false);
    const formData = {
      project_id: task.project_id,
      todo_id: task.bid_todo.id,
      complete: 1,
    };

    try {
      await request(formData);
      setLoading(false);
    } catch (message) {
      setLoading(false);
    }
  };

  const { handleSubmit, renderSubmitButton, setFields } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const fields = (
    <div className="row">
      <Loader loading={!!loading} />
      <div className="col-12">Add Attachment</div>
      <div className="col-3">
        <TodoAttachment bid={bid} setBid={setBid} task={task} setDocLoading={setDocLoading} />
      </div>
      {!!error && (
        <div className="col-12">
          <Alert variant="danger">{error}</Alert>
        </div>
      )}

      {!!dataError && (
        <div className="col-12">
          <Alert variant="danger">Something went wrong. Please try again.</Alert>
        </div>
      )}
    </div>
  );
  return {
    schema,
    initialData,
    fields,
    handleSubmit,
    renderSubmitButton,
    setFields,
  };
};

export default useBidTask;
