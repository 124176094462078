import React from "react";
import styles from "./styles.module.scss";
import { ChevronDown } from "react-feather";

const TypeValue = ({ data }) => {
  return (
    <div className={styles.fakeDropdown}>
      <span>{data.type || "None Selected"}</span>
      <ChevronDown size="16" color="#717171" className="ms-2"/>
    </div>
  );
};

export default TypeValue;
