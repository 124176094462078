import EndDate from "../columns/EndDate";

const endDate = {
  headerName: "End Date",
  field: "end_date",
  cellRenderer: EndDate,
  maxWidth: 115,
};

export default endDate;
