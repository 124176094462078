import React from "react";
import InteriorPage from "../pageLayout/InteriorPage";
import { AlertCircle } from "react-feather";
import { useAuth } from "component-library";
import UnauthorizedCard from "../UnauthorizedCard";

const Unauthorized = () => {
  const { modules } = useAuth();

  return (
    <InteriorPage navTitle={!modules ? "Loading..." : "Unauthorized"} navIcon={<AlertCircle color="#fff" size={14} />}>
      <UnauthorizedCard />
    </InteriorPage>
  );
};

export default Unauthorized;
