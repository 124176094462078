import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import convertMinutesToTime from "../../../../../../utils/convertMinutesToTime";
import TimeInOut from "../components/TimeInOut";
import ForemanInput from "../components/ForemanInput/ForemanInput";
import { Link, useParams } from "react-router-dom";

const Foreman = ({ foreman, selectedForemanIds, handleForemanToggle }) => {
  const [timesheets, setTimesheets] = useState();
  const { id } = useParams();

  useEffect(() => {
    if (!!foreman?.foreman?.time_sheets?.length) {
      setTimesheets(foreman?.foreman?.time_sheets);
    } else {
      setTimesheets();
    }
  }, [foreman?.foreman?.time_sheets]);

  const ForemanName = () => (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      className={` ${styles.name} btn btn-link med text-limiter font-weight-bold`}
      to={`/project/${id}/field-management/crew-log/${foreman?.foreman_id}`}
    >
      {`${foreman?.foreman?.first_name} ${foreman?.foreman?.last_name}`}
    </Link>
  )

  return (
    <div className={styles.foreman}>
      <div className={styles.details}>
        <div>
          <ForemanInput
            foreman={foreman}
            selectedForemanIds={selectedForemanIds}
            handleForemanToggle={handleForemanToggle}
            name={<ForemanName />}
          />
        </div>
        {!!timesheets?.length &&
          timesheets.map((sheet, index) => (
            <div key={index} className={styles.sheetWrapper}>
              <TimeInOut timesheet={sheet} />
              {timesheets.length > 1 && (
                <div className="d-flex align-items-center">
                  <span className={`${styles.hours} ${styles.small}`}>
                    {convertMinutesToTime(parseInt(sheet.total_minutes))}
                  </span>
                </div>
              )}
            </div>
          ))}
      </div>
      <div className="d-flex align-items-center">
        {timesheets?.length === 1 && (
          <>
            <span className={styles.hours}>{convertMinutesToTime(parseInt(timesheets[0].total_minutes))}</span>
          </>
        )}
      </div>
    </div>
  );
};

Foreman.propTypes = {
  foreman: PropTypes.object,
  selectedForemenIds: PropTypes.array,
  setSelectedForemenIds: PropTypes.func,
};

export default Foreman;
