import React from "react";
import FieldLogRow from "../../PrintableGrids/rows/FieldLogs/FieldLogRow";
import TimesheetRow from "../../PrintableGrids/rows/Timesheets/TimesheetRow";
import TaskRow from "../../PrintableGrids/rows/Tasks/TaskRow";
import TimesheetHeaders from "../../PrintableGrids/rows/Timesheets/TimesheetHeaders";
import TaskHeaders from "../../PrintableGrids/rows/Tasks/TaskHeaders";

const PrintableRow = ({ fl }) => {
  return (
    <>
      <FieldLogRow fieldLog={fl} />
      {!!fl?.timesheets?.length && (
        <div className="mt-2 mb-2">
          <TimesheetHeaders />
          {fl?.timesheets?.map((timesheet) => {
            return (
              <div key={timesheet.id}>
                <TimesheetRow timesheet={timesheet} />
                {!!timesheet.tasks.length && (
                  <div className="mt-2 mb-2">
                    <TaskHeaders />
                    {timesheet.tasks.map((task) => {
                      return <TaskRow task={task} key={task.id} />;
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
export default PrintableRow;
