import StartDate from "../columns/StartDate";

const startDate = {
  headerName: "Start Date",
  field: "start_date",
  cellRenderer: StartDate,
  maxWidth: 115,
};

export default startDate;
