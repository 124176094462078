export const createInitialState = () => {
  return {
    showSelectModal: false,
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "toggle_update_photos_modal":
      return {
        ...state,
        showSelectModal: !state.showSelectModal,
      };
    default:
      return state;
  }
};
