import React from "react";
import PropTypes from "prop-types";
import { AlertCircle } from "react-feather";
import styles from "../styles.module.scss";

const Quantity = ({ editTasks, item, viewPhases, qtyTotal, updateItem }) => {
  return (
    <td className="text-end">
      <div className="d-flex align-items-center justify-content-end">
        {!!viewPhases && (
          <span
            className={` 
            ${styles.total}
            ${qtyTotal > item.bid_quantity ? styles.overage : ""}
          `}
          >
            {["item", "material", "task"].includes(item.entity_type) && (
              <>
                {parseFloat(qtyTotal) > item.bid_quantity && (
                  <AlertCircle className="me-1" size={16} color={"#ff6700"} />
                )}
                {`${parseFloat(qtyTotal)}/`}
              </>
            )}
          </span>
        )}
        {item.entity_type === "task" && !viewPhases && !!editTasks ? (
          <>
            <label htmlFor={`${item.id}-quantity`} aria-label="Item bid quantity edit"></label>
            <input
              id={`${item.id}-quantity`}
              name={`${item.id}-quantity`}
              type="text"
              className="form-control text-end"
              value={item?.bid_quantity || 0}
              placeholder="Qty"
              onChange={(e) => updateItem("bid_quantity", e.target.value, item.id)}
            />
          </>
        ) : (
          <span className={`${styles.bidQuantity}`}>{item.bid_quantity}</span>
        )}
      </div>
    </td>
  );
};

Quantity.propTypes = {
  editTasks: PropTypes.bool,
  item: PropTypes.object,
  viewPhases: PropTypes.bool,
  qtyTotal: PropTypes.number,
  updateItem: PropTypes.func,
};

export default Quantity;
