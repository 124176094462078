import React from "react";
import useModalNote from "../useModalNote";
import styles from "../styles.module.scss";
import { Button } from "react-bootstrap";
import { Camera, Save } from "react-feather";
import AttachedPhotos from "../AttachedPhotos";

const EditInput = ({
  setOpen,
  photos,
  setPhotos,
  setCommentText,
  commentText,
  saveEdit,
  projectId,
}) => {

  const { removeAllFiles } = useModalNote({projectId});
  const deleteAndClear = () => {
    removeAllFiles(photos);
    setPhotos([]);
  };

  return (
    <div>
      <div>
        <div className={`${styles.textHeader} d-flex align-items-center p-2`}>
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            <Camera size={24} color="#ffffff" />
          </Button>
          <Button variant="tertiary" onClick={saveEdit} className="med  ms-2">
            <div className="d-flex align-items-center">
              <Save className="me-1" size={18} color="#ffffff" />
              Save Edit
            </div>
          </Button>
        </div>

        <div className="form-group mb-0">
          <textarea
            className="w-100 p-2"
            value={commentText}
            onChange={({ target: { value } }) => setCommentText(value)}
            autoFocus
            rows="4"
          />
        </div>
        {!!photos.length && <AttachedPhotos photos={photos} deletePhotos={deleteAndClear} setOpen={setOpen} />}
      </div>
    </div>
  );
};

export default EditInput;
