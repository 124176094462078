import React from "react";
import styles from "./styles.module.scss";
import { Eye, LogIn, Trash2 } from "react-feather";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { ReactComponent as Unpinned } from "../../../../assets/photoUnpinned.svg";

const AlbumsGrid = ({ gridItems, handleUpdateView, project }) => {
  return (
    <>
      <div className={styles.photoGrid}>
        {!!gridItems?.length ? (
          gridItems.map((item) => (
            <div className={styles.imgWrapper} key={item.id}>
              {!!handleUpdateView ? (
                <Button
                  variant="link"
                  className={`p-0 position-relative ${styles.imgContainer}`}
                  onClick={() => handleUpdateView(item.id)}
                >
                  <img src={item?.first_photo?.small_thumbnail || item?.first_photo?.abs_url} alt="" loading="lazy" />
                  <div className={styles.overlay}>
                    <LogIn color="#fff" />
                  </div>
                </Button>
              ) : (
                <div className={`position-relative ${styles.imgContainer}`}>
                  <img src={item?.first_photo?.small_thumbnail || item?.first_photo?.abs_url} alt="" loading="lazy" />
                  <div className={styles.overlay}>
                    <Link to={`/project/${project.id}/photo-library/album/${item.id}`} className={styles.hoverIcon}>
                      <Eye color="#fff" size={"2rem"} />
                    </Link>
                    <div className={styles.hoverIcon}>
                      <Unpinned className={styles.pinIcon} />
                    </div>
                    <div className={styles.hoverIcon}>
                      <Trash2 size={"2rem"} color="#fff" />
                    </div>
                  </div>
                </div>
              )}
              <div>
                <div className={`${styles.name} text-limiter`}>{item?.name}</div>
                <div className={styles.count}>
                  {item?.photo_count || 0} Photo{item?.photo_count !== 1 && "s"}
                </div>
              </div>
            </div>
          ))
        ) : (
          <>No Albums found</>
        )}
      </div>
    </>
  );
};

AlbumsGrid.propTypes = {
  gridItems: PropTypes.array,
};

export default AlbumsGrid;
