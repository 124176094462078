import React, { useState, useEffect } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import useAddContact from "../../hooks/useAddContact";
import ContactSearch from "./ContactSearch";
import { X } from "react-feather";
import Select from "react-select";
import PropTypes from "prop-types";
import { ContactCard, submittalConfig } from "component-library";
import AddLocation from "../ContactsAdmin/AddLocation";
import AddCompanyForm from "../Company/AddCompanyForm";

const AddContact = ({
  hide,
  show,
  addContact,
  selectedRole,
  error,
  setError,
  updateSource,
  label = "Contact",
  contacts,
}) => {
  const [addNew, setAddNew] = useState(false);
  const [contact, setContact] = useState({});
  const [role, setRole] = useState(selectedRole || "");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const {
    handleSubmit,
    fields,
    renderSubmitButton,
    addingLocation,
    setAddingLocation,
    selectedOption,
    setCompany,
    addingCompany,
    setAddingCompany,
  } = useAddContact({
    contact,
    setContact,
    setAddNew,
  });

  const handleChangeRole = ({ value }) => {
    setRole(value);
  };

  const handleAdd = async () => {
    setSubmitted(true);
    setLoading(true);
    setError(false);
    if (role === "source") {
      updateSource(contact);
    }

    await addContact({ role, contact });
    setLoading(false);
  };

  useEffect(() => {
    if (submitted && !error && !loading) {
      hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, error, submitted]);

  let options = submittalConfig.roles;
  if (!selectedRole) {
    options = submittalConfig.roles.filter((r) => r.value === "sub" || r.value === "supplier");
  }

  return (
    <Modal
      animation={false}
      show={show}
      onHide={() => {
        setAddNew(false);
        hide();
      }}
      dialogClassName="modal-lg"
    >
      {!!addingLocation && (
        <AddLocation setAddingLocation={setAddingLocation} company={selectedOption} setLocation={setCompany} />
      )}
      {!!addingCompany && (
        <AddCompanyForm
          initialCompany={!!contact && contact.company}
          setCompany={setCompany}
          setAddingCompany={setAddingCompany}
        />
      )}
      {!addingCompany && !addingLocation && (
        <>
          <Modal.Header>
            <h4 className="mb-0 lg font-weight-bold">{addNew ? "Add New Contact to Address Book" : `Add ${label}`}</h4>
            <Button
              onClick={() => {
                setAddNew(false);
                hide();
              }}
              variant="link"
              className="me-n3 mt-n1"
            >
              <X color="#979797" />
            </Button>
          </Modal.Header>
          <Modal.Body>
            {error && <Alert variant="danger">{error}</Alert>}
            {addNew ? (
              <form onSubmit={handleSubmit}>
                <Button variant="link" size="sm" onClick={() => setAddNew(false)} className="ps-0 mb-2">
                  &lsaquo; Search
                </Button>
                {fields}
                <div className="d-flex justify-content-end">{renderSubmitButton("Save Contact")}</div>
              </form>
            ) : (
              <>
                <div className={`row ${!!selectedRole ? "d-none" : ""}`}>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label htmlFor="contact-role-select">Role</label>
                      <Select
                        select
                        name="contact-role-select"
                        inputId="contact-role-select"
                        onChange={handleChangeRole}
                        options={options}
                        value={submittalConfig.roles.find((opt) => opt.value === role)}
                        placeholder="Select..."
                        isDisabled={!!selectedRole}
                        className="w-100"
                      />
                    </div>
                  </div>
                </div>

                <ContactSearch setContact={setContact} setAddNew={setAddNew} contacts={contacts} />

                {contact && !!Object.keys(contact).length && (
                  <div className="col-lg-4 mb-2 p-0">
                    <ContactCard contact={contact} />
                  </div>
                )}

                <Button disabled={!role || !Object.keys(contact).length} onClick={handleAdd} className="btn-tertiary">
                  {loading ? "Loading..." : "Add Contact"}
                </Button>
              </>
            )}
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

AddContact.propTypes = {
  addContact: PropTypes.func,
  hide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  selectedRole: PropTypes.string,
  error: PropTypes.any,
  setError: PropTypes.func.isRequired,
  updateSource: PropTypes.func,
};

export default AddContact;
