import React, { useEffect } from "react";
import { Edit3, XCircle, PlusCircle } from "react-feather";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import companies from "../../../api/companies";
import { useApi } from "component-library";
import ContactSubNav from "../../ContactSubNav";

const CompanyLocationNav = ({
  company,
  setCompany,
  location,
  setEditingLocation,
  setArchivingLocation,
  setArchiveLocation,
  setEditLocation,
  archived = false,
  canRemove = false,
  setRemovingLocationConfirm,
}) => {

  const { request, data } = useApi(companies.restoreLocation);

  useEffect(() => {
    if (!!data && !!data.success) {
      setCompany({
        ...company,
        locations: company.locations.map((loc) => {
          if (loc.id === location.id) {
            return {
              ...loc,
              archived: data.location.archived
            }
          } else {
            return loc
          }
        } )
      })
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <ContactSubNav>
      {!!archived ? (
        <li>
          <Button
            size="sm"
            variant="link"
            className="p-0 mb-2"
              onClick={() => {
                request(location.id);
              }}
          >
            <PlusCircle
              size="12"
              className="attachment-icon attachment-download mx-1"
            />
            <span className="text-gray">Restore Location</span>
          </Button>
        </li>
      ) : (
        <>
          <li>
            <Button
              size="sm"
              variant="link"
              className="p-0 mb-2"
              onClick={() => {
                setEditingLocation();
                if (!!setEditLocation) {
                  setEditLocation();
                }
              }}
            >
              <Edit3
                size="12"
                className="attachment-icon attachment-download mx-1"
              />
              <span className="text-gray">Edit Location</span>
            </Button>
          </li>
          {!!company && !!company.locations && !!company.locations.length && (
            <li>
              <Button
                size="sm"
                variant="link"
                className="p-0 mb-2"
                  onClick={() => {
                    setArchiveLocation(location);
                    setArchivingLocation(true);
                  }}
                >
                  <XCircle
                    size="12"
                    className="attachment-icon attachment-download mx-1"
                  />
                  <span className="text-gray">Archive Location</span>
                </Button>
            </li>
          )}
        </>
      )}
      {!!canRemove && (
        <li>
          <Button
            size="sm"
            variant="link"
            className="p-0 mb-2"
            onClick={() => {
              setRemovingLocationConfirm(true);
            }}
          >
            <XCircle
              size="12"
              className="attachment-icon attachment-download mx-1"
            />
            <span className="text-gray">Remove Location</span>
          </Button>
        </li>
      )}
    </ContactSubNav>
  );
};

CompanyLocationNav.propTypes = {
  setEditingLocation: PropTypes.func,
};

export default CompanyLocationNav;
