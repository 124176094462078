const geofenceTypeOptions = [
  {
    value: "dump",
    label: "Dump",
  },
  {
    value: "quarry",
    label: "Quarry",
  },
  {
    value: "dealer_service",
    label: "Dealer Service",
  },
  {
    value: "hq",
    label: "HQ",
  },
  {
    value: "supplier",
    label: "Supplier",
  },
];

export default geofenceTypeOptions;
