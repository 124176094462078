import Category from "../../CardView/Card/Category";

const category = {
  headerName: "Project Category",
  field: "category",
  cellRenderer: Category,
  width: 300,
  resizable: true,
};

export default category;

