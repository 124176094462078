import React from "react";
import useDeleteFolder from "./useDeleteFolder";
import PropTypes from "prop-types";
import { ConfirmModal } from "component-library";
import { Trash2 } from "react-feather";
import { useParams } from "react-router-dom";

const DeleteFolderModal = ({ showModal, setShowModal }) => {
  const { deleteFolderLoading, deleteFolderError, handleDeleteFolder } = useDeleteFolder();
  const { folderName } = useParams();

  return (
    <ConfirmModal
      showConfirmation={showModal}
      setShowConfirmation={setShowModal}
      confirmFunction={() => {
        handleDeleteFolder(folderName);
      }}
      Icon={() => <Trash2 color="#ff6700" size={16} />}
      loading={deleteFolderLoading}
      error={deleteFolderError}
      name={"Delete Folder"}
      message={"Are you sure you want to delete this folder? This action cannot be undone."}
      type="folder"
      itemName={folderName}
      buttonText="Delete Folder"
    />
  );
};

DeleteFolderModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default DeleteFolderModal;
