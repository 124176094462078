import React from "react";
import useBidMeeting from "./hooks/useBidMeeting";
import PropTypes from "prop-types";

const BidMeeting = (task, setTodos, setBid) => {
  const {
    fields,
    renderSubmitButton,
    handleSubmit,
  } = useBidMeeting(
    task,
    setTodos,
    setBid
  );

  return (
    <form className="row position-relative" onSubmit={handleSubmit}>
      <div className="col-md-8 position-static">{fields}</div>
      <div className="col-md-4 d-flex flex-column justify-content-end text-end p-3">
        <div className="flex-end">{renderSubmitButton("Mark Complete")}</div>
      </div>
    </form>
  );
};

BidMeeting.propTypes = {
  task: PropTypes.object.isRequired,
  setTodos: PropTypes.func.isRequired,
  setBid: PropTypes.func.isRequired,
};

export default BidMeeting;
