const getTaskNumber = (task) => {
  return `${
    task?.item_number ||
    task?.bid_item_number ||
    task?.parent_bid_item_number ||
    ""
  }${
    !!task.cost_code &&
    (!!task?.item_number ||
      !!task?.bid_item_number ||
      !!task?.parent_bid_item_number)
      ? " - "
      : ""
  }${task.cost_code}`;
};

export default getTaskNumber;
