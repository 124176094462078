import React from "react";
import { Briefcase, Tag, Clock, Truck } from "react-feather";

const Icons = ({ label }) => {
  return (
    <>
      {label === "Project Management" && <Briefcase className="me-2" color="#0071ce" size={18} />}
      {label === "Telematics" && <Truck className="me-2" color="#0071ce" size={18} />}

      {label === "Bid Build" && <Tag className="me-2" color="#0071ce" size={16} />}
      {label === "Time Tracking" && <Clock className="me-2" color="#0071ce" size={16} />}
    </>
  );
};

export default Icons;
