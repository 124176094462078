import NoteToggler from "../columns/NoteToggler";
import SelectAllToggler from "../columns/SelectAllToggler";

const toggler = {
  field: "selected",
  headerName: "",
  width: 75,
  maxWidth: 75,
  suppressMenu: true,
  headerComponent: SelectAllToggler,
  cellRenderer: NoteToggler,
};

export default toggler;
