import { useState, useEffect } from "react";
import useProject from "../../../../../hooks/useProject";
import { useApi, dailyFieldlogsApi } from "component-library";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import useDailyFieldLogs from "../context/useDailyFieldLogs";

const useEquipment = () => {
  const { updateRequests } = useDailyFieldLogs();
  const [active, setActive] = useState(true);
  const { project, fieldMgmtDateRange: dateRange } = useProject();
  const [equipment, setEquipment] = useState();

  const {
    request: equipmentRequest,
    data: equipmentData,
    error: equipmentError,
    loading: equipmentLoading,
  } = useApi(dailyFieldlogsApi.getEquipment);

  const formatDate = (unformattedDate) => {
    return DateTime.fromJSDate(new Date(unformattedDate)).toFormat("yyyy-MM-dd");
  };

  const getRequestParams = (projectJobNumber, fromDate, toDate) => {
    let params;
    if (!!toDate) {
      params = {
        job_number: projectJobNumber,
        from_date: formatDate(fromDate),
        to_date: formatDate(toDate),
      };
    }

    if (!!fromDate && !toDate) {
      params = {
        job_number: project.job_number,
        date: formatDate(fromDate),
      };
    }

    return params;
  };

  useEffect(() => {
    if (!!updateRequests && !!project) {
      const requestParams = getRequestParams(project.job_number, dateRange[0], dateRange[1], true);
      equipmentRequest(requestParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRequests]);

  useEffect(() => {
    if (equipmentData?.equipment) {
      setEquipment(equipmentData.equipment);
    } else {
      setEquipment();
    }
  }, [equipmentData?.equipment]);

  return {
    active,
    setActive,
    equipment,
    equipmentError,
    equipmentLoading,
  };
};

useEquipment.propTypes = {
  selectedForemenIds: PropTypes.array,
};

export default useEquipment;
