import React from "react";
import ColumnHeader from "./taskColumns/ColumnHeader";
const ColumnHeaders = () => {
  return (
    <div className="d-flex ps-2">
      <ColumnHeader text="Code" width={95} alignment="left" />
      <ColumnHeader text="Title" width={200} alignment="left" />
      <ColumnHeader text="Qty Rep/Est" width={150} />
      <ColumnHeader text="Hours Rep/Ernd" width={150} />
      <ColumnHeader text="Hours Est/Rmn" width={150} />
      <ColumnHeader text="Pacing" width={150} />
      <ColumnHeader text="%" width={75} />
    </div>
  );
};
export default ColumnHeaders;
