import React, { useEffect } from "react";
import MobileHeader from "../MobileHeader/MobileHeader";
import styles from "./styles.module.scss";
import Detail from "./Detail";
import { useParams } from "react-router-dom";
import { useApi, telematicsApi } from "component-library";

const TimelineDetail = () => {
  const { driverId } = useParams();
  const { data: userData, error: userError, request: userRequest } = useApi(telematicsApi.getUserByID);

  const {
    data: timelineRequestData,
    loading: timelineLoading,
    error: timelineError,
    request: timelineRequest,
  } = useApi(telematicsApi.getDriverTimeline);

  useEffect(() => {
    if (driverId) {
      userRequest(driverId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.mobileWrap}`}>
      <MobileHeader
        backUrl={`${process.env.REACT_APP_URL}page/schedule_contact_info?this_id=${driverId}&type=driver`}
      />
      <Detail
        timelineRequestData={timelineRequestData}
        timelineLoading={timelineLoading}
        timelineError={timelineError}
        timelineRequest={timelineRequest}
        userError={userError}
        driverData={{
          sched_driver_name: userData?.data ? `${userData?.data?.first_name} ${userData?.data?.last_name}` : "",
          sched_assigned_to_user_id: driverId,
        }}
      />
    </div>
  );
};

export default TimelineDetail;
