import React from "react";
import styles from "./styles.module.scss";
import { ArrowRightCircle, CheckCircle } from "react-feather";
import { Link } from "react-router-dom";
import useFilterBlock from "./useFilterBlock";

const FilterBlock = ({
  title,
  Icon,
  iconColor,
  warningColor,
  timesheetCount,
  size = "lg",
  selectedConsoleFilters,
  setSelectedConsoleFilters,
  timesheetText,
  projectId,
  displayTitle,
  isFieldlog = false,
  isProject = false,
}) => {

  const { active, handleToggle, getActiveColor } = useFilterBlock({
    setSelectedConsoleFilters,
    selectedConsoleFilters,
    title,
    timesheetCount,
  });

  return (
    <>
      {!!isFieldlog ? (
        <>
          {timesheetCount === 0 ? (
            <div
              className={`${styles.block} ${size === "lg" ? styles.largeBlock : styles.smallBlock}`}
              style={{
                backgroundColor: getActiveColor("#f7f7f7", "#F2FBEA", warningColor),
              }}
            >
              <header className={styles.header}>
                <div className="d-flex align-items-center">
                  <div className={styles.iconWrapper} style={{ backgroundColor: "#6FCB21" }}>
                    <Icon />
                  </div>
                  <h3 className={styles.title}>{displayTitle}</h3>
                </div>
                {timesheetCount === 0 && <CheckCircle size={16} color="#6FCB21" />}
              </header>
            </div>
          ) : (
            <Link
              onClick={() => setSelectedConsoleFilters([title])}
              to={`${
                isProject && projectId
                  ? `/project/${projectId}/field-management/timesheet-logs`
                  : `/field-management/crew-logs`
              }`}
              className={`${styles.block} ${size === "lg" ? styles.largeBlock : styles.smallBlock}`}
              style={{
                backgroundColor: getActiveColor("#f7f7f7", "#F2FBEA", warningColor),
              }}
            >
              <header className={styles.header}>
                <div className="d-flex align-items-center">
                  <div
                    className={styles.iconWrapper}
                    style={{ backgroundColor: getActiveColor("#a7a7a7", "#6FCB21", iconColor) }}
                  >
                    <Icon />
                  </div>
                  <h3 className={styles.title}>{displayTitle}</h3>
                </div>
                {size !== "lg" && <div className={styles.count}>{timesheetCount}</div>}
                {timesheetCount === 0 ? (
                  <CheckCircle size={16} color="#6FCB21" />
                ) : (
                  <ArrowRightCircle color="#0071CE" size={16} />
                )}
              </header>
              {size === "lg" && (
                <section className={styles.countRow}>
                  <span className={styles.count}>{timesheetCount}</span> {timesheetText || "timesheets"}
                </section>
              )}
            </Link>
          )}
        </>
      ) : (
        <div
          className={`${styles.block} ${size === "lg" ? styles.largeBlock : styles.smallBlock}`}
          style={{
            backgroundColor: getActiveColor("#f7f7f7", "#F2FBEA", warningColor),
          }}
        >
          <header className={styles.header}>
            <div className="d-flex align-items-center">
              <div
                className={styles.iconWrapper}
                style={{ backgroundColor: getActiveColor("#a7a7a7", "#6FCB21", iconColor) }}
              >
                <Icon />
              </div>
              <h3 className={styles.title}>{displayTitle}</h3>
            </div>
            {size !== "lg" && <div className={styles.count}>{timesheetCount}</div>}
            {timesheetCount > 0 ? (
              <div className={`form-check m-0`}>
                {!isFieldlog && (
                  <>
                    <input
                      onChange={() => handleToggle(active ? 0 : 1, title)}
                      value={active}
                      checked={active}
                      type="checkbox"
                      id={`${title}`}
                      className="form-check-input"
                    />
                    <label
                      className={`${
                        !!active ? (iconColor === "#E70000" ? "red" : "yellow") : "white"
                      } form-check-label sm  ms-4`}
                      htmlFor={`${title}`}
                    ></label>
                  </>
                )}
              </div>
            ) : (
              <CheckCircle size={16} color="#6FCB21" />
            )}
          </header>
          {timesheetCount !== 0 && size === "lg" && (
            <>
              <section className={styles.countRow}>
                <span className={styles.count}>{timesheetCount}</span> {timesheetText || "timesheets"}
              </section>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default FilterBlock;
