import React from "react";
import PerJobItem from "./PerJobItem.js";
import PerJobsChart from "./PerJobsChart";
import styles from "./styles.module.scss";

const PerJobGrid = ({ jobs }) => {
  return (
    <section className="col">
      <header>
        <h1 className="med">Total Hours</h1>
      </header>
      <div className={styles.boomboardGrid}>
        <div className={styles["status-overall"]}>
          <PerJobsChart />
        </div>
        {jobs.map((job) => (
          <PerJobItem job={job} key={job.job_id} />
        ))}
      </div>
    </section>
  );
};

export default PerJobGrid;
