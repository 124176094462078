import React from "react";
import { Lock } from "react-feather";
import styles from "./styles.module.scss";
import sectionStyles from "../styles.module.scss";

const InternalNotes = ({ day, updateDateField }) => {
  return (
    <section className={sectionStyles.fieldSection}>
      <div className="form-group d-flex align-items-start">
        <h3 htmlFor={`${day.id}-internal-note`}>
          <Lock color="#FF6700" size={16} className="me-2" />
          Internal Note
        </h3>
        <textarea
          id={`${day.id}-internal-note`}
          onChange={(e) => updateDateField(day, "internal_note", e.target.value)}
          className={`${styles.textArea} form-control med`}
          value={day.internal_note}
        />
      </div>
    </section>
  );
};

export default InternalNotes;
