import { useState, useEffect } from "react";
import { useApi, taskDetailApi } from "component-library";
import { useParams } from "react-router-dom";

const useSubtasks = () => {
  const { id, taskId } = useParams();
  const [subtasks, setSubtasks] = useState([]);

  const {
    request: subtasksRequest,
    data: subtasksData,
    loading: subtasksLoading,
    error: subtasksError,
  } = useApi(taskDetailApi.getTaskSubtasks);

  useEffect(() => {
    if (taskId) {
      subtasksRequest({ projectId: id, taskId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  useEffect(() => {
    setSubtasks(subtasksData?.subtasks || []);
  }, [subtasksData]);

  return {
    subtasks,
    subtasksLoading,
    subtasksError,
  };
};

export default useSubtasks;
