import React from "react";
import { Map } from "react-feather";
import { useParams, Link } from "react-router-dom";
import CalloutLink from "../../../components/CalloutLink";
import DashboardSection from "./DashboardSection";
import styles from "./styles.module.scss";

const Documents = () => {
  const { id } = useParams();
  return (
    <DashboardSection title="Project Documents">
      <div className={styles.docLinks}>
        <div className={styles.docCalloutWrap}>
          <Link
            className={styles.docCallout}
            to={`/project/${id}/documents/folder/Plans%20and%20Specs`}
            aria-label="Project documents folder page for project plans and specs"
          >
            <Map size="18" />
            Plans and Specs
          </Link>
        </div>
        <div className={styles.pageLinkWrap}>
          <CalloutLink url={`/project/${id}/documents`}>Project Documents</CalloutLink>
        </div>
      </div>
    </DashboardSection>
  );
};

export default Documents;
