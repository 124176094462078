/**
 * a function that creates a debounce function. the created debounce function, called like this:
 * const { debounce } = useDebounce();
 * should be wrapped a round another function to delay its invocation until a certain time has passed since the last time it was invoked
 * 
 * @returns {Object} An object containing a single method:
 *   - debounce {Function} - The debounced function that should be used to wrap the target function.
 */

const useDebounce = () => {
  let timeout;
  const debounce = (func, args) => {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      func(args);
    }, 1000);
  };
  return { debounce };
};

export default useDebounce;
