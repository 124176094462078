import React from "react";
import SelectorTodo from "../PreconChecklist/SelectorTodo";
import PropTypes from "prop-types";

const TeamItem = ({ item, roleOptions }) => {
  return (
    <div className="mb-4 text-gray2">
      <h3 className="med font-weight-bold text-white">
        {item.key === "project_manager" && <>Project Manager(s)</>}
        {item.key === "project_coordinator" && <>Project Coordinator(s)</>}
        {item.key === "superintendant" && <>Superintendent(s)</>}
        {item.key === "foremen" && <>Project Foreman(men)</>}
      </h3>
      <SelectorTodo todo={item} roleOptions={roleOptions} />
    </div>
  );
}

TeamItem.propTypes = {
  item: PropTypes.object.isRequired,
  roleOptions: PropTypes.object,
};

export default TeamItem;
