import React, { useRef, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Table, Card } from "react-bootstrap";
import debounceFunc from "../../hooks/useDebounce";
import companiesApi from "../../api/companies";
import { useApi } from "component-library";
import { Plus, Search } from "react-feather";
import PropTypes from "prop-types";
import LocationOptions from "./LocationOptions";
import InfiniteScroll from "react-infinite-scroll-component";

const { debounce } = debounceFunc();

const LocationSearch = ({
  setCompany,
  setAddNewCompany,
  setAddNewLocation,
  setLocation,
  setAddNew,
  placeholder,
  error,
  location,
  label = "Search in address book",
  setSelectedOption,
}) => {
  const node = useRef();
  const [locationOptions, setLocationOptions] = useState([]);
  const [cursor, setCursor] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [q, setQ] = useState("");

  const { data: searchData, request: search } = useApi(companiesApi.searchLocations);
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    if (searchData && searchData.locations) {
      if (searchData.locations.current_page <= 1) {
        setLocationOptions(searchData.locations.data);
        setCursor(0);
      } else {
        setLocationOptions([...locationOptions, ...searchData.locations.data]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  useEffect(() => {
    if (searchData && searchData.locations) {
      setLocationOptions(searchData.locations.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectLocation = (location) => {
    setLocation(location);
    setSearchOpen(false);
  };

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setQ("");
      setTimeout(function () {
        setLocationOptions([]);
      }, 300);
    } else {
      setQ(e.target.value);
      debounce(search, { q: e.target.value });
    }
  };

  const handleLoadMore = () => {
    debounce(search, { q: q, page: searchData.locations.current_page + 1 });
  };

  const handleClick = (e) => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }
    setSearchOpen(false);
  };

  useEffect(() => {
    if (location) {
      setQ(location.company_name);
    } else {
      setQ("");
    }
  }, [location]);

  useEffect(() => {
    debounce(search, { q: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`form-group position-relative ${error ? "error" : ""}`} ref={node}>
      <label htmlFor="location-search">{label}</label>
      <div className="input-search">
        <input
          id="location-search"
          type="text"
          onChange={handleSearch}
          className="form-control pe-4"
          onFocus={() => setSearchOpen(true)}
          value={q}
          placeholder={placeholder || "Start typing to search for a company location"}
          autoComplete="nope"
          onKeyDown={(e) => {
            switch (e.key) {
              case "Enter":
                e.preventDefault();
                if (cursor < locationOptions.length) {
                  setLocation(locationOptions[cursor]);
                } else if (cursor === locationOptions.length) {
                  setAddNew(true);
                }
                setSearchOpen(false);
                break;
              case "ArrowDown":
                if (cursor < locationOptions.length) {
                  setCursor(cursor + 1);
                } else if (cursor === locationOptions.length) {
                  setCursor(0);
                }
                break;
              case "ArrowUp":
                if (cursor > 0) {
                  setCursor(cursor - 1);
                } else if (cursor === 0) {
                  setCursor(locationOptions.length);
                }
                break;
              default:
                break;
            }
          }}
        />
        <Search size="14" className="input-group-append" />
      </div>
      {!!error && <span className="help-block is-error">Required.</span>}
      {searchOpen && (
        <>
          <Card id="options" className={styles.options}>
            <InfiniteScroll
              dataLength={locationOptions.length}
              next={() => handleLoadMore()}
              hasMore={
                !!searchData && searchData?.locations?.current_page < searchData?.locations?.last_page ? true : false
              }
              loader={<p className="p-2">Loading...</p>}
              scrollableTarget="options"
            >
              <Table className={`mb-0 ${styles["option-table"]}`}>
                <tbody>
                  <LocationOptions
                    locations={locationOptions}
                    handleSelect={handleSelectLocation}
                    selected={cursor}
                    setAddingLocation={setAddNewLocation}
                    setCompany={setCompany}
                    setSelectedOption={setSelectedOption}
                  />
                </tbody>
              </Table>
            </InfiniteScroll>
          </Card>
          <div className={styles.addNewWrapper}>
            <button
              onClick={() => {
                setAddNewCompany(true);
              }}
              type="button"
              tabIndex="-1"
              className={`${styles["add-new-button"]} ${
                cursor === locationOptions.length ? "option-active" : ""
              } med btn btn-link d-flex justify-content-between align-items-center`}
            >
              <span>Create New Company</span>
              <Plus size="14" />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

LocationSearch.propTypes = {
  location: PropTypes.object,
  error: PropTypes.any,
  placeholder: PropTypes.string,
  setLocation: PropTypes.func,
  setAddNew: PropTypes.func,
};

export default LocationSearch;
