import React from "react";
import { Camera } from "react-feather";
import styles from "./styles.module.scss";

const ImagesIcon = ({ data }) => {
  return (
    <div className="position-relative">
      {!!data?.files?.length && (
        <div className="text-gray2">
          <Camera size={16} color="#0071CE" />
          <span className={styles.imageCounter}>{data?.files?.length}</span>
        </div>
      )}
    </div>
  );
};

export default ImagesIcon;
