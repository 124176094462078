import { useState, useEffect } from "react";

const useTodoAttachment = (bid, task, setBid) => {
  const [fileId, setFileId] = useState(task.file_id);
  const [todoFiles, setTodoFiles] = useState([]);
  const [file, setFile] = useState();

  useEffect(() => {
    if (!fileId && file) {
      const todoIds = todoFiles.map(t => t.id);
      setBid({
        ...bid,
        attachments: bid.attachments.filter(a => todoIds.includes(a.id))
      });
    }
    setFile(todoFiles.find((f) => f.id === fileId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId, todoFiles]);

  useEffect(() => {
    if (!!file) {
      const alreadyThere = bid.attachments.find((f) => f.id === file.id);
      if (!alreadyThere) {
        setBid({
          ...bid,
          attachments: [...bid.attachments, file],
          bid_todos: bid.bid_todos.map((td) =>
            td.id === task.id ? { ...task, file_id: fileId } : td
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    setTodoFiles(bid.attachments.filter((a) => a.type === "Todo"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid]);

  useEffect(() => {
    setFileId(task.file_id);
  }, [task]);

  return { file, setFileId, setTodoFiles };
};

export default useTodoAttachment;
