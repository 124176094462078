import React from "react";
import { Edit3 } from "react-feather";
import { currency, displayDate } from "component-library";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";

const NeededPermitsListItem = ({ permit, setEditPermit }) => {
  return (
    <tr className="list-row">
      <td>{!!permit.needed_by && displayDate(permit.needed_by)}</td>
      <td className={styles.descColumn}>{permit.description}</td>
      <td>{currency(permit.cost)}</td>
      <td className="text-capitalize font-italic">{permit.status}</td>
      <td>
        <Button
          className={styles.editNeededPermitButton}
          onClick={() => setEditPermit(permit)}
          aria-label="Edit needed permit"
        >
          <Edit3 color="#0071CE" size={18} className="my-2" />
        </Button>
      </td>
    </tr>
  );
};

NeededPermitsListItem.propTypes = {
  permit: PropTypes.object.isRequired,
  setEditPermit: PropTypes.func.isRequired,
};

export default NeededPermitsListItem;
