import ProjectName from "../../ListView/ProjectName";

const name = {
  headerName: "Project Name",
  cellRenderer: ProjectName,
  field: "name",
  resizable: true,
  minWidth: 400,
  flex: 1,
};

export default name;
