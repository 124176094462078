import VendorToggler from "../addRFQColumns/VendorToggler";
import SelectAllToggler from "../addRFQColumns/SelectAllToggler";

const toggler = {
  field: "selected",
  headerName: "",
  width: 50,
  maxWidth: 50,
  suppressMenu: true,
  headerComponent: SelectAllToggler,
  cellRenderer: VendorToggler,
};

export default toggler;
