import PreBidMeeting from "../bidColumns/PreBidMeeting";
import dateTimeComparator from "../../../utils/dateTimeComparator";

const pre_bid_date = {
  headerName: "Pre Bid Meeting",
  field: "bid_todos",
  width: 172,
  comparator: dateTimeComparator,
  cellRenderer: PreBidMeeting,
};

export default pre_bid_date;
