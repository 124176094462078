import React from "react";
import { Pie, PieChart, Cell } from "recharts";
import styles from "./styles.module.scss";
import Dot from "./Dot";
import useDashboard from "./useDashboard";

const COLORS = ["#b2b6be", "#ea5644", "#1bc4a6"];

const StatusJobsChart = () => {
  const {
    aggregateStats: { project_status },
  } = useDashboard();
  if (!project_status) return null;

  const chartData = [
    { name: "Even", value: parseFloat(project_status.even) },
    { name: "Over", value: parseFloat(project_status.over) },
    { name: "Under", value: parseFloat(project_status.under) },
  ];
  return (
    <div>
      {!!chartData && (
        <figure
          className={`
            ${styles.chart}
            mt-4
          `}
        >
          <div className="d-flex align-items-center px-3 mb-5">
            <PieChart width={150} height={150}>
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={40}
                outerRadius={70}
                fill="#82ca9d"
                labelLine={false}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
            <div className={styles.total}>
              <div className={styles["total-count"]}>{project_status.total_jobs}</div>
              <div className={styles["total-label"]}>Active Jobs</div>
            </div>
            <figcaption className="ms-5">
              <dl className="mb-0">
                <div>
                  <dd className={`${styles.legendDD} ${styles.large}`} style={{ color: "#b2b6be" }}>
                    {project_status.even}
                  </dd>
                  <dt className={styles.legendDT}>Even</dt>
                </div>
                <div>
                  <dd className={`${styles.legendDD} ${styles.large}`} style={{ color: "#ea5644" }}>
                    {project_status.over}
                  </dd>
                  <dt className={styles.legendDT}>Over</dt>
                </div>
                <div>
                  <dd className={`${styles.legendDD} ${styles.large}`} style={{ color: "#1bc4a6" }}>
                    {project_status.under}
                  </dd>
                  <dt className={`${styles.legendDT} mb-0`}>Under</dt>
                </div>
              </dl>
            </figcaption>
          </div>
          <figcaption>
            <dl className={`${styles.legend} mt-3 ps-3`}>
              <div>
                <dt>
                  <Dot color={COLORS[0]} />
                </dt>
                <dd>Even</dd>
              </div>
              <div>
                <dt>
                  <Dot color={COLORS[1]} />
                </dt>
                <dd>Over</dd>
              </div>
              <div>
                <dt>
                  <Dot color={COLORS[2]} />
                </dt>
                <dd>Under</dd>
              </div>
            </dl>
          </figcaption>
        </figure>
      )}
    </div>
  );
};

export default StatusJobsChart;
