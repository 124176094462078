import React, { createContext, useContext } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

const GoogleMapsContext = createContext({ isLoaded: false });

export const GoogleMapsProvider = ({
  children,
  ...loadScriptOptions
}) => {
  const { isLoaded, loadError } = useJsApiLoader(loadScriptOptions);

  return (
    <GoogleMapsContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMaps = () => useContext(GoogleMapsContext);
