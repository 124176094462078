import React from "react";
import useProject from "../../../hooks/useProject";
import { ContactCard, ConfirmModal } from "component-library";
import styles from "../styles.module.scss";
import Alert from "../../../../components/Alert";
import ContactSubNav from "../../../../components/ContactSubNav";
import ContactMenuItems from "../../ContactMenuItems";
import EditTeamRole from "./EditTeamRole";
import useProjectTeam from "./useProjectTeam";
import EditContact from "../../../../components/Contacts/EditContactForm";

const ProjectTeam = () => {
  const { project } = useProject();
  const {
    contacts,
    editContactOpen,
    setEditContactOpen,
    editingContact,
    setEditingContact,
    editRoleOpen,
    setEditRoleOpen,
    confirmModalOpen,
    setConfirmModalOpen,
    showNotice,
    removeContact,
    removeError,
    removeLoading,
    setUpdatedContact,
  } = useProjectTeam();
  return (
    <>
      <div className={styles.sectionWrap}>
        <h2 className="xl text-gray2">Project Team</h2>
        {!!contacts && !!contacts.length && (
          <div className={styles.grid}>
            {!!contacts &&
              !!contacts.length &&
              contacts.map((contact) => (
                <div key={contact.id} className={`${styles.cardWrapper} position-relative`}>
                  <ContactCard cardTitle={contact.cardTitle} contact={contact} />
                  <ContactSubNav>
                    <ContactMenuItems
                      contact={contact}
                      canEdit={false}
                      setEditContactOpen={setEditContactOpen}
                      editContactOpen={editContactOpen}
                      setEditingContact={setEditingContact}
                      setEditRoleOpen={setEditRoleOpen}
                      setConfirmModalOpen={setConfirmModalOpen}
                      canRemove={true}
                    />
                  </ContactSubNav>
                </div>
              ))}
          </div>
        )}

        {!!showNotice && (
          <Alert
            title="You haven't assigned a Project Team"
            message="Add your team in the project settings"
            linkText="Review Now"
            linkTo={`/project/${project.id}/settings`}
          />
        )}
      </div>
      <EditTeamRole editRoleOpen={editRoleOpen} contact={editingContact} setEditingRole={setEditRoleOpen} />
      {!!editContactOpen && (
        <EditContact
          contact={editingContact}
          setAddingContact={() => setEditContactOpen(false)}
          setEditedContact={setUpdatedContact}
        />
      )}
      {!!confirmModalOpen && (
        <ConfirmModal
          showConfirmation={confirmModalOpen}
          setShowConfirmation={setConfirmModalOpen}
          confirmFunction={() => {
            removeContact(editingContact);
          }}
          loading={removeLoading}
          error={removeError}
          name="Remove Project Contact"
          message={`Are you sure you want to remove ${editingContact.first_name} ${editingContact.last_name} from the project?`}
        />
      )}
    </>
  );
};

export default ProjectTeam;
