import React from "react";
import styles from "./styles.module.scss";
import Icons from "./Icons";

const Module = ({ module, handleToggle }) => {
  return (
    <>
      <div className={styles.moduleWrapper}>
        <div className="d-flex align-items-center">
          <Icons label={module.label} />
          <h2 className={`${styles.label} mb-0`}>{module.label}</h2>
        </div>

        <div className={`toggle form-group form-check`}>
          <input
            type="checkbox"
            className="form-check-input"
            name={module.label}
            id={module.key}
            onChange={(e) => handleToggle(e.target.value, module)}
            value={module.active}
            checked={module.active}
            aria-label="toggle active status"
          />
          <label className="form-check-label sm" htmlFor={module.key}></label>
        </div>
      </div>
    </>
  );
};

export default Module;
