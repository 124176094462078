import Status from "../rfqColumns/Status";

const status = {
  headerName: "Status",
  field: "status",
  width: 112,
  valueGetter: ({ data }) => (data.status?.id ? data.status.id : null),
  cellRenderer: Status,
};

export default status;
