import React from "react";
import convertDecimalHours from "../../../utils/convertDecimalHours";

const Hours = ({ data }) => {

  return (
    <div style={parseFloat(data.hours) > 12 ? { color: "#E70000" } : null}>
      {data.hours ? convertDecimalHours(data.hours) : "0:00"}
    </div>
  );
};

export default Hours;
