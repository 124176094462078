import React from "react";
import InteriorPage from "../../components/pageLayout/InteriorPage";
import { PlusCircle, Printer, Users } from "react-feather";
import Submenu from "../Submenu";
import { AgGridMasterDetail, HeaderButton, Loader, useAuth } from "component-library";
import gridRowHeights from "../../utils/gridRowHeights";
import noteAdminGridConfig from "./noteAdminGridConfig";
import useDetailCellConfig from "./noteDetailConfig";
import useNotesAdmin from "./context/useNotesAdmin";
import ProjectNote from "../../components/ProjectNote/ProjectNote";
import styles from "./styles.module.scss";
import NotesAdminFilters from "./NotesAdminFilters";
import Pagination from "../../components/Pagination";
import AddNoteModal from "./AddNoteModal";
import TwoWeekMessage from "../../_projects/project/FieldManagement/FieldLogs/TwoWeekMessage";
import { Alert } from "react-bootstrap";
import useResourceManagement from "../context/useResourceManagement";

const FieldNotesAdmin = () => {
  const {
    notesToPrint,
    notes,
    notesError,
    notesLoading,
    refreshColumns,
    setRefreshColumns,
    expandAll,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    viewAddNoteModal,
    setViewAddNoteModal,
    noteIncludeInReportLoading,
    noteIncludeInReportError,
    totalResults,
    updateDetailId,
    setUpdateDetailId,
    message,
    setMessage,
    usersError,
  } = useNotesAdmin();

  const { dateRange } = useResourceManagement();

  const cellConfig = useDetailCellConfig();
  const { user } = useAuth();

  const checkDisablePrint = () => {
    let disable;
    if (!notesToPrint?.length || !!notesLoading || noteIncludeInReportLoading) {
      disable = true;
    } else {
      disable = false;
    }

    return disable;
  };

  const ActionButtons = () => (
    <div className="d-flex align-items-center">
      <HeaderButton
        text="Print Selected"
        onClick={() => window.print()}
        icon={<Printer size={16} color={checkDisablePrint() ? "#7b7b7b" : "#0071ce"} />}
        disabled={checkDisablePrint()}
      />
      <HeaderButton
        text="Add Note"
        onClick={() => setViewAddNoteModal(true)}
        icon={<PlusCircle size={16} color="#0071ce" />}
      />
    </div>
  );

  return (
    <div className={`${styles.notesTable} notes-admin-table`}>
      <InteriorPage
        navTitle={"Field Management"}
        navIcon={<Users color="#fff" size={14} />}
        pageTitle="Field Notes"
        pageTitleSecondaryContent={() => <ActionButtons />}
        pageSubtitlePrimaryContent={() => <Submenu />}
        error={notesError || noteIncludeInReportError}
      >
        <div className="hiddenPrint position-relative flex-1">
          <Loader loading={!!notesLoading || !!noteIncludeInReportLoading} size={16} />
          <TwoWeekMessage dateRange={dateRange} />
          {!!message && (
            <Alert onClick={() => setMessage()} variant="success" dismissible>
              {message}
            </Alert>
          )}
          {!!usersError && (
            <Alert onClick={() => setMessage()} variant="danger">
              {usersError}
            </Alert>
          )}
          <div className="mt-3 mb-3">
            <NotesAdminFilters />
          </div>
          <AgGridMasterDetail
            gridData={notes}
            gridConfig={noteAdminGridConfig}
            id={`${user.id}_note_admin_listing`}
            rowHeight={gridRowHeights.medium}
            detailCellConfig={cellConfig}
            getDetailRowData={(params) => {
              params.successCallback(params?.data?.note);
            }}
            presetStyle="boxGroupTable"
            refreshColumns={refreshColumns}
            setRefreshColumns={setRefreshColumns}
            expandAll={expandAll}
            updateDetailId={updateDetailId}
            setUpdateDetailId={setUpdateDetailId}
            fallbackMessage={"No notes found."}
          />

          <Pagination
            items={notes}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            total={totalResults || 0}
          />
        </div>
        {!!notesToPrint?.length && (
          <div className="showPrint">
            {notesToPrint.map((note) => {
              return (
                <div key={note.id}>
                  <ProjectNote
                    project={{ name: note?.project?.name, job_number: note?.project?.job_number, id: note.project_id }}
                    note={note}
                  />
                </div>
              );
            })}
          </div>
        )}
      </InteriorPage>
      <AddNoteModal showModal={viewAddNoteModal} onHide={() => setViewAddNoteModal(false)} date={new Date()} />
    </div>
  );
};

export default FieldNotesAdmin;
