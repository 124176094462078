import React from "react";
import SubmittalSidebar from "../../../SubmittalSidebar";
import EditContacts from "../../../Contacts/EditContacts";
import useSubmittalFunctions from "../../../hooks/useSubmittalFunctions";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import { Briefcase } from "react-feather";
import { Navigate } from "react-router-dom";
import { Submittal as S, ConfirmModal, useAuth } from "component-library";
import useProjectManagerSubmittal from "./useProjectManagerSubmittal";

const ProjectManagerSubmittal = () => {
  const { config, userCan, user } = useAuth();

  const {
    loading,
    error,
    pageTitle,
    submittal,
    projectOwner,
    setSubmittal,
    updateRequest,
    setProject,
    project,
    PageTag,
    attachmentsLoading,
    setAttachmentsLoading,
  } = useSubmittalFunctions();

  const { ActionButtons, redirect, confirmDelete, setConfirmDelete, handleDelete, deleteLoading, deleteError } =
    useProjectManagerSubmittal({ submittal, attachmentsLoading, project, setProject });

  return (
    <InteriorPage
      loading={loading}
      error={error}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle={"Submittals"}
      pageSubtitlePrimaryContent={() => <div className="lg font-weight-bold mb-0">{submittal.name}</div>}
      pageSubtitleSecondaryContent={ActionButtons}
      NavContent={PageTag}
    >
      {!!redirect && <Navigate to={`/project/${submittal.project_id}/submittals`} />}

      <div className="row mb-4">
        <div className="col-lg-9">
          <div>
            <div>
              <S.SubmittalSpecPlans submittal={submittal} />
            </div>
            {!!submittal.description && (
              <div className="border-bottom mb-3">
                <h5 className="pt-3 med">Description</h5>
                <p className="med">{submittal.description}</p>
              </div>
            )}

            <div className="border-bottom mb-3 pb-4">
              <h5 className="pt-3 med">Contacts</h5>
              <EditContacts
                projectOwner={projectOwner}
                contacts={submittal.contacts}
                source={submittal.source}
                updateContent={setSubmittal}
                submittal={submittal}
              />
            </div>

            <div className="col-xxl-9 col-12">
              <div className="border-bottom mb-3 pb-4">
                <h5 className="pt-3 mb-3 med">Attachments</h5>
                <S.EditAttachments
                  updateContent={setSubmittal}
                  submittal={submittal}
                  multiType
                  config={config}
                  userCan={userCan}
                  userId={user.id}
                  loading={attachmentsLoading}
                  setLoading={setAttachmentsLoading}
                />
              </div>

              <div className="border-bottom mb-3 pb-4">
                {submittal.requirements && (
                  <S.SubmittalRequirements
                    submittal={submittal}
                    requirements={submittal.requirements}
                    setSubmittal={setSubmittal}
                    config={config}
                    userCan={userCan}
                    userId={user.id}
                    multiType
                    loading={attachmentsLoading}
                    setLoading={setAttachmentsLoading}
                  />
                )}
              </div>
              <h5 className="pt-3 med">History</h5>
              <S.SubmittalHistory submittal={submittal} setSubmittal={setSubmittal} userCan={userCan} />
            </div>
          </div>
        </div>
        <div className="col-lg-3 submittal-sidebar order-first order-lg-last ps-lg-4">
          {!!Object.entries(submittal).length && (
            <SubmittalSidebar
              submittal={submittal}
              setSubmittal={(submittal) => {
                setSubmittal(submittal);
                updateRequest(submittal);
              }}
            />
          )}
        </div>
      </div>

      <ConfirmModal
        showConfirmation={confirmDelete}
        setShowConfirmation={setConfirmDelete}
        confirmFunction={() => {
          handleDelete();
        }}
        loading={deleteLoading}
        error={deleteError}
        name={"Confirm Delete Submittal"}
        message={"Are you sure you want to delete this submittal?"}
      />
    </InteriorPage>
  );
};

export default ProjectManagerSubmittal;
