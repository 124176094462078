import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { initialData } from "../../../schema/submittal";
import useProject from "../../../_projects/hooks/useProject";

const useQuickAddSubmittals = () => {
  const { id } = useParams();
  const { project } = useProject();
  const [counter, setCounter] = useState(1);
  const [projectOwner, setProjectOwner] = useState();
  const [projectOwnerOptions, setProjectOwnerOptions] = useState([]);
  const [poError, setPoError] = useState();
  const [submittals, setSubmittals] = useState([]);

  const addSubmittal = (submittal, _id) => {
    const newSubmittals = submittals.map((sub) => (sub._id === _id ? { ...sub, submittal: submittal } : sub));
    newSubmittals.push({
      _id: counter,
      submittal: { ...initialData, project_id: id },
    });
    setCounter(counter + 1);
    setSubmittals(newSubmittals);
  };

  const handleSelectPo = (selected) => {
    setProjectOwner(selected.value);
    if (selected) setPoError();
  };

  const updateSubmittal = (submittal) => {
    const updatedSubmittals = submittals.map((sub) =>
      sub.submittal.id === submittal.id ? { ...sub, submittal: submittal } : sub
    );
    setSubmittals(updatedSubmittals);
  };

  useEffect(() => {
    setSubmittals([{ _id: counter, submittal: { ...initialData, project_id: id } }]);
    setCounter(counter + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!project?.project_contacts?.length) {
      const engineers = project.project_contacts.filter((po) => po.role.name === "Engineer");
      setProjectOwnerOptions(
        engineers.map((po) => {
          return {
            label: `${po.contact.first_name} ${po.contact.last_name}`,
            value: po.contact.id,
          };
        })
      );
      setProjectOwner(project.project_contacts[0].contact.id);
    }
  }, [project]);
  return {
    addSubmittal,
    projectOwnerOptions,
    handleSelectPo,
    updateSubmittal,
    projectOwner,
    poError,
    submittals,
    id,
    setPoError,
  };
};

export default useQuickAddSubmittals;
