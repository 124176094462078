import React from 'react';
import styles from "./styles.module.scss";

const Type = ({ data }) => {
  return (
    <div className={styles.typeWrap}>
      <div
        className={`${styles.type} ${
          data.type === "Field Log" ? styles.fieldLogType : ""
        }`}
      >
        {data.type}
      </div>
    </div>
  );
}

export default Type;