/* global google */
import { useEffect, useState } from "react";
import { useApi, bidApi, geofenceApi } from "component-library";
import { useGoogleMaps } from "../components/GoogleMapsProvider";
let selection = null;

const useGeofence = ({ project, refreshProject, poi }) => {
  const {
    loading: updateGeofenceLoading,
    request: updateGeofenceRequest,
    error: updateGeofenceError,
    data: updateGeofenceData,
  } = useApi(bidApi.saveGeofence);

  const {
    request: geofencePOIRequest,
    data: geofencePOIData,
    loading: geofencePOILoading,
    error: geofencePOIError,
  } = useApi(geofenceApi.savePOIGeofence);

  const [initialLoad, setInitialLoad] = useState(true);
  const [initialProject, setInitialProject] = useState({});
  const [initialPoi, setInitialPoi] = useState({});
  const [polygons, setPolygons] = useState([]);
  const [geoFence, setGeoFence] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState({});
  const [map, setMap] = useState();
  const [newRegionName, setNewRegionName] = useState();
  const [drawingManager, setDrawingManager] = useState();
  const [saveModal, setSaveModal] = useState(false);
  const [storedShape, setStoredShape] = useState({});
  const [shapeChanged, setShapeChanged] = useState(false);
  const { isLoaded } = useGoogleMaps();
  const [selectedMarker, setSelectedMarker] = useState();

  const polyOptions = {
    strokeWeight: 1,
    fillOpacity: 0.45,
    editable: true,
  };

  const onLoadMap = (gmap) => {
    setMap(gmap);
  };

  const saveCurrentFences = () => {
    const fences = geoFence.map((fence) => {
      const matchingPolygon = polygons.find((p) => p.id === fence.polyId);

      const path = fence.getPath();
      const polygon = [];
      for (let i = 0; i < path.getLength(); i++) {
        const xy = path.getAt(i);
        polygon.push({ lat: xy.lat(), lng: xy.lng() });
      }

      const p = {
        polygon: !!matchingPolygon ? matchingPolygon.polygon : polygon,
        color: fence.fillColor,
        name: fence.name,
        polyId: fence.polyId,
      };

      if (matchingPolygon && !!matchingPolygon.id) {
        p.id = matchingPolygon.id;
      }

      return p;
    });
    if (!!project) {
      updateGeofenceRequest(project.id, fences);
    }
    if (!!poi?.active) {
      geofencePOIRequest({ poi_id: poi.id, geofences: fences });
    }
  };

  const setSelected = (shape, polyId) => {
    if (selection) {
      selection.setEditable(false);
    }

    setSelectedPolygon(polygons.find((poly) => polyId === poly.id));

    shape.setEditable(true);
    shape.polyId = polyId;
    selection = shape;
  };

  const drawPolygons = (zoomedPolygon) => {
    if (!!polygons && !!polygons.length) {
      let bounds = new google.maps.LatLngBounds();
      let zoomBounds = new google.maps.LatLngBounds();
      let shapes = [];
      polygons.forEach((poly) => {
        if (poly.polygon) {
          poly.polygon.forEach((latLong) => {
            const ll = new google.maps.LatLng(latLong.lat, latLong.lng);
            bounds.extend(ll);
          });
          if (poly.id === zoomedPolygon?.id) {
            poly.polygon.forEach((latLong) => {
              const llz = new google.maps.LatLng(latLong.lat, latLong.lng);
              zoomBounds.extend(llz);
            });
          }
          const shape = new google.maps.Polygon({
            paths: poly.polygon.map((latLong) => {
              return {
                lat: parseFloat(latLong.lat),
                lng: parseFloat(latLong.lng),
              };
            }),
            ...polyOptions,
            fillColor: poly.color,
            editable: false,
            polyId: poly.id,
            name: poly.name,
          });
          shape.setMap(map);
          shape.addListener("click", () => {
            setSelected(shape, poly.id);
          });
          shape.addListener("mouseup", () => {
            checkShapeChange(shape, poly.id);
          });
          shapes.push(shape);
        }
      });

      setGeoFence(shapes);
      if (zoomedPolygon) {
        map?.fitBounds(zoomBounds);
      } else {
        map?.fitBounds(bounds);
      }
    }
  };

  function getPaths(polygon) {
    var polygonBounds = polygon.getPath();
    var bounds = [];
    for (var i = 0; i < polygonBounds.length; i++) {
      var point = {
        lat: polygonBounds.getAt(i).lat(),
        lng: polygonBounds.getAt(i).lng(),
      };
      bounds.push(point);
    }
    return bounds;
  }

  const checkShapeChange = (shape, polyId) => {
    const shapeCoords = getPaths(shape);
    const matchingPolygon = polygons.find((poly) => poly.id === polyId);
    setStoredShape({
      coords: shapeCoords,
      polyId: polyId,
    });
    if (matchingPolygon.polygon.length !== shapeCoords.length) {
      setShapeChanged(true);
    } else {
      shapeCoords.forEach((newCoords, index) => {
        if (
          newCoords.lat !== parseFloat(matchingPolygon.polygon[index].lat) ||
          newCoords.lng !== parseFloat(matchingPolygon.polygon[index].lng)
        ) {
          setShapeChanged(true);
        }
      });
    }
  };

  const onPolygonComplete = (shape) => {
    const polyId = Date.now();
    shape.polyId = polyId;

    setSaveModal(true);
    setGeoFence([...geoFence, shape]);
    shape.addListener("click", () => setSelected(shape));
    shape.addListener("mouseup", () => {
      checkShapeChange(shape, polyId);
    });
    setSelected(shape, polyId);
    setNewRegionName();
    drawingManager.setDrawingMode(null);
  };

  const onCancelPolygon = () => {
    geoFence.map((fence) => fence.setMap(null));
    setGeoFence([]);
    setSelectedPolygon({});
    drawPolygons();
    selection = null;
    setSaveModal(false);
  };

  const savePolygonComplete = () => {
    if (selection) {
      const updatedFences = geoFence.map((fence) => {
        if (selection.polyId === fence.polyId) {
          fence.name = !!newRegionName ? newRegionName : `project site ${geoFence.length}`;
          fence.fillColor = "#0071ce";
        }
        return fence;
      });

      geoFence.map((fence) => fence.setMap(null));
      setGeoFence(updatedFences);
      saveCurrentFences();
    }
  };

  const focusPolygon = (polygon) => {
    let bounds = new google.maps.LatLngBounds();
    polygon.polygon.forEach((latLong) => {
      const ll = new google.maps.LatLng(latLong.lat, latLong.lng);
      bounds.extend(ll);
    });
    map?.fitBounds(bounds);
  };

  const getSelectedCenter = () => {
    var bounds = new google.maps.LatLngBounds();
    const paths = selectedPolygon.polygon.map((latLong) => {
      return { lat: parseFloat(latLong.lat), lng: parseFloat(latLong.lng) };
    });
    for (let i = 0; i < paths.length; i++) {
      bounds.extend(paths[i]);
    }
    return bounds.getCenter();
  };

  const clearSelection = () => {
    if (!!selection) {
      selection.setEditable(false);
      setSelectedPolygon({});
    }
    selection = null;
  };

  const onClickMap = () => {
    clearSelection();
    setSelectedMarker();
  };

  const onLoadDrawingManager = (manager) => {
    setDrawingManager(manager);
    drawPolygons();
  };

  useEffect(() => {
    if (!!project && !!initialLoad) {
      setInitialProject(project);
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (!!poi && !!initialLoad) {
      setInitialPoi(poi);
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poi]);

  useEffect(() => {
    if (!!shapeChanged && !!storedShape) {
      const updatedPolygons = polygons.map((poly) => {
        if (poly.id === storedShape.polyId) {
          return {
            ...poly,
            id: storedShape.polyId,
            polygon: storedShape.coords,
          };
        } else {
          return poly;
        }
      });
      if (!!project) {
        updateGeofenceRequest(project.id, updatedPolygons);
      }
      if (!!poi?.active) {
        geofencePOIRequest({ poi_id: poi.id, geofences: updatedPolygons });
      }
      setStoredShape({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shapeChanged]);

  useEffect(() => {
    if (initialProject?.geofence?.length) {
      setPolygons(initialProject.geofence);
    }
  }, [initialProject]);

  useEffect(() => {
    if (initialPoi?.geofence?.length) {
      setPolygons(initialPoi.geofence);
    }
  }, [initialPoi]);

  useEffect(() => {
    if (!!updateGeofenceData) {
      refreshProject();
      if (!!updateGeofenceData?.geofences) {
        setInitialProject({ ...initialProject, geofence: updateGeofenceData.geofences });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateGeofenceData]);

  useEffect(() => {
    if (!!geofencePOIData) {
      if (!!geofencePOIData?.poi) {
        setInitialPoi({ ...initialPoi, geofence: geofencePOIData.poi });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geofencePOIData]);

  useEffect(() => {
    if (!!initialProject || !!initialPoi) {
      geoFence.map((fence) => fence.setMap(null));
      setGeoFence([]);
      if (polygons && polygons.length) {
        if (shapeChanged) {
          drawPolygons(selectedPolygon);
          setShapeChanged(false);
        } else {
          drawPolygons();
        }
      }
      setSelectedPolygon({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygons]);

  return {
    map,
    project,
    polygons,
    setPolygons,
    updateGeofenceLoading,
    updateGeofenceRequest,
    updateGeofenceError,
    updateGeofenceData,
    saveCurrentFences,
    onLoadMap,
    selectedPolygon,
    setSelectedPolygon,
    isLoaded,
    polyOptions,
    onPolygonComplete,
    savePolygonComplete,
    newRegionName,
    setNewRegionName,
    setSaveModal,
    saveModal,
    onClickMap,
    focusPolygon,
    getSelectedCenter,
    onLoadDrawingManager,
    selectedMarker,
    setSelectedMarker,
    onCancelPolygon,
    geofencePOIRequest,
    geofencePOIData,
    geofencePOILoading,
    geofencePOIError,
  };
};

export default useGeofence;
