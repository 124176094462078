import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useApi, timesheetApi } from "component-library";
import { DateTime } from "luxon";

const useUpdateTimesheet = ({
  timesheet,
  setTimesheet,
  overAllocatedTimeSheets,
  setOverAllocatedTimeSheets,
  logDate,
}) => {
  const [timeIn, setTimeIn] = useState(!!timesheet?.time_in ? Date.parse(timesheet.time_in) : null);
  const [timeOut, setTimeOut] = useState(!!timesheet?.time_out ? Date.parse(timesheet.time_out) : null);
  const { request: updateTimesheetRequest, loading, error: updateError } = useApi(timesheetApi.updateTimeSheet);

  const fixDate = (time) => {
    const date = new Date(time);
    const formattedDate = DateTime.fromISO(logDate).toFormat(`yyyy-MM-dd`);
    const formattedTime = DateTime.fromJSDate(date).toFormat(`HH:mm:ss`);
    const fixedDate = `${formattedDate} ${formattedTime}`;
    return fixedDate;
  };

  const updateTimesheet = async (updatedTimesheet) => {
    const { responseData, success } = await updateTimesheetRequest(updatedTimesheet);
    if (success) {
      const newTimesheet = responseData.time_sheet.find((ts) => ts.time_sheet.id === updatedTimesheet.id);
      if (responseData.time_sheet[0].over_allocated) {
        setOverAllocatedTimeSheets([...overAllocatedTimeSheets, updatedTimesheet.id]);
      } else {
        setOverAllocatedTimeSheets(overAllocatedTimeSheets.filter((ts) => ts !== updatedTimesheet.id));
      }
      if (!!newTimesheet && !!newTimesheet.time_sheet) {
        setTimesheet(newTimesheet.time_sheet);
      }
    }
  };

  const updateTimeIn = (time) => {
    if (!!timeOut && !!time) {
      updateTimesheet({
        ...timesheet,
        time_in: fixDate(time),
        time_out: fixDate(timeOut),
      });
    }
  };

  const updateTimeOut = (time) => {
    if (!!timeIn && !!time) {
      updateTimesheet({
        ...timesheet,
        time_in: fixDate(timeIn),
        time_out: fixDate(time),
      });
    }
  };

  useEffect(() => {
    if (timesheet || !!updateError) {
      setTimeIn(!!timesheet?.time_in ? Date.parse(timesheet.time_in) : null);
      setTimeOut(!!timesheet?.time_out ? Date.parse(timesheet.time_out) : null);
    }
  }, [timesheet, updateError]);

  return {
    timeIn,
    setTimeIn,
    timeOut,
    setTimeOut,
    updateTimesheet,
    updateTimeIn,
    updateTimeOut,
    loading,
    updateError,
  };
};

useUpdateTimesheet.propTypes = {
  timesheet: PropTypes.object.isRequired,
  setTimesheet: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  projectsList: PropTypes.array.isRequired,
};

export default useUpdateTimesheet;
