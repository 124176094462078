const usePhotos = ({ dispatch }) => {
  const handleToggleUpdatePhotosModal = () => {
    dispatch({ type: "toggle_update_photos_modal" });
  };

  return {
    handleToggleUpdatePhotosModal,
  };
};

export default usePhotos;
