import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { initialData, schema } from "../../../../schema/permit";
import { useFormControl, useApi, projectApi, dropdownStyles } from "component-library";
import useProject from "../../../../_projects/hooks/useProject";
import ContactSearch from "../../../Contacts/ContactSearch";
import EditContactForm from "../../../Contacts/EditContactForm";
import { ProjectAttachment } from "../../../../_projects/ProjectAttachments";
import PropTypes from "prop-types";
import styles from "../../styles.module.scss";

const useAddPermit = (close, update, permit, userName, setDocLoading) => {
  const [error, setError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const { project } = useProject();
  const { request, loading } = useApi(projectApi.savePermit);
  const [contact, setContact] = useState(permit ? permit.issuing_contact : null);
  const [addNew, setAddNew] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [attachment, setAttachment] = useState(!!permit && !!permit.file ? permit.file : null);
  const [fileId, setFileId] = useState(!!permit ? permit.file_id : null);
  const [maxDate, setMaxDate] = useState();
  const [minDate, setMinDate] = useState();

  const onSubmit = async (formData, setLoading) => {
    setError(false);
    setContactError(false);

    try {
      if (!!contact) {
        const requestData = {
          id: permit ? permit.id : null,
          cost: formData.cost,
          project_id: project.id,
          user: userName,
          status: formData.status,
          description: formData.description,
          issuing_contact_id: contact.id,
        };
        const permitData =
          !!formData.status && formData.status === "needed"
            ? {
                ...requestData,
                needed_by: formData.needed_by,
              }
            : {
                ...requestData,
                number: formData.number,
                issue_date: formData.issue_date,
                expiration: formData.expiration,

                file_id: fileId || null,
              };
        const { error, success } = await request(permitData);
        if (success) {
          update();
          close();
        } else {
          setError(error);
        }
      } else {
        setContactError(true);
        throw new Error("Contact");
      }
    } catch (error) {
      return Promise.reject(error);
    }
    setLoading(false);
  };

  const { handleSubmit, renderSubmitButton, renderTextField, renderSelectField, data } = useFormControl({
    schema,
    onSubmit,
    scrollToTop: false,
    initialData: !!permit ? permit : initialData,
  });

  useEffect(() => {
    if (!!attachments.length) {
      setAttachment(attachments.find((a) => a.id === fileId));
    } else {
      setAttachment(null);
    }
  }, [fileId, attachments]);

  useEffect(() => {
    if (!!contact && contactError) {
      setContactError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  useEffect(() => {
    if (data.expiration) {
      let date = new Date(data.expiration);
      setMaxDate(date.setDate(date.getDate() - 1));
    } else {
      setMaxDate();
    }
  }, [data.expiration]);

  useEffect(() => {
    if (data.issue_date) {
      let date = new Date(data.issue_date);
      setMinDate(date.setDate(date.getDate() + 1));
    } else {
      setMinDate();
    }
  }, [data.issue_date]);

  const fields = (
    <>
      {!!addNew && <EditContactForm setAddingContact={() => setAddNew(false)} setEditedContact={setContact} />}
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="row">
        <div className="col-md-8">
          {!!permit ? (
            <>
              {!!!permit.number && (
                <>
                  {renderTextField({
                    name: "number",
                    label: "Permit #",
                  })}
                </>
              )}
              <div className="pb-2">
                <div className="label">Permit Description</div>
                <div className={styles.modalPermitDescription}>{permit.description}</div>
              </div>
              <div className="pb-2">
                <div className="label">Issuing Body</div>
                <div>
                  {!!permit.issuing_contact && (
                    <>{`${permit.issuing_contact.first_name} ${permit.issuing_contact.last_name}`}</>
                  )}
                </div>
              </div>
              <div className="pb-2">
                <div className="label">Cost</div>
                <div>
                  $
                  {parseFloat(permit.cost).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </div>
              </div>
              <ProjectAttachment
                attachment={attachment}
                setAttachment={setAttachments}
                type="Permit"
                projectId={project.id}
                canUpload={true}
                setFileId={setFileId}
                setDocLoading={setDocLoading}
                isBid={project?.status === "bid"}
              />
            </>
          ) : (
            <>
              {data.status !== "needed" && (
                <>
                  {renderTextField({
                    name: "number",
                    label: "Permit #",
                  })}
                </>
              )}
              {renderTextField({
                name: "description",
                label: "Permit Description",
              })}
              <ContactSearch
                setContact={setContact}
                setAddNew={setAddNew}
                contact={contact}
                placeholder="Add Contact"
                label="Issuing Body"
                error={contactError}
                colDisplay={true}
              />
              {renderTextField({
                name: "cost",
                label: "Cost",
                format: "currency",
              })}
            </>
          )}
        </div>
        <div className="col-md-4">
          {renderSelectField({
            name: "status",
            label: "Status",
            styles: dropdownStyles,
            options: [
              { value: "active", label: "Active" },
              { value: "closed", label: "Closed" },
              { value: "expired", label: "Expired" },
              { value: "needed", label: "Needed" },
            ],
          })}
          {data.status !== "needed" && (
            <>
              {renderTextField({
                name: "issue_date",
                label: "Issue Date",
                type: "date",
                maxDate: maxDate,
              })}
              {renderTextField({
                name: "expiration",
                label: "Expiration Date",
                type: "date",
                minDate: minDate,
              })}
            </>
          )}

          {data.status === "needed" && (
            <>
              {renderTextField({
                name: "needed_by",
                label: "Needed By",
                type: "date",
              })}
            </>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {!!permit ? (
            <>Permit History</>
          ) : (
            <>
              {data.status !== "needed" && (
                <ProjectAttachment
                  attachment={attachment}
                  setAttachment={setAttachments}
                  type="Permit"
                  projectId={project.id}
                  canUpload={true}
                  setFileId={setFileId}
                  setDocLoading={setDocLoading}
                  isBid={project?.status === "bid"}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );

  return {
    fields,
    handleSubmit,
    renderSubmitButton,
    loading,
  };
};

useAddPermit.propTypes = {
  close: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  permit: PropTypes.object,
};

export default useAddPermit;
