import { DateTime } from "luxon";

const time_of_reading = {
  headerName: "Time",
  field: "time_of_reading",
  sort: "desc",
  valueGetter: ({ data }) => data.time_of_reading,
  valueFormatter: ({ data }) =>
    data.time_of_reading ? `${DateTime.fromSQL(data.time_of_reading).toFormat("hh:mm a")}` : null,
};

export default time_of_reading;
