import React from 'react'
import ForemanInput from '../ForemanInput/ForemanInput'
import styles from "./styles.module.scss";
import convertMinutesToTime from '../../../../../../../utils/convertMinutesToTime';
import { Avatar } from 'component-library';

const DailyUserHeader = ({ user, foreman, timesheets, selectedForemanIds, handleForemanToggle }) => {

  const getTotalTime = (timesheets) => {
    let totalMinutes = 0;
    timesheets.forEach(timesheet => {
      totalMinutes += parseInt(timesheet.total_minutes, 10);
    });
    return convertMinutesToTime(totalMinutes);
  }

  const Name = () => (
    <div className={styles.name}>{user?.first_name || foreman?.foreman?.first_name} {user?.last_name || foreman?.foreman?.last_name}</div>
  )

  return (
    <div className={styles.headerContent}>
      {!!foreman ? (
        <ForemanInput
          foreman={foreman}
          selectedForemanIds={selectedForemanIds}
          handleForemanToggle={handleForemanToggle}
          name={<Name />}
        />
      ) : (
        <div className="d-flex align-items-center">
          <Avatar user={user} />
          <Name />
        </div>
      )}
      {timesheets?.length > 0 && (
        <div className={styles.hours}>{getTotalTime(timesheets)}</div>
      )}
    </div>
  )
}

export default DailyUserHeader