import { DateTime } from "luxon";
import { useState, useEffect } from "react";
import { useApi, photoLibraryApi } from "component-library";

const useCurrentView = ({ project, combinedList, currentView, handlePin, refreshLists, selectedItems }) => {
  const [isPhoto, setIsPhoto] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [title, setTitle] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [attachedTo, setAttachedTo] = useState(0);
  const [photoCount, setPhotoCount] = useState("");
  const [viewUrl, setViewUrl] = useState("");
  const [isPinned, setIsPinned] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selected, setSelected] = useState(false);
  const [storeItem, setStoreItem] = useState(null);

  const {
    request: updatePhotoDetailRequest,
    data: updatePhotoDetailData,
    loading: updatePhotoDetailLoading,
  } = useApi(photoLibraryApi.updatePhotoDetails);

  const {
    request: deletePhotoRequest,
    data: deletePhotoData,
    loading: deletePhotoLoading,
    error: deletePhotoError,
  } = useApi(photoLibraryApi.deletePhoto);

  const { request: pinAlbumRequest, data: pinAlbumData, loading: pinAlbumLoading } = useApi(photoLibraryApi.pinAlbum);

  const {
    request: deleteAlbumRequest,
    data: deleteAlbumData,
    loading: deleteAlbumLoading,
    error: deleteAlbumError,
  } = useApi(photoLibraryApi.deleteAlbum);

  const isSelected = (storedItems, data) =>
    storedItems.some((storedItem) =>
      data.photo_count === undefined
        ? storedItem.type === "photo" && storedItem.id === data.id
        : storedItem.type === "album" && storedItem.id === data.id
    );

  useEffect(() => {
    if (updatePhotoDetailData?.photo) {
      handlePin(updatePhotoDetailData?.photo.id, "photo");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePhotoDetailData]);

  useEffect(() => {
    if (deletePhotoData?.success) {
      refreshLists();
    }
    setShowConfirmDeleteModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePhotoData]);

  useEffect(() => {
    if (pinAlbumData?.photos) {
      handlePin(pinAlbumData?.photos.id, "album");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinAlbumData]);

  useEffect(() => {
    if (deleteAlbumData?.success) {
      refreshLists();
    }
    setShowConfirmDeleteModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAlbumData]);

  useEffect(() => {
    if (combinedList.length > 0 && currentView >= 0 && currentView < combinedList.length) {
      const curData = combinedList[currentView];
      const _isPhoto = curData.photo_count === undefined;

      if (curData) {
        setIsPhoto(_isPhoto);
        setImgUrl(_isPhoto ? curData.url : curData.first_photo?.url);
        setTitle(`${_isPhoto ? `${curData.user?.first_name} ${curData.user?.last_name}` : curData.name}`);
        setTimestamp(
          _isPhoto
            ? `Uploaded ${displayDateTime(curData.created_at)}`
            : `Updated ${displayDateTime(curData.updated_at)}`
        );
        setAttachedTo(_isPhoto ? (curData.albums || []).length : 0);
        setPhotoCount(_isPhoto ? "" : `${curData.photo_count} Photo(s)`);
        setViewUrl(
          _isPhoto
            ? `/project/${project?.id}/photo-library/photo/${curData.id}`
            : `/project/${project?.id}/photo-library/album/${curData.id}`
        );
        setIsPinned(curData.pinned);
        if (selectedItems?.length) {
          setSelected(isSelected(selectedItems, curData));
        } else {
          setSelected(false);
        }
        setStoreItem({ type: _isPhoto ? "photo" : "album", id: curData.id, pinned: curData.pinned });
      }
    }
  }, [combinedList, currentView, project?.id, selectedItems]);

  const displayDateTime = (date) => {
    let formattedDate = DateTime.fromJSDate(new Date(date)).toFormat("MM/dd/yyyy hh:mm a");
    return formattedDate;
  };

  const updatePin = () => {
    if (combinedList.length > 0 && currentView >= 0 && currentView < combinedList.length) {
      const curData = combinedList[currentView];
      const _isPhoto = curData.photo_count === undefined;
      if (_isPhoto) {
        updatePhotoDetailRequest({ photo_id: curData.id, pinned: !curData.pinned });
      } else {
        pinAlbumRequest({ projectId: project.id, albumId: curData.id, pinned: !curData.pinned });
      }
    }
  };

  const deleteAsset = () => {
    if (combinedList.length > 0 && currentView >= 0 && currentView < combinedList.length) {
      const curData = combinedList[currentView];
      const _isPhoto = curData.photo_count === undefined;
      if (_isPhoto) {
        deletePhotoRequest(curData.id);
      } else {
        deleteAlbumRequest(curData.id);
      }
    }
  };

  return {
    isPhoto,
    imgUrl,
    title,
    timestamp,
    attachedTo,
    photoCount,
    viewUrl,
    isPinned,
    updatePin,
    updatePhotoDetailLoading,
    pinAlbumLoading,
    deleteAsset,
    deletePhotoLoading,
    deletePhotoError,
    deleteAlbumLoading,
    deleteAlbumError,
    showConfirmDeleteModal,
    setShowConfirmDeleteModal,
    selected,
    storeItem,
  };
};

export default useCurrentView;
