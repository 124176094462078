import { endpoints, client } from "component-library";
const admin = {
  getUsers: () => client.get(endpoints.secure.GET_USERS),
  getArchivedUsers: () => client.get(endpoints.secure.GET_ARCHIVED_USERS),
  updateUser: (data) => client.post(endpoints.secure.UPDATE_USER, data),
  deactivateUser: (user_id) => client.get(`${endpoints.secure.DEACTIVATE_USER}/${user_id}`),
  activateUser: (user_id) => client.get(`${endpoints.secure.ACTIVATE_USER}/${user_id}`),
};

export default admin;
