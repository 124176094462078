import { useState, useEffect } from "react";
import {
  useApi,
  dailyFieldlogsApi,
  projectApi,
  weatherFullDayConditions,
  getWeatherIcon,
  getProxyWeatherUrl,
  useAuth,
} from "component-library";
import useProject from "../../../../../hooks/useProject";
import { DateTime } from "luxon";
import useDailyFieldLogs from "../context/useDailyFieldLogs";
import { useParams } from "react-router-dom";

const useWeather = () => {
  const { config } = useAuth();
  const { id } = useParams();
  const { updateRequests, getRequestParams } = useDailyFieldLogs();
  const today = DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd");
  const queryString = require("query-string");
  const { project, fieldMgmtDateRange } = useProject();
  const [weather, setWeather] = useState();
  const [rawWeather, setRawWeather] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [center, setCenter] = useState();
  const [conditions, setConditions] = useState();
  const [icon, setIcon] = useState();

  useEffect(() => {
    if (weather?.length) {
      setConditions(weather[0]);
    } else {
      setConditions();
    }
  }, [weather]);

  useEffect(() => {
    if (!!conditions) {
      setIcon(getWeatherIcon(conditions?.weather_code, 35));
    }
  }, [conditions]);

  const {
    request: weatherHistoryRequest,
    data: weatherHistoryData,
    error: weatherHistoryError,
    loading: weatherHistoryLoading,
  } = useApi(dailyFieldlogsApi.getWeatherHistory);

  const {
    request: projectCenterRequest,
    data: projectCenterData,
    error: projectCenterError,
    loading: projectCenterLoading,
  } = useApi(projectApi.getProjectCenter);

  const fetchWeatherData = (coords) => {
    if (coords.lat !== 0 && coords.lng !== 0) {
      const fields = [
        "temperatureMax",
        "temperature",
        "temperatureMin",
        "weatherCodeFullDay",
        "precipitationIntensity",
      ];

      const getTimelineParameters = queryString.stringify(
        {
          apikey: "Wl5no5HDZvzOTnQ9X3ZEHtjwBWR0AeXb",
          location: coords,
          fields,
          units: "imperial",
          timesteps: ["1d"],
          startTime: new Date().toISOString(),
          timezone: "America/Chicago",
        },
        { arrayFormat: "comma" }
      );
      fetch(`${getProxyWeatherUrl(config)}?${getTimelineParameters}`, { method: "GET", compress: true })
        .then((result) => result.json())
        .then((json) => setRawWeather(json))
        .catch((err) => console.error("error: " + err));
    }
  };

  const handleDisplayRawWeather = (weatherData) => {
    const values = weatherData.timelines[0].intervals[0].values;
    setWeather([
      {
        high_temperature: values.temperatureMax,
        low_temperature: values.temperatureMin,
        current_conditions: weatherFullDayConditions.find(
          (condition) => parseInt(condition.value) === parseInt(values.weatherCodeFullDay)
        )?.condition,
        weather_code: values.weatherCodeFullDay,
        precipitation_accumulation: values.precipitationIntensity,
      },
    ]);
  };

  useEffect(() => {
    if (!!updateRequests) {
      if (DateTime.fromJSDate(new Date(fieldMgmtDateRange[0])).toFormat("yyyy-MM-dd") !== today) {
        const requestParams = getRequestParams();
        weatherHistoryRequest(requestParams);
      } else {
        if (project?.id && !center) {
          projectCenterRequest(id);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRequests]);

  useEffect(() => {
    if (weatherHistoryData?.entries && !!weatherHistoryData.success) {
      setWeather(weatherHistoryData.entries);
    } else {
      setWeather();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weatherHistoryData?.entries]);

  useEffect(() => {
    if (projectCenterData?.locale) {
      setCenter(projectCenterData.locale);
    }
  }, [projectCenterData?.locale]);

  useEffect(() => {
    if (
      !!fieldMgmtDateRange[0] &&
      !fieldMgmtDateRange[1] &&
      DateTime.fromJSDate(new Date(fieldMgmtDateRange[0])).toFormat("yyyy-MM-dd") === today &&
      center?.lat &&
      center?.lng
    ) {
      if (!rawWeather?.data) {
        fetchWeatherData([center.lat, center.lng]);
      } else {
        handleDisplayRawWeather(rawWeather.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldMgmtDateRange, center]);

  useEffect(() => {
    if (rawWeather?.data) {
      const values = rawWeather.data.timelines[0].intervals[0].values;
      setWeather([
        {
          high_temperature: values.temperatureMax,
          low_temperature: values.temperatureMin,
          current_conditions: weatherFullDayConditions.find(
            (condition) => parseInt(condition.value) === parseInt(values.weatherCodeFullDay)
          )?.condition,
          weather_code: values.weatherCodeFullDay,
          precipitation_accumulation: values.precipitationIntensity,
        },
      ]);
    }
  }, [rawWeather?.data]);

  useEffect(() => {
    const load = weatherHistoryLoading || projectCenterLoading;
    if (!!load) {
      setLoading(load);
    } else {
      setLoading();
    }
  }, [weatherHistoryLoading, projectCenterLoading]);

  useEffect(() => {
    const error = weatherHistoryError || projectCenterError;
    if (!!error) {
      setError(error);
    } else {
      setError();
    }
  }, [weatherHistoryError, projectCenterError]);

  return {
    loading,
    error,
    conditions,
    icon,
    center,
  };
};

export default useWeather;
