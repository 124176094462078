import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useApi, projectApi, useFormControl } from "component-library";
import useProject from "../../../hooks/useProject";
import useInterval from "react-useinterval";
import { XCircle } from "react-feather";
import styles from "../PreconChecklist.module.scss";
import { BeatLoader } from "react-spinners";
import { schema } from "./schema/upb";
import { DateTime } from "luxon";
import Upload from "./Upload";

const UPBUpload = ({ setAlert, hasWarning = false, showDate }) => {
  const { project, setProject } = useProject();
  const [checking, setChecking] = useState(false);
  const [replacing, setReplacing] = useState(false);
  const [upbStatus, setUpbStatus] = useState(
    parseInt(project.upb_import_status, 10)
  );
  const { data, request, error } = useApi(projectApi.upbUploadStatus);
  const {
    data: importUPBData,
    request: importUPBRequest,
    error: importUPBError,
  } = useApi(projectApi.fetchUPB);
  const {
    data: upbFetchStatusData,
    request: upbFetchStatusRequest,
    error: upbFetchStatusError,
  } = useApi(projectApi.upbFetchStatus);

  const handleReplace = () => {
    setReplacing(true);
  };

  const formatDateTime = (date) => {
    const dateTime = DateTime.fromJSDate(new Date(date));

    return dateTime.toFormat("MM/dd/yyyy hh:mm a");
  };

  const onSubmit = async (formData, setLoading) => {
    setReplacing(false);
    importUPBRequest(project.id, formData.estimate_number);
  };

  const checkStatus = () => {
    if (
      (parseInt(project.upb_import_fetch_status, 10) === 1 ||
        parseInt(project.upb_import_fetch_status, 10) === -1) &&
      (parseInt(project.upb_import_status, 10) === 1 ||
        parseInt(project.upb_import_status, 10) === -1)
    ) {
      setChecking(false);
    }
    if (project.upb_import_id && parseInt(project.upb_import_status) === 0) {
      if (parseInt(project.upb_import_fetch_status) === 0) {
        upbFetchStatusRequest(project.upb_import_id);
      } else {
        request(project.upb_import_id);
      }
    }
  };

  useInterval(checkStatus, checking ? 2000 : null);

  const {
    handleSubmit,
    renderTextField,
    data: formData,
  } = useFormControl({
    schema,
    onSubmit,
    initialData: { estimate_number: project.estimate_number || "" },
  });

  useEffect(() => {
    if (!!data) {
      if (data.status === 1) {
        setAlert("File import complete.");
      }
      setProject({
        ...project,
        upb_import_status: parseInt(data.status, 10),
        upb_import_date: new Date(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!!importUPBData) {
      setProject({
        ...project,
        upb_import_status: 0,
        upb_import_fetch_status: 0,
        upb_import_id: importUPBData.uuid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importUPBData]);

  useEffect(() => {
    if (project) {
      setUpbStatus(project.upb_import_status);
    }
  }, [project]);

  useEffect(() => {
    setChecking(parseInt(upbStatus, 10) === 0);
  }, [upbStatus]);

  useEffect(() => {
    if (!!upbFetchStatusData) {
      if (
        parseInt(upbFetchStatusData.project[0].upb_import_fetch_status, 10) ===
          1 ||
        parseInt(upbFetchStatusData.project[0].upb_import_fetch_status, 10) ===
          -1
      ) {
        setProject({
          ...project,
          upb_import_filename:
            upbFetchStatusData.project[0].upb_import_filename,
          upb_import_status: upbFetchStatusData.project[0].upb_import_status,
          upb_import_fetch_status:
            upbFetchStatusData.project[0].upb_import_fetch_status,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upbFetchStatusData]);

  return (
    <>
      <div className="d-flex align-items-start">
        {!!project && project.upb_import_id && parseInt(project.upb_import_status) !== -1 && !replacing ? (
          <>
            {(!!error || !!importUPBError || !!upbFetchStatusError) && (
              <span className="error me-2">{error || importUPBError || upbFetchStatusError}</span>
            )}
            {parseInt(project.upb_import_status) === 1 && (
              <div className={`d-flex align-items-center me-2 ${styles.pillBadge}`}>
                <Button onClick={handleReplace} className="px-0 pe-1" variant="link" aria-label="remove estimate">
                  <XCircle size="13" />
                </Button>
                {!!project.upb_import_filename ? project.upb_import_filename : "import.csv"}
              </div>
            )}
            {parseInt(project.upb_import_status) === 0 && (
              <div className={`d-flex align-items-center me-2 py-2 ${styles.pillBadge}`}>
                <BeatLoader color={"#0071ce"} size={10} className="me-2" />
                <span className="ms-2">Estimate import in progress.</span>
              </div>
            )}
          </>
        ) : (
          <>
            {(parseInt(project.upb_import_status) === -1 || parseInt(project.upb_import_fetch_status) === -1) && (
              <span className="error me-2">Upload Failed, please try again</span>
            )}
            <a
              href={`${process.env.REACT_APP_S3_STORAGE_URL}documents/upb_template.csv`}
              className="btn btn-tertiary med me-2"
            >
              Download Template
            </a>
            <Upload setReplacing={setReplacing} />
            <form className="ms-2 d-flex align-items-center inline-form">
              <div>
                {renderTextField({
                  name: "estimate_number",
                  placeholder: "Estimate Number",
                  style: { marginBottom: 0 },
                  hasWarning: hasWarning,
                })}
              </div>
              <Button
                type="submit"
                onClick={handleSubmit}
                className="btn btn-tertiary med ms-2"
                disabled={!formData.estimate_number}
              >
                Import from B2W
              </Button>
            </form>
          </>
        )}
      </div>
      {!!showDate && !!project.upb_import_date && (
        <em className={styles.tinyText}>{`Last imported ${formatDateTime(project.upb_import_date)}`}</em>
      )}
    </>
  );
};

export default UPBUpload;
