import React, { useState, useEffect } from "react";
import { useApi, reportsApi, useFormControl } from "component-library";
import { schema, initialData } from "../schema/timecardSummary";
import ToFromFields from "../ToFromFields";
import useForms from "./useForms";
import { Button } from "react-bootstrap";
import DivisionByProjectReportTable from "../AdminReports/DivisionByProjectReportTable";

const DivisionalByProjectReport = ({ setLoading, setError, setData, setShowExport }) => {
  const {
    request: runReportRequest,
    data: runReportData,
    loading: runReportLoading,
    error: runReportError,
  } = useApi(reportsApi.getDivisionalByProjectReport);

  const { onSubmit } = useForms({
    runReportRequest,
    runReportData,
    runReportLoading,
    runReportError,
    setLoading,
    setError,
    setData,
  });

  const { handleSubmit, renderTextField, renderSubmitButton, data } = useFormControl({
    schema,
    initialData,
    onSubmit,
  });

  const [gridData, setGridData] = useState();
  const [exportURL, setExportURL] = useState();

  useEffect(() => {
    if (!!runReportData?.gridData) {
      setGridData(runReportData.gridData);
    }
    if (!!runReportData?.url) {
      setExportURL(runReportData.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runReportData]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <ToFromFields renderTextField={renderTextField} data={data} />
        </div>
        <div className="d-flex align-items-center justify-content-end">
          {!!exportURL && (
            <Button
              className="form-control-submit med btn btn-primary mb-2 me-2"
              onClick={() => {
                setShowExport(true);
              }}
            >
              Export File
            </Button>
          )}
          <div className="mb-2">{renderSubmitButton("Run Report")}</div>
        </div>
      </form>
      {!!gridData?.length && <DivisionByProjectReportTable gridData={gridData} formData={data} />}
    </>
  );
};

export default DivisionalByProjectReport;
