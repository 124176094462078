import React from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";

const Submenu = () => {
  const { id } = useParams();
  return (
    <nav className="headerTabs">
      <NavLink className="headerTab med text-darkblue11" to={`/project/${id}/field-management/dashboard`}>
        Dashboard
      </NavLink>
      <NavLink className="headerTab med text-darkblue11" to={`/project/${id}/field-management/field-logs`}>
        Field Logs
      </NavLink>
      <NavLink className="headerTab med text-darkblue11" to={`/project/${id}/field-management/timesheet-logs`}>
        Crew Timesheets
      </NavLink>
      <NavLink className="headerTab med text-darkblue11" to={`/project/${id}/field-management/field-forms`}>
        Field Forms
      </NavLink>
      <NavLink className="headerTab med text-darkblue11" to={`/project/${id}/field-management/field-notes`}>
        Field Notes
      </NavLink>
      <NavLink className="headerTab med text-darkblue11" to={`/project/${id}/field-management/weekly-reports`}>
        Weekly Reports
      </NavLink>
    </nav>
  );
};

export default Submenu;
