import React from "react";
import { DateTime } from "luxon";

const Updated = ({ data }) => {
  const formatDate = (date) => {
    return DateTime.fromFormat(date, "MM/dd/yyyy h:mm a").toFormat("MM/dd/yy h:mm a");
  };
  return <span>{!!data?.updated && formatDate(data?.updated)}</span>;
};

export default Updated;
