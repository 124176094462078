import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import { fieldLogsApi, useApi, useAuth } from "component-library";
import { BeatLoader } from "react-spinners";
import WorkRatePill from "../../../../../components/WorkRatePill/WorkRatePill";
import debounceFunction from "../../../../../hooks/useDebounce";

const { debounce } = debounceFunction();

const TopBar = ({
  task,
  editing,
  setFieldLog,
  fieldLog,
  foremanId,
  setOverAllocatedTimeSheets,
  setDisableToggle,
  requiredValueValidate,
}) => {
  const [quantity, setQuantity] = useState(!!task ? task.quantity : 0);
  const { userCan } = useAuth();

  const {
    request: updateRequest,
    data: updateData,
    loading: updateLoading,
    error: updateError,
  } = useApi(fieldLogsApi.updateFieldLog);

  const updateTaskQuantity = ({ taskId, value }) => {
    const updatedLog = {
      ...fieldLog,
      foreman_id: foremanId,
      work_day_tasks: fieldLog.work_day_tasks.map((task) => {
        delete task.remaining_quantity;
        if (task.id === taskId) {
          return {
            ...task,
            quantity: value,
          };
        } else {
          return task;
        }
      }),
    };

    updateRequest(updatedLog);
  };

  useEffect(() => {
    if (!!updateData) {
      setFieldLog(updateData.work_day.work_day);
      if (userCan("payroll-review-time")) {
        const filteredOverAllocatedTimeSheets = updateData.work_day.over_allocated.filter((oats) =>
          fieldLog?.time_sheets.find((ts) => ts.id === oats)
        );
        setOverAllocatedTimeSheets(filteredOverAllocatedTimeSheets);
      }
    } else {
      setQuantity(task.quantity);
    }
    setDisableToggle(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData, updateError]);

  useEffect(() => {
    if (!!updateError) {
      setQuantity(task.quantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);
  return (
    <div className={`text-gray2 ${styles.taskBody}`}>
      <div className={styles.topBar}>
        <div className="row col-9 d-flex flex-row align-items-center">
          <div className={styles.firstColumn}>{task.production_rate}</div>
          <div
            className={`form-group mb-0 position-relative input-wrap ${!!updateError ? "error" : undefined} ${
              styles.inputWrapper
            }`}
          >
            {!!updateLoading && (
              <div className="loaderWrapper">
                <BeatLoader color={"#0071ce"} />
              </div>
            )}
            <input
              className={`
                d-inline me-2 form-control text-gray3
                ${
                  (userCan("pm-review-time") && !["Exported", "PM Approved"].includes(fieldLog.status.status)) ||
                  (userCan("payroll-review-time") && !!editing)
                    ? ""
                    : "bg-gray5"
                } ${styles.input}
                ${!requiredValueValidate(task, quantity) ? styles.invalidInput : ""}
              `}
              onChange={({ target: { value } }) => {
                setDisableToggle(true);
                setQuantity(value);
                debounce(updateTaskQuantity, { taskId: task.id, value: value });
              }}
              onBlur={({ target: { value } }) => {
                if (parseInt(value) !== parseInt(task.quantity) && !updateLoading) {
                  updateTaskQuantity({ taskId: task.id, value: value });
                }
              }}
              disabled={
                !(
                  (userCan("pm-review-time") && !["Exported", "PM Approved"].includes(fieldLog.status.status)) ||
                  (userCan("payroll-review-time") && !!editing)
                )
              }
              value={quantity}
            />
            {`${
              !!task.remaining_quantity || task.remaining_quantity === 0
                ? parseFloat(task.remaining_quantity).toFixed(2)
                : ""
            } remaining of ${task.task?.bid_quantity} ${task.task?.task_uom}`}
            {!!task?.task?.quantity_required ? <span className={styles.asterisk}>*</span> : ""}
          </div>
        </div>
        <WorkRatePill workRate={parseFloat(task?.work_rate || 0)} />
      </div>
    </div>
  );
};

TopBar.propTypes = {
  task: PropTypes.object,
  editing: PropTypes.bool,
  setFieldLog: PropTypes.func,
  fieldLog: PropTypes.object,
  updateRequest: PropTypes.any,
  updateData: PropTypes.object,
  setOverAllocatedTimeSheets: PropTypes.func,
};

export default TopBar;
