import React from "react";
import styles from "./styles.module.scss";
import { AlertOctagon } from "react-feather";
import Wrapper from "./Wrapper";

const UnauthorizedCard = () => {

  return (
    <Wrapper>
      <div className={styles.logo}>
        <AlertOctagon color="#CF5400" size="24" />
      </div>
      <h2>You don't have permission to access this page.</h2>
      <p>You can request access from your administrator.</p>
    </Wrapper>
  );
};

export default UnauthorizedCard;
