import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ChevronDown, ChevronRight } from "react-feather";
import styles from "../../components/ContactsAdmin/ContactsAdmin.module.scss";
import CategoryButton from "../../components/ContactsAdmin/CategoryButton";
import { certifications, FilterSearch, useAuth } from "component-library";
import CertificationButton from "./CertificationButton";

const VendorFilters = ({
  selectedCategories,
  updateCategory,
  selectedCertifications,
  updateCertification,
  searchVendors,
  setQ,
  loading,
}) => {
  const { config } = useAuth();
  const [filtersOpen, setFiltersOpen] = useState(false);

  return (
    <div className="mb-4">
      <div className="d-flex flex-row align-items-center justify-content-between mb-2">
        <Button
          className="mb-2 lg ps-0 pe-0 me-3 font-weight-bold"
          onClick={() => setFiltersOpen(!filtersOpen)}
          variant="link"
        >
          Filters
          {!!filtersOpen ? <ChevronDown size="16" /> : <ChevronRight size="16" />}
        </Button>
        <FilterSearch
          searchFunction={searchVendors}
          inline
          style={{ maxWidth: "38rem", width: "100%" }}
          setExternalQ={setQ}
          disabled={!!loading}
        />
      </div>
      {!!filtersOpen ? (
        <>
          <h4 className="sm-med semibold">Category</h4>
          <div className={styles.categoryFilters}>
            {!!config.company_categories &&
              !!config.company_categories.length &&
              config.company_categories.map((cat) => (
                <CategoryButton
                  key={cat.id}
                  category={cat}
                  updateCategory={updateCategory}
                  active={selectedCategories.includes(cat.id)}
                  setFiltersOpen={setFiltersOpen}
                />
              ))}
          </div>
          <h4 className="sm-med semibold mt-4">Certifications</h4>
          {!!certifications &&
            certifications.map((cert) => (
              <CertificationButton
                key={cert.id}
                certification={cert}
                updateCertification={updateCertification}
                active={selectedCertifications.includes(cert.id)}
                setFiltersOpen={setFiltersOpen}
              />
            ))}
        </>
      ) : (
        <>
          {!!config.company_categories &&
            !!config.company_categories.length &&
            config.company_categories.map(
              (cat) =>
                selectedCategories.includes(cat.id) && (
                  <CategoryButton
                    key={cat.id}
                    category={cat}
                    updateCategory={updateCategory}
                    active={true}
                    setFiltersOpen={setFiltersOpen}
                  />
                )
            )}
        </>
      )}
    </div>
  );
};

export default VendorFilters;
