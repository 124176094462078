import { useState, useEffect } from "react";
import useEstimateConversion from "../../useEstimateConversion";

const useTask = ({ task, bidItems, bidItem, setBidItems, updateError }) => {
  const { openTasks, setPhasesError } = useEstimateConversion();

  const [open, setOpen] = useState(false);

  const updateLeadingCostCode = (field, value, item) => {
    const parent = bidItems.find((bidItem) => bidItem.id === item.parent_id);

    setBidItems(
      bidItems.map((bItem) => {
        if (bItem.id === parent.id) {
          return {
            ...bItem,
            sub_items: bidItem.sub_items.map((sItem) => {
              return {
                ...sItem,
                leading_cost_code: sItem.id === item.id ? value : 0,
              };
            }),
          };
        } else {
          return bItem;
        }
      })
    );
  };

  const updateItem = (field, value, id) => {
    const update = (item) => {
      if (item.id === id)
        return {
          ...item,
          [field]: value,
        };
      if (item.sub_items) {
        return {
          ...item,
          sub_items: item.sub_items.map(update),
        };
      }
      return item;
    };

    setBidItems(
      bidItems.map((bItem) => {
        if (bItem.id === bidItem.id) {
          return {
            ...bItem,
            sub_items: bidItem.sub_items.map(update),
          };
        } else {
          return bItem;
        }
      })
    );
  };

  useEffect(() => {
    if (!!openTasks?.length && openTasks.includes(task?.id)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTasks]);

  useEffect(() => {
    if (!!updateError) {
      setPhasesError(updateError);
    } else {
      setPhasesError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  return {
    updateLeadingCostCode,
    updateItem,
    open,
  };
};

export default useTask;
