import React from "react";
import { ChevronRight } from "react-feather";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";
import { useAuth } from "component-library";

const AdminDashboard = () => {
  const { userCan } = useAuth();
  return (
    <>
      <h2 className={styles.sectionTitle}>Quick Links</h2>
      {userCan("admin-users") && (
        <div className={styles.quickLinkGrid}>
          <section className={styles.gridBlock}>
            <header className="d-flex align-items-center">
              Super Admin <ChevronRight className="ms-2" color="#fff" size="18" />
            </header>
            <section className={styles.blockContent}>
              <NavLink className="btn-link" to="/super-admin/modules">
                Modules
              </NavLink>
              <NavLink className="btn-link" to="/super-admin/app-settings">
                App Settings
              </NavLink>
              {/* <NavLink to="/super-admin/company-details">Company Details</NavLink>
              <NavLink to="/super-admin/project-settings">Project Settings</NavLink> */}
            </section>
          </section>
          <section className={styles.gridBlock}>
            <header className="d-flex align-items-center">
              General <ChevronRight className="ms-2" color="#fff" size="18" />
            </header>
            <section className={styles.blockContent}></section>
          </section>
          <section className={styles.gridBlock}>
            <header className="d-flex align-items-center">
              User Management <ChevronRight className="ms-2" color="#fff" size="18" />
            </header>
            <section className={styles.blockContent}>
              <NavLink className="btn-link" to="/user-admin">
                Add New User
              </NavLink>
              <NavLink className="btn-link" to="/user-admin">
                Edit User
              </NavLink>
              <NavLink className="btn-link" to="/roles-admin">
                Edit Roles
              </NavLink>
            </section>
          </section>
        </div>
      )}
    </>
  );
};

export default AdminDashboard;
