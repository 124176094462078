import React from "react";
import styles from "./styles.module.scss";
import Foreman from "./Foreman";
import { Button } from "react-bootstrap";
import { ChevronDown, ChevronRight } from "react-feather";
import { SelectAll, AlertPopup } from "component-library";
import { BeatLoader } from "react-spinners";
import PropTypes from "prop-types";

const Foremen = ({
  foremen,
  selectedForemenIds,
  setSelectedForemenIds,
  foremenError,
  foremenLoading,
  selectedForemen,
  setSelectedForemen,
  active,
  setActive,
  handleForemanToggle,
}) => {

  const headerContent = (
    <>
      <h2 className={styles.heading}>
        Foremen
        <span className={styles.count}>({foremen ? foremen.length : 0})</span>
      </h2>
      {!!foremenLoading ? (
        <BeatLoader size={10} color={"#0071ce"} />
      ) : (
        <>
          {!!foremenError ? (
            <AlertPopup error={foremenError} />
          ) : (
            <>
              {!!foremen?.length && (
                <>
                  {active ? <ChevronDown size="16" color={"#7B7B7B"} /> : <ChevronRight size="16" color={"#7B7B7B"} />}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      <div className={styles.foremen}>
        {!!foremen?.length ? (
          <Button
            className={`${styles.title} pe-2`}
            variant="link"
            onClick={() => {
              foremen?.length && setActive(!active);
            }}
          >
            {headerContent}
          </Button>
        ) : (
          <div
            className={`${styles.title} pe-2`}
            variant="link"
            onClick={() => {
              foremen?.length && setActive(!active);
            }}
          >
            {headerContent}
          </div>
        )}
        {!!active && !foremenLoading && (
          <>
            <div>
              {!!foremen?.length &&
                foremen.map((foreman, index) => (
                  <Foreman
                    key={index}
                    foreman={foreman}
                    selectedForemanIds={selectedForemenIds}
                    handleForemanToggle={handleForemanToggle}
                  />
                ))}
            </div>
            {!!foremen?.length && (
              <div className={styles.selectAll}>
                <SelectAll
                  selectedIds={selectedForemenIds}
                  setSelectedIds={setSelectedForemenIds}
                  items={foremen}
                  selectedItems={selectedForemen}
                  setSelectedItems={setSelectedForemen}
                  small={true}
                  field="foreman_id"
                />
                Show All
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

Foremen.propTypes = {
  foremen: PropTypes.array,
  selectedForemenIds: PropTypes.array,
  setSelectedForemenIds: PropTypes.func,
  foremenError: PropTypes.any,
  foremenLoading: PropTypes.bool,
};

export default Foremen;
