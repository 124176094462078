import React from "react";
import { Trash } from "react-feather";
import styles from "./styles.module.scss";
import MinutesInput from "../FieldLogs/FieldLog/MinutesInput";
import { Button } from "react-bootstrap";

const PTO = ({ timesheet, setTimesheet, editing }) => {
  const removePTO = () => {
    setTimesheet({
      ...timesheet,
      pto_duration: 0,
    });
  };
  return (
    <>
      {!!parseFloat(timesheet?.pto_duration) && (
        <div className={`d-flex justify-content-between align-items-center py-2 row ${styles.task}`}>
          <div className={`${styles.name} col`}>PTO</div>

          <div className="d-flex justify-content-end align-items-center col-md-2">
            <MinutesInput
              minutes={parseFloat(timesheet?.pto_duration)}
              setMinutes={(minutes) => {
                setTimesheet({
                  ...timesheet,
                  pto_duration: minutes,
                });
              }}
              canEdit={editing}
            />
            {editing && (
              <Button variant="transparent" size="sm" onClick={removePTO} className="p-0">
                <Trash color="#D3D3D3" size={16} className="ms-1" />
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PTO;
