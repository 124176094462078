import React from "react";
import PhotoHeaders from "../SingleItems/PhotoHeaders";
import SinglePhoto from "../SingleItems/SinglePhoto";
import { BeatLoader } from "react-spinners";
import styles from "../styles.module.scss";

const PhotosList = ({
  photosList,
  title,
  TitleIcon,
  isAccordion,
  handlePin,
  handleDelete,
  loading,
  params,
  selectedItems,
  setSelectedItems,
  selectMode,
}) => {
  return (
    <div className={isAccordion ? "ms-5" : ""}>
      <PhotoHeaders title={title} TitleIcon={TitleIcon} isAccordion={isAccordion} />
      {!!loading ? (
        <div className={styles.loaderWrapper}>
          <BeatLoader color="#0071ce" />
        </div>
      ) : (
        <>
          {!!photosList?.length ? (
            photosList.map((item) => (
              <SinglePhoto
                key={item.id}
                photo={item}
                params={params}
                isAccordion={isAccordion}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                selectMode={selectMode}
                handlePin={handlePin}
                handleDelete={handleDelete}
              />
            ))
          ) : (
            <div className="mb-2">No items found.</div>
          )}
        </>
      )}
    </div>
  );
};

export default PhotosList;
