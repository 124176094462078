import { useState, useEffect } from "react";
import { useApi, dailyFieldlogsApi } from "component-library";
import PropTypes from "prop-types";
import removeDuplicateValues from "../../../../../../utils/removeDuplicateValues";
import useDailyFieldLogs from "../context/useDailyFieldLogs";

const useCrew = ({ foremen, selectedForemenIds }) => {
  const { updateRequests, getRequestParams } = useDailyFieldLogs();
  const [active, setActive] = useState(true);
  const [crew, setCrew] = useState();
  const [filteredCrew, setFilteredCrew] = useState();

  const {
    request: crewRequest,
    data: crewData,
    error: crewError,
    loading: crewLoading,
  } = useApi(dailyFieldlogsApi.getCrew);

  useEffect(() => {
    if (!!updateRequests) {
      const requestParams = getRequestParams();
      crewRequest(requestParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRequests]);

  useEffect(() => {
    if (crewData?.crew) {
      setCrew(crewData.crew);
    } else {
      setCrew();
    }
  }, [crewData?.crew]);

  useEffect(() => {
    let filtered = [];
    if (!!crew?.length && selectedForemenIds?.length) {
      filtered = crew;
      if (!!foremen?.length) {
        filtered = filtered.filter((c) => c.foreman_id !== c.user_id);
      }
      if (selectedForemenIds?.length) {
        filtered = filtered.filter((cm) => selectedForemenIds?.includes(cm.foreman_id));
        filtered = removeDuplicateValues(filtered, "user_id");
      }
    }
    setFilteredCrew(filtered);
  }, [crew, selectedForemenIds, foremen]);

  return {
    active,
    setActive,
    filteredCrew,
    crewError,
    crewLoading,
  };
};

useCrew.propTypes = {
  selectedForemenIds: PropTypes.array,
};

export default useCrew;
