import React, { useState, useEffect } from 'react';
import CheckboxListStatus from "./CheckboxListStatus";
import useProject from "../../../hooks/useProject";
import { useApi, projectApi } from "component-library";
import { BounceLoader } from "react-spinners";
import styles from "../PreconChecklist.module.scss";
import { Alert } from "react-bootstrap";

const DotCatListStatus = () => {
  const { project, setProject } = useProject();
  const { loading, request, error } = useApi(projectApi.assignProjectDotCategories);
  const [selectedItems, setSelectedItems] = useState(project.dot_categories);

  const handleRemove = async (name, groupName, id) => {
    const projectId = project.id;
    let categoryIds = [];
    const updatedCategories = [
      ...project.dot_categories.filter(function (e) {
        return e.id !== id;
      }),
    ];

    categoryIds = updatedCategories.map((item) => {
      return item.dot_category.id;
    });

    const result = await request({
      project_id: projectId,
      dot_categories: categoryIds,
    });

    if (!!result.success) {
      setProject({
        ...project,
        dot_categories: result.responseData.project.dot_categories,
      });
    }
  };

  useEffect(() => {
    setSelectedItems(project.dot_categories);
  }, [project]);

  return (
    <div className="position-relative">
      {!!loading && (
        <div className={styles.removeLoaderWrapper}>
          <BounceLoader size="25" color={"#0071ce"} className={styles.loader} />
        </div>
      )}
      {!!error && <Alert variant="danger">{error}</Alert>}
      <CheckboxListStatus selectedItems={selectedItems} handleRemove={handleRemove} />
    </div>
  );
};

export default DotCatListStatus;