import React from 'react';
import { ArrowLeftCircle } from "react-feather";
import { Link } from "react-router-dom";
import useProject from "../../hooks/useProject";

const BackToPOsLink = () => {
  const { project } = useProject();

  return (
    <Link
      className="sm-med d-flex align-items-center"
      to={`/project/${!!project && project.id}/purchase-orders`}
      aria-label="back to purchase order list"
    >
      <ArrowLeftCircle size="16" className="me-2" /> Back to PO List
    </Link>
  );
}

export default BackToPOsLink;