import React from "react";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import useEstimateConversion from "../../useEstimateConversion";

const PhaseToggle = () => {
  const { viewPhases, setViewPhases, setEditPhases, phases, importable, canEdit } = useEstimateConversion();

  const handleCheckboxChange = () => {
    if (!!phases && phases.length === 0) {
      setEditPhases(true);
    } else {
      setViewPhases(!viewPhases);
    }
  };

  return (
    <div className="d-flex flex-row me-2">
      <div className="align-self-center">
        <Button
          onClick={() => (!!importable ? setEditPhases(true) : handleCheckboxChange())}
          className={`med pe-1 ${styles.phaseToggleLabel}`}
          variant="link"
          aria-label={"create phases"}
          disabled={!canEdit}
        >
          Phases
        </Button>
      </div>
      <div className="form-group form-check toggle" onClick={handleCheckboxChange}>
        <input
          id="phases-toggle"
          type="checkbox"
          className="form-check-input"
          name="phase_toggle"
          onChange={handleCheckboxChange}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              handleCheckboxChange();
            }
          }}
          checked={viewPhases}
          aria-label="show phases in table"
        />
        <label htmlFor="phases-toggle" className={`${styles.toggle} form-check-label`} />
      </div>
    </div>
  );
};

export default PhaseToggle;
