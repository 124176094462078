import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import { ArrowRightCircle, ChevronDown, ChevronRight, MessageSquare } from "react-feather";
import getTaskNumber from "../../../../../../../_resourceManagement/FieldLogs/FieldLog/TaskView/Task/getTaskNumber";
import convertMinutesToTime from "../../../../../../../utils/convertMinutesToTime";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const TaskHeader = ({ task, active, setActive, setComments, toggleTasksOpen }) => {
  const [taskHours, setTaskHours] = useState();
  const { id } = useParams();

  useEffect(() => {
    if (!!task?.time_entries?.length) {
      setTaskHours(task.time_entries.reduce((acc, curr) => acc + parseInt(curr.minutes), 0));
    } else {
      setTaskHours(0);
    }
  }, [task?.time_entries]);

  return (
    <>
      <div className={styles.tasksHeader}>
        <div className="flex-1 w-50">
          <Button
            className={`${styles.toggler} text-decoration-none me-3 w-75`}
            onClick={() => setActive(!active)}
            variant="link"
          >
            <h2 className={`${styles.title} text-limiter`}>
              {getTaskNumber(task?.task)} - {task?.task.name}
            </h2>
          </Button>
        </div>
        <div className="d-flex align-items-center">
          <div className={styles.actionWrapper}>
            <Link
              className="me-3"
              to={`/project/${id}/field-management/field-logs/${task?.work_day_id}/foreman/${task?.foreman_id}`}
            >
              Field Log
              <ArrowRightCircle className="ms-2" size={16} />
            </Link>
            <Button
              variant="link"
              className="position-relative"
              onClick={() => {
                if (task?.task_comments?.length) {
                  setComments(
                    task?.task_comments?.map((comment) => {
                      return {
                        ...comment,
                        work_day_task_data: {
                          quantity: task?.quantity,
                          hours: task?.hours,
                          uom_id: parseInt(task?.task?.unit_measure),
                          station: task?.station,
                        },
                      };
                    })
                  );
                }
              }}
              disabled={!task?.task_comments?.length}
            >
              {!!task?.task_comments?.length && (
                <div className={styles.countIndicator}>{task.task_comments.length || 0}</div>
              )}
              <MessageSquare size={18} color="#7B7B7B" />
            </Button>
          </div>
          <div className="d-flex align-items-center">
            <div className={styles.totalHours}>{convertMinutesToTime(taskHours)}</div>
            <Button onClick={() => toggleTasksOpen(task.id)} variant="link">
              {active ? <ChevronDown size="16" color={"#7B7B7B"} /> : <ChevronRight size="16" color={"#7B7B7B"} />}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

TaskHeader.propTypes = {
  task: PropTypes.object,
  active: PropTypes.bool,
  setActive: PropTypes.func,
  setComments: PropTypes.func,
  toggleTasksOpen: PropTypes.func,
};

export default TaskHeader;
