import { AgGridTable, useAuth } from "component-library";
import equipmentTimecardReportConfig from "./equipmentTimecardReportConfig";

const EquipmentTimecardGridReport = ({ gridData }) => {
  const { user } = useAuth();

  return (
    <AgGridTable
      gridData={gridData}
      gridConfig={equipmentTimecardReportConfig}
      fieldsToSearch={["job_number", "cost_code", "date", "equip_no"]}
      id={`${user.id}_equipment_timecard_report`}
    />
  );
};

export default EquipmentTimecardGridReport;
