import React, { useEffect, useState } from "react";
import { useApi, fieldLogsApi, projectApi, useAuth } from "component-library";
import ForemanSummaryReport from "./forms/ForemanSummaryReport";
import EmployeeTimeSummaryReport from "./forms/EmployeeTimeSummaryReport";
import ExportModal from "./ExportModal";
import EquipmentTimecardReport from "./forms/EquipmentTimecardReport";
import CrossJobReport from "./forms/CrossJobReport";
import LaborSummaryReport from "./forms/LaborSummaryReport";
import UnallocatedTimeSummaryReport from "./forms/UnallocatedTimeSummaryReport";
import GeofenceReport from "./forms/GeofenceReport";
import EmployeeAttendenceReport from "./forms/EmployeeAttendenceReport";
import ManHoursReport from "./forms/ManHoursReport";
import DivisionalLaborReport from "./forms/DivisionalLaborReport";
import DivisionalByProjectReport from "./forms/DivisionalByProjectReport";
import ProjectByDivisionReport from "./forms/ProjectByDivisionReport";

const Reports = ({ selectedReport, setSelectedReport }) => {
  const { config } = useAuth();
  const [foremanOptions, setForemanOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [exportLoading, setExportLoading] = useState();
  const [exportError, setExportError] = useState();
  const [reportData, setReportData] = useState();
  const [urlParams, setURLParams] = useState();

  const { request: projectListRequest, data: projectListData } = useApi(projectApi.getProjectsReportList);
  const { request: statusRequest, data: statusData } = useApi(fieldLogsApi.getConfig);

  const viewableReports = [
    "export_time_report",
    "export_foreman_summary",
    "equipment_timecard_report",
    "labor_summary_report",
    "geofence_report",
    "employee_attendence_report",
    "man_hours_report",
    "division_labor_report",
    "division_by_project_report",
    "project_by_division_report",
  ];

  useEffect(() => {
    if (config?.foreman) {
      setForemanOptions(
        config.foreman.map((option) => {
          return {
            label: `${option.first_name} ${option.last_name}`,
            value: option.id,
          };
        })
      );
    }
  }, [config]);

  useEffect(() => {
    if (projectListData?.projects) {
      setProjectOptions(
        projectListData.projects.map((project) => {
          return {
            label: `${project.job_number} - ${project.name}`,
            value: project.id,
          };
        })
      );
    }
  }, [projectListData]);

  useEffect(() => {
    if (statusData?.config) {
      setStatusOptions(
        statusData.config.map((c) => {
          return {
            label: c.status,
            value: c.id,
          };
        })
      );
    }
  }, [statusData]);

  useEffect(() => {
    if (!!urlParams) {
      const params = new URLSearchParams(urlParams);
      setSelectedReport(params.get("report"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  useEffect(() => {
    projectListRequest();
    statusRequest();
    if (window.location.search) {
      setURLParams(window.location.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!reportData && !viewableReports.includes(selectedReport)) {
      setShowExport(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  return (
    <>
      {selectedReport === "export_foreman_summary" && (
        <ForemanSummaryReport
          foremanOptions={foremanOptions}
          setLoading={setExportLoading}
          setError={setExportError}
          setData={setReportData}
          setShowExport={setShowExport}
        />
      )}
      {selectedReport === "export_time_report" && (
        <EmployeeTimeSummaryReport
          statusOptions={statusOptions}
          setLoading={setExportLoading}
          setError={setExportError}
          setData={setReportData}
          setShowExport={setShowExport}
          urlParams={urlParams}
        />
      )}
      {selectedReport === "equipment_timecard_report" && (
        <EquipmentTimecardReport
          setLoading={setExportLoading}
          setError={setExportError}
          setData={setReportData}
          setShowExport={setShowExport}
        />
      )}
      {selectedReport === "employee_attendence_report" && (
        <EmployeeAttendenceReport
          setLoading={setExportLoading}
          setError={setExportError}
          setData={setReportData}
          setShowExport={setShowExport}
        />
      )}
      {selectedReport === "cross_job_report" && (
        <CrossJobReport setLoading={setExportLoading} setError={setExportError} setData={setReportData} />
      )}
      {selectedReport === "labor_summary_report" && (
        <LaborSummaryReport
          projects={projectOptions}
          setLoading={setExportLoading}
          setError={setExportError}
          setData={setReportData}
          setShowExport={setShowExport}
        />
      )}
      {selectedReport === "unallocated_time_report" && (
        <UnallocatedTimeSummaryReport setLoading={setExportLoading} setError={setExportError} setData={setReportData} />
      )}
      {selectedReport === "geofence_report" && (
        <GeofenceReport
          setLoading={setExportLoading}
          setError={setExportError}
          setData={setReportData}
          setShowExport={setShowExport}
        />
      )}
      {selectedReport === "man_hours_report" && (
        <ManHoursReport
          setLoading={setExportLoading}
          setError={setExportError}
          setData={setReportData}
          setShowExport={setShowExport}
        />
      )}
      {selectedReport === "division_labor_report" && (
        <DivisionalLaborReport
          setLoading={setExportLoading}
          setError={setExportError}
          setData={setReportData}
          setShowExport={setShowExport}
        />
      )}
      {selectedReport === "division_by_project_report" && (
        <DivisionalByProjectReport
          setLoading={setExportLoading}
          setError={setExportError}
          setData={setReportData}
          setShowExport={setShowExport}
        />
      )}
      {selectedReport === "project_by_division_report" && (
        <ProjectByDivisionReport
          setLoading={setExportLoading}
          setError={setExportError}
          setData={setReportData}
          setShowExport={setShowExport}
        />
      )}
      <ExportModal
        showExport={showExport && selectedReport !== "cross_job_report"}
        setShowExport={setShowExport}
        loading={exportLoading}
        error={exportError}
        data={reportData}
      />
    </>
  );
};

export default Reports;
