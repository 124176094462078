import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const UnitMeasure = ({ editTasks, item, unitsOfMeasure, updateItem, customControlStyles }) => {
  return (
    <td className="text-end">
      {!!editTasks && item.entity_type === "task" ? (
        <>
          <div className=" h-100 ms-2 me-2">
            <label
              className="d-none"
              htmlFor={`${item.id}-units-measure`}
              aria-label="Item unit measure select edit"
            ></label>
            <Select
              placeholder="Select"
              menuPosition="fixed"
              menuPlacement="auto"
              options={unitsOfMeasure}
              name={`${item.id}-units-measure`}
              inputId={`${item.id}-units-measure`}
              className="sm text-center w-100"
              onChange={(value) => updateItem("unit_measure", value.value, item.id)}
              styles={{ control: customControlStyles }}
              value={unitsOfMeasure.find((opt) => parseInt(opt.value) === parseInt(item?.unit_measure))}
              aria-label="Unit Measure Select"
              isSearchable={false}
            />
          </div>
        </>
      ) : (
        <>{item?.task_uom?.toLowerCase()}</>
      )}
    </td>
  );
};

UnitMeasure.propTypes = {
  editTasks: PropTypes.bool,
  item: PropTypes.object,
  updateItem: PropTypes.func,
  unitsOfMeasure: PropTypes.array,
  customControlStyles: PropTypes.any,
};

export default UnitMeasure;
