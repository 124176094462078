import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmationModal = ({
  showConfirmation,
  setShowConfirmation,
  confirmSendRFQ,
  sendLoading,
  bidName,
}) => {
  return (
    <Modal
      show={showConfirmation}
      centered
      onHide={() => setShowConfirmation(false)}
      animation={false}
    >
      <div className="d-flex flex-column align-items-center pt-4 mt-2 pb-3">
        <h3 className="lg semibold">Send Request for Quote?</h3>
        <p className="med text-center px-4">
          Are you sure you want to send a request for quote for{" "}
          <span className="semibold">{bidName}</span>?
        </p>
        <div className="d-flex justify-content-center align-items-center w-100 px-4 mt-1">
          <Button
            className="light-text mx-1 btn btn-lg w-50"
            variant="light-gray"
            onClick={() => setShowConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            className="light-text mx-1 btn btn-lg med w-50"
            variant="orange"
            onClick={() => {
              confirmSendRFQ();
            }}
            disabled={sendLoading}
          >
            {sendLoading ? "Loading..." : "Send"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
