import React from "react";
import ProvideProjectsList from "./hooks/ProvideProjectsList";
import ProjectSelectionContent from "./ProjectSelectionContent";

const ProjectSelection = () => {
  return (
    <ProvideProjectsList>
      <ProjectSelectionContent />
    </ProvideProjectsList>
  );
};

export default ProjectSelection;
