import React from "react";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";

const EquipmentDetails = ({ data, truckDefectTypes, truckDefects, setSelectedEquipment }) => {
  return (
    <section className="mb-3">
      <h3 className={`${styles.sectionTitle} mb-0`}>Tractor / Truck:</h3>
      <div className="d-flex mx-3">
        <table>
          <tbody>
            <tr>
              <td className={styles.label}>ID / Number:</td>
              <td className={styles.value}>
                {!!setSelectedEquipment ? (
                  <Button
                    className="med p-0"
                    variant="link"
                    onClick={() => setSelectedEquipment({ equipment_id: data?.data?.truck_id })}
                  >
                    {data?.data?.truck_id}
                  </Button>
                ) : (
                  <div className="med p-0">{data?.data?.truck_id}</div>
                )}
              </td>
            </tr>
            <tr>
              <td className={styles.label}>Description:</td>
              <td className={`${styles.value}`}>
                {!!setSelectedEquipment ? (
                  <Button
                    className="med p-0"
                    variant="link"
                    onClick={() => setSelectedEquipment({ equipment_id: data?.data?.truck_id })}
                  >
                    {data?.data?.description || "No description"}
                  </Button>
                ) : (
                  <div className="med p-0">{data?.data?.description || "No description"}</div>
                )}
              </td>
            </tr>
            <tr>
              <td className={styles.label}>Odometer:</td>
              <td className={styles.value}> {data?.data?.truck_odometer.toLocaleString() || 0}</td>
            </tr>
            <tr>
              <td className={styles.label}>Defects: </td>
              <td className={styles.value}>
                {!!truckDefectTypes && !!truckDefects?.length
                  ? truckDefects.map((defect) => {
                    return truckDefectTypes.map(
                      (opt, index) =>
                        defect[0].includes(opt[0]) && (
                          <div key={index} className={`d-flex align-items-center ${styles.defect}`}>
                            {opt[1]}
                          </div>
                        )
                    );
                    })
                  : "None Detected"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default EquipmentDetails;
