import { bidApi, useApi, useFormControl } from "component-library";
import { useNavigate } from "react-router-dom";
import useContactsListField from "../../hooks/useContactsListField";
import useLocationField from "../../hooks/useLocationField";
import useBidTodoValidation from "./useBidTodoValidation";
import useFormatBidData from "./useFormatBidData";
import { schema as bidSchema } from "../../schema/bid";
import { schema as projectSchema } from "../../schema/project";
import { useEffect } from "react";

const useSaveBidEdits = ({ bidObject, isBid, redirect, setProject, bidEditing }) => {
  const history = useNavigate();
  const schema = isBid ? bidSchema : projectSchema;
  const { request, error, data: completeData } = useApi(bidApi.completeBid);

  const { bidTodoErrors, submitBidTodos } = useBidTodoValidation();
  const { formatBidData } = useFormatBidData();

  const onSubmit = async (formData, setLoading) => {
    if (isBid) {
      const bidErrors = submitBidTodos(formData);
      if (!!Object.keys(bidErrors).length) {
        setLoading(false);
        return new Promise((resolve, reject) => {
          reject(new Error("Project Requirements is missing information"));
        });
      }
    }

    const dataToSubmit = formatBidData(formData);

    delete dataToSubmit.upb_import_date;
    delete dataToSubmit.upb_import_fetch_status;
    delete dataToSubmit.upb_import_filename;
    delete dataToSubmit.upb_import_status;
    delete dataToSubmit.upb_import_id;

    const { success } = await request(dataToSubmit);
    if (!success) {
      window.scrollTo(0, 0);
      throw new Error();
    }
    setLoading(false);
  };

  const {
    handleSubmit,
    renderSubmitButton,
    loading,
    renderTextField,
    renderCheckbox,
    renderSelectField,
    renderFieldRowRepeater,
    renderCheckboxInGroup,
    renderRadioGroup,
    data,
    setFields,
    errors,
    schema: fcSchema,
  } = useFormControl({
    schema: schema(bidObject.type_id),
    onSubmit,
    initialData: {
      ...bidObject,
      milestones: bidObject.milestones.map((m, i) => ({
        ...m,
        key: i + 1,
      })),
    },
    scrollToTop: true,
  });

  const { renderLocationField } = useLocationField({
    data,
    setFields,
    schema: fcSchema,
    errors,
  });

  const { renderContactsListField } = useContactsListField({
    data,
    setFields,
    schema: fcSchema,
    errors,
    modifyRemoveFunc: bidEditing,
  });

  useEffect(() => {
    if (completeData && completeData.project) {
      if (!!setProject) {
        setProject({ ...bidObject, ...completeData.project });
      }
      if (!!redirect) {
        history(`${redirect}`);
      } else if (isBid) {
        history(`/bid/${completeData.project.id}`);
      } else {
        history(`/project/${completeData.project.id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeData]);

  useEffect(() => {
    if (!!errors) {
      const firstError = document.querySelector(".error");
      if (firstError) {
        firstError.scrollIntoView({ behavior: "smooth" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return {
    completeData,
    error,
    handleSubmit,
    renderSubmitButton,
    loading,
    renderTextField,
    renderCheckbox,
    renderSelectField,
    renderFieldRowRepeater,
    renderCheckboxInGroup,
    renderRadioGroup,
    bidTodoErrors,
    renderLocationField,
    renderContactsListField,
    data,
    setFields,
  };
};

export default useSaveBidEdits;