import InteriorPage from "../../components/pageLayout/InteriorPage";
import React, { useEffect } from "react";
import { AgGridTable, useAuth } from "component-library";
import { Briefcase } from "react-feather";
import AddRFQHeaderButtons from "./AddRFQHeaderButtons";
import VendorFilters from "./VendorFilters";
import { RiseLoader } from "react-spinners";
import EditContactForm from "../../components/Contacts/EditContactForm";
import useAddRFQContact from "../hooks/useAddRFQContact";
import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import useCreateRFQ from "./hooks/useCreateRFQ";
import useVendorSelection from "./hooks/useVendorSelection";
import ConfirmationModal from "./ConfirmationModal";
import useRFQs from "./useRFQs";
import Pagination from "../../components/Pagination";
import addRFQConfig from "../../config/addRFQConfig";
import gridRowHeights from "../../utils/gridRowHeights";

const AddRFQs = () => {
  const { id } = useParams();
  const { bid } = useRFQs();
  const { user } = useAuth();

  const {
    VendorSelector,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    paginatedVendors,
    selectedVendorIds,
    vendors,
    setVendors,
    searchVendors,
    setQ,
    vendorsLoading,
    vendorsError,
    updateCategory,
    updateCertification,
    selectedCategories,
    selectedCertifications,
  } = useVendorSelection();

  const { rfqsAdded, sendLoading, sendError, sendRFQ, confirmSendRFQ, showConfirmation, setShowConfirmation } =
    useCreateRFQ(selectedVendorIds);

  const { addNewContact, setAddNewContact, newVendorContact, setNewVendorContact } = useAddRFQContact(
    vendors,
    setVendors
  );

  useEffect(() => {
    if (!!selectedCategories && selectedCategories.length) {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories]);

  const HeaderButtons = () => (
    <AddRFQHeaderButtons selectedVendorIds={selectedVendorIds} sendRFQ={sendRFQ} setAddNewContact={setAddNewContact} />
  );

  const getSortedVendors = () => {
    let unsortedVendors = paginatedVendors.map((v) => ({
      ...v,
      company_name: !!v.company && v.company.name,
      contact_name: !!v.contact && `${v.contact.first_name} ${v.contact.last_name}`,
      territory: !!v.contact && v.contact.territory_location,
    }));

    return unsortedVendors.sort((a, b) => {
      if (a.company_name < b.company_name) {
        return -1;
      }
      if (a.company_name > b.company_name) {
        return 1;
      }
      return 0;
    });
  };

  return (
    <InteriorPage
      navTitle={(!!bid && bid.name) || ""}
      pageTitle="RFQs"
      navIcon={<Briefcase color="#fff" size={14} />}
      pageSubtitlePrimaryContent={VendorSelector}
      pageSubtitleSecondaryContent={HeaderButtons}
      error={vendorsError || sendError}
      titleTag="Bids — Quotes"
    >
      {addNewContact && (
        <EditContactForm
          setAddingContact={setAddNewContact}
          setEditedContact={setNewVendorContact}
          contact={newVendorContact}
          contactType="Vendor"
        />
      )}
      <ConfirmationModal
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        confirmSendRFQ={confirmSendRFQ}
        sendLoading={sendLoading}
        bidName={!!bid && bid.name}
      />
      <div className="position-relative">
        {!!rfqsAdded && <Navigate to={`/bid/${id}/rfqs`} />}
        <VendorFilters
          selectedCategories={selectedCategories}
          updateCategory={updateCategory}
          selectedCertifications={selectedCertifications}
          updateCertification={updateCertification}
          searchVendors={searchVendors}
          setQ={setQ}
          loading={vendorsLoading}
        />
        <div className="position-relative">
          {(vendorsLoading || sendLoading) && (
            <div className="loaderWrapper">
              <RiseLoader color={"#0071ce"} />
            </div>
          )}
          <AgGridTable
            gridData={getSortedVendors()}
            gridConfig={addRFQConfig}
            loading={vendorsLoading}
            rowHeight={gridRowHeights.medium}
            id={`${user.id}_add_rfq_table`}
            presetStyle="sortableTable"
          />
          <Pagination
            items={paginatedVendors}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            total={vendors.length}
          />
        </div>
      </div>
    </InteriorPage>
  );
};

export default AddRFQs;
