import React, { useState } from "react";
import { Button } from "react-bootstrap";
import CategoryButton from "./CategoryButton";
import { ChevronRight, ChevronDown } from "react-feather";
import styles from "./ContactsAdmin.module.scss";
import { useAuth } from "component-library";

const CategoryFilters = ({ updateCategory, selectedCategories }) => {
  const { config } = useAuth();
  const [filtersOpen, setFiltersOpen] = useState(false);

  return (
    <div>
      <div className="d-flex flex-row align-items-center mb-2">
        <Button
          className="mb-2 lg ps-0 pe-0 me-3 font-weight-bold"
          onClick={() => setFiltersOpen(!filtersOpen)}
          variant="link"
          aria-label={
            filtersOpen ? "Hide additional categories to filter by" : "Show additional categories to filter by"
          }
        >
          Filter By Category
          {!!filtersOpen ? <ChevronDown size="16" /> : <ChevronRight size="16" />}
        </Button>
      </div>
      {!!filtersOpen ? (
        <div className={styles.categoryFilters}>
          {!!config.company_categories &&
            !!config.company_categories.length &&
            config.company_categories.map((cat) => (
              <CategoryButton
                key={cat.id}
                category={cat}
                updateCategory={updateCategory}
                active={selectedCategories.includes(cat.id)}
                setFiltersOpen={setFiltersOpen}
              />
            ))}
        </div>
      ) : (
        <>
          {!!config.company_categories &&
            !!config.company_categories.length &&
            config.company_categories.map(
              (cat) =>
                selectedCategories.includes(cat.id) && (
                  <CategoryButton
                    key={cat.id}
                    category={cat}
                    updateCategory={updateCategory}
                    active={true}
                    setFiltersOpen={setFiltersOpen}
                  />
                )
            )}
        </>
      )}
    </div>
  );
};

export default CategoryFilters;
