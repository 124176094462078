import React from "react";
import PropTypes from "prop-types";
import { ChevronDown, ChevronRight } from "react-feather";
import styles from "./styles.module.scss";
import useEstimateConversion from "../useEstimateConversion";
import PhaseBadges from "./Phases/PhaseBadges";

const TaskHeader = ({ updateItem, editTasks, bidItem, open }) => {
  const { phases, toggleOpen, importable } = useEstimateConversion();

  return (
    <td>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center w-75">
          {!!bidItem?.sub_items?.length &&
            (open ? (
              <ChevronDown color={"#7b7b7b"} size={15} onClick={() => toggleOpen(bidItem.id)} />
            ) : (
              <ChevronRight color={"#7b7b7b"} size={15} onClick={() => toggleOpen(bidItem.id)} />
            ))}
          {!!editTasks && (
            <>
              <label htmlFor={`${bidItem.id}-task-name`} aria-label="bid item task name edit"></label>
              <input
                id={`${bidItem.id}-task-name`}
                name={`${bidItem.id}-task-name`}
                type="text"
                className="form-control"
                value={bidItem.name}
                onChange={(e) => updateItem("name", e.target.value)}
                placeholder="Task Name"
              />
            </>
          )}
          {!editTasks && <span className={styles.materialName}>{bidItem.name}</span>}
        </div>
        {!!importable && !editTasks && (
          <PhaseBadges open={open} bidItem={bidItem} updateItem={updateItem} projectPhases={phases} />
        )}
      </div>
    </td>
  );
};

TaskHeader.propTypes = {
  updateItem: PropTypes.func.isRequired,
  editTasks: PropTypes.bool,
  bidItem: PropTypes.object.isRequired,
};

export default TaskHeader;
