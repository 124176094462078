import React from "react";
import PropTypes from "prop-types";
import { dropdownStylesWithWidth } from "component-library";
import Select from "react-select";
import styles from "../createBid.module.scss";

const LiquidatedDamages = ({ item, handleChange, config, focusAdd }) => {
  const liquidatedDamagesConfig = config.liquidated_damages_config.map(
    (item) => ({
      value: item.id,
      label: item.value,
    })
  );

  const liquidatedDamagesCustomValue = liquidatedDamagesConfig.find(
    (item) => item.label.toLowerCase() === "custom"
  ).value;

  return (
    <div className="row py-2">
      <div className="col-md-4">
        <label
          htmlFor={`liquidated_damages_amount_${item.key ? item.key : item.id}`}
        >
          Liquidated Damages
        </label>
        <div className={styles.currencyInput}>
          $
          <input
            id={`liquidated_damages_amount_${item.key ? item.key : item.id}`}
            className="form-control"
            name="liquidated_damages_amount"
            type="number"
            onChange={(e) => {
              handleChange(
                "milestones",
                item.key,
                "liquidated_damages_amount",
                e.target.value
              );
            }}
            onFocus={focusAdd}
            value={
              !item.liquidated_damages_amount
                ? ""
                : item.liquidated_damages_amount
            }
          />
        </div>
      </div>
      <div className="col-md-2 d-flex align-items-end">
        <Select
          className="w-100"
          id="source"
          onChange={(e) => {
            handleChange(
              "milestones",
              item.key,
              "liquidated_damages_type",
              e.value
            );
          }}
          options={liquidatedDamagesConfig}
          styles={dropdownStylesWithWidth(450)}
          value={
            liquidatedDamagesConfig.find(
              (opt) => opt.value === item.liquidated_damages_type
            ) || 1
          }
          aria-label="Liquidated damages type"
        />
      </div>
      {!!item.liquidated_damages_type &&
        item.liquidated_damages_type === liquidatedDamagesCustomValue && (
          <div className="col-md-3">
            <label
              htmlFor={`liquidated_damages_type_other_${
                item.key ? item.key : item.id
              }`}
            >
              Custom
            </label>
            <input
              id={`liquidated_damages_type_other_${
                item.key ? item.key : item.id
              }`}
              className="form-control"
              name="liquidated_damages_type_other"
              onChange={(e) => {
                handleChange(
                  "milestones",
                  item.key,
                  "liquidated_damages_type_other",
                  e.target.value
                );
              }}
              onFocus={focusAdd}
              value={
                !item.liquidated_damages_type_other
                  ? ""
                  : item.liquidated_damages_type_other
              }
            />
          </div>
        )}
    </div>
  );
};

LiquidatedDamages.propTypes = {
  item: PropTypes.object,
  handleChange: PropTypes.func,
  config: PropTypes.object,
  focusAdd: PropTypes.func,
};

export default LiquidatedDamages;
