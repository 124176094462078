import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import AddContact from "../../components/Contacts/AddContact";
import { useAuth } from "component-library";
import ContactControl from "../../components/Contacts/ContactControl";
import Select from "react-select";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";

const Contacts = ({
  contacts = [],
  addContact,
  removeContact,
  error,
  setError,
  editing,
  projectOwners,
  updateProjectOwner,
  projectOwner,
  source,
  updateSource,
}) => {
  const { userCan } = useAuth();
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [projectOwnerOptions, setProjectOwnerOptions] = useState([]);
  const [currentOwner, setCurrentOwner] = useState();
  const [label, setLabel] = useState();
  const addProjectOwner = (ownerId) => {
    const selectedOwner = projectOwners.find((owner) => owner.contact.id === ownerId && owner);
    setCurrentOwner(selectedOwner.contact);
    updateProjectOwner(ownerId);
  };

  useEffect(() => {
    if (!!projectOwner) {
      setCurrentOwner(projectOwner);
    }
  }, [projectOwner]);

  useEffect(() => {
    if (projectOwners) {
      setProjectOwnerOptions(
        projectOwners.map((x) => ({
          label: `${x.contact.first_name} ${x.contact.last_name}`,
          value: x.contact.id,
        }))
      );
    }

    if (!!projectOwners && projectOwners.length === 1) {
      setCurrentOwner(projectOwners[0].contact);
      updateProjectOwner(projectOwners[0].contact.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectOwners]);

  const noAdditional =
    !contacts.length ||
    !contacts.filter((c) => (typeof c.role === "string" ? c.role === "other" : c.role_id === 32)).length;

  const AddContactButton = ({ role, label }) => (
    <div className="d-flex h-100 circle-file-dropzone">
      <Button
        variant="outline-secondary"
        className="dropzone justify-content-center align-self-center btn btn-outline-secondary thirteen p-1"
        onClick={() => {
          setSelectedRole(role);
          setLabel(label);
          setModalOpen(true);
        }}
        aria-label="Add new contact"
      >
        Add New Contact +
      </Button>
    </div>
  );

  const unsetCurrentOwner = () => {
    setCurrentOwner();
  };

  return (
    <>
      {editing && (
        <>
          {!!projectOwners && projectOwners.length === 0 && (
            <div className="pb-3">
              <h5 className="light-text sm text-uppercase mb-3">Project Engineer</h5>
              <Alert variant="danger">
                <span>
                  You must add a Project Engineer to the project before creating submittals. You can add a Project
                  Engineer on the
                </span>
                <Link to={`/project/${id}/settings`}> project edit page</Link>.
              </Alert>
            </div>
          )}
          {!currentOwner && !!projectOwnerOptions && projectOwnerOptions.length > 1 && (
            <div className="pb-3">
              <h5 className="light-text sm text-uppercase mb-3">Project Engineer</h5>
              <Select
                name={`project-owner`}
                className="w-100"
                id={`project-owner`}
                value={projectOwnerOptions.find((po) => po.value === currentOwner)}
                onChange={({ value }) => addProjectOwner(value)}
                options={projectOwnerOptions}
                isSearchable={false}
              />
            </div>
          )}
        </>
      )}
      <div className="contacts mb-2 py-2 px-1">
        {!!editing && !!currentOwner && (
          <>
            <div className="contact d-flex">
              <ContactControl
                title={"Project Engineer"}
                role={"project-owner"}
                contact={currentOwner}
                removeContact={!!projectOwners && projectOwners.length > 1 && unsetCurrentOwner}
              />
            </div>
          </>
        )}

        {!editing && (
          <>
            {!!projectOwner && (
              <div className="contact d-flex">
                <ContactControl title={"Project Engineer"} role={"project-owner"} contact={projectOwner} />
              </div>
            )}
          </>
        )}

        <div className="contact d-flex flex-lg-column">
          {!!source ? (
            <ContactControl
              title={"Source"}
              role={"source"}
              editing={editing}
              contact={source}
              removeContact={!!editing && removeContact}
            />
          ) : (
            <>
              {addContact && (
                <>
                  <h5 className="light-text sm text-uppercase mb-1">Source</h5>
                  {!!editing && <AddContactButton role="source" label="Source" />}
                </>
              )}
            </>
          )}
        </div>
        {contacts &&
          contacts
            .filter((c) => (typeof c.role === "string" ? c.role === "other" : c.role_id === 32))
            .map(({ role, contact }, i) => (
              <div className="contact d-flex" key={contact.id}>
                <ContactControl
                  title={"Additional Contact"}
                  role={role}
                  contact={contact}
                  removeContact={!!editing && removeContact}
                />
              </div>
            ))}
        {!!editing && userCan("submittal-edit") && addContact && (
          <div className="contact d-flex flex-lg-column">
            {noAdditional && <h5 className="light-text sm text-uppercase mb-1">Additional Contacts</h5>}
            <AddContactButton role="other" label="Additional Contact" />
          </div>
        )}

        {modalOpen && (
          <AddContact
            show={modalOpen}
            hide={() => setModalOpen(false)}
            addContact={addContact}
            updateSource={updateSource}
            error={error}
            setError={setError}
            selectedRole={selectedRole}
            label={label}
            contacts={contacts}
          />
        )}
      </div>
    </>
  );
};

Contacts.propTypes = {
  contacts: PropTypes.array,
  error: PropTypes.any,
  addContact: PropTypes.func,
  removeContact: PropTypes.func,
  setError: PropTypes.func,
  updateProjectOwner: PropTypes.func,
  updateSource: PropTypes.func,
  source: PropTypes.object,
  projectOwner: PropTypes.object,
  projectOwners: PropTypes.array,
  editing: PropTypes.bool,
};
export default Contacts;
