import {
  current_condition,
  dew_point,
  humidity,
  pressure,
  temperature,
  time_of_reading,
  wind_direction,
  wind_gust,
  wind_speed,
} from "./columnDefs";

const columnDefs = [
  time_of_reading,
  temperature,
  dew_point,
  humidity,
  wind_direction,
  wind_gust,
  wind_speed,
  pressure,
  current_condition,
];

export default columnDefs;