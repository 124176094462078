import React from "react";
import useProvideSuperAdmin from "./useProvideSuperAdmin";
import superAdminContext from "./superAdminContext";

function ProvideSuperAdmin({ children }) {
  const superAdmin = useProvideSuperAdmin();
  return <superAdminContext.Provider value={superAdmin}>{children}</superAdminContext.Provider>;
}

export default ProvideSuperAdmin;
