import QtyRepEst from "../columnRenders/QtyRepEst";

const qtyRepEst = {
  headerName: "Qty Rep/Est",
  field: "completed_quantity",
  resizable: true,
  cellRenderer: QtyRepEst,
  width: 175,
  headerClass: "ag-right-aligned-header",
};

export default qtyRepEst;
