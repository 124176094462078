import React from "react";
import ActiveSections from "./ActiveSections";
import InteriorPage from "../../../../../components/pageLayout/InteriorPage";
import Tabs from "../../Tabs";
import AdminIcon from "../../../../../icons/AdminIcon";
import useModules from "./useModules";
import { Alert } from "react-bootstrap";
import { useAuth } from "component-library";
import HeaderPortal from "../../../../../components/HeaderPortal";

const Modules = () => {
  const { modules, handleToggle, successMessage, setSuccessMessage, handleSubmit, SaveButton } = useModules();

  const { modulesLoading, modulesError } = useAuth();

  return (
    <InteriorPage
      pageTitle="Super Admin Settings"
      navTitle="Admin"
      pageSubtitlePrimaryContent={Tabs}
      navIcon={<AdminIcon color="#fff" size={14} />}
      loading={modulesLoading}
    >
      {!!modulesError && <Alert variant="danger">{modulesError}</Alert>}
      {!!successMessage && (
        <Alert variant="success" dismissible onClick={() => setSuccessMessage()}>
          {successMessage}
        </Alert>
      )}

      <form id="saveSuperAdminSettings" onSubmit={handleSubmit}>
        <ActiveSections modules={modules} handleToggle={handleToggle} />
        <HeaderPortal>
          <SaveButton />
        </HeaderPortal>
      </form>
    </InteriorPage>
  );
};

export default Modules;
