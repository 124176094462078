import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import contacts from "../../../api/contacts";
import { useApi } from "component-library";

const ArchiveCompany = ({ company, setCompany, setArchiving, contact }) => {
  const { request, data } = useApi(contacts.archiveContact);

  const archiveContact = () => {
    request(contact.id);
  };

  useEffect(() => {
    if (data && !!data.success) {
      setCompany(data.contact);
      setArchiving(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Modal dialogClassName="modal-lg" centered animation={false} show={true} onHide={() => setArchiving(false)}>
      <Modal.Header closeButton>
        <h5 className="mb-0 med">
          {!!contact && `Archive Contact ${contact && `${contact.first_name} ${contact.last_name}`}`}
        </h5>
      </Modal.Header>
      <Modal.Body>Are you sure you want to archive this contact?</Modal.Body>
      <Modal.Footer>
        <Button className="mt-2" onClick={() => setArchiving(false)}>
          Cancel
        </Button>
        <Button variant="tertiary" className="mt-2" onClick={() => archiveContact()}>
          Confirm Archive
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArchiveCompany;
