import { useApi, dailyFieldlogsApi, HeaderButton } from "component-library";
import { useState, useEffect, forwardRef } from "react";
import useProject from "../../../../hooks/useProject";
import useResourceManagement from "../../../../../_resourceManagement/context/useResourceManagement";
import removeDuplicateValues from "../../../../../utils/removeDuplicateValues";
import useDailyFieldLogs from "./context/useDailyFieldLogs";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftCircle, ArrowRightCircle, ChevronDown, Eye } from "react-feather";
import { Button, Dropdown } from "react-bootstrap";
import { DateTime } from "luxon";
import styles from "./styles.module.scss";

const useDailyFieldlog = () => {
  const { project, fieldMgmtDateRange, dailyFieldLogLoaded, setDailyFieldLogLoaded, setWeeklyReportDateRange } =
    useProject();
  const { id } = useParams();
  const { setUpdateRequests, getRequestParams } = useDailyFieldLogs();
  const [selectedForemenIds, setSelectedForemenIds] = useState([]);
  const [openDailyReport, setOpenDailyReport] = useState(false);
  const [selectedForemen, setSelectedForemen] = useState([]);
  const [foremen, setForemen] = useState();
  const { calendarClosed, setCalendarClosed } = useResourceManagement();
  const history = useNavigate();

  const {
    request: foremenRequest,
    data: foremenData,
    error: foremenError,
    loading: foremenLoading,
  } = useApi(dailyFieldlogsApi.getForemen);

  useEffect(() => {
    if (!dailyFieldLogLoaded) {
      setDailyFieldLogLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyFieldLogLoaded]);

  useEffect(() => {
    if (!!calendarClosed && !!fieldMgmtDateRange[0] && !!project && id && !foremenLoading) {
      const requestParams = getRequestParams();
      foremenRequest(requestParams);
      setCalendarClosed(false);
      setUpdateRequests(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarClosed, project]);

  useEffect(() => {
    if (foremenData?.foreman) {
      setUpdateRequests(false);
      const uniqueForemen = removeDuplicateValues(foremenData.foreman, "foreman_id");
      setForemen(uniqueForemen);
      setSelectedForemenIds(
        foremenData?.foreman?.map((foreman) => {
          return foreman.foreman_id;
        })
      );
      setSelectedForemen(foremenData.foreman);
    } else {
      setSelectedForemenIds([]);
      setSelectedForemen([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foremenData]);

  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <button
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ border: 0, backgroundColor: "transparent" }}
    >
      {children}
    </button>
  ));

  const getAllWeeks = (date1, date2) => {
    if (!date1) {
      return [];
    }
    const parsed1 = Date.parse(date1);
    const oneDay = 24 * 60 * 60 * 1000;
    let allWeeks = [];
    let curDate = parsed1;

    while (true) {
      const curr = new Date(curDate);
      const week = [0, 6].map((offset) => {
        const day = curr.getDate() - curr.getDay() + offset;
        const date = new Date(curr.getFullYear(), curr.getMonth(), day);
        const formatted = DateTime.fromJSDate(date).toFormat("MM/dd/yy");
        return { date, formatted };
      });
      allWeeks.push({
        start: week[0],
        end: week[1],
      });
      if (!date2) {
        break;
      }
      const parsed2 = Date.parse(date2);
      curDate += oneDay;
      if (curDate > parsed2) {
        break;
      }
    }
    let uniqueWeeks = [];
    allWeeks.forEach((week) => {
      if (
        !uniqueWeeks
          .map((item) => `${item.start.formatted}-${item.end.formatted}`)
          .includes(`${week.start.formatted}-${week.end.formatted}`)
      ) {
        uniqueWeeks.push(week);
      }
    });
    return uniqueWeeks;
  };

  const handleWeeklyReport = (week) => {
    setWeeklyReportDateRange([week.start.date, week.end.date]);
    history(`/project/${project?.id}/field-management/weekly-reports`);
  };

  const SecondaryButton = () => {
    const weeks = getAllWeeks(fieldMgmtDateRange[0], fieldMgmtDateRange[1]);
    const oneDateSelected =
      Date.parse(fieldMgmtDateRange[0]) === Date.parse(fieldMgmtDateRange[1]) || fieldMgmtDateRange.length === 1;
    return (
      <div className="d-flex align-items-center justify-content-end">
        <HeaderButton
          icon={openDailyReport ? <ArrowRightCircle size={16} color="#0071ce" /> : <ArrowLeftCircle size={16} color="#0071ce" />}
          ariaLabel="Toggle daily report"
          onClick={() => setOpenDailyReport(!openDailyReport)}
          text={openDailyReport ? "Close Daily Report" : "Open Daily Report"}
          disabled={!oneDateSelected}
        />
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>
            <Button
              variant="link"
              className="sm-med d-flex align-items-center"
              onClick={() => {
                if (weeks.length === 1) {
                  handleWeeklyReport(weeks[0]);
                }
              }}
              disabled={!weeks.length}
            >
              <Eye size={16} />
              <span className="dark-text ms-2">Weekly Report</span>
            </Button>
          </Dropdown.Toggle>
          {weeks.length > 1 && (
            <Dropdown.Menu className={styles.customDropdown}>
              {weeks.map((week, index) => (
                <Dropdown.Item key={`week_${index}`} onClick={() => handleWeeklyReport(week)}>
                  <div className={styles.week}>{`${week.start.formatted} - ${week.end.formatted} `}</div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          )}
        </Dropdown>
        {openDailyReport && (
          <Button
            variant="link"
            className="sm-med d-flex align-items-center"
            onClick={() => {
              console.log("Send Daily Report");
            }}
          >
            <span className="dark-text me-2">Send</span>
            <ChevronDown size={16} />
          </Button>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (fieldMgmtDateRange[0] && fieldMgmtDateRange[1]) {
      const oneDateSelected =
        Date.parse(fieldMgmtDateRange[0]) === Date.parse(fieldMgmtDateRange[1]) || fieldMgmtDateRange.length === 1;
      if (!oneDateSelected) {
        setOpenDailyReport(false);
      }
    } else {
      setOpenDailyReport(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldMgmtDateRange]);

  return {
    foremen,
    selectedForemenIds,
    setSelectedForemenIds,
    foremenError,
    foremenLoading,
    selectedForemen,
    setSelectedForemen,
    getRequestParams,
    SecondaryButton,
    openDailyReport,
  };
};

export default useDailyFieldlog;
