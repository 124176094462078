export const createInitialState = ({ day, report }) => {
  return {
    photosList: [],
    albumsList: [],
    q: "",
    dateRange: [new Date(report?.start_date?.replace(/-/g, "/")), new Date(report?.end_date?.replace(/-/g, "/"))],
    selectedPhotos: day?.files || [],
    selectedPhotoIds: day?.files?.map((file) => file?.id) || [],
    view: "photos",
    viewingAlbumId: null,
    showUploadModal: false,
    message: "",
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "set_initial_albums_list":
      return {
        ...state,
        albumsList: action.albums,
      };
    case "set_initial_photos_list":
      return {
        ...state,
        photosList: action.photos,
      };
    case "update_photos_list":
      return {
        ...state,
        photosList: action.photosList,
      };
    case "update_date_range":
      return {
        ...state,
        dateRange: !!action.dateRange?.length
          ? [new Date(action.dateRange[0]), action.dateRange[1] ? new Date(action.dateRange[1]) : ""]
          : [],
      };
    case "set_q":
      return {
        ...state,
        q: action.q,
      };
    case "update_selected_photos":
      return {
        ...state,
        selectedPhotos: action.updatedPhotos,
        selectedPhotoIds: action.updatedIds,
      };
    case "update_view":
      return {
        ...state,
        view: action.status,
        viewingAlbumId: action?.albumId || null,
      };
    case "toggle_upload_modal":
      return {
        ...state,
        showUploadModal: !state.showUploadModal,
      };
    case "update_success_message":
      return {
        ...state,
        message: action?.message || "",
      };
    default:
      return state;
  }
};
