import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { submittalConfig, useAuth } from "component-library";

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 3,
    content: '" "',
    display: "block",
    marginRight: 6,
    height: 6,
    width: 6,
    flexShrink: 0,
  },
});

const ApprovalSelect = ({ item, handleChangeApproval, customStyles, disabled = false }) => {
  const { config } = useAuth();
  const colourStyles = {
    container: (styles) => ({
      ...styles,
      ...customStyles.container,
    }),
    control: (styles) => ({
      ...styles,
      ...customStyles.control,
    }),
    valueContainer: (styles) => ({
      ...styles,
      ...customStyles.valueContainer,
      paddingLeft: 3,
      paddingRight: 0,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      ...customStyles.dropdownIndicator,
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      ...customStyles.indicatorsContainer,
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      ...dot(data.color),
      ...customStyles.singleValue,
      fontSize: 12,
      marginRight: 0,
      width: "100%",
    }),
    input: (styles) => ({
      ...styles,
      ...dot(),
      height: 6,
      ...customStyles.input,
    }),
    option: (styles, { data }) => ({
      ...styles,
      ...dot(data.color),
      ...customStyles.option,
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none",
    }),
  };

  const options = config.submittal_statuses.map((status) => ({
    label: status.name,
    value: status.id,
    color: submittalConfig.statusColors[status.id],
  }));

  return (
    <Select
      name={`status-${item.id}`}
      className="w-100"
      id={`status-${item.id}`}
      onChange={handleChangeApproval(item)}
      options={options}
      value={options.find((opt) => opt.value === item.status_id)}
      styles={colourStyles}
      isSearchable={false}
      aria-label="approval status"
      isDisabled={disabled}
    />
  );
};

ApprovalSelect.propTypes = {
  item: PropTypes.object.isRequired,
  handleChangeApproval: PropTypes.func.isRequired,
  customStyles: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ApprovalSelect;
