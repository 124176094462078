import React from "react";
import Number from "./Number";
import styles from "./styles.module.scss";

const FooterStats = ({ weeklyReport }) => {
  return (
    <div className={styles.footerStats}>
      <Number number={weeklyReport?.total_this_week || 0} color="#0E2B56" label="Total for the week" />
      <Number number={weeklyReport?.previous_report_total || 0} color="#FF6700" label="Total used last report" />
      <Number number={weeklyReport?.total_working_days_used || 0} color="#0071CE" label="Total used to date" />
      <Number
        number={weeklyReport?.working_days_specified || 0}
        color="#0E2B56"
        label="Number of working days specified"
      />
      <Number
        number={weeklyReport?.percent_contract_time || 0}
        color="#6FCB21"
        label="% of contract time used to date"
      />
      <Number
        number={weeklyReport?.report?.project?.percent_contract_completed || 0}
        color="#0E2B56"
        label="% of contract quantity completed to date"
      />
    </div>
  );
}

export default FooterStats;