import { useApi, purchaseOrdersApi } from "component-library";
import { useEffect, useState } from "react";

const useAddNote = (purchaseOrder, setPurchaseOrder, setLoading) => {
  const [note, setNote] = useState("");

  const {
    data: addNoteData,
    loading: addNoteLoading,
    error: addNoteError,
    request: addNoteRequest,
  } = useApi(purchaseOrdersApi.addNote);

  const addNote = (text) => {
    addNoteRequest(text, purchaseOrder.id);
  };

  useEffect(() => {
    if (addNoteData && addNoteData.note) {
      setPurchaseOrder({
        ...purchaseOrder,
        notes: [addNoteData.note, ...purchaseOrder.notes],
      });
      setNote('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNoteData]);

  useEffect(() => {
    if (!!addNoteLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNoteLoading]);

  return {
    addNoteData,
    addNoteLoading,
    addNoteError,
    addNote,
    note,
    setNote,
  };
};

export default useAddNote;
