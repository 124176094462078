import { DateTime } from "luxon";
import { useState, useEffect, useMemo } from "react";

const useGalleryView = ({ photosList, albumsList }) => {
  const [currentView, setCurrentView] = useState(0);

  const combineList = (photosList, albumsList) => {
    if (!albumsList) {
      return photosList;
    }

    const combinedPinnedList = [...photosList, ...albumsList]
      .filter((item) => (item.photo_count === undefined ? true : item.first_photo))
      .sort((a, b) => {
        const createdDateA = DateTime.fromJSDate(new Date(a.created_at)).toFormat("yyyy-MM-dd HH:mm:ss.SSS");
        const createdDateB = DateTime.fromJSDate(new Date(b.created_at)).toFormat("yyyy-MM-dd HH:mm:ss.SSS");
        if (createdDateA < createdDateB) {
          return -1;
        }
        if (createdDateA > createdDateB) {
          return 1;
        }
        return 0;
      });
    return combinedPinnedList;
  };

  const combinedList = useMemo(() => {
    return combineList(photosList, albumsList);
  }, [photosList, albumsList]);

  useEffect(() => {
    setCurrentView(0);
  }, [combinedList]);

  return {
    combinedList,
    currentView,
    setCurrentView,
  };
};

export default useGalleryView;
