import { Joi } from "component-library";
import regexPhone from "../utils/regexPhone";

const schema = {
  name: Joi.saneString().required().label("Name"),
  company_categories: Joi.array(),
  certifications: Joi.array(),
  locations: Joi.array().min(1).items(
    Joi.object().keys({
      name: Joi.string().required().label("Name"),
      phone: Joi.saneString().regex(regexPhone).label("Phone"),
      address: Joi.object().required().keys({
        address1: Joi.string().required().label("Address"),
        address2: Joi.saneString().label("Address 2"),
        city: Joi.string().required().label("city"),
        state: Joi.string().required().label("State"),
        zip: Joi.string().required().label("ZIP"),
      }),
    })
  ).label("Locations").required()
};

const initialData = {
  name: "",
  company_categories: [],
  certifications: [],
  locations: [{ id: 1 }],
};

export { schema, initialData };
