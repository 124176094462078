import React from "react";
import { Button } from "react-bootstrap";
import { X } from "react-feather";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import { CompanyLocationContactCard } from "component-library";
import logo from "../../../assets/boomerang-logo-stacked.png";

const LocationControl = ({ location, removeLocation, title, showLogo = false }) => {
  const newLocation = location?.address
    ? {
        archived: location?.archived,
        company_name: location?.company_name,
        location_name: location?.name,
        address1: location?.address?.address1,
        address2: location?.address?.address2,
        city: location?.address?.city,
        state: location?.address?.state,
        zip: location?.address?.zip,
        phone: location.phone
      }
    : location;

  return (
    <div
      className={`light-text p-3 w-100 mb-2 position-relative ${styles.locationControlWrapper}`}
      style={{
        minWidth: 172,
        backgroundColor: "#F7F7F7",
        borderRadius: "1.2rem",
      }}
    >
      {showLogo && newLocation?.company_name === "Boomerang Corp." ? 
        <img src={logo} alt="Boomerang Logo" className={styles.logoImage} 
      /> : null}
      {title && <div className={`${styles.locationTitle} text-quaternary sm text-uppercase`}>{title}</div>}
      {!!removeLocation && (
        <Button size="sm" variant="link" onClick={() => removeLocation(location)} className={styles.close}>
          <X size="14" color="#979797" />
        </Button>
      )}
      {!!location && <div className="hidePrint"><CompanyLocationContactCard hasPadding={false} location={newLocation} /></div>} 
      {!!location && <div className="showPrint"><CompanyLocationContactCard hasPadding={true} location={newLocation} /></div>}
    </div>
  );
};

LocationControl.propTypes = {
  location: PropTypes.object,
  role: PropTypes.string,
  removeLocation: PropTypes.func,
  title: PropTypes.string,
};

export default LocationControl;
