import React, { useEffect, useState } from "react";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import useProject from "../../hooks/useProject";
import { Calendar } from "react-feather";
import "devextreme/dist/css/dx.light.css";
import styles from "./styles.module.scss";

import useSchedule from "./useSchedule";
import { Scheduler } from "devextreme-react";
import { useNavigate } from "react-router-dom";
const views = ["day", "week", "workWeek", "month"];
const colors = ["#338DD8", "#51B2BE", "#FFA400", "#A38BCF"];

const CalendarView = () => {
  let history = useNavigate();
  const { project, pageTitle, PageTag } = useProject();
  const { data, loading, error } = useSchedule();
  const [calendar, setCalendar] = useState();

  useEffect(() => {
    if (!!data && !!data.schedule) {
      setCalendar(
        data.schedule.tasks.map((t, i) => ({
          ...t,
          text: t.title,
          startDate: new Date(t.start),
          endDate: new Date(t.end),
          color: colors[i % 4],
        }))
      );
    }
  }, [data]);

  const handleTaskDoubleClick = (e) => {
    if (e.key !== 0) {
      history(`/project/${project.id}/schedule/task/${e.appointmentData.id}`);
      e.cancel = true;
    }
  };

  return (
    <InteriorPage
      loading={loading}
      error={error}
      navTitle={pageTitle}
      navIcon={<Calendar color="#fff" size={14} />}
      pageTitle="Schedule"
      NavContent={PageTag}
    >
      <div className="dx-viewport">
        <div className={`${styles.gantt} position-relative`}>
          {!!calendar && (
            <Scheduler
              timeZone="America/Chicago"
              dataSource={calendar}
              views={views}
              height={778}
              defaultCurrentView="month"
              // defaultCurrentDate={currentDate}
              startDayHour={9}
              onAppointmentDblClick={handleTaskDoubleClick}
            />
          )}
        </div>
      </div>
    </InteriorPage>
  );
};

export default CalendarView;
