import { FileText } from "react-feather";
import SharePage from "../../SharePage";

const SharedNotes = () => {
  return (
    <SharePage
      pageTitle={"Notes"}
      subtitle="14 notes"
      loading={false}
      error={null}
      fillScreen
      navIcon={<FileText color="#0071CE" size={16} />}
    >
      <>Shared Notes Page</>
    </SharePage>
  );
};

export default SharedNotes;
