import React from "react";

const GoogleMaps = ({ renderTextField }) => {
  return (
    <div className="row">
      <div className="col-6">
        {renderTextField({
          name: "gmap_key",
          label: "Google Maps Api Key",
          placeholder: "Enter Gmaps api key here",
        })}
      </div>
    </div>
  );
};

export default GoogleMaps;
