import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { Popover, OverlayTrigger } from "react-bootstrap";
import categoryWidths from "./categoryWidths";

const Categories = ({ data }) => {
  const [visible, setVisible] = useState(1);
  const [visibleCats, setVisibleCats] = useState([]);
  const [hiddenCats, setHiddenCats] = useState([]);
  const wrap = useRef(null);

  const getNumberThatFit = () => {
    let width = 172;
    let count = 0;
    let sum = 22;
    data?.categories.forEach((c) => {
      if (sum < width) {
        let w = categoryWidths[c.name.toUpperCase()];
        if (!w) w = 5.2 * c.name.length;
        sum = sum + 20 + w;
        count++;
      }
    });
    if (data?.categories.length >= count && sum > width) count = count - 1;
    setVisible(count);
  };

  useEffect(() => {
    getNumberThatFit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!visible) {
      setVisibleCats(data?.categories?.slice(0, visible));
      setHiddenCats(data?.categories?.slice(visible));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className={styles.categoriesPopup}>
        {hiddenCats.map((c) => (
          <div className={`pill ${styles.category_pill} me-1`} key={c.id}>
            {c.name}
          </div>
        ))}
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="d-flex align-datas-center" ref={wrap}>
      {!!visibleCats.length &&
        visibleCats.map((c) => (
          <div className={`pill ${styles.category_pill} me-1`} key={c.id}>
            {c.name}
          </div>
        ))}
      {!!hiddenCats.length && (
        <OverlayTrigger trigger="click" placement="bottom-start" transition={false} overlay={popover} rootClose>
          <span className={styles.moreLink}>+{hiddenCats.length}</span>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default Categories;
