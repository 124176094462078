import React from "react";
import useProvideResourceManagement from "./useProvideResourceManagement";
import resourceManagementContext from "./resourceManagementContext";

function ProvideResourceManagement({ children }) {
  const resourceManagement = useProvideResourceManagement();
  return <resourceManagementContext.Provider value={resourceManagement}>{children}</resourceManagementContext.Provider>;
}

export default ProvideResourceManagement;
