import { useEffect, useState } from "react";
import { Alert, Button, ProgressBar } from "react-bootstrap";
import { Upload, Folder, XCircle } from "react-feather";
import styles from "./styles.module.scss";
import { schema, initialData } from "./photo";
import { useFormControl, useApi, photoLibraryApi, InlineUpload, useAuth } from "component-library";
import PropTypes from "prop-types";
import createQueue from "../../../../../utils/createQueue";

const useUploadPhoto = ({
  project,
  setAlbumPhotosList,
  albumPhotosList,
  setShowModal,
  setMessage,
  photosList,
  setPhotosList,
  refreshLists,
  albumId,
  showModal,
}) => {
  const { user } = useAuth();
  const [newFiles, setNewFiles] = useState([]);
  const [error, setError] = useState();
  const [photoQueue, setPhotoQueue] = useState();
  const [uploadedPhotos, setUploadedPhotos] = useState([]);

  const {
    request: uploadPhotoRequest,
    loading: uploadPhotoLoading,
    error: uploadPhotoError,
  } = useApi(photoLibraryApi.uploadPhoto);

  const handleSingleFileUpload = async (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      let newFile = {
        data: e.target.result,
        name: file.path,
        size: file.size,
        type: file.type,
        file_type: [1],
        thumbnail: 1,
        lat: null,
        lng: null,
      };
      setNewFiles((oldFiles) => (!!oldFiles?.length ? [...oldFiles, newFile] : [newFile]));
    };
    reader.readAsDataURL(file);
  };

  const onHide = () => {
    setShowModal(false);
  };

  const onSubmit = (formData) => {
    setPhotoQueue(
      formData.photos.map((photo) => {
        return {
          file: photo,
          pinned: 0,
          project_id: project.id,
          user_id: user.id,
          album_ids: !!albumId ? [albumId] : [],
        };
      })
    );
  };

  const handledCompletedTasks = (result) => {
    if (!!result.responseData?.photo)
      setUploadedPhotos((uploadedPhotos) => [...uploadedPhotos, result.responseData.photo]);
  };

  useEffect(() => {
    if (!!photoQueue?.length) {
      createQueue(
        photoQueue.map((photo) => {
          return async () => await uploadPhotoRequest(photo);
        }),
        2,
        handledCompletedTasks
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoQueue?.length]);

  useEffect(() => {
    if (uploadedPhotos?.length > 0 && uploadedPhotos?.length === photoQueue?.length) {
      handleUpdatedFiles(uploadedPhotos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedPhotos]);

  const addPendingAttachment = (file) => {
    handleSingleFileUpload(file);
  };

  const { handleSubmit, data, setFields } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const acceptedFileTypes = { "image/*": [".png", ".jpeg", ".jpg"] };

  const handleUpdatedFiles = (photosToAdd) => {
    if (!!photosToAdd.length) {
      setPhotosList([...photosToAdd, ...photosList]);
      if (!!albumId) {
        setAlbumPhotosList([...photosToAdd, ...albumPhotosList]);
      }
      setShowModal(false);
      setMessage("Photo(s) successfully uploaded");
    }

    if (!!refreshLists) {
      refreshLists();
    }
  };

  const headerContent = (
    <>
      <div className="d-flex align-items-center">
        <Upload size={16} color="#0071ce" />
        <h2 className={styles.modalTitle}>Upload New Photo(s)</h2>
      </div>
    </>
  );

  const bodyContent = (
    <>
      {!!error && <Alert variant="danger">{error}</Alert>}
      <h3 className={styles.formTitle}>Select Photo(s)</h3>
      <form className="position-relative" id="upload-photo-modal-form" onSubmit={handleSubmit}>
        {!!uploadPhotoLoading && (
          <div className="loaderWrapper">
            {photoQueue.length > 0 && (
              <ProgressBar animated striped now={(uploadedPhotos.length / photoQueue.length) * 100 || 5} />
            )}
          </div>
        )}
        <div className="form-group">
          <div className="row">
            {Object.keys(data?.photo || {}).length === 0 ? (
              <InlineUpload
                uploadText="Select File(s)"
                addAttachment={addPendingAttachment}
                accept={acceptedFileTypes}
                multiple={true}
              />
            ) : (
              <>
                <div className={`${styles.fileBorder} py-2`}>
                  <div className="d-flex ms-1">
                    <Folder className={`${styles.modalIcons}`} size="16" />
                    <div className={`${styles.simplifiedTitle} text-break thirteen ms-1`}>{data?.photo?.name}</div>
                  </div>
                  <Button
                    variant="link"
                    className="d-flex align-content-center justify-content-center p-0"
                    onClick={() => setFields({ ...data, photo: null })}
                  >
                    <XCircle className={`${styles.modalIcons}`} size="16" />
                  </Button>
                </div>
              </>
            )}
            {!!data?.photos?.length &&
              data.photos.map((photo) => (
                <div className={styles.preview} key={photo.name}>
                  <img alt="" src={photo.data} />
                </div>
              ))}
          </div>
        </div>
      </form>
    </>
  );
  const footerContent = (
    <>
      <Button
        variant="orange"
        className="med d-flex align-items-center"
        form="upload-photo-modal-form"
        type="submit"
        aria-label="Create new project photo album"
        disabled={!data?.photos?.length || uploadPhotoLoading}
      >
        Upload File(s)
      </Button>
    </>
  );

  useEffect(() => {
    if (!!newFiles?.length) {
      setFields({ ...data, photos: newFiles });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFiles]);

  useEffect(() => {
    if (uploadPhotoError) {
      setError(uploadPhotoError);
    } else {
      setError();
    }
  }, [uploadPhotoError]);

  useEffect(() => {
    if (!showModal) {
      setFields(initialData);
      setPhotoQueue([]);
      setError();
      setNewFiles([]);
      setUploadedPhotos([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return {
    headerContent,
    bodyContent,
    footerContent,
    onHide,
  };
};

useUploadPhoto.propTypes = {
  albumPhotosList: PropTypes.array,
  setAlbumPhotosList: PropTypes.func,
  setShowModal: PropTypes.func,
};

export default useUploadPhoto;
