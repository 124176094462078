import React from "react";
import styles from "./styles.module.scss";

const colors = {
  "Complete": "#7DDB2D",
  "Signature Requested": "#FFA400",
  "Signature in Progress": "#FFA400",
  "Tenant Signature in Progress": "#FFA400",
  "Signed by Supplier": "#FFA400",
  "Draft": "#F0C808",
};

const Status = ({ item: { statusName } }) => {
  return (
    <div className="d-flex">
      <div
        className={styles.statusPill}
        style={{ backgroundColor: colors[statusName] }}
      >
        {statusName}
      </div>
    </div>
  );
};

export default Status;
