import {
  costCode,
  name,
  pacing,
  qtyRepEst,
  hoursRepEarned,
  hrsEstRmn,
  percentage,
} from "../../../../../config/taskReportingConfig/taskDetailConfig/columnDefs";

const columnDefs = [costCode, name, qtyRepEst, hoursRepEarned, hrsEstRmn, pacing, percentage];

export default columnDefs;
