import React from "react";
import PropTypes from "prop-types";
import { displayDate } from "component-library";

const PermitsHistory = ({ permit }) => {
  return (
    <>
      {permit && (
        <>
          <div className="med font-weight-bold">History</div>
          {permit.history.map((history) => (
            <div className="row d-flex align-items-center" key={history.id}>
              <div className="col-3">
                {displayDate(history.created_at)}
              </div>
              <div className="font-weight-bold col-3 py-2">#{permit.number}</div>
              <div className="col-2 font-italic">{history.description}</div>
              <div className="col-4">{history.user}</div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

PermitsHistory.propTypes = {
  permit: PropTypes.object,
};

export default PermitsHistory;
