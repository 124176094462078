import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import useProject from "../../hooks/useProject";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
function ArchiveModal({ archiveModalVisible, setArchiveModalVisible, data, request, error, loading }) {
  const history = useNavigate();
  const { project } = useProject();

  const [archived, setArchived] = useState(false);

  const handleCheckboxChange = (event) => {
    setArchived(event.target.checked);
  };

  const handleClose = () => {
    setArchiveModalVisible(false);
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    request(project.id, 1);
  };

  useEffect(() => {
    if (!!data) {
      setTimeout(() => {
        handleClose();
        history(`/projects`);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Modal className="geofence-modal-wrap" animation={false} centered show={!!archiveModalVisible} onHide={handleClose}>
      {!!data ? (
        <p className="semibold m-5 text-center">Project Successfully Archived</p>
      ) : (
        <>
          <Modal.Header className={styles.moduleHeader}>
            <h5 className="semibold med">Archive Project?</h5>
          </Modal.Header>
          <Modal.Body>
            {error && <Alert variant="danger">{error}</Alert>}
            <form className="d-flex flex-column" onSubmit={(e) => handleSubmit(e)} id="archive-project-modal">
              <p>
                Are you sure you want to archive <span className="semibold">{project?.name}</span>?
              </p>
              <label className="semibold d-flex align-items-center">
                <input
                  className="m-2"
                  type="checkbox"
                  value="archived"
                  checked={archived}
                  onChange={handleCheckboxChange}
                />
                Yes, I'm sure!
              </label>
              <div className="d-flex flex-row mt-3 justify-content-end">
                <button
                  className={`btn btn-light-gray ${styles.cancelBtn}`}
                  type="button"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </button>
                <button
                  className={`btn btn-tertiary ms-2 ${styles.submitBtn}`}
                  type="submit"
                  value="Save"
                  disabled={!archived && !loading && !data}
                >
                  Archive
                </button>
              </div>
            </form>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
}

export default ArchiveModal;
