import React, { useState, useEffect } from "react";
import { dropdownStyles, useFormControl } from "component-library";
import { schema, initialData } from "../../schema/addenda";
import { Alert } from "react-bootstrap";
import { useApi, times, bidApi, useAuth } from "component-library";
import { ProjectAttachment } from "../../_projects/ProjectAttachments";

const useAddAddenda = ({ project_id, hide, setBid, bid, type, setDocLoading }) => {
  const { error, request } = useApi(bidApi.addAddenda);
  const [addendaDocs, setAddendaDocs] = useState(bid.attachments.filter((a) => a.type === "Addenda"));
  const [fileId, setFileId] = useState();
  const [upbFileId, setUpbFileId] = useState();
  const [addendaDoc, setAddendaDoc] = useState();
  const [upbFile, setUpbFile] = useState();

  const { config, userCan } = useAuth();

  useEffect(() => {
    if (bid) {
      setAddendaDocs(bid.attachments.filter((a) => a.type === "Addenda"));
    }
  }, [bid]);

  useEffect(() => {
    setAddendaDoc(addendaDocs.find((a) => a.id === fileId));
  }, [addendaDocs, fileId]);

  useEffect(() => {
    setUpbFile(addendaDocs.find((a) => a.id === upbFileId));
  }, [addendaDocs, upbFileId]);

  const onSubmit = async (formData, setLoading) => {
    formData.project_id = project_id;
    if (fileId) {
      formData.files = [fileId];
    }

    if (upbFileId) {
      formData.upb_file = upbFileId;
    }

    try {
      const { success, responseData } = await request({
        ...formData,
        addenda_types: formData.addenda_types.map((t) => ({
          project_addenda_type_id: t,
        })),
      });
      if (success) {
        setBid(responseData.project);
        hide();
      }
    } catch (message) {
      console.error(message);
      setLoading(false);
    }
  };

  const { handleSubmit, renderSubmitButton, renderCheckbox, renderTextField, renderSelectField, setFields, data } =
    useFormControl({
      schema,
      onSubmit,
      initialData,
    });

  const fields = (
    <>
      {!!error && <Alert variant="danger">{error}</Alert>}
      <div className="row">
        <div className="col-6 col-md-3">
          {renderTextField({
            name: "addenda_number",
            label: "Addendum #",
          })}
        </div>
        <div className="col-6 col-md-3 offset-md-6">
          {renderTextField({
            name: "addenda_date",
            label: "Date",
            placeholder: "mm/dd/yyyy",
            type: "date",
          })}
        </div>
      </div>
      <div className="row mb-0">
        <div className="col-md-5 mb-n1">
          {renderSelectField({
            name: "addenda_types",
            label: "Addendum Type",
            isMulti: true,
            closeMenuOnSelect: false,
            options: config.project_addenda_types.map((opt) => ({
              value: opt.id,
              label: opt.name,
            })),
            styles: dropdownStyles,
          })}
        </div>
      </div>

      {/* {(type === "Prime IDOT" || type === "Subprime IDOT") &&
        renderCheckbox({
          name: "download_ebsx_addenda",
          label: "Download EBSX Addenda File",
        })} */}
      <ProjectAttachment
        attachment={addendaDoc}
        setAttachment={setAddendaDocs}
        type="Addenda"
        projectId={bid.id}
        canUpload={userCan("bid-create") || userCan("bid-edit")}
        setDocLoading={setDocLoading}
        setFileId={setFileId}
        isBid={bid?.status === "bid"}
      />
      <div className="mt-5">Required Changes</div>
      <div className="row">
        <div className="col-md-6">
          {renderCheckbox({
            name: "bid_date_change",
            label: "Bid Date Change",
          })}
          {data.bid_date_change === 1 && (
            <div className="row">
              <div className="col-md-12">
                {renderTextField({
                  name: "bid_date",
                  label: "Bid Date",
                  placeholder: "mm/dd/yyyy",
                  type: "date",
                })}
              </div>
              <div className="col-md-12">
                {renderSelectField({
                  name: "bid_time",
                  label: "Bid Time",
                  options: times,
                })}
              </div>
              <div className="col-md-12">
                {renderSelectField({
                  name: "bid_runner_id",
                  label: "Bid Runner",
                  options: config.runners.map((opt) => ({
                    value: opt.id,
                    label: `${opt.first_name} ${opt.last_name}`,
                  })),
                })}
              </div>
            </div>
          )}
          {renderCheckbox({
            name: "bid_location_change",
            label: "Bid Location Change",
          })}
          {data.bid_location_change === 1 && (
            <div className="row">
              <div className="col-md-12">
                {renderTextField({
                  name: "bid_location",
                  label: "New Bid Location",
                })}
              </div>
            </div>
          )}
        </div>
        <div className="col-md-6">
          {!!bid.bid_todos ? (
            <>
              {bid.bid_todos.map((todo, index) =>
                !!todo.bid_todo && todo.bid_todo.name === "Pre Bid Meeting" ? (
                  <span key={index}>
                    {renderCheckbox({
                      name: "pre_bid_meeting_change",
                      label: "Pre-bid Meeting Change",
                    })}
                    {data.pre_bid_meeting_change === 1 && (
                      <div className="row">
                        <div className="col-md-12">
                          {renderTextField({
                            name: "pre_bid_meeting_date",
                            label: "New Pre-Bid Date",
                            placeholder: "mm/dd/yyyy",
                            type: "date",
                          })}
                        </div>
                        <div className="col-md-12">
                          {renderSelectField({
                            name: "pre_bid_meeting_time",
                            label: "New Pre-Bid Time",
                            options: times,
                          })}
                        </div>
                        <div className="col-md-12">
                          {renderTextField({
                            name: "pre_bid_location",
                            label: "Pre-Bid Location",
                            placeholder: "Enter a Location",
                          })}
                        </div>
                        <div className="col-md-12">
                          {renderCheckbox({
                            name: "attendance_required",
                            label: "Mandatory",
                          })}
                        </div>
                      </div>
                    )}
                  </span>
                ) : null
              )}
            </>
          ) : null}
          {!!bid.bid_todos.find((t) => t.bid_todo_id === 15) && (
            <>
              {renderCheckbox({
                name: "upb_change",
                label: "UPB Change",
              })}
              {data.upb_change === 1 && (
                <ProjectAttachment
                  attachment={upbFile}
                  setAttachment={setAddendaDocs}
                  type="Addenda"
                  projectId={bid.id}
                  canUpload={userCan("bid-create") || userCan("bid-edit")}
                  setDocLoading={setDocLoading}
                  setFileId={setUpbFileId}
                  isBid={bid?.status === "bid"}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="row mt-5">
        <div className="col">
          {renderTextField({
            name: "notes",
            label: "Notes",
          })}
        </div>
      </div>
    </>
  );
  return {
    schema,
    initialData,
    fields,
    handleSubmit,
    renderSubmitButton,
    setFields,
  };
};

export default useAddAddenda;
