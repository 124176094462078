import {
  cost_class,
  cost_code,
  date,
  equip_no,
  hours,
  job_number,
} from "./columnDefs";

const columnDefs = [cost_class, cost_code, date, equip_no, hours, job_number];

export default columnDefs;
