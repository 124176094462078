import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

const Status = ({ data }) => {
  const [pill, setPill] = useState();
  useEffect(() => {
    let value;
    switch (true) {
      case data.status === "review":
        value = {
          name: "In Progress",
          color: "#FF6700",
        };
        break;
      case data.status === "sent":
        value = {
          name: "Sent",
          color: "#6FCB21",
        };
        break;
      default:
        value = {
          name: "draft",
          color: "#D3D3D3",
        };
        break;
    }
    setPill(value);
  }, [data?.status]);
  return (
    <div style={{ backgroundColor: pill?.color }} className={styles.statusPill}>
      {pill?.name}
    </div>
  );
};

export default Status;
