import { useState, useEffect } from "react";

const usePinnedList = ({ photosList, albumsList, dateRange }) => {
  const [pinnedList, setPinnedList] = useState();
  const [unpinnedPhotos, setUnpinnedPhotos] = useState();
  const [unpinnedAlbums, setUnpinnedAlbums] = useState();

  const combinePinnedLists = (photosList, albumsList) => {
    const pinnedPhotos = photosList.filter((photo) => !!photo.pinned) || [];
    const pinnedAlbums = albumsList.filter((album) => !!album.pinned) || [];
    const combinedPinnedList = [...pinnedPhotos, ...pinnedAlbums];

    combinedPinnedList.sort((a, b) => {
      const A = a.name || a.file_name || "";
      const B = b.name || b.file_name || "";
      return A.localeCompare(B);
    });

    return combinedPinnedList;
  };

  useEffect(() => {
    if (!!photosList?.length || albumsList?.length) {
      setPinnedList(combinePinnedLists(photosList, albumsList));
    } else {
      setPinnedList([]);
    }
    if (!!photosList) {
      setUnpinnedPhotos(photosList.filter((photo) => !photo.pinned) || []);
    }
    if (!!albumsList) {
      setUnpinnedAlbums(albumsList.filter((album) => !album.pinned) || []);
    }
  }, [photosList, albumsList, dateRange]);

  return {
    pinnedList,
    setPinnedList,
    unpinnedPhotos,
    setUnpinnedPhotos,
    unpinnedAlbums,
    setUnpinnedAlbums,
  };
};

export default usePinnedList;
