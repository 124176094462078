import React from "react";
import PerJobGrid from "./PerJobGrid.js";
import PageHeader from "./PageHeader.js";
import DashboardPage from "./DashboardPage.js";
import useDashboard from "./useDashboard.js";

const PerJobPage = () => {
  const { jobs } = useDashboard();

  return (
    <DashboardPage>
      <PageHeader title="$$ Per Job" />
      <div className="row">
        <PerJobGrid jobs={jobs} />
      </div>
    </DashboardPage>
  );
};

export default PerJobPage;
