import { Joi } from "component-library";

const schema = {
  phases: Joi.array().required().label("Phases"),
};

const initialData = {
  phases: [],
};

export { schema, initialData };
