import { Joi } from "component-library";

const schema = {
  bid_parent_id: Joi.number().required().label("Bid Item Id"),
  task_parent_id: Joi.number(),
};

const initialData = {
  task_parent_id: 0,
};

export { schema, initialData };
