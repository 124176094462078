import { useState, useEffect } from "react";
import InteriorPage from "../../components/pageLayout/InteriorPage";
import { Briefcase, Tag } from "react-feather";
import {
  AgGridTable,
  useApi,
  projectApi,
  ConfirmModal,
  HeaderButton,
  FilterSearch,
  Loader,
  useAuth,
} from "component-library";
import projectArchiveConfig from "../../config/projectArchiveConfig";
import gridRowHeights from "../../utils/gridRowHeights";
import Pagination from "../../components/Pagination";
import styles from "./styles.module.scss";

const ProjectArchive = () => {
  const { user } = useAuth();

  const {
    data: archiveListData,
    request: archiveListRequest,
    error: archiveListError,
    loading: archiveListLoading,
  } = useApi(projectApi.getArchivedProjects);

  const {
    data: archiveData,
    request: archiveRequest,
    error: archiveError,
    loading: archiveLoading,
  } = useApi(projectApi.archiveProject);

  const [q, setQ] = useState("");
  const [listData, setListData] = useState([]);
  const [paginatedListData, setPaginatedListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeId, setActiveId] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const itemsPerPage = 25;

  useEffect(() => {
    if (!!user) {
      archiveListRequest(user?.id, q);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, q]);

  useEffect(() => {
    if (!!archiveListData?.project) {
      setListData(archiveListData.project);
    }
  }, [archiveListData]);

  useEffect(() => {
    if (!!archiveData) {
      setShowConfirmation(false);
      setListData(listData.filter((project) => project.id !== activeId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveData]);

  useEffect(() => {
    if (!!listData) {
      setPaginatedListData(paginateList(listData, currentPage));
    }
  }, [listData, currentPage]);

  const handleCellClick = ({ data, column }) => {
    if (["reactivate"].includes(column?.colId)) {
      setActiveId(data.id);
      setShowConfirmation(true);
    }
  };

  const handleReopenProject = () => {
    archiveRequest(activeId, 0);
  };

  const handleSearch = (value) => {
    setQ(value);
  };

  const paginateList = (list, pageNumber) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return list.slice(startIndex, endIndex);
  };

  const ProjectListLink = () => (
    <HeaderButton
      linkTo="/projects"
      text="Active Projects"
      icon={<Tag size={16} color="#0071ce" />}
      ariaLabel="Active Projects"
    />
  );

  return (
    <InteriorPage
      pageTitle="Project Archive"
      navTitle="Projects"
      error={archiveListError}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitleSecondaryContent={ProjectListLink}
    >
      <div className="d-flex flex-column">
        <div className={styles.searchFilter}>
          <FilterSearch inline searchFunction={handleSearch} loading={archiveListLoading} />
        </div>
        <AgGridTable
          gridData={paginatedListData}
          gridConfig={projectArchiveConfig}
          loading={archiveLoading || archiveListLoading}
          rowHeight={gridRowHeights.medium}
          id={`${user.id}_project_archive_listing`}
          presetStyle="sortableTable"
          handleCellClick={handleCellClick}
          fallbackMessage="No archived projects available."
        />
        <Pagination
          items={listData}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          total={listData.length}
        />
      </div>

      <ConfirmModal
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        confirmFunction={() => {
          handleReopenProject();
        }}
        loading={archiveLoading}
        error={archiveError}
        name={"Reopen Project"}
        message={"Are you sure you want to reopen this project?"}
      />
    </InteriorPage>
  );
};

export default ProjectArchive;
