import React from "react";
import styles from "./styles.module.scss";
import sectionStyles from "../styles.module.scss";

const Notes = ({ day, updateDateField }) => {
  return (
    <section className={sectionStyles.fieldSection}>
      <div className="form-group d-flex align-items-start">
        <h3 className={styles.title} htmlFor={`${day.id}-notes`}>
          Note(s)
        </h3>
        <textarea
          id={`${day.id}-notes`}
          onChange={(e) => updateDateField(day, "note", e.target.value)}
          className="med form-control"
          value={day.note}
        />
      </div>
    </section>
  );
};

export default Notes;
