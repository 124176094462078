import React, { useState } from "react";
import useBidBond from "./hooks/useBidBond";
import { displayDate } from "component-library";
import PropTypes from "prop-types";

const BidBond = ({task, setTodos, setBid, bid}) => {
  const [docLoading, setDocLoading] = useState(false);

  const { fields, renderSubmitButton, handleSubmit } = useBidBond({
    task,
    setTodos,
    setBid,
    bid,
    setDocLoading,
  });

  return (
    <form className="row position-relative" onSubmit={handleSubmit}>
      <div className="col-md-8">{fields}</div>
      <div className="col-md-4 d-flex flex-column justify-content-between text-end p-3">
        <div>{displayDate(new Date().toISOString())}</div>
        <div className="flex-end">{renderSubmitButton("Mark Complete", !!docLoading)}</div>
      </div>
    </form>
  );
};

BidBond.propTypes = {
  task: PropTypes.object.isRequired,
  setTodos: PropTypes.func.isRequired,
  setBid: PropTypes.func.isRequired,
  bid: PropTypes.object.isRequired,
};

export default BidBond;
