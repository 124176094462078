import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./createBid.module.scss";
import StartEndDate from "./MilestoneSections/StartEndDate";
import Incentive from "./MilestoneSections/Incentive";
import Disincentive from "./MilestoneSections/Disincentive";
import LiquidatedDamages from "./MilestoneSections/LiquidatedDamages";
import Notes from "./MilestoneSections/Notes";
import Title from "./MilestoneSections/Title";
import { HeaderButton, useAuth } from "component-library";

const Milestones = ({ renderFieldRowRepeater, scheduleMilestones, setFields, data }) => {
  const { config } = useAuth();
  const [counter, setCounter] = useState(10000);

  const addItem = (emptyItem) => {
    let newList = [];
    if (data?.milestones) {
      newList = [...data["milestones"]];
    }
    setCounter(counter + 1);
    newList.push({ ...emptyItem, key: `milestones-${counter}` });
    setFields({ ...data, milestones: newList });
  };

  const emptyItem = {
    title: "",
    early_start_date: "",
    late_start_date: "",
    total_days: 0,
    total_days_type: 1,
    incentive_amount: 0,
    incentive_type: 1,
    incentive_type_other: "",
    incentive_cap: 0,
    disincentive_amount: 0,
    disincentive_type: 1,
    disincentive_type_other: "",
    liquidated_damages_amount: 0,
    liquidated_damages_type: 1,
    liquidated_damages_type_other: "",
    notes: "",
  };

  return (
    <>
      <div className="row py-2">
        <div className={scheduleMilestones ? "d-flex justify-content-between align-items-center col-12" : "col-12"}>
          <h1
            className="form-section-header"
            style={
              scheduleMilestones
                ? {
                    color: "#4f4f4f",
                    fontSize: "2rem",
                    fontWeight: 700,
                  }
                : null
            }
          >
            {scheduleMilestones ? "Project Milestones" : "Milestones"}
          </h1>
          {scheduleMilestones && (
            <HeaderButton
              onClick={() => addItem(emptyItem)}
              className="me-5 pe-4"
              ariaLabel="add new project milestone"
            />
          )}
        </div>
      </div>
      <div className="px-3">
        {renderFieldRowRepeater({
          hideButton: scheduleMilestones ? true : false,
          name: "milestones",
          emptyItem: emptyItem,
          rowClasses: "align-items-start",
          renderItem: (item, handleChange, name, focusAdd) => (
            <div className={`col-11 mb-4 ${styles.addMilestone}`}>
              <Title item={item} handleChange={handleChange} focusAdd={focusAdd} />

              <StartEndDate item={item} handleChange={handleChange} config={config} focusAdd={focusAdd} />

              <Incentive item={item} handleChange={handleChange} config={config} focusAdd={focusAdd} />

              <Disincentive item={item} handleChange={handleChange} config={config} focusAdd={focusAdd} />

              <LiquidatedDamages item={item} handleChange={handleChange} config={config} focusAdd={focusAdd} />

              <Notes item={item} handleChange={handleChange} focusAdd={focusAdd} />
            </div>
          ),
        })}
      </div>
    </>
  );
};

Milestones.defaultProps = {
  scheduleMilestones: false,
};

Milestones.propTypes = {
  renderFieldRowRepeater: PropTypes.func.isRequired,
  scheduleMilestones: PropTypes.bool,
};

export default Milestones;
