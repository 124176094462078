import React from "react";
import { Modal, Button } from "react-bootstrap";

const ModalResetPhases = ({ resetPhases, setResetPhasesConfirmation }) => {
  return (
    <Modal
      show={true}
      centered
      onHide={() => setResetPhasesConfirmation(false)}
    >
      <div className="mx-4 mt-1 mb-3">
        <div className="med confirmation-header mt-3">Reset Phases</div>
        <div className="my-4 med confirmation-text">
          Are you sure you want to reset the phases?
        </div>
        <div className="d-flex justify-content-end">
          <Button
            className="light-text mx-1 med py-1"
            variant="light-gray"
            onClick={() => setResetPhasesConfirmation(false)}
          >
            Cancel
          </Button>
          <Button
            className="light-text mx-1 med py-1"
            variant="orange"
            onClick={() => {
              setResetPhasesConfirmation(false);
              resetPhases();
            }}
          >
            Reset Phases
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalResetPhases;
