import React from "react";
import { Pie, PieChart, Cell } from "recharts";
import Chiclet from "./Chiclet";
import styles from "./styles.module.scss";
import useDashboard from "./useDashboard";
import ArrowLink from "../components/ArrowLink/ArrowLink";

const COLORS = ["#0071CE", "#C8C8C8"];

const DollarsPerJobOverallChart = ({ className }) => {
  const {
    aggregateStats: { spend },
  } = useDashboard();
  if (!spend) return null;

  const chartData = [{ name: "Spend", value: parseFloat(spend.spend) }];
  if (spend.remaining > 0)
    chartData.push({ name: "Remaining", value: parseFloat(spend.remaining) });

  return (
    <Chiclet className={className}>
      <div className="d-flex justify-content-between">
        <h2 className={styles.header}>Job Cost</h2>
      </div>
      {!!chartData && (
        <figure className={` ${styles.chart}`}>
          <div className="d-flex align-items-center  px-4">
            <PieChart width={150} height={150}>
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={40}
                outerRadius={70}
                fill="#fff"
                labelLine={false}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
            <figcaption className="mb-0 ms-3 ps-3">
              <dl className="mb-0">
                <div>
                  <dd className={styles.legendDD} style={{ color: "#345D9D" }}>
                    {`$${new Intl.NumberFormat().format(spend.budgeted)}`}
                  </dd>
                  <dt className={styles.legendDT}>Budgeted Cost</dt>
                </div>
                <div>
                  <dd className={styles.legendDD} style={{ color: "#0071CE" }}>
                    {`$${new Intl.NumberFormat().format(spend.spend)}`}
                  </dd>
                  <dt className={styles.legendDT}>Actual Cost</dt>
                </div>
                {spend.remaining >= 0 ? (
                  <div>
                    <dd className={styles.legendDD} style={{ color: "#c8c8c8" }}>
                      {`$${new Intl.NumberFormat().format(spend.remaining)}`}
                    </dd>
                    <dt className={`${styles.legendDT} mb-0`}>Remaining</dt>
                  </div>
                ) : (
                  <div>
                    <dd className={styles.legendDD} style={{ color: "#ff0000" }}>
                      {`$${new Intl.NumberFormat().format(spend.remaining)}`}
                    </dd>
                    <dt className={styles.legendDT}>Deficit</dt>
                  </div>
                )}
              </dl>
            </figcaption>
          </div>
        </figure>
      )}
      <ArrowLink url="/dashboard/dollars-per-job" label="view costs by job" />
    </Chiclet>
  );
};

export default DollarsPerJobOverallChart;
