import React from "react";
import ProjectNote from "../../../../components/FieldNoteAccordion/Note";

const Note = ({ data }) => {

  return (
    <div className="w-100">
      <ProjectNote
        project={{ id: data?.project_id }}
        note={data}
        isInTable
      />
    </div>
  );
};

export default Note;
