import Edit from "../columnRenders/Edit";

const edit = {
  headerName: "Edit",
  field: "",
  cellRenderer: Edit,
  autoHeight: true,
  width: 60,
};

export default edit;
