import React from "react";
import PropTypes from "prop-types";
import { PlusCircle, X } from "react-feather";
import styles from "../styles.module.scss";
import useAddNote from "./useAddNote";
import { Alert } from "react-bootstrap";
import useRemoveNote from "./useDeleteNote";

const Notes = ({ purchaseOrder, setPurchaseOrder, setLoading }) => {
  const { addNoteError, addNote, note, setNote } = useAddNote(purchaseOrder, setPurchaseOrder, setLoading);

  const { removeNoteError, removeNote } = useRemoveNote(purchaseOrder, setPurchaseOrder, setLoading);

  return (
    <div className={`${styles.section} ${styles.notesSection}`}>
      <label className={styles.label}>Notes</label>
      {!!addNoteError && <Alert variant="danger">{addNoteError}</Alert>}
      {!!removeNoteError && <Alert variant="danger">{removeNoteError}</Alert>}
      {purchaseOrder.status === 0 && (
        <div className={styles.addNote}>
          <input onChange={(e) => setNote(e.target.value)} value={note} aria-label="Notes" />
          <button
            className={`${styles.iconButton} btn-link`}
            onClick={() => addNote(note)}
            aria-label="add note"
            disabled={!note}
          >
            <PlusCircle size={24} color={!note ? "#7b7b7b" : "#0071CE"} />
          </button>
        </div>
      )}
      <div className={styles.notes}>
        {!!purchaseOrder.notes &&
          !!purchaseOrder.notes.length &&
          purchaseOrder.notes.map((note) => (
            <div className={styles.note} key={note.id}>
              <div className={styles.noteBody}>{note.text}</div>
              {purchaseOrder.status === 0 && (
                <button className={styles.iconButton} onClick={() => removeNote(note.id)} aria-label="delete note">
                  <X size={24} color="#d3d3d3" />
                </button>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

Notes.propTypes = {
  purchaseOrder: PropTypes.object.isRequired,
  setPurchaseOrder: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
};

export default Notes;
