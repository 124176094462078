import React from "react";
import ChecklistItem from "./ChecklistItem";
import PropTypes from "prop-types";

const Checklist = ({ items, updateItems, roleOptions, setAlert }) => {
  const updateItem = (item) =>
    updateItems(items.map((i) => (item.id === i.id ? item : i)));
  return (
    <div className="mt-5">
      {items.map((item) => (
        <ChecklistItem
          item={item}
          updateItem={updateItem}
          key={item.id}
          roleOptions={roleOptions}
          setAlert={setAlert}
        />
      ))}
    </div>
  );
};

Checklist.propTypes = {
  items: PropTypes.array.isRequired,
  updateItems: PropTypes.func.isRequired,
  roleOptions: PropTypes.object,
  setAlert: PropTypes.func.isRequired
};

export default Checklist;
