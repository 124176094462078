import { AlertTriangle, Book, DollarSign, Map, Layout, Folder } from "react-feather";

const FolderIcon = ({ folder }) => {
  const Icon = () => {
    let FIcon;
    switch (folder.toLowerCase()) {
      case "specbook":
        FIcon = () => <Book size={16} />;
        break;
      case "plans":
        FIcon = () => <Map size={16} />;
        break;
      case "safety":
        FIcon = () => <AlertTriangle size={16} />;
        break;
      case "job board":
        FIcon = () => <Layout size={16} />;
        break;
      case "wage decision":
        FIcon = () => <DollarSign size={16} />;
        break;
      default:
        FIcon = () => <Folder size={16} />;
        break;
    }
    return <FIcon />;
  };

  return <Icon />;
};

export default FolderIcon;
