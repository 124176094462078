import { useEffect } from "react";
import useProject from "../../hooks/useProject";

/**
 * Custom React hook for handling loading of the proper dates in the project field logs
 *
 * @param {boolean} initialLoad - flag for the initial loader, only used to load the right date
 * @param {function} setInitialLoad - Function to set the initialLoad flag.
 *
 * @returns {void} This hook does not return any value, just has a useEffect.
 */

const useInitialLoader = (initialLoad, setInitialLoad) => {
  const { fieldMgmtDateRange, setFieldMgmtDateRange, getStoredDateRange, dailyFieldLogLoaded } = useProject();

  useEffect(() => {
    if (!!initialLoad) {
      const [startDate, endDate] = fieldMgmtDateRange;
      if (!startDate && !dailyFieldLogLoaded) {
        const storedDateRange = getStoredDateRange();
        setFieldMgmtDateRange([new Date(storedDateRange[0]), new Date(storedDateRange[1])]);
      }
      if (!!startDate && !endDate && !!initialLoad && !!dailyFieldLogLoaded) {
        setFieldMgmtDateRange([]);
      }
    }
    setInitialLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, dailyFieldLogLoaded]);
};

export default useInitialLoader;
