import { useState, useEffect } from "react";
import useProject from "../../../../hooks/useProject";
import { useApi, projectApi, displayDate } from "component-library";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

const useFieldForms = () => {
  const { project } = useProject();
  const [forms, setForms] = useState();
  const [filteredForms, setFilteredForms] = useState();
  const [formOptions, setFormOptions] = useState([]);
  const [selectedFormOption, setSelectedFormOption] = useState();
  const [q, setQ] = useState();

  const {
    request: formsRequest,
    data: formsData,
    error: formsError,
    loading: formsLoading,
  } = useApi(projectApi.getAllFieldForms);

  const handleSetForms = (submissions) => {
    if (!!submissions?.length) {
      setForms(submissions);
    } else {
      setForms([]);
    }
  };

  const handleSetFilteredForms = (filterForms) => {
    if (!!filterForms?.length) {
      setFilteredForms(filterForms);
    } else {
      setFilteredForms();
    }
  };

  const handleSetFormOptions = (submissions) => {
    if (submissions) {
      const formTypes = [...new Set(submissions.map((item) => item.form_name))];
      if (!!formTypes) {
        setFormOptions(
          formTypes.map((opt, index) => {
            return {
              value: index,
              label: opt,
            };
          })
        );
      }
    }
  };

  const filterSubmissions = (query) => {
    let filtered = [...forms];

    if (!!selectedFormOption) {
      filtered = filtered.filter((form) => form.form_name === selectedFormOption.label);
    }

    if (query) {
      filtered = filtered.filter(
        (form) =>
          `${displayDate(form.date_submitted)} ${DateTime.fromJSDate(new Date(form?.date_submitted)).toFormat("t")} ${
            form.form_name
          } ${form.submitted_by}`
            .toLowerCase()
            .indexOf(query.toLowerCase()) > -1
      );
    }

    setFilteredForms(filtered);
  };

  useEffect(() => {
    if (!!project?.id) {
      formsRequest(project.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.id]);

  useEffect(() => {
    if (forms) {
      filterSubmissions(q);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormOption, q]);

  useEffect(() => {
    handleSetForms(formsData?.submissions);
  }, [formsData?.submissions]);

  useEffect(() => {
    handleSetFilteredForms(forms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  useEffect(() => {
    handleSetFormOptions(formsData?.submissions);
  }, [formsData?.submissions]);

  return {
    formsError,
    formsLoading,
    forms,
    filteredForms,
    filterSubmissions,
    formOptions,
    setSelectedFormOption,
    setQ,
  };
};

useFieldForms.propTypes = {
  currentSelectedDate: PropTypes.string,
  selectedForemenIds: PropTypes.array,
};

export default useFieldForms;
