import React from "react";
import useUserAdmin from "./hooks/useUserAdmin";
import { Alert } from "react-bootstrap";
import { Loader, useAuth } from "component-library";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import AdminIcon from "../../../icons/AdminIcon";
import { HeaderButton, FilterSearch } from "component-library";
import { Eye } from "react-feather";
import { AgGridTable } from "component-library";
import PasswordReset from "./PasswordReset/PasswordReset";
import userAdminConfig from "../../../config/userAdminConfig";
import gridRowHeights from "../../../utils/gridRowHeights";
import ActivateUserModal from "./ActivateUserModal";
import DeactivateUserModal from "./DeactivateUserModal";
import EditUserModal from "./EditUserModal";
import CreateUserModal from "./CreateUserModal";
import Unauthorized from "../../../components/pages/Unauthorized";

const UserAdmin = () => {
  const { userCan, user } = useAuth();

  const {
    hasError,
    isLoading,
    successMessage,
    setSuccessMessage,
    setError,
    setAddUser,
    registrationAlert,
    setRegistrationAlert,
    registrationError,
    registrationLoading,
    filteredUsers,
    handleUserSearch,
    archived,
    setArchived,
  } = useUserAdmin();

  const ActionLinks = () => (
    <div className="d-flex flex-row">
      <HeaderButton
        icon={<Eye size="16" color="#0071ce" />}
        onClick={() => setArchived(!archived)}
        text={archived ? "View Active Users" : "View Deactivated Users"}
        ariaLabel={archived ? "View Active Users" : "View Deactivated Users"}
      />
      {!archived && <HeaderButton text="Add User" onClick={() => setAddUser(true)} ariaLabel="add new user" />}
    </div>
  );

  return (
    <>
      {userCan("admin-users") ? (
        <InteriorPage
          pageTitle={archived ? "Manage Archived Users" : "Edit and Add Users"}
          navTitle="Admin"
          navIcon={<AdminIcon color="#fff" size={14} />}
          pageTitleSecondaryContent={ActionLinks}
        >
          <>
            <Loader loading={!!registrationLoading || isLoading()} />
            <div className="d-flex align-items-center justify-content-center w-100 mb-3">
              <FilterSearch
                searchFunction={handleUserSearch}
                placeholder="Find a User"
                style={{
                  maxWidth: "44rem",
                  width: "66vw",
                }}
              />
            </div>
            {!!registrationAlert && !registrationError && (
              <Alert variant="success" onClick={() => setRegistrationAlert(false)} dismissible>
                User account successfully added.
              </Alert>
            )}
            {hasError() && (
              <Alert variant="danger" onClick={() => setError(false)}>
                {hasError()}
              </Alert>
            )}
            {!!successMessage && (
              <Alert variant="success" onClick={() => setSuccessMessage(false)} dismissible>
                {successMessage}
              </Alert>
            )}

            <div className="d-flex align-items-center justify-content-center w-100 mb-3">
              <AgGridTable
                gridConfig={userAdminConfig}
                gridData={filteredUsers}
                redrawRows={true}
                rowHeight={gridRowHeights.medium}
                id={`${user.id}_user_admin`}
                presetStyle="sortableTable"
              />
            </div>

            <EditUserModal />

            <CreateUserModal />

            <PasswordReset />

            <DeactivateUserModal />

            <ActivateUserModal />
          </>
        </InteriorPage>
      ) : (
        <Unauthorized />
      )}
    </>
  );
};

export default UserAdmin;
