
import Categories from "../companiesAdminColumns/Categories";

const company_categories = {
  headerName: "Categories",
  field: "company_categories",
  flex: 1,
  wrapText: true,
  cellRenderer: Categories,
};

export default company_categories;
