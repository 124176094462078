import React from "react";
import styles from "../styles.module.scss";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip } from "recharts";
import Dot from "../StatPieChart/Dot";

const HoursBarChart = ({ stats }) => {
  const chartData = [
    {
      name: "Estimated",
      value: Math.round(stats?.man_hours_estimated) || 0,
      fill: "#0071ce",
    },
    {
      name: "Reported",
      value: Math.round(stats?.man_hours) || 0,
      fill: "#e98926",
    },
    {
      name: "Earned",
      value: Math.round(stats?.earned_hours) || 0,
      fill: "#5DB115",
    },
  ];

  return (
    <section className={`${styles.section} ${styles.hasBg} pb-0`}>
      <h2>Man Hours</h2>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center me-3">
          <Dot color="#0071ce" /> <span className="ms-2 text-gray3">Estimated</span>
        </div>
        <div className="d-flex align-items-center me-3">
          <Dot color="#e98926" /> <span className="ms-2 text-gray3">Reported</span>
        </div>
        <div className="d-flex align-items-center">
          <Dot color="#5DB115" /> <span className="ms-2 text-gray3">Earned</span>
        </div>
      </div>
      <figure className={styles.chartWrapper}>
        <ResponsiveContainer width="100%" height={150}>
          <BarChart data={chartData} stackOffset="sign" barCategoryGap={16} barGap={16}>
            <Tooltip />
            <XAxis dataKey="name" stroke="#D3D3D3" height={5} axisLine={true} tick={false} tickLine={false} />
            <YAxis tick={{ fill: "#4F4F4F" }} stroke="#D3D3D3" axisLine={true} tickLine={true} />
            <Bar dataKey="value" stackId="Estimated" barSize={65} />
          </BarChart>
        </ResponsiveContainer>
      </figure>
    </section>
  );
};

export default HoursBarChart;
