import React from "react";
import getAlertIcon from "../../../utils/getAlertIcon";

const Alerts = ({ data }) => {
  const getAllAlerts = () => {
    //TO DO: this should be time_sheets or the other one should be timesheets.
    //normalize repsonse from api to turn this into shared component
    //also on Crew Logs/Crew Timesheets

    if (data?.timesheets?.length) {
      const uniqueAlerts = new Set();

      data.timesheets.forEach((timesheet) => {
        const alerts = timesheet.alerts || {};
        for (const alert in alerts) {
          if (alerts.hasOwnProperty(alert)) {
            uniqueAlerts.add(alert);
          }
        }
      });

      return Array.from(uniqueAlerts);
    }
  };

  const allAlerts = getAllAlerts(data.alerts);

  return (
    <div className="d-flex">
      {allAlerts?.length ? allAlerts.map((alert, index) => <div key={index}>{getAlertIcon(alert)}</div>) : ""}
    </div>
  );
};

export default Alerts;
