import React from "react";

const Roles = ({ data }) => {
  const roles = data.roles.map((role) => role.name);
  return (
    <div>
      {roles.join(", ")}
    </div>
  );
}

export default Roles;