import React from "react";
import { Alert } from "react-bootstrap";

const TwoWeekMessage = ({ dateRange }) => {
  return (
    <>
      {!dateRange[0] && (
        <Alert variant="success">
          Data returned without a selected date range returns results from the last two weeks.
        </Alert>
      )}
    </>
  );
};

export default TwoWeekMessage;
