import React from "react";
import useGreeting from "./useGreeting";
import { useAuth } from "component-library";
import InteriorPage from "../components/pageLayout/InteriorPage";
import { DateTime } from "luxon";
import useDashboard from "./useDashboard";

const DashboardPage = ({ children }) => {
  const { user } = useAuth();
  const currentGreeting = useGreeting();
  const { loading } = useDashboard();
  return (
    <InteriorPage
      pageTitle={`${currentGreeting}${user && user.first_name ? `, ${user.first_name}` : ""}!`}
      navTitle="Welcome to Tractics"
      subtitle={DateTime.local().toFormat("cccc, LLLL d, y")}
      navHomeTitle="Tractics"
      loading={loading}
      titleTag="Tractics — Dashboard"
    >
      {children}
    </InteriorPage>
  );
};

export default DashboardPage;
