import React from "react";
import PropTypes from "prop-types";

const ProgressTracker = ({ completed, total }) => {
  const colorSwitch = (value) => {
    let progress;
    switch (true) {
      case value < 0.66:
        progress = "in-progress";
        break;
      case value < 1:
        progress = "near-complete";
        break;
      default:
        progress = "";
    }
    return progress;
  };
  return (
    <>
      <div className="progress-label text-uppercase">
        {completed} / {total} Tasks Completed
      </div>
      <div
        className={`d-flex flex-row justify-content-between tracker ${
          completed === total ? "finished" : ""
        }`}
      >
        {[...Array(total)].map((x, i) => (
          <div
            className={`p-1 counter ${
              i < completed ? colorSwitch(completed / total) : ""
            }`}
            key={i}
          ></div>
        ))}
      </div>
    </>
  );
};

ProgressTracker.propTypes = {
  completed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired, 
};

export default ProgressTracker;
