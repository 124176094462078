import React from 'react';
import ContactControl from "../components/Contacts/ContactControl";
import PropTypes from "prop-types";
import LocationControl from "../components/Locations/LocationControl";

const BidContacts = ({contractOwner, projectContacts, projectOwners}) => {

  return (
    <div className="row">
      {!!contractOwner && (
        <div className="col-md-4 pb-2 d-flex">
          <LocationControl
            title={'Contract Owner'}
            location={contractOwner}
            colDisplay={true}
          />
        </div>
      )}

      {projectOwners && projectOwners.length > 0 && (
        projectOwners.map((contact) => (
          <div className="col-md-4 pb-2 d-flex" key={contact.id}>
            <ContactControl
              title={'Project Engineer'}
              role={'project_owner'}
              contact={contact.contact}
              colDisplay={true}
            />
          </div>
        )))
      }
      {projectContacts && projectContacts.length > 0 && (
        projectContacts.map((contact) => (
          <div className="col-md-4 pb-2 d-flex" key={contact.contact_id}>
            <ContactControl
              title={contact.role.name}
              role={'project_contact'}
              contact={contact.contact}
              colDisplay={true}
            />
          </div>
        )))
      }
    </div>
  );
};

BidContacts.propTypes = {
  contractOwner: PropTypes.object,
  projectContacts: PropTypes.array,
  projectOwners: PropTypes.array,
};

export default BidContacts;