import { Joi } from "component-library";

const regexLink = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;

const schema = (type_id) => ({
  id: Joi.number(),
  specs_location: Joi.string()
    .pattern(new RegExp(regexLink))
    .label("Link to Spec Book")
    .allow(null)
    .empty("")
    .messages({
      "string.pattern.base": "Please enter a valid link to the Spec Book file",
    }),
  plans_location: Joi.string()
    .pattern(new RegExp(regexLink))
    .label("Link to Plans")
    .allow(null)
    .empty("")
    .messages({
      "string.pattern.base": "Please enter a valid link to the Plans file",
    }),

  description: Joi.saneString().label("Project Description"),
  bid_date: Joi.date().required().label("Bid Date").messages({
    "string.base": "Bid Date is required.",
  }),
  bid_time: Joi.string().required().label("Bid Time").messages({
    "string.base": "Bid Time is required.",
  }),
  bid_location: [3, 6].includes(type_id)
    ? Joi.saneString().label("Bid Location")
    : Joi.string().required().label("Bid Location").messages({
        "string.base": "Bid Location is required.",
      }),
  bid_type: Joi.any(),
  bid_type_id: Joi.number().required(),
  estimator_id: Joi.number().label("Estimator"),
  runner_id: Joi.number().label("Runner"),
  leave_time: Joi.saneString().label("Leave Time"),
  owner_project_number: Joi.saneString()
    .allow(null)
    .label("Owner Project Number"),
  completion_date: Joi.date().allow(null).label("Completion Date"),
  substantial_start_date: Joi.date().allow(null).label("Substantial Start Date"),
  working_days: Joi.number().label("Days"),
  working_days_type: Joi.number().label("Days Type").messages({
    "number.base": "Days Type must have an option selected.",
  }),
  early_start_date: Joi.date().allow(null).label("Early start date"),
  late_start_date: Joi.date().allow(null).label("Late start date"),
  maintenance_period_type: Joi.number()
    .label("Maintenance Period Type")
    .messages({
      "number.base": "Maintenance Period Type  must have an option selected.",
    }),
  maintenance_period: Joi.number()
    .required()
    .label("Maintenance Period")
    .messages({
      "string.base": "Maintenance Period is required.",
      "string.empty": "Maintenance Period is required.",
    }),
  maintenance_period_other: Joi.saneString().label("Maintenance Period Other"),
  contract_turnaround_time: Joi.number().label("Contract Turnaround Time"),
  contract_turnaround_type: Joi.number()
    .label("Contract Turnaround Type")
    .messages({
      "number.base": "Contract Turnaround Type must have an option selected.",
    }),
  contract_turnaround_time_other: Joi.saneString().label(
    "Contract Turnaround Time Other"
  ),
  pay_app_due_dates_type_other: Joi.saneString().label(
    "Pay App Due Dates Type Other"
  ),
  council_meeting_dates_type_other: Joi.saneString().label(
    "Council Meeting Dates Type Other"
  ),
  progress_meeting_dates_type_other: Joi.saneString().label(
    "Progress Meeting Dates Type Other"
  ),
  liquidated_damages: Joi.saneString().label("Liquidated Damages"),
  retainage_amount: Joi.saneString().label("Retainage Amount"),
  retainage_cap: Joi.saneString().label("Retainage Cap"),
  incentive_amount: Joi.saneString().label("Incentive Amount"),
  estimated_contract_amount: Joi.saneString()
    .required()
    .label("Engineer's estimated contract amount")
    .custom((value, helper) => {
      if (parseFloat(value) > 9999999999) {
        return helper.message("Engineer estimated contract amount is too high.")
      } else {
        return true
      }
    }),
  estimated_contract_amount_high: Joi.saneString().label("Range max"),
  tax_exempt: Joi.checkbox(),
  milestones: Joi.array().label("Milestones"),
  job_number: Joi.saneString(),
  name: Joi.saneString(),
  active: Joi.any(),
  category_id: Joi.number(),
  type_id: Joi.number(),
  status: Joi.saneString(),
  preparer_id: Joi.number(),
  project_owner_ids: Joi.array(),
  project_contact_ids: Joi.array(),
  submittals: Joi.array(),
  type: Joi.any(),
  category: Joi.any(),
  notes: Joi.array(),
  attachments: Joi.array(),
  contacts: Joi.array(),
  all_users: Joi.any(),
  plan_room_bid_id: Joi.saneString().label("Plan Room Bid Id"),
  project_owners: Joi.array(),
  contract_owner: Joi.any().required(),
  addendas: Joi.any(),
  project_contacts: Joi.any(),
  bid_closed: Joi.any(),
  bid_read_low: Joi.any(),
  bid_docs: Joi.any(),
  bid_tracking: Joi.any(),
  bid_todo_ids: Joi.any(),
  archived: Joi.any(),
  latest_checklist: Joi.any(),
  project_todos: Joi.any(),
  bid_todos: Joi.any(),
  idot_proposal_guaranty_amount: Joi.saneString().allow(null).allow(""),
  idot_project_no: Joi.any(),
  latest_meeting_outline: Joi.any(),
  certified_payroll: Joi.checkbox(),
  relative_start_date: Joi.saneString().label("Relative Start Date"),
  project_docs: Joi.any(),
  field_docs: Joi.any(),
  project_manager_ids: Joi.array(),
  project_coordinator_ids: Joi.array(),
  project_foremen_ids: Joi.array(),
  project_superintendent_ids: Joi.array(),
  ais: Joi.checkbox(),
  contract_turn_around_time_type: Joi.number().label("Contract Turnaround Time Type"),
  idot_proposal_number: Joi.saneString().label("IDOT Proposal Number"),
  gc_project_number: Joi.saneString().label("GC Project Number"),
  delivery_address_city: Joi.saneString().label("Delivery Address City"),
  delivery_address_state: Joi.saneString().label("Delivery Address State"),
  delivery_address_zip: Joi.saneString().label("Delivery Address Zip"),
  delivery_address_street: Joi.saneString().label("Delivery Address Street"),
  pay_app_due_dates: Joi.number().optional().allow(null).label("Pay App Due Date"),
  pay_app_due_dates_type: Joi.number().optional().allow(null).label("Pay App Due Date Type"),
  council_meeting_dates_type: Joi.number().optional().allow(null).label("Council Meeting Date Type"),
  council_meeting_dates: Joi.number().optional().allow(null).label("Council Meeting"),
  progress_meeting_dates_type: Joi.number().optional().allow(null).label("Progress Meeting Type"),
  progress_meeting_dates: Joi.number().optional().allow(null).label("Progress Meeting Date"),
  nonbillable: Joi.checkbox(),
});

const initialData = {
  id: undefined,
  milestones: [],
  contract_turnaround_type: 1,
  maintenance_period_type: 1,
  working_days_type: 1,
  tax_exempt: 0,
  certified_payroll: 0,
  project_owner_ids: [],
  project_contact_ids: [],
  submittals: [],
  notes: [],
  attachments: [],
  contacts: [],
  project_owners: [],
  bid_todos: [],
  project_manager_ids: [],
  project_foremen_ids: [],
  project_superintendent_ids: [],
  project_coordinator_ids: [],
  ais: 0,
  nonbillable: 0
};

export { schema, initialData };

