import React from "react";
import ModalWrapper from "../ModalWrapper";
import useAddtoAlbum from "./useAddtoAlbum";
import PropTypes from "prop-types";

const AddPhotoToAlbumModal = ({
  photoDetails,
  setPhotoDetails,
  showModal,
  setShowModal,
  selectedItems,
  setSelectedItems,
  setSelectMode,
}) => {
  const { headerContent, bodyContent, footerContent, onHide } = useAddtoAlbum({
    photoDetails,
    setPhotoDetails,
    showModal,
    setShowModal,
    selectedItems,
    setSelectedItems,
    setSelectMode,
  });
  return (
    <>
      <ModalWrapper
        showModal={showModal}
        setShowModal={setShowModal}
        headerContent={headerContent}
        bodyContent={bodyContent}
        footerContent={footerContent}
        onHide={() => onHide()}
        hideCancel
      />
    </>
  );
};

AddPhotoToAlbumModal.propTypes = {
  photoDetails: PropTypes.object,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  setPhotoDetails: PropTypes.func,
};

export default AddPhotoToAlbumModal;
