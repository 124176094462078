import {
  date,
  id,
  job_number,
  lunch,
  timesheet_hours,
  perdiem,
  time_in,
  time_out,
  project_name,
  expand,
  unallocated,
  note_count,
} from "./columnDefs";

const columnDefs = [
  date,
  id,
  job_number,
  project_name,
  perdiem,
  time_in,
  time_out,
  lunch,
  unallocated,
  timesheet_hours,
  note_count,
  expand,
];

export default columnDefs;
