const estimator_id = {
  headerName: "Estimator",
  field: "estimator_id",
  width: 172,
  valueGetter: ({ data }) =>
    data?.estimatorFirstName && data?.estimatorLastName
      ? `${data.estimatorFirstName} ${data.estimatorLastName}`
      : null,
};

export default estimator_id;
