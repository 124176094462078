import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./pages/Dashboard";
import Projects from "./pages/ProjectList";
import { ScrollToTop } from "component-library";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import UserAdmin from "../_admin/pages/UserAdmin";
import RolesAdmin from "../_admin/pages/RolesAdmin/RolesAdmin";
import InitialCreateBid from "./pages/InitialCreateBid";
import InitialCreateProject from "./pages/InitialCreateProject";
import Bid from "./pages/Bid";
import BidListing from "./pages/BidListing";
import ArchivedBidListing from "./pages/ArchivedBidListing";
import ContactsAdmin from "./pages/ContactsAdmin";
import Project from "./pages/Project";
import CompanyPage from "./pages/CompanyPage";
import Admin from "./pages/Admin";
import Support from "./pages/Support";
import VendorManagement from "./pages/VendorManagement";
import AdminReports from "./pages/AdminReports";
import AccountSettings from "./pages/AccountSettings";
import NotificationSettings from "./pages/NotificationSettings";
import ResourceManagement from "./pages/ResourceManagement";
// import FleetManagementRoutes from "./pages/FleetManagementRoutes";
import FleetManagementIframeRoutes from "../_fleetManagement/FleetManagementIframeRoutes";
import TimelineDetail from "../_fleetManagement/Pages/DriverScreens/TimelineDetail";
import InspectionDetail from "../_fleetManagement/Pages/DriverScreens/InspectionDetail";
import WeatherArchive from "./pages/WeatherArchive";
import SuperAdmin from "../_admin/pages/SuperAdmin";
import Unauthorized from "./pages/Unauthorized";
import ProjectArchive from "../_projects/ProjectSelection/ProjectArchive";
import ShareRoutes from "../_shared/ShareRoutes";
const TracticsRoutes = () => {
  //include 404 route
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/bid/:id/*" element={<Bid />} />
        <Route path="/project/:id/*" element={<Project />} />

        {/* <Route path="/fleet-management/*" element={<FleetManagementRoutes />} /> */}
        <Route path="/fleet-management/*" element={<FleetManagementIframeRoutes />} />

        <Route path="/admin/*" element={<Admin />} />
        <Route path="/super-admin/*" element={<SuperAdmin />} />
        <Route path="/field-management/*" element={<ResourceManagement />} />
        <Route path="/share/:tokenId/*" element={<ShareRoutes />} />

        <Route path="/user-admin" element={<PrivateRoute Component={<UserAdmin />} />} />
        <Route path="/roles-admin" element={<PrivateRoute Component={<RolesAdmin />} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/support" element={<Support />} />
        <Route path={`/fleet-management/driver-timeline/:driverId`} element={<TimelineDetail />} />
        <Route path={`/fleet-management/inspection-details/:inspectionId`} element={<InspectionDetail />} />
        <Route path="/reporting" element={<PrivateRoute Component={<AdminReports />} />} />
        <Route path="/account-settings" element={<PrivateRoute Component={<AccountSettings />} />} />
        <Route path="/notification-settings" element={<PrivateRoute Component={<NotificationSettings />} />} />
        <Route
          path="/directory/company/:id"
          element={<PrivateRoute viewPermission="contact-list" Component={<CompanyPage />} />}
        />
        <Route path="/directory/company/:id" element={<PrivateRoute Component={<UserAdmin />} />} />
        <Route path="/bids/archived" element={<PrivateRoute module="BidBuild" Component={<ArchivedBidListing />} />} />
        <Route path="/bids" element={<PrivateRoute module="BidBuild" Component={<BidListing />} />} />
        <Route
          path="/bid/create"
          element={<PrivateRoute viewPermission="bid-create" module="BidBuild" Component={<InitialCreateBid />} />}
        />
        <Route path="/projects" element={<PrivateRoute module="ProjectManagement" Component={<Projects />} />} />
        <Route
          path="/projects/create"
          element={
            <PrivateRoute
              viewPermission="project-create"
              module="ProjectManagement"
              Component={<InitialCreateProject />}
            />
          }
        />
        <Route
          path="/projects/archive"
          element={<PrivateRoute module="ProjectManagement" Component={<ProjectArchive />} />}
        />
        <Route path="/vendor-management" element={<PrivateRoute module="Portal" Component={<VendorManagement />} />} />
        {/* <Route path="/accounting" element={<PrivateRoute Component={<Accounting />} />} /> */}
        <Route
          path="/directory"
          element={<PrivateRoute viewPermission="contact-list" Component={<ContactsAdmin />} />}
        />
        <Route path="/weather" element={<PrivateRoute Component={<WeatherArchive />} />} />
        <Route path="*" element={<Unauthorized />} />
      </Routes>
    </Router>
  );
};

export default TracticsRoutes;
