import React from "react";
import { Clock, XCircle } from "react-feather";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";

const Late = ({ timesheet, setTimesheet, editing }) => {
  const removePerDiem = () => {
    setTimesheet({
      ...timesheet,
      late: 0,
    });
  };
  return (
    <>
      {timesheet?.late === 1 && (
        <div className="row my-3">
          <div className={`${styles.late}`}>
            <div className={styles.perDiemLabel}>
              <Clock color="#FFA400" size={16} className={styles.icon} />
              {`Late`}
            </div>
            {!!editing && (
              <Button variant="transparent" size="sm" onClick={removePerDiem} className="p-0">
                <XCircle color="#FFA400" size={20} />
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Late;
