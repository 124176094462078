import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DailyAccordion from "../components/DailyAccordion";
import TimesheetRow from "../components/TimesheetRow";
import DailyUserHeader from "../components/DailyUserHeader/DailyUserHeader";
import styles from "./styles.module.scss";

const Foreman = ({ foreman, selectedForemanIds, handleForemanToggle }) => {
  const [timesheets, setTimesheets] = useState();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!!foreman?.foreman?.time_sheets?.length) {
      setTimesheets(foreman?.foreman?.time_sheets);
    } else {
      setTimesheets();
    }
  }, [foreman?.foreman?.time_sheets]);

  const HeaderContent = () => (
    <DailyUserHeader
      foreman={foreman}
      selectedForemanIds={selectedForemanIds}
      handleForemanToggle={handleForemanToggle}
      timesheets={timesheets}
    />
  );

  return (
    <DailyAccordion
      headerContent={<HeaderContent />}
      active={active}
      setActive={setActive}
      wrapperStyles={styles.borderWrapper}
      zebraStripped
    >
      <div className="d-flex flex-column">
        {timesheets?.map((timesheet) => (<TimesheetRow timesheet={timesheet} />))}
      </div>
    </DailyAccordion>
  );
};

Foreman.propTypes = {
  foreman: PropTypes.object,
  selectedForemenIds: PropTypes.array,
  setSelectedForemenIds: PropTypes.func,
};

export default Foreman;
