import { useFormControl } from "component-library";
import React from "react";
import { Joi } from "component-library";
import styles from "../styles.module.scss";
import { Button } from "react-bootstrap";

const schema = {
  sum: Joi.saneString().label("Sum"),
  edited_sum_reason: Joi.saneString()
    .required()
    .label("Reason for editing sum"),
};

const EditSum = ({ purchaseOrder, setEditing, updatePurchaseOrder }) => {
  const onSubmit = async (data, setLoading) => {
    await updatePurchaseOrder({
      id: purchaseOrder.id,
      edited_sum_reason: data.edited_sum_reason,
      sum: data.sum,
    });
    setLoading(false);
    setEditing(false);
  };

  const {
    renderTextField,
    renderSubmitButton,
    renderTextareaField,
    handleSubmit,
  } = useFormControl({
    schema,
    onSubmit,
    initialData: {
      sum: purchaseOrder.sum,
      edited_sum_reason: purchaseOrder.edited_sum_reason,
    },
  });

  return (
    <form onSubmit={handleSubmit} className={styles.sumEdit}>
      <div className={styles.sumField}>
        {renderTextField({
          name: "sum",
          label: "For the sum of",
          autoFocus: true,
          format: "currency",
        })}
      </div>
      <div className={styles.sumReasonField}>
        {renderTextareaField({
          name: "edited_sum_reason",
          label: "Why are you editing the sum?",
        })}
      </div>
      <div className={styles.sumButtons}>
        <Button variant="secondary" onClick={() => setEditing(false)}>
          Cancel
        </Button>
        {renderSubmitButton("Update Sum")}
      </div>
    </form>
  );
};

export default EditSum;
