import { projectApi, useApi, AgGridTable, useAuth } from "component-library";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import DashboardSection from "./DashboardSection";
import gridRowHeights from "../../../utils/gridRowHeights"
import recentlyUpdatedConfig from "../../../config/recentlyUpdatedConfig"

const RecentlyUpdated = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const [updates, setUpdates] = useState([]);
  const { data, loading, error, request } = useApi(projectApi.getRecentlyUpdated);
  let history = useNavigate();

  useEffect(() => {
    request(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!data && data.updates)
      setUpdates(
        data.updates.map((u) => {
          const item = {
            ...u,
            date: DateTime.fromFormat(u.updated, DateTime.DATETIME_SHORT).toISO(),
          };
          if (u.type === "Field Log") item.id = `${u.workday_id}${u.foreman_id}${u.updated}`;
          if (u.type === "Quote") {
            item.quote_id = item.id;
            item.id = `${item.quote_id}${item.updated}${item.source}${item.description}`;
          }
          return item;
        })
      );
  }, [data]);

  const getLink = (item) => {
    let link;
    switch (item.type) {
      case "Field Log":
        link = `/project/${id}/field-management/field-logs/${item.workday_id}/foreman/${item.foreman_id}`;
        break;
      case "Submittal":
        link = `/project/${id}/submittals/${item.id}`;
        break;
      case "Locate":
        link = `/project/${id}/sitemanagement`;
        break;
      case "Permit":
        link = `/project/${id}/sitemanagement/permits`;
        break;
      case "Quote":
        link = `/project/${id}/quotes/${item.quote_id}`;
        break;
      default:
        break;
    }
    return link;
  };

  const handleCellClicked = ({ data, event }) => {
    if (event.ctrlKey || event.altKey || event.shiftKey) {
      window.open(getLink(data), "_blank");
    } else {
      history(getLink(data));
    }
  }

  return (
    <DashboardSection title="Recently Updated">
      {!!error && <Alert variant="danger">{error}</Alert>}
      <AgGridTable
        gridConfig={recentlyUpdatedConfig}
        gridData={updates}
        rowHeight={gridRowHeights.small}
        onCellClicked={handleCellClicked}
        loading={loading}
        id={`${user.id}_recently_updated_grid`}
        presetStyle="sortableTable"
        fallbackMessage="No recent updates found."
      />
    </DashboardSection>
  );
};

export default RecentlyUpdated;
