import React from "react";
import RowContent from "../../RowContent";
import RowItemContent from "../../RowItemContent";
import convertMinutesToTime from "../../../../utils/convertMinutesToTime";
import convertDecimalHours from "../../../../utils/convertDecimalHours";
import taskWidths from "../../gridWidths/taskWidths";

const TaskRow = ({ task }) => {
  return (
    <RowContent indent="5rem">
      <RowItemContent width={taskWidths.task}>{task.task_name || task.task}</RowItemContent>
      <RowItemContent width={taskWidths.role}>{task.role}</RowItemContent>
      <RowItemContent width={taskWidths.equipment}>{task.equipment_name || task.equipment}</RowItemContent>
      <RowItemContent width={taskWidths.hours}>
        {task.minutes ? convertMinutesToTime(task.minutes) : convertDecimalHours(task.hours)}
      </RowItemContent>
    </RowContent>
  );
};

export default TaskRow;
