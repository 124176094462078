import SubmittedBy from "../columnRenders/SubmittedBy";

const submittedBy = {
  headerName: "Submitted By",
  valueGetter: ({ data }) => {
    return `${data.foreman_first_name} ${data.foreman_last_name}`;
  },
  resizable: true,
  cellRenderer: SubmittedBy,
};

export default submittedBy;
