import React, { useEffect, useState } from "react";
import { useApi, bidApi, useFormControl } from "component-library";
import { schema, initialData } from "../schema/bidTracking";
import { Loader, Save, XCircle } from "react-feather";
import { Button, Table, Alert } from "react-bootstrap";
import PropTypes from "prop-types";
import { currency } from "component-library";

const BidTracking = ({ bid, readLow }) => {
  const { loading, data, request, error } = useApi(bidApi.addTracking);

  const {
    loading: deleteLoading,
    request: deleteRequest,
    data: deleteData,
    error: deleteError,
  } = useApi(bidApi.deleteTracking);

  const [tracking, setTracking] = useState(bid.bid_tracking || []);
  const [lowChosen, setLowChosen] = useState(false);

  const onSubmit = async (formData, setLoading) => {
    try {
      formData.project_id = bid.id;

      const formattedTracking = tracking.map((t) => ({
        contractor: t.contractor,
        amount: t.amount,
        low_bid: t.low_bid,
        id: t.id,
      }));

      await request({
        project_id: bid.id,
        trackings: [...formattedTracking, formData],
      });
      setLoading(false);
    } catch (message) {
      setLoading(false);
    }
  };

  const { handleSubmit, renderTextField, renderCheckbox } = useFormControl({
    initialData,
    schema,
    onSubmit,
  });

  const handleSetLowChosen = (trackingData) => {
    setLowChosen(trackingData.reduce((acc, curr) => acc + curr.low_bid, 0));
  };

  const deleteTrackingItem = (id) => {
    deleteRequest({
      project_id: bid.id,
      delete_ids: [id],
    });
  };

  useEffect(() => {
    if (!!data && !!data.trackings) {
      setTracking(data.trackings);
      handleSetLowChosen(data.trackings);
    }
  }, [data]);

  useEffect(() => {
    if (!!deleteData && !!deleteData.trackings) {
      setTracking(deleteData.trackings);
      handleSetLowChosen(deleteData.trackings);
    }
  }, [deleteData]);

  return (
    <>
      {deleteError && <Alert variant="danger">{deleteError}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <Table responsive className="sortable-table mb-0">
        <thead>
          <tr className="default">
            <th
              className="text-nowrap pb-2 ps-0 pt-0 border-top-0"
              style={{
                width: "34%",
              }}
            >
              <span>Contractor</span>
            </th>
            <th
              className="text-nowrap pb-2 ps-1 pt-0 border-top-0"
              style={{
                width: "34%",
              }}
            >
              <span>Bid</span>
            </th>
            <th
              className="text-nowrap pb-2 ps-1 pt-0 border-top-0"
              style={{
                width: "32%",
              }}
            >
              <span>Low Bid</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {tracking
            .slice(0)
            .reverse()
            .map((item) => (
              <tr key={item.id}>
                <td>{item.contractor}</td>
                <td>{currency(item.bid)}</td>
                <td>
                  <div className="d-flex  justify-content-between">
                    <span>{item.low_bid === 0 ? "No" : "Yes"}</span>

                    {deleteLoading ? (
                      <Loader />
                    ) : (
                      <Button onClick={() => deleteTrackingItem(item.id)} variant="link">
                        <XCircle size="14" />
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="d-flex flex-row justify-content-start row">
        <div className="col-4">
          <div className="my-2">
            {renderTextField({
              name: "contractor",
            })}
          </div>
        </div>
        <div className="col-4">
          <div className="my-2">
            {renderTextField({
              name: "bid",
              format: "currency",
              hasPlaceholder: true,
            })}
          </div>
        </div>
        <div className="col-4 d-flex justify-content-between">
          <div className="align-self-center mb-2">
            {renderCheckbox({
              name: "low_bid",
              isToggle: true,
              disabled: !!lowChosen || !!readLow,
            })}
          </div>
          <div className="align-self-center mb-2">
            {loading ? (
              <Loader />
            ) : (
              <Button onClick={handleSubmit} variant="link">
                <Save size="14" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

BidTracking.propTypes = {
  bid: PropTypes.PropTypes.shape({
    id: PropTypes.number.isRequired,
    bid_tracking: PropTypes.array,
  }),
};

export default BidTracking;
