import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi, projectApi, HeaderButton } from "component-library";
import BidDetailsForm from "../../_bidManagement/BidDetailsForm";
import InteriorPage from "../pageLayout/InteriorPage";
import { Tag, Save, ArrowRightCircle } from "react-feather";

const CompleteBid = () => {
  const { id } = useParams();
  const { request, loading, error, data } = useApi(projectApi.getProject);
  const [docLoading, setDocLoading] = useState(false);

  useEffect(() => {
    request(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getPlansLocation = () => {
    const plans3or6 =
      data.project.type_id === 3 || data.project.type_id === 6
        ? "https://iowadot.gov/contracts/plans-and-estimation-proposals"
        : "";

    const pLocation = !!data.project.plans_location ? data.project.plans_location : plans3or6;

    return pLocation;
  };

  const getSpecsLocation = () => {
    const spec3or6 =
      data.project.type_id === 3 || data.project.type_id === 6
        ? "https://iowadot.gov/contracts/plans-and-estimation-proposals"
        : "";

    const sLocation = !!data.project.specs_location ? data.project.specs_location : spec3or6;

    return sLocation;
  };

  const SaveBidComplete = () => {
    return (
      <div className="d-flex align-items-center">
        <HeaderButton
          linkTo={`/bid/${id}`}
          icon={<ArrowRightCircle size="16" color="#0071ce" />}
          text="Skip"
          disabled={docLoading}
        />
        <HeaderButton
          formId="bid-details-form"
          text="Save Bid"
          icon={<Save size={16} color="#0071ce" />}
          disabled={docLoading}
        />
      </div>
    );
  };

  return (
    <InteriorPage
      pageTitle="Create New Bid"
      loading={loading}
      error={error}
      navTitle="Bids"
      navIcon={<Tag color="#fff" size={14} />}
      fillScreen
      pageTitleSecondaryContent={SaveBidComplete}
    >
      {!!data && data.project && (
        <BidDetailsForm
          initialBid={{
            ...data.project,
            specs_location: getSpecsLocation(),
            plans_location: getPlansLocation(),
            maintenance_period: data.project.type_id === 3 || data.project.type_id === 6 ? 1 : 0,
            maintenance_period_type: data.project.type_id === 3 || data.project.type_id === 6 ? 4 : 1,
          }}
          setDocLoading={setDocLoading}
        />
      )}
    </InteriorPage>
  );
};

export default CompleteBid;
