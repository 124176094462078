import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const Controls = ({cancelRequote, sendRequote}) => {
  return (
    <div className="controls">
      <Button className={`btn med btn-gray5 text-white font-weight-bold me-3`} onClick={() => cancelRequote()}>
        Cancel Requote
      </Button>
      <Button className={`btn med btn-tertiary font-weight-bold`} onClick={() => sendRequote()}>
        Send Requote
      </Button>
    </div>
  );
};

Controls.propTypes = {
  cancelRequote: PropTypes.func,
  sendRequote: PropTypes.func,
};

export default Controls;
