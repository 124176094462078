import React, { useEffect } from "react";
import useProjectSettings from "./hooks/useProjectSettings";
import PropTypes from "prop-types";

const SettingsForm = ({ initialBid, setProject, refreshProject, setCanSubmit }) => {
  const { fields, handleSubmit, loading, documentLoading } = useProjectSettings(setProject, refreshProject, initialBid);

  useEffect(
    () => {
      if (documentLoading || loading) {
        setCanSubmit(false);
      } else {
        setCanSubmit(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentLoading, loading]
  );

  return (
    <div className="container py-4">
      <form id="project-settings" onSubmit={handleSubmit}>
        {fields}
      </form>
    </div>
  );
};

SettingsForm.propTypes = {
  initialBid: PropTypes.object,
  setProject: PropTypes.func,
  refreshProject: PropTypes.func,
  setCanSubmit: PropTypes.func,
};

export default SettingsForm;
