import dateTimeComparator from "../../../utils/dateTimeComparator";

const date = {
  headerName: "Date",
  field: "date",
  width: 100,
  cellStyle: {
    color: "#4F4F4F",
    fontWeight: 600,
  },
  comparator: dateTimeComparator,
};

export default date;
