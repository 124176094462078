import Source from "../Columns/Source";

const source = {
  headerName: "Source",
  field: "submitted_by",
  resizable: true,
  width: 170,
  cellRenderer: Source,
};

export default source;
