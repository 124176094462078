var directions = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];

const getDirection = (angle) => {
  var index = Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8;
  return directions[index];
};

const wind_direction = {
  headerName: "Wind",
  field: "wind_direction",
  valueGetter: ({ data }) =>
    data.wind_direction ? `${getDirection(data.wind_direction)}` : null,
};

export default wind_direction;
