import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAuth } from "component-library";

const useDetailsBar = ({ timesheet, foremanId, workDay }) => {
  const { config } = useAuth();
  const [foremen, setForemen] = useState();
  const [driveTime, setDrivetime] = useState(0);

  useEffect(() => {
    if (!!timesheet?.time_entries?.length && !!config?.foreman) {
      let driveTime = timesheet.travel_times.reduce((a, c) => a + parseInt(c.travel_duration, 10), 0);
      setDrivetime(driveTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, timesheet?.time_entries, foremanId]);

  useEffect(() => {
    let foreman;
    if (!!foremanId) {
      if (!!timesheet?.time_entries?.length) {
        foreman = timesheet.time_entries[0].allocated_by;
      } else {
        foreman = config.foreman.find((foreman) => foreman.id === parseInt(foremanId));
      }
      if (!foreman && !!workDay?.crew) {
        foreman = workDay.crew.find((crew) => crew.id === parseInt(foremanId));
      }
    }
    setForemen(foreman);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workDay?.crew, timesheet?.time_entries]);

  return {
    foremen,
    driveTime,
  };
};

useDetailsBar.propTypes = {
  timesheet: PropTypes.array,
};

export default useDetailsBar;
