import React from "react";
import { Table } from "react-bootstrap";
import LocatesItem from "./LocatesItem";
import styles from "../styles.module.scss";
import PropTypes from "prop-types";

const LocatesList = ({ locates, setEditLocate, setLocates }) => {
  return (
    <Table className={styles.table}>
      <thead className={styles.tableHead}>
        <tr>
          <th></th>
          <th>Active Date</th>
          <th>Locate #</th>
          <th>Description</th>
          <th>SB / DS</th>
          <th>Expiration</th>
          <th>Status</th>
          <th>Relocate?</th>
        </tr>
      </thead>
      <tbody>
        {locates.map((locate) => (
          <LocatesItem key={locate.id} locate={locate} setEditLocate={setEditLocate} setLocates={setLocates} />
        ))}
      </tbody>
    </Table>
  );
};

LocatesList.propTypes = {
  locates: PropTypes.array.isRequired,
  setEditLocate: PropTypes.func.isRequired,
};

export default LocatesList;
