import React from "react";
import ProjectNote from "../../../../components/ProjectNote";
import useProject from "../../../hooks/useProject";

const Comments = ({ comments }) => {
  const { project } = useProject();
  const sortComments = (comments) => {
    const sortedComments = comments.sort((a, b) => new Date(b.note_date) - new Date(a.note_date));
    return sortedComments;
  };

  return (
    <div className="position-relative">
      {!!comments?.length &&
        sortComments(comments).map((comment) => {
          return (
            <>
              <ProjectNote
                key={comment.id}
                project={project}
                note={comment}
                showPhoto={false}
              />
            </>
          );
        })}
    </div>
  );
};

export default Comments;
