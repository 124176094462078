import HoursEstRmn from "../columnRenders/HoursEstRmn";

const hoursRepErnd = {
  headerName: "Hrs Est/Rmn",
  field: "reported_hours",
  cellRenderer: HoursEstRmn,
  width: 175,
  headerClass: "ag-right-aligned-header",
};

export default hoursRepErnd;
