import { useState, useEffect } from "react";
import { DateTime } from "luxon";

const usePhotosView = ({ photosList, dateRange }) => {
  const [pinnedPhotos, setPinnedPhotos] = useState();
  const [todaysPhotos, setTodaysPhotos] = useState();
  const [recentPhotos, setRecentPhotos] = useState();
  const [previousPhotos, setPreviousPhotos] = useState();
  const [dateRangePhotos, setDateRangePhotos] = useState();

  const formatCreatedDate = (created) => {
    return DateTime.fromSQL(created).toFormat("yyyy-MM-dd");
  };

  useEffect(() => {
    const today = new Date();
    if (!!photosList?.length) {
      setPinnedPhotos(photosList.filter((photo) => photo.pinned === 1));
      if (!dateRange[0]) {
        setTodaysPhotos(
          photosList.filter(
            (photo) => !photo?.pinned && formatCreatedDate(photo.created_at) === DateTime.now().toFormat("yyyy-MM-dd")
          )
        );
        setRecentPhotos(
          photosList.filter(
            (photo) =>
              !photo?.pinned &&
              formatCreatedDate(photo.created_at) < DateTime.now().toFormat("yyyy-MM-dd") &&
              formatCreatedDate(photo.created_at) >=
                DateTime.fromJSDate(today).minus({ days: 7 }).toFormat("yyyy-MM-dd")
          )
        );
        setPreviousPhotos(
          photosList.filter(
            (photo) =>
              !photo?.pinned &&
              (!photo.created_at ||
                formatCreatedDate(photo.created_at) <
                  DateTime.fromJSDate(today).minus({ days: 8 }).toFormat("yyyy-MM-dd"))
          )
        );
      } else {
        setDateRangePhotos(photosList.filter((photo) => !photo?.pinned));
      }
    } else {
      setPinnedPhotos();
      setTodaysPhotos();
      setRecentPhotos();
      setPreviousPhotos();
      setDateRangePhotos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photosList]);

  return {
    pinnedPhotos,
    todaysPhotos,
    recentPhotos,
    previousPhotos,
    setTodaysPhotos,
    dateRangePhotos,
  };
};

export default usePhotosView;
