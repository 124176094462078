import Email from "../columnRenders/Email";

const email = {
  headerName: "Email",
  field: "email",
  cellRenderer: Email,
  autoHeight: true,
  minWidth: 124,
};

export default email;
