import LogToggler from "../columnRenders/LogToggler";
import SelectAllToggler from "../columnRenders/SelectAllToggler";

const toggler = {
  field: "selected",
  headerName: "",
  width: 50,
  suppressMenu: true,
  headerComponent: SelectAllToggler,
  cellRenderer: LogToggler
};

export default toggler;
