import React, { useState, useEffect } from "react";
import { fieldLogsApi, useApi, ConfirmModal, HeaderButton, useAuth } from "component-library";
import PropTypes from "prop-types";
import { Edit, XCircle, CheckCircle } from "react-feather";
import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import ListingExport from "./ListingExport";
import { useLocation } from "react-router-dom";

const HeaderButtons = ({
  editing,
  setEditing,
  project,
  updateExportedStatus,
  status,
  setFieldLog,
  fieldLog,
  approveDisabled,
  rejectDisabled,
  verifySubmit,
}) => {
  const [approveConfirm, setApproveConfirm] = useState(false);
  const [rejectConfirm, setRejectConfirm] = useState(false);
  const { userCan } = useAuth();
  const { fieldLogId, foremanId } = useParams();
  const { pathname } = useLocation();

  const {
    data: approveData,
    error: approveError,
    request: approveRequest,
    loading: approveLoading,
  } = useApi(fieldLogsApi.approveFieldLog);

  const {
    data: rejectData,
    error: rejectError,
    request: rejectRequest,
    loading: rejectLoading,
  } = useApi(fieldLogsApi.rejectFieldLog);

  const approveFieldLog = () => {
    approveRequest({ foreman_id: foremanId, work_day_id: fieldLogId });
  };

  const rejectFieldLog = () => {
    rejectRequest({ foreman_id: foremanId, work_day_id: fieldLogId });
  };

  const updateStatus = (newStatus, id) => {
    setFieldLog({
      ...fieldLog,
      status: { id: id, status: newStatus },
    });
  };

  useEffect(() => {
    if (!!userCan("payroll-review-time")) {
      if (!!approveData) {
        updateStatus("PM Approved", 5);
        setApproveConfirm(false);
      } else if (!!rejectData) {
        updateStatus("Rejected", 3);
        setRejectConfirm(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectData, approveData]);

  return (
    <>
      {(!!approveData || !!rejectData) && !userCan("payroll-review-time") && (
        <Navigate
          to={
            !!pathname.includes("/project/")
              ? `/project/${project.id}/field-management/field-logs`
              : `/field-management/field-logs`
          }
        />
      )}
      <div className="d-flex">
        {!editing ? (
          <>
            {!!userCan("payroll-review-time") && fieldLog && (
              <HeaderButton
                onClick={() => setEditing(true)}
                icon={
                  <Edit
                    size={16}
                    color={!project || [1, 4].includes(status) ? "#8f8f8f" : "#0071CE"}
                    className="mr-1"
                  />
                }
                text="Edit Field Log"
                disabled={!project || [1, 4].includes(status)}
              />
            )}
            {(!!userCan("pm-review-time") || !!userCan("payroll-review-time")) && (
              <HeaderButton
                onClick={() => setRejectConfirm(true)}
                disabled={rejectDisabled()}
                icon={<XCircle size={16} color={rejectDisabled() ? "#8f8f8f" : "#0071CE"} />}
                text="Reject Field Log"
              />
            )}
            {(!!userCan("pm-review-time") || !!userCan("payroll-review-time")) && (
              <HeaderButton
                onClick={() => {
                  if (!verifySubmit()) setApproveConfirm(true);
                }}
                icon={<CheckCircle size={16} color="#0071CE" />}
                text="Approve Field Log"
              />
            )}
            <ListingExport
              selected={[`${fieldLogId}:${foremanId}`]}
              project={project}
              single
              updateExportedStatus={updateExportedStatus}
              verifySubmit={verifySubmit}
              updateOnClose={true}
            />
          </>
        ) : (
          <>
            <HeaderButton
              onClick={() => setEditing(false)}
              icon={<XCircle size="16" color="#0071CE" />}
              text="Close Edit"
            />
          </>
        )}
      </div>
      <ConfirmModal
        showConfirmation={approveConfirm}
        setShowConfirmation={setApproveConfirm}
        confirmFunction={approveFieldLog}
        loading={approveLoading}
        error={approveError}
        name="Approve Field Log"
        message="Are you sure you want to approve this field log?"
      />
      <ConfirmModal
        showConfirmation={rejectConfirm}
        setShowConfirmation={setRejectConfirm}
        confirmFunction={rejectFieldLog}
        loading={rejectLoading}
        error={rejectError}
        name="Reject Field Log"
        message="Are you sure you want to reject this field log?"
      />
    </>
  );
};

HeaderButtons.propTypes = {
  editing: PropTypes.bool,
  setEditing: PropTypes.func,
  project: PropTypes.object,
  fieldLog: PropTypes.object,
  exportDisabled: PropTypes.bool,
  updateExportedStatus: PropTypes.func,
  status: PropTypes.number,
};

export default HeaderButtons;
