import Reactivate from "../columnRenders/Reactivate";

const reactivate = {
  headerName: "Reactivate Project",
  field: "reactivate",
  width: 200,
  cellRenderer: Reactivate,
};

export default reactivate;
