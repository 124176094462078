import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ActivePermitsList from "./ActivePermitsList";
import NeededPermitsList from "./NeededPermitsList";
import { ChevronRight, ChevronDown } from "react-feather";
import AddNewPermit from "./AddNewPermit";
import { useParams, Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const PermitsSection = ({ permits, updateData }) => {
  const { id } = useParams();
  const [open, setOpen] = useState(true);
  const [addNew, setAddNew] = useState(false);
  const [editPermit, setEditPermit] = useState(null);
  const [unArchivedPermits, setUnArchivedPermits] = useState([]);
  const [neededPermits, setNeededPermits] = useState([]);

  useEffect(() => {
    if (!!permits) {
      const filteredPermits = permits.filter(
        (permit) =>
          !(
            permit.status.toLowerCase() === "archived" ||
            permit.status.toLowerCase() === "needed"
          )
      );

      const needed = permits.filter(
        (permit) => permit.status.toLowerCase() === "needed"
      );

      needed.sort((a, b) => {
        return new Date(a.needed_by) - new Date(b.needed_by);
      });

      filteredPermits.sort((a, b) => {
        return new Date(a.expiration) - new Date(b.expiration);
      });

      setNeededPermits(needed);
      setUnArchivedPermits(filteredPermits);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permits]);

  return (
    <>
      {addNew && <AddNewPermit close={() => setAddNew(false)} update={updateData} />}
      {!!editPermit && <AddNewPermit close={() => setEditPermit(null)} update={updateData} permit={editPermit} />}
      <div className="permits-section">
        <div className="section-header d-flex flex-row justify-content-between py-3">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="page-title font-weight-bold">Permits</div>
            <div className="d-flex align-items-center">
              <Link
                to={`/project/${id}/sitemanagement/permits`}
                className="align-self-center sm ms-2"
                aria-label="view all permits"
              >
                View All
              </Link>
            </div>
          </div>
          <Button
            className={`${styles.sectionToggleButton} d-flex flex-grow-1 justify-content-end`}
            onClick={() => setOpen(!open)}
            aria-label={!!open ? "Hide permit section toggle" : "Show permit section toggle"}
          >
            {open ? <ChevronDown color="#C4C4C4" size={24} /> : <ChevronRight color="#C4C4C4" size={24} />}
          </Button>
        </div>
        {open && (
          <div className="section-content text-end">
            <Button onClick={() => setAddNew(true)} variant="link" aria-label="add new permit">
              Add New +
            </Button>
            <ActivePermitsList permits={unArchivedPermits} setEditPermit={setEditPermit} />
            <NeededPermitsList permits={neededPermits} setEditPermit={setEditPermit} />
          </div>
        )}
      </div>
      <hr />
    </>
  );
};

PermitsSection.propTypes = {
  permits: PropTypes.array,
  updateData: PropTypes.func.isRequired,
};

export default PermitsSection;
