import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell } from "recharts";
import styles from "./styles.module.scss";

const TaskPercentage = ({ reported, estimated, size }) => {
  const [chartData, setChartData] = useState();
  const [percentage, setPercentage] = useState();

  const COLORS = ["#99C6EB", "#0071CE"];

  useEffect(() => {
    if (reported || estimated) {
      setChartData([
        { name: "In Progress", value: estimated - reported },
        { name: "Reported", value: reported },
      ]);
    }
  }, [reported, estimated]);

  useEffect(() => {
    if (!!reported && estimated > 0) {
      setPercentage((reported / estimated) * 100);
    } else {
      setPercentage(0);
    }
  }, [reported, estimated]);

  return (
    <div className="d-inline-block position-relative">
      {!!chartData && (
        <PieChart width={size === "small" ? 50 : 115} height={size === "small" ? 50 : 115}>
          <Pie
            data={chartData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={size === "small" ? 16 : 45}
            outerRadius={size === "small" ? 24 : 55}
            fill="#0071CE"
            labelLine={false}
            startAngle={-270}
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={percentage > 100 ? "#DD482A" : COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      )}
      <div className={styles.totalBase}>
        <div
          className={`${styles.percentageText} ${parseInt(percentage) > 50 ? styles.mediumText : ""} ${
            parseInt(percentage) >= 100 ? styles.smallText : ""
          } ${size === "small" && styles.xsText} text-center`}
        >
          {parseInt(percentage) || 0}
          {size !== "small" ? <sup>%</sup> : <span className={styles.tinyPercent}>%</span>}
        </div>
      </div>
    </div>
  );
};

export default TaskPercentage;
