import React from "react";
import { Loader } from "component-library";
import { Modal, Button, Alert } from "react-bootstrap";
import { Download } from "react-feather";

const ExportModal = ({ showExport, setShowExport, loading, data, error }) => {
  return (
    <>
      <Modal show={showExport} centered onHide={() => setShowExport(false)} animation={false}>
        <div className="d-flex flex-column align-items-center pt-4 mt-2 pb-3">
          {!loading && !!data && data.url ? (
            <>
              <h3 className={` p-3 lg semibold`}>Export Successful</h3>
              <p>
                <a href={data.url} target="_blank" download rel="noreferrer" className="dark-text btn med">
                  <Download size="16" className="me-1" color="#0071ce" />
                  Download file
                </a>
              </p>
              <Button
                className="light-text mx-1 btn btn-lg med w-50"
                variant="orange"
                onClick={() => setShowExport(false)}
              >
                Close
              </Button>
            </>
          ) : (
            <>
              <h3 className="p-3 lg semibold">Export {data?.report_type}</h3>
              {!!error && (
                <Alert variant="danger" className="w-100 errorMsg">
                  {error}
                </Alert>
              )}
              <div className="position-relative">
                <Loader loading={!!loading} />
              </div>
              <Button
                className="light-text mx-1 btn btn-lg med w-50"
                variant="orange"
                onClick={() => setShowExport(false)}
              >
                Close
              </Button>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ExportModal;
