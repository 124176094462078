import React from 'react';
import PropTypes from "prop-types";

const PlanRoom = ({ renderTextField }) => {
  return (
    <div className="row">
      <div className="col-3 py-2">
        {renderTextField({
          name: "plan_room_bid_id",
          label: "Plan Room Bid ID",
        })}
      </div>
    </div>
  );
}

export default PlanRoom;

PlanRoom.propTypes = {
  renderTextField: PropTypes.func.isRequired,
};
