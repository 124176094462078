import React from "react";
import styles from "../styles.module.scss";
import PropTypes from "prop-types";

const ContractAmounts = ({ renderTextField, renderCheckbox, formData }) => {
  const is3or6 = formData.type_id === 3 || formData.type_id === 6;

  return (
    <>
      <fieldset>
        <legend style={{ fontSize: "1.6rem" }}>Engineer's Estimated Contract Amount</legend>
        <div className="d-flex">
          {renderTextField({
            name: "estimated_contract_amount",
            format: "currency",
          })}
          {!!is3or6 && (
            <>
              <p className={`${styles.tinyText} mt-1 me-1 ms-1`}>to</p>
              {renderTextField({
                name: "estimated_contract_amount_high",
                format: "currency",
              })}
            </>
          )}
        </div>
      </fieldset>

      <div className="d-flex">
        <div className={styles.rowFields}>
          {renderTextField({
            name: "retainage_amount",
            label: "Retainage Amount",
            format: "percentage",
          })}
        </div>
        <div className={styles.rowFields}>
          {renderTextField({
            name: "retainage_cap",
            label: "Retainage Cap",
            format: "currency",
          })}
        </div>
      </div>

      <div className="d-flex">
        <div className={styles.rowFields}>
          {renderTextField({
            name: "timesheet_threshold",
            label: `Timesheet Threshold`,
          })}
        </div>
      </div>
      
      <div className="mb-5">
        {renderCheckbox({
          name: "tax_exempt",
          label: "Tax Exempt",
          isToggle: true,
        })}
      </div>
    </>
  );
};

export default ContractAmounts;

ContractAmounts.propTypes = {
  renderTextField: PropTypes.func.isRequired,
  renderCheckbox: PropTypes.func.isRequired,
};
