const typeOptions = [
  { value: "New Locate", label: "New Locate" },
  { value: "Dig in Ticket", label: "Dig in Ticket" },
  {
    value: "Joint Meet Requirement",
    label: "Joint Meet Requirement",
  },
  { value: "Non Response", label: "Non Response" },
  { value: "Utility Locate", label: "Utility Locate" },
];

export default typeOptions;
