import React from "react";
import useUserAdmin from "./hooks/useUserAdmin";
import { Modal, Button } from "react-bootstrap";

const DeactivateUserModal = () => {
  const { userToDeactivate, updateUserStatus, setConfirmDeactivate, setUserToDeactivate, confirmDeactivate } =
    useUserAdmin();

  const handleDeactivate = () => {
    updateUserStatus(userToDeactivate);
    setConfirmDeactivate(false);
    setUserToDeactivate({});
  };

  return (
    <Modal
      centered
      animation={false}
      show={!!confirmDeactivate && !!Object.keys(userToDeactivate).length}
      onHide={() => setConfirmDeactivate(false)}
    >
      <Modal.Header closeButton>
        <h5 className="mb-0 med">
          Confirm Deactivating: {userToDeactivate?.last_name}, {userToDeactivate?.first_name}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-end">
          <Button className="mx-2" variant="tertiary" size="lg" onClick={() => setConfirmDeactivate(false)}>
            Cancel
          </Button>
          <Button className="ms-2" size="lg" onClick={() => handleDeactivate()}>
            Deactivate
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeactivateUserModal;
