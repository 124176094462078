import React from 'react';
import styles from "../styles.module.scss";

const Scope = ({ purchaseOrder, setPurchaseOrder, updatePurchaseOrder }) => {
  return (
    <div className={styles.section}>
      <div className={styles.scope}>
        <label className={styles.label}>Scope</label>
        <textarea
          type="text"
          className={styles.scopeField}
          value={purchaseOrder.scope || ''}
          onChange={(e) =>
            setPurchaseOrder({ ...purchaseOrder, scope: e.target.value })
          }
          onBlur={(e) =>
            updatePurchaseOrder({ id: purchaseOrder.id, scope: e.target.value })
          }
          placeholder="Description"
          aria-label="Scope description"
        />
      </div>
    </div>
  );
}

export default Scope;