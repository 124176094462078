import { ExternalLink } from "react-feather";

const LinkRender = () => {
  return (
    <div className="btn btn-link">
      <ExternalLink size={16} color="#0071ce" />
    </div>
  );
};

const HeaderRender = () => {
  return <ExternalLink size="16" color={"#A7A7A7"} />;
};

const log_link = {
  headerName: "",
  field: "log_link",
  width: 50,
  cellClass: "grid-cell-centered",
  headerClass: "ag-center-aligned-header",
  cellRenderer: LinkRender,
  headerComponent: HeaderRender,
};

export default log_link;
