import React from "react";
import useVendorSelection from "../../../_bidManagement/rfqs/hooks/useVendorSelection";
import { SelectAll } from "component-library";

const SelectAllToggler = () => {
  const { selectedVendorIds, vendors, setSelectedVendorIds, setSelectedVendors, selectedVendors } =
    useVendorSelection();

  return (
    <>
      {!!vendors?.length && (
        <SelectAll
          selectedIds={selectedVendorIds}
          items={vendors}
          setSelectedIds={setSelectedVendorIds}
          setSelectedItems={setSelectedVendors}
          selectedItems={selectedVendors}
        />
      )}
    </>
  );
};

export default SelectAllToggler;
