import {
  complete,
  description,
  difference,
  earned_man_hours,
  estimated_man_hours,
  estimated_qty,
  reported_man_hours,
  reported_qty,
  tracking_id,
  um,
} from "./columnDefs";

const columnDefs = [
  complete,
  description,
  difference,
  earned_man_hours,
  estimated_man_hours,
  estimated_qty,
  reported_man_hours,
  reported_qty,
  tracking_id,
  um,
];

export default columnDefs;
