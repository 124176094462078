import React from "react";
import { useAuth } from "component-library";
import { ProjectAttachment } from "../_projects/ProjectAttachments";
import useTodoAttachment from "./hooks/useTodoAttachment";

const TodoAttachment = ({ bid, setBid, task, setDocLoading }) => {
  const { userCan } = useAuth();
  const { file, setTodoFiles, setFileId } = useTodoAttachment(bid, task, setBid);

  return (
    <ProjectAttachment
      attachment={file}
      setAttachment={setTodoFiles}
      type="Todo"
      projectId={bid.id}
      canUpload={userCan("bid-create") || userCan("bid-edit")}
      setDocLoading={setDocLoading}
      setFileId={setFileId}
      todoId={task.id}
      isBid={bid?.status === "bid"}
    />
  );
};

export default TodoAttachment;
