import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const IconButton = ({
  icon,
  label,
  onClick,
  buttonClassName,
  labelClassName,
  disabled,
  ...props
}) => {
  return (
    <Button
      className={`${buttonClassName} ${styles.button} d-flex align-items-center`}
      variant={disabled ? "link" : "transparent"}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {icon}
      <span className={`${styles.label} ${labelClassName}`}>{label}</span>
    </Button>
  );
}

IconButton.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  onClick: PropTypes.func,
  buttonClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default IconButton;
  