import convertBoolToYN from "../../gridUtils/convertBoolToYN";

const time_in_geofence = {
  headerName: "In-Geo",
  field: "time_in_within_geofence",
  width: 90,
  valueGetter: ({ data }) => convertBoolToYN(data.time_out_within_geofence),
};

export default time_in_geofence;
