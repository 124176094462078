import React from "react";
import Select from "react-select";
import { dropdownStylesWithWidth } from "component-library";
import sectionStyles from "../styles.module.scss";
import delayReasons from "./delayReasons";

const Delay = ({ day, updateDateField }) => {
  const handleSelectChange = (value) => {
    updateDateField(day, "delayed_reason", !!value?.value ? value.value : "");
  };

  return (
    <section className={`${sectionStyles.fieldSection}`}>
      <div className="d-flex align-items-center mb-5">
        <h3>Delay</h3>
        <div className="form-group d-flex align-items-start w-100">
          <Select
            options={delayReasons}
            name={`${day.id}-reason_id`}
            id={`${day.id}-reason_id`}
            onChange={(value) => handleSelectChange(value)}
            value={delayReasons.find((opt) => opt.value === day.delayed_reason)}
            styles={dropdownStylesWithWidth(300)}
            aria-label="Delay reason options"
            isClearable={true}
            tabSelectsValue={false}
            isMulti={false}
            isSearchable={false}
            placeholder="Reason..."
          />
        </div>
      </div>
    </section>
  );
};

export default Delay;
