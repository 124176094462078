import PlansLink from "../bidColumns/PlansLink";

const plans_location = {
  headerName: "Plans",
  field: "plans_location",
  width: 72,
  headerClass: "ag-center-aligned-header",
  cellStyle: { justifyContent: "center" },
  suppressMenu: true,
  cellRenderer: PlansLink,
};

export default plans_location;
