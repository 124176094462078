import React, { useState, useEffect } from "react";
import contactsApi from "../../api/contacts";
import Contacts from "./Contacts";
import { useApi } from "component-library";
import PropTypes from "prop-types";

const EditContacts = ({
  contacts,
  updateContent,
  submittal,
  projectOwner,
  projectOwners,
  updateProjectOwner,
  editing,
  updateSource,
  source,
}) => {
  const [error, setError] = useState(false);
  const { request: removeRequest, data: removeData, error: removeError } = useApi(contactsApi.removeContact);

  const { request: addRequest, data: addData, error: addError } = useApi(contactsApi.addContact);

  const removeContact = async (selectedContact, role) => {
    if (role === "source") {
      updateSource();
    } else {
      await removeRequest({
        submittal_id: submittal.id,
        contact_id: selectedContact.id,
        contact_role: role.name,
      });
    }
  };

  const addContact = async (newContact) => {
    addRequest({
      submittal_id: submittal.id,
      contact_role: newContact.role,
      contact_id: newContact.contact.id,
    });
  };

  useEffect(() => {
    if (!!addData) {
      updateContent(addData.submittal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addData]);

  useEffect(() => {
    if (removeData && removeData.submittal) updateContent(removeData.submittal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeData]);

  useEffect(() => {
    if (!!addError || !!removeError) {
      setError(addError || removeError);
    } else {
      setError();
    }
  }, [addError, removeError]);

  return (
    <Contacts
      contacts={contacts}
      addContact={addContact}
      removeContact={removeContact}
      updateProjectOwner={updateProjectOwner}
      error={error}
      setError={setError}
      projectOwner={projectOwner}
      editing={editing}
      projectOwners={projectOwners}
      updateSource={updateSource}
      source={source}
    />
  );
};

EditContacts.propTypes = {
  contacts: PropTypes.array,
  updateContent: PropTypes.func.isRequired,
  submittal: PropTypes.object,
  projectOwner: PropTypes.object,
  projectOwners: PropTypes.array,
  updateProjectOwner: PropTypes.func,
  editing: PropTypes.bool,
  source: PropTypes.object,
  updateSource: PropTypes.func,
};

export default EditContacts;
