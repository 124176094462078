import React from "react";
import { FileText } from "react-feather";

const Filename = ({ data }) => {
  return (
    <div className="text-primary d-flex align-items-center">
      <FileText color="#0071ce" size="16" className="me-3" /> {data.form_name}
    </div>
  );
};

export default Filename;
