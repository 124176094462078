import React, { useState, useEffect } from "react";
import { projectApi, useApi } from "component-library";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import DetailsBar from "../../DetailsBar";
import { Briefcase } from "react-feather";
import HeaderButtons from "../HeaderButtons";
import useFieldLogDetail from "../hooks/useFieldLogDetail";
import TaskView from "./TaskView";
import TimesheetView from "./TimesheetView";
import { Alert, Badge, Button } from "react-bootstrap";
import useExportLogic from "../../../_projects/project/FieldManagement/FieldLogs/FieldLog/useExportLogic";
import formatProjectTitle from "../../../utils/formatProjectTitle";
import { displayDate } from "component-library";
import Tabs from "../../Tabs";
import { Paperclip } from "react-feather";
import ProjectNotesModal from "../../ProjectNotesModal";
import styles from "./styles.module.scss";
import useFieldLogVerification from "./useFieldLogVerification";
import HeaderPortal from "../../../components/HeaderPortal";

const FieldLog = () => {
  const { request: configRequest, data: configData, loading: configLoading } = useApi(projectApi.getProjectConfig);

  const { request: projectRequest, data: projectData, loading: projectLoading } = useApi(projectApi.getProject);

  const [config, setConfig] = useState();
  const [project, setProject] = useState();
  const [pageTitle, setPageTitle] = useState();
  const [showNotesModal, setShowNotesModal] = useState(false);

  const PageTag = () => (
    <>
      {!!project && !!project.certified_payroll && (
        <Badge pill className="ms-2 pill" bg="redOrange">
          pw
        </Badge>
      )}
    </>
  );

  const {
    fieldLog,
    setFieldLog,
    taskView,
    setTaskView,
    loading,
    error,
    editing,
    setEditing,
    crew,
    foremanId,
    projectsList,
    overAllocatedTimeSheets,
    setOverAllocatedTimeSheets,
    crewLoading,
    minutesUnassigned,
  } = useFieldLogDetail();

  const tasks = !!fieldLog?.work_day_tasks?.length ? fieldLog.work_day_tasks : [];

  useEffect(() => {
    if (!!fieldLog) {
      if (!config && !configLoading) {
        configRequest(fieldLog.project_id);
      }
      if (!project && !projectLoading) {
        projectRequest(fieldLog.project_id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldLog?.project_id]);

  useEffect(() => {
    if (configData && configData.config) {
      setConfig(configData.config);
    }
  }, [configData]);

  useEffect(() => {
    if (!!projectData && !!projectData.project) {
      setProject(projectData.project);
    }
  }, [projectData]);

  useEffect(() => {
    setPageTitle(formatProjectTitle(project, loading, error));
  }, [project, loading, error]);

  const { exportDisabled, updateExportedStatus, status } = useExportLogic({
    fieldLog,
    setFieldLog,
    minutesUnassigned,
    overAllocatedTimeSheets,
  });

  const {
    approveDisabled,
    rejectDisabled,
    verifySubmit,
    errorMessageList,
    setErrorMessageList,
    invalidRequiredQuantities,
    setInvalidRequiredQuantities,
    verifyQuantity,
    requiredValueValidate,
  } = useFieldLogVerification({ tasks, project, status, minutesUnassigned, overAllocatedTimeSheets });

  return (
    <InteriorPage
      navTitle={pageTitle}
      loading={loading}
      error={error}
      pageTitle="Field Log Review"
      navIcon={<Briefcase color="#fff" size={14} />}
      pageSubtitlePrimaryContent={() => (
        <div className="lg font-weight-bold mb-0">{!!fieldLog && displayDate(fieldLog.date)}</div>
      )}
      NavContent={PageTag}
      titleTag={"Field Management"}
    >
      <Button variant="link" className="d-flex align-items-center med ps-0" onClick={() => setShowNotesModal(true)}>
        <Paperclip className="me-1" size={18} color="#0071ce" />
        View Project Notes
      </Button>
      {!!errorMessageList.length && errorMessageList.map((error) => <Alert variant="danger">{error}</Alert>)}
      <div className={`${styles.detailsRow} d-flex align-items-end justify-content-between`}>
        <Tabs taskView={taskView} setTaskView={setTaskView} />
        <DetailsBar fieldLog={fieldLog} project={project} foremanId={foremanId} crew={crew} crewLoading={crewLoading} />
      </div>
      {!!taskView ? (
        <TaskView
          editing={editing}
          config={config}
          tasks={tasks}
          timesheets={!!fieldLog?.time_sheets?.length ? fieldLog.time_sheets : []}
          fieldLog={fieldLog}
          crew={crew}
          crewLoading={crewLoading}
          setFieldLog={setFieldLog}
          foremanId={foremanId}
          overAllocatedTimeSheets={overAllocatedTimeSheets}
          setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
          projectId={!!project ? project.id : undefined}
          prevailingWage={!!project ? project.certified_payroll : 0}
          status={status}
          job_number={project?.job_number}
          errorMessageList={errorMessageList}
          setErrorMessageList={setErrorMessageList}
          invalidRequiredQuantities={invalidRequiredQuantities}
          setInvalidRequiredQuantities={setInvalidRequiredQuantities}
          verifyQuantity={verifyQuantity}
          requiredValueValidate={requiredValueValidate}
        />
      ) : (
        <TimesheetView
          editing={editing}
          config={config}
          tasks={tasks}
          timesheets={!!fieldLog?.time_sheets?.length ? fieldLog.time_sheets : []}
          projectsList={projectsList}
          setFieldLog={setFieldLog}
          fieldLog={fieldLog}
          foremanId={foremanId}
          crew={crew}
          crewLoading={crewLoading}
          project={project}
          overAllocatedTimeSheets={overAllocatedTimeSheets}
          setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
          errorMessageList={errorMessageList}
          setErrorMessageList={setErrorMessageList}
          requiredValueValidate={requiredValueValidate}
        />
      )}
      <ProjectNotesModal
        project={project}
        projectLoading={projectLoading}
        showModal={showNotesModal}
        onHide={() => setShowNotesModal(false)}
      />
      <HeaderPortal elementId={"pageSubTitleSecondary"}>
        <HeaderButtons
          editing={editing}
          setEditing={setEditing}
          exportDisabled={exportDisabled}
          project={project}
          status={status}
          fieldLog={fieldLog}
          setFieldLog={setFieldLog}
          updateExportedStatus={updateExportedStatus}
          minutesUnassigned={minutesUnassigned}
          overAllocatedTimeSheets={overAllocatedTimeSheets}
          errorMessageList={errorMessageList}
          setErrorMessageList={setErrorMessageList}
          verifySubmit={verifySubmit}
          approveDisabled={approveDisabled}
          rejectDisabled={rejectDisabled}
        />
      </HeaderPortal>
    </InteriorPage>
  );
};

export default FieldLog;
