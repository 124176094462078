import React from "react";
import { Alert } from "react-bootstrap";
import useContactsFilters from "./hooks/useContactsFilters";
import ContactsFilters from "./ContactsFilters";
import Pagination from "../Pagination";
import { AgGridTable, useAuth } from "component-library";
import contactsAdminListConfig from "../../config/contactsAdminListConfig";
import { useNavigate } from "react-router-dom";
import UnauthorizedCard from "../UnauthorizedCard";

const ContactsAdmin = ({
  contacts,
  error,
  loading,
  successMessage,
  setSuccessMessage,
  setError,
  searchContacts,
  searchLoading,
  setCurrentPage,
  currentPage,
  resultsTotal,
  itemsPerPage,
}) => {
  const { userCan, user } = useAuth();
  const { filteredByLetter, updateCategory, updateLetter, letter, selectedCategories } = useContactsFilters(
    contacts,
    "last_name",
    true
  );
  let history = useNavigate();

  const handleCellClick = ({ data, column }) => {
    if (!["phone", "email"].includes(column?.colId)) {
      let url = `/directory/company/${data.company_id}`;
      history(url);
    }
  };

  return (
    <div>
      {userCan("contact-list") ? (
        <>
          <ContactsFilters
            updateCategory={updateCategory}
            updateLetter={updateLetter}
            letter={letter}
            selectedCategories={selectedCategories}
            searchFunction={searchContacts}
            searchLoading={searchLoading}
            searchText={"Find a Contact"}
          />
          {!!error && (
            <Alert variant="danger" onClick={() => setError(false)}>
              {error}
            </Alert>
          )}
          {!!successMessage && (
            <Alert variant="success" onClick={() => setSuccessMessage(false)} dismissible>
              {successMessage}
            </Alert>
          )}
          <div className="position-relative">
            <AgGridTable
              gridData={filteredByLetter.sort((a, b) => {
                return a.contact_archived - b.contact_archived;
              })}
              loading={!!loading || !!searchLoading}
              gridConfig={contactsAdminListConfig}
              handleCellClick={handleCellClick}
              id={`${user.id}_contact_admin_listing`}
              presetStyle="sortableTable"
            />
          </div>
          <Pagination
            items={contacts}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            total={resultsTotal}
          />
        </>
      ) : (
        <UnauthorizedCard />
      )}
    </div>
  );
};

export default ContactsAdmin;
