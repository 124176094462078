import React from "react";
import PropTypes from "prop-types";
import { times, dropdownStyles, useAuth } from "component-library";

const BidInfo = ({ renderSelectField, renderTextField, data }) => {
  const { config } = useAuth();

  return (
    <div className="row py-2">
      <div className="col-12">
        <h1 className="form-section-header">Bid info</h1>
      </div>
      <div className="col-md-3">
        {renderTextField({
          name: "bid_date",
          label: "Bid Date",
          type: "date",
        })}
      </div>
      <div className="col-md-3">
        {renderSelectField({
          name: "bid_time",
          label: "Bid Time",
          options: times,
          styles: dropdownStyles,
        })}
      </div>
      {data.type_id !== 3 && data.type_id !== 6 && (
        <div className="col-md-8">
          {renderTextField({
            name: "bid_location",
            label: "Bid Location",
          })}
        </div>
      )}

      <div className="w-100"></div>
      <div className="col-md-3">
        {renderSelectField({
          name: "bid_type_id",
          label: "Bid Type",
          options: !!config.bid_types
            ? config.bid_types.map((type) => ({
                value: type.id,
                label: type.name,
              }))
            : [],
          styles: dropdownStyles,
        })}
      </div>
    </div>
  );
};

export default BidInfo;

BidInfo.propTypes = {
  data: PropTypes.object.isRequired,
  renderSelectField: PropTypes.func.isRequired,
  renderTextField: PropTypes.func.isRequired,
};
