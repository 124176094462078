import React from 'react';
import ProvideProject from './../../_projects/ProvideProject';
import ProjectPage from '../pageLayout/ProjectPage';

const Project = () => {
  return (
    <ProvideProject>
      <ProjectPage />
    </ProvideProject>
  );
}

export default Project;