import React from 'react';
import { Button } from 'react-bootstrap';
import styles from "./ContactsAdmin.module.scss";
import { XCircle } from "react-feather";

const CategoryButton = ({ category, updateCategory, active }) => {
  const handleSelection = () => {
    updateCategory(category.id);
  }
  return (
    <Button
      className={`${styles.categoryButton} ${active ? `${styles.catActive} font-weight-bold` : ''}`}
      onClick={() => handleSelection()}
    >
      {category.name}
      {!!active && (
        <XCircle className={styles.close} size="12" />
      )}
    </Button>
  );
}

export default CategoryButton;