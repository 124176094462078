import React from "react";
import styles from "./styles.module.scss";
import { CheckCircle } from "react-feather";

const GallerySelector = ({ selected, selectable, onSelectionChange = () => {} }) => {
  return (
    <>
      <div
        className={`${styles.selector} ${selectable ? styles.selectable : ""} ${selected ? styles.selected : ""}`}
        onClick={onSelectionChange}
      >
        <CheckCircle color="#FFFFFF" size={"2rem"} />
      </div>
    </>
  );
};

GallerySelector.propTypes = {};

export default GallerySelector;
