import OnSite from "../../CardView/Card/OnSite";

const onsite = {
  headerName: "Timesheets",
  field: "onsite",
  width: 124,
  cellRenderer: OnSite,
};

export default onsite;
