import React, { useState } from "react";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import { ArrowRight, Briefcase, XCircle } from "react-feather";
import useTimesheetDetail from "./useTimesheetDetail";
import { Badge } from "react-bootstrap";
import { ConfirmModal, HeaderButton, displayDate } from "component-library";
import DetailsBar from "./DetailsBar";
import styles from "./styles.module.scss";
import Map from "../../../../_resourceManagement/Map";
import EditTime from "./EditTime";
import TimeEntries from "./TimeEntries";
import { Alert } from "react-bootstrap";
import useUpdateTimesheet from "../../../../_resourceManagement/FieldLogs/hooks/useUpdateTimesheet";
import { BeatLoader } from "react-spinners";
import TimesheetHeader from "./TimesheetHeader";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const TimesheetDetail = () => {
  const { id } = useParams();
  const [overAllocatedTimeSheets, setOverAllocatedTimeSheets] = useState([]);
  const [focusMarker, setFocusMarker] = useState();

  const PageTag = () => (
    <>
      {!!project && !!project.certified_payroll && (
        <Badge pill className="ms-2 pill" bg="redOrange">
          pw
        </Badge>
      )}
    </>
  );

  const {
    pageTitle,
    timesheet,
    setTimesheet,
    error,
    project,
    workDay,
    workDayError,
    projectsList,
    unAssignedHours,
    timesheetLocked,
    config,
    foremanId,
    timeSheetError,
    canDeleteTimesheet,
    confirmModalOpen,
    setConfirmModalOpen,
    timeSheetDeleteLoading,
    timeSheetDeleteError,
    handleDeleteTimesheet,
    deleteReason,
    setDeleteReason,
  } = useTimesheetDetail();

  const { loading, updateError, timeIn, setTimeIn, setTimeOut, timeOut, updateTimeIn, updateTimeOut, updateTimesheet } =
    useUpdateTimesheet({
      timesheet,
      setTimesheet,
      overAllocatedTimeSheets,
      setOverAllocatedTimeSheets,
      logDate: workDay?.date,
    });

  const SubtitleContent = () => (
    <div className="d-flex align-items-center">
      <h1 className="mb-0 me-5 font-weight-bold">
        {!!timesheet ? `${timesheet?.user?.last_name}, ${timesheet?.user?.first_name}` : "Loading..."}
      </h1>
      <div className="mb-0 lg text-nearBlack">{!!timesheet && displayDate(timesheet?.time_in, true)}</div>
      {!!project?.job_number && <div className="ms-5 lg text-nearBlack">#{project?.job_number}</div>}
    </div>
  );

  return (
    <InteriorPage
      navTitle={pageTitle || "Loading..."}
      error={error || workDayError || timeSheetDeleteError || updateError}
      pageTitle={
        <div className={styles.breadcrumbs}>
          <Link to={`/project/${id}/field-management/timesheet-logs`}>Crew Timesheets</Link>
          <ArrowRight className="mx-4" size={12} color="#7B7B7B" />
          <Link to={`/project/${id}/field-management/crew-log/${timesheet?.user?.id}`}>Crew Detail</Link>
        </div>
      }
      loading={timeSheetError}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageSubtitlePrimaryContent={SubtitleContent}
      pageTitleSecondaryContent={() => (
        <HeaderButton
          icon={<XCircle size={16} color={!canDeleteTimesheet ? "#8f8f8f" : "#0071CE"} />}
          text="Delete Timesheet"
          disabled={!canDeleteTimesheet}
          onClick={() => {
            if (!!canDeleteTimesheet) {
              setConfirmModalOpen(true);
              setDeleteReason("");
            }
          }}
        />
      )}
      NavContent={PageTag}
      titleTag={"Field Management"}
    >
      <div>
        {(!!overAllocatedTimeSheets?.length || unAssignedHours < 0) && (
          <Alert variant="danger">Timesheet is overallocated</Alert>
        )}
        {!!timesheet && <TimesheetHeader user={timesheet?.user} />}
        <div className="contentContainer">
          <div className="mainContent">
            <DetailsBar
              project={project}
              timesheet={timesheet}
              foremanId={foremanId}
              workDay={workDay}
              unAssignedHours={unAssignedHours}
              overAllocatedTimeSheets={overAllocatedTimeSheets}
            />
            <div className="position-relative">
              {!!loading && (
                <div className="loaderWrapper">
                  <BeatLoader color="#0071ce" />
                </div>
              )}
              <TimeEntries
                entries={timesheet?.time_entries}
                workDay={workDay}
                timesheet={timesheet}
                setTimesheet={setTimesheet}
                project={project}
                overAllocatedTimeSheets={overAllocatedTimeSheets}
                setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
                updateTimesheet={updateTimesheet}
                projectsList={projectsList}
                timesheetLocked={timesheetLocked}
                config={config}
              />
            </div>
          </div>
          <div className="sidebar">
            <div className={styles.mapWrapper}>
              <Map timesheet={timesheet} project={project} timesheetLoading={loading} focusMarker={focusMarker} />
            </div>
            <EditTime
              loading={loading}
              timeIn={timeIn}
              setTimeIn={setTimeIn}
              setTimeOut={setTimeOut}
              timeOut={timeOut}
              updateTimeIn={updateTimeIn}
              updateTimeOut={updateTimeOut}
              timesheetLocked={timesheetLocked}
              timesheet={timesheet}
              project={project}
              setFocusMarker={setFocusMarker}
            />
          </div>
        </div>
      </div>
      <ConfirmModal
        showConfirmation={confirmModalOpen}
        setShowConfirmation={setConfirmModalOpen}
        confirmFunction={handleDeleteTimesheet}
        loading={timeSheetDeleteLoading}
        error={timeSheetDeleteError}
        name="Delete Timesheet"
        message="Are you sure you want to delete this timesheet?"
        disabled={!deleteReason}
      >
        <div className={`form-group w-100`}>
          <label className="form-label me-3" htmlFor="delete-reason-field">
            Please state your reason for deleting this timesheet.
          </label>
          <input
            id="delete-reason-field"
            className="form-control border-bottom"
            value={deleteReason}
            onChange={(e) => {
              setDeleteReason(e.target.value);
            }}
          />
        </div>
      </ConfirmModal>
    </InteriorPage>
  );
};

export default TimesheetDetail;
