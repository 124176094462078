import React from "react";
import ForemanReminder from "../../_projects/project/FieldManagement/FieldLogs/ForemanReminder";
import ListingExport from "./ListingExport";

const GlobalSecondaryHeader = ({
  updateExportedStatus,
  selected,
  updateApproveStatus,
  selectedLogs,
  consoleData,
  verifySubmit,
}) => {
  return (
    <div className="d-flex align-items-center">
      <ForemanReminder />
      <ListingExport
        selected={selected}
        updateExportedStatus={updateApproveStatus}
        approve={true}
        selectedLogs={selectedLogs}
        disableIfAllApproved={true}
        consoleData={consoleData}
        verifySubmit={verifySubmit}
      />
      <ListingExport
        selected={selected}
        updateExportedStatus={updateExportedStatus}
        consoleData={consoleData}
        exportConfirm
        verifySubmit={verifySubmit}
      />
    </div>
  );
};

export default GlobalSecondaryHeader;
