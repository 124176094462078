import React from "react";
import PropTypes from "prop-types";
import { dropdownStyles, useAuth } from "component-library";

const ProjectDetails = ({ renderCheckbox, renderTextField, renderSelectField, data }) => {
  const { config } = useAuth();
  const maintenancePeriodOptions = config.maintenance_period_config.map((option) => ({
    value: option.id,
    label: option.value,
  }));

  const maintenancePeriodCustomValue = maintenancePeriodOptions.find(
    (option) => option.label.toLowerCase() === "custom"
  ).value;

  const contractTurnaroundOptions = config.contract_turnaround_time_config.map((option) => ({
    value: option.id,
    label: option.value,
  }));

  const contractTurnaroundCustomValue = contractTurnaroundOptions.find(
    (option) => option.label.toLowerCase() === "custom"
  ).value;

  return (
    <>
      <div className="row py-2 d-flex align-items-center">
        <div className="col-md-4">
          {renderTextField({
            name: "maintenance_period",
            label: `Maintenance Period`,
          })}
        </div>
        <div className="col-md-2">
          {renderSelectField({
            name: "maintenance_period_type",
            options: maintenancePeriodOptions,
            styles: dropdownStyles,
          })}
        </div>
        {data.maintenance_period_type === maintenancePeriodCustomValue && (
          <div className="col-md-4">
            {renderTextField({
              name: "maintenance_period_other",
              label: `Custom`,
            })}
          </div>
        )}
      </div>
      <div className="row py-2">
        <div className="col-md-4">
          {renderTextField({
            name: "contract_turnaround_time",
            label: `Contract Turnaround Time`,
          })}
        </div>
        <div className="col-md-2">
          {renderSelectField({
            name: "contract_turnaround_type",
            options: contractTurnaroundOptions,
            styles: dropdownStyles,
          })}
        </div>
        {data.contract_turnaround_type === contractTurnaroundCustomValue && (
          <div className="col-md-4">
            {renderTextField({
              label: `Custom`,
              name: "contract_turnaround_time_other",
            })}
          </div>
        )}
      </div>
      <div className="row py-2">
        <div className="col-md-5">
          {renderTextField({
            name: "retainage_amount",
            label: `Retainage Amount`,
            format: "percentage",
          })}
        </div>
        <div className="col-md-5">
          {renderTextField({
            name: "retainage_cap",
            label: `Retainage Cap`,
            format: "currency",
          })}
        </div>
      </div>
      <div className="row py-2">
        <div className="col-md-4">
          {renderCheckbox({
            name: "tax_exempt",
            isToggle: true,
            label: `Tax Exempt`,
          })}
        </div>
      </div>
      <div className="row py-2">
        <div className="col-md-4">
          {renderCheckbox({
            name: "certified_payroll",
            isToggle: true,
            label: `Certified Payroll`,
          })}
        </div>
      </div>
      <div className="row py-2">
        <div className="col-md-4">
          {renderCheckbox({
            name: "nonbillable",
            isToggle: true,
            label: `Overhead Job`,
          })}
        </div>
      </div>
    </>
  );
};

export default ProjectDetails;

ProjectDetails.propTypes = {
  renderTextField: PropTypes.func.isRequired,
  renderCheckbox: PropTypes.func.isRequired,
  renderSelectField: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
};
