import React from "react";
import PhotoHeaders from "../SingleItems/PhotoHeaders";
import SingleAlbum from "../SingleItems/SingleAlbum";
import SinglePhoto from "../SingleItems/SinglePhoto";
import { ReactComponent as Unpinned } from "../../../../assets/photoUnpinned.svg";
import styles from "../styles.module.scss";
import { BeatLoader } from "react-spinners";

const PinnedPhotosAlbums = ({
  pinnedList,
  handlePin,
  handleDelete,
  params,
  loading,
  selectedItems,
  setSelectedItems,
  selectMode,
}) => {
  const TitleIcon = () => <Unpinned className={styles.titleIcon} />;

  return (
    <div className={`${styles.listContainer} mt-2`}>
      <PhotoHeaders title="Pinned Photos and Albums" TitleIcon={TitleIcon} />
      {!!loading ? (
        <div className={styles.loaderWrapper}>
          <BeatLoader color="#0071ce" />
        </div>
      ) : (
        <>
          {!!pinnedList?.length ? (
            pinnedList.map((item) =>
              item.file_name ? (
                <SinglePhoto
                  photo={item}
                  params={params}
                  key={`${item.id}photo`}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  selectMode={selectMode}
                  handlePin={handlePin}
                  handleDelete={handleDelete}
                />
              ) : (
                <SingleAlbum
                  album={item}
                  params={params}
                  key={`${item.id}album`}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  selectMode={selectMode}
                  handlePin={handlePin}
                  handleDelete={handleDelete}
                />
              )
            )
          ) : (
            <div>No items found.</div>
          )}
        </>
      )}
    </div>
  );
};

export default PinnedPhotosAlbums;
