import React from "react";
import PropTypes from "prop-types";

const CostCode = ({ editTasks, item, updateItem }) => {
  return (
    <td className="text-end">
      {!!editTasks && item?.entity_type === "task" && !item.time_entries_count ? (
        <>
          <label className="d-none" htmlFor={`${item.id}-cost-code-edit`} aria-label="task cost code edit"></label>
          <input
            id={`${item.id}-cost-code-edit`}
            name={`${item.id}-cost-code-edit`}
            type="text"
            className="form-control"
            value={item.cost_code || ""}
            onChange={(e) => updateItem("cost_code", e.target.value, item.id)}
            placeholder="Code"
          />
        </>
      ) : (
        <>{!!item.cost_code ? item.cost_code : "-"}</>
      )}
    </td>
  );
};

CostCode.propTypes = {
  editTasks: PropTypes.bool,
  item: PropTypes.object,
  updateItem: PropTypes.func,
};

export default CostCode;
