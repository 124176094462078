import React from "react";
import styles from "./TakeoffDetail.module.scss";
const Materials = ({ task }) => {
  return (
    <div className={`d-flex justify-content-between align-items-center pe-4 ms-5 ${styles["material-block"]}`}>
      <div className="d-flex align-center">
        <div className="me-5 text-gray2">{task.name}</div>
        {!!task.description && <div className="sm pt-1">{task.description}</div>}
      </div>
      <div className="text-gray2">
        {task.bid_quantity} {task.unit_measure}
      </div>
    </div>
  );
};

export default Materials