import {
  absent,
  date,
  late,
  name,
  absent_note,
  absent_reason,
} from "./columnDefs";

const columnDefs = [name, date, late, absent, absent_note, absent_reason];

export default columnDefs;
