import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const NoteImages = ({ images }) => {
  return (
    <>
      <div
        className={styles.firstImage}
        style={{ backgroundImage: `url(${images[0]?.file?.small_thumbnail || images[0]?.file?.abs_url})` }}
      ></div>
      {images.length >= 2 && (
        <div className={styles.secondCol}>
          <span
            className={styles.smImg}
            style={{ backgroundImage: `url(${images[1]?.file?.small_thumbnail || images[1]?.file?.abs_url})` }}
          ></span>
          {!!images[2] && (
            <span
              className={styles.smImg}
              style={{ backgroundImage: `url(${images[2]?.file?.small_thumbnail || images[2]?.file?.abs_url})` }}
            >
              {images.length > 3 && <span className={styles.overlay}>+{images.length - 3}</span>}
            </span>
          )}
        </div>
      )}
    </>
  );
};

NoteImages.propTypes = {
  images: PropTypes.array,
};

export default NoteImages;
