import { useApi,purchaseOrdersApi } from "component-library";

const useAddPurchaseOrder = () => {
  const { data, loading, error, request } = useApi(purchaseOrdersApi.addPurchaseOrder);

  const addPurchaseOrder = async (quote_id) => {
    await request(quote_id);
  };

  return ({
    addPurchaseOrder,
    loading,
    error,
    data,
  });
}

export default useAddPurchaseOrder;