import { DateTime } from "luxon";
import formatGridValues from "../../../../utils/formatGridValue";

const renderDateColumnDefs = (config, formData) => {
  let newColumnDefs = config;
  const oneDay = { days: 1 };
  if (formData?.from_date && formData?.to_date) {
    for (
      let date = DateTime.fromISO(formData.from_date);
      date <= DateTime.fromISO(formData.to_date);
      date = date.plus(oneDay)
    ) {
      let newObject = {
        headerName: date.toFormat("M/d/yy"),
        field: date.toFormat("yyyy-MM-dd"),
        type: "numericColumn",
        cellStyle: { justifyContent: "flex-end" },
        valueGetter: (params) => {
          const value = formatGridValues(params.data[date.toFormat("yyyy-MM-dd")] ?? 0);
          return value === 0 ? "-" : value;
        },
      };
      newColumnDefs = [...newColumnDefs, newObject];
    }
  }
  return newColumnDefs;
};

export default renderDateColumnDefs;
