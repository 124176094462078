import { useState, useEffect } from "react";
import { useApi, dailyFieldlogsApi } from "component-library";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

const useMilestones = () => {
  const { id } = useParams();
  const [milestones, setMilestones] = useState();

  const {
    request: milstonesRequest,
    data: milestonesData,
    error: milestonesError,
    loading: milestonesLoading,
  } = useApi(dailyFieldlogsApi.getMilestones);

  useEffect(() => {
    if (!!id) {
      milstonesRequest(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (milestonesData?.milestones) {
      setMilestones(milestonesData.milestones);
    }
  }, [milestonesData?.milestones]);

  return {
    milestones,
    milestonesError,
    milestonesLoading,
  };
};

useMilestones.propTypes = {
  currentSelectedDate: PropTypes.string,
};

export default useMilestones;
