import { useState, useEffect } from "react";
import { useApi, taskDetailApi } from "component-library";
import { useParams } from "react-router-dom";

const useTaskDetailPhotos = () => {
  const { id, taskId } = useParams();
  const [photos, setPhotos] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    request: photosRequest,
    data: photosData,
    loading: photosLoading,
    error: photosError,
  } = useApi(taskDetailApi.getTaskFiles);

  useEffect(() => {
    if (taskId) {
      photosRequest({ projectId: id, taskId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  useEffect(() => {
    setPhotos(photosData?.files?.data?.map((file) => {
      return {
        file: file.attachment.file
      }
    }) || []);
  }, [photosData]);

  return {
    photos,
    photosLoading,
    photosError,
    modalOpen,
    setModalOpen,
  };
};

export default useTaskDetailPhotos;
