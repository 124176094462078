import React from 'react'
import styles from "./styles.module.scss";

const HistoryEvent = ({ historyEvent }) => {

  const userName = `${historyEvent.user.first_name} ${historyEvent.user.last_name}`

  return (
    <div className="d-flex mb-3">
      <div className={`${styles.historyDateTime} ${styles.historyDateWidth}`}>
        {historyEvent?.date}
      </div>
      <div className={styles.eventBorder}>
        <div className={styles.historyDateTime}>
          {historyEvent?.time}
        </div>
        <div className={styles.event}>
          {historyEvent?.event} by {userName}
        </div>
      </div>
    </div>
  )
}

export default HistoryEvent