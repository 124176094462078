import React from "react";
import { Button } from "react-bootstrap";
import { FilterSearch } from "component-library";
import { Grid, List, Map } from "react-feather";
import CardView from "./CardView";
import { Loader } from "component-library";
import { Alert } from "react-bootstrap";
import styles from "./styles.module.scss";
import useProjectSelection from "./hooks/useProjectSelection";
import ListView from "./ListView";
import MapView from "./MapView";

const ProjectSelectionContent = () => {
  const { filteredProjects, q, searchFunction, tab, setTab, error, favoriteError, loading } = useProjectSelection();

  return (
    <div className="project-selection">
      <Alert variant="danger" show={!!error || !!favoriteError}>
        {error || favoriteError}
      </Alert>
      <div className={styles.header}>
        <FilterSearch placeholder="Search for a project" value={q} searchFunction={searchFunction} inline />
        <div className={styles.buttonGroup}>
          <Button
            className={tab === "list" ? styles.buttonActive : styles.button}
            onClick={() => setTab("list")}
            active={tab === "list"}
            aria-label="view projects as a list"
          >
            <List size={16} />
          </Button>
          <Button
            className={tab === "grid" ? styles.buttonActive : styles.button}
            onClick={() => setTab("grid")}
            active={tab === "grid"}
            aria-label="view projects as a grid"
          >
            <Grid size={16} />
          </Button>
          <Button
            className={tab === "map" ? styles.buttonActive : styles.button}
            onClick={() => setTab("map")}
            active={tab === "map"}
            aria-label="view projects on a map"
          >
            <Map size={16} />
          </Button>
        </div>
      </div>
      <div className={styles.results}>
        {loading ? (
          <Loader />
        ) : (
          <>
            {tab === "grid" && <CardView projects={filteredProjects} />}
            {tab === "list" && <ListView projects={filteredProjects} />}
            {tab === "map" && <MapView projects={filteredProjects} loading={loading} />}
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectSelectionContent;
