export const createInitialState = () => {
  return {
    report: {},
    weather: [],
    notes: [],
    removed_notes: [],
    processNoteFiles: false,
    loading: false,
    error: false,
    message: "",
    reportUpdated: false,
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "set_intitial_report":
      return {
        ...state,
        report: action.report,
      };
    case "update_report":
      return {
        ...state,
        report: action.report,
        processNoteFiles: false,
        message: action.message || state.message,
        reportUpdated: true,
      };
    case "update_weather":
      return {
        ...state,
        weather: action.weather,
      };
    case "remove_note":
      return {
        ...state,
        removed_notes: !!state?.removed_notes?.length
          ? [...state.removed_notes, action.removedNote]
          : [action.removedNote],
        notes: action.notes,
      };
    case "update_notes":
      return {
        ...state,
        notes: action.notes,
        removed_notes: state?.removed_notes?.filter((note) => action?.noteIds?.includes(note.id)),
        processNoteFiles: true,
      };
    case "set_initial_notes":
      return {
        ...state,
        notes: action.notes,
      };
    case "update_loading":
      return {
        ...state,
        loading: action.loading,
      };
    case "update_error":
      return {
        ...state,
        error: action.error,
      };
    case "set_message":
      return {
        ...state,
        message: action.message,
      };
    default:
      return state;
  }
};
