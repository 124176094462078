import React from "react";
import styles from "./styles.module.scss";
const ReportDetails = ({ data }) => {
  return (
    <section className="mb-3">
      <h3 className={`${styles.sectionTitle} mb-0`}>Details:</h3>
      <div className="d-flex mx-3">
        <table>
          <tbody>
            <tr>
              <td className={styles.label}>Driver:</td>
              <td className={styles.value}>{data?.data?.driver_name}</td>
            </tr>
            <tr>
              <td className={styles.label}>Company:</td>
              <td className={styles.value}>{data?.data?.carrier}</td>
            </tr>
            <tr>
              <td className={styles.label}>Address:</td>
              <td className={styles.value}>{data?.data?.address}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default ReportDetails;
