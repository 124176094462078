import React from "react";
import { Button } from "react-bootstrap";
import { Eye, EyeOff } from "react-feather";

const ExpandToggle = ({ expandState, setExpandState }) => {
  return (
    <Button variant="link" onClick={() => setExpandState(!expandState)}>
      {!!expandState ? <Eye size="16" color="#A7A7A7" /> : <EyeOff size="16" color="#A7A7A7" />}
    </Button>
  );
};

export default ExpandToggle;
