import {
  complete_qty,
  description,
  difference_reported_to_estimated,
  earned_man_hours,
  estimated_man_hours,
  est_man_hoursunit,
  estimated_qty,
  manhours_overunder_reported_to_earned,
  overunder_reported_to_earned,
  reported_man_hours,
  reported_man_hoursunit,
  reported_qty,
  tracking_id,
  um,
} from "./columnDefs";

const columnDefs = [
  complete_qty,
  description,
  difference_reported_to_estimated,
  earned_man_hours,
  estimated_man_hours,
  est_man_hoursunit,
  manhours_overunder_reported_to_earned,
  overunder_reported_to_earned,
  reported_man_hours,
  reported_man_hoursunit,
  estimated_qty,
  reported_qty,
  tracking_id,
  um,
];

export default columnDefs;
