import React from "react";
import styles from "./ContactsAdmin.module.scss";
import alphabet from "../../utils/alphabet";
import { FilterSearch } from "component-library";
import CategoryFilters from "./CategoryFilters";
import LetterButton from "../LetterButton";

const ContactsFilters = ({
  updateCategory,
  updateLetter,
  letter,
  selectedCategories,
  searchFunction,
  searchLoading,
  searchText = "Find a Company",
}) => {
  return (
    <>
      <div className={`${styles.filters} py-3 d-flex justify-content-between`}>
        <CategoryFilters updateCategory={updateCategory} selectedCategories={selectedCategories} />
      </div>
      <div className="d-flex align-items-center justify-content-center w-100 mb-5">
        <FilterSearch searchFunction={searchFunction} placeholder={searchText} loading={searchLoading} />
      </div>
      <div className="d-flex justify-content-between mt-3 mb-4">
        {alphabet.map((l) => (
          <LetterButton key={l} letter={l} updateLetter={updateLetter} active={letter === l} />
        ))}
      </div>
    </>
  );
};

export default ContactsFilters;
