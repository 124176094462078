import Lunch from "../../sharedColumns/columnRenders/Lunch"

const lunch = {
  headerName: "Lunch",
  field: "lunch",
  width: 100,
  cellRenderer: Lunch,
};

export default lunch;
