import React, { useState, useEffect } from "react";
import { Button, Alert } from "react-bootstrap";
import { FolderPlus, Move } from "react-feather";
import styles from "./styles.module.scss";
import usePhotoLibrary from "../../context/usePhotoLibrary";
import { schema, initialData } from "./albums";
import { useFormControl, photoLibraryApi, useApi } from "component-library";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import PropTypes from "prop-types";
import AlbumItem from "./AlbumItem";
import useProject from "../../../../hooks/useProject";

const useAddtoAlbum = ({
  showModal,
  setShowModal,
  photoDetails,
  setPhotoDetails,
  selectedItems,
  setSelectedItems,
  setSelectMode,
}) => {
  const { photoId } = useParams();
  const { project } = useProject();
  const [albumOptions, setAlbumOptions] = useState([]);
  const [currentAlbum, setCurrentAlbum] = useState(null);
  const { albumsList, setMessage, refreshLists, photosList } = usePhotoLibrary();
  const [createError, setCreateError] = useState();
  const [updateError, setUpdateError] = useState();
  const isMove = photoDetails?.albums?.length > 0;
  const isSelectMode = !photoDetails && selectedItems?.length > 0;
  const selectedPhotoIds = selectedItems?.filter((item) => item.type === "photo").map((item) => item.id) || [];

  const {
    request: updateDetailRequest,
    data: updateDetailData,
    loading: updateDetailLoading,
    error: updateDetailError,
  } = useApi(photoLibraryApi.updatePhotoDetails);

  const {
    request: createAlbumRequest,
    data: createAlbumData,
    loading: createAlbumLoading,
    error: createAlbumError,
  } = useApi(photoLibraryApi.createAlbum);

  const {
    request: updateAlbumDetailRequest,
    data: updateAlbumDetailData,
    loading: updateAlbumDetailLoading,
    error: updateAlbumDetailError,
  } = useApi(photoLibraryApi.updateAlbumDetails);

  const onSubmit = (formData) => {
    if (isSelectMode) {
      if (formData.new_album_name) {
        let requestParams = {
          name: formData.new_album_name,
          project_id: project.id,
          photo_ids: selectedPhotoIds,
        };
        createAlbumRequest(requestParams);
      } else {
        const selectedAlbumId = formData.albums[0];
        const inAlbumPhotoIds = photosList
          .filter((photo) => photo.albums.map((album) => album.id).includes(selectedAlbumId))
          .map((photo) => photo.id);
        const newPhotoIds = [...inAlbumPhotoIds, ...selectedPhotoIds];
        const filtered = albumsList.filter((album) => album.id === selectedAlbumId);
        const selectedAlbumName = filtered.length ? filtered[0].name : "";

        updateAlbumDetailRequest({
          name: selectedAlbumName,
          album_id: selectedAlbumId,
          photo_ids: newPhotoIds,
        });
      }
    } else {
      if (!!formData.new_album_name) {
        let requestParams = {
          name: formData.new_album_name,
          project_id: project.id,
          photo_ids: photoId ? [photoId] : [],
        };
        createAlbumRequest(requestParams);
      } else {
        const selectedAlbumId = formData.albums[0];
        updateDetailRequest({ photo_id: photoId, album_ids: [selectedAlbumId] });
      }
    }
  };

  const {
    handleSubmit,
    renderTextField,
    data: formData,
    setFields,
  } = useFormControl({
    schema: schema,
    onSubmit,
    initialData,
    scrollToTop: true,
  });

  const onHide = () => {
    if (isMove && currentAlbum) {
      setFields({ albums: [currentAlbum.value], new_album_name: "" });
    }
    setShowModal(false);
  };

  useEffect(() => {
    if (!!formData.new_album_name && !!formData.albums[0]) {
      setFields({ albums: [], new_album_name: formData.new_album_name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (isSelectMode) {
      setShowModal(false);
      setSelectedItems([]);
      setSelectMode(false);
      setMessage(
        selectedPhotoIds.length > 1 ? "Photos added to album successfully." : "Photo added to album successfully."
      );
      refreshLists();
    } else {
      if (createAlbumData?.success) {
        updateDetailRequest({ photo_id: photoId, album_ids: [createAlbumData.album.id] });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAlbumData]);

  const headerContent = (
    <>
      <div className="d-flex align-items-center">
        {isMove ? <Move color="#0071ce" size={16} /> : <FolderPlus color="#0071ce" size={16} />}
        <h2 className={styles.modalTitle}>{isMove ? "Move to Album" : "Add to Album"}</h2>
      </div>
    </>
  );

  const bodyContent = (
    <div className="position-relative">
      {updateError && <Alert variant="danger">{updateError}</Alert>}
      {(!!updateDetailLoading || updateAlbumDetailLoading) && (
        <div className="loaderWrapper">
          <BeatLoader color={"#0071ce"} />
        </div>
      )}

      <form onSubmit={handleSubmit} id="add_to_album_form">
        {isMove && currentAlbum && (
          <div className="mb-5">
            <h2 className={styles.albumItemText}>Current Album</h2>
            <AlbumItem album={currentAlbum} setFields={setFields} formData={formData} />
          </div>
        )}
        <div>
          {isMove && <h2 className={styles.albumItemText}>Other Albums</h2>}
          <div role="radiogroup" className={styles.scrollable}>
            {albumOptions
              .filter((album) => currentAlbum?.value !== album.value)
              .map((album) => (
                <AlbumItem key={album.value} album={album} setFields={setFields} formData={formData} />
              ))}
          </div>
        </div>
        {!isMove && (
          <>
            <div className="d-flex align-items-center mt-5">
              <FolderPlus color="#0071ce" size={16} />
              <h2 className={styles.modalTitle}>Create New Album</h2>
            </div>
            <div className="position-relative">
              {createError && <Alert variant="danger">{createError}</Alert>}
              {!!createAlbumLoading && (
                <div className="loaderWrapper">
                  <BeatLoader color={"#0071ce"} />
                </div>
              )}
              <div className="form-group">
                <div className="row">
                  {renderTextField({
                    name: "new_album_name",
                    label: "Enter your album title here.",
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </form>
    </div>
  );

  const footerContent = (
    <div className="d-flex flex-row align-items-center">
      <Button
        onClick={() => {
          onHide();
        }}
        variant="primary"
        className="med me-3 d-flex align-items-center"
      >
        Cancel
      </Button>
      <Button
        variant="orange"
        form="add_to_album_form"
        type="submit"
        className="med d-flex align-items-center"
        aria-label={isMove ? "Move to Album" : "Add to Album"}
        disabled={
          isMove ? formData?.albums[0] === currentAlbum?.value : !formData?.albums[0] && !formData.new_album_name
        }
      >
        {isMove ? "Move to Album" : "Add to Album"}
      </Button>
    </div>
  );

  useEffect(() => {
    if (!!albumsList?.length) {
      setAlbumOptions(
        albumsList.map((album) => {
          return { label: album.name, value: album.id };
        })
      );
    }
  }, [albumsList]);

  useEffect(() => {
    if (isMove) {
      setCurrentAlbum({ label: photoDetails?.albums[0].name, value: photoDetails?.albums[0].id });
    }
    setFields({
      ...formData,
      albums: !!photoDetails?.albums?.length
        ? photoDetails?.albums.map((album) => {
            return album.id;
          })
        : [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoDetails]);

  useEffect(() => {
    if (updateDetailData) {
      setPhotoDetails({
        ...photoDetails,
        albums: updateDetailData.photo.albums,
      });
      setShowModal(false);
      setMessage("Photo added to album successfully.");
      refreshLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDetailData]);

  useEffect(() => {
    if (updateAlbumDetailData) {
      setShowModal(false);
      setSelectedItems([]);
      setSelectMode(false);
      setMessage(
        selectedPhotoIds.length > 1 ? "Photos added to album successfully." : "Photo added to album successfully."
      );
      refreshLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAlbumDetailData]);

  useEffect(() => {
    if (updateDetailError || updateAlbumDetailError) {
      setUpdateError(updateDetailError || updateAlbumDetailError);
    } else {
      setUpdateError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDetailError, updateAlbumDetailError]);

  useEffect(() => {
    if (createAlbumError) {
      setCreateError(createAlbumError);
    } else {
      setCreateError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAlbumError]);

  useEffect(() => {
    if (!showModal) {
      setUpdateError();
      setCreateError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return {
    headerContent,
    bodyContent,
    footerContent,
    onHide,
  };
};

useAddtoAlbum.propTypes = {
  photoDetails: PropTypes.object,
  setShowModal: PropTypes.func,
  setPhotoDetails: PropTypes.func,
  selectedItems: PropTypes.array,
};

export default useAddtoAlbum;
