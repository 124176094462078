import React from "react";
import PropTypes from "prop-types";
import { InlineUpload } from "component-library";
import useProjectAttachment from "./useProjectAttachment";
import { Button } from "react-bootstrap";
import { XCircle } from "react-feather";
import styles from "./styles.module.scss";
import { BeatLoader } from "react-spinners";
import useProject from "../hooks/useProject";

const ProjectAttachmentInline = ({
  attachment,
  setAttachment,
  type,
  projectId,
  isSingle,
  setDocLoading,
  setFileId,
  todoId,
}) => {
  const { refreshProject } = useProject();
  const { config, loading, percentComplete, removeAttachment, addAttachment, removeFileLoading, fileError } =
    useProjectAttachment({
      projectId,
      todoId,
      setDocLoading,
      type,
      setAttachment,
      isSingle,
      setFileId,
      refreshAttachments: refreshProject,
    });
  return (
    <div className={`${styles.file} position-relative`}>
      {!!fileError && <p className="error mb-n2">Error. Please try again.</p>}
      {!!removeFileLoading && (
        <div className="loaderWrapper">
          <BeatLoader size={10} color={"#0071ce"} />
        </div>
      )}
      {!!attachment ? (
        <div className={`${styles.pillBadge} py-2`}>
          {!!removeAttachment && (
            <Button
              size="sm"
              variant="link"
              className="d-flex align-content-center justify-content-center p-0"
              onClick={() => removeAttachment(attachment)}
            >
              <XCircle size="13" className="attachment-icon attachment-download mx-1" />
            </Button>
          )}
          <h6 className="text-uppercase sm mb-0">
            <a
              href={attachment.abs_url}
              target="_blank"
              rel="noreferrer"
              style={{ maxWidth: "20rem" }}
              className={`${styles.simplifiedTitle} d-block text-start text-break thirteen`}
            >
              {attachment.file_name}
            </a>
          </h6>
        </div>
      ) : (
        <div className={styles.uploadWrapper}>
          <InlineUpload
            uploadText=""
            fileType={[config.file_types.find((t) => t.name === type).id]}
            addAttachment={addAttachment}
            removeAttachment={removeAttachment}
            loading={loading}
            percentComplete={percentComplete}
            hasBorder={true}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectAttachmentInline;

ProjectAttachmentInline.propTypes = {
  attachment: PropTypes.any,
  setAttachment: PropTypes.func.isRequired,
  type: PropTypes.string,
  projectId: PropTypes.number.isRequired,
};
