import React from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import styles from "./styles.module.scss";
import { X } from "react-feather";
import { FilterSearch, displayDate } from "component-library";
import { DateTime } from "luxon";
import Note from "../../../../../components/FieldNoteAccordion/Note";
import { BeatLoader } from "react-spinners";
import CustomScroll from "react-custom-scroll";
import useSelectFieldNotesModal from "./useSelectFieldNotesModal";
import ToggleAllAccordion from "../../../../../components/ToggleAllAccordion";

const SelectFieldNotesModal = ({
  report,
  notes,
  updatingFieldNotes,
  onHide,
  handleSaveModalSelections,
}) => {
  const {
    ContextAwareToggle,
    handleNoteSearch,
    sortedNotes,
    projectNotesError,
    projectNotesLoading,
    updatedSelectedNotes,
    activeItems,
    setActiveItems,
  } = useSelectFieldNotesModal({ notes, report });

  const Header = ({ item }) => (
    <ContextAwareToggle noteCount={item?.notes?.length || 0} eventKey={item.note_date}>
      <div className="d-flex w-100 flex-1 med text-gray2">
        {!!item?.note_date ? (
          <>
            {displayDate(item.note_date)}
            <span className="ms-5 font-weight-bold">{DateTime.fromISO(item.note_date).toFormat("EEEE")}</span>
          </>
        ) : (
          "Unknown"
        )}
      </div>
    </ContextAwareToggle>
  );

  const Body = ({ item }) => (
    <div className="mt-3">
      {item?.notes?.map((note) => (
        <Note
          note={note}
        />
      ))}
    </div>
  );

  return (
    <Modal
      show={!!updatingFieldNotes}
      centered
      onHide={() => {
        onHide();
      }}
      animation={false}
      className="text-gray2 p-0 fullWidthContent"
      backdropClassName={styles.backdrop}
      dialogClassName={styles.dialogWrap}
      contentClassName={styles.modalContent}
    >
      <Modal.Header className={`${styles.header}`}>
        <h1 className={`${styles.titleText} med mb-0`}>Attach Field Note(s)</h1>
        <Button
          variant="link"
          onClick={() => {
            onHide();
          }}
          className="p-0"
        >
          <X size={25} color="#D3D3D3" />
        </Button>
      </Modal.Header>
      <Modal.Body className={`${styles.bodyWrapper} flex-1 position-relative mx-4`}>
        {!!projectNotesError && <Alert variant="danger">{!!projectNotesError && projectNotesError}</Alert>}{" "}
        {!!projectNotesLoading && (
          <div className="loaderWrapper">
            <BeatLoader color="#0017CE" />
          </div>
        )}
        <div className="d-flex align-items-center justify-content-between w-100 mb-3">
          <div className="d-flex align-items-center">
            {displayDate(report?.start_date)} - {displayDate(report?.end_date)}
          </div>
          <FilterSearch searchFunction={handleNoteSearch} placeholder="Search Notes" />
        </div>
        <div className={styles.modalBody}>
          {!projectNotesLoading && !sortedNotes?.length && <div className="mt-5">No notes found</div>}
          <CustomScroll heightRelativeToParent="100%">
            <ToggleAllAccordion
              items={sortedNotes}
              activeItems={activeItems}
              setActiveItems={setActiveItems}
              Header={Header}
              Body={Body}
              itemStyles="mb-4"
              idField="note_date"
            />
          </CustomScroll>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <Button
          className="light-text mx-1 btn btn-lg med font-weight-bold"
          variant="light-gray"
          type="submit"
          onClick={() => onHide()}
        >
          Cancel
        </Button>
        <Button
          className="light-text mx-1 btn btn-lg med font-weight-bold"
          variant="tertiary"
          type="submit"
          onClick={() => handleSaveModalSelections(updatedSelectedNotes)}
        >
          Update Notes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default SelectFieldNotesModal;
