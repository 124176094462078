import React from "react";
import styles from "./styles.module.scss";
import { displayDate } from "component-library";
import DateFields from "../DateFields";
import Weather from "./DateRowWeather/DateRowWeather";

const DateRow = ({ project, day, updateDate, report, weather }) => {
  const updateDateField = (day, field, value) => {
    updateDate(day, field, value);
  };
  const todaysWeather = weather?.find((w) => w.date === day?.work_day?.date);

  const date = !!day?.work_day?.date ? new Date(day?.work_day?.date?.replace(/-/g, "/")) : null;
  const dayOfWeek = !!date ? date.toLocaleString("default", { weekday: "long" }) : null;

  return (
    <div className="mb-5">
      <div className={`${styles.dayWrapper} ${day?.working_day_flag ? styles.active : ""}`}>
        <div className="d-flex align-items-center flex-1">
          <div className={`${styles.toggle} form-group form-check toggle`}>
            <input
              type="checkbox"
              className="form-check-input"
              name={`${day.id}-working-day`}
              id={`${day.id}-working-day`}
              onChange={() => updateDate(day, "working_day_flag", day.working_day_flag === 1 ? 0 : 1)}
              value={day.working_day_flag}
              checked={day.working_day_flag}
            />
            <label className={`${styles.toggleLabel} form-check-label`} htmlFor={`${day.id}-working-day`} />
          </div>
          <div className={styles.dayText}>{dayOfWeek}</div>
          <div className={styles.dateText}>{!!date && displayDate(date)}</div>
          <div className={`${styles.workDayValue} d-flex align-items-center`}>
            <input
              type="number"
              className={`form-control ${styles.wrappedInput}`}
              name={`${day.date}-working-day-value`}
              id={`${day.date}-working-day-value`}
              onChange={(e) => updateDate(day, "working_day_value", e.target.value)}
              value={parseFloat(day.working_day_value)}
              max="1"
              min="0"
              step="0.5"
              aria-label="working day value"
              disabled={!day.working_day_flag}
            />
            <label>{!!day?.working_day_flag ? "Working Day" : "Non Working Day"}</label>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Weather conditions={todaysWeather?.weather} />
          {/* <FieldSelectPopover day={day} updateDateField={updateDateField} /> */}
        </div>
      </div>
      {!!day?.working_day_flag && (
        <DateFields
          project={project}
          day={day}
          fields={day.working_day_fields}
          updateDateField={updateDateField}
          report={report}
          todaysWeather={todaysWeather}
        />
      )}
    </div>
  );
};

export default DateRow;
