import { useState } from "react";
import Submenu from "../../Submenu";
import { Users } from "react-feather";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import useWeeklyReportsList from "./useWeeklyReportsList";
import { DateRangeSelector, AgGridTable, BoomSelector, useAuth } from "component-library";
import styles from "./styles.module.scss";
import weeklyReportsColumnConfig from "./weeklyReportsColumnConfig";
import gridRowHeights from "../../../utils/gridRowHeights";
import { getMaxDate } from "../../../utils/getMaxDate";
import useResourceManagement from "../../context/useResourceManagement";
import PMSelector from "../../FieldLogs/ControlBar/PMSelector";
import { useNavigate } from "react-router-dom";

const WeeklyReportsList = () => {
  const history = useNavigate();
  const { userCan, user } = useAuth();

  const { projectsList, projectsLoading } = useResourceManagement();
  const [projectId, setProjectId] = useState(0);
  const [projectManager, setProjectManager] = useState();
  const [pmOptions, setPmOptions] = useState([]);
  const { gridData, error, loading, dateRange, setDateRange } = useWeeklyReportsList({
    projectId,
    projectManager,
  });

  const handleCellClick = ({ data }) => {
    history(
      `/field-management/weekly-reports/${data?.id}/${
        ["sent"].includes(data?.status) || !userCan("weekly-report-edit") ? "review" : "edit"
      }`
    );
  };

  return (
    <InteriorPage
      error={error}
      loading={loading}
      pageTitle="Weekly Reports"
      navTitle="Field Management"
      navIcon={<Users color="#fff" size={14} />}
      pageSubtitlePrimaryContent={() => <Submenu />}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className={styles.datePickerWrapper}>
          <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} maxDate={getMaxDate(dateRange, 14)} />
        </div>
        <div className="d-flex align-items-center">
          <BoomSelector
            selected={projectId}
            setSelected={setProjectId}
            options={projectsList}
            loading={projectsLoading}
            placeholder="Filter by Project"
          />
          <PMSelector
            selected={projectManager}
            setSelected={setProjectManager}
            pmOptions={pmOptions}
            setPmOptions={setPmOptions}
            loading={loading}
          />
        </div>
      </div>
      <AgGridTable
        gridData={gridData}
        gridConfig={weeklyReportsColumnConfig}
        loading={loading}
        rowHeight={gridRowHeights.medium}
        id={`${user.id}_weekly_reports_listing`}
        presetStyle="sortableTable"
        handleCellClick={(data) => handleCellClick(data)}
        fallbackMessage="No reports found."
      />
    </InteriorPage>
  );
};

export default WeeklyReportsList;
