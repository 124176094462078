import React from "react";
import { Button } from "react-bootstrap";
import PhaseToggle from "./Phases/PhaseToggle";
import { Edit3, Save, RotateCw, RotateCcw } from "react-feather";
import styles from "./styles.module.scss";
import useEstimateConversion from "../useEstimateConversion";

const TaskActions = () => {
  const {
    viewPhases,
    editTasks,
    setEditTasks,
    setResetPhasesConfirmation,
    setViewPhases,
    importable,
    refreshEstimate,
    refreshingEstimate,
    canEdit,
    deactivatedTasks,
    setTasksDeactivatedConfirm,
    submitEdit,
    bidItems,
  } = useEstimateConversion();

  return (
    <div className="task-actions align-items-center d-flex justify-content-end">
      {!refreshingEstimate && !!importable && !editTasks && !viewPhases && (
        <Button
          variant="link"
          onClick={() => refreshEstimate()}
          className={`d-flex justify-content-center align-items-center med text-gray2`}
          disabled={!canEdit}
        >
          <RotateCcw className="me-1" size={14} color={!canEdit ? "#6c757d" : "#0071ce"} />
          Refresh Estimate
        </Button>
      )}
      {!!importable && !editTasks && !!viewPhases && (
        <Button
          variant="link"
          onClick={() => setResetPhasesConfirmation(true)}
          className={`d-flex justify-content-center align-items-center med ${styles.resetPhase}`}
          disabled={!canEdit}
        >
          <RotateCw className="me-1" size={14} color={"#0071ce"} />
          Reset Phases
        </Button>
      )}
      {!editTasks && <PhaseToggle />}
      {!!importable && (
        <Button
          onClick={() => {
            if (editTasks) {
              if (!!deactivatedTasks?.length) {
                setTasksDeactivatedConfirm(true);
              } else {
                submitEdit(bidItems);
                setEditTasks(!editTasks);
              }
            } else {
              setEditTasks(!editTasks);
              setViewPhases(false);
            }
          }}
          variant="link"
          className="text-gray2 d-flex justify-content-center align-items-center med pe-0"
          disabled={!canEdit}
        >
          {!!editTasks ? (
            <>
              <Save color={!canEdit ? "#6c757d" : "#0071ce"} className="me-1" size="16" />
              {"Save Changes"}
            </>
          ) : (
            <>
              <Edit3 color={!canEdit ? "#6c757d" : "#0071ce"} className="me-1" size="16" />
              {"Edit Tasks"}
            </>
          )}
        </Button>
      )}
    </div>
  );
};

export default TaskActions;
