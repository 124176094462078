import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import TaskHeader from "./TaskHeader/TaskHeader";
import TaskBody from "./TaskBody";
import PropTypes from "prop-types";
import TopBar from "./TopBar";
import { useDrop } from "react-dnd";
import useAddTimeEntry from "./TimeEntryPopover/useAddTimeEntry";
import { BeatLoader } from "react-spinners";

const Task = ({
  task,
  config,
  editing,
  setFieldLog,
  fieldLog,
  crew,
  foremanId,
  overAllocatedTimeSheets,
  setOverAllocatedTimeSheets,
  projectId,
  prevailingWage,
  crewLoading,
  openTasks,
  toggleTasksOpen,
  setOpenTasks,
  invalidRequiredQuantities,
  setInvalidRequiredQuantities,
  tasks,
  errorMessageList,
  setErrorMessageList,
  verifyQuantity,
  requiredValueValidate,
}) => {
  const { addTimeEntry, loading } = useAddTimeEntry({
    foremanId,
    fieldLog,
    setFieldLog,
    task,
    laborTypes: config?.labor_types,
  });
  const [entries, setEntries] = useState();
  const [disableToggle, setDisableToggle] = useState(false);
  const [active, setActive] = useState(false);

  const [{ isOver }, dropRef] = useDrop({
    accept: "unallocated hours",
    drop: (item) => {
      addTimeEntry(item.timesheet, item.timesheet.user, item.unassignedHours);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  useEffect(() => {
    if (!!openTasks?.length && openTasks?.includes(task?.id)) {
      setActive(true);
    } else {
      setActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTasks]);

  useEffect(() => {
    if (task) {
      const timeEntries = fieldLog.time_sheets.reduce((acc, curr) => [...acc, ...curr.time_entries], []);
      setEntries(timeEntries.filter((te) => task.time_entry_ids.includes(te.id)));
    }
  }, [fieldLog, task]);

  return (
    <div className={`position-relative ${styles.task}`} ref={dropRef}>
      {!!loading && (
        <div className="loaderWrapper">
          <BeatLoader color="#0071ce" />
        </div>
      )}
      {!!isOver && <div className={styles.overlay} />}
      <TaskHeader
        task={task}
        disableToggle={disableToggle}
        taskOpen={active}
        setFieldLog={setFieldLog}
        fieldLog={fieldLog}
        foremanId={foremanId}
        editing={editing}
        projectId={projectId}
        entries={entries}
        toggleTasksOpen={toggleTasksOpen}
        setOpenTasks={setOpenTasks}
        openTasks={openTasks}
      />
      <TopBar
        task={task}
        editing={editing}
        setFieldLog={setFieldLog}
        fieldLog={fieldLog}
        foremanId={foremanId}
        setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
        setDisableToggle={setDisableToggle}
        invalidRequiredQuantities={invalidRequiredQuantities}
        setInvalidRequiredQuantities={setInvalidRequiredQuantities}
        tasks={tasks}
        errorMessageList={errorMessageList}
        setErrorMessageList={setErrorMessageList}
        verifyQuantity={verifyQuantity}
        requiredValueValidate={requiredValueValidate}
      />
      {!!active && (
        <TaskBody
          task={task}
          config={config}
          editing={editing}
          setFieldLog={setFieldLog}
          fieldLog={fieldLog}
          crew={crew}
          crewLoading={crewLoading}
          foremanId={foremanId}
          overAllocatedTimeSheets={overAllocatedTimeSheets}
          setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
          prevailingWage={prevailingWage}
          entries={entries}
          setDisableToggle={setDisableToggle}
          projectId={projectId}
        />
      )}
    </div>
  );
};

Task.propTypes = {
  task: PropTypes.object,
  config: PropTypes.object,
  editing: PropTypes.bool,
  setFieldLog: PropTypes.func,
  fieldLog: PropTypes.object,
  crew: PropTypes.array,
  foremanId: PropTypes.string,
  overAllocatedTimeSheets: PropTypes.array,
  setOverAllocatedTimeSheets: PropTypes.func,
  prevailingWage: PropTypes.any,
};

export default Task;
