import React from "react";
import styles from "./styles.module.scss";
import LibraryGrid from "../../../../../../../_projects/project/PhotoLibrary/LibraryGrid";
import usePhotosView from "../../../../../../../_projects/project/hooks/usePhotosView";
import { DateTime } from "luxon";
import { displayDate } from "component-library";

const PhotoGridOptions = ({ project, selectedPhotoIds, photosList, updateSelectedPhotos, dateRange }) => {
  const { pinnedPhotos, todaysPhotos, recentPhotos, previousPhotos, dateRangePhotos } = usePhotosView({
    photosList,
    dateRange,
  });
  return (
    <div className="position-relative">
      {!!pinnedPhotos?.length && (
        <section>
          <h2 className={styles.sectionTitle}>Pinned</h2>
          <LibraryGrid
            project={project}
            gridSize="large"
            gridItems={pinnedPhotos}
            updateSelectedPhotos={updateSelectedPhotos}
            selectedPhotoIds={selectedPhotoIds}
          />
        </section>
      )}
      {!dateRange[0] && (
        <>
          <section>
            <h2 className={styles.sectionTitle}>All Photos - Today</h2>
            <LibraryGrid
              project={project}
              gridSize="large"
              gridItems={todaysPhotos}
              updateSelectedPhotos={updateSelectedPhotos}
              selectedPhotoIds={selectedPhotoIds}
            />
          </section>
          <section>
            <h2 className={styles.sectionTitle}>Last 7 Days</h2>
            <LibraryGrid
              project={project}
              gridSize="medium"
              gridItems={recentPhotos}
              updateSelectedPhotos={updateSelectedPhotos}
              selectedPhotoIds={selectedPhotoIds}
            />
          </section>
          <section>
            <h2 className={styles.sectionTitle}>Previous Photos</h2>
            <LibraryGrid
              project={project}
              gridSize="small"
              gridItems={previousPhotos}
              updateSelectedPhotos={updateSelectedPhotos}
              selectedPhotoIds={selectedPhotoIds}
            />
          </section>
        </>
      )}
      {!!dateRange[0] && !!dateRange[1] && (
        <section>
          <h2 className={styles.sectionTitle}>
            {displayDate(dateRange[0])} {`${!!dateRange[1] ? ` - ${displayDate(dateRange[1])} ` : ""}`}
          </h2>
          <LibraryGrid
            project={project}
            gridSize="small"
            gridItems={dateRangePhotos}
            updateSelectedPhotos={updateSelectedPhotos}
            selectedPhotoIds={selectedPhotoIds}
          />
        </section>
      )}
    </div>
  );
};

export default PhotoGridOptions;
