import React from "react";
import { Tag } from "react-feather";
import { RiseLoader } from "react-spinners";
import useQuote from "../../_projects/project/Quotes/Quote/useQuote";
import InteriorPage from "../../components/pageLayout/InteriorPage";
import QuoteDetail from "../../_projects/project/Quotes/Quote/QuoteDetail";
import { useParams } from "react-router-dom";

const Quote = () => {
  const { id } = useParams();
  const {
    error,
    quote,
    setQuote,
    getLoading,
    loading,
    setLoading,
    requote,
  } = useQuote();

  return (
    <InteriorPage
      loading={getLoading}
      error={error}
      navIcon={<Tag color="#fff" size={14} />}
      pageTitle={`Quote ${!!quote ? quote.id : ""}`}
      titleTag="Bids — Quotes"
    >
      <div className="position-relative">
        {loading && (
          <div className="loaderWrapper">
            <RiseLoader color={"#0071ce"} />
          </div>
        )}
        {!!quote && (
          <QuoteDetail
            quote={quote}
            setQuote={setQuote}
            setLoading={setLoading}
            requote={requote}
            backLink={`/bid/${id}/rfqs`}
            noControls
          />
        )}
      </div>
    </InteriorPage>
  );
};

export default Quote;
