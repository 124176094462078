import {
  company_name,
  address,
  company_categories,
  phone,
} from "./columnDefs";

const columnDefs = [
  company_name,
  address,
  company_categories,
  phone,
];

export default columnDefs;