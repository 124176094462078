import React from "react";
import useProvideRFQs from "./useProvideRFQs";
import context from "./context";

function ProvideRFQs({ children }) {
  const rfqs = useProvideRFQs();
  return (
    <context.Provider value={rfqs}>
      {children}
    </context.Provider>
  );
}

export default ProvideRFQs;
