import React from "react";
import { Routes, Route } from "react-router";
import PrivateRoute from "../../../components/PrivateRoute";
import PhotoLibrary from "./PhotoLibrary";
import AlbumDetail from "./AlbumDetail";
import PhotoDetail from "./PhotoDetail";
import PhotosByDate from "./PhotosByDate";
import ProvidePhotoLibrary from "./context/ProvidePhotoLibrary";

const PhotoLibraryRoutes = () => {
  return (
    <ProvidePhotoLibrary>
      <Routes>
        <Route index element={<PrivateRoute module="ProjectManagement" Component={<PhotoLibrary />} />} />
        <Route
          path="photo-library"
          element={<PrivateRoute module="ProjectManagement" Component={<PhotoLibrary />} />}
        />
        <Route
          path="album/:albumId"
          element={<PrivateRoute module="ProjectManagement" Component={<AlbumDetail />} />}
        />
        <Route
          path="photo/:photoId"
          element={<PrivateRoute module="ProjectManagement" Component={<PhotoDetail />} />}
        />
        <Route
          path="photos/:photoDate"
          element={<PrivateRoute module="ProjectManagement" Component={<PhotosByDate />} />}
        />
      </Routes>
    </ProvidePhotoLibrary>
  );
};

export default PhotoLibraryRoutes;
