import React, { useEffect } from 'react';
import InteriorPage from "../../../../../components/pageLayout/InteriorPage";
import { Briefcase } from "react-feather";
import useProject from "../../../../hooks/useProject";
import useQuoteAddLineItems from "./useQuoteAddLineItems";
import LineItems from "../LineItems";
import Controls from './Controls';
import Takeoffs from "./Takeoffs";
import { Navigate } from "react-router-dom";

const QuoteAddLineItems = () => {
  const { pageTitle, PageTag } = useProject();
  const {
    error,
    quote,
    loading,
    takeoffs,
    getTakeoffs,
    updateLineItem,
    addLineItems,
    newLineItems,
    updateNewLineItems,
    setQuote,
    redirect,
  } = useQuoteAddLineItems();

  const quoteId = !!quote && quote.id;

  useEffect(() => {
    if (!!quoteId) {
      getTakeoffs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId]);

  return (
    <InteriorPage
      navTitle={pageTitle}
      pageTitle="Add Line Items"
      navIcon={<Briefcase color="#fff" size={14} />}
      error={error}
      loading={loading}
      NavContent={PageTag}
    >
      {!!redirect && <Navigate to={redirect} />}
      {!!quote && !!quote.po_submitted ? (
        <>A purchase order has already been created for this quote and edits are not allowed</>
      ) : (
        <div className="position-relative">
          {!!quote && !!takeoffs.length && (
            <>
              <div className="requote">
                <div className="d-flex justify-content-end">
                  <Controls newLineItems={newLineItems} addLineItems={addLineItems} quote={quote} />
                </div>
                {!!quote && quote.status !== 2 ? (
                  <>
                    {!!quote.line_items && !!quote.line_items.length && (
                      <LineItems updateLineItem={updateLineItem} quote={quote} hasFooter={false} setQuote={setQuote} />
                    )}
                  </>
                ) : (
                  <>
                    {!!quote.requote.line_items && !!quote.requote.line_items.length && (
                      <LineItems updateLineItem={updateLineItem} quote={quote} hasFooter={false} setQuote={setQuote} />
                    )}
                  </>
                )}
                <Takeoffs takeoffs={takeoffs} updateNewLineItems={updateNewLineItems} />
              </div>
            </>
          )}
        </div>
      )}
    </InteriorPage>
  );
};

export default QuoteAddLineItems;