import React from "react";
import { schema } from "../../schema/srf";
import { useFormControl, Loader, useApi, bidApi } from "component-library";
import { Alert } from "react-bootstrap";
import useUpdateTodos from "./useUpdateTodos";
import TodoAttachment from "../TodoAttachment";

const useSRFRequirement = (bid, task, setTodos, setBid, setDocLoading) => {
  const { request, error, data: updateData, loading } = useApi(bidApi.completeToDo);
  const { dataError, setDataError } = useUpdateTodos(updateData, setTodos, setBid);

  const onSubmit = async (formData, setLoading) => {
    setDataError(false);
    try {
      await request({
        project_id: task.project_id,
        todo_id: task.bid_todo.id,
        complete: 1,
        ...formData,
      });
      setLoading(false);
    } catch (message) {
      setLoading(false);
    }
  };

  const initialData = {
    srf_number: task.srf_number,
  };

  const { handleSubmit, renderSubmitButton, renderTextField, setFields } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const fields = (
    <div className="row">
      <Loader loading={!!loading} />

      <div className="col-12">Add Attachment</div>
      <div className="col-3">
        <TodoAttachment setBid={setBid} bid={bid} task={task} setDocLoading={setDocLoading} />
      </div>
      <div className="col-12">
        {renderTextField({
          name: "srf_number",
          label: "SRF Number",
        })}
      </div>
      {!!error && (
        <div className="col-12">
          <Alert variant="danger">{error}</Alert>
        </div>
      )}
      {!!dataError && (
        <div className="col-12">
          <Alert variant="danger">Something went wrong. Please try again.</Alert>
        </div>
      )}
    </div>
  );
  return {
    schema,
    initialData,
    fields,
    handleSubmit,
    renderSubmitButton,
    setFields,
  };
};

export default useSRFRequirement;
