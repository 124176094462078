import React from "react";
import styles from "../styles.module.scss";
import { Button } from "react-bootstrap";
import { X } from "react-feather";

const Photo = ({ photo, removePhoto }) => {
  return (
    <div className={styles.photo}>
      <Button
        onClick={() => removePhoto(photo)}
        className={styles.close}
      >
        <X size="18" />
      </Button>
      <div className={styles.imageWrapper}>
        <img
          src={photo.file.abs_url}
          className={styles.thumbnail}
          alt="..."
        />
      </div>
    </div>
  );
};

export default Photo;