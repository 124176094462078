import React from "react";
import styles from "./styles.module.scss";
import useFieldLogSelection from "../../../_resourceManagement/context/useFieldlogSelection";

const LogToggler = ({ data }) => {
  const { toggleLogSelection, selected } = useFieldLogSelection();

  const checked = !!selected && !!selected.includes(data?.id) ? 1 : 0;

  const handleChange = () => {
    toggleLogSelection(data.id);
  };

  return (
    <>
      {(data.work_day_status_id === 5 ||
        data.work_day_status_id === 1 ||
        data.work_day_status_id === 2) && (
        <div className={styles.checkWrapper}>
          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              name={data.id}
              id={data.id}
              onChange={handleChange}
              value={checked}
              checked={checked}
              aria-label="select row"
            />
            <label className="form-check-label" htmlFor={data.id}></label>
          </div>
        </div>
      )}
    </>
  );
};

export default LogToggler;
