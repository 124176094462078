import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import useAddLocation from './useAddLocation';

const AddLocation = ({ company, setCompany, location, setAddingLocation, setLocation, selectedOption, isAdmin = false }) => {
  const { handleSubmit, fields, renderSubmitButton } = useAddLocation({
    company,
    setCompany,
    setLocation,
    initialLocation: location,
    setAddingLocation,
    isAdmin,
    selectedOption,
  });

  return (
    <Modal dialogClassName="modal-lg" centered animation={false} show={true} onHide={() => setAddingLocation(false)}>
      <Modal.Header closeButton>
        <h5 className="mb-0 med">{!!location ? `Edit ${location && location.name}` : "Add Location"}</h5>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>{fields}</Modal.Body>
        <Modal.Footer>
          <Button className="mt-2" onClick={() => setAddingLocation(false)}>
            Cancel
          </Button>
          {renderSubmitButton(!!location ? "Save Edits" : "Add Location")}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

AddLocation.propTypes = {
  location: PropTypes.object,
  setAddingLocation: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
};

export default AddLocation;
