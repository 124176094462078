import React from "react";
import styles from "./styles.module.scss";
import useNotesAdmin from "../../context/useNotesAdmin";

const NoteToggler = ({ data }) => {
  const { selectedNotesToPrintIds, toggleNotePrintSelection } = useNotesAdmin();

  const checked = selectedNotesToPrintIds?.includes(data.id) ? 1 : 0;

  const handleChange = () => {
    toggleNotePrintSelection(data.id);
  };

  return (
    <div className={`${styles.toggleInput} form-group form-check`}>
      <input
        type="checkbox"
        className="form-check-input"
        name={data.id}
        id={data.id}
        onChange={handleChange}
        value={checked}
        checked={checked}
        aria-label="select row"
      />
      <label className="form-check-label" htmlFor={data.id}></label>
    </div>
  );
};

export default NoteToggler;
