import React from "react";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";
import { X } from "react-feather";

const AttachedPhotos = ({ photos, setOpen, deletePhotos }) => {
  return (
  <div className="justify-content-between w-100 d-flex bg-gray5 rounded">
    <Button
      variant="link"
      onClick={() => setOpen(true)}
      className="med"
    >
      <div className={styles.photosSelected}>
        {photos.length} Photos Selected
      </div>
    </Button>
    <Button
      onClick={() => {
        deletePhotos();
      }}
      variant="link"
    >
      <X size={16} name="x" color="#808080" />
    </Button>
  </div>
  );
};

export default AttachedPhotos;
