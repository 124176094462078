import React from "react";
import { Badge } from "react-bootstrap";
import styles from "./styles.module.scss";

const PWPillGrid = ({ data }) => {
  return (
    <>
      {data?.certified_payroll === 1 && (
        <Badge pill className={`${styles.thPill} nine pill text-light semibold`} bg={"redOrange"}>
          pw
        </Badge>
      )}
    </>
  );
};

export default PWPillGrid;
