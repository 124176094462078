import React from "react";
import styles from "./styles.module.scss";

const Project = ({ data }) => {
  return (
    <div className={`${styles.projectName} text-limiter`}>
      {!!data?.project?.job_number && `${data?.project?.job_number} - `}
      {!!data?.project?.name && data?.project?.name}
    </div>
  );
};

export default Project;
