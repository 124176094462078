import React from "react";
import { Tag, Save, XCircle } from "react-feather";
import { useParams } from "react-router-dom";
import { HeaderButton } from "component-library";
import useProject from "../../hooks/useProject";

const HeaderButtons = ({ canSubmit, canArchive = true, setArchiveModalVisible }) => {
  const { id } = useParams();
  const { project } = useProject();

  return (
    <div className="d-flex flex-row align-items-center">
      <HeaderButton
        className="align-self-center"
        linkTo={`/bid/${id}`}
        text="View Bid"
        icon={<Tag size={16} color={project?.created_without_bid === 1 ? "#7b7b7b" : "#0071ce"} />}
        disabled={project?.created_without_bid === 1}
      />
      <HeaderButton
        formId="project-settings"
        text="Save Project"
        disabled={!canSubmit}
        icon={<Save size={16} color="#0071ce" />}
      />
      <HeaderButton
        onClick={() => setArchiveModalVisible(true)}
        text="Archive Project"
        icon={<XCircle size={16} color="#0071ce" />}
        disabled={!canArchive}
      />
    </div>
  );
};

export default HeaderButtons;
