import React, { useState, useEffect } from "react";
import { Grid } from "react-feather";
import LibraryGrid from "../../../../components/PhotoLibrary/GridView/SingleItems/LibraryGrid";
import styles from "./styles.module.scss";
import { formatApiDate } from "component-library";
import { ReactComponent as Unpinned } from "../../../../assets/photoUnpinned.svg";
import AlbumListView from "./AlbumListView/AlbumListView";
import GalleryView from "../../../../components/PhotoLibrary/GalleryView";
import GridWrapper from "../../../../components/PhotoLibrary/GridView/SingleItems/GridWrapper";

const AlbumViews = ({
  isVisible,
  activeView,
  pinnedPhotos,
  project,
  albumPhotosList,
  dateRange,
  handlePin,
  handleDelete,
  albumPhotosLoading,
  albumPhotosWithParamsLoading,
  selectMode,
  selectedItems,
  setSelectedItems,
  refreshLists,
  albumPhotosWithParamsError,
  albumPhotosError,
  isGalleryView,
  q,
}) => {
  const [params, setParams] = useState();

  useEffect(() => {
    if (!!project?.id) {
      setParams({
        project_id: project.id,
        from_date: formatApiDate(dateRange[0]),
        to_date: formatApiDate(dateRange[1]),
        q: q,
      });
    }
  }, [q, dateRange, project]);

  if (!isVisible) return null;

  return (
    <>
      {activeView === "grid-view" && (
        <>
          <GridWrapper
            icon={<Unpinned className={styles.pinIcon} />}
            title="Pinned Photos"
            loading={albumPhotosLoading || albumPhotosWithParamsLoading}
            error={albumPhotosError || albumPhotosWithParamsError}
          >
            <LibraryGrid
              project={project}
              gridItems={pinnedPhotos}
              gridSize="large"
              handlePin={handlePin}
              handleDelete={handleDelete}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              selectMode={selectMode}
            />
          </GridWrapper>
          <GridWrapper
            icon={<Grid color="#4F4F4F" size={"1.6rem"} />}
            title="All Photos"
            loading={albumPhotosLoading}
            error={albumPhotosError}
          >
            <LibraryGrid
              project={project}
              gridItems={albumPhotosList}
              gridSize="large"
              handlePin={handlePin}
              handleDelete={handleDelete}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              selectMode={selectMode}
            />
          </GridWrapper>
        </>
      )}
      {activeView === "list-view" && (
        <AlbumListView
          pinnedPhotos={pinnedPhotos}
          photosList={albumPhotosList}
          params={params}
          handlePin={handlePin}
          handleDelete={handleDelete}
          loading={albumPhotosLoading || albumPhotosWithParamsLoading}
          selectMode={selectMode}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      )}
      {isGalleryView && (
        <GalleryView
          project={project}
          photosList={albumPhotosList}
          photosError={albumPhotosWithParamsError}
          photosLoading={albumPhotosWithParamsLoading}
          dateRange={dateRange}
          albumsError={albumPhotosError}
          albumsLoading={albumPhotosLoading}
          handlePin={handlePin}
          handleDelete={handleDelete}
          refreshLists={refreshLists}
          selectMode={selectMode}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      )}
    </>
  );
};

export default AlbumViews;
