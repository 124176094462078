import React from 'react';
import { displayDate } from "component-library";

const PreBidMeeting = ({data}) => {
  return data.bid_todos ? (
    <>
      {data.bid_todos.map((todo, index) => 
        !!todo.bid_todo && todo.bid_todo.name === "Pre Bid Meeting" ? (
          <div key={index}>
            <div>{displayDate(todo.pre_bid_date, true)}</div>
          </div>

        ) : null
      )}
    </>
  ) : null;
}

export default PreBidMeeting;