const expand = {
  headerName: "",
  field: "expand",
  maxWidth: 50,
  minWidth: 50,
  cellRenderer: "agGroupCellRenderer",
  pinned: "right",
};

export default expand;
