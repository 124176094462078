import React from "react";
import styles from "./styles.module.scss";
import { getWeatherIcon } from "component-library";

const HourlyBlock = ({ conditions }) => {
  const formattedTime = (time) => {
    const date = new Date(time);

    const formatted = date
      .toLocaleTimeString([], { hour: "numeric", minute: "numeric", hour12: true })
      .toLowerCase()
      .replace(":00", "")
      .replace(" ", "");

    return formatted || "";
  };

  const precipitation = parseFloat(conditions?.rain_accumulation + conditions?.snow_accumulation).toFixed(2);

  return (
    <>
      {!!conditions && (
        <div className={styles.hourlyBlock}>
          <div className="d-flex align-items-center text-center justify-content-center">
            <div className={styles.tempsBlock}>
              <div className={styles.conditions}>
                <div className="d-flex align-items-center text-center">
                  {getWeatherIcon(conditions?.weather_code, 21)}
                  <span className={styles.value}>{parseInt(conditions?.temperature) || 0}°</span>
                </div>
              </div>
              <div className={styles.precip}>
                <div className={styles.description}>Precip - {precipitation}"</div>
              </div>
            </div>
          </div>
          <div className={`text-center ${styles.startTime}`}>{formattedTime(conditions.time_of_reading)}</div>
        </div>
      )}
    </>
  );
};

export default HourlyBlock;
