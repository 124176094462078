import React from "react";
import { XCircle } from "react-feather";
import { HeaderButton } from "component-library";

const CreateBidHeader = () => {

  return (
    <div className="d-flex flex-row">
      <HeaderButton
        className="align-self-center"
        text="Cancel"
        linkTo="/dashboard"
        icon={<XCircle size={16} color="#0071ce" />}
        ariaLabel="cancel creating new bid"
      />
      <HeaderButton
        formId="create-initial-bid-form"
        text="Create Bid"
      />
    </div>
  );
};

export default CreateBidHeader;
