/**
 * formats the project title based on params
 * @param  {String} project project object containing job number and name
 * @param  {String} loading project loading state
 * @param  {String} error project error state
 * @return {String} project title containing job number and name, with exceptions for loading state and errors
 */

const formatProjectTitle = (project, loading, error) => {
  return project
    ? `${!!project.job_number ? `${project.job_number} - ` : ""}${project.name}`
    : (loading && "Loading...") || (!!error && "Error") || "";
};

export default formatProjectTitle;