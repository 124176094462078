import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { photoLibraryApi, useApi } from "component-library";
import useProject from "../../../hooks/useProject";

const usePhotosByDate = () => {
  const { project } = useProject();
  const { photoDate } = useParams();
  const [photos, setPhotos] = useState([]);

  const {
    request: photosRequest,
    data: photosData,
    loading: photosLoading,
    error: photosError,
  } = useApi(photoLibraryApi.getPhotosByDate);

  useEffect(() => {
    if (!!project && !!photoDate) {
      photosRequest({ project_id: project?.id, date: photoDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, photoDate]);

  useEffect(() => {
    if (photosData) {
      setPhotos(photosData.photo);
    }
  }, [photosData]);

  return {
    photos,
    photoDate,
    photosLoading,
    photosError,
  };
};

export default usePhotosByDate;
