import React from "react";
import StatusGrid from "./StatusGrid.js";
import DashboardPage from "./DashboardPage.js";
import useDashboard from "./useDashboard.js";
import PageHeader from "./PageHeader";

const StatusPage = () => {
  const { jobs } = useDashboard();

  return (
    <DashboardPage>
      <PageHeader title="Current Status" />

      <div className="row">
        <StatusGrid jobs={jobs} />
      </div>
    </DashboardPage>
  );
};

export default StatusPage;
