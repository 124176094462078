import Certifications from "../rfqColumns/Certifications";

const certifications = {
  headerName: "Certifications",
  field: "certifications",
  minWidth: 172,
  flex: 1,
  cellRenderer: Certifications
};

export default certifications;