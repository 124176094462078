import { useState } from "react";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import useProject from "../../../../../hooks/useProject";

const useProvideDailyFieldLogs = () => {
  const { fieldMgmtDateRange } = useProject();
  const { id } = useParams();
  const [updateRequests, setUpdateRequests] = useState(false);

  const formatDate = (unformattedDate) => {
    return DateTime.fromJSDate(new Date(unformattedDate)).toFormat("yyyy-MM-dd");
  };

  const getRequestParams = (isStats = false) => {
    const fromDate = fieldMgmtDateRange[0];
    const toDate = fieldMgmtDateRange[1];

    let params = {
      project_id: id,
    };
    if (!!toDate) {
      params = {
        ...params,
        from_date: formatDate(fromDate),
        to_date: formatDate(toDate),
      };
    }

    if ((!!fromDate && !toDate) || isStats) {
      params = {
        ...params,
        project_date: formatDate(fromDate),
      };
    }

    return params;
  };

  return {
    updateRequests,
    setUpdateRequests,
    getRequestParams,
  };
};

export default useProvideDailyFieldLogs;
