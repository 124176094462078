import { useState, useEffect } from "react";
import rolesApi from "../../api/roles";
import permissionsApi from '../../api/permissions';
import { handleError, useFormControl, useApi, Loader } from "component-library";
import { schema, initialData } from "../../schema/role";
import { Alert } from "react-bootstrap";

const useEditRole = (setRoles, roles, setSuccessMessage) => {
  const [editRole, setEditRole] = useState();
  const [error, setError] = useState();
  const [permissions, setPermissions] = useState([]);

  const {
    request: permissionsRequest,
    data: permissionsData,
    error: permissionsError,
    loading: permissionsLoading,
  } = useApi(permissionsApi.getPermissions);

  useEffect(() => {
    permissionsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!permissionsData?.success && !!permissionsData?.permissions) setPermissions(permissionsData.permissions);
  }, [permissionsData]);

  const onSubmit = async (data, setLoading) => {
    setLoading(false);
    setSuccessMessage();
    const processedFields = { ...data, permission: editRole.permissions || [], id: editRole.id };
    try {
      const result = await rolesApi.updateRole(processedFields);
      const resultError = handleError.isError(result);
      if (resultError) setError(resultError);
      if (result && result.data && result.data.role) {
        setRoles(roles.map((role) => (role.id === result.data.role.id ? result.data.role : role)));
        setEditRole();
        setLoading(false);
        setSuccessMessage(`${result.data.role.name} role updated.`);
      } else {
        setError("Something went wrong.");
      }
    } catch (error) {
      setError(error);
    }
  };

  const setValuesArray = (values) => {
    setEditRole({ ...editRole, permissions: values });
  };

  const { handleSubmit, renderTextField, renderSubmitButton, renderCheckboxInGroup, setFields } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const fields = (
    <div className="position-relative">
      <Loader loading={permissionsLoading} />
      {(!!permissionsError || !!error) && <Alert variant="danger">{permissionsError || error}</Alert>}
      <div className="row">
        <div className="col">
          {renderTextField({
            name: "name",
            label: "Name",
            autoFocus: true,
          })}
        </div>
      </div>
      {permissions.map((permission) => (
        <div className="row" key={permission.id}>
          <div className="col">
            {renderCheckboxInGroup({
              groupName: "permissions[]",
              name: permission.name,
              label: permission.name,
              setValuesArray,
              valuesArray: (!!editRole && editRole.permissions) || [],
            })}
          </div>
        </div>
      ))}
    </div>
  );

  return {
    editRole,
    setEditRole,
    handleSubmit,
    fields,
    renderSubmitButton,
    setFields,
  };
};

export default useEditRole;
