const timesheetWidths = {
  id: "8rem",
  crew_member: "18rem",
  per_diem: "7rem",
  foreman: "11.5rem",
  clock_in: "10rem",
  clock_out: "10rem",
  lunch: "10rem",
  unallocated: "11.5rem",
  hours: "8rem",
  date: "8rem",
  job_number: "8rem",
  project_name: "18rem",
};

export default timesheetWidths;
