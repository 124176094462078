import { Joi } from "component-library";

const schema = {
  time_zone: Joi.saneString(),
  gmap_key: Joi.saneString(),
  logo: Joi.object(),
  accent_color: Joi.saneString(),
};

const initialData = {
  time_zone: "",
  gmap_key: "",
  logo: {},
  accent_color: "",
};

export { schema, initialData };
