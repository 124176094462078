import React, { useState, useEffect } from "react";
import { Button, Popover, OverlayTrigger, Alert } from "react-bootstrap";
import styles from "./../TaskView/Task/TimeEntryPopover/styles.module.scss";
import { Search } from "react-feather";
import PropTypes from "prop-types";
import { BeatLoader } from "react-spinners";

const AddCrew = ({ children, fieldLog, crew, loading, error, selectTimesheet, crewLoading }) => {
  const [q, setQ] = useState("");
  const [fullCrew, setFullCrew] = useState(crew || []);

  const handleClick = (value) => {
    selectTimesheet(value);
    document.body.click();
  };

  const filterTimesheets = (value) => {
    setQ(value);
  };

  useEffect(() => {
    let fullCrewList;

    if (q === "") {
      if (!!crew?.length) {
        fullCrewList = crew;
      }
    } else {
      if (!!crew?.length) {
        fullCrewList = crew.filter(
          (c) =>
            c?.last_name?.toLowerCase().includes(q.toLowerCase()) ||
            c?.first_name?.toLowerCase().includes(q.toLowerCase())
        );
      }
    }

    setFullCrew(fullCrewList);
  }, [q, fieldLog, crew]);

  const popover = (
    <Popover placement="bottom" id="popover-entries" positionbottom={-50}>
      <Popover.Body>
        <div className="position-relative">
          {(!!loading || !!crewLoading) && (
            <div className="loaderWrapper">
              <BeatLoader size={10} color={"#0071ce"} />
            </div>
          )}
          <div className={styles.header}>
            <div className="position-relative d-flex align-items-center">
              <input
                className="form-control border-bottom"
                placeholder="Search Crew"
                onChange={(e) => {
                  filterTimesheets(e.target.value);
                }}
                value={q}
              />
              <div className={styles.formControlIcon}>
                <Search size="18" />
              </div>
            </div>
          </div>
          {!!error && (
            <div>
              <Alert variant="danger">{error}</Alert>
            </div>
          )}
          <div className={styles.popover}>
            {!!fullCrew?.length &&
              fullCrew.map((c, index) => {
                return (
                  <Button
                    variant="link"
                    key={index}
                    onClick={() => handleClick(c)}
                    className={`${styles.option} d-flex align-items-center justify-content-between w-100 text-gray2 med text-decoration-none`}
                  >
                    {c.first_name} {c.last_name}
                  </Button>
                );
              })}
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <OverlayTrigger trigger="click" placement="bottom-start" transition={false} overlay={popover} rootClose>
        {children}
      </OverlayTrigger>
    </>
  );
};

AddCrew.propTypes = {
  children: PropTypes.node,
  fieldLog: PropTypes.object,
  crew: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  selectTimesheet: PropTypes.func,
};

export default AddCrew;
