import Hours from "../columnRenders/TotalHours";

const timesheet_hours = {
  headerName: "Hours",
  field: "hours",
  maxWidth: 100,
  cellRenderer: Hours
};

export default timesheet_hours;
