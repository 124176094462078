import React from "react";

const PM = ({ data }) => {

  return (
    <>
      {!!data?.project_managers &&
        data.project_managers.map((pm, i, arr) => {
          if (arr.length - 1 === i) {
            return (
              <span key={i}>
                {pm.first_name} {pm.last_name}
              </span>
            );
          } else {
            return (
              <span className="me-2" key={i}>
                {`${pm.first_name} ${pm.last_name},`}
              </span>
            );
          }
        })}
    </>
  );
};

export default PM;
