import React from "react";
import DayStat from "./DayStat";
import styles from "./styles.module.scss";

const DayStats = ({ report }) => {
  return (
    <div className={styles.dayStatsRow}>
      <DayStat textStyle={styles.darkText} data={report?.project?.working_days || 0} label="Total Days" />
      <DayStat textStyle={styles.orangeText} data={report?.rows.reduce((acc, curr) => acc + parseFloat(curr.working_day_value), 0) || 0} label="Days Added" />
      <DayStat textStyle={styles.darkText} data={report?.project?.worked_days || 0} label="Used Days" />
      <DayStat textStyle={styles.lightText} data={report?.project?.remaining_days || 0} label="Days Left" />
    </div>
  );
};

export default DayStats;
