import {
  toggler,
  category,
  certifications,
  company_name,
  contact_name,
  territory
} from "./columnDefs";

const columnDefs = [
  toggler,
  company_name,
  territory,
  contact_name,
  category,
  certifications,
];

export default columnDefs;