import React from 'react';

const Addendas = ({data}) => {
  return data.addendas ? (
    <>
    {data.addendas.length ? (
      data.addendas[data.addendas.length - 1].addenda_number
    ) : null }
    </>
  ) : null;
}

export default Addendas;