import { Joi } from "component-library";
import regexPhone from "../utils/regexPhone";

const schema = {
  first_name: Joi.saneString().required().label("First Name"),
  last_name: Joi.saneString().required().label("Last Name"),
  contact_type: Joi.saneString().required().label("Contact Type"),
  title: Joi.saneString().label("Title"),
  email: Joi.saneString()
    .required()
    .email({ tlds: { allow: false } })
    .label("Email"),
  phone: Joi.saneString().regex(regexPhone).label("Phone"),
  cell_phone: Joi.saneString().regex(regexPhone).label("Cell Phone"),
  company: Joi.any(),
  company_id: Joi.any(),
  id: Joi.any(),
  territory_location: Joi.saneString(),
  locations: Joi.array().required().min(1).label("Location"),
};

const initialData = {
  contact_type: "",
  first_name: "",
  last_name: "",
  title: "",
  email: "",
  phone: "",
  cell_phone: "",
  territory_location: "",
  locations: [],
};

export { schema, initialData };
