import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useAuth } from "component-library";
import useResourceManagement from "../../context/useResourceManagement";

const useControlBar = ({
  projectId,
  getFieldLogs,
  loading,
  page,
  setPage,
  itemsPerPage,
  total,
  viewAll,
  initialLoad = false,
  dateRange,
}) => {
  const { q, contextInitialLoad, statuses, projectManager } = useResourceManagement();

  const { userCan } = useAuth();
  const [pmOptions, setPmOptions] = useState([]);
  const [startDate, endDate] = dateRange;

  const getSettings = (query, id, currentPage) => {
    let settings = {
      q: query,
      statuses,
      payroll: userCan("payroll-review-time") ? 1 : 0,
      project_manager_id: projectManager || null,
      include_timesheets: !!viewAll ? 0 : 1,
      project_id: id,
      items_per_page: !!viewAll ? total : itemsPerPage,
    };
    if (!viewAll) {
      settings = {
        ...settings,
        page: currentPage,
      };
    }
    if (!!startDate && !!endDate) {
      settings = {
        ...settings,
        from_date: DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd"),
        to_date: DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd"),
      };
    }

    return settings;
  };

  const searchFieldLogs = (query, projectId, currentPage = 1) => {
    if (page !== currentPage) {
      setPage(currentPage);
    }
    const settings = getSettings(query, projectId, currentPage);
    getFieldLogs(settings);
  };

  useEffect(() => {
    if (!loading && !contextInitialLoad && !initialLoad && (!startDate || !!endDate)) {
      if (!!viewAll) {
        getFieldLogs(getSettings(q, projectId, 1));
      } else {
        searchFieldLogs(q, projectId, page);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectManager, statuses, q, projectId, dateRange, page, viewAll, contextInitialLoad, initialLoad]);

  return {
    pmOptions,
    setPmOptions,
    viewAll,
  };
};

export default useControlBar;
