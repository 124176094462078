import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { Eye, CheckCircle, Trash2 } from "react-feather";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ReactComponent as Pinned } from "../../../../assets/photoPinned.svg";
import { ReactComponent as Unpinned } from "../../../../assets/photoUnpinned.svg";
import { useApi, photoLibraryApi, ConfirmModal } from "component-library";
import { BeatLoader } from "react-spinners";
import { toggleItem } from "../../hooks/toggleItem";

const GridItem = ({ project, item, handlePin, handleDelete, selectMode, selectedItems, setSelectedItems }) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const imageUrl = useMemo(() => {
    return item.first_photo?.small_thumbnail || item.first_photo?.abs_url || item.small_thumbnail || item.abs_url;
  }, [item]);

  const isAlbum = useMemo(() => {
    return item.photo_count || item.first_photo || !item.extension;
  }, [item]);

  const { request: albumPinRequest, loading: albumPinLoading, data: albumPinData } = useApi(photoLibraryApi.pinAlbum);

  const {
    request: albumDeleteRequest,
    loading: albumDeleteLoading,
    data: albumDeleteData,
  } = useApi(photoLibraryApi.deleteAlbum);

  const {
    request: updateDetailRequest,
    data: updateDetailData,
    loading: updateDetailLoading,
  } = useApi(photoLibraryApi.updatePhotoDetails);

  const {
    request: photoDeleteRequest,
    data: photoDeleteData,
    loading: photoDeleteLoading,
    error: photoDeleteError,
  } = useApi(photoLibraryApi.deletePhoto);

  const handlePinRequest = () => {
    if (isAlbum) {
      albumPinRequest({ projectId: project?.id, albumId: item.id, pinned: !item.pinned });
    } else {
      updateDetailRequest({ photo_id: item.id, pinned: !item.pinned });
    }
  };

  const handleDeleteRequest = () => {
    if (isAlbum) {
      albumDeleteRequest(item.id);
    } else {
      photoDeleteRequest(item.id);
    }
  };

  useEffect(() => {
    if (!!albumDeleteData?.success) {
      handleDelete(item.id, "album");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [albumDeleteData]);

  useEffect(() => {
    if (!!albumPinData?.success) {
      handlePin(item.id, "album");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [albumPinData]);

  useEffect(() => {
    if (!!updateDetailData?.photo) {
      handlePin(item.id, "photo");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDetailData]);

  useEffect(() => {
    if (!!photoDeleteData?.success) {
      handleDelete(item.id, "photo");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoDeleteData]);

  const handleToggler = (item) => {
    toggleItem(item, selectedItems, setSelectedItems);
  };

  const isChecked = (storedItems, data, type) => {
    return storedItems.some((storedItem) => storedItem.type === type && storedItem.id === data.id);
  };

  return (
    <div className="d-flex flex-column position-relative">
      {!!selectMode ? (
        <Button
          variant="link"
          key={item.id}
          className={`${styles.imgWrapper} ${styles.hover} p-0`}
          onClick={() => handleToggler(item)}
        >
          <img src={imageUrl} alt="" loading="lazy" />
          {!!isChecked(selectedItems, item, !!isAlbum ? "album" : "photo") && (
            <div className={styles.selectedOverlay} />
          )}
          <div
            className={`
            ${styles.selectedIndicator} 
            ${!!isChecked(selectedItems, item, !!isAlbum ? "album" : "photo") ? styles.active : ""} 
            ${isAlbum ? styles.isAlbum : ""}
            `}
          >
            {!!isChecked(selectedItems, item, !!isAlbum ? "album" : "photo") && <CheckCircle size={16} color="#fff" />}
          </div>
        </Button>
      ) : (
        <div key={item.id} className={styles.imgWrapper}>
          <>
            {updateDetailLoading || photoDeleteLoading || albumPinLoading || albumDeleteLoading ? (
              <div className="d-flex justify-content-center align-items-center h-100">
                <BeatLoader color="#0071ce" />
              </div>
            ) : (
              <>
                <img src={imageUrl} alt="" loading="lazy" />
                <div className={`${styles.overlay} ${isAlbum ? styles.overlayAlbum : ""}`}>
                  <Link
                    to={`/project/${project?.id}/photo-library/${isAlbum ? "album" : "photo"}/${item.id}`}
                    key={item.id}
                    className={styles.hoverIcon}
                  >
                    <Eye color="#fff" size={"2rem"} />
                  </Link>
                  <div className={styles.hoverIcon} onClick={handlePinRequest}>
                    {item?.pinned ? <Pinned className={styles.pinIcon} /> : <Unpinned className={styles.pinIcon} />}
                  </div>
                  <div className={styles.hoverIcon} onClick={() => setShowConfirmDeleteModal(true)}>
                    <Trash2 size={"2rem"} color="#fff" />
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      )}
      {isAlbum && (
        <div className={styles.footerTextBox}>
          <div className={`${styles.name} text-limiter`}>{item?.name}</div>
          <div className={styles.count}>
            {item?.photo_count || 0} Photo{item?.photo_count !== 1 && "s"}
          </div>
        </div>
      )}
      <ConfirmModal
        showConfirmation={showConfirmDeleteModal}
        setShowConfirmation={setShowConfirmDeleteModal}
        confirmFunction={handleDeleteRequest}
        loading={photoDeleteLoading}
        error={photoDeleteError}
        name={`Confirm Delete: ${item?.name || item?.display_name || item?.file_name} `}
        message={`Are you sure you want to delete: ${item?.name || item?.display_name || item?.file_name}?`}
      />
    </div>
  );
};

export default GridItem;
