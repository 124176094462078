import { useApi, timesheetApi, fieldLogsApi } from "component-library";
import { useState, useEffect } from "react";

const useUpdateTimeEntry = ({
  entry,
  tasks,
  timesheet,
  setTimesheet,
  foremanId,
  setOverAllocatedTimeSheets,
  overAllocatedTimeSheets,
  config,
  fieldLog,
  setFieldLog,
}) => {
  const [loading, setLoading] = useState();
  const [laborOptions, setLaborOptions] = useState([]);
  const [error, setError] = useState();
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [secondaryEquipmentOptions, setSecondaryEquipmentOptions] = useState();

  const { request: removeEntryFromWorkDay } = useApi(fieldLogsApi.removeEntryFromFieldLog);

  const { request: updateEntry, loading: updateLoading, error: updateError } = useApi(timesheetApi.updateTimeEntry);

  const removeTimeEntry = async () => {
    if (!!entry?.id && !!entry?.work_day_task_id && !!foremanId) {
      const response = await removeEntryFromWorkDay(entry.id, entry.work_day_task_id, foremanId);
      setFieldLog({
        ...fieldLog,
        work_day_tasks: fieldLog.work_day_tasks.map((task) => {
          if (task.id === entry.work_day_task_id) {
            return {
              ...task,
              time_entries: task.time_entries.filter((e) => e.id !== entry.id),
              time_entry_ids: task.time_entry_ids.filter((e) => e !== entry.id),
            };
          } else {
            return task;
          }
        }),
        time_sheets: fieldLog.time_sheets.map((sheet) => {
          if (sheet.id === entry.time_sheet_id) {
            return {
              ...sheet,
              time_entries: sheet.time_entries.filter((e) => e.id !== entry.id),
              time_entry_ids: sheet.time_entry_ids.filter((e) => e !== entry.id),
            };
          } else {
            return sheet;
          }
        }),
      });
      if (!!response.success) {
        if (!!response?.responseData?.time_entry?.work_day_task?.over_allocated) {
          setOverAllocatedTimeSheets([...overAllocatedTimeSheets, entry.time_sheet_id]);
        } else {
          setOverAllocatedTimeSheets(overAllocatedTimeSheets.filter((ts) => ts !== entry.time_sheet_id));
        }
      }
    }
  };

  useEffect(() => {
    const noEquip = { label: `No Equipment`, value: 0 };

    if (!!config?.equipment) {
      const configEquip = config.equipment.map((equip) => ({
        value: equip.id,
        label: `#${equip.equip_no} ${equip?.display_description || equip?.name}`,
      }));
      setEquipmentOptions([noEquip, ...configEquip]);
    } else {
      setEquipmentOptions([noEquip]);
    }
  }, [config]);

  useEffect(() => {
    if (!!entry?.equipment?.secondary_equipment?.length) {
      const noEquipment = {
        label: `No Equipment`,
        value: 0,
      };
      const equipment = entry.equipment.secondary_equipment.map((equip) => ({
        label: `#${equip?.equip_no} ${equip?.display_description || equip?.name}`,
        value: equip?.id,
      }));
      setSecondaryEquipmentOptions([noEquipment, ...equipment]);
    } else {
      setSecondaryEquipmentOptions([]);
    }
    if (!!entry?.equipment?.labor_types?.length) {
      setLaborOptions(
        entry.equipment.labor_types.map((labor) => ({
          value: labor.id,
          label: labor.name,
        }))
      );
    } else {
      if (!entry.equipment_id && !!config?.labor_no_equipment?.length) {
        setLaborOptions(
          config.labor_no_equipment.map((labor) => ({
            value: labor.id,
            label: labor.name,
          }))
        );
      } else {
        setLaborOptions([{ value: 14, label: "General Laborer" }]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!entry && entry.equipment]);

  const task = !!tasks ? tasks.find((task) => task.id === entry.work_day_task_id) : null;
  const taskName = !!task ? task.task?.name : "";

  const updateTime = async (minutes) => {
    const newTimesheet = { ...timesheet };
    newTimesheet.time_entries = newTimesheet.time_entries.map((timeEntry) => {
      if (timeEntry.id === entry.id) {
        timeEntry.minutes = minutes;
      }
      return timeEntry;
    });
    const { success, responseData } = await updateEntry({
      ...entry,
      minutes,
    });
    if (success) {
      if (!!newTimesheet?.all_time_entries?.length) {
        newTimesheet.all_time_entries = newTimesheet.all_time_entries.map((timeEntry) => {
          if (timeEntry.id === entry.id) {
            timeEntry = responseData.time_entry.time_entry;
          }
          return timeEntry;
        });
      }
      setTimesheet(newTimesheet);
      if (!responseData.time_entry.over_allocated) {
        setOverAllocatedTimeSheets(
          overAllocatedTimeSheets.filter((ts) => ts.id !== responseData.time_entry.time_sheet_id)
        );
      } else {
        setOverAllocatedTimeSheets([...overAllocatedTimeSheets, timesheet.id]);
      }
    }
  };

  const updateEquipment = async (equipmentId) => {
    const equipment = config.equipment.find((e) => e.id === equipmentId);

    const laborTypeId = !!equipment?.labor_type_ids?.length
      ? equipment.labor_type_ids.length === 1
        ? equipment.labor_type_ids[0].labor_type_id
        : 0
      : 14;

    const confirmLaborType = !!config.labor_types.find((l) => l.id === laborTypeId) ? laborTypeId : 14;

    const { success, responseData } = await updateEntry({
      ...entry,
      equipment_id: equipmentId,
      secondary_equipment: null,
      secondary_equipment_id: 0,
      labor_id: confirmLaborType,
    });
    if (success && !!responseData?.time_entry) {
      const newTimesheet = { ...timesheet };
      newTimesheet.time_entries = newTimesheet.time_entries.map((timeEntry) => {
        if (timeEntry.id === entry.id) {
          timeEntry = responseData.time_entry.time_entry;
        }
        return timeEntry;
      });
      setTimesheet(newTimesheet);
    }
  };

  const updateSecondaryEquipment = async (equipmentId) => {
    const { success, responseData } = await updateEntry({
      ...entry,
      secondary_equipment_id: equipmentId,
    });
    if (success && !!responseData && !!responseData.time_entry) {
      const newTimesheet = { ...timesheet };
      newTimesheet.time_entries = newTimesheet.time_entries.map((timeEntry) => {
        if (timeEntry.id === entry.id) {
          timeEntry = responseData.time_entry.time_entry;
        }
        return timeEntry;
      });
      setTimesheet(newTimesheet);
    }
  };

  const updateLaborType = async (laborTypeId) => {
    const { success, responseData } = await updateEntry({
      ...entry,
      labor_id: laborTypeId,
    });
    if (success && !!responseData?.time_entry) {
      const newTimesheet = { ...timesheet };
      newTimesheet.time_entries = newTimesheet.time_entries.map((timeEntry) => {
        if (timeEntry.id === entry.id) {
          timeEntry = responseData.time_entry.time_entry;
        }
        return timeEntry;
      });
      setTimesheet(newTimesheet);
    }
  };

  useEffect(() => {
    if (!!updateError) {
      setError(updateError);
    } else {
      setError();
    }
  }, [updateError]);

  useEffect(() => {
    if (!!updateLoading) {
      setLoading(updateLoading);
    } else {
      setLoading();
    }
  }, [updateLoading]);

  return {
    removeTimeEntry,
    updateTime,
    updateEquipment,
    updateLaborType,
    laborOptions,
    equipmentOptions,
    secondaryEquipmentOptions,
    updateSecondaryEquipment,
    task,
    taskName,
    loading,
    error,
  };
};

export default useUpdateTimeEntry;
