import React, { useState } from "react";
import AdminIcon from "../../icons/AdminIcon";
import ContactsAdminNav from "../ContactsAdmin/ContactsAdminNav";
import InteriorPage from "../pageLayout/InteriorPage";
import { Button } from "react-bootstrap";
import styles from "../ContactsAdmin/ContactsAdmin.module.scss";
import { HeaderButton, useAuth } from "component-library";
import UnauthorizedCard from "../UnauthorizedCard";

const ContactsAdmin = () => {
  const { userCan } = useAuth();
  const [byCompany, setByCompany] = useState(true);
  const [addingContact, setAddingContact] = useState(false);
  const [addingCompany, setAddingCompany] = useState(false);
  const [addingLocation, setAddingLocation] = useState(false);

  const AddButton = () => (
    <HeaderButton
      onClick={() => {
        if (!!byCompany) {
          setAddingCompany(true);
        } else {
          setAddingContact(true);
        }
      }}
      ariaLabel={!!byCompany ? "Add New Company Contact" : "Add New Contact"}
    />
  );

  const pageTabs = () => (
    <nav role="navigation">
      <Button
        variant="link"
        className={`${!!byCompany && styles.active} ${styles.inlineTabs}`}
        onClick={() => setByCompany(true)}
      >
        By Company
      </Button>
      <Button
        variant="link"
        className={`${!byCompany && styles.active} ${styles.inlineTabs}`}
        onClick={() => setByCompany(false)}
      >
        By Contact
      </Button>
    </nav>
  );

  return (
    <InteriorPage
      navTitle="Directory"
      navIcon={<AdminIcon color="#fff" size={14} />}
      pageTitlePrimaryContent={pageTabs}
      pageTitleSecondaryContent={AddButton}
    >
      {userCan("contact-list") ? (
        <ContactsAdminNav
          byCompany={byCompany}
          setByCompany={setByCompany}
          addingContact={addingContact}
          setAddingContact={setAddingContact}
          addingCompany={addingCompany}
          setAddingCompany={setAddingCompany}
          addingLocation={addingLocation}
          setAddingLocation={setAddingLocation}
        />
      ) : (
        <UnauthorizedCard />
      )}
    </InteriorPage>
  );
};

export default ContactsAdmin;
