import React from "react";
import { Tag } from "react-feather";
import InteriorPage from "../../../../../components/pageLayout/InteriorPage";
import useQuote from "../useQuote";
import { RiseLoader } from "react-spinners";
import useProject from "../../../../hooks/useProject";
import { Quote as Q, useAuth } from "component-library";
import LineItems from "../LineItems";
import QuoteSupplier from "./QuoteSupplier";
import { Navigate } from "react-router-dom";
import Controls from "./Controls";

const QuoteEdit = () => {
  const { pageTitle, PageTag } = useProject();
  const { user, config } = useAuth();
  const {
    error,
    quote,
    setQuote,
    getLoading,
    loading,
    used,
    handleDeleteQuote,
    redirect,
    deleteQuoteLoading,
    deleteQuoteError,
    SaveQuoteButton,
    updateQuote,
    updateTerms,
  } = useQuote();

  return (
    <InteriorPage
      loading={getLoading}
      error={error}
      navTitle={pageTitle}
      navIcon={<Tag color="#fff" size={14} />}
      pageTitle={`Edit Quote ${!!quote && !!quote.id ? quote.id : ""}`}
      className="requote"
      pageTitleSecondaryContent={SaveQuoteButton}
      NavContent={PageTag}
    >
      {!!redirect && <Navigate to={redirect} />}
      <div className="position-relative">
        {loading && (
          <div className="loaderWrapper">
            <RiseLoader color={"#0071ce"} />
          </div>
        )}
        {!!quote && (quote.status === 2 || quote.status === 3 || !!quote.po_submitted) ? (
          <>
            {!!quote.po_submitted ? (
              <>A Purchase Order has already been created with this quote and it cannot be edited at this time</>
            ) : (
              <>This quote is undergoing a requote and cannot be edited at this time.</>
            )}
          </>
        ) : (
          <>
            {!!quote && (
              <div className="requote">
                <div className="d-flex justify-content-between align-items-center pb-4">
                  <Q.EditMetaData quote={quote} setquote={setQuote} editDate={true} updateQuote={updateQuote} />
                  <Controls
                    updateQuote={updateQuote}
                    quote={quote}
                    used={used}
                    handleDeleteQuote={handleDeleteQuote}
                    deleteQuoteLoading={deleteQuoteLoading}
                    deleteQuoteError={deleteQuoteError}
                  />
                </div>
                <div className="q-contactSection">
                  <QuoteSupplier quote={quote} updateQuote={updateQuote} />
                  <div className="q-fileSection">
                    <Q.File quote={quote} setQuote={setQuote} user={user} />
                  </div>
                </div>
                <div className="q-section">
                  <Q.EditTerms
                    updateTerms={updateTerms}
                    quote={quote}
                    setQuote={setQuote}
                    paymentOptions={config.payment_terms.map((term) => {
                      return { value: term, label: term };
                    })}
                  />
                </div>
                <div className="q-section">
                  <LineItems quote={quote} setQuote={setQuote} />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </InteriorPage>
  );
};

export default QuoteEdit;
