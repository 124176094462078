import React from 'react'
import styles from "./styles.module.scss";
import InterfaceIcon from '../../../../../../../components/InterfaceIcon';
import formatTimesheetTime from '../../../../../../../utils/formatTimesheetTime';
import { ArrowLeftCircle, ArrowRightCircle } from 'react-feather';

const TimeInOut = ({ timesheet }) => {
  return (
    <div className="d-flex align-items-center">
      <div className={styles.timeWrapper}>
        <ArrowRightCircle className="me-1" color="#259FAD" size={16} />
        {!!timesheet && formatTimesheetTime(timesheet?.time_in)}
        <div className="me-1">
          <InterfaceIcon
            isWithinGeofence={timesheet?.within_geofence}
            interfaceUsed={timesheet?.interface}
            size={16}
          />
        </div>
      </div>
      <div className={styles.timeWrapper}>
        <ArrowLeftCircle className="me-1" color="#FF6700" size={16} />
        {!!timesheet && formatTimesheetTime(timesheet?.time_out)}

        {!!timesheet?.time_out && (
          <div className="me-1">
            <InterfaceIcon
              isWithinGeofence={timesheet?.within_geofence_clock_out}
              interfaceUsed={timesheet?.interface_clock_out}
              size={16}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TimeInOut