const task = {
  headerName: "Task",
  field: "task",
  flex: 1,
  cellStyle: {
    display: "block",
    marginTop: 7,
    color: "#0071CE",
    fontWeight: 600,
  },
  resizable: true,
};

export default task;
