import { useEffect, useState } from "react";
import contactsApi from "../api/contacts";
import { useApi } from "component-library";

const useContactsAdmin = () => {
  const itemsPerPage = 100;
  const [contacts, setContacts] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsTotal, setResultsTotal] = useState();
  const [q, setQ] = useState("");

  const { request, loading, error, data } = useApi(contactsApi.searchContacts);

  useEffect(() => {
    if (data && data.contacts) {
      setContacts(data.contacts.data);
      setResultsTotal(data.contacts.total);
    }
  }, [data]);

  const {
    request: searchBids,
    data: searchData,
    loading: searchLoading,
  } = useApi(contactsApi.searchContacts);

  useEffect(() => {
    if (searchData) {
      setContacts(searchData.contacts.data);
      setResultsTotal(searchData.contacts.total);
    }
  }, [searchData]);

  const searchContacts = async (value) => {
    if (value) {
      setQ(value);
      searchBids({
        q: value,
        archived: 1,
        page: 1,
        items_per_page: itemsPerPage,
      });
    } else {
      setQ("");
      request({ q: "", archived: 1, page: 1, items_per_page: itemsPerPage });
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    searchBids({
      q: q,
      archived: 1,
      page: currentPage,
      items_per_page: itemsPerPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return {
    request,
    contacts,
    error,
    successMessage,
    setSuccessMessage,
    setContacts,
    loading,
    searchContacts,
    searchLoading,
    currentPage,
    setCurrentPage,
    resultsTotal,
    itemsPerPage,
  };
};

export default useContactsAdmin;
