import React from "react";
import PropTypes from "prop-types";
import styles from "../styles.module.scss";

const ItemNumber = ({ fixedColumns, open, item }) => {
  return (
    <td className={`text-center p-0 ${!!open ? "border-0" : ""}`}>
      <div
        className={`${!!fixedColumns && !!open ? styles.hasBorder : styles.noBorder} 
    px-1 text-center text-nowrap`}
      >
        {item.item_number || "-"}
      </div>
    </td>
  );
};

ItemNumber.propTypes = {
  fixedColumns: PropTypes.bool,
  item: PropTypes.object,
  open: PropTypes.bool,
};

export default ItemNumber;
