import { useState, useEffect } from "react";
import { useApi, dailyFieldlogsApi } from "component-library";

const useTask = ({ task, tasks, setTasks, openTasks }) => {
  const [active, setActive] = useState();
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    setQuantity(parseFloat(task?.quantity) || 0);
  }, [task?.quantity]);

  const {
    request: updateRequest,
    data: updateData,
    error: updateError,
    loading: updateLoading,
  } = useApi(dailyFieldlogsApi.updateTask);

  const updateTasks = (task, value) => {
    updateRequest({ work_day_task_id: task.id, quantity: value });
  };

  useEffect(() => {
    if (!!updateData?.work_day_task) {
      setTasks(tasks.map((t) => (t.id === updateData?.work_day_task?.task?.id ? updateData?.work_day_task?.task : t)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    if (openTasks?.includes(task.id)) {
      setActive(true);
    } else {
      setActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTasks]);

  return {
    active,
    setActive,
    quantity,
    setQuantity,
    updateError,
    updateLoading,
    updateTasks,
  };
};

export default useTask;
