import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApi, submittalsApi } from "component-library";
import useProject from "./../../_projects/hooks/useProject";

const useSubmittalFunctions = () => {
  const { id, submittalId } = useParams();
  const [submittal, setSubmittal] = useState({});
  const [projectOwner, setProjectOwner] = useState();
  const { request, data, loading, error } = useApi(submittalsApi.getSubmittal);
  const { project, setProject, pageTitle, pageSubtitle, PageTag } = useProject();
  const { data: updateData, request: updateRequest } = useApi(submittalsApi.adminUpdateSubmittal);
  const [attachmentsLoading, setAttachmentsLoading] = useState();

  useEffect(() => {
    if (updateData && updateData.submittal) {
      setSubmittal(updateData.submittal);
      if (project && setProject) {
        setProject({
          ...project,
          submittals: project.submittals.map((sub) =>
            sub.id === updateData.submittal.id ? updateData.submittal : sub
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    if (project && submittal) {
      if (project.project_contacts && submittal.project_owner_id) {
        const selectedOwner = project.project_contacts.find((x) => x.contact.id === submittal.project_owner_id);
        if (!!selectedOwner && !!selectedOwner.contact) {
          setProjectOwner(selectedOwner.contact);
        }
      }
    }
  }, [project, submittal]);
  
  useEffect(() => {
    request(submittalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittalId]);
  
  useEffect(() => {
    if (data && data.submittal) {
      setSubmittal(data.submittal);
    }
  }, [data]);

  return {
    id,
    submittalId,
    submittal,
    setSubmittal,
    projectOwner,
    setProjectOwner,
    request,
    data,
    loading,
    error,
    project,
    setProject,
    pageTitle,
    pageSubtitle,
    updateData,
    updateRequest,
    PageTag,
    attachmentsLoading,
    setAttachmentsLoading,
  };
}

export default useSubmittalFunctions