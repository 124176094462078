import React from "react";
import styles from "../styles.module.scss";
import { Link } from "react-feather";

const PhotoHeaders = ({ title, TitleIcon, isAccordion }) => {
  const headerItems = ["Source", "Created", "Uploaded"];

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.listTitle}>
        {!!TitleIcon && <TitleIcon />}
        {!!title && <h2>{title}</h2>}
      </div>
      <div className={styles.unflexHeaders}>
        {headerItems.map((item, index) => (
          <div key={index} className={styles.headerItem}>
            {item}
          </div>
        ))}
        {!isAccordion && <div className={styles.headerItem}>Album</div>}
        <div className={styles.rowItemSmall}>
          <Link size="16" color="#7B7B7B" />
        </div>
        <div className={styles.invsibleCol}></div>
      </div>
    </div>
  );
};

export default PhotoHeaders;
