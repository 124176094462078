import React from "react";
import styles from "./styles.module.scss";
import { DateTime } from "luxon";
import { Button } from "react-bootstrap";
import { Trash2, AlertCircle } from "react-feather";
import PropTypes from "prop-types";
import { Avatar, useAuth, displayDate } from "component-library";
import NoteImages from "./NoteImages";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const ProjectNote = ({
  project,
  note,
  setPhotos,
  setPhotoModalOpen,
  deleteNote,
  condensed = false,
  showPhoto = true,
  isInTable = false,
}) => {
  const { id: projectId } = useParams();
  const { user, config } = useAuth();
  const canDelete = user?.id === note?.user_id && deleteNote;
  const isDeleted = !!note?.title && note?.title.includes("Timesheet Deleted");

  const photos = note?.files || note?.attachments || [];
  return (
    <div className={`${!condensed && (!!note?.rfi || !!isDeleted) && styles.rfi}`}>
      <div
        className={`${styles.note}  ${!!isInTable ? styles.tableNote : ""} ${
          !condensed && (!!note?.rfi || !!note?.title) && styles.warn
        }`}
        key={note.id}
      >
        <header className={styles.header}>
          {!!note?.title && <div className={styles.noteTitle}>{note.title}</div>}
          {!!project?.name && (
            <div className="showPrint">
              {!!project?.job_number && `${project.job_number}-`}
              {project.name}
            </div>
          )}
          <div>
            <div className="d-flex align-items-center mt-2">
              <div className="d-flex align-items-center mt-2 w-100">
                {!!note?.user && (
                  <div className={styles.avatar}>
                    <Avatar size={!!condensed ? "sm" : "med"} hasColor user={note?.user} />
                  </div>
                )}
                <div className={styles.noteName}>
                  {note?.user?.first_name} {note?.user?.last_name}
                </div>
                <div className={styles.dateDay}>
                  <div className="me-3 d-flex align-items-center">
                    <div className="d-flex flex-column justify-content-center">
                      {!condensed && !!note.note_date && (
                        <div className="d-flex align-items-center">
                          <div className="me-3">{displayDate(note?.note_date)}</div>
                        </div>
                      )}
                      {/*
                      {!condensed && !!note.created_at && (
                        <div className={styles.createdDate}>
                          Created
                          <div className="ms-2 me-3 lh-1">{displayDate(note?.created_at)}</div>
                          <div className={styles.time}>{DateTime.fromSQL(note?.created_at).toFormat("hh:mm a")}</div>
                        </div>
                      )}
                      */}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      {!!canDelete && (
                        <Button onClick={() => deleteNote(note.id)} variant="link" className="d-flex align-self-center">
                          <Trash2 size={18} className={styles.icon} />
                        </Button>
                      )}
                      {/* {!!canEdit && (
                      <Button variant="link" onClick={() => setIsEditing(true)}>
                        <Edit3 size={20} color="#0071CE" />
                      </Button>
                    )} */}
                    </div>
                  </div>
                </div>
              </div>
              {!!note?.work_day_task_data?.station && (
                <div className="d-flex align-items-center">
                  <span className={styles.workdayValueWrapper}>
                    STN: <span className={styles.stationValue}>{note?.work_day_task_data?.station || "n/a"}</span>
                  </span>
                </div>
              )}
            </div>
            {!condensed ? (
              <div className={`${styles.taskNameWrap} sm`}>
                <div>
                  {!!note?.task && (
                    <>
                      <Link to={`/project/${!!projectId ? projectId : project?.id}/reporting/task/${note?.task?.id}`}>
                        {note?.task?.name}
                      </Link>
                    </>
                  )}
                </div>
                {!!note?.work_day_task_data && (
                  <div className="d-flex align-items-center">
                    <span className={styles.workdayValueWrapper}>
                      Qty: <span className={styles.workdayValue}>{note?.work_day_task_data?.quantity}</span>
                      <span className="ms-1">
                        {
                          config?.units_of_measure?.find((uom) => uom?.id === note?.work_day_task_data?.uom_id)
                            ?.unit_measure
                        }
                      </span>
                    </span>
                    <span className={styles.workdayValueWrapper}>
                      Hours:
                      <span className={styles.workdayValue}>{note?.work_day_task_data?.hours}</span>
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <>
                {!!note.note_date && (
                  <div className="d-flex align-items-center">
                    <div className="me-3">{displayDate(note?.note_date)}</div>
                  </div>
                )}
                <div className={styles.createdDate}>
                  <div className="me-3 lh-1">{displayDate(note?.created_at)}</div>
                  <div className={styles.time}>{DateTime.fromSQL(note?.created_at).toFormat("hh:mm a")}</div>
                </div>
              </>
            )}
          </div>
        </header>
        <div className={styles.notesBody}>
          <div className="w-100">
            <div className={`${!!note?.files?.length && showPhoto ? styles.hasImages : styles.noImages} h-100`}>
              <div
                className={
                  !!note.title ? styles.deletedTimesheet : !!condensed ? styles.noteContent : styles.noteContentBg
                }
              >
                {!!note?.title && <AlertCircle size={18} className={styles.iconDeleted} />}
                <span className={`${styles.content} ${!!condensed ? styles.condensedContent : ""}`}>
                  {note.comment}
                </span>
              </div>
            </div>
          </div>
          {!condensed && (
            <>
              {!!photos.length && showPhoto && (
                <div
                  className={`${styles.imageGroupWrapper} ${photos?.length > 1 && styles.large}`}
                  onClick={() => {
                    setPhotoModalOpen(true);
                    setPhotos(photos);
                  }}
                >
                  <NoteImages images={photos} />
                </div>
              )}
            </>
          )}
        </div>
        {!condensed && (
          <>
            {!!photos?.length && showPhoto && (
              <div className={styles.photosRow}>
                {photos.map((photo, index) => (
                  <img
                    src={photo?.file?.small_thumbnail || photo?.file?.abs_url}
                    alt={"Note"}
                    className={styles.printImage}
                    key={index}
                    loading="lazy"
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

ProjectNote.propTypes = {
  note: PropTypes.object,
  photos: PropTypes.array,
  setPhotos: PropTypes.func,
  setPhotoModalOpen: PropTypes.func,
  deleteNote: PropTypes.func,
};

export default ProjectNote;
