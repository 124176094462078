import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FilterSearch, DateRangeSelector } from "component-library";
import { Search } from "react-feather";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const PermitsListingHeader = ({
  currentPage,
  itemsPerPage,
  permits,
  setPaginatedPermits,
  setSearchQuery,
  setExpirationEndDate,
  setExpirationStartDate,
  expirationEndDate,
  expirationStartDate,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const [showSearch, setShowSearch] = useState(false);
  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [expirationRange, setExpirationRange] = useState([expirationStartDate, expirationEndDate]);

  const currentFirstItem = permits.length > 0 ? (currentPage - 1) * itemsPerPage + 1 : 0;
  const currentLastItem = permits.length < itemsPerPage ? permits.length : currentPage * itemsPerPage;
  const totalItems = !!permits.length ? permits.length : 0;

  useEffect(() => {
    if (!!dateRange && dateRange.length > 1) {
      setStartDate(dateRange[0]);
      setEndDate(dateRange[1]);
    } else {
      setStartDate();
      setEndDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    if (!!expirationRange && expirationRange.length > 1) {
      setExpirationStartDate(expirationRange[0]);
      setExpirationEndDate(expirationRange[1]);
    } else {
      setExpirationStartDate();
      setExpirationEndDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expirationRange]);

  return (
    <>
      <div className="d-md-flex justify-content-end mb-2 d-block">
        <div className="d-flex">
          <div className="d-flex align-self-end align-items-center">
            <span className="pb-1">
              {currentFirstItem} - {currentLastItem} of {totalItems}
            </span>
            <Button
              className="pt-0 pb-0"
              variant="link"
              onClick={() => {
                setPaginatedPermits(permits);
                setDateRange([]);
                setExpirationRange([]);
              }}
            >
              Show All
            </Button>
          </div>
          <div className="mx-2">
            <h2 className={`${styles.dateTitle} sm mb-0 light-text`}>Issue Date</h2>
            <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
          </div>
          <div className="mx-2">
            <h2 className={`${styles.dateTitle} sm sm mb-0 light-text`}>Expiration</h2>
            <DateRangeSelector dateRange={expirationRange} setDateRange={setExpirationRange} />
          </div>
          <div className="d-flex align-self-end">
            {!!showSearch ? (
              <FilterSearch
                searchFunction={setSearchQuery}
                placeholder="Filter permits"
                loading={false}
                inline
                close={() => {
                  setSearchQuery("");
                  setShowSearch(false);
                }}
              />
            ) : (
              <Button variant="light" onClick={() => setShowSearch(!showSearch)} aria-label="search">
                <Search size={14} color="#7b7b7b" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

PermitsListingHeader.propTypes = {
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  permits: PropTypes.array.isRequired,
  setPaginatedPermits: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  setExpirationEndDate: PropTypes.func.isRequired,
  setExpirationStartDate: PropTypes.func.isRequired,
  expirationEndDate: PropTypes.any,
  expirationStartDate: PropTypes.any,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
};

export default PermitsListingHeader;
