import TimesheetId from "../../../sharedColumns/columnRenders/TimesheetId";

const id = {
  headerName: "ID",
  field: "id",
  width: 80,
  cellRenderer: TimesheetId,
};

export default id;
