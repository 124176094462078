import React from "react";
import GoogleMaps from "./GoogleMaps";
import TimeZone from "./TimeZone/TimeZone";
import useAppSettings from "./useAppSettings";
import InteriorPage from "../../../../../components/pageLayout/InteriorPage";
import Tabs from "../../Tabs";
import AdminIcon from "../../../../../icons/AdminIcon";
import { Alert } from "react-bootstrap";
import useSuperAdmin from "../../context/useSuperAdmin";
import AccentColor from "./AccentColor";
import HeaderPortal from "../../../../../components/HeaderPortal";

const AppSettings = () => {
  const { updateLoading, updateError, tenantSettingsLoading, tenantSettingsError } = useSuperAdmin();

  const { successMessage, setSuccessMessage, handleSubmit, SaveButton, renderSelectField, renderTextField } =
    useAppSettings();

  return (
    <InteriorPage
      pageTitle="Super Admin Settings"
      navTitle="Admin"
      pageSubtitlePrimaryContent={Tabs}
      navIcon={<AdminIcon color="#fff" size={14} />}
      loading={updateLoading || tenantSettingsLoading}
    >
      {(!!tenantSettingsError || !!updateError) && <Alert variant="danger">{updateError || tenantSettingsError}</Alert>}
      {!!successMessage && (
        <Alert variant="success" dismissible onClick={() => setSuccessMessage()}>
          {successMessage}
        </Alert>
      )}

      <form id="saveSuperAdminSettings" onSubmit={handleSubmit}>
        <div className="mb-5">
          <TimeZone renderSelectField={renderSelectField} />
          <AccentColor renderTextField={renderTextField} />
        </div>
        <h2>Api Keys</h2>
        <GoogleMaps renderTextField={renderTextField} />
        <HeaderPortal>
          <SaveButton />
        </HeaderPortal>
      </form>
    </InteriorPage>
  );
};

export default AppSettings;
