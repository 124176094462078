import React from "react";
import { NavLink, useParams, useLocation } from "react-router-dom";
import useBid from "../../_bidManagement/hooks/useBid";

const BidLinks = () => {
  const { id } = useParams();
  const { bid } = useBid();
  const { pathname } = useLocation();

  return (
    <div className="subNav">
      {!!id && (
        <>
          <NavLink
            className={`tertiaryNavTitleLink ${
              pathname.includes("/bid") && !pathname.includes("rfqs") ? "active" : ""
            }`}
            to={`/bid/${id}`}
            end
          >
            {!!bid ? bid.name : "Bid"}
          </NavLink>
          {/* {portalModule?.active === 1 && (
            <NavLink className="tertiaryNavLink" to={`/bid/${id}/rfqs`}>
              Quotes
            </NavLink>
          )} */}
        </>
      )}
    </div>
  );
};

export default BidLinks;
