import React from 'react';
import { Mail, Phone, Smartphone } from 'react-feather';
import styles from '../styles/CompanyContact.module.scss';
import CompanyContactNav from './CompanyContactNav';

const CompanyContact = ({
  setCompany,
  location,
  contact,
  setEditingContact,
  setArchivingContact,
  setArchiveContact,
  contact: {
    contact: {
      first_name,
      last_name,
      title,
      email,
      phone,
      cell_phone,
    }
  }
}) => {
  return (
    <div className={styles.contactWrap}>
      {!!setEditingContact && (
        <CompanyContactNav
          setCompany={setCompany}
          contact={contact}
          setEditingContact={() => setEditingContact(contact.contact)}
          setArchivingContact={setArchivingContact}
          setArchiveContact={() => setArchiveContact(contact.contact)}
          archived={contact.contact.archived}
        />
      )}
      <div>
        <h2
          className={`${styles.contactName} ${!!contact.contact.archived ? "text-gray3" : ""} med mb-0`}
        >{`${first_name} ${last_name}`}</h2>
      </div>
      <div className={`mb-2 sm ${styles.title}`}>{title}</div>
      <div className="font-weight-normal sm text-uppercase text-gray3">{location.name}</div>
      {!!contact.contact.archived ? (
        <div className="sm text-gray3 ms-1 mb-2 mt-2">(archived)</div>
      ) : (
        <div className="sm text-gray3 text-uppercase">
          {!!location.address.address1 && <div>{location.address.address1}</div>}
          {!!location.address.address2 && <div>{location.address.address2}</div>}
          <div>{`${location.address.city ? `${location.address.city}, ` : ""} ${location.address.state} ${
            location.address.zip
          }`}</div>
        </div>
      )}

      <div>
        {email && (
          <>
            {!!contact.contact.archived ? (
              <div className="phone archived">
                <Mail className="phoneIcon" size="9" />
                {email}
              </div>
            ) : (
              <>
                <a className="btn-link phone" href={`mailto:${email}`}>
                  <Mail className="phoneIcon" size="9" />
                  {email}
                </a>
              </>
            )}
          </>
        )}
      </div>
      <div>
        {phone && (
          <>
            {!!contact.contact.archived ? (
              <div className="phone archived">
                <Phone className="phoneIcon" size="9" />
                {phone}
              </div>
            ) : (
              <a className="btn-link phone" href={`mailto:${phone}`}>
                <Phone className="phoneIcon" size="9" />
                {phone}
              </a>
            )}
          </>
        )}
      </div>
      <div>
        {cell_phone && (
          <>
            {!!contact.archived ? (
              <div className="phone archived">
                <Smartphone className="phoneIcon" size="9" />
                {cell_phone}
              </div>
            ) : (
              <a className="btn-link phone" href={`mailto:${cell_phone}`}>
                <Smartphone className="phoneIcon" size="9" />
                {cell_phone}
              </a>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default CompanyContact;