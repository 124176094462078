import React from "react";
import { SortableTable, Loader, displayDate } from "component-library";
import rfqsListPrint from "./rfqsListPrint";
import useRFQs from "./useRFQs";
import { useParams, Link } from "react-router-dom";
import useBid from "../hooks/useBid";
import BidInformation from "./BidInformation";

const statusSortOrder = [2, 1, 0, 3, 4];

const PrintableRFQs = () => {
  const { id } = useParams();
  const { rfqs, loading } = useRFQs();
  const { bid, estimatorName } = useBid();

  const formatDate = (date) => {
    return displayDate(date);
  };

  document.title = !!bid ? `RFQs for ${bid.name}` : "RFQs";

  return (
    <div className="p-3">
      {!loading ? (
        <>
          {!!bid && <BidInformation bid={bid} estimatorName={estimatorName} formatDate={formatDate} />}
          {!!rfqs && !!rfqs.length ? (
            <SortableTable
              items={rfqs.map((rfq) => ({
                ...rfq,
                supplier_name: !!rfq.company ? rfq.company.name : "",
                email: !!rfq.contact ? rfq.contact.email : "",
                quote_id: (!!rfq.quote && rfq.quote.id) || "N/A",
                quote_amount: !!rfq.quote && rfq.quote.amount,
                status_sort: !!rfq.status && statusSortOrder[rfq.status.id],
                sent: !!rfq && rfq.date_sent,
              }))}
              config={rfqsListPrint}
              fallbackSort="status_sort"
            />
          ) : (
            <div className="d-flex justify-content-center align-items-center flex-column mt-5">
              <p>An RFQ has not been generated for this bid</p>
              <Link to={`/bid/${id}/rfqs/add`} className="btn btn-tertiary btn-lg">
                Generate RFQ
              </Link>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default PrintableRFQs;
