import HoursRepErnd from "../columnRenders/HoursRepErnd";

const hoursRepEarned = {
  headerName: "Hours Rep/Ernd",
  field: "reported_hours",
  cellRenderer: HoursRepErnd,
  width: 175,
  headerClass: "ag-right-aligned-header",
};

export default hoursRepEarned;
