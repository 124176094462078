import React from "react";
import Alert from "../../../components/Alert";
import styles from "./styles.module.scss";

const ProjectAlert = ({ alert, dismiss }) => {
  const data = !!alert.data && JSON.parse(alert.data);
  let url;
  let actionText;
  if (!!data && data.type) {
    switch (data.type) {
      case "field_log":
        url = `/project/${alert.project_id}/field-management/field-logs/${data.workday_id}/foreman/${data.foreman_id}`;
        actionText = "Review Now"
        break;

      default:
        break;
    }
  }
  return (
    <div className={styles.projectAlert}>
      <Alert
        variant="secondary"
        title={alert.title}
        message={alert.body}
        linkText={actionText}
        linkTo={url}
        shadow
        dismissable={false}
        dismiss={dismiss}
      />
    </div>
  );
};

export default ProjectAlert;
