import React from "react";
import { Calendar } from "react-feather";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import { displayDate } from "component-library";
import CustomScroll from "react-custom-scroll";

const Milestone = ({ item }) => {
  return (
    <div className={styles.milestone}>
      {!!item && (
        <>
          <Calendar size={18} />
          <div className={styles.date}>{displayDate(item?.completion_date)}</div>
          <div>
            <CustomScroll heightRelativeToParent="100%">
              <div className={styles.description}>{item?.title}</div>
            </CustomScroll>
          </div>
        </>
      )}
    </div>
  );
};

Milestone.propTypes = {
  key: PropTypes.number,
  item: PropTypes.object,
};

export default Milestone;
