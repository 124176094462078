import { useState, useEffect } from "react";
import { useApi, taskDetailApi } from "component-library";
import { useParams } from "react-router-dom";

const useTaskDetailNotes = () => {
  const { id, taskId } = useParams();
  const [notes, setNotes] = useState([]);

  const {
    request: notesRequest,
    data: notesData,
    loading: notesLoading,
    error: notesError,
  } = useApi(taskDetailApi.getTaskNotes);

  useEffect(() => {
    if (taskId) {
      notesRequest({ projectId: id, taskId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  useEffect(() => {
    setNotes(notesData?.notes || []);
  }, [notesData]);

  return {
    notes,
    notesLoading,
    notesError,
  };
};

export default useTaskDetailNotes;
