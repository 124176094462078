import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useAddSubmittal from "../../hooks/useAddSubmittal";
import SubmittalSidebar from "../../SubmittalSidebar";

const SubmittalEditForm = ({ submittalData }) => {
  const { hash } = useLocation();

  const { fields, handleSubmit, data, setFields } = useAddSubmittal(submittalData);

  useEffect(() => {
    if (hash) {
      const e = document.getElementById(hash.substring(1));
      if (e) e.scrollIntoView();
    }
  }, [hash]);

  return (
    <div className="row">
      <div className="col-lg-9 pe-lg-4 edit-submittal">
        <form onSubmit={handleSubmit} id="edit-submittal-form">
          {fields}
        </form>
      </div>
      <div className="col-lg-3 submittal-sidebar ps-lg-4">
        <SubmittalSidebar submittal={data} setSubmittal={setFields} isEdit={true} />
      </div>
    </div>
  );
};

export default SubmittalEditForm;
