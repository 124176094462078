import React from "react";
import { dropdownStyles, states } from "component-library";
import { Button } from "react-bootstrap";
import { Plus, XCircle } from "react-feather";
import Select from "react-select";
import InputMask from "react-input-mask";
import { Alert } from "react-bootstrap";
import regexPhone from "../../utils/regexPhone";

const CompanyLocationFields = ({
  data,
  locations,
  setFields,
  updateLocations,
  singleLocation,
  error,
}) => {

  const filterOption = (option, inputValue) => {
    const { label, value } = option;
    return (
      value.includes(inputValue.toUpperCase()) ||
      label.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  };

  return (
    <>
      <div className="mb-3">
        <h6 className="mt-3 lg">Company Locations</h6>
        {!!error && !!error["locations"] && (
          <Alert variant="danger">{error["locations"] && "Required Location data is missing."}</Alert>
        )}
        <div>
          {!!locations &&
            locations.map((loc, index) => {
              return (
                <div className="border-bottom border-bottom-gray3 mb-3 pb-3" key={index}>
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="med" htmlFor={`name-${index}`}>
                        Location Name
                      </label>
                      <div className={`${!!error && !!error["locations"] && !loc.name ? "error" : ""} form-group`}>
                        <input
                          className="form-control"
                          onChange={({ target: { value } }) => updateLocations(loc, value, "name")}
                          id={`name-${index}`}
                          name="name"
                          value={!!loc && !!loc.name ? loc.name : ""}
                          autoComplete="nope"
                        />
                        {!!error && !!error["locations"] && !loc.name && (
                          <span className="help-block is-error">"Location Name" is required</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-3 med">Address</h6>
                  <div className="row">
                    <div
                      className={`${
                        !!error && !!error["locations"] && (!loc.address || !loc.address.address1) ? "error" : ""
                      } form-group col-lg-12`}
                    >
                      <input
                        onChange={({ target: { value } }) => updateLocations(loc, value, "address1")}
                        id={`address1-${index}`}
                        className="form-control"
                        name="address1"
                        value={!!loc.address ? loc.address.address1 : ""}
                        placeholder="Address 1"
                        autoComplete="nope"
                      />
                      {!!error && !!error["locations"] && (!loc.address || !loc.address.address1) && (
                        <span className="help-block is-error">"Address" is required</span>
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-lg-12 form-group">
                      <input
                        onChange={({ target: { value } }) => updateLocations(loc, value, "address2")}
                        id={`address2-${index}`}
                        className="form-control"
                        name="address2"
                        value={!!loc.address ? loc.address.address2 : ""}
                        placeholder="Address 2"
                        autoComplete="nope"
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div
                      className={`${
                        !!error && !!error["locations"] && (!loc.address || !loc.address.city) ? "error" : ""
                      } form-group col-lg-4`}
                    >
                      <input
                        onChange={({ target: { value } }) => updateLocations(loc, value, "city")}
                        id={`city-${index}`}
                        className="form-control"
                        name="city"
                        value={!!loc.address ? loc.address.city : ""}
                        placeholder="City"
                        autoComplete="nope"
                      />
                      {!!error && !!error["locations"] && (!loc.address || !loc.address.city) && (
                        <span className="help-block is-error">"City" is required</span>
                      )}
                    </div>
                    <div
                      className={`${
                        !!error && !!error["locations"] && (!loc.address || !loc.address.state) ? "error" : ""
                      } form-group col-lg-3`}
                    >
                      <Select
                        name={`status-${loc.id}`}
                        className="w-100"
                        id={`status-${loc.id}`}
                        onChange={(value) => {
                          updateLocations(loc, value.value, "state");
                        }}
                        options={states}
                        value={
                          !!loc && !!loc.address && !!loc.address.state ? locations.find((l) => l.value === loc.id) : ""
                        }
                        styles={dropdownStyles}
                        isSearchable={true}
                        filterOption={filterOption}
                        aria-label="State dropdown list"
                      />
                      {!!error && !!error["locations"] && (!loc.address || !loc.address.state) && (
                        <span className="help-block is-error">"State" is required</span>
                      )}
                    </div>
                    <div
                      className={`${
                        !!error && !!error["locations"] && (!loc.address || !loc.address.zip) ? "error" : ""
                      } form-group col-lg-3`}
                    >
                      <input
                        onChange={({ target: { value } }) => updateLocations(loc, value, "zip")}
                        id={`zip-${index}`}
                        className="form-control"
                        name="zip"
                        value={!!loc.address ? loc.address.zip : ""}
                        placeholder="Zip"
                        autoComplete="nope"
                      />
                      {!!error && !!error["locations"] && (!loc.address || !loc.address.zip) && (
                        <span className="help-block is-error">"ZIP" is required</span>
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="form-group col-lg-6">
                      <label className="med" htmlFor={`name-${index}`}>
                        Phone
                      </label>
                      <InputMask
                        mask="1-(999)-999-9999"
                        value={!!loc && !!loc.phone ? loc.phone : ""}
                        onChange={({ target: { value } }) => updateLocations(loc, value, "phone")}
                      >
                        <input
                          id={`phone-${index}`}
                          className="form-control"
                          name="phone"
                          autoComplete="nope"
                          aria-label="phone number"
                        />
                      </InputMask>
                      {!!error && !!error["locations"] && !!loc.phone && !loc.phone.match(regexPhone) && (
                        <span className="help-block is-error">Phone number must be 10 digits.</span>
                      )}
                    </div>
                  </div>
                  <div className="row pt-1">
                    {index > 0 && (
                      <Button
                        onClick={() =>
                          setFields({
                            ...data,
                            locations: locations.filter((location) => location.id !== loc.id),
                          })
                        }
                        variant="link"
                      >
                        <XCircle size="12" color="#ff0000" className="me-2" />
                        Remove Location
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        {!singleLocation && (
          <div>
            <Button
              className="p-0"
              onClick={() =>
                setFields({
                  ...data,
                  locations: [...locations, { id: locations[locations.length - 1].id + 1 }],
                })
              }
              variant="link"
            >
              Add Location <Plus size="12" />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default CompanyLocationFields;
