import { displayDate } from "component-library";

const last_modified = {
  headerName: "Updated",
  name: "created_at",
  cellStyle: { color: "#4f4f4f" },
  valueGetter: ({ data }) => displayDate(data.versions[data.versions.length - 1]?.created_at),
  width: 100,
};

export default last_modified;
