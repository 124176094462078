const renderDateColumnDefs = (config, gridMonths) => {
  let newColumnDefs = config;
  gridMonths.forEach((month) => {
    const newColumn = {
      headerName: month.month,
      field: month.month,
      type: "numericColumn",
      flex: 1,
      cellStyle: { justifyContent: "flex-end" },
      valueGetter: (params) => {
        return params?.data?.months?.find((dMonth) => dMonth.month === month.month)?.total || "-";
      },
    };
    newColumnDefs = [...newColumnDefs, newColumn];
  });
  return newColumnDefs;
};

export default renderDateColumnDefs;
