import React, { useState } from "react";
import { useFormControl } from "component-library";
import { schema, initialData } from "../../../../schema/register";

const useCreateAccount = ({ registerUser, setRegistrationAlert }) => {
  const [loggedIn] = useState(false);

  const onSubmit = async (formData, setLoading) => {
    try {
      const { success } = await registerUser(formData);
      if (success) {
        setRegistrationAlert(true);
      } else {
        throw new Error();
      }
    } catch (message) {
      throw new Error(message);
    }
    setLoading(false);
  };

  const { handleSubmit, renderSubmitButton, renderTextField } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const fields = (
    <>
      <div className="row">
        <div className="col-md-6">
          {renderTextField({
            name: "first_name",
            label: "First Name",
            autoFocus: true,
          })}
        </div>
        <div className="col-md-6">
          {renderTextField({
            name: "last_name",
            label: "Last Name",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {renderTextField({
            name: "email",
            label: "Email",
            autoFocus: true,
            placeholder: "Email Address",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {renderTextField({
            name: "password",
            label: "Password",
            type: "password",
          })}
        </div>
        <div className="col-md-6">
          {renderTextField({
            name: "password_confirmation",
            label: "Confirm Password",
            type: "password",
          })}
        </div>
      </div>
    </>
  );
  return {
    initialData,
    schema,
    fields,
    handleSubmit,
    renderSubmitButton,
    loggedIn,
  };
};

export default useCreateAccount;
