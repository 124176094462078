import { useEffect, useState } from "react";
import useProject from "../../_projects/hooks/useProject";
import { useApi, submittalsApi } from "component-library";

const useProvideSubmittals = () => {
  const { project, setProject } = useProject();
  const {
    data: updateData,
    error: updateError,
    request: updateRequest,
    loading: updateLoading,
  } = useApi(submittalsApi.updateSubmittal);
  const [submittal, setSubmittal] = useState();
  const [submittals, setSubmittals] = useState([]);

  const handleChangeApproval = (selected, data) => {
    setSubmittal({ ...data, status_id: selected.value });
  };

  const handleUpdate = (submittal) => {
    setSubmittals(submittals.map((item) => (item.id === submittal.id ? submittal : item)));
  };

  useEffect(() => {
    if (!!updateData?.submittal) {
      handleUpdate(updateData.submittal);
      setSubmittal();
      if (project && setProject) {
        setProject({
          ...project,
          submittals: project.submittals.map((sub) =>
            sub.id === updateData.submittal.id ? updateData.submittal : sub
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    if (submittal && submittal.status_id !== 5 && submittal.status_id !== 6 && submittal.status_id !== 7) {
      updateRequest(submittal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittal]);

  useEffect(() => {
    if (project && project.submittals) {
      const submittalsWithSourceName = project.submittals.map((sub) => {
        const source_name = sub.source ? `${sub.source.company}, ${sub.source.first_name} ${sub.source.last_name}` : "";
        return { ...sub, source_name };
      });
      setSubmittals(submittalsWithSourceName);
    }
  }, [project]);

  return {
    submittals,
    setSubmittals,
    submittal,
    setSubmittal,
    handleChangeApproval,
    updateRequest,
    updateData,
    updateError,
    updateLoading,
  };
};

export default useProvideSubmittals;
