import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "../../TaskManagment/styles.module.scss";
import { ChevronDown, ChevronRight } from "react-feather";
import TaskRows from "./TaskRows";

const BidItem = ({ bidItem, toggleSelectedBidItem, selectedTaskBidItems, allSelected, index }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!!allSelected && !!bidItem.tasks.length) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelected]);

  useEffect(() => {
    const taskIds = bidItem.tasks.map((task) => task.id);
    taskIds.forEach((s) => {
      if (selectedTaskBidItems.includes(s)) {
        setOpen(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTaskBidItems]);

  return (
    <>
      <tr key={bidItem.id} className={`${!!open ? styles.openTaskHeader : ""} default`}>
        <td></td>
        <td className="text-center">{index}</td>
        <td>
          <div className="d-flex align-items-center">
            {!!bidItem?.tasks?.length &&
              (open ? (
                <ChevronDown color={"#7b7b7b"} size={15} onClick={() => setOpen(!open)} className="me-1" />
              ) : (
                <ChevronRight color={"#7b7b7b"} size={15} onClick={() => setOpen(!open)} className="me-1" />
              ))}
            <span className={styles.taskName}>{bidItem.name}</span>
          </div>
        </td>
        <td className="text-end px-2"> {bidItem.cost_code || "-"} </td>
      </tr>
      {!!open && !!bidItem && !!bidItem.tasks.length && (
        <TaskRows
          toggleSelectedBidItem={toggleSelectedBidItem}
          selectedTaskBidItems={selectedTaskBidItems}
          bidItem={bidItem}
        />
      )}
    </>
  );
};

BidItem.propTypes = {
  bidItem: PropTypes.object.isRequired,
  toggleSelectedBidItem: PropTypes.func,
  selectedTaskBidItems: PropTypes.array,
  allSelected: PropTypes.number,
};

export default BidItem;
