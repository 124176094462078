import { useEffect, useState } from 'react';
import useProject from "../../hooks/useProject";
import { DateTime } from "luxon";

const useUpcomingMilestones = () => {
  const { project } = useProject();
  const [upcomingMilestones, setUpcomingMilestones] = useState([]);
  const today = DateTime.now();

  useEffect(() => {
    if (!!project && !!project.milestones && !!project.milestones.length) {
      const upcoming = project.milestones.filter(
        (m) =>
          DateTime.fromISO(m.early_start_date) > today ||
          DateTime.fromISO(m.late_start_date) > today ||
          DateTime.fromISO(m.completion_date) > today
      );
      const sorted = upcoming.sort(
        (a, b) =>
          DateTime.fromISO(a.early_start_date) -
          DateTime.fromISO(b.early_start_date)
      );
      setUpcomingMilestones(sorted.slice(0, 4));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return upcomingMilestones;
}

export default useUpcomingMilestones;