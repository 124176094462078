import { HeaderSelection } from "component-library";
import React, { useState, useEffect } from "react";
import { useApi, rfqsApi } from "component-library";

const useVendorSelection = () => {
  const [showHeaderSelection, setShowHeaderSelection] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedVendorIds, setSelectedVendorIds] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [paginatedVendors, setPaginatedVendors] = useState([]);
  const [q, setQ] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  const {
    loading: vendorsLoading,
    error: vendorsError,
    request: vendorsRequest,
    data: vendorsData,
  } = useApi(rfqsApi.getVendors);

  useEffect(() => {
    if (!!vendorsData?.vendors?.length) {
      setVendors(vendorsData.vendors);
    } else {
      setVendors([]);
    }
  }, [vendorsData]);

  useEffect(() => {
    setPaginatedVendors(vendors.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
  }, [currentPage, vendors]);

  const updateCategory = (newCat) => {
    let categories = [...selectedCategories, newCat];
    if (selectedCategories.includes(newCat)) {
      categories = selectedCategories.filter((id) => id !== newCat);
    }
    vendorsRequest({
      category_ids: categories,
      certification_ids: selectedCertifications,
      q,
    });
    setSelectedCategories(categories);
  };

  const updateCertification = (newCert) => {
    let certifications = [...selectedCertifications, newCert];
    if (selectedCertifications.includes(newCert)) {
      certifications = selectedCertifications.filter((id) => id !== newCert);
    }
    vendorsRequest({
      category_ids: selectedCategories,
      certification_ids: certifications,
      q,
    });

    setSelectedCertifications(certifications);
  };

  const searchVendors = (query) => {
    vendorsRequest({
      category_ids: selectedCategories,
      certification_ids: selectedCertifications,
      q: query,
    });
  };

  const toggleVendorSelection = (vendorId) => {
    if (selectedVendorIds.includes(vendorId)) {
      setSelectedVendorIds(selectedVendorIds.filter((id) => id !== vendorId));
      setSelectedVendors(selectedVendors.filter((v) => v.id !== vendorId));
    } else {
      const newVendor = vendors.find((v) => v.id === vendorId);
      setSelectedVendorIds([...selectedVendorIds, vendorId]);
      setSelectedVendors([...selectedVendors, newVendor]);
    }
  };

  const VendorSelector = () => (
    <HeaderSelection
      selectedItems={selectedVendors}
      toggleSelection={toggleVendorSelection}
      selectedIds={selectedVendorIds}
      showHeaderSelection={showHeaderSelection}
      setShowHeaderSelection={setShowHeaderSelection}
      header="Vendor Selection"
      itemName="Vendors"
      RenderLabel={({ item }) => (
        <>
          <span className="semibold pe-2">{!!item.company && item.company.name}</span>
          <span>{!!item.contact && `${item.contact.first_name} ${item.contact.last_name}`}</span>
        </>
      )}
    />
  );

  return {
    VendorSelector,
    paginatedVendors,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    vendors,
    setVendors,
    vendorsRequest,
    vendorsLoading,
    vendorsError,
    searchVendors,
    setQ,
    updateCategory,
    updateCertification,
    selectedCategories,
    selectedCertifications,
    toggleVendorSelection,
    selectedVendorIds,
    setSelectedVendorIds,
    selectedVendors,
    setSelectedVendors,
  };
};

export default useVendorSelection;
