import React, { useState, useEffect } from "react";
import { Avatar } from "component-library";
import { ArrowRightCircle, ArrowLeftCircle } from "react-feather";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import InterfaceIcon from "../../../../../../components/InterfaceIcon";
import convertMinutesToTime from "../../../../../../utils/convertMinutesToTime";
import formatTimesheetTime from "../../../../../../utils/formatTimesheetTime";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const CrewMember = ({ index, crewMember }) => {
  const [timesheets, setTimesheets] = useState();
  const { id } = useParams();

  useEffect(() => {
    if (!!crewMember?.user?.time_sheets?.length) {
      setTimesheets(crewMember?.user?.time_sheets);
    } else {
      setTimesheets();
    }
  }, [crewMember?.user?.time_sheets]);

  return (
    <div key={index} className={styles.crewMember}>
      <div className={styles.details}>
        <div className={`${styles.name}`}>
          <div className="me-3">
            <Avatar user={crewMember?.user} />
          </div>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-link med text-limiter font-weight-bold"
            to={`/project/${id}/field-management/crew-log/${crewMember?.user_id}`}
          >{`${crewMember?.user?.first_name} ${crewMember?.user?.last_name}`}</Link>
        </div>
        {!!timesheets?.length &&
          timesheets.map((sheet, index) => (
            <div key={index} className={styles.sheetWrapper}>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center me-3">
                  <ArrowRightCircle className="me-1" color="#259FAD" size={16} />
                  {!!sheet && formatTimesheetTime(sheet?.time_in)}
                  {!!sheet && sheet?.time_in && (
                    <div className="me-1">
                      <InterfaceIcon
                        isWithinGeofence={sheet?.within_geofence}
                        interfaceUsed={sheet?.interface}
                        size={16}
                      />
                    </div>
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <ArrowLeftCircle className="me-1" color="#FF6700" size={16} />
                  {!!sheet && formatTimesheetTime(sheet?.time_out)}
                  {!!sheet && sheet?.time_out && (
                    <div className="me-1">
                      <InterfaceIcon
                        isWithinGeofence={sheet?.within_geofence_clock_out}
                        interfaceUsed={sheet?.interface_clock_out}
                        size={16}
                      />
                    </div>
                  )}
                </div>
              </div>
              {timesheets.length > 1 && (
                <div className="d-flex align-items-center">
                  <span className={`${styles.hours} ${styles.small}`}>
                    {convertMinutesToTime(parseInt(sheet.total_minutes))}
                  </span>
                </div>
              )}
            </div>
          ))}
      </div>
      <div className="d-flex align-items-center">
        {timesheets?.length === 1 && (
          <>
            <span className={styles.hours}>{convertMinutesToTime(parseInt(timesheets[0].total_minutes))}</span>
          </>
        )}
      </div>
    </div>
  );
};

CrewMember.propTypes = {
  crewMember: PropTypes.object,
};

export default CrewMember;
