import React from 'react';
import PropTypes from "prop-types";

const SpecAndPlansLocation = ({ renderTextField }) => {
  return (
    <div className="row py-2">
      <div className="col-12">
        <h1 className="form-section-header">Spec Book and Plans</h1>
      </div>
      <div className="col-6 py-2">
        {renderTextField({
          name: "specs_location",
          label: "Link to Spec Book",
          subLabel: "Paste a link to the spec book here",
          autoFocus: true,
        })}
      </div>
      <div className="col-6 py-2">
        {renderTextField({
          name: "plans_location",
          label: "Link to Plans",
          subLabel: "Paste a link to the plans here",
        })}
      </div>
    </div>
  );
}

export default SpecAndPlansLocation;

SpecAndPlansLocation.propTypes = {
  renderTextField: PropTypes.func.isRequired,
};