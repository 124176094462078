import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "../../../components/PrivateRoute";
import FieldLogs from "./FieldLogs/FieldLogs";
import FieldLog from "./FieldLogs/FieldLog";
import ProvideResourceManagement from "../../../_resourceManagement/context/ProvideResourceManagement";
import ProvideFieldLogSelection from "../../../_resourceManagement/context/ProvideFieldLogSelection";
import FormDetail from "./FormDetail";
import FieldForms from "./FieldLogs/FieldForms";
import Unauthorized from "../../../components/pages/Unauthorized";
import FieldNotes from "./FieldLogs/FieldNotes";
import WeeklyReportsList from "./FieldLogs/WeeklyReports/WeeklyReportsList";
import WeeklyReportEdit from "./FieldLogs/WeeklyReports/WeeklyReportEdit";
import WeeklyReportReview from "./FieldLogs/WeeklyReports/WeeklyReportReview";
import TimesheetLogs from "./FieldLogs/TimesheetLogs";
import CrewLogDetail from "./FieldLogs/CrewLogDetail";
import TimesheetDetail from "./TimesheetDetail";
import DailyFieldLogPage from "./FieldLogs/DailyFieldLog/DailyFieldLogsPage";

const FieldManagementRoutes = () => {
  return (
    <>
      <ProvideResourceManagement>
        <ProvideFieldLogSelection>
          <Routes>
            <Route index element={<Navigate to="dashboard" />} />
            <Route path="dashboard" element={<PrivateRoute Component={<DailyFieldLogPage />} />} />
            <Route path="field-logs" element={<PrivateRoute Component={<FieldLogs />} />} />
            <Route path="timesheet-logs" element={<PrivateRoute Component={<TimesheetLogs />} />} />
            <Route
              path="field-logs/:fieldLogId/foreman/:foremanId"
              element={<PrivateRoute Component={<FieldLog />} />}
            />
            <Route path="field-forms" element={<PrivateRoute Component={<FieldForms />} />} />
            <Route path="field-notes" element={<PrivateRoute Component={<FieldNotes />} />} />
            <Route path="crew-log/:crewId" element={<PrivateRoute Component={<CrewLogDetail />} />} />
            <Route path="field-form/:submissionId" element={<PrivateRoute Component={<FormDetail />} />} />
            <Route path="weekly-reports" element={<PrivateRoute Component={<WeeklyReportsList />} />} />
            <Route path="weekly-reports/:report_id/edit" element={<PrivateRoute Component={<WeeklyReportEdit />} />} />
            <Route
              path="weekly-reports/:report_id/review"
              element={<PrivateRoute Component={<WeeklyReportReview />} />}
            />
            <Route
              path="timesheet/:timesheetId/foreman/:foremanId"
              element={<PrivateRoute module="TimeTracking" Component={<TimesheetDetail />} />}
            />
            <Route
              path="timesheet/:timesheetId"
              element={<PrivateRoute module="TimeTracking" Component={<TimesheetDetail />} />}
            />
            <Route path="*" element={<Unauthorized />} />
          </Routes>
        </ProvideFieldLogSelection>
      </ProvideResourceManagement>
    </>
  );
};

export default FieldManagementRoutes;
