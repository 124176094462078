import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import BidItems from "./TaskManagment/BidItems";
import { ConfirmModal, Loader } from "component-library";
import Takeoffs from "./Takeoffs";
import Popup from "./TaskManagment/Popup";
import EditPhases from "./TaskManagment/Phases/EditPhases";
import { useParams } from "react-router";
import ModalResetPhases from "./TaskManagment/Phases/ModalResetPhases";
import UPBUpload from "../PreconChecklist/UPBUpload/UPBUpload";
import Pagination from "../../../components/Pagination";
import TaskSearch from "./TaskManagment/TaskSearch";
import styles from "./styles.module.scss";
import useEstimateConversion from "./useEstimateConversion";
import useProject from "../../hooks/useProject";
import { XCircle, PlusCircle } from "react-feather";
import AddTaskForm from "./TaskManagment/AddTaskForm";
import AddBidItemForm from "./TaskManagment/AddBidItemForm";
import MoveTaskForm from "./TaskManagment/MoveTaskForm/MoveTaskForm";

const EstimateConversion = ({ setAddingTask, addingTask, setAddingBidItem, addingBidItem }) => {
  const { project, config } = useProject();

  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState("");

  useEffect(() => {
    if (!!config && !!config.phases.length) {
      if (config.phases.length === 1) {
        setPhases(config.phases);
      } else {
        const sortedPhases = config.phases.sort((a, b) => {
          return new Date(a.start_date) - new Date(b.start_date);
        });
        setPhases(sortedPhases);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const params = useParams();

  const {
    upbData,
    upbLoading,
    upbRequest,
    upbError,
    updateData,
    updateLoading,
    updateError,
    resetData,
    resetLoading,
    resetError,
    resetRequest,
    syncRequest,
    syncLoading,
    syncError,
    syncData,
    viewTasks,
    setViewTasks,
    setEditTasks,
    editPhases,
    setFilteredBidItems,
    bidItems,
    setBidItems,
    setPhases,
    resetPhasesConfirmation,
    setResetPhasesConfirmation,
    setCurrentPage,
    currentPage,
    itemsPerPage,
    filter,
    setFilter,
    importable,
    // setImportable,
    setTotalResults,
    totalResults,
    refreshingEstimate,
    setRefreshingEstimate,
    canEdit,
    tasksDeactivatedConfirm,
    setTasksDeactivatedConfirm,
    submitEdit,
    editTasks,
    setViewPhases,
    setDeactivatedTasks,
    movingTask,
    viewAll,
    setOpenTasks,
    getAllItemIds,
  } = useEstimateConversion();

  const syncPhases = () => {
    syncRequest({ id: project.id, page: currentPage, items_per_page: itemsPerPage });
  };

  const resetPhases = () => {
    resetRequest({ id: project.id, page: currentPage, items_per_page: itemsPerPage });
  };

  const refreshUpbItems = () => {
    upbRequest({ q: filter, id: project.id, page: currentPage, items_per_page: itemsPerPage });
  };

  useEffect(() => {
    if (!!resetData?.items?.data?.length) {
      setBidItems(resetData.items.data);
      setFilteredBidItems(resetData.items.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetData]);

  useEffect(() => {
    if (!!upbData) {
      setBidItems(upbData.upb_items.data);
      setFilteredBidItems(upbData.upb_items.data);
      if (upbData.upb_items.total !== totalResults) {
        setTotalResults(upbData.upb_items.total);
      }
      setCurrentPage(upbData.upb_items.current_page);
      // setImportable(upbData.importable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upbData]);

  useEffect(() => {
    if (!!syncData?.items?.data?.length) {
      setBidItems(syncData.items.data);
      setFilteredBidItems(syncData.items.data);
      setCurrentPage(syncData.items.current_page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncData]);

  useEffect(() => {
    if (!!updateData) {
      setEditTasks(false);
      setBidItems(updateData.upb_items);
      setFilteredBidItems(updateData.upb_items);
      if (!!viewAll) {
        setOpenTasks(getAllItemIds(updateData.upb_items));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    if (params.view === "takeoffs") {
      setViewTasks(false);
      setTotalResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!viewTasks) {
      setCurrentPage(1);
      setFilter("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewTasks]);

  useEffect(() => {
    if (!!project && !!project.id) {
      upbRequest({
        q: filter,
        id: project.id,
        page: 1,
        items_per_page: itemsPerPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (!!alert) {
      setRefreshingEstimate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert]);

  return (
    <>
      {!!alert && !!alert.length && (
        <Alert onClick={() => setAlert("")} dismissible variant="success">
          {alert}
        </Alert>
      )}
      {!refreshingEstimate && parseInt(project?.upb_import_status) === 1 ? (
        <>
          {!!upbError && <Alert variant="danger">{upbError}</Alert>}
          {!!updateError && <Alert variant="danger">{updateError}</Alert>}
          {!!resetError && <Alert variant="danger">{resetError}</Alert>}
          {!!syncError && <Alert variant="danger">{syncError}</Alert>}
          <Popup message={message} setMessage={setMessage} />
          {!!viewTasks ? (
            <>
              <div className={`${styles.searchWrapper} ${!importable ? styles.notImportable : ""} `}>
                {!!importable && (
                  <div className="d-flex align-items-center">
                    <Button
                      variant="link"
                      className="med d-flex align-items-center ps-0"
                      disabled={!canEdit}
                      onClick={() => setAddingTask(true)}
                    >
                      <PlusCircle size="16" color={!canEdit ? "#6c757d" : "#0071ce"} className="me-2" />
                      Add Task
                    </Button>
                    <Button
                      variant="link"
                      className="med d-flex align-items-center"
                      disabled={!canEdit}
                      onClick={() => setAddingBidItem(true)}
                    >
                      <PlusCircle size="16" color={!canEdit ? "#6c757d" : "#0071ce"} className="me-2" />
                      Add Bid Item
                    </Button>
                  </div>
                )}
                <TaskSearch />
              </div>
              <div className="d-flex justify-content-between">
                {resetPhasesConfirmation && (
                  <ModalResetPhases resetPhases={resetPhases} setResetPhasesConfirmation={setResetPhasesConfirmation} />
                )}
                {editPhases && (
                  <EditPhases
                    project={project}
                    setMessage={setMessage}
                    refreshUpbItems={refreshUpbItems}
                    syncPhases={syncPhases}
                  />
                )}
              </div>

              {!!upbLoading || !!updateLoading || !!resetLoading || !!syncLoading ? (
                <Loader loading size={14} />
              ) : (
                <BidItems />
              )}
              {!syncLoading && !upbLoading && !resetLoading && !updateLoading && !!viewTasks && (
                <Pagination
                  items={bidItems}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  total={totalResults}
                  disableReset={true}
                />
              )}
            </>
          ) : (
            <Takeoffs />
          )}
        </>
      ) : (
        <div className="pt-5 mt-5 d-flex align-items-center justify-content-center flex-column">
          {!refreshingEstimate && !!project && !project.upb_import_filename && (
            <h3 className="mb-4 lg">No Estimate Uploaded</h3>
          )}
          {!!refreshingEstimate && (
            <div className="d-flex align-items-center mb-4">
              <h3 className="lg mb-0">Refresh Estimate</h3>
              <Button variant="link" onClick={() => setRefreshingEstimate(false)}>
                <XCircle size={18} />
              </Button>
            </div>
          )}
          {!!project && <UPBUpload setAlert={setAlert} />}
        </div>
      )}
      <ConfirmModal
        showConfirmation={tasksDeactivatedConfirm}
        setShowConfirmation={setTasksDeactivatedConfirm}
        confirmFunction={() => {
          submitEdit(bidItems);
          setEditTasks(!editTasks);
          setViewPhases(false);
          setTasksDeactivatedConfirm(false);
          setDeactivatedTasks([]);
        }}
        loading={updateLoading}
        error={updateError}
        name={"Deactivating Tasks"}
        message={
          "Some of the selected tasks may have time and quantity associated with them. Are you sure you want to deactivate?"
        }
      />
      {!!movingTask && <MoveTaskForm bidItems={bidItems} setMessage={setMessage} />}
      {addingTask && (
        <AddTaskForm
          bidItems={bidItems}
          setAddingTask={setAddingTask}
          addingTask={addingTask}
          setMessage={setMessage}
        />
      )}
      {addingBidItem && (
        <AddBidItemForm setAddingBidItem={setAddingBidItem} addingBidItem={addingBidItem} setMessage={setMessage} />
      )}
    </>
  );
};

export default EstimateConversion;
