import React from "react";
import useProvideVendorSelection from "./useProvideVendorSelection";
import vendorSelectionContext from "./vendorSelectionContext";

function ProvideVendorSelection({ children }) {
  const vendorSelection = useProvideVendorSelection();
  return <vendorSelectionContext.Provider value={vendorSelection}>{children}</vendorSelectionContext.Provider>;
}

export default ProvideVendorSelection;
