import { Joi } from "component-library";

const schema = {
  role_id: Joi.saneString().required().label("Role Id"),
};

const initialData = {
  role_id: '',
};

export { schema, initialData };
