/**
 * Converts a number of minutes into a time in the format of 00:00
 * @param  {Number} mins Number of minutes
 * @return {String} Hours/Minutes in the format of time, or "-" if NaN or 0
 */

const convertMinutesToTime = (mins) => {
  let valToProcess;
  if (Array.isArray(mins)) {
    valToProcess = mins.reduce((a, c) => a + c, 0);
  } else {
    valToProcess = mins;
  }

  const hours = Math.floor(Math.abs(valToProcess)/60);
  const min = Math.abs(valToProcess) % 60;
  
  return `${valToProcess < 0 ? "-" : ""}${hours}:${min.toString().padStart(2, "0")}`;
};

export default convertMinutesToTime