import React, { useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PhotosModal = ({ photos, setOpen, open, initalIndex }) => {
  const ref = useRef();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (initalIndex && open) {
      ref.current.slickGoTo(initalIndex);
    }
  }, [initalIndex, open]);

  return (
    <Modal
      animation={false}
      show={!!open}
      onHide={() => {
        setOpen(false);
      }}
      dialogClassName="modal-xl"
      className="text-gray3"
      centered
    >
      <Modal.Header closeButton className={styles.modalHeader}></Modal.Header>
      <div className={styles.wrapper}>
        <div className={{ flex: 1, alignItems: "center" }}>
          <Slider {...settings} ref={ref}>
            {photos.map((photo, index) => {
              return (
                <div key={index} className={styles.slide}>
                  <img alt="" src={photo.file.abs_url} className={styles.photo} />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <Modal.Footer>
        <Button onClick={() => setOpen(false)} className={`${[styles.button, styles.cancelButton]} med`}>
          Close Preview
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PhotosModal;
