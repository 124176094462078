import React from 'react';
import { Link } from "react-router-dom";

const PageHeader = ({ title }) => {
  return (
    <>
      <div className="row mb-3 pb-3 no-print">
        <div className="col">
          <Link to="/dashboard">&lt; Back to Dashboard</Link>
        </div>
      </div>
      <div className="mb-3 pb-2 row">
        <div className="col">
          <h1 className="mb-0" style={{ fontSize: "2rem" }}>
            {title}
          </h1>
        </div>
      </div>
    </>
  );
}

export default PageHeader;