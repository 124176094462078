import React from 'react';
import styles from '../PreconChecklist.module.scss';
import { XCircle } from 'react-feather';
import { Button } from 'react-bootstrap';
import useWindowSize from '../../../../hooks/useWindowSize';

const CheckboxListStatus = ({ handleRemove, selectedItems }) => {

  const { isLarge } = useWindowSize(1200);

  return (
    !!selectedItems &&
    selectedItems.length > 0 && (
      <div className="d-flex">
        {selectedItems.length > 2 ? (
          <div className={styles.pillBadge}>{selectedItems.length} Selected</div>
        ) : !isLarge ? (
          <div className={styles.pillBadge}>{selectedItems.length} Selected</div>
        ) : (
          selectedItems.map((item) => (
            <div key={item.id} className={`d-flex align-items-center me-2 ${styles.pillBadge}`}>
              <Button
                onClick={() => handleRemove(item.dot_category.label, item.dot_category.header, item.id)}
                className="px-0 pe-1"
                variant="link"
              >
                <XCircle size="13" />
              </Button>
              {item.dot_category.label}
            </div>
          ))
        )}
      </div>
    )
  );
}

export default CheckboxListStatus;