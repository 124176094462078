import Types from "../columnRenders/Types";
import TypeValue from "../columnRenders/TypeValue";
import typeOptions from "../../../utils/typeOptions";

const type = {
  headerName: "Type",
  field: "type",
  editable: true,
  cellEditor: Types,
  cellEditorParams: typeOptions,
  cellEditorPopup: true,
  cellEditorPopupPosition: "under",
  cellRenderer: TypeValue,
  maxWidth: 185,
  valueGetter: ({ data }) =>
    data?.status_id ? typeOptions.find((status) => status.value === data.status_id).label : null,
};

export default type;
