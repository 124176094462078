import React from 'react';
import { states, dropdownStyles } from 'component-library';
import styles from "../styles.module.scss";
import PropTypes from "prop-types";

const ProjectAddress = ({ renderTextField, renderSelectField }) => {
  return (
    <>
      <div style={{width: 550}}>
        {renderTextField({
          name: "delivery_address_street",
          label: "Delivery Address",
        })}
      </div>

      <div className="d-flex">
        <div className={styles.rowFields} style={{width: 216}}>
          {renderTextField({
            name: "delivery_address_city",
            label: "City",
          })}
        </div>

        <div className={styles.rowFields} style={{width: 86}}>
          {renderSelectField({
            name: "delivery_address_state",
            label: "State",
            options: states,
            styles: dropdownStyles
          })}
        </div>

        <div className={styles.rowFields} style={{width: 120}}>
          {renderTextField({
            name: "delivery_address_zip",
            label: "ZIP",
          })}
        </div>

      </div>
    </>
  );
}

export default ProjectAddress;

ProjectAddress.propTypes = {
  renderTextField: PropTypes.func.isRequired,
  renderSelectField: PropTypes.func.isRequired
};