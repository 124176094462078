import React from "react";
import formatTime from "../../../utils/formatTime";
import InterfaceIcon from "../../../components/InterfaceIcon";

const ClockOut = ({ data }) => {
  //TODO: sync terms across api
  const clockOutTime = data.clock_out || data.time_out;

  return (
    <div className="d-flex align-items-center">
      {!!clockOutTime && (
        <InterfaceIcon isWithinGeofence={data.within_geofence_clock_out} interfaceUsed={data.interface_clock_out} />
      )}
      <div className="ms-2">{clockOutTime ? formatTime(clockOutTime) : "-"}</div>
    </div>
  );
};

export default ClockOut;
