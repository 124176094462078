import React from "react";
import { certifications, useAuth } from "component-library";
import CompanyLocationFields from "./CompanyLocationFields";

const CompanyFields = ({
  data,
  renderTextField,
  renderSelectField,
  initialCompany,
  locations,
  setFields,
  updateLocations,
  singleLocation,
  error,
}) => {
  const { config } = useAuth();
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          {renderTextField({
            name: `name`,
            label: "Name",
            autoFocus: true,
            autoComplete: "nope",
          })}
        </div>
      </div>
      {!initialCompany && (
        <CompanyLocationFields
          data={data}
          locations={locations}
          setFields={setFields}
          updateLocations={updateLocations}
          singleLocation={singleLocation}
          renderTextField={renderTextField}
          error={error}
        />
      )}
      <div className="row">
        <div className="col">
          {renderSelectField({
            name: "company_categories",
            label: "Categories",
            isSearchable: false,
            closeMenuOnSelect: false,
            isMulti: true,
            isClearable: false,
            options: config.company_categories.map((opt) => ({
              label: opt.name,
              value: opt.id,
            })),
          })}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {renderSelectField({
            name: "certifications",
            label: "Certifications",
            isSearchable: false,
            closeMenuOnSelect: false,
            isMulti: true,
            isClearable: true,
            options: certifications.map((opt) => ({
              label: opt.name,
              value: opt,
            })),
          })}
        </div>
      </div>
    </>
  );
};

export default CompanyFields;
