import React, { useState, useEffect } from "react";
import Alert from "../../../components/Alert";
import useProject from "../../hooks/useProject";

const MissingProjectInfo = () => {
  const { project } = useProject();
  const [show, setShow] = useState();

  const shouldShow = () => {
    const hasEN = !!project.estimate_number || !!project.upb_import_id || !!project.upb_import_date ;
    const hasSuper =
      !!project.project_superintendent_ids &&
      !!project.project_superintendent_ids.length;
    const hasPM =
      !!project.project_manager_ids && !!project.project_manager_ids.length;
    const hasForemen =
      !!project.project_foremen_ids && !!project.project_foremen_ids.length;
    const hasPC = !!project.project_coordinator_ids &&
        !!project.project_coordinator_ids.length;
    const hasDOTCat = !!project.dot_categories && !!project.dot_categories.length;

    return !(hasEN && hasSuper && hasPM && hasForemen && hasPC && hasDOTCat);
  };

  useEffect(() => {
    setShow(shouldShow());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return !!show ? (
    <Alert
      variant="warning"
      title="You still have missing project details"
      message="For full functionality please complete missing information"
      linkText="Review Project Info"
      linkTo={`/project/${project.id}/settings`}
    />
  ) : null;
};

export default MissingProjectInfo;
