import { Joi } from "component-library";

const schema = {
  estimate_number:  Joi.saneString().required().label("Estimate Number"),
};

const initialData = {
  estimate_number: "",
};

export { schema, initialData };
