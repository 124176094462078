import React, { useState } from "react";
import BidTaskEntry from "./BidTaskEntry";
import PropTypes from "prop-types";
import { Collapse } from "react-bootstrap";
import { BidSectionToggler } from "component-library";

const BidTasks = ({ tasks, setTodos, setBid, bid }) => {
  const completedTasks = tasks.filter((t) => t.complete === 1);
  const incompleteTasks = tasks.filter((t) => t.complete === 0);
  const [show, setShow] = useState(true);

  return (
    <>
      {incompleteTasks.length > 0 && (
        <BidSectionToggler
          title="Todos"
          show={show}
          setShow={setShow}
          ariaLabel="To do"
        />
      )}
      <Collapse in={show}>
        <div className="pt-2">
          {incompleteTasks &&
            incompleteTasks
              .slice(0)
              .map((task) => (
                <BidTaskEntry
                  task={task}
                  completed={false}
                  setTodos={setTodos}
                  setBid={setBid}
                  key={task.id}
                  bid={bid}
                />
              ))}
          {completedTasks.length > 0 && (
            <h5 className="pt-5 semibold lg">Completed Todos</h5>
          )}
          {completedTasks &&
            completedTasks
              .slice(0)
              .map((task) => (
                <BidTaskEntry
                  task={task}
                  completed={true}
                  setTodos={setTodos}
                  setBid={setBid}
                  key={task.id}
                  bid={bid}
                />
              ))}
        </div>
      </Collapse>
    </>
  );
};

BidTaskEntry.propTypes = {
  task: PropTypes.object.isRequired,
  setTodos: PropTypes.func.isRequired,
  setBid: PropTypes.func,
  bid: PropTypes.object,
};

export default BidTasks;
