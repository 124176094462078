import React from "react";
import styles from "./styles.module.scss";
import TeamItem from "./TeamItem";
import PropTypes from "prop-types";

const AssignTeam = ({ items, roleOptions }) => {
  return (
    <div className={styles.projectTeam}>
      <h2 className={styles.teamHeader}>Project Team</h2>
      <div className="text-white px-3 mb-3">
        Assign a Project Team
      </div>
      <div className={styles.itemsWrapper}>
        {!!items && items.map((item) => (
          <TeamItem
            item={item}
            key={item.key}
            roleOptions={roleOptions}
          />
        ))}
      </div>
    </div>
  );
}

AssignTeam.propTypes = {
  items: PropTypes.array.isRequired,
  roleOptions: PropTypes.object,
};

export default AssignTeam;
