import React from "react";
import { displayDate } from "component-library";
import PropTypes from "prop-types";

const HistoryItem = ({ history, locate }) => {
  return (
    <>
      <div className="row d-flex align-items-center" key={history.id}>
        <div className="col-3">
          {displayDate(history.created_at)}
        </div>
        <div className="font-weight-bold col-3 py-2">#{locate.number}</div>
        <div className="col-3 font-italic">{history.description}</div>
        <div className="col-3">{history.user}</div>
      </div>
      {!!locate.relocate.length && locate.relocate.map((relocate) => (
        !!relocate.history.length && relocate.history.map((history) => (
          <HistoryItem history={history} locate={relocate} key={history.id} />
        ))
      ))}
    </>
  );
};

HistoryItem.propTypes = {
  history: PropTypes.object.isRequired,
  locate: PropTypes.object.isRequired,
};

export default HistoryItem;