import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApi, weeklyReportsApi } from "component-library";

const useWeeklyReportReview = () => {
  const { report_id } = useParams();
  const { request, data, error, loading } = useApi(weeklyReportsApi.getWeeklyReportDetails);
  const {
    request: sendRequest,
    error: sendReportError,
    loading: sendReportLoading,
  } = useApi(weeklyReportsApi.sendWeeklyReport);
  const [recipients, setRecipients] = useState([]);
  const [weather, setWeather] = useState([]);
  const [weeklyReport, setWeeklyReport] = useState({});

  const addRecipient = (recipient) => {
    setRecipients([...recipients, recipient]);
  };

  const {
    request: weatherRequest,
    data: weatherData,
    error: weatherError,
    loading: weatherLoading,
  } = useApi(weeklyReportsApi.getWeeklyReportWeather);

  const {
    request: updateReportRequest,
    data: updateReportData,
    loading: updateStatusLoading,
    error: updateStatusError,
  } = useApi(weeklyReportsApi.updateWeeklyReport);

  const sendReport = () => {
    sendRequest(report_id);
  };

  const handleUpdateReportStatus = (updatedReport) => {
    updateReportRequest(updatedReport);
  };

  useEffect(() => {
    if (report_id) {
      request(report_id);
      weatherRequest(report_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report_id]);

  useEffect(() => {
    if (data) {
      setWeeklyReport(data.stats);
      setRecipients(data?.stats?.recipients);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!!weatherData?.weather?.length) {
      setWeather(weatherData.weather);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weatherData]);

  useEffect(() => {
    if (!!updateReportData?.report) {
      setWeeklyReport({
        ...weeklyReport,
        status: updateReportData.report.status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateReportData?.report]);

  return {
    weeklyReport,
    loading,
    error,
    weather,
    weatherLoading,
    weatherError,
    recipients,
    report_id,
    sendReportLoading,
    sendReportError,
    setRecipients,
    addRecipient,
    sendReport,
    handleUpdateReportStatus,
    updateStatusLoading,
    updateStatusError,
  };
};

export default useWeeklyReportReview;
