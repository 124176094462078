import { Joi } from "component-library";

const schema = {
  bid_bond_plans_form_required: Joi.number().label("Form Required"),
  bid_bond_date: Joi.date().required().label("Bond Date"),
  bid_bond_comments: Joi.saneString().label("Bond Comments"),
  bid_bond_subcontracted_amount: Joi.saneString().label("Bond Subcontracted Amounts"),
};

export { schema };
