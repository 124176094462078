import Source from "../columnRenders/Source";

const source_name = {
  headerName: "Source",
  field: "source_name",
  minWidth: 300,
  wrapText: true,
  flex: 1,
  cellStyle: { fontWeight: 600 },
  cellRenderer: Source
};

export default source_name;
