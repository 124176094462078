import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const HeaderPortal = ({ elementId = "pageTitleSecondary", children }) => {
  const [headerContent, setHeaderContent] = useState();

  useEffect(() => {
    setHeaderContent(document.getElementById(elementId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{!!headerContent && createPortal(children, headerContent)}</>;
};

export default HeaderPortal;
