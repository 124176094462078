import React from "react";
import styles from "./styles.module.scss";
import { Grid, Folder } from "react-feather";
import { ReactComponent as Unpinned } from "../../../../assets/photoUnpinned.svg";

const Title = ({ combinedList, currentView }) => {
  const currentAsset =
    combinedList.length > 0 && currentView >= 0 && currentView < combinedList.length ? combinedList[currentView] : null;
  const isPhoto = currentAsset ? currentAsset.photo_count === undefined : false;
  const isPinned = currentAsset ? currentAsset.pinned : false;

  return (
    <>
      <h2 className={styles.sectionTitle}>
        {isPinned ? (
          <Unpinned className={styles.pinIcon} />
        ) : isPhoto ? (
          <Grid color="#4F4F4F" size={"2rem"} />
        ) : (
          <Folder color="#4F4F4F" size={"2rem"} />
        )}
        {`${isPinned ? "Pinned " : ""} ${isPhoto ? "Photo" : "Album"}`}
      </h2>
    </>
  );
};

Title.propTypes = {};

export default Title;
