import React from "react";
import { useAuth } from "component-library";
import { NavLink, useLocation } from "react-router-dom";

const AdminLinks = () => {
  const { userCan } = useAuth();
  const { pathname } = useLocation();

  return (
    <div className="subNav">
      {userCan("admin-users") && (
        <NavLink
          to="/user-admin"
          className={`subNavLink ${
            pathname.includes("user-admin") || pathname.includes("archived-user-admin") ? "active" : ""
          }`}
        >
          User Admin
        </NavLink>
      )}
      {userCan("role-list") && (
        <NavLink to="/roles-admin" className="subNavLink">
          Roles Admin
        </NavLink>
      )}
      {userCan("admin-users") && (
        <NavLink to={"/super-admin"} className={`subNavLink ${pathname.includes("super-admin") ? "active" : ""}`}>
          Super Admin
        </NavLink>
      )}
    </div>
  );
};

export default AdminLinks;
