import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { BeatLoader } from "react-spinners";
import { useApi, bidApi } from "component-library";
import styles from "../Takeoffs.module.scss";
import PropTypes from "prop-types";

const TakeoffAmountField = ({ item, updateTakeoff, open }) => {
  const { request, data, loading, error } = useApi(bidApi.updateTakeoff);
  const [value, setValue] = useState(item.pm_quantity);
  const handleFieldChange = ({ target: { value } }) => {
    if (parseInt(value) !== parseInt(item.pm_quantity)) {
      request({
        ...item,
        pm_quantity: value,
      });
    }
  };

  useEffect(() => {
    if (!!data?.takeoffs?.length) updateTakeoff(data.takeoffs[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setValue(item.pm_quantity || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!item && item.pm_quantity]);

  return (
    <div className="d-flex align-items-center justify-content-start">
      <div className="position-relative me-2">
        {!!loading && (
          <div className="loaderWrapper">
            <BeatLoader color={"#0071ce"} size={10} />
          </div>
        )}
        {!!error && <span className="error me-1">Error.</span>}
        <NumericFormat
          name={item.name}
          className={`form-control text-end ${styles["pm-takeoff-input"]} ${!!open ? styles.openInput : ""}`}
          id={item.id}
          value={value}
          displayType={"input"}
          onBlur={handleFieldChange}
          disabled
        />
      </div>
      {item.unit_measure}
    </div>
  );
};

TakeoffAmountField.propTypes = {
  item: PropTypes.object,
  updateTakeoff: PropTypes.func,
  open: PropTypes.bool,
};

export default TakeoffAmountField;
