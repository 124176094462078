/**
 * Converts a number of hours in decimal format into a time in the format of 00:00
 * @param  {Number} hours Number of hours as a float
 * @return {String} Hours in the format of time, or "--" if NaN
 */

const convertDecimalHours = (hours) => {
  if (typeof hours !== "number" || isNaN(hours)) {
    return "--";
  }
  return `${String(Math.floor(hours)).padStart(2, "0")}:${String(Math.round((hours - Math.floor(hours)) * 60)).padStart(2,"0")}`;
};

export default convertDecimalHours;
