import React, { useState, useEffect } from 'react'
import DailyUserHeader from '../components/DailyUserHeader/DailyUserHeader';
import DailyAccordion from '../components/DailyAccordion';
import TimesheetRow from '../components/TimesheetRow';
import styles from "./styles.module.scss";

const CrewMember = ({ crewMember }) => {
  const [timesheets, setTimesheets] = useState();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!!crewMember.user.time_sheets?.length) {
      setTimesheets(crewMember.user.time_sheets);
    } else {
      setTimesheets();
    }
  }, [crewMember.user.time_sheets]);

  const HeaderContent = () => (
    <DailyUserHeader
      user={crewMember.user}
      timesheets={crewMember.user.time_sheets}
    />
  );

  return (
    <DailyAccordion
      headerContent={<HeaderContent />}
      active={active}
      setActive={setActive}
      wrapperStyles={styles.borderWrapper}
      zebraStripped
    >
      <div className="d-flex flex-column">
        {timesheets?.map((timesheet) => (<TimesheetRow timesheet={timesheet} />))}
      </div>
    </DailyAccordion>
  )
}

export default CrewMember