import Name from "../columnRenders/Name";

const description = {
  headerName: "Title",
  field: "description",
  flex: 1,
  minWidth: 300,
  cellRenderer: Name,
  tooltipField: "description",
  tooltipValueGetter: ({ data }) => {
    return data?.description || data?.name;
  },
};

export default description;
