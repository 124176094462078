import React from "react";
import styles from "./styles.module.scss";
import fieldlogStatusColors from "../../../utils/fieldlogStatusColors";

const Status = ({ data }) => {
  return data?.type === "Field Log" ? (
    <div className={styles.typeWrap}>
      <div className={`${styles.status}`} style={{ backgroundColor: fieldlogStatusColors[data.status] }}>
        {data.status}
      </div>
    </div>
  ) : (
    <span>{data.status}</span>
  );
};

export default Status;
