import React from "react";
import { ExternalLink } from "react-feather";
import { Link } from "react-router-dom";

const TimesheetLink = ({ data }) => {
  return (
    <Link
      to={`/project/${data.project_id}/field-management/timesheet/${data.timesheet_id}${
        data?.foreman?.id ? `/foreman/${data.foreman.id}` : ""
      }`}
    >
      <ExternalLink size="16" className="me-1" color={"#0071ce"} />
    </Link>
  );
};

export default TimesheetLink;
