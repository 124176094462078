import { DateTime } from "luxon";

/**
 * standardizes formatting of time for timesheets
 * @param  {String} date string of a date that will be converted to js date via luxon
 * @return {String} datetime in the format of hh:mm a, or "No Time" if date is null
 */

const formatTimesheetTime = (date) => {
  if (!date) return "No Time";

  const formattedDate = DateTime.fromJSDate(new Date(date)).toFormat("hh:mm a");
  return formattedDate;
};

export default formatTimesheetTime;
