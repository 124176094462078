import React from "react";
import styles from "./styles.module.scss";

const ColumnHeader = ({ text = "", width = 42, alignment = "right" }) => {
  return (
    <div className={styles.colHeader} style={{ width: width, textAlign: alignment }}>
      {text}
    </div>
  );
};

export default ColumnHeader;
