import React from "react";
import useProvideFleetManagementIframes from "./useProvideFleetManagementIframes";
import fleetManagementContext from "./fleetManagementContext";

function ProvideFleetManagement({ children }) {
  const fleetManagement = useProvideFleetManagementIframes();
  return <fleetManagementContext.Provider value={fleetManagement}>{children}</fleetManagementContext.Provider>;
}

export default ProvideFleetManagement;
