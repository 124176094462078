import React from "react";
import AddAddenda from "./AddAddenda";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

const AddAddendaModal = ({addingAddenda, setAddingAddenda, bid, setBid}) => {
  return (
    <Modal
      animation={false}
      show={addingAddenda}
      onHide={() => {
        setAddingAddenda(false);
      }}
      size="lg"
    >
      <AddAddenda
        project_id={bid.id}
        type={bid.type && bid.type.name}
        hide={() => {
          setAddingAddenda(false);
        }}
        setBid={(bid) => {
          setBid(bid);
        }}
        bid={bid}
      />
    </Modal>
  );
};

AddAddendaModal.propTypes = {
  addingAddenda: PropTypes.bool.isRequired,
  setAddingAddenda: PropTypes.func.isRequired,
  bid: PropTypes.object.isRequired,
  setBid: PropTypes.func.isRequired
};

export default AddAddendaModal;
