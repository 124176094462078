import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Alert, Button } from "react-bootstrap";
import { ChevronDown, ChevronRight } from "react-feather";
import { fieldLogsApi, Loader, useApi } from "component-library";
import getTaskNumber from "../getTaskNumber";

const TasksContainer = ({ phase, fieldLog, setFieldLog, foremanId, setOverAllocatedTimeSheets }) => {
  const [phaseOpen, setPhaseOpen] = useState(true);

  const {
    request: updateRequest,
    data: updateData,
    loading: updateLoading,
    error: updateError,
  } = useApi(fieldLogsApi.updateFieldLog);

  const cleanUpWorkDayData = (updatedWorkDay) => {
    let cleanWorkDay = {
      ...updatedWorkDay,
      time_sheets: updatedWorkDay.time_sheets.map((sheet) => {
        delete sheet.created_at;
        delete sheet.updated_at;
        delete sheet.payroll_minutes;
        return sheet;
      }),
      work_day_tasks: updatedWorkDay.work_day_tasks.map((task) => {
        delete task.created_at;
        delete task.updated_at;
        delete task.remaining_quantity;
        return task;
      }),
    };

    return cleanWorkDay;
  };

  const addTask = (task) => {
    const updatedLog = {
      ...fieldLog,
      foreman_id: foremanId,
      work_day_tasks: [
        ...fieldLog.work_day_tasks,
        {
          foreman_id: foremanId,
          task_id: task.id,
          task: task,
          task_comments: [],
          time_entries: [],
          time_entry_ids: [],
          work_day_id: fieldLog.id,
          quantity: 0,
          production_rate: "",
          phase: {
            id: phase.id,
            name: phase.name,
          },
        },
      ],
    };

    updateRequest(cleanUpWorkDayData(updatedLog));
  };

  useEffect(() => {
    if (!!updateData) {
      setFieldLog({
        ...fieldLog,
        work_day_tasks: updateData.work_day.work_day.work_day_tasks,
      });
      const filteredOverAllocatedTimeSheets = updateData.work_day.over_allocated.filter((oats) =>
        fieldLog?.time_sheets.find((ts) => ts.id === oats)
      );
      setOverAllocatedTimeSheets(filteredOverAllocatedTimeSheets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  return (
    <>
      {!!updateError && <Alert variant="danger">{updateError}</Alert>}
      <Loader loading={!!updateLoading} />
      <div className="text-gray2 med" key={phase.id}>
        <Button
          onClick={() => setPhaseOpen(!phaseOpen)}
          variant="link"
          className="d-flex align-items-center w-100 p-0 justify-content-between med text-gray2 font-weight-bold mb-2"
        >
          {phase.name}
          {!!phaseOpen ? <ChevronDown size={20} color={"#7b7b7b"} /> : <ChevronRight size={20} color={"#7b7b7b"} />}
        </Button>
        {!!phaseOpen && (
          <div className={styles.tasksWrapper}>
            {!!phase.tasks && !!phase.tasks.length ? (
              phase.tasks.map((t) => {
                return (
                  <div key={t.id}>
                    <Button
                      variant="link"
                      className={`text-start med text-gray2 mb-3 ${styles.taskOption}`}
                      onClick={() => addTask(t)}
                    >
                      <div>{getTaskNumber(t)}</div>
                      <div> {t.name}</div>
                    </Button>
                  </div>
                );
              })
            ) : (
              <>No Tasks for this phase</>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TasksContainer;
