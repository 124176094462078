import React from "react";
import Card from "./Card";
import { Star } from "react-feather";
import styles from "./styles.module.scss";
import { yourProjects, otherProjects, activeProjects } from "../projectsFilter";

const CardView = ({ projects }) => {
  return (
    <>
      <div className={styles.header}>
        <h2 className={styles.title}>Your Projects</h2>
        <Star size={18} color="#F0C808" fill="#F0C808" />
      </div>
      <div className={styles.row}>
        {yourProjects(projects).map((project) => (
          <Card project={project} key={`${project.id}`} />
        ))}
      </div>
      <div className={styles.background}>
        <h3 className={styles.subtitle}>Active Projects</h3>
        <div className={styles.row}>
          {activeProjects(projects).map((project) => (
            <Card project={project} key={`${project.id}`} />
          ))}
        </div>
        <h3 className={styles.subtitle}>Other Projects</h3>
        <div className={styles.row}>
          {otherProjects(projects).map((project) => (
            <Card project={project} key={`${project.id}`} />
          ))}
        </div>
      </div>
    </>
  );
};

export default CardView;
