import React from "react";
import PropTypes from "prop-types";
import styles from "../styles.module.scss";
import { Crosshair } from "react-feather";

const LeadingCostCode = ({ editTasks, item, updateLeadingCostCode }) => {
  return (
    <>
      {!!editTasks && item?.entity_type === "task" ? (
        <td className="sm-med text-center">
          <div className="d-flex align-items-center justify-content-center form-group my-0 ">
            <div className="d-flex align-items-center justify-content-center form-group form-check my-0 ">
              <input
                className="form-check-input"
                id={`${item.id}-leading-indicator`}
                name={`${item.id}-leading-indicator`}
                value={item?.leading_cost_code}
                checked={item?.leading_cost_code}
                type="checkbox"
                onChange={(val) => {
                  updateLeadingCostCode("leading_cost_code", val.target.checked ? 1 : 0, item);
                }}
              />
              <label htmlFor={`${item.id}-leading-indicator`} className={`${styles.activeCheckbox}`}>
                {!!item?.leading_cost_code ? (
                  <span className={styles.selectedLeadingCostCode}>
                    <Crosshair size={8} color="#ffffff" />
                  </span>
                ) : (
                  <span className={styles.unselectedLeadingCostCode}>
                    <Crosshair size={8} color="#ffffff" />
                  </span>
                )}
              </label>
            </div>
          </div>
        </td>
      ) : (
        <td className="text-center">
          {!!item?.leading_cost_code && item?.entity_type === "task" && (
            <span className={styles.leadingIndicator}>
              <Crosshair color="#fff" size={10} />
            </span>
          )}
        </td>
      )}
    </>
  );
};

LeadingCostCode.propTypes = {
  item: PropTypes.object,
  editTasks: PropTypes.bool,
  updateItem: PropTypes.func,
};

export default LeadingCostCode;
