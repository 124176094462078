import React, { useState } from "react";
import useAddPermit from "./hooks/useAddPermit";
import { Modal, Button } from "react-bootstrap";
import PermitsHistory from "./PermitsHistory";
import PropTypes from "prop-types";
import { useApi, projectApi, useAuth } from "component-library";

const AddNewPermit = ({ close, update, permit }) => {
  const { user } = useAuth();
  const userName = `${user.first_name} ${user.last_name}`;
  const [docLoading, setDocLoading] = useState(false);
  const { fields, handleSubmit, renderSubmitButton, loading } = useAddPermit(
    close,
    update,
    permit,
    userName,
    setDocLoading
  );
  const { request, loading: savePermitLoading } = useApi(projectApi.savePermit);

  const archivePermit = async () => {
    const { success } = await request({
      id: permit.id,
      number: permit.number || null,
      cost: permit.cost || null,
      issue_date: permit.issue_date || null,
      expiration: permit.expiration || null,
      project_id: permit.project_id || null,
      description: permit.description || null,
      issuing_contact_id: permit.issuing_contact_id || null,
      file_id: permit.file_id || null,
      status: "archived",
      user: userName,
    });
    if (success) {
      update();
      close();
    }
  };

  return (
    <Modal
      show={true}
      onHide={() => {
        close();
      }}
    >
      <Modal.Header
        closeButton
        closeLabel={
          !!permit
            ? !!permit.number
              ? `Close Permit #${permit.number} Modal`
              : "Close Permit Modal"
            : "Close Add New Permit Modal"
        }
      >
        <div className="mb-0 med">
          {!!permit ? (!!permit.number ? `Permit #${permit.number}` : "Edit Permit") : "Add New Permit"}
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {fields}
          <PermitsHistory permit={permit} />
          <div className="text-end">
            {!!permit && (
              <Button variant="light-gray" onClick={archivePermit} className="mt-2 me-3 font-weight-bold">
                {savePermitLoading ? "Archiving Permit" : "Archive Permit"}
              </Button>
            )}
            {renderSubmitButton("Save Permit", !!loading || docLoading)}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

AddNewPermit.propTypes = {
  close: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  permit: PropTypes.object,
};

export default AddNewPermit;
