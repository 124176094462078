import convertBoolToYN from "../../gridUtils/convertBoolToYN";

const time_out_geofence = {
  headerName: "Out-Geo",
  field: "time_out_within_geofence",
  width: 90,
  valueGetter: ({ data }) => convertBoolToYN(data.time_out_within_geofence),
};

export default time_out_geofence;
