import React from "react";
import styles from "./styles.module.scss";
import { Avatar } from "component-library";

const Name = ({ data }) => {
  return data.last_name && data.first_name ? (
    <div className="d-flex align-items-center">
      <Avatar
        hasColor
        user={{first_name: data.first_name, last_name: data.last_name, avatar: data.avatar}}
      />
      <div className={styles.nameText}>
        {data.last_name}, {data.first_name}
      </div>
    </div>
  ) : null;
};

export default Name;
