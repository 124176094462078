import React from "react";
import InteriorPage from "../pageLayout/InteriorPage";
import { useParams } from "react-router";
import { RiseLoader } from "react-spinners";
import { AgGridTable, useAuth } from "component-library";
import permitsConfig from "../../config/permitsConfig";
import Pagination from "../Pagination";
import PermitsListingHeader from "../LocatesAndPermits/Permits/PermitsListingHeader";
import usePermitListing from "../LocatesAndPermits/Permits/hooks/usePermitListing";
import useProject from "../../_projects/hooks/useProject";
import { Briefcase } from "react-feather";

const PermitsListing = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const { pageTitle, PageTag } = useProject();
  const {
    locatesLoading,
    locatesError,
    initialLoading,
    setExpirationEndDate,
    setExpirationStartDate,
    setStartDate,
    setEndDate,
    setSearchTerm,
    setCurrentPage,
    currentPage,
    itemsPerPage,
    allPermits,
    filteredPermits,
    paginatedPermits,
    setPaginatedPermits,
    startDate,
    endDate,
    expirationStartDate,
    expirationEndDate,
  } = usePermitListing(id);

  return (
    <InteriorPage
      navTitle={pageTitle}
      error={locatesError}
      pageTitle={"Permits"}
      NavContent={PageTag}
      navIcon={<Briefcase color="#fff" size={14} />}
      loading={initialLoading}
    >
      <div className="position-relative">
        {!!locatesLoading && (
          <div className={"loaderWrapper"}>
            <RiseLoader color={"#0071ce"} />
          </div>
        )}
        <PermitsListingHeader
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          permits={allPermits}
          setPaginatedPermits={setPaginatedPermits}
          setSearchQuery={setSearchTerm}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
          setExpirationStartDate={setExpirationStartDate}
          setExpirationEndDate={setExpirationEndDate}
          expirationEndDate={expirationEndDate}
          expirationStartDate={expirationStartDate}
        />
        <AgGridTable
          gridData={paginatedPermits}
          loading={initialLoading}
          gridConfig={permitsConfig}
          id={`${user.id}_permits_listing`}
          presetStyle="sortableTable"
        />
        <Pagination
          items={filteredPermits}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </InteriorPage>
  );
};

export default PermitsListing;
