import React from "react";
import styles from "./styles.module.scss";

const DashboardSection = ({ title, children }) => {
  return (
    <section className={styles.dashSection}>
      <h1 className="text-uppercase light-text sm mb-1">{title}</h1>
      {children}
    </section>
  );
}

export default DashboardSection;