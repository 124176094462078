import React from "react";
import useProject from "../../hooks/useProject";
import styles from "./styles.module.scss";
import Sidebar from "../Sidebar";
import MissingProjectInfo from "./MissingProjectInfo";
import Documents from "./Documents";
import Milestones from "./Milestones";
import Reporting from "./Reporting";
import RecentlyUpdated from "./RecentlyUpdated";
import Alerts from "./Alerts";

const DashboardContent = () => {
  const { project } = useProject();

  return (
    <div className={styles.inlineRow}>
      {project && (
        <>
          <Alerts />
          <div className={styles.innerWrapper}>
            <MissingProjectInfo />
            <Documents />
            <Milestones />
            <Reporting />
            <RecentlyUpdated />
          </div>
          <Sidebar />
        </>
      )}
    </div>
  );
};

export default DashboardContent;
