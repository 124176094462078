import ExpandToggle from "../../sharedColumns/columnRenders/ExpandToggle";
import useResourceManagement from "../../../_resourceManagement/context/useResourceManagement";

const ExpandAll = () => {
  const { crewExpanded, setCrewExpanded } = useResourceManagement();

  return <ExpandToggle expandState={crewExpanded} setExpandState={setCrewExpanded} />;
};

export default ExpandAll;
