import { endpoints, client } from "component-library";
const contacts = {
  searchContacts: ({ q, archived, page, items_per_page }) =>
    client.post(endpoints.secure.SEARCH_CONTACTS, {
      q,
      archived,
      page,
      items_per_page,
    }),
  createContact: (contact) => client.post(endpoints.secure.CREATE_CONTACT, contact),
  addContact: (contact) => client.post(endpoints.secure.ADD_CONTACT, contact),
  removeContact: (contact) => client.post(endpoints.secure.REMOVE_CONTACT, contact),
  getContacts: () => client.get(endpoints.secure.GET_CONTACTS),
  updateContact: (contact) => client.post(endpoints.secure.UPDATE_CONTACT, contact),
  archiveContact: (contact_id) => client.get(endpoints.secure.ARCHIVE_CONTACT(contact_id)),
  restoreContact: (contact_id) => client.get(endpoints.secure.RESTORE_CONTACT(contact_id)),
};

export default contacts;
