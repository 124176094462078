import { useEffect, useState } from "react";
import { useApi, bidApi } from "component-library";
import useEstimateConversion from "../useEstimateConversion";

const useItemPhasing = ({ item }) => {
  const [qtyTotal, setQtyTotal] = useState();
  const [phasesLoaded, setPhasesLoaded] = useState();
  const [phaseValues, setPhaseValues] = useState([]);
  const [phaseIds, setPhaseIds] = useState();
  const [updatePhaseQty, setUpdatePhaseQty] = useState(false);

  const { bidItems, setBidItems } = useEstimateConversion();

  const {
    data: updateData,
    error: updateError,
    loading: updateLoading,
    request: updateRequest,
  } = useApi(bidApi.addPhasesToUPB);

  const getPhaseQty = (currentPhase) => {
    if (!!phaseValues && !!phaseValues.length) {
      const phase = phaseValues.find((p) => p.phase_id === currentPhase.id);
      if (!!phase) {
        return parseFloat(phase.quantity);
      } else {
        return 0.0;
      }
    }
  };

  const setPhaseQty = (value, phase) => {
    if (!!phaseValues && !!phaseValues.length) {
      setPhaseValues(
        phaseValues.map((p) => {
          if (p.phase_id === phase.id) {
            return {
              ...p,
              quantity: !!value ? parseFloat(value) : 0.0,
            };
          } else {
            return p;
          }
        })
      );
    }
  };

  const setPhasesQtyTotal = () => {
    if (!!phaseValues && !!phaseValues.length) {
      const currentTotal = phaseValues.reduce((p, c) => {
        return p + c.quantity;
      }, 0);
      setQtyTotal(currentTotal);
    }
  };

  const setInitialPhaseValues = (itemPhases) => {
    setPhaseValues(
      itemPhases.map((phase) => {
        return {
          id: phase.id,
          phase_id: phase.phase.id,
          name: phase.phase.name,
          quantity: !!phase.quantity ? parseFloat(phase.quantity) : 0.0,
        };
      })
    );
    setPhasesLoaded(true);
  };

  const filterPhases = (currentIds) => {
    currentIds.map(
      (c) =>
        !phaseIds.includes(c) &&
        setPhaseValues(phaseValues.filter((p) => phaseIds.includes(p.phase_id)))
    );
  };

  const addNewPhases = (currentIds) => {
    let newPhases = item.phases.filter(
      (p) =>
        !currentIds.includes(p.phase_id) &&
        item.phases.find((phase) => phase.phase_id === p.phase_id)
    );
    if (!!newPhases && !!newPhases.length) {
      setPhaseValues([
        ...phaseValues,
        ...newPhases.map((np) => {
          return {
            id: np.id,
            phase_id: np.phase.id,
            name: np.phase.name,
            quantity: 0.0,
          };
        }),
      ]);
    }
  };

  const updatePhaseIds = (itemPhases) => {
    let itemPhaseIds = [];
    if (!!itemPhases && !!itemPhases.length) {
      itemPhaseIds = itemPhases.map((p) => p.phase_id);
    }
    setPhaseIds(itemPhaseIds);
  };

  const updatePhaseValues = (itemPhases) => {
    if (!!phaseIds && !!phaseIds.length) {
      if (!phasesLoaded) {
        setInitialPhaseValues(itemPhases);
      } else {
        const currentIds = [...phaseValues.map((p) => p.phase_id)];
        addNewPhases(currentIds);
        if (!!currentIds && !!currentIds.length) {
          filterPhases(currentIds);
        }
      }
    } else {
      setPhaseValues([]);
      setQtyTotal(0);
    }
  };

  useEffect(() => {
    updatePhaseIds(item.phases);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!item && item.phases]);

  useEffect(() => {
    setPhasesQtyTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phaseValues]);

  useEffect(() => {
    if (!!phasesLoaded && !!updatePhaseQty && qtyTotal <= item.bid_quantity) {
      updateRequest({
        upb_id: item.id,
        phases: phaseValues.map((p) => {
          return {
            id: p.phase_id,
            quantity: p.quantity,
          };
        }),
      });
      setUpdatePhaseQty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtyTotal]);

  useEffect(() => {
    if (!!updateData && !!updateData.upb_item) {
      setBidItems(
        bidItems.map((bItem) => {
          if (bItem.id === item.id) {
            return {
              ...bItem,
              phases: updateData.upb_item.phases,
            };
          } else {
            return bItem;
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    updatePhaseValues(item.phases);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phaseIds]);

  return {
    qtyTotal,
    phaseIds,
    getPhaseQty,
    setPhaseQty,
    setUpdatePhaseQty,
    updateError,
    updateLoading,
  };
};

export default useItemPhasing;
