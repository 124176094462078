import { useState, useEffect } from "react";
import { useApi, photoLibraryApi, formatApiDate, useAuth } from "component-library";
import useProject from "../../../hooks/useProject";

const useProvidePhotoLibrary = () => {
  const { project } = useProject();
  const { user } = useAuth();
  const [dateRange, setDateRange] = useState([]);
  const [q, setQ] = useState("");
  const [photosList, setPhotosList] = useState([]);
  const [albumsList, setAlbumsList] = useState([]);
  const [message, setMessage] = useState();
  const [onlyMyPhotos, setOnlyMyPhotos] = useState(false);

  const {
    request: photosRequest,
    data: photosData,
    loading: photosLoading,
    error: photosError,
  } = useApi(photoLibraryApi.getProjectPhotos);

  const {
    request: albumsRequest,
    data: albumsData,
    loading: albumsLoading,
    error: albumsError,
  } = useApi(photoLibraryApi.getProjectAlbums);

  const refreshLists = () => {
    let requestParams;
    if (project?.id) {
      if (!!dateRange[1]) {
        requestParams = {
          project_id: project.id,
          from_date: formatApiDate(dateRange[0]),
          to_date: formatApiDate(dateRange[1]),
        };
      }

      if (!dateRange[0]) {
        requestParams = {
          project_id: project.id,
        };
      }

      requestParams = {
        ...requestParams,
        q: q || "",
      };

      if (onlyMyPhotos) {
        requestParams = {
          ...requestParams,
          user_id: user.id,
        };
      }

      if (!dateRange[0] || !!dateRange[1]) {
        albumsRequest(requestParams);
        photosRequest(requestParams);
      }
    }
  };

  const handlePin = (id, type) => {
    if (type === "photo") {
      const updatedPhotosList = photosList.map((photo) => {
        if (photo.id === id) {
          return { ...photo, pinned: !photo.pinned };
        } else {
          return photo;
        }
      });
      setPhotosList(updatedPhotosList);
      const updatedAlbumsList = albumsList.map((album) => {
        if (album.photos) {
          const updatedPhotos = album.photos.map((photo) => {
            if (photo.id === id) {
              return { ...photo, pinned: !photo.pinned };
            } else {
              return photo;
            }
          });
          return { ...album, photos: updatedPhotos };
        } else {
          return album;
        }
      });
      setAlbumsList(updatedAlbumsList);
    } else if (type === "album") {
      const updatedAlbumsList = albumsList.map((album) => {
        if (album.id === id) {
          return { ...album, pinned: !album.pinned };
        } else {
          return album;
        }
      });
      setAlbumsList(updatedAlbumsList);
    }
  };

  const handleDelete = (id, type) => {
    if (type === "photo") {
      //figure out the albums to update
      const albumsToUpdate = albumsList.filter((album) => {
        return photosList.some(
          (photo) =>
            photo.albums.length && photo.albums.some((photoAlbum) => photoAlbum.id === album.id) && photo.id === id
        );
      });
      //update photo count on album
      const updatedAlbumsList = albumsList.map((album) => {
        if (albumsToUpdate.some((updateAlbum) => updateAlbum.id === album.id)) {
          return {
            ...album,
            photo_count: album.photo_count - 1,
            photos: album.photos?.filter((photo) => photo.id !== id),
          };
        }
        return album;
      });
      //update lists
      setAlbumsList(updatedAlbumsList);
      const updatedPhotosList = photosList.filter((photo) => photo.id !== id);
      setPhotosList(updatedPhotosList);
    } else if (type === "album") {
      const updatedPhotosList = photosList.map((photo) => {
        if (photo?.albums.map((album) => album.id === id)) {
          const updatedAlbums = photo.albums.filter((album) => album.id !== id);
          return { ...photo, albums: updatedAlbums };
        }
        return photo;
      });
      //update lists
      setPhotosList(updatedPhotosList);
      const updatedAlbumsList = albumsList.filter((album) => album.id !== id);
      setAlbumsList(updatedAlbumsList);
    }
  };

  const handleSearch = (value) => {
    setQ(value);
  };

  useEffect(() => {
    if (project?.id) {
      albumsRequest({ project_id: project.id, q: q });
      photosRequest({ project_id: project.id, q: q });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.id]);

  useEffect(() => {
    if (!!albumsLoading || !!photosLoading) return;
    refreshLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, q, onlyMyPhotos]);

  useEffect(() => {
    if (!!photosData?.photos?.data) {
      setPhotosList(photosData.photos.data);
    }
  }, [photosData]);

  useEffect(() => {
    if (!!albumsData?.albums) {
      setAlbumsList(albumsData.albums.data);
    }
  }, [albumsData]);

  return {
    photosList,
    setPhotosList,
    dateRange,
    setDateRange,
    handleSearch,
    albumsList,
    photosError,
    photosLoading,
    albumsLoading,
    albumsError,
    setAlbumsList,
    message,
    setMessage,
    refreshLists,
    handlePin,
    handleDelete,
    onlyMyPhotos,
    setOnlyMyPhotos,
  };
};

export default useProvidePhotoLibrary;
