import { Joi } from "component-library";

const schema = {
  download_dbe: Joi.checkbox(),
  download_ebsx: Joi.checkbox(),
};

const initialData = {
  download_dbe: 0,
  download_ebsx: 0
};

export { schema, initialData };
