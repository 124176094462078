import Star from "../../ListView/Star";
import Favorite from "../../Favorite";

const favorite = {
  headerComponent: Star,
  field: "favorite",
  cellRenderer: Favorite,
  width: 40,
};

export default favorite;
