import React, {useState} from "react";
import { Button } from "react-bootstrap";
import styles from "./TakeoffDetail.module.scss";
import { ChevronDown, ChevronRight } from "react-feather";
import Materials from "./Materials";

const Subtask = ({ item }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="ms-4">
      <Button
        onClick={() => {
          setOpen(!open);
        }}
        variant="link"
        className={`d-block w-100 med text-gray2 text-start ${styles["subtask-block"]}`}
      >
        {!!open ? (
          <ChevronDown color="#7B7B7B" size="18" className={styles.icon} />
        ) : (
          <ChevronRight color="#7B7B7B" size="18" className={styles.icon} />
        )}
        {item.name}
      </Button>
      {!!open && <Materials task={item} />}
    </div>
  );
};

export default Subtask;
