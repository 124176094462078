import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import useLocate from "./useLocate";

const useLocatesListing = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedLocates, setPaginatedLocates] = useState([]);
  const [error, setError] = useState();

  const itemsPerPage = 25;

  const {
    locates,
    setLocates,
    searchResults,
    projects,
    searchLoading,
    searchError,
    searchRequest,
    loading,
    projectError,
    updateError,
    isPM,
    isPC,
    error: locateError,
  } = useLocate();

  useEffect(() => {
    if (!!locateError) {
      setError(locateError);
    }
    if (!!updateError) {
      setError(updateError);
    }
    if (!!searchError) {
      setError(searchError);
    }
    if (!!projectError) {
      setError(projectError);
    }
    if (!locateError && !updateError && !searchError && !projectError) {
      setError(null);
    }
  }, [locateError, updateError, projectError, searchError]);

  const setRange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    if (!!projects.length && !!searchResults) {
      const formattedLocates = searchResults.map((locate) => {
        const project = projects.find((p) => p.value === locate.project_id);
        if (project) {
          return {
            ...locate,
            project_name: project.label,
            project_id: project.value,
          };
        } else return locate;
      });
      setLocates(formattedLocates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults, projects]);

  useEffect(() => {
    if (!!locates) {
      setPaginatedLocates(locates.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, locates]);

  const handleSearch = () => {
    searchRequest({
      q: searchQuery || "",
      project_id: selectedProject || "",
      from_date: startDate ? DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd") : null,
      to_date: endDate ? DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd") : null,
      status: isPC ? "requested" : "",
    });
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, selectedProject, startDate, endDate]);

  return {
    locates,
    loading,
    error,
    searchLoading,
    projects,
    selectedProject,
    setSelectedProject,
    setRange,
    showSearch,
    setShowSearch,
    searchQuery,
    setSearchQuery,
    paginatedLocates,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setPaginatedLocates,
    startDate,
    endDate,
    isPM,
    isPC,
    setStartDate,
    setEndDate,
  };
};

export default useLocatesListing;
