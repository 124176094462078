import React from "react";
import useProvideLocate from "./hooks/useProvideLocate";
import locateContext from "./hooks/locateContext";

function ProvideLocate({ children }) {
  const locate = useProvideLocate();
  return <locateContext.Provider value={locate}>{children}</locateContext.Provider>;
}

export default ProvideLocate;
