import Timesheet from "./Timesheet";
import styles from "./styles.module.scss";
import { UserPlus } from "react-feather";
import PropTypes from "prop-types";
import AddCrew from "./AddCrew";
import { useApi, timesheetApi, Loader } from "component-library";
import { Button, Alert } from "react-bootstrap";

const TimesheetView = ({
  editing,
  timesheets,
  config,
  tasks,
  projectsList,
  fieldLog,
  setFieldLog,
  foremanId,
  project,
  crew,
  overAllocatedTimeSheets,
  setOverAllocatedTimeSheets,
  crewLoading,
}) => {
  const { request: updateCrewRequest, loading, error } = useApi(timesheetApi.addTimeSheetWithCrew);

  const setTimesheet = (timesheet) => {
    setFieldLog({
      ...fieldLog,
      time_sheets: fieldLog.time_sheets.map((ts) => {
        if (ts.id === timesheet.id) {
          return timesheet;
        }
        return ts;
      }),
    });
  };

  const timesheetClicked = async (newCrew) => {
    const { success, responseData } = await updateCrewRequest({
      workday_id: fieldLog.id,
      project_id: fieldLog.project_id,
      foreman_id: foremanId,
      user_id: newCrew.id,
    });
    if (success) {
      setFieldLog({
        ...fieldLog,
        time_sheets: [...fieldLog.time_sheets, responseData.timesheet],
      });
    }
  };

  const hasOverAllocatedTimeSheets = () => {
    return !!timesheets?.some((ts) => overAllocatedTimeSheets?.some((oats) => oats === ts.id));
  };

  return (
    <div className="mt-5">
      <Loader loading={!!loading} />
      {error && <Alert variant="danger">{error}</Alert>}
      {hasOverAllocatedTimeSheets() && (
        <div className={styles.overAllocatedContainer}>
          <Alert variant="danger">There are over allocated time sheets.</Alert>
        </div>
      )}
      {!!timesheets && !!timesheets.length ? (
        <>
          {timesheets.map((timesheet) => (
            <Timesheet
              key={timesheet.id}
              timesheet={timesheet}
              config={config}
              tasks={tasks}
              projectsList={projectsList}
              setTimesheet={setTimesheet}
              editing={editing}
              foremanId={foremanId}
              fieldLog={fieldLog}
              setFieldLog={setFieldLog}
              project={project}
              overAllocatedTimeSheets={overAllocatedTimeSheets}
              setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
            />
          ))}
          {editing && (
            <div className="text-center">
              <AddCrew
                fieldLog={fieldLog}
                setFieldLog={setFieldLog}
                crew={crew}
                crewLoading={crewLoading}
                error={false}
                loading={false}
                selectTimesheet={timesheetClicked}
              >
                <Button variant="link" size="lg">
                  + Add Timesheet
                </Button>
              </AddCrew>
            </div>
          )}
        </>
      ) : (
        <div className={styles.noTimesheet}>
          <div className={styles.noTimesheetText}>No timesheets have been added for today</div>
          {editing && (
            <AddCrew
              fieldLog={fieldLog}
              setFieldLog={setFieldLog}
              crew={crew}
              error={false}
              loading={false}
              selectTimesheet={timesheetClicked}
            >
              <Button variant="transparent" className="d-flex align-items-center">
                <UserPlus size={18} color="#0071CE" />
                <span className={`ms-1 ${styles.btnLabel}`}>Add Timesheet</span>
              </Button>
            </AddCrew>
          )}
        </div>
      )}
    </div>
  );
};

TimesheetView.propTypes = {
  timesheets: PropTypes.array,
  config: PropTypes.object,
  tasks: PropTypes.array,
  projectsList: PropTypes.array,
  fieldLog: PropTypes.object,
  setFieldLog: PropTypes.func,
  crew: PropTypes.array,
  editing: PropTypes.bool,
};

export default TimesheetView;
