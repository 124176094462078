import { Joi } from "component-library";

const schema = {
  email: Joi.saneString()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email Address"),
  first_name: Joi.saneString().required().label("First Name"),
  last_name: Joi.saneString().required().label("Last Name"),
  roles: Joi.array().required().label("Role"),
  locked_out: Joi.checkbox(),
};

const initialData = {
  email: "",
  first_name: "",
  last_name: "",
  roles: [],
  locked_out: 0,
};

export { schema, initialData };
