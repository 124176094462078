import React from "react";
import { Link } from "react-router-dom";
import QuickAddSubmittal from "./QuickAddSubmittal";
import SubmittalRow from "./SubmittalRow";
import styles from "./styles.module.scss";
import Select from "react-select";
import { Alert } from "react-bootstrap";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import { Briefcase } from "react-feather";
import { ContactCard, dropdownStyles } from "component-library";
import { ArrowLeftCircle } from "react-feather";
import useQuickAddSubmittal from "./useQuickAddSubmittals";
import useProject from "../../../_projects/hooks/useProject";

const QuickAddSubmittals = () => {
  const { project, loading, error, pageTitle, PageTag } = useProject();

  const {
    addSubmittal,
    projectOwnerOptions,
    handleSelectPo,
    updateSubmittal,
    projectOwner,
    poError,
    submittals,
    id,
    setPoError,
  } = useQuickAddSubmittal();
  return (
    <InteriorPage
      pageTitle="Quick Add Submittals"
      loading={loading}
      error={error}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      NavContent={PageTag}
    >
      <Link className="sm-med d-flex align-items-center mb-3" to={`/project/${!!project && project.id}/submittals`}>
        <ArrowLeftCircle size="16" className="me-2" /> Back to Submittals List
      </Link>
      <div className="row">
        <div className="col">
          {project && projectOwnerOptions && !projectOwnerOptions.length && (
            <>
              <label>Project Engineer</label>
              <Alert variant="danger">
                <span>
                  You must add a Project Engineer to the project before creating submittals. You can add a Project
                  Engineer on the
                </span>
                <Link to={`/project/${id}/settings`}> project edit page</Link>.
              </Alert>
            </>
          )}
        </div>
      </div>

      {project && projectOwnerOptions && (
        <div className="row">
          <div className="col-lg-4">
            <div className={`form-group mb-4 ${poError ? "error" : ""}`}>
              {projectOwnerOptions.length > 1 && (
                <>
                  <label>Project Engineer</label>
                  <Select
                    options={projectOwnerOptions}
                    value={projectOwnerOptions.find((po) => projectOwner === po.value)}
                    onChange={handleSelectPo}
                    styles={dropdownStyles}
                    className={` ${poError ? "error" : ""}`}
                    autoFocus
                  />
                </>
              )}
              {projectOwnerOptions.length === 1 && (
                <>
                  <ContactCard
                    contact={project.project_contacts.find((contact) => contact.role.name === "Engineer").contact}
                    cardTitle="Project Engineer"
                  />
                </>
              )}

              {!!poError && <span className="help-block is-error">Project Engineer is required.</span>}
            </div>
          </div>
        </div>
      )}

      <div className="d-table w-100 mx-n2">
        <div className={`d-table-row ${styles["submittal-row-head"]}`}>
          <div className={`d-table-cell light-text text-uppercase sm ${styles["sub-cell"]}`}>Title</div>
          <div className={`d-table-cell light-text text-uppercase sm ${styles["sub-cell"]}`}>Source</div>

          <div className={`d-table-cell light-text text-uppercase sm ${styles["sub-cell"]}`} style={{ width: 125 }}>
            Section of Spec
          </div>
          <div className={`d-table-cell light-text text-uppercase sm ${styles["sub-cell"]}`} style={{ width: 80 }}>
            Plan Sheet
          </div>
          <div className={`d-table-cell light-text text-uppercase sm ${styles["sub-cell"]}`}>Requirements</div>
          <div className={`d-table-cell light-text text-uppercase sm ${styles["sub-cell"]}`}></div>
        </div>
        {submittals.map((subItem) => {
          return subItem.submittal.id ? (
            <SubmittalRow
              submittal={subItem.submittal}
              key={subItem.submittal.id}
              updateSubmittal={updateSubmittal}
              projectOwner={projectOwner}
            />
          ) : (
            <QuickAddSubmittal
              key={subItem._id}
              submittal={subItem.submittal}
              addSubmittal={(sub) => addSubmittal(sub, subItem._id)}
              projectOwner={projectOwner}
              setPoError={setPoError}
            />
          );
        })}
      </div>
    </InteriorPage>
  );
};

export default QuickAddSubmittals;
