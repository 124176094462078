import React from "react";
import { NavLink } from "react-router-dom";

const FieldManagementLinks = () => {
  return (
    <div className="subNav">
      <NavLink className="tertiaryNavLink" to={`/field-management/field-logs`}>
        Field Logs
      </NavLink>
      <NavLink className="tertiaryNavLink" to={`/field-management/crew-logs`}>
        Crew Timesheets
      </NavLink>
      <NavLink className="tertiaryNavLink" to={`/field-management/field-notes`}>
        Field Notes
      </NavLink>
      <NavLink className="tertiaryNavLink" to={`/field-management/locates`}>
        Locates
      </NavLink>
      <NavLink className="tertiaryNavLink" to={`/field-management/weekly-reports`}>
        Weekly Reports
      </NavLink>
    </div>
  );
};

export default FieldManagementLinks;
