import React from "react";
import styles from "./styles.module.scss";
import { Eye, Paperclip } from "react-feather";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ReactComponent as Pinned } from "../../../../assets/photoPinned.svg";
import { ReactComponent as Unpinned } from "../../../../assets/photoUnpinned.svg";
import { Trash2 } from "react-feather";

const GridItem = ({ project, item, updateSelectedPhotos, selectedPhotoIds }) => {
  return (
    <>
      {!!updateSelectedPhotos ? (
        <Button
          variant="link"
          key={item.id}
          className={`${styles.imgWrapper} p-0`}
          onClick={() => updateSelectedPhotos(selectedPhotoIds?.includes(item.id) ? 0 : 1, item)}
        >
          <img src={item?.small_thumbnail || item?.abs_url} alt="" loading="lazy" />
          {!!selectedPhotoIds?.includes(item.id) && <div className={styles.selectedOverlay} />}
          <div className={`${styles.selectedIndicator} ${selectedPhotoIds?.includes(item.id) ? styles.active : ""}`}>
            {selectedPhotoIds?.includes(item.id) && <Paperclip size={16} color="#fff" />}
          </div>
        </Button>
      ) : (
        <div key={item.id} className={styles.imgWrapper}>
          <img src={item?.small_thumbnail || item?.abs_url} alt="" loading="lazy" />
          <div className={styles.overlay}>
            <Link
              to={`/project/${project?.id}/photo-library/photo/${item.id}`}
              key={item.id}
              className={styles.hoverIcon}
            >
              <Eye color="#fff" size={"2rem"} />
            </Link>
            <div className={styles.hoverIcon}>
              {item?.pinned ? <Pinned className={styles.pinIcon} /> : <Unpinned className={styles.pinIcon} />}
            </div>
            <div className={styles.hoverIcon}>
              <Trash2 size={"2rem"} color="#fff" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GridItem;
