import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import convertMinutesToTime from "../../../../../../utils/convertMinutesToTime";

const Timesheet = ({
  timesheet,
  addTimeEntry,
  overAllocatedTimeSheets,
}) => {
  const [isOverAllocated, setIsOverAllocated] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [alreadyAssignedHours, setAlreadyAssignedHours] = useState();
  const [unAssignedMinutes, setUnAssignedMinutes] = useState(0);

  useEffect(() => {
    if (!!overAllocatedTimeSheets && !!overAllocatedTimeSheets.length &&  overAllocatedTimeSheets.includes(timesheet.id)) {
      setIsOverAllocated(true);
    } else {
      setIsOverAllocated(false);
    }
  }, [overAllocatedTimeSheets, timesheet]);

  useEffect(() => {
    setUnAssignedMinutes(
      timesheet.time_entries.reduce(
        (acc, curr) => acc - parseFloat(curr.minutes),
        (parseFloat(timesheet.adjusted_minutes))
      )
    );
  }, [timesheet]);

  useEffect(() => {
    setAlreadyAssignedHours(
      timesheet.time_entries.reduce(
      (acc, curr) => acc + parseFloat(curr.minutes),
      0
      ));
  }, [timesheet]);

  useEffect(() => {
    if (timesheet.adjusted_minutes && unAssignedMinutes <= 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheet, unAssignedMinutes]);

  return (
    <Button
      onClick={() => addTimeEntry(timesheet, timesheet.user, unAssignedMinutes)}
      variant="link"
      disabled={!!disabled || !!isOverAllocated || unAssignedMinutes <= 0 ? true : false}
      className={`${styles.option} ${!!isOverAllocated || unAssignedMinutes < 0 ? styles.error : undefined} d-flex align-items-center justify-content-between w-100 text-gray2 med text-decoration-none`}
    >
      {timesheet.user.first_name} {timesheet.user.last_name}
      <span className={styles.timePill}>
        {convertMinutesToTime(parseFloat(timesheet.adjusted_minutes) - parseFloat(alreadyAssignedHours))}
      </span>
    </Button>
  );
};

Timesheet.propTypes = {
  timesheet: PropTypes.object,
  addTimeEntry: PropTypes.func,
  overAllocatedTimeSheets: PropTypes.array,
};

export default Timesheet;
