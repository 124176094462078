import React from "react";
import styles from "./styles.module.scss";
import AttachedPhotos from "./AttachedPhotos";

const AddInput = ({
  note,
  setNote,
  setOpen,
  photos,
  setPhotos,
}) => {

  return (
    <div>
      <div className="form-group">
        <textarea
          className={`${styles.textInput} w-100`}
          onChange={({ target: { value } }) =>
            setNote(value)
          }
          value={note}
          autoFocus={false}
          rows="6"
        />
        {!!photos.length && (
          <AttachedPhotos
            photos={photos}
            deletePhotos={() => setPhotos([])}
            setOpen={setOpen}
          />
        )}
      </div>
    </div>
  );
};

export default AddInput;
