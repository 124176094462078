import React, { useEffect, useState } from "react";
import SelectModal from "../../../_resourceManagement/FieldLogs/FieldLog/TaskView/Task/TaskHeader/PhotoSelect/SelectModal";
import useModalNote from "../useModalNote";
import EditInput from "./EditInput";
import ProjectNote from "../../ProjectNote";

const Comment = ({
  comment,
  comments,
  setComments,
  deleteComment,
  editComment,
  commentBeingEdited,
  setCommentBeingEdited,
  projectId,
  handleUpdate,
  task,
  canEdit,
}) => {
  const { removeFile } = useModalNote({
    task,
    projectId,
    handleUpdate,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [photos, setPhotos] = useState(comment.files || []);
  const [commentText, setCommentText] = useState(comment.comment);
  const [selectModalOpen, setSelectModalOpen] = useState(false);

  useEffect(() => {
    if (isEditing) {
      setCommentBeingEdited(comment.id);
    } else {
      setCommentBeingEdited(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useEffect(() => {
    setPhotos(comment.files ? comment.files : []);
  }, [comment.files]);

  useEffect(() => {
    if (commentBeingEdited !== comment.id) {
      setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentBeingEdited]);

  const saveEdit = () => {
    if (commentText !== comment.comment) {
      editComment(comment.id, commentText, photos);
    }
    setCommentBeingEdited(null);
    setIsEditing(false);
  };

  const addPhoto = (addFileData) => {
    setComments(
      comments.map((comment) => {
        if (comment.id === addFileData.comment.upb_comment_id) {
          return {
            ...comment,
            files:
              comment && comment.files && comment.files.length
                ? [...comment.files, addFileData.comment]
                : [addFileData.comment],
          };
        } else {
          return comment;
        }
      })
    );
  };

  const removePhoto = async (file) => {
    removeFile(comment, file);
  };

  return (
    <>
      {!!selectModalOpen && (
        <SelectModal
          photos={photos}
          setOpen={setSelectModalOpen}
          open={selectModalOpen}
          addFile={addPhoto}
          removeFile={removePhoto}
          task={task}
          commentId={comment.id}
          projectId={projectId}
        />
      )}
      {!selectModalOpen && (
        <>
          {!!commentBeingEdited ? (
            isEditing && (
              <>
                <EditInput
                  commentText={commentText}
                  setCommentText={setCommentText}
                  setOpen={setSelectModalOpen}
                  photos={photos}
                  setPhotos={setPhotos}
                  saveEdit={saveEdit}
                  projectId={projectId}
                />
              </>
            )
          ) : (
            <ProjectNote
              project={{ id: projectId }}
              key={comment.id}
              note={comment}
              setPhotos={setPhotos}
              photoModalOpen={photos}
              setPhotoModalOpen={setPhotos}
              deleteNote={deleteComment}
              canEdit={canEdit}
              setIsEditing={setIsEditing}
            />
          )}
        </>
      )}
    </>
  );
};

export default Comment;
