import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { XCircle } from "react-feather";
import { useFormControl, useApi, bidApi, dropdownStyles, HeaderButton, useAuth } from "component-library";
import { schema, initialData } from "../../schema/initialProject";
import { useNavigate } from "react-router-dom";
import getLabel from "../../utils/getLabel";

const useInitialCreateProject = (bidObject = initialData) => {
  const { config } = useAuth();
  const { error, request, data, loading } = useApi(bidApi.createBid);
  const {
    error: closeBidError,
    request: closeBidRequest,
    data: closeData,
    loading: closeLoading,
  } = useApi(bidApi.closeBid);
  const history = useNavigate();

  const onSubmit = async (formData, setLoading) => {
    const { success } = await request({
      ...formData,
      created_without_bid: 1,
    });

    if (!success) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data?.project) {
      closeBidRequest({
        id: data.project.id,
        bid_read_low: 1,
        job_number: data.project.job_number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (closeData) {
      history(`/project/${data.project.id}/settings`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeData]);

  const CreateProjectHeader = () => {
    return (
      <div className="d-flex flex-row">
        <HeaderButton
          className="align-self-center"
          text="Cancel"
          linkTo="/projects"
          icon={<XCircle size={16} color="#0071ce" />}
          ariaLabel="cancel creating new bid"
          disabled={!!loading || !!closeLoading}
        />
        <HeaderButton
          formId="create-initial-project-form"
          text="Create Project"
          disabled={!!loading || !!closeLoading}
        />
      </div>
    );
  };

  const {
    handleSubmit,
    renderSubmitButton,
    renderTextField,
    renderTextareaField,
    renderSelectField,
    data: formData,
  } = useFormControl({
    schema,
    onSubmit,
    initialData: bidObject,
  });

  const fields = (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      {closeBidError && <Alert variant="danger">{closeBidError}</Alert>}
      <div className="row pb-2">
        <div className="col-md-9">
          {renderTextField({
            name: "name",
            label: "Project Name",
            autoFocus: true,
          })}
        </div>
        <div className="col-md-3">
          {renderTextField({
            name: "job_number",
            label: "Job #",
          })}
        </div>
      </div>
      <div className="row pb-2">
        <div className="col-md-6">
          {renderSelectField({
            name: "type_id",
            label: "Project Type",
            options: config.project_types.map((type) => ({
              value: type.id,
              label: type.name,
            })),
            styles: dropdownStyles,
          })}
        </div>
        <div className="col-md-6">
          {renderSelectField({
            name: "category_id",
            label: "Project Category",
            options: config.project_categories.map((cat) => ({
              value: cat.id,
              label: cat.name,
            })),
            styles: dropdownStyles,
          })}
        </div>
      </div>
      <div className="row pb-2">
        <div className="col-12">
          {renderTextareaField({
            name: "description",
            label: getLabel("description", formData.type_id),
            className: "w-100 p-2",
            rows: "2",
          })}
        </div>
      </div>
      <div className="row pb-2">
        {(formData.type_id === 3 || formData.type_id === 6) && (
          <div className="col-md-4">
            {renderTextField({
              name: "idot_project_no",
              label: "IDOT Project No.",
            })}
          </div>
        )}
      </div>
    </>
  );
  return {
    initialData,
    fields,
    handleSubmit,
    renderSubmitButton,
    loading,
    closeLoading,
    CreateProjectHeader,
  };
};

export default useInitialCreateProject;
