import React from "react";
import { Pie, PieChart, Cell } from "recharts";
import styles from "./styles.module.scss";

const COLORS = ["#0071CE", "#F5A611"];

const ManHoursItemChart = ({ job, colors = COLORS, isLarge, compact }) => {
  const chartData = !!job &&
    !!job.man_hours && [
      {
        name: "Actual",
        value: parseFloat(job.man_hours.billed),
      },
      {
        name: "Remaining",
        value:
          job.man_hours.remaining > 0 ? parseFloat(job.man_hours.remaining) : 0,
      },
    ];

  return (
    <div className={!!compact ? styles.compact : {}}>
      {!!chartData && (
        <figure className={`${styles.chart} ${isLarge ? "" : "mt-3 mb-0"}`}>
          <div
            className={`${!!isLarge ? "d-flex align-items-center px-4 justify-content-start" : "d-flex px-2"} ${
              !!compact ? "align-items-center" : ""
            }`}
          >
            <PieChart width={!!isLarge ? 150 : 86} height={!!isLarge ? 150 : 86} className={isLarge ? "me-3" : ""}>
              <Pie
                data={[{ value: 100 }]}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={!!isLarge ? 40 : 23}
                outerRadius={!!isLarge ? 70 : 40}
                fill="#e8eaef"
                isAnimationActive={false}
              />

              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={!!isLarge ? 40 : 23}
                outerRadius={!!isLarge ? 70 : 40}
                fill="#82ca9d"
                labelLine={false}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
              </Pie>
            </PieChart>
            <figcaption className="ms-3">
              <dl className="mb-0">
                {!!job.man_hours.estimated && !compact && (
                  <div>
                    <dd className={styles.legendDD} style={{ color: "#345d9d" }}>
                      {new Intl.NumberFormat().format(parseFloat(job.man_hours.estimated))}
                    </dd>
                    <dt className={styles.legendDT}>Estimated</dt>
                  </div>
                )}

                <div>
                  <dd className={styles.legendDD} style={{ color: colors[0] }}>
                    {new Intl.NumberFormat().format(parseFloat(job.man_hours.billed))}
                  </dd>
                  <dt className={styles.legendDT}>{compact ? "Billed" : "Actual"}</dt>
                </div>
                <div>
                  <dd className={styles.legendDD} style={{ color: colors[1] }}>
                    {new Intl.NumberFormat().format(parseFloat(job.man_hours.remaining))}
                  </dd>
                  <dt className={`${styles.legendDT} mb-0`}>Remaining</dt>
                </div>
              </dl>
            </figcaption>
          </div>
        </figure>
      )}
    </div>
  );
};

export default ManHoursItemChart;
