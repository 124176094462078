import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useAddSubmittal from "../../hooks/useAddSubmittal";
import SubmittalSidebar from "../../SubmittalSidebar";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import useProject from "../../../_projects/hooks/useProject";
import { Briefcase, Save } from "react-feather";
import { Link } from "react-router-dom";
import { ArrowLeftCircle } from "react-feather";

const AddSubmittal = () => {
  const { id } = useParams();
  const { pageTitle, project, PageTag } = useProject();

  const { fields, loading, error, handleSubmit, data, setFields } = useAddSubmittal();

  useEffect(() => {
    setFields({ ...data, project_id: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const SaveSubmittalButton = () => (
    <div>
      <button className="btn btn-link med d-inline-flex align-items-center med" form="create-submittal-form">
        <Save className="me-1" size="17" type="submit" />
        <span className="text-gray3 med">Save</span>
      </button>
    </div>
  );

  return (
    <InteriorPage
      loading={loading}
      error={error}
      pageTitle={"Submittals"}
      pageSubtitlePrimaryContent={() => <h2 className="lg font-weight-bold mb-0">Add New Submittal</h2>}
      pageSubtitleSecondaryContent={SaveSubmittalButton}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      NavContent={PageTag}
    >
      <div className="d-flex align-items-center justify-content-between">
        <Link className="sm-med d-flex align-items-center mb-3" to={`/project/${!!project && project.id}/submittals`}>
          <ArrowLeftCircle size="16" className="me-2" /> Back to Submittals List
        </Link>
      </div>
      <div className="row">
        <div className="col-lg-9 pe-lg-4 new-submittal">
          <form onSubmit={handleSubmit} id="create-submittal-form">
            {fields}
          </form>
        </div>
        <div className="col-lg-3 submittal-sidebar ps-lg-4">
          <SubmittalSidebar submittal={data} setSubmittal={setFields} />
        </div>
      </div>
    </InteriorPage>
  );
};

export default AddSubmittal;
