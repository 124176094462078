import React from "react";

const colors = ["#A7A7A7", "#F0C808", "#7DDB2D", "#0071CE", "#A7A7A7"];

const Status = ({ data }) => (
  <>
    {!!data.status && (
      <div className="pill" style={{ backgroundColor: colors[data.status.id], width: "8.6rem" }}>
        {data.status.name}
      </div>
    )}
  </>
);

export default Status;
