import React, { useMemo } from "react";
import Task from "./Task";
import PropTypes from "prop-types";

const TaskRows = ({ editTasks, bidItem, bidItems, setBidItems, fixedColumns, phaseIds }) => {
  const tasks = useMemo(() => {
    return bidItem.sub_items.map((task, index) => {
      return (
        <Task
          task={task}
          editTasks={editTasks}
          bidItem={bidItem}
          bidItems={bidItems}
          setBidItems={setBidItems}
          key={task.id}
          fixedColumns={fixedColumns}
          bgColor={index % 2 === 0 ? "light" : "dark"}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidItem, bidItems, editTasks, setBidItems, phaseIds]);

  return tasks;
};

TaskRows.propTypes = {
  editTasks: PropTypes.bool,
  bidItem: PropTypes.object.isRequired,
  bidItems: PropTypes.array.isRequired,
  setBidItems: PropTypes.func.isRequired,
};

export default TaskRows;
