import React from "react";
import PropTypes from "prop-types";
import { SingleAttachment } from "component-library";
import useProjectAttachment from "./useProjectAttachment";

const ProjectAttachment = ({
  attachment,
  setAttachment,
  type,
  projectId,
  isSingle,
  hideType,
  canUpload,
  setDocLoading,
  setFileId,
  todoId,
  isBid = true,
}) => {
  const { config, loading, percentComplete, removeAttachment, addAttachment, fileError, updateAttachment } =
    useProjectAttachment({
      projectId,
      todoId,
      setDocLoading,
      type,
      setAttachment,
      isSingle,
      setFileId,
    });

  return (
    <>
      {fileError && <p className="error mb-n2">Error. Please try again.</p>}
      <SingleAttachment
        hideInternal={isBid}
        attachment={attachment}
        fileType={[config?.file_types?.find((t) => t.name === type)?.id]}
        addAttachment={addAttachment}
        updateAttachment={updateAttachment}
        removeAttachment={removeAttachment}
        loading={loading}
        percentComplete={percentComplete}
        externalHandleUpload
        hideType={hideType}
        canUpload={canUpload}
      />
    </>
  );
};

export default ProjectAttachment;

ProjectAttachment.propTypes = {
  attachment: PropTypes.any,
  setAttachment: PropTypes.func.isRequired,
  type: PropTypes.string,
  projectId: PropTypes.number.isRequired,
};
