import { useState } from 'react';

const useToggleSections = () => {
  const [showBoom, setShowBoom] = useState(true);
  const [showSource, setShowSource] = useState(true);
  const [showProjectOwner, setShowProjectOwner] = useState(true);

  const showSection = (key) => () => {
    switch (key) {
      case "Boom":
        setShowBoom(!showBoom);
        break;
      case "Source":
        setShowSource(!showSource);
        break;
      case "Project Engineer":
        setShowProjectOwner(!showProjectOwner);
        break;
      default:
        break;
    }
  };

  const sectionVisible = (key) => {
    switch (key) {
      case "Boom":
        return showBoom;
      case "Source":
        return showSource;
      case "Project Engineer":
        return showProjectOwner;
      default:
        break;
    }
  }
  return {
    showSection,
    sectionVisible
  };
}

export default useToggleSections;