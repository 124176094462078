import React from "react";
import { ConfirmModal } from "component-library";
import PropTypes from "prop-types";
import { Trash2 } from "react-feather";

const BatchDeleteModal = ({
  isAlbumView,
  showDeleteModal,
  setShowDeleteModal,
  deleteItems,
  deleteError,
  selectedItems,
  albumBatchDeleteLoading,
  photoBatchDeleteLoading,
}) => {
  return (
    <ConfirmModal
      showConfirmation={showDeleteModal}
      setShowConfirmation={setShowDeleteModal}
      confirmFunction={() => {
        deleteItems(selectedItems);
      }}
      Icon={() => <Trash2 color="#FF6700" size={16} />}
      cancelColor={"blue"}
      loading={photoBatchDeleteLoading || albumBatchDeleteLoading}
      error={deleteError}
      name={selectedItems?.length > 1 ? "Delete Items" : "Delete Item"}
      message={
        selectedItems?.length > 1 ? (
          <p>
            Are you sure you want to delete these items? <br />
            This action cannot be undone.
          </p>
        ) : (
          <p>
            Are you sure you want to delete this item? <br />
            This action cannot be undone.
          </p>
        )
      }
      buttonText={selectedItems?.length > 1 ? "Delete Items" : "Delete Item"}
    />
  );
};

BatchDeleteModal.propTypes = {
  isAlbumView: PropTypes.bool,
};

export default BatchDeleteModal;
