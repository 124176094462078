import { useEffect, useState } from "react";
import { AgGridTable } from "component-library";
import divisionLaborReportConfig from "./divisionalLaborReportConfig";
import renderMonthColumnDefs from "../gridUtils/renderMonthColumnDefs";

const DivisionalLaborReportTable = ({ gridData, formData }) => {
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    if (!!formData && !!gridData) {
      setColumnDefs(renderMonthColumnDefs(divisionLaborReportConfig, gridData[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  return (
    <AgGridTable
      gridData={gridData[1]}
      gridConfig={columnDefs}
      id="divisional_labor_report"
      fieldsToSearch={["division"]}
      suppressSizeToFit
      updateColumnDefs
    />
  );
};

export default DivisionalLaborReportTable;
