import Percentage from "../columnRenders/Percentage";

const percentage = {
  headerName: "%",
  field: "percentage_complete",
  resizable: true,
  cellRenderer: Percentage,
  width: 100,
  cellStyle: { textAlign: "right", justifyContent: "flex-end" },
  cellClass: "ag-percentage-cell",
  headerClass: "ag-right-aligned-header",
};

export default percentage;
