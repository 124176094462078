import React, { useEffect, useState } from 'react';
import styles from "../styles.module.scss";
import ContractAmounts from './ContractAmounts';
import PropTypes from "prop-types";
import useProject from '../../../hooks/useProject';
import ProjectDotCatList from './ProjectDotCatList';
import { useApi, projectApi } from "component-library";
import autoCompleteContractAmounts from "../../../../utils/autoCompleteContractAmounts";

const ProjectDetails = ({ renderTextField, renderCheckbox, renderCheckboxInGroup, setFields, formData }) => {
  const { request: dotCatRequest, data: dotCatData } = useApi(projectApi.getProjectDotCategories);

  const [dotCategories, setDotCategories] = useState();
  const { project } = useProject();
  const [listValues, setListValues] = useState([]);

  useEffect(() => {
    if (!!dotCatData) {
      setDotCategories(dotCatData.cats);
    }
  }, [dotCatData]);

  useEffect(() => {
    dotCatRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!project.dot_categories && project.dot_categories) {
      const categoryIds = project.dot_categories.map((item) => item.dot_category.id);
      setListValues(categoryIds);
    }
  }, [project]);

  useEffect(() => {
    if (!!listValues.length) {
      setFields({
        ...formData,
        dot_categories: listValues,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listValues]);

  useEffect(() => {
    const updatedData = autoCompleteContractAmounts(formData);
    setFields(updatedData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.idot_proposal_guaranty_amount]);

  return (
    <>
      <h2 className={styles.heading18}>Project Details</h2>

      <div className="d-flex">
        <div className={styles.rowFields}>
          {renderTextField({
            name: "idot_proposal_guaranty_amount",
            label: "Proposal Guaranty Amount",
            format: "currency",
          })}
        </div>

        <div className={styles.rowFields}>
          {renderTextField({
            name: "idot_proposal_number",
            label: "Proposal Number",
          })}
        </div>
      </div>

      <div className="d-flex">
        <div className={styles.rowFields}>
          {renderTextField({
            name: "owner_project_number",
            label: "Owner Project Number",
          })}
        </div>

        <div className={styles.rowFields}>
          {renderTextField({
            name: "gc_project_number",
            label: "GC Project Number",
          })}
        </div>
      </div>

      <ContractAmounts renderTextField={renderTextField} renderCheckbox={renderCheckbox} formData={formData} />

      <ProjectDotCatList
        renderCheckboxInGroup={renderCheckboxInGroup}
        options={dotCategories}
        listValues={listValues}
        setListValues={setListValues}
      />
    </>
  );
};

export default ProjectDetails;

ProjectDetails.propTypes = {
  renderTextField: PropTypes.func.isRequired,
  renderCheckbox: PropTypes.func.isRequired,
  renderCheckboxInGroup: PropTypes.func.isRequired
};