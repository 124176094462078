import AlbumsGrid from "../../../../../../../_projects/project/PhotoLibrary/AlbumsGrid";

const AlbumGridOptions = ({ albumsList, handleUpdateView }) => {
  const onClickUpdateView = (id) => {
    handleUpdateView("album_detail", id);
  };
  return <AlbumsGrid gridItems={albumsList} handleUpdateView={onClickUpdateView} />;
};

export default AlbumGridOptions;
