import React, { useState } from "react";
import useAddAddenda from "./hooks/useAddAddenda";
import { Modal } from "react-bootstrap";

const AddAddenda = (props) => {
  const [docLoading, setDocLoading] = useState(false);
  const { fields, renderSubmitButton, handleSubmit } = useAddAddenda({ ...props, setDocLoading });
  return (
    <>
      <Modal.Header closeButton closeLabel="close add addendum modal">
        <h5 className="mb-0 med">New Addendum</h5>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {fields}
          <div className="text-end">{renderSubmitButton("Add Addenda", !!docLoading)}</div>
        </form>
      </Modal.Body>
    </>
  );
};

export default AddAddenda;
