import React from "react";
import ProvideRFQs from "./../../_bidManagement/rfqs/ProvideRFQs";
import RFQsPage from "../../_bidManagement/rfqs/RFQsPage";

const RFQs = () => {
  return (
    <ProvideRFQs>
      <RFQsPage />
    </ProvideRFQs>
  );
};

export default RFQs;
