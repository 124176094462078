import React, { useState } from "react";
import InteriorPage from "../../../../../components/pageLayout/InteriorPage";
import useProject from "../../../../hooks/useProject";
import { Briefcase, Paperclip } from "react-feather";
import DetailsBar from "../../../../../_resourceManagement/DetailsBar";
import HeaderButtons from "../../../../../_resourceManagement/FieldLogs/HeaderButtons";
import useFieldLogDetail from "../../../../../_resourceManagement/FieldLogs/hooks/useFieldLogDetail";
import TaskView from "../../../../../_resourceManagement/FieldLogs/FieldLog/TaskView";
import TimesheetView from "../../../../../_resourceManagement/FieldLogs/FieldLog/TimesheetView";
import useExportLogic from "./useExportLogic";
import { displayDate } from "component-library";
import Tabs from "../../../../../_resourceManagement/Tabs";
import styles from "./styles.module.scss";
import { Alert, Button } from "react-bootstrap";
import ProjectNotesModal from "../../../../../_resourceManagement/ProjectNotesModal";
import useFieldLogVerification from "../../../../../_resourceManagement/FieldLogs/FieldLog/useFieldLogVerification";
import HeaderPortal from "../../../../../components/HeaderPortal";

const FieldLog = () => {
  const [showNotesModal, setShowNotesModal] = useState(false);
  const { project, pageTitle, config, PageTag, loading: projectLoading } = useProject();
  const {
    fieldLog,
    setFieldLog,
    taskView,
    setTaskView,
    loading,
    error,
    editing,
    setEditing,
    crew,
    foremanId,
    projectsList,
    overAllocatedTimeSheets,
    setOverAllocatedTimeSheets,
    crewLoading,
    minutesUnassigned,
  } = useFieldLogDetail();

  const tasks = !!fieldLog?.work_day_tasks?.length ? fieldLog.work_day_tasks : [];

  const { exportDisabled, updateExportedStatus, status } = useExportLogic({
    fieldLog,
    setFieldLog,
    minutesUnassigned,
    overAllocatedTimeSheets,
  });

  const {
    approveDisabled,
    rejectDisabled,
    verifySubmit,
    errorMessageList,
    setErrorMessageList,
    invalidRequiredQuantities,
    setInvalidRequiredQuantities,
    verifyQuantity,
    requiredValueValidate,
  } = useFieldLogVerification({ tasks, project, status, minutesUnassigned, overAllocatedTimeSheets });

  return (
    <InteriorPage
      navTitle={pageTitle}
      loading={loading || projectLoading}
      error={error}
      pageTitle="Field Log Review (Payroll)"
      navIcon={<Briefcase color="#fff" size={14} />}
      pageSubtitlePrimaryContent={() => (
        <div className="lg font-weight-bold mb-0">{!!fieldLog && displayDate(fieldLog.date)}</div>
      )}
      NavContent={PageTag}
    >
      <Button variant="link" className="d-flex align-items-center med ps-0" onClick={() => setShowNotesModal(true)}>
        <Paperclip className="me-1" size={18} color="#0071ce" />
        View Project Notes
      </Button>
      {!!errorMessageList.length && errorMessageList.map((error) => <Alert variant="danger">{error}</Alert>)}
      <div className={`${styles.detailsRow} d-flex align-items-end justify-content-between`}>
        <Tabs taskView={taskView} setTaskView={setTaskView} />
        <DetailsBar fieldLog={fieldLog} project={project} foremanId={foremanId} crew={crew} crewLoading={crewLoading} />
      </div>
      {!!taskView ? (
        <TaskView
          editing={editing}
          config={config}
          tasks={tasks}
          timesheets={!!fieldLog?.time_sheets?.length ? fieldLog.time_sheets : []}
          fieldLog={fieldLog}
          crew={crew}
          crewLoading={crewLoading}
          setFieldLog={setFieldLog}
          foremanId={foremanId}
          overAllocatedTimeSheets={overAllocatedTimeSheets}
          setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
          projectId={!!project ? project.id : undefined}
          job_number={project?.job_number}
          prevailingWage={!!project ? project.certified_payroll : undefined}
          taskView={taskView}
          setTaskView={setTaskView}
          errorMessageList={errorMessageList}
          setErrorMessageList={setErrorMessageList}
          invalidRequiredQuantities={invalidRequiredQuantities}
          setInvalidRequiredQuantities={setInvalidRequiredQuantities}
          verifyQuantity={verifyQuantity}
          requiredValueValidate={requiredValueValidate}
        />
      ) : (
        <TimesheetView
          editing={editing}
          config={config}
          tasks={tasks}
          timesheets={!!fieldLog?.time_sheets?.length ? fieldLog.time_sheets : []}
          projectsList={projectsList}
          setFieldLog={setFieldLog}
          fieldLog={fieldLog}
          foremanId={foremanId}
          project={project}
          crew={crew}
          crewLoading={crewLoading}
          overAllocatedTimeSheets={overAllocatedTimeSheets}
          setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
          taskView={taskView}
          setTaskView={setTaskView}
          errorMessageList={errorMessageList}
          setErrorMessageList={setErrorMessageList}
          requiredValueValidate={requiredValueValidate}
        />
      )}
      <ProjectNotesModal
        project={project}
        projectLoading={projectLoading}
        showModal={showNotesModal}
        onHide={() => setShowNotesModal(false)}
      />
      <HeaderPortal elementId={"pageSubTitleSecondary"}>
        <HeaderButtons
          editing={editing}
          setEditing={setEditing}
          exportDisabled={exportDisabled}
          project={project}
          status={status}
          fieldLog={fieldLog}
          setFieldLog={setFieldLog}
          updateExportedStatus={updateExportedStatus}
          minutesUnassigned={minutesUnassigned}
          overAllocatedTimeSheets={overAllocatedTimeSheets}
          errorMessageList={errorMessageList}
          setErrorMessageList={setErrorMessageList}
          verifySubmit={verifySubmit}
          approveDisabled={approveDisabled}
          rejectDisabled={rejectDisabled}
        />
      </HeaderPortal>
    </InteriorPage>
  );
};

export default FieldLog;
