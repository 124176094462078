import {
  startDate,
  endDate,
  pm,
  updatedDate,
  status,
} from "../../../../../../_resourceManagement/WeeklyReports/weeklyReportsConfig/columnDefs";

const columnDefs = [startDate, endDate, pm, updatedDate, status];

export default columnDefs;
