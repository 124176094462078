import { useState, useEffect } from "react";
import { useApi, projectApi, formatApiDate, useAuth } from "component-library";

const useProjectSelection = () => {
  const { user } = useAuth();
  const { request, loading, error, data } = useApi(projectApi.getProjectsWithGeofences);
  const { request: favoriteRequest, error: favoriteError } = useApi(projectApi.favoriteProject);
  const [q, setQ] = useState("");
  const [tab, setTab] = useState("list");
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    if (!!user) {
      request({
        user_id: user.id,
        q: "",
        date: formatApiDate(new Date()),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (data?.projects) {
      setProjects(data.projects);
    }
  }, [data]);

  const searchFunction = (text) => {
    setQ(text);
  };

  const favoriteProject = (project) => {
    favoriteRequest(project.id, user.id);
    setProjects(
      projects.map((p) => {
        if (p.id === project.id) {
          return {
            ...p,
            favorited: p.favorited ? 0 : 1,
          };
        }
        return p;
      })
    );
  };

  useEffect(() => {
    if (projects) {
      setFilteredProjects(
        projects.filter((project) => {
          return `${project.job_number} ${project.name}`.toLowerCase().includes(`${q}`.toLowerCase());
        })
      );
    }
  }, [q, projects]);

  return {
    projects,
    filteredProjects,
    loading,
    error,
    favoriteError,
    searchFunction,
    favoriteProject,
    tab,
    setTab,
  };
};

export default useProjectSelection;
