import { useApi, purchaseOrdersApi } from "component-library";
import { useEffect } from "react";

const useRemoveNote = (purchaseOrder, setPurchaseOrder, setLoading) => {

  const {
    data: removeNoteData,
    loading: removeNoteLoading,
    error: removeNoteError,
    request: removeNoteRequest,
  } = useApi(purchaseOrdersApi.removeNote);

  const removeNote = (text) => {
    removeNoteRequest(text, purchaseOrder.id);
  };

  useEffect(() => {
    if (removeNoteData && removeNoteData.note) {
      setPurchaseOrder({
        ...purchaseOrder,
        notes: purchaseOrder.notes.filter((n) => n.id !== removeNoteData.note),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeNoteData]);

  useEffect(() => {
    if (!!removeNoteLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeNoteLoading]);

  return {
    removeNoteData,
    removeNoteLoading,
    removeNoteError,
    removeNote,
  };
};

export default useRemoveNote;
