import React, { useState, useEffect } from "react";
import styles from "../styles.module.scss";
import { Link } from "react-router-dom";
import { displayDateTimeWithOffset, useApi, photoLibraryApi, ConfirmModal } from "component-library";
import PinAndDelete from "./PinAndDelete";
import Toggler from "./AssetToggler";
import { DateTime } from "luxon";

const SinglePhoto = ({
  photo,
  isAccordion,
  handlePin,
  handleDelete,
  params,
  selectMode,
  selectedItems,
  setSelectedItems,
}) => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const removeFileExtension = (filename) => filename?.replace(/\.[^.]+$/, "");

  const {
    request: updateDetailRequest,
    data: updateDetailData,
    loading: updateDetailLoading,
  } = useApi(photoLibraryApi.updatePhotoDetails);

  const {
    request: photoDeleteRequest,
    data: photoDeleteData,
    loading: photoDeleteLoading,
    error: photoDeleteError,
  } = useApi(photoLibraryApi.deletePhoto);

  useEffect(() => {
    if (!!updateDetailData?.photo) {
      handlePin(photo.id, "photo");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDetailData]);

  useEffect(() => {
    if (!!photoDeleteData?.success) {
      handleDelete(photo.id, "photo");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoDeleteData]);

  const handlePinPhoto = () => {
    updateDetailRequest({ photo_id: photo.id, pinned: !photo.pinned });
  };

  const handleDeletePhoto = () => {
    photoDeleteRequest(photo.id);
  };

  const displayDateTime = (date) => {
    let formattedDate = DateTime.fromJSDate(new Date(date)).toFormat("L/dd/yy - t");
    return formattedDate;
  };

  const handleToggler = (item) => {
    const storedItem = { type: item.file_name && "photo", id: item.id, pinned: item.pinned };
    if (selectedItems.some((selectedItem) => selectedItem.type === "photo" && selectedItem.id === item.id)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== storedItem.id));
    } else {
      setSelectedItems([...selectedItems, storedItem]);
    }
  };

  const isChecked = (storedItems, data) => {
    return storedItems.some((storedItem) => storedItem.type === "photo" && storedItem.id === data.id);
  };

  return (
    <>
      <div
        className={`${styles.photoRow} ${selectMode ? styles.rowHover : ""}`}
        style={selectMode && isChecked(selectedItems, photo) ? { backgroundColor: "#E5F0FA" } : null}
      >
        {!!selectMode && (
          <Toggler data={photo} checked={isChecked(selectedItems, photo)} handleChange={() => handleToggler(photo)} />
        )}
        <img alt={photo.file_name} src={photo.small_thumbnail} />
        <Link
          to={`/project/${params?.project_id}/photo-library/photo/${photo.id}`}
          className={`${styles.filename} ${styles.rowItem}`}
        >
          {photo?.display_name || removeFileExtension(photo.file_name)}
        </Link>
        <div className={`${styles.rowItem}`}>{photo.source}</div>
        <div className={`${styles.rowItem} ${styles.date}`}>
          {photo.external_created_at ? displayDateTimeWithOffset(photo.external_created_at) : "-"}
        </div>
        <div className={`${styles.rowItem} ${styles.date}`}>{displayDateTime(photo.created_at)}</div>
        {!isAccordion && (
          <div className={`${styles.rowItem}`}>{!!photo?.albums?.length ? `${photo.albums[0].name}` : "-"}</div>
        )}
        <div className={`${styles.rowItemSmall}`}>{!!photo.note_count ? photo.note_count : "-"}</div>
        <PinAndDelete
          loading={photoDeleteLoading || updateDetailLoading}
          isPinned={!!photo.pinned}
          handlePin={handlePinPhoto}
          setShowConfirmModal={setShowConfirmDeleteModal}
          selectMode={selectMode}
        />
      </div>
      <ConfirmModal
        showConfirmation={showConfirmDeleteModal}
        setShowConfirmation={setShowConfirmDeleteModal}
        confirmFunction={handleDeletePhoto}
        loading={photoDeleteLoading}
        error={photoDeleteError}
        name={"Confirm Delete Photo"}
        message={"Are you sure you want to delete this photo?"}
      />
    </>
  );
};

export default SinglePhoto;
