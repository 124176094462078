import {
  name,
  geofence_type,
  status,
} from "./columnDefs";

const columnDefs = [
  name,
  geofence_type,
  status,
];

export default columnDefs;