import React from "react";
import { NavLink, useParams } from "react-router-dom";

const Tabs = () => {
  const { id } = useParams();

  return (
    <nav className="headerTabs">
      <NavLink className="headerTab" to={`/project/${id}/reporting/summary`}>
        Summary
      </NavLink>
      <NavLink className="headerTab" to={`/project/${id}/reporting/analysis`}>
        Analysis
      </NavLink>
    </nav>
  );
};

export default Tabs;
