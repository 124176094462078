import React from "react";
import { NavLink, useParams, Navigate, useLocation } from "react-router-dom";
import useProject from "../../../_projects/hooks/useProject";
import { useAuth } from "component-library";

const ProjectLinks = () => {
  const { userCan, submittalModule, timeModule } = useAuth();
  const { id } = useParams();
  const { pathname } = useLocation();
  const { project, pageTitle } = useProject();

  return (
    <>
      {id && (
        <>
          {project?.status !== "bid" ? (
            <div className="subNav">
              <NavLink
                className="subNavTitleLink"
                to={`/project/${id}`}
                aria-label={`Dashboard for project ${pageTitle}`}
                end
              >
                {pageTitle}
              </NavLink>
              <NavLink className="subNavLink" to={`/project/${id}/schedule`} aria-label="Project Schedule">
                Schedule
              </NavLink>
              {/* {portalModule?.active === 1 && (
                <NavLink
                  className="subNavLink"
                  to={`/project/${id}/purchase-orders`}
                  aria-label="Project Purchase Orders"
                >
                  Purchase Orders
                </NavLink>
              )} */}
              {/* {portalModule?.active === 1 && (
                <NavLink className="subNavLink" to={`/project/${id}/quotes`} aria-label="Project Quotes">
                  Quotes
                </NavLink>
              )} */}
              <NavLink className="subNavLink" to={`/project/${id}/documents`} aria-label="Project Documents">
                Documents
              </NavLink>

              <NavLink className="subNavLink" to={`/project/${id}/directory`} aria-label="Project Directory">
                Directory
              </NavLink>

              {!!userCan("estimate-conversion-view") && (
                <NavLink
                  className={`subNavLink ${
                    pathname.includes(`/project/${id}/estimate-conversion`) ||
                    pathname.includes(`/project/${id}/takeoff`)
                      ? "active"
                      : ""
                  }`}
                  to={`/project/${id}/estimate-conversion/tasks`}
                  aria-label="Project Estimate Conversion"
                >
                  Estimate Conversion
                </NavLink>
              )}

              <NavLink className="subNavLink" to={`/project/${id}/sitemanagement`} aria-label="Project Site Management">
                Site Management
              </NavLink>
              {timeModule?.active === 1 && (
                <NavLink
                  className={`subNavLink ${pathname.includes(`/project/${id}/field-management`) ? "active" : ""}`}
                  to={`/project/${id}/field-management/dashboard`}
                  aria-label="Project Field Management"
                >
                  Field Management
                </NavLink>
              )}
              {submittalModule?.active === 1 && (
                <NavLink
                  className={`subNavLink ${
                    pathname.includes(`submittal`) && !pathname.includes("documents") ? "active" : ""
                  }`}
                  to={`/project/${id}/submittals`}
                  aria-label="Project Submittals"
                >
                  Submittals
                </NavLink>
              )}

              <NavLink
                className={`subNavLink ${pathname.includes(`reporting`) ? "active" : ""}}`}
                to={`/project/${id}/reporting`}
                aria-label="Project Reporting"
              >
                Reporting
              </NavLink>
              <NavLink
                className={`subNavLink ${pathname.includes(`photo-library`) ? "active" : ""}}`}
                to={`/project/${id}/photo-library`}
                aria-label="Project photo library"
              >
                Photo Library
              </NavLink>
              <NavLink className="subNavLink" to={`/project/${id}/settings`} aria-label="Project Settings">
                Settings
              </NavLink>
            </div>
          ) : (
            <Navigate to={`/bid/${id}`} />
          )}
        </>
      )}
    </>
  );
};

export default ProjectLinks;
