import React from "react";
import PropTypes from "prop-types";
import { ProjectAttachment } from "../../_projects/ProjectAttachments";
import { useAuth } from "component-library";

const SpecBooksAndPlans = ({ specFile, setSpecFile, plansFile, setPlansFile, projectId, setDocLoading, project }) => {
  const { userCan } = useAuth();
  return (
    <>
      <div className="row">
        <div className="col-md-6 py-2">
          <h2 className="mb-2 med">Upload Spec Book</h2>
          <div className="row">
            <div className="col d-flex flex-wrap">
              {specFile.map((spec) => (
                <div key={spec.id}>
                  <ProjectAttachment
                    attachment={spec}
                    setAttachment={setSpecFile}
                    type="Specbook"
                    projectId={projectId}
                    canUpload={userCan("bid-create") || userCan("bid-edit")}
                    setDocLoading={setDocLoading}
                    isBid={project?.status === "bid"}
                  />
                </div>
              ))}
              <div className="">
                <ProjectAttachment
                  setAttachment={setSpecFile}
                  type="Specbook"
                  projectId={projectId}
                  canUpload={userCan("bid-create") || userCan("bid-edit")}
                  setDocLoading={setDocLoading}
                  isBid={project?.status === "bid"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 py-2">
          <h2 className="mb-2 med">Upload Plans</h2>
          <div className="row">
            <div className="col d-flex flex-wrap">
              {plansFile.map((plan) => (
                <div key={plan.id}>
                  <ProjectAttachment
                    attachment={plan}
                    setAttachment={setPlansFile}
                    type="Plans"
                    projectId={projectId}
                    canUpload={userCan("bid-create") || userCan("bid-edit")}
                    setDocLoading={setDocLoading}
                    isBid={project?.status === "bid"}
                  />
                </div>
              ))}
              <div className="">
                <ProjectAttachment
                  setAttachment={setPlansFile}
                  type="Plans"
                  projectId={projectId}
                  canUpload={userCan("bid-create") || userCan("bid-edit")}
                  setDocLoading={setDocLoading}
                  isBid={project?.status === "bid"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecBooksAndPlans;

SpecBooksAndPlans.propTypes = {
  specFile: PropTypes.array,
  plansFile: PropTypes.array,
  setSpecFile: PropTypes.func,
  setPlansFile: PropTypes.func,
  projectId: PropTypes.number,
};
