import React, { useState, useEffect } from "react";
import NoTasks from "./NoTasks";
import styles from "./styles.module.scss";
import Task from "./Task";
import PropTypes from "prop-types";
import TaskPopover from "./Task/TaskPopover";
import useToggleTasks from "../../hooks/useToggleTasks";
import { Button } from "react-bootstrap";
import { Eye, EyeOff } from "react-feather";
import SidebarBank from "./SidebarBank/SidebarBank";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const TaskView = ({
  tasks,
  timesheets,
  config,
  editing,
  setFieldLog,
  fieldLog,
  crew,
  foremanId,
  overAllocatedTimeSheets,
  setOverAllocatedTimeSheets,
  projectId,
  prevailingWage,
  crewLoading,
  status,
  job_number,
  errorMessageList,
  setErrorMessageList,
  invalidRequiredQuantities,
  setInvalidRequiredQuantities,
  verifyQuantity,
  requiredValueValidate,
}) => {
  const [unselectedTasks, setUnselectedTasks] = useState({});
  const { openTasks, allActive, toggleTasksOpen, handleToggleAll, setOpenTasks } = useToggleTasks({ tasks });

  useEffect(() => {
    if (!!config && !!config.tasks) {
      const taskIds = tasks.map((task) => task.task_id);

      setUnselectedTasks(
        config.tasks.map((phase) => {
          return {
            ...phase,
            tasks: phase.tasks.filter((t) => !taskIds.includes(t.id)),
          };
        })
      );
    }
  }, [fieldLog, tasks, config]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <div className={styles.tasks}>
          <div className="contentContainer">
            <div className="mainContent">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  {!!tasks?.length && (
                    <Button onClick={() => handleToggleAll()} variant="link">
                      {!!allActive ? <Eye size="16" color={"#0071ce"} /> : <EyeOff size="16" color={"#0071ce"} />}
                    </Button>
                  )}
                  <TaskPopover
                    editing={editing}
                    tasks={unselectedTasks}
                    fieldLog={fieldLog}
                    setFieldLog={setFieldLog}
                    foremanId={foremanId}
                    setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
                  />
                </div>
              </div>
              {!!tasks && !!tasks.length ? (
                tasks.map((task) => (
                  <Task
                    config={config}
                    key={task.id}
                    task={task}
                    editing={editing}
                    setFieldLog={setFieldLog}
                    fieldLog={fieldLog}
                    crew={crew}
                    crewLoading={crewLoading}
                    foremanId={foremanId}
                    overAllocatedTimeSheets={overAllocatedTimeSheets}
                    setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
                    projectId={projectId}
                    prevailingWage={prevailingWage}
                    openTasks={openTasks}
                    toggleTasksOpen={toggleTasksOpen}
                    setOpenTasks={setOpenTasks}
                    invalidRequiredQuantities={invalidRequiredQuantities}
                    setInvalidRequiredQuantities={setInvalidRequiredQuantities}
                    tasks={tasks}
                    errorMessageList={errorMessageList}
                    setErrorMessageList={setErrorMessageList}
                    verifyQuantity={verifyQuantity}
                    requiredValueValidate={requiredValueValidate}
                  />
                ))
              ) : (
                <NoTasks foremanId={foremanId} crew={crew} />
              )}
            </div>
            <div className="sidebar">
              <SidebarBank
                fieldLog={fieldLog}
                timesheets={timesheets}
                editing={editing}
                status={status}
                job_number={job_number}
                foremanId={foremanId}
                overAllocatedTimeSheets={overAllocatedTimeSheets}
              />
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

TaskView.propTypes = {
  tasks: PropTypes.array,
  config: PropTypes.object,
  editing: PropTypes.bool,
  setFieldLog: PropTypes.func,
  fieldLog: PropTypes.object,
  crew: PropTypes.array,
  foremanId: PropTypes.string,
  overAllocatedTimeSheets: PropTypes.array,
  setOverAllocatedTimeSheets: PropTypes.func,
  prevailingWage: PropTypes.any,
  status: PropTypes.any,
};

export default TaskView;
