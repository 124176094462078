import { useEffect, useReducer } from "react";
import useFieldNotes from "../../../../../_projects/project/hooks/useFieldNotes";
import { useSelectFieldNotesModalReducer, createInitialState } from "./useSelectFieldNotesModalReducer";
import { useApi, weeklyReportsApi } from "component-library";

const useSelectFieldNotesModal = ({ notes, report }) => {
  const [state, dispatch] = useReducer(useSelectFieldNotesModalReducer, { notes, report }, createInitialState);
  const {
    data: notesList,
    request: notesListRequest,
    loading: projectNotesLoading,
    error: projectNotesError,
  } = useApi(weeklyReportsApi.getWeeklyReportNotesList);

  const { activeItems, setActiveItems, toggleAll, sortedNotes, handleNoteSearch, ContextAwareToggle } = useFieldNotes({
    projectNotes: notesList?.notes,
    dateRange: state.dateRange,
  });

  useEffect(() => {
    if (report?.id) {
      notesListRequest(report.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  const handleStoredChange = (selectedValue, noteWithId) => {
    let updatedNotes;

    if (selectedValue === 0) {
      updatedNotes = state?.selected_notes.filter((note) => noteWithId?.id !== note?.id);
    } else {
      const updatedNote = { ...noteWithId, include_on_report: 1 };
      updatedNotes = !!state?.selected_notes?.length ? [...state.selected_notes, updatedNote] : [updatedNote];
    }

    dispatch({ type: "update_selected_notes", updatedNotes });
  };

  const handleUpdateDateRange = (dateRange) => {
    dispatch({ type: "update_date_range", dateRange: dateRange });
  };

  return {
    updatedSelectedNotes: state.selected_notes,
    dateRange: state.dateRange,
    ContextAwareToggle,
    handleNoteSearch,
    sortedNotes,
    projectNotesError,
    projectNotesLoading,
    handleStoredChange,
    handleUpdateDateRange,
    activeItems,
    toggleAll,
    setActiveItems,
  };
};

export default useSelectFieldNotesModal;
