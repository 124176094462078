import React from "react";
import useVendorSelection from "../../../_bidManagement/rfqs/hooks/useVendorSelection";

const VendorToggler = ({ data }) => {
  const { toggleVendorSelection, selectedVendorIds } = useVendorSelection();

  const checked = selectedVendorIds?.includes(data.id) ? 1 : 0;

  const handleChange = () => {
    toggleVendorSelection(data.id);
  };

  return (
    <div>
      <div className={`form-group form-check`}>
        <input
          type="checkbox"
          className="form-check-input"
          name={data.id}
          id={data.id}
          onChange={handleChange}
          value={checked}
          checked={checked}
          aria-label="select row"
        />
        <label className="form-check-label" htmlFor={data.id}></label>
      </div>
    </div>
  );
};

export default VendorToggler;
