import { useState, useEffect } from "react";
import { projectApi, useApi } from "component-library";
import { useParams } from "react-router-dom";

const useFormSubmission = () => {
  const [submission, setSubmission] = useState();
  const { submissionId } = useParams();

  const { request: submissionRequest, data: submissionData, loading, error } = useApi(projectApi.getFormSubmission);

  useEffect(() => {
    if (!!submissionId) {
      submissionRequest(submissionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionId]);

  useEffect(() => {
    if (submissionData) {
      setSubmission(submissionData.submission);
    }
  }, [submissionData]);

  return {
    submission,
    loading,
    error,
  };
};

export default useFormSubmission;
