import {
  allocatable_time,
  allocated,
  allocated_time,
  clock_in,
  clock_out,
  clocked_time,
  employee_no,
  equipment,
  first_name,
  foreman,
  job_name,
  job_num,
  labor_type,
  last_name,
  lunch_total,
  notes,
  payable_time,
  per_diem,
  project_manager,
  status,
  submitted_date,
  timecard_date,
  timesheet_id,
  travel_time,
} from "./columnDefs";

const columnDefs = [
  first_name,
  last_name,
  allocatable_time,
  allocated,
  allocated_time,
  clock_in,
  clock_out,
  clocked_time,
  employee_no,
  equipment,
  foreman,
  job_name,
  job_num,
  labor_type,
  lunch_total,
  notes,
  payable_time,
  per_diem,
  project_manager,
  status,
  submitted_date,
  timecard_date,
  timesheet_id,
  travel_time,
];

export default columnDefs;