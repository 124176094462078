import React from "react";
import { SelectAll } from "component-library";
import useNotesAdmin from "../../context/useNotesAdmin";

const SelectAllToggler = () => {
  const { notes, selectedNotesToPrintIds, setSelectedNotesToPrintIds, notesToPrint, setNotesToPrint } = useNotesAdmin();

  return (
    <>
      {!!notes?.length && (
        <SelectAll
          selectedIds={selectedNotesToPrintIds}
          items={notes}
          setSelectedIds={setSelectedNotesToPrintIds}
          setSelectedItems={setNotesToPrint}
          selectedItems={notesToPrint}
        />
      )}
    </>
  );
};

export default SelectAllToggler;
