import React from "react";
import useProvideNotesAdmin from "./useProvideNotesAdmin";
import notesAdminContext from "./notesAdminContext";

function ProvideNotesAdmin({ children }) {
  const notesAdmin = useProvideNotesAdmin();
  return <notesAdminContext.Provider value={notesAdmin}>{children}</notesAdminContext.Provider>;
}

export default ProvideNotesAdmin;
