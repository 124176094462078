import dateTimeComparator from "../../../utils/dateTimeComparator";

const late_start_date = {
  headerName: "Late Start Date",
  field: "late_start_date",
  headerClass: "ag-center-aligned-header",
  wrapHeaderText: true,
  width: 112,
  cellStyle: { justifyContent: "center" },
  suppressMenu: true,
  comparator: dateTimeComparator,
};

export default late_start_date;
