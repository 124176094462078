import React from "react";
import PropTypes from "prop-types";

const Notes = ({ item, handleChange, focusAdd }) => {
  return (
    <div className="row py-3">
      <div className="col">
        <label htmlFor={`notes_${item.key ? item.key : item.id}`}>Notes</label>
        <input
          id={`notes_${item.key ? item.key : item.id}`}
          className="form-control"
          name="notes"
          onChange={(e) => {
            handleChange("milestones", item.key, "notes", e.target.value);
          }}
          onFocus={focusAdd}
          value={!item.notes ? "" : item.notes}
        />
      </div>
    </div>
  );
};

Notes.propTypes = {
  item: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  focusAdd: PropTypes.func,
};

export default Notes;
