import React from "react";
import PropTypes from "prop-types";

const ProjectDates = ({ renderTextField }) => {

  return (
    <>
      <div className="row py-2">
        <div className="col-md">
          {renderTextField({
            name: "early_start_date",
            label: `Early Start Date`,
            type: "date",
          })}
        </div>
        <div className="col-md">
          {renderTextField({
            name: "late_start_date",
            label: `Late Start Date`,
            type: "date",
          })}
        </div>
        <div className="col-md">
          <div>
            {renderTextField({
              name: "completion_date",
              label: `Completion Date`,
              type: "date",
            })}
          </div>
        </div>
        <div className="col-md">
          {renderTextField({
            name: "working_days",
            label: `Total Days`,
          })}
        </div>
      </div>
    </>
  );
};

export default ProjectDates;

ProjectDates.propTypes = {
  renderTextField: PropTypes.func.isRequired,
};
