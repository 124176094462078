import React from "react";
import styles from "./styles.module.scss";
import WorkRatePill from "../../../../../../../components/WorkRatePill";
import PropTypes from "prop-types";
import { BeatLoader } from "react-spinners";
import { useAuth } from "component-library";

const TaskSubheader = ({ task, updateTasks, updateLoading, quantity, setQuantity }) => {
  const { userCan } = useAuth();

  return (
    <>
      <div className={styles.taskSubheader}>
        <div className="d-flex align-items-center me-4">
          <div className={styles.rate}>{!!task?.task?.production_rate && <>({task.task.production_rate})</>}</div>

          <div className="d-flex align-items-center position-relative">
            {!!updateLoading && (
              <div className="loaderWrapper">
                <BeatLoader size={10} color={"#0071ce"} />
              </div>
            )}
            <span className="form-wrap">
              <input
                onBlur={(e) => {
                  if (parseFloat(e.target.value) !== parseFloat(task.quantity)) {
                    updateTasks(task, e.target.value);
                  }
                }}
                onChange={(e) => setQuantity(e.target.value)}
                value={quantity}
                className={`${styles.qtyInput} form-control`}
                type="text"
                placeholder="QTY"
                disabled={!userCan("pm-review-time")}
              />
            </span>
            /{task?.task?.bid_quantity || 0}
            {task?.task?.task_uom?.toLowerCase()}
          </div>
        </div>
        <WorkRatePill workRate={task?.work_rate || 0} />
      </div>
    </>
  );
};

TaskSubheader.propTypes = {
  task: PropTypes.object,
  updateTasks: PropTypes.func,
};

export default TaskSubheader;
