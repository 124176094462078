import { useState, useEffect } from "react";
import { useApi, submittalsApi, HeaderButton } from "component-library";
import { Edit, XCircle } from "react-feather";

const useProjectManagerSubmittal = ({ submittal, attachmentsLoading, project, setProject }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const {
    request: deleteRequest,
    data: deleteData,
    loading: deleteLoading,
    error: deleteError,
  } = useApi(submittalsApi.deleteSubmittal);

  const handleDelete = () => {
    deleteRequest(submittal.id);
  };

  const ActionButtons = () => (
    <div className="d-flex align-items-center">
      <HeaderButton
        icon={<XCircle size="16" color={!!deleteLoading ? "#7b7b7b" : "#0071ce"} />}
        ariaLabel="Delete Submittal"
        text="Delete"
        disabled={deleteLoading || attachmentsLoading}
        onClick={() => setConfirmDelete(true)}
      />
      <HeaderButton
        linkTo={`/project/${submittal.project_id}/submittals/${submittal.id}/edit`}
        className={`d-inline-flex align-items-center med btn btn-link text-gray3`}
        text="Edit"
        icon={<Edit size="16" color={!!deleteLoading ? "#7b7b7b" : "#0071ce"} />}
      />
    </div>
  );

  useEffect(() => {
    if (!!deleteData && !!deleteData.success) {
      setRedirect(true);
      setProject({
        ...project,
        submittals: project.submittals.filter((s) => submittal.id !== s.id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteData]);
  return {
    ActionButtons,
    redirect,
    confirmDelete,
    setConfirmDelete,
    handleDelete,
    deleteLoading,
    deleteError,
  };
};

export default useProjectManagerSubmittal;
