import React from "react";
import { useMatch, useLocation, NavLink } from "react-router-dom";
import { Briefcase, FileText, Phone, Tag, UserCheck, Users } from "react-feather";
import AdminIcon from "../../../icons/AdminIcon";
import ProjectLinks from "./ProjectLinks";
import AdminLinks from "../../../_admin/AdminLinks";
import BidLinks from "../../sidebarNav/BidLinks";
import FieldManagementLinks from "../../sidebarNav/FieldManagementLinks";
import FleetManagementLinks from "../../../_fleetManagement/FleetManagementLinks";
import { ReactComponent as Truck } from "../../../assets/fleet.svg";
import { PageLoader, useAuth, SiteVersion } from "component-library";

const NavLinks = () => {
  const { userCan, modulesLoading, bidModule, projectModule, fleetModule, portalModule, timeModule } = useAuth();
  let isProject = useMatch({ path: "/project/:id/*" });
  let isFieldMgmt = useMatch({ path: "/field-management/*" });
  let isBid = useMatch({ path: "/bid/*" });
  let isBidCreate = useMatch({ path: "/bid/create/*" });
  let isBids = useMatch({ path: "/bids/*" });
  let isDirectory = useMatch({ path: "/directory/*" });
  let isFleetManagement = useMatch({ path: "/fleet-management/*" });
  let isResourceManagement = useMatch({ path: "/field-management/*" });
  const { pathname } = useLocation();

  return (
    <>
      {modulesLoading ? (
        <PageLoader />
      ) : (
        <>
          {bidModule?.active === 1 && (
            <NavLink to="/bids" className={`siteNavItem ${isBid || isBidCreate || isBids ? "active" : ""}`}>
              <Tag size="14" />
              Bids
            </NavLink>
          )}

          {isBid && !isBidCreate && bidModule?.active === 1 && <BidLinks />}

          {projectModule?.active === 1 && (
            <NavLink
              to="/projects"
              className={`siteNavItem ${
                pathname.includes("/project/") || pathname.includes("/projects") ? "active" : ""
              }`}
            >
              <Briefcase size="14" />
              Projects
            </NavLink>
          )}
          {isProject && projectModule?.active === 1 && <ProjectLinks />}
          {timeModule?.active === 1 && (
            <NavLink
              to="/field-management/field-logs"
              className={`siteNavItem ${isResourceManagement ? "active" : ""}`}
            >
              <Users size="14" />
              Field Management
            </NavLink>
          )}
          {isFieldMgmt && timeModule?.active === 1 && <FieldManagementLinks />}
          {(userCan("fleet-view") || userCan("fleet-edit")) && fleetModule?.active === 1 && (
            <NavLink to="/fleet-management/dashboard" className={`siteNavItem ${isFleetManagement ? "active" : ""}`}>
              <Truck width={14} height={14} />
              Fleet Management
            </NavLink>
          )}
          {isFleetManagement && fleetModule?.active === 1 && <FleetManagementLinks />}
          {portalModule?.active === 1 && (
            <NavLink to="/vendor-management" className="siteNavItem">
              <UserCheck size="14" />
              Vendor Management
            </NavLink>
          )}
          <NavLink to="/reporting" className="siteNavItem">
            <FileText size="14" />
            Reporting
          </NavLink>
          {/* <NavLink to="/accounting" className="siteNavItem">
            <DollarSign size="14" />
            Accounting
          </NavLink> */}
          {(userCan("admin-users") || userCan("role-list")) && (
            <NavLink
              to="/directory"
              className={`siteNavItem ${pathname.includes("/directory/") || isDirectory ? "active" : ""}`}
            >
              <Phone size="14" />
              Directory
            </NavLink>
          )}
          {(userCan("admin-users") || userCan("role-list")) && (
            <>
              <NavLink to="/admin" className={`siteNavItem fillIcon ${pathname.includes("admin") ? "active" : ""}`} end>
                <AdminIcon size="16" />
                Admin
              </NavLink>
              {pathname.includes("admin") && <AdminLinks />}
            </>
          )}
          <SiteVersion isLoginScreen={false} />
        </>
      )}
    </>
  );
};

export default NavLinks;
