import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { AlertTriangle } from "react-feather";

const OverAllocated = ({ user }) => {
  const popover = (
    <Popover id="popover-basic" className={""}>
      <Popover.Body className={"text-center sm-med"}>You have over-assigned hours</Popover.Body>
    </Popover>
  );

  return (
    <div className="ms-2">
      <OverlayTrigger trigger={["focus", "hover"]} placement="top-start" transition={false} overlay={popover} rootClose>
        <AlertTriangle size={18} color={"#FF6700"} />
      </OverlayTrigger>
    </div>
  );
};

export default OverAllocated;
