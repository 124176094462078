import React, { useState } from "react";
import useBidTask from './hooks/useBidTask';
import PropTypes from "prop-types";

const BidTaskUpdate = ({ task, setTodos, setBid, bid }) => {
  const [docLoading, setDocLoading] = useState(false);
  const {
    fields,
    renderSubmitButton,
    handleSubmit,
  } = useBidTask(task, setTodos, setBid, bid, setDocLoading);

    return (
      <form className="row position-relative" onSubmit={handleSubmit}>
        <div className="col-md-8 py-2 position-static">{fields}</div>
        <div className="col-md-4 d-flex flex-column justify-content-end text-end p-3">
          <div className="flex-end">{renderSubmitButton("Mark Complete", !!docLoading)}</div>
        </div>
      </form>
    );
};

BidTaskUpdate.propTypes = {
  task: PropTypes.object.isRequired,
  setTodos: PropTypes.func.isRequired, 
  setBid: PropTypes.func, 
  bid: PropTypes.object,
};

export default BidTaskUpdate;