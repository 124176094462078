import React from "react";
import styles from "./styles.module.scss";
import ProjectNote from "../../../../../components/ProjectNote";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import useTaskDetailNotes from "./useTaskDetailNotes";
import { BeatLoader } from "react-spinners";
import useProject from "../../../../hooks/useProject";

const Notes = () => {
  const { notes, notesLoading, notesError } = useTaskDetailNotes();
  const { project } = useProject();
  return (
    <>
      <header className="d-flex align-items-center justify-content-between mt-5 mb-3">
        <div className="d-flex align-items-center">
          {!!notesError && <Alert variant="danger">{notesError}</Alert>}
          <h3 className={styles.noteTitle}>Field Notes</h3>
          <span className={styles.noteCount}>({notes?.length || 0})</span>
        </div>
        <div>
          <Link className="btn btn-link med" to={`/project/${project?.id}/field-management/field-notes`}>
            View All
          </Link>
        </div>
      </header>
      <div>
        {!!notesLoading && <BeatLoader size={14} color="#0071CE" />}
        {!!notes?.length &&
          notes.map((note) => (
            <div className="position-relative" key={note.id}>
              <ProjectNote project={project} key={note.id} note={note} condensed />
            </div>
          ))}
      </div>
    </>
  );
};

export default Notes;
