import { useEffect, useState } from "react";
import { useApi, quoteApi, bidApi } from "component-library";
import { useParams } from "react-router-dom";

const useQuoteAddLineItems = () => {
  const { quoteId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [takeoffs, settakeoffs] = useState([]);
  const [newLineItems, setNewLineItems] = useState([]);
  const [quote, setQuote] = useState();
  const [redirect, setRedirect] = useState();

  const {
    data,
    loading: getLoading,
    error: getError,
    request,
  } = useApi(quoteApi.getQuote);

  const {
    data: takeoffsData,
    loading: takeoffsLoading,
    error: takeoffsError,
    request: takeoffsRequest
  } = useApi(bidApi.getTakeoffs);

  const {
    data: addLineData,
    loading: addLineLoading,
    error: addLineError,
    request: addLineRequest
  } = useApi(quoteApi.addLineItemsToRequote);


  const getTakeoffs = () => {
    takeoffsRequest(quote.project_id);
  }

  const addLineItems = () => {
    if (!!quote && quote.status !== 2) {
      addLineRequest(quote.id, { takeoff_ids: newLineItems.map((item) => item.id) });
    } else {
      addLineRequest(quote.requote.id, { takeoff_ids: newLineItems.map((item) => item.id) });
    }
  }

  const updateNewLineItems = (newItem, status) => {
    if (!!status) {
      setNewLineItems([...newLineItems, newItem]);
    } else {
      if (!!newLineItems.length) {
        setNewLineItems(newLineItems.filter((lineItem) => newItem.id !== lineItem.id))
      }
    }
  }

  useEffect(() => {
    if (!!addLineData) {
      if (quote.status !== 2) {
        setRedirect(`/project/${quote.project_id}/quotes/${quote.id}/edit`);
      } else {
        setRedirect(`/project/${quote.project_id}/quotes/${quote.id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addLineData]);

  useEffect(() => {
    if (!!takeoffsData) {
      if (!!quote && quote.status !== 2) {
        settakeoffs(
          takeoffsData.takeoffs.filter(
            (takeoff) => !quote.line_items.map((item) => item.takeoff_id).includes(takeoff.id)
          )
        );
      } else {
        settakeoffs(
          takeoffsData.takeoffs.filter(
            (takeoff) => !quote.requote.line_items.map((item) => item.takeoff_id).includes(takeoff.id)
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takeoffsData]);

  useEffect(() => {
    if (quoteId) request(quoteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId]);

  useEffect(() => {
    if (!!data && !!data.quote) setQuote(data.quote);
  }, [data]);

  useEffect(() => {
    if (!!takeoffsLoading || !!addLineLoading ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [takeoffsLoading, addLineLoading]);

  useEffect(() => {
    if (!!getError || !!takeoffsError || !!addLineError ) {
      setError(getError || takeoffsError || addLineError);
      setNewLineItems([]);
    } else {
      setError();
    }
  }, [getError,takeoffsError, addLineError]);

  return {
    quote,
    setQuote,
    error,
    getLoading,
    loading,
    setLoading,
    takeoffs,
    getTakeoffs,
    newLineItems,
    updateNewLineItems,
    addLineItems,
    redirect,
  };
};

export default useQuoteAddLineItems;
