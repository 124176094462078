import DocName from "../columnRenders/DocName";

const file_name = {
  headerName: "Filename",
  name: "file_name",
  flex: 1,
  minWidth: 400,
  maxWidth: 500,
  cellRenderer: DocName,
};

export default file_name;
