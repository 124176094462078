import { useState, useEffect } from "react";
import { schema, initialData as emptySchema } from "./ActiveSections/modules";
import { useFormControl, useApi, HeaderButton, tenantApi, useAuth } from "component-library";
import { Save } from "react-feather";

const useModules = () => {
  const [initialData, setInitialData] = useState(emptySchema);
  const [successMessage, setSuccessMessage] = useState();
  const { modules, setModules, modulesLoading } = useAuth();

  const {
    request: updateRequest,
    data: updateData,
    error: updateError,
    loading: updateLoading,
  } = useApi(tenantApi.updateTenantModules);

  const onSubmit = async (formData, formLoading) => {
    formLoading(true);
    const modules = formData.modules.map((mod) => {
      return {
        id: mod.id,
        active: mod.active,
      };
    });
    try {
      setSuccessMessage("");
      const result = await updateRequest({ modules: modules });
      if (result?.success) {
        formLoading(false);
      }
    } catch (error) {
      formLoading(false);
    }
  };

  const { handleSubmit, loading, data, setFields } = useFormControl({
    schema: schema,
    onSubmit,
    initialData,
    scrollToTop: true,
  });

  const handleToggle = (value, opt) => {
    setFields({
      ...data,
      modules: data.modules.map((mod) => {
        if (opt.id === mod.id) {
          return {
            ...opt,
            active: parseInt(value) === 1 ? 0 : 1,
          };
        } else {
          return mod;
        }
      }),
    });
  };

  const SaveButton = () => (
    <HeaderButton
      icon={<Save size={16} color="#0071ce" />}
      ariaLabel="Save super admin settings changes"
      formId="saveSuperAdminSettings"
      text="Save Modules"
      disabled={modulesLoading || updateLoading || loading}
    />
  );

  useEffect(() => {
    if (modules) {
      const initialFormData = {
        ...data,
        modules: modules.length ? [...modules] : [],
      };
      setFields(initialFormData);
      setInitialData(initialFormData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modules]);

  useEffect(() => {
    if (!!updateData?.modules) {
      setModules(updateData?.modules);
      setSuccessMessage("Modules successfully updated.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData?.modules]);

  return {
    modules: data?.modules,
    handleSubmit,
    setFields,
    data,
    SaveButton,
    updateError,
    updateLoading,
    successMessage,
    setSuccessMessage,
    handleToggle,
  };
};

export default useModules;
