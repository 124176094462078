import { useState, useEffect } from "react";
import { useApi, fieldLogsApi } from "component-library";
import getProjectNoteDate from "../../../utils/getProjectNoteDate";

const useProjectNotes = ({ projectId, dateRange, setDateRange, getStoredDateRange, dailyFieldLogLoaded = false }) => {
  const itemsPerPage = 50;
  const [totalResults, setTotalResults] = useState(0);
  const [projectNotes, setProjectNotes] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [deletingId, setDeletingId] = useState();
  const [q, setQ] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [calendarClosed, setCalendarClosed] = useState();

  const {
    request: deleteCommentRequest,
    loading: deleteLoading,
    error: deleteError,
  } = useApi(fieldLogsApi.removeProjectComment);

  const {
    request: projectNotesRequest,
    data: projectNotesData,
    loading: projectNotesLoading,
    error: projectNotesError,
  } = useApi(fieldLogsApi.getAllComments);

  const deleteNote = (noteId) => {
    setConfirmationModalOpen(true);
    setDeletingId(noteId);
  };

  const onConfirmDeleteNote = async () => {
    const { success } = await deleteCommentRequest(deletingId);
    if (success) {
      setProjectNotes(projectNotes.filter(({ id }) => id !== deletingId));
    }

    setConfirmationModalOpen(false);
    setDeletingId(null);
  };

  const onCancleDeleteNote = () => {
    setConfirmationModalOpen(false);
    setDeletingId(null);
  };

  const handleOnDateBlur = () => {
    if (!!dateRange[1]) {
      setCalendarClosed(true);
    }
  };

  const sortNotes = (notes) => {
    const sorted = notes?.sort((a, b) => {
      let dateA = new Date(getProjectNoteDate(a));
      let dateB = new Date(getProjectNoteDate(b));
      if (dateA === dateB) {
        return 0;
      }
      return dateA < dateB ? 1 : -1;
    });
    return sorted;
  };

  const updateProjectNotes = (page = 1) => {
    if (!projectNotesLoading) {
      if (!dateRange[0] || !!dateRange[1]) {
        projectNotesRequest({
          from_date: dateRange[0],
          to_date: dateRange[1],
          q: q,
          page: page,
          project_id: projectId,
          num_results: itemsPerPage,
        });
      }
    }
  };

  useEffect(() => {
    if (projectId) {
      updateProjectNotes(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, currentPage, calendarClosed, q, dateRange]);

  useEffect(() => {
    if (!!projectNotesData?.success) {
      setInitialLoad(false);

      setProjectNotes(sortNotes(projectNotesData?.notes?.notes));
      setTotalResults(projectNotesData?.notes.count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectNotesData]);

  return {
    projectNotes,
    projectNotesLoading,
    projectNotesError,
    confirmationModalOpen,
    setConfirmationModalOpen,
    deleteNote,
    onConfirmDeleteNote,
    onCancleDeleteNote,
    deleteError,
    deleteLoading,
    setProjectNotes,
    totalResults,
    setCurrentPage,
    currentPage,
    itemsPerPage,
    setQ,
    handleOnDateBlur,
    initialLoad,
  };
};

export default useProjectNotes;
