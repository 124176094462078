import React from "react";
import useProvideProjectDocuments from "./useProvideProjectDocuments";
import projectDocumentsContext from "./projectDocumentsContext";

function ProvideProjectDocuments({ children }) {
  const projectDocuments = useProvideProjectDocuments();
  return <projectDocumentsContext.Provider value={projectDocuments}>{children}</projectDocumentsContext.Provider>;
}

export default ProvideProjectDocuments;
