import React from "react";
import PhotoList from "./Options/PhotoList";
import styles from "./styles.module.scss";
import Photos from "./PreviousPhotos/Photos";
import { Modal, Button } from "react-bootstrap";

const SelectModal = ({
  photos,
  setOpen,
  open,
  addFile,
  removeFile,
  task,
  commentId,
  projectId,
}) => {

  return (
    <Modal
      animation={false}
      show={!!open}
      onHide={() => {
        setOpen(false);
      }}
      dialogClassName="modal-lg"
      className="text-gray3"
      backdrop={false}
    >
      <div className="position-relative">
        <Modal.Header closeButton className={styles.modalHeader}>
          <div className={`${styles.taskName} xl text-gray`}>
            {task.task.name}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className={styles.flex1}>
              <PhotoList
                addFile={addFile}
                commentId={commentId}
                projectId={projectId}
              />
              {!!photos && !!photos.length && (
                <Photos photos={photos} removePhoto={(p) => removeFile(p)} />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setOpen(false)}
            className={`${[styles.button, styles.cancelButton]} med`}
          >Close</Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default SelectModal;
