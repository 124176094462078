import { useEffect, useState } from "react";
import { bidApi, useApi, projectApi, useFormControl, Loader, dropdownStyles } from "component-library";
import useEstimateConversion from "../../useEstimateConversion";
import { schema, initialData } from "./moveTask";
import { useParams } from "react-router";
import useProject from "../../../../hooks/useProject";
import { Alert, Button } from "react-bootstrap";
import styles from "./styles.module.scss";

const useMoveTaskForm = ({ setMessage, taskToMove }) => {
  const { id: projectId } = useParams();
  const { request, data: moveTaskData, loading, error } = useApi(projectApi.moveUPBTask);

  const { upbRequest, itemsPerPage, currentPage, refreshProject, unitsOfMeasure, filter, setMovingTask } =
    useEstimateConversion();

  const { data: topLevelData, request: topLevelRequest, error: topLevelError } = useApi(bidApi.getTopLevelUPB);
  const { project } = useProject();
  const [parentBidOptions, setParentBidOptions] = useState([]);
  const [parentTaskOptions, setParentTaskOptions] = useState([]);

  const onSubmit = async (formData, setLoading) => {
    setLoading(true);
    try {
      await request({
        upb_items: [
          {
            id: taskToMove.id,
            parent_id: !!formData.task_parent_id ? formData.task_parent_id : formData.bid_parent_id,
          },
        ],
      });
      setLoading(false);
    } catch (message) {
      console.error(message);
      setLoading(false);
    }
  };

  const { handleSubmit, renderSubmitButton, renderTextField, renderSelectField, data } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  useEffect(() => {
    topLevelRequest(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!moveTaskData?.success) {
      setMovingTask(false);
      upbRequest({
        q: filter,
        id: project.id,
        page: currentPage,
        items_per_page: itemsPerPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveTaskData]);

  useEffect(() => {
    if (!!data?.success) {
      setMessage("Task moved.");
      upbRequest({
        q: filter,
        id: projectId,
        page: currentPage,
        items_per_page: itemsPerPage,
      });
      if (project.upb_import_status !== 1) {
        refreshProject();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!!topLevelData?.items) {
      setParentBidOptions(
        topLevelData.items.map((option) => ({
          value: option.id,
          label: option.name,
        }))
      );
    }
  }, [topLevelData]);

  useEffect(() => {
    if (data?.bid_parent_id) {
      const selectedBidItem = topLevelData.items.find((bidItem) => bidItem.id === data.bid_parent_id);
      let taskOptions = [];
      if (!!selectedBidItem?.sub_items?.length) {
        taskOptions = selectedBidItem.sub_items;
        taskOptions = taskOptions.map((opt) => {
          return {
            label: `${!!opt?.cost_code ? `${opt?.cost_code} - ` : ""}${opt.name}`,
            value: opt.id,
          };
        });
      }
      setParentTaskOptions(taskOptions);
    } else {
      setParentTaskOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.bid_parent_id]);

  const fields = (
    <>
      <section>
        <Loader loading={loading} />
        {(!!error || topLevelError) && <Alert variant="danger">{error || topLevelError}</Alert>}
        <div className={`${styles.formRow} row`}>
          <div className="col-md-12 mb-4">
            {renderSelectField({
              name: "bid_parent_id",
              label: "New Bid Item",
              options: parentBidOptions,
              styles: dropdownStyles,
            })}
          </div>
          {!!parentTaskOptions?.length && (
            <div className="row">
              <div className="col-md-7">
                {renderSelectField({
                  name: "task_parent_id",
                  label: "Parent Task (optional)",
                  options: parentTaskOptions,
                  styles: dropdownStyles,
                })}
              </div>
            </div>
          )}
        </div>
      </section>
      <footer className="d-flex align-items-center justify-content-end">
        <Button className={`med me-3 text-white font-weight-bold`} variant="gray5" onClick={() => setMovingTask(false)}>
          Cancel
        </Button>
        {renderSubmitButton("Move Task", !!loading || !data?.bid_parent_id, styles.submitButton)}
      </footer>
    </>
  );

  return {
    handleSubmit,
    loading,
    error,
    renderTextField,
    renderSelectField,
    unitsOfMeasure,
    renderSubmitButton,
    fields,
  };
};

export default useMoveTaskForm;
