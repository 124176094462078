import { useState, useEffect } from "react";
import { useApi, fieldLogsApi, useAuth } from "component-library";

const useModalNote = ({ task, projectId, handleUpdate }) => {
  const { userCan, user } = useAuth();
  const [note, setNote] = useState("");
  const [photos, setPhotos] = useState([]);
  const [removingFileId, setRemovingFileId] = useState();
  const [commentBeingEdited, setCommentBeingEdited] = useState();
  const [comments, setComments] = useState(!!task ? task.task_comments : []);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [adding, setAdding] = useState(false);
  const [updatingImages, setUpdatingImages] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [deleteCommentModalOpen, setDeleteCommentModalOpen] = useState(false);
  const [commentBeingDeleted, setCommentBeingDeleted] = useState();

  const {
    request: deleteRequest,
    error: deleteCommentError,
    loading: deleteCommentLoading,
  } = useApi(fieldLogsApi.removeComment);

  const {
    request: addCommentRequest,
    data: addCommentData,
    error: addCommentError,
    loading: addCommentLoading,
  } = useApi(fieldLogsApi.addComment);

  const {
    request: editCommentRequest,
    data: editCommentData,
    error: editCommentError,
    loading: editCommentLoading,
  } = useApi(fieldLogsApi.addComment);

  const {
    request: removeFileRequest,
    error: removeFileError,
    data: removeFileData,
  } = useApi(fieldLogsApi.removeCommentFile);

  const handleSubmit = () => {
    setError(null);
    if (!!note && !!note.length) {
      const newComment = {
        comment: note,
        project_id: projectId,
        item_id: task.id,
        files: photos,
        user_id: user.id,
      };
      addCommentRequest(newComment);
    }
  };

  const removeFile = async (comment, file) => {
    setCommentBeingEdited(comment.id);
    setError(null);
    setRemovingFileId(file.id);
    removeFileRequest(file.id);
  };

  const removeAllFiles = async (photos) => {
    setError(null);
    for (let i = 0; i < photos.length; i++) {
      await removeFile(photos[i].id);
    }
  };

  const deleteComment = async (commentId) => {
    setCommentBeingDeleted(commentId);
    setDeleteCommentModalOpen(true);
  };

  const deleteCommentRequest = async () => {
    setError(null);
    const { success } = await deleteRequest(commentBeingDeleted);
    if (success) {
      setComments(comments.filter((comment) => comment.id !== commentBeingDeleted));
    }
    setDeleteCommentModalOpen(false);
    return success;
  };

  const cancelDeleteComment = () => {
    setDeleteCommentModalOpen(false);
    setCommentBeingDeleted(null);
  };

  const editComment = (id, note) => {
    setError(null);
    if (!!note && !!note.length) {
      const newComment = {
        id: id,
        comment: note,
        project_id: projectId,
        item_id: task.id,
        files: [],
        user_id: user.id,
      };
      editCommentRequest(newComment);
    }
  };

  useEffect(() => {
    if (!!addCommentData) {
      setNote("");
      setPhotos([]);
      setComments([...comments, addCommentData.comment]);
      setAdding(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCommentData]);

  useEffect(() => {
    if (!!editCommentData) {
      setComments(
        comments.map((comment) => (comment.id === editCommentData.comment.id ? editCommentData.comment : comment))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCommentData]);

  useEffect(() => {
    if (!!task && !!task.task_comments && task.task_comments.length) {
      setComments(task.task_comments);
    }
  }, [task]);

  useEffect(() => {
    handleUpdate(comments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  useEffect(() => {
    if (!!removeFileData) {
      setComments(
        comments.map((comment) => {
          if (comment.id === commentBeingEdited) {
            return {
              ...comment,
              files: comment.files.filter((file) => file.id !== removingFileId),
            };
          } else {
            return comment;
          }
        })
      );
    }
    setRemovingFileId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeFileData]);

  useEffect(() => {
    if (userCan("payroll-review-time") || userCan("pm-review-time")) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!!addCommentError || !!removeFileError || !!deleteCommentError || !!editCommentError) {
      setError(addCommentError || removeFileError || deleteCommentError || editCommentError);
    } else {
      setError();
    }
  }, [addCommentError, removeFileError, deleteCommentError, editCommentError]);

  useEffect(() => {
    if (!!addCommentLoading || !!editCommentLoading || !!deleteCommentLoading) {
      setLoading(addCommentLoading || editCommentLoading || deleteCommentLoading);
    } else {
      setLoading();
    }
  }, [addCommentLoading, editCommentLoading, deleteCommentLoading]);

  return {
    note,
    setNote,
    commentBeingEdited,
    setCommentBeingEdited,
    handleSubmit,
    editComment,
    removeFile,
    deleteComment,
    photos,
    setPhotos,
    comments,
    setComments,
    error,
    loading,
    removeAllFiles,
    addCommentLoading,
    adding,
    setAdding,
    updatingImages,
    setUpdatingImages,
    canEdit,
    cancelDeleteComment,
    deleteCommentRequest,
    deleteCommentModalOpen,
    deleteCommentError,
    deleteCommentLoading,
  };
};

export default useModalNote;
