import Project from "../columns/Project";

const project = {
  headerName: "Project",
  field: "project",
  cellRenderer: Project,
  autoHeight: true,
  flex: 1,
  maxWidth: 300,
};

export default project;
