import { useState, useEffect } from "react";
import { fieldLogsApi, useApi, useAuth } from "component-library";
import { useParams } from "react-router-dom";
import useResourceManagement from "../context/useResourceManagement";

const useCrewLogsDetail = ({ projectId, dateRange, initialLoad }) => {
  const [gridData, setGridData] = useState();
  const [consoleData, setConsoleData] = useState();
  const [selectedCrewMember, setSelectedCrewMember] = useState();
  const [selectedForeman, setSelectedForeman] = useState();
  const [foremenList, setForemenList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const { config } = useAuth();

  const {
    projectsList,
    projectsLoading,
    selectedConsoleFilters,
    setCrewDetailExpanded,
    selectedProject,
    setSelectedProject,
  } = useResourceManagement();
  const { data, request, loading, error } = useApi(fieldLogsApi.getCrewTimesheets);

  const { crewId } = useParams();

  const getSelectedUser = (data) => {
    const selectedCrewData = data.find((item) => item.user_id.toString() === crewId);
    if (selectedCrewData) {
      return {
        first_name: selectedCrewData.first_name,
        last_name: selectedCrewData.last_name,
        avatar: selectedCrewData.avatar,
        email: selectedCrewData.email,
        phone: selectedCrewData.phone,
      };
    }
  };

  const updateRequest = (currentPage = 1) => {
    if (!initialLoad && !loading && !!crewId && (!dateRange[0] || !!dateRange[1])) {
      let requestParams = {
        project_id: selectedProject || projectId,
        foreman_id: selectedForeman,
        user_id: crewId,
        page: currentPage,
      };
      if (!!selectedConsoleFilters?.length) {
        selectedConsoleFilters.forEach((filter) => {
          requestParams[filter.toLowerCase()] = 1;
        });
      }
      if (!dateRange[0]) {
        requestParams = {
          ...requestParams,
          from_date: "",
          to_date: "",
        };
      }
      if (!!dateRange[1]) {
        requestParams = {
          ...requestParams,
          from_date: dateRange[0],
          to_date: dateRange[1],
        };
      }
      request(requestParams);
    }
  };

  useEffect(() => {
    updateRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConsoleFilters, selectedProject, selectedForeman, crewId, page, dateRange, initialLoad]);

  useEffect(() => {
    if (!!data?.time_sheets) {
      setCrewDetailExpanded(false);
      setGridData(data?.time_sheets?.time_sheets[0]?.time_sheets);
      if (!selectedCrewMember) {
        setSelectedCrewMember(getSelectedUser(data?.time_sheets?.time_sheets));
      }
      setConsoleData(data?.time_sheets?.console);
      setTotal(data?.time_sheets?.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!!data?.time_sheets?.time_sheets?.length) {
      if (!selectedCrewMember) {
        setSelectedCrewMember(getSelectedUser(data.time_sheets.time_sheets));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crewId]);

  useEffect(() => {
    if (config?.foreman) {
      setForemenList(
        config.foreman.map((foreman) => {
          return {
            label: `${foreman?.first_name} ${foreman?.last_name}`,
            value: foreman.id,
          };
        })
      );
    }
  }, [config?.foreman]);

  return {
    projectsList,
    projectsLoading,
    selectedCrewMember,
    setSelectedCrewMember,
    gridData,
    setGridData,
    foremenList,
    loading,
    selectedProject,
    setSelectedProject,
    selectedForeman,
    setSelectedForeman,
    crewId,
    error,
    consoleData,
    page,
    setPage,
    total,
  };
};

export default useCrewLogsDetail;
