import React, { useReducer } from "react";
import { PlusCircle, Trash2 } from "react-feather";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import sectionStyles from "../styles.module.scss";
import SelectReportPhotosModal from "./SelectReportPhotosModal";
import { reducer, createInitialState } from "./usePhotosReducer";
import usePhotos from "./usePhotos";

const Photos = ({ project, day, updateDateField, report }) => {
  const [state, dispatch] = useReducer(reducer, createInitialState);
  const { handleToggleUpdatePhotosModal } = usePhotos({ dispatch });

  const deletePhoto = (photo) => {
    updateDateField(
      day,
      "files",
      day.files.filter((fieldPhoto) => fieldPhoto.id !== photo.id)
    );
  };

  return (
    <>
      <section className={sectionStyles.fieldSection}>
        <div className="d-flex">
          <h3>Photo(s)</h3>
          <div className={`${styles.photosGrid} w-100`}>
            {!!day?.files?.length &&
              day.files.map((photo) => {
                return (
                  <Button
                    variant="link"
                    key={photo.id}
                    className={styles.photoWrapper}
                    onClick={() => {
                      deletePhoto(photo);
                    }}
                  >
                    <img
                      src={
                        photo?.file?.small_thumbnail || photo?.file?.abs_url || photo?.small_thumbnail || photo?.abs_url
                      }
                      alt={""}
                      loading="lazy"
                    />
                    <div className={styles.overlay}>
                      <div className={styles.inner}>
                        <Trash2 color="#FF6700" size={16} />
                      </div>
                    </div>
                  </Button>
                );
              })}
            <Button variant="link" className={styles.addBlock} onClick={() => handleToggleUpdatePhotosModal()}>
              <PlusCircle size={16} color="#A7A7A7" />
            </Button>
          </div>
        </div>
      </section>
      {state?.showSelectModal && (
        <SelectReportPhotosModal
          project={project}
          day={day}
          report={report}
          showSelectModal={state?.showSelectModal}
          onHide={() => handleToggleUpdatePhotosModal()}
          updateDateField={updateDateField}
        />
      )}
    </>
  );
};

export default Photos;
