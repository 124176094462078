import React from "react";
import CashflowGridItem from "./CashflowGridItem";
import styles from "./styles.module.scss";

const CashflowGrid = ({ jobs }) => {
  return (
    <section className="col">
      <div className={styles.boomboardGrid}>
        {jobs.map((job) => (
          <CashflowGridItem job={job} key={job.job_id} />
        ))}
      </div>
    </section>
  );
};

export default CashflowGrid;
