import equipmentConfig from "./equipmentConfig";

const otherCategories = ["Misc", "Attachments", "Tools"];

export const getCategoryColorFromName = (iname) => equipmentConfig?.[iname]?.color || "#5B5A5A";

export const getCategoryAbbrevFromName = (iname) => {
  if (iname === "unknown" || iname === "pending" || iname === "loading") return "??";
  return equipmentConfig?.[iname]?.abbr || "OT";
};

export const mapUnknownCategories = (beacons) => {
  const allCats = [...Object.keys(equipmentConfig), ...otherCategories];
  return beacons.map((b) => (!allCats.includes(b.category) ? { ...b, category: "Misc" } : b));
};

export const parseRelativeDate = (rd) => {
  if (!!rd) {
    let parsed = rd.replace("day ago", "d");
    parsed = parsed.replace("days ago", "d");
    parsed = parsed.replace("week ago", "w");
    parsed = parsed.replace("weeks ago", "w");
    parsed = parsed.replace("hours ago", "h");
    parsed = parsed.replace("hour ago", "h");
    parsed = parsed.replace("minutes ago", "m");
    parsed = parsed.replace("minute ago", "m");
    return parsed;
  } else {
    return "n/a";
  }
};

export const split_addr = (istring) => {
  let retvalue = "";
  const str_array = istring.split(",");
  if (str_array < 2) {
    return <>istring</>;
  }
  for (var i; i < str_array.length; i++) {
    str_array[i].trim();
  }
  retvalue = (
    <>
      {str_array[0]}
      <br />
      {`${str_array[1]}, ${str_array[2]}`}
    </>
  );
  return retvalue;
};

export const timeframeOptions = [
  {
    value: 1,
    label: `Today`,
  },
  {
    value: 2,
    label: `Previous 2 Days`,
  },
  {
    value: 7,
    label: `Previous 7 Days`,
  },
  {
    value: 30,
    label: `Previous 30 Days`,
  },
  {
    value: 90,
    label: `Previous 90 Days`,
  },
];

export const scheduleTimeframeOptions = [
  {
    value: 1,
    label: `Today`,
  },
  {
    value: 2,
    label: `Previous 2 Days`,
  },
  {
    value: 3,
    label: "This Week",
  },
  {
    value: "-1",
    label: `Tomorrow Only`,
  },
  {
    value: "-7",
    label: `Next 7 Days`,
  },
  {
    value: "-30",
    label: `Next 30 Days`,
  },
  {
    value: 7,
    label: `Previous 7 Days`,
  },
  {
    value: 30,
    label: `Previous 30 Days`,
  },
];

export const scheduleGroupOptions = [
  {
    value: 0,
    label: `No Grouping`,
  },
  {
    value: "day",
    label: `Group by Day`,
  },
  {
    value: "driver",
    label: `Group by Driver`,
  },
  {
    value: "type",
    label: `Group by Trucking Type`,
  },
  {
    value: "project",
    label: `Group by Project`,
  },
];

const truckingTypeMapOrder = ["lowboy", "materials", "dump", "quarry", "general", "repair"];
const truckingTypeColorArray = ["#48699F", "#C1259E", "#AD6304", "#D8AF01", "#98B05E", "#444444"];
const truckingTypeMapAbbrev = ["E", "M", "D", "Q", "G", "R"];

export const get_trucking_type_abbrev_from_name = (name) => {
  let index = truckingTypeMapOrder.indexOf(name);
  if (index === -1) {
    index = truckingTypeMapOrder.indexOf("general");
  }
  return truckingTypeMapAbbrev[index];
};

export const get_trucking_type_color_from_name = (name) => {
  let index = truckingTypeMapOrder.indexOf(name);
  if (index === -1) {
    index = truckingTypeMapOrder.indexOf("general");
  }
  return truckingTypeColorArray[index];
};

export const trucking_type = (activity_type) => {
  const letter = get_trucking_type_abbrev_from_name(activity_type);
  const color = get_trucking_type_color_from_name(activity_type);

  return { letter: letter, color: color };
};

export const getDayOfWeek = (date, style = 0) => {
  const dayOfWeek = new Date(date + "T12:00:00Z").getDay();
  if (style === 3) {
    return isNaN(dayOfWeek) ? null : ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][dayOfWeek];
  } else {
    return isNaN(dayOfWeek)
      ? null
      : ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][dayOfWeek];
  }
};

export const getMonthOfYear = (monthval, style = 0) => {
  const monthnum = parseInt(monthval) - 1;
  if (style === 3) {
    return isNaN(monthnum)
      ? null
      : ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"][monthnum];
  } else {
    return isNaN(monthnum)
      ? null
      : [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ][monthnum];
  }
};