import React from "react";
import useProject from "../../../../hooks/useProject";
import InteriorPage from "../../../../../components/pageLayout/InteriorPage";
import { Briefcase, PlusCircle, Printer } from "react-feather";
import Submenu from "../Submenu";
import { FilterSearch, HeaderButton, DateRangeSelector, Loader, displayDate } from "component-library";
import Note from "../../../../../components/FieldNoteAccordion/Note";
import AddProjectNoteModal from "../../../AddProjectNoteModal";
import useProjectNotes from "../../../hooks/useProjectNotes";
import { DateTime } from "luxon";
import useFieldNotes from "../../../hooks/useFieldNotes";
import styles from "./styles.module.scss";
import ToggleAllAccordion from "../../../../../components/ToggleAllAccordion/ToggleAllAccordion";
import { getMaxDate } from "../../../../../utils/getMaxDate";
import Pagination from "../../../../../components/Pagination";
import { useParams } from "react-router-dom";
import TwoWeekMessage from "../TwoWeekMessage";

const FieldNotes = () => {
  const { id: projectId } = useParams();
  const {
    project,
    pageTitle,
    error,
    loading: projectLoading,
    fieldMgmtDateRange,
    setFieldMgmtDateRange,
    getStoredDateRange,
    dailyFieldLogLoaded,
  } = useProject();

  const {
    projectNotes,
    projectNotesLoading,
    projectNotesError,
    deleteLoading,
    setProjectNotes,
    totalResults,
    setCurrentPage,
    currentPage,
    itemsPerPage,
    setQ,
    handleOnDateBlur,
    initialLoad,
  } = useProjectNotes({
    projectId,
    dateRange: fieldMgmtDateRange,
    setDateRange: setFieldMgmtDateRange,
    getStoredDateRange,
    dailyFieldLogLoaded,
  });

  const { activeItems, setActiveItems, addingNote, setAddingNote, sortedNotes, handleNoteSearch, ContextAwareToggle } =
    useFieldNotes({
      projectNotes,
      setQ,
    });

  const Header = ({ item }) => (
    <ContextAwareToggle noteCount={item?.notes?.length || 0} eventKey={item.note_date}>
      <div className="d-flex w-100 flex-1 med text-gray2">
        {!!item?.note_date ? (
          <>
            {displayDate(item.note_date)}
            <span className="ms-5 font-weight-bold">{DateTime.fromISO(item.note_date).toFormat("EEEE")}</span>
          </>
        ) : (
          "Unknown"
        )}
      </div>
    </ContextAwareToggle>
  );

  const Body = ({ item }) => (
    <div className="mt-3">
      {item?.notes?.map((note) => (
        <Note key={item.id} note={note} />
      ))}
    </div>
  );

  const PageHeaderButtons = () => (
    <div className="d-flex align-items-center">
      <HeaderButton
        icon={<Printer size={16} color="#0071ce" />}
        ariaLabel="Print project notes"
        text="Print Notes"
        onClick={() => window.print()}
        disabled={!project || !!projectNotesLoading || deleteLoading}
      />
      <HeaderButton
        icon={<PlusCircle size={16} color="#0071ce" />}
        ariaLabel="Add a field note to the project"
        text="Add Note"
        onClick={() => setAddingNote(true)}
        disabled={!project || !!projectNotesLoading || deleteLoading}
      />
    </div>
  );

  return (
    <>
      <div className={styles.hidePrint}>
        <InteriorPage
          error={projectNotesError || error}
          loading={projectLoading || initialLoad}
          navTitle={pageTitle}
          navIcon={<Briefcase color="#fff" size={14} />}
          pageTitle="Field Management"
          pageSubtitlePrimaryContent={() => <Submenu />}
          pageSubtitleSecondaryContent={PageHeaderButtons}
        >
          <div className="position-relative">
            <Loader loading={!!projectNotesLoading || !!initialLoad} size={16} />
            <TwoWeekMessage dateRange={fieldMgmtDateRange} />
            <div className="mt-2">
              <div className="d-flex align-items-center justify-content-between w-100 mb-3">
                <DateRangeSelector
                  dateRange={fieldMgmtDateRange}
                  setDateRange={setFieldMgmtDateRange}
                  disabled={!!projectNotesLoading || !!initialLoad}
                  maxDate={getMaxDate(fieldMgmtDateRange, 14)}
                  handleOnDateBlur={handleOnDateBlur}
                />
                <FilterSearch searchFunction={handleNoteSearch} placeholder="Search Notes" />
              </div>

              <ToggleAllAccordion
                items={sortedNotes}
                activeItems={activeItems}
                setActiveItems={setActiveItems}
                Header={Header}
                Body={Body}
                itemStyles="mb-4"
                idField="note_date"
              />
              {!sortedNotes?.length && !projectNotesLoading && <div>No field notes found.</div>}
              <Pagination
                items={sortedNotes}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                total={totalResults}
              />
            </div>
          </div>

          <AddProjectNoteModal
            notes={projectNotes}
            showModal={addingNote}
            onHide={() => setAddingNote()}
            setNotes={setProjectNotes}
            date={new Date()}
          />
        </InteriorPage>
      </div>
      <div className="showPrint">
        <span className="h2">Field Notes</span>
        <h1 className="font-weight-bold">{pageTitle}</h1>
        {!!sortedNotes?.length &&
          sortedNotes?.map((noteDate) => (
            <div className="mb-3" key={noteDate.note_date}>
              <Header item={noteDate} />
              <Body item={noteDate} />
            </div>
          ))}
        <DateRangeSelector dateRange={fieldMgmtDateRange} setDateRange={setFieldMgmtDateRange} />
        <div className="mt-5">{!sortedNotes?.length && "No notes found"}</div>
      </div>
    </>
  );
};

export default FieldNotes;
