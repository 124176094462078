import React from "react";
import styles from "./styles.module.scss";
import TaskPercentage from "../TaskPercentage";

const TaskHeader = ({ task }) => {
  return (
    <>
      {!!task && (
        <header className="d-flex align-items-center mb-5">
          <TaskPercentage
            label="Complete"
            reported={parseFloat(task?.quantity_reported) || 0}
            estimated={parseFloat(task?.bid_quantity) || 1}
          />
          <div className={styles.wrapper}>
            <div>
              <span className={styles.code}>{task?.cost_code}</span>
              <span className={styles.bidItem}>{task?.parent_item_name}</span>
            </div>
            <h2 className={styles.taskName}>{task?.name}</h2>
            {!!task?.updated_at && <div className={styles.date}>Updated {task.updated_at}</div>}
          </div>
        </header>
      )}
    </>
  );
};

export default TaskHeader;
