import React from "react";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import useProject from "../../hooks/useProject";
import { Briefcase } from "react-feather";

const ManageChecklist = () => {
  const { pageTitle } = useProject();

  return (
    <InteriorPage
      navTitle={pageTitle}
      pageTitle="Manage Checklist"
      navIcon={<Briefcase color="#fff" size={14} />}
    >
      <div>
        Coming Soon!
      </div>
    </InteriorPage>
  );
}

export default ManageChecklist;
