import React, { useState, useEffect } from "react";
import { SelectAll } from "component-library";
import styles from "./styles.module.scss";
import useFieldLogSelection from "../../../_resourceManagement/context/useFieldlogSelection";

const SelectAllToggler = () => {
  const { selected, setSelected, selectedLogs, setSelectedLogs, fieldLogs } = useFieldLogSelection();
  const [selectable, setSelectable] = useState([]);

  useEffect(() => {
    if (!!fieldLogs && !!fieldLogs.length) {
      setSelectable(fieldLogs.filter((fl) => [5, 2, 1].includes(fl.work_day_status_id)));
    }
  }, [fieldLogs]);

  return (
    <>
      {!!selectable?.length && (
        <div className={styles.checkWrapper}>
          <SelectAll
            selectedIds={selected}
            setSelectedIds={setSelected}
            selectedItems={selectedLogs}
            setSelectedItems={setSelectedLogs}
            items={selectable}
          />
        </div>
      )}
    </>
  );
};

export default SelectAllToggler;
