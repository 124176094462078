import React from "react";
import { Alert } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import FieldLogsTable from "./FieldLogsTable";
import styles from "./styles.module.scss";
import useFieldlogList from "./useFieldlogList";
import Pagination from "../../../../../components/Pagination";

const Fieldlogs = ({ loading }) => {
  const { fieldlogs, fieldlogLoading, error, currentPage, setCurrentPage, total } = useFieldlogList();
  return (
    <section className={`${styles.fieldLogs} position-relative`}>
      <h2 className={styles.tableTitle}>Field Logs</h2>
      {!!error && <Alert variant="danger">{error}</Alert>}
      {!!fieldlogLoading && <BeatLoader color="#0071CE" size={16} />}
      {!!fieldlogs?.length ? (
        <>
          <FieldLogsTable loading={loading} fieldlogs={fieldlogs} />
          <Pagination
            items={fieldlogs}
            itemsPerPage={25}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            total={total}
            disableReset
          />
        </>
      ) : (
        <>No Fieldlogs found</>
      )}
    </section>
  );
};

export default Fieldlogs;
