import React, { useMemo } from "react";
import { AgGridTable, useAuth } from "component-library";
import fieldlogsConfig from "./fieldlogsConfig";
import useProject from "../../../../hooks/useProject";

const FieldLogsTable = ({ fieldlogs, loading }) => {
  const { project } = useProject();
  const { user } = useAuth();

  const handleCellClick = ({ data }) => {
    window.open(
      `/project/${project?.id}/field-management/field-logs/${data.workday_id}/foreman/${data.foreman_id}`,
      "_blank"
    );
  };

  const defaultColDef = useMemo(
    () => ({
      sortable: false,
      filter: false,
    }),
    []
  );

  return (
    <AgGridTable
      gridData={fieldlogs}
      gridConfig={fieldlogsConfig}
      loading={loading}
      rowHeight={45}
      id={`${user.id}_project_field_logs_table`}
      presetStyle="sortableTable"
      handleCellClick={handleCellClick}
      rowIdField="wdt_id"
      defaultColDef={defaultColDef}
    />
  );
};

export default FieldLogsTable;
