import { displayDate } from "component-library";
import dateTimeComparator from "../../../utils/dateTimeComparator";

const bid_date = {
  headerName: "Date",
  field: "bid_date",
  width: 200,
  cellStyle: {fontWeight: "600"},
  comparator: dateTimeComparator,
  valueGetter: ({ data }) => data?.bid_date && displayDate(data.bid_date, true),
};

export default bid_date;
