import { useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { FolderPlus } from "react-feather";
import styles from "./styles.module.scss";
import { schema, initialData } from "./album";
import { useFormControl, useApi, photoLibraryApi } from "component-library";
import usePhotoLibrary from "../../context/usePhotoLibrary";
import useProject from "../../../../hooks/useProject";
import { BeatLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const useCreateAlbum = ({ setShowModal }) => {
  const history = useNavigate();
  const { id, photoId } = useParams();
  const { project } = useProject();
  const { setMessage, refreshLists } = usePhotoLibrary();

  const {
    request: createAlbumRequest,
    data: createAlbumData,
    loading: createAlbumLoading,
    error: createAlbumError,
  } = useApi(photoLibraryApi.createAlbum);

  const onSubmit = (formData) => {
    let requestParams = {
      name: formData.name,
      project_id: project.id,
      photo_ids: photoId ? [photoId] : [],
    };
    createAlbumRequest(requestParams);
  };

  const { handleSubmit, renderTextField, data } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const onHide = () => {
    setShowModal(false);
  };

  const headerContent = (
    <>
      <div className="d-flex align-items-center">
        <FolderPlus color="#0071ce" size={16} />
        <h2 className={styles.modalTitle}>Create New Album</h2>
      </div>
    </>
  );
  const bodyContent = (
    <div className="position-relative">
      {!!createAlbumError && <Alert variant="danger">{createAlbumError}</Alert>}
      {!!createAlbumLoading && (
        <div className="loaderWrapper">
          <BeatLoader color={"#0071ce"} />
        </div>
      )}
      <h3 className={styles.formTitle}>Album Title</h3>
      <form id="create-photo-album" onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="row">
            {renderTextField({
              name: "name",
              label: "Enter your Album Title here",
              disabled: !!data.albums,
            })}
          </div>
        </div>
      </form>
    </div>
  );
  const footerContent = (
    <>
      <Button
        variant="orange"
        className="med d-flex align-items-center"
        form="create-photo-album"
        type="submit"
        aria-label="Create new project photo album"
        disabled={!data?.name || createAlbumLoading}
      >
        Create Album
      </Button>
    </>
  );

  useEffect(() => {
    if (!!createAlbumData?.album) {
      refreshLists();
      setMessage("Album created successfully.");
      setShowModal(false);
      history(`/project/${id}/photo-library/album/${createAlbumData?.album?.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAlbumData]);

  return {
    headerContent,
    bodyContent,
    footerContent,
    onHide,
  };
};

useCreateAlbum.propTypes = {
  setShowModal: PropTypes.func,
};

export default useCreateAlbum;
