import React from "react";
import styles from "./styles.module.scss";

const Number = ({ number, color, label }) => {
  return (
    <div className={styles.numberSection}>
      <div className={styles.numberLabel}>{label}:</div>
      <div className={styles.number} style={{ color: color }}>
        {number}
      </div>
    </div>
  );
};

export default Number;