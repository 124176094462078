import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { initialData, schema } from "../schema/contact";
import {
  useApi,
  useFormControl,
  dropdownStylesWithWidth,
  CompanyLocationContactCard,
  Loader,
  useAuth,
} from "component-library";
import contactsApi from "../api/contacts";
import LocationSearch from "../components/Locations/LocationSearch";
import CompanyLocationSelect from "../components/ContactsAdmin/CompanyLocationSelect";

const useAddContact = ({ initialCompany, setContact, setAddNew, initialContact, contactType }) => {
  const { config } = useAuth();
  const [addingCompany, setAddingCompany] = useState(false);
  const [addingLocation, setAddingLocation] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [company, setCompany] = useState(!!initialContact && initialContact.company);
  const [locations, setLocations] = useState(
    !!initialContact &&
      !!initialContact.locations &&
      !!initialContact.locations.length &&
      initialContact.locations.map((loc) => {
        return {
          label: !!loc.location ? loc.location.name : loc.name,
          value: !!loc.location ? loc.location.id : loc.id,
        };
      })
  );
  const [locationOptions, setLocationOptions] = useState(
    !!initialCompany && !!initialCompany.locations && initialCompany.locations
  );
  const [savedContact, setSavedContact] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState();

  const {
    request: createRequest,
    data: createData,
    loading: createLoading,
    error: createError,
  } = useApi(contactsApi.createContact);

  const {
    request: updateRequest,
    data: updateData,
    loading: updateLoading,
    error: updateError,
  } = useApi(contactsApi.updateContact);

  const onSubmit = async (formData, setFormLoading) => {
    let newContact = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      title: formData.title,
      email: formData.email,
      phone: formData.phone,
      cell_phone: formData.cell_phone,
      contact_type: formData.contact_type,
      company_id: !!initialCompany ? initialCompany.id : company.company_id,
      territory_location: formData.territory_location,
    };

    if (!!initialContact && !!initialContact.id) {
      newContact.id = initialContact.id;
    }

    if (!!initialCompany && !!locations && !!locations.length) {
      newContact.location_ids = locations.map((loc) => loc.value);
    } else if (!!company) {
      newContact.location_ids = [company.location_id];
    }

    try {
      let response;
      if (!!initialContact && !!initialContact.id) {
        response = await updateRequest(newContact);
      } else {
        response = await createRequest(newContact);
      }

      if (!response.success) {
        throw new Error("Contact");
      }
    } catch (error) {
      return Promise.reject(error);
    }

    setFormLoading(false);
  };

  const { data, setFields, errors, handleSubmit, renderSubmitButton, renderTextField, renderSelectField } =
    useFormControl({
      schema,
      onSubmit,
      initialData: initialContact || initialData,
      scrollToTop: false,
    });

  useEffect(() => {
    if (!!initialCompany) {
      setLocationOptions(!!initialCompany && !!initialCompany.locations && initialCompany.locations);
    }
  }, [initialCompany]);

  useEffect(() => {
    if (!!createError || !!updateError) {
      setError(createError || updateError);
    } else {
      setError();
    }
  }, [createError, updateError]);

  useEffect(() => {
    if (!!createLoading || !!updateLoading) {
      setLoading(createLoading || updateLoading);
    } else {
      setLoading();
    }
  }, [createLoading, updateLoading]);

  useEffect(() => {
    if (!!updateData) {
      setContact(updateData.contact);
      setSavedContact(updateData.contact);
      setCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    if (!!createData) {
      setContact(createData.contact);
      setSavedContact(createData.contact);
      setCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData]);

  useEffect(() => {
    if (!!contactType) {
      initialData.contact_type = contactType;
    }
  }, [contactType]);

  useEffect(() => {
    if (!!locations || !!company) {
      setFields({
        ...data,
        locations: locations || [company],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, company]);

  const fields = (
    <div className="position-relative">
      <Loader loading={!!loading} />
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="row">
        <div className="col-lg-4">
          {renderSelectField({
            name: "contact_type",
            label: "Contact Type",
            options: config.contact_types.map((ct) => ({
              value: ct.name,
              label: ct.name,
            })),
            isDisabled: !!contactType,
            emptyOption: "Select...",
            isClearable: false,
            styles: dropdownStylesWithWidth("100%"),
          })}
        </div>
      </div>

      <h4>Contact Info</h4>
      <div className="row">
        <div className="col-lg-6">
          {renderTextField({
            name: "first_name",
            label: "First Name",
            autoFocus: true,
            autoComplete: "nope",
          })}
        </div>
        <div className="col-lg-6">
          {renderTextField({
            name: "last_name",
            label: "Last Name",
            autoComplete: "nope",
          })}
        </div>
        <div className="col-lg-6">
          {renderTextField({
            name: "title",
            label: "Job Title",
            autoComplete: "nope",
          })}
        </div>
        <div className="col-lg-6">
          {renderTextField({
            name: "territory_location",
            label: "Territory Location",
            autoComplete: "nope",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          {renderTextField({
            name: "phone",
            label: "Phone",
            placeholder: "(XXX)-XXX-XXXX",
            type: "tel",
            format: "phone",
            autoComplete: "nope",
          })}
        </div>
        <div className="col-lg-6">
          {renderTextField({
            name: "cell_phone",
            label: "Cell Phone",
            placeholder: "(XXX)-XXX-XXXX",
            type: "tel",
            format: "phone",
            autoComplete: "nope",
          })}
        </div>
        <div className="col-lg-8">
          {renderTextField({
            name: "email",
            label: "Email",
            type: "email",
            autoComplete: "nope",
          })}
        </div>
      </div>
      <h4>Company Info</h4>

      {!!initialCompany && !!initialCompany.id ? (
        <CompanyLocationSelect
          locations={locations}
          locationOptions={locationOptions}
          setLocations={setLocations}
          error={!!errors && !!errors.locations && data.locations.length === 0 ? true : false}
        />
      ) : (
        <>
          <LocationSearch
            setAddNewCompany={setAddingCompany}
            setAddNewLocation={setAddingLocation}
            addNewLocation={addingLocation}
            setLocation={setCompany}
            setSelectedOption={setSelectedOption}
            error={!!errors && !!errors.locations && data.locations.length === 0 ? true : false}
          />
          {!!company && !!Object.keys(company).length && (
            <div className="col-4 p-0 mt-2">
              <CompanyLocationContactCard location={company} />
            </div>
          )}
        </>
      )}
    </div>
  );

  useEffect(() => {
    if (savedContact) setAddNew(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedContact]);

  return {
    fields,
    handleSubmit,
    renderSubmitButton,
    company,
    setCompany,
    addingCompany,
    addingLocation,
    setAddingCompany,
    setAddingLocation,
    selectedOption,
  };
};

export default useAddContact;
