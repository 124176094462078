import React from "react";
import CompanyLocation from "./CompanyLocation";
import styles from "../styles/Company.module.scss";

const Locations = ({
  company,
  setCompany,
  locations,
  setEditingLocation,
  setArchivingLocation,
  setArchiveLocation,
  setEditLocation
}) => {
  return (
    <div>
      <div>
        <div className={`align-items-start ${styles.gridWrapper}`}>
          {!!locations && !!locations.length && (
            locations.map((loc) => (
              <div
                className="p-0"
                key={loc.id}
              >
                <div key={loc.id}>
                  <CompanyLocation
                    company={company}
                    setCompany={setCompany}
                    location={loc}
                    setEditingLocation={setEditingLocation}
                    setArchivingLocation={setArchivingLocation}
                    setArchiveLocation={setArchiveLocation}
                    setEditLocation={() => setEditLocation(loc)}
                  />
                </div>
              </div>
            ))
          )} 
        </div>
      </div>
    </div>
  );
};

export default Locations;
