import React from "react";
import styles from "./styles.module.scss";
import { Folder } from "react-feather";

const AlbumItem = ({ album, setFields, formData }) => {
  const handleChange = () => {
    setFields({ albums: [album.value], new_album_name: "" });
  };

  return (
    <div className={styles.albumItemWrapper}>
      <input
        className={styles.radio}
        type="radio"
        name={album.label}
        value={album.value}
        onChange={handleChange}
        checked={formData.albums[0] === album.value}
      />
      <Folder size="16" color="#0071ce" className="me-3" />
      <label className={styles.albumItemText}>{album.label}</label>
    </div>
  );
};

export default AlbumItem;
