import React from "react";
import TaskRows from "../TaskRows";
import PropTypes from "prop-types";
import useEstimateConversion from "../../useEstimateConversion";
import styles from "./styles.module.scss";
import TaskHeader from "../TaskHeader";
import PhaseColumns from "../PhaseColumns";
import CostCode from "../Columns/CostCode";
import ProductionRate from "../Columns/ProductionRate";
import Quantity from "../Columns/Quantity";
import ItemNumber from "../Columns/ItemNumber";
import UnitMeasure from "../Columns/UnitMeasure";
import Active from "../Columns/Active";
import Hidden from "../Columns/Hidden";
import EstimatedHours from "../Columns/EstimatedHours";
import useBidItem from "./useBidItem";
import ForcedQuantity from "../Columns/ForcedQuantity";

const BidItem = ({ bidItem, fixedColumns = false }) => {
  const { viewPhases, editTasks, bidItems, setBidItems, unitsOfMeasure, customControlStyles } = useEstimateConversion();

  const {
    qtyTotal,
    open,
    updateItem,
    phaseIds,
    getPhaseQty,
    setPhaseQty,
    setUpdatePhaseQty,
    updateLoading,
    totalEstHours,
  } = useBidItem({ bidItem });

  return (
    <>
      <tr
        className={`
          ${!fixedColumns && qtyTotal > bidItem.bid_quantity ? styles.warningBg : ""}
          ${!!open ? styles.openTaskHeader : ""}
          position-relative
        `}
      >
        {!!fixedColumns ? (
          <>
            <ItemNumber fixedColumns={fixedColumns} item={bidItem} open={open} />
            <TaskHeader bidItem={bidItem} open={open} editTasks={editTasks} updateItem={updateItem} />

            <CostCode editTasks={editTasks} item={bidItem} updateItem={updateItem} />

            <ProductionRate item={bidItem} />
          </>
        ) : (
          <>
            <PhaseColumns
              item={bidItem}
              qtyTotal={qtyTotal}
              phaseIds={phaseIds}
              getPhaseQty={getPhaseQty}
              setPhaseQty={setPhaseQty}
              setUpdatePhaseQty={setUpdatePhaseQty}
              updateLoading={updateLoading}
            />
            <td></td>
            <Quantity
              item={bidItem}
              editTasks={editTasks}
              viewPhases={viewPhases}
              qtyTotal={qtyTotal}
              updateItem={updateItem}
            />

            <UnitMeasure
              editTasks={editTasks}
              item={bidItem}
              unitsOfMeasure={unitsOfMeasure}
              updateItem={updateItem}
              customControlStyles={customControlStyles}
            />
            <EstimatedHours item={bidItem} totalEstHours={totalEstHours} />
            <ForcedQuantity editTasks={editTasks} item={bidItem} updateItem={updateItem} />
            <Active editTasks={editTasks} item={bidItem} updateItem={updateItem} />
            <Hidden editTasks={editTasks} item={bidItem} updateItem={updateItem} />
          </>
        )}
      </tr>
      {!!open && !!bidItem.sub_items?.length && (
        <TaskRows
          bidItem={bidItem}
          editTasks={editTasks}
          bidItems={bidItems}
          setBidItems={setBidItems}
          fixedColumns={fixedColumns}
          phaseIds={phaseIds}
        />
      )}
    </>
  );
};

BidItem.propTypes = {
  bidItem: PropTypes.object.isRequired,
};

export default BidItem;
