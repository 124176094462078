import {
  action,
  source,
  status,
  type,
  updated_by,
  updated
} from "./columnDefs";

const columnDefs = [
  type,
  source,
  updated,
  updated_by,
  action,
  status,
];

export default columnDefs;