import React from 'react';
import { Phone } from 'react-feather';
import styles from '../styles/CompanyContact.module.scss';
import CompanyLocationNav from './CompanyLocationNav';

const CompanyLocation = ({
  company,
  setCompany,
  location,
  setEditingLocation,
  archiveLocation,
  setArchivingLocation,
  setArchiveLocation,
  setEditLocation,
  location: {
    name,
    phone,
    address
  }
}) => {
  return (
    <div className={styles.contactWrap}>
      {!!setEditingLocation && (
        <CompanyLocationNav
          company={company}
          setCompany={setCompany}
          location={location}
          setArchivingLocation={setArchivingLocation}
          setEditingLocation={() => setEditingLocation(location)}
          archiveLocation={archiveLocation}
          setArchiveLocation={setArchiveLocation}
          setEditLocation={setEditLocation}
          archived={location.archived}
        />
      )}
      <h2
        className={`${
          !!location.archived && "text-gray3"
        } semibold sm-med mb-0 text-quaternary text-limiter text-uppercase`}
      >{`${name} ${!!location.archived ? "(archived)" : ""}`}</h2>
      {!!location.address && (
        <div className={`sm-med text-gray3 ${styles.address}`}>
          {!!address.address1 && <div className="text-limiter">{address.address1}</div>}
          {!!address.address2 && <div className="text-limiter">{address.address2}</div>}
          <div className="text-limiter">{`${address.city ? `${address.city}, ` : ""} ${address.state} ${
            address.zip
          }`}</div>
        </div>
      )}
      <div>
        {phone && (
          <>
            {!!location.archived ? (
              <div className="phone archived">
                <Phone className="phoneIcon" size="9" />
                {phone}
              </div>
            ) : (
              <a className="btn-link phone" href={`mailto:${phone}`}>
                <Phone className="phoneIcon" size="9" />
                {phone}
              </a>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default CompanyLocation;