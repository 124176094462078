import { useState } from "react";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import { Briefcase } from "react-feather";
import Submenu from "./Submenu";
import { Loader } from "component-library";
import CrewLogFilters from "../../../../_resourceManagement/CrewLogs/CrewLogFilters";
import useCrewLogs from "../../../../_resourceManagement/CrewLogs/useCrewLogs";
import ReportingConsole from "../../../../_resourceManagement/ReportingConsole";
import { useParams } from "react-router-dom";
import useProject from "../../../hooks/useProject";
import CrewLogListing from "../../../../_resourceManagement/CrewLogs/CrewLogListing";
import Pagination from "../../../../components/Pagination";
import useInitialLoader from "../../hooks/useInitialLoader";
import TwoWeekMessage from "./TwoWeekMessage";

const TimesheetLogs = () => {
  const [initialLoad, setInitialLoad] = useState(true);
  const { id: projectId } = useParams();

  const {
    pageTitle,
    error: projectError,
    loading: projectLoading,
    fieldMgmtDateRange,
    setFieldMgmtDateRange,
  } = useProject();

  const {
    filteredCrewMembers,
    searchCrewMembers,
    loading,
    projectsList,
    projectsLoading,
    q,
    foremenList,
    selectedForeman,
    setSelectedForeman,
    columnDefs,
    consoleData,
    error,
    page,
    setPage,
    total,
  } = useCrewLogs({ initialLoad, projectId, dateRange: fieldMgmtDateRange });

  useInitialLoader(initialLoad, setInitialLoad);

  return (
    <InteriorPage
      error={error || projectError}
      loading={projectLoading}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle="Crew Timesheets"
      pageSubtitlePrimaryContent={() => <Submenu />}
    >
      <TwoWeekMessage dateRange={fieldMgmtDateRange}/>
      <CrewLogFilters
        searchFunction={searchCrewMembers}
        projectsList={projectsList}
        projectsLoading={projectsLoading}
        foremenList={foremenList}
        selectedForeman={selectedForeman}
        setSelectedForeman={setSelectedForeman}
        projectId={projectId}
        loading={loading}
        dateRange={fieldMgmtDateRange}
        setDateRange={setFieldMgmtDateRange}
      />
      <ReportingConsole
        isProject
        q={q}
        projectId={projectId}
        foremanId={selectedForeman}
        error={error}
        loading={loading}
        consoleData={consoleData}
      />
      <div className="position-relative">
        <Loader loading={loading} />
        <CrewLogListing
          filteredCrewMembers={filteredCrewMembers}
          columnDefs={columnDefs}
          loading={loading}
          projectId={projectId}
        />
        <Pagination
          currentPage={page}
          setCurrentPage={setPage}
          total={total}
          itemsPerPage={25}
          items={filteredCrewMembers}
        />
      </div>
    </InteriorPage>
  );
};

export default TimesheetLogs;
