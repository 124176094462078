import useProject from "../../../../../hooks/useProject";
import { Briefcase, Save, Send } from "react-feather";
import InteriorPage from "../../../../../../components/pageLayout/InteriorPage";
import useWeeklyReportsEdit from "../../../../../../_resourceManagement/WeeklyReports/WeeklyReportEdit/useWeeklyReportEdit";
import { HeaderButton, useAuth } from "component-library";
import Unauthorized from "../../../../../../components/pages/Unauthorized";
import WeeklyReportEditPageContent from "../../../../../../_resourceManagement/WeeklyReports/WeeklyReportEdit/WeeklyReportEditPageContent";
import { useNavigate, useParams } from "react-router-dom";

const WeeklyReportEdit = () => {
  const history = useNavigate();
  const { report_id } = useParams();
  const { userCan } = useAuth();
  const { project, pageTitle, loading: projectLoading } = useProject();
  const handleRedirect = () => {
    history(`/project/${project?.id}/field-management/weekly-reports/${report_id}/review`);
  };

  const {
    report,
    loading,
    updateDate,
    weather,
    handleUpdateNotes,
    reportNotes,
    handleRemoveNote,
    message,
    handleUnsetMessage,
    updateWeeklyNotesError,
    reportLoading,
    reportError,
    error,
    handleSaveReport,
    updateReportStatus,
  } = useWeeklyReportsEdit({ handleRedirect });

  const HeaderButtons = () => (
    <div className="d-flex align-items-center">
      <HeaderButton
        onClick={() => {
          handleSaveReport();
        }}
        ariaLabel="Save current changes to weekly report"
        text="Save Report"
        icon={
          <Save
            size={16}
            color={`${
              !!projectLoading || !!reportLoading || !userCan("weekly-report-edit") || loading ? "#4f4f4f" : "#0071ce"
            }`}
          />
        }
        type="submit"
        disable={!!projectLoading || !!reportLoading || loading || !userCan("weekly-report-edit")}
      />
      <HeaderButton
        onClick={() => {
          updateReportStatus({
            id: report.id,
            status: "review",
          });
        }}
        type="submit"
        ariaLabel="update status to review"
        text="Review and Send"
        icon={<Send size={16} color={`${loading ? "#4f4f4f" : "#0071ce"}`} />}
        disable={loading || !userCan("weekly-report-edit")}
      />
    </div>
  );
  return (
    <>
      {userCan("weekly-report-edit") ? (
        <InteriorPage
          error={reportError || error}
          loading={projectLoading || reportLoading}
          navTitle={pageTitle}
          navIcon={<Briefcase color="#fff" size={14} />}
          pageTitle="Field Management"
          pageSubtitlePrimaryContent={() => "New Weekly Report"}
          pageSubtitleSecondaryContent={HeaderButtons}
        >
          <WeeklyReportEditPageContent
            project={project}
            report={report}
            updateDate={updateDate}
            weather={weather}
            loading={reportLoading}
            handleUpdateNotes={handleUpdateNotes}
            reportNotes={reportNotes}
            handleRemoveNote={handleRemoveNote}
            message={message}
            handleUnsetMessage={handleUnsetMessage}
            updateWeeklyNotesError={updateWeeklyNotesError}
          />
        </InteriorPage>
      ) : (
        <Unauthorized />
      )}
    </>
  );
};

export default WeeklyReportEdit;
