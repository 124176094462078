import { useState, useEffect } from "react";
import { useApi, bidApi, useAuth } from "component-library";
import useProject from "../../hooks/useProject";

const useProvideEstimateConversion = () => {
  const { user, userCan } = useAuth();
  const itemsPerPage = 50;
  const { project } = useProject();
  const { config } = useAuth();
  const [viewTasks, setViewTasks] = useState(true);
  const [editTasks, setEditTasks] = useState(false);
  const [editPhases, setEditPhases] = useState(false);
  const [viewPhases, setViewPhases] = useState(false);
  const [filteredBidItems, setFilteredBidItems] = useState([]);
  const [bidItems, setBidItems] = useState([]);
  const [phases, setPhases] = useState([]);
  const [resetPhasesConfirmation, setResetPhasesConfirmation] = useState(false);
  const [openTasks, setOpenTasks] = useState([]);
  const [phasesError, setPhasesError] = useState();
  const [importable, setImportable] = useState(true);
  const [updatingColumn, setUpdatingColumn] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [totalResults, setTotalResults] = useState();
  const [refreshingEstimate, setRefreshingEstimate] = useState(false);
  const [poCreated, setPoCreated] = useState();
  const [unitsOfMeasure, setUnitsOfMeasure] = useState([]);
  const [unitsRatesMeasure, setUnitsRatesMeasure] = useState([]);
  const [canEdit, setCanEdit] = useState();
  const [tasksDeactivatedConfirm, setTasksDeactivatedConfirm] = useState();
  const [deactivatedTasks, setDeactivatedTasks] = useState([]);
  const [movingTask, setMovingTask] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const [taskToMove, setTaskToMove] = useState();

  const { data: upbData, loading: upbLoading, request: upbRequest, error: upbError } = useApi(bidApi.getUPB);

  const {
    data: updateData,
    loading: updateLoading,
    request: updateRequest,
    error: updateError,
  } = useApi(bidApi.updateUPB);

  const { data: syncData, loading: syncLoading, request: syncRequest, error: syncError } = useApi(bidApi.syncPhases);

  const {
    data: resetData,
    loading: resetLoading,
    request: resetRequest,
    error: resetError,
  } = useApi(bidApi.resetPhases);

  const submitEdit = (bidItems) => {
    updateRequest({ upb_items: bidItems });
  };

  const toggleOpen = (itemId) => {
    let updatedOpenTasks;
    if (openTasks.includes(itemId)) {
      updatedOpenTasks = openTasks.filter((id) => id !== itemId);
    } else {
      updatedOpenTasks = [...openTasks, itemId];
    }
    setOpenTasks(updatedOpenTasks);
  };

  const refreshEstimate = () => {
    setRefreshingEstimate(true);
  };

  const handleDeactivatedTaskWarning = (activeState, itemId) => {
    if (activeState === 0) {
      setDeactivatedTasks(!!deactivatedTasks?.length ? [...deactivatedTasks, itemId] : [itemId]);
    } else {
      setDeactivatedTasks(deactivatedTasks.filter((task) => task !== itemId));
    }
  };

  const toggleAllItems = () => {
    setViewAll(!viewAll);
  };

  const getAllItemIds = (items) => {
    let openItems = [];
    if (!!viewAll) {
      items.forEach((item) => {
        if (!!item?.sub_items?.length) {
          openItems.push(item.id);
          const subItemIds = getSubItemIds(item.sub_items);
          openItems.push(...subItemIds);
        }
      });
    } else {
      openItems = [];
    }

    return openItems;
  };

  const getSubItemIds = (subItems) => {
    let subItemIds = subItems.map((sItem) => sItem.id);
    subItems.forEach((sItem) => {
      if (!!sItem?.sub_items?.length) {
        const sItemSubItems = getSubItemIds(sItem.sub_items);
        subItemIds.push(...sItemSubItems);
      }
    });
    // get sub item's sub items here
    return subItemIds;
  };

  useEffect(() => {
    if (!!upbData && currentPage !== upbData.upb_items.current_page) {
      upbRequest({ q: filter, id: project.id, page: currentPage, items_per_page: itemsPerPage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (!!project) {
      upbRequest({ q: filter, id: project.id, page: currentPage, items_per_page: itemsPerPage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (!!project) {
      setPoCreated(project?.po_created || false);
    }
  }, [project]);

  useEffect(() => {
    if (!!config?.units_of_measure) {
      setUnitsOfMeasure(
        config.units_of_measure.map((option) => ({
          value: option.id,
          label: option.unit_measure,
        }))
      );
    }

    if (!!config?.rate_ums) {
      setUnitsRatesMeasure(
        Object.entries(config.rate_ums).map((option) => ({
          value: option[0],
          label: option[1],
        }))
      );
    }
  }, [config]);

  const customControlStyles = () => ({
    display: "flex",
    alignItems: "center",
    height: 24,
    minHeight: 24,
    cursor: "pointer",
    backgroundColor: "#fff",
    color: "#495057",
    border: "1px solid #ced4da",
    borderRadius: "0.4rem",
  });

  useEffect(() => {
    if (userCan("estimate-conversion-edit")) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setOpenTasks(getAllItemIds(bidItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewAll]);

  return {
    bidItems,
    setBidItems,
    viewTasks,
    setViewTasks,
    viewPhases,
    setViewPhases,
    editTasks,
    setEditTasks,
    editPhases,
    setEditPhases,
    filteredBidItems,
    setFilteredBidItems,
    phases,
    setPhases,
    resetPhasesConfirmation,
    setResetPhasesConfirmation,
    submitEdit,
    upbData,
    upbLoading,
    upbRequest,
    upbError,
    updateData,
    updateLoading,
    updateRequest,
    updateError,
    syncData,
    syncLoading,
    syncRequest,
    syncError,
    resetData,
    resetLoading,
    resetRequest,
    resetError,
    openTasks,
    setOpenTasks,
    toggleOpen,
    phasesError,
    setPhasesError,
    importable,
    setImportable,
    updatingColumn,
    setUpdatingColumn,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    filter,
    setFilter,
    totalResults,
    setTotalResults,
    refreshEstimate,
    refreshingEstimate,
    setRefreshingEstimate,
    poCreated,
    unitsRatesMeasure,
    unitsOfMeasure,
    customControlStyles,
    canEdit,
    handleDeactivatedTaskWarning,
    deactivatedTasks,
    tasksDeactivatedConfirm,
    setTasksDeactivatedConfirm,
    setDeactivatedTasks,
    movingTask,
    setMovingTask,
    viewAll,
    toggleAllItems,
    getAllItemIds,
    taskToMove,
    setTaskToMove,
  };
};

export default useProvideEstimateConversion;
