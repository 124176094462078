import { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { Trash2, Folder, AlertTriangle } from "react-feather";
import styles from "./styles.module.scss";
import { useApi, photoLibraryApi } from "component-library";
import usePhotoLibrary from "../../context/usePhotoLibrary";
import { useNavigate, useParams } from "react-router-dom";
import useProject from "../../../../hooks/useProject";
import { BeatLoader } from "react-spinners";
import PropTypes from "prop-types";

const useDeleteAlbum = ({ album, setShowModal }) => {
  const { project } = useProject();
  const { albumId } = useParams();
  const history = useNavigate();

  const { albumsList, setAlbumsList, setMessage } = usePhotoLibrary();
  const [deletedAlbum, setDeletedAlbum] = useState(false);

  const {
    request: deleteAlbumRequest,
    data: deleteAlbumData,
    loading: deleteAlbumLoading,
    error: deleteAlbumError,
  } = useApi(photoLibraryApi.deleteAlbum);

  const handleDeleteAlbum = (id) => {
    deleteAlbumRequest(id);
  };

  const onHide = () => {
    setShowModal(false);
  };

  const headerContent = (
    <>
      <div className="d-flex align-items-center">
        <Trash2 color="#FF6700" size={16} />
        <h2 className={styles.modalTitle}>Delete Album</h2>
      </div>
    </>
  );
  const bodyContent = (
    <div className="d-flex position-relative text-center text-gray2 flex-column align-items-center">
      {!!deleteAlbumLoading && (
        <div className="loaderWrapper">
          <BeatLoader color={"#0071ce"} />
        </div>
      )}
      <div className={styles.alert}>
        <AlertTriangle color="#FF6700" size={24} />
      </div>
      {!!deleteAlbumError && <Alert variant="danger">{deleteAlbumError}</Alert>}
      Are you sure you want to delete this album?
      <div className="d-flex">
        <div className={styles.currentTitleWrapper}>
          <Folder color="#0071ce" size={16} /> <span className={styles.currentTitle}>{album?.name}</span>
        </div>
      </div>
    </div>
  );
  const footerContent = (
    <>
      <Button
        variant="redOrange"
        className="med d-flex align-items-center text-white font-weight-bold"
        form="create-photo-album"
        type="submit"
        aria-label="Create new project photo album"
        disabled={deleteAlbumLoading}
        onClick={() => handleDeleteAlbum(albumId)}
      >
        Delete Album
      </Button>
    </>
  );

  useEffect(() => {
    if (!!deleteAlbumData?.album) {
      setAlbumsList(albumsList.filter((album) => album.id !== parseInt(albumId)));
      setDeletedAlbum(true);
      setMessage("Album deleted successfully.");
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAlbumData]);

  useEffect(() => {
    if (deletedAlbum) {
      history(`/project/${project?.id}/photo-library`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedAlbum]);

  return {
    headerContent,
    bodyContent,
    footerContent,
    onHide,
  };
};

useDeleteAlbum.propTypes = {
  album: PropTypes.object,
  setShowModal: PropTypes.func,
};

export default useDeleteAlbum;
