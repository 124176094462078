import React from "react";
import useProvideUserAdmin from "./useProvideUserAdmin";
import UserAdminContext from "./UserAdminContext";

function ProvideUserAdmin({ children }) {
  const data = useProvideUserAdmin();
  return <UserAdminContext.Provider value={data}>{children}</UserAdminContext.Provider>;
}

export default ProvideUserAdmin;
