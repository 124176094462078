import React, { useState, useEffect } from "react";
import {
  useApi,
  reportsApi,
  useFormControl,
  dropdownStylesWithWidth,
} from "component-library";
import { schema, initialData } from "../schema/foremanSummary";
import ToFromFields from "../ToFromFields";
import { Button } from "react-bootstrap";
import useForms from "./useForms";
import ForemanSummaryGridReport from "../AdminReports/ForemanSummaryGridReport";

const ForemanSummaryReport = ({
  foremanOptions,
  setLoading,
  setError,
  setData,
  setShowExport,
}) => {
  const {
    request: runReportRequest,
    data: runReportData,
    loading: runReportLoading,
    error: runReportError,
  } = useApi(reportsApi.getForemanSummaryReport);

  const { onSubmit } = useForms({
    runReportRequest,
    runReportData,
    runReportLoading,
    runReportError,
    setLoading,
    setError,
    setData,
  });

  const {
    handleSubmit,
    renderTextField,
    renderSubmitButton,
    renderSelectField,
    data,
    setFields,
  } = useFormControl({
    schema,
    initialData,
    onSubmit,
  });

  const [gridData, setGridData] = useState();
  const [exportURL, setExportURL] = useState();

  useEffect(() => {
    if (!!runReportData?.gridData) {
      setGridData(runReportData.gridData);
    }
    if (!!runReportData?.url) {
      setExportURL(runReportData.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runReportData]);

  const selectAllForemen = () => {
    if (data?.foremen?.length === foremanOptions?.length) {
      setFields({
        ...data,
        foremen: [],
      });
    } else {
      setFields({
        ...data,
        foremen: foremanOptions.map((opt) => opt.value),
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} id="foreman_summary_report">
        <div className="row">
          <ToFromFields renderTextField={renderTextField} data={data} />
        </div>
        <Button className="ps-0" variant="link" onClick={() => selectAllForemen()}>
          {data?.foremen?.length === foremanOptions?.length ? "Deselect" : "Select"} All Foremen
        </Button>
        <div className="row">
          <div className="col-4">
            {renderSelectField({
              name: "foremen",
              label: "Foremen",
              options: foremanOptions,
              styles: dropdownStylesWithWidth,
              emptyOption: "Select...",
              isMulti: true,
              closeMenuOnSelect: false,
            })}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          {!!exportURL && (
            <Button
              className="form-control-submit med undefined btn btn-primary mb-2 me-2"
              onClick={() => {
                setShowExport(true);
              }}
            >
              Export File
            </Button>
          )}
          <div className="mb-2">{renderSubmitButton("Run Report")}</div>
        </div>
      </form>
      {!!gridData && <ForemanSummaryGridReport gridData={gridData} formData={data} />}
    </>
  );
};

export default ForemanSummaryReport;
