import Status from "../columnRenders/Status";

const status = {
  headerName: "Status",
  field: "status",
  cellRenderer: Status,
  maxWidth: 200,
};

export default status;
