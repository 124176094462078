const company_name = {
  headerName: "Company",
  field: "company_name",
  minWidth: 300,
  maxWidth: 500,
  flex: 1,
  cellStyle: {
    fontWeight: 600,
    color: "#000000",
  },
};

export default company_name;
