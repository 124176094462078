import React from "react";
import styles from "./styles.module.scss";

const Indicator = ({ workingDay }) => {
  return (
    <div
      className={styles.indicator}
      style={{
        backgroundColor: workingDay ? "#7DDB2D" : "#FF6700",
      }}
    />
  );
};

export default Indicator;
