import React from "react";
import IframePage from "./IframePage";
import ConfigurationMenu from "./submenus/ConfigurationMenu";

const ConfigurationTelematicsIframe = () => {
  return (
    <IframePage
      srcName="manage_configuration_telematics"
      pageTitle="Configure Telematics"
      Submenu={ConfigurationMenu}
    />
  );
};

export default ConfigurationTelematicsIframe;
