import React, { useEffect, useState } from "react";
import { HeaderButton, AgGridTable, FilterSearch, useAuth } from "component-library";
import useProject from "../../hooks/useProject";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import { Briefcase, Upload } from "react-feather";
import AddDocument from "./AddDocument/AddDocument";
import gridRowHeights from "../../../utils/gridRowHeights";
import documentsListConfig from "./documentsListConfig";
import useProjectDocuments from "./context/useProjectDocuments";
import FolderList from "./FolderList";

const ProjectDocuments = () => {
  const { userCan, user } = useAuth();
  const { attachments, attachmentsLoading, attachmentsError, folderList } = useProjectDocuments();
  const { project, loading, error, pageTitle, PageTag } = useProject();
  const [filteredAttachments, setFilteredAttachments] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [query, setQuery] = useState();

  const handleSearch = (q) => {
    setQuery(q);
  };

  useEffect(() => {
    let results = attachments;
    if (query) {
      results = results.filter((attachment) =>
        `${attachment.folder} ${attachment?.file_name} ${attachment?.user?.first_name} ${attachment?.user?.last_name}`
          .toLowerCase()
          .includes(query.toLowerCase())
      );
    }

    setFilteredAttachments(results);
  }, [attachments, query]);

  const AddButton = () => (
    <HeaderButton
      text="Upload File"
      onClick={() => setModalOpen(true)}
      ariaLabel="add new project document"
      icon={<Upload size={16} color="#0071ce" />}
    />
  );

  return (
    <InteriorPage
      loading={loading || attachmentsLoading}
      error={error || attachmentsError}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle={"Documents"}
      NavContent={PageTag}
      pageTitleSecondaryContent={AddButton}
    >
      <div>
        <div className="d-flex align-items-center justify-content-end w-100 mb-4 mt-4">
          <FilterSearch inline searchFunction={handleSearch} placeholder="Search" />
        </div>
        <>
          <FolderList folderList={folderList} />
          <div className="documentTable">
            <AgGridTable
              gridData={filteredAttachments}
              gridConfig={documentsListConfig}
              loading={loading}
              rowHeight={gridRowHeights.medium}
              id={`${user.id}_project_documents_listing`}
              presetStyle="sortableTable"
              fallbackMessage="No attachments found."
              autoHeight
            />
          </div>

          {!!modalOpen && !!project && (
            <AddDocument
              setModalOpen={setModalOpen}
              projectId={project.id}
              canUpload={userCan("bid-create") || userCan("bid-edit")}
            />
          )}
        </>
      </div>
    </InteriorPage>
  );
};

export default ProjectDocuments;
