import React, { useState, useEffect } from "react";
import AlbumsList from "./Lists/AlbumsList";
import AllPhotosList from "./Lists/AllPhotosList";
import PinnedPhotosAlbumsList from "./Lists/PinnedPhotosAlbumsList";
import usePinnedList from "../hooks/usePinnedList";
import { formatApiDate } from "component-library";

const ListView = ({
  photosList,
  albumsList,
  handlePin,
  handleDelete,
  dateRange = [],
  q = "",
  project,
  loading,
  selectedItems,
  setSelectedItems,
  selectMode,
}) => {
  const { pinnedList } = usePinnedList({
    photosList,
    albumsList,
    dateRange,
  });

  const [params, setParams] = useState();

  useEffect(() => {
    if (!!project?.id) {
      setParams({
        project_id: project.id,
        from_date: formatApiDate(dateRange[0]),
        to_date: formatApiDate(dateRange[1]),
        q: q,
      });
    }
  }, [q, dateRange, project]);

  return (
    <>
      <PinnedPhotosAlbumsList
        pinnedList={pinnedList}
        handlePin={handlePin}
        handleDelete={handleDelete}
        loading={loading}
        params={params}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectMode={selectMode}
      />
      <AlbumsList
        unpinnedAlbumsList={albumsList}
        handlePin={handlePin}
        handleDelete={handleDelete}
        loading={loading}
        params={params}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectMode={selectMode}
      />
      <AllPhotosList
        photosList={photosList}
        handlePin={handlePin}
        handleDelete={handleDelete}
        loading={loading}
        params={params}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectMode={selectMode}
      />
    </>
  );
};

export default ListView;
