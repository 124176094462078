import React from "react";
import useInitalCreateProject from "../../_bidManagement/hooks/useInitalCreateProject";
import InteriorPage from "../pageLayout/InteriorPage";
import { Tag } from "react-feather";

const InitialCreateProject = () => {
  const { fields, handleSubmit, loading, closeLoading, CreateProjectHeader } = useInitalCreateProject();

  return (
    <InteriorPage
      loading={loading || closeLoading}
      navTitle="Projects"
      pageTitle="Create New Project"
      navIcon={<Tag color="#fff" size={14} />}
      pageTitleSecondaryContent={CreateProjectHeader}
    >
      <div className="row justify-content-center pt-4">
        <div className="col-8">
          <form onSubmit={handleSubmit} id="create-initial-project-form">
            {fields}
          </form>
        </div>
      </div>
    </InteriorPage>
  );
};

export default InitialCreateProject;
