import React from "react";
import { DateRangeSelector, FilterSearch, BoomSelector } from "component-library";
import StatusSelector from "./StatusSelector";
import styles from "./styles.module.scss";
import useResourceManagement from "../../context/useResourceManagement";
import PMSelector from "./PMSelector";
import useControlBar from "./useControlBar";
import { getMaxDate } from "../../../utils/getMaxDate";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import TwoWeekMessage from "../../../_projects/project/FieldManagement/FieldLogs/TwoWeekMessage";

const ControlBar = ({
  getFieldLogs,
  loading,
  page,
  setPage,
  itemsPerPage,
  total,
  viewAll,
  setViewAll,
  initialLoad,
  dateRange = [],
  setDateRange,
}) => {
  const { id } = useParams();

  const {
    q,
    setQ,
    statuses,
    setStatuses,
    projectManager,
    setProjectManager,
    projectsList,
    projectsLoading,
    selectedProject,
    setSelectedProject,
  } = useResourceManagement();

  const { pmOptions, setPmOptions } = useControlBar({
    projectId: id || selectedProject,
    getFieldLogs,
    loading,
    page,
    setPage,
    itemsPerPage,
    total,
    viewAll,
    initialLoad,
    dateRange,
  });

  return (
    <>
      <div className={styles.controlBar}>
        <div className={styles.dateSelWrapper}>
          <DateRangeSelector
            dateRange={dateRange}
            setDateRange={setDateRange}
            disabled={!!loading}
            maxDate={getMaxDate(dateRange, 14)}
          />
          {!id && (
            <Button
              variant="link"
              className="p-0 pt-1 sm-med"
              onClick={() => setViewAll(!viewAll)}
              disabled={!!loading}
            >
              {!!viewAll ? <>View Some</> : <>View All</>}
            </Button>
          )}
        </div>
        <div className="d-flex">
          <PMSelector
            selected={projectManager}
            setSelected={setProjectManager}
            pmOptions={pmOptions}
            setPmOptions={setPmOptions}
            loading={loading}
          />
          {!id && (
            <BoomSelector
              selected={selectedProject}
              setSelected={setSelectedProject}
              options={projectsList}
              loading={projectsLoading}
              placeholder="Filter By Project"
            />
          )}
          <StatusSelector selected={statuses} setSelected={setStatuses} loading={loading} />
          <FilterSearch inline searchFunction={setQ} initialValue={q} />
        </div>
      </div>
    </>
  );
};

export default ControlBar;
