import { useEffect, useState } from "react";
import manHoursReportConfig from "./manHoursReportConfig";
import { AgGridTable } from "component-library";
import renderDateColumnDefs from "./columnDefs/renderDateColumnDefs";

const ManHoursReportTable = ({ gridData, formData }) => {
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    if (!!formData && !!gridData) {
      setColumnDefs(renderDateColumnDefs(manHoursReportConfig, formData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  return (
    <AgGridTable
      gridData={gridData}
      gridConfig={columnDefs}
      id="man_hours_report"
      fieldsToSearch={["name", "foreman", "total", "ytd_hours", "ytd_absent", "ytd_late"]}
      suppressSizeToFit
      updateColumnDefs
    />
  );
};

export default ManHoursReportTable;
