const fieldlogStatusColors = {
  Submitted: "#FFA400",
  Rejected: "#ff0000",
  Approved: "#0071CE",
  Exported: "#0071CE",
  "In Progress": "#FF6700",
  "PM Approved": "#7DDB2D",
};

export default fieldlogStatusColors;
