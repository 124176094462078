import React from "react";
import styles from "./styles.module.scss";
import Map from "./Map";
import AssignTeam from "./AssignTeam";
import PropTypes from "prop-types";
import useProject from "../../hooks/useProject";
import CustomScroll from "react-custom-scroll";
import OnSiteList from "../OnSiteList/OnSiteList";
import useOnSiteCrew from "../../hooks/useOnSiteCrew";

const Sidebar = ({ roleOptions }) => {

  const { project } = useProject();
  const { onSiteCrew } = useOnSiteCrew();

  const teamAssignments = [
    {
      key: "project_manager",
      label: "Assign Project Manager",
    },
    {
      key: "project_coordinator",
      label: "Assign Project Coordinator",
    },
    {
      key: "superintendant",
      label: "Assign Superintendent",
    },
    {
      key: "foremen",
      label: "Assign Foreman(men)",
    },
  ];

  return (
    <div className={styles.sidebar}>
      <Map project={project} />
      <CustomScroll className={styles.scroll} allowOuterScroll={true}>
        <div className={styles.scrollWrap}>
          {!!project && project.status === "precon" ? (
            <AssignTeam
              items={teamAssignments}
              roleOptions={roleOptions}
            />
          ) : (
            <OnSiteList onSiteCrew={onSiteCrew}/>
          )}
        </div>
      </CustomScroll>
    </div>
  );
}

Sidebar.propTypes = {
  roleOptions: PropTypes.object,
};

export default Sidebar;
