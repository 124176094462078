import React from "react";
import ModalWrapper from "../ModalWrapper";
import PropTypes from "prop-types";
import useRenameAlbum from "./useRenameAlbum";

const RenameAlbumModal = ({ showModal, setShowModal, album, setAlbum }) => {
  const { headerContent, bodyContent, footerContent, onHide } = useRenameAlbum({ album, setShowModal, setAlbum });

  return (
    <ModalWrapper
      showModal={showModal}
      setShowModal={setShowModal}
      headerContent={headerContent}
      bodyContent={bodyContent}
      footerContent={footerContent}
      onHide={() => onHide()}
    />
  );
};

RenameAlbumModal.propTypes = {
  album: PropTypes.object,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  setAlbum: PropTypes.func,
};

export default RenameAlbumModal;
