import React from "react";
import PropTypes from "prop-types";
import { useAuth } from "component-library";

const Requirements = ({ data }) => {
  const { config } = useAuth();

  return (
    <span>
      {config.submittal_requirements
        .filter((req) => data.requirements.indexOf(req.id) > -1)
        .map((r) => r.name)
        .join(", ")}
    </span>
  );
};

export default Requirements;

Requirements.propTypes = {
  data: PropTypes.object,
};
