import { fieldLogsApi, FilterSearch, ItemToggler, useApi, useAuth } from "component-library";
import React, { useEffect, useState } from "react";
import { Alert, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { Bell } from "react-feather";
import styles from "./styles.module.scss";
import CustomScroll from "react-custom-scroll";
import "react-custom-scroll/dist/customScroll.css";

const ForemanReminder = () => {
  const { config } = useAuth();
  const { loading, error, request, data } = useApi(fieldLogsApi.remindForemen);
  const [filteredForemen, setFilteredForemen] = useState(config.foreman);
  const [selectedForemenIds, setSelectedForemenIds] = useState([]);

  useEffect(() => {
    setFilteredForemen(config.foreman);
  }, [config]);

  const filterForemen = (q) => {
    setFilteredForemen(
      config.foreman.filter((f) => `${f.first_name} ${f.last_name}`.toLowerCase().indexOf(q.toLowerCase()) > -1)
    );
  };

  const toggleForemanSelection = (foremanId) => {
    if (selectedForemenIds.includes(foremanId)) {
      setSelectedForemenIds(selectedForemenIds.filter((id) => id !== foremanId));
    } else {
      setSelectedForemenIds([...selectedForemenIds, foremanId]);
    }
  };

  const sendReminders = () => {
    request(selectedForemenIds);
  };

  const ForemanSelectorPopover = (
    <Popover id="foremenToggler" className={styles.foremenModal}>
      <Popover.Body>
        <div className={styles.foremenHeader}>
          <FilterSearch searchFunction={filterForemen} inline />
        </div>
        <div className={`position-relative ${styles.foremenWrap}`}>
          {!!error && (
            <Alert variant="danger" className="m-2">
              {error}
            </Alert>
          )}
          {!!data && !!data.success && (
            <Alert variant="success" className="m-2">
              Reminders sent.
            </Alert>
          )}
          <CustomScroll>
            <div className={styles.foremen}>
              {filteredForemen.map((f) => (
                <div className="d-flex align-items-center" key={f.id}>
                  <ItemToggler
                    item={f}
                    selectedIds={selectedForemenIds}
                    toggleSelection={toggleForemanSelection}
                    label={`${f.first_name} ${f.last_name}`}
                  />
                </div>
              ))}
            </div>
          </CustomScroll>
        </div>
        <div className={styles.foremenFooter}>
          <Button
            variant="tertiary"
            className="w-100 med"
            disabled={!!loading || !selectedForemenIds.length}
            onClick={sendReminders}
          >
            {!!loading ? "Loading..." : "Send Reminder"}
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom-end"
      transition={false}
      overlay={ForemanSelectorPopover}
      rootClose
    >
      <Button className={`${styles.overlayButton} btn btn-link med`}>
        <Bell className={styles.icon} color="#0071ce" size={16} />
        Remind Foremen
      </Button>
    </OverlayTrigger>
  );
};

export default ForemanReminder;
