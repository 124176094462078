import Addendas from "../bidColumns/Addendas";

const addendas = {
  headerName: "Addendum",
  field: "addendas",
  headerClass: "ag-center-aligned-header",
  width: 124,
  cellStyle: { justifyContent: "center" },
  suppressMenu: true,
  cellRenderer: Addendas,
};

export default addendas;
