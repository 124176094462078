import React from "react";
import { StyledDatePicker } from "component-library";
import PhaseBadge from "./PhaseBadge";
import Select from "react-select";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const PhaseRepeater = ({ getPhaseColor, phaseToAdd, renderFieldRowRepeater, geoFenceOptions }) => {
  return (
    <>
      {renderFieldRowRepeater({
        name: "phases",
        emptyItem: {
          name: "",
          start_date: null,
          end_date: null,
          geofences: [],
          key: phaseToAdd,
        },
        rowClasses: "my-0",
        renderItem(item, handleChange, name) {
          return (
            <div key={item.key} className="col col-11">
              <div className="row align-items-center">
                <div className={`${styles.badgeWrapper} mb-2 mb-lg-0 ms-3 ms-lg-0`}>
                  <PhaseBadge
                    phaseColor={getPhaseColor(item.key - 1)}
                    phaseName={item.key ? `Phase ${item.key}` : item.name}
                  />
                </div>
                <div className="col-lg-4 col-xl-5">
                  <input
                    type="text"
                    name="phase_name"
                    value={item.name}
                    onChange={(e) => handleChange(name, item.key, "name", e.target.value)}
                    className="form-control"
                    placeholder="Phase name"
                    required
                  />
                </div>
                <div className="col">
                  {!!geoFenceOptions.length ? (
                    <Select
                      isMulti={true}
                      options={geoFenceOptions}
                      name="geofences"
                      id="geofences"
                      onChange={(value) => handleChange("phases", item.key, "geofences", value)}
                      value={item.geofences}
                    />
                  ) : (
                    <Select isDisabled={true} placeholder="No Zones Created" />
                  )}
                </div>
                <div className="col-sm-5 col-lg-3">
                  <div className="d-flex align-items-center justify-content-lg-end my-2">
                    <div className="sm me-1">Start Date:</div>
                    <div className={styles.flex1}>
                      <StyledDatePicker
                        name="start_date"
                        selected={item.start_date}
                        onChange={(e) => handleChange("phases", item.key, "start_date", e)}
                        selectsStart
                        startDate={item.start_date}
                        endDate={item.end_date}
                        isClearable
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-lg-end my-2">
                    <div className="sm me-1 ">End Date:</div>
                    <div className={styles.flex1}>
                      <StyledDatePicker
                        name="end_date"
                        selected={item.end_date}
                        onChange={(e) => handleChange("phases", item.key, "end_date", e)}
                        selectsEnd
                        endDate={item.end_date}
                        startDate={item.start_date}
                        minDate={item.start_date ? item.start_date : null}
                        isClearable
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        },
        center: true,
        hideButton: true,
      })}
    </>
  );
};

PhaseRepeater.propTypes = {
  getPhaseColor: PropTypes.func.isRequired,
  phaseToAdd: PropTypes.number.isRequired,
  renderFieldRowRepeater: PropTypes.func.isRequired,
  geoFenceOptions: PropTypes.array.isRequired,
};

export default PhaseRepeater;
