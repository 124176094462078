import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { AgGridMasterDetail, useAuth } from "component-library";
import fieldLogListConfig from "../../config/fieldLogListConfig";
import useDetailCellConfig from "../../config/fieldLogListConfig/detailCellConfig";
import gridRowHeights from "../../utils/gridRowHeights";
import useResourceManagement from "../context/useResourceManagement";
import PrintableFieldLogs from "./PrintableFieldLogs";

const Listing = ({ fieldLogs, loading, isGlobal, viewAll, initialLoad }) => {
  const { id } = useParams();
  const { userCan, user } = useAuth();
  const { flExpanded, setFlExpanded } = useResourceManagement();
  const cellConfig = useDetailCellConfig();
  const [columnDefs, setColumnDefs] = useState(fieldLogListConfig);

  const handleCellClick = ({ data, column }) => {
    if (!["expand", "selected", "note_count", "work_day_status_name"].includes(column?.colId)) {
      let url = !!isGlobal
        ? `/field-management/field-logs/${data.workday_id}/foreman/${data.foreman_id}`
        : `/project/${id}/field-management/field-logs/${data.workday_id}/foreman/${data.foreman_id}`;
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    setFlExpanded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldLogs]);

  useEffect(() => {
    let newColumnDefs = fieldLogListConfig;
    if (!isGlobal || !userCan("payroll-review-time")) {
      newColumnDefs = newColumnDefs.filter((single) => single.field !== "selected");
    }
    if (!!viewAll) {
      newColumnDefs = newColumnDefs.filter((single) => single.field !== "expand");
    }
    setColumnDefs(newColumnDefs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewAll]);

  return (
    <>
      <div className="showPrint">
        <PrintableFieldLogs data={fieldLogs} />
      </div>
      <div className="hiddenPrint">
        <AgGridMasterDetail
          gridData={fieldLogs}
          gridConfig={columnDefs}
          loading={loading}
          detailCellConfig={cellConfig}
          isRowMaster={(dataItem) => (dataItem?.timesheets?.length ? true : false)}
          getDetailRowData={(params) => {
            params.successCallback(params.data?.timesheets);
          }}
          rowHeight={gridRowHeights.medium}
          id={`${user.id}_field_log_listing`}
          presetStyle="sortableTable"
          expandAll={flExpanded}
          handleCellClick={handleCellClick}
          suppressSizeToFit
          autoHeight
          updateColumnDefs
          redrawRows
          fallbackMessage="No field logs found."
        />
      </div>
    </>
  );
};

Listing.propTypes = {
  fieldLogs: PropTypes.array,
  loading: PropTypes.bool,
  isGlobal: PropTypes.bool,
};

export default Listing;
