import React, { useState } from "react";
import { useFormControl, useApi, bidApi, dropdownStyles, Loader } from "component-library";
import { schema } from "../../schema/bidBond";
import Select from "react-select";
import { Alert } from "react-bootstrap";
import useUpdateTodos from "./useUpdateTodos";
import TodoAttachment from "../TodoAttachment";
import { NumericFormat } from "react-number-format";

const useBidBond = ({ task, setTodos, setBid, bid, setDocLoading }) => {
  const { error, request, data: updateData, loading } = useApi(bidApi.completeToDo);
  const [bidBond, setBidBond] = useState({
    bid_bond_amount: task.bid_bond_amount,
    bid_bond_amount_type: task.bid_bond_amount_type,
  });
  const { dataError, setDataError } = useUpdateTodos(updateData, setTodos, setBid);

  const amountOptions = [
    { value: "5", label: "5%" },
    { value: "10", label: "10%" },
    { value: "other", label: "Other %" },
  ];

  const initialData = {
    bid_bond_date: task.bid_bond_date,
    bid_bond_comments: task.bid_bond_comments,
    bid_bond_plans_form_required: !!task.file_id ? 1 : 0,
    bid_bond_subcontracted_amount: task.bid_bond_subcontracted_amount,
  };

  const handleBidBondFieldChange = (name, value) => {
    setBidBond({ ...bidBond, [name]: value });
  };

  const onSubmit = async (formData, setLoading) => {
    try {
      setDataError(false);
      const { success } = await request({
        project_id: task.project_id,
        todo_id: task.bid_todo.id,
        bid_bond_plans_form_required: formData.bid_bond_plans_form_required,
        bid_bond_date: formData.bid_bond_date,
        bid_bond_amount: bidBond.bid_bond_amount.replace("%", ""),
        bid_bond_amount_type: bidBond.bid_bond_amount_type,
        bid_bond_comments: formData.bid_bond_comments,
        bid_bond_subcontracted_amount: formData.bid_bond_subcontracted_amount,
        complete: 1,
      });
      setLoading(false);
      if (!success) {
        throw new Error();
      }
    } catch (message) {
      setLoading(false);
      throw new Error(message);
    }
  };

  const { handleSubmit, renderSubmitButton, renderCheckbox, renderTextField, renderTextareaField, setFields, data } =
    useFormControl({
      schema,
      onSubmit,
      initialData,
    });

  const fields = (
    <div>
      <Loader loading={!!loading} />
      <div>Is a Specific Bond Form Required?</div>
      {renderCheckbox({
        name: "bid_bond_plans_form_required",
        isToggle: true,
        ariaLabel: "Is a Specific Bond Form Required?",
      })}
      {data.bid_bond_plans_form_required === 1 && (
        <>
          <div>Add Attachment</div>
          <TodoAttachment setBid={setBid} bid={bid} task={task} setDocLoading={setDocLoading} />
        </>
      )}
      {renderTextField({
        name: "bid_bond_date",
        label: "Date of Bid Bond",
        placeholder: "mm/dd/yyyy",
        type: "date",
      })}
      <div className={`form-group`}>
        <label htmlFor="bid_bond_amount_type">Amount</label>
        <Select
          options={amountOptions}
          name="bid_bond_amount_type"
          className="w-100"
          id="bid_bond_amount_type"
          onChange={({ value }) => handleBidBondFieldChange("bid_bond_amount_type", value)}
          value={amountOptions.find((opt) => opt.value === bidBond["bid_bond_amount_type"])}
          isClearable={false}
          tabSelectsValue={false}
          styles={dropdownStyles}
          isMulti={false}
          isSearchable={false}
        />
      </div>

      {bidBond.bid_bond_amount_type === "other" && (
        <div className={`form-group`}>
          <label htmlFor="bid_bond_amount_type" />
          <NumericFormat
            type="text"
            name="bid_bond_amount"
            className="form-control"
            id="bid_bond_amount"
            value={bidBond["bid_bond_amount"]}
            suffix="%"
            displayType={"input"}
            thousandSeparator={false}
            onChange={({ target: { value } }) =>
              setBidBond({
                ...bidBond,
                bid_bond_amount: value,
              })
            }
          />
        </div>
      )}
      {renderTextField({
        name: "bid_bond_subcontracted_amount",
        label: "Subcontracted Amount",
        format: "currency",
      })}
      {renderTextareaField({
        name: "bid_bond_comments",
        label: "Other Comments",
      })}
      {error && <Alert variant="danger">{error}</Alert>}
      {!!dataError && <Alert variant="danger">Something went wrong. Please try again.</Alert>}
    </div>
  );
  return {
    schema,
    initialData,
    fields,
    handleSubmit,
    renderSubmitButton,
    setFields,
  };
};

export default useBidBond;
