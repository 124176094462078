import React from "react";
import PropTypes from "prop-types";
import DateOption from "./DateOption";

const RepeatingDates = ({ renderTextField, renderSelectField, formData, setFields, dateOptions }) => {
  return (
    <>
      {dateOptions.map((opt) => (
        <DateOption
          formData={formData}
          setFields={setFields}
          renderSelectField={renderSelectField}
          renderTextField={renderTextField}
          item={opt}
          key={opt.name}
        />
      ))}
    </>
  );
};

export default RepeatingDates;

RepeatingDates.propTypes = {
  formData: PropTypes.object,
  setFields: PropTypes.func,
  renderTextField: PropTypes.func.isRequired,
  renderSelectField: PropTypes.func.isRequired,
};
