import React, { useState } from "react";
import ProjectNote from "../ProjectNote";
import PhotosModal from "../PhotosModal";
import PropTypes from "prop-types";

const Note = ({ project, note, isInTable = false }) => {
  const [photos, setPhotos] = useState([]);
  const [photosModalOpen, setPhotoModalOpen] = useState();
  return (
    <>
      <ProjectNote
        project={project}
        photos={photos}
        setPhotos={setPhotos}
        note={note}
        setPhotoModalOpen={setPhotoModalOpen}
        isInTable={isInTable}
      />
      {photosModalOpen && (
        <>
          <PhotosModal photos={photos} open={photosModalOpen} setOpen={setPhotoModalOpen} />
        </>
      )}
    </>
  );
};

Note.propTypes = {
  note: PropTypes.object,
};

export default Note;
