import ToggleViewAll from "../columns/ToggleViewAll";

const toggle = {
  headerName: "",
  suppressMenu: true,
  sortable: false,
  width: 50,
  maxWidth: 50,
  headerComponent: ToggleViewAll,
  cellRenderer: "agGroupCellRenderer",
};

export default toggle;
