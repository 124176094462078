import JobNumber from "../../ListView/JobNumber";

const job_number = {
  headerName: "Job #",
  field: "job_number",
  cellRenderer: JobNumber,
  width: 112,
};

export default job_number;
