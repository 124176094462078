import React from "react";
import { ArrowRightCircle, PauseCircle } from "react-feather";
import Initials from "../../../../../../_fleetManagement/Initials";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import convertMinutesToTime from "../../../../../../utils/convertMinutesToTime";

const SingleEquipment = ({ index, equipment }) => {
  return (
    <div key={index} className={styles.singleEquipment}>
      <div>
        <Initials name={equipment?.category} size="large" />
      </div>
      <div>
        <div className={`${styles.equipmentName} text-limiter`}>
          <Link className={styles.equipID} to={`/fleet-management/equipment/details/${equipment?.equip_no}`}>
            <span>{equipment?.equip_no}</span>
          </Link>
          <span>{equipment?.display_description || equipment?.name}</span>
        </div>
        <div className="d-flex align-items-center">
          <div className="me-4 text-gray2">
            <ArrowRightCircle color="#7DDB2D" size={16} /> {convertMinutesToTime(parseInt(equipment.run_minutes))}hrs
          </div>
          <div className="text-gray2">
            <PauseCircle color="#DCB600" size={16} /> {convertMinutesToTime(parseInt(equipment.idle_minutes))}hrs
          </div>
        </div>
      </div>
    </div>
  );
};

SingleEquipment.propTypes = {
  equipment: PropTypes.object,
};

export default SingleEquipment;
