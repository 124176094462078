import React from "react";
import useUserAdmin from "./hooks/useUserAdmin";
import { Modal, Button } from "react-bootstrap";

const ActivateUserModal = () => {
  const { confirmActivate, setConfirmActivate, userToActivate, updateUserStatus, setUserToActivate } = useUserAdmin();

  const handleActivate = () => {
    updateUserStatus(userToActivate);
    setConfirmActivate(false);
    setUserToActivate();
  };

  return (
    <Modal
      animation={false}
      show={!!confirmActivate && !!Object.keys(userToActivate).length}
      onHide={() => setConfirmActivate(false)}
      centered
    >
      <Modal.Header closeButton>
        <h5 className="mb-0 med">
          Confirm Activating: {userToActivate?.last_name}, {userToActivate?.first_name}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-end">
          <Button className="mx-2" variant="tertiary" size="lg" onClick={() => setConfirmActivate(false)}>
            Cancel
          </Button>
          <Button className="ms-2" size="lg" onClick={() => handleActivate()}>
            Activate
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ActivateUserModal;
