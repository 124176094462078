import React from "react";
import styles from "./styles.module.scss";

const CompanyName = ({ data }) => {
  return data.company_name ? (
    <div className="d-flex flex-column">
      <div className={styles.nameText}>{data.company_name},</div>
      {data.location_name && (
        <div className={styles.locationText}>{data.location_name}</div>
      )}
    </div>
  ) : null;
};

export default CompanyName;
