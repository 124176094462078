import { Folder } from "react-feather";
import SharePage from "../../SharePage";

const SharedAlbum = () => {
  return (
    <SharePage
      pageTitle={"Album Name"}
      subtitle="1 photos"
      loading={false}
      error={null}
      fillScreen
      navIcon={<Folder color="#0071CE" size={16} />}
    >
      <>Share Album Page</>
    </SharePage>
  );
};

export default SharedAlbum;
