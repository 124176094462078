import React from 'react';
import { HelpCircle } from "react-feather";
import SupportContent from "../../_support/Support";
import InteriorPage from "../pageLayout/InteriorPage";

const Support = () => {
  return (
    <InteriorPage
      navTitle="Support"
      navIcon={<HelpCircle color="#fff" size={14} />}
    >
      <SupportContent />
    </InteriorPage>
  );
}

export default Support;