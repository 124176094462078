import React from "react";
import convertMinutesToTime from "../../../utils/convertMinutesToTime";

const Lunch = ({ data }) => {
  //TO DO: get api guys to get these terms to match
  const lunchTime = data.lunch_duration || data.lunch;
  const hasAlert = !!data?.alerts?.lunch || (!data.absent && data.lunch < 30);

  return (
    <div style={!!hasAlert ? { color: "#E70000" } : null}>{lunchTime ? convertMinutesToTime(lunchTime) : "0:00"}</div>
  );
};

export default Lunch;
