import React from "react";
import { Routes, Route, Navigate } from "react-router";
import PrivateRoute from "../../../components/PrivateRoute";
import Analysis from "./Analysis";
import Summary from "./Summary";
import TaskDetail from "./TaskDetail";

const ReportingRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="summary" />} />
      <Route path="summary" element={<PrivateRoute module="ProjectManagement" Component={<Summary />} />} />
      <Route path="task/:taskId" element={<PrivateRoute module="ProjectManagement" Component={<TaskDetail />} />} />

      <Route path="analysis" element={<PrivateRoute module="ProjectManagement" Component={<Analysis />} />} />
    </Routes>
  );
};

export default ReportingRoutes;
