import { useState, useEffect } from "react";
import { handleError, useFormControl, useApi, bidApi } from "component-library";
import { schema, initialData } from "../schema/phases";
import { Alert, Button } from "react-bootstrap";
import { Plus } from "react-feather";
import { phaseColors } from "../config/phaseColors";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import PhaseRepeater from "../_projects/project/EstimateConversion/TaskManagment/Phases/PhaseRepeater";

const useEditPhases = (project, phases, setPhases, setMessage, setEditPhases, refreshUpbItems, syncPhases, canEdit) => {
  const [geoFenceOptions, setGeoFenceOptions] = useState([]);
  const [bidsToRemove, setBidsToRemove] = useState([]);
  const [autoAssignPhases, setAutoAssignPhases] = useState(false);
  const { data: updateData, error: updateError, request: updateRequest } = useApi(bidApi.updatePhases);

  const onSubmit = async (data, setLoading) => {
    const formattedData = data.phases.map((phase) => {
      if (!!phase.id) {
        return {
          name: phase.name,
          id: phase.id,
          start_date: DateTime.fromJSDate(phase.start_date).toISODate(),
          end_date: DateTime.fromJSDate(phase.end_date).toISODate(),
          geofences:
            !!phase && !!phase.geofences && !!phase.geofences.length ? phase.geofences.map((geo) => geo.value) : [],
          project_id: project.id,
        };
      }
      return {
        name: phase.name,
        geofences:
          !!phase && !!phase.geofences && !!phase.geofences.length ? phase.geofences.map((geo) => geo.value) : [],
        start_date: DateTime.fromJSDate(phase.start_date).toISODate(),
        end_date: DateTime.fromJSDate(phase.end_date).toISODate(),
        project_id: project.id,
      };
    });
    try {
      const { success } = await updateRequest({
        delete_ids: bidsToRemove,
        phases: formattedData,
        project_id: project.id,
      });

      if (success) {
        if (autoAssignPhases) {
          syncPhases();
        } else {
          refreshUpbItems();
        }
        if (!!bidsToRemove.length) {
          setMessage("Phases updated.");
        } else {
          setMessage("Phases added.");
        }
      }
    } catch (error) {
      handleError(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!!updateData && !!updateData.phases) {
      if (updateData.phases.length === 1) {
        setPhases(updateData.phases);
      } else if (updateData.phases.length > 1) {
        const sortedPhases = updateData.phases.sort((a, b) => {
          return a.start_date > b.start_date ? 1 : -1;
        });
        setPhases(sortedPhases);
      } else {
        setPhases([]);
      }
      setEditPhases(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    if (!!project && !!project.geofence && !!project.geofence.length) {
      const options = project.geofence.map((geofence) => {
        return {
          value: geofence.id,
          label: geofence.name,
        };
      });
      setGeoFenceOptions(options);
    }
  }, [project]);

  const { handleSubmit, renderSubmitButton, renderFieldRowRepeater, setFields, data } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });
  const [phaseToAdd, setPhaseToAdd] = useState(1);

  useEffect(() => {
    if (!!geoFenceOptions && !!phases && !!phases.length) {
      const formattedData = phases.map((phase, index) => {
        return {
          ...phase,
          name: phase.name,
          geofences:
            phase.geofences.map((geo) => {
              return geoFenceOptions.find((opt) => geo === opt.value);
            }) || [],
          start_date: phase.start_date ? DateTime.fromISO(phase.start_date).toJSDate() : null,
          end_date: phase.end_date ? DateTime.fromISO(phase.end_date).toJSDate() : null,
          project_id: project.id,
          key: index + 1,
        };
      });
      setFields({
        phases: formattedData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phases, geoFenceOptions]);

  useEffect(() => {
    if (!!data && !!data.phases) {
      setPhaseToAdd(findNextPhase(data.phases));
      const missingIds = phases.map((phase) => {
        if (!data.phases.find((dataPhase) => dataPhase.id === phase.id)) {
          return phase.id;
        }
        return null;
      });
      setBidsToRemove(missingIds.filter((bid) => bid !== null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const missingNumber = (array) => {
    const sortedArray = array.sort((a, b) => a - b);
    if (sortedArray[0] !== 1) {
      return 1;
    }
    for (let i = 0; i < sortedArray.length - 1; i++) {
      if (i === sortedArray.length - 2) {
        return sortedArray[i + 1] + 1;
      } else if (sortedArray[i + 1] - sortedArray[i] !== 1) {
        return sortedArray[i] + 1;
      }
    }
  };

  const findNextPhase = (phases) => {
    const phaseIds = phases.map((phase) => phase.key);
    if (phaseIds.length > 1) {
      const sortedPhaseIds = phaseIds.sort((a, b) => a - b);
      return missingNumber(sortedPhaseIds);
    } else {
      if (phaseIds[0] === 1) {
        return 2;
      } else {
        return 1;
      }
    }
  };

  const getPhaseColor = (phaseNumber) => {
    return phaseColors[phaseNumber % phaseColors.length];
  };

  const fields = (
    <>
      {!!updateError && <Alert variant="danger">{updateError}</Alert>}

      <PhaseRepeater
        getPhaseColor={getPhaseColor}
        renderFieldRowRepeater={renderFieldRowRepeater}
        phaseToAdd={phaseToAdd}
        geoFenceOptions={geoFenceOptions}
      />

      <div className="row me-3">
        <div className="col-6">
          <Button
            className="ms-n4 med"
            size="sm"
            variant="link"
            disabled={!canEdit}
            onClick={() =>
              setFields({
                ...data,
                phases: [
                  ...data.phases,
                  {
                    name: "",
                    start_date: null,
                    end_date: null,
                    key: phaseToAdd,
                  },
                ],
              })
            }
          >
            Add Phase <Plus size="14" />
          </Button>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <div className="d-flex align-items-center px-3 mt-2">
            <input
              type="checkbox"
              name="auto_assign_phases"
              className={`me-2`}
              checked={autoAssignPhases}
              onChange={(e) => setAutoAssignPhases(e.target.checked)}
              aria-label="auto assign phases"
              id="auto-assign-toggle"
              disabled={!canEdit}
            />
            <label htmlFor="auto-assign-toggle" className="form-check-label">
              Auto-Assign-Phases
            </label>
          </div>
          {renderSubmitButton("Save Phases", !canEdit)}
        </div>
      </div>
    </>
  );

  return {
    handleSubmit,
    fields,
    setFields,
  };
};

useEditPhases.propTypes = {
  project: PropTypes.object.isRequired,
  phases: PropTypes.array,
  setPhases: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  setEditPhases: PropTypes.func.isRequired,
};

export default useEditPhases;
