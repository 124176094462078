import React from "react";
import styles from "./styles.module.scss";

const Name = ({ data }) => {
  return data.first_name && data.last_name ? (
    <div className={styles.nameText}>
      {data.last_name}, {data.first_name}
    </div>
  ) : null;
};

export default Name;
