import Submitted from "../Columns/Submitted";

const submitted = {
  headerName: "Submitted",
  field: "date_submitted",
  width: 170,
  cellRenderer: Submitted,
};

export default submitted;
