import React from "react";
import styles from "./styles.module.scss";
import TimeEntries from "./TimeEntries";
import PropTypes from "prop-types";
import TimeEntryPopover from "../TimeEntryPopover/TimeEntryPopover";
import { useAuth } from "component-library";

const TaskBody = ({
  task,
  config,
  editing,
  setFieldLog,
  fieldLog,
  crew,
  foremanId,
  overAllocatedTimeSheets,
  setOverAllocatedTimeSheets,
  prevailingWage,
  entries,
  crewLoading,
  projectId,
}) => {
  const { userCan } = useAuth();

  return (
    <>
      {!!task && (
        <div className={`text-gray2 ${styles.taskBody}`}>
          <TimeEntries
            config={config}
            entries={entries}
            editing={editing}
            setFieldLog={setFieldLog}
            fieldLog={fieldLog}
            foremanId={foremanId}
            overAllocatedTimeSheets={overAllocatedTimeSheets}
            setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
            prevailingWage={prevailingWage}
            projectId={projectId}
          />
          {!!userCan("payroll-review-time") && !!editing && (
            <div className="d-flex justify-content-center align-items-center flex-row">
              <TimeEntryPopover
                laborTypes={config?.labor_types}
                task={task}
                crew={crew}
                crewLoading={crewLoading}
                fieldLog={fieldLog}
                setFieldLog={setFieldLog}
                foremanId={foremanId}
                overAllocatedTimeSheets={overAllocatedTimeSheets}
                setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

TaskBody.propTypes = {
  task: PropTypes.object.isRequired,
  config: PropTypes.object,
  editing: PropTypes.bool,
  setFieldLog: PropTypes.func,
  fieldLog: PropTypes.object,
  foremanId: PropTypes.string,
  overAllocatedTimeSheets: PropTypes.array,
  setOverAllocatedTimeSheets: PropTypes.func,
  prevailingWage: PropTypes.any,
  entries: PropTypes.array,
};

export default TaskBody;
