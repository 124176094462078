import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useApi, timesheetApi, useAuth } from "component-library";

const useAddTimeEntry = ({ foremanId, fieldLog, setFieldLog, task, laborTypes = [] }) => {
  const { user } = useAuth();
  const genLa = laborTypes.find((lc) => lc.trade_no === "GENLA");
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const {
    request: addEntryRequest,
    data: addEntryData,
    loading: addEntryLoading,
    error: addEntryError,
  } = useApi(timesheetApi.addTimeEntry);

  const addTimeEntry = (timesheet, selectedCrew, minutes = 0) => {
    const newTimeEntry = {
      labor_id: genLa.id,
      labor_types: genLa,
      work_day_task_id: task.id,
      user: selectedCrew,
      minutes: minutes,
      allocated_by: user,
      time_sheet_id: timesheet.id,
    };

    addEntryRequest(newTimeEntry);
  };

  const updateTaskEntries = (entry) => {
    const updatedTask = {
      ...task,
      time_entries: [...task.time_entries, entry],
      time_entry_ids: [...task.time_entry_ids, entry.id],
      work_rate: entry.task_data.work_rate || 0,
    };

    setFieldLog({
      ...fieldLog,
      work_day_tasks: fieldLog.work_day_tasks.map((t) => (t.id === task.id ? updatedTask : t)),
      time_sheets: fieldLog.time_sheets.map((sheet) =>
        sheet.id === entry.time_sheet_id ? { ...sheet, time_entries: [...sheet.time_entries, entry] } : sheet
      ),
    });
  };

  useEffect(() => {
    if (!!addEntryData) {
      updateTaskEntries(addEntryData.time_entry.time_entry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEntryData]);

  useEffect(() => {
    if (!!addEntryLoading) {
      setLoading(addEntryLoading);
    } else {
      setLoading();
    }
  }, [addEntryLoading]);

  useEffect(() => {
    if (!!addEntryError) {
      setError(addEntryError);
    } else {
      setError();
    }
  }, [addEntryError]);

  return {
    loading,
    error,
    addTimeEntry,
  };
};

useAddTimeEntry.propTypes = {
  foremanId: PropTypes.any,
  fieldLog: PropTypes.object,
  setFieldLog: PropTypes.func,
  task: PropTypes.object,
  laborTypes: PropTypes.array,
};

export default useAddTimeEntry;
