import React, { useState, useEffect } from "react";
import { RefreshCcw, XCircle, Loader } from "react-feather";
import { useApi, projectApi, useAuth } from "component-library";

const Relocate = ({ data }) => {
  const { user } = useAuth();
  const userName = `${user.first_name} ${user.last_name}`;
  const [loading, setLoading] = useState(false);

  const { request: relocateRequest, loading: relocateLoading, data: relocateData } = useApi(projectApi.saveRelocate);

  const { request: saveRequest, loading: saveLoading, data: saveData } = useApi(projectApi.saveLocate);

  useEffect(() => {
    if (relocateLoading || saveLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [relocateLoading, saveLoading]);

  useEffect(() => {
    if (!!relocateData && !!relocateData.locates) {
      setRelocateRequested(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relocateData]);

  useEffect(() => {
    if (!!saveData && !!saveData.locates) {
      setRelocateRequested(!relocateRequested);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  const [relocateRequested, setRelocateRequested] = useState(data.status.toLowerCase() === "requested");

  useEffect(() => {
    if (data) {
      setRelocateRequested(data.status.toLowerCase() === "requested");
    }
  }, [data]);

  const handleRelocate = () => {
    if (!loading) {
      relocateRequest({
        relocate_id: data.id,
        id: data.id,
        description: data.description,
        number: null,
        status: "requested",
        type: data.type,
        sb: data.sb,
        ds: data.ds,
        sb_required: data.sb_required || 0,
        ds_required: data.ds_required || 0,
        active_date: data.active_date,
        expiration: data.expiration,
        project_id: data.project_id,
        parent_id: !!data ? data.parent_id : 0,
        user: userName,
      });
    }
  };

  const cancelRelocate = () => {
    if (!loading) {
      saveRequest({
        id: data.id,
        description: data.description,
        number: data.number,
        status: "Canceled",
        type: data.type,
        sb: data.sb,
        ds: data.ds,
        sb_required: data.sb_required || 0,
        ds_required: data.ds_required || 0,
        active_date: data.active_date,
        expiration: data.expiration,
        project_id: data.project_id,
        parent_id: !!data ? data.parent_id : 0,
        user: userName,
      });
    }
  };

  return (
    <div className="d-flex align-items-center px-3">
      {loading ? (
        <Loader size={14} />
      ) : (
        <>
          {relocateRequested ? (
            <XCircle size={16} color="#A7A7A7" onClick={cancelRelocate} />
          ) : (
            <>
              <RefreshCcw size={14} color="#0071ce" onClick={handleRelocate} />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Relocate;
