import React, { useState } from "react";
import Estimate from "./Estimate";
import TakeoffAmountField from "./TakeoffAmountField";
import TaskRows from "./Tasks/TaskRows";
import Difference from "./Difference";
import styles from "../../TaskManagment/styles.module.scss";
import { ChevronDown, ChevronRight } from "react-feather";

const Material = ({ material, setTakeoffs, takeoffs, updateTakeoffs, setNewMaterialError }) => {
  const [open, setOpen] = useState();

  return (
    <>
      <tr key={material.id} className={`${!!open ? styles.openTaskHeader : ""} default`}>
        <td className={`text-center p-0 ${!!open ? "border-0" : ""}`}>
          <div
            className={`
              ${!!open ? styles.materialHasBorder : styles.noBorder}
              px-1 text-center
            `}
          >
            {material.material_code || "-"}
          </div>
        </td>
        <td>
          <div className="d-flex align-items-center">
            {!!material?.tasks?.length &&
              (open ? (
                <ChevronDown color={"#7b7b7b"} size={15} onClick={() => setOpen(!open)} className="me-1" />
              ) : (
                <ChevronRight color={"#7b7b7b"} size={15} onClick={() => setOpen(!open)} className="me-1" />
              ))}
            <span className={styles.materialName}>{material.name}</span>
          </div>
        </td>
        <td className="text-end">
          <Estimate item={material} />
        </td>
        <td className="text-end">
          <TakeoffAmountField item={material} updateTakeoff={updateTakeoffs} open={open} />
        </td>
        <td className="text-end">
          <Difference item={material} />
        </td>
      </tr>
      {!!open && !!material?.tasks?.length && (
        <TaskRows
          setTakeoffs={setTakeoffs}
          takeoffs={takeoffs}
          material={material}
          setNewMaterialError={setNewMaterialError}
        />
      )}
    </>
  );
};

export default Material;
