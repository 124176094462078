import { Joi } from "component-library";
import regexPhone from "../../../utils/regexPhone";

const schema = {
  name: Joi.saneString().required().label("Name"),
  address1: Joi.saneString().required().label("Address"),
  address2: Joi.saneString().label("Address 2"),
  city: Joi.saneString().required().label("city"),
  state: Joi.saneString().required().label("State"),
  zip: Joi.saneString().required().label("ZIP"),
  phone: Joi.saneString().regex(regexPhone).label("Phone"),
  id: Joi.any(),
};

const initialData = {
  name: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
};

export { schema, initialData };
