const ytdAbsent = {
  headerName: "YTD Absent",
  field: "ytd_absent",
  type: "numericColumn",
  pinned: "right",
  width: 115,
  cellStyle: { justifyContent: "flex-end" },
};

export default ytdAbsent;
