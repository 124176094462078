import { useEffect, useState } from 'react';

const useGreeting = () => {
  const num_hours = new Date().getHours();
  const [currentGreeting, setCurrentGreeting] = useState("Salutations");

  useEffect(() => {
    const greetings = ["Good morning", "Good afternoon", "Good evening"];
    if (num_hours < 12) {
      setCurrentGreeting(greetings[0]);
    } else if (num_hours < 18) {
      setCurrentGreeting(greetings[1]);
    } else {
      setCurrentGreeting(greetings[2]);
    }
  }, [num_hours]);

  return currentGreeting;
}

export default useGreeting;