import React, { useState, useEffect } from "react";
import { Avatar } from "component-library";
import TimePicker from "../../../../components/TimePicker";
import { ChevronDown, ChevronRight } from "react-feather";
import convertMinutesToTime from "../../../../utils/convertMinutesToTime";
import { Button, Table } from "react-bootstrap";
import PropTypes from "prop-types";
import { setHours, setMinutes } from "date-fns";
import OverAllocated from "./OverAllocated";
import styles from "./styles.module.scss";
import InterfaceIcon from "../../../../components/InterfaceIcon";
import { Link } from "react-router-dom";

const TimesheetHeader = ({
  timesheet,
  open,
  setOpen,
  editing,
  overAllocatedTimeSheets,
  timeIn,
  timeOut,
  setTimeIn,
  setTimeOut,
  updateTimeIn,
  updateTimeOut,
  foremanId,
}) => {
  const [totalHours, setTotalHours] = useState(0);
  const [unAssignedHours, setUnassignedHours] = useState(0);
  const [isOverAllocated, setIsOverAllocated] = useState(false);

  useEffect(() => {
    setIsOverAllocated(overAllocatedTimeSheets.some((ts) => ts === timesheet.id));
  }, [overAllocatedTimeSheets, timesheet]);

  useEffect(() => {
    if (!!timesheet) {
      const entry_time = timesheet.time_entries.reduce((acc, curr) => {
        return acc + parseInt(curr.minutes, 10);
      }, 0);
      setUnassignedHours(parseInt(timesheet.adjusted_minutes, 10) - entry_time);
      setTotalHours(timesheet.total_minutes);
    }
  }, [timesheet]);

  const hyperlink = `/project/${timesheet.project_id}/field-management/timesheet/${timesheet.id}/foreman/${foremanId}`;

  return (
    <Table className={styles.table}>
      <thead>
        <tr>
          <th className={styles.toggleHeader}></th>
          <th className={styles.nameHeader}></th>
          <th>Total Hours</th>
          <th>Unassigned Hours</th>
          <th className={styles.clockHeader}>Clock In</th>
          <th className={styles.clockHeader}>Clock Out</th>
          <th className={styles.toggleHeader}></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Avatar user={timesheet?.user} hasColor size="sm" />
          </td>
          <td className={styles.timesheetName}>
            <Link to={hyperlink} className=" px-2">
              {timesheet?.user?.first_name} {timesheet?.user?.last_name}
            </Link>
          </td>
          <td className={styles.textRight}>{convertMinutesToTime(totalHours)}</td>
          <td className={styles.textRight}>
            <span className="d-flex align-items-center justify-content-end">
              {isOverAllocated && (
                <span className="me-2">
                  <OverAllocated user={timesheet.user} />
                </span>
              )}
              {convertMinutesToTime(unAssignedHours)}
            </span>
          </td>
          <td className={styles.textRight}>
            <span className="d-flex align-items-center justify-content-end">
              <span className={styles.interfaceIconWrapper}>
                <InterfaceIcon isWithinGeofence={timesheet.within_geofence} interfaceUsed={timesheet.interface} />
              </span>
              <TimePicker
                minTime={setHours(setMinutes(new Date(), 0), 0)}
                maxTime={setHours(
                  setMinutes(new Date(), parseInt(new Date(timeOut).getMinutes())),
                  parseInt(new Date(timeOut).getHours())
                )}
                selected={timeIn}
                setSelected={setTimeIn}
                sendRequest={(date) => updateTimeIn(Date.parse(date))}
                canEdit={editing}
              />
            </span>
          </td>
          <td className={styles.textRight}>
            <span className="d-flex align-items-center justify-content-end">
              <span className={styles.interfaceIconWrapper}>
                <InterfaceIcon
                  isWithinGeofence={timesheet.within_geofence_clock_out}
                  interfaceUsed={timesheet.interface_clock_out}
                />
              </span>
              <TimePicker
                minTime={setHours(
                  setMinutes(new Date(), parseInt(new Date(timeIn).getMinutes())),
                  parseInt(new Date(timeIn).getHours())
                )}
                maxTime={setHours(setMinutes(new Date(), 45), 23)}
                selected={timeOut}
                setSelected={setTimeOut}
                sendRequest={(date) => updateTimeOut(Date.parse(date))}
                canEdit={editing}
              />
            </span>
          </td>
          <td className={styles.textRight}>
            {open ? (
              <Button
                variant="transparent"
                size="sm"
                onClick={() => setOpen(false)}
                className="p-0"
                aria-label="hide tasks"
              >
                <ChevronDown size={18} color="#C4C4C4" />
              </Button>
            ) : (
              <Button
                variant="transparent"
                size="sm"
                onClick={() => setOpen(true)}
                className="p-0"
                aria-label="show tasks"
              >
                <ChevronRight size={18} color="#C4C4C4" />
              </Button>
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

TimesheetHeader.propTypes = {
  timesheet: PropTypes.object.isRequired,
  setTimesheet: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  logDate: PropTypes.string.isRequired,
  overAllocatedTimeSheets: PropTypes.array.isRequired,
};

export default TimesheetHeader;
