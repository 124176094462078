import React from "react";
import { useFormControl, Loader } from "component-library";
import { schema, initialData } from "../../schema/downloadFiles";
import { useApi, bidApi } from "component-library";
import { Alert } from "react-bootstrap";
import useUpdateTodos from "./useUpdateTodos";

const useDownloadFilesRequirements = (task, setTodos, setBid) => {
  const { request, data: updateData, loading, error } = useApi(bidApi.completeToDo);

  const { dataError, setDataError } = useUpdateTodos(updateData, setTodos, setBid);

  const onSubmit = async (formData, setLoading) => {
    setDataError(false);
    try {
      await request({
        project_id: task.project_id,
        todo_id: task.bid_todo.id,
        complete: 1,
        ...formData,
      });
      setLoading(false);
    } catch (message) {
      setLoading(false);
    }
  };

  const { handleSubmit, renderSubmitButton, renderCheckbox, setFields } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const fields = (
    <>
      <Loader loading={!!loading} />
      {renderCheckbox({
        name: "download_dbe",
        label: "Download DBE",
      })}
      {renderCheckbox({
        name: "download_ebsx",
        label: "Download EBSX",
      })}
      {!!error && <Alert variant="danger">{error}</Alert>}
      {!!dataError && <Alert variant="danger">{dataError}</Alert>}
    </>
  );
  return {
    schema,
    initialData,
    fields,
    handleSubmit,
    renderSubmitButton,
    setFields,
  };
};

export default useDownloadFilesRequirements;
