import React, { useEffect, useState, useContext } from "react";
import { ChevronDown, ChevronRight, MessageSquare } from "react-feather";
import { useAccordionButton, AccordionContext, Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import removeDuplicateValues from "../../../utils/removeDuplicateValues";

const useFieldNotes = ({ projectNotes, setQ }) => {
  const [addingNote, setAddingNote] = useState(false);
  const [sortedNotes, setSortedNotes] = useState();
  const [activeItems, setActiveItems] = useState([]);

  useEffect(() => {
    let activeValues = [];

    if (!!sortedNotes?.length) {
      projectNotes.forEach((note) => {
        if (!activeValues.includes(note.note_date)) {
          activeValues.push(note.note_date);
        }
      });
    }
    setActiveItems(activeValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let sorted = [];
    let notes = projectNotes?.map((note) => {
      return {
        ...note,
        note_date: note?.note_date || note?.created_at?.split(" ")[0],
      };
    });
    if (!!notes?.length) {
      const noteDates = notes?.map((note) => {
        return {
          note_date: note.note_date,
          notes: [],
        };
      });
      sorted = removeDuplicateValues(noteDates, "note_date");
      sorted = sorted.map((noteDate) => {
        const newNotes = notes.filter((note) => note.note_date === noteDate.note_date);
        return {
          ...noteDate,
          notes: newNotes,
        };
      });
    }
    setSortedNotes(sorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectNotes]);

  const handleToggle = (eventKey) => {
    if (activeItems.includes(eventKey)) {
      setActiveItems(activeItems.filter((item) => item !== eventKey));
    } else {
      setActiveItems([...activeItems, eventKey]);
    }
  };

  const handleNoteSearch = (query) => {
    setQ(query);
  };

  const ContextAwareToggle = ({ children, eventKey, callback, noteCount }) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () => {
      callback && callback(eventKey);
    });

    const isCurrentEventKey = !!activeEventKey?.length && !!activeEventKey.includes(eventKey);

    return (
      <Button
        variant="gray9"
        className={styles.toggleButton}
        type="button"
        onClick={() => {
          handleToggle(eventKey);
          decoratedOnClick();
        }}
      >
        {children}
        {
          <>
            <MessageSquare color="#D3D3D3" size={16} /> <span className={styles.noteCount}>{noteCount}</span>
          </>
        }
        {isCurrentEventKey ? <ChevronDown color="#C4C4C4" size={16} /> : <ChevronRight color="#C4C4C4" size={16} />}
      </Button>
    );
  };

  return {
    addingNote,
    setAddingNote,
    sortedNotes,
    ContextAwareToggle,
    activeItems,
    setActiveItems,
    handleNoteSearch,
  };
};

export default useFieldNotes;
