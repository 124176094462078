import React from "react";
import styles from "../styles.module.scss";
import RepeatingDates from "./RepeatingDates";
import SubmittalDate from "./SubmittalDate";

const OtherDates = ({ renderTextField, renderSelectField, formData, setFields }) => {
  const externalDateOptions = [
    {
      name: "pay_app_due_dates",
      label: "Pay App due dates",
      type: "pay_app_due_dates_type",
    },
    {
      name: "council_meeting_dates",
      label: "Council Meeting Dates",
      type: "council_meeting_dates_type",
    },
    {
      name: "progress_meeting_dates",
      label: "Progress Meeting Dates",
      type: "progress_meeting_dates_type",
    },
  ];

  return (
    <div className="d-flex">
      <div className={styles.columnWrap}>
        <h2 className={styles.heading18}>External Dates</h2>
        <RepeatingDates
          renderTextField={renderTextField}
          renderSelectField={renderSelectField}
          formData={formData}
          setFields={setFields}
          dateOptions={externalDateOptions}
        />
      </div>
      <div className={styles.columnWrap}>
        <h2 className={styles.heading18}>Submittal Dates</h2>
        <SubmittalDate
          renderSelectField={renderSelectField}
          renderTextField={renderTextField}
          formData={formData}
          mainField={{ name: "engineer_response_time", label: "Engineer Response Time" }}
          typeField="engineer_response_time_type"
          customField="engineer_response_time_other"
        />
        <SubmittalDate
          renderSelectField={renderSelectField}
          renderTextField={renderTextField}
          formData={formData}
          mainField={{ name: "supplier_response_time", label: "Default Supplier Response Time" }}
          typeField="supplier_response_time_type"
          customField="supplier_response_time_other"
        />
        <SubmittalDate
          renderSelectField={renderSelectField}
          renderTextField={renderTextField}
          formData={formData}
          mainField={{ name: "lead_time", label: "Default Lead Time" }}
          typeField="lead_time_type"
          customField="lead_time_other"
        />
      </div>
    </div>
  );
};

export default OtherDates;
