import React, { useState, useEffect } from "react";
import { AttachmentNav, ConfirmModal } from "component-library";
import { useParams } from "react-router-dom";
import useProjectAttachment from "../../../../ProjectAttachments/useProjectAttachment";
import { BeatLoader } from "react-spinners";
import { useToasts } from "component-library";
import { Trash2 } from "react-feather";
import RenameFileModal from "../../../AssetPage/RenameFileModal";
import useProjectDocuments from "../../context/useProjectDocuments";

const ActionMenu = ({ data }) => {
  const { triggerToast } = useToasts();
  const { id } = useParams();
  const [attachment, setAttachment] = useState();
  const [docLoading, setDocLoading] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const { attachments, attachmentsRequest, setAttachments, folderList } = useProjectDocuments();

  const handleRefreshAttachments = () => {
    attachmentsRequest(id);
  };

  const {
    removeFileLoading,
    removeAttachment,
    fileError,
    updateAttachment,
    removeAttachmentData,
    handleMoveFile,
    updateAttachmentInfoLoading,
    updateAttachmentInfoError,
  } = useProjectAttachment({
    fileName: data.file_name,
    projectId: id,
    type: data?.type,
    setAttachment,
    isSingle: true,
    setDocLoading,
    refreshAttachments: handleRefreshAttachments,
  });

  const handleRemoveAttachment = () => {
    removeAttachment(data);
  };

  useEffect(() => {
    if (data) {
      setAttachment(data);
    }
  }, [data]);

  useEffect(() => {
    if (removeAttachmentData) {
      setAttachments(removeAttachmentData?.project.attachments);
      setShowConfirmation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeAttachmentData]);

  useEffect(() => {
    if (!!updateAttachmentInfoError) {
      triggerToast(
        "Error moving file",
        `Error moving file: ${updateAttachmentInfoError}`,
        "attachment-move-error",
        "danger"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAttachmentInfoError]);

  return (
    <div className="d-flex align-items-center">
      {(!!docLoading || !!updateAttachmentInfoLoading) && (
        <div className="loaderWrapper">
          <BeatLoader color="#0071ce" />
        </div>
      )}

      {!!attachment && (
        <div className="mx-4">
          <AttachmentNav
            attachment={attachment}
            canEdit={true}
            hideHistory={false}
            removeAttachment={() => setShowConfirmation(true)}
            updateAttachment={updateAttachment}
            externalHandleUpload={true}
            position="left"
            attachments={attachments}
            manageFolder={data?.type !== "Construction Plans" && data?.type !== "Specbook"}
            handleMoveFile={handleMoveFile}
            setShowRenameModal={setShowRenameModal}
            folderList={folderList}
          />
        </div>
      )}
      <ConfirmModal
        showConfirmation={showConfirmation}
        setShowConfirmation={setShowConfirmation}
        confirmFunction={() => {
          handleRemoveAttachment();
        }}
        Icon={() => <Trash2 color="#ff6700" size={16} />}
        loading={removeFileLoading}
        error={fileError}
        name={"Confirm Delete File"}
        message={"Are you sure you want to delete this file?"}
        type="file"
        itemName={data?.file_name}
        buttonText="Delete File"
      />
      {!!showRenameModal && (
        <RenameFileModal asset={data} showModal={showRenameModal} setShowModal={setShowRenameModal} />
      )}
    </div>
  );
};

export default ActionMenu;
