import React from "react";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const EmptyAlbum = ({ setShowModal, setShowMultiSelect }) => {
  const numPlaceholders = 15;
  const placeholdersArray = Array.from({ length: numPlaceholders }, (_, index) => index);

  return (
    <div className={styles.noResultsWrapper}>
      <div className={styles.grid}>
        {placeholdersArray.map((index) => (
          <div key={index} className={styles.placeholder} />
        ))}
      </div>
      <div className={styles.overlay}>
        <h2 className={styles.title}>Empty Album</h2>
        <div className={styles.subtext}>Add some photos to get started</div>
        <Button onClick={() => setShowMultiSelect(true)} className={styles.uploadButton} variant="primary">
          Browse Tractics Library
        </Button>
        <Button onClick={() => setShowModal(true)} className={styles.uploadButton} variant="tertiary">
          Upload Photos
        </Button>
      </div>
    </div>
  );
};

EmptyAlbum.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  setShowMultiSelect: PropTypes.func,
};

export default EmptyAlbum;
