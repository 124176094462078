import User from "../columnRenders/User";

const user = {
  headerName: "User",
  field: "user",
  cellRenderer: User,
  autoHeight: true,
};

export default user;
