import React from "react";
import styles from "./styles.module.scss";
import checkReportFS from "../../../../utils/checkReportFS";
import formatGridValue from "../../../../utils/formatGridValue";

const QtyRepEst = ({ data }) => {
  const getReportedQty = (reportedQty) => {
    let qty = 0;

    if (!!reportedQty) {
      qty = formatGridValue(reportedQty);
    }

    return qty;
  };
  // Completed qty on sub task table
  const reportedQty = getReportedQty(data?.range?.[0]?.reported_quantity || data?.quantity_reported);

  const estQty = formatGridValue(data?.bid_quantity);

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.reported} ${checkReportFS([reportedQty, estQty], 14) ? "sm" : "med"}`}>
        <span className={styles.estimate}>{reportedQty}</span>/{estQty}
        <span className="ms-1">{data?.um?.toLowerCase()}</span>
      </div>
    </div>
  );
};

export default QtyRepEst;
