import { Joi } from "component-library";

const schema = {
  name: Joi.saneString().required().label("Album Title"),
};

const initialData = {
  name: "",
};

export { schema, initialData };
