import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const useToggleTasks = ({ tasks }) => {
  const [openTasks, setOpenTasks] = useState([]);
  const [allActive, setAllActive] = useState();

  const toggleTasksOpen = (taskId) => {
    let updatedOpenTasks;
    if (openTasks?.includes(taskId)) {
      updatedOpenTasks = openTasks.filter((id) => id !== taskId);
    } else {
      updatedOpenTasks = !!openTasks?.length ? [...openTasks, taskId] : [taskId];
    }
    setOpenTasks(updatedOpenTasks);
  };

  const handleToggleAll = () => {
    if (allActive) {
      setOpenTasks([]);
    } else {
      setOpenTasks(tasks?.map((task) => task.id));
    }
  };

  useEffect(() => {
    if (openTasks?.length === tasks?.length) {
      setAllActive(true);
    } else {
      setAllActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTasks]);

  return {
    openTasks,
    setOpenTasks,
    allActive,
    setAllActive,
    toggleTasksOpen,
    handleToggleAll,
  };
};

useToggleTasks.propTypes = {
  tasks: PropTypes.array,
};

export default useToggleTasks;
