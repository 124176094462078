const qty = {
  headerName: "Qty",
  field: "quantity",
  resizable: true,
  valueGetter: ({ data }) => {
    return `${data.quantity} / 1000 ${data?.unit_measure}`;
  },
  filterValueGetter: function ({ data }) {
    return `${data.quantity}`;
  },
};

export default qty;
