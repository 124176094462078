import React from "react";
import { DateRangeSelector } from "component-library";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import { getMaxDate } from "../../../../../../utils/getMaxDate";
import useProject from "../../../../../hooks/useProject";
import useResourceManagement from "../../../../../../_resourceManagement/context/useResourceManagement";

const DateSelector = () => {
  const { setCalendarClosed } = useResourceManagement();
  const { fieldMgmtDateRange, setFieldMgmtDateRange, handleOnDateBlur } = useProject();
  const newBlur = () => {
    setCalendarClosed(true);
    handleOnDateBlur();
  };
  return (
    <div className={styles.datePickerWrapper}>
      <DateRangeSelector
        className={styles.datePickerInner}
        dateRange={fieldMgmtDateRange}
        setDateRange={setFieldMgmtDateRange}
        handleOnDateBlur={newBlur}
        maxDate={getMaxDate(fieldMgmtDateRange, 14)}
        noDateText="No Date Selected"
        dropdown={true}
        split=""
      />
    </div>
  );
};

DateSelector.propTypes = {
  currentSelectedDate: PropTypes.string,
  selectedForemenIds: PropTypes.array,
};

export default DateSelector;
