import React from "react";
import styles from "./styles.module.scss";
import { Avatar } from "component-library";

const PM = ({ data }) => {
  return (
    <div className="d-flex align-items-center">
      {data?.project?.project_managers?.length
        ? data.project.project_managers.map((pm) => (
            <div key={pm.id} className={`${styles.text} d-flex align-items-center me-4`}>
              <span className={styles.avatarIcon}>
                <Avatar hasColor size="xs" user={pm?.user} />
              </span>
              {pm?.user?.first_name} {pm?.user?.last_name}
            </div>
          ))
        : "-"}
    </div>
  );
};

export default PM;
