import React, { useState } from "react";
import styles from "../styles.module.scss";
import { DateTime } from "luxon";
import { AlertTriangle, ChevronRight, ChevronDown } from "react-feather";
import { Button } from "react-bootstrap";
import Relocate from "./Relocate";
import PreviousLocate from "./PreviousLocate";
import PropTypes from "prop-types";
import { displayDate } from "component-library";

const LocatesListItem = ({ locate, setEditLocate, setLocates }) => {
  const [showHistory, setShowHistory] = useState(false);
  return (
    <>
      <tr className="list-row">
        <td>
          {!!locate.relocate.length && (
            <Button
              className={styles.rowHistoryToggleButton}
              onClick={() => setShowHistory(!showHistory)}
              aria-label={!!showHistory ? "Hide locate history toggle" : "Show locate history toggle"}
            >
              {showHistory ? <ChevronDown color="#D3D3D3" /> : <ChevronRight color="#D3D3D3" />}
            </Button>
          )}
        </td>
        <td>{!!locate.active_date && displayDate(locate.active_date)}</td>
        <td>
          <Button className={`${styles.number}`} variant="link" onClick={() => setEditLocate(locate)}>
            #{locate.number}
          </Button>
        </td>
        <td className={`${styles.descColumn}`}>{locate.description}</td>
        <td className="text-center">
          {!!locate.sb && <span className={`${styles.sbBadge} ${styles.badge}`}>SB</span>}

          {!!locate.ds && <span className={`${styles.dsBadge} ${styles.badge}`}>DS</span>}
        </td>
        <td>
          {!!locate.expiration && DateTime.fromISO(locate.expiration).diffNow().as("days") < 3 && (
            <AlertTriangle size={14} color="#ffa400" />
          )}
          {!!locate.expiration && displayDate(locate.expiration)}
        </td>
        <td
          className={`text-capitalize ${
            !!locate.status && locate.status.toLowerCase() === "requested" ? "font-italic" : ""
          }`}
        >
          {locate.status}
        </td>
        <td>
          <Relocate locate={locate} setLocates={setLocates} />
        </td>
      </tr>
      {showHistory && locate.relocate.map((relocate) => <PreviousLocate locate={relocate} key={relocate.id} />)}
    </>
  );
};

LocatesListItem.propType = {
  locate: PropTypes.object.isRequired,
  setEditLocate: PropTypes.func.isRequired,
};

export default LocatesListItem;
