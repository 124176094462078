/**
 * Compares two dates and determines their order chronologically
 * @param  {String} date1 first date to compare
 * @param  {String} date2 second date to compare
 * @return {Number} Numeric value indicating whether or not date 1 comes before, after, or is equal to date 2
 */

const timeRegex = /^(\d{1,2}):(\d{2})\s(AM|PM)$/i;

const getTimeFromTimeString = (timeString) => {
  const match = timeString.match(timeRegex);
  if (!match) {
    return null;
  }
  let [, hours, minutes, period] = match;
  hours = (Number(hours) % 12) + (period.toLowerCase() === "pm" ? 12 : 0);
  return new Date().setHours(hours, minutes, 0, 0);
};

const dateTimeComparator = (date1, date2) => {
  let date1Number = date1 && (new Date(date1).getTime() || getTimeFromTimeString(date1));
  let date2Number = date2 && (new Date(date2).getTime() || getTimeFromTimeString(date2));
  if (date1Number === null && date2Number === null) {
    return 0;
  }

  if (date1Number === null) {
    return -1;
  } else if (date2Number === null) {
    return 1;
  }

  return date1Number - date2Number;
};

export default dateTimeComparator;
