import React, { useState, useEffect } from "react";
import { Briefcase } from "react-feather";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import useProject from "../../../hooks/useProject";
import ControlBar from "../../../../_resourceManagement/FieldLogs/ControlBar/ControlBar";
import Listing from "../../../../_resourceManagement/FieldLogs/Listing";
import useFieldLogs from "../../../../_resourceManagement/FieldLogs/hooks/useFieldLogs";
import ReportingConsole from "../../../../_resourceManagement/ReportingConsole";
import useFieldLogSelection from "../../../../_resourceManagement/context/useFieldlogSelection";
import usePassedComponent from "../../../../hooks/usePassedComponent";
import HeaderSecondary from "./HeaderSecondary";
import { useAuth } from "component-library";
import Submenu from "./Submenu";
import Pagination from "../../../../components/Pagination";
import useInitialLoader from "../../hooks/useInitialLoader";
import TwoWeekMessage from "./TwoWeekMessage";

const FieldLogs = () => {
  const { userCan } = useAuth();
  const [page, setPage] = useState(1);
  const [initialLoad, setInitialLoad] = useState(true);

  const { project, pageTitle, loading: projectLoading, fieldMgmtDateRange, setFieldMgmtDateRange } = useProject();
  const { fieldLogs, setFieldLogs, getFieldLogs, loading, error, consoleData, total } = useFieldLogs();
  const { FieldLogSelection, selected, setFieldLogs: setSelectFieldLogs } = useFieldLogSelection();

  useEffect(() => {
    setSelectFieldLogs(fieldLogs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldLogs]);

  useInitialLoader(initialLoad, setInitialLoad);

  const updateExportedStatus = () => {
    setFieldLogs(
      fieldLogs.map((fl) =>
        selected.includes(fl.id) ? { ...fl, work_day_status_id: 1, work_day_status_name: "Exported" } : fl
      )
    );
  };

  const { BuiltComponent: RemindAndExport } = usePassedComponent(HeaderSecondary, [selected], {
    selected,
    updateExportedStatus,
    isProject: true,
  });

  return (
    <InteriorPage
      error={error}
      loading={projectLoading}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle="Field Management"
      pageTitleSecondaryContent={RemindAndExport}
      pageSubtitlePrimaryContent={() => <Submenu />}
      pageTitlePrimaryContent={!!userCan("payroll-review-time") && FieldLogSelection}
    >
      <TwoWeekMessage dateRange={fieldMgmtDateRange} />
      <ControlBar
        getFieldLogs={getFieldLogs}
        setPage={setPage}
        page={page}
        loading={loading}
        initialLoad={initialLoad}
        dateRange={fieldMgmtDateRange}
        setDateRange={setFieldMgmtDateRange}
      />
      <ReportingConsole
        isProject
        projectId={project?.id}
        isFieldlog
        error={error}
        loading={loading}
        consoleData={consoleData}
      />
      <div className="position-relative">
        <Listing fieldLogs={fieldLogs} loading={loading} initialLoad={initialLoad} />
        <Pagination currentPage={page} setCurrentPage={setPage} items={fieldLogs} itemsPerPage={25} total={total} />
      </div>
    </InteriorPage>
  );
};

export default FieldLogs;
