import React from "react";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import useProject from "../../hooks/useProject";
import { Calendar } from "react-feather";

import useSchedule from "./useSchedule";
import GanttChart from "./GanttChart";

const GanttView = () => {
  const { pageTitle, PageTag } = useProject();
  const { data, loading, error } = useSchedule();

  return (
    <InteriorPage
      loading={loading}
      error={error}
      navTitle={pageTitle}
      navIcon={<Calendar color="#fff" size={14} />}
      pageTitle="Schedule"
      fillScreen
      NavContent={PageTag}
    >
      {(showMenu) => (
        <GanttChart showMenu={showMenu} data={data} />
      )}
    </InteriorPage>
  );
};

export default GanttView;
