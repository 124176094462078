import {
  leave_time,
  name,
  bid_time,
  bid_date,
  pre_bid_date,
  addendas,
  plans_location,
  color,
  rfq,
  early_start_date,
  late_start_date,
  completion_date,
  estimated_contract_amount,
  estimator_id,
  contract_owner
} from "./columnDefs";

const columnDefs = [
  color,
  bid_date,
  bid_time,
  contract_owner,
  name,
  estimator_id,
  pre_bid_date,
  leave_time,
  plans_location,
  addendas,
  rfq,
  estimated_contract_amount,
  early_start_date,
  late_start_date,
  completion_date,

];

export default columnDefs;