import React from "react";
import styles from "./styles.module.scss";
import { Maximize, ArrowRightCircle } from "react-feather";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Map = ({project}) => {
  return (
    <Link to={`/project/${project.id}/sitemanagement/`} className={styles.mapWrapper}>
      {!!project && !!project.thumbnail ? (
        <div>
          <img src={project.thumbnail} className={styles.thumbnail} alt="project geofences display" />
          <div className={styles.iconWrapper}>
            <div className={styles.setupLink}>
              <Maximize className={styles.maxIcon} size={30} />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.noMap}>
          <div>
            <h2 className={styles.mapTitle}>Project Map Has Not Been Configured.</h2>
            <div className={styles.setupLink}>
              Set Up Now
              <ArrowRightCircle className="ms-2" size={20} name="arrow-right-circle" />
            </div>
          </div>
        </div>
      )}
    </Link>
  );
}

Map.propTypes = {
  project: PropTypes.object.isRequired,
};

export default Map;
