import { useState, useEffect } from "react";
import { useApi, bidApi } from "component-library";
import useProject from "../../../hooks/useProject";

const useProjectTeam = () => {
  const {
    project,
    setProject,
  } = useProject();
  const [contacts, setContacts] = useState();
  const [showNotice, setShowNotice] = useState(false);

  const [editContactOpen, setEditContactOpen] = useState(false);
  const [editingContact, setEditingContact] = useState();
  const [editRoleOpen, setEditRoleOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [updatedContact, setUpdatedContact] = useState();

  const {
    request: removeRequest,
    error: removeError,
    loading: removeLoading,
    data: removeData
  } = useApi(bidApi.completeBid);

  const removeContact = (contact) => {
    const contactsRemoved = {
      ...project,
      [contact.category]: project[contact.category].filter((id) => id !== contact.id),
    };
    delete contactsRemoved.dot_categories;
    removeRequest(contactsRemoved);
  };

  useEffect(() => {
    if (!!removeData) {
      setProject(removeData.project);
      setConfirmModalOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeData]);

  useEffect(() => {
    if (!!project) {
      const combinedContacts = [];
      if (!!project.project_managers && !!project.project_managers.length)
        combinedContacts.push(...project.project_managers.map((c) => {
          return {
            ...c.user,
            cardTitle: "Project Manager",
            category: "project_manager_ids",
            role: "project_managers",
          }
        }));
      if (!!project.project_coordinators && !!project.project_coordinators.length)
        combinedContacts.push(...project.project_coordinators.map((c) => {
          return {
            ...c.user,
            cardTitle: "Project Coordinator",
            category: "project_coordinator_ids",
            role: "project_coordinators",
          }
        }));
      if (!!project.project_superintendents && !!project.project_superintendents.length)
        combinedContacts.push(...project.project_superintendents.map((c) => {
          return {
            ...c.user,
            cardTitle: "Superintendent",
            category: "project_superintendent_ids",
            role: "project_superintendents",
          }
        }));
      if (!!project.project_foremen && !!project.project_foremen.length)
        combinedContacts.push(...project.project_foremen.map((c) => {
          return {
            ...c.user,
            cardTitle: "Foreman",
            category: "project_foremen_ids",
            role: "project_foremen",
          }
        }));

      !!combinedContacts.length ? setShowNotice(false) : setShowNotice(true);
      setContacts(combinedContacts);
    }
  }, [project]);

  useEffect(() => {
    if (!!updatedContact) {
      setProject({
        ...project,
        [editingContact.role]: project[editingContact.role].map((contact) => (
          contact.id === updatedContact.id ? updatedContact : contact
        ))
      });
      setUpdatedContact();
      setEditingContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedContact]);

  return {
    contacts,
    editContactOpen,
    setEditContactOpen,
    editingContact,
    setEditingContact,
    editRoleOpen,
    setEditRoleOpen,
    confirmModalOpen,
    setConfirmModalOpen,
    showNotice,
    removeContact,
    removeError,
    removeLoading,
    setUpdatedContact
  };
};

export default useProjectTeam;
