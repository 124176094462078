import React, { useEffect, useState } from "react";
import ProgressTracker from "../../../components/ProgressTracker";
import { useApi, projectApi } from "component-library";
import useProject from "../../hooks/useProject";
import Checklist from "./Checklist";
import ProjectLauncher from "./ProjectLauncher";
import { RiseLoader } from 'react-spinners';
import { Alert } from "react-bootstrap";
import styles from "./PreconChecklist.module.scss";
import Sidebar from "../Sidebar";

const PreconChecklist = () => {
  const { project, setProject } = useProject();
  const [completedCount, setCompletedCount] = useState(0);
  const [alert, setAlert] = useState("");

  const { data: roleOptions, request: requestRoleOptions } = useApi(
    projectApi.getProjectRoleOptions
  );

  const { request: todosRequest, error: todosError, loading: todosLoading } = useApi(
    projectApi.updatePreconTodos
  );

  const updateItems = async (items) => {
    const projectId = project.id;
    const result = await todosRequest(projectId, items);

    if (!!result.success) {
      setProject({ ...project, precon_todos: items });
    }
  };

  useEffect(() => {
    requestRoleOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (project && project.precon_todos && !!project.precon_todos.length) {
      setCompletedCount(
        project.precon_todos.filter((i) => i.complete === 1).length
      );
    }
  }, [project]);

  return (
    <div className={styles.inlineRow}>
      <div className="flex-1 w-100 position-relative px-4">
        {!!alert && !!alert.length && (
          <Alert
            onClick={() => setAlert("")}
            dismissible
            variant="success">{alert}</Alert>
        )}
        <h2 className={`${styles.preconTitle} xxxl mt-3 mb-4 text-gray2`}>👋 Welcome to your New Project</h2>
        {project.precon_todos && project.precon_todos.length && (
          <>
            <ProjectLauncher skip={completedCount !== project.precon_todos.length} />
            <ProgressTracker
              completed={completedCount}
              total={project.precon_todos && project.precon_todos.length}
            />
            <div className="position-relative">
            {
              !!todosLoading && (
                <div className={styles.loaderWrapper}>
                  <RiseLoader color={"#0071ce"} className={styles.loader} />
                </div>
              )
            }
            {!!todosError && (
              <Alert variant="danger">{todosError}</Alert>
            )}
            <Checklist
              items={project.precon_todos}
              updateItems={updateItems}
              roleOptions={roleOptions}
              setAlert={setAlert}
            />
            </div>
          </>
        )}
      </div>
      <Sidebar
        roleOptions={roleOptions}
      />
    </div>
  );
};

export default PreconChecklist;
