import React, { useState, useEffect } from "react";
import { useFormControl, handleError, useApi, projectApi } from "component-library";
import { useParams, useNavigate } from "react-router-dom";
import { Folder } from "react-feather";
import styles from "./styles.module.scss";
import { schema, initialData } from "./renameFolder";
import useProjectDocuments from "../../context/useProjectDocuments";

const useRenameFolder = ({ setShowModal }) => {
  const history = useNavigate();
  const { id, folderName } = useParams();
  const { displayMessage, attachmentsRequest } = useProjectDocuments();
  const [newFolderName, setNewFolderName] = useState();
  const {
    request: renameFolderRequest,
    data: renameFolderData,
    error: renameFolderError,
    loading: renameFolderLoading,
  } = useApi(projectApi.updateAttachmentFolderInfo);

  const onSubmit = async (formData, formLoading) => {
    formLoading(true);
    setNewFolderName(formData?.folderName);
    try {
      await renameFolderRequest({
        id: id,
        new_folder: formData.folderName,
        existing_folder: folderName,
      });
    } catch (error) {
      handleError(error);
    }
    formLoading(false);
  };

  const { data, handleSubmit, renderSubmitButton, renderTextField } = useFormControl({
    onSubmit,
    schema,
    initialData,
  });

  useEffect(() => {
    if (!!renameFolderData?.success) {
      displayMessage({
        title: "Folder Renamed",
        content: `Folder name changed from ${folderName} to ${newFolderName}`,
        id: "folder-renamed",
        variant: "success",
      });
      attachmentsRequest(id);
      setNewFolderName();
      setShowModal(false);
      history(`/project/${id}/documents/folder/${newFolderName}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renameFolderData]);

  const fields = (
    <div>
      <div className="d-flex mb-5">
        <div className={styles.currentTitleWrapper}>
          <Folder color="#0071ce" size={16} /> <span className={styles.currentTitle}>{folderName}</span>
        </div>
      </div>
      <div>
        {renderTextField({
          name: "folderName",
          label: "New Name",
          subLabel: "Enter your New Folder Name here",
          autoFocus: true,
        })}
      </div>
    </div>
  );

  return {
    fields,
    handleSubmit,
    renderSubmitButton,
    renameFolderError,
    renameFolderLoading,
    data,
  };
};

export default useRenameFolder;
