import React, { useState, useEffect } from "react";
import { InlineUpload, useFileUpload, fieldLogsApi, useAuth } from "component-library";
import { Alert } from "react-bootstrap";

const PhotoList = ({ addFile, commentId, projectId }) => {
  const [loading, setLoading] = useState();
  const { user } = useAuth();

  const getAddObject = (objectId, e, file, fileType, unused, commentId) => {
    return {
      upb_comment_id: commentId,
      file: {
        ...file,
        size: file.size,
        data: e.target.result,
        name: file.name,
        file_type: [18],
        type: fileType,
      },
      project_id: objectId,
    };
  };

  const { uploadFile, updatedData, error } = useFileUpload({
    attachmentApi: fieldLogsApi,
    getAddObject,
    objectId: projectId,
    todoId: commentId,
    setLoading,
    maxSize: 5000 * 1024,
    userId: user.id,
  });

  useEffect(() => {
    if (!!updatedData) {
      addFile(updatedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedData]);

  return (
    <div className="mb-5">
      {!!error && <Alert variant="danger">{error}</Alert>}
      <InlineUpload uploadText="Add New Image" addAttachment={uploadFile} className="py-2" loading={loading} />
    </div>
  );
};

export default PhotoList;
