import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import Note from "../../../../../../components/FieldNoteAccordion/Note";
import { Loader } from "component-library";
import { Alert } from "react-bootstrap";

const NotesModal = ({ openNotes, setOpenNotes, setModalOpen, loading, error }) => {

  return (
    <Modal
      animation={false}
      show={!!openNotes}
      onHide={() => {
        setOpenNotes();
        if (setModalOpen) {
          setModalOpen(false);
        }
      }}
      dialogClassName="modal-lg"
      className="text-gray3"
      centered
    >
      <div className="position-relative">
        <Loader loading={!!loading} size={14} />
        <Modal.Header closeButton className={styles.modalHeader}>
          <div className={`${styles.taskName} xl`}>Task Notes</div>
        </Modal.Header>
        <Modal.Body>
          {!!error && (
            <Alert variant="danger">{error}</Alert>
          )}
          {!!openNotes?.length ? (
            openNotes.map((note, index) => (
              <div key={index}>
                <Note
                  project={{ id: note?.project_id }}
                  note={note}
                />
              </div>
            ))
          ) : (
            <div>No Task Notes Found</div>
          )}
        </Modal.Body>
      </div>
    </Modal>
  );
};

NotesModal.propTypes = {
  openNotes: PropTypes.any,
  setOpenNotes: PropTypes.func,
};

export default NotesModal;
