import { useEffect, useState } from "react";
import { useApi, chartsApi } from "component-library";

const useProvideDashboard = () => {
  const [loading, setLoading] = useState(true);
  const { data, loading: chartsLoading, error, request } = useApi(chartsApi.getCharts);
  const [jobs, setJobs] = useState([]);
  const [idLookup, setIdLookup] = useState({});
  const [aggregateStats, setAggregateStats] = useState({});
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!chartsLoading && (data || error)) {
      setLoading(false);
    }
  }, [chartsLoading, data, error]);

  useEffect(() => {
    if (!!data) {
      const {
        jobs: jobsData,
        man_hours: mhData,
        per_man_hour: pmh,
        spend: s,
        project_status: ps,
        invoices: i,
        alerts: alertsData,
        cashflow: cf,
      } = data;
      setJobs(jobsData);
      if (alerts) setAlerts(alertsData);
      setAggregateStats({
        invoices: i,
        man_hours: mhData,
        per_man_hour: pmh,
        spend: s,
        project_status: ps,
        cashflow: cf,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setIdLookup(jobs?.reduce((a, c) => (!!c.project_id ? { ...a, [c.job_id]: c.project_id } : a), {}));
  }, [jobs]);

  return {
    jobs,
    aggregateStats,
    alerts,
    loading,
    error,
    idLookup
  };
};

export default useProvideDashboard;
