import React from "react";
import { Quote as Q, useAuth } from "component-library";
import QuoteControls from "./QuoteControls";
import BackToQuotesList from "./BackToQuotesList";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";

const QuoteDetail = ({
  quote,
  setQuote,
  setLoading,
  addPOData,
  requote,
  cancelRequote,
  addPurchaseOrder,
  backLink,
  noControls,
}) => {
  const { user } = useAuth();
  return (
    <>
      <div className="q-nav">
        <BackToQuotesList backLink={backLink} />
        {!quote?.supplier && <Alert variant="danger">Supplier required before creating Purchase Orders</Alert>}
        {!noControls && (
          <QuoteControls
            quote={quote}
            setLoading={setLoading}
            requote={requote}
            cancelRequote={cancelRequote}
            addPurchaseOrder={addPurchaseOrder}
            addPOData={addPOData}
          />
        )}
      </div>
      <div className="q-secondary">
        <Q.MetaData quote={quote} />
      </div>
      {!!quote && (
        <>
          <div className="q-contactSection">
            {!!quote.supplier && <Q.Contacts quote={quote} />}
            <div className="q-fileSection">
              <Q.File quote={quote} setQuote={setQuote} user={user} />
            </div>
          </div>
          <Q.Terms quote={quote} />
          <div className="q-section">
            <Q.LineItems quote={quote} />
          </div>
          <Q.Total total={quote.amount} />
          <Q.History history={quote.history} />
        </>
      )}
    </>
  );
};

QuoteDetail.propTypes = {
  quote: PropTypes.object,
  setLoading: PropTypes.func,
  addPOData: PropTypes.object,
  requote: PropTypes.func,
  cancelRequote: PropTypes.func,
};

export default QuoteDetail;
