import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useApi, dropdownStylesWithWidth, fieldLogsApi } from "component-library";

const StatusSelector = ({ selected = [], setSelected, loading }) => {
  const [opts, setOpts] = useState([]);
  const { data, request } = useApi(fieldLogsApi.getConfig);

  useEffect(() => {
    request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!data && !!data.config) {
      setOpts(data.config.map(c => ({ value: c.id, label: c.status })));
    }
  }, [data]);

  return (
    <div className="me-2">
      <Select
        isMulti
        name="statuses"
        isSearchable={false}
        closeMenuOnSelect={false}
        placeholder={!!selected.length ? `Filter by Status (${selected.length})` : "Filter by Status"}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        onChange={(sel) => setSelected(sel.map((s) => s.value))}
        isClearable={false}
        styles={dropdownStylesWithWidth(215)}
        options={opts}
        value={opts.filter((s) => selected.indexOf(s.value) > -1)}
        aria-label="filter by status"
        isOptionDisabled={() => !!loading}
      />
    </div>
  );
};

export default StatusSelector;
