// import { useNavigate } from "react-router-dom";
import nestedDetailCellConfig from "./nestedDetailCellConfig";

import { hours, last_name, id, time_in, time_out, perdiem, expand, lunch, unallocated, note_count } from "./columnDefs";

const columnDefs = [id, last_name, perdiem, time_in, time_out, lunch, unallocated, hours, note_count, expand];

const useCellConfig = () => {

  const detailGridOptions = {
    rowSelection: "multiple",
    pagination: false,
    columnDefs: columnDefs,
    rowHeight: 42,
    defaultColDef: {
      sortable: true,
    },
    rowClass: "detailGroupRow",
    masterDetail: true,
    isRowMaster: (dataItem) => (dataItem?.tasks?.length ? true : false),
    detailRowAutoHeight: true,
    detailCellRendererParams: {
      detailGridOptions: nestedDetailCellConfig,
      getDetailRowData: (params) => {
        params.successCallback(params.data.tasks);
      },
    },
  };

  return detailGridOptions;
};

export default useCellConfig;
