import React from "react";
import { NavLink } from "react-router-dom";

const TruckingMenu = () => {
  return (
    <nav className="headerTabs">
      <NavLink className="headerTab" to={`/fleet-management/trucking/schedule`}>
        Schedule
      </NavLink>
      <NavLink className="headerTab" to={`/fleet-management/trucking/activity`}>
        Activity
      </NavLink>
      <NavLink className="headerTab" to={`/fleet-management/trucking/timekeeping`}>
        Timekeeping
      </NavLink>
    </nav>
  );
};

export default TruckingMenu;
