import { useState, useEffect } from "react";
import { useApi, taskDetailApi } from "component-library";
import { useParams } from "react-router-dom";

const useTask = () => {
  const { taskId } = useParams();
  const [fieldlogs, setFieldlogs] = useState();
  const [error, setError] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState();

  const {
    request: fieldlogRequest,
    data: fieldlogData,
    loading: fieldlogLoading,
    error: fieldlogError,
  } = useApi(taskDetailApi.getTaskFieldlogs);

  useEffect(() => {
    if (taskId) {
      fieldlogRequest(taskId, currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, taskId]);

  useEffect(() => {
    if (fieldlogData?.task?.logs) {
      setFieldlogs(fieldlogData.task.logs);
      setTotal(fieldlogData.task.total);
    }
  }, [fieldlogData]);

  useEffect(() => {
    const apiError = fieldlogError;
    if (apiError) {
      setError(apiError);
    }
  }, [fieldlogError]);

  return {
    fieldlogs,
    fieldlogLoading,
    error,
    currentPage,
    setCurrentPage,
    total,
  };
};

export default useTask;
