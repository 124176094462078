import React from "react";
import { Calendar } from "react-feather";
import { ReactComponent as CalendarSelect } from "../../../../assets/calendar-select.svg";
import PropTypes from "prop-types";
import styles from "../styles.module.scss";

const DateCard = ({ date, title, grid = false }) => {
  return (
    <div className={`${styles.dateCard}`}>
      {grid ? (
        <CalendarSelect width={16} height={16} color="#0071CE" />
      ) : (
        <Calendar size={16} color={"#0071CE"} />
      )}
      <div className={`${styles.date}`}>{date}</div>
      <div className={`${styles.title}`}>{title}</div>
    </div>
  );
};

DateCard.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  grid: PropTypes.bool,
};

export default DateCard;
