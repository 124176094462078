import React, { useState } from "react";
import PhotosModal from "../../../../components/PhotosModal";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";

const Photos = ({ photos }) => {
  const [open, setOpen] = useState(false);
  const [initialIndex, setInitialIndex] = useState(0);

  const photoClicked = (index) => {
    setInitialIndex(index);
    setOpen(true);
  };

  return (
    <>
      <PhotosModal photos={photos} setOpen={setOpen} open={open} initalIndex={initialIndex} />
      <div className={styles.photos}>
        {photos?.map((photo, index) => (
          <Button className="p-0" variant="link" onClick={() => photoClicked(index)}>
            <img src={photo?.file?.url} alt={photo?.file?.file_name} loading="lazy" className={styles.photo} />
          </Button>
        ))}
      </div>
    </>
  );
};

export default Photos;
