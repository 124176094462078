import React from "react";
import styles from "./styles.module.scss";

const Project = ({ data }) => {
  return (
    <span className={styles.text}>
      {data?.project?.job_number} - {data?.project?.name}
    </span>
  );
};

export default Project;
