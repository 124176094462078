import React from "react";
import styles from "./styles.module.scss";
import getCategoryColor from "../../getCategoryColor";

const Category = ({ data }) => {
  return (
    <>
      {!!data?.category && (
        <div className={styles.projectCategory} style={{ backgroundColor: getCategoryColor(data?.category) }}>
          {data?.category}
        </div>
      )}
    </>
  );
};

export default Category;
