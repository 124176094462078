import React, { useEffect, useState } from "react";
import Select from "react-select";
import useProject from "../../hooks/useProject";
import { useApi, bidApi } from "component-library";
import styles from "./PreconChecklist.module.scss";
import { BeatLoader } from "react-spinners";
import PropTypes from "prop-types";

const SelectorTodo = ({ todo, roleOptions }) => {
  const { project, setProject } = useProject();
  const [keys, setKeys] = useState();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const { request, loading, error, data } = useApi(bidApi.completeBid);

  useEffect(() => {
    if (!!todo) {
      let todoKeys;
      switch (todo.key) {
        case "superintendant":
          todoKeys = {
            role: "superintendents",
            selected: "project_superintendents",
            val: "project_superintendent_ids",
          };
          break;
        case "project_manager":
          todoKeys = {
            role: "project-managers",
            selected: "project_managers",
            val: "project_manager_ids",
          };
          break;
        case "project_coordinator":
          todoKeys = {
            role: "project-coordinators",
            selected: "project_coordinators",
            val: "project_coordinator_ids",
          };
          break;
        case "foremen":
          todoKeys = {
            role: "foremen",
            selected: "project_foremen",
            val: "project_foremen_ids",
          };
          break;
        default:
          break;
      }
      setKeys(todoKeys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todo]);

  useEffect(() => {
    if (roleOptions && roleOptions.users && keys.role) {
      setOptions(
        roleOptions.users[keys.role].map((user) => ({
          value: user.id,
          label: `${user?.first_name} ${user?.last_name}`,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleOptions, keys]);

  useEffect(() => {
    if (error) {
      setProject(project);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (data && data.project) {
      setProject({
        ...project,
        [keys.val]: data.project[keys.val],
        [keys.selected]: data.project[keys.selected],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSelectChange = (values) => {
    const selectedIds = values.map((v) => v.value);

    if (values) {
      request({ id: project.id, [keys.val]: selectedIds });
    }
  };

  useEffect(() => {
    if (!!keys && !!project && !!project[keys.val] && !!project[keys.val].length) {
      setSelected(project[keys.selected].map((opt) => {
        return {
          value: opt?.user?.id,
          label: `${opt?.user?.first_name?.charAt(0)}. ${opt?.user?.last_name}`,
        };
        }
      ));
    } else {
      setSelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todo, project, roleOptions]);

  return (
    <div className="position-relative">
      {!!error && <span className="error">Something went wrong. Please try again.</span>}
      {!!loading && (
        <div className={styles.loaderWrapper}>
          <BeatLoader color={"#0071ce"} size={10} />
        </div>
      )}
      <Select
        menuPlacement="auto"
        isMulti={true}
        options={options}
        name={todo.key}
        id={todo.key}
        onChange={(value) => handleSelectChange(value)}
        value={selected}
        className={`${styles.teamSelect} team-select`}
        classNamePrefix="todo-select"
      />
    </div>
  );
};

SelectorTodo.propTypes = {
  todo: PropTypes.object.isRequired,
  roleOptions: PropTypes.object,
};

export default SelectorTodo;
