import { useState, useEffect } from "react";
import { useApi, fieldLogsApi } from "component-library";
import useResourceManagement from "../../context/useResourceManagement";
import { DateTime } from "luxon";

const useProvideNotesAdmin = () => {
  const {
    dateRange,
    setDateRange,
    projectsList,
    calendarClosed,
    setCalendarClosed,
  } = useResourceManagement();
  const [selectedNotesToPrintIds, setSelectedNotesToPrintIds] = useState([]);
  const [notesToPrint, setNotesToPrint] = useState([]);
  const [notes, setNotes] = useState();
  const [selectedProject, setSelectedProject] = useState("");
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [q, setQ] = useState("");
  const [refreshColumns, setRefreshColumns] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewAddNoteModal, setViewAddNoteModal] = useState(false);
  const [totalResults, setTotalResults] = useState();
  const itemsPerPage = 50;
  const [refreshDetail, setRefreshDetail] = useState();
  const [updateDetailId, setUpdateDetailId] = useState();
  const [message, setMessage] = useState();

  const {
    request: notesRequest,
    data: notesData,
    loading: notesLoading,
    error: notesError,
  } = useApi(fieldLogsApi.getAllComments);

  const { request: usersRequest, data: usersData, error: usersError } = useApi(fieldLogsApi.getCrewMembers);

  const toggleNotePrintSelection = (noteId) => {
    if (selectedNotesToPrintIds.includes(noteId)) {
      setSelectedNotesToPrintIds(selectedNotesToPrintIds.filter((id) => id !== noteId));
      setNotesToPrint(notesToPrint.filter((note) => note.id !== noteId));
    } else {
      const newVendor = notes.find((note) => note.id === noteId);
      setSelectedNotesToPrintIds([...selectedNotesToPrintIds, noteId]);
      setNotesToPrint([...notesToPrint, newVendor]);
    }
  };

  const handleSearch = (query) => {
    setQ(query || "");
  };

  const getRequestParams = () => {
    let params = {
      q: q,
      project_id: selectedProject || null,
      user_id: selectedUser || null,
      page: currentPage,
      num_results: itemsPerPage,
    };

    if (!!dateRange[0] && !!dateRange[1]) {
      params = {
        ...params,
        from_date: DateTime.fromJSDate(dateRange[0]).toFormat("yyyy-MM-dd"),
        to_date: DateTime.fromJSDate(dateRange[1]).toFormat("yyyy-MM-dd"),
      };
    }

    return params;
  };

  useEffect(() => {
    //TODO: this is causing an error look into it
    usersRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!calendarClosed) {
      setCalendarClosed(false);
    }
    if (!notesLoading) {
      notesRequest(getRequestParams());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, calendarClosed, q, selectedUser, selectedProject]);

  useEffect(() => {
    if (!dateRange[0] || !!dateRange[1]) {
      notesRequest(getRequestParams());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    if (!!notesData?.notes?.notes?.length) {
      setNotes(
        notesData.notes.notes.map((note) => {
          return {
            ...note,
            note: [note],
          };
        })
      );
      setTotalResults(notesData?.notes?.count || notesData.notes.notes.length);
    } else {
      setNotes();
    }
  }, [notesData]);

  useEffect(() => {
    if (!!notes?.length) {
      setTimeout(() => {
        setExpandAll(true);
      }, 100);
    }
  }, [notes, currentPage]);

  useEffect(() => {
    if (!!usersData?.crew_members?.length) {
      setUserOptions(
        usersData.crew_members.map((user) => {
          return {
            value: user?.id,
            label: `${user?.first_name} ${user?.last_name}`,
          };
        })
      );
    }
  }, [usersData]);

  return {
    notes,
    setNotes,
    notesLoading,
    notesError,
    selectedNotesToPrintIds,
    setSelectedNotesToPrintIds,
    toggleNotePrintSelection,
    notesToPrint,
    setNotesToPrint,
    userOptions,
    selectedProject,
    setSelectedProject,
    selectedUser,
    setSelectedUser,
    handleSearch,
    dateRange,
    setDateRange,
    projectsList,
    refreshColumns,
    setRefreshColumns,
    expandAll,
    setExpandAll,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    viewAddNoteModal,
    setViewAddNoteModal,
    totalResults,
    refreshDetail,
    setRefreshDetail,
    updateDetailId,
    setUpdateDetailId,
    message,
    setMessage,
    usersError,
    getRequestParams,
    notesRequest,
  };
};

export default useProvideNotesAdmin;
