import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import FolderIcon from "../../../../../components/FolderIcon";

const Folder = ({ data }) => {
  const { id } = useParams();
  return (
    <>
      {!!data?.folder ? (
        <Link
          className={`${styles.link} text-limiter text-start p-0 med`}
          to={`/project/${id}/documents/folder/${data.folder}`}
        >
          <FolderIcon folder={data.folder} />
          {data.folder}
        </Link>
      ) : (
        "-"
      )}
    </>
  );
};

export default Folder;
