import Project from "../columnRenders/Project";

const project = {
  headerName: "Project",
  field: "project_name",
  flex: 1,
  cellRenderer: Project,
};

export default project;
