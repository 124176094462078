import React from "react";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import useProject from "../../../hooks/useProject";
import { Briefcase } from "react-feather";
import { useParams } from "react-router-dom";
import { AgGridTable, FilterSearch, useAuth } from "component-library";
import gridRowHeights from "../../../../utils/gridRowHeights";
import folderListConfig from "./folderListConfig";
import DeleteFolderModal from "./DeleteFolderModal/DeleteFolderModal";
import RenameFolderModal from "./RenameFolderModal/RenameFolderModal";
import AddDocument from "../AddDocument";
import useProjectFolderDetail from "./useProjectFolderDetail";
import useProjectDocuments from "../context/useProjectDocuments";

const ProjectFolderDetail = () => {
  const { userCan, user } = useAuth();
  const { attachmentsLoading, attachmentsError } = useProjectDocuments();
  const { id } = useParams();
  const { loading, error, pageTitle, PageTag } = useProject();
  const {
    showDeleteConfirmModal,
    setShowDeleteConfirmModal,
    showRenameModal,
    setShowRenameModal,
    addDocModalOpen,
    setAddDocModalOpen,
    PageTitleHeader,
    HeaderButtons,
    handleSearch,
    filteredAttachments,
  } = useProjectFolderDetail();

  return (
    <InteriorPage
      loading={loading || attachmentsLoading}
      error={error || attachmentsError}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle={"Documents"}
      pageSubtitlePrimaryContent={PageTitleHeader}
      pageTitleSecondaryContent={HeaderButtons}
      NavContent={PageTag}
    >
      <div className="d-flex align-items-center justify-content-end w-100 mb-4 mt-4">
        <FilterSearch inline searchFunction={handleSearch} placeholder="Search" />
      </div>
      <div className="documentTable">
        <AgGridTable
          gridData={filteredAttachments}
          gridConfig={folderListConfig}
          loading={loading}
          rowHeight={gridRowHeights.medium}
          id={`${user.id}_project_folder_listing`}
          presetStyle="sortableTable"
          fallbackMessage="No attachments found."
          autoHeight
        />
      </div>
      <DeleteFolderModal showModal={showDeleteConfirmModal} setShowModal={setShowDeleteConfirmModal} />
      <RenameFolderModal showModal={showRenameModal} setShowModal={setShowRenameModal} />
      {!!addDocModalOpen && (
        <AddDocument
          setModalOpen={setAddDocModalOpen}
          projectId={id}
          canUpload={userCan("bid-create") || userCan("bid-edit")}
        />
      )}
    </InteriorPage>
  );
};

export default ProjectFolderDetail;
