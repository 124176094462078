import { useEffect, useState } from 'react';

const useWindowSize = (breakpoint) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isLarge, setIsLarge] = useState(window.innerWidth >= breakpoint ? true : false);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      if (newWidth >= breakpoint) {
        setIsLarge(true);
      } else {
        setIsLarge(false);
      }
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return {
    width,
    isLarge,
  }

}

export default useWindowSize;