import { useState, useEffect } from "react";
import useProject from "../../../../hooks/useProject";
import useEstimateConversion from "../../useEstimateConversion";
import { useFormControl, useApi, projectApi, bidApi, dropdownStyles, Loader } from "component-library";
import { schema, initialData } from "../../../../../schema/task";
import styles from "./styles.module.scss";
import { Alert, Button } from "react-bootstrap";
import { useParams } from "react-router";

const useAddTaskForm = ({ setAddingTask, setMessage, bidItems }) => {
  const { id: projectId } = useParams();
  const [parentItemOptions, setParentItemOptions] = useState([]);
  const { upbRequest, itemsPerPage, currentPage, unitsRatesMeasure, unitsOfMeasure } = useEstimateConversion();
  const { project, refreshProject } = useProject();
  const [parentTaskOptions, setParentTaskOptions] = useState();

  const { request, data: updateData, loading, error } = useApi(projectApi.addTask);
  const { data: topLevelData, request: topLevelRequest, error: topLevelError } = useApi(bidApi.getTopLevelUPB);

  const onSubmit = async (formData, setLoading) => {
    setLoading(true);
    const defaultMeasure = unitsRatesMeasure.find((option) => option.label === "UM/Man Hour");

    try {
      await request({
        ...formData,
        entity_type: "task",
        project_id: project.id,
        rate_um: defaultMeasure.value,
        parent_id: formData?.parent_task_id || formData?.parent_bid_id || 0,
      });
      setLoading(false);
    } catch (message) {
      console.error(message);
      setLoading(false);
    }
  };

  const {
    handleSubmit,
    renderSubmitButton,
    renderTextField,
    renderSelectField,
    renderCheckbox,
    data: formData,
  } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  useEffect(() => {
    if (updateData) {
      setAddingTask(false);
      setMessage("New task added.");
      upbRequest({
        q: "",
        id: project.id,
        page: currentPage,
        items_per_page: itemsPerPage,
      });
      if (project.upb_import_status !== 1) {
        refreshProject();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    if (projectId) {
      topLevelRequest(projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (!!topLevelData?.items) {
      setParentItemOptions(
        topLevelData.items.map((option) => ({
          value: option.id,
          label: `${!!option?.cost_code ? `${option?.cost_code} - ` : ""}${option.name}`,
        }))
      );
    }
  }, [topLevelData]);

  useEffect(() => {
    if (formData?.parent_bid_id) {
      const selectedBidItem = topLevelData.items.find((bidItem) => bidItem.id === formData.parent_bid_id);
      let taskOptions = [];
      if (!!selectedBidItem?.sub_items?.length) {
        taskOptions = selectedBidItem.sub_items;
        taskOptions = taskOptions.map((opt) => {
          return {
            label: `${!!opt?.cost_code ? `${opt?.cost_code} - ` : ""}${opt.name}`,
            value: opt.id,
          };
        });
      }
      setParentTaskOptions(taskOptions);
    } else {
      setParentTaskOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const getProdRate = () => {
    const rate = (parseFloat(formData?.bid_quantity) / parseFloat(formData?.estimated_hours)).toFixed(2);
    const unitMeasure = unitsOfMeasure
      ?.find((opt) => parseInt(opt.value) === parseInt(formData?.unit_measure))
      ?.label.toLowerCase();

    return `${rate} ${unitMeasure} / hr`;
  };

  const fields = (
    <>
      <section className={styles.contentWrapper}>
        <Loader loading={loading} />
        {(!!error || !!topLevelError) && <Alert variant="danger">{error || topLevelError}</Alert>}
        <div className={`${styles.formRow} row`}>
          <div className="col-md-8">
            {renderTextField({
              name: "name",
              label: "Task Name",
              autoFocus: true,
              placeholder: "Task Name",
            })}
          </div>
          <div className="col-md-4">
            {renderTextField({
              name: "cost_code",
              label: "Cost Code",
              placeholder: "Cost Code",
            })}
          </div>
        </div>
        <div className={`${styles.formRowHalf} row`}>
          <div className="col-md-3">
            {renderTextField({
              name: "bid_quantity",
              label: "Bid Quantity",
              placeholder: "Bid Quantity",
            })}
          </div>
          <div className="col-md-3">
            {renderSelectField({
              name: "unit_measure",
              label: "Unit",
              options: unitsOfMeasure,
              styles: dropdownStyles,
            })}
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <div className={styles.prodRatePreview}>
              <h3>Production Rate</h3>
              <span>
                {!!formData?.estimated_hours && !!formData?.bid_quantity && !!formData?.unit_measure
                  ? getProdRate()
                  : "n/a"}
              </span>
            </div>
          </div>
        </div>
        <div className={`${styles.formRow} row`}>
          <div className="col-md-3">
            {renderTextField({
              name: "estimated_hours",
              label: "Estimated Hours",
              placeholder: "Qty",
            })}
          </div>
          <div className="col-md-4"></div>
        </div>
        <div className="row mb-2">
          {!!parentItemOptions?.length && (
            <div className="col-md-7">
              {renderSelectField({
                name: "parent_bid_id",
                label: "Parent Bid Item",
                options: parentItemOptions,
                styles: dropdownStyles,
              })}
            </div>
          )}
        </div>
        <div className={`${styles.formRowHalf} row`}>
          {!!parentTaskOptions?.length && (
            <div className="row">
              <div className="col-md-7">
                {renderSelectField({
                  name: "parent_task_id",
                  label: "Parent Task",
                  options: parentTaskOptions,
                  styles: dropdownStyles,
                })}
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-6">
            {renderCheckbox({
              name: "remove_from_app",
              label: "Hide Task",
            })}
          </div>
        </div>
      </section>
      <footer className={styles.footer}>
        <Button className={`med me-3 text-white font-weight-bold`} variant="gray5" onClick={() => setAddingTask(false)}>
          Cancel
        </Button>
        {renderSubmitButton("Add Task", !!loading, styles.submitButton)}
      </footer>
    </>
  );

  return {
    handleSubmit,
    parentItemOptions,
    unitsOfMeasure,
    loading,
    error,
    topLevelError,
    renderSubmitButton,
    renderTextField,
    renderSelectField,
    unitsRatesMeasure,
    formData,
    parentTaskOptions,
    renderCheckbox,
    fields,
  };
};

export default useAddTaskForm;
