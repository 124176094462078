import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { currency } from "component-library";
import { AlertTriangle, Trash2 } from "react-feather";
import { Card } from "react-bootstrap";
import { BeatLoader } from 'react-spinners';
import useLineItemUpdate from "./useLineItemUpdate";
import LineItemInput from "./LineItemInput";

const LineItem = ({ item, quoteItem, quote, setQuote, setLineError }) => {
  const [lineItem, setLineItem] = useState(item);
  const {
    updateLineItemLoading,
    handleUpdate,
    deleteLineItems,
    partNumber,
    setPartNumber,
    quantity,
    setQuantity,
    unitPrice,
    setUnitPrice,
  } = useLineItemUpdate(quote, setQuote, item, setLineError);

  useEffect(() => {
    if (!!item) {
      setLineItem(item);
    }
  }, [item]);

  return (
    <>
      {quote.status !== 2 && (
        <td>
          <LineItemInput
            item={item}
            field={"supplier_part_number"}
            value={partNumber}
            originalValue={lineItem.supplier_part_number}
            setValue={setPartNumber}
            handleUpdate={handleUpdate}
          />
        </td>
      )}
      <td>{!!lineItem.material && lineItem.material.name}</td>
      <td className="d-flex number justify-content-end overflow-visible">
        <>
          {!!updateLineItemLoading && (
            <div className="loaderWrapper">
              <BeatLoader size="1rem" color={"#0071ce"} />
            </div>
          )}
          {!!quote && quote.status === 2 && (
            <>
              {!!quoteItem && quoteItem.rfq_quantity !== lineItem.rfq_quantity && (
                <div className="position-relative">
                  <AlertTriangle className="me-2 qty-alert" color="#ff6700" size="20" />
                  <Card className="menu-content qty-alert-message">
                    <div className="text-gray3 sm-med text-center">
                      The QTY for this line item does not match the associated takeoff QTY
                      <div className="sm-med d-lg-flex justify-content-between font-weight-bold  text-gray2 mt-3">
                        <div>
                          Takeoff: {quoteItem.rfq_quantity} {lineItem.rfq_quantity_uom}
                        </div>
                        <div>
                          Requote: {lineItem.rfq_quantity} {lineItem.rfq_quantity_uom}
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              )}
            </>
          )}
          <LineItemInput
            field={"rfq_quantity"}
            value={quantity}
            originalValue={lineItem.rfq_quantity}
            setValue={setQuantity}
            handleUpdate={handleUpdate}
          />
        </>
      </td>
      <td className="text-gray3">{lineItem.rfq_quantity_uom}</td>
      {quote.status !== 2 ? (
        <>
          <td>
            <LineItemInput
              field={"base_price"}
              value={unitPrice}
              originalValue={lineItem.base_price}
              setValue={setUnitPrice}
              handleUpdate={handleUpdate}
            />
          </td>
          <td className="text-end">{currency(lineItem.ext_price)}</td>
        </>
      ) : (
        <>
          <td className="unit">{lineItem.estimated_delivery}</td>
          <td className="text-end">{currency(lineItem.ext_price)}</td>
        </>
      )}
      {quote.status !== 2 && (
        <td className="text-end">
          <div
            onClick={() => {
              deleteLineItems([lineItem.id]);
            }}
            className="pe-2"
          >
            <Trash2 size="16" color="#797c84" />
          </div>
        </td>
      )}
    </>
  );
}

LineItem.propTypes = {
  quote: PropTypes.object,
  item: PropTypes.object,
  quoteItem: PropTypes.object,
  setQuote: PropTypes.func,
  setLineError: PropTypes.func,
};

export default LineItem;