import React from "react";
import styles from "./styles.module.scss";
import { useAuth } from "component-library";

const MapSettingsCheck = ({ children }) => {
  const { config, userCan } = useAuth();
  const googleMapsApiKey = config.tenant?.find((tenConfig) => tenConfig.key === "TenantConfig::google_api_key").value;

  return (
    <>
      {googleMapsApiKey ? (
        children
      ) : (
        <div className={styles.mapWrapper}>
          <div className={styles.notice}>
            Google Maps API Key is not set, <br />
            {userCan("admin-users") ? (
              <a href="/super-admin/app-settings">click here to update</a>
            ) : (
              "please contact your administrator to set it up"
            )}
            .
          </div>
        </div>
      )}
    </>
  );
};

export default MapSettingsCheck;
