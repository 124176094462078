import { useEffect, useState } from "react";
import adminApi from "../../../../api/admin";
import { useApi, authApi } from "component-library";
import { useSearchParams } from "react-router-dom";

const useUserAdmin = () => {
  const [users, setUsers] = useState();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [successMessage, setSuccessMessage] = useState();
  const [addUser, setAddUser] = useState(false);
  const [registrationAlert, setRegistrationAlert] = useState(false);
  const [editUser, setEditUser] = useState();
  const [userToDeactivate, setUserToDeactivate] = useState();
  const [userToActivate, setUserToActivate] = useState();
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);
  const [confirmActivate, setConfirmActivate] = useState(false);
  const [changePassword, setChangePassword] = useState();
  const [changedPasswordMessage, setChangedPasswordMessage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const archived = searchParams.get("archived") === "true";

  const setArchived = (archived) => {
    setSearchParams({ archived: archived });
  };

  const {
    request: registrationRequest,
    data: registrationData,
    loading: registrationLoading,
    error: registrationError,
  } = useApi(authApi.register);

  const {
    error: archivedUsersError,
    request: archivedUsersRequest,
    data: archivedUsersData,
    loading: archivedUsersLoading,
  } = useApi(adminApi.getArchivedUsers);

  const {
    error: usersError,
    request: usersRequest,
    data: usersData,
    loading: usersLoading,
  } = useApi(adminApi.getUsers);

  const {
    error: activateUserError,
    request: activateUserRequest,
    data: activateUserData,
    loading: activateUserLoading,
  } = useApi(adminApi.activateUser);

  const {
    error: deactivateUserError,
    request: deactivateUserRequest,
    data: deactivateUserData,
    loading: deactivateUserLoading,
  } = useApi(adminApi.deactivateUser);

  const updateUserStatus = (user) => {
    if (user?.active) {
      deactivateUserRequest(user.id);
    } else {
      activateUserRequest(user.id);
    }
  };

  const handleUserSearch = (q) => {
    setFilteredUsers(
      users.filter((user) => {
        let roles = "";
        if (!!user?.roles?.length) {
          user.roles.forEach((role) => {
            roles = `${roles} ` + role?.name?.replace("-", " ").toLowerCase() + " " + role?.name?.toLowerCase();
          });
        }
        if (`${user.first_name} ${user.last_name} ${user.email} ${roles}`.toLowerCase().includes(q.toLowerCase())) {
          return user;
        } else {
          return null;
        }
      })
    );
  };

  useEffect(() => {
    if (deactivateUserData?.user) {
      const user = deactivateUserData?.user;
      let message = `${user.first_name} ${user.last_name} successfully deactivated.`;

      setSuccessMessage(message);
      setUsers(users.filter((u) => u.id !== user.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deactivateUserData]);

  useEffect(() => {
    if (activateUserData?.user) {
      const user = activateUserData?.user;
      let message = `${user.first_name} ${user.last_name} successfully activated.`;

      setSuccessMessage(message);
      setUsers(users.filter((u) => u.id !== user.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateUserData]);

  const registerUser = (data) => registrationRequest({ ...data });

  useEffect(() => {
    if (registrationData && registrationData.user) {
      setUsers(
        [registrationData.user, ...users].sort((a, b) => {
          return a.last_name.localeCompare(b.last_name);
        })
      );
      setAddUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationData]);

  useEffect(() => {
    if (users) {
      setFilteredUsers(users);
    } else {
      setFilteredUsers([]);
    }
  }, [users]);

  useEffect(() => {
    if (archived) {
      archivedUsersRequest();
    } else {
      usersRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archived]);

  useEffect(() => {
    if (usersData?.users) {
      setUsers(usersData?.users);
    }
  }, [usersData]);

  useEffect(() => {
    if (archivedUsersData?.users) {
      setUsers(archivedUsersData?.users);
    }
  }, [archivedUsersData]);

  const hasError = () => {
    return archivedUsersError || usersError || deactivateUserError || activateUserError || registrationError || null;
  };

  const isLoading = () => {
    return (
      archivedUsersLoading ||
      usersLoading ||
      deactivateUserLoading ||
      activateUserLoading ||
      registrationLoading ||
      false
    );
  };

  return {
    users,
    hasError,
    isLoading,
    updateUserStatus,
    successMessage,
    setSuccessMessage,
    setUsers,
    registerUser,
    addUser,
    setAddUser,
    registrationAlert,
    setRegistrationAlert,
    registrationError,
    registrationLoading,
    filteredUsers,
    setFilteredUsers,
    handleUserSearch,
    editUser,
    setEditUser,
    userToDeactivate,
    setUserToDeactivate,
    confirmDeactivate,
    setConfirmDeactivate,
    confirmActivate,
    setConfirmActivate,
    archived,
    setArchived,
    userToActivate,
    setUserToActivate,
    changePassword,
    setChangePassword,
    changedPasswordMessage,
    setChangedPasswordMessage,
  };
};

export default useUserAdmin;
