import React from "react";
import styles from "./styles.module.scss";
import { displayDate } from "component-library";

const Date = ({ data }) => {
  return (
    <div className="text-gray2">
      {!!data?.note_date ? (
        <span className={styles.date}>{displayDate(data?.note_date)}</span>
      ) : (
        "Unknown"
      )}
    </div>
  );
};

export default Date;
