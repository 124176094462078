import { useApi, chartsApi } from "component-library";
import { useEffect, useState } from "react";

const useReporting = (project) => {
  const {
    loading: reportingLoading,
    error: reportingError,
    request,
    data,
  } = useApi(chartsApi.getProjectReporting);
  const [reporting, setReporting] = useState();

  useEffect(() => {
    if (!!project && !!project.job_number) {
      request(project.job_number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!project && project.job_number]);

  useEffect(() => {
    if (!!data && !!data.project) {
      setReporting(data.project);
    }
  }, [data]);

  return {
    reportingLoading,
    reportingError,
    reporting,
  };
};

export default useReporting;
