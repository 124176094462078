import React from "react";
import PropTypes from "prop-types";
import styles from "../styles.module.scss";

const Hidden = ({ editTasks, item, updateItem }) => {
  return (
    <td>
      {!!editTasks && item?.entity_type === "task" && (
        <div className="d-flex align-items-center justify-content-center form-group form-check my-0 ">
          <input
            id={`${item.id}-hide-toggle`}
            className="form-check-input"
            value={item.remove_from_app}
            checked={item.remove_from_app}
            type="checkbox"
            onChange={(val) => {
              updateItem("remove_from_app", val.target.checked ? 1 : 0, item.id);
            }}
          />
          <label
            className={`${styles.activeCheckbox} form-check-label sm`}
            aria-label="item toggle hidden state"
            htmlFor={`${item.id}-hide-toggle`}
          ></label>
        </div>
      )}
      {!editTasks && item.entity_type === "task" && (
        <div className="d-flex align-items-center justify-content-center form-group form-check my-0 ">
          {!!item.remove_from_app && (
            <>
              <input
                id={`${item.id}-hidden-indicator`}
                className="form-check-input"
                value={item.remove_from_app}
                checked={item.remove_from_app}
                type="checkbox"
                readOnly
              />
              <label
                htmlFor={`${item.id}-hidden-indicator`}
                className={`${styles.activeCheckbox} form-check-label sm`}
                aria-label="item is hidden"
              ></label>
            </>
          )}
        </div>
      )}
    </td>
  );
};

Hidden.propTypes = {
  item: PropTypes.object,
  editTasks: PropTypes.bool,
  id: PropTypes.any,
};

export default Hidden;
