import React from "react";
import ProjectSelector from "./ProjectSelector";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";
import { StyledDatePicker } from "component-library";

const ReportSelector = ({ projects, project, setProject, date, setDate, handleRunReport }) => {
  return (
    <div className={styles.formWrapper}>
      <h4 className={styles.searchText}>Search for weather conditions by project and day</h4>
      {!!projects && <ProjectSelector projects={projects} project={project} setProject={setProject} />}
      <div className="d-flex flex-row justify-content-between w-100">
        <div className={styles.datePickerWrapper}>
          <StyledDatePicker
            selected={date}
            onChange={(date) => setDate(date)}
            className={styles.datePicker}
            maxDate={Date.now()}
            isClearable
          />
        </div>
        <Button variant="orange" className="ms-4 pe-4 ps-4" onClick={() => handleRunReport()}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ReportSelector;
