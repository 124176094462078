import React from "react";
import { Button, Modal } from "react-bootstrap";
import { X, PlusCircle } from "react-feather";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import useAddBidItemForm from "./useAddBidItemForm";

const AddBidItem = ({ addingBidItem, setAddingBidItem, setMessage }) => {
  const { handleSubmit, fields } = useAddBidItemForm({ setAddingBidItem, setMessage });
  return (
    <Modal
      dialogClassName="modal-lg"
      animation={false}
      center
      show={addingBidItem}
      onHide={() => setAddingBidItem(false)}
    >
      <Modal.Header className="d-flex align-items-center p-0">
        <div className={styles.header}>
          <h2 className={`med mb-0 ${styles.headerText}`}>
            <PlusCircle color="#0071CE" size={16} />
            Add Bid Item
          </h2>
          <Button onClick={() => setAddingBidItem(false)} variant="link">
            <X color="#D3D3D3" size={24} />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <form onSubmit={handleSubmit}>{fields}</form>
      </Modal.Body>
    </Modal>
  );
};

AddBidItem.propTypes = {
  addingBidItem: PropTypes.bool,
  setAddingBidItem: PropTypes.func,
  setMessage: PropTypes.func,
};

export default AddBidItem;
