import React from "react";
import context from "./context";
import useProvideDashboard from "./useProvideDashboard";

function ProvideDashboard({ children }) {
  const dashboard = useProvideDashboard();
  return (
    <context.Provider value={dashboard}>
      {children}
    </context.Provider>
  );
}

export default ProvideDashboard;
