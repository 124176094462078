/* global google */
import React, { useEffect, useState } from "react";
import { GoogleMap, DrawingManager, InfoWindow } from "@react-google-maps/api";
import hqCoordinates from "../../../_fleetManagement/Map/hqCoordinates";
import styles from "./styles.module.scss";
import useGeofences from "../../../hooks/useGeofences";
import { mapStyles } from "../../../utils/mapStyles";
import ProjectListItem from "./ProjectListItem";
import ProjectsList from "./ProjectsList";
import MapSettingsCheck from "../../../components/MapSettingsCheck";

const MapView = ({ projects, loading }) => {
  const [geofenceLocations, setGeofenceLocations] = useState();
  const {
    onLoadDrawingManager,
    polyOptions,
    onLoadMap,
    setSelectedPolygon,
    selectedPolygon,
    getSelectedCenter,
    setActiveMarker,
  } = useGeofences({
    showGeofences: !!geofenceLocations?.length ? true : false,
    geofenceLocations,
  });

  const containerStyle = {
    width: "100%",
    height: "700px",
    border: "1px solid #d3d3d3",
    borderRadius: "4px",
  };

  useEffect(() => {
    let geofences = [];
    if (projects?.length) {
      geofences = projects.reduce((acc, curr) => {
        return [
          ...acc,
          ...curr.geofences.map((geo) => {
            return {
              ...geo,
              project: curr,
              color: curr?.favorited === 1 ? "#F0C808" : geo.color,
              geofence: geo.polygons,
            };
          }),
        ];
      }, []);
    }
    setGeofenceLocations(geofences);
  }, [projects]);

  return (
    <>
      <h2 className={styles.header}>All Projects</h2>
      <div className={`${styles.mapContainer} position-relative d-flex`}>
        <div className="col-8">
          <figure>
            <div className={`${styles.map} position-relative`}>
              <MapSettingsCheck>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={hqCoordinates}
                  onClick={() => {
                    setActiveMarker();
                    setSelectedPolygon();
                  }}
                  zoom={7}
                  onLoad={onLoadMap}
                  options={{
                    styles: mapStyles,
                    fullscreenControl: false,
                  }}
                >
                  {!loading && !!projects?.length && !!geofenceLocations?.length && (
                    <>
                      <DrawingManager
                        onLoad={onLoadDrawingManager}
                        options={{
                          drawingControlOptions: {
                            position: google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: [null],
                          },
                          polygonOptions: {
                            ...polyOptions,
                          },
                        }}
                      />
                    </>
                  )}

                  {!!selectedPolygon?.geofence?.length && !!selectedPolygon?.name && (
                    <InfoWindow
                      onCloseClick={() => {
                        setSelectedPolygon({});
                      }}
                      position={getSelectedCenter()}
                    >
                      <div className="d-flex align-items-stretch justify-content-center px-2 font-weight-bold">
                        <ProjectListItem
                          project={projects.find((project) => project.id === selectedPolygon.project.id)}
                          infoWindow
                        />
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMap>
              </MapSettingsCheck>
            </div>
          </figure>
        </div>
        {
          <ProjectsList
            projects={projects.sort((a, b) => {
              return b.favorited - a.favorited;
            })}
          />
        }
      </div>
    </>
  );
};

export default MapView;
