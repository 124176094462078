import React from "react";
import { Routes, Route } from "react-router";
import PrivateRoute from "../../components/PrivateRoute";
import AddRFQs from "./AddRFQs";
import ProvideVendorSelection from "./hooks/ProvideVendorSelection";
import PrintableRFQs from "./PrintableRFQs";
import Quote from "./Quote";
import RFQsList from "./RFQsList";

const RFQsRoutes = () => {
  return (
    <ProvideVendorSelection>
      <Routes>
        <Route index element={<PrivateRoute module="Portal" Component={<RFQsList />} />} />
        <Route path="rfqs" element={<PrivateRoute module="Portal" Component={<RFQsList />} />} />
        <Route path="add" element={<PrivateRoute module="Portal" Component={<AddRFQs />} />} />
        <Route path="print" element={<PrivateRoute module="Portal" Component={<PrintableRFQs />} />} />
        <Route path="quote/:quoteId" element={<PrivateRoute module="Portal" Component={<Quote />} />} />
      </Routes>
    </ProvideVendorSelection>
  );
};

export default RFQsRoutes;
