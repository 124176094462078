import React from "react";
import ProjectListItem from "./ProjectListItem";
import CustomScroll from "react-custom-scroll";
import styles from "./styles.module.scss";
import { FilterSearch } from "component-library";

const ProjectsList = ({ projects, handleUserSearch }) => {
  return (
    <section className={`${styles.list} col-4`}>
      <FilterSearch
        style={{ height: "3.5rem", width: "100%" }}
        searchFunction={handleUserSearch}
        inline
        placeholder="Find a Project..."
      />
      {!projects?.length && <div className="font-weight-bold p-4">No project results.</div>}

      {!!projects?.length && (
        <CustomScroll heightRelativeToParent="100%">
          <div>
            {!!projects?.length &&
              projects
                .sort((a, b) => {
                  return b.favorited - a.favorited;
                })
                .map((project) => <ProjectListItem key={project.id} project={project} />)}
          </div>
        </CustomScroll>
      )}
    </section>
  );
};

export default ProjectsList;
