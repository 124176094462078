import React from "react";
import { FilterSearch } from "component-library";
import useEstimateConversion from "../useEstimateConversion";

const TaskSearch = () => {
  const { setFilter } = useEstimateConversion();

  const filterBidItems = (q) => {
    setFilter(q);
  };

  return (
    <div>
      <FilterSearch searchFunction={filterBidItems} placeholder="Search" loading={false} inline />
    </div>
  );
};

export default TaskSearch;
