import React from "react";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const SubmittalsPageHeader = ({ submittals, statusClick, statusFilter }) => {
  const approvedCount = submittals.filter((sub) => sub.status_id === 4 || sub.status_id === 5).length;
  const rejectedCount = submittals.filter((sub) => sub.status_id === 7).length;

  return (
    <div className={`d-lg-flex ${styles["submittals-header"]} justify-content-between align-items-end`}>
      <div className="d-lg-flex align-items-end">
        <div className="status-filter">
          <Button
            variant="link"
            className={"med me-lg-3 p-0 light-text ms-0" + (statusFilter === "Active" && "active")}
            onClick={() => statusClick("Active")}
          >{`${submittals.length - (approvedCount + rejectedCount)} Total`}</Button>
          <Button
            variant="link"
            className={"med mx-lg-3 p-0 light-text " + (statusFilter === "Rejected" && "active")}
            onClick={() => statusClick("Rejected")}
          >{`${rejectedCount} Rejected`}</Button>
          <Button
            variant="link"
            className={"med mx-lg-3 p-0 light-text " + (statusFilter === "Approved" && "active")}
            onClick={() => statusClick("Approved")}
          >{`${approvedCount} Approved`}</Button>
        </div>
      </div>
    </div>
  );
};

SubmittalsPageHeader.propTypes = {
  submittals: PropTypes.array,
  statusClick: PropTypes.func.isRequired,
  statusFilter: PropTypes.string,
};

export default SubmittalsPageHeader;
