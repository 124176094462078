import React, { useEffect, useState } from "react";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import useWeeklyReportReview from "./useWeeklyReportReview";
import { HeaderButton, useApi, projectApi, useAuth } from "component-library";
import { ArrowRight, Briefcase, Save } from "react-feather";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import WeeklyReportReviewPageContent from "./WeeklyReportReviewPageContent";

const WeeklyReportReview = () => {
  const { userCan } = useAuth();
  const {
    request: projectRequest,
    data: projectData,
    loading: projectLoading,
    error: projectError,
  } = useApi(projectApi.getProject);

  const [project, setProject] = useState();
  const {
    weeklyReport,
    loading,
    error,
    weatherError,
    report_id,
    sendReportError,
    handleUpdateReportStatus,
    updateStatusLoading,
    updateStatusError,
    weather,
    weatherLoading,
  } = useWeeklyReportReview();

  useEffect(() => {
    if (!!weeklyReport?.report?.id) {
      projectRequest(weeklyReport?.report?.project_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weeklyReport?.report?.id]);

  useEffect(() => {
    if (!!projectData?.success) {
      setProject(projectData?.project);
    }
  }, [projectData]);

  const HeaderButtons = () => (
    <div className="d-flex align-items-center">
      <HeaderButton
        onClick={() => {
          if (weeklyReport.report.status !== "sent") {
            handleUpdateReportStatus({
              id: weeklyReport.report.id,
              status: "sent",
            });
          }
          window.print();
        }}
        ariaLabel="Save weekly report"
        text="Save to PDF"
        icon={<Save size={16} color="#0071ce" />}
      />
      {/* {!sendReportLoading ? (
        <HeaderButton
          onClick={() => {
            sendReport();
          }}
          disabled={weeklyReport?.report?.status === "sent"}
          ariaLabel="Send weekly report"
          text={weeklyReport?.report?.status === "sent" ? "Sent" : "Send"}
          icon={<Send size={16} color="#0071ce" />}
        />
      ) : (
        <div className="ps-4">
          <BeatLoader size={8} color="#0071ce" />
        </div>
      )} */}
    </div>
  );

  const BreadCrumbs = () => {
    return (
      <>
        <div className={styles.row}>
          <div className={styles.subHeader}>
            {!!project && !!report_id && (
              <>
                {userCan("weekly-report-edit") && (
                  <>
                    <Link to={`/field-management/weekly-reports/${report_id}/edit`}>New Weekly Report</Link>
                    <span>
                      <ArrowRight size={12} />
                    </span>
                    <span>Review</span>
                  </>
                )}
              </>
            )}
          </div>
          <HeaderButtons />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="hidePrint">
        <InteriorPage
          pageTitle="Weekly Report Review"
          pageSubtitleSecondaryContent={BreadCrumbs}
          navTitle={"Weekly Report Review"}
          navIcon={<Briefcase size={14} className={styles.navIcon} />}
          titleTag={`Weekly Report Review - ${project?.job_number}-${project?.name}`}
          loading={loading || projectLoading || updateStatusLoading}
          error={error || weatherError || sendReportError || updateStatusError || projectError}
          pageSubtitle={HeaderButtons}
        >
          <WeeklyReportReviewPageContent
            project={project}
            weeklyReport={weeklyReport}
            weather={weather}
            weatherLoading={weatherLoading}
          />
        </InteriorPage>
      </div>
      <div className="showPrint">
        <WeeklyReportReviewPageContent
          project={project}
          weeklyReport={weeklyReport}
          weather={weather}
          weatherLoading={weatherLoading}
        />
      </div>
    </>
  );
};

export default WeeklyReportReview;
