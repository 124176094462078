import React from "react";
import { AlertPopup } from "component-library";
import { Button } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import { ArrowRightCircle } from "react-feather";
import { Link } from "react-router-dom";
import useProject from "../../../../../hooks/useProject";
import styles from "./styles.module.scss";
import useFieldForms from "./useFieldForms";
import FieldFormsGrid from "../../FieldForms/FieldFormsGrid";
import PropTypes from "prop-types";
import DailyAccordion from "../components/DailyAccordion";

const FieldForms = () => {
  const { project } = useProject();
  const { forms, active, setActive, formsLoading, formsError } = useFieldForms();

  const headerContent = (
    <h2 className={`${styles.title} mb-0`}>
      Field Forms
      {!!formsLoading ? (
        <BeatLoader size={10} color={"#0071ce"} />
      ) : (
        <>
          {!!formsError ? (
            <span className="ms-1">
              <AlertPopup error={formsError} />
            </span>
          ) : (
            <span className="ms-1">({forms?.length || 0})</span>
          )}
        </>
      )}
    </h2>
  );

  const HeaderContent = () => (
    <div className={styles.header}>
      <div className="d-flex align-items-center">
        {!!forms?.length ? (
          <Button className="d-flex align-items-center px-0 me-2" onClick={() => setActive(!active)} variant="link">
            {headerContent}
          </Button>
        ) : (
          <div className={styles.headerContent}>{headerContent}</div>
        )}

        <Link className={styles.link} to={`/project/${project?.id}/field-management/field-forms`}>
          Field Forms <ArrowRightCircle size={16} color="#0071ce" />
        </Link>
      </div>
    </div>
  );

  return (
    <DailyAccordion
      headerContent={<HeaderContent />}
      active={active}
      setActive={setActive}
      loading={formsLoading}
      error={formsError}
    >
      <>
        {!formsError && !formsLoading && !!forms?.length && !!active && (
          <FieldFormsGrid formsLoading={formsLoading} forms={forms} />
        )}
        {!forms?.length && !formsError && !formsLoading && <div className={styles.noResults}>No forms found</div>}
      </>
    </DailyAccordion>
  );
};

FieldForms.propTypes = {
  currentSelectedDate: PropTypes.string,
  selectedForemenIds: PropTypes.array,
};

export default FieldForms;
