import {
  name,
  source_name,
  requirements,
  spec_section,
  updated_at,
  due_date,
  priority_id,
} from "./columnDefs";

const columnDefs = [
  name,
  source_name,
  requirements,
  spec_section,
  updated_at,
  due_date,
  priority_id
];

export default columnDefs;