import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import useEditPhases from "../../../../../hooks/useEditPhases";
import { X } from "react-feather";
import useEstimateConversion from "../../useEstimateConversion";

const EditPhases = ({ project, setMessage, refreshUpbItems, syncPhases }) => {
  const { setEditPhases, phases, setPhases, canEdit } = useEstimateConversion();

  const { fields, handleSubmit } = useEditPhases(
    project,
    phases,
    setPhases,
    setMessage,
    setEditPhases,
    refreshUpbItems,
    syncPhases,
    canEdit
  );

  return (
    <Modal size="xl" show={true} centered onHide={() => setEditPhases(false)}>
      <Modal.Header className="d-flex justify-space-between align-items-center pt-4 mb-0 pb-0">
        <h2 className="med">Create Phases</h2>
        <Button
          onClick={() => setEditPhases(false)}
          variant="link"
          aria-label="close create phases modal"
        >
          <X color="#E9E9E9" />
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-0 mx-3">
        <hr />
        <form onSubmit={handleSubmit}>{fields}</form>
      </Modal.Body>
    </Modal>
  );
};

EditPhases.propTypes = {
  project: PropTypes.object.isRequired,
  setEditPhases: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  phases: PropTypes.array,
  setPhases: PropTypes.func.isRequired,
};

export default EditPhases;
