import React from "react";

const colors = ["#259FAD", "#8C63C4", "#0071ce", "#FFA400"];

const Certifications = ({ data }) => {
  return (
    <div className="d-flex align-items-center">
      {!!data.company &&
        data.company.certifications.map((c) => (
          <div key={c.id} className="pill me-1" style={{ backgroundColor: colors[c.id - 1] }}>
            {c.name}
          </div>
        ))}
    </div>
  );
};

export default Certifications;
