import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import FieldLogs from "../../_resourceManagement/FieldLogs";
import PrivateRoute from "../PrivateRoute";
import FieldLog from "../../_resourceManagement/FieldLogs/FieldLog";
import CrewLogs from "../../_resourceManagement/CrewLogs/CrewLogs";
import CrewLogDetail from "../../_resourceManagement/CrewLogs/CrewLogDetail";
import ProvideFieldLogSelection from "../../_resourceManagement/context/ProvideFieldLogSelection";
import ProvideResourceManagement from "../../_resourceManagement/context/ProvideResourceManagement";
import FieldNotesAdminPage from "../../_resourceManagement/FieldNotesAdmin/FieldNotesAdminPage";
import Locates from "./Locates";
import WeeklyReportsList from "../../_resourceManagement/WeeklyReports";
import WeeklyReportEdit from "../../_resourceManagement/WeeklyReports/WeeklyReportEdit";
import WeeklyReportReview from "../../_resourceManagement/WeeklyReports/WeeklyReportReview";

const ResourceManagement = () => {
  return (
    <ProvideResourceManagement>
      <ProvideFieldLogSelection>
        <Routes>
          <Route index element={<Navigate to="field-logs" />} />
          <Route path="field-logs" element={<PrivateRoute module="TimeTracking" Component={<FieldLogs />} />} />
          <Route
            path="field-logs/:fieldLogId/foreman/:foremanId"
            element={<PrivateRoute module="TimeTracking" Component={<FieldLog />} />}
          />
          <Route path="crew-logs" element={<PrivateRoute module="TimeTracking" Component={<CrewLogs />} />} />
          <Route
            path="crew-log/:crewId"
            element={<PrivateRoute module="TimeTracking" Component={<CrewLogDetail />} />}
          />
          <Route
            path="field-notes"
            element={<PrivateRoute module="TimeTracking" Component={<FieldNotesAdminPage />} />}
          />
          <Route
            path="weekly-reports"
            element={<PrivateRoute module="TimeTracking" Component={<WeeklyReportsList />} />}
          />
          <Route path="weekly-reports/:report_id/edit" element={<PrivateRoute Component={<WeeklyReportEdit />} />} />
          <Route
            path="weekly-reports/:report_id/review"
            element={<PrivateRoute Component={<WeeklyReportReview />} />}
          />
          <Route path="/locates" element={<PrivateRoute Component={<Locates />} />} />
        </Routes>
      </ProvideFieldLogSelection>
    </ProvideResourceManagement>
  );
};

export default ResourceManagement;
