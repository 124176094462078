import React, { useEffect, useState } from "react";
import { FileText } from "react-feather";
import { AgGridTable, useAuth } from "component-library";
import submittalListConfig from "../../../config/submittalListConfig";
import AddNote from "./AddNote";
import PropTypes from "prop-types";

const SubmittalsList = ({ initialSubmittals, id, hideHeader }) => {
  const [submittals, setSubmittals] = useState(initialSubmittals);
  const [submittal, setSubmittal] = useState();

  const { user } = useAuth();

  useEffect(() => {
    setSubmittals(initialSubmittals);
  }, [initialSubmittals]);

  useEffect(() => {
    setSubmittal();
  }, [submittals]);

  return (
    <div>
      {!hideHeader && (
        <header className="d-lg-flex align-items-center justify-content-between">
          <h4 className="text-uppercase sm-med light-text">
            <FileText size="12" className="me-1" /> Submittals
          </h4>
        </header>
      )}

      <AgGridTable
        gridData={submittals.map((s) => ({
          ...s,
          statusName: !!s.status && s.status.name,
        }))}
        gridConfig={submittalListConfig}
        id={`${user.id}_submittal_listing`}
        navigateTo={`/project/${id}/submittals`}
        presetStyle="sortableTable"
      />

      <AddNote submittal={submittal} setSubmittal={setSubmittal} />
    </div>
  );
};

SubmittalsList.propTypes = {
  initialSubmittals: PropTypes.array,
  id: PropTypes.string,
  hideHeader: PropTypes.bool,
};

export default SubmittalsList;
