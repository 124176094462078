import { AgGridMasterDetail, useAuth } from "component-library";
import crewLogDetailConfig from "../../config/crewLogDetailConfig";
import detailCellConfig from "../../config/crewLogDetailConfig/detailCellConfig";
import gridRowHeights from "../../utils/gridRowHeights";
import { useNavigate } from "react-router-dom";
import useResourceManagement from "../context/useResourceManagement";
import PrintableCrewLogDetail from "./PrintableCrewLogDetail";

const CrewLogDetailListing = ({ gridData, loading }) => {
  const { crewDetailExpanded } = useResourceManagement();
  const { user } = useAuth();
  let history = useNavigate();

  const handleCellClick = ({ data, column }) => {
    if (!["expand"].includes(column?.colId)) {
      let url = `/project/${data.project_id}/field-management/timesheet/${data.timesheet_id}/foreman/${data.foreman_id}`;
      history(url);
    }
  };

  return (
    <>
      <div className="showPrint">{!!gridData?.length && <PrintableCrewLogDetail data={gridData} />}</div>
      <div className="hiddenPrint">
        <AgGridMasterDetail
          gridData={gridData}
          gridConfig={crewLogDetailConfig}
          id={`${user.id}_crew_log_detail_listing`}
          rowHeight={gridRowHeights.medium}
          isRowMaster={(dataItem) => (dataItem?.time_entries?.length ? true : false)}
          detailCellConfig={detailCellConfig}
          getDetailRowData={(params) => {
            params.successCallback(
              params.data.time_entries.map((entry) => {
                return {
                  ...entry,
                  timesheet_id: params.data.timesheet_id,
                  project_id: params.data.project_id,
                };
              })
            );
          }}
          rowIdField="timesheet_id"
          handleCellClick={handleCellClick}
          loading={loading}
          expandAll={crewDetailExpanded}
          suppressSizeToFit
          presetStyle="sortableTable"
          autoHeight
          fallbackMessage="No timesheets found."
        />
      </div>
    </>
  );
};

export default CrewLogDetailListing;
