import React from "react";
import { Routes, Route } from "react-router";
import SharedPhotos from "./pages/SharedPhotos";
import SharedAlbum from "./pages/SharedAlbum";
import SharedPhotoDetail from "./pages/SharedPhotoDetail";
import Unauthorized from "../components/pages/Unauthorized";
import SharedNotes from "./pages/SharedNotes";

const PhotoLibraryRoutes = () => {
  return (
    <Routes>
      <Route path="photos" element={<SharedPhotos />} />
      <Route path="photos/:photoId" element={<SharedPhotoDetail />} />
      <Route path="albums/:albumId" element={<SharedAlbum />} />
      <Route path="notes" element={<SharedNotes />} />
      <Route path="*" element={<Unauthorized />} />
    </Routes>
  );
};

export default PhotoLibraryRoutes;
