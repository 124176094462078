import { useState, useEffect } from "react";
import { useApi, projectApi, useFormControl, InlineUpload, dropdownStyles, useAuth } from "component-library";
import { schema, initialData } from "./notes";
import styles from "./styles.module.scss";
import { X } from "react-feather";
import { Button } from "react-bootstrap";
import { DateTime } from "luxon";
import useResourceManagement from "../../context/useResourceManagement";
import useNotesAdmin from "../context/useNotesAdmin";

const useAddNote = ({ date, onHide, photo }) => {
  const { projectsList } = useResourceManagement();
  const { setMessage, notesRequest, getRequestParams } = useNotesAdmin();
  const { user } = useAuth();
  const [newFile, setNewFile] = useState();

  const acceptedFileTypes = { "image/*": [".png", ".jpeg", ".jpg"] };

  const {
    request: addCommentRequest,
    error: addCommentError,
    loading: addCommentLoading,
    data: addCommentData,
  } = useApi(projectApi.addComment);

  const onSubmit = async (formData, formLoading) => {
    formLoading(true);
    try {
      const newComment = {
        comment: formData.note,
        note_date: DateTime.fromJSDate(date).toFormat("yyyy-MM-dd"),
        project_id: formData.project_id,
        files: formData.files,
        file_ids: photo ? [photo] : null,
        user_id: user.id,
        thumbnail: 1,
      };

      await addCommentRequest(newComment);
    } catch (message) {
      console.error(message);
      formLoading(false);
    }
    formLoading(false);
  };

  const { handleSubmit, renderSelectField, renderSubmitButton, renderTextareaField, data, setFields } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const handleSingleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      let newFile = {
        data: e.target.result,
        name: file.path,
        size: file.size,
        file_type: [18],
      };
      setNewFile(newFile);
    };
    reader.readAsDataURL(file);
  };

  const handleImageRemove = (tempId) => {
    const filteredFiles = data.files.filter((file) => file.tempId !== tempId);
    setFields({
      ...data,
      files: filteredFiles,
    });
  };

  const fields = (
    <>
      {renderSelectField({
        name: "project_id",
        label: "Project",
        options: projectsList || [],
        styles: dropdownStyles,
      })}
      {renderTextareaField({
        name: "note",
        label: "New Field Note",
      })}
      {!!data?.files?.length && (
        <div className={styles.imageGrid}>
          <div className={styles.imagesWrapper}>
            {data.files.map((file, index) => (
              <div className={styles.imageWrapper} key={index}>
                <img alt="" src={file.data} />
                <Button className={styles.closeButton} variant="link" onClick={() => handleImageRemove(file.tempId)}>
                  <X size={18} />
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}
      <InlineUpload
        uploadText="Upload Image"
        addAttachment={(file) => {
          handleSingleFileUpload(file);
        }}
        hasBorder={false}
        accept={acceptedFileTypes}
      />
      <div className="d-flex justify-content-end align-items-center">
        <Button
          className="light-text mx-1 btn btn-lg med font-weight-bold"
          variant="light-gray"
          type="submit"
          onClick={() => onHide()}
        >
          Cancel
        </Button>
        {renderSubmitButton("Save Note", !!addCommentLoading || !data.note || !data.project_id, styles.submitButton)}
      </div>
    </>
  );

  const photoFields = (
    <div className={styles.photoNoteWrapper}>
      {renderTextareaField({
        name: "note",
        className: styles.textArea,
        placeholder: "Add Field Note...",
      })}
      <button
        type="submit"
        className={`btn btn-link ${styles.photoSubmitBtn}`}
        disabled={!!addCommentLoading || !data.note}
      >
        Save Note
      </button>
    </div>
  );

  useEffect(() => {
    if (newFile) {
      const newFileWithId = { ...newFile, tempId: new Date().getTime() };
      setFields({
        ...data,
        files: data?.files?.length ? [...data.files, newFileWithId] : [newFileWithId],
      });
      setNewFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFile]);

  useEffect(() => {
    if (addCommentData?.comment) {
      setMessage("Note created successfully.");
      notesRequest(getRequestParams());
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCommentData]);

  return {
    fields,
    handleSubmit,
    addCommentLoading,
    addCommentError,
    photoFields,
  };
};

export default useAddNote;
