import { HeaderSelection } from "component-library";
import { DateTime } from "luxon";
import React, { useState } from 'react';

const useProvideFieldLogSelection = () => {
  const [showHeaderSelection, setShowHeaderSelection] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedLogs, setSelectedLogs] = useState([]);
  const [fieldLogs, setFieldLogs] = useState([]);

  const toggleLogSelection = (logId) => {
    if (selected.includes(logId)) {
      setSelected(selected.filter((id) => id !== logId));
      setSelectedLogs(selectedLogs.filter((v) => v.id !== logId));
    } else {
      const newVendor = fieldLogs.find((v) => v.id === logId);
      setSelected([...selected, logId]);
      setSelectedLogs([...selectedLogs, newVendor]);
    }
  };

  const FieldLogSelection = () => (
    <HeaderSelection
      selectedItems={selectedLogs}
      toggleSelection={toggleLogSelection}
      selectedIds={selected}
      showHeaderSelection={showHeaderSelection}
      setShowHeaderSelection={setShowHeaderSelection}
      itemName="Field Logs"
      RenderLabel={({ item }) => (
        <>
          <span className="semibold pe-2">{DateTime.fromISO(item.date).toFormat("M/d")}</span>
          <span>{`${item.foreman_first_name} ${item.foreman_last_name}`}</span>
        </>
      )}
    />
  );

  return {
    FieldLogSelection,
    showHeaderSelection,
    toggleLogSelection,
    setFieldLogs,
    fieldLogs,
    selected,
    setSelected,
    selectedLogs,
    setSelectedLogs,
  };
};

export default useProvideFieldLogSelection;