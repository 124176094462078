import CompanyName from "../contactsAdminColumns/CompanyName";

const company_name = {
  headerName: "Company",
  field: "company_name",
  flex: 1,
  minWidth: 300,
  resizable: true,
  cellRenderer: CompanyName,
};

export default company_name;
