import { useState, useEffect } from "react";
import { useApi, taskDetailApi } from "component-library";
import { useParams } from "react-router-dom";

const useTask = () => {
  const { id, taskId } = useParams();
  const [task, setTask] = useState();
  const [error, setError] = useState();

  const {
    request: taskRequest,
    data: taskData,
    loading: taskLoading,
    error: taskError,
  } = useApi(taskDetailApi.getTaskDetails);

  useEffect(() => {
    if (taskId) {
      taskRequest({ projectId: id, taskId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, taskId]);

  useEffect(() => {
    if (taskData?.task) {
      setTask(taskData.task);
    }
  }, [taskData]);

  useEffect(() => {
    const apiError = taskError;
    if (apiError) {
      setError(apiError);
    }
  }, [taskError]);

  return {
    task,
    taskLoading,
    error,
  };
};

export default useTask;
