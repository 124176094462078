import React from "react";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine, ResponsiveContainer } from "recharts";
import { DateTime } from "luxon";
import styles from "./styles.module.scss";
import ArrowLink from "../components/ArrowLink/ArrowLink";

const CashflowChart = ({ cashflow, isAggregate, onDashboard, title = "Project Snapshot" }) => {
  const chartData = cashflow.map((item) => {
    const spendItems = item.spend.reduce((acc, curr) => {
      acc[curr.description] = curr.amount;
      return acc;
    }, {});
    return {
      Billed: item.income,
      month_ending: item.month_ending,
      ...spendItems,
    };
  });

  const formatter = (t) => {
    return isAggregate
      ? `$${new Intl.NumberFormat("en-US").format(parseFloat(t) / 1000000)}m`
      : `$${new Intl.NumberFormat("en-US").format(parseFloat(t) / 1000)}k`;
  };
  return (
    <div>
      <div className="d-flex mb-3 align-items-center">
        <h2 className={styles.header}>{title}</h2>
        <div className="ms-4">
          {onDashboard && <ArrowLink url="/dashboard/cashflow" label="view cash flow by job" />}
        </div>
      </div>
      <figure>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={chartData} stackOffset="sign">
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="month_ending"
              axisLine={false}
              tickLine={false}
              tickFormatter={(t) => DateTime.fromSQL(t).toFormat("M/yyyy")}
            />
            <YAxis axisLine={false} tickLine={false} tickFormatter={formatter} />
            <Tooltip labelFormatter={(l) => DateTime.fromSQL(l).toFormat("M/yyyy")} formatter={formatter} />
            <ReferenceLine y={0} stroke="#EBEFF7" />
            <Bar dataKey="Billed" fill="#1BC4A6" barSize={20} />
            <Bar dataKey="BOND/INS/ADMIN" fill="#D85F00" stackId="stack" barSize={20} />
            <Bar dataKey="BURDEN" fill="#F5A611" stackId="stack" barSize={20} />
            <Bar dataKey="LABOR" fill="#F3CB0D" stackId="stack" barSize={20} />
            <Bar dataKey="MATERIALS" fill="#1194B0" stackId="stack" barSize={20} />
            <Bar dataKey="OWNED EQUIPMENT" fill="#1F98FB" stackId="stack" barSize={20} />
            <Bar fill="#0071CE" dataKey="RENTED EQUIPMENT" stackId="stack" barSize={20} />
            <Bar fill="#A564BE" dataKey="SUBCONTRACTORS" stackId="stack" barSize={20} />
            <Bar fill="#EF5672" dataKey="TESTING" stackId="stack" barSize={20} />
          </BarChart>
        </ResponsiveContainer>
      </figure>
    </div>
  );
};

export default CashflowChart;
