import React, { useState } from "react";
import PropTypes from "prop-types";
import { ProjectAttachment } from "../../../ProjectAttachments";
import { useAuth } from "component-library";
import ArrowLink from "../../../../components/ArrowLink/ArrowLink";
import styles from "../styles.module.scss";

const ProjectDocuments = ({ formData, setDocumentLoading }) => {
  const { userCan } = useAuth();

  const [noticeOfAward, setNoticeOfAward] = useState(
    formData.attachments.find((attachment) => attachment.type === "Notice of Award")
  );
  const [noticeToProceed, setNoticeToProceed] = useState(
    formData.attachments.find((attachment) => attachment.type === "Notice to Proceed")
  );
  const [contractDocuments, setContractDocuments] = useState(
    formData.attachments.filter((attachment) => attachment.type === "Contract Document")
  );

  return (
    <>
      <div className="row pb-2">
        <div className="col-12 d-flex align-items-start flex-row">
          <h2 className={styles.heading18}>Project Documents</h2>
          <ArrowLink
            url={`/project/${formData.id}/documents`}
            text="View All Documents"
            label="link to documents"
            className="mt-3 ms-2"
          />
        </div>

        <div className="col-md-3">
          <p className="med mb-1">Notice of Award</p>
          <ProjectAttachment
            attachment={noticeOfAward}
            setAttachment={setNoticeOfAward}
            type="Notice of Award"
            projectId={formData.id}
            isSingle
            hideType
            canUpload={userCan("bid-create") || userCan("bid-edit")}
            setDocLoading={setDocumentLoading}
            isBid={formData?.status === "bid"}
          />
        </div>
        <div className="col-md-3">
          <p className="med mb-1">Notice To Proceed</p>
          <ProjectAttachment
            attachment={noticeToProceed}
            setAttachment={setNoticeToProceed}
            type="Notice to Proceed"
            projectId={formData.id}
            isSingle
            hideType
            canUpload={userCan("bid-create") || userCan("bid-edit")}
            setDocLoading={setDocumentLoading}
            isBid={formData?.status === "bid"}
          />
        </div>
      </div>

      <p className="med mb-1">Contract Documents</p>
      <div className="row">
        {contractDocuments.map((doc, index) => (
          <div className="px-3" key={index}>
            <ProjectAttachment
              attachment={doc}
              setAttachment={setContractDocuments}
              type="Contract Document"
              projectId={formData.id}
              false
              hideType
              canUpload={userCan("bid-create") || userCan("bid-edit")}
              setDocLoading={setDocumentLoading}
              isSingle={false}
              key={doc.id}
              isBid={formData?.status === "bid"}
            />
          </div>
        ))}
        <div className="px-3">
          <ProjectAttachment
            setAttachment={setContractDocuments}
            type="Contract Document"
            projectId={formData.id}
            false
            hideType
            canUpload={userCan("bid-create") || userCan("bid-edit")}
            setDocLoading={setDocumentLoading}
            isSingle={false}
            project={formData}
            isBid={formData?.status === "bid"}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectDocuments;

ProjectDocuments.propTypes = {
  formData: PropTypes.object,
  setDocumentLoading: PropTypes.func,
};
