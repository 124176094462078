import React from "react";
import convertMinutesToTime from "../../../../../../utils/convertMinutesToTime";
import styles from "./styles.module.scss";
import { ArrowRightCircle, ArrowLeftCircle } from "react-feather";
import Grabby from "../../../Grabby/Grabby";
import { Avatar } from "component-library";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ChevronDown, ChevronRight } from "react-feather";
import useTimesheet from "./useTimesheet";
import formatTimesheetTime from "../../../../../../utils/formatTimesheetTime";

const Timesheet = ({ timesheet, status, editing, foremanId, overAllocatedTimeSheets }) => {
  const { active, setActive, unassignedHours, isDragging, dragRef, canDrag } = useTimesheet({
    timesheet,
    status,
    editing,
  });

  const getIsOverAllocated = () => {
    return overAllocatedTimeSheets?.some((sheet) => sheet === timesheet?.id);
  };
  
  return (
    <div className={`${styles.timesheet} ${getIsOverAllocated() ? styles.overAllocated : ""}`}>
      <Button
        className="p-0 text-start text-decoration-none position-relative"
        variant="link"
        onClick={() => setActive(!active)}
      >
        {!(unassignedHours > 0) && <div className={styles.overlay} />}
        <div className={styles.header}>
          <Avatar size="xl" user={timesheet?.user} />
          <div className=" w-100 ms-3">
            <div className="d-flex justify-content-between">
              <div className={styles.timesheetName}>
                <span>{timesheet?.user?.first_name}</span>
                <span>{timesheet?.user?.last_name}</span>
              </div>
              <span className={styles.totalHours}>{convertMinutesToTime(timesheet?.total_minutes)}</span>
            </div>

            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <span className="d-flex align-items-center me-3">
                  <ArrowRightCircle size={16} color="#259FAD" className="me-1" />
                  {!!timesheet && formatTimesheetTime(timesheet?.time_in)}
                </span>
                <span className="d-flex align-items-center ">
                  <ArrowLeftCircle size={16} color="#FF6700" className="me-1" />
                  {!!timesheet && formatTimesheetTime(timesheet?.time_out)}
                </span>
              </div>
              <>{active ? <ChevronDown color="#A7A7A7" size={12} /> : <ChevronRight color="#A7A7A7" size={12} />}</>
            </div>
          </div>
        </div>
      </Button>
      {!!active && (
        <div className={`${styles.unassignedWrapper} ${!!isDragging ? styles.isDragging : ""} `}>
          {unassignedHours > 0 && (
            <div ref={dragRef} className={`${styles.unassignedInner} ${!canDrag ? styles.noDrag : ""}`}>
              <div className="d-flex align-items-center text-gray2">
                {!!canDrag && <Grabby />}
                Unassigned Hours
              </div>
              <div className={styles.hoursPill}>{convertMinutesToTime(unassignedHours)}</div>
            </div>
          )}
          <div className="w-100 text-center mt-2">
            <Link
              to={`/project/${timesheet.project_id}/field-management/timesheet/${timesheet.id}/foreman/${foremanId}`}
            >
              View Timesheet
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Timesheet;
