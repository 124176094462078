import { useEffect, useState } from "react";
import usePhotoLibrary from "../../context/usePhotoLibrary";
import styles from "./styles.module.scss";
import { FolderPlus } from "react-feather";
import LibraryGrid from "../../LibraryGrid";
import { DateTime } from "luxon";
import { useApi, photoLibraryApi } from "component-library";
import { Alert, Button } from "react-bootstrap";
import useProject from "../../../../hooks/useProject";

const useMultiSelectModal = ({ album, setShowModal, setAlbum, showModal }) => {
  const { project } = useProject();
  const { photosList, refreshLists } = usePhotoLibrary();
  const [photosNotInAlbum, setPhotosNotInAlbum] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const { request, loading, error, data } = useApi(photoLibraryApi.updateAlbumDetails);

  useEffect(() => {
    if (album) {
      setPhotosNotInAlbum(photosList.filter((photo) => !photo?.albums?.length));
    } else {
      setPhotosNotInAlbum(photosList);
    }
  }, [album, photosList, showModal]);

  const formatDate = (date) => {
    return DateTime.fromJSDate(new Date(date)).toFormat("yyyy-MM-dd");
  };

  const pinnedPhotos = (list) => {
    return list.filter((item) => item.pinned);
  };

  const todaysPhotos = (list) => {
    return list.filter((item) => formatDate(item.created_at) === formatDate(new Date()));
  };

  const recentPhotos = (list) => {
    const sevenDaysAgo = DateTime.local().minus({ days: 7 }).toFormat("yyyy-MM-dd");
    const today = DateTime.local().toFormat("yyyy-MM-dd");
    return list.filter((item) => {
      const itemDate = formatDate(item.created_at);
      return itemDate < today && itemDate >= sevenDaysAgo;
    });
  };

  const previousPhotos = (list) => {
    const eightDaysAgo = DateTime.local().minus({ days: 8 }).toFormat("yyyy-MM-dd");
    return list.filter((item) => !item.created_at || formatDate(item.created_at) <= eightDaysAgo);
  };

  const photoClick = (num, photo) => {
    if (selectedPhotos.includes(photo.id)) {
      setSelectedPhotos(selectedPhotos.filter((item) => item !== photo.id));
    } else {
      setSelectedPhotos([...selectedPhotos, photo.id]);
    }
  };

  const bodyContent = (
    <div className={styles.body}>
      <Alert variant="danger" show={!!error}>
        {error}
      </Alert>
      <section>
        <h2 className={styles.sectionTitle}>Pinned Photos</h2>
        <LibraryGrid
          project={project}
          gridSize="large"
          gridItems={pinnedPhotos(photosNotInAlbum)}
          selectedPhotoIds={selectedPhotos}
          updateSelectedPhotos={photoClick}
        />
      </section>
      <section>
        <h2 className={styles.sectionTitle}>Todays Photos</h2>
        <LibraryGrid
          project={project}
          gridSize="medium"
          gridItems={todaysPhotos(photosNotInAlbum)}
          selectedPhotoIds={selectedPhotos}
          updateSelectedPhotos={photoClick}
        />
      </section>
      <section>
        <h2 className={styles.sectionTitle}>Last 7 Days</h2>
        <LibraryGrid
          project={project}
          gridSize="medium"
          gridItems={recentPhotos(photosNotInAlbum)}
          selectedPhotoIds={selectedPhotos}
          updateSelectedPhotos={photoClick}
        />
      </section>
      <section>
        <h2 className={styles.sectionTitle}>Previous Photos</h2>
        <LibraryGrid
          project={project}
          gridSize="medium"
          gridItems={previousPhotos(photosNotInAlbum)}
          selectedPhotoIds={selectedPhotos}
          updateSelectedPhotos={photoClick}
        />
      </section>
    </div>
  );

  const headerContent = (
    <div className="d-flex align-items-center">
      <FolderPlus color="#0071ce" size={16} />
      <h2 className={styles.modalTitle}>Add Photo(s) to Album</h2>
    </div>
  );

  const onHide = () => {
    setShowModal(false);
  };

  const onSubmit = () => {
    const photoIdsInAlbum =
      photosList
        .filter((photo) => {
          const albumIds = photo.albums.map((album) => album.id);
          return albumIds.includes(parseInt(album.id));
        })
        ?.map((photo) => photo.id) || [];
    const ids = [...new Set([...photoIdsInAlbum, ...selectedPhotos])];
    request({
      name: album.name,
      album_id: album.id,
      photo_ids: ids,
    });
  };

  const footerContent = (
    <>
      <Button
        onClick={onSubmit}
        className="light-text mx-1 btn btn-lg med"
        variant="orange"
        disabled={loading || selectedPhotos.length === 0}
      >
        {loading ? (
          "Loading..."
        ) : (
          <>
            Add
            {selectedPhotos.length > 0 ? ` ${selectedPhotos.length} Photos` : " Photos"}
          </>
        )}
      </Button>
    </>
  );

  useEffect(() => {
    if (data) {
      setAlbum(data.album.photos);
      setShowModal(false);
      refreshLists();
      setSelectedPhotos([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    headerContent,
    bodyContent,
    footerContent,
    onHide,
    loading,
  };
};

export default useMultiSelectModal;
