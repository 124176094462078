import { useState, useEffect } from "react";
import { useApi, fieldLogsApi, timesheetApi } from "component-library";

const useTimeEntry = ({
  entry,
  foremanId,
  fieldLog,
  setFieldLog,
  setOverAllocatedTimeSheets,
  overAllocatedTimeSheets,
  config,
}) => {
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [laborOptions, setLaborOptions] = useState([]);

  useEffect(() => {
    if (!!entry?.equipment?.labor_types?.length > 0) {
      setLaborOptions(
        entry.equipment.labor_types.map((labor) => ({
          value: labor.id,
          label: labor.name,
        }))
      );
    } else {
      if (!entry.equipment_id && !!config?.labor_no_equipment?.length) {
        setLaborOptions(
          config.labor_no_equipment.map((labor) => ({
            value: labor.id,
            label: labor.name,
          }))
        );
      } else {
        setLaborOptions([
          { value: 14, label: "General Laborer" },
        ]);
      }
    }
  }, [entry, config]);

  useEffect(() => {
    if (!!config) {
      if (!!config?.equipment?.length) {
        const noEquipment = {
          label: `No Equipment`,
          value: 0,
        };
        const equipment = config.equipment.map((e) => {
          return {
            label: `#${e.equip_no} ${e?.display_description || e.name}`,
            value: e.id,
          };
        });
        setEquipmentOptions([noEquipment, ...equipment]);
      }
    }
  }, [config]);

  const [secondaryEquipmentOptions, setSecondaryEquipmentOptions] = useState();
  const [minutes, setMinutes] = useState(
    !!entry && !!entry.minutes ? entry.minutes : 0
  );
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  const {
    request: updateRequest,
    data: updateData,
    loading: updateLoading,
    error: updateError,
  } = useApi(timesheetApi.updateTimeEntry);

  const {
    request: removeRequest,
    loading: removeLoading,
    error: removeError,
  } = useApi(fieldLogsApi.removeEntryFromFieldLog);

  const deleteTimeEntry = async () => {
    if (!!entry?.id && !!entry?.work_day_task_id && !!foremanId) {
      const response = await removeRequest(entry.id, entry.work_day_task_id, foremanId);
      if (!!response.success) {
        setFieldLog({
          ...fieldLog,
          work_day_tasks: fieldLog.work_day_tasks.map((task) => {
            if (task.id === entry.work_day_task_id) {
              return {
                ...task,
                time_entries: task.time_entries.filter((e) => e.id !== entry.id),
                time_entry_ids: task.time_entry_ids.filter((e) => e !== entry.id),
              };
            } else {
              return task;
            }
          }),
          time_sheets: fieldLog.time_sheets.map((sheet) => {
            if (sheet.id === entry.time_sheet_id) {
              return {
                ...sheet,
                time_entries: sheet.time_entries.filter((e) => e.id !== entry.id),
                time_entry_ids: sheet.time_entry_ids.filter((e) => e !== entry.id),
              };
            } else {
              return sheet;
            }
          }),
        });

        if (!!response?.responseData?.time_entry?.work_day_task?.over_allocated) {
          setOverAllocatedTimeSheets([...overAllocatedTimeSheets, entry.time_sheet_id]);
        } else {
          setOverAllocatedTimeSheets(overAllocatedTimeSheets.filter((ts) => ts !== entry.time_sheet_id));
        }
      }
    }
  };

  const updateTimeEntry = (name, value) => {
    let updatedTimeEntry;

    if (name === "labor") {
      updatedTimeEntry = {
        ...entry,
        labor_id: value,
      };
    } else if (name === "equipment") {
      const equipment = config.equipment.find((e) => e.id === value);

      const laborTypeId = !!equipment?.labor_type_ids?.length
        ? equipment.labor_type_ids.length === 1
          ? equipment.labor_type_ids[0].labor_type_id
          : 0
        : 14;

      const confirmLaborType = !!config.labor_types.find(
        (l) => l.id === laborTypeId
      ) ? laborTypeId : 14;

      updatedTimeEntry = {
        ...entry,
        equipment_id: value,
        secondary_equipment: null,
        secondary_equipment_id: 0,
        labor_id: confirmLaborType,
      };
    } else if (name === "minutes") {
      updatedTimeEntry = {
        ...entry,
        minutes: value,
      };
    } else if (name === "secondary_equipment") {
      updatedTimeEntry = {
        ...entry,
        secondary_equipment_id: value,
      };
    }
    return updatedTimeEntry;
  };

  const handleSelectChange =
    (name) =>
    ({ value }) => {
      const updatedTimeEntry = updateTimeEntry(name, value);
      updateRequest(updatedTimeEntry);
    };

  useEffect(() => {
    if (!!entry?.equipment?.secondary_equipment?.length) {
      const noEquipment = {
        label: `No Equipment`,
        value: 0,
      };
      const secondaryEquipment = entry.equipment.secondary_equipment.map(
        (e) => {
          return {
            label: `#${e.equip_no} ${e.name}`,
            value: e.id,
          };
        }
      );
      setSecondaryEquipmentOptions([noEquipment, ...secondaryEquipment]);
    } else {
      setSecondaryEquipmentOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!entry && entry.equipment]);

  useEffect(() => {
    if (!!updateError) {
      setMinutes(entry.minutes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  useEffect(() => {
    if (minutes !== entry.minutes) {
      const updatedTimeEntry = updateTimeEntry("minutes", minutes);
      updateRequest(updatedTimeEntry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes]);

  useEffect(() => {
    if (!!updateData) {
      setFieldLog({
        ...fieldLog,
        time_sheets: fieldLog.time_sheets.map((sheet) => {
          if (sheet.id === updateData.time_entry.time_entry.time_sheet_id) {
            return {
              ...sheet,
              time_entries: sheet.time_entries.map((e) =>
                e.id === updateData.time_entry.time_entry.id
                  ? updateData.time_entry.time_entry
                  : e
              ),
            };
          } else {
            return sheet;
          }
        }),
      });

      const overAllocated = updateData.time_entry.over_allocated;
      const overAllocatedTimesheetId =
        updateData.time_entry.time_entry.time_sheet_id;
      setOverAllocatedTimeSheets(
        overAllocated
          ? [...overAllocatedTimeSheets, overAllocatedTimesheetId]
          : overAllocatedTimeSheets.filter(
              (id) => id !== overAllocatedTimesheetId
            )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    if (!!updateError) {
      setError(updateError);
    } else {
      setError();
    }
  }, [updateError]);

  useEffect(() => {
    if (!!updateLoading || !!removeLoading) {
      setLoading(!!updateLoading || !!removeLoading);
    } else {
      setLoading();
    }
  }, [updateLoading, removeLoading]);

  return {
    error,
    removeError,
    loading,
    handleSelectChange,
    equipmentOptions,
    secondaryEquipmentOptions,
    deleteTimeEntry,
    minutes,
    setMinutes,
    laborOptions,
  };
};

export default useTimeEntry;
