import { Joi } from "component-library";

const regexCurrency = /^\$?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/;

const schema = {
  contractor: Joi.any(),
  bid: Joi.saneString()
  .regex(regexCurrency)
  .message("Bid must be a valid dollar ammount.")
  .label("Bid amount"),
  low_bid: Joi.number(),
};

const initialData = { 
  contractor: "", 
  bid: "", 
  low_bid: 0 
};

export { schema, initialData };
