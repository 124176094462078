import React, { useEffect, useState } from "react";
import { useApi, FilterSearch, bidApi } from "component-library";
import bidListConfig from "../../config/bidListConfig";
import { Archive } from "react-feather";
import { Tag } from "react-feather";
import InteriorPage from "../pageLayout/InteriorPage";
import { HeaderButton, AgGridTable, useAuth } from "component-library";
import gridRowHeights from "../../utils/gridRowHeights";

const BidListing = () => {
  const { config, user } = useAuth();
  const [bids, setBids] = useState([]);
  const { loading, request, error, data } = useApi(bidApi.getBids);
  const { request: searchBids, data: searchData, loading: searchLoading } = useApi(bidApi.searchBids);

  useEffect(() => {
    request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchData) setBids(searchData.bids);
  }, [searchData]);

  useEffect(() => {
    if (data && data.projects) {
      setBids(data.projects.sort((a, b) => new Date(b.bid_date) - new Date(a.bid_date)));
    }
  }, [data]);

  const filterBids = async (q) => {
    searchBids(q);
  };

  const HeaderButtons = () => (
    <div className="d-flex align-items-center">
      <HeaderButton
        linkTo="/bids/archived"
        text="View Inactive"
        icon={<Archive size={16} color="#0071ce" />}
        ariaLabel="View Inactive Bids"
      />
      <HeaderButton linkTo="/bid/create" ariaLabel="Add New Bid" />
    </div>
  );

  return (
    <InteriorPage
      pageTitle="Bid Calendar"
      loading={loading}
      error={error}
      navIcon={<Tag color="#fff" size={14} />}
      navTitle="Bids"
      titleTag="Bids — Active Bids"
      pageTitleSecondaryContent={HeaderButtons}
    >
      <div className="d-flex mb-4 mt-2">
        <FilterSearch
          searchFunction={filterBids}
          placeholder="Search"
          loading={searchLoading}
          inline
          style={{ width: 350 }}
        />
      </div>
      {bids && !!bids.length && (
        <AgGridTable
          gridData={bids.map((bid) => {
            const preBidTodo = bid.bid_todos.find(
              (todo) => !!todo.bid_todo && todo.bid_todo.name === "Pre Bid Meeting"
            );
            const runner = config.runners.find((r) => r.id === bid.runner_id);
            const estimator = config.estimators.find((e) => e.id === bid.estimator_id);
            const completedTodosLength = bid.bid_todos.filter((todo) => todo.complete === 1).length;

            var color = bid.bid_todos.length === 0 ? -1 : completedTodosLength / bid.bid_todos.length;

            return {
              ...bid,
              color: color,
              pre_bid_date: !!preBidTodo && preBidTodo.pre_bid_date,
              runnerFirstName: !!runner && runner.first_name,
              runnerLastName: !!runner && runner.last_name,
              estimatorFirstName: !!estimator && estimator.first_name,
              estimatorLastName: !!estimator && estimator.last_name,
            };
          })}
          gridConfig={bidListConfig}
          loading={loading || searchLoading}
          rowHeight={gridRowHeights.medium}
          headerHeight={52}
          suppressSizeToFit
          navigateTo="/bid"
          id={`${user.id}_active_bid_list`}
        />
      )}
    </InteriorPage>
  );
};

export default BidListing;
