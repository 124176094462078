import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { initialData, schema } from "./schema.js";
import companiesApi from "../../../api/companies";
import { states, dropdownStylesWithWidth, useFormControl } from "component-library";

const useAddLocation = ({
  company,
  setCompany,
  setAddingLocation,
  initialLocation,
  setLocation,
  isAdmin,
  selectedOption,
}) => {
  const apiCall = !!initialLocation
    ? companiesApi.updateLocation
    : companiesApi.createLocation;

  const initialAddress =
    initialLocation && initialLocation.address && initialLocation.address;
  const initialAddressData = initialAddress
    ? {
        address1: initialAddress.address1,
        address2: initialAddress.address2,
        city: initialAddress.city,
        state: initialAddress.state,
        zip: initialAddress.zip,
      }
    : undefined;

  const [error, setError] = useState(false);

  const onSubmit = async (formData, setLoading) => {
    let location = {
      company_id: company.id,
      name: formData.name,
      phone: formData.phone,
      archived: 0,
      address: {
        address1: formData.address1,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
      },
    };
    if (!!initialLocation) {
      location.id = initialLocation.id;
      location.archived = initialLocation.archived;
    }

    setError(false);
    try {
      const { data, error: locationError } = await apiCall(location);
      setError(locationError);
      setLoading(false);

      if (!isAdmin) {
        const newLocation = data.location;
        setLocation({
          location_id: newLocation.id,
          company_id: company.id,
          company_name: company.name,
          location_name: newLocation.name,
          address1: newLocation.address.address1,
          address2: newLocation.address.address2,
          city: newLocation.address.city,
          state: newLocation.address.state,
          zip: newLocation.address.zip,
          phone: newLocation.phone,
        });
      } else {
        setCompany({
          ...company,
          locations: !!initialLocation
            ? company.locations.map((loc) => {
                if (loc.id === data.location.id) {
                  return {
                    ...data.location,
                    contacts: loc.contacts,
                  };
                } else {
                  return loc;
                }
              })
            : [...company.locations, data.location],
        });
      }

      if (!!data && !!data.message && data.success === false) {
        throw data.message;
      } else {
        setAddingLocation(false);
      }
    } catch (message) {
      console.error(message);
      setLoading(false);
      setError(message);
    }
  };

  const {
    handleSubmit,
    renderSubmitButton,
    renderTextField,
    renderSelectField,
  } = useFormControl({
    schema,
    onSubmit,
    initialData: !!initialLocation
      ? {
          ...initialAddressData,
          ...initialLocation,
        }
      : initialData,
  });

  const fields = (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <>
        {!!company ? <div className="font-weight-bold">{company.name}</div> : <>Company select</>}
        <div className="row">
          <div className="col">
            {renderTextField({
              name: `name`,
              label: "Location Name",
              autoComplete: "nope",
              autoFocus: !!company ? true : false,
            })}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            {renderTextField({
              name: `phone`,
              label: "Phone",
              type: "tel",
              autoComplete: "nope",
            })}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {renderTextField({
              name: `address1`,
              label: "Address",
              autoComplete: "nope",
            })}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {renderTextField({
              name: `address2`,
              label: "Address 2",
              autoComplete: "nope",
            })}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5">
            {renderTextField({
              name: `city`,
              label: "City",
              autoComplete: "nope",
            })}
          </div>
          <div className="col-lg-4">
            {renderSelectField({
              name: `state`,
              label: "State",
              options: states,
              emptyOption: "Select...",
              isClearable: false,
              styles: dropdownStylesWithWidth("100%"),
              autoComplete: "nope",
            })}
          </div>
          <div className="col-lg-3">
            {renderTextField({
              name: `zip`,
              label: "ZIP",
              autoComplete: "nope",
            })}
          </div>
        </div>
      </>
    </>
  );

  return {
    fields,
    handleSubmit,
    renderSubmitButton,
  };
};

export default useAddLocation;
