import { Joi } from "component-library";

const schema = {
  email: Joi.saneString()
    .email({ tlds: { allow: false } })
    .required()
    .label("Email Address"),
  password: Joi.saneString().min(8).required().label("Password"),
  password_confirmation: Joi.ref("password"),
  first_name: Joi.saneString().required().label("First Name"),
  last_name: Joi.saneString().required().label("Last Name"),
};

const initialData = {
  email: "",
  password: "",
  first_name: "",
  last_name: "",
  password_confirmation: "",
};

export { schema, initialData };
