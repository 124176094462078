import React from "react";
import { Routes, Route } from "react-router";
import PrivateRoute from "../../../components/PrivateRoute";
import AddPurchaseOrder from "./AddPurchaseOrder";
import PurchaseOrders from "./PurchaseOrders";
import PurchaseOrderPage from "./PurchaseOrder/PurchaseOrderPage";

const PurchaseOrderRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={<PrivateRoute viewPermission="submittal-list" module="Portal" Component={<PurchaseOrders />} />}
      />
      <Route path="add" element={<PrivateRoute module="Portal" Component={<AddPurchaseOrder />} />} />
      <Route path="order/:poId" element={<PrivateRoute module="Portal" Component={<PurchaseOrderPage />} />} />
    </Routes>
  );
};

export default PurchaseOrderRoutes;
