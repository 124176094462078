const phaseColors = [
  "#0071CE",
  "#7DDB2D",
  "#F0C808",
  "#FFA400",
  "#FF6700",
  "#FF2D55",
  "#8C63C4",
  "#99C6EB",
  "#259FAD",
  "#006F3C"
];

const getPhaseColor = (phase, sortedPhases) => {
  const phaseIndex = sortedPhases.findIndex(
    p => p.id === phase.id
  );
  return phaseColors[phaseIndex % phaseColors.length];
};

export { phaseColors, getPhaseColor };