import React from "react";
import { Modal, Button } from "react-bootstrap";
import useUserAdmin from "./hooks/useUserAdmin";
import useEditUser from "../../hooks/useEditUser";

const EditUserModal = () => {
  const { editUser, setEditUser, changePassword } = useUserAdmin();

  const { handleSubmit, fields, renderSubmitButton } = useEditUser();

  return (
    <Modal centered animation={false} show={!!editUser && !changePassword} onHide={() => setEditUser()}>
      <Modal.Header closeButton closeLabel="close edit user modal">
        <h5 className="lg mb-0 med">
          {`Edit User ${editUser && editUser.first_name} ${editUser && editUser.last_name}`}
        </h5>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          {fields}
          </Modal.Body>
        <Modal.Footer>
          <Button variant="light-gray" onClick={() => setEditUser()} className="form-control-submit med btn">
            Cancel
          </Button>
          {renderSubmitButton("Save Edits")}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditUserModal;
