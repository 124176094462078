import React from "react";
import useProvideBid from "./useProvideBid";
import bidContext from "../../context/bidContext";

function ProvideBid({ children }) {
  const bid = useProvideBid();
  return <bidContext.Provider value={bid}>{children}</bidContext.Provider>;
}

export default ProvideBid;
