import React from "react";
import PropTypes from "prop-types";
import ContactControl from "../../components/Contacts/ContactControl";
import { useAuth } from "component-library";

const Contacts = ({ renderLocationField, data, renderContactsListField, modifyRemoveFunc }) => {
  const { config } = useAuth();

  return (
    <>
      <div className="row" id="contacts">
        <div className="col-12">
          <h1 className="pt-2 form-section-header">Contacts</h1>
        </div>
      </div>
      <div className="d-flex">
        <div className="px-3 ">
          {renderLocationField({
            name: "contract_owner_id",
            location: data.contract_owner,
            locationObjectKey: "contract_owner",
            label: "Contract Owner",
          })}
        </div>
        {!!data.project_owners?.length && (
          <div className="px-3 mt-2">
            <span className="mt-3">Legacy Engineers</span>
            <div className="mt-2">
              {data.project_owners.map((contact) => {
                return (
                  <ContactControl
                    key={contact.id}
                    contact={contact.contact}
                    title={"Legacy Engineer"}
                    colDisplay={true}
                  />
                );
              })}
            </div>
            {/* {renderContactsListField({
            name: "project_owner_ids",
            contacts: data.project_owners,
            contactsName: "project_owners",
            label: "Project Engineers",
            modifyRemoveFunc: modifyRemoveFunc,
          })} */}
          </div>
        )}
        <div className="px-3">
          {renderContactsListField({
            name: "project_contacts",
            contacts: data.project_contacts,
            label: "Project Contacts",
            roles: config.roles,
          })}
        </div>
      </div>
    </>
  );
};

export default Contacts;

Contacts.propTypes = {
  renderLocationField: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  renderContactsListField: PropTypes.func.isRequired,
};
