import React from "react";
import ModalWrapper from "../ModalWrapper";
import PropTypes from "prop-types";
import useCreateAlbum from "./useCreateAlbum";

const CreateAlbumModal = ({ showModal, setShowModal }) => {
  const { headerContent, bodyContent, footerContent, onHide } = useCreateAlbum({ setShowModal });

  return (
    <ModalWrapper
      showModal={showModal}
      setShowModal={setShowModal}
      headerContent={headerContent}
      bodyContent={bodyContent}
      footerContent={footerContent}
      onHide={() => onHide()}
    />
  );
};

CreateAlbumModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default CreateAlbumModal;
