import React from "react";
import useDownloadFilesRequirements from "./hooks/useDownloadFilesRequirements";
import PropTypes from "prop-types";

const DownloadFiles = ({ task, setTodos, setBid }) => {
  const {
    fields,
    renderSubmitButton,
    handleSubmit,
  } = useDownloadFilesRequirements(task, setTodos, setBid);

  return (
    <form className="row position-relative" onSubmit={handleSubmit}>
      <div className="col-md-8 position-static">{fields}</div>
      <div className="col-md-4 d-flex flex-column justify-content-end text-end p-3">
        <div className="flex-end">{renderSubmitButton("Mark Complete")}</div>
      </div>
    </form>
  );
};

DownloadFiles.propTypes = {
  task: PropTypes.object,
  setTodos: PropTypes.func,
  setBid: PropTypes.func,
};
export default DownloadFiles;
