import React from 'react'
import TimeInOut from '../TimeInOut';
import { DateTime } from 'luxon'
import styles from "./styles.module.scss";
import convertMinutesToTime from '../../../../../../../utils/convertMinutesToTime'

const TimesheetRow = ({ timesheet }) => {

  const formatDate = (date) => {
    const dateTime = DateTime.fromJSDate(new Date(date));
    return dateTime.toFormat("MM/dd");
  };

  return (
    <div className={styles.rowWrapper}>
      <span>{formatDate(timesheet.time_in)}</span>
      <TimeInOut timesheet={timesheet} />
      <span>{convertMinutesToTime(timesheet.total_minutes)}</span>
    </div>
  )
}

export default TimesheetRow