import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Popover, Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import typeOptions from "../../../utils/typeOptions";
import useLocate from "../../../components/LocatesAndPermits/Locates/hooks/useLocate";

const Types = ({ data }) => {
  const { locates, setLocates } = useLocate();
  const [selectedType, setSelectedType] = useState();

  useEffect(() => {
    if (selectedType) {
      setLocates(
        locates.map((l) => {
          if (data.id === l.id) {
            return { ...l, type: selectedType };
          }
          return l;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType]);

  return (
    <Popover id="popover-basic" className={styles.popoverWrapper}>
      <Popover.Body className="me-4 pt-0 pb-1">
        <div>
          {typeOptions.map((option) => (
            <div className="d-flex flex-row align-items-center" key={option.value}>
              <Button size="med" variant="link" onClick={() => setSelectedType(option.value)}>
                {option.label}
              </Button>
            </div>
          ))}
        </div>
      </Popover.Body>
    </Popover>
  );
};

Types.propTypes = {
  data: PropTypes.object,
};

export default Types;
