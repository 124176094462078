import { useEffect, useState } from "react";
import useProject from "../../../../_projects/hooks/useProject";
import { useApi, submittalsApi, useFormControl } from "component-library";
import { schema } from "../../../../schema/submittal";

const useQuickAddSubmittal = ({ submittal, addSubmittal, setIsEdit, isEdit, projectOwner, setPoError }) => {
  const { project, setProject } = useProject();

  const [source, setSource] = useState(isEdit ? submittal.source : undefined);
  const [addNew, setAddNew] = useState();
  const [sourceError, setSourceError] = useState(false);
  const operation = isEdit ? submittalsApi.updateSubmittal : submittalsApi.createSubmittal;
  const { loading, data, request } = useApi(operation);
  const onSubmit = async (formData, setLoading) => {
    if (!projectOwner) {
      setPoError(true);
      setLoading(false);
      return Promise.reject("Project Engineer is Required");
    }
    if (!source) {
      setSourceError(true);
      setLoading(false);
      return Promise.reject("Source is Required");
    }
    const dataWithPO = {
      ...formData,
      project_owner_id: projectOwner,
      source_id: source.id,
    };
    delete dataWithPO.project_owner;
    delete dataWithPO.source;
    await request(dataWithPO);
  };

  const {
    handleSubmit,
    renderTextField,
    renderSelectField,
    data: fieldData,
    setFields,
  } = useFormControl({
    schema,
    initialData: submittal,
    onSubmit,
  });

  useEffect(() => {
    if (data && data.submittal) {
      addSubmittal(data.submittal);
      if (project && setProject) {
        const existing = project.submittals.find((s) => s.id === data.submittal.id);

        if (existing) {
          setProject({
            ...project,
            submittals: project.submittals.map((sub) => (sub.id === data.submittal.id ? data.submittal : sub)),
          });
        } else {
          setProject({
            ...project,
            submittals: [data.submittal, ...project.submittals],
          });
        }
      }

      if (isEdit && setIsEdit) setIsEdit(false);
      setSource();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!!project && project.ais) {
      if (fieldData && !fieldData.requirements.includes(1)) {
        setFields({
          ...fieldData,
          requirements: [...fieldData.requirements, 1],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, fieldData]);

  return {
    fieldData,
    handleSubmit,
    renderTextField,
    setSource,
    source,
    setAddNew,
    sourceError,
    renderSelectField,
    loading,
    addNew,
  };
};

export default useQuickAddSubmittal;
