import React from "react";
import { XCircle } from "react-feather";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";

const PerDiem = ({ timesheet, setTimesheet, editing }) => {
  const removePerDiem = () => {
    setTimesheet({
      ...timesheet,
      perdiem: 0,
    });
  };
  return (
    <>
      {!!timesheet?.perdiem > 0 && (
        <div className="row my-3">
          <div className={`${styles.perDiem}`}>
            <div className={styles.perDiemLabel}>
              <span className={styles.value}>${timesheet?.perdiem}</span>
              {`Per Diem Assigned`}
            </div>
            {editing && (
              <Button variant="transparent" size="sm" onClick={removePerDiem} className="p-0">
                <XCircle color="#0071CE" size={20} />
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PerDiem;
