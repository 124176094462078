import Description from "../columnRenders/Description";

const description = {
  headerName: "Description",
  field: "description",
  flex: 1,
  maxWidth: 600,
  cellRenderer: Description,
};

export default description;
