import convertDecimalHours from "../../../utils/convertDecimalHours";

const unallocated = {
  headerName: "Unallocated",
  field: "unallocated",
  width: 140,
  cellStyle: {
    color: "#E70000",
  },
  valueGetter: ({ data }) => (!!data?.unallocated ? convertDecimalHours(data.unallocated) : ""),
};

export default unallocated;
