import React from "react";
import PurchaseOrder from "./PurchaseOrder";
import ProvidePurchaseOrder from "./context/ProvidePurchaseOrder";

const PurchaseOrderPage = () => {
  return (
    <ProvidePurchaseOrder>
      <PurchaseOrder />
    </ProvidePurchaseOrder>
  );
};

export default PurchaseOrderPage;
