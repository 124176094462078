import { useEffect, useState } from "react";
import useProject from "../../hooks/useProject";
import { useApi, purchaseOrdersApi } from "component-library";

const usePurchaseOrders = () => {
  const { project } = useProject();
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [filteredPOs, setFilteredPOs] = useState([]);

  const { data, loading, error, request } = useApi(
    purchaseOrdersApi.getPurchaseOrders
  );

  const filterPOs = (q) => {
    setFilteredPOs(
      purchaseOrders.filter(
        (po) =>
          (!!po.supplier && po.supplier.toLowerCase().indexOf(q.toLowerCase()) >
            -1) || po.id.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  };

  useEffect(() => {
    if (project && project.id) request(project.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (!!data && data.purchase_orders) setPurchaseOrders(data.purchase_orders);
  }, [data]);

  useEffect(() => {
    setFilteredPOs(purchaseOrders);
  }, [purchaseOrders]);

  return {
    purchaseOrders,
    loading,
    error,
    filterPOs,
    filteredPOs,
  };
};

export default usePurchaseOrders;
