import Address from "../companiesAdminColumns/Address";

const address1 = {
  headerName: "Address",
  field: "address1",
  cellRenderer: Address,
  flex: 1,
  resizable: true,
};

export default address1;
