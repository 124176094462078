export const toggleItem = (item, selectedItems, setSelectedItems) => {
  let storedItem = {
    type: Object.keys(item).includes("photo_count") ? "album" : "photo",
    id: item.id,
    pinned: item.pinned,
  };
  if (item.first_photo) {
    storedItem.photo_count = item.photo_count;
  }

  if (selectedItems.some((selectedItem) => selectedItem.id === item.id && selectedItem.type === storedItem.type)) {
    setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== storedItem.id));
  } else {
    setSelectedItems([...selectedItems, storedItem]);
  }
};
