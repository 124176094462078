/* global google */
import React from "react";
import { GoogleMap, DrawingManager, InfoWindow } from "@react-google-maps/api";
import MapSettingsCheck from "../../../components/MapSettingsCheck";
import { mapStyles } from "../../../utils/mapStyles";
import useGeofence from "../../../hooks/useGeofence";
import ProjectGeoFenceModal from "../../../components/ProjectGeofence/ProjectGeoFenceModal";
import SiteGeoMeta from "../../../components/ProjectGeofence/SiteGeoMeta";
import { Alert } from "react-bootstrap";

const containerStyle = {
  width: "100%",
  height: "40rem",
};

const POIGeofence = ({ poi }) => {
  const {
    polygons,
    onLoadMap,
    selectedPolygon,
    setSelectedPolygon,
    isLoaded,
    polyOptions,
    onPolygonComplete,
    savePolygonComplete,
    newRegionName,
    setNewRegionName,
    setSaveModal,
    saveModal,
    onLoadDrawingManager,
    onCancelPolygon,
    getSelectedCenter,
    setPolygons,
    focusPolygon,
    geofencePOIRequest,
    geofencePOIData,
    geofencePOILoading,
    geofencePOIError,
  } = useGeofence({ poi });

  return (
    <>
      <>
        {!!isLoaded && (
          <MapSettingsCheck>
            <GoogleMap
              center={{
                lat: 42.1088287,
                lng: -91.3095023,
              }}
              zoom={10}
              onLoad={onLoadMap}
              options={{
                styles: mapStyles,
                fullscreenControl: false,
              }}
              mapContainerStyle={containerStyle}
              onClick={() => {
                setSelectedPolygon();
              }}
            >
              {!!poi?.active && (
                <>
                  <DrawingManager
                    onLoad={onLoadDrawingManager}
                    onPolygonComplete={onPolygonComplete}
                    options={{
                      drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                      },
                      polygonOptions: {
                        ...polyOptions,
                        fillColor: "#0071ce",
                      },
                    }}
                  />
                  {!!selectedPolygon && !!selectedPolygon.polygon && !!selectedPolygon.name && (
                    <InfoWindow
                      onCloseClick={() => {
                        setSelectedPolygon({});
                      }}
                      position={getSelectedCenter()}
                    >
                      <div className="px-2 font-weight-bold">{selectedPolygon.name}</div>
                    </InfoWindow>
                  )}
                </>
              )}
            </GoogleMap>
          </MapSettingsCheck>
        )}
      </>
      {!!saveModal && (
        <ProjectGeoFenceModal
          saveModal={saveModal}
          setSaveModal={setSaveModal}
          setNewRegionName={setNewRegionName}
          newRegionName={newRegionName}
          savePolygonComplete={savePolygonComplete}
          polygons={polygons}
          onCancelPolygon={onCancelPolygon}
        />
      )}
      <div className="section-header d-flex flex-column justify-content-between pt-3">
        <>
          {!!poi?.active ? (
            <>
              <div className="page-title font-weight-bold">Geofence Zones</div>
              {!!polygons?.length ? (
                <div>
                  {!!geofencePOIError && <Alert variant="danger">{geofencePOIError}</Alert>}
                  <div className="lg font-weight-bold mb-2 mt-2">POI Sites:</div>
                  <SiteGeoMeta
                    setPolygons={setPolygons}
                    poi={poi}
                    polygons={polygons}
                    request={geofencePOIRequest}
                    loading={geofencePOILoading}
                    data={geofencePOIData}
                    focusPolygon={focusPolygon}
                  />
                </div>
              ) : (
                <Alert variant="success">Click on the polygon icon at the top of the map to add geofences.</Alert>
              )}
            </>
          ) : (
            <Alert variant="success" dismissible>
              Unarchive this point of interest to edit or add geofences.
            </Alert>
          )}
        </>
      </div>
    </>
  );
};

export default POIGeofence;
