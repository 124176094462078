import React, { useEffect, useState } from "react";
import { useApi, projectApi } from "component-library";
import useProject from "../../../hooks/useProject";
import Dropzone from "react-dropzone";

const Upload = ({ setReplacing }) => {
  const [file, setFile] = useState();
  const [overwriteValue, setOverwriteValue] = useState(0);
  const { project, setProject } = useProject();
  const { data, error, loading, request } = useApi(projectApi.uploadUPB);

  const handleSingleFileUpload = (acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        let newFile = {
          data: e.target.result,
          name: file.path,
          size: file.size,
          type: file.type,
        };
        setFile(newFile);
      };
      reader.readAsDataURL(file);
      return file;
    });
  };

  useEffect(() => {
    if (file) {
      const overwrite = overwriteValue ? 1 : 0;
      request({
        project_id: project.id,
        file,
        overwrite,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if (data) {
      setProject({
        ...project,
        upb_import_id: data.uuid,
        upb_import_status: 0,
      });
      setReplacing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="d-flex justify-content-end flex-column">
      {!!error && <span className="error me-2">{error}</span>}
      <Dropzone onDrop={(acceptedFiles) => handleSingleFileUpload(acceptedFiles)} accept=".csv">
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              className: "btn btn-tertiary med",
            })}
          >
            <input {...getInputProps()} />
            {<span>{loading ? "Loading..." : "Upload File"}</span>}
          </div>
        )}
      </Dropzone>

      <div
        className={`d-flex align-items-center justify-content-end form-group form-check form-check-small mb-0 mt-1 ms-2`}
      >
        <input
          type="checkbox"
          className="form-check-input med"
          name="overwrite"
          id="overwrite"
          onChange={() => setOverwriteValue(!overwriteValue)}
          value={overwriteValue}
          checked={overwriteValue}
        />
        <label className="form-check-label med" htmlFor="overwrite">
          Overwrite
        </label>
      </div>
    </div>
  );
};

export default Upload;
