import React from "react";
import styles from "./styles.module.scss";

const Name = ({ data }) => {
  return (
    <div className="d-flex">
      <div className={styles.bidItemTitle}>{data.bid_item_name}</div>
      <div className={styles.bidItemTasks}>{data.tasks.length ? data.tasks.length : 0} tasks</div>
    </div>
  );
};

export default Name;
