/**
 * Used in the report summary screens. Gets the combined length of data points and checks to see if their length exceeds a limit.
 * @param  {Array} params An array of data points
 * @param  {Number} limit limit for the stlying change
 * @return {Boolean} true if the combined length of the params exceeds the limit
 */

const checkReportFS = (params, limit) => {
  const parseAndConvert = (dataStr) => {
    return parseFloat(dataStr).toFixed(2).toString();
  };

  let combinedLength = 0;

  params.forEach((param) => {
    if (param !== null) {
      const parsed = parseAndConvert(param);
      combinedLength = combinedLength + parsed.length;
    }
  });

  if (combinedLength > limit) {
    return true;
  }
};

export default checkReportFS;
