import { useEffect } from "react";
import { useApi, projectApi } from "component-library";
import { useNavigate, useParams } from "react-router-dom";
import useProjectDocuments from "../../context/useProjectDocuments";

const useDeleteFolder = () => {
  const { displayMessage, attachmentsRequest } = useProjectDocuments();
  const { id, folderName } = useParams();
  const history = useNavigate();

  const {
    request: deleteFolderRequest,
    data: deleteFolderData,
    loading: deleteFolderLoading,
    error: deleteFolderError,
  } = useApi(projectApi.updateAttachmentFolderInfo);

  const handleDeleteFolder = (name) => {
    deleteFolderRequest({ id: id, new_folder: "", existing_folder: name });
  };

  useEffect(() => {
    if (!!deleteFolderData?.success) {
      displayMessage({
        title: "Folder Deleted",
        content: `Successfully removed folder: ${folderName}`,
        id: "folder-deleted",
        variant: "success",
      });
      attachmentsRequest(id);
      history(`/project/${id}/documents`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteFolderData]);

  return {
    handleDeleteFolder,
    deleteFolderLoading,
    deleteFolderError,
  };
};

export default useDeleteFolder;
