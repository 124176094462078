import { useState, useEffect } from "react";
import { geofenceApi, useApi } from "component-library";

const useAllPOIs = () => {
  const [initialLoad, setInitialLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [total, setTotal] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [gridData, setGridData] = useState();
  const { request, data, loading, error } = useApi(geofenceApi.getPOIs);

  const itemsPerPage = 15;

  const statusOptions = [
    { label: "Active", value: "on" },
    { label: "Inactive", value: "off" },
  ];

  useEffect(() => {
    if (!initialLoad) {
      if (page !== 1) {
        setPage(1);
      } else {
        request(
          `items_per_page=${itemsPerPage}&page=${1}${q && `&q=${q}`}${statusFilter && `&active=${statusFilter}`}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, statusFilter]);

  useEffect(() => {
    request(`items_per_page=${itemsPerPage}&page=${page}${q && `&q=${q}`}${statusFilter && `&active=${statusFilter}`}`);
    setInitialLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (!!data?.pois?.data) {
      setGridData(data.pois.data);
      setTotal(data.pois.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    gridData,
    loading,
    error,
    page,
    setPage,
    total,
    q,
    setQ,
    statusFilter,
    setStatusFilter,
    statusOptions,
    itemsPerPage,
  };
};

export default useAllPOIs;
