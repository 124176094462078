import React, { useState, useEffect } from "react";
import Company from "../Company";
import InteriorPage from "../pageLayout/InteriorPage";
import { Link } from "react-router-dom";
import { Edit3, XCircle, ChevronLeft, Phone } from "react-feather";
import { Button } from "react-bootstrap";
import { useApi, useAuth } from "component-library";
import companies from "../../api/companies";
import UnauthorizedCard from "../UnauthorizedCard";

const CompanyPage = () => {
  const { userCan } = useAuth();
  const [editing, setEditing] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [company, setCompany] = useState();
  const { request, data } = useApi(companies.restoreCompany);

  const backButton = () => (
    <Link to="/directory" className="sm-med d-flex align-items-center">
      <ChevronLeft /> Back to Contacts
    </Link>
  );

  useEffect(() => {
    if (!!data) {
      setCompany({ ...company, archived: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const contactActions = () => (
    <div className="row mt-0">
      <div className="col mt-0 d-flex justify-content-between align-items-center">
        {userCan("contact-edit") && (
          <div className="d-flex justify-content-end">
            {!!company && !company.archived ? (
              <>
                <Button variant="link" className="sm-med d-flex align-items-center" onClick={() => setArchiving(true)}>
                  <XCircle size={14} className="me-1" />
                  Archive
                </Button>
                <Button
                  variant="link"
                  className="sm-med pe-0 d-flex align-items-center "
                  onClick={() => setEditing(true)}
                >
                  <Edit3 size={14} className="me-1" />
                  Edit
                </Button>
              </>
            ) : (
              <Button variant="link" className="sm-med d-flex align-items-center" onClick={() => request(company.id)}>
                <XCircle size={14} className="me-1" />
                Restore Company
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <InteriorPage
      navTitle="Directory"
      pageTitlePrimaryContent={backButton}
      pageTitleSecondaryContent={!!company && contactActions}
      navIcon={<Phone color="#fff" size={14} />}
    >
      {userCan("contact-list") ? (
        <Company
          company={company}
          setCompany={setCompany}
          editing={editing}
          setEditing={setEditing}
          archiving={archiving}
          setArchiving={setArchiving}
        />
      ) : (
        <UnauthorizedCard />
      )}
    </InteriorPage>
  );
};

export default CompanyPage;
