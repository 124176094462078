import React from "react";
import { Pie, PieChart, Cell } from "recharts";
import styles from "./styles.module.scss";
import useDashboard from "./useDashboard";
import ArrowLink from "../components/ArrowLink/ArrowLink";

const COLORS = ["#0E2B56", "#FF6700", "#7DDB2D"];

const JobStatusChart = ({ className }) => {
  const {
    aggregateStats: { project_status },
  } = useDashboard();
  if (!project_status) return null;

  const chartData = [
    { name: "On Schedule", value: parseFloat(project_status.even) },
    { name: "Behind", value: parseFloat(project_status.over) },
    { name: "Ahead", value: parseFloat(project_status.under) },
  ];

  return (
    <div className={className}>
      <div className="d-flex justify-content-between">
        <h2 className={styles.header}>BPR</h2>
      </div>
      {!!chartData && (
        <figure
          className={`
            ${styles.chart}
          `}
        >
          <div className="d-flex align-items-center px-4 justify-content-start">
            <PieChart width={150} height={150} className="me-3">
              <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={40}
                outerRadius={70}
                fill="#82ca9d"
                labelLine={false}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
            <div className={`${styles.total} me-3`}>
              <div className={styles["total-count"]}>{project_status.total_jobs}</div>
              <div className={styles["total-label"]}>Active Projects</div>
            </div>
            <figcaption className="ms-3">
              <dl className="mb-0">
                <div>
                  <dd className={styles.legendDD} style={{ color: "#0E2B56" }}>
                    {project_status.even}
                  </dd>
                  <dt className={styles.legendDT}>Even</dt>
                </div>
                <div>
                  <dd className={styles.legendDD} style={{ color: "#FF6700" }}>
                    {project_status.over}
                  </dd>
                  <dt className={styles.legendDT}>Over</dt>
                </div>
                <div>
                  <dd className={styles.legendDD} style={{ color: "#7DDB2D" }}>
                    {project_status.under}
                  </dd>
                  <dt className={styles.legendDT}>Under</dt>
                </div>
              </dl>
            </figcaption>
          </div>
        </figure>
      )}
      <ArrowLink url="/dashboard/status" label="view BPR by job" />
    </div>
  );
};

export default JobStatusChart;
