import getFloatFromCurrency from "../../utils/getFloatFromCurrency";

const useFormatBidData = () => {
  const formatBidData = (formData) => ({
    ...formData,
    milestones: formData.milestones,
    dot_categories: formData.dot_categories.map((item) =>
      !!item.dot_category ? item.dot_category.id : item
    ),
    estimated_contract_amount: !!formData.estimated_contract_amount
      ? getFloatFromCurrency(formData.estimated_contract_amount)
      : 0,
    estimated_contract_amount_high: !!formData.estimated_contract_amount_high
      ? getFloatFromCurrency(formData.estimated_contract_amount_high)
      : 0,
    idot_proposal_guaranty_amount: !!formData.idot_proposal_guaranty_amount
      ? getFloatFromCurrency(formData.idot_proposal_guaranty_amount)
      : 0,
  });

  return { formatBidData };
};

export default useFormatBidData;
