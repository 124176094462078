import React from "react";
import useProvidePurchaseOrder from "./useProvidePurchaseOrder";
import purchaseOrderContext from "./context";

function ProvidePurchaseOrder({ children }) {
  const purchaseOrder = useProvidePurchaseOrder();
  return <purchaseOrderContext.Provider value={purchaseOrder}>{children}</purchaseOrderContext.Provider>;
}

export default ProvidePurchaseOrder;
