import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Controls = ({quote, addLineItems, newLineItems}) => {
  return (
    <div className="controls">
      <Link
        className={`btn btn-gray5 text-white font-weight-bold me-3`}
        to={
          quote.status !== 2
            ? `/project/${quote.project_id}/quotes/${quote.id}/edit`
            : `/project/${quote.project_id}/quotes/${quote.id}`
        }
      >
        Cancel Add Items
      </Link>
      <Button
        className={`btn ${!!newLineItems && !!newLineItems.length ? "btn-tertiary" : "btn-gray3"} font-weight-bold`}
        onClick={() => {
          if (!!newLineItems.length) addLineItems();
        }}
      >
        Add {!!newLineItems && newLineItems.length > 0 && newLineItems.length} Items
      </Button>
    </div>
  );
};

Controls.propTypes = {
  quote: PropTypes.object,
  addLineItems: PropTypes.func,
  newLineItems: PropTypes.array,
};

export default Controls;
