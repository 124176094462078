import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const Stat = ({ title, subTitle, color, symbol, value, subValue, size }) => {
  return (
    <div className={styles.statBlock}>
      <h3 className={styles.title}>{title}</h3>
      <div style={{ color: color }} className={`${styles.value} ${value > 99 || value < -99 ? styles.small : ""}`}>
        <div className="d-flex align-items-center">
          {!!value ? (
            <span>
              {Math.round(value)}
              {!!symbol && <span className={styles.symbol}>{symbol}</span>}
            </span>
          ) : (
            0
          )}
          {!!subValue && (
            <div className={styles.subValue}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <span className={styles.val}>{subValue}</span>
                    </td>
                    <td>
                      <span className={styles.secondary}>Est</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className={styles.val}>{parseInt(value) - parseInt(subValue)}</span>
                    </td>
                    <td>
                      <span className={styles.secondary}>Diff</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
        {!!subTitle && <span className={styles.subTitle}>{subTitle}</span>}
      </div>
    </div>
  );
};

Stat.propTypes = {
  item: PropTypes.object,
};

export default Stat;
