import UpdatedDate from "../columns/UpdatedDate";

const updatedDate = {
  headerName: "",
  field: "updated_status_date",
  cellRenderer: UpdatedDate,
  cellStyle: { justifyContent: "flex-end" },
};

export default updatedDate;
