const crewLogWidths = {
  first_name: "0rem",
  last_name: "18rem",
  phone: "10rem",
  hours: "7rem",
  alerts: "12rem",
  expand: "0rem",
};

export default crewLogWidths;
