import {
  date,
  certified_payroll,
  foreman_id,
  job_number,
  project_managers,
  project_name,
  status,
  toggler,
  expand,
  hours,
  note_count,
  alerts,
  log_link,
} from "./columnDefs";

const columnDefs = [
  toggler,
  date,
  certified_payroll,
  job_number,
  project_name,
  foreman_id,
  project_managers,
  hours,
  alerts,
  status,
  note_count,
  log_link,
  expand,
];

export default columnDefs;
