import React from "react";
import styles from "./styles.module.scss";
import Module from "./Module";

const ActiveSections = ({ handleToggle, modules }) => {
  return (
    <div>
      {!!modules?.length &&
        modules?.map((opt) => (
          <div key={`${opt.label} ${opt.id}`} className={styles.wrapper}>
            <Module module={opt} handleToggle={handleToggle} />
          </div>
        ))}
    </div>
  );
};

export default ActiveSections;
