import React from "react";
import Pinned from "./Pinned";
import { BeatLoader } from "react-spinners";
import { Trash2 } from "react-feather";
import styles from "../styles.module.scss";
import { useAuth } from "component-library";

const PinAndDelete = ({ loading, isPinned, handlePin, setShowConfirmModal, selectMode }) => {
  const { userCan } = useAuth();

  return (
    <>
      {!!loading ? (
        <div className="d-flex justify-content-center">
          <BeatLoader color="#0017CE" />
        </div>
      ) : (
        <div className={`${styles.pinDeleteWrap}`}>
          {!selectMode && (
            <>
              <div className={`${styles.rowItemSmall}`}>
                <Pinned isPinned={isPinned} onClick={handlePin} />
              </div>
              {userCan("photo-library-delete") && (
                <div className="btn btn-link">
                  <Trash2 color="#A7A7A7" size="16" onClick={() => setShowConfirmModal(true)} />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default PinAndDelete;
