import React, { useEffect, useState } from "react";
import { useFormControl, submittalsApi, Submittal as S } from "component-library";
import { schema, initialData } from "../../schema/submittal";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { useApi, useAuth } from "component-library";
import Contacts from "../../components/Contacts/Contacts";
import EditContacts from "../../components/Contacts/EditContacts";
import useProject from "./../../_projects/hooks/useProject";

const useAddSubmittal = (submittalData = initialData) => {
  const { config, userCan, user } = useAuth();
  const [attachmentError, setAttachmentError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [projectOwners, setProjectOwners] = useState();
  const [projectOwner, setProjectOwner] = useState();
  const [source, setSource] = useState();
  const operation = submittalData.id ? submittalsApi.adminUpdateSubmittal : submittalsApi.createSubmittal;
  const { loading, error, request, data: apiData } = useApi(operation);
  const { project, setProject } = useProject();
  const [sourceError, setSourceError] = useState();
  const [projectOwnerError, setProjectOwnerError] = useState();
  const [attachmentsLoading, setAttachmentsLoading] = useState();

  const updateContacts = ({ contacts }) => {
    setFields({ ...data, contacts });
  };

  const updateAttachments = ({ attachments }) => {
    setFields({ ...data, attachments });
  };

  const setRequirements = (requirements) => {
    setFields({ ...data, requirements });
  };

  let history = useNavigate();

  const onSubmit = async (formData, setLoading) => {
    if (formData.source_id && formData.project_owner_id) {
      const contacts = formData.contacts.map((contact) => ({
        contact_role: contact.role,
        id: contact.contact.id,
      }));

      const subToSubmit = { ...formData, contacts };
      try {
        await request(subToSubmit);
      } catch (message) {
        console.error(message);
        setLoading(false);
      }
    } else {
      if (!formData.source_id) setSourceError("Contact Source is required");
      if (!formData.project_owner_id) setProjectOwnerError("Contact Project Engineer is required");
      setLoading(false);
      return new Promise((resolve, reject) => reject(new Error("Contact Validation Error")));
    }
  };

  useEffect(() => {
    if (apiData && apiData.submittal) {
      if (project && setProject) {
        if (submittalData.id) {
          setProject({
            ...project,
            submittals: project.submittals.map((sub) => (sub.id === apiData.submittal.id ? apiData.submittal : sub)),
          });
        } else {
          setProject({
            ...project,
            submittals: [apiData.submittal, ...project.submittals],
          });
        }
      }
      history(`/project/${apiData.submittal.project_id}/submittals/${apiData.submittal.id}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData]);

  const {
    handleSubmit,
    renderSubmitButton,
    renderTextField,
    renderTextareaField,
    renderCheckboxInGroup,
    data,
    setFields,
  } = useFormControl({
    schema,
    onSubmit,
    initialData: submittalData,
  });

  useEffect(() => {
    if (project?.project_contacts) {
      setProjectOwners(project.project_contacts.filter((contact) => contact.role.name === "Engineer"));
    }
  }, [project]);

  useEffect(() => {
    setSourceError();
  }, [source]);

  useEffect(() => {
    if (projectOwners && data.project_owner_id) {
      const selectedOwner = projectOwners.find((x) => x.contact.id === data.project_owner_id);
      if (!!selectedOwner && !!selectedOwner.contact) {
        setProjectOwner(selectedOwner.contact);
      }
    }
  }, [data, projectOwners]);

  useEffect(() => {
    if (data && data.source) setSource(data.source);
    if (data && data.source_id) setSourceError();
    if (data && data.project_owner_id) setProjectOwnerError();
  }, [data]);

  useEffect(() => {
    if (!!project && project.ais) {
      if (data && !data.requirements.includes(1)) {
        setRequirements([...data.requirements, 1]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, data]);

  const updateSource = (newSource, status) => {
    if (status === "edit") {
      if (newSource?.id) {
        setFields({ ...data, source_id: newSource.id, source: newSource });
      } else {
        setFields({
          ...data,
          source_id: undefined,
          source: undefined,
        });
      }
    }

    if (status === "add") {
      if (newSource?.id) {
        data.source_id = newSource.id;
        data.source = newSource;
        setFields({ ...data, source_id: newSource.id, source: newSource });
      } else {
        setFields({
          ...data,
          source_id: undefined,
          source: undefined,
        });
      }
    }
    setSource(source);
  };

  const removeContact = (subContact, role) => {
    if (role.toLowerCase() === "source") {
      setFields({
        ...data,
        source_id: undefined,
        source: undefined,
      });
      setSource();
    } else {
      const contactsCopy = data.contacts.filter((c) => c.contact.id !== subContact.id);
      setFields({ ...data, contacts: contactsCopy });
    }
  };

  const fields = (
    <>
      <div className="row">
        <div className="col-lg-12">
          {renderTextField({
            name: "name",
            label: "Title",
            autoFocus: true,
          })}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {renderTextareaField({
            name: "description",
            label: "Description",
            subLabel: "A note describing the submittal",
            className: "p-2 w-100 sm-med form-control",
          })}
        </div>
      </div>

      <h3 className="lg mt-4 mb-3">Spec Book and Plans</h3>
      <div className="row">
        <div className="col-lg-12 plans">
          {renderTextField({
            name: "spec_section",
            label: "Section of Spec Book",
            subLabel: "Please note the  section of the spec book where submittal information can be found",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 plans">
          {renderTextField({
            name: "plans_section",
            label: "Plan Sheet",
            subLabel: "Please note the plan sheet where submittal information can be found",
          })}
        </div>
      </div>

      <h4 className="lg mt-5 mb-3">Contacts</h4>
      <div className="row">
        <div className="col">
          {sourceError && <Alert variant="danger">{sourceError}</Alert>}
          {projectOwnerError && <Alert variant="danger">{projectOwnerError}</Alert>}
          {submittalData.id ? (
            <EditContacts
              projectOwners={projectOwners}
              projectOwner={projectOwner}
              contacts={data.contacts}
              updateContent={updateContacts}
              submittal={submittalData}
              editing={true}
              source={source}
              updateProjectOwner={(ownerId) => {
                setFields({ ...data, project_owner_id: ownerId });
              }}
              updateSource={(source) => {
                if (source && source.id) {
                  data.source_id = source.id;
                  data.source = source;
                  setFields({ ...data, source_id: source.id, source: source });
                } else {
                  setFields({
                    ...data,
                    source_id: undefined,
                    source: undefined,
                  });
                }
                setSource(source);
              }}
            />
          ) : (
            <Contacts
              contacts={data.contacts}
              projectOwner={projectOwner}
              projectOwners={projectOwners}
              source={source}
              addContact={(newContact) => {
                setFields({ ...data, contacts: [...data.contacts, newContact] });
              }}
              removeContact={(subContact, role) => {
                removeContact(subContact, role);
              }}
              updateProjectOwner={(ownerId) => {
                setFields({ ...data, project_owner_id: ownerId });
              }}
              updateSource={(source) => {
                updateSource(source, "add");
              }}
              error={contactError}
              setError={setContactError}
              editing={true}
            />
          )}
        </div>
      </div>
      <h4 className="lg mt-5 mb-3" id="requirements">
        Requirements
      </h4>
      <div className="row">
        <div className="col checkbox-columns">
          {config.submittal_requirements.map((requirement) => (
            <div key={requirement.id}>
              {renderCheckboxInGroup({
                groupName: "requirements[]",
                name: requirement.id,
                label: requirement.name,
                setValuesArray: setRequirements,
                valuesArray: data.requirements,
              })}
            </div>
          ))}
        </div>
      </div>
      <h4 className="lg mt-5 mb-3">Attachments</h4>
      <p className="med light-text">Add any additional attachments here</p>
      <div className="row">
        <div className="col">
          {submittalData.id ? (
            <S.EditAttachments
              updateContent={updateAttachments}
              submittal={data}
              multiType={true}
              config={config}
              userCan={userCan}
              loading={attachmentsLoading}
              setLoading={setAttachmentsLoading}
              userId={user.id}
            />
          ) : (
            <S.Attachments
              submittal={data}
              config={config}
              userCan={userCan}
              attachments={data.attachments}
              multiType={true}
              addAttachment={(file, formData) => {
                const attachmentsUpdated = [...data.attachments];
                attachmentsUpdated.push({
                  file_type: formData.file_type,
                  name: file.name,
                  data: file.src,
                  size: file.size,
                  type: file.type,
                });
                setFields({ ...data, attachments: attachmentsUpdated });
                return { success: true };
              }}
              error={attachmentError}
              setError={setAttachmentError}
              removeAttachment={(subAttachment) => {
                const attachmentsUpdated = data.attachments.filter((a) => a.name !== subAttachment.name);
                setFields({ ...data, attachments: attachmentsUpdated });
              }}
            />
          )}
        </div>
      </div>
    </>
  );
  return {
    fields,
    handleSubmit,
    renderSubmitButton,
    loading,
    error,
    data,
    setFields,
  };
};

export default useAddSubmittal;
