import ExpandAll from "../columnRenders/ExpandAll";

const expand = {
  headerName: "",
  field: "expand",
  maxWidth: 50,
  minWidth: 50,
  cellRenderer: "agGroupCellRenderer",
  headerComponent: ExpandAll,
  pinned: "right",
};

export default expand;
