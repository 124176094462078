import { displayTime } from "component-library";
import dateTimeComparator from "../../../utils/dateTimeComparator";

const bid_time = {
  headerName: "Time",
  field: "bid_time",
  width: 124,
  comparator: dateTimeComparator,
  valueGetter: ({ data }) => data?.bid_time && displayTime(data.bid_time),
};

export default bid_time;
