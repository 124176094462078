import React from "react";
import styles from "./styles.module.scss";
import FieldLogHeaders from "../../PrintableGrids/rows/FieldLogs/FieldLogHeaders";
import PrintableRow from "./PrintableRow";

const PrintableListing = ({ data }) => {
  return (
    <div className={styles.container}>
      <FieldLogHeaders />
      {data.map((fl) => {
        return <PrintableRow fl={fl} key={`${fl.workday_id}-${fl.foreman_id}`} />;
      })}
    </div>
  );
};
export default PrintableListing;
