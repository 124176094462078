const categoryWidths = {
"AGGREGATES": 52,
"ASBESTOES": 41,
"ASPHALT": 35,
"BORING": 31,
"BRIDGE": 30,
"BUILDING SUPPLY": 70,
"CARPENTRY": 47,
"CASTINGS": 39,
"SEAL JOINTS": 50,
"CLEARING": 39,
"CMP": 18,
"CONCRETE": 42,
"CRANE": 27,
"DEWATERING": 52,
"DIAMOND GRINDING": 79,
"DIRECTIONAL DRILLING": 91,
"DOORS": 28,
"DUST CONTROL": 61,
"ELECTRICAL": 47,
"ELECTRICAL SUPPLY": 79,
"ENGINEERING": 54,
"EQUIPMENT":  47,
"EXCAVATING": 48,
"EXPLOSIVES": 48,
"FABRIC": 28,
"FENCE": 26,
"FLOORING": 41,
"FORMS": 28,
"GLASS": 25,
"GUARDRAIL": 45,
"HATCHES": 36,
"HVAC": 21,
"INSULATION": 48,
"LANDSCAPE": 47,
"LINER": 24,
"LINER MANHOLE": 64,
"LUMBER": 33,
"MASONRY": 39,
"METAL BUILDING": 66,
"MILLING": 33,
"MISC METAL": 47,
"PAINTING": 38,
"PAVING": 29,
"PILING/SHEETING": 70,
"PIPE": 18,
"PRECAST": 36,
"PUMPS": 28,
"READY MIX": 42,
"REBAR": 26,
"ROOFING": 37,
"SAWING": 32,
"SIGN": 19,
"SLUDGE": 32,
"SPRINKLERS": 49,
"STABILIZATION": 58,
"STEEL": 24,
"STEEL ERECTION": 66,
"STRIPPING": 43,
"SUBDRAIN": 41,
"SURVEY": 31,
"TESTING": 34,
"TRAFFIC CONTROL": 72,
"TRUCKING": 41,
"WELDING": 37,
"WELL": 22,
"WINDOWS": 40,
"WWTP": 25,
};

export default categoryWidths;