import { useState, useEffect } from "react";
import { mapStyles } from "../../utils/mapStyles";
import useGeofences from "../../hooks/useGeofences";

const useMap = ({ project }) => {
  const [geofenceLocations, setGeofenceLocations] = useState();
  const containerStyle = {
    width: "100%",
    height: "27.5rem",
  };
  const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    draggableCursor: false,
    draggingCursor: false,
    draggable: true,
    keyboardShortcuts: false,
    scrollWheel: false,
  };

  const { onLoadDrawingManager, polyOptions, onLoadMap, map, polygons } = useGeofences({
    geofenceLocations,
    showGeofences: true,
    selectableFences: false,
  });

  useEffect(() => {
    if (project?.geofence?.length) {
      setGeofenceLocations(project.geofence);
    }
  }, [project?.geofence]);

  return {
    containerStyle,
    options,
    onLoadMap,
    onLoadDrawingManager,
    polyOptions,
    geofenceLocations,
    map,
    polygons,
  };
};

export default useMap;
