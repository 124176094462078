import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ChevronDown, ChevronRight } from "react-feather";
import styles from "../../../TaskManagment/styles.module.scss";
import useEstimateConversion from "../../../useEstimateConversion";
import { useApi, bidApi } from "component-library";
import { BeatLoader } from "react-spinners";

const Task = ({
  material,
  task,
  indentation = 20,
  bgColor,
  parentOpen = false,
  takeoffs,
  setTakeoffs,
  setNewMaterialError,
}) => {
  const [open, setOpen] = useState();
  const [quantity, setQuantity] = useState(task.pm_quantity || 0);
  const { poCreated, canEdit } = useEstimateConversion();
  const {
    request: updateRequest,
    data: updateData,
    loading: updateLoading,
    error: updateError,
  } = useApi(bidApi.updateTakeoff);

  const handleUpdateTask = (field, value) => {
    if (parseInt(value) !== parseInt(task[field])) {
      const updatedTask = {
        ...task,
        [field]: value,
      };
      let updatedTakeoff = {
        id: material.id,
        tasks: material.tasks.map((t) =>
          t.id === task.id
            ? { id: updatedTask.id, pm_quantity: parseInt(updatedTask.pm_quantity) }
            : { id: t.id, pm_quantity: parseInt(t.pm_quantity) }
        ),
        new_tasks: [],
        delete_tasks: [],
      };
      updateRequest({
        ...updatedTakeoff,
        pm_quantity: updatedTakeoff.tasks.reduce((p, c) => p + (c.pm_quantity || 0), 0),
      });
    }
  };

  useEffect(() => {
    if (updateData) {
      setTakeoffs(takeoffs.map((takeoff) => (takeoff.id === material.id ? updateData.takeoffs[0] : takeoff)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    if (updateError) {
      setNewMaterialError(updateError);
    } else {
      setNewMaterialError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  return (
    <>
      <tr
        className={`
        position-relative
        ${bgColor === "white" ? styles.whiteBg : bgColor === "light" ? styles.lightBg : styles.darkBg}`}
      >
        <td className={`${styles.taskCostCode} p-0 text-end`}>
          {!!updateLoading && (
            <div className="loaderWrapper">
              <BeatLoader color={"#0071ce"} size={10} />
            </div>
          )}
          <div className={`w-100 text-gray3 font-italic sm ${styles.materialHasBorder}`}>
            <div className="text-end w-100">{task.cost_code || ""}</div>
          </div>
        </td>
        <td className={!!parentOpen || !!open ? styles.subBorderLeftActive : ""} style={{ paddingLeft: indentation }}>
          <div className="d-flex align-items-center w-75">
            {!!task.tasks && !!task.tasks.length ? (
              <div>
                {!!open ? (
                  <ChevronDown color={"#7b7b7b"} size={15} onClick={() => setOpen(!open)} className="me-1" />
                ) : (
                  <ChevronRight color={"#7b7b7b"} size={15} onClick={() => setOpen(!open)} className="me-1" />
                )}
              </div>
            ) : (
              <span className="me-3">&bull;</span>
            )}
            <span className={styles.taskName}>{task.name}</span>
          </div>
        </td>
        <td>
          <div className="form-group mb-0 d-flex align-items-center justify-content-end">
            {task.bid_quantity}
            <div className="ms-1">{task.unit_measure}</div>
          </div>
        </td>
        <td>
          <div className="form-group mb-0 d-flex align-items-center justify-content-start">
            <input
              name={`${task.id}-${material.id}`}
              id={`${task.id}-${material.id}`}
              value={quantity}
              onChange={(e) => {
                setQuantity(e.target.value);
              }}
              onBlur={() => {
                handleUpdateTask("pm_quantity", quantity);
              }}
              type="number"
              className="form-control w-50"
              placeholder="PM Qty"
              disabled={!canEdit || !!poCreated}
            />
            <div className="ms-1">{task.unit_measure}</div>
          </div>
        </td>
        <td className="text-end ">
          <div>{`${parseInt(task.bid_quantity) - (parseInt(task.pm_quantity) || 0)} ${task.unit_measure || ""}`}</div>
        </td>
      </tr>
    </>
  );
};

Task.propTypes = {
  tasks: PropTypes.object,
  material: PropTypes.object,
  task: PropTypes.object,
  indentation: PropTypes.number,
  bgColor: PropTypes.string,
  parentOpen: PropTypes.bool,
  takeoffs: PropTypes.array,
  setTakeoffs: PropTypes.func,
  setNewMaterialError: PropTypes.func,
};

export default Task;
