import React from "react";
import useProvideSchedule from "./useProvideSchedule";
import context from "./context";

function ProvideSchedule({ children }) {
  const schedule = useProvideSchedule();
  return (
    <context.Provider value={schedule}>
      {children}
    </context.Provider>
  );
}

export default ProvideSchedule;
