import { displayTime } from "component-library";

const leave_time = {
  headerName: "Leave Time",
  field: "leave_time",
  width: 112,
  suppressMenu: true,
  headerClass: "ag-center-aligned-header",
  cellStyle: {justifyContent: "center"},
  valueGetter: ({ data }) => data?.leave_time && displayTime(data.leave_time),
};

export default leave_time;
