import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { initialData } from "../../schema/project";
import ProjectDates from "../project/Schedule/ScheduleView/ProjectDates";
import Milestones from "../../_bidManagement/createBid/Milestones";
import useSaveBidEdits from "../../_bidManagement/hooks/useSaveBidEdits";

const useEditSchedule = (setProject, refreshProject, bidObject, setIsEditing) => {
  const {
    error,
    handleSubmit,
    renderSubmitButton,
    renderTextField,
    renderFieldRowRepeater,
    data,
    completeData,
    loading,
    setFields,
  } = useSaveBidEdits({
    bidObject,
    isBid: false,
    redirect: `/project/${bidObject.id}/schedule`,
    setProject,
    refreshProject,
  });

  useEffect(() => {
    if (!!completeData) {
      setIsEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completeData]);

  const fields = (
    <div className="ms-n4">
      {error && <Alert variant="danger">{error}</Alert>}
      <ProjectDates renderTextField={renderTextField} data={data} setFields={setFields} />
      <Milestones
        renderFieldRowRepeater={renderFieldRowRepeater}
        scheduleMilestones={true}
        setFields={setFields}
        data={data}
      />
    </div>
  );

  return {
    initialData,
    fields,
    handleSubmit,
    renderSubmitButton,
    data,
    setFields,
    loading,
  };
};

export default useEditSchedule;
