import React from "react";
import ReportDayHeader from "./ReportDay/ReportDayHeader";
import ReportDayBody from "./ReportDay/ReportDayBody";
import NumberSection from "./NumberSection";
import FooterStats from "./FooterStats";
import styles from "./styles.module.scss";
import LocationControl from "../../../components/Locations/LocationControl";

const WeeklyReportReviewPageContent = ({ project, weeklyReport, weather, weatherLoading }) => {
  const reportDateString = new Date(weeklyReport?.report?.start_date).toLocaleDateString() + " - " + new Date(weeklyReport?.report?.end_date).toLocaleDateString();

  const contractorLocation = {
    company_name: weeklyReport?.report?.contractor.split(/\r?\n/)[0],
    phone: weeklyReport?.report?.contractor_phone,
    address: {
      address1: weeklyReport?.report?.contractor.split(/\r?\n/)[1],
      city: weeklyReport?.report?.contractor.split(/\r?\n/)[2].split(" ")[0],
      state: weeklyReport?.report?.contractor.split(/\r?\n/)[2].split(" ")[1],
      zip: weeklyReport?.report?.contractor.split(/\r?\n/)[2].split(" ")[2],
    },
  }

  return (
    <>
      <div className="row pb-2">
        <div className="col">
          <div className="showPrint">
            <h2 className="font-weight-bold">Report for <span className="font-weight-normal">{reportDateString}</span></h2>
          </div>
          <h2 className="semibold hidePrint">Weekly Report Review - {project?.name}</h2>
          <div className="hidePrint">
            <strong className="me-2">Report No:</strong> {weeklyReport?.report?.id}
          </div>
          <div className="showPrint">
            <span>Report Generated {new Date(weeklyReport?.date).toLocaleDateString()}</span>
          </div>
          <div className="hidePrint">
            <strong className="me-2">Date:</strong> {weeklyReport?.date}
          </div>
          <div className="hidePrint"><NumberSection weeklyReport={weeklyReport} /></div>
        </div>
        
        <div className="col-md-2 hidePrint">
          <span>Contractor</span>
          {!!weeklyReport?.report?.contractor && (            
            <LocationControl
              location={contractorLocation}
            />
          )}
        </div>

        <div className="col-md-3 showPrint">
          <span>Contractor</span>
          {!!weeklyReport?.report?.contractor && (            
            <LocationControl
              location={contractorLocation}
              showLogo={true}
            />
          )}
        </div>

        <div className="col-md-2 hidePrint">
          <div className="col-md-3">
            <span>Recipients</span>
            {!!project?.contract_owner && <LocationControl location={project?.contract_owner} />}
          </div>
        </div>
        
        <div className="col-md-3 showPrint">
          <div className="col-md-3">
            <span>Recipients</span>
            {!!project?.contract_owner && <LocationControl location={project?.contract_owner} />}
          </div>
        </div>

      </div>

      <div className="showPrint">
        <NumberSection weeklyReport={weeklyReport} />
      </div>

      {!!weeklyReport?.report?.rows?.length &&
        weeklyReport?.report?.rows?.map((row, index) => (
          <div key={index} className={styles.reportDay}>
            <ReportDayHeader item={row} weather={weather} weatherLoading={weatherLoading} />
            <ReportDayBody item={row} weather={weather} weatherLoading={weatherLoading} />
          </div>
        ))}
      <FooterStats weeklyReport={weeklyReport} />
    </>
  );
};

export default WeeklyReportReviewPageContent;
