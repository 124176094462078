import React from "react";
import { Dropdown } from "react-bootstrap";
import { MoreHorizontal } from "react-feather";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    ref={ref}
    onClick={(e) => {
      console.log("clicked");
      // e.preventDefault();
      onClick(e);
    }}
    style={{ border: 0, backgroundColor: "transparent" }}
  >
    {children}
  </button>
));

const TaskMenu = ({ item }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <MoreHorizontal color="#A7A7A7" size="16" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="1">Red</Dropdown.Item>
        <Dropdown.Item eventKey="2">Blue</Dropdown.Item>
        <Dropdown.Item eventKey="3" active>
          Orange
        </Dropdown.Item>
        <Dropdown.Item eventKey="1">Red-Orange</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TaskMenu;
