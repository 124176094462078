import { currency } from "component-library";

const estimated_contract_amount = {
  headerName: "Engineer's Estimate",
  field: "estimated_contract_amount",
  headerClass: "ag-center-aligned-header",
  wrapHeaderText: true,
  width: 148,
  cellStyle: {justifyContent: "center"},
  suppressMenu: true,
  valueGetter: ({data}) => {
    return data.estimated_contract_amount ?  currency(data.estimated_contract_amount) : null
  },
};

export default estimated_contract_amount;
