import { Joi } from "component-library";

const schema = {
  photos: Joi.array().required().label("Photos"),
};

const initialData = {
  photos: [],
};

export { schema, initialData };
