import { DateTime } from "luxon";
import React from "react";
import { X } from "react-feather";
import Select from "react-select";
import { StyledDatePicker, times, dropdownStyles, useAuth } from "component-library";
import PropTypes from "prop-types";
import styles from "./styles/BidTodos.module.scss";

const BidTodosForm = ({ data, renderCheckbox, setFields, errors, horizontal = false, singleColumn = false }) => {
  const { config } = useAuth();
  const todosForType = !!config.bid_todos
    ? config.bid_todos.filter((t) => t.type.indexOf(!!data.type && data.type.name) > -1)
    : [];

  const initialTodos = [...data?.bid_todos];

  const preBidMeeting = data.bid_todos.find((t) => t.bid_todo_id === 1) || {
    pre_bid_attendance_required: 0,
    pre_bid_time: "",
    pre_bid_location: "",
    pre_bid_date: "",
    bid_todo_id: 1,
  };

  const srfNumber = data.bid_todos.find((t) => t.bid_todo_id === 8) || {
    srf_number: "",
    bid_todo_id: 8,
  };

  const bidBond = data.bid_todos.find((t) => t.bid_todo_id === 4 || t.bid_todo_id === 12) || {
    bid_bond_amount: "",
    bid_bond_amount_type: "",
    bid_todo_id: !!data.type && (data.type.name === "Prime IDOT" || data.type.name === "Subprime IDOT") ? 12 : 4,
  };

  const amountOptions = [
    { value: "5", label: "5%" },
    { value: "10", label: "10%" },
    { value: "other", label: "Other" },
  ];

  const setTodos = (bid_todo_ids) => {
    const newBidTodos = bid_todo_ids.map((t) => {
      const existing = data.bid_todos.find((todo) => todo.bid_todo_id === t);
      const initialExisting = initialTodos.find((todo) => todo.bid_todo_id === t);
      if (t === 1) {
        return preBidMeeting;
      } else if (t === 8) {
        return srfNumber;
      } else if (t === 4 || t === 12) {
        return bidBond;
      }
      if (existing) return { ...existing };
      if (initialExisting) return { ...initialExisting };
      return { bid_todo_id: t };
    });
    setFields({ ...data, bid_todo_ids, bid_todos: newBidTodos });
  };

  const handlePreBidMeetingFieldChange = (name, value) => {
    setFields({
      ...data,
      bid_todos: data.bid_todos.map((t) => {
        if (t.bid_todo_id === 1) return { ...preBidMeeting, [name]: value };
        return t;
      }),
    });
  };

  const handleAttendanceRequired = () => {
    const value = preBidMeeting["pre_bid_attendance_required"] === 0 ? 1 : 0;
    setFields({
      ...data,
      bid_todos: data.bid_todos.map((t) => {
        if (t.bid_todo_id === 1) return { ...preBidMeeting, pre_bid_attendance_required: value };
        return t;
      }),
    });
  };

  const handleBidBondFieldChange = (name, value) => {
    setFields({
      ...data,
      bid_todos: data.bid_todos.map((t) => {
        if (t.bid_todo_id === 4 || t.bid_todo_id === 12) return { ...bidBond, [name]: value };
        return t;
      }),
    });
  };

  const handleSRFNumber = (value) => {
    setFields({
      ...data,
      bid_todos: data.bid_todos.map((t) => {
        if (t.bid_todo_id === 8) return { ...srfNumber, srf_number: value };
        return t;
      }),
    });
  };

  return (
    <>
      {!!todosForType.length && (
        <div className="row py-2 mt-2">
          <div className="col-12">
            <h1 className="form-section-header">Project Requirements</h1>
          </div>
        </div>
      )}

      {todosForType.map((todo) => (
        <div key={todo.id}>
          <div className="row">
            <div className={singleColumn ? "col-12" : "col-lg-6"}>
              {renderCheckbox({
                groupName: "bid_todos[]",
                name: todo.id,
                label: todo.name,
                setValuesArray: setTodos,
                valuesArray: data.bid_todo_ids,
              })}
            </div>
          </div>
          {todo.id === 1 && data.bid_todo_ids.indexOf(1) > -1 && (
            <div className="row ms-4">
              <div className={`${singleColumn ? `col-12` : `col-lg-6`} ms-2`}>
                <div className="row border-top  py-2 ms-n3">
                  <div className={singleColumn ? "col-12" : "col-lg-5"}>
                    <div className={`form-group ${!!errors["pre_bid_date"] ? "error" : ""}`}>
                      <label htmlFor="pre_bid_date">Pre-Bid Date</label>
                      <StyledDatePicker
                        name="pre_bid_date"
                        id="pre_bid_date"
                        selected={Date.parse(preBidMeeting.pre_bid_date)}
                        onChange={(date) =>
                          handlePreBidMeetingFieldChange("pre_bid_date", DateTime.fromJSDate(date).toISO())
                        }
                        isClearable
                      />
                      {errors && errors.pre_bid_date && (
                        <span className="help-block is-error">{errors["pre_bid_date"]}</span>
                      )}
                    </div>
                  </div>
                  <div className={singleColumn ? "col-12" : "col-lg-5"}>
                    <div className={`form-group ${!!errors["pre_bid_time"] ? "error" : ""}`}>
                      <label htmlFor="pre_bid_time">Pre-Bid Time</label>
                      <Select
                        options={times}
                        name={"pre_bid_time"}
                        className="w-100"
                        id="pre_bid_time"
                        onChange={({ value }) => handlePreBidMeetingFieldChange("pre_bid_time", value)}
                        value={
                          times.find(
                            (opt) =>
                              opt.value === preBidMeeting["pre_bid_time"] || opt.value === preBidMeeting["pre_bid_time"]
                          ) || ""
                        }
                        isClearable={false}
                        tabSelectsValue={false}
                        styles={dropdownStyles}
                        isMulti={false}
                      />
                      {errors && errors.pre_bid_time && (
                        <span className="help-block is-error">{errors["pre_bid_time"]}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className={`form-group ${!!errors["pre_bid_location"] ? "error" : ""}`}>
                      <label htmlFor="pre_bid_location">Pre-Bid Location</label>
                      <input
                        type="text"
                        className="form-control"
                        name="pre_bid_location"
                        id="pre_bid_location"
                        onChange={({ target: { value } }) => handlePreBidMeetingFieldChange("pre_bid_location", value)}
                        value={preBidMeeting["pre_bid_location"] || ""}
                      />
                      {errors && errors.pre_bid_location && (
                        <span className="help-block is-error">{errors["pre_bid_location"]}</span>
                      )}
                    </div>
                    <div className={`form-group form-check`}>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="pre_bid_attendance_required"
                        id="pre_bid_attendance_required"
                        onChange={handleAttendanceRequired}
                        value={preBidMeeting["pre_bid_attendance_required"]}
                        checked={!!preBidMeeting["pre_bid_attendance_required"]}
                      />
                      <label className="form-check-label" htmlFor="pre_bid_attendance_required">
                        Mandatory
                      </label>
                      <X size="18" color="#fff" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {todo.id === 8 && data.bid_todo_ids.indexOf(8) > -1 && (
            <div className="row ms-4">
              <div className={`${singleColumn ? `col-12` : `col-lg-6`} ms-2`}>
                <div className="row border-top  py-2 ms-n3">
                  <div className="col-12">
                    <div className={`form-group`}>
                      <label htmlFor="pre_bid_time">SRF Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="srf_number"
                        id="srf_number"
                        onChange={({ target: { value } }) => handleSRFNumber(value)}
                        value={srfNumber["srf_number"] || ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(todo.id === 4 || todo.id === 12) &&
            (data.bid_todo_ids.indexOf(4) > -1 || data.bid_todo_ids.indexOf(12) > -1) && (
              <div className="row">
                <div className={`${singleColumn ? `col-12` : `col-lg-6`} ms-4`}>
                  <div className="row border-top py-2 ms-2">
                    <div className={`${singleColumn ? `col-12` : `col-lg-6 p-0`}`}>
                      <div className={`form-group ${!!errors["bid_bond_amount_type"] ? "error" : ""}`}>
                        <label htmlFor="bid_bond_amount_type">Amount</label>
                        <Select
                          options={amountOptions}
                          name="bid_bond_amount_type"
                          className="w-100"
                          id="bid_bond_amount_type"
                          onChange={({ value }) => handleBidBondFieldChange("bid_bond_amount_type", value)}
                          value={amountOptions.find((opt) => opt.value === bidBond["bid_bond_amount_type"])}
                          isClearable={false}
                          tabSelectsValue={false}
                          styles={dropdownStyles}
                          isMulti={false}
                          isSearchable={false}
                          aria-label="bid bond percentage amount"
                        />
                        {errors && errors.bid_bond_amount_type && (
                          <span className="help-block is-error">{errors["bid_bond_amount_type"]}</span>
                        )}
                      </div>
                    </div>

                    {bidBond.bid_bond_amount_type === "other" && (
                      <div className={`${singleColumn ? `col-12` : `col-lg-6`} ps-2`}>
                        <div className={`form-group ${!!errors["bid_bond_amount_type"] ? "error" : ""}`}>
                          {!horizontal && <label htmlFor="bid_bond_amount_type"></label>}
                          <div className={styles.inputWrapper}>
                            <input
                              type="number"
                              className={`form-control ${styles.wrappedInput}`}
                              name="bid_bond_amount"
                              id="bid_bond_amount"
                              onChange={({ target: { value } }) => handleBidBondFieldChange("bid_bond_amount", value)}
                              value={bidBond["bid_bond_amount"] || ""}
                              max="100"
                              min="0"
                              step="0.01"
                              aria-label="bid bond percentage custom amount"
                            />
                            %
                          </div>

                          {errors && errors.bid_bond_amount_type && (
                            <span className="help-block is-error">{errors["bid_bond_amount_type"]}</span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
        </div>
      ))}
    </>
  );
};

BidTodosForm.propTypes = {
  data: PropTypes.object.isRequired,
  renderCheckbox: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
};

export default BidTodosForm;
