import React from "react";
import ManHoursItem from "./ManHoursItem.js";
import ManHoursJobsChart from "./ManHoursJobsChart";
import styles from "./styles.module.scss";

const ManHoursGrid = ({ jobs }) => {
  return (
    <section className="col">
      <header>
        <h1 className="med">Total Hours</h1>
      </header>
      <div className={styles.boomboardGrid}>
        <div className={styles["status-overall"]}>
          <ManHoursJobsChart />
        </div>
        {jobs.map((job) => (
          <ManHoursItem job={job} key={job.job_id} />
        ))}
      </div>
    </section>
  );
};

export default ManHoursGrid;
