import { currency } from "component-library";

const amount = {
  headerName: "Amount",
  field: "amount",
  valueGetter: ({ data }) => (data.amount ? currency(data.amount) : null),
  maxWidth: 172,
};

export default amount;
