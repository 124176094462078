const useTaskDetailRow = ({ task }) => {
  const remainingHours = parseFloat((parseFloat(task?.estimated_hours) || 0) - (parseFloat(task?.reported_hours) || 0));

  const overUnder = parseFloat((task?.reported_hours || 0) - (task?.earned_hours || 0)).toFixed(2);
  const overUnderPercent = parseFloat(task?.earned_hours) === 0 ? 0 : (overUnder / task?.earned_hours) * 100;
  const displayPercentage =
    parseInt(task?.bid_quantity) > 0 ? parseInt((task?.quantity_reported / task?.bid_quantity) * 100) : 0;

  return {
    remainingHours,
    overUnder,
    overUnderPercent,
    displayPercentage,
  };
};

export default useTaskDetailRow;
