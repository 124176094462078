import React from "react";
import useProvideProjectSelection from "./useProvideProjectSelection";
import projectsListContext from "./projectsListContext";

function ProvideProjectsList({ children }) {
  const projectsList = useProvideProjectSelection();
  return <projectsListContext.Provider value={projectsList}>{children}</projectsListContext.Provider>;
}

export default ProvideProjectsList;
