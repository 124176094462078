import { useState } from "react";

const useProjectAttachments = ({ bidObject }) => {

  const [plansFile, setPlansFile] = useState(
    bidObject.attachments.filter((a) => a.type === "Plans")
  );
  const [bidderFile, setBidderFile] = useState(
    bidObject.attachments.find((a) => a.type === "Notice to Bidders")
  );
  const [specFile, setSpecFile] = useState(
    bidObject.attachments.filter((a) => a.type === "Specbook")
  );

  return {
    plansFile,
    bidderFile,
    specFile,
    setPlansFile,
    setBidderFile,
    setSpecFile,
  };
};

export default useProjectAttachments;
