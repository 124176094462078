import React from "react";
import { Routes, Route } from "react-router-dom";
import ProvideFleetManagementIframes from "./contextIframes/ProvideFleetManagement";
import PrivateRoute from "../components/PrivateRoute";
import HomeIframe from "./iframePages/HomeIframe";
import ReportsIframe from "./iframePages/ReportsIframe";
import EquipmentIframe from "./iframePages/EquipmentIframe";
import ConfigurationIframe from "./iframePages/ConfigurationIframe";
import OperationsIframe from "./iframePages/OperationsIframe";
import ConfigurationBeaconsIframe from "./iframePages/ConfigurationBeaconsIframe";
import ConfigurationTelematicsIframe from "./iframePages/ConfigurationTelematicsIframe";
import ConfigurationUsersIframe from "./iframePages/ConfigurationUsersIframe";
import TruckingActivityIframe from "./iframePages/TruckingActivityIframe";
import TruckingScheduleIframe from "./iframePages/TruckingScheduleIframe";
import TruckingTimeKeepingIframe from "./iframePages/TruckingTimeKeepingIframe";
import AllPOIs from "./Pages/POIs/AllPOIs";
import AddPOI from "./Pages/POIs/AddPOI";
import SinglePOI from "./Pages/POIs/SinglePOI";

const FleetManagementRoutes = () => {
  return (
    <ProvideFleetManagementIframes>
      <Routes>
        <Route
          index
          element={<PrivateRoute module="Telematics" viewPermission="fleet-view" Component={<HomeIframe />} />}
        />
        <Route
          path="dashboard"
          element={<PrivateRoute module="Telematics" viewPermission="fleet-view" Component={<HomeIframe />} />}
        />
        <Route
          path="reports/home"
          element={<PrivateRoute module="Telematics" viewPermission="fleet-view" Component={<ReportsIframe />} />}
        />
        <Route
          path="equipment"
          element={<PrivateRoute module="Telematics" viewPermission="fleet-view" Component={<EquipmentIframe />} />}
        />
        <Route
          path="configuration/home"
          element={<PrivateRoute module="Telematics" viewPermission="fleet-view" Component={<ConfigurationIframe />} />}
        />
        <Route
          path="configuration/beacons"
          element={
            <PrivateRoute module="Telematics" viewPermission="fleet-view" Component={<ConfigurationBeaconsIframe />} />
          }
        />
        <Route
          path="configuration/telematics"
          element={
            <PrivateRoute
              module="Telematics"
              viewPermission="fleet-view"
              Component={<ConfigurationTelematicsIframe />}
            />
          }
        />
        <Route
          path="configuration/users"
          element={
            <PrivateRoute module="Telematics" viewPermission="fleet-view" Component={<ConfigurationUsersIframe />} />
          }
        />
        <Route
          path="trucking/activity"
          element={
            <PrivateRoute module="Telematics" viewPermission="fleet-view" Component={<TruckingActivityIframe />} />
          }
        />
        <Route
          path="trucking/schedule"
          element={
            <PrivateRoute module="Telematics" viewPermission="fleet-view" Component={<TruckingScheduleIframe />} />
          }
        />
        <Route
          path="trucking/timekeeping"
          element={
            <PrivateRoute module="Telematics" viewPermission="fleet-view" Component={<TruckingTimeKeepingIframe />} />
          }
        />
        <Route
          path="operations"
          element={<PrivateRoute module="Telematics" viewPermission="fleet-view" Component={<OperationsIframe />} />}
        />
        <Route path="/pois" element={<PrivateRoute module="Telematics" Component={<AllPOIs />} />} />
        <Route path="/pois/add" element={<PrivateRoute module="Telematics" Component={<AddPOI />} />} />
        <Route path="/pois/edit/:poiId" element={<PrivateRoute module="Telematics" Component={<SinglePOI />} />} />
      </Routes>
    </ProvideFleetManagementIframes>
  );
};

export default FleetManagementRoutes;
