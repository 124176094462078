import React from "react";
import { useParams, Link } from "react-router-dom";
import { useAuth } from "component-library";
import PropTypes from "prop-types";

const BidHeader = ({ project, noEdit }) => {
  const { id } = useParams();
  const { userCan } = useAuth();

  return (
    <div className="d-lg-flex w-100 justify-content-end">
      {userCan("project-edit") && !noEdit && (
        <Link to={`/bid/${id}/edit/`} className="btn btn-link py-0">
          edit
        </Link>
      )}
    </div>
  );
};

export default BidHeader;

BidHeader.propTypes = {
  project: PropTypes.object,
};
