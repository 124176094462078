import HistoryEvent from './HistoryEvent';
import { RefreshCw } from 'react-feather';
import styles from "./styles.module.scss";
import { BeatLoader } from 'react-spinners';
import { Alert } from 'react-bootstrap';
import usePhotoHistory from './usePhotoHistory';

const PhotoHistory = ({ photoDetails }) => {

  const { photoHistoryLoading, photoHistoryError, photoHistoryRequest, photoHistory } = usePhotoHistory({ photoDetails })

  return (
    <div className="mt-4">
      {!!photoHistoryLoading ? (
        <div className={styles.historyHeader}>
          <BeatLoader color="#0017CE" size={12} />
        </div>
      ) : (
        <h2 className={styles.historyHeader}>
          <RefreshCw
            size={16} color="#0071ce"
            className="me-2"
            onClick={() => photoHistoryRequest(photoDetails.id)}
          />
          <span>History</span>
        </h2>
      )}
      {!!photoHistoryError && <Alert variant="danger">{photoHistoryError}</Alert>}
      {photoHistory?.length ? (
        photoHistory.map((historyEvent) => <HistoryEvent historyEvent={historyEvent} />)
      ) : (
        <>No history results found.</>
      )}
    </div>
  )
}

export default PhotoHistory