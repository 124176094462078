import { currency } from "component-library";
import React from "react";

const QuoteAmount = ({ data }) => {
  return (
    <>
      {!!data.quote && data.quote.amount > 0
        ? currency(data.quote.amount)
        : "N/A"}
    </>
  );
};

export default QuoteAmount;
