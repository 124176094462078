import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Equipment from "./Equipment";
import styles from "./styles.module.scss";
import Timesheet from "./Timesheet";
import useEquipment from "./useEquipment";
import CustomScroll from "react-custom-scroll";

const SidebarBank = ({ fieldLog, timesheets, status, editing, job_number, foremanId, overAllocatedTimeSheets }) => {
  const [active, setActive] = useState("crew");
  const { equipment, equipmentError, equipmentLoading } = useEquipment({ fieldLog, date: fieldLog?.date, job_number });

  return (
    <>
      <div className={`${styles.sidebarToggle} mb-4`}>
        <Button
          onClick={() => {
            setActive("crew");
          }}
          className={`${styles.toggleButton} ${active === "crew" ? styles.active : ""}`}
          aria-label={`View crew with unassigned hours`}
        >
          Crew
        </Button>
        <Button
          onClick={() => {
            setActive("equipment");
          }}
          className={`${styles.toggleButton} ${active === "equipment" ? styles.active : ""}`}
          aria-label={`View equipment on site`}
        >
          Equipment
        </Button>
      </div>
      <div className={styles.sidebarInner}>
        <CustomScroll heightRelativeToParent="100%" allowOuterScroll={true}>
          {active === "crew" && (
            <>
              {!!timesheets.length ? (
                <>
                  {timesheets.map((sheet) => (
                    <Timesheet
                      status={status}
                      timesheet={sheet}
                      editing={editing}
                      foremanId={foremanId}
                      overAllocatedTimeSheets={overAllocatedTimeSheets}
                    />
                  ))}
                </>
              ) : (
                <div className={styles.noResults}>No unassigned hours to show</div>
              )}
            </>
          )}
          {active === "equipment" && (
            <>
              <Equipment equipment={equipment} equipmentError={equipmentError} equipmentLoading={equipmentLoading} />
            </>
          )}
        </CustomScroll>
      </div>
    </>
  );
};

export default SidebarBank;
