import PropTypes from "prop-types";
import { ArrowRightCircle } from "react-feather";
import styles from "./styles.module.scss";
import { Avatar } from "component-library";
import InterfaceIcon from "../InterfaceIcon";

const OnSiteCard = ({
  contact: { first_name, last_name, time_in, interface: interfaceUsed, within_geofence },
  avatar,
}) => {
  const formattedTime = new Date(time_in).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");

  return (
    <div className={styles.wrapper}>
      <div className="w-32">
        <Avatar user={{ first_name, last_name, avatar }} />
      </div>

      <div className={styles.crew_container}>
        <div className={styles.crew_name}>
          {!!first_name && !!last_name && (
            <div className="text-nowrap text-truncate">{`${last_name}, ${first_name[0].toUpperCase()}`}</div>
          )}
        </div>

        <div className={styles.crew_meta}>
          {!!time_in && (
            <div className={styles.time_wrapper}>
              <ArrowRightCircle size={12} style={{ marginBottom: "0.3rem", marginRight: "0.2rem" }} />
              {formattedTime}
            </div>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center">
        {(!!within_geofence || !!interfaceUsed) && (
          <InterfaceIcon isWithinGeofence={within_geofence} interfaceUsed={interfaceUsed} size={24} />
        )}
      </div>
    </div>
  );
};

OnSiteCard.propTypes = {
  contact: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    time_in: PropTypes.string,
    interfaceUsed: PropTypes.number,
    within_geofence: PropTypes.number,
  }),
  avatar: PropTypes.string,
};

export default OnSiteCard;
