import React, { useState, useEffect } from "react";
import { projectApi, useApi, useAuth } from "component-library";
import styles from "../styles.module.scss";
import PropTypes from "prop-types";

const ProjectTeam = ({ renderSelectField, formData }) => {
  const { data: roleOptions, request: requestRoleOptions } = useApi(projectApi.getProjectRoleOptions);

  useEffect(() => {
    requestRoleOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { config } = useAuth();

  const [projectManagers, setProjectManagers] = useState([]);
  const [projectCoordinators, setProjectCoordinators] = useState([]);
  const [estimators, setEstimators] = useState([]);
  const [superintendents, setSuperintendents] = useState([]);
  const [foremen, setForemen] = useState([]);

  useEffect(() => {
    if (!!roleOptions && !!roleOptions.users) {
      setProjectManagers(roleOptions.users["project-managers"]);
      setProjectCoordinators(roleOptions.users["project-coordinators"]);
      setSuperintendents(roleOptions.users.superintendents);
      setForemen(roleOptions.users.foremen);
    }
  }, [roleOptions]);

  useEffect(() => {
    if (!!config) {
      setEstimators(config.estimators);
    }
  }, [config]);

  return (
    <>
      <h2 className={styles.heading18}>Project Team</h2>
      <div className="row">
        <div className="col-md-6">
          {renderSelectField({
            name: "project_manager_ids",
            label: "Project Manager",
            options:
              projectManagers &&
              projectManagers.map((user) => ({
                value: user.id,
                label: `${user.first_name} ${user.last_name}`,
              })),
            isMulti: true,
            hasWarning: formData && formData.project_manager_ids && formData.project_manager_ids.length ? false : true,
          })}
        </div>
        <div className="col-md-6">
          {renderSelectField({
            name: "project_coordinator_ids",
            label: "Project Coordinator",
            options:
              projectCoordinators &&
              projectCoordinators.map((user) => ({
                value: user.id,
                label: `${user.first_name} ${user.last_name}`,
              })),
            isMulti: true,
            hasWarning:
              formData && formData.project_coordinator_ids && formData.project_coordinator_ids.length ? false : true,
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {renderSelectField({
            name: "estimator_id",
            label: "Estimator",
            options:
              estimators &&
              estimators.map((user) => ({
                value: user.id,
                label: `${user.first_name} ${user.last_name}`,
              })),
          })}
        </div>
      </div>

      {renderSelectField({
        name: "project_superintendent_ids",
        label: "Superintendent(s)",
        options:
          superintendents &&
          superintendents.map((user) => ({
            value: user.id,
            label: `${user.first_name} ${user.last_name}`,
          })),
        isMulti: true,
        hasWarning:
          formData && formData.project_superintendent_ids && formData.project_superintendent_ids.length ? false : true,
      })}

      {renderSelectField({
        name: "project_foremen_ids",
        label: "Foreman(men)",
        options:
          foremen &&
          foremen.map((user) => ({
            value: user.id,
            label: `${user.first_name} ${user.last_name}`,
          })),
        isMulti: true,
        hasWarning: formData && formData.project_foremen_ids && formData.project_foremen_ids.length ? false : true,
      })}
    </>
  );
};

export default ProjectTeam;

ProjectTeam.propTypes = {
  renderSelectField: PropTypes.func.isRequired,
};
