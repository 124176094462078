import React, { useState, useEffect } from 'react';
import ChecklistForm from './ChecklistForm';
import { useApi, projectApi } from "component-library";
import useProject from "../../../hooks/useProject";
import { Alert } from "react-bootstrap";
import { Loader } from "component-library";

const DotCatList = () => {
  const { loading, request, error } = useApi(projectApi.assignProjectDotCategories);

  const {
    loading: dotCatLoading,
    request: dotCatRequest,
    data: dotCatData,
    error: dotCatError,
  } = useApi(projectApi.getProjectDotCategories);

  const { project, setProject } = useProject();
  const [dotCategories, setDotCategories] = useState();
  const [listValues, setListValues] = useState(
    !!project.dot_categories ? project.dot_categories.map((i) => i.dot_category_id) : []
  );

  useEffect(() => {
    if (!!project.dot_categories) {
      const categoryIds = project.dot_categories.map((item) => item.dot_category.id);
      setListValues(categoryIds);
    }
  }, [project]);

  const onBlurHandler = async () => {
    const projectId = project.id;

    const result = await request({
      project_id: projectId,
      dot_categories: listValues,
    });

    if (!!result.success) {
      setProject({
        ...project,
        dot_categories: result.responseData.project.dot_categories,
      });
    }
  };

  useEffect(() => {
    dotCatRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!dotCatData) {
      setDotCategories(dotCatData.cats);
    }
  }, [dotCatData]);

  return (
    <div className="position-relative overflow-hidden">
      <div className="pt-2 mb-5">
        <Loader loading={!!dotCatLoading} />
      </div>
      {!!dotCatError && <Alert variant="danger">{error}</Alert>}
      <ChecklistForm
        options={dotCategories}
        listValues={listValues}
        setListValues={setListValues}
        onBlur={onBlurHandler}
        loading={loading}
        error={error}
      />
    </div>
  );
};

export default DotCatList;