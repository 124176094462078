import { DateTime } from "luxon";

/**
 * standardizes formatting of time
 * @param  {String} date string of a date that will be converted to js date via luxon
 * @return {String} datetime in the format of hh:mm a
 */

const formatTime = (date) => {
  const dateTime = DateTime.fromJSDate(new Date(date));
  return dateTime.toFormat("hh:mm a");
}

export default formatTime