import { useState, useEffect } from "react";
import { useToasts } from "component-library";
import { projectApi, useApi, useAuth } from "component-library";
import { useParams } from "react-router-dom";
import { getAttachmentFoldersList } from "../../utils/getAttachmentFoldersList";

const useProvideProjectDocuments = () => {
  const { config } = useAuth();
  const { id } = useParams();
  const [attachments, setAttachments] = useState([]);
  const [folderList, setFolderList] = useState();

  const {
    request: attachmentsRequest,
    data: attachmentsData,
    loading: attachmentsLoading,
    error: attachmentsError,
  } = useApi(projectApi.getProjectFiles);

  const { triggerToast } = useToasts();

  const displayMessage = (message) => {
    if (!!message) {
      triggerToast(message?.title, message?.content, message?.id, message?.variant);
    }
  };

  const refreshAttachments = () => {
    attachmentsRequest(id);
  };

  useEffect(() => {
    if (!!id) {
      attachmentsRequest(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!!attachmentsData?.success) {
      setAttachments(attachmentsData.files);
    } else {
      setAttachments([]);
    }
  }, [attachmentsData]);

  useEffect(() => {
    setFolderList(getAttachmentFoldersList(attachments, config?.folders));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  return {
    folderList,
    displayMessage,
    attachments,
    attachmentsLoading,
    attachmentsError,
    setAttachments,
    attachmentsRequest,
    refreshAttachments,
  };
};

export default useProvideProjectDocuments;
