import React, { useState } from "react";
import { Circle } from "react-feather";
import styles from "./styles.module.scss";

const StatusColor = ({ data }) => {
  const [showTip, setShowTip] = useState(false);
  const colorSwitch = (value) => {
    let color;
    switch (true) {
      case value === -1:
        color = "#8f8f8f";
        break;
      case value === 0:
        color = "#FF6700";
        break;
      case value < 0.66:
        color = "#FFA400";
        break;
      case value < 1:
        color = "#F0C808";
        break;
      case value === 1:
        color = "#7DDB2D";
        break;
      default:
        color = "#8f8f8f";
    }
    return color;
  };

  const dataColor = colorSwitch(data.color);

  return (
    <div className="position-relative">
      {!!showTip && (
        <div className={styles.todosStatus}>
          <span className={styles.todoStatusText}>{`${data.bid_todos.filter((i) => !!i.complete).length}/${
            data.bid_todos.length
          }`}</span>
        </div>
      )}
      <div
        className={styles.statusCircle}
        // onClick={() => setShowTip(!showTip)}
        onMouseOver={() => setShowTip(true)}
        onMouseOut={() => setShowTip(false)}
      >
        <Circle color={dataColor} fill={dataColor} size="0.8rem" />
      </div>
    </div>
  );
};

export default StatusColor;
