import { Joi } from "component-library";

const schema = {
  name: Joi.saneString().required().label("Project/Bid Name"),
  job_number: Joi.saneString().required().label("Job #"),
  description: Joi.saneString().label('Project Description'),
  type_id: Joi.number().required().label('Project Type'),
  category_id: Joi.number().required().label('Project Category'),
  idot_project_no: Joi.any().allow(null)
};

const initialData = {
  name: '',
  description: '',
  job_number: '',
  type_id: undefined,
  category_id: undefined,
  idot_project_no: ''
};

export { schema, initialData };
