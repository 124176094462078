import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell } from "recharts";
import styles from "./styles.module.scss";
import Dot from "./Dot";

const StatPieChart = ({ used, budgeted, dispBudgeted, usedLabel, estimateLabel }) => {
  const [chartData, setChartData] = useState([]);

  const COLORS = ["#0071CE", "#1C4177"];

  useEffect(() => {
    if (used || budgeted) {
      setChartData([
        { name: "In Progress", value: budgeted },
        { name: "Complete", value: used },
      ]);
    }
  }, [budgeted, used]);

  return (
    <figure>
      <div className={`d-flex align-items-center`}>
        <div className="d-inline-block position-relative">
          <PieChart width={80} height={80}>
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={25}
              outerRadius={40}
              fill="#0071CE"
              labelLine={false}
              startAngle={-270}
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
          <div className={styles.totalBase}>
            <div className={`${styles.percentageText} text-center`}>
              {parseInt(budgeted) > 0 ? Math.round((parseInt(used) / parseInt(budgeted)) * 100) : 0}
              <sup>%</sup>
            </div>
          </div>
        </div>
        <figcaption className={"ms-3"}>
          <dl className="mb-0">
            <div className="d-flex align-items-center">
              <Dot color="#1C4177" />
              <dd className={`ms-2 mb-0 ${styles.legendDD}`}>{parseInt(used || 0)}</dd>
              <dt className={`${styles.label} ms-1`}>{usedLabel}</dt>
            </div>
            <div className="d-flex align-items-center">
              <Dot color="#0071CE" />
              <dd className={`ms-2 mb-0 ${styles.legendDD} ${styles.label}`}>{parseInt(dispBudgeted) || 0}</dd>
              <dt className={`${styles.label} ms-1`}>{estimateLabel}</dt>
            </div>
          </dl>
        </figcaption>
      </div>
    </figure>
  );
};

export default StatPieChart;
