import { Joi } from "component-library";

const schema = {
  eligibility_request: Joi.checkbox(),
  eligibility_subcontract_amount: Joi.saneString().label("Subcontract amount"),
  eligibility_woh: Joi.saneString().label("WOH"),
};

  const initialData = {
    eligibility_request: 0,
    eligibility_subcontract_amount: "",
    eligibility_woh: "",
  };

export { schema, initialData };
