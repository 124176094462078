import React from "react";
import { Navigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import useProject from "../../hooks/useProject";
import useAddPurchaseOrder from "./useAddPurchaseOrder";
import styles from "./styles.module.scss";

const CreatePO = ({ item }) => {
  const { project } = useProject();
  const { addPurchaseOrder, loading, data } = useAddPurchaseOrder();
  return (
    <div className="d-flex align-items-center">
      {!!data && !!data.purchaseOrder && (
        <Navigate to={`/project/${project.id}/purchase-orders/${data.purchaseOrder.id}`} />
      )}
      {!!loading && (
        <div className={styles.fieldLoading}>
          <BeatLoader size={10} color={"#0071ce"} />
        </div>
      )}

      <button className="med link" onClick={() => addPurchaseOrder(item.id)}>
        Create PO &rsaquo;
      </button>
    </div>
  );
};

export default CreatePO;
