import { Joi } from "component-library";

const schema = {
  item_number: Joi.saneString().required().label("Item Number"),
  name: Joi.saneString().required().label("Name"),
  bid_quantity: Joi.number().label("Bid Quantity"),
  rate: Joi.number().label("Production Rate"),
  rate_um: Joi.saneString().label("Rate Measure"),
  unit_measure: Joi.number().label("Unit Measure"),
  cost_code: Joi.saneString().label("Cost Code"),
};

const initialData = {
  name: "",
  cost_code: "",
  item_number: "",
  bid_quantity: 0,
  rate_um: "16",
};

export { schema, initialData };
