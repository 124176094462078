import {
  date,
  foreman,
  job_name,
  job_number,
  name,
  time_in,
  userid,
  time_in_geofence,
  time_out_geofence,
  time_out,
} from "./columnDefs";

const columnDefs = [
  userid,
  date,
  name,
  job_name,
  job_number,
  foreman,
  time_in,
  time_in_geofence,
  time_out,
  time_out_geofence,
];

export default columnDefs;
