import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Popover, OverlayTrigger, Alert } from "react-bootstrap";
import { Plus } from "react-feather";
import styles from "./styles.module.scss";
import Timesheet from "./Timesheet";
import { Search } from "react-feather";
import { BeatLoader } from "react-spinners";
import useAddTimeEntry from "./useAddTimeEntry";

const TimeEntryPopover = ({
  task,
  fieldLog,
  setFieldLog,
  crew,
  foremanId,
  laborTypes = [],
  overAllocatedTimeSheets,
  setOverAllocatedTimeSheets,
  crewLoading,
}) => {
  const [q, setQ] = useState("");
  const [crewWithTimesheets, setCrewWithTimesheets] = useState();
  const { loading, error, addTimeEntry } = useAddTimeEntry({ foremanId, fieldLog, setFieldLog, task, laborTypes });

  const filterTimesheets = (value) => {
    setQ(value);
  };

  useEffect(() => {
    let crewWithTimesheets;

    if (q === "") {
      if (!!crew && !!crew.length) {
        crewWithTimesheets = fieldLog.time_sheets;
      }
    } else {
      if (!!crew && !!crew.length) {
        crewWithTimesheets = fieldLog.time_sheets.filter((sheet) =>
          `${sheet.user.first_name.toLowerCase()} ${sheet.user.last_name.toLowerCase()}`.includes(q.toLowerCase())
        );
      }
    }

    setCrewWithTimesheets(crewWithTimesheets);
  }, [q, fieldLog, crew]);

  const popover = (
    <Popover placement="bottom" id="popover-entries" positionbottom={-50}>
      <Popover.Body>
        <div className="position-relative">
          {(!!loading || !!crewLoading) && (
            <div className="loaderWrapper">
              <BeatLoader size={10} color={"#0071ce"} />
            </div>
          )}
          <div className={styles.header}>
            <div className="position-relative d-flex align-items-center">
              <input
                className="form-control border-bottom"
                placeholder="Search Crew"
                onChange={(e) => {
                  filterTimesheets(e.target.value);
                }}
                value={q}
              />
              <div className={styles.formControlIcon}>
                <Search size="18" />
              </div>
            </div>
          </div>
          {!!error && (
            <div>
              <Alert variant="danger">{error}</Alert>
            </div>
          )}
          <div className={styles.popover}>
            {!!crewWithTimesheets &&
              !!crewWithTimesheets.length &&
              crewWithTimesheets.map((sheet, index) => {
                return (
                  <Timesheet
                    key={index}
                    timesheet={sheet}
                    addTimeEntry={addTimeEntry}
                    foremanId={foremanId}
                    overAllocatedTimeSheets={overAllocatedTimeSheets}
                    setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
                  />
                );
              })}
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <OverlayTrigger trigger="click" placement="bottom-start" transition={false} overlay={popover} rootClose>
        <Button variant="link d-flex align-items-center med">
          Add Hours <Plus size="14" color="#0071ce" />
        </Button>
      </OverlayTrigger>
    </>
  );
};

TimeEntryPopover.propTypes = {
  task: PropTypes.object,
  fieldLog: PropTypes.object,
  setFieldLog: PropTypes.func,
  crew: PropTypes.array,
  foremanId: PropTypes.string,
  laborTypes: PropTypes.array,
  overAllocatedTimeSheets: PropTypes.array,
  setOverAllocatedTimeSheets: PropTypes.func,
};

export default TimeEntryPopover;
