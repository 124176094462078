import { scheduleApi, useApi } from "component-library";
import { useEffect } from "react";
import useProject from "../../hooks/useProject";

const useProvideSchedule = () => {
  const { project } = useProject();
  const { data, loading, request, error } = useApi(
    scheduleApi.getProjectSchedule
  );

  useEffect(() => {
    if (project) request(project.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return {
    data,
    loading,
    error,
  };
};

export default useProvideSchedule;
