import React from "react";
import Select from "react-select";
import styles from "./styles.module.scss";
import { dropdownStylesWithWidth } from "component-library";

const ProjectSelector = ({ projects, project, setProject }) => {
  return (
    <div className={styles.dropdownWrapper}>
      <Select
        options={projects}
        name="project_selection"
        id="project_selection"
        className="mb-3"
        onChange={({ value, label }) =>
          setProject({ value: value, label: label })
        }
        styles={dropdownStylesWithWidth}
        value={projects.find((opt) => opt.value === project)}
        aria-label="Select Project dropdown"
      />
    </div>
  );
};

export default ProjectSelector;
