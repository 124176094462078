import React from "react";
import BidItem from "./BidItem";
import useEstimateConversion from "../useEstimateConversion";
import { Table, Alert, Button } from "react-bootstrap";
import BidItemsHeader from "./BidItemsHeader";
import styles from "./styles.module.scss";
import { Eye, EyeOff } from "react-feather";

const BidItems = () => {
  const { filteredBidItems, bidItems, viewPhases, phasesError, setPhasesError, viewAll, toggleAllItems } =
    useEstimateConversion();

  return (
    <div className="overflow-auto">
      {!!phasesError && (
        <Alert className="mt-4" onClick={() => setPhasesError()} dismissible variant="danger">
          {phasesError}
        </Alert>
      )}
      <Button variant="link" className="p-0" onClick={() => toggleAllItems()}>
        {!!viewAll ? <Eye color="#0071ce" size={16} /> : <EyeOff color="#0071ce" size={16} />}
      </Button>

      <div className={styles.view}>
        <div className={`${styles.fixed} ${!!viewPhases ? styles.phasesActive : ""}`}>
          <Table striped hover className={`mt-2 ${styles.taskTable} text-gray2`}>
            <BidItemsHeader fixedColumns={true} />
            <tbody>
              {!!filteredBidItems.length &&
                filteredBidItems.map((item) => (
                  <BidItem
                    bidItem={bidItems.find((bidItem) => bidItem.id === item.id)}
                    key={item.id}
                    fixedColumns={true}
                    setPhasesError={setPhasesError}
                  />
                ))}
            </tbody>
          </Table>
        </div>
        <div className={`${styles.scrollTableWrapper} ${!!viewPhases ? styles.phasesActive : ""}`}>
          <Table striped className={`${styles.scrollTable} ${styles.taskTable} mt-2 text-gray2`}>
            <BidItemsHeader />
            <tbody>
              {!!filteredBidItems.length &&
                filteredBidItems.map((item) => (
                  <BidItem
                    bidItem={bidItems.find((bidItem) => bidItem.id === item.id)}
                    key={item.id}
                    setPhasesError={setPhasesError}
                  />
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
export default BidItems;
