import React from 'react'
import Initials from '../../../../../../_fleetManagement/Initials';
import { Link } from 'react-router-dom';
import styles from "./styles.module.scss";
import { ArrowRightCircle, PauseCircle } from "react-feather";
import convertMinutesToTime from '../../../../../../utils/convertMinutesToTime';

const EquipmentItem = ({ index, singleEquipment }) => {

  return (
    <div key={index} className={styles.singleEquipment}>
      <div className="d-flex align-items-center">
        <Initials name={singleEquipment?.category} size="medium" />
        <Link className={styles.name} to={`/fleet-management/equipment/details/${singleEquipment?.equip_no}`}>
          <span className="me-4">{singleEquipment?.equip_no}</span>
        </Link>
        <span>{singleEquipment?.display_description || singleEquipment?.name}</span>
      </div>

      <div className="d-flex align-items-center me-4">
        <div className="me-4 text-gray2">
          <ArrowRightCircle color="#7DDB2D" size={16} /> {convertMinutesToTime(parseInt(singleEquipment.run_minutes))}hrs
        </div>
        <div className="text-gray2">
          <PauseCircle color="#DCB600" size={16} /> {convertMinutesToTime(parseInt(singleEquipment.idle_minutes))}hrs
        </div>
      </div>
    </div>
  );
}

export default EquipmentItem