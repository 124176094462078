import React from "react";
import styles from "./styles.module.scss";
import { BeatLoader } from "react-spinners";
import useTaskDetailRow from "./useTaskDetailRow";

const TaskDetailRow = ({ task, taskLoading }) => {
  const { remainingHours, overUnder, overUnderPercent, displayPercentage } = useTaskDetailRow({ task });
  return (
    <section className={styles.wrapper}>
      {!!taskLoading && <BeatLoader color="#0071CE" size={14} />}
      {!!task && (
        <>
          <div>
            <span className={styles.label}>Qty Rep/Est</span>
            <div className={styles.value}>
              <span>
                <span className={styles.current}>{task?.quantity_reported || 0}/</span>
                {Math.round(task?.bid_quantity) || 0}
              </span>
              <span className={styles.subText}>{task?.unit_measure}</span>
            </div>
          </div>
          <div>
            <span className={styles.label}>Hours Rep/Ernd</span>
            <div className={styles.value}>
              <span>
                <span className={styles.current}>{Math.round(task?.reported_hours) || 0}/</span>
                {Math.round(task?.earned_hours)}
              </span>
            </div>
          </div>
          <div>
            <span className={styles.label}>Hours Est/Rmn</span>
            <div className={styles.value}>
              <span>
                <span className={styles.current}>{Math.round(task?.estimated_hours)}/</span>
                {Math.round(remainingHours)}
              </span>
            </div>
          </div>
          <div>
            <span className={styles.label}>Pacing</span>
            <div className={styles.value}>
              <span>
                <span className={styles.current}>{Math.round(overUnder)}</span>({Math.round(overUnderPercent)})%
              </span>
            </div>
          </div>
          <div>
            <span className={styles.label}>%</span>
            <div className={styles.value}>
              <span>{displayPercentage}%</span>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default TaskDetailRow;
