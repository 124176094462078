import React, { useState } from "react";
import { XCircle } from "react-feather";
import { Link } from "react-router-dom";
import useProject from "../../../hooks/useProject";
import styles from "../styles.module.scss";
import DeleteConfirmation from "./DeleteConfirmation";
import usePurchaseOrder from "./context/usePurchaseOrder";

const PurchaseOrderControls = ({
  purchaseOrder,
  deletePurchaseOrder,
  deleteLoading,
  updatePurchaseOrder,
}) => {
  const {
    setSignatureModalOpen,
    sendForSignature,
    cancelSignatureRequest,
  } = usePurchaseOrder();
  const { project } = useProject();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const handleUpdate = () => {
    updatePurchaseOrder(purchaseOrder);
  };
  return (
    <>
      <div className={styles.controls}>
        <button
          className={`link ${styles.link}`}
          onClick={() => setShowDeleteConfirmation(true)}
        >
          Delete
        </button>
        <Link
          to={`/project/${!!project && project.id}/purchase-orders/${
            !!purchaseOrder && purchaseOrder.id
          }/pdf`}
          className={`link ${styles.link}`}
        >
          Print PDF
        </Link>
        {/* <button className={`link ${styles.link}`}>Send For Signature</button> */}
        {purchaseOrder.status === 0 && (
          <button className={`link ${styles.link}`} onClick={handleUpdate}>
            Save Draft
          </button>
        )}
        {purchaseOrder.status === 0 && (
          <button className={`link ${styles.link}`} onClick={sendForSignature}>
            Send For Signature
          </button>
        )}
        {purchaseOrder.status === 1 && (
          <span className={`light-text ${styles.link}`}>
            Sent for Signature
            <button
              className={`${styles.iconButton} ${styles.iconButtonSm}`}
              onClick={cancelSignatureRequest}
            >
              <XCircle />
            </button>
          </span>
        )}
        {purchaseOrder.status === 3 && (
          <button
            className="btn btn-tertiary"
            onClick={() => setSignatureModalOpen(true)}
          >
            Sign Purchase Order
          </button>
        )}
      </div>
      {!!showDeleteConfirmation && (
        <DeleteConfirmation
          setShowConfirmation={setShowDeleteConfirmation}
          deletePurchaseOrder={deletePurchaseOrder}
          deleteLoading={deleteLoading}
          id={purchaseOrder.id}
        />
      )}
    </>
  );
};

export default PurchaseOrderControls;
