
import Category from "../contactsAdminColumns/Category";

const company_categories = {
  headerName: "Category",
  field: "company_categories",
  width: 200,
  wrapText: true,
  cellRenderer: Category,
};

export default company_categories;
