import DatePicker from "react-datepicker";
import styles from "./styles.module.scss";

const TimePicker = ({
  setSelected,
  selected,
  sendRequest,
  minTime,
  maxTime,
  canEdit = false,
  style,
  placement = "bottom-start",
}) => {
  return (
    <DatePicker
      selected={selected}
      value={selected}
      onChange={(date) => {
        setSelected(date || selected);
      }}
      onBlur={() => {
        sendRequest(selected);
      }}
      onCalendarClose={() => {
        sendRequest(selected);
      }}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={15}
      timeCaption="Time"
      dateFormat={"hh : mm : aa"}
      minTime={minTime}
      maxTime={maxTime}
      className={`${canEdit ? styles.timePickerEditable : styles.timePicker} ${style}`}
      disabled={!canEdit}
      popperPlacement={placement}
    />
  );
};

export default TimePicker;
