import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { DateRangeSelector, FilterSearch, BoomSelector } from "component-library";
import { Search } from "react-feather";
import PropTypes from "prop-types";
import useLocate from "./hooks/useLocate";
import styles from "./styles.module.scss";

const LocatesListingHeader = ({
  currentPage,
  itemsPerPage,
  setPaginatedLocates,
  selectedProject,
  setSelectedProject,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  showSearch,
  setShowSearch,
  setSearchQuery,
}) => {
  const { isPC, locates, projects, updateRelocates, updateLoading } = useLocate();
  const currentFirstItem = locates.length > 0 ? (currentPage - 1) * itemsPerPage + 1 : 0;
  const currentLastItem = locates.length < itemsPerPage ? locates.length : currentPage * itemsPerPage;
  const totalItems = !!locates.length ? locates.length : 0;

  const [dateRange, setDateRange] = useState([startDate, endDate]);

  useEffect(() => {
    if (!!dateRange && dateRange.length > 1) {
      setStartDate(dateRange[0]);
      setEndDate(dateRange[1]);
    } else {
      setStartDate();
      setEndDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  return (
    <div className="d-flex justify-content-between mb-2 flex-md-column flex-lg-row">
      <div className="d-flex align-items-center">
        <div className={styles.headerText}>Locate Requests</div>
      </div>
      <div className="d-flex align-items-center">
        <span>
          {currentFirstItem} - {currentLastItem} of {totalItems}
        </span>
        <Button
          className="pt-0 pb-0"
          variant="link"
          onClick={() => {
            setPaginatedLocates(locates);
            setDateRange([]);
          }}
        >
          Show All
        </Button>
        <div className="mx-2">
          <BoomSelector
            selected={selectedProject}
            setSelected={setSelectedProject}
            options={projects}
            placeholder="Filter By Project"
          />
        </div>
        <div className="mx-2">
          <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
        </div>
        {!!showSearch ? (
          <FilterSearch
            searchFunction={setSearchQuery}
            placeholder="Filter locates"
            loading={false}
            inline
            close={() => {
              setSearchQuery("");
              setShowSearch(false);
            }}
          />
        ) : (
          <Button variant="light" onClick={() => setShowSearch(!showSearch)}>
            <Search size={14} color="#7b7b7b" />
          </Button>
        )}
        {isPC && (
          <Button variant="orange" className="ms-2 font-weight-bold" onClick={updateRelocates} disabled={updateLoading}>
            {updateLoading ? "Updating..." : "Update Locates"}
          </Button>
        )}
      </div>
    </div>
  );
};

LocatesListingHeader.propTypes = {
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  setPaginatedLocates: PropTypes.func.isRequired,
  selectedProject: PropTypes.object,
  setSelectedProject: PropTypes.func.isRequired,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  showSearch: PropTypes.bool.isRequired,
  setShowSearch: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
};

export default LocatesListingHeader;
