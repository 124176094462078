import React from "react";
import PropTypes from "prop-types";
import getLabel from "../../utils/getLabel";
import { ProjectAttachment } from "../../_projects/ProjectAttachments";
import { useAuth } from "component-library";

const NoticeToBidders = ({ typeId, attachment, setAttachment, projectId, setDocLoading, project }) => {
  const { userCan } = useAuth();

  return (
    <div className="row py-2">
      <div className="col-md-3">
        <p className="med mb-1">{getLabel("notice_to_bidders", typeId)}</p>
        <ProjectAttachment
          attachment={attachment}
          setAttachment={setAttachment}
          type="Notice to Bidders"
          projectId={projectId}
          isSingle
          hideType
          canUpload={userCan("bid-create") || userCan("bid-edit")}
          setDocLoading={setDocLoading}
          isBid={project?.status === "bid"}
        />
      </div>
    </div>
  );
};

export default NoticeToBidders;

NoticeToBidders.propTypes = {
  data: PropTypes.object,
  attachment: PropTypes.object,
  typeId: PropTypes.number,
  setAttachment: PropTypes.func,
  projectId: PropTypes.number,
};
