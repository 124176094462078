import {
  job_number,
  name,
  reactivate,
} from "./columnDefs";

const columnDefs = [
  job_number,
  name,
  reactivate,
];

export default columnDefs;