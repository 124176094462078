import React from "react";
import { Button } from "react-bootstrap";
import styles from "../../components/ContactsAdmin/ContactsAdmin.module.scss";
import { XCircle } from "react-feather";

const colors = ["#259FAD", "#8C63C4", "#0071ce", "#FFA400"];

const CertificationButton = ({ certification, updateCertification, active }) => {
  const handleSelection = () => {
    updateCertification(certification.id);
  };
  return (
    <Button
      className={`${styles.categoryButton} ${
        active ? `${styles.catActive} font-weight-bold` : ""
      }`}
      style={{
        borderColor: colors[certification.id - 1],
        color: active ? "#fff" : "#7b7b7b",
        backgroundColor: active ? colors[certification.id - 1] : "transparent",
      }}
      onClick={() => handleSelection()}
    >
      {certification.name}
      {!!active && <XCircle className={styles.close} size="12" />}
    </Button>
  );
};

export default CertificationButton;
