import React, { useState, useEffect } from "react";
import { RefreshCcw, XCircle, Loader } from "react-feather";
import { useApi, projectApi, useAuth } from "component-library";

const Relocate = ({ locate, label, setLocates }) => {
  const { user } = useAuth();
  const userName = `${user.first_name} ${user.last_name}`;
  const [loading, setLoading] = useState(false);

  const { request: relocateRequest, loading: relocateLoading, data: relocateData } = useApi(projectApi.saveRelocate);

  const { request: saveRequest, loading: saveLoading, data: saveData } = useApi(projectApi.saveLocate);

  useEffect(() => {
    if (relocateLoading || saveLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [relocateLoading, saveLoading]);

  useEffect(() => {
    if (!!relocateData && !!relocateData.locates) {
      if (!!setLocates) {
        setLocates(relocateData.locates);
      } else {
        setRelocateRequested(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relocateData]);

  useEffect(() => {
    if (!!saveData && !!saveData.locates) {
      if (!!setLocates) {
        setLocates(saveData.locates);
      } else {
        setRelocateRequested(!relocateRequested);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveData]);

  const [relocateRequested, setRelocateRequested] = useState(locate.status.toLowerCase() === "requested");

  useEffect(() => {
    if (locate) {
      setRelocateRequested(locate.status.toLowerCase() === "requested");
    }
  }, [locate]);

  const handleRelocate = () => {
    if (!loading) {
      relocateRequest({
        relocate_id: locate.id,
        id: locate.id,
        description: locate.description,
        number: null,
        status: "requested",
        type: locate.type,
        sb: locate.sb,
        ds: locate.ds,
        sb_required: locate.sb_required || 0,
        ds_required: locate.ds_required || 0,
        active_date: locate.active_date,
        expiration: locate.expiration,
        project_id: locate.project_id,
        parent_id: !!locate ? locate.parent_id : 0,
        user: userName,
      });
    }
  };

  const cancelRelocate = () => {
    if (!loading) {
      saveRequest({
        id: locate.id,
        description: locate.description,
        number: locate.number,
        status: "Canceled",
        type: locate.type,
        sb: locate.sb,
        ds: locate.ds,
        sb_required: locate.sb_required || 0,
        ds_required: locate.ds_required || 0,
        active_date: locate.active_date,
        expiration: locate.expiration,
        project_id: locate.project_id,
        parent_id: !!locate ? locate.parent_id : 0,
        user: userName,
      });
    }
  };

  return (
    <div className="d-flex align-items-center px-3">
      {loading ? (
        <Loader size={14} />
      ) : (
        <>
          {relocateRequested ? (
            <XCircle size={16} color="#A7A7A7" onClick={cancelRelocate} />
          ) : (
            <>
              <RefreshCcw size={14} color="#0071ce" onClick={handleRelocate} />
              {label && (
                <span className="ms-2 sm" style={{ color: "#0071ce" }}>
                  {label}
                </span>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Relocate;
