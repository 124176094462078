import React, { useMemo } from "react";
import { AgGridTable, useAuth } from "component-library";
import projectListConfig from "../config/projectListConfig";
import gridRowHeights from "../../../utils/gridRowHeights";
import styles from "./styles.module.scss";
import { yourProjects, otherProjects, activeProjects } from "../projectsFilter";
import { Star } from "react-feather";
import { useNavigate } from "react-router-dom";

const ListView = ({ projects }) => {
  const { user } = useAuth();
  const history = useNavigate();

  const handleCellClick = ({ data, column }) => {
    if (!["favorite"].includes(column?.colId)) {
      let url = `/project/${data.id}`;
      history(url);
    }
  };

  const favProjectsList = useMemo(() => yourProjects(projects), [projects]);
  const activeProjectsList = useMemo(() => activeProjects(projects), [projects]);
  const otherProjectsList = useMemo(() => otherProjects(projects), [projects]);

  return (
    <div className={styles.listView}>
      <h2 className={styles.title}>
        Your Projects <Star color="#F0C808" fill="#F0C808" height={16} />
      </h2>
      <AgGridTable
        gridData={favProjectsList}
        gridConfig={projectListConfig}
        rowHeight={gridRowHeights.medium}
        id={`${user.id}_project_list_your_projects`}
        presetStyle="sortableTable"
        handleCellClick={handleCellClick}
      />
      <h3 className={styles.subTitle}>Active Projects</h3>
      <AgGridTable
        gridData={activeProjectsList}
        gridConfig={projectListConfig}
        rowHeight={gridRowHeights.medium}
        id={`${user.id}_project_list_active_projects`}
        presetStyle="sortableTable"
        handleCellClick={handleCellClick}
      />
      <h3 className={styles.subTitle}>Other Projects</h3>
      <AgGridTable
        gridData={otherProjectsList}
        gridConfig={projectListConfig}
        rowHeight={gridRowHeights.medium}
        id={`${user.id}_project_list_other_projects`}
        presetStyle="sortableTable"
        handleCellClick={handleCellClick}
      />
    </div>
  );
};

export default ListView;
