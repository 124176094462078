import React from "react";

const Source = ({ data }) => {
  return (
    <>
      {!!data?.source?.locations?.length && (
        <span>
          {`${data.source.locations[0].company_name}${
            data?.source?.first_name && data?.source?.last_name
              ? `, ${data.source.first_name} ${data.source.last_name}`
              : null
          }`}
        </span>
      )}
    </>
  );
};

export default Source;
