import React, { useEffect, useState } from "react";
import useProject from "../../../hooks/useProject";
import { useAuth } from "component-library";
import { ProjectAttachmentInline } from "../../../ProjectAttachments";

const PlansUpload = () => {
  const { userCan } = useAuth();
  const { project, setProject } = useProject();

  const [plansFile, setPlansFile] = useState(project.attachments.find((a) => a.type === "Construction Plans"));

  useEffect(() => {
    if (plansFile) {
      const attachmentIds = project.attachments.map((attachment) => attachment.id);
      setProject({
        ...project,
        attachments: !attachmentIds.includes(plansFile.id) ? [...project.attachments, plansFile] : project.attachments,
      });
    } else {
      setProject({
        ...project,
        attachments: [...project.attachments.filter((attachment) => attachment.type !== "Construction Plans")],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansFile]);

  return (
    <>
      <ProjectAttachmentInline
        attachment={plansFile}
        setAttachment={setPlansFile}
        type="Construction Plans"
        isSingle={true}
        projectId={project.id}
        canUpload={userCan("bid-create") || userCan("bid-edit")}
      />
    </>
  );
};

export default PlansUpload;
