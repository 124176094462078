import { useEffect } from "react";
import { useToasts } from "component-library";

const useForms = ({
  runReportRequest,
  runReportData,
  runReportLoading,
  runReportError,
  setLoading,
  setError,
  setData,
  reportType,
}) => {
  const { triggerToast } = useToasts();
  const onSubmit = async (formData, setLoading, event) => {
    try {
      await runReportRequest(formData);
      setLoading(false);
    } catch (message) {
      setLoading(false);
    }
    event.preventDefault();
  };

  useEffect(() => {
    if (runReportLoading) {
      setLoading(runReportLoading);
    } else {
      setLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runReportLoading]);

  useEffect(() => {
    if (runReportError) {
      setError(runReportError);
    } else {
      setError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runReportError]);

  useEffect(() => {
    if (runReportData) {
      if (["cross_job_report"].includes(reportType)) {
        triggerToast(
          "Generating Report",
          "Report is generating in the background. You'll receive a notification when it's ready."
        );
      } else {
        setData(runReportData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runReportData]);

  return {
    onSubmit,
  };
};

export default useForms;
