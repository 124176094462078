import React from "react";
import styles from "./styles.module.scss";

const Section = ({ header, body }) => {
  return (
    <>
      {!!body && (
        <div className={styles.section}>
          <strong style={styles.sectionHeader}>{header}:</strong>
          <div style={styles.sectionBody}>{body}</div>
        </div>
      )}
    </>
  );
};

export default Section;
