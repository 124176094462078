import { Joi } from "component-library";

const schema = {
  name: Joi.saneString().required().label("Name"),
  bid_quantity: Joi.number().required().label("Bid Quantity"),
  cost_code: Joi.saneString().required().label("Cost Code"),
  unit_measure: Joi.number().required().label("Unit Measure"),
  rate_um: Joi.saneString().required().label("Rate Measure"),
  estimated_hours: Joi.number().required().label("Estimated Hours"),
  parent_bid_id: Joi.number().label("Parent Bid Id"),
  parent_task_id: Joi.number(),
  remove_from_app: Joi.checkbox(),
};

const initialData = {
  name: "",
  cost_code: "",
  parent_task_id: 0,
  rate_um: "16",
  remove_from_app: 0,
  estimated_hours: 0,
  bid_quantity: 0,
};

export { schema, initialData };
