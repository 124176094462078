import { AgGridTable } from "component-library";
import employeeAttendenceReportConfig from "./employeeAttendenceReportConfig";

const EmployeeAttendenceReport = ({ gridData }) => {
  return (
    <AgGridTable
      gridData={gridData}
      gridConfig={employeeAttendenceReportConfig}
      fieldsToSearch={["name", "date", "absent_notes", "absent_reason"]}
      id={`employee_attendance_report`}
    />
  );
};

export default EmployeeAttendenceReport;
