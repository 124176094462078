import React from "react";
import Select from "react-select";
import { dropdownStylesWithWidthAndColor, Loader } from "component-library";
import { Alert } from "react-bootstrap";
import { Trash2 } from "react-feather";
import styles from "./styles.module.scss";
import MinutesInput from "./../MinutesInput";
import useUpdateTimeEntry from "../../hooks/useUpdateTimeEntry";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

const TimeEntry = ({
  entry,
  config,
  tasks,
  editing,
  timesheet,
  setTimesheet,
  foremanId,
  project,
  overAllocatedTimeSheets,
  setOverAllocatedTimeSheets,
  fieldLog,
  setFieldLog,
}) => {
  const {
    removeTimeEntry,
    updateTime,
    updateLaborType,
    updateEquipment,
    updateSecondaryEquipment,
    laborOptions,
    equipmentOptions,
    secondaryEquipmentOptions,
    task,
    error,
    loading,
  } = useUpdateTimeEntry({
    entry,
    config,
    tasks,
    timesheet,
    setTimesheet,
    foremanId,
    overAllocatedTimeSheets,
    setOverAllocatedTimeSheets,
    fieldLog,
    setFieldLog,
  });

  const dropdownColor = editing && !!project && project.certified_payroll ? "#fff" : "#d3d3d3";

  return (
    <div className="position-relative">
      <Loader loading={!!loading} />
      {!!entry && (
        <div
          className={`d-flex justify-content-between align-items-center py-2 ps-2 ${styles.task} position-relative flex-grow-1`}
        >
          <div className={`${styles.taskName} col`}>
            {task?.task?.cost_code ? `${task.task.cost_code} — ` : ""}
            {entry?.task_data.task_name}
          </div>
          <div className="d-flex flex-grow-1 mr-2">
            {!!equipmentOptions?.length && (
              <Select
                styles={dropdownStylesWithWidthAndColor("auto", dropdownColor)}
                options={equipmentOptions}
                name="equipment_id"
                id="equipmentId"
                className={`${styles.select} mx-2 flex-grow-1`}
                onChange={(e) => {
                  updateEquipment(e.value);
                }}
                isDisabled={!(editing && !!project && project.certified_payroll)}
                value={equipmentOptions.find((e) => e.value === entry.equipment_id) || null}
              />
            )}
            {!!secondaryEquipmentOptions?.length && (
              <Select
                styles={dropdownStylesWithWidthAndColor("auto", dropdownColor)}
                options={secondaryEquipmentOptions}
                name="secondary_equipment_id"
                id="secondaryEquipmentId"
                className={`${styles.select} mx-2 flex-grow-1`}
                onChange={(e) => {
                  updateSecondaryEquipment(e.value);
                }}
                isDisabled={!(editing && !!project && project.certified_payroll)}
                value={secondaryEquipmentOptions.find((e) => e.value === entry.secondary_equipment_id) || null}
              />
            )}
            {!!laborOptions?.length && (
              <Select
                styles={dropdownStylesWithWidthAndColor("auto", dropdownColor)}
                options={laborOptions}
                name="labor_type_id"
                id="laborTypeId"
                className={`${styles.select} mx-2 flex-grow-1`}
                onChange={(e) => {
                  updateLaborType(e.value);
                }}
                isDisabled={!(editing && !!project && project.certified_payroll)}
                value={laborOptions.find((l) => l.value === entry.labor_id) || null}
              />
            )}
          </div>
          <div className="d-flex justify-content-end align-items-center col-md-2">
            {editing && (
              <Button variant="transparent" size="sm" onClick={removeTimeEntry} className="p-0">
                <Trash2 color="#E70000" size={16} className="me-1" />
              </Button>
            )}
            <MinutesInput
              minutes={entry.minutes}
              setMinutes={(minutes) => {
                updateTime(minutes);
              }}
              canEdit={editing}
            />
          </div>
        </div>
      )}
      {error && <Alert variant="danger">{error}</Alert>}
    </div>
  );
};

TimeEntry.propTypes = {
  entry: PropTypes.object,
  config: PropTypes.object,
  tasks: PropTypes.array,
  editing: PropTypes.bool,
  timesheet: PropTypes.object,
  setTimesheet: PropTypes.func,
  foremanId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  project: PropTypes.object,
  overAllocatedTimeSheets: PropTypes.array,
  setOverAllocatedTimeSheets: PropTypes.func,
};

export default TimeEntry;
