import React from "react";
import styles from "./styles.module.scss";
import formatGridValues from "../../../../utils/formatGridValue";

const Percentage = ({ data }) => {
  return (
    <div className={styles.percWrap}>
      {formatGridValues(data?.percentage_complete) || data?.quantity_reported_percentage || 0}%
    </div>
  );
};

export default Percentage;
