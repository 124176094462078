import React from "react";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";
import useReportingConsole from "./hooks/useReportingConsole";
import useResourceManagement from "../context/useResourceManagement";
import FilterBlock from "./FilterBlock";
import {
  AlertTriangle,
  AlertCircle,
  Truck,
  DollarSign,
  ArrowUpCircle,
  Trash2,
  Clock,
  Eye,
  EyeOff,
} from "react-feather";
import { ReactComponent as CalendarX } from "../../assets/CalendarX.svg";
import { ReactComponent as Lunch } from "../../assets/lunch.svg";

const TimesheetConsole = ({ projectId, foremanId, statuses, pm, crewId, isProject, error, loading, consoleData }) => {
  const { selectedConsoleFilters, setSelectedConsoleFilters } = useResourceManagement();

  const { filterList, consoleOpen, setConsoleOpen, handleSelectAllFilters } = useReportingConsole({
    projectId,
    foremanId,
    statuses,
    crewId,
    pm,
  });

  return (
    <div className={`${styles.wrapper} ${!!consoleOpen && !error && styles.open}`}>
      <div className="d-flex align-items-center justify-content-between">
        <div className={styles.header}>
          <h2 className={`mb-2 ${styles.consoleTitle}`}>Reporting Console</h2>
          {!error && (
            <Button variant="link" onClick={() => setConsoleOpen(!consoleOpen)}>
              {!!consoleOpen ? <Eye color="#0071ce" size={16} /> : <EyeOff color="#0071ce" size={16} />}
            </Button>
          )}
        </div>
        {!!consoleData && consoleOpen && (
          <div className="form-check d-flex align-items-center">
            <input
              onChange={(e) => {
                handleSelectAllFilters(e.target.value);
              }}
              value={selectedConsoleFilters?.length === filterList?.length ? 1 : 0}
              checked={selectedConsoleFilters?.length === filterList?.length ? 1 : 0}
              type="checkbox"
              id={`select-all-reporting-console`}
              className="form-check-input"
            />
            <label className="d-flex align-items-center form-check-label sm" htmlFor={`select-all-reporting-console`}>
              {selectedConsoleFilters?.length === filterList?.length ? "Deselect All" : "Select All"}
            </label>
          </div>
        )}
      </div>

      {!!consoleData && !!consoleOpen && !error && !loading && (
        <div className={styles.consoleRow}>
          <FilterBlock
            Icon={() => <AlertTriangle size={16} color="#fff" />}
            iconColor="#E70000"
            warningColor="#FFF6F6"
            title="Unclaimed"
            displayTitle="Unclaimed"
            timesheetCount={consoleData.unclaimed}
            selectedConsoleFilters={selectedConsoleFilters}
            setSelectedConsoleFilters={setSelectedConsoleFilters}
            isProject={isProject}
            projectId={projectId}
          />
          <FilterBlock
            Icon={() => <AlertCircle size={16} color="#fff" />}
            iconColor="#E70000"
            warningColor="#FFF6F6"
            title="Unallocated"
            displayTitle="Unallocated"
            timesheetCount={consoleData.unallocated}
            hours={consoleData.unallocated_hours}
            selectedConsoleFilters={selectedConsoleFilters}
            setSelectedConsoleFilters={setSelectedConsoleFilters}
            isProject={isProject}
          />
          <FilterBlock
            Icon={() => <Truck size={16} color="#fff" />}
            iconColor="#E70000"
            warningColor="#FFF6F6"
            title="Missing Trade"
            displayTitle="Missing Trade"
            timesheetCount={consoleData.missing_trade}
            selectedConsoleFilters={selectedConsoleFilters}
            setSelectedConsoleFilters={setSelectedConsoleFilters}
            isProject={isProject}
          />
          <FilterBlock
            Icon={() => <DollarSign size={16} color="#fff" />}
            iconColor="#FFA400"
            warningColor="#FFF8EB"
            title="Perdiem"
            displayTitle="Per Diem"
            timesheetCount={consoleData.perdiem}
            selectedConsoleFilters={selectedConsoleFilters}
            setSelectedConsoleFilters={setSelectedConsoleFilters}
            isProject={isProject}
          />
          <FilterBlock
            Icon={() => <ArrowUpCircle size={16} color="#fff" />}
            iconColor="#FFA400"
            warningColor="#FFF8EB"
            title="over_threshold"
            displayTitle="Over Threshold"
            timesheetCount={consoleData.over_threshold}
            hours={consoleData.over_threshold_hours}
            selectedConsoleFilters={selectedConsoleFilters}
            setSelectedConsoleFilters={setSelectedConsoleFilters}
            isProject={isProject}
          />
          <div className={styles.grid}>
            <FilterBlock
              size="sm"
              Icon={() => <Lunch width={16} height={16} color="#fff" />}
              iconColor="#FFA400"
              warningColor="#FFF8EB"
              title="Lunch"
              displayTitle="Lunch"
              timesheetCount={consoleData.lunch}
              selectedConsoleFilters={selectedConsoleFilters}
              setSelectedConsoleFilters={setSelectedConsoleFilters}
              isProject={isProject}
            />
            <FilterBlock
              size="sm"
              Icon={() => <CalendarX size={16} color="#fff" />}
              iconColor="#FFA400"
              warningColor="#FFF8EB"
              title="Absent"
              displayTitle="Absent"
              timesheetCount={consoleData.absent}
              selectedConsoleFilters={selectedConsoleFilters}
              setSelectedConsoleFilters={setSelectedConsoleFilters}
              isProject={isProject}
            />
            <FilterBlock
              size="sm"
              Icon={() => <Trash2 size={16} color="#fff" />}
              iconColor="#FFA400"
              warningColor="#FFF8EB"
              title="Deleted"
              displayTitle="Deleted"
              timesheetCount={consoleData.deleted}
              selectedConsoleFilters={selectedConsoleFilters}
              setSelectedConsoleFilters={setSelectedConsoleFilters}
              isProject={isProject}
            />
            <FilterBlock
              size="sm"
              Icon={() => <Clock size={16} color="#fff" />}
              iconColor="#FFA400"
              warningColor="#FFF8EB"
              title="Late"
              displayTitle="Late"
              timesheetCount={consoleData.late}
              selectedConsoleFilters={selectedConsoleFilters}
              setSelectedConsoleFilters={setSelectedConsoleFilters}
              isProject={isProject}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TimesheetConsole;
