import { useState } from "react";

const useSubcontractors = ({ day, updateDateField }) => {
  const [modalOpen, setModalOpen] = useState();
  const [addingCompany, setAddingCompany] = useState(false);
  const [addingLocation, setAddingLocation] = useState(false);

  const handleRemoveLocation = (subcontractor) => {
    const filteredSubs = day.subcontractors.filter(
      (existingSubcontractor) => existingSubcontractor.location_id !== subcontractor.location_id
    );

    updateDateField(day, "subcontractors", filteredSubs);
  };

  const handleAddLocation = (location) => {
    updateDateField(
      day,
      "subcontractors",
      !!day?.subcontractors?.length ? [...day.subcontractors, location] : [location]
    );
  };

  return {
    modalOpen,
    setModalOpen,
    addingCompany,
    setAddingCompany,
    addingLocation,
    setAddingLocation,
    handleRemoveLocation,
    handleAddLocation,
  };
};

export default useSubcontractors;
