import React, { useState, useEffect } from "react";
import { useApi, reportsApi, useFormControl, dropdownStylesWithWidth, AgGridTable, useAuth } from "component-library";
import { schema, initialData } from "../schema/laborReportSummary";
import ToFromFields from "../ToFromFields";
import useForms from "./useForms";
import { Button } from "react-bootstrap";
import laborColumnDefs from "../AdminReports/laborGridReport/laborGridReport";
import ohColumnDefs from "../AdminReports/overheadGridReport/overheadGridReport";
import { ChevronDown, ChevronUp } from "react-feather";

const LaborSummaryReport = ({ projects, setLoading, setError, setData, setShowExport }) => {
  const {
    request: runReportRequest,
    data: runReportData,
    loading: runReportLoading,
    error: runReportError,
  } = useApi(reportsApi.laborSummaryReport);

  const { onSubmit } = useForms({
    runReportRequest,
    runReportData,
    runReportLoading,
    runReportError,
    setLoading,
    setError,
    setData,
  });

  const { handleSubmit, renderTextField, renderSubmitButton, data, renderSelectField } = useFormControl({
    schema,
    initialData,
    onSubmit,
  });

  const [collapseLG, setCollapseLG] = useState(false);
  const [collapseOG, setCollapseOG] = useState(false);
  const [laborGridData, setLaborGridData] = useState();
  const [overheadGridData, setOverheadGridData] = useState();
  const [exportURL, setExportURL] = useState();
  const { user } = useAuth();

  useEffect(() => {
    if (!!runReportData?.laborGridData) {
      setLaborGridData(runReportData.laborGridData);
    }
    if (!!runReportData?.overheadGridData) {
      setOverheadGridData(runReportData.overheadGridData);
    }
    if (!!runReportData?.url) {
      setExportURL(runReportData.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runReportData]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <ToFromFields renderTextField={renderTextField} data={data} />
        </div>
        <div className="row row">
          <div className="col-6">
            {renderSelectField({
              name: "project_id",
              label: "Project",
              options: projects,
              styles: dropdownStylesWithWidth,
              emptyOption: "Select...",
            })}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          {!!exportURL && (
            <Button
              className="form-control-submit med undefined btn btn-primary mb-2 me-2"
              onClick={() => {
                setShowExport(true);
              }}
            >
              Export File
            </Button>
          )}
          <div className="mb-2">{renderSubmitButton("Run Report")}</div>
        </div>
      </form>
      {!!overheadGridData && (
        <>
          <div className="d-flex flex-row align-items-center" onClick={() => setCollapseOG(!collapseOG)}>
            <h2 className="mt-2 mb-2 me-2">Overhead Grid Data</h2>
            {!!collapseOG ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          </div>
          {!collapseOG && (
            <AgGridTable
              gridData={overheadGridData}
              gridConfig={ohColumnDefs}
              id={`${user.id}_overhead_grid`}
              suppressSizeToFit
            />
          )}
        </>
      )}
      {!!laborGridData && (
        <>
          <div className="d-flex flex-row align-items-center" onClick={() => setCollapseLG(!collapseLG)}>
            <h2 className="mt-2 mb-2 me-2">Labor Grid Data</h2>
            {!!collapseLG ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          </div>
          {!collapseLG && (
            <AgGridTable
              gridData={laborGridData}
              gridConfig={laborColumnDefs}
              id={`${user.id}_labor_grid`}
              suppressSizeToFit
            />
          )}
        </>
      )}
    </>
  );
};

export default LaborSummaryReport;
