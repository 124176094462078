import { Joi } from "component-library";

const schema = {
  attachments: Joi.any(),
};

const initialData = {
  attachments: [],
};

export { schema, initialData };
