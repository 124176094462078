const last_name = {
  headerName: "Crew",
  field: "last_name",
  flex: 1,
  minWidth: 200,
  valueGetter: ({ data }) =>
    data.last_name && data.first_name
      ? `${data.first_name} ${data.last_name}`
      : null,
};

export default last_name;
