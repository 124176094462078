import React, { useState, useEffect } from "react";
import convertMinutesToTime from "../../../utils/convertMinutesToTime";
import intuitMinutesFromFieldEntry from "../../../utils/intuitMinutesFromFieldEntry";
import styles from "./styles.module.scss";

const MinutesInput = ({
  minutes,
  setMinutes,
  canEdit,
  max
}) => {
  const [minutesText, setMinutesText] = useState(
    convertMinutesToTime(parseInt(minutes).toString())
  );
  const setInputValue = () => {
    let updated = intuitMinutesFromFieldEntry(minutesText);
    if (updated < 0) updated = 0;
    if (updated > max) updated = max;
    setMinutes(updated.toString());
    setMinutesText(convertMinutesToTime(updated));
  };

  useEffect(() => {
    setMinutesText(convertMinutesToTime(parseInt(minutes, 10)));
  }, [minutes]);

  const formatTime = (text) => {
    if (!!text && !!text.length) {
      let updated = text.replace(/[^0-9]/g, "");
      updated = updated.replace(/^0+/, "");
      if (updated.length > 4) updated = updated.slice(0, 4);
      if (updated.length === 0) return "0:00";
      if (updated.length === 1) updated = `00${updated}`;
      if (updated.length === 2) updated = `0${updated}`;
      updated = updated.replace(/(\d{1,2})(\d{2})/, "$1:$2");
      return updated;
    }
    return "0:00";
  };

  return (
    <input
      type="text"
      className={`form-control text-center ${styles.hourInput} ${!canEdit ? "bg-gray5" : undefined}`}
      disabled={!canEdit ? true : false}
      onChange={(e) => {
        setMinutesText(formatTime(e.target.value));
      }}
      onBlur={() => setInputValue() }
      value={minutesText}
    />
  );
};

export default MinutesInput;
