import React from "react";
import { FileText } from "react-feather";
import { useParams } from "react-router-dom";
import { HeaderButton } from "component-library";
import useBid from "../hooks/useBid";

const ListHeaderButtons = () => {
  const { id } = useParams();
  const { archived, bid } = useBid();

  return (
    <div className="d-flex align-items-center">
      {!archived && bid?.status !== "precon" && bid?.status !== "project" && (
        <HeaderButton
          linkTo={`/bid/${id}/rfqs/add`}
          icon={<FileText color="#0071ce" size={16} />}
          text="Generate RFQ"
        />
      )}
      <HeaderButton
        linkTo={`/bid/${id}/rfqs/print`}
        blankTarget={true}
        icon={<FileText color="#0071ce" size={16} />}
        text="Print Report"
      />
    </div>
  );
};

export default ListHeaderButtons;
