import React, { useState, useEffect } from "react";
import { X, AlertCircle, ArrowRightCircle } from "react-feather";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

///BOOM ALERT COMPONENT///
///Alerts with four different variants available
///Valid variants are "primary" (default), "secondary", "warning", and "danger"

const Alert = ({
  variant,
  title,
  message,
  dismissable,
  linkText,
  linkTo,
  onClick,
  onClickText,
  animate = true,
  shadow,
  dismiss,
}) => {
  const [variantColors, setVariantColors] = useState(["#0071CE", "#F2F8FD"]);
  const [dismissed, setDismissed] = useState(false);
  const [leaving, setLeaving] = useState(false);

  const handleDismiss = () => {
    setLeaving(true);
    setTimeout(() => setDismissed(true), 600);
  };

  useEffect(() => {
    if (dismiss) handleDismiss();
  }, [dismiss]);

  useEffect(() => {
    if (variant === "warning") {
      //orange
      setVariantColors(["#E95E00", "#FFEFE5"]);
    } else if (variant === "danger") {
      //red
      setVariantColors(["#e3242b", "#ffe5ec"]);
    } else if (variant === "secondary") {
      //green
      setVariantColors(["#5DB115", "#F2FBEA"]);
    } else {
      //blue
      setVariantColors(["#0071CE", "#F2F8FD"]);
    }
  }, [variant]);

  return (
    <>
      {!dismissed && (
        <div
          className={`${styles.wrapper} ${!!leaving ? styles.leaving : ""} ${animate ? styles.animate : ""}  ${
            shadow ? styles.shadow : ""
          }`}
          style={{ color: variantColors[0], backgroundColor: variantColors[1] }}
        >
          <div className={styles.container}>
            <div style={{ width: 24 }} className="align-self-center">
              <AlertCircle size={24} />
            </div>

            <div className={styles.text_wrapper}>
              <div className="semibold">{title}</div>

              <div className={styles.center_wrapper}>
                <div style={{ color: "#7B7B7B" }}>
                  {message}

                  {!!linkText && (
                    <Link to={linkTo} className={styles.link_text} style={{ color: variantColors[0] }}>
                      {linkText}
                      <ArrowRightCircle size={16} className="ms-1" />
                    </Link>
                  )}

                  {!!onClickText && (
                    <div onClick={onClick} className={styles.link_text} style={{ color: variantColors[0] }}>
                      {onClickText}
                      <ArrowRightCircle size={16} className="ms-1" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {!!dismissable && (
            <Button onClick={handleDismiss} variant="link" className="align-self-start">
              <X color={variantColors[0]} size={23} />
            </Button>
          )}
        </div>
      )}
    </>
  );
};

Alert.defaultProps = {
  variant: "primary",
  dismissable: true,
};

Alert.propTypes = {
  variant: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dismissable: PropTypes.bool,
  linkTo: PropTypes.string,
  linkText: PropTypes.string,
  onClick: PropTypes.func,
  onClickText: PropTypes.string,
};

export default Alert;
