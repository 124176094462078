import React from "react";
import TimeEntry from "./TimeEntry";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const TaskEntries = ({
  entries,
  config,
  editing,
  setFieldLog,
  fieldLog,
  foremanId,
  overAllocatedTimeSheets,
  setOverAllocatedTimeSheets,
  prevailingWage,
  projectId,
}) => {
  return (
    <div className={styles.timeEntries}>
      {!!entries && !!entries.length ? (
        entries.map((entry) => (
          <TimeEntry
            key={entry.id}
            entry={entry}
            editing={editing}
            setFieldLog={setFieldLog}
            fieldLog={fieldLog}
            foremanId={foremanId}
            overAllocatedTimeSheets={overAllocatedTimeSheets}
            setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
            prevailingWage={prevailingWage}
            config={config}
            projectId={projectId}
          />
        ))
      ) : (
        <div className={styles.noEntries}>No time entries have been added for this task.</div>
      )}
    </div>
  );
};

TaskEntries.propTypes = {
  entries: PropTypes.array,
  config: PropTypes.object,
  editing: PropTypes.bool,
  setFieldLog: PropTypes.func,
  fieldLog: PropTypes.object,
  foremanId: PropTypes.string,
  overAllocatedTimeSheets: PropTypes.array,
  setOverAllocatedTimeSheets: PropTypes.func,
  prevailingWage: PropTypes.any
};

export default TaskEntries;
