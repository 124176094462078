import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { projectApi, useApi } from "component-library";
import useStoredDateRanges from "./useStoredDateRanges";

const useProvideResourceManagement = () => {
  const [q, setQ] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [contextInitialLoad, setContextInitialLoad] = useState(true);
  const [projectManager, setProjectManager] = useState();
  const [projectsList, setProjectsList] = useState();
  const [calendarClosed, setCalendarClosed] = useState(false);
  const [selectedConsoleFilters, setSelectedConsoleFilters] = useState([]);
  const [selectedProject, setSelectedProject] = useState();

  // Store Dashboard Dates while in context
  const [urlParams, setURLParams] = useState();
  const [logDate, setLogDate] = useState();

  //expand all states
  const [flExpanded, setFlExpanded] = useState(false);
  const [crewExpanded, setCrewExpanded] = useState(false);
  const [crewDetailExpanded, setCrewDetailExpanded] = useState(false);

  const { request: projectsRequest, data: projectsData, loading: projectsLoading } = useApi(projectApi.getProjectsList);

  const getStoredDateRange = () => {
    const storedStartDate = localStorage.getItem("resource_mgmt_start_date");
    const storedEndDate = localStorage.getItem("resource_mgmt_end_date");
    if (!!storedStartDate && !!storedEndDate) {
      return [storedStartDate, storedEndDate];
    } else {
      return [DateTime.now().minus({ days: 7 }).toJSDate(), DateTime.now().toJSDate()];
    }
  };

  useEffect(() => {
    projectsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!projectsData && !!projectsData.projects) {
      setProjectsList(
        projectsData.projects.map((project) => {
          return {
            value: project.id,
            label: project.name,
          };
        })
      );
    }
  }, [projectsData]);

  useStoredDateRanges(dateRange, "resource_mgmt_start_date", "resource_mgmt_end_date")
  
  useEffect(() => {
    if (!!contextInitialLoad) {
      const storedDateRange = getStoredDateRange();
      setDateRange([new Date(storedDateRange[0]), new Date(storedDateRange[1])]);
      setContextInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.location.search) {
      setURLParams(window.location.search);
    }
  }, []);

  useEffect(() => {
    if (!!urlParams) {
      const params = new URLSearchParams(urlParams);
      setLogDate(params.get("date"));
    }
  }, [urlParams]);

  const handleOnDateBlur = () => {
    if (!!dateRange[0]) {
      setCalendarClosed(true);
    }
  };

  useEffect(() => {
    if (!!contextInitialLoad) {
      setCalendarClosed(true);
    }
  }, [contextInitialLoad]);

  return {
    q,
    setQ,
    dateRange,
    setDateRange,
    statuses,
    setStatuses,
    contextInitialLoad,
    setContextInitialLoad,
    projectManager,
    setProjectManager,
    logDate,
    setLogDate,
    getStoredDateRange,
    projectsList,
    setProjectsList,
    projectsLoading,
    handleOnDateBlur,
    calendarClosed,
    setCalendarClosed,
    selectedConsoleFilters,
    setSelectedConsoleFilters,
    selectedProject,
    setSelectedProject,
    flExpanded,
    setFlExpanded,
    crewExpanded,
    setCrewExpanded,
    crewDetailExpanded,
    setCrewDetailExpanded,
  };
};

export default useProvideResourceManagement;
