import React from "react";
import { Button, Modal } from "react-bootstrap";
import useAddCompany from "../ContactsAdmin/hooks/useAddCompany";
import styles from "./styles/Company.module.scss";

const AddCompanyForm = ({ setCompany, initialCompany, setAddingCompany }) => {
  const {
    handleSubmit: handleCompanySubmit,
    fields: companyFields,
    renderSubmitButton: renderCompanySubmit,
  } = useAddCompany({
    setCompany,
    initialCompany,
    setAddingCompany,
    singleLocation: true,
  });

  return (
    <>
      <Modal.Header closeButton>
        <h5 className="mb-0 lg font-weight-bold">Add New Company</h5>
      </Modal.Header>
      <form onSubmit={handleCompanySubmit}>
        <Modal.Body>{companyFields}</Modal.Body>
        <Modal.Footer>
          <Button variant="gray2" className={styles.modalCancelButton} onClick={() => setAddingCompany(false)}>
            Cancel
          </Button>
          {renderCompanySubmit("Add Company", false, styles.submitButton)}
        </Modal.Footer>
      </form>
    </>
  );
};

export default AddCompanyForm;
