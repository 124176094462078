import React from "react";
import useRolesAdmin from "../../hooks/useRolesAdmin";
import useEditRole from "../../hooks/useEditRole";
import { Alert, Table, Button, Modal } from "react-bootstrap";
import { useAuth } from "component-library";
import styles from "./styles.module.scss";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import AdminIcon from "../../../icons/AdminIcon";
import UnauthorizedCard from "../../../components/UnauthorizedCard";

const RolesAdmin = () => {
  const { userCan } = useAuth();
  const { roles, error, successMessage, setSuccessMessage, setError, setRoles } = useRolesAdmin();

  const { editRole, setEditRole, handleSubmit, fields, setFields, renderSubmitButton } = useEditRole(
    setRoles,
    roles,
    setSuccessMessage
  );

  return (
    <InteriorPage
      pageTitle="View and edit roles"
      navTitle="Admin"
      loading={!roles && !error ? true : false}
      navIcon={<AdminIcon color="#fff" size={14} />}
    >
      {userCan("role-list") ? (
        <>
          {!!error && (
            <Alert variant="danger" onClick={() => setError(false)}>
              {error}
            </Alert>
          )}
          {!!successMessage && (
            <Alert variant="success" onClick={() => setSuccessMessage(false)} dismissible>
              {successMessage}
            </Alert>
          )}

          {!!roles && !!roles.length && (
            <>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Role</th>
                    <th>Permissions</th>
                    {userCan("role-edit") && <th>Edit</th>}
                  </tr>
                </thead>
                <tbody>
                  {roles.map((role) => (
                    <tr key={`${role.id}-${role.name}`}>
                      <td>{role.name}</td>
                      <td>{role.permissions.map((p) => p.name).join(", ")}</td>
                      {userCan("role-edit") && (
                        <td>
                          <Button
                            size="sm"
                            onClick={() => {
                              setFields({
                                name: role.name,
                              });
                              setEditRole({
                                ...role,
                                permissions: role.permissions.map((p) => p.name),
                              });
                            }}
                            aria-label={`Edit ${role.name} role`}
                          >
                            Edit
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Modal animation={false} centered show={!!editRole} onHide={() => setEditRole()}>
                <Modal.Header closeButton closeLabel="close edit role modal">
                  <h5 className="mb-0 xl">{`Edit Role ${editRole && editRole.name}`}</h5>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                  <Modal.Body>{fields}</Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setEditRole()} className={styles.submit} aria-label="cancel edit role modal">
                      Cancel
                    </Button>
                    {renderSubmitButton("Save Edits")}
                  </Modal.Footer>
                </form>
              </Modal>
            </>
          )}
        </>
      ) : (
        <UnauthorizedCard />
      )}
    </InteriorPage>
  );
};

export default RolesAdmin;
