import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "../../../components/PrivateRoute";
import Modules from "./pages/Modules";
import AppSetttings from "./pages/AppSettings";
import ProvideSuperAdmin from "./context/ProvideSuperAdmin";

const SuperAdmin = () => {
  return (
    <ProvideSuperAdmin>
      <Routes>
        <Route index element={<Navigate to="modules" />} />
        <Route path="modules" element={<PrivateRoute viewPermission="admin-users" Component={<Modules />} />} />
        <Route
          path="app-settings"
          element={<PrivateRoute viewPermission="admin-users" Component={<AppSetttings />} />}
        />
        {/* <Route path="company-details" viewPermission="admin-users" element={<PrivateRoute Component={<CompanyDetails />} />} />
        <Route path="project-settings" viewPermission="admin-users" element={<PrivateRoute Component={<ProjectSettings />} />} /> */}
      </Routes>
    </ProvideSuperAdmin>
  );
};

export default SuperAdmin;
