import React, { useEffect, useState } from "react";

const ToFromFields = ({
  data,
  renderTextField,
  setFields,
  useStoredDate = false,
}) => {
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();

  useEffect(() => {
    if (!!useStoredDate && setFields) {
      const storedStartDate = localStorage.getItem("resource_mgmt_start_date");
      const storedEndDate = localStorage.getItem("resource_mgmt_end_date");
      setFields({
        ...data,
        from_date: new Date(storedStartDate).toISOString(),
        to_date: new Date(storedEndDate).toISOString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useStoredDate]);

  useEffect(() => {
    if (data?.from_date) {
      let date = new Date(data.from_date);
      setMinDate(date.setDate(date.getDate() + 1));
    } else {
      setMinDate();
    }
  }, [data?.from_date]);

  useEffect(() => {
    if (data?.to_date) {
      let date = new Date(data.to_date);
      setMaxDate(date.setDate(date.getDate() + 1));
    } else {
      setMaxDate();
    }
  }, [data?.to_date]);

  return (
    <>
      <div className="col-6">
        {renderTextField({
          name: "from_date",
          label: "From Date",
          placeholder: "mm/dd/yyyy",
          type: "date",
          maxDate: maxDate,
        })}
      </div>
      <div className="col-6">
        {renderTextField({
          name: "to_date",
          label: "To Date",
          placeholder: "mm/dd/yyyy",
          type: "date",
          minDate: minDate,
        })}
      </div>
    </>
  );
};

export default ToFromFields;
