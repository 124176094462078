import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";
import UPBUpload from "../../PreconChecklist/UPBUpload/UPBUpload";


const RefreshImport = ({ formData }) => {
  const [alert, setAlert] = useState(null);

  return (
    <>
      <p className="med mb-1">Estimate Import</p>
      <UPBUpload setAlert={setAlert} hasWarning={!formData.upb_import_id} showDate />
      {!!alert && !!alert.length && (
        <Alert onClick={() => setAlert("")} dismissible variant="success">
          {alert}
        </Alert>
      )}
    </>
  );
};

export default RefreshImport;

RefreshImport.propTypes = {
  formData: PropTypes.object,
};
