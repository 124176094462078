import React from "react";
import { StyledDatePicker, displayDate } from "component-library";
import { DateTime } from "luxon";
import styles from "../styles.module.scss";

const PurchaseOrderDate = ({ updatePurchaseOrder, purchaseOrder }) => {
  const handleChange = (date) => {
    updatePurchaseOrder({ id: purchaseOrder.id, po_date: date.slice(0, 10) });
  };

  return (
    <div className={`d-flex align-items-center ${styles.datePicker}`}>
      <label className={styles.dateLabel}>PO Date</label>
      {purchaseOrder.status === 0 ? (
        <StyledDatePicker
          name="po_date"
          id="po_date"
          selected={Date.parse(`${purchaseOrder.po_date}T12:00`)}
          onChange={(date) => handleChange(DateTime.fromJSDate(date).toISO())}
          isClearable
        />
      ) : (
        <>{!!purchaseOrder?.po_date && displayDate(purchaseOrder.po_date)}</>
      )}
    </div>
  );
};

export default PurchaseOrderDate;
