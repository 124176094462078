import React from "react";
import styles from "./styles.module.scss";

const EntryRow = ({ equipment }) => {
  return (
    <div className="mb-2">
      <div className={styles.idBar}>
        <span className={styles.actionTitle}>
          {equipment?.equipment_id || equipment?.description
            ? `${equipment?.equipment_id} ${equipment.description}`
            : equipment.sched_material}
        </span>
      </div>
      <div className={styles.listsWrapper}>
        {!!equipment?.overall_condition_satisfactory && (
          <div>
            <span className={styles.listHeadline}>Vehicle Status:</span>
            <ul>
              <li>{equipment.overall_condition_satisfactory === 1 ? "Satisfactory" : "Unsatisfactory"}</li>
            </ul>
          </div>
        )}
        <div>
          <span className={styles.listHeadline}>Recorded Location:</span>
          <ul>
            <li>{equipment?.delivery_reported_address}</li>
          </ul>
        </div>
        <div className={styles.notesWrapper}>
          <span className={styles.listHeadline}>Notes/Alerts:</span>
          <ul>
            <li>{equipment?.tag_description || "None"}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EntryRow;
