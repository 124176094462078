import { useState, useEffect } from "react";
import { DateTime } from "luxon";

const useDriverTimeline = ({ driverData, timelineRequest, timelineRequestData }) => {
  const today = new Date();
  const [selectedTimelineDate, setSelectedTimelineDate] = useState(today);
  const [timelineData, setTimelineData] = useState([]);
  const getActivityType = (value) => {
    if (value) {
      let type;
      let label;
      switch (true) {
        case value === "pickup":
          type = "pickup";
          label = "Pick Up";
          break;
        case value === "dropoff":
          type = "dropoff";
          label = "Drop Off";
          break;
        case value === "end":
          type = "stop";
          label = "Trucking End";
          break;
        case value === "manual":
          type = "inspection";
          label = "Manual";
          break;
        case value === "issue":
          type = "shop";
          label = "Field Report";
          break;
        case value === "checkin":
          type = "checkin";
          label = "Check In";
          break;
        case value === "start":
          type = "start";
          label = "Start";
          break;
        case value === "inspect":
          type = "inspection";
          label = "Vehicle Inspection";
          break;
        default:
          type = "shop";
          label = "Unknown";
          break;
      }
      return { label: label, type: type };
    }
  };

  useEffect(() => {
    if (driverData?.sched_assigned_to_user_id) {
      timelineRequest(
        0,
        DateTime.fromJSDate(selectedTimelineDate).toFormat("yyyy-MM-dd"),
        DateTime.fromJSDate(selectedTimelineDate).toFormat("yyyy-MM-dd"),
        "all_types",
        today.getTimezoneOffset(),
        driverData.sched_assigned_to_user_id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimelineDate]);

  useEffect(() => {
    if (timelineRequestData?.data) {
      setTimelineData(timelineRequestData.data);
    } else {
      setTimelineData([]);
    }
  }, [timelineRequestData]);

  return {
    getActivityType,
    timelineData,
    today,
    selectedTimelineDate,
    setSelectedTimelineDate,
  };
};

export default useDriverTimeline;
