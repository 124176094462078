const bidFieldLabels = {
  description: {
    3: "Project Description for Bid Bond",
    6: "Project Description for Bid Bond",
    default: "Project Description",
  },
  notice_to_bidders: {
    3: "Proposal",
    6: "Proposal",
    default: "Notice to Bidders",
  },
  owner_project_number: {
    3: "Proposal No.",
    6: "Proposal No.",
    default: "Owner Bid Number",
  },
};

export default bidFieldLabels;