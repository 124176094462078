import React from "react";
import PropTypes from "prop-types";

const LineItemInput = ({setValue, value, originalValue, handleUpdate, field }) => {
  return (
    <input
      onChange={({ target: { value } }) =>
        setValue(value)
      }
      onBlur={({ target: { value } }) => {
        if (parseFloat(originalValue) !== parseFloat(value)) {
          handleUpdate(field, value);
        }
      }}
      className="tableInput"
      name={field}
      value={!!value ? value : 0}
    />
  )
}

LineItemInput.propTypes = {
  setValue: PropTypes.func,
  value: PropTypes.any,
  handleUpdate: PropTypes.func,
  field: PropTypes.string,
  originalValue: PropTypes.any
};

export default LineItemInput;