import React from "react";
import CalloutLink from "../../../components/CalloutLink";
import useProject from "../../hooks/useProject";
import Milestone from "./Milestone";
import styles from "./styles.module.scss";
import useUpcomingMilestones from "./useUpcomingMilestones";

const MilestonesList = () => {
  const { project } = useProject();
  const upcomingMilestones = useUpcomingMilestones();
  return (
    <div className={styles.milestonesList}>
      <div className={styles.milestones}>
        { upcomingMilestones.map((m) => <Milestone milestone={m} key={m.id} />)}
      </div>
      <div className={styles.pageLinkWrap}>
        <CalloutLink url={`/project/${project.id}/schedule`}>
          Project Schedule
        </CalloutLink>
      </div>
    </div>
  );
};

export default MilestonesList;
