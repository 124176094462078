import { useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { Type } from "react-feather";
import styles from "./styles.module.scss";
import { schema, initialData } from "./photo";
import { useFormControl, useApi, photoLibraryApi } from "component-library";
import usePhotoLibrary from "../../context/usePhotoLibrary";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import PropTypes from "prop-types";

const useRenamePhoto = ({ photoDetails, setPhotoDetails, setShowModal }) => {
  const { photoId } = useParams();
  const { setMessage } = usePhotoLibrary();

  const {
    request: renamePhotoRequest,
    data: renamePhotoData,
    loading: renamePhotoLoading,
    error: renamePhotoError,
  } = useApi(photoLibraryApi.updatePhotoDetails);

  const onSubmit = (formData) => {
    renamePhotoRequest({
      photo_id: photoId,
      display_name: formData.name,
    });
  };

  const { handleSubmit, renderTextField, data } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const onHide = () => {
    setShowModal(false);
  };

  const headerContent = (
    <>
      <div className="d-flex align-items-center">
        <Type color="#0071ce" size={16} />
        <h2 className={styles.modalTitle}>Rename Photo</h2>
      </div>
    </>
  );

  const bodyContent = (
    <>
      {renamePhotoError && <Alert variant="danger">{renamePhotoError}</Alert>}
      <div className="position-relative">
        {renamePhotoLoading && (
          <div className="loaderWrapper">
            <BeatLoader color={"#0071ce"} />
          </div>
        )}
        <div>Current Title</div>
        <div className="d-flex">
          <div className={styles.currentTitleWrapper}>
            <span className={styles.currentTitle}>{photoDetails?.display_name || photoDetails?.file_name}</span>
          </div>
        </div>
      </div>
      <h3 className={styles.formTitle}>New Title</h3>
      <form id="rename-photo" onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="row">
            {renderTextField({
              name: "name",
              label: "Enter your new photo title here",
            })}
          </div>
        </div>
      </form>
    </>
  );
  const footerContent = (
    <>
      <Button
        variant="orange"
        className="med d-flex align-items-center"
        form="rename-photo"
        type="submit"
        aria-label="Rename existing photo name"
        disabled={!data?.name || data?.name.length >= 255 || renamePhotoLoading}
      >
        Rename Photo
      </Button>
    </>
  );

  useEffect(() => {
    if (renamePhotoData?.photo) {
      setPhotoDetails(renamePhotoData.photo);
      setShowModal(false);
      setMessage("Photo name updated successfully");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renamePhotoData]);

  return {
    headerContent,
    bodyContent,
    footerContent,
    onHide,
  };
};

useRenamePhoto.propTypes = {
  photoDetails: PropTypes.object,
  setPhotoDetails: PropTypes.func,
  setShowModal: PropTypes.func,
};

export default useRenamePhoto;
