import React from "react";
import styles from "./styles.module.scss";
import checkReportFS from "../../../../utils/checkReportFS";
import formatGridValue from "../../../../utils/formatGridValue";

const HoursRepEarned = ({ data }) => {
  const getEarnedHours = (estimatedHours) => {
    let hours = 0;
    if (!!estimatedHours) {
    }
    hours = formatGridValue(estimatedHours);
    return hours;
  };

  const getReportedHours = (reportedHours) => {
    let hours = 0;
    if (!!reportedHours) {
      hours = formatGridValue(reportedHours);
    }

    return hours;
  };

  const reportedHours = getReportedHours(data?.range?.[0]?.reported_hours || data?.reported_hours);
  const earnedHours = getEarnedHours(data?.earned_hours);

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.reported} ${checkReportFS([reportedHours, earnedHours], 14) ? "sm" : "med"}`}>
        {reportedHours}/<span className={styles.estimate}>{earnedHours}</span>
      </div>
    </div>
  );
};

export default HoursRepEarned;
