import { useEffect, useReducer } from "react";
import { photoLibraryApi, useApi } from "component-library";
import { DateTime } from "luxon";
import { reducer, createInitialState } from "./useReducer";

const useSelectedReportPhotos = ({ project, day, report, updateDateField, onHide }) => {
  const [state, dispatch] = useReducer(reducer, { day, report }, createInitialState);

  const {
    request: photosRequest,
    data: photosData,
    loading: photosLoading,
    error: photosError,
  } = useApi(photoLibraryApi.getPhotosByProjectAndDate);

  const {
    request: albumsRequest,
    data: albumsData,
    loading: albumsLoading,
    error: albumsError,
  } = useApi(photoLibraryApi.getAlbumsByProjectAndDate);

  const handleSearch = (value) => {
    dispatch({ type: "set_q", q: value });
  };

  const handleUpdateDateRange = (dateRange) => {
    dispatch({ type: "update_date_range", dateRange: dateRange });
  };

  const handleSearchChange = (value) => {
    dispatch({ type: "set_q", q: value });
  };

  const formatDate = (date) => {
    return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
  };

  const refreshLists = () => {
    let requestParams;
    if (project?.id) {
      if (!!state.dateRange[1]) {
        requestParams = {
          project_id: project.id,
          from_date: formatDate(state.dateRange[0]),
          to_date: formatDate(state.dateRange[1]),
        };
      }

      if (!state.dateRange[0]) {
        requestParams = {
          project_id: project.id,
        };
      }

      requestParams = {
        ...requestParams,
        q: state.q,
      };

      albumsRequest({
        project_id: project.id,
        q: state.q,
      });
      photosRequest(requestParams);
    }
  };

  const handleUpdatePhotosList = (photosList) => {
    dispatch({ type: "update_photos_list", photosList: photosList });
  };

  const handleStoredChange = (selectedValue, photoWithId) => {
    let updatedPhotos;
    let updatedIds;
    if (selectedValue === 0) {
      updatedPhotos = state.selectedPhotos.filter((photo) => photoWithId?.id !== photo?.id);
      updatedIds = state.selectedPhotoIds.filter((photoId) => photoWithId?.id !== photoId);
    } else {
      updatedPhotos = !!state.selectedPhotos?.length ? [...state.selectedPhotos, photoWithId] : [photoWithId];
      updatedIds = !!state.selectedPhotoIds?.length ? [...state.selectedPhotoIds, photoWithId.id] : [photoWithId.id];
    }
    dispatch({ type: "update_selected_photos", updatedPhotos, updatedIds });
  };

  const handleSaveModalSelections = (selectedPhotos) => {
    updateDateField(day, "files", selectedPhotos);
    onHide();
  };

  const handleUpdateView = (status, albumId) => {
    dispatch({ type: "update_view", status, albumId });
  };

  const toggleShowUploadModal = () => {
    dispatch({ type: "toggle_upload_modal" });
  };

  useEffect(() => {
    if (!!photosData?.photo) {
      dispatch({ type: "set_initial_photos_list", photos: photosData?.photo });
    }
  }, [photosData]);

  useEffect(() => {
    if (!!albumsData?.album) {
      dispatch({ type: "set_initial_albums_list", albums: albumsData?.album });
    }
  }, [albumsData]);

  useEffect(() => {
    if (project?.id) {
      if (!state.dateRange[0] || !!state.dateRange[1]) refreshLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, state?.q, state?.dateRange]);

  return {
    albumsList: state.albumsList,
    photosList: state.photosList,
    dateRange: state.dateRange,
    selectedPhotos: state.selectedPhotos,
    selectedPhotoIds: state.selectedPhotoIds,
    view: state.view,
    viewingAlbumId: state.viewingAlbumId,
    showUploadModal: state.showUploadModal,
    handleUpdateDateRange,
    handleSearchChange,
    handleSearch,
    photosLoading,
    albumsLoading,
    photosError,
    albumsError,
    refreshLists,
    handleUpdatePhotosList,
    handleStoredChange,
    handleSaveModalSelections,
    handleUpdateView,
    toggleShowUploadModal,
  };
};

export default useSelectedReportPhotos;
