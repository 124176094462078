/**
 * removes duplicate values for a specific field in an array of objects
 * @param  {Array} options an array of objects
 * @param  {String} field the field within the object you want to be filtered
 * @return {Array} returns the array of objects with the duplicate values filetered out
 */

const removeDuplicateValues = (options, field = "value") => {
  let uniqueValues = [];
  const unique = options.filter((element) => {
    const isDuplicate = uniqueValues.includes(element[field]);
    if (!isDuplicate) {
      uniqueValues.push(element[field]);
      return true;
    }
    return false;
  });
  return unique;
};

export default removeDuplicateValues
