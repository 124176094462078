import React, { useState, useEffect } from 'react';
import styles from "../styles.module.scss";
import PropTypes from "prop-types";

const ProjectDotCatList = ({ renderCheckboxInGroup, options, setListValues, listValues }) => {
  const [optionList, setOptionList] = useState();
  const [hasWarning, setHasWarning] = useState(true);

  const setValuesArray = (values) => {
    setListValues(values);
  }

  useEffect(() => {
    if (!!options) {
      let newList = [];
      Object.values(options).forEach(option => (option).forEach(item => newList.push(item)));
      setOptionList(
        newList.map(item => ({
          value: item.id,
          label: item.label,
        }))
      );
    }
  },[options])

  useEffect(() => {
    if (!!listValues && listValues.length > 0) {
      setHasWarning(false)
    } else {
      setHasWarning(true)
    }
  }, [listValues])
  

  return (
    <>
      <h2 className={styles.heading16}>DOT Categories</h2>
      <div className={styles.checkboxWrap}>
        {!!optionList && (
          <>
            {optionList.map((cat) => (
              <div key={cat.value}>
                {
                  renderCheckboxInGroup({
                    groupName: 'dot_categories',
                    name: cat.value,
                    label: cat.label,
                    setValuesArray,
                    valuesArray: listValues,
                    hasWarning: hasWarning
                  })
                }
              </div>
            ))}
          </>
        )}

        </div>
    </>
  );
}

export default ProjectDotCatList;

ProjectDotCatList.propTypes = {
  renderCheckboxInGroup: PropTypes.func.isRequired,
  options: PropTypes.object,
  listValues: PropTypes.array,
  setListValues: PropTypes.func
};