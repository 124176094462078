import {
  adjusted_status,
  amount,
  id,
  supplier_name,
} from "./columnDefs";

const columnDefs = [
  id,
  supplier_name,
  adjusted_status,
  amount,
];

export default columnDefs;