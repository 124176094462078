import React, { useState, useEffect } from "react";
import { AgGridMasterDetail, useAuth } from "component-library";
import Pagination from "../../../../../../components/Pagination";
import gridRowHeights from "../../../../../../utils/gridRowHeights";
import PrintableTasks from "../PrintableTasks";
import useCellConfig from "../../../../../../config/taskReportingConfig/taskDetailConfig";
import taskReportingConfig from "../../../../../../config/taskReportingConfig/taskReportingConfig";

const Tasks = ({
  tasks,
  selectedFilterOption,
  refreshColumns,
  setRefreshColumns,
  expandAll,
  setExpandAll,
  currentPage,
  setCurrentPage,
}) => {
  const { user } = useAuth();
  const itemsPerPage = 15;
  const detailCellConfig = useCellConfig({ selectedFilterOption });
  const [paginatedTasks, setPaginatedTasks] = useState(tasks.slice(0, itemsPerPage));

  const paginateList = (list, pageNumber) => {
    let newList = list;
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    let result = newList.slice(startIndex, endIndex);
    return result;
  };

  useEffect(() => {
    if (!!currentPage && !!tasks) {
      setExpandAll(false);
      const tasksWithId = tasks.map((task) => {
        if (task.item_id === 0) {
          return {
            ...task,
            item_id: task.tasks[0].task_id,
          };
        } else {
          return task;
        }
      });

      setPaginatedTasks(paginateList(tasksWithId, currentPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, tasks]);

  return (
    <>
      {!!tasks?.length && (
        <>
          <div className="hiddenPrint">
            <AgGridMasterDetail
              gridData={paginatedTasks}
              gridConfig={taskReportingConfig}
              id={`${user.id}_task_reporting_summary`}
              rowHeight={gridRowHeights.medium}
              isRowMaster={(dataItem) => (dataItem?.tasks?.length ? true : false)}
              detailCellConfig={detailCellConfig}
              getDetailRowData={(params) => {
                params.successCallback(params.data?.tasks);
              }}
              suppressSizeToFit
              rowIdField="item_id"
              presetStyle="standardMasterDetail"
              refreshColumns={refreshColumns}
              setRefreshColumns={setRefreshColumns}
              expandAll={expandAll}
              autoHeight
            />
          </div>
          <div className="showPrint">
            <PrintableTasks taskData={paginatedTasks} />
          </div>
          <Pagination
            items={tasks}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            total={tasks.length}
          />
        </>
      )}
    </>
  );
};

export default Tasks;
