import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { initialData, schema } from "../../../schema/company";
import { useFormControl, useApi, Loader } from "component-library";
import companiesApi from "../../../api/companies";
import CompanyFields from "../CompanyFields";

const useAddCompany = ({ setCompany, setAddingCompany, initialCompany, singleLocation, isSearch }) => {
  const {
    request: createRequest,
    data: createData,
    loading: createLoading,
    error: createError,
  } = useApi(companiesApi.createCompany);
  const {
    request: updateRequest,
    data: updateData,
    loading: updateLoading,
    error: updateError,
  } = useApi(companiesApi.updateCompany);

  const [error, setError] = useState(false);
  const [load, setLoad] = useState(false);

  const onSubmit = (formData, setLoading) => {
    const companyObject = {
      name: formData.name,
      company_categories: formData.company_categories,
      certifications: formData.certifications,
    };

    if (!!initialCompany) {
      updateRequest({ ...companyObject, id: initialCompany.id });
    } else {
      createRequest({ ...companyObject, locations: data.locations });
    }
    setLoading(false);
  };

  const updateLocations = (loc, value, field) => {
    setFields({
      ...data,
      locations: data.locations.map((location) => {
        if (loc.id === location.id) {
          if (field !== "name" && field !== "phone") {
            return {
              ...location,
              address: {
                ...loc.address,
                [field]: value,
              },
            };
          } else {
            return {
              ...loc,
              [field]: value,
            };
          }
        } else {
          return location;
        }
      }),
    });
  };

  const { handleSubmit, renderSubmitButton, renderTextField, renderSelectField, data, errors, setFields } =
    useFormControl({
      schema,
      onSubmit,
      initialData: !!initialCompany
        ? {
            ...initialCompany,
            locations:
              !!initialCompany && !!initialCompany.locations && !!initialCompany.locations.length
                ? initialCompany.locations
                : [],
            company_categories: !!initialCompany.company_categories ? initialCompany.company_categories : [],
          }
        : initialData,
    });

  useEffect(() => {
    if (!!updateData) {
      setCompany({
        ...initialCompany,
        name: updateData.company.name,
        company_categories: updateData.company.company_categories,
        certifications: updateData.company.certifications,
      });
      setAddingCompany(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    if (!!createData && !!createData.success) {
      if (!!isSearch) {
        setCompany(createData.company);
      } else {
        const newLocation = createData.company.locations[0];
        setCompany({
          location_id: newLocation.id,
          company_id: createData.company.id,
          company_name: createData.company.name,
          location_name: newLocation.name,
          address1: newLocation.address.address1,
          address2: newLocation.address.address2,
          city: newLocation.address.city,
          state: newLocation.address.state,
          zip: newLocation.address.zip,
          phone: newLocation.phone,
        });
      }
      setAddingCompany(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData]);

  useEffect(() => {
    if (!!createError || !!updateError) {
      setError(createError || updateError);
    } else {
      setError();
    }
  }, [createError, updateError]);

  useEffect(() => {
    if (!!createLoading || !!updateLoading) {
      setLoad(createLoading || updateLoading);
    } else {
      setLoad();
    }
  }, [createLoading, updateLoading]);

  const fields = (
    <div className="position-relative">
      <Loader loading={!!load} />
      {error && <Alert variant="danger">{error}</Alert>}
      <CompanyFields
        data={data}
        renderSelectField={renderSelectField}
        renderTextField={renderTextField}
        initialCompany={initialCompany}
        locations={data.locations}
        setFields={setFields}
        updateLocations={updateLocations}
        singleLocation={singleLocation}
        error={errors}
      />
    </div>
  );

  return {
    fields,
    handleSubmit,
    renderSubmitButton,
  };
};

export default useAddCompany;
