import React from "react";
import { Modal, Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import { X } from "react-feather";
import PropTypes from "prop-types";

const ModalWrapper = ({
  showModal,
  headerContent,
  bodyContent,
  footerContent,
  hideCancel = false,
  onHide,
  size = "lg",
}) => {
  return (
    <Modal centered animation={false} show={!!showModal} size={size} onHide={() => onHide()}>
      <Modal.Header className={styles.header}>
        {headerContent}
        <Button className={styles.closeButton} onClick={() => onHide()}>
          <X />
        </Button>
      </Modal.Header>
      <Modal.Body className={styles.body}>{bodyContent}</Modal.Body>
      {!!footerContent && (
        <Modal.Footer className={styles.footer}>
          {!hideCancel && (
            <Button
              onClick={() => {
                onHide();
              }}
              variant="light-gray"
              className="med d-flex align-items-center me-3"
            >
              Cancel
            </Button>
          )}
          {footerContent}
        </Modal.Footer>
      )}
    </Modal>
  );
};

ModalWrapper.propTypes = {
  album: PropTypes.object,
  showModal: PropTypes.bool,
  headerContent: PropTypes.any,
  bodyContent: PropTypes.any,
  footerContent: PropTypes.any,
};

export default ModalWrapper;
