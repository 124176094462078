import React, { useEffect, useState } from "react";
import { FancyFilters, formatApiDate, Loader, projectApi, useApi } from "component-library";
import WeatherHourlyGrid from "./WeatherHourlyGrid";
import styles from "./styles.module.scss";
import { DateTime } from "luxon";
import ReportSelector from "./ReportSelector";
import removeDuplicateValues from "../../utils/removeDuplicateValues";

const WeatherPage = () => {
  const [gridData, setGridData] = useState();
  const [project, setProject] = useState(null);
  const [projects, setProjects] = useState();
  const [date, setDate] = useState(new Date());
  const [reportProject, setReportProject] = useState("");
  const [reportDate, setReportDate] = useState("");
  const [urlParams, setURLParams] = useState();

  const [filters, setFilters] = useState();
  const [filterOptions, setFilterOptions] = useState();
  const [filteredGridData, setFilteredGridData] = useState();

  const { request, data, loading } = useApi(projectApi.getWeatherHistory);

  const { request: projectsRequest, data: projectsData, loading: projectsLoading } = useApi(projectApi.getProjectsList);

  const displayFullDate = (date) => {
    return DateTime.fromJSDate(date).toFormat("DDDD");
  };

  const addOneDay = (date) => {
    return new Date(date.getTime() + 24 * 60 * 60 * 1000);
  };

  const handleRunReport = () => {
    if (!!project && !!date) {
      request(!!project?.value ? project.value : project, formatApiDate(date));
      setReportDate(displayFullDate(date));
      setReportProject(project.label);
    }
  };

  const applyFilters = (rowData, filters) => {
    let transformedFilters = [...filters];
    return rowData.filter((single) => transformedFilters.includes(`${single.current_conditions}`));
  };

  useEffect(() => {
    projectsRequest();
    if (window.location.search) {
      setURLParams(window.location.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!projectsData?.projects) {
      const filteredProjects = projectsData.projects.filter((project) => !!project?.job_number && project);
      const formattedProjects = filteredProjects.map((project) => {
        return {
          label: `${project.job_number} — ${project.name}`,
          value: project.id,
        };
      });
      setProjects(formattedProjects);
    }
  }, [projectsData]);

  useEffect(() => {
    if (data?.entries) {
      setGridData(data.entries);
    }
  }, [data]);

  useEffect(() => {
    if (!!gridData) {
      setFilteredGridData(gridData);
      let options = [];
      gridData.forEach((single) => {
        options = [
          ...options,
          {
            abbr: `${single.current_conditions[0]}${single.current_conditions[1]}`,
            value: `${single.current_conditions}`,
            color: "#0071ce",
          },
        ];
      });
      const unique = removeDuplicateValues(options);
      setFilters(
        unique.map((item) => {
          return item.value;
        })
      );
      setFilterOptions(unique);
    }
  }, [gridData]);

  useEffect(() => {
    if (!!gridData && !!filters) {
      setFilteredGridData(applyFilters(gridData, filters));
    }
  }, [gridData, filters]);

  useEffect(() => {
    if (!!urlParams && !!projects) {
      let newDate;
      const params = new URLSearchParams(urlParams);
      const projectId = params.get("projectId");
      const paramDate = params.get("date");
      if (projectId) {
        setProject(projects.find((project) => `${project.value}` === projectId).value);
      }
      if (paramDate) {
        newDate = new Date(paramDate);
        setDate(newDate.setDate(newDate.getDate() + 1));
        setReportProject(projects.find((project) => `${project.value}` === projectId).label);
        newDate = new Date(paramDate);
        setReportDate(displayFullDate(addOneDay(newDate)));
      }
      if (paramDate && projectId) {
        request(projectId, paramDate);
        setProject(projects.find((project) => `${project.value}` === projectId).value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams, projects]);

  return (
    <div className="d-flex flex-column align-items-center mt-4">
      {!projectsLoading ? (
        <ReportSelector
          project={project}
          setProject={setProject}
          projects={projects}
          date={date}
          setDate={setDate}
          handleRunReport={handleRunReport}
        />
      ) : (
        <Loader />
      )}
      <div className={styles.weatherGridHeader}>
        <p className={styles.projectTtileText}>{reportProject}</p>
        <p className={styles.headerText}>{reportDate}</p>
        <div className="d-flex mb-4">
          {!!filterOptions && (
            <FancyFilters
              filters={filters}
              setFilters={setFilters}
              options={filterOptions}
              title="All Conditions"
              hideTotals
            />
          )}
        </div>
      </div>
      {!!loading ? (
        <Loader />
      ) : (
        <>
          {!!gridData?.length ? (
            <WeatherHourlyGrid gridData={filteredGridData} loading={loading} />
          ) : (
            <>{gridData && <div className={styles.noDataText}>No Weather Data Available!</div>}</>
          )}
        </>
      )}
    </div>
  );
};

export default WeatherPage;
