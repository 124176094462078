import React from "react";
import styles from "./styles.module.scss";
import GridItem from "./GridItem";

const LibraryGrid = ({
  project,
  gridItems,
  gridSize = "small",
  handlePin,
  handleDelete,
  selectedItems,
  setSelectedItems,
  selectMode,
}) => {
  return (
    <>
      <div
        className={`${
          gridSize === "large"
            ? styles.largeGrid
            : gridSize === "medium"
              ? styles.mediumGrid
              : gridSize === "small"
                ? styles.smallGrid
                : ""
        } ${styles.libraryGrid}`}
      >
        {!!gridItems?.length ? (
          gridItems.map((item) => (
            <GridItem
              project={project}
              key={item.id}
              item={item}
              handlePin={handlePin}
              handleDelete={handleDelete}
              selectMode={selectMode}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          ))
        ) : (
          <>No items found.</>
        )}
      </div>
    </>
  );
};

export default LibraryGrid;
