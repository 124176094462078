import { Joi } from "component-library";

const schema = {
  name: Joi.saneString().required().label("Name"),
  permissions: Joi.array()
};

const initialData = {
  name: "",
  permissions: [],
};

export { schema, initialData };
