import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ChevronRight, ChevronDown, CheckCircle } from "react-feather";
import BidBond from "./BidBond";
import BidTaskUpdate from "./BidTaskUpdate";
import CompletedTask from "./CompletedTask";
import BidMeeting from "./BidMeeting";
import Eligibility from "./Eligibility";
import DownloadFiles from "./DownloadFiles";
import SRFRequirement from "./SRFRequirement";
import PropTypes from "prop-types";
import styles from "./styles/BidTodos.module.scss";

const BidTaskEntry = ({ task, completed, setTodos, setBid, bid }) => {
  const [expand, setExpand] = useState();
  return (
    <>
      {task && task.bid_todo && (
        <div key={task.id} className="rounded todo-item my-2">
          <Button
            onClick={() => setExpand(expand === task.id ? "" : task.id)}
            variant="button"
            size="sm"
            className={`${styles.todoToggler} py-2 px-3`}
            aria-label={(expand === task.id ? "hide " : "expand ") + task.bid_todo.name + " task"}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-lg-flex">
                <div className="me-lg-5 mb-0 med">
                  {completed === true && <CheckCircle size="12" color="#0071CE" className="me-2" />}
                  {task.bid_todo && task.bid_todo.name}
                </div>
              </div>
              <span
                style={{
                  padding: "0.6rem 1.2rem",
                }}
              >
                {expand === task.id ? <ChevronDown color="#979797" /> : <ChevronRight color="#979797" />}
              </span>
            </div>
          </Button>
          {expand === task.id && (
            <div className="py-2 my-2 px-3">
              {completed ? (
                <CompletedTask task={task} setTodos={setTodos} bid={bid} />
              ) : (
                <>
                  {(task.bid_todo.id === 4 || task.bid_todo.id === 12) && (
                    <BidBond task={task} setTodos={setTodos} setBid={setBid} bid={bid} />
                  )}
                  {task.bid_todo.id === 1 && <BidMeeting task={task} setTodos={setTodos} setBid={setBid} />}
                  {task.bid_todo.id === 13 && <Eligibility task={task} setTodos={setTodos} setBid={setBid} />}
                  {task.bid_todo.id === 14 && <DownloadFiles task={task} setTodos={setTodos} setBid={setBid} />}
                  {task.bid_todo.id === 8 && (
                    <SRFRequirement task={task} setTodos={setTodos} setBid={setBid} bid={bid} />
                  )}
                  {task.bid_todo.id !== 4 &&
                    task.bid_todo.id !== 1 &&
                    task.bid_todo.id !== 12 &&
                    task.bid_todo.id !== 13 &&
                    task.bid_todo.id !== 14 &&
                    task.bid_todo.id !== 8 && (
                      <BidTaskUpdate task={task} setTodos={setTodos} setBid={setBid} bid={bid} />
                    )}
                  {task.bid_todo.preloaded_document && (
                    <a className="sm-med" href={task.bid_todo.document_url} target="_blank" rel="noreferrer">
                      {task.bid_todo.preloaded_document}
                    </a>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

BidTaskEntry.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    bid_todo: PropTypes.shape({
      id: PropTypes.number.isRequired,
      document_url: PropTypes.string,
      preloaded_document: PropTypes.string,
    }),
  }),
  completed: PropTypes.bool,
  setTodos: PropTypes.func.isRequired,
  setBid: PropTypes.func,
  bid: PropTypes.object,
};

export default BidTaskEntry;
