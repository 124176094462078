import React from "react";
import useNotesAdmin from "../../context/useNotesAdmin";
import { Eye, EyeOff } from "react-feather";
import { Button } from "react-bootstrap";

const ToggleViewAll = () => {
  const { expandAll, setExpandAll } = useNotesAdmin();

  return (
    <Button className="btn btn-link" variant="link" onClick={() => setExpandAll(!expandAll)}>
      {!!expandAll ? (
        <>
          <Eye size="16" color={"#D3D3D3"} />
        </>
      ) : (
        <>
          <EyeOff size="16" color={"#D3D3D3"} />
        </>
      )}
    </Button>
  );
};

export default ToggleViewAll;
