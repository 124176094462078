import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MapPin, Trash2 } from "react-feather";
import SiteGeoMetaRow from "./SiteGeoMetaRow";

const SiteGeoMeta = ({ polygons, poi, setPolygons, project, request, data, focusPolygon, loading }) => {
  const [update, setUpdate] = useState(false);

  const deletePolygon = (poly) => {
    if (!!project) {
      request(
        project.id,
        polygons.filter((p) => p.id !== poly.id)
      );
    }
    if (!!poi) {
      request({ poi_id: poi.id, geofences: polygons.filter((p) => p.id !== poly.id) });
    }
  };

  useEffect(() => {
    if (!!update) {
      if (!!project) {
        request(project.id, polygons);
      }
      if (!!poi) {
        request({ poi_id: poi.id, geofences: polygons });
      }
      setUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygons]);

  useEffect(() => {
    if (!!data?.geofences) {
      setPolygons(data.geofences);
    }
    if (!!data?.poi?.geofences) {
      setPolygons(data.poi.geofences);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {polygons.map((poly) => (
        <div key={poly.id} className="d-flex flex-row mb-3">
          <button
            className="btn btn-primary text-white me-2"
            onClick={() => {
              focusPolygon(poly);
            }}
            type="button"
            aria-label={`focus on ${poly.name} geofence`}
          >
            <MapPin size="20" fill="#0071ce" stroke="#fff"></MapPin>
          </button>
          <button
            className={`btn btn-red text-white ${loading && "btn-disabled"}`}
            onClick={() => {
              deletePolygon(poly);
            }}
            type="button"
            disabled={loading}
            aria-label={`delete ${poly.name} geofence`}
          >
            <Trash2 size="20" stroke="#fff"></Trash2>
          </button>
          <div className="ms-2">
            <SiteGeoMetaRow
              poly={poly}
              polygons={polygons}
              setPolygons={setPolygons}
              setUpdate={setUpdate}
              loading={loading}
            />
          </div>
        </div>
      ))}
    </>
  );
};

SiteGeoMeta.propTypes = {
  polygons: PropTypes.array,
  setPolygons: PropTypes.func,
  project: PropTypes.object,
  request: PropTypes.func,
  data: PropTypes.object,
  focusPolygon: PropTypes.func,
};

export default SiteGeoMeta;
