import React from "react";
import Photo from "./Photo";
import styles from "../styles.module.scss";

const Photos = ({ photos, removePhoto }) => {
  return (
    <div className={styles.photos}>
      {photos.map((photo, index) => (
        <Photo
          key={`${photo.file.name}-${index}`}
          photo={photo}
          removePhoto={removePhoto}
        />
      ))}
    </div>
  );
};

export default Photos;
