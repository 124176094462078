import React from "react";
import PropTypes from "prop-types";

const EstimatedHours = ({ item, totalEstHours, editTasks, updateItem, isSubTask = false }) => {
  const displayEstHours = !!isSubTask ? item?.estimated_hours || 0 : item?.task_estimated_hours || 0;
  return (
    <td className="text-end">
      {totalEstHours >= 0 ? (
        <>{totalEstHours}</>
      ) : (
        <>
          {item.entity_type === "task" ? (
            <>
              {!!editTasks ? (
                <>
                  <label className="d-none" id={`${item.id}-task_estimated_hours`} aria-label="Item estimated hours" />
                  <input
                    id={`${item.id}-task_estimated_hours`}
                    name={`${item.id}-task_estimated_hours`}
                    type="text"
                    className="form-control text-end"
                    value={item?.task_estimated_hours || 0}
                    placeholder="Est Hours"
                    onChange={(e) => updateItem("task_estimated_hours", e.target.value, item.id)}
                  />
                </>
              ) : (
                <>{displayEstHours}</>
              )}
            </>
          ) : (
            "-"
          )}
        </>
      )}
    </td>
  );
};

EstimatedHours.propTypes = {
  item: PropTypes.object,
  totalEstHours: PropTypes.number,
};

export default EstimatedHours;
