/* global google */
import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, DrawingManager, InfoWindow } from "@react-google-maps/api";
import styles from "./styles.module.scss";
import useMap from "./useMap";
import PropTypes from "prop-types";
import { ArrowRightCircle, ArrowLeftCircle } from "react-feather";
import InterfaceIcon from "../../components/InterfaceIcon";
import MapSettingsCheck from "../../components/MapSettingsCheck";

const Map = ({ timesheet, project, timesheetLoading, focusMarker }) => {
  const [clockInMarker, setClockInMarker] = useState();
  const [clockOutMarker, setClockOutMarker] = useState();
  const { containerStyle, options, onLoadMap, onLoadDrawingManager, polyOptions, geofenceLocations, map, polygons } =
    useMap({
      project,
    });

  useEffect(() => {
    if (!timesheetLoading) {
      if (!!timesheet) {
        setClockInMarker(
          timesheet?.clockin_lat && parseFloat(timesheet.clockin_lat) !== 0 && parseFloat(timesheet.clockin_lng) !== 0
            ? { lat: parseFloat(timesheet.clockin_lat), lng: parseFloat(timesheet.clockin_lng) }
            : null
        );
        setClockOutMarker(
          timesheet?.clockout_lat &&
            parseFloat(timesheet.clockout_lat) !== 0 &&
            parseFloat(timesheet.clockout_lng) !== 0
            ? { lat: parseFloat(timesheet.clockout_lat), lng: parseFloat(timesheet.clockout_lng) }
            : null
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheetLoading, timesheet]);

  useEffect(() => {
    if (!!map && !!clockInMarker && !!project) {
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(clockInMarker);
      if (!!clockOutMarker) {
        bounds.extend(clockOutMarker);
      }
      map.fitBounds(bounds, {
        top: 125,
        bottom: 125,
        left: 125,
        right: 125,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clockInMarker, clockOutMarker, map, project, polygons]);

  const focusMap = (center) => {
    map.setCenter(center);
    map.setZoom(15);
  };

  useEffect(() => {
    if (!!focusMarker) {
      focusMap(focusMarker);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusMarker]);

  return (
    <>
      {!!clockInMarker && project ? (
        <MapSettingsCheck>
          <GoogleMap
            mapContainerStyle={containerStyle}
            onLoad={onLoadMap}
            options={options}
            center={
              clockInMarker && clockOutMarker
                ? {
                    lat: (clockInMarker.lat + clockOutMarker.lat) / 2,
                    lng: (clockInMarker.lng + clockOutMarker.lng) / 2,
                  }
                : {
                    lat: clockInMarker.lat,
                    lng: clockInMarker.lng,
                  }
            }
            zoom={10}
          >
            {!!clockInMarker && (
              <>
                <InfoWindow
                  position={{
                    lat: clockInMarker?.lat,
                    lng: clockInMarker?.lng,
                  }}
                  zIndex={
                    clockInMarker?.lat === focusMarker?.lat && clockInMarker?.lng === focusMarker?.lng && styles.active
                  }
                >
                  <div className="d-flex align-items-center justify-content-center px-2 font-weight-bold">
                    <ArrowRightCircle size={16} color="#259FAD" />
                    <InterfaceIcon
                      isWithinGeofence={timesheet?.within_geofence}
                      interfaceUsed={timesheet?.interface}
                      size={24}
                    />
                  </div>
                </InfoWindow>
                <Marker
                  position={{
                    lat: clockInMarker?.lat,
                    lng: clockInMarker?.lng,
                  }}
                  icon={{
                    url: require(timesheet.within_geofence
                      ? `../../assets/pins/map-pin-cr.png`
                      : `../../assets/pins/map-pin-at.png`),
                    scaledSize: new window.google.maps.Size(35, 35),
                  }}
                  labelClass={styles.label}
                  zIndex={99}
                />
              </>
            )}

            {!!clockOutMarker && (
              <>
                <Marker
                  position={{
                    lat: clockOutMarker?.lat,
                    lng: clockOutMarker?.lng,
                  }}
                  icon={{
                    url: require(timesheet.within_geofence_clock_out
                      ? `../../assets/pins/map-pin-cr.png`
                      : `../../assets/pins/map-pin-at.png`),
                    scaledSize: new window.google.maps.Size(40, 40),
                  }}
                />
                <InfoWindow
                  position={{
                    lat: clockOutMarker?.lat,
                    lng: clockOutMarker?.lng,
                  }}
                  zIndex={
                    clockOutMarker?.lat === focusMarker?.lat &&
                    clockOutMarker?.lng === focusMarker?.lng &&
                    styles.active
                  }
                >
                  <div className="d-flex align-items-center justify-content-center px-2 font-weight-bold">
                    <ArrowLeftCircle size={16} color="#FF6700" />
                    <InterfaceIcon
                      isWithinGeofence={timesheet?.within_geofence_clock_out}
                      interfaceUsed={timesheet?.interface_clock_out}
                      size={24}
                    />
                  </div>
                </InfoWindow>
              </>
            )}

            {!!geofenceLocations?.length && (
              <DrawingManager
                onLoad={onLoadDrawingManager}
                options={{
                  drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [null],
                  },
                  polygonOptions: {
                    ...polyOptions,
                  },
                }}
              />
            )}
          </GoogleMap>
        </MapSettingsCheck>
      ) : (
        <div className={styles.noMap}>
          <div>
            <h2 className={`${styles.mapTitle} text-center`}>No Location Data Available</h2>
          </div>
        </div>
      )}
    </>
  );
};

Map.propTypes = {
  timesheet: PropTypes.object,
  coords: PropTypes.object,
  project: PropTypes.object,
  projectLoading: PropTypes.bool,
};

export default Map;
