import React from "react";
import styles from "./styles.module.scss";
import CriticalPath from "./CriticalPath";
import DescriptionOfWork from "./DescriptionOfWork";
import Subcontractors from "./Subcontractors";
import Notes from "./Notes";
import InternalNotes from "./InternalNotes/InternalNotes";
import HourlyWeather from "./HourlyWeather/HourlyWeather";
import Delay from "./Delay";
import Photos from "./Photos";

const DateFields = ({ project, day, updateDateField, report, todaysWeather }) => {
  return (
    <div className={styles.dateFieldWrapper}>
      {!!todaysWeather ? (
        <HourlyWeather day={day} updateDateField={updateDateField} weather={todaysWeather} />
      ) : (
        <div className="mb-3">No weather conditions found.</div>
      )}
      <div className={styles.columnWrapper}>
        <div className={styles.column}>
          <Delay day={day} updateDateField={updateDateField} />
          <CriticalPath day={day} updateDateField={updateDateField} />
          <DescriptionOfWork day={day} updateDateField={updateDateField} />
          <Subcontractors day={day} updateDateField={updateDateField} />
          <Notes day={day} updateDateField={updateDateField} />
        </div>
        <div className={styles.column}>
          <InternalNotes day={day} updateDateField={updateDateField} />
          <Photos project={project} report={report} day={day} updateDateField={updateDateField} />
        </div>
      </div>
    </div>
  );
};

export default DateFields;
