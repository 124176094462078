import { Joi } from "component-library";

const schema = {
  number: Joi.alternatives().conditional("status", {
    is: "needed",
    then: Joi.any(),
    otherwise: Joi.number().required(),
  }).label("Permit number"),

  description: Joi.string().required(),
  issue_date: Joi.alternatives().conditional("status", {
    is: "needed",
    then: Joi.any(),
    otherwise: Joi.date().required(),
  }),

  expiration: Joi.alternatives().conditional("status", {
    is: "needed",
    then: Joi.any(),
    otherwise: Joi.date()
      .required()
      .when("issue_date", {
        is: Joi.exist(),
        then: Joi.date().required().greater(Joi.ref("issue_date")).messages({
          "date.greater": "Expiration date must be after issue date",
        }),
      }),
  }),
  needed_by: Joi.alternatives().conditional("status", {
    is: "needed",
    then: Joi.date().required(),
    otherwise: Joi.any(),
  }),
  status: Joi.string().required(),
  cost: Joi.string().required(),
};

const initialData = {
  number: null,
  description: "",
  issue_date: null,
  expiration: null,
  needed_by: null,
  cost: "",
  status: "active",
};

export { schema, initialData };
