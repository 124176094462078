import React, { useState } from 'react';
import styles from './PreconChecklist.module.scss';
import ChecklistCheckbox from './ChecklistCheckbox';
import UPBUpload from './UPBUpload';
import ListToggle from './ListToggle';
import DotCatList from "./CheckboxList/DotCatList";
import DotCatListStatus from "./CheckboxList/DotCatListStatus";
import PlansUpload from './PlansUpload/PlansUpload';

const ChecklistItem = ({ item, updateItem, setAlert }) => {
  const [listDotCatActive, setListDotCatActive] = useState();

  return (
    <>
      {!!item && !!item.precon_todo && (
        <div className={styles.itemWrap}>
        <div className={styles.headerWrap}>
          <ChecklistCheckbox updateItem={updateItem} todo={item} />
          {item.precon_todo.key === "upb" && <UPBUpload todo={item} setAlert={setAlert} />}
          {item.precon_todo.key === "dot_categories" && (
            <div className="d-flex justify-content-center align-items-center">
              <DotCatListStatus />
              <ListToggle
                listActive={listDotCatActive}
                setListActive={setListDotCatActive}
              />
            </div>
            )}
            {item.precon_todo.key === "upload_plans" && <PlansUpload />}
        </div>
        {item.precon_todo.key === "dot_categories" && !!listDotCatActive && <DotCatList />}
      </div>
      )}
    </>
  );
}

export default ChecklistItem;