import nestedDetailCellConfig from "./nestedDetailCellConfig";

import {
  date,
  id,
  job_number,
  lunch,
  hours,
  perdiem,
  time_in,
  time_out,
  project_name,
  expand,
  unallocated,
  note_count,
} from "./columnDefs";

const columnDefs = [
  date,
  id,
  job_number,
  project_name,
  perdiem,
  time_in,
  time_out,
  lunch,
  unallocated,
  hours,
  note_count,
  expand,
];

const detailGridOptions = {
  rowSelection: "multiple",
  pagination: false,
  columnDefs: columnDefs,
  rowHeight: 42,
  defaultColDef: {
    sortable: true,
  },
  rowClass: "detailGroupRow",
  masterDetail: true,
  detailRowAutoHeight: true,
  isRowMaster: (dataItem) => (dataItem?.time_entries?.length ? true : false),
  detailCellRendererParams: {
    detailGridOptions: nestedDetailCellConfig,
    getDetailRowData: (params) => {
      params.successCallback(
        params.data.time_entries.map((entry) => {
          return {
            ...entry,
            timesheet_id: params.data.timesheet_id,
            project_id: params.data.project_id,
          };
        })
      );
    },
  },
};

export default detailGridOptions;