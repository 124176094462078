import { Joi } from "component-library";

const schema = {
  project_id: Joi.number(),
  addenda_number: Joi.number().required().label("Addenda Number"),
  addenda_types: Joi.any().required().label("Addenda Type"),
  bid_date_change: Joi.number(),
  addenda_date: Joi.date().required(),

  bid_date: Joi.date().allow(null),
  bid_runner_id: Joi.number().label("Addenda Runner"),
  bid_time: Joi.saneString(),
  bid_location: Joi.saneString(),
  notes: Joi.saneString(),

  bid_location_change: Joi.number(),
  upb_change: Joi.number(),
  upb_file: Joi.object().allow(null),
  pre_bid_meeting_change: Joi.number(),
  pre_bid_meeting_date: Joi.date().allow(null),
  pre_bid_meeting_time: Joi.saneString(),
  pre_bid_location: Joi.saneString(),
  attendance_required: Joi.checkbox(),

  download_ebsx_addenda: Joi.checkbox(),
};

const initialData = {
  addenda_number: "",
  addenda_types: [],
  bid_date_change: 0,
  bid_date: null,
  bid_runner_id:0,
  bid_time: "",

  bid_location_change: 0,
  bid_location: "",
  upb_change: 0,
  upb_file: null,
  pre_bid_meeting_change: 0,
  pre_bid_meeting_date: null,
  pre_bid_meeting_time: null,
  pre_bid_location: "",
  attendance_required: 0,

  download_ebsx_addenda: 0,
  notes: "",
};

export { schema, initialData };
