import React from "react";
import Select from "react-select";
import { submittalConfig, useAuth } from "component-library";

const pill = (color = "#ccc") => ({
  alignItems: "center",
  backgroundColor: color,
  textTransform: "uppercase",
  borderRadius: 8,
  padding: "0.1rem 0.8rem",
  display: "flex",
  color: "#fff",
  fontSize: "1.2rem",
});

const PrioritySelect = ({ handleSelectChange, submittal, customStyles }) => {
  const { config } = useAuth();

  const styles = {
    container: (styles) => ({
      ...styles,
      ...customStyles.container,
    }),
    control: (styles) => ({
      ...styles,
      ...customStyles.control,
    }),
    valueContainer: (styles) => ({
      ...styles,
      ...customStyles.valueContainer,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      ...customStyles.dropdownIndicator,
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      ...customStyles.indicatorsContainer,
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      ...pill(data.color),
      ...customStyles.singleValue,
    }),
    input: (styles) => ({
      ...styles,
      ...customStyles.input,
    }),
    option: (styles, { data }) => ({
      ...styles,
      ...customStyles.option,
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      ...customStyles.indicatorSeparator,
    }),
  };

  const options = config.submittal_priorities.map((opt) => ({
    color: submittalConfig.priorities.find((p) => p.id === opt.id).color,
    value: opt.id,
    label: opt.name,
  }));

  return (
    <Select
      options={options}
      name="priority_id"
      id="priority_id"
      styles={styles}
      onChange={handleSelectChange("priority_id")}
      value={options.find((p) => p.value === submittal.priority_id)}
      aria-label="priority options"
    />
  );
};

export default PrioritySelect;
