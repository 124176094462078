const size = {
  headerName: "Size",
  name: "size",
  cellStyle: { color: "#4f4f4f" },
  width: 100,
  valueGetter: ({ data }) => {
    const fileSizeKB = parseFloat(data.size / 1024).toFixed(2);
    const fileSizeMB = parseFloat(data.size / 1048576).toFixed(2);
    return fileSizeMB <= 0 ? `${fileSizeKB}KB` : `${fileSizeMB}MB`;
  },
  filterValueGetter: function (params) {
    return parseFloat(params.data.size);
  },
};

export default size;
