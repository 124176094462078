import React from "react";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const ViewSwitcher = ({ activeColor, deactivateColor, activeView, setActiveView, options }) => {
  return (
    <div className={styles.viewToggle}>
      {options.map((option, index) => (
        <Button
          key={`view_swicher_${index}`}
          className={`btn-link ${activeView === option.value ? styles.active : ""}`}
          onClick={() => setActiveView(option.value)}
        >
          <option.icon size={15} color={activeView === option.value ? activeColor : deactivateColor} />
        </Button>
      ))}
    </div>
  );
};

ViewSwitcher.propTypes = {
  options: PropTypes.array,
  activeColor: PropTypes.string,
  deactivateColor: PropTypes.string,
  activeView: PropTypes.string,
  setActiveView: PropTypes.any,
};

export default ViewSwitcher;
