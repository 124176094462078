import React from "react";
import useProject from "./../hooks/useProject";
import DashboardContent from "./DashboardContent/DashboardContent";
import PreconChecklist from "./PreconChecklist";
import InteriorPage from "../../components/pageLayout/InteriorPage";
import { Briefcase } from "react-feather";

const Dashboard = () => {
  const { loading, error, project, pageTitle, PageTag } = useProject();

  return (
    <InteriorPage
      loading={!project && loading}
      error={error}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      NavContent={PageTag}
      fillScreen={true}
    >
      {project && project.id && project.status === "precon" ? (
        <PreconChecklist />
      ) : (
        <DashboardContent />
      )}
    </InteriorPage>
  );
};

export default Dashboard;
