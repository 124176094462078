import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useApi, projectApi, useFormControl, dropdownStylesWithWidth, Loader, useAuth } from "component-library";
import { initialData, schema } from "../../../../schema/contactRole";
import { Alert } from "react-bootstrap";
import useProject from "../../../hooks/useProject";
import { RiseLoader } from "react-spinners";

const EditContactRole = ({ roles, contact, contacts, setContacts, setEditingRole }) => {
  const { config } = useAuth();
  const { project } = useProject();

  let roleOptions;
  if (!!roles) {
    roleOptions = roles.map((ct) => ({
      value: ct.name,
      label: ct.name,
    }));
  } else {
    roleOptions = config.roles.map((ct) => ({
      value: ct.name,
      label: ct.name,
    }));
  }

  const {
    request: updateRequest,
    data: updateData,
    loading: updateLoading,
    error: updateError,
  } = useApi(projectApi.updateProjectContact);

  const onSubmit = async (formData, formLoading) => {
    await updateRequest(project.id, contact.id, formData.role_id);
    formLoading(false);
  };

  useEffect(() => {
    if (!updateData) return;
    setContacts(contacts.map((c) => (c.id === updateData.contact.id ? updateData.contact : c)));
    setEditingRole(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  const { error, loading, handleSubmit, renderSubmitButton, renderSelectField } = useFormControl({
    schema,
    onSubmit,
    initialData: !!contact && !!contact.role ? { ...initialData, role_id: contact.role.name } : initialData,
    scrollToTop: false,
  });

  return (
    <Modal dialogClassName="modal-lg" centered animation={false} show={true} onHide={() => setEditingRole(false)}>
      {!!updateLoading && (
        <div className="loaderWrapper">
          <RiseLoader color={"#0071ce"} />
        </div>
      )}
      <>
        <Modal.Header closeButton>
          <h5 className="mb-0 med">
            {!!contact
              ? `Edit Role for ${contact && contact.contact.first_name} ${contact && contact.contact.last_name}`
              : "Update Contact Role"}
          </h5>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          {!!updateError && (
            <div>
              <Alert variant="danger">{updateError}</Alert>
            </div>
          )}
          <Modal.Body>
            <div className="position-relative">
              <Loader loading={!!loading} />
              {error && <Alert variant="danger">{error}</Alert>}
              <div className="row">
                <div className="col-lg-4">
                  {renderSelectField({
                    name: "role_id",
                    label: "Role",
                    options: roleOptions,
                    emptyOption: "Select...",
                    isClearable: false,
                    styles: dropdownStylesWithWidth("100%"),
                  })}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="mt-2" onClick={() => setEditingRole(false)}>
              Cancel
            </Button>
            {renderSubmitButton("Update Role")}
          </Modal.Footer>
        </form>
      </>
    </Modal>
  );
};

EditContactRole.propTypes = {
  contact: PropTypes.object,
};

export default EditContactRole;
