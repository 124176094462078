import React from "react";
import { Edit3, Mail, XCircle, FilePlus, Save } from "react-feather";
import { HeaderButton, useAuth } from "component-library";

const BidHeaderLinks = ({ bid, showEditButton, setAddingAddenda, docLoading }) => {
  const { userCan, portalModule } = useAuth();

  return (
    <>
      {!!bid && (
        <>
          {!!showEditButton ? (
            <div className="d-flex align-items-center">
              {portalModule?.active === 1 && (
                <HeaderButton
                  linkTo={`/bid/${bid.id}/rfqs/add`}
                  icon={<Mail size="16" color="#0071ce" />}
                  text="Generate RFQ"
                />
              )}
              <HeaderButton
                icon={<FilePlus size="16" color={"#0071ce"} />}
                onClick={() => {
                  setAddingAddenda(true);
                }}
                text="Add Addenda"
              />
              {userCan("project-edit") && (
                <HeaderButton
                  linkTo={`/bid/${bid.id}/edit/`}
                  icon={<Edit3 size="16" color="#0071ce" />}
                  text="Edit Bid"
                />
              )}
              <HeaderButton
                linkTo={`/bid/${bid.id}/close`}
                icon={<XCircle size="16" color="#0071ce" />}
                text="Close Bid"
              />
            </div>
          ) : (
            <div className="d-flex align-items-center">
              {userCan("project-edit") && (
                <>
                  <HeaderButton
                    linkTo={`/bid/${bid.id}`}
                    icon={<XCircle size="16" color="#0071ce" />}
                    text="Cancel"
                    disabled={docLoading}
                  />
                  <HeaderButton
                    formId="bid-details-form"
                    text="Save Bid"
                    icon={<Save size={16} color="#0071ce" />}
                    disabled={docLoading}
                  />
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BidHeaderLinks;
