import { Joi } from "component-library";

const schema = {
  modules: Joi.array(),
};

const initialData = {
  modules: [],
};

export { schema, initialData };
