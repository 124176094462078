import styles from "./styles.module.scss";
import React from "react";

const NewLocateNumberValue = ({ data }) => {
  return (
    <div className={styles.fakeInput}>
      <span>{data.new_number}</span>
    </div>
  );
};

export default NewLocateNumberValue;
