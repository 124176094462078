import { useEffect, useState } from "react";
import { fieldLogsApi, useApi } from "component-library";

const useFieldLogs = () => {
  const [fieldLogs, setFieldLogs] = useState([]);
  const [consoleData, setConsoleData] = useState([]);
  const [total, setTotal] = useState(0);
  const [viewAll, setViewAll] = useState(false);

  const { data, request: getFieldLogs, loading, error } = useApi(fieldLogsApi.getFieldLogs);

  useEffect(() => {
    if (!!data && !!data.field_logs) {
      setFieldLogs(
        data.field_logs.logs.map((fl) => ({
          ...fl,
          id: `${fl.workday_id}:${fl.foreman_id}`,
          timesheets: fl?.timesheets?.map((timesheet) => {
            return {
              ...timesheet,
              project_id: fl?.project_id,
              foreman_id: fl?.foreman_id,
            };
          }),
        }))
      );
      setConsoleData(data?.field_logs?.console);
      setTotal(data?.field_logs?.total);
    } else {
      setFieldLogs([]);
    }
  }, [data]);

  return {
    fieldLogs,
    setFieldLogs,
    getFieldLogs,
    loading,
    error,
    consoleData,
    total,
    viewAll,
    setViewAll,
  };
};

export default useFieldLogs;
