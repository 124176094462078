import React from "react";
import { Grid } from "react-feather";
import styles from "../styles.module.scss";
import PhotosList from "./PhotosList";

const AllPhotosList = ({
  photosList,
  handlePin,
  handleDelete,
  loading,
  params,
  selectedItems,
  setSelectedItems,
  selectMode,
}) => {
  const TitleIcon = () => <Grid className={styles.titleIcon} />;

  return (
    <div className={`${styles.listContainer} mt-5`}>
      <PhotosList
        photosList={photosList}
        title="All Photos"
        TitleIcon={TitleIcon}
        handlePin={handlePin}
        handleDelete={handleDelete}
        loading={loading}
        params={params}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectMode={selectMode}
      />
    </div>
  );
};

export default AllPhotosList;
