import React, { useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import styles from "./styles.module.scss";
import { Upload, X } from "react-feather";
import CustomScroll from "react-custom-scroll";
import useSelectedReportPhotos from "./useSelectedReportPhotos";
import { FilterSearch, DateRangeSelector } from "component-library";
import { BeatLoader } from "react-spinners";
import PhotoGridOptions from "./PhotoGridOptions";
import AlbumGridOptions from "./AlbumGridOptions";
import { Grid, Folder } from "react-feather";
import AlbumDetailOption from "./AlbumDetailOptions";
import { ChevronLeft } from "react-feather";
import UploadPhotoModal from "../../../../../../../_projects/project/PhotoLibrary/Modals/UploadPhotoModal/UploadPhoto";
import useAlbumDetail from "../../../../../../../_projects/project/hooks/useAlbumDetail";

const SelectReportPhotosModal = ({ project, showSelectModal, updateDateField, onHide, day, report }) => {
  const {
    photosList,
    albumsList,
    dateRange,
    handleUpdateDateRange,
    handleSearchChange,
    photosLoading,
    albumsLoading,
    photosError,
    albumsError,
    refreshLists,
    selectedPhotos,
    selectedPhotoIds,
    view,
    showUploadModal,
    viewingAlbumId,
    handleStoredChange,
    handleSaveModalSelections,
    handleUpdateView,
    toggleShowUploadModal,
    handleUpdatePhotosList,
  } = useSelectedReportPhotos({
    project,
    day,
    report,
    updateDateField,
    onHide,
  });

  const {
    albumPhotosLoading,
    albumDetailsError,
    albumPhotosError,
    albumPhotosList,
    pinnedPhotos,
    todaysPhotos,
    recentPhotos,
    previousPhotos,
    setAlbumPhotosList,
  } = useAlbumDetail({ albumId: viewingAlbumId });

  const [message, setMessage] = useState();

  return (
    <>
      {!!showUploadModal && !viewingAlbumId ? (
        <UploadPhotoModal
          project={project}
          showModal={showUploadModal}
          setShowModal={toggleShowUploadModal}
          photosList={photosList}
          setPhotosList={handleUpdatePhotosList}
          setMessage={setMessage}
        />
      ) : (
        <>
          {!!showUploadModal && !!viewingAlbumId ? (
            <UploadPhotoModal
              albumId={viewingAlbumId}
              refreshLists={refreshLists}
              showModal={showUploadModal}
              setShowModal={toggleShowUploadModal}
              albumPhotosList={albumPhotosList}
              setAlbumPhotosList={setAlbumPhotosList}
              photosList={albumPhotosList}
              setPhotosList={setAlbumPhotosList}
              setMessage={setMessage}
            />
          ) : (
            <Modal
              show={!!showSelectModal}
              centered
              onHide={() => {
                onHide();
              }}
              animation={false}
              className={`text-gray2 p-0 fullWidthContent ${!!showUploadModal ? styles.dimmed : ""}`}
              backdropClassName={styles.backdrop}
              dialogClassName={styles.dialogWrap}
              contentClassName={styles.modalContent}
            >
              <Modal.Header className={`${styles.header}`}>
                <h1 className={`${styles.titleText} med mb-0`}>Attach Photo(s)</h1>
                <div className="d-flex align-items-center">
                  <Button
                    variant="link"
                    className="d-flex align-items-center med me-5 text-gray2"
                    onClick={() => toggleShowUploadModal()}
                  >
                    <Upload className={styles.icon} size={16} color="#0071CE" /> Upload Photo
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => {
                      onHide();
                    }}
                    className="p-0"
                  >
                    <X size={25} color="#D3D3D3" />
                  </Button>
                </div>
              </Modal.Header>
              <Modal.Body className={`${styles.bodyWrapper} flex-1 position-relative mx-4`}>
                {!!photosError && <Alert variant="danger">{!!photosError && photosError}</Alert>}
                {!!albumsError && <Alert variant="danger">{!!albumsError && albumsError}</Alert>}
                {!!photosLoading && (
                  <div className="loaderWrapper">
                    <BeatLoader color="#0071ce" />
                  </div>
                )}
                {!!message && (
                  <Alert dismissible onClick={() => setMessage("")} variant="success">
                    {message}
                  </Alert>
                )}
                <div className="d-flex align-items-center justify-content-between w-100 mb-5">
                  {view === "albums" && <div />}
                  {view === "photos" && (
                    <DateRangeSelector
                      dateRange={dateRange}
                      setDateRange={handleUpdateDateRange}
                      maxDate={Date.now()}
                    />
                  )}
                  {!!viewingAlbumId && view === "album_detail" && (
                    <Button
                      onClick={() => handleUpdateView("albums")}
                      variant="link"
                      className={`${styles.albumName} d-flex align-items-center`}
                    >
                      <ChevronLeft className={styles.icon} color="#0071ce" size={24} />
                      {albumsList.find((album) => album.id === viewingAlbumId)?.name}
                      <span className={styles.photoCount}>
                        {albumsList.find((album) => album.id === viewingAlbumId)?.photo_count}
                      </span>
                    </Button>
                  )}

                  <div className="d-flex align-items-center">
                    <FilterSearch searchFunction={handleSearchChange} placeholder="Search Photos" />

                    <div className={styles.viewToggle}>
                      <Button
                        className={`btn-link ${view === "photos" ? styles.active : ""}`}
                        onClick={() => handleUpdateView("photos")}
                      >
                        <Grid size={15} color={view === "photos" ? "#FFF" : "#D3D3D3"} />
                      </Button>
                      <Button
                        className={`btn-link me-2 ${view === "albums" ? styles.active : ""}`}
                        onClick={() => handleUpdateView("albums")}
                      >
                        <Folder size={15} color={view === "albums" ? "#FFF" : "#D3D3D3"} />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className={styles.modalBody}>
                  <CustomScroll heightRelativeToParent="100%">
                    {view === "photos" && (
                      <PhotoGridOptions
                        project={project}
                        selectedPhotoIds={selectedPhotoIds}
                        photosList={photosList}
                        updateSelectedPhotos={handleStoredChange}
                        dateRange={dateRange}
                      />
                    )}
                    {view === "albums" && (
                      <AlbumGridOptions
                        albumsLoading={albumsLoading}
                        albumsList={albumsList}
                        updateSelectedPhotos={handleStoredChange}
                        handleUpdateView={handleUpdateView}
                      />
                    )}
                    {!!viewingAlbumId && !showUploadModal && (
                      <AlbumDetailOption
                        project={project}
                        albumId={viewingAlbumId}
                        selectedPhotoIds={selectedPhotoIds}
                        updateSelectedPhotos={handleStoredChange}
                        albumPhotosLoading={albumPhotosLoading}
                        albumDetailsError={albumDetailsError}
                        albumPhotosError={albumPhotosError}
                        albumPhotosList={albumPhotosList}
                        pinnedPhotos={pinnedPhotos}
                        todaysPhotos={todaysPhotos}
                        recentPhotos={recentPhotos}
                        previousPhotos={previousPhotos}
                        message={message}
                        setMessage={setMessage}
                        setShowModal={toggleShowUploadModal}
                      />
                    )}
                  </CustomScroll>
                </div>
              </Modal.Body>
              <Modal.Footer className={styles.footer}>
                <Button
                  className="light-text mx-1 btn btn-lg med font-weight-bold"
                  variant="light-gray"
                  type="submit"
                  onClick={() => onHide()}
                >
                  Cancel
                </Button>
                <Button
                  className="light-text mx-1 btn btn-lg med font-weight-bold"
                  variant="tertiary"
                  type="submit"
                  onClick={() => handleSaveModalSelections(selectedPhotos)}
                >
                  Update Photos
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </>
      )}
    </>
  );
};
export default SelectReportPhotosModal;
