import React from "react";
import useProvidePhotoLibrary from "./useProvidePhotoLibrary";
import photoLibraryContext from "./photoLibraryContext";

function ProvidePhotoLibrary({ children }) {
  const photoLibrary = useProvidePhotoLibrary();
  return <photoLibraryContext.Provider value={photoLibrary}>{children}</photoLibraryContext.Provider>;
}

export default ProvidePhotoLibrary;
