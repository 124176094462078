import { useState, useEffect } from "react";
import { useApi, projectApi } from "component-library";
import useProject from "../../../../hooks/useProject";
import { DateTime } from "luxon";

const useTasks = ({ dateRange, setDateRange }) => {
  const { project } = useProject();
  const [tasks, setTasks] = useState();
  const [taskOptions, setTaskOptions] = useState();
  const [filteredTasks, setFilteredTasks] = useState();
  const [error, setError] = useState();

  const [taskCount, setTaskCount] = useState(0);
  const [selectedTask, setSelectedTask] = useState({});
  const [refreshColumns, setRefreshColumns] = useState(false);
  const {
    request: tasksRequest,
    data: tasksData,
    loading: tasksLoading,
    error: tasksError,
  } = useApi(projectApi.getProjectSummaryTasks);

  const rangeFilterOptions = [
    { value: "all", label: "All Dates" },
    { value: "date_range", label: "Date Range" },
  ];

  const getCombinedTasks = (tasksList) => {
    let combinedTasksList = [];
    if (!!tasksList?.length) {
      tasksList.forEach((task) => {
        if (!!task?.tasks?.length) {
          combinedTasksList.push(...task.tasks);
        }
      });
    }

    return combinedTasksList;
  };

  const getTotalTasksData = (tasksList) => {
    let combinedTasksList = getCombinedTasks(tasksList);
    setTaskCount(combinedTasksList.length);
  };

  const handleSearch = (q) => {
    if (q) {
      let filteredTaskList = [];
      tasks.forEach((bid_item) => {
        let combinedString = `${bid_item.bid_item_name} ${bid_item.bid_item_number}`.toLowerCase();
        bid_item.tasks?.forEach((task) => {
          const combinedTaskString = `${task.cost_code} ${task.description} ${task.task_id} `.toLowerCase();
          combinedString = combinedString + combinedTaskString;
        });
        if (combinedString.includes(q.toLowerCase())) {
          filteredTaskList.push(bid_item);
        }
      });
      setFilteredTasks(filteredTaskList);
    } else {
      setFilteredTasks(tasks);
    }
  };

  useEffect(() => {
    if (project?.id && !tasksLoading) {
      tasksRequest(project.id, "", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (project?.id) {
      if (!!dateRange[1]) {
        tasksRequest(
          project.id,
          DateTime.fromJSDate(dateRange[0]).toFormat("yyyy-MM-dd"),
          DateTime.fromJSDate(dateRange[1]).toFormat("yyyy-MM-dd")
        );
      }
      if (!dateRange[0]) {
        tasksRequest(project.id, "", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    setRefreshColumns(true);
    setTasks(tasksData?.data?.all?.tasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksData]);

  useEffect(() => {
    if (!!tasks) {
      getTotalTasksData(tasks);
      setFilteredTasks(tasks);
      setTaskOptions(
        tasks.map((task) => {
          return {
            value: task.item_id,
            label: `${!!task?.bid_item_number ? `${task?.bid_item_number} - ` : ""} ${task.bid_item_name}`,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  useEffect(() => {
    if (selectedTask && filteredTasks) {
      setFilteredTasks(filteredTasks.filter((task) => task.item_id === selectedTask));
    } else if (!selectedTask && tasks) {
      setFilteredTasks(tasks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTask]);

  useEffect(() => {
    const apiError = tasksError;
    if (apiError) {
      setError(apiError);
    }
  }, [tasksError]);

  useEffect(() => {
    if (!!filteredTasks?.length) {
      const combinedTasks = getCombinedTasks(filteredTasks);
      setTaskCount(combinedTasks.length);
    }
  }, [filteredTasks]);

  return {
    dateRange,
    setDateRange,
    tasks,
    tasksLoading,
    error,
    taskCount,
    selectedTask,
    setSelectedTask,
    handleSearch,
    taskOptions,
    filteredTasks,
    setFilteredTasks,
    rangeFilterOptions,
    refreshColumns,
    setRefreshColumns,
  };
};

export default useTasks;
