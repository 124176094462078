import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styles from "./styles.module.scss";
import { HeaderButton, useAuth } from "component-library";
import { Folder, Trash2, Type, Upload } from "react-feather";
import useProjectDocuments from "../context/useProjectDocuments";

const useProjectFolderDetail = () => {
  const { attachments } = useProjectDocuments();
  const { folderName } = useParams();
  const { config } = useAuth();
  const [folderAttachments, setFolderAttachments] = useState();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [addDocModalOpen, setAddDocModalOpen] = useState(false);
  const [filteredAttachments, setFilteredAttachments] = useState();
  const [query, setQuery] = useState("");
  const isDefaultFolder = !!config?.folders?.find((folder) => folder.name.toLowerCase() === folderName.toLowerCase());
  const handleSearch = (q) => {
    setQuery(q);
  };

  const PageTitleHeader = () => (
    <div className="d-flex align-items-center">
      <Folder size={16} color="#0071ce" />
      <h2 className={styles.folderName}>{folderName}</h2>
      <span className={styles.attachmentCount}>{folderAttachments?.length || 0} Files</span>
    </div>
  );

  const HeaderButtons = () => (
    <div className="d-flex align-items-center">
      {!isDefaultFolder && (
        <>
          <HeaderButton
            text="Delete Folder"
            onClick={() => setShowDeleteConfirmModal(true)}
            icon={<Trash2 color="#0071ce" size={16} />}
          />
          <HeaderButton
            text="Rename Folder"
            onClick={() => setShowRenameModal(true)}
            icon={<Type color="#0071ce" size={16} />}
          />
        </>
      )}
      <HeaderButton
        text="Upload File"
        onClick={() => setAddDocModalOpen(true)}
        icon={<Upload color="#0071ce" size={16} />}
      />
    </div>
  );

  useEffect(() => {
    if (!!attachments?.length) {
      setFolderAttachments(
        attachments.filter((attachment) => attachment.folder.toLowerCase() === folderName.toLowerCase())
      );
    } else {
      setFolderAttachments([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  useEffect(() => {
    let results = folderAttachments;
    if (query) {
      results = results.filter((attachment) =>
        `${attachment?.file_name} ${attachment?.user?.first_name} ${attachment?.user?.last_name}`
          .toLowerCase()
          .includes(query.toLowerCase())
      );
    }

    setFilteredAttachments(results);
  }, [folderAttachments, query]);

  return {
    showDeleteConfirmModal,
    setShowDeleteConfirmModal,
    showRenameModal,
    setShowRenameModal,
    addDocModalOpen,
    setAddDocModalOpen,
    PageTitleHeader,
    HeaderButtons,
    handleSearch,
    folderAttachments,
    filteredAttachments,
  };
};

export default useProjectFolderDetail;
