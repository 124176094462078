import React, { useState } from "react";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import useProject from "../../../hooks/useProject";
import { Calendar, Edit, XCircle, Save } from "react-feather";
import Dates from "./Dates";
import DateMilestones from "./DateMilestones";
import EditSchedule from "./EditSchedule";
import { HeaderButton } from "component-library";

const ScheduleView = () => {
  const { pageTitle, PageTag, project, setProject, refreshProject, loading, error } = useProject();
  const [isEditing, setIsEditing] = useState(false);

  const HeaderButtons = () => (
    <>
      {!isEditing ? (
        <HeaderButton
          onClick={() => setIsEditing(true)}
          icon={<Edit size={16} color="#0071CE" />}
          text="Edit Schedule"
        />
      ) : (
        <div className="d-flex align-items-center">
          <HeaderButton
            onClick={() => setIsEditing(false)}
            icon={<XCircle size="16" color="#0071ce" />}
            text="Close Edit"
          />
          <HeaderButton formId="edit-schedule-form" icon={<Save size={16} color="#0071CE" />} text="Save Schedule" />
        </div>
      )}
    </>
  );

  return (
    <InteriorPage
      loading={loading}
      error={error}
      navTitle={pageTitle}
      navIcon={<Calendar color="#fff" size={14} />}
      pageTitle="Schedule"
      fillScreen
      NavContent={PageTag}
      pageTitleSecondaryContent={HeaderButtons}
    >
      <div className="container">
        {project && (
          <>
            {isEditing ? (
              <>
                <EditSchedule
                  bidObject={project}
                  setProject={setProject}
                  refreshProject={refreshProject}
                  setIsEditing={setIsEditing}
                />
              </>
            ) : (
              <>
                <Dates project={project} setIsEditing={setIsEditing} />
                <hr />
                <DateMilestones project={project} setIsEditing={setIsEditing} />
              </>
            )}
          </>
        )}
      </div>
    </InteriorPage>
  );
};

export default ScheduleView;
