import React from "react";
import PropTypes from "prop-types";
import { dropdownStylesWithWidth } from "component-library";
import Select from "react-select";
import styles from "../createBid.module.scss";

const Disincentive = ({ item, handleChange, config, focusAdd }) => {
  const disincentiveConfig = config.disincentive_config.map((item) => ({
    value: item.id,
    label: item.value,
  }));

  const disincentiveCustomValue = disincentiveConfig.find(
    (item) => item.label.toLowerCase() === "custom"
  ).value;

  return (
    <div className="row py-2">
      <div className="col-md-4">
        <label htmlFor={`disincentive_amount_${item.key ? item.key : item.id}`}>
          Disincentive
        </label>
        <div className={styles.currencyInput}>
          $
          <input
            id={`disincentive_amount_${item.key ? item.key : item.id}`}
            className="form-control"
            name="disincentive_amount"
            type="number"
            onChange={(e) => {
              handleChange(
                "milestones",
                item.key,
                "disincentive_amount",
                e.target.value
              );
            }}
            onFocus={focusAdd}
            value={!item.disincentive_amount ? "" : item.disincentive_amount}
          />
        </div>
      </div>
      <div className="col-md-2 d-flex align-items-end">
        <Select
          className="w-100"
          id="source"
          onChange={(e) => {
            handleChange("milestones", item.key, "disincentive_type", e.value);
          }}
          options={disincentiveConfig}
          styles={dropdownStylesWithWidth(450)}
          value={
            disincentiveConfig.find(
              (opt) => opt.value === item.disincentive_type
            ) || 1
          }
          aria-label="Disincentive type"
        />
      </div>
      {!!item.disincentive_type &&
        item.disincentive_type === disincentiveCustomValue && (
          <div className="col-md-3">
            <label htmlFor={`disincentive_type_other_${item.key}`}>
              Custom
            </label>
            <input
              id={`disincentive_type_other_${item.key ? item.key : item.id}`}
              className="form-control"
              name="name"
              onChange={(e) => {
                handleChange(
                  "milestones",
                  item.key,
                  "disincentive_type_other",
                  e.target.value
                );
              }}
              onFocus={focusAdd}
              value={
                !item.disincentive_type_other
                  ? ""
                  : item.disincentive_type_other
              }
            />
          </div>
        )}
    </div>
  );
};

Disincentive.propTypes = {
  item: PropTypes.object,
  handleChange: PropTypes.func,
  config: PropTypes.object,
  focusAdd: PropTypes.func,
};

export default Disincentive;
