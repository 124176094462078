import React, { useEffect, useState } from "react";
import useProject from "../../hooks/useProject";
import { useApi, notificationsApi } from "component-library";
import useInterval from "react-useinterval";
import ProjectAlert from "./ProjectAlert";

const Alerts = () => {
  const { project } = useProject();
  const [count, setCount] = useState(0);
  const [alert, setAlert] = useState();
  const [dismiss, setDismiss] = useState(false);
  const { data: countData, request: countRequest } = useApi(
    notificationsApi.getProjectNotificationsCount
  );
  const { data, request } = useApi(
    notificationsApi.getProjectNotifications
  );

  const updateCount = () => {
    countRequest(project.id);
  };

  useEffect(() => {
    updateCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!countData && parseInt(countData.count) !== count) {
      setCount(parseInt(countData.count));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countData]);

  useEffect(() => {
    if (!!count && parseInt(count) > 0 && !alert) request(project.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (!!data && !!data.notifications) {
      setAlert(data.notifications);
      setTimeout(() => setDismiss(true), 10000);
      setTimeout(() => {
        setDismiss(false);
        setAlert();
        setCount(count - 1);
      }, 11000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useInterval(updateCount, count > 0 ? null : 60000);

  return !!alert ? (
    <ProjectAlert alert={alert} dismiss={dismiss} />
  ) : null;
};

export default Alerts;
