import task from "../../../sharedColumns/columnDefs/task";
import role from "../../../sharedColumns/columnDefs/role";
import equipment from "../../../sharedColumns/columnDefs/equipment";
import hours from "../../../sharedColumns/columnDefs/hours";
import foreman from "../../../sharedColumns/columnDefs/foreman";
import timesheetLink from "../../../sharedColumns/columnDefs/timesheet_link";

const columnDefs = [task, role, equipment, foreman, hours, timesheetLink];

const detailGridOptions = {
  rowSelection: "multiple",
  pagination: false,
  columnDefs: columnDefs,
  rowHeight: 42,
  defaultColDef: {
    sortable: true,
  },
};

export default detailGridOptions;
