import Updated from "../columnRenders/Updated"
import dateTimeComparator from "../../../utils/dateTimeComparator"

const updated = {
  headerName: "updated",
  field: "updated",
  cellRenderer: Updated,
  comparator: dateTimeComparator
};

export default updated;