import React, { useState, useEffect } from "react";
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import { useApi, projectApi, Loader } from "component-library";
import styles from "./styles.module.scss";
import { Alert } from "react-bootstrap";

const AssetEditor = ({ asset, documentType }) => {
  const [editorConfig, setEditorConfig] = useState();
  const [error, setError] = useState();
  const { request: documentEditRequest, loading: documentEditLoading } = useApi(projectApi.getDocumentEditConfig);

  const handleRequestEditorConfig = async () => {
    const response = await documentEditRequest({ documentId: asset.id });
    try {
      if (!!response?.success) {
        setEditorConfig(response.responseData);
      }
      setError();
    } catch (error) {
      setError(error);
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    if (!!asset?.id) {
      handleRequestEditorConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset?.id]);

  return (
    <div className={styles.editorWrapper}>
      {!!error && (
        <Alert variant="danger" dismissible onClick={() => setError()}>
          {error}
        </Alert>
      )}
      <Loader loading={documentEditLoading} />
      {!!documentType && !!editorConfig && (
        <DocumentEditor
          id="boomDocxEditor"
          documentServerUrl={process.env.REACT_APP_ONLYOFFICE_URL}
          config={editorConfig}
        />
      )}
    </div>
  );
};

export default AssetEditor;
