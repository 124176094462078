import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Move, X } from "react-feather";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import useEstimateConversion from "../../useEstimateConversion";
import useMoveTaskForm from "./useMoveTaskForm";

const MoveTaskForm = ({ setMessage }) => {
  const { bidItems, movingTask, setMovingTask, taskToMove, setTaskToMove } = useEstimateConversion();
  const { handleSubmit, fields } = useMoveTaskForm({ setMessage, taskToMove });
  return (
    <Modal
      dialogClassName="modal-lg"
      animation={false}
      center
      show={movingTask}
      onHide={() => {
        setMovingTask(false);
        setTaskToMove();
      }}
    >
      <Modal.Header className="d-flex align-items-center p-0">
        <div className={styles.header}>
          <h2 className={`med mb-0 ${styles.headerText}`}>
            <Move color="#0071CE" size={16} /> Move Task
          </h2>
          <Button
            onClick={() => {
              setMovingTask(false);
              setTaskToMove();
            }}
            variant="link"
          >
            <X color="#D3D3D3" size={24} />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body className={`${styles.modalBody} position-relative`}>
        Select a new bid item to move your task
        <div>
          <h3 className={styles.taskTitle}>Task</h3>
          <div className={styles.taskDetails}>
            <span>{taskToMove.name}</span> {taskToMove.cost_code}
          </div>
        </div>
        <div>
          <h3 className={styles.taskTitle}>Current Bid Item</h3>
          <div className={styles.bidDetails}>
            <div>
              {bidItems.find((item) => item.id === taskToMove.parent_id)?.item_number || "-"}
              <span>{bidItems.find((item) => item.id === taskToMove.parent_id)?.name}</span>
            </div>
            {bidItems.find((item) => item.id === taskToMove.parent_id)?.cost_code}
          </div>
        </div>
        <form onSubmit={handleSubmit}>{fields}</form>
      </Modal.Body>
    </Modal>
  );
};

MoveTaskForm.propTypes = {
  addingTask: PropTypes.bool,
  setAddingTask: PropTypes.func,
  setMessage: PropTypes.func,
};

export default MoveTaskForm;
