import React from "react";
import PropTypes from "prop-types";
import useSubmittals from "../../../_submittals/hooks/useSubmittals";
import { Popover, Button } from "react-bootstrap";
import { submittalConfig, useAuth } from "component-library";
import "./optionStyles.scss";
import styles from "./styles.module.scss";

const TableApproval = ({ data }) => {
  const { config } = useAuth();
  const { handleChangeApproval } = useSubmittals();

  const options = config.submittal_statuses.map((status) => ({
    label: status.name,
    value: status.id,
    color: submittalConfig.statusColors[status.id],
  }));

  return (
    <Popover id="popover-basic" className={styles.popoverWrapper}>
      <Popover.Body className="me-4 pt-0 pb-1">
        <div>
          {options.map((option) => (
            <div className="d-flex flex-row align-items-center" key={option.value}>
              <Button
                size="med"
                variant="link"
                className={`statusOption${option.value}`}
                onClick={() => handleChangeApproval(option, data)}
              >
                {option.label}
              </Button>
            </div>
          ))}
        </div>
      </Popover.Body>
    </Popover>
  );
};

TableApproval.propTypes = {
  data: PropTypes.object,
};

export default TableApproval;
