import React from "react";
import { ExternalLink } from "react-feather";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

const CompanyProjects = ({ currentProjects, pastProjects }) => {

  return (
    <div className={styles.projectsWrapper}>
      <h2 className="font-weight-bold med">Current Projects</h2>
      <div className="row">
      <div className="container">
        {!!currentProjects && !!currentProjects.length ? (
          <div className="row align-items-center">
            {currentProjects.map((project) => {
              return (
                <Link to={`/project/${project.id}`}
                  key={project.id}
                  className={`${styles.projectCard} position-relative col-6 col-lg-3 col-xxl-2 text-decoration-none`}
                >
                  <h3 className="text-primary med font-weight-bold">
                    {project.name}
                  </h3>
                  <div className="text-gray2">
                    #{project.job_number}
                  </div>
                  <ExternalLink className={styles.cardIcon} size="15" color="#0071ce" />
                </Link>
              )
            })}
          </div>
        ) : (
          <div>No current projects to display</div>
        )}
        </div>

      </div>
      <div className="mt-4">
        <h2 className="font-weight-bold med">Past Projects</h2>
        <div className="row">
            <div className="container">
              {!!pastProjects && !!pastProjects.length ? (
                    <div className="row align-items-center">
                      {pastProjects.map((project) => {
                        return (
                          <Link to={`/project/${project.id}`}
                            key={project.id}
                            className={`${styles.projectCard} position-relative col-6 col-lg-3 col-xxl-2 text-decoration-none`}
                          >
                            <h3 className="text-primary med font-weight-bold">
                              {project.name}
                            </h3>
                            <div className="text-gray2">
                              #{project.job_number}
                            </div>
                            <ExternalLink className={styles.cardIcon} size="15" color="#0071ce" />
                          </Link>
                        )
                      })}
                </div>
              ) : (
                <div>No past projects to display</div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProjects;
