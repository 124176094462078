import { useEffect, useState, useMemo } from "react";
import { AgGridTable } from "component-library";
import projectByDivisionConfig from "./projectByDivisionConfig";
import gridRowHeights from "../../../utils/gridRowHeights";
import renderMonthColumnDefs from "../gridUtils/renderMonthColumnDefs";

const ProjectByDivisionReportTable = ({ gridData, formData }) => {
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    if (!!formData && !!gridData) {
      setColumnDefs(renderMonthColumnDefs(projectByDivisionConfig, gridData[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  const isGroupOpenByDefault = () => {
    return true;
  };

  const extractGridData = () => {
    let extracted = [];
    gridData[1].forEach((project) => {
      extracted.push({
        project_number: project.project_number,
        project_name: project.project_name,
      });
      if (project?.divisions?.length) {
        project?.divisions.forEach((division) => {
          extracted.push(division);
        });
      }
    });
    return extracted;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let extractedData = useMemo(() => extractGridData(), [gridData]);

  return (
    <AgGridTable
      gridData={extractedData}
      gridConfig={columnDefs}
      rowHeight={gridRowHeights.medium}
      suppressSizeToFit
      autoHeight
      isGroupOpenByDefault={isGroupOpenByDefault}
    />
  );
};

export default ProjectByDivisionReportTable;
