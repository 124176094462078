import React, { useEffect, useState } from "react";
import Gantt, {
  Tasks,
  Dependencies,
  Resources,
  ResourceAssignments,
  Column,
  Toolbar,
  Item,
  Validation,
  Editing,
} from "devextreme-react/gantt";
import styles from "./styles.module.scss";
import { DateTime } from "luxon";
import TaskMenu from "./TaskMenu";
import { Droplet, Settings, StopCircle } from "react-feather";
import "devextreme/dist/css/dx.light.css";
import "devexpress-gantt/dist/dx-gantt.min.css";
import { useNavigate } from "react-router-dom";
import useProject from "../../hooks/useProject";

const GanttChart = ({ showMenu, data }) => {
  let history = useNavigate();
  const { project } = useProject();
  const [redraw, setRedraw] = useState();

  useEffect(() => {
    setRedraw(true);
    setTimeout(() => setRedraw(false), 300);
  }, [showMenu]);

  const handleTaskDoubleClick = (e) => {
    if (e.key !== 0) {
      history(`/project/${project.id}/schedule/task/${e.data.id}`);
      e.cancel = true;
    }
  };

  return (
    <div className="dx-viewport">
      <div className={`${styles.gantt} position-relative`}>
        {!!data && !!data.schedule && !redraw && (
          <Gantt taskListWidth={700} scaleType="weeks" height={"100%"} onTaskDblClick={handleTaskDoubleClick}>
            <Tasks dataSource={data.schedule.tasks} />
            <Dependencies dataSource={data.schedule.dependencies} />
            <Resources dataSource={data.schedule.resources} />
            <ResourceAssignments dataSource={data.schedule.resourceAssignments} />

            <Toolbar>
              {/* <Item name="undo" />
              <Item name="redo" />
              <Item name="separator" /> */}
              <Item name="collapseAll" />
              <Item name="expandAll" />
              <Item name="separator" />
              {/* <Item name="addTask" />
              <Item name="deleteTask" /> */}
              {/* <Item name="separator" /> */}
              <Item name="zoomIn" />
              <Item name="zoomOut" />
            </Toolbar>

            <Column
              dataField="title"
              caption="Tasks"
              width={320}
              cellRender={(c) => (
                <div>
                  {c.data.id === 22 && <Droplet className="me-1" size="20" color="#338DD8" />}
                  {c.data.id === 20 && <StopCircle className="me-1" size="20" color="#338DD8" />}
                  <span className={c.data.id === 34 ? "strike" : ""}>{c.displayValue}</span>
                </div>
              )}
            />
            <Column dataField="id" caption="ID" width={60} alignment="center" />
            <Column
              alignment="center"
              width={45}
              headerCellRender={() => <Settings size="16" color="#0071CE" />}
              cellRender={(item) => <TaskMenu item={item} />}
              cssClass="overflow-visible"
            />
            <Column
              dataField="start"
              caption="Start"
              width={90}
              alignment="center"
              cellRender={(c) => DateTime.fromJSDate(c.displayValue).toFormat("L/d/y")}
            />
            <Column
              dataField="end"
              width={90}
              alignment="center"
              caption="Finish"
              cellRender={(c) => DateTime.fromJSDate(c.displayValue).toFormat("L/d/y")}
            />
            <Column
              caption="Duration"
              alignment="center"
              width={80}
              calculateCellValue={(rowData) =>
                `${Math.ceil(
                  DateTime.fromJSDate(rowData.end)
                    .startOf("day")
                    .diff(DateTime.fromJSDate(rowData.start).startOf("day"))
                    .as("days")
                )} Days`
              }
            />

            <Validation autoUpdateParentTasks />
            <Editing enabled />
          </Gantt>
        )}
      </div>
    </div>
  );
};

export default GanttChart;
