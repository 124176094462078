import React from "react";
import styles from "../styles.module.scss";
import { Folder, Link } from "react-feather";

const AlbumHeaders = () => {
  return (
    <div className={styles.headerWrapper}>
      <div className={styles.listTitle}>
        <Folder className={styles.titleIcon} />
        <h2>Albums</h2>
      </div>
      <div className={styles.unflexHeaders}>
        <div className={styles.headerItem}>Updated</div>
        <div className={styles.rowItemSmall}>
          <Link size="16" color="#7B7B7B" />
        </div>
        <div className={styles.invsibleColAlbums}></div>
      </div>
    </div>
  );
};

export default AlbumHeaders;
