import React from "react";
import formatTime from "../../../utils/formatTime";
import InterfaceIcon from "../../../components/InterfaceIcon";

const ClockIn = ({ data }) => {
  //TODO: sync terms across api
  const clockInTime = data.clock_in || data.time_in;

  return (
    <div className="d-flex align-items-center">
      {!!clockInTime && <InterfaceIcon isWithinGeofence={data.within_geofence} interfaceUsed={data.interface} />}
      <div className="ms-2">{clockInTime ? formatTime(clockInTime) : "-"}</div>
    </div>
  );
};

export default ClockIn;
