import React from "react";
import useUserAdmin from "../hooks/useUserAdmin";
import ModalContent from "./ModalContent";

const PasswordReset = () => {
  const { editUser, changePassword } = useUserAdmin();

  return <>{!!editUser && !!changePassword && <ModalContent />}</>;
};

export default PasswordReset;
