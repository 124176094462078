import React from "react";
import { NavLink } from "react-router-dom";

const Submenu = () => {
  return (
    <nav className="headerTabs">
      <NavLink className="headerTab med text-darkblue11" to={`/field-management/field-logs`}>
        Field Logs
      </NavLink>
      <NavLink className="headerTab med text-darkblue11" to={`/field-management/crew-logs`}>
        Crew Timesheets
      </NavLink>
      <NavLink className="headerTab med text-darkblue11" to={`/field-management/field-notes`}>
        Field Notes
      </NavLink>
      <NavLink className="headerTab med text-darkblue11" to={`/field-management/weekly-reports`}>
        Weekly Reports
      </NavLink>
    </nav>
  );
};

export default Submenu;
