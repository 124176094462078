import OnSiteCard from "../../../components/OnSiteCard";
import { Loader } from "component-library";
import { MapPin } from "react-feather";
import { Link, useParams } from "react-router-dom";
import { ArrowRightCircle } from "react-feather";
import styles from "./styles.module.scss";

const OnSiteList = ({ onSiteCrew, loading }) => {
  const { id } = useParams();

  return (
    <>
      {!!loading ? (
        <Loader />
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.onSiteHeader}>
            <MapPin color="#fff" size={16} />
            <div className={styles.onSiteHeaderText}>Onsite</div>

            {!!onSiteCrew && (
              <div
                className={styles.onSiteHeaderCountWrap}
                style={onSiteCrew.length > 0 ? { backgroundColor: "#0071CE" } : { backgroundColor: "#003C6D" }}
              >
                <div className={onSiteCrew.length > 0 ? styles.onSiteHeaderCount : styles.onSiteHeaderCountZero}>
                  {onSiteCrew.length}
                </div>
              </div>
            )}
          </div>

          {!!onSiteCrew && onSiteCrew.length > 0 ? (
            <>
              {onSiteCrew.map((crew) => (
                <OnSiteCard contact={crew} key={`${crew.first_name}${crew.last_name}${crew.time_in}`} />
              ))}
            </>
          ) : (
            <div className={styles.countZeroWrap}>
              😴
              <div className={styles.countZeroText}>0 People Onsite</div>
              <Link to={`/project/${id}/field-management/field-logs`} className={styles.countZeroLink}>
                Review Field Logs <ArrowRightCircle size={16} />
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
};

OnSiteList.defaultProps = {
  onSiteCrew: [],
};

export default OnSiteList;
