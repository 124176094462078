const yourProjects = (projects) => {
  const filteredProjects = projects.filter((project) => {
    return project.favorited === 1 || parseFloat(project.hours_logged) > 0;
  });
  return filteredProjects;
};

const activeProjects = (projects) => {
  const filteredProjects = projects.filter((project) => {
    return parseFloat(project.on_site) > 0 && project.favorited !== 1;
  });
  return filteredProjects;
};

const otherProjects = (projects) => {
  const filteredProjects = projects.filter((project) => {
    if (!(project.favorited === 1 || parseFloat(project.hours_logged) > 0 || parseFloat(project.on_site) > 0)) {
      return project;
    } else {
      return false;
    }
  });
  return filteredProjects;
};

export { yourProjects, otherProjects, activeProjects };