import { useEffect } from "react";
import { Alert, Button } from "react-bootstrap";
import { Folder, Type } from "react-feather";
import styles from "./styles.module.scss";
import { schema, initialData } from "./album";
import { useFormControl, useApi, photoLibraryApi } from "component-library";
import usePhotoLibrary from "../../context/usePhotoLibrary";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import PropTypes from "prop-types";

const useRenameAlbum = ({ album, setAlbum, setShowModal }) => {
  const { albumId } = useParams();
  const { albumsList, setAlbumsList, setMessage } = usePhotoLibrary();

  const {
    request: renameAlbumRequest,
    data: renameAlbumData,
    loading: renameAlbumLoading,
    error: renameAlbumError,
  } = useApi(photoLibraryApi.updateAlbumDetails);

  const onSubmit = (formData) => {
    renameAlbumRequest({
      name: formData.name,
      album_id: albumId,
      photo_ids: album?.photos?.length ? album.photos.map((photo) => photo.id) : [],
    });
  };

  const { handleSubmit, renderTextField, data } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const onHide = () => {
    setShowModal(false);
  };

  const headerContent = (
    <>
      <div className="d-flex align-items-center">
        <Type color="#0071ce" size={16} />
        <h2 className={styles.modalTitle}>Rename Album</h2>
      </div>
    </>
  );

  const bodyContent = (
    <>
      {!!renameAlbumError && <Alert variant="danger">{renameAlbumError}</Alert>}
      <div className="position-relative">
        {!!renameAlbumLoading && (
          <div className="loaderWrapper">
            <BeatLoader color={"#0071ce"} />
          </div>
        )}
        <div>Current Title</div>
        <div className="d-flex">
          <div className={styles.currentTitleWrapper}>
            <Folder color="#0071ce" size={16} /> <span className={styles.currentTitle}>{album?.name}</span>
          </div>
        </div>
      </div>
      <h3 className={styles.formTitle}>New Title</h3>
      <form id="rename-album" onSubmit={handleSubmit}>
        <div className="form-group">
          <div className="row">
            {renderTextField({
              name: "name",
              label: "Enter your New Album Title here",
            })}
          </div>
        </div>
      </form>
    </>
  );
  const footerContent = (
    <>
      <Button
        variant="orange"
        className="med d-flex align-items-center"
        form="rename-album"
        type="submit"
        aria-label="Rename existing photo album"
        disabled={!data?.name || renameAlbumLoading}
      >
        Rename Album
      </Button>
    </>
  );

  useEffect(() => {
    if (renameAlbumData?.album) {
      setAlbum({
        ...album,
        name: renameAlbumData.album.name,
      });
      setAlbumsList(
        albumsList.map((item) => {
          if (item.id === parseInt(albumId)) {
            return renameAlbumData.album;
          } else {
            return item;
          }
        })
      );
      setShowModal(false);
      setMessage("Album name updated successfully.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renameAlbumData]);

  return {
    headerContent,
    bodyContent,
    footerContent,
    onHide,
  };
};

useRenameAlbum.propTypes = {
  album: PropTypes.object,
  setShowModal: PropTypes.func,
  setAlbum: PropTypes.func,
};

export default useRenameAlbum;
