import React from "react";
import { getCategoryColorFromName, getCategoryAbbrevFromName } from "./helpers";
import styles from "./styles.module.scss";

const Initials = ({ name, size, hasLocation = true, bgColor }) => {
  return (
    <div
      className={`${styles.initials} ${size === "large" ? styles.large : size === "medium" ? styles.medium : ""}`}
      style={{ backgroundColor: bgColor ? bgColor : !hasLocation ? "#D3D3D3" : getCategoryColorFromName(name) }}
    >
      {getCategoryAbbrevFromName(name)}
    </div>
  );
};

export default Initials;
