import React from "react";
import styles from "./styles.module.scss";
import sectionStyles from "../styles.module.scss";

const CriticalPath = ({ day, updateDateField }) => {
  return (
    <section className={`${styles.container} ${sectionStyles.fieldSection}`}>
      <div className="form-group d-flex align-items-start">
        <h3 htmlFor={`${day.id}-critical-path`}>Critical Path</h3>
        <input
          id={`${day.id}-critical-path`}
          onChange={(e) => updateDateField(day, "critical_path", e.target.value)}
          type="text"
          className={`w-100 med form-control ${styles.input}`}
          value={day.critical_path}
        />
      </div>
    </section>
  );
};

export default CriticalPath;
