import React from "react";
import { Routes, Route } from "react-router";
import PrivateRoute from "../../../components/PrivateRoute";
import ProjectDocuments from "./ProjectDocuments";
import ProvideProjectDocuments from "./context/ProvideProjectDocuments";
import ProjectFolderDetail from "./ProjectFolderDetail/ProjectFolderDetail";
import AssetPage from "../AssetPage";

const ProjectDocumentRoutes = () => {
  return (
    <ProvideProjectDocuments>
      <Routes>
        <Route
          index
          path=":filter?"
          element={<PrivateRoute module="ProjectManagement" Component={<ProjectDocuments />} />}
        />
        <Route
          index
          path="folder/:folderName"
          element={<PrivateRoute module="ProjectManagement" Component={<ProjectFolderDetail />} />}
        />
        <Route
          path="asset/:assetId/:submittalId?"
          element={<PrivateRoute module="ProjectManagement" Component={<AssetPage />} />}
        />
      </Routes>
    </ProvideProjectDocuments>
  );
};

export default ProjectDocumentRoutes;
