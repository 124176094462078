import { useState, useEffect } from "react";
import { projectApi, useApi, bidApi } from "component-library";
import useProject from "../../../hooks/useProject";
import stakeHolders from "../../../../utils/stakeholders";

const useProjectContacts = () => {
  const { project, setProject } = useProject();
  const [contacts, setContacts] = useState();
  const [filteredContacts, setFilteredContacts] = useState();
  const [letter, setLetter] = useState("");
  const [activeQuickFilter, setActiveQuickFilter] = useState("All Contacts");
  const [updatedContact, setUpdatedContact] = useState();
  const [editingContact, setEditingContact] = useState();

  const {
    request: contactsRequest,
    data: contactsData,
    loading: contactsLoading,
    error: contactsError,
  } = useApi(projectApi.getProjectContacts);

  const {
    request: addRequest,
    error: addError,
    data: addData,
    loading: addLoading
  } = useApi(bidApi.completeBid);

  const {
    request: removeRequest,
    error: removeError,
    data: removeData,
    loading: removeLoading
  } = useApi(bidApi.completeBid);

  const updateLetter = (newLet) => {
    setLetter(newLet === letter ? "" : newLet);
  };

  const addContact = (contact, role) => {
    const addedContact = { ...project, project_contacts: [...project.project_contacts, { contact, role }] };
    delete addedContact.dot_categories;
    addRequest(addedContact);
  }

  const removeContact = (contactId) => {
    const removedContact = {
      ...project,
      project_contacts: project.project_contacts.filter((contact) => contact.contact_id !== contactId),
    };
    delete removedContact.dot_categories;
    removeRequest(removedContact);
  }

  useEffect(() => {
    if (!!project) {
      contactsRequest(project.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!contactsData) {
      setContacts(contactsData.contacts);
    }
  }, [contactsData]);

  const filterByLetter = () => {
    if (!!contacts && !!contacts.length && !!letter) {
      setFilteredContacts(contacts.filter((contact) => contact.contact.last_name.charAt(0) === letter))
    } else {
      setFilteredContacts(contacts);
    }
  }

  useEffect(() => {
    filterByLetter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letter]);

  useEffect(() => {
    if (!!contacts && !!contacts.length) {
      setFilteredContacts(contacts);
    }
  }, [contacts]);

  useEffect(() => {
    if (!!addData) {
      const existingProjectContactIds = project.project_contacts.map((contact) => contact.id);
      const newContact = addData.project.project_contacts.find((contact) => !existingProjectContactIds.includes(contact.id));

      setProject({
        ...project,
        project_contacts: addData.project.project_contacts
      });
      setContacts([ ...contacts, newContact ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addData]);

  useEffect(() => {
    if (!!removeData) {
      setProject({
        ...project,
        project_contacts: removeData.project.project_contacts
      });
      setContacts(contacts.filter((contact) => contact.contact_id !== editingContact.contact_id));
      setEditingContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeData]);

  useEffect(() => {
    if (!!updatedContact) {
      setContacts(contacts.map((contact) => {
        if (contact.contact_id === updatedContact.id) {
          return {
            ...contact,
            contact: updatedContact
          }
        } else {
          return contact
        }
      }));
      setUpdatedContact();
      setEditingContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedContact]);

  useEffect(() => {
    if (!!contacts && !!contacts.length) {
      switch (activeQuickFilter) {
        case "Subcontractors":
          setFilteredContacts(contacts.filter((contact) => (
            !!contact.role && contact.role.name === "Subcontractor"
          )));
          break;
        case "Suppliers":
          setFilteredContacts(contacts.filter((contact) => (
            !!contact.role && contact.role.name === "Supplier"
          )));
          break;
        case "Stakeholders":
          setFilteredContacts(contacts.filter((contact) => !!contact.role.name && stakeHolders.includes(contact.role.name)));
          break;
        default:
          filterByLetter();
          break;
      } 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuickFilter]);

  return {
    contacts,
    setContacts,
    filteredContacts,
    loading: addLoading || removeLoading ||contactsLoading,
    error: addError || removeError || contactsError,
    letter,
    updateLetter,
    addContact,
    removeContact,
    setUpdatedContact,
    editingContact,
    setEditingContact,
    activeQuickFilter,
    setActiveQuickFilter
  };
};

export default useProjectContacts;
