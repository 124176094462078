import React, {useState} from "react";
import { Button } from "react-bootstrap";
import styles from "./TakeoffDetail.module.scss";
import Materials from "./Materials";
import Subtask from "./Subtask";
import { ChevronRight, ChevronDown } from "react-feather";

const TaskAccordion = ({ task }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles["taskWrapper"]}>
      <Button
        onClick={() => {
          setOpen(!open);
        }}
        variant="link"
        className={`d-flex align-items-center w-100 med py-1 px-0 text-gray2 text-start ${styles["topLevelTask"]}`}
      >
        {!!task.sub_items.length && (
          <div>
            {!!open ? (
              <ChevronDown color="#7B7B7B" size="18" className={styles.icon} />
            ) : (
              <ChevronRight color="#7B7B7B" size="18" className={styles.icon} />
            )}
          </div>
        )}
        <div>{task.name}</div>
      </Button>
      {!!open && (
        <>
          {!!task.sub_items &&
            task.sub_items.map((item) =>
              !!item.sub_items.length ? <Subtask key={item.id} item={item} /> : <Materials key={item.id} task={item} />
            )}
        </>
      )}
    </div>
  );
};

export default TaskAccordion;
