const ytdHours = {
  headerName: "YTD Hours",
  field: "ytd_hours",
  type: "numericColumn",
  pinned: "right",
  width: 115,
  cellStyle: { justifyContent: "flex-end" },
};

export default ytdHours;
