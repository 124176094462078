import React from "react";
import { useAuth } from "component-library";
import { Navigate } from "react-router-dom";
import Unauthorized from "./pages/Unauthorized";

function PrivateRoute({ Component, viewPermission, module }) {
  let { userCan, user, modules } = useAuth();
  const checkModule = (mod) => {
    let currentMod = modules?.find((single) => single.key === mod);
    return currentMod?.active === 1;
  };

  return user ? (
    <>
      {(userCan(viewPermission) || !viewPermission) && (checkModule(module) || !module) ? (
        <>{Component}</>
      ) : (
        <Unauthorized />
      )}
    </>
  ) : (
    <Navigate
      to={{
        pathname: "/login",
      }}
    />
  );
}

export default PrivateRoute;
