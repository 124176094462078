import React from "react";
import styles from "./styles.module.scss";
import Takeoff from "./Takeoff/Takeoff";
import useTakeoffs from "./useTakeoffs";
import { Alert } from "react-bootstrap";
import { BeatLoader } from "react-spinners";

const Takeoffs = () => {
  const { takeoffs, takeoffsLoading, takeoffsError } = useTakeoffs();

  return (
    <section className={`${styles.takeoffsWrapper} position-relative`}>
      {!!takeoffsError && <Alert variant="danger">{takeoffsError}</Alert>}
      <h2 className={styles.tableTitle}>Takeoffs</h2>
      {!!takeoffsLoading && <BeatLoader color="#0071CE" size={16} />}
      {!!takeoffs?.length ? (
        takeoffs.map((takeoff) => <Takeoff takeoff={takeoff} key={takeoff.id} />)
      ) : (
        <div>No takeoffs found</div>
      )}
    </section>
  );
};

export default Takeoffs;
