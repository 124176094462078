import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { initialData } from "../../../../schema/project";
import useSaveBidEdits from "../../../../_bidManagement/hooks/useSaveBidEdits";
import ProjectHeadInfo from "../Fields/ProjectHeadInfo";
import ProjectDetails from "../Fields/ProjectDetails";
import ProjectAddress from "../Fields/ProjectAddress";
import ProjectDates from "../Fields/ProjectDates";
import ProjectDocuments from "../Fields/ProjectDocuments";
import ProjectTeam from "../Fields/ProjectTeam";
import MainProjectContacts from "../Fields/MainProjectContacts";
import OtherDates from "../Fields/OtherDates";
import Sidebar from "../Fields/Sidebar";
import { Loader } from "component-library";

const useProjectSettings = (setProject, refreshProject, bidObject) => {
  const {
    loading,
    error,
    handleSubmit,
    renderTextField,
    renderCheckbox,
    renderCheckboxInGroup,
    renderSelectField,
    renderFieldRowRepeater,
    renderLocationField,
    bidTodoErrors,
    data,
    setFields,
  } = useSaveBidEdits({ bidObject, isBid: false, setProject });

  const [documentLoading, setDocumentLoading] = useState(false);

  const fields = (
    <div className="row">
      <div className="col-md-9">
        <Loader loading={!!loading} />
        {error && <Alert variant="danger">{error}</Alert>}
        <div className="large-form-section pt-0">
          <ProjectHeadInfo renderTextField={renderTextField} renderCheckbox={renderCheckbox} formData={data} />
        </div>

        <div className="large-form-section">
          <ProjectAddress
            renderTextField={renderTextField}
            renderSelectField={renderSelectField}
            renderFieldRowRepeater={renderFieldRowRepeater}
          />
        </div>

        <div className="large-form-section">
          <ProjectDetails
            renderTextField={renderTextField}
            renderCheckbox={renderCheckbox}
            renderCheckboxInGroup={renderCheckboxInGroup}
            setFields={setFields}
            formData={data}
          />
        </div>

        <div className="large-form-section">
          <ProjectDates
            renderTextField={renderTextField}
            renderSelectField={renderSelectField}
            formData={data}
            setFields={setFields}
          />
        </div>

        <div className="large-form-section">
          <OtherDates
            renderSelectField={renderSelectField}
            renderTextField={renderTextField}
            setFields={setFields}
            formData={data}
          />
        </div>

        <div className="large-form-section">
          <ProjectDocuments formData={data} setDocumentLoading={setDocumentLoading} />
        </div>

        <div className="large-form-section">
          <ProjectTeam formData={data} renderSelectField={renderSelectField} />
        </div>
        <div className="large-form-section">
          <MainProjectContacts
            setFields={setFields}
            renderLocationField={renderLocationField}
            errors={error}
            data={data}
          />
        </div>
      </div>
      <div className="col-md-3">
        <div className="fixed-sidebar">
          <Sidebar
            renderSelectField={renderSelectField}
            renderCheckbox={renderCheckbox}
            renderCheckboxInGroup={renderCheckboxInGroup}
            data={data}
            setFields={setFields}
            errors={bidTodoErrors}
            loading={loading}
            docLoading={documentLoading}
          />
        </div>
      </div>
    </div>
  );
  return {
    initialData,
    fields,
    handleSubmit,
    loading,
    documentLoading,
    data,
    setFields,
  };
};

export default useProjectSettings;
