import { Joi } from "component-library";
import { DateTime } from "luxon";

const schema = {
  from_date: Joi.date().required(),
  to_date: Joi.date().required(),
  include_inactive: Joi.checkbox(),
};

const to = DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd");
const from = new Date();
from.setDate(from.getDate() - 7);

const initialData = {
  from_date: DateTime.fromJSDate(from).toFormat("yyyy-MM-dd"),
  to_date: to,
  include_inactive: 0,
};

export { schema, initialData };
