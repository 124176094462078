import "./App.scss";
import WeatherWrap from "./components/WeatherWrap";
import AppLoader from "./components/AppLoader";
import { ProvideNotifications, ProvideAuth } from "component-library";

function App() {
  return (
    <div className="App">
      <ProvideAuth>
        <ProvideNotifications>
          <WeatherWrap>
            <AppLoader />
          </WeatherWrap>
        </ProvideNotifications>
      </ProvideAuth>
    </div>
  );
}

export default App;
