import React from "react";
import { AgGridTable, useAuth } from "component-library";
import formListConfig from "./formListConfig";
import PropTypes from "prop-types";
import useProject from "../../../../../hooks/useProject";
import gridRowHeights from "../../../../../../utils/gridRowHeights";

const FieldFormsGrid = ({ formsLoading, forms }) => {
  const { project } = useProject();
  const { user } = useAuth();

  return (
    <>
      <AgGridTable
        gridData={forms}
        gridConfig={formListConfig}
        loading={formsLoading}
        rowHeight={gridRowHeights.medium}
        navigateTo={`/project/${project.id}/field-management/field-form`}
        id={`${user.id}_field_forms_grid`}
        navigationParam="js_id"
        presetStyle="sortableTable"
        autoHeight
      />
    </>
  );
};

FieldFormsGrid.propTypes = {
  forms: PropTypes.array,
  formsLoading: PropTypes.bool,
};

export default FieldFormsGrid;
