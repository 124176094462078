import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import NotesModal from "../../../_projects/project/FieldManagement/FieldLogs/DailyFieldLog/Tasks/NotesModal";
import { fieldLogsApi } from "component-library";
import { useApi } from "component-library";

const NoteCount = ({ data }) => {
  const [openNotes, setOpenNotes] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    loading: notesLoading,
    error: notesError,
    request: noteRequest,
    data: noteData,
  } = useApi(fieldLogsApi.getFieldNotes);

  const handleCellClicked = () => {
    noteRequest(data.workday_id, data.foreman_id);
    setModalOpen(true);
  };

  useEffect(() => {
    if (noteData?.notes) {
      setOpenNotes(noteData.notes);
    }
  }, [noteData]);

  return (
    <>
      {data.note_count ? (
        <div className={styles.linkLight} onClick={() => handleCellClicked()}>
          {data.note_count}
        </div>
      ) : (
        <div>-</div>
      )}
      {!!modalOpen && (
        <NotesModal
          openNotes={openNotes}
          setOpenNotes={setOpenNotes}
          setModalOpen={setModalOpen}
          loading={notesLoading}
          error={notesError}
        />
      )}
    </>
  );
};

export default NoteCount;
