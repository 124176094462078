import React from "react";
import { Button } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import { ArrowRightCircle } from "react-feather";
import { Link } from "react-router-dom";
import Task from "./Task/Task";
import useProject from "../../../../../hooks/useProject";
import styles from "./styles.module.scss";
import useToggleTasks from "../../../../../../_resourceManagement/FieldLogs/hooks/useToggleTasks";
import useTasks from "./useTasks";
import PropTypes from "prop-types";
import DailyAccordion from "../components/DailyAccordion";

const Tasks = ({ selectedForemenIds }) => {
  const { project } = useProject();
  const { tasks, filteredTasks, active, setActive, tasksLoading, tasksError, setTasks } = useTasks({
    selectedForemenIds,
  });
  const { openTasks, toggleTasksOpen } = useToggleTasks({ tasks });

  const headerContent = (
    <h2 className={`${styles.title} mb-0`}>
      Tasks
      {!!tasksLoading ? (
        <BeatLoader size={10} color={"#0071ce"} />
      ) : (
        <span className={`${styles.title} ms-1`}>({filteredTasks?.length || 0})</span>
      )}
    </h2>
  );

  const HeaderContent = () => (
    <div className={styles.tasksHeader}>
      <div className="d-flex align-items-center">
        {!!filteredTasks?.length ? (
          <Button className="d-flex align-items-center px-0 me-2" onClick={() => setActive(!active)} variant="link">
            {headerContent}
          </Button>
        ) : (
          <div className={styles.headerContent}>{headerContent}</div>
        )}

        <Link className={styles.link} to={`/project/${project?.id}/field-management/field-logs`}>
          Field Logs <ArrowRightCircle size={16} color="#0071ce" />
        </Link>
      </div>
    </div>
  )

  return (
    <div className={`position-relative`}>
      <DailyAccordion
        headerContent={<HeaderContent />}
        active={active}
        setActive={setActive}
        loading={tasksLoading}
        error={tasksError}
      >
        <>
          {!tasksLoading && !!filteredTasks?.length ? (
            filteredTasks.map((task, index) => (
              <div key={index}>
                <Task
                  task={task}
                  openTasks={openTasks}
                  toggleTasksOpen={toggleTasksOpen}
                  tasks={tasks}
                  setTasks={setTasks}
                />
              </div>
            ))
          ) : (
            <>{!tasksLoading && !tasksError && <div className={styles.noTasks}>No tasks found</div>}</>
          )}
        </>
      </DailyAccordion>
    </div>
  );
};

Tasks.propTypes = {
  selectedForemenIds: PropTypes.array,
};

export default Tasks;
