import React from "react";
import { Routes, Route } from "react-router";
import BidPage from "../components/pageLayout/BidPage";
import CreateBidDetails from "../components/pages/CreateBidDetails";
import CloseBid from "../components/pages/CloseBid";
import CompleteBid from "../components/pages/CompleteBid";
import RFQs from "../components/pages/RFQs";
import PrivateRoute from "../components/PrivateRoute";

const BidRoutes = () => {
  return (
    <Routes>
      <Route index element={<PrivateRoute module="BidBuild" Component={<BidPage />} />} />
      <Route path="bid/:id" element={<PrivateRoute module="BidBuild" Component={<BidPage />} />} />
      <Route
        path="edit"
        element={<PrivateRoute viewPermission="bid-edit" module="BidBuild" Component={<CreateBidDetails />} />}
      />
      <Route
        path="edit"
        element={<PrivateRoute viewPermission="bid-edit" module="BidBuild" Component={<CreateBidDetails />} />}
      />
      <Route path="complete" element={<PrivateRoute module="BidBuild" Component={<CompleteBid />} />} />
      <Route path="close" element={<PrivateRoute module="BidBuild" Component={<CloseBid />} />} />
      <Route path="rfqs/*" element={<RFQs />} />
    </Routes>
  );
};

export default BidRoutes;
