import React from "react";
import useUserAdmin from "../../../_admin/pages/UserAdmin/hooks/useUserAdmin";

const Edit = ({ data }) => {
  const { setEditUser } = useUserAdmin();

  const handleEditUser = () => {
    setEditUser(data);
  };

  return (
    <button className="btn btn-primary" onClick={handleEditUser}>
      Edit
    </button>
  );
};

export default Edit;
