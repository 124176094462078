import React from "react";
import { Modal } from "react-bootstrap";
import useAddPerdiem from "./hooks/useAddPerDiem";
import PropTypes from "prop-types";

const PerDiemModal = ({ close, timesheet, setTimesheet }) => {
  const { content } = useAddPerdiem({
    timesheet,
    setTimesheet,
    close,
  });

  return (
    <Modal show={true} onHide={close} centered>
      <Modal.Body>{content}</Modal.Body>
    </Modal>
  );
};

PerDiemModal.propType = {
  close: PropTypes.func.isRequired,
  timesheet: PropTypes.object.isRequired,
  setTimesheet: PropTypes.func.isRequired,
};

export default PerDiemModal;
