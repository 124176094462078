import React from "react";
import useFieldForms from "./useFieldForms";
import PropTypes from "prop-types";
import InteriorPage from "../../../../../components/pageLayout/InteriorPage";
import { Briefcase } from "react-feather";
import Submenu from "../Submenu";
import useProject from "../../../../hooks/useProject";
import FieldFormsGrid from "./FieldFormsGrid";
import { FilterSearch, dropdownStylesWithWidth } from "component-library";
import Select from "react-select";

const FieldForms = () => {
  const { pageTitle, PageTag, loading: projectLoading } = useProject();
  const {
    forms,
    formsLoading,
    formsError,
    filteredForms,
    formOptions,
    selectedFormOption,
    setSelectedFormOption,
    setQ,
  } = useFieldForms();
  return (
    <InteriorPage
      navTitle={pageTitle}
      pageTitle="Field Management"
      navIcon={<Briefcase color="#fff" size={14} />}
      NavContent={PageTag}
      pageSubtitlePrimaryContent={() => <Submenu />}
      loading={formsLoading || projectLoading}
    >
      <div className="d-flex align-items-center justify-content-end mb-4">
        <Select
          options={formOptions}
          name="estimator_id"
          id="estimatorId"
          onChange={(value) => setSelectedFormOption(value)}
          styles={dropdownStylesWithWidth(240)}
          value={formOptions.find((est) => est.value === selectedFormOption)}
          aria-label="filter rows by timeframe dropdown list"
          isClearable={true}
        />
        <div className="ms-5">
          <FilterSearch inline={true} searchFunction={setQ} placeholder="Find a Submission" />
        </div>
      </div>
      {!formsError && !formsLoading && !!filteredForms?.length && (
        <>
          <FieldFormsGrid formsLoading={formsLoading} forms={filteredForms} />
        </>
      )}
      {!forms?.length && !formsError && !formsLoading && <>No forms found</>}
    </InteriorPage>
  );
};

FieldForms.propTypes = {
  currentSelectedDate: PropTypes.string,
  selectedForemenIds: PropTypes.array,
};

export default FieldForms;
