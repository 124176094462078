import React from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { Type, X } from "react-feather";
import useRenameFolder from "./useRenameFolder";
import { BeatLoader } from "react-spinners";

const RenameFolderModal = ({ showModal, setShowModal }) => {
  const { handleSubmit, fields, renderSubmitButton, renameFolderError, renameFolderLoading, data } = useRenameFolder({
    setShowModal,
  });
  return (
    <>
      <Modal dialogClassName="modal-lg" centered animation={false} show={showModal} onHide={() => setShowModal(false)}>
        <div className="position-relative">
          {!!renameFolderLoading && (
            <div className="loaderWrapper">
              <BeatLoader color="#0071ce" />
            </div>
          )}
          <Modal.Header
            closeLabel="close add document modal"
            className={`${styles.header} d-flex align-items-center justify-content-between`}
          >
            <div className="d-flex align-items-center">
              <Type size={16} color="#0071ce" />
              <h5 className={`mb-0 med ${styles.title}`}>Rename Folder</h5>
            </div>
            <Button variant="link" className="p-0" onClick={() => setShowModal(false)}>
              <X size={24} color="#d3d3d3" />
            </Button>
          </Modal.Header>
          <form onSubmit={handleSubmit}>
            <Modal.Body className={styles.body}>
              {!!renameFolderError && <Alert variant="danger">{renameFolderError}</Alert>}

              {fields}
            </Modal.Body>
            <Modal.Footer className={styles.footer}>
              <Button
                variant="gray2"
                className={`${styles.modalCancelButton}`}
                onClick={() => setShowModal(false)}
                aria-label="cancel add document modal"
              >
                Cancel
              </Button>
              {renderSubmitButton("Rename Folder", !!renameFolderLoading || !data.folderName, styles.submitButton)}
            </Modal.Footer>
          </form>
        </div>
      </Modal>
    </>
  );
};

RenameFolderModal.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default RenameFolderModal;
