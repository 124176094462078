import React from "react";
import InteriorPage from "../pageLayout/InteriorPage";

const NotificationSettings = () => {
  return (
    <InteriorPage
      navTitle="Notification Settings"
    >
      <p>Notification Settings</p>
    </InteriorPage>
  );
};

export default NotificationSettings;
