import React from "react";
import styles from "../styles.module.scss";
import PropTypes from "prop-types";
import { displayDate } from "component-library";

const PreviousLocate = ({ locate }) => {
  return (
    <>
      <tr className={styles.relocate} key={locate.id}>
        <td></td>
        <td>{displayDate(locate.active_date)}</td>
        <td className="ps-4 py-2">#{locate.number}</td>
        <td></td>
        <td></td>
        <td>{displayDate(locate.expiration)}</td>
        <td>{locate.status}</td>
      </tr>
      {locate.relocate && locate.relocate.map((relocate) => <PreviousLocate locate={relocate} key={relocate.id} />)}
    </>
  );
};

PreviousLocate.propTypes = {
  locate: PropTypes.object.isRequired,
};

export default PreviousLocate;
