import React from "react";
import StatusItem from "./StatusItem.js";
import StatusJobsChart from "./StatusJobsChart";
import styles from "./styles.module.scss";

const StatusGrid = ({ jobs }) => {
  return (
    <section className="col">
      <header>
        <h1 className="med">All Jobs</h1>
      </header>
      <div className={styles.boomboardGrid}>
        <div className={styles["status-overall"]}>
          <StatusJobsChart />
        </div>
        {jobs.map((job) => (
          <StatusItem job={job} key={job.job_id} />
        ))}
      </div>
    </section>
  );
};

export default StatusGrid;
