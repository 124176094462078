import React from "react";
import Favorite from "../../Favorite";
import map from "./tractics.png";
import styles from "./styles.module.scss";
import OnSite from "./OnSite";
import PW from "./PW";
import Category from "./Category";

const Card = ({ project }) => {
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <a href={`/project/${project?.id}`} className={styles.cardTitle}>
          {!!project?.job_number && <div className={styles.projectNumber}>{project?.job_number}</div>}
          <div className={styles.projectName}>{project?.name}</div>
        </a>

        <Favorite data={project} />
      </div>

      <a href={`/project/${project?.id}`}>
        <div className={styles.cardBody}>
          <img src={project?.thumbnail || map} alt={project?.name} className={styles.cardImage} loading="lazy" />
        </div>
      </a>
      <div className={styles.cardFooter}>
        <div className={styles.container}>
          <OnSite data={project} />
        </div>
        <div className={styles.container}>
          <PW data={project} />
          <Category data={project} />
        </div>
      </div>
    </div>
  );
};
export default Card;
