/**
 * Generates a message based on the selected items and pin action.
 * @param {Array} selectedItems - An array of selected items.
 * @param {boolean} isPinAction - A boolean indicating whether the action is to pin or unpin.
 * @returns {string} A message indicating the action to be performed on the selected items.
 */

const getPinUnpinMessage = (selectedItems, isPinAction) => {
  if (selectedItems && selectedItems.length > 0) {
    const selectedPhotos = selectedItems.filter((item) => item.type === "photo").length;
    const selectedAlbums = selectedItems.filter((item) => item.type === "album").length;
    const actionName = isPinAction ? "Pin" : "Unpin";
    if (selectedPhotos && selectedAlbums) {
      return `${actionName} ${selectedPhotos} Photo(s) and ${selectedAlbums} Album(s)?`;
    } else if (selectedPhotos) {
      return `${actionName} ${selectedPhotos} Photo(s)?`;
    } else {
      return `${actionName} ${selectedAlbums} Album(s)?`;
    }
  }
  return "";
};

export default getPinUnpinMessage;
