import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddNote from "./AddNote";
import { AgGridTable, useSubmittalFilters, submittalConfig, HeaderButton, useAuth } from "component-library";
import SubmittalsList from "./SubmittalsList";
import SubmittalsPageHeader from "./SubmittalsPageHeader";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";
import { Briefcase, ChevronDown, ChevronRight } from "react-feather";
import useSubmittals from "../../hooks/useSubmittals";
import useToggleSections from "../../hooks/useToggleSections";
import submittalPMListConfig from "../../../config/submittalPMListConfig";
import useProject from "../../../_projects/hooks/useProject";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import { Zap } from "react-feather";
import { ReactComponent as BBall } from "../../../assets/bball.svg";
import { useNavigate } from "react-router-dom";
import TableApproval from "../../../config/submittalPMListConfig/columnRenders/TableApproval";
import StatusValue from "../../../config/submittalPMListConfig/columnRenders/StatusValue";
import gridRowHeights from "../../../utils/gridRowHeights";

const SubmittalsListing = () => {
  let history = useNavigate();

  const { userIs, userCan, config, getCompanyName, user } = useAuth();
  const isPM = userIs("project-manager");

  const { id } = useParams();
  const { loading, error, pageTitle, PageTag } = useProject();
  const { submittals, submittal, setSubmittal, updateError } = useSubmittals();
  const [sortedSubmittals, setSortedSubmittals] = useState([]);
  const [updatedConfig, setUpdatedConfig] = useState([]);
  const { showSection, sectionVisible } = useToggleSections();

  const { filteredSubmittals, statusClick, statusFilter, RenderFilters } = useSubmittalFilters(submittals, config);

  useEffect(() => {
    if (filteredSubmittals) {
      setSubmittal();
      setSortedSubmittals({
        Boom: filteredSubmittals.filter((e) => e.bic_id === 1),
        Source: filteredSubmittals.filter((e) => e.bic_id === 3),
        "Project Engineer": filteredSubmittals.filter((e) => e.bic_id === 2),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSubmittals]);

  useEffect(() => {
    if (submittalPMListConfig && config) {
      const submittalStatuses = config.submittal_statuses.map((status) => ({
        label: status.name,
        value: status.id,
        color: submittalConfig.statusColors[status.id],
      }));

      setUpdatedConfig([
        ...submittalPMListConfig,
        {
          headerName: "Status",
          field: "status_name",
          editable: true,
          cellEditor: TableApproval,
          cellEditorParams: submittalStatuses,
          cellEditorPopup: true,
          cellEditorPopupPosition: "over",
          cellRenderer: StatusValue,
          minWidth: 175,
          valueGetter: ({ data }) =>
            data?.status_id ? submittalStatuses.find((status) => status.value === data.status_id).label : null,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittalPMListConfig, config]);

  const ActionLinks = () =>
    userCan("submittal-create") && (
      <div className="d-flex flex-row">
        <HeaderButton
          linkTo={`/project/${id}/submittals/add-submittals`}
          icon={<Zap color={"#0071ce"} size={16} />}
          text="Quick Add"
          ariaLabel="Quick Add New Submittal"
        />
        <HeaderButton linkTo={`/project/${id}/submittals/add-submittal`} ariaLabel="Add New Submittal" />
      </div>
    );

  const handleCellClick = ({ data, column }) => {
    if (!["status_name"].includes(column?.colId)) {
      let url = `/project/${id}/submittals/${data.id}`;
      history(url);
    }
  };
  return (
    <InteriorPage
      loading={loading}
      error={error || updateError}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle="Submittals"
      pageTitleSecondaryContent={ActionLinks}
      NavContent={PageTag}
    >
      <>
        {submittals && (
          <>
            {isPM && (
              <SubmittalsPageHeader statusClick={statusClick} statusFilter={statusFilter} submittals={submittals} />
            )}
          </>
        )}
        {submittals && !isPM && <SubmittalsList id={id} initialSubmittals={submittals} hideHeader />}

        {isPM && submittals && <RenderFilters />}

        {isPM && sortedSubmittals && (
          <>
            {Object.keys(sortedSubmittals).map((key) => (
              <div className="row mb-5" key={key}>
                <div className="col">
                  <section className="sortable-table">
                    <header className="d-flex justify-content-between align-items-end pb-1 border-bottom border-gray5">
                      <hgroup className="d-lg-flex align-items-end">
                        <h4 className={`${styles["table-heading"]} d-flex align-items-center me-lg-3`}>
                          <BBall className="me-2" />
                          {key !== "Boom" ? <>{key}</> : <>{getCompanyName()}</>}
                        </h4>
                        <h6
                          className={`${styles["table-count"]} mx-lg-3`}
                        >{`${sortedSubmittals[key].length} Total`}</h6>
                      </hgroup>
                      <Button onClick={showSection(key)} variant="link">
                        {!!sectionVisible(key) ? <ChevronDown color="#c4c4c4" /> : <ChevronRight color="#c4c4c4" />}
                      </Button>
                    </header>
                    {sectionVisible(key) && (
                      <>
                        <AgGridTable
                          id={`${user.id}_${key}_submittal_grid`}
                          gridData={sortedSubmittals[key].map((s) => ({
                            ...s,
                            status_name: !!s.status && s.status.name,
                          }))}
                          gridConfig={updatedConfig}
                          loading={loading}
                          handleCellClick={handleCellClick}
                          rowHeight={gridRowHeights.large}
                          presetStyle="sortableTable"
                          rowHeight={gridRowHeights.large}
                        />
                      </>
                    )}
                  </section>
                </div>
              </div>
            ))}
            <AddNote submittal={submittal} setSubmittal={setSubmittal} />
          </>
        )}
      </>
    </InteriorPage>
  );
};

export default SubmittalsListing;
