import React from "react";
import { useAuth } from "component-library";
import ProjectManagerSubmittal from "./ProjectManagerSubmittal";
import FieldSubmittal from "./FieldSubmittal";

const SubmittalDetail = () => {
  const { userIs } = useAuth();
  return <>{userIs("project-manager") ? <ProjectManagerSubmittal /> : <FieldSubmittal />}</>;
};

export default SubmittalDetail;
