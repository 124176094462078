import React, { useState, useEffect } from "react";
import EditContactForm from "../Contacts/EditContactForm";

const EditCompanyContacts = ({
  company,
  setCompany,
  editingContact,
  setEditingContact,
}) => {
  const [editedContact, setEditedContact] = useState();

  const updateLocation = (location) => {
    let updatedLocation = { ...location };
    const locationHasContact = !!location.contacts && location.contacts.find((c) => c.contact.id === editedContact.id);
    const contactHasLocation = !!editedContact.locations && editedContact.locations.find((l) => l.id === location.id);
    
    if (!!locationHasContact && !contactHasLocation) {
      updatedLocation = {...location, contacts: location.contacts.filter((c) => c.contact.id !== editedContact.id)}
    } else if (!!locationHasContact && !!contactHasLocation) {
      updatedLocation = {...location, contacts: location.contacts.map((c) => c.contact.id === editedContact.id ? { contact: editedContact } : c)}
    } else if (!locationHasContact && !!contactHasLocation) {
      updatedLocation = { ...location, contacts: [...location.contacts, { contact: editedContact }]}
    }

    return updatedLocation;
  }

  const setContactLocations = () => {
    setCompany({
      ...company,
      locations: company.locations.map((existingLoc) => updateLocation(existingLoc))
    });
  };

  useEffect(() => {
    if (!!editedContact) {
      setContactLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedContact]);

  return (
    <>
      {!!editingContact && (
        <EditContactForm
          setAddingContact={setEditingContact}
          setEditedContact={setEditedContact}
          contact={editingContact}
          initialCompany={company}
        />
      )}
    </>
  )
}

export default EditCompanyContacts;