import { useState, useEffect } from "react";
import { useApi, telematicsApi, useAuth } from "component-library";

const useProvideFleetManagementIframes = () => {
  const { config, user } = useAuth();
  const [authKey, setAuthKey] = useState();
  const [userBsuid, setUserBsuid] = useState();
  const [companyCode, setCompanyCode] = useState();

  const {
    data: usersData,
    request: usersRequest,
    error: usersError,
    loading: usersLoading,
  } = useApi(telematicsApi.getUsers);

  const {
    data: authKeyData,
    request: authKeyRequest,
    error: authKeyError,
    loading: authKeyLoading,
  } = useApi(telematicsApi.getIakAuthKey);

  useEffect(() => {
    authKeyRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!companyCode) {
      usersRequest({ companyCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyCode]);

  useEffect(() => {
    if (!!usersData?.success) {
      setUserBsuid(usersData.data.find((userData) => userData.orig_id === user.id)?.bsuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData]);

  useEffect(() => {
    if (config?.company_code) {
      setCompanyCode(config.company_code);
    }
  }, [config]);

  useEffect(() => {
    if (!!authKeyData?.success) {
      setAuthKey(authKeyData.auth_key);
    }
  }, [authKeyData]);

  return {
    authKey,
    userBsuid,
    usersError,
    usersLoading,
    authKeyError,
    authKeyLoading,
    companyCode,
  };
};

export default useProvideFleetManagementIframes;
