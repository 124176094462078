import React from "react";
import useDeleteFile from "./useDeleteFile";
import PropTypes from "prop-types";
import { ConfirmModal } from "component-library";
import { Trash2 } from "react-feather";

const DeleteFileModal = ({ asset, showModal, setShowModal }) => {
  const { deleteFileLoading, deleteFileError, handleDeleteFile } = useDeleteFile({ asset });

  return (
    <ConfirmModal
      showConfirmation={showModal}
      setShowConfirmation={setShowModal}
      confirmFunction={() => {
        handleDeleteFile();
      }}
      Icon={() => <Trash2 color="#ff6700" size={16} />}
      loading={deleteFileLoading}
      error={deleteFileError}
      name={"Delete File"}
      message={"Are you sure you want to delete this file? This action cannot be undone."}
      type="file"
      itemName={asset?.file_name}
      buttonText="Delete File"
    />
  );
};

DeleteFileModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default DeleteFileModal;
