import React, { useRef, useEffect, useState } from "react";
import ContactOptions from "./ContactOptions";
import styles from "./styles.module.scss";
import { Table, Card } from "react-bootstrap";
import debounceFunc from "../../hooks/useDebounce";
import contactsApi from "../../api/contacts";
import { useApi } from "component-library";
import { Plus, Search } from "react-feather";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";

const { debounce } = debounceFunc();

const ContactSearch = ({
  setContact,
  setAddNew,
  placeholder,
  error,
  contact,
  label = "Search in address book",
  colDisplay = false,
  contacts = [],
}) => {
  const node = useRef();
  const [contactOptions, setContactOptions] = useState([]);
  const [cursor, setCursor] = useState(0);
  const [searchOpen, setSearchOpen] = useState(false);
  const [q, setQ] = useState("");
  const { data: searchData, request: search } = useApi(contactsApi.searchContacts);

  const filterContacts = (arr1, arr2) => {
    let result = [];
    for (let i = 0; i < arr1.length; i++) {
      let shouldInclude = true;
      const id1 = arr1[i].id;
      for (let j = 0; j < arr2.length; j++) {
        const id2 = arr2[j].contact?.id;
        if (id1 === id2) {
          shouldInclude = false;
          break;
        }
      }
      if (shouldInclude) {
        result = [...result, arr1[i]];
      }
    }
    return result;
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    if (searchData && searchData.contacts) {
      if (searchData.contacts.current_page <= 1) {
        setContactOptions(filterContacts(searchData.contacts.data, contacts));
        setCursor(0);
      } else {
        setContactOptions(filterContacts([...contactOptions, ...searchData.contacts.data], contacts));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  useEffect(() => {
    if (searchData && searchData.contacts) {
      setContactOptions(filterContacts(searchData.contacts, contacts));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectContact = (contact) => {
    setContact(contact);
    setSearchOpen(false);
  };

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setQ("");
      setTimeout(function () {
        setContactOptions([]);
      }, 300);
    } else {
      setQ(e.target.value);
      debounce(search, { q: e.target.value });
    }
  };

  const handleLoadMore = () => {
    debounce(search, { q: q, page: searchData.contacts.current_page + 1 });
  };

  const handleClick = (e) => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }
    setSearchOpen(false);
  };

  useEffect(() => {
    if (contact) {
      setQ(`${contact.first_name} ${contact.last_name}`);
    } else {
      setQ("");
    }
  }, [contact]);

  useEffect(() => {
    debounce(search, { q: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`form-group position-relative ${error ? "error" : ""}`} ref={node}>
      <label htmlFor="contact-search">{label}</label>
      <div className="input-search">
        <input
          type="text"
          id="contact-search"
          onChange={handleSearch}
          className="form-control pe-4"
          onFocus={() => setSearchOpen(true)}
          autoComplete="nope"
          value={q}
          placeholder={placeholder || "Start typing to search for a company, engineer or contact name"}
          onKeyDown={(e) => {
            switch (e.key) {
              case "Enter":
                e.preventDefault();
                if (cursor < contactOptions.length) {
                  setContact(contactOptions[cursor]);
                } else if (cursor === contactOptions.length) {
                  setAddNew(true);
                }
                setSearchOpen(false);
                break;
              case "ArrowDown":
                if (cursor < contactOptions.length) {
                  setCursor(cursor + 1);
                } else if (cursor === contactOptions.length) {
                  setCursor(0);
                }
                break;
              case "ArrowUp":
                if (cursor > 0) {
                  setCursor(cursor - 1);
                } else if (cursor === 0) {
                  setCursor(contactOptions.length);
                }
                break;
              default:
                break;
            }
          }}
        />
        <Search size="14" className="input-group-append" />
      </div>
      {!!error && <span className="help-block is-error">Required.</span>}
      {searchOpen && (
        <>
          <Card className={styles.options} id="options">
            <div className={styles.optionWrapper}>
              <InfiniteScroll
                dataLength={contactOptions.length}
                next={() => handleLoadMore()}
                hasMore={
                  !!searchData && searchData.contacts.current_page < searchData.contacts.last_page ? true : false
                }
                loader={<p className="p-2">Loading...</p>}
                scrollableTarget="options"
              >
                <Table className={`mb-0 ${styles["option-table"]}`}>
                  <tbody>
                    <ContactOptions
                      contacts={contactOptions}
                      handleSelect={handleSelectContact}
                      selected={cursor}
                      colDisplay={colDisplay}
                    />
                  </tbody>
                </Table>
              </InfiniteScroll>
            </div>
          </Card>
          <div className={styles.addNewWrapper}>
            <button
              onClick={() => setAddNew(true)}
              type="button"
              tabIndex="-1"
              className={`${styles["add-new-button"]} ${
                cursor === contactOptions.length ? "option-active" : ""
              } med btn btn-link d-flex justify-content-between align-items-center`}
            >
              <span>Create New Contact</span>
              <Plus size="14" />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

ContactSearch.propTypes = {
  contact: PropTypes.object,
  error: PropTypes.any,
  placeholder: PropTypes.string,
  setContact: PropTypes.func,
  setAddNew: PropTypes.func,
};

export default ContactSearch;
