import React, { useState, useEffect } from "react";
import useProject from "../../../hooks/useProject";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import { Briefcase, Link, ZoomIn, ZoomOut, PlusCircle, Edit3 } from "react-feather";
import { HeaderButton, ContactPill, ConfirmModal, useAuth } from "component-library";
import styles from "./styles.module.scss";
import Comments from "./Comments";
import usePhotoDetail from "./usePhotoDetail";
import { Alert, Button } from "react-bootstrap";
import UserDetails from "./UserDetails/UserDetails";
import AddPhotoToAlbumModal from "../Modals/AddPhotoToAlbumModal/AddPhotoToAlbumModal";
import AddNote from "./AddNote";
import CreateAlbumModal from "../Modals/CreateAlbumModal";
import UploadPhotoModal from "../Modals/UploadPhotoModal";
import RenamePhotoModal from "../Modals/RenamePhotoModal";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import { mapStyles } from "../../../../utils/mapStyles";
import MapSettingsCheck from "../../../../components/MapSettingsCheck";
import FsLightbox from "fslightbox-react";
import Carousel from "../../../../components/PhotoLibrary/GalleryView/Carousel";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import PhotoHistory from "./Components/PhotoHistory";

//TODO: getting big, needs to be split into separate components

const PhotoDetail = () => {
  const { userCan } = useAuth();
  const { project, pageTitle, loading, error } = useProject();
  const [lightboxToggler, setLightboxToggler] = useState(false);
  const {
    photoDetails,
    photoDetailsLoading,
    photoDetailsError,
    showAddToAlbumModal,
    setShowAddToAlbumModal,
    setPhotoDetails,
    showCreateAlbumModal,
    setShowCreateAlbumModal,
    PhotoActionButtons,
    showUploadFileModal,
    setShowUploadFileModal,
    notes,
    setNotes,
    updateDetailLoading,
    updateDetailError,
    showConfirmDeleteModal,
    setShowConfirmDeleteModal,
    showRenamePhotoModal,
    setShowRenamePhotoModal,
    handleDeletePhoto,
    photoDeleteLoading,
    photoDeleteError,
    message,
    setMessage,
    thumbLists,
  } = usePhotoDetail();
  const [currentView, setCurrentView] = useState(0);
  const navigate = useNavigate();

  const containerStyle = {
    width: "100%",
    height: "25rem",
    borderRadius: "0.4rem",
  };

  const polyOptions = {
    strokeWeight: 1,
    fillOpacity: 0.3,
    strokeOpacity: 0,
    editable: false,
    fillColor: "#0071ce",
  };

  const parseLatLng = (data) => {
    return data.map((obj) => {
      const newObj = { ...obj };
      newObj.lat = parseFloat(newObj.lat);
      newObj.lng = parseFloat(newObj.lng);
      return newObj;
    });
  };

  const onSelectPhoto = (index) => {
    if (thumbLists?.length && index >= 0 && index < thumbLists.length) {
      const selectedPhoto = thumbLists[index];
      navigate(`/project/${project?.id}/photo-library/photo/${selectedPhoto?.id}`);
    }
  };

  useEffect(() => {
    if (thumbLists?.length && photoDetails?.id) {
      const imageIndex = thumbLists.findIndex((photo) => photo.id === photoDetails.id);
      if (imageIndex !== -1) {
        setCurrentView(imageIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbLists, photoDetails]);

  const pageSubtitleContent = () => (
    <>
      {photoDetailsLoading ? (
        <BeatLoader color="#0071ce" />
      ) : (
        <>
          <div className="d-flex align-items-center">
            {!!project && !!photoDetails && !photoDetailsError && !photoDetailsLoading && (
              <Button
                className="d-flex align-items-center p-0"
                variant="btn-link"
                onClick={() => setShowRenamePhotoModal(!showRenamePhotoModal)}
              >
                <div className={styles.subHeaderText}>{photoDetails?.display_name || photoDetails?.file_name}</div>
                <Edit3 size="16" color="#0071ce" />
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );

  return (
    <InteriorPage
      loading={loading || photoDetailsLoading}
      error={error}
      navTitle={pageTitle}
      pageTitle="Photo Library"
      navIcon={<Briefcase color="#fff" size={14} />}
      pageSubtitlePrimaryContent={pageSubtitleContent}
      pageSubtitleSecondaryContent={PhotoActionButtons}
    >
      {!!photoDetailsError && <Alert variant="danger">{photoDetailsError}</Alert>}
      {!!message && (
        <Alert variant="success" dismissible onClick={() => setMessage()}>
          {message}
        </Alert>
      )}
      <div className={styles.container}>
        <FsLightbox
          toggler={!!photoDetails && (!photoDetailsError || !photoDetailsLoading) && lightboxToggler}
          sources={[
            <div style={{ width: "100%", height: "100%" }}>
              <Button
                variant="link"
                className="d-flex align-items-center med p-2 mt-4 me-4 end-0 position-absolute bg-white"
                onClick={() => setLightboxToggler(!lightboxToggler)}
              >
                <ZoomOut size={16} color="#A7A7A7" />
              </Button>
              <img className="w-100 h-100" src={photoDetails?.abs_url} alt="" />
            </div>,
          ]}
        />
        <section className={styles.photoSectionWrapper}>
          <div className={styles.photo}>
            <img src={photoDetails?.abs_url} alt="" className={styles.photoBack} />
            <img src={photoDetails?.abs_url} alt="" className={styles.photoFront} />
            <Button
              variant="link"
              className="d-flex align-items-center med p-2 mt-2 me-2 end-0 position-absolute bg-white"
              onClick={() => setLightboxToggler(!lightboxToggler)}
            >
              <ZoomIn size={16} color="#A7A7A7" />
            </Button>
          </div>
          {!!updateDetailError && (
            <Alert variant="danger" dismissible>
              {updateDetailError}
            </Alert>
          )}
          <div className="d-flex justify-content-between w-100 ms-0 mt-3">
            {!!userCan("photo-library-edit") && (
              <HeaderButton
                icon={<PlusCircle size={16} color="#0071ce" />}
                ariaLabel="Add Field Note"
                text={"Add Field Note"}
                className="ms-0"
                onClick={() => setShowAddToAlbumModal(true)}
                disabled={!!updateDetailLoading || !!photoDetailsError}
              />
            )}
          </div>
          {!!photoDetails?.albums[0] && (
            <div className="mt-2 d-flex flex-row">
              <ContactPill
                data={`/project/${project?.id}/photo-library/album/${photoDetails?.albums[0]?.id}`}
                customText={photoDetails?.albums[0].name}
                key={photoDetails?.albums[0]?.id}
              />
            </div>
          )}
          {thumbLists?.length > 0 && (
            <div className="d-flex flex-column gap-3 mt-5">
              <div className={styles.thumbTitle}>More Photos</div>
              <div className={styles.thumbSection}>
                <Carousel
                  combinedList={thumbLists}
                  currentView={currentView}
                  setCurrentView={setCurrentView}
                  smallThumbs={true}
                  onSelectChange={onSelectPhoto}
                />
              </div>
            </div>
          )}
        </section>
        <section className={styles.commentSectionWrap}>
          <UserDetails
            user={photoDetails?.user}
            exifDateTime={photoDetails?.external_created_at}
            uploadDateTime={photoDetails?.created_at}
          />
          <AddNote
            photoDetailsError={photoDetailsError}
            notes={notes}
            setNotes={setNotes}
            date={new Date()}
            onHide={() => {}}
            photo={photoDetails?.id}
          />
          {photoDetails?.lat && photoDetails?.lng ? (
            <div className={styles.mapWrapper}>
              <MapSettingsCheck>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  zoom={12}
                  options={{
                    styles: mapStyles,
                    fullscreenControl: true,
                  }}
                  center={{ lat: parseFloat(photoDetails.lat), lng: parseFloat(photoDetails.lng) }}
                >
                  <Marker
                    position={{
                      lat: parseFloat(photoDetails.lat),
                      lng: parseFloat(photoDetails.lng),
                    }}
                  />
                  {project?.geofence.map((geo) => (
                    <Polygon path={parseLatLng(geo?.polygon)} options={polyOptions} editable={false} />
                  ))}
                </GoogleMap>
              </MapSettingsCheck>
            </div>
          ) : (
            <div className={styles.noMap}>
              <div>
                <h2 className={`${styles.mapTitle} text-center`}>No Location Data Available</h2>
              </div>
            </div>
          )}
          <>
            <h5 className={styles.fakeHeaderBtn}>
              <Link size={16} color="#0071ce" className="me-3" />
              <span>Attached to ({notes?.length ? notes.length : 0}) </span>
            </h5>
            {notes?.length ? (
              <Comments comments={notes} />
            ) : (
              <div className="mt-3">No Attached Items</div>
            )}
          </>
          <PhotoHistory photoDetails={photoDetails} />
        </section>
      </div>
      <AddPhotoToAlbumModal
        setPhotoDetails={setPhotoDetails}
        photoDetails={photoDetails}
        showModal={showAddToAlbumModal}
        setShowModal={setShowAddToAlbumModal}
      />
      <CreateAlbumModal showModal={showCreateAlbumModal} setShowModal={setShowCreateAlbumModal} />
      <UploadPhotoModal project={project} showModal={showUploadFileModal} setShowModal={setShowUploadFileModal} />
      <ConfirmModal
        showConfirmation={showConfirmDeleteModal}
        setShowConfirmation={setShowConfirmDeleteModal}
        confirmFunction={() => {
          handleDeletePhoto();
        }}
        loading={photoDeleteLoading}
        error={photoDeleteError}
        name={"Confirm Delete Photo"}
        message={"Are you sure you want to delete this photo?"}
      />
      <RenamePhotoModal
        showModal={showRenamePhotoModal}
        setShowModal={setShowRenamePhotoModal}
        photoDetails={photoDetails}
        setPhotoDetails={setPhotoDetails}
      />
    </InteriorPage>
  );
};

export default PhotoDetail;
