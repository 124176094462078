import React from "react";

const SubmittedBy = ({ data }) => {
  return (
    <div className="d-flex align-items-center">
      <span className="ms-3">
        {data?.foreman_first_name} {data?.foreman_last_name}
      </span>
    </div>
  );
};

export default SubmittedBy;
