import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { X } from "react-feather";
import PropTypes from "prop-types";
import { CompanyLocationContactCard } from "component-library";
import LocationSearch from "./Locations/LocationSearch";
import AddCompanyForm from "./Company/AddCompanyForm";
import AddLocation from "./ContactsAdmin/AddLocation";

const FormSelectLocation = ({
  hide,
  show,
  addLocation,
  label = "Location",
  setAddNewCompany,
  addingCompany,
  addingLocation,
  setAddingLocation,
  setAddingCompany
}) => {
  const [location, setLocation] = useState();
  const [selectedOption, setSelectedOption] = useState();

  const handleSelect = async () => {
    addLocation(location);
    setLocation();
    hide();
  };

  return (
    <Modal
      animation={false}
      show={show}
      onHide={() => {
        hide();
        setLocation();
      }}
      dialogClassName="modal-lg"
    >
      {!!addingLocation ? (
        <AddLocation setAddingLocation={setAddingLocation} company={selectedOption} setLocation={setLocation} />
      ) : (
        <>
          {!!addingCompany ? (
            <AddCompanyForm setCompany={setLocation} setAddingCompany={setAddingCompany} />
          ) : (
            <>
              <Modal.Header>
                <h4 className="mb-0">{`Select ${label}`}</h4>
                <Button
                  onClick={() => {
                    hide();
                    setLocation();
                  }}
                  variant="link"
                  className="me-n3 mt-n1"
                >
                  <X color="#979797" />
                </Button>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col">
                    <LocationSearch
                      setLocation={setLocation}
                      setAddNewLocation={setAddingLocation}
                      setAddNewCompany={setAddingCompany}
                      setSelectedOption={setSelectedOption}
                    />
                  </div>
                </div>
                <div className="col-4 p-0">
                  {location && !!Object.keys(location).length && <CompanyLocationContactCard location={location} />}
                </div>

                <Button onClick={handleSelect} className={`btn-tertiary mt-2`} disabled={!location}>
                  {`Select ${label}`}
                </Button>
              </Modal.Body>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

FormSelectLocation.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  addLocation: PropTypes.func.isRequired,
};

export default FormSelectLocation;
