import React from "react";
import styles from "../styles.module.scss";
import { ChevronDown, ChevronRight } from "react-feather";
import { BeatLoader } from "react-spinners";
import { Alert } from "react-bootstrap";

const Accordion = ({
  style,
  headerContent,
  bodyContent,
  request,
  loading,
  error,
  params,
  data,
  selected,
  isOpen,
  setIsOpen,
  selectMode,
}) => {
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    if (!data) {
      request(params);
    }
  };

  return (
    <div style={style}>
      <div
        className={`${styles.accordionRow} ${selectMode ? styles.rowHover : ""}`}
        style={{
          ...(selected && { backgroundColor: "#E5F0FA" }),
          ...(!isOpen && { borderBottom: "1px solid #D3D3D3" }),
        }}
      >
        {headerContent}
        <button onClick={toggleAccordion} className="btn btn-link">
          {isOpen ? <ChevronDown color="#C4C4C4" size={16} /> : <ChevronRight color="#C4C4C4" size={16} />}
        </button>
      </div>
      <div>
        {isOpen && !loading && bodyContent}
        {isOpen && !!loading && (
          <div className="d-flex justify-content-center mt-5">
            <BeatLoader color="#0071ce" />
          </div>
        )}
        {/* will need to be switched to AlertMessage with react query */}
        {isOpen && error && <Alert variant="danger">{error}</Alert>}
      </div>
    </div>
  );
};

export default Accordion;
