/**
 * gets minutes from the field entry for use in the minutes input, splitting by ":"
 * @param  {String} fieldEntry number of minutes from the field entry as a string, containing a ":" separator
 * @return {Number} updated number of minutes, after hours are converted to minutes
 */

const intuitMinutesFromFieldEntry = (fieldEntry) => {
  let updated = fieldEntry;
  if (fieldEntry.indexOf(":") > -1) {
    let sections = fieldEntry.split(":");
    let hrs = !!sections[0] ? parseInt(sections[0], 10) * 60 : 0;
    let mins = !!sections[1] ? parseInt(sections[1], 10) : 0;
    updated = hrs + mins;
  }
  return updated;
};

export default intuitMinutesFromFieldEntry;