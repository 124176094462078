import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ChevronDown, ChevronRight } from "react-feather";
import styles from "../../TaskManagment/styles.module.scss";

const Task = ({ task, indentation = 30, bgColor, toggleSelectedBidItem, selectedTaskBidItems }) => {
  const [open, setOpen] = useState();
  const [checked, setChecked] = useState();

  useEffect(() => {
    setChecked(
      !!selectedTaskBidItems?.length && selectedTaskBidItems.find((t) => t === task.id) ? 1 : 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTaskBidItems]);

  return (
    <>
      <tr className={`${bgColor === "white" ? styles.whiteBg : bgColor === "light" ? styles.lightBg : styles.darkBg}`}>
        <td>
          <div className="form-group form-check mx-auto my-0 d-flex justify-content-center">
            <input
              name={`${task.name}-${task.id}`}
              id={`${task.name}-${task.id}`}
              value={!!selectedTaskBidItems?.length && selectedTaskBidItems.find((t) => t === task.id) ? 1 : 0}
              checked={!!selectedTaskBidItems?.length && selectedTaskBidItems.find((t) => t === task.id) ? 1 : 0}
              type="checkbox"
              onChange={() => toggleSelectedBidItem(task.id, task.parent_id)}
              className={`form-check-input me-0 d-flex align-items-center justify-content-center`}
            />
            <label
              className={`${!checked ? styles.checklistCheckbox : styles.unSelected} form-check-label`}
              htmlFor={`${task.name}-${task.id}`}
            ></label>
          </div>
        </td>
        <td></td>
        <td style={{ paddingLeft: indentation }}>
          <div className="d-flex align-items-center w-75">
            {!!task.tasks && !!task.tasks.length ? (
              <div>
                {!!open ? (
                  <ChevronDown color={"#7b7b7b"} size={15} onClick={() => setOpen(!open)} className="me-1" />
                ) : (
                  <ChevronRight color={"#7b7b7b"} size={15} onClick={() => setOpen(!open)} className="me-1" />
                )}
              </div>
            ) : (
              <span className="me-3">&bull;</span>
            )}
            <span>{task.name}</span>
          </div>
        </td>
        <td className="text-end px-2">{task.cost_code || "-"}</td>
      </tr>
    </>
  );
};

Task.propTypes = {
  task: PropTypes.object,
  indentation: PropTypes.number,
  bgColor: PropTypes.string,
  toggleSelectedBidItem: PropTypes.func,
  selectedTaskBidItems: PropTypes.array,
};

export default Task;
