import React from "react";
import styles from "./styles.module.scss";
import Milestone from "./Milestone";
import { BeatLoader } from "react-spinners";
import { AlertPopup } from "component-library";
import useMilestones from "./useMilestones";

const Milestones = () => {
  const { milestonesError, milestonesLoading, milestones } = useMilestones();

  return (
    <div>
      <h2 className={styles.statsTitle}>
        Upcoming Milestones
        {!!milestonesError ? (
          <span className="ms-2">
            <AlertPopup error={milestonesError} />
          </span>
        ) : (
          <>
            {!!milestonesLoading ? (
              <span className="ms-2">
                <BeatLoader size={10} color={"#0071ce"} />
              </span>
            ) : (
              <span className="ms-2">({milestones?.length || 0})</span>
            )}
          </>
        )}
      </h2>
      <div className={styles.milestonesRow}>
        {!!milestones?.length && !milestonesLoading ? (
          milestones.map((item, index) => {
            return <Milestone key={index} item={item} />;
          })
        ) : (
          <>{!milestonesLoading && !milestonesError && <>No Upcoming milestones</>}</>
        )}
        {!!milestonesLoading && (
          <>
            <Milestone />
            <Milestone />
          </>
        )}
      </div>
    </div>
  );
};

export default Milestones;
