import { useState, useEffect } from "react";
import { useApi, photoLibraryApi, HeaderButton, useAuth, formatApiDate } from "component-library";
import { Trash2, FolderPlus, Upload, Type, PlusCircle, Link } from "react-feather";
import useLibraryView from "../PhotoLibrary/PhotoLibrary/useLibraryView";

const useAlbumDetail = ({ albumId, project }) => {
  const { userCan, user } = useAuth();
  const [album, setAlbum] = useState();
  const [albumPhotosList, setAlbumPhotosList] = useState([]);
  const [pinnedPhotos, setPinnedPhotos] = useState();
  const [showDeleteAlbumModal, setShowDeleteAlbumModal] = useState(false);
  const [showRenameAlbumModal, setShowRenameAlbumModal] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [showMultiSelect, setShowMultiSelect] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [q, setQ] = useState("");
  const [onlyMyPhotos, setOnlyMyPhotos] = useState(false);
  const [activeView, setActiveView] = useState("grid-view");

  const {
    request: albumDetailsRequest,
    data: albumDetailsData,
    loading: albumDetailsLoading,
    error: albumDetailsError,
  } = useApi(photoLibraryApi.getAlbumDetails);
  const {
    request: albumPhotosRequest,
    loading: albumPhotosLoading,
    error: albumPhotosError,
  } = useApi(photoLibraryApi.getAlbumPhotos);
  const {
    request: albumPhotosWithParamsRequest,
    loading: albumPhotosWithParamsLoading,
    error: albumPhotosWithParamsError,
    data: albumPhotosWithParamsData,
  } = useApi(photoLibraryApi.getAlbumPhotosWithParams);

  const refreshLists = () => {
    let requestParams;
    if (project?.id) {
      if (!!dateRange[1]) {
        requestParams = {
          albumId: albumId,
          projectId: project.id,
          from_date: formatApiDate(dateRange[0]),
          to_date: formatApiDate(dateRange[1]),
        };
      }

      if (!dateRange[0]) {
        requestParams = {
          albumId: albumId,
          projectId: project.id,
        };
      }

      if (onlyMyPhotos) {
        requestParams = {
          ...requestParams,
          user_id: user.id,
        };
      }

      requestParams = {
        ...requestParams,
        q: q || "",
      };

      if (!dateRange[0] || !!dateRange[1]) {
        albumPhotosWithParamsRequest(requestParams);
      }
    }
  };

  const {
    selectedItems,
    setSelectedItems,
    selectMode,
    setSelectMode,
    SelectActionButtons,
    ItemSelection,
    showPinUnpinModal,
    setShowPinUnpinModal,
    pinUnpinItems,
    photoBatchPinUnpinLoading,
    albumBatchPinUnpinLoading,
    pinUnpinError,
    setPinUnpinError,
    isPinAction,
    showDeleteModal,
    setShowDeleteModal,
    deleteItems,
    deleteError,
    photoBatchDeleteLoading,
  } = useLibraryView({ refreshLists, isAlbumView: true });

  const handleSearch = (value) => {
    setQ(value);
  };

  useEffect(() => {
    if (albumId && q) {
      albumPhotosWithParamsRequest({ album_id: albumId, q: q });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [album]);

  useEffect(() => {
    refreshLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, q, onlyMyPhotos]);

  useEffect(() => {
    setAlbumPhotosList(albumPhotosWithParamsData?.photos?.data);
  }, [albumPhotosWithParamsData]);

  const AlbumActionButtons = () => (
    <div className="d-flex align-items-center">
      <HeaderButton
        icon={<FolderPlus size={16} color="#0071ce" />}
        ariaLabel="Select Photos"
        text="Select Photos"
        onClick={() => setSelectMode(!selectMode)}
        disabled={!userCan("photo-library-edit") || !albumDetailsData?.album?.photos?.length}
        className="me-5"
      />
      {/* <HeaderButton
        icon={<Link size={16} color="#0071ce" />}
        ariaLabel="Attach"
        text="Attach"
        onClick={() => console.log("Attach")}
        disabled={!userCan("photo-library-edit") || !project}
        className="me-5"
      /> */}
      <HeaderButton
        icon={<Trash2 size={16} color="#0071ce" />}
        ariaLabel="Delete photo album"
        text="Delete Album"
        onClick={() => setShowDeleteAlbumModal(true)}
        disabled={!userCan("photo-library-edit") || !project}
        className="me-5"
      />
      <HeaderButton
        icon={<Type size={16} color="#0071ce" />}
        ariaLabel="Rename photo album"
        text="Rename Album"
        onClick={() => setShowRenameAlbumModal(true)}
        disabled={!userCan("photo-library-edit") || !project}
        className="me-3"
      />
      <HeaderButton
        icon={<PlusCircle size={16} color="#0071ce" />}
        ariaLabel="Add Photos to album"
        text="Add Photo(s)"
        onClick={() => setShowMultiSelect(true)}
        disabled={!userCan("photo-library-edit") || !project}
      />
      <HeaderButton
        icon={<Upload size={16} color="#0071ce" />}
        ariaLabel="Upload photo to project"
        text="Upload Photo(s)"
        onClick={() => setShowUploadFileModal(true)}
        disabled={!userCan("photo-library-edit") || !project}
      />
    </div>
  );

  useEffect(() => {
    if (albumId) {
      albumDetailsRequest(albumId);
      albumPhotosRequest(albumId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [albumId]);

  useEffect(() => {
    if (!!albumDetailsData?.album) {
      setAlbum({
        name: albumDetailsData?.album?.name,
        id: albumId,
      });
      setAlbumPhotosList(albumDetailsData?.album?.photos);
    }
  }, [albumId, albumDetailsData]);

  useEffect(() => {
    if (!!albumPhotosList?.length) {
      setPinnedPhotos(albumPhotosList.filter((photo) => photo.pinned === 1));
    } else {
      setPinnedPhotos();
    }
  }, [albumPhotosList]);

  return {
    album,
    AlbumActionButtons,
    albumPhotosList,
    dateRange,
    setDateRange,
    setAlbumPhotosList,
    setAlbum,
    pinnedPhotos,
    albumDetailsLoading,
    albumDetailsError,
    refreshLists,
    handleSearch,
    setShowRenameAlbumModal,
    showUploadFileModal,
    setShowUploadFileModal,
    showDeleteAlbumModal,
    showRenameAlbumModal,
    setShowDeleteAlbumModal,
    showMultiSelect,
    setShowMultiSelect,
    albumPhotosLoading,
    albumPhotosError,
    albumPhotosWithParamsError,
    albumPhotosWithParamsLoading,
    onlyMyPhotos,
    setOnlyMyPhotos,
    activeView,
    setActiveView,
    q,
    setQ,
    selectedItems,
    setSelectedItems,
    selectMode,
    SelectActionButtons,
    ItemSelection,
    showPinUnpinModal,
    setShowPinUnpinModal,
    pinUnpinItems,
    photoBatchPinUnpinLoading,
    albumBatchPinUnpinLoading,
    pinUnpinError,
    setPinUnpinError,
    isPinAction,
    showDeleteModal,
    setShowDeleteModal,
    deleteItems,
    deleteError,
    photoBatchDeleteLoading,
  };
};

export default useAlbumDetail;
