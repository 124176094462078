import React, { useState } from "react";
import PropTypes from "prop-types";
import { Badge, Button } from "react-bootstrap";
import AssignPhase from "./AssignPhase";
import useEstimateConversion from "../../useEstimateConversion";

const PhaseBadges = ({ bidItem, updateItem, open }) => {
  const [assignPhase, setAssignPhase] = useState(false);

  const { editTasks, viewPhases, phases, canEdit } = useEstimateConversion();

  return (
    <>
      <AssignPhase
        setAssignPhase={setAssignPhase}
        bidItem={bidItem}
        updateItem={updateItem}
        projectPhases={phases}
        assignPhase={assignPhase}
      />
      {!!viewPhases && !editTasks && (
        <Button
          variant="link"
          className="flex-wrap d-flex align-center text-decoration-none"
          onClick={() => setAssignPhase(true)}
          disabled={!canEdit}
        >
          <div className="d-flex align-items-center me-2">
            <Badge pill className="px-2 text-uppercase text-white nine pb-1" bg={!!open ? "darkblue3" : "gray5"}>
              +Phase
            </Badge>
          </div>
        </Button>
      )}
    </>
  );
};

PhaseBadges.propTypes = {
  bidItem: PropTypes.object,
  updateItem: PropTypes.func,
  projectPhases: PropTypes.array,
};

export default PhaseBadges;
