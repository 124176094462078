import React from "react";
import CashflowChart from "./CashflowChart";
import CashflowGrid from "./CashflowGrid";
import DashboardPage from "./DashboardPage";
import PageHeader from "./PageHeader";
import useDashboard from "./useDashboard";

const CashflowPage = () => {
  const { jobs, aggregateStats } = useDashboard();
  return (
    <DashboardPage>
      <PageHeader title="Project Snapshots" />
      {jobs && !!jobs.length && aggregateStats && (
        <>
          <div className="pb-3 mb-3 row">
            <div className="col">
              <CashflowChart
                cashflow={aggregateStats.cashflow}
                title="Overall Snapshot"
              />
            </div>
          </div>
          <div className="row pb-3 mb-3">
            <CashflowGrid cashflow={jobs} jobs={jobs} />
          </div>
        </>
      )}
    </DashboardPage>
  );
};

export default CashflowPage;
