import React, { useState } from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import { X } from "react-feather";
import CustomScroll from "react-custom-scroll";
import styles from "./styles.module.scss";
import { Loader, ConfirmModal } from "component-library";
import PhotosModal from "../../components/PhotosModal";
import { DateRangeSelector } from "component-library";
import ProjectNote from "../../components/ProjectNote";
import PropTypes from "prop-types";
import useProjectNotes from "../../_projects/project/hooks/useProjectNotes";
import useResourceManagement from "../context/useResourceManagement";
import Pagination from "../../components/Pagination";

const ProjectNotesModal = ({ showModal, onHide, project, projectLoading }) => {
  const { dateRange, setDateRange, getStoredDateRange } = useResourceManagement();
  const {
    projectNotes,
    projectNotesLoading,
    projectNotesError,
    confirmationModalOpen,
    deleteNote,
    onConfirmDeleteNote,
    onCancleDeleteNote,
    deleteError,
    deleteLoading,
    totalResults,
    currentPage,
    itemsPerPage,
    setCurrentPage,
  } = useProjectNotes({ projectId: project?.id, dateRange, setDateRange, getStoredDateRange });

  const [photoModalOpen, setPhotoModalOpen] = useState(false);
  const [photos, setPhotos] = useState();

  return (
    <>
      {confirmationModalOpen ? (
        <ConfirmModal
          showConfirmation={onCancleDeleteNote}
          setShowConfirmation={onCancleDeleteNote}
          confirmFunction={onConfirmDeleteNote}
          loading={deleteLoading}
          error={deleteError}
          name={"Delete Project Note"}
          message={"Are you sure you want to delete this note?"}
        />
      ) : photoModalOpen ? (
        <>
          <PhotosModal photos={photos} open={photoModalOpen} setOpen={setPhotoModalOpen} />
        </>
      ) : (
        <Modal
          show={!!showModal}
          centered
          onHide={() => onHide}
          animation={false}
          className="text-gray2 p-0 fullWidthContent"
          size="lg"
        >
          <div className="position-relative">
            <Loader size={16} loading={!!projectNotesLoading || !!projectLoading} />

            <Modal.Header>
              <div>
                <h3 className="mb-0 xxl">Project Notes</h3>
                <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} maxDate={new Date()} />
              </div>
              <Button className="p-0" variant="link" onClick={onHide} aria-label="Close Project Notes Modal">
                <X size={25} />
              </Button>
            </Modal.Header>
            <Modal.Body className={`${styles.modalBody}`}>
              {!!projectNotesError && <Alert variant="danger">{projectNotesError}</Alert>}
              <CustomScroll heightRelativeToParent="100%">
                <section className={styles.body}>
                  {!!projectNotes?.length ? (
                    <>
                      {projectNotes.map((note) => {
                        return (
                          <ProjectNote
                            project={project}
                            key={note.id}
                            note={note}
                            setPhotos={setPhotos}
                            photoModalOpen={photoModalOpen}
                            setPhotoModalOpen={setPhotoModalOpen}
                            deleteNote={deleteNote}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <div>No project notes found.</div>
                  )}
                </section>
              </CustomScroll>
            </Modal.Body>
            <Modal.Footer className="d-flex align-items-center justify-content-center">
              <Pagination
                items={projectNotes}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                total={totalResults}
              />
            </Modal.Footer>
          </div>
        </Modal>
      )}
    </>
  );
};

ProjectNotesModal.propTypes = {
  projectNotes: PropTypes.array,
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
  projectNotesLoading: PropTypes.bool,
  projectNotesError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  deleteNote: PropTypes.func,
  confirmationModalOpen: PropTypes.bool,
  onConfirmDeleteNote: PropTypes.func,
  onCancleDeleteNote: PropTypes.func,
  deleteError: PropTypes.bool,
  deleteLoading: PropTypes.bool,
};

export default ProjectNotesModal;
