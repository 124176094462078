import React, { useState } from "react";
import styles from "../styles/Login.module.scss";
import FormAddContact from "../components/Contacts/FormAddContact";
import { Button } from "react-bootstrap";
import ContactControl from "../components/Contacts/ContactControl";

const addStyles = {
  borderStyle: "dashed",
  width: "7.2rem",
  minHeight: "7.2rem",
  padding: "0.9rem",
  fontSize: "1.3rem",
};

const useContactsListField = ({ data, setFields, schema, errors, roles }) => {
  const [modalOpen, setModalOpen] = useState({});

  const renderContactsListField = ({
    contacts = [],
    name = [],
    contactsName,
    label,
    subLabel,
    roles,
    modifyRemoveFunc,
  }) => {

    const removeContact = (contact) => {
      if (!!roles) {
        setFields({
          ...data,
          [name]: data[name].filter((c) => c.contact.id !== contact.contact.id),
        });
      } else if (contactsName === "project_owners") {
        setFields({
          ...data,
          [contactsName]: data[contactsName].filter((c) => c.id !== contact.id),
          [name]: data[name].filter((cid) => cid !== contact.id),
          project_owners: data["project_owners"].filter(
            (c) => c.id !== contact.id
          ),
          project_owner_ids: data["project_owner_ids"].filter((c) =>
            modifyRemoveFunc ? c !== contact?.id : c !== contact?.contact?.id
          ),
        });
      } else {
        setFields({
          ...data,
          [contactsName]: data[contactsName].filter((c) => c.id !== contact.id),
          [name]: data[name].filter((cid) => cid !== contact.id),
        });
      }
    };

    const handleAddContact = (contact, role) => {
      if (roles) {
        setFields({
          ...data,
          [name]: [...data[name], { role, contact }],
        });
      } else {
        setFields({
          ...data,
          [contactsName]: [...data[contactsName], contact],
          [name]: [...data[name], contact.id],
        });
      }
    };
    return (
      <>
        <label htmlFor={name} className="mt-2 med">
          {label}
        </label>
        <div className=" w-100 flex-1 d-flex flex-wrap">
          {contacts.map((contact, i) => (
            <div
              className={`${styles.contact} pb-2 d-flex`}
              key={roles ? !!contact.contact && contact.contact.id : contact.id}
            >
              <ContactControl
                contact={contact.contact || contact}
                removeContact={() => removeContact(contact)}
                title={roles ? contact.role.name : ""}
                role={contact.role}
              />
            </div>
          ))}

          <div
            className={`pb-2 form-group ${errors[name] ? "error" : ""} ${
              schema[name] && schema[name]._flags && schema[name]._flags.presence === "required" ? styles.required : ""
            }`}
          >
            <div className="light-text sm-med subLabel">{subLabel}</div>
            <div className="d-flex h-100 circle-file-dropzone justify-content-center">
              <Button
                variant="outline-secondary"
                className="dropzone rounded-circle justify-content-center align-self-center"
                style={addStyles}
                onClick={() => {
                  setModalOpen({ ...modalOpen, [name]: true });
                }}
              >
                Add New Contact +
              </Button>
            </div>

            {!!errors[name] && <span className="help-block is-error">{errors[name]}</span>}
          </div>
        </div>

        {modalOpen && (
          <FormAddContact
            contacts={data.project_contacts}
            show={!!modalOpen[name]}
            hide={() => setModalOpen({ ...modalOpen, [name]: false })}
            addContact={(contact, role) => {
              handleAddContact(contact, role);
            }}
            roles={roles}
            label={label}
          />
        )}
      </>
    );
  };

  return { renderContactsListField };
};

export default useContactsListField;
