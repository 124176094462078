import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { ReactComponent as AddLocation } from "../../assets/addLocation.svg";

const LocationOptions = ({ locations, handleSelect, selected, setAddingLocation, setSelectedOption }) => {
  return (
    <>
      {locations.map((option, i) => (
        <tr
          onClick={() => handleSelect(option)}
          key={option.location_id}
          className={`${selected === i ? "option-active" : ""} dropdown-option`}
        >
          <td className={`${styles.company} font-weight-bold text-gray2`}>{option.company_name}</td>
          <td className={`${styles.name} sm-med text-gray2`}>{option.location_name}</td>
          <td className={`${styles.address} sm-med font-italic text-gray3`}>
            {`${option.address1},
              ${option.city},
              ${option.state}
              ${option.zip}`}
          </td>
          <td className={`${styles.addLocation} text-center`}>
            <Button
              onClick={() => {
                setAddingLocation(true);
                setSelectedOption({
                  name: option.company_name,
                  id: option.company_id,
                });
              }}
              className="p-0"
              variant="link"
            >
              <AddLocation />
            </Button>
          </td>
        </tr>
      ))}
    </>
  );
};

LocationOptions.propTypes = {
  locations: PropTypes.array,
  handleSelect: PropTypes.func.isRequired,
  selected: PropTypes.number,
};

export default LocationOptions;
