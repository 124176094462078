import React from "react";
import styles from "./TakeoffDetail.module.scss";
import { Table } from "react-bootstrap";
import { currency } from "component-library";

const QuotesTable = ({quotes}) => {
  return (
    <div className={styles.quoteTableWrapper}>
      <Table>
        <thead>
          <tr className="default">
            <th className="border-0 med text-gray2 px-20 pt-15 pb-0">Quotes</th>
          </tr>
        </thead>
        <tbody>
          {quotes.map((quote) => (
            <tr style={{ backgroundColor: !!quote.status === "Selected" && "#f1f0f0" }} key={quote.id}>
              <td className="border-0">
                {quote.status === "Selected" && <div className="text-gray2 sm">Selected Quote</div>}
                <div className="text-gray2">{quote.contact.company.name}</div>
              </td>
              <td className="border-0" style={{ width: "65%" }}>
                {/* <a className="sm" href="#">View Quote</a> */}
              </td>
              <td className="text-gray2 text-end border-0">{currency(quote.total)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default QuotesTable;
