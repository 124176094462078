import React from "react";
import { Badge } from "react-bootstrap";
import styles from "./styles.module.scss";

const PWPill = () => {
  return (
    <Badge pill className={`${styles.thPill} nine pill text-light semibold`} bg={"redOrange"}>
      pw
    </Badge>
  );
};

export default PWPill;
