import React from "react";
import useProvideSubmittals from "./useProvideSubmittals"
import submittalsContext from "./submittalsContext";

function ProvideSubmittals({ children }) {
  const submittals = useProvideSubmittals();
  return <submittalsContext.Provider value={submittals}>{children}</submittalsContext.Provider>;
}

export default ProvideSubmittals;
