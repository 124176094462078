import React from "react";
import styles from "./styles.module.scss";
import DateRow from "./DateRow";

const ReportDates = ({ project, report, updateDate, weather }) => {
  return (
    <>
      <h1 className={styles.reportTitle}>Project Report</h1>
      {!!report?.rows?.length &&
        report.rows.map((day) => (
          <DateRow project={project} report={report} key={day.id} day={day} updateDate={updateDate} weather={weather} />
        ))}
    </>
  );
};

export default ReportDates;
