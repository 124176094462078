import React from "react";
import { AgGridTable, useAuth } from "component-library";
import subtasksConfig from "./subtasksConfig";
import styles from "./styles.module.scss";
import useProject from "../../../../hooks/useProject";
import gridRowHeights from "../../../../../utils/gridRowHeights";
import useSubtasks from "./useSubTasks";
import { BeatLoader } from "react-spinners";
import { Alert } from "react-bootstrap";

const SubTasksTable = ({ tasks, taskLoading }) => {
  const { subtasks, subTasksLoading, subTasksError } = useSubtasks();
  const { project } = useProject();
  const { user } = useAuth();
  const handleCellClick = ({ data, column }) => {
    if (["name"].includes(column?.colId)) {
      window.open(`/project/${project?.id}/reporting/task/${data?.id}`, "_blank");
    }
  };

  return (
    <section className={`${styles.tableWrapper} detail-subtasks`}>
      <h2 className={styles.tableTitle}>Sub Tasks</h2>
      {!!subTasksLoading && <BeatLoader color="#0071CE" size={16} />}
      {!!subTasksError && <Alert variant="danger">{subTasksError}</Alert>}
      <AgGridTable
        gridData={subtasks}
        gridConfig={subtasksConfig}
        loading={taskLoading}
        rowHeight={gridRowHeights.medium}
        id={`${user.id}_subtask_listing`}
        presetStyle="standardMasterDetail"
        suppressSizeToFit
        handleCellClick={handleCellClick}
        fallbackMessage="No subtasks found."
      />
    </section>
  );
};

export default SubTasksTable;
