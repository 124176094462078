import ExpandToggle from "../../sharedColumns/columnRenders/ExpandToggle";
import useResourceManagement from "../../../_resourceManagement/context/useResourceManagement";

const ExpandAll = () => {
  const { flExpanded, setFlExpanded } = useResourceManagement();

  return <ExpandToggle expandState={flExpanded} setExpandState={setFlExpanded} />;
};

export default ExpandAll;
