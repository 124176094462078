import { useState, useEffect } from "react";
import { useApi, photoLibraryApi, HeaderButton, useAuth } from "component-library";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Trash2, Edit3, Share2, Download, Move, FolderPlus } from "react-feather";
import styles from "./styles.module.scss";
import useProject from "../../../hooks/useProject";
import usePhotoLibrary from "../context/usePhotoLibrary";
import { ReactComponent as Unpinned } from "../../../../assets/photoUnpinned.svg";
import { ReactComponent as Pinned } from "../../../../assets/photoPinned.svg";

const usePhotoDetail = () => {
  const { userCan } = useAuth();
  const { project } = useProject();
  const [photoDetails, setPhotoDetails] = useState();
  const [showAddToAlbumModal, setShowAddToAlbumModal] = useState(false);
  const [showCreateAlbumModal, setShowCreateAlbumModal] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [notes, setNotes] = useState([]);
  const [pinned, setPinned] = useState(0);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showRenamePhotoModal, setShowRenamePhotoModal] = useState(false);
  const history = useNavigate();
  const { photoId } = useParams();
  const { message, setMessage, refreshLists, photosList } = usePhotoLibrary();
  const [thumbLists, setThumbLists] = useState([]);

  const {
    request: photoDetailsRequest,
    data: photoDetailsData,
    loading: photoDetailsLoading,
    error: photoDetailsError,
  } = useApi(photoLibraryApi.getPhotoDetails);

  const {
    request: photoDeleteRequest,
    data: photoDeleteData,
    loading: photoDeleteLoading,
    error: photoDeleteError,
  } = useApi(photoLibraryApi.deletePhoto);

  const {
    request: updateDetailRequest,
    data: updateDetailData,
    loading: updateDetailLoading,
    error: updateDetailError,
  } = useApi(photoLibraryApi.updatePhotoDetails);

  const disableButtons = !project || updateDetailLoading || !!photoDetailsError || !photoDetailsData;

  const PhotoActionButtons = () => (
    <div className="d-flex align-items-center gap-3">
      {!!userCan("photo-library-edit") && (
        <>
          <HeaderButton
            icon={
              photoDetails?.albums[0] ? <Move size={16} color="#0071ce" /> : <FolderPlus size={16} color="#0071ce" />
            }
            ariaLabel="Add or move to album"
            text={photoDetails?.albums[0] ? "Move to Album" : "Add to Album"}
            onClick={() => setShowAddToAlbumModal(true)}
            disabled={disableButtons}
          />
          {!!disableButtons ? (
            <HeaderButton
              icon={<Download size={16} color="#0071ce" />}
              ariaLabel="Download photo"
              text="Download"
              disabled={disableButtons}
            />
          ) : (
            <Link to={photoDetails?.url} download={photoDetails?.file_name} target="_blank">
              <HeaderButton
                icon={<Download size={16} color="#0071ce" />}
                ariaLabel="Download photo"
                text="Download"
                disabled={disableButtons}
              />
            </Link>
          )}
          <HeaderButton
            icon={<Edit3 size={16} color="#0071ce" />}
            ariaLabel="Rename photo"
            text="Edit"
            onClick={() => setShowRenamePhotoModal(true)}
            disabled={disableButtons}
          />
          <HeaderButton
            icon={!!pinned ? <Pinned className={styles.pinIcon} /> : <Unpinned className={styles.pinIcon} />}
            ariaLabel={!!pinned ? "Unpin Photo" : "Pin Photo"}
            text={!!pinned ? "Unpin Photo" : "Pin Photo"}
            onClick={() => setPinned(!!pinned ? 0 : 1)}
            disabled={disableButtons}
          />
        </>
      )}
      {!!userCan("photo-library-share") && (
        <HeaderButton
          icon={<Share2 size={16} color="#0071ce" />}
          ariaLabel="Share photo"
          text="Share Photo"
          disabled={disableButtons}
        />
      )}
      {!!userCan("photo-library-delete") && (
        <HeaderButton
          icon={<Trash2 size={16} color="#0071ce" />}
          ariaLabel="Delete the photo"
          text="Delete Photo"
          onClick={() => setShowConfirmDeleteModal(true)}
          disabled={disableButtons}
        />
      )}
    </div>
  );

  const handleDeletePhoto = () => {
    photoDeleteRequest(photoId);
  };

  useEffect(() => {
    setMessage("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (photoId) {
      photoDetailsRequest(photoId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoId]);

  useEffect(() => {
    if (photoDetailsData?.photo) {
      setPhotoDetails(photoDetailsData.photo);
      setPinned(photoDetailsData.photo.pinned);
      setNotes(photoDetailsData.photo.notes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoDetailsData]);

  useEffect(() => {
    if (!!photoDeleteData?.success) {
      refreshLists();
      setMessage("Photo deleted.");
      history(`/project/${project?.id}/photo-library`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoDeleteData]);

  useEffect(() => {
    if (!!photoDetails && pinned !== photoDetails.pinned) {
      updateDetailRequest({ photo_id: photoId, pinned: pinned });
      setPhotoDetails({ ...photoDetails, pinned: pinned });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinned]);

  useEffect(() => {
    if (!!updateDetailData) {
      refreshLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDetailData]);

  useEffect(() => {
    if (photoDetailsData?.photo?.id && photosList?.length) {
      const albums = photoDetailsData.photo.albums;

      if (albums.length) {
        const albumId = albums[0].id;
        const albumPhotos = photosList.filter((photo) =>
          photo.albums?.length ? photo.albums.findIndex((album) => album.id === albumId) !== -1 : false
        );
        setThumbLists(albumPhotos);
      } else {
        setThumbLists(photosList);
      }
    } else {
      setThumbLists([]);
    }
  }, [photoDetailsData, photosList]);

  return {
    pinned,
    setPinned,
    photoDetailsLoading,
    photoDetailsError,
    photoDetails,
    showAddToAlbumModal,
    setShowAddToAlbumModal,
    setPhotoDetails,
    showCreateAlbumModal,
    setShowCreateAlbumModal,
    PhotoActionButtons,
    showUploadFileModal,
    setShowUploadFileModal,
    notes,
    setNotes,
    updateDetailLoading,
    updateDetailError,
    showConfirmDeleteModal,
    setShowConfirmDeleteModal,
    showRenamePhotoModal,
    setShowRenamePhotoModal,
    handleDeletePhoto,
    photoDeleteLoading,
    photoDeleteError,
    message,
    setMessage,
    thumbLists,
  };
};

export default usePhotoDetail;
