const delayReasons = [
  {
    value: "site_conditions",
    label: "Site Conditions",
  },
  {
    value: "weather",
    label: "Weather",
  },
  {
    value: "material",
    label: "Material",
  },
  {
    value: "scope_change",
    label: "Scope Change",
  },
  {
    value: "util_conflict",
    label: "Utility Conflict",
  },
  {
    value: "plan_change",
    label: "Plan Change",
  },
  {
    value: "waiting_for_direction",
    label: "Waiting for Direction",
  },
  {
    value: "drawings",
    label: "Drawings",
  },
  {
    value: "outside_contractors",
    label: "Outside Contractors",
  },
];

export default delayReasons;
