import React, { useState, useEffect } from "react";
import BidSidebar from "../../_bidManagement/BidSidebar";
import BidTasks from "../../_bidManagement/BidTasks";
import BidContacts from "../../_bidManagement/BidContacts";
import ProgressTracker from "../ProgressTracker";
import { useParams } from "react-router-dom";
import {
  useApi,
  projectApi,
  bidApi,
  BidSectionMilestones,
  BidSectionBasics,
  BidSectionProjectInfo,
  BidSectionToggler,
  BidSectionAddenda,
  BidSectionDocuments,
  HeaderButton,
  useAuth,
} from "component-library";
import { Tag, ArrowLeftCircle } from "react-feather";
import { Collapse } from "react-bootstrap";
import InteriorPage from "./InteriorPage";
import AddAddendaModal from "../../_bidManagement/AddAddendaModal";
import BidHeaderLinks from "../../_bidManagement/BidHeaderLinks";
import useBid from "../../_bidManagement/hooks/useBid";

const BidPage = () => {
  const [openContacts, setContactsOpen] = useState(true);
  const [addingAddenda, setAddingAddenda] = useState(false);
  const [todos, setTodos] = useState([]);
  const [completedTodos, setCompletedTodos] = useState([]);
  const [bidName, setBidName] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [contractOwner, setContractOwner] = useState({});
  const [projectContacts, setProjectContacts] = useState([]);
  const [projectOwners, setProjectOwners] = useState([]);

  const { id } = useParams();
  const { bid, setBid, archived } = useBid();
  const { config, userCan } = useAuth();
  const { request, loading, data } = useApi(projectApi.getProject);
  const { request: completeBid } = useApi(bidApi.completeBid);

  useEffect(() => {
    request(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data && data.project) {
      setTodos(data.project.bid_todos);
      setBid(data.project);
      setBidName(data.project.name);
      setSubtitle(!!data.project.bid_type ? data.project.bid_type.name : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (bid) {
      setTodos(bid.bid_todos);
      setContractOwner(bid.contract_owner);
      setProjectContacts(bid.project_contacts);
      setProjectOwners(bid.project_owners);
    }
  }, [bid]);

  useEffect(() => {
    setCompletedTodos(todos.filter((t) => t.complete === 1));
  }, [todos]);

  const BidHeaderSecondary = () =>
    !!archived || bid?.status === "precon" || bid?.status === "project" ? (
      <>
        <HeaderButton linkTo="/bids/archived" text="View List" icon={<ArrowLeftCircle size={16} color="#0071ce" />} />
      </>
    ) : (
      <BidHeaderLinks showEditButton bid={bid} setAddingAddenda={setAddingAddenda} />
    );

  return (
    <InteriorPage
      loading={loading}
      navTitle="Bids"
      navIcon={<Tag color="#fff" size={14} />}
      fillScreen
      pageTitle={bidName}
      subtitle={subtitle}
      pageTitleSecondaryContent={BidHeaderSecondary}
    >
      {!!bid && (
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="large-form-section py-3">
                <div className="tracker-wrap">
                  {todos.length > 0 && <ProgressTracker completed={completedTodos.length} total={todos.length} />}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-9">
              <div className="large-form-section">
                <BidSectionBasics bid={bid} />
              </div>

              <BidSectionAddenda bid={bid} userCan={userCan} isPortal={false} />

              <AddAddendaModal
                addingAddenda={addingAddenda}
                setAddingAddenda={setAddingAddenda}
                bid={bid}
                setBid={setBid}
              />

              <BidSectionProjectInfo project={bid} config={config} isPortal={false} />

              <BidSectionDocuments bid={bid} userCan={userCan} isPortal={false} />

              <BidSectionMilestones milestones={bid.milestones} config={config} isPortal={false} />

              <div className="large-form-section">
                <BidSectionToggler show={openContacts} setShow={setContactsOpen} title="Contacts" />
                <Collapse in={openContacts}>
                  <div id="contacts">
                    <BidContacts
                      contractOwner={contractOwner}
                      projectContacts={projectContacts}
                      projectOwners={projectOwners}
                    />
                  </div>
                </Collapse>
              </div>

              {todos && !!todos.length && (
                <div className="large-form-section">
                  <BidTasks tasks={todos} setTodos={setTodos} setBid={setBid} bid={bid} />
                </div>
              )}
            </div>

            <div className="col-12 col-lg-3 order-0 order-lg-1 fixed-sidebar border-start-lg ps-4-lg">
              {bid && (
                <BidSidebar
                  bid={bid}
                  setBid={(bid) => {
                    setBid(bid);
                    completeBid(bid);
                  }}
                  archived={archived}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </InteriorPage>
  );
};

export default BidPage;
