import React from "react";
import RowContent from "../../RowContent";
import Date from "../../columnRenders/Date";
import PM from "../../../../config/fieldLogListConfig/columnRenders/PM";
import PWPillGrid from "../../../../components/PWPillGrid";
import RowItemContent from "../../RowItemContent";
import convertDecimalHours from "../../../../utils/convertDecimalHours";
import Status from "../../../../config/fieldLogListConfig/columnRenders/Status";
import Alerts from "../../../../config/fieldLogListConfig/columnRenders/Alerts";
import fieldLogWidths from "../../gridWidths/fieldLogWidths";
import ProjectName from "../../columnRenders/ProjectName";

const FieldLogRow = ({ fieldLog, isCrew = false }) => {
  return (
    <RowContent>
      <RowItemContent width={fieldLogWidths.date}>
        <Date date={fieldLog.date} />
      </RowItemContent>
      {!isCrew && (
        <RowItemContent>
          <PWPillGrid data={fieldLog} />
        </RowItemContent>
      )}
      <RowItemContent width={fieldLogWidths.job_number}>{fieldLog.job_number}</RowItemContent>
      <RowItemContent width={fieldLogWidths.project_name}>
        <ProjectName projectName={fieldLog.project_name} />
      </RowItemContent>
      <RowItemContent width={fieldLogWidths.foreman}>
        <>{`${fieldLog.foreman_first_name} ${fieldLog.foreman_last_name}`}</>
      </RowItemContent>
      <RowItemContent width={fieldLogWidths.project_managers}>
        <PM data={fieldLog} />
      </RowItemContent>
      <RowItemContent width={fieldLogWidths.hours}>{convertDecimalHours(fieldLog.hours)}</RowItemContent>
      <RowItemContent width={fieldLogWidths.alerts}>
        <Alerts data={fieldLog} />
      </RowItemContent>
      <RowItemContent width={fieldLogWidths.status}>
        <Status data={fieldLog} />
      </RowItemContent>
      <RowItemContent>{fieldLog.note_count ? fieldLog.note_count : "-"}</RowItemContent>
    </RowContent>
  );
};

export default FieldLogRow;
