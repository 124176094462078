import { useEffect, useState } from "react";
import useCategoryFilters from "./useCategoryFilters";

const useContactsFilters = (contacts, alphaName, isContact) => {
  const [letter, setLetter] = useState("");
  const [filteredByLetter, setFilteredByLetter] = useState([]);

  const { filteredByCategory, updateCategory, selectedCategories } =
    useCategoryFilters(contacts, isContact);

  const updateLetter = (newLet) => {
    setLetter(newLet === letter ? "" : newLet);
  };

  useEffect(() => {
    if (filteredByCategory) {
      setFilteredByLetter(
        filteredByCategory.filter((item) => {
          if (letter) {
            if (!item[alphaName]) return false;
            const startsWithLetter =
              item[alphaName].toLowerCase().indexOf(letter.toLowerCase()) === 0;
            return startsWithLetter;
          } else {
            return true;
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letter, filteredByCategory]);

  return {
    filteredByLetter,
    updateCategory,
    updateLetter,
    letter,
    selectedCategories,
  };
};

export default useContactsFilters;
