import React, { useState } from 'react'
import Foremen from './Foremen'
import Crew from './Crew'
import Equipment from './Equipment'
import styles from "./styles.module.scss";
import DailyAccordion from '../components/DailyAccordion';

const ForemanCrewEquipment = ({
  selectedForemenIds,
  setSelectedForemenIds,
  foremen,
  foremenError,
  foremenLoading,
  selectedForemen,
  setSelectedForemen,
  crewActive,
  setCrewActive,
  filteredCrew,
  crewError,
  crewLoading,
  handleForemanToggle,
  foremenActive,
  setForemenActive,
  equipmentActive,
  setEquipmentActive,
  equipment,
  equipmentError,
  equipmentLoading
}) => {

  const [active, setActive] = useState(false);

  const HeaderContent = () => (
    <h2 className={styles.largeHeaderText}>{"Foreman, Crew & Equipment"}</h2>
  )
  return (
    <>
      <DailyAccordion
        headerContent={<HeaderContent />}
        active={active}
        setActive={setActive}>
        <>
          <Foremen
            active={foremenActive}
            setActive={setForemenActive}
            foremen={foremen}
            selectedForemenIds={selectedForemenIds}
            setSelectedForemenIds={setSelectedForemenIds}
            foremenError={foremenError}
            foremenLoading={foremenLoading}
            selectedForemen={selectedForemen}
            setSelectedForemen={setSelectedForemen}
            handleForemanToggle={handleForemanToggle}
          />
          <Crew
            active={crewActive}
            setActive={setCrewActive}
            loading={crewLoading}
            error={crewError}
            filteredCrew={filteredCrew}
            foremen={foremen}
            selectedForemenIds={selectedForemenIds}
          />
          <Equipment
            active={equipmentActive}
            setActive={setEquipmentActive}
            equipment={equipment}
            equipmentError={equipmentError}
            equipmentLoading={equipmentLoading}
          />
        </>
      </DailyAccordion>
    </>
  )
}

export default ForemanCrewEquipment