import React from "react";
import convertDecimalHours from "../../../utils/convertDecimalHours";
function Hours({ data }) {
  //TODO: awaiting api change to turn this into minutes and convert to time

  const getNumOfMinutes = () => {
    let hours = 0.0;
    data.time_sheets.forEach((timesheet) => (hours = hours + timesheet.hours));
    return convertDecimalHours(hours);
  };

  return <div>{getNumOfMinutes()}</div>;
}

export default Hours;
