import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import convertMinutesToTime from "../../utils/convertMinutesToTime";
import { BeatLoader } from "react-spinners";

const DetailsBar = ({ project, foremanId, crew, fieldLog, crewLoading }) => {
  const [foreman, setForeman] = useState();
  const [totalTime, setTotalTime] = useState(0);

  useEffect(() => {
    if (!!foremanId && !!crew) {
      const fieldLogForeman = crew.find((c) => c.id === parseInt(foremanId));
      if (!!fieldLogForeman) {
        setForeman(`${fieldLogForeman.first_name} ${fieldLogForeman.last_name}`);
      } else {
        setForeman(null);
      }
    }
  }, [crew, foremanId]);

  useEffect(() => {
    if (!!fieldLog && !!fieldLog.time_sheets && !!fieldLog.time_sheets.length) {
      const timeEntries = fieldLog.time_sheets.reduce((acc, curr) => [...acc, ...curr.time_entries], []);
      const assignedEntryIds = fieldLog.work_day_tasks.reduce((a, c) => [...a, ...c.time_entry_ids], []);
      const assignedTime = timeEntries.reduce(
        (a, c) => (assignedEntryIds.includes(c.id) ? a + parseInt(c.minutes, 10) : a),
        0
      );

      setTotalTime(assignedTime);
    }
  }, [fieldLog]);

  return (
    <>
      <div className={styles.detailsBar}>
        <div className={styles.detailColumn}>
          <div className={styles.detailWrap}>
            <div className={styles.label}>PM</div>
            {!!project?.project_managers?.length ? (
              project.project_managers.map((pm, index) => {
                return (
                  <div key={pm.id} className={styles.value}>
                    {pm.user.first_name} {pm.user.last_name}
                    {index !== project.project_managers.length - 1 && ", "}
                  </div>
                );
              })
            ) : (
              <>N/A</>
            )}
          </div>
          <div className={styles.detailWrap}>
            <div className={styles.label}>Foreman</div>
            <div className={styles.value}>
              {!!crewLoading ? <BeatLoader color="#0071ce" size={10} /> : <>{!!foreman && <>{foreman}</>}</>}
            </div>
          </div>
          <div className={styles.detailWrap}>
            <div className={styles.label}>Hours</div>
            <div className={`${styles.value}`}>{convertMinutesToTime(totalTime) || "-"}</div>
          </div>
          <div className={styles.detailWrap}>
            <div className={`${styles.label} `}>Status</div>
            <div className={`${styles.value} `}>
              {!!fieldLog && !!fieldLog.status ? <>{fieldLog.status.status}</> : "Not Submitted"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

DetailsBar.propTypes = {
  project: PropTypes.object,
  foremanId: PropTypes.string,
  crew: PropTypes.array,
  fieldLog: PropTypes.object,
  crewLoading: PropTypes.bool,
};

export default DetailsBar;
