import React from "react";
import styles from "./styles.module.scss";
import CrewMember from "./CrewMember";
import { Button } from "react-bootstrap";
import { ChevronDown, ChevronRight } from "react-feather";
import { AlertPopup } from "component-library";
import { BeatLoader } from "react-spinners";
import PropTypes from "prop-types";

const Crew = ({ active, setActive, filteredCrew, crewError, crewLoading }) => {

  const headerContent = (
    <>
      <h2 className={styles.title}>
        Crew <span className="ms-2">({filteredCrew?.length || 0})</span>
      </h2>
      {!!crewLoading ? (
        <BeatLoader size={10} color={"#0071ce"} />
      ) : (
        <>
          {!!crewError ? (
            <AlertPopup error={crewError} />
          ) : (
            <>
              {!!filteredCrew?.length && (
                <>
                  {active ? <ChevronDown size="16" color={"#7B7B7B"} /> : <ChevronRight size="16" color={"#7B7B7B"} />}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );

  return (
    <div className={styles.crew}>
      {!!filteredCrew?.length ? (
        <Button
          className="d-flex align-items-center justify-content-between w-100 mb-1 text-decoration-none ps-0 pe-2"
          variant="link"
          onClick={() => !!filteredCrew?.length && setActive(!active)}
        >
          {headerContent}
        </Button>
      ) : (
        <div className="d-flex align-items-center justify-content-between w-100 mb-1 text-decoration-none ps-0 pe-2">
          {headerContent}
        </div>
      )}
      {!crewLoading && !!active && (
        <div>
          {!!filteredCrew?.length ? (
            filteredCrew?.map((crewMember, index) => <CrewMember index={index} crewMember={crewMember} />)
          ) : (
            <>{!crewError && !crewLoading && <div className={styles.noResults}>No crew found</div>}</>
          )}
        </div>
      )}
    </div>
  );
};

Crew.propTypes = {
  selectedForemenIds: PropTypes.array,
};

export default Crew;
