import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import companies from "../../../api/companies";
import { useApi } from "component-library";

const ArchiveLocation = ({ company, setCompany, setArchiving, location }) => {
  const { request: archiveRequest, data: archiveData } = useApi(companies.archiveLocation);

  const archiveLocation = () => {
    archiveRequest(location.id);
  };

  useEffect(() => {
    if (!!archiveData && !!archiveData.success) {
      setCompany({
        ...company,
        locations: company.locations.map((loc) => {
          if (loc.id === location.id) {
            return {
              ...loc,
              archived: archiveData.location.archived
            }
          } else {
            return loc
          }
        } )
      })
      setArchiving(false)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveData]);

  return (
    <Modal dialogClassName="modal-lg" centered animation={false} show={true} onHide={() => setArchiving(false)}>
      <Modal.Header closeButton>
        <h5 className="mb-0 med">{!!location && `Archive Location ${location && location.name}`}</h5>
      </Modal.Header>
      <Modal.Body>Are you sure you want to archive this Location?</Modal.Body>
      <Modal.Footer>
        <Button className="mt-2" onClick={() => setArchiving(false)}>
          Cancel
        </Button>
        <Button variant="tertiary" className="mt-2" onClick={() => archiveLocation()}>
          Confirm Archive
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArchiveLocation;
