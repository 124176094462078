import detailCellConfig from "../../config/crewLogConfig/detailCellConfig";
import { AgGridMasterDetail, useAuth } from "component-library";
import { useNavigate } from "react-router-dom";
import gridRowHeights from "../../utils/gridRowHeights";
import useResourceManagement from "../context/useResourceManagement";
import PrintableCrewLogs from "./PrintableCrewLogs";

const CrewLogListing = ({ filteredCrewMembers, columnDefs, loading, projectId }) => {
  const { crewExpanded } = useResourceManagement();
  const { user } = useAuth();

  let history = useNavigate();

  const handleCellClick = ({ data, column }) => {
    if (!["expand", "phone"].includes(column?.colId)) {
      let url = !!projectId
        ? `/project/${projectId}/field-management/crew-log/${data.user_id}`
        : `/field-management/crew-log/${data.user_id}`;
      history(url);
    }
  };

  return (
    <>
      <div className="showPrint">
        {!!filteredCrewMembers?.length && <PrintableCrewLogs data={filteredCrewMembers} columns={columnDefs} />}
      </div>
      <div className="hiddenPrint">
        <AgGridMasterDetail
          gridData={filteredCrewMembers}
          gridConfig={columnDefs}
          id={`${user.id}_crew_log_listing`}
          handleCellClick={handleCellClick}
          loading={loading}
          isRowMaster={(dataItem) => (dataItem?.time_sheets?.length ? true : false)}
          detailCellConfig={detailCellConfig}
          getDetailRowData={(params) => {
            params.successCallback(params.data?.time_sheets);
          }}
          rowHeight={gridRowHeights.medium}
          expandAll={crewExpanded}
          suppressSizeToFit
          autoHeight
          updateColumnDefs
          rowIdField="user_id"
          fallbackMessage="No timesheets found."
        />
      </div>
    </>
  );
};

export default CrewLogListing;
