import React from "react";
import InteriorPage from "../pageLayout/InteriorPage";
import ProjectSelection from "../../_projects/ProjectSelection/ProjectSelection";
import { HeaderButton, useAuth } from "component-library";
import { Archive, Briefcase } from "react-feather";

const ProjectList = () => {
  const { userCan } = useAuth();

  const HeaderButtons = () => (
    <>
      {userCan("project-create") && (
        <div className="d-flex flex-row">
          <HeaderButton
            linkTo="/projects/archive"
            text="Project Archive"
            icon={<Archive size={16} color="#0071ce" />}
            ariaLabel="View Project Archive"
          />
          <HeaderButton linkTo="/projects/create" ariaLabel="Add New Project" />
        </div>
      )}
    </>
  );

  return (
    <InteriorPage
      pageTitle="Project Selection"
      navTitle="Projects"
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitleSecondaryContent={HeaderButtons}
    >
      <ProjectSelection />
    </InteriorPage>
  );
};

export default ProjectList;
