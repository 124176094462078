import React from "react";
import styles from "./styles.module.scss";

const TaskContent = ({ children, size }) => {
  return (
    <div className={`${styles.taskContentWrapper} ${size === "small" && styles.taskSmallWrapper} `}>{children}</div>
  );
};

export default TaskContent;
