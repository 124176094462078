import React from "react";
import { DateRangeSelector, FilterSearch, dropdownStylesWithWidth } from "component-library";
import useNotesAdmin from "./context/useNotesAdmin";
import styles from "./styles.module.scss";
import Select from "react-select";
import { getMaxDate } from "../../utils/getMaxDate";

const NotesAdminFilter = () => {
  const {
    projectsList,
    userOptions,
    selectedProject,
    setSelectedProject,
    selectedUser,
    setSelectedUser,
    handleSearch,
    dateRange,
    setDateRange,
  } = useNotesAdmin();

  return (
    <div className="d-flex justify-content-between">
      <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} maxDate={getMaxDate(dateRange, 14)} />
      <div className="d-flex align-items-center">
        <div className={`d-flex align-items-center form-group`}>
          <Select
            options={projectsList}
            name="project-select"
            id="project-select"
            styles={dropdownStylesWithWidth(200)}
            onChange={(value) => setSelectedProject(value?.value)}
            value={projectsList?.find((p) => p.value === selectedProject)}
            aria-label="priority options"
            isClearable={true}
            placeholder="Filter by Project"
            className={styles.selectField}
          />
          <Select
            options={userOptions}
            name="user-select"
            id="user-select"
            styles={dropdownStylesWithWidth(200)}
            onChange={(value) => setSelectedUser(value?.value)}
            value={userOptions?.find((p) => p.value === selectedUser)}
            aria-label="priority options"
            isClearable={true}
            placeholder="Filter by User"
            className={styles.selectField}
          />
          <FilterSearch inline searchFunction={handleSearch} />
        </div>
      </div>
    </div>
  );
};

export default NotesAdminFilter;
