import RFQStatus from "../bidColumns/RFQStatus";

const rfq = {
  headerName: "RFQ",
  field: "rfq",
  width: 72,
  headerClass: "ag-center-aligned-header",
  cellStyle: {justifyContent: "center"},
  suppressMenu: true,
  cellRenderer: RFQStatus,
};

export default rfq;
