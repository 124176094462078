import React from "react";
import styles from "./styles.module.scss";
import RecentTask from "./RecentTask";
import CustomScroll from "react-custom-scroll";

const RecentTasks = ({ tasks }) => {
  return (
    <>
      <h2 className={styles.title}>Recent Tasks</h2>
      <CustomScroll heightRelativeToParent={!!tasks?.length > 2 ? "100%" : "auto"}>
        <div className={styles.tasksWrapper}>
          <div className={styles.taskList}>
            {!!tasks?.length ? (
              tasks.map((task) => <RecentTask task={task} key={task.id} />)
            ) : (
              <>No recent tasks found</>
            )}
          </div>
        </div>
      </CustomScroll>
    </>
  );
};

export default RecentTasks;
