import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import useAddDocument from "./useAddDocument";
import { Navigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { Upload, X } from "react-feather";
import { BeatLoader } from "react-spinners";

const AddDocument = ({ setModalOpen, projectId }) => {
  const [fileChosenForUpload, setFileChosenForUpload] = useState();
  const [dropdownSelection, setDropdownSelection] = useState("");

  const { handleSubmit, fields, renderSubmitButton, redirect, loading, selectedFolder } = useAddDocument({
    setModalOpen,
    projectId,
    fileChosenForUpload,
    setFileChosenForUpload,
    dropdownSelection,
    setDropdownSelection,
  });

  return (
    <>
      {!!redirect && <Navigate to={redirect} />}
      <Modal dialogClassName="modal-lg" centered animation={false} show={true} onHide={() => setModalOpen(false)}>
        <div className="position-relative">
          {!!loading && (
            <div className="loaderWrapper">
              <BeatLoader color="#0071ce" />
            </div>
          )}
          <Modal.Header
            closeLabel="close add document modal"
            className={`${styles.header} d-flex align-items-center justify-content-between`}
          >
            <div className="d-flex align-items-center">
              <Upload size={16} color="#0017ce" />
              <h5 className={`mb-0 med ${styles.title}`}>Upload File</h5>
            </div>
            <Button variant="link" className="p-0" onClick={() => setModalOpen(false)}>
              <X size={24} color="#d3d3d3" />
            </Button>
          </Modal.Header>
          <form onSubmit={handleSubmit}>
            <Modal.Body>{fields}</Modal.Body>
            <Modal.Footer className={styles.footer}>
              <Button
                variant="gray2"
                className={`${styles.modalCancelButton}`}
                onClick={() => setModalOpen(false)}
                aria-label="cancel add document modal"
              >
                Cancel
              </Button>
              {renderSubmitButton(
                "Upload File",
                !!loading || !dropdownSelection || !fileChosenForUpload || !selectedFolder,
                styles.submitButton
              )}
            </Modal.Footer>
          </form>
        </div>
      </Modal>
    </>
  );
};

AddDocument.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  projectId: PropTypes.any.isRequired,
};

export default AddDocument;
