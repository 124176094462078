import React from "react";
import { Button } from "react-bootstrap";
import { Eye, EyeOff } from "react-feather";
import useProject from "../../../_projects/hooks/useProject";

const ExpandAll = () => {
  const { reportingExpanded, setReportingExpanded } = useProject();

  return (
    <Button variant="link" onClick={() => setReportingExpanded(!reportingExpanded)}>
      {!!reportingExpanded ? <Eye size="16" color="#A7A7A7" /> : <EyeOff size="16" color="#A7A7A7" />}
    </Button>
  );
};

export default ExpandAll;
