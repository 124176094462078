import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const RecentTask = ({ task }) => {
  const { id } = useParams();

  return (
    <div className={styles.task}>
      <Link className={`${styles.taskLink} btn-link`} to={`/project/${id}/reporting/task/${task?.id}`} target="_blank">
        <span className={styles.code}>{task?.cost_code || ""}</span>
        <span className={styles.taskName}>{task?.name}</span>
      </Link>
    </div>
  );
};

export default RecentTask;
