import { useEffect, useState } from "react";
import { useApi, tenantApi } from "component-library";

const useProvideSuperAdmin = () => {
  const [tenantSettings, setTenantSettings] = useState();

  const {
    request: tenantSettingsRequest,
    data: tenantSettingsData,
    error: tenantSettingsError,
    loading: tenantSettingsLoading,
  } = useApi(tenantApi.getTenantSettings);

  const {
    request: updateTenantSettingsRequest,
    data: updateTenantData,
    error: updateError,
    loading: updateLoading,
  } = useApi(tenantApi.updateTenantSettings);

  useEffect(() => {
    tenantSettingsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tenantSettingsData?.config) {
      setTenantSettings(tenantSettingsData.config);
    } else {
      setTenantSettings();
    }
  }, [tenantSettingsData]);

  return {
    tenantSettings,
    tenantSettingsError,
    tenantSettingsLoading,
    updateTenantSettingsRequest,
    updateTenantData,
    setTenantSettings,
    updateError,
    updateLoading,
  };
};

export default useProvideSuperAdmin;
