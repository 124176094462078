import React from "react";
import { ReactComponent as UnpinnedIcon } from "../../../../assets/photoUnpinned.svg";
import { ReactComponent as PinnedIcon } from "../../../../assets/photoPinned.svg";
import styles from "../styles.module.scss";

const Pinned = ({ isPinned, onClick }) => {
  return (
    <button onClick={onClick} className="btn btn-link">
      {isPinned ? <PinnedIcon className={styles.pinIcon} /> : <UnpinnedIcon className={styles.unpinIcon} />}
    </button>
  );
};

export default Pinned;
