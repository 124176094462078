import React from "react";
import useProvideEstimateConversion from "./useProvideEstimateConversion"
import estimateConversionContext from "./estimateConversionContext";

function ProvideEstimateConversion({ children }) {
  const estimateConversion = useProvideEstimateConversion();
  return <estimateConversionContext.Provider value={estimateConversion}>{children}</estimateConversionContext.Provider>;
}

export default ProvideEstimateConversion;
