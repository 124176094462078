import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useFormControl, useApi, bidApi, dropdownStyles, useAuth } from "component-library";
import { schema, initialData } from "../../schema/initialBid";
import { useNavigate } from "react-router-dom";
import getLabel from "../../utils/getLabel";

const useInitialCreateBid = (bidObject = initialData) => {
  const { config } = useAuth();
  const { error, request, data } = useApi(bidApi.createBid);
  const history = useNavigate();

  const onSubmit = async (formData, setLoading) => {
    const { success } = await request(formData);
    if (!success) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data && data.project) {
      history(`/bid/${data.project.id}/complete`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const {
    handleSubmit,
    renderSubmitButton,
    renderTextField,
    renderTextareaField,
    renderSelectField,
    data: formData,
  } = useFormControl({
    schema,
    onSubmit,
    initialData: bidObject,
  });

  const fields = (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="row">
        <div className="col-12">
          {renderTextField({
            name: "name",
            label: "Project / Bid Name",
            autoFocus: true,
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {renderSelectField({
            name: "type_id",
            label: "Project Type",
            options: config.project_types.map((type) => ({
              value: type.id,
              label: type.name,
            })),
            styles: dropdownStyles,
          })}
        </div>
        <div className="col-md-6">
          {renderSelectField({
            name: "category_id",
            label: "Project Category",
            options: config.project_categories.map((cat) => ({
              value: cat.id,
              label: cat.name,
            })),
            styles: dropdownStyles,
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {renderTextareaField({
            name: "description",
            label: getLabel("description", formData.type_id),
            className: "w-100 p-2",
            rows: "2",
          })}
        </div>
      </div>
      <div className="row pb-2">
        {(formData.type_id === 3 || formData.type_id === 6) && (
          <div className="col-md-4">
            {renderTextField({
              name: "idot_project_no",
              label: "Project No.",
            })}
          </div>
        )}
      </div>
    </>
  );
  return {
    initialData,
    fields,
    handleSubmit,
    renderSubmitButton,
  };
};

export default useInitialCreateBid;
