import React from "react";
import { FilterSearch } from "component-library";
import styles from "../styles.module.scss";
import { BoomSelector } from "component-library";

const POIFilters = ({ setQ, statusFilter, setStatusFilter, statusOptions, loading }) => {
  const handleSearch = (value) => setQ(value);

  return (
    <div className="d-flex justify-content-end">
      <BoomSelector
        selected={statusFilter}
        setSelected={setStatusFilter}
        options={statusOptions}
        loading={loading}
        placeholder="Filter by Status"
      />
      <div className={styles.searchFilter}>
        <FilterSearch inline searchFunction={handleSearch} loading={loading} />
      </div>
    </div>
  );
};

export default POIFilters;
