import { Joi } from "component-library";

const schema = {
  fileName: Joi.saneString().required().label("File Name"),
};

const initialData = {
  fileName: "",
};

export { schema, initialData };
