import ImagesIcon from "../columns/ImagesIcon";

const imagesIcon = {
  headerName: "",
  field: "files",
  cellRenderer: ImagesIcon,
  suppressMenu: true,
  sortable: false,
  width: 50,
  maxWidth: 50,
};

export default imagesIcon;
