import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

const Popup = ({ message, setMessage }) => {
  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const handleClick = () => {
    setMessage("");
  };

  return (
    <Modal
      centered
      show={!!message.length}
      contentClassName="popup-content"
      onClick={() => handleClick()}
      onHide={handleClick}
    >
      {message}
    </Modal>
  );
};

Popup.propTypes = {
  message: PropTypes.string,
  setMessage: PropTypes.func.isRequired,
};

export default Popup;
