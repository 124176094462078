import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Truck, DollarSign } from "react-feather";
import { ReactComponent as Lunch } from "../../assets/lunch.svg";
import DriveTimeModal from "./DriveTimeModal";
import PerDiemModal from "./PerDiemModal";
import PropTypes from "prop-types";
import IconButton from "../FieldLogs/IconButton";

const TimeEntryEditActions = ({ timesheet, projectsList, editing, updateTimesheet }) => {
  const [showDriveTimeModal, setShowDriveTimeModal] = useState(false);
  const [showPerDiemModal, setShowPerDiemModal] = useState(false);

  return (
    <>
      {!!editing && (
        <>
          {showDriveTimeModal && (
            <DriveTimeModal
              timesheet={timesheet}
              setTimesheet={updateTimesheet}
              close={() => setShowDriveTimeModal(false)}
              projectsList={projectsList}
            />
          )}
          {showPerDiemModal && (
            <PerDiemModal
              timesheet={timesheet}
              setTimesheet={updateTimesheet}
              close={() => setShowPerDiemModal(false)}
              projectsList={projectsList}
            />
          )}
          <div className={`${styles.actions} row`}>
            <div className={`${styles.buttonGroup} col`}>
              <IconButton
                icon={<Truck size={16} color="#0071CE" className="me-2" />}
                label="Add Drive Time"
                onClick={() => setShowDriveTimeModal(true)}
                buttonClassName="px-0 ms-1"
              />
              <IconButton
                icon={<Lunch width={16} height={16} color="#0071CE" className="me-1" />}
                label="Add Lunch"
                onClick={() =>
                  updateTimesheet({
                    ...timesheet,
                    lunch_duration: 30,
                  })
                }
                disabled={parseFloat(timesheet?.lunch_duration) > 0}
                buttonClassName="px-0 ms-1"
              />
              <IconButton
                icon={<DollarSign size={16} color="#0071CE" className="me-1" />}
                label="Per Diem"
                onClick={() => setShowPerDiemModal(true)}
                buttonClassName="px-0 ms-1"
                disabled={!!timesheet?.perdiem}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

TimeEntryEditActions.propTypes = {
  timesheet: PropTypes.object,
  setTimesheet: PropTypes.func,
  projectsList: PropTypes.array,
  editing: PropTypes.bool,
};

export default TimeEntryEditActions;
