import { note } from "./noteAdminGridConfig/columnDefs";

const columnDefs = [note];

const useCellConfig = () => {
  const detailGridOptions = {
    rowClass: "boxGroupTable",
    pagination: false,
    columnDefs: columnDefs,
    defaultColDef: {
      sortable: true,
    },
    detailRowAutoHeight: true,
    rowSelection: "multiple",
    refreshStrategy: "nothing",
    enableCellChangeFlash: false,
    suppressRowClickSelection: true,
  };

  return detailGridOptions;
};

export default useCellConfig;
