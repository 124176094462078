import React from "react";
import useUserAdmin from "../../../_admin/pages/UserAdmin/hooks/useUserAdmin";

const DeactivateHeader = () => {
  const { archived } = useUserAdmin();

  return <>{archived ? <>Activiate</> : <>Deactivate</>}</>;
};

export default DeactivateHeader;
