import React from "react";
import ProjectTasks from "./ProjectTasks";
import ProvideEstimateConversion from "../ProvideEstimateConversion";

const ProjectTasksPage = () => {
  return (
    <ProvideEstimateConversion>
      <ProjectTasks />
    </ProvideEstimateConversion>
  );
};

export default ProjectTasksPage;
