import { Image } from "react-feather";
import SharePage from "../../SharePage";

const SharedPhoto = () => {
  return (
    <SharePage
      pageTitle={"Photo name"}
      loading={false}
      error={null}
      fillScreen
      navIcon={<Image color="#0071CE" size={16} />}
    >
      <>Share photo detail page</>
    </SharePage>
  );
};

export default SharedPhoto;
