import React from "react";
import Chiclet from "../../../../_dashboard/Chiclet";
import styles from "./styles.module.scss";
import StatusItemChart from "../../../../_dashboard/StatusItemChart";
import ManHoursItemChart from "../../../../_dashboard/ManHoursItemChart";
import CashflowChart from "../../../../_dashboard/CashflowChart";
import InvoiceChart from "../../../../_dashboard/InvoiceChart";
import PerJobItemChart from "../../../../_dashboard/PerJobItemChart";
import ScoreCard from "./ScoreCard";
import PerHourFigure from "./PerHourFigure";

const JobAnalysis = ({ job }) => {
  return (
    <>
      {job && Object.entries(job).length > 0 && (
        <div className="mt-3">
          <div className="row mb-3 pb-1 job-top-row print-section">
            <div className="col-lg-4 bpe-wrap pb-3">
              {job && job.project_status && (
                <Chiclet className={styles.firstJPRow}>
                  <h3 className={styles["chart-title"]}>BPR - % over/under</h3>
                  <StatusItemChart status={job.project_status} />
                </Chiclet>
              )}
            </div>
            <div className="col-lg-4 bhr-wrap pb-3">
              <Chiclet className={styles.firstJPRow}>
                <h3 className={styles["chart-title"]}>Bryce Health Report</h3>
                <PerHourFigure amount={job.dollars_hours} />
              </Chiclet>
            </div>
            <div className="col-lg-4 man-hours-wrap pb-3">
              <Chiclet className={styles.firstJPRow}>
                <h3 className={styles["chart-title"]}>Man Hours</h3>
                <ManHoursItemChart job={job} />
              </Chiclet>
            </div>
          </div>

          {job && job.cashflow && !!job.cashflow.length && (
            <div className="row mb-3 pb-1 cashflow print-section">
              <div className="col">
                <CashflowChart cashflow={job.cashflow} />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-lg-7 print-section pb-3">
              <Chiclet className={styles.otherJPRow}>
                <h3 className={styles["chart-title"]}>Current AR</h3>
                <InvoiceChart invoiceTotals={job.invoices} />
              </Chiclet>
            </div>
            <div className="col-lg-5 print-section pb-3">
              <Chiclet className={`job-cost ${styles.otherJPRow}`}>
                <h3 className={styles["chart-title"]}>Job Cost</h3>
                <PerJobItemChart job={job} lg />
              </Chiclet>
            </div>
          </div>
          {job && job.scorecard && job.scorecard.summary && !!job.scorecard.summary.length && (
            <div className="row">
              <ScoreCard job={job} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default JobAnalysis;
