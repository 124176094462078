import convertMinutesToTime from "../../../../../utils/convertMinutesToTime";

const minutes = {
  headerName: "Hours",
  field: "minutes",
  maxWidth: 112,
  valueGetter: ({ data }) =>
    data.minutes ? convertMinutesToTime(data.minutes) : "0:00",
};

export default minutes;
