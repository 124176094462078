/* global google */
import { useEffect, useState } from "react";
import { useGoogleMaps } from "../components/GoogleMapsProvider";
let selection = null;

const useGeofences = ({ geofenceLocations, showGeofences, selectableFences = true }) => {
  const { isLoaded } = useGoogleMaps();
  const [polygons, setPolygons] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState({});
  const [map, setMap] = useState();
  const [newRegionName, setNewRegionName] = useState();
  const [saveModal, setSaveModal] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [storedShapes, setStoredShapes] = useState();

  const getPolyColor = (value) => {
    let color;
    switch (true) {
      case value === "project":
        color = "#0066C9";
        break;
      case value === "hq":
        color = "#98B05E";
        break;
      case value === "company":
        color = "#98B05E";
        break;
      case value === "dump":
        color = "#AD6304";
        break;
      case value === "quarry":
        color = "#D8AF01";
        break;
      case value === "supplier":
        color = "#C1259E";
        break;
      case value === "dealer_service":
        color = "#444444";
        break;
      default:
        color = "#0071ce";
    }
    return color;
  };

  const polyOptions = {
    strokeWeight: 1,
    fillOpacity: 0.3,
    strokeOpacity: 0,
    editable: true,
  };

  const onLoadMap = (gmap) => {
    setMap(gmap);
  };

  const setSelected = (shape, polyId) => {
    if (selection) {
      selection.setEditable(false);
    }
    setActiveMarker();
    setSelectedPolygon(polygons.find((poly) => polyId === poly.id));

    shape.setEditable(true);
    shape.polyId = polyId;
    selection = shape;
  };

  const drawPolygons = () => {
    if (!!storedShapes?.length) {
      storedShapes.forEach((shape) => {
        shape.setMap(null);
      });
    }
    if (!!polygons?.length) {
      let bounds = new google.maps.LatLngBounds();
      let shapes = [];

      polygons.forEach((poly) => {
        if (poly.geofence) {
          poly.geofence.forEach((latLong) => {
            const ll = new google.maps.LatLng(latLong.lat, latLong.lng);
            bounds.extend(ll);
          });
          const polyColor = poly?.color || getPolyColor(poly?.geofence_type);
          const shape = new google.maps.Polygon({
            paths: poly?.geofence?.map((latLong) => {
              return {
                lat: parseFloat(latLong.lat),
                lng: parseFloat(latLong.lng),
              };
            }),
            ...polyOptions,
            fillColor: polyColor,
            editable: false,
            polyId: poly.id,
            name: poly.name,
          });

          shape.setMap(map);
          if (!!selectableFences) {
            shape.addListener("click", () => {
              setSelected(shape, poly.id);
            });
          }
          shapes.push(shape);
        }

        if (poly.polygon) {
          poly.polygon.forEach((latLong) => {
            const ll = new google.maps.LatLng(latLong.lat, latLong.lng);
            bounds.extend(ll);
          });
          const polyColor = getPolyColor(poly?.geofence_type);
          const shape = new google.maps.Polygon({
            paths: poly?.polygon?.map((latLong) => {
              return {
                lat: parseFloat(latLong.lat),
                lng: parseFloat(latLong.lng),
              };
            }),
            ...polyOptions,
            fillColor: polyColor,
            editable: false,
            polyId: poly.id,
            name: poly.name,
          });

          shape.setMap(map);
          if (!!selectableFences) {
            shape.addListener("click", () => {
              setSelected(shape, poly.id);
            });
          }
          shapes.push(shape);
        }
      });
      setStoredShapes(shapes);
      if (map) {
        map.fitBounds(bounds);
      }
    }
  };

  const getSelectedCenter = () => {
    var bounds = new google.maps.LatLngBounds();
    let paths;
    if (selectedPolygon?.geofence) {
      paths = selectedPolygon?.geofence?.map((latLong) => {
        return { lat: parseFloat(latLong.lat), lng: parseFloat(latLong.lng) };
      });
    }
    if (selectedPolygon?.polygon) {
      paths = selectedPolygon?.polygon?.map((latLong) => {
        return { lat: parseFloat(latLong.lat), lng: parseFloat(latLong.lng) };
      });
    }
    for (let i = 0; i < paths?.length; i++) {
      bounds.extend(paths[i]);
    }
    return bounds.getCenter();
  };

  const onLoadDrawingManager = () => {
    drawPolygons();
  };

  const onLoadPolyManager = (path) => {
    if (!!path) {
      let bounds = new google.maps.LatLngBounds();
      path.forEach((single) => {
        const ll = new google.maps.LatLng(single.lat, single.lng);
        bounds.extend(ll);
      });
      if (map) {
        map.fitBounds(bounds);
      }
    }
  };

  useEffect(() => {
    if (!!showGeofences) {
      if (!!geofenceLocations?.length) {
        setPolygons(geofenceLocations);
      }
    } else {
      if (storedShapes) {
        storedShapes.forEach((shape) => {
          shape.setMap(null);
        });
        setStoredShapes();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geofenceLocations, showGeofences]);

  useEffect(() => {
    if (!!polygons?.length) {
      drawPolygons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygons]);

  return {
    map,
    polygons,
    setPolygons,
    onLoadMap,
    selectedPolygon,
    setSelectedPolygon,
    isLoaded,
    polyOptions,
    newRegionName,
    setNewRegionName,
    setSaveModal,
    saveModal,
    getSelectedCenter,
    onLoadDrawingManager,
    activeMarker,
    setActiveMarker,
    onLoadPolyManager,
  };
};

export default useGeofences;
