import { useState } from "react";
import PropTypes from "prop-types";

const useForemen = ({ selectedForemenIds, selectedForemen, setSelectedForemen, setSelectedForemenIds }) => {
  const [active, setActive] = useState(true);

  const handleForemanToggle = (foreman) => {
    let updatedIds;
    let updatedSelected;
    if (selectedForemenIds.includes(foreman.foreman_id)) {
      updatedIds = selectedForemenIds.filter((s) => s !== foreman.foreman_id);
      updatedSelected = selectedForemen.filter((f) => f.id !== foreman.foreman_id);
    } else {
      updatedIds = selectedForemenIds.length ? [...selectedForemenIds, foreman.foreman_id] : [foreman.foreman_id];
      updatedSelected = selectedForemen.length ? [...selectedForemen, foreman] : [foreman];
    }
    setSelectedForemenIds(updatedIds);
    setSelectedForemen(updatedSelected);
  };

  return {
    active,
    setActive,
    handleForemanToggle,
  };
};

useForemen.propTypes = {
  selectedForemenIds: PropTypes.array,
  selectedForemen: PropTypes.array,
  setSelectedForemen: PropTypes.func,
  setSelectedForemenIds: PropTypes.func,
};

export default useForemen;
