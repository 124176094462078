import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { dropdownStyles, useAuth } from "component-library";
import { DateTime } from "luxon";

const DateOption = ({ renderTextField, renderSelectField, formData, setFields, item }) => {
  const { config } = useAuth();
  const [filteredOptions, setFilteredOptions] = useState();
  let contractTurnaroundOptions = config.date_reminder_config.map((option) => ({
    value: option.id,
    label: option.value,
  }));

  const setDayText = (dayIndex, dayName) => {
    let text;
    switch (dayIndex) {
      case 0:
        text = `Monthly - First ${dayName}`;
        break;
      case 1:
        text = `Monthly - Second ${dayName}`;
        break;
      case 2:
        text = `Monthly - Third ${dayName}`;
        break;
      case 3:
        text = `Monthly - Fourth ${dayName}`;
        break;
      case 4:
        text = `Monthly - Last ${dayName}`;
        break;
      default:
        break;
    }

    return text;
  };

  const getMatchingDaysInMonth = (fieldDateValue) => {
    const month = fieldDateValue.toFormat("M");
    const year = fieldDateValue.toFormat("yyyy");
    const selectedWeekdayNumber = fieldDateValue.toFormat("E");
    const numberOfDaysInMOnth = new Date(year, month, 0).getDate();
    let matchingDays = [];

    for (let i = 1; i <= numberOfDaysInMOnth; ) {
      let checkingDate = new Date(year, parseInt(month) - 1, i);
      let checkingWeekdayNumber = DateTime.fromJSDate(checkingDate).toFormat("E");

      if (checkingWeekdayNumber === selectedWeekdayNumber) {
        matchingDays.push(DateTime.fromJSDate(checkingDate).toFormat("d"));
        i += 7;
      } else {
        i++;
      }
    }

    return matchingDays;
  };

  const getFilteredOptions = () => {
    let updatedOptions;
    const fieldDateValue = DateTime.fromFormat(formData[item.name], "yyyy-MM-dd");
    const weekDayName = fieldDateValue.toFormat("EEEE");
    const dayOfTheMonthNumber = fieldDateValue.toFormat("d");
    const matchingDays = getMatchingDaysInMonth(fieldDateValue);
    const dayIndex = matchingDays.indexOf(dayOfTheMonthNumber);
    const dayText = setDayText(dayIndex, weekDayName);

    updatedOptions = contractTurnaroundOptions.filter(
      (opt) => !opt.label.includes("Monthly -") || opt.label.includes(weekDayName)
    );
    if (parseInt(dayOfTheMonthNumber) > 28) {
      updatedOptions = updatedOptions.filter(
        (opt) => opt.label.includes("Monthly -") || opt.label.includes("No Repeat")
      );
    }
    updatedOptions = updatedOptions.filter((opt) => opt.label.includes(dayText) || !opt.label.includes("Monthly -"));

    return updatedOptions;
  };

  const checkToUnsetTypeField = () => {
    const currentSelectedType = contractTurnaroundOptions.find((opt) => opt.value === formData[item.type]);
    const isInValidOptions =
      !!currentSelectedType && filteredOptions.find((opt) => opt.value === currentSelectedType.value);
    if (!!currentSelectedType && !isInValidOptions) {
      setFields({
        ...formData,
        [item.type]: null,
      });
    }
  };

  useEffect(() => {
    if (!!formData && !!formData[item.name]) {
      const filteredOptions = getFilteredOptions();
      setFilteredOptions(filteredOptions);
    } else {
      setFilteredOptions(contractTurnaroundOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!formData && formData[item.name]]);

  useEffect(() => {
    if (!!filteredOptions) {
      checkToUnsetTypeField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredOptions]);

  return (
    <>
      <div className="row flex-row align-items-end">
        <div className="col-md-4">
          {renderTextField({
            name: item.name,
            label: item.label,
            type: "date",
          })}
        </div>
        {!!formData[item.name] && (
          <div className="col-md-3">
            {renderSelectField({
              value:
                !!formData[item.type] && !!filteredOptions
                  ? filteredOptions.find((option) => option.value === formData[item.type])
                  : null,
              name: item.type,
              options: !!filteredOptions ? filteredOptions : [],
              styles: dropdownStyles,
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default DateOption;

DateOption.propTypes = {
  item: PropTypes.object,
  renderTextField: PropTypes.func.isRequired,
  renderSelectField: PropTypes.func.isRequired,
  formData: PropTypes.object,
  setFields: PropTypes.func,
};
