import React from "react";
import Task from "./Task";
import PropTypes from "prop-types";

const TaskRows = ({ takeoffs, setTakeoffs, material, setNewMaterialError }) => {
  return (
    <>
      {material?.tasks.map((task, index) => (
        <Task
          material={material}
          task={task}
          key={task.id}
          bgColor={index % 2 === 0 ? "light" : "dark"}
          takeoffs={takeoffs}
          setTakeoffs={setTakeoffs}
          setNewMaterialError={setNewMaterialError}
        />
      ))}
    </>
  );
};

TaskRows.propTypes = {
  takeoffs: PropTypes.array,
  setTakeoffs: PropTypes.func,
  material: PropTypes.object,
  setNewMaterialError: PropTypes.func,
};

export default TaskRows;
