import React from "react";
import Select from "react-select";
import { times, dropdownStyles, useAuth } from "component-library";
import PropTypes from "prop-types";

const BidSidebar = ({ bid, setBid, archived }) => {
  const { user, config } = useAuth();

  const handleSelectChange =
    (name) =>
    ({ value }) => {
      setBid({ ...bid, [name]: value });
    };

  const estimatorOptions = config.estimators.map((est) => ({
    value: est.id,
    label: `${est.first_name} ${est.last_name}`,
  }));

  const runnerOptions = config.runners.map((est) => ({
    value: est.id,
    label: `${est.first_name} ${est.last_name}`,
  }));

  return (
    <div className="text-end-lg fixed-sidebar">
      <form className="mb-3 pb-2 pt-2">
        <h1 style={{ fontSize: "1.6rem" }}>Pre-Bid Preparer</h1>
        <p>{`${user.first_name} ${user.last_name}`}</p>

        <h1 style={{ fontSize: "1.6rem" }}>Estimator</h1>
        <Select
          options={estimatorOptions}
          name="estimator_id"
          id="estimatorId"
          className="mb-3"
          onChange={handleSelectChange("estimator_id")}
          styles={dropdownStyles}
          value={estimatorOptions.find((est) => est.value === bid.estimator_id)}
          isDisabled={!!archived}
          aria-label="Estimator"
        />
        <h1 style={{ fontSize: "1.6rem" }}>Runner</h1>
        <Select
          options={runnerOptions}
          name="runner_id"
          id="estimatorId"
          className="mb-3"
          onChange={handleSelectChange("runner_id")}
          styles={dropdownStyles}
          value={runnerOptions.find((run) => run.value === bid.runner_id)}
          isDisabled={!!archived}
          aria-label="Runner"
        />
        <h1 style={{ fontSize: "1.6rem" }}>Leave Time</h1>
        <Select
          options={times}
          name="leave_time"
          id="leaveTime"
          className="mb-3"
          onChange={handleSelectChange("leave_time")}
          styles={dropdownStyles}
          value={times.find((t) => t.value === bid.leave_time)}
          isDisabled={!!archived}
          aria-label="Leave Time"
        />
      </form>
    </div>
  );
};

BidSidebar.propTypes = {
  bid: PropTypes.shape({
    id: PropTypes.number.isRequired,
    estimator_id: PropTypes.number,
    runner_id: PropTypes.number,
    leave_time: PropTypes.string,
  }),
  setBid: PropTypes.func.isRequired,
  archived: PropTypes.bool,
};

export default BidSidebar;
