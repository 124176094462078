import { Grid } from "react-feather";
import SharePage from "../../SharePage";

const SharedPhoto = () => {
  return (
    <SharePage
      pageTitle={"Photos"}
      subtitle="2 photos"
      loading={false}
      error={null}
      fillScreen
      navIcon={<Grid color="#0071CE" size={16} />}
    >
      <>Share Photos Page</>
    </SharePage>
  );
};

export default SharedPhoto;
