import React from "react";
import PropTypes from "prop-types";
import styles from "../styles.module.scss";
import ArrowLink from "../../../../components/ArrowLink/ArrowLink";
import { dropdownStyles, useAuth } from "component-library";

const ProjectDates = ({ renderTextField, renderSelectField, formData, setFields }) => {
  const { config } = useAuth();

  const maintenancePeriodOptions = config.maintenance_period_config.map((option) => ({
    value: option.id,
    label: option.value,
  }));

  const maintenancePeriodCustomValue =
    !!maintenancePeriodOptions &&
    maintenancePeriodOptions.find((option) => option.label.toLowerCase() === "custom").value;

  const totalDaysConfg = config.total_days_config.map((item) => ({
    value: item.id,
    label: item.value,
  }));

  const contractTurnaroundTimeOptions = config.contract_turnaround_time_config.map((option) => ({
    value: option.id,
    label: option.value,
  }));

  const contractTurnaroundTimeValue =
    !!contractTurnaroundTimeOptions &&
    contractTurnaroundTimeOptions.find((option) => option.label.toLowerCase() === "custom").value;

  const showWarning = () => {
    let infoMissing = false;
    if ((!formData.early_start_date && !formData.late_start_date) || !formData.completion_date) infoMissing = true;
    return infoMissing;
  };

  return (
    <>
      <div className="d-flex flex-row align-items-start">
        <h2 className={styles.heading18}>Project Dates</h2>
        <ArrowLink
          url={`/project/${formData.id}/schedule`}
          text="Go to Milestones"
          label="link to milestones"
          className="mt-3 ms-2"
        />
      </div>
      <div className="row flex-row align-items-end">
        <div className="col-md-3">
          {renderTextField({
            name: "early_start_date",
            label: "Early Start Date",
            type: "date",
            hasWarning: showWarning(),
          })}
        </div>
        <div className="col-md-3">
          {renderTextField({
            name: "late_start_date",
            label: "Late Start Date",
            type: "date",
            hasWarning: showWarning(),
          })}
        </div>
        <div className="col-md-3">
          {renderTextField({
            name: "substantial_start_date",
            label: "Substantial Start Date",
            type: "date",
            hasWarning: showWarning(),
          })}
        </div>
        <div className="col-md-3">
          {renderTextField({
            name: "completion_date",
            label: "Completion Date",
            type: "date",
            hasWarning: showWarning(),
          })}
        </div>
      </div>
      <div className="row flex-row align-items-end">
        <div className="col-md-2">
          {renderTextField({
            name: "working_days",
            label: "Total Days",
          })}
        </div>
        <div className="col-md-3">
          {renderSelectField({
            name: "working_days_type",
            options: totalDaysConfg,
            styles: dropdownStyles,
          })}
        </div>

        <div className="col-md-2">
          {renderTextField({
            name: "maintenance_period",
            label: "Maintenance Period",
          })}
        </div>
        <div className="col-md-3">
          {renderSelectField({
            name: "maintenance_period_type",
            options: maintenancePeriodOptions,
            styles: dropdownStyles,
          })}
        </div>
        {formData.maintenance_period_type === maintenancePeriodCustomValue && (
          <div className="col-md-2">
            {renderTextField({
              name: "maintenance_period_other",
              label: `Custom`,
            })}
          </div>
        )}
      </div>
      <div className="row flex-row align-items-end">
        <div className="col-md-4">
          {renderTextField({
            name: "contract_turnaround_time",
            label: "Contract Turnaround Time",
          })}
        </div>
        <div className="col-md-3">
          {renderSelectField({
            name: "contract_turnaround_type",
            options: contractTurnaroundTimeOptions,
            styles: dropdownStyles,
          })}
        </div>
        {formData.contract_turnaround_type === contractTurnaroundTimeValue && (
          <div className="col-md-4">
            {renderTextField({
              name: "contract_turnaround_time_other",
              label: `Custom`,
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectDates;

ProjectDates.propTypes = {
  renderTextField: PropTypes.func.isRequired,
  renderSelectField: PropTypes.func.isRequired,
  formData: PropTypes.object,
};
