import React from "react";
import useProvideDailyFieldLogs from "./useProvideDailyFieldLogs";
import dailyFieldLogsContext from "./dailyFieldLogsContext";

function ProvideDailyFieldLogs({ children }) {
  const dailyFieldLogs = useProvideDailyFieldLogs();
  return <dailyFieldLogsContext.Provider value={dailyFieldLogs}>{children}</dailyFieldLogsContext.Provider>;
}

export default ProvideDailyFieldLogs;
