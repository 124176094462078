import { projectApi, useFileUpload, useAuth } from "component-library";

const getAddObject = (bidId, e, file, fileType, parentId, todoId, folderName) => {
  const attachment = {
    data: e.target.result,
    name: file.path,
    size: file.size,
    type: file.type,
    file_type: fileType,
  };

  const addObj = {
    project_id: bidId,
  };

  if (!!folderName) {
    addObj.folder = folderName;
  }

  if (parentId) {
    addObj.existing_id = parentId;
    attachment.parent_id = parentId;
  }

  if (todoId) addObj.todo_id = todoId;

  return { ...addObj, attachment };
};

const useProjectFileUpload = ({ bidId, setLoading, setPercentComplete, todoId }) => {
  const { user } = useAuth();
  const { uploadFile, updatedData, error } = useFileUpload({
    attachmentApi: projectApi,
    getAddObject,
    objectId: bidId,
    setLoading,
    setPercentComplete,
    todoId,
    userId: user.id,
  });

  return {
    uploadFile,
    updatedData,
    error,
  };
};

export default useProjectFileUpload;
