import React, { useEffect } from "react";
import Select from "react-select";
import { useApi, dropdownStylesWithWidth, projectApi } from "component-library";

const PMSelector = ({ selected = [], setSelected, pmOptions, setPmOptions, loading }) => {
  const { data, request } = useApi(projectApi.getProjectRoleOptions);

  useEffect(() => {
    request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!data?.users) {
      setPmOptions(
        data.users[`project-managers`].map((c) => ({ value: c.id, label: `${c.first_name} ${c.last_name}` }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="me-2">
      <Select
        name="project managers"
        isSearchable={true}
        placeholder={"Filter by PM"}
        hideSelectedOptions={false}
        onChange={(sel) => setSelected(sel?.value || "")}
        styles={dropdownStylesWithWidth(215)}
        options={pmOptions}
        isClearable={true}
        value={pmOptions.find((pm) => pm.value === selected)}
        isDisabled={!!loading}
        aria-label="filter by project managers"
      />
    </div>
  );
};

export default PMSelector;
