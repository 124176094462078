import React, { useEffect, useState } from 'react';
import Alert from "../../../components/Alert";
import useProject from "../../hooks/useProject";

const MissingProjectDates = () => {
  const { project } = useProject();
  const [show, setShow] = useState();

  const shouldShow = () => {
    let infoMissing = false;
    if (
      (!project.early_start_date &&
      !project.late_start_date) ||
      !project.completion_date
    ) infoMissing = true;
    return infoMissing;
  };

  useEffect(() => {
    setShow(shouldShow());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return !!show ? (
    <Alert
      title="You haven't set Project Dates yet"
      message="Add project dates in the project information settings"
      linkText="Review Now"
      linkTo={`/project/${project.id}/settings`}
    />
  ) : null;
}

export default MissingProjectDates;