import React from "react";
import { AlertPopup } from "component-library";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import { BeatLoader } from "react-spinners";
import { ArrowRightCircle } from "react-feather";
import Note from "../../../../../../components/FieldNoteAccordion/Note";
import AddProjectNoteModal from "../../../../AddProjectNoteModal";
import useNotes from "./useNotes";
import PropTypes from "prop-types";
import useResourceManagement from "../../../../../../_resourceManagement/context/useResourceManagement";
import useProject from "../../../../../hooks/useProject";
import DailyAccordion from "../components/DailyAccordion";

const Notes = () => {
  const { project } = useProject();
  const { dateRange } = useResourceManagement();
  const { notes, active, setActive, setNotes, addingNote, setAddingNote, notesError, notesLoading } = useNotes();

  const headerContent = (
    <h2 className={`${styles.title} mb-0`}>
      Notes{" "}
      {!!notesLoading ? (
        <BeatLoader size={10} color={"#0071ce"} />
      ) : (
        <>
          {!!notesError ? (
            <span className="ms-1">
              <AlertPopup error={notesError} />
            </span>
          ) : (
            <>({notes?.length || 0})</>
          )}
        </>
      )}
    </h2>
  );

  const HeaderContent = () => (
    <div className={styles.notesHeader}>
      <div className="d-flex align-items-center">
        {!!notes?.length ? (
          <Button className="d-flex align-items-center" onClick={() => setActive(!active)} variant="link">
            {headerContent}
          </Button>
        ) : (
          <div className={styles.headerContent}>{headerContent}</div>
        )}

        {!!dateRange[0] && !dateRange[1] && (
          <Button variant="link" className={`${styles.link} ms-2`} onClick={() => setAddingNote(true)}>
            Add Note <ArrowRightCircle size={16} className="ms-1" color="#0071ce" />
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <div className="position-relative">
      <DailyAccordion
        headerContent={<HeaderContent />}
        active={active}
        setActive={setActive}
        loading={notesLoading}
        error={notesError}
      >
        <>
          {!!notes?.length ? (
            notes?.map((note, index) => (
              <div key={index}>
                <Note
                  project={project}
                  note={note}
                />
              </div>
            ))
          ) : (
            <>
              {!notesError && !notesLoading && (
                <div>
                  <span className={styles.noNotes}>No notes found</span>
                </div>
              )}
            </>
          )}
        </>
      </DailyAccordion>
      {!!dateRange[0] && !dateRange[1] && !!addingNote && (
        <AddProjectNoteModal
          notes={notes}
          showModal={addingNote}
          onHide={() => setAddingNote()}
          setNotes={setNotes}
          date={dateRange[0]}
        />
      )}
    </div>
  );
};

Notes.propTypes = {
  selectedForemanIds: PropTypes.array,
};

export default Notes;
