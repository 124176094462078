import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import DailyAccordion from "../components/DailyAccordion";
import Foreman from "./Foreman";
import { SelectAll } from "component-library";

const Foremen = ({
  foremen,
  selectedForemenIds,
  setSelectedForemenIds,
  foremenError,
  foremenLoading,
  selectedForemen,
  setSelectedForemen,
  handleForemanToggle,
  active,
  setActive,
}) => {

  const HeaderContent = () => (
    <>
      <h2 className={styles.title}>
        Foremen
        <span className="ms-2">({foremen ? foremen.length : 0})</span>
      </h2>
    </>
  );

  return (
    <DailyAccordion
      headerContent={<HeaderContent />}
      active={active}
      setActive={setActive}
      loading={foremenLoading}
      error={foremenError}
      wrapperStyles={styles.wrapperNoPadding}
    >
      <div>
        <div>
          {!!foremen?.length ?
            foremen.map((foreman, index) => (
              <Foreman
                key={index}
                foreman={foreman}
                selectedForemanIds={selectedForemenIds}
                handleForemanToggle={handleForemanToggle}
              />
            )) : (
              <div className="ms-2">No foremen found</div>
            )}
        </div>
        {!!foremen?.length && (
          <div className={styles.selectAll}>
            <SelectAll
              selectedIds={selectedForemenIds}
              setSelectedIds={setSelectedForemenIds}
              items={foremen}
              selectedItems={selectedForemen}
              setSelectedItems={setSelectedForemen}
              small={true}
              field="foreman_id"
            />
            Show All
          </div>
        )}
      </div>
    </DailyAccordion>
  );
};

Foremen.propTypes = {
  foremen: PropTypes.array,
  selectedForemenIds: PropTypes.array,
  setSelectedForemenIds: PropTypes.func,
  foremenError: PropTypes.any,
  foremenLoading: PropTypes.bool,
};

export default Foremen;
