const getCategoryColor = (category) => {
  switch (category) {
    case "Jack & Bore/Directional Drilling":
      return "#E39200";
    case "Box Culvert - Precast":
      return "#5B47AB";
    case "Box Culvert - Cast in Place":
      return "#877EC8";
    case "Building":
      return "#51B2BE";
    case "Reconstruction/City":
      return "#488E0C";
    case "Utilities":
      return "#C9A600";
    case "Misc.":
      return "#1B8B97";
    case "Demolition":
      return "#BA4B00";
    case "Grading/Dirt":
      return "#D18700";
    case "Lift Stations":
      return "#0062B2";
    case "Water Treatment Facilities":
      return "#66AAE2";
    case "Site Development":
      return "#5DB115";
    case "Crushing":
      return "#E95E00";
    default:
      return "#0071CE";
  }
};

export default getCategoryColor;
