import React, { useState } from "react";
import { Collapse, Alert } from "react-bootstrap";
import TimesheetHeader from "./TimesheetHeader";
import TimeEntries from "./TimeEntries";
import { Loader } from "component-library";
import PropTypes from "prop-types";
import useUpdateTimesheet from "../../hooks/useUpdateTimesheet";
import styles from "./styles.module.scss";

const TimeSheet = ({
  timesheet,
  config,
  tasks,
  editing,
  projectsList,
  setTimesheet,
  foremanId,
  project,
  fieldLog,
  overAllocatedTimeSheets,
  setOverAllocatedTimeSheets,
  error,
  setFieldLog,
}) => {
  const [open, setOpen] = useState(false);

  const { updateTimeIn, updateTimeOut, updateTimesheet, timeIn, timeOut, setTimeIn, setTimeOut, loading, updateError } =
    useUpdateTimesheet({
      timesheet,
      setTimesheet,
      overAllocatedTimeSheets,
      setOverAllocatedTimeSheets,
      logDate: fieldLog?.date,
    });

  return (
    <div className="position-relative">
      <Loader loading={!!loading} />

      <div className={`container-fluid py-1 ${styles.timesheetWrapper}`}>
        <TimesheetHeader
          logDate={fieldLog.date}
          timesheet={timesheet}
          setTimesheet={setTimesheet}
          updateTimeIn={updateTimeIn}
          updateTimeOut={updateTimeOut}
          timeIn={timeIn}
          timeOut={timeOut}
          open={open}
          setOpen={setOpen}
          editing={editing}
          overAllocatedTimeSheets={overAllocatedTimeSheets}
          foremanId={foremanId}
          setTimeIn={setTimeIn}
          setTimeOut={setTimeOut}
        />

        <Collapse in={open}>
          <div>
            <TimeEntries
              timesheet={timesheet}
              setTimesheet={setTimesheet}
              updateTimesheet={updateTimesheet}
              tasks={tasks}
              config={config}
              editing={editing}
              projectsList={projectsList}
              foremanId={foremanId}
              project={project}
              overAllocatedTimeSheets={overAllocatedTimeSheets}
              setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
              loading={loading}
              fieldLog={fieldLog}
              setFieldLog={setFieldLog}
            />
          </div>
        </Collapse>
      </div>

      {(error || updateError) && <Alert variant="danger">{error || updateError}</Alert>}
    </div>
  );
};

TimeSheet.propTypes = {
  timesheet: PropTypes.object,
  config: PropTypes.object,
  tasks: PropTypes.array,
  editing: PropTypes.bool,
  projectsList: PropTypes.array,
  setTimesheet: PropTypes.func,
  foremanId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  project: PropTypes.object,
  fieldLog: PropTypes.object,
  overAllocatedTimeSheets: PropTypes.array,
  setOverAllocatedTimeSheets: PropTypes.func,
};

export default TimeSheet;
