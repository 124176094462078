import React from "react";
import { DateRangeSelector, BoomSelector } from "component-library";
import { getMaxDate } from "../../utils/getMaxDate";

function CrewDetailFilters({
  selectedProject,
  setSelectedProject,
  projectsList,
  projectsLoading,
  selectedForeman,
  setSelectedForeman,
  loading,
  dateRange,
  setDateRange,
  foremenList,
  projectId,
}) {
  return (
    <div className="d-flex flex-row justify-content-between mb-3 w-100">
      <DateRangeSelector
        dateRange={dateRange}
        setDateRange={setDateRange}
        disabled={!!loading}
        maxDate={getMaxDate(dateRange, 14)}
      />
      <div className="d-flex flex-row">
        <BoomSelector
          selected={selectedForeman}
          setSelected={setSelectedForeman}
          options={foremenList}
          loading={loading}
          placeholder="Filter by Foreman"
        />
        {!projectId && (
          <BoomSelector
            selected={selectedProject}
            setSelected={setSelectedProject}
            options={projectsList}
            loading={projectsLoading || loading}
            placeholder="Filter by Project"
          />
        )}
      </div>
    </div>
  );
}

export default CrewDetailFilters;
