import CreatePO from "../PurchaseOrders/CreatePO";

const quoteListConfig = [
  {
    name: "id",
    label: "Quote #",
    sort: "numeric",
    width: "16.3%",
    noLink: true,
  },
  {
    name: "supplier_name",
    label: "Supplier",
    sort: "alpha",
    width: "57%",
    isTitle: true,
    noLink: true,
  },
  {
    name: "amount",
    label: "Amount",
    sort: "numeric",
    width: "13.6%",
    isCurrency: true,
    noLink: true,
  },
  {
    name: "create_po",
    render: CreatePO,
  },
];

export default quoteListConfig;
