import React from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styles from "./styles.module.scss";

const CashflowChart = ({ job: { cashflow } }) => {
  if (!cashflow || !cashflow.length) return <h3>Unavailable</h3>;
  let chartData = {};
  let billed = 0;
  let spend = 0;
  cashflow.forEach((item) => {
    item.spend.forEach((a) => {
      if (chartData[a.description]) {
        chartData[a.description] += parseInt(a.amount);
      } else {
        chartData[a.description] = parseInt(a.amount);
      }
      spend += parseFloat(a.amount);
    });
    billed += parseFloat(item.income);
  });

  const net = billed - spend;
  return (
    <div>
      <div className={styles.balance}>
        {`${new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(net)}`}
      </div>
      <figure>
        <ResponsiveContainer width="100%" height={80}>
          <BarChart
            data={[{ BILLED: billed }, chartData]}
            stackOffset="sign"
            layout="vertical"
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barGap={0}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={true}
              horizontal={false}
            />
            <XAxis
              type="number"
              axisLine={false}
              tickLine={false}
              orientation="top"
              tickFormatter={(t) =>
                `${new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }).format(parseFloat(t / 1000))}k`
              }
            />
            <YAxis hide type="category" axisLine={false} tickLine={false} />
            <Tooltip
              labelFormatter={(l) => ""}
              formatter={(value) =>
                `${new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(parseFloat(value))}`
              }
              wrapperStyle={{ zIndex: 1000}}
            />
            <Bar dataKey="BILLED" fill="#34D27C" barSize={25} stackId="stack" />

            <Bar
              dataKey="BOND/INS/ADMIN"
              fill="#D85F00"
              stackId="stack"
              barSize={25}
            />
            <Bar dataKey="BURDEN" fill="#F5A611" stackId="stack" barSize={25} />
            <Bar dataKey="LABOR" fill="#F3CB0D" stackId="stack" barSize={25} />
            <Bar
              dataKey="MATERIALS"
              fill="#1194B0"
              stackId="stack"
              barSize={25}
            />
            <Bar
              dataKey="OWNED EQUIPMENT"
              fill="#1F98FB"
              stackId="stack"
              barSize={25}
            />
            <Bar
              fill="#0071CE"
              dataKey="RENTED EQUIPMENT"
              stackId="stack"
              barSize={25}
            />
            <Bar
              fill="#A564BE"
              dataKey="SUBCONTRACTORS"
              stackId="stack"
              barSize={25}
            />
            <Bar
              fill="#EF5672"
              dataKey="TESTING"
              stackId="stack"
              barSize={25}
            />
          </BarChart>
        </ResponsiveContainer>
      </figure>
    </div>
  );
};

export default CashflowChart;
