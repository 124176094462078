import { useState, useEffect } from "react";
import adminApi from "./../../api/admin";
import rolesApi from "./../../api/roles";
import { useApi, handleError, userApi, Loader, authApi } from "component-library";
import { useFormControl, Avatar } from "component-library";
import { schema, initialData } from "./../../schema/user";
import { Alert, Button } from "react-bootstrap";
import { RefreshCw, Send } from "react-feather";
import styles from "./styles.module.scss";
import useUserAdmin from "../pages/UserAdmin/hooks/useUserAdmin";

const useEditUser = () => {
  const {
    users,
    setUsers,
    setChangePassword,
    setChangedPasswordMessage,
    changedPasswordMessage,
    editUser,
    setEditUser,
  } = useUserAdmin();

  const [error, setError] = useState();
  const [roles, setRoles] = useState([]);
  const [passwordSuccess, setPasswordSuccess] = useState();
  const [thumbnail, setThumbail] = useState();
  const [file, setFile] = useState();

  const { request: uploadAvatarRequest, error: uploadAvatarError } = useApi(userApi.portalAvatarAdd);

  const {
    request: rolesRequest,
    data: rolesData,
    error: rolesError,
    loading: rolesLoading,
  } = useApi(rolesApi.getRoles);

  const resizeImage = (file) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const elem = document.createElement("canvas");
          const height = 800;
          const scaleFactor = height / img.height;
          const width = img.width * scaleFactor;
          elem.width = width;
          elem.height = height;

          const ctx = elem.getContext("2d");
          // img.width and img.height will contain the original dimensions
          ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
          ctx.canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            0.8
          );
        };
      };
    });

  const handleSingleFileUpload = (file) => {
    const reader = new FileReader();

    resizeImage(file).then((resizedImage) => {
      reader.readAsDataURL(resizedImage);
    });

    reader.onload = (e) => {
      let newFile = {
        data: e.target.result,
        name: file.name,
        size: file.size,
        type: file.type,
        file_type: [],
      };
      setFile(newFile);
    };
    reader.readAsDataURL(file);
  };

  const {
    request: passwordRequest,
    data: passwordData,
    loading: passwordLoading,
    error: requestResetError,
  } = useApi(authApi.forgotPassword);

  const onSubmit = async (data, setLoading) => {
    const processedFields = { ...data, id: editUser.id, roles: data?.roles };
    let avatar = null;
    try {
      const result = await adminApi.updateUser(processedFields);

      if (file) {
        const result = await uploadAvatarRequest(editUser.id, file);
        avatar = result?.responseData?.user?.avatar;
      }
      const resultError = handleError.isError(result);
      if (resultError) {
        setError(resultError);
        setLoading(false);
        setFields(editUser);
      } else if (result && result.data && result.data.user) {
        setUsers(
          users.map((user) => (user.id === result.data.user.id ? { ...result.data.user, avatar: avatar } : user))
        );

        if (!uploadAvatarError) {
          setEditUser();
        }
        setLoading(false);
      } else {
        setError("Something went wrong.");
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const { handleSubmit, renderSubmitButton, renderTextField, renderCheckbox, renderMultiSelect, setFields } =
    useFormControl({
      schema,
      onSubmit,
      initialData,
    });

  const handlePasswordRequest = (email) => {
    passwordRequest({ email });
  };

  useEffect(() => {
    let ignore = false;
    if (ignore) return;
    rolesRequest();

    return () => (ignore = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!rolesData?.success) {
      setRoles(
        rolesData?.roles.map((role) => ({
          label: role.name,
          value: role.name,
        }))
      );
    }
  }, [rolesData]);

  useEffect(() => {
    if (!!editUser) {
      const formattedData = {
        email: editUser.email,
        first_name: editUser.first_name,
        last_name: editUser.last_name,
        roles: editUser?.roles.map((role) => {
          return role.name;
        }),
        locked_out: editUser.locked_out,
        password: "",
        password_confirmation: "",
      };
      setFields(formattedData);
      setPasswordSuccess();
      setThumbail(editUser.avatar);
    } else {
      setFields(initialData);
      setPasswordSuccess();
      setThumbail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUser]);

  useEffect(() => {
    if (passwordData) {
      setPasswordSuccess("Password request sent");
    }
  }, [passwordData]);

  const selectFile = (e) => {
    e.preventDefault();
    const avatarFile = e.target.files[0];
    handleSingleFileUpload(avatarFile);
    setThumbail(URL.createObjectURL(avatarFile));
  };

  const selectAvatar = (e) => {
    e.preventDefault();
    document.getElementById("avatar").click();
  };

  const fields = (
    <div className="position-relative">
      <Loader loading={!!rolesLoading} />
      {(!!rolesError || !!error) && <Alert variant="danger">{rolesError || error}</Alert>}
      {uploadAvatarError && <Alert variant="danger">{uploadAvatarError}</Alert>}
      {!!changedPasswordMessage && (
        <Alert onClick={() => setChangedPasswordMessage(null)} variant="success" dismissible>
          {changedPasswordMessage}
        </Alert>
      )}
      <div className="row">
        <div className="col d-flex align-items-center">
          <input
            type="file"
            onChange={(e) => selectFile(e)}
            accept={"image/png, image/jpeg, image/jpg"}
            hidden
            id="avatar"
            name="avatar"
          />
          <button className={styles.avatarButton} onClick={selectAvatar}>
            <Avatar
              user={
                thumbnail
                  ? {
                      ...editUser,
                      avatar: thumbnail,
                    }
                  : editUser
              }
              size="xxl"
              hasColor
            />
          </button>
          <label htmlFor="avatar" className={styles.avatarLabel}>
            Upload Photo
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {renderTextField({
            name: "first_name",
            label: "First Name",
            autoFocus: true,
          })}
        </div>
        <div className="col-md-6">
          {renderTextField({
            name: "last_name",
            label: "Last Name",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {renderTextField({
            name: "email",
            label: "Email",
            autoFocus: true,
            placeholder: "Email Address",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {renderMultiSelect({
            label: "Roles",
            name: "roles",
            options: roles,
          })}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {renderCheckbox({
            name: "locked_out",
            label: "Revoke Login Ability",
            isToggle: true,
          })}
        </div>
      </div>
      <div className="row mt-3 mb-3">
        <div className="col">
          {!!passwordSuccess && <Alert variant="success">{passwordSuccess}</Alert>}
          {!!requestResetError && <Alert variant="danger">{requestResetError}</Alert>}
          <div className="d-flex align-items-center">
            <Button
              className="med d-flex align-items-center"
              variant="link"
              disabled={passwordLoading}
              onClick={() => handlePasswordRequest(editUser?.email)}
            >
              <Send className="me-1" size={16} />
              Send Password Reset Request
            </Button>
            <Button
              variant="link"
              className="d-flex align-items-center ms-3 med"
              onClick={() => setChangePassword(true)}
            >
              <RefreshCw className="me-1" size={16} />
              Reset Password
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  return {
    editUser,
    setEditUser,
    onSubmit,
    handleSubmit,
    fields,
    renderSubmitButton,
    setFields,
  };
};

export default useEditUser;
