import React from "react";
import { useAuth } from "component-library";

const Categories = ({ data }) => {
  const { config } = useAuth();
  const categoriesFiltered = config?.company_categories.filter((single) => data.company_categories.includes(single.id));

  return data.company_categories ? (
    <div className="d-flex flex-row">
      {categoriesFiltered.map(
        (single, index) => ` ${single.name}${index === data.company_categories.length - 1 ? "" : ", "}`
      )}
    </div>
  ) : null;
};

export default Categories;
