import React, { useEffect } from "react";
import { useFormControl, handleError, useApi, projectApi } from "component-library";
import { useParams, useNavigate } from "react-router-dom";
import { File } from "react-feather";
import styles from "./styles.module.scss";
import { schema, initialData } from "./renameFile";
import useProjectDocuments from "../../ProjectDocuments/context/useProjectDocuments";

const useRenameFile = ({ asset, setShowModal }) => {
  const { displayMessage, attachmentsRequest } = useProjectDocuments();
  const { id, assetId } = useParams();
  const history = useNavigate();
  const {
    request: renameFileRequest,
    data: renameFileData,
    error: renameFileError,
    loading: renameFileLoading,
  } = useApi(projectApi.updateAttachmentInfo);

  const onSubmit = async (formData, formLoading) => {
    formLoading(true);
    let requestParams = {
      id: asset.id || assetId,
      file_name: formData.fileName,
      folder: asset.folder,
    };
    try {
      await renameFileRequest(requestParams);
    } catch (error) {
      handleError(error);
    }
    formLoading(false);
  };

  const { data, handleSubmit, renderSubmitButton, renderTextField } = useFormControl({
    onSubmit,
    schema,
    initialData,
  });

  useEffect(() => {
    if (!!renameFileData?.success) {
      displayMessage({
        title: "File Renamed",
        content: `File renamed from ${asset.file_name} to ${renameFileData?.file?.file_name}`,
        id: "file-renamed",
        variant: "success",
      });
      if (!!assetId) {
        history(`/project/${id}/documents/asset/${renameFileData?.file?.id}`);
      }
      setShowModal(false);
      attachmentsRequest(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renameFileData]);

  const fields = (
    <div>
      <div className="d-flex mb-5">
        <div>
          <h3 className={`${styles.currentTitle} m-0`}>Current Title</h3>
          <div className={styles.currentTitleWrapper}>
            <File color="#0071ce" size={16} /> <span className={styles.currentTitle}>{asset?.file_name}</span>
          </div>
        </div>
      </div>
      <div>
        {renderTextField({
          name: "fileName",
          label: "New Name",
          subLabel: "Enter your New File Name here",
          autoFocus: true,
        })}
      </div>
    </div>
  );

  return {
    fields,
    handleSubmit,
    renderSubmitButton,
    renameFileError,
    renameFileLoading,
    data,
  };
};

export default useRenameFile;
