import React from "react";
import { Button, Modal } from "react-bootstrap";
import styles from "./styles.module.scss";
import { X } from "react-feather";
import { BeatLoader } from "react-spinners";

const ModalWrapper = ({ loading, data, onHide, title, children, modalSize = "lg", FooterContent }) => {
  return (
    <Modal
      show={!!data}
      centered
      onHide={() => {
        onHide();
      }}
      animation={false}
      className="text-gray2 p-0 fullWidthContent"
      backdropClassName={styles.backdrop}
      dialogClassName={styles.telematicsModal}
      contentClassName={styles.modalContent}
      size={modalSize}
    >
      <Modal.Header className={`${styles.header}`}>
        {!!loading ? (
          <BeatLoader size={10} color={"#ffffff"} />
        ) : (
          <h1 className={`${styles.titleText} med`}>{title}</h1>
        )}
        <Button
          variant="link"
          onClick={() => {
            onHide();
          }}
          className="p-0"
        >
          <X size={25} color="#ffffff" />
        </Button>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>{children}</Modal.Body>
      {!!FooterContent && (
        <Modal.Footer>
          <FooterContent />
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalWrapper;
