import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalWrapper from "../../../components/ModalWrapper";

const PhotoModal = ({ data, onHide }) => {
  const [photos, setPhotos] = useState([]);
  const [sliderActive, setSliderActive] = useState(false);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    if (data) {
      let photosArray = [];
      for (let i = 1; i <= 4; i++) {
        if (!!data[`photo_url_0${i}`]) {
          photosArray = [...photosArray, data[`photo_url_0${i}`]];
        }
      }
      setPhotos(photosArray);
    }
  }, [data]);
  return (
    <ModalWrapper data={data} title="Equipment Photos" onHide={() => onHide()} size="xl">
      {!!sliderActive ? (
        <div className={styles.wrapper}>
          <div className={{ flex: 1, alignItems: "center" }}>
            <Slider {...settings}>
              {photos.map((photo, index) => (
                <div key={index} className={styles.slide}>
                  <img alt="" src={photo} className={styles.photo} loading="lazy" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.subheader}>
            <div className={`${styles.equipmentName} d-flex align-items-center pe-4 xl`}>
              {data?.equipment_id} {data?.description}
            </div>
          </div>
          <div className={styles.body}>
            {!!photos?.length &&
              photos.map((photo, index) => (
                <Button
                  onClick={() => setSliderActive(true)}
                  variant="link"
                  className={styles.imageWrapper}
                  key={index}
                >
                  <img className={styles.image} src={photo} alt="" loading="lazy" />
                </Button>
              ))}
          </div>
        </>
      )}
    </ModalWrapper>
  );
};

export default PhotoModal;
