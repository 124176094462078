import formatTime from "../../../utils/formatTime";
import dateTimeComparator from "../../../utils/dateTimeComparator";
import ClockOut from "../../sharedColumns/columnRenders/ClockOut";

const time_out = {
  headerName: "Clock Out",
  field: "clock_out",
  maxWidth: 132,
  comparator: dateTimeComparator,
  valueGetter: ({ data }) => (data.clock_out ? formatTime(data.clock_out) : null),
  cellRenderer: ClockOut
};

export default time_out;
