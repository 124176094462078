import ProvideDailyFieldLogs from "./context/ProvideDailyFieldLogs";
import DailyFieldLog from "./DailyFieldLog";

const DailyFieldLogPage = () => {
  return (
    <ProvideDailyFieldLogs>
      <DailyFieldLog />
    </ProvideDailyFieldLogs>
  );
};

export default DailyFieldLogPage;
