import React from "react";
import PropTypes from "prop-types";
import useEstimateConversion from "../useEstimateConversion";
import styles from "./styles.module.scss";
import { BeatLoader } from "react-spinners";

const PhaseColumns = ({ item, qtyTotal, phaseIds, getPhaseQty, setPhaseQty, setUpdatePhaseQty, updateLoading }) => {
  const { viewPhases, editTasks, phases, updatingColumn, setUpdatingColumn, canEdit } = useEstimateConversion();

  return (
    <>
      {!editTasks && !!viewPhases && (
        <>
          {!!phases &&
            !!phases.length &&
            phases.map((phase) => (
              <td
                key={phase.id}
                className={`
                  ${styles.phaseCell}
                  ${
                    qtyTotal <= item.bid_quantity && !!phaseIds && !!phaseIds.length && !phaseIds.includes(phase.id)
                      ? styles.empty
                      : ""
                  }
                  text-nowrap ps-1 pt-0 border-top-0`}
              >
                <div className="form-group mb-0 position-relative">
                  {!!phaseIds && !!phaseIds.length && !!phaseIds.includes(phase.id) && (
                    <>
                      {!!updateLoading &&
                        !!updatingColumn &&
                        updatingColumn.id === phase.id &&
                        updatingColumn.item === item.id && (
                          <div className="loaderWrapper">
                            <BeatLoader size={10} color={"#0071ce"} />
                          </div>
                        )}
                      {["item", "material", "task"].includes(item.entity_type) && (
                        <input
                          disabled={!canEdit}
                          defaultValue={getPhaseQty(phase)}
                          className={`${styles.phaseInput} form-control`}
                          onBlur={(e) => {
                            setUpdatingColumn({ id: phase.id, item: item.id });
                            setPhaseQty(e.target.value, phase);
                            setUpdatePhaseQty(true);
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </td>
            ))}
        </>
      )}
    </>
  );
};

PhaseColumns.propTypes = {
  item: PropTypes.object.isRequired,
};

export default PhaseColumns;
