import React from "react";
import Chiclet from "./Chiclet";
import PerJobItemChart from "./PerJobItemChart";
import styles from "./styles.module.scss";
import getJobName from "./getJobName";
import FooterLinkToProject from "./FooterLinkToProject";

const PerJobItem = ({ job }) => {
  return (
    <Chiclet>
      <div>
        <h1 className={styles["job-title"]}>{getJobName(job)}</h1>
        <PerJobItemChart job={job} />
      </div>
      <FooterLinkToProject job={job} />
    </Chiclet>
  );
};

export default PerJobItem;
