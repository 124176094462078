import Date from "../columnRenders/Date";

const date = {
  headerName: "Date",
  field: "date",
  resizable: true,
  cellRenderer: Date,
};

export default date;
