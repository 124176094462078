import React from "react";
import Weather from "../Weather";
import Section from "../Section";
import styles from "./styles.module.scss";
import Photos from "../Photos";
import delayReasons from "../../WeeklyReportEdit/ReportDates/DateFields/Delay/delayReasons";

const ReportDayBody = ({ item, weather, weatherLoading }) => {
  const reasonForDelay = delayReasons.find((opt) => opt.value === item?.delayed_reason);

  return (
    <div
      className={styles.reportDayBody}
      style={{
        backgroundColor: item?.working_day_flag ? "#FFFFFF" : "#F7F7F7",
        border: item?.working_day_flag ? "1px solid #D3D3D3" : "none",
      }}
    >
      <Weather day={item} weather={weather} weatherLoading={weatherLoading} />
      <div className={styles.content}>
        <Section header="Critical Path" body={item?.critical_path} />
        <Section header="Description of work" body={item?.description} />
        <Section header="Delay" body={reasonForDelay?.label} />
        {!!item?.subcontractors?.length && (
          <Section
            header="Subcontractors"
            body={
              <div>
                {item?.subcontractors?.map((contractor, index) => (
                  <span key={index}>
                    {!!contractor?.company ? contractor.company.name : contractor?.location_name}
                    {item.subcontractors.length - 1 !== index && ", "}
                  </span>
                ))}
              </div>
            }
          />
        )}
        <Section header="Note(s)" body={item?.note} />
        {!!item?.files?.length && <Section header="Photo(s)" body={<Photos photos={item?.files} />} />}
      </div>
    </div>
  );
};

export default ReportDayBody;
