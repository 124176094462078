import { useEffect, useState } from "react";
import { projectApi, useApi, useAuth } from "component-library";
import { useParams } from "react-router-dom";

const useProvideBid = () => {
  const { id } = useParams();
  const [bid, setBid] = useState(null);
  const [archived, setArchived] = useState(false);
  const { config } = useAuth();
  const { request, data, loading, error } = useApi(projectApi.getProject);
  const [estimatorName, setEstimatorName] = useState(null);

  useEffect(() => {
    if (id) {
      request(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data?.project) {
      setBid(data.project);
    }
  }, [data]);

  useEffect(() => {
    if (bid) {
      const estimator = config && config.estimators.find((estimator) => estimator.id === bid.estimator_id);
      const estimatorName = !!estimator ? `${estimator.first_name} ${estimator.last_name}` : "";
      setEstimatorName(estimatorName);
      setArchived(bid.bid_read_low === 0 && bid.bid_closed === 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid]);

  return {
    bid,
    loading,
    error,
    setBid,
    archived,
    estimatorName,
  };
};

export default useProvideBid;
