import React from "react";
import ProjectNote from "../../../../components/ProjectNote";
import PhotosModal from "../../../../components/PhotosModal";
import { PlusCircle } from "react-feather";
import { Button } from "react-bootstrap";
import SelectFieldNotesModal from "./SelectFieldNotesModal";
import useReportFieldNotes from "./useReportFieldNotes";
import styles from "./styles.module.scss";

const FieldNotes = ({ project, reportNotes, report, handleUpdateNotes, handleRemoveNote }) => {
  const {
    photoModalOpen,
    togglePhotoModal,
    photos,
    handleSetPhotosForViewModal,
    updatingFieldNotes,
    toggleUpdateNotesModal,
    handleSaveModalSelections,
  } = useReportFieldNotes({ handleUpdateNotes, reportNotes, handleRemoveNote });

  return (
    <>
      <section className={styles.notesSidebarWrapper}>
        <div className={`${styles.notesTitle} d-flex justify-content-between`}>
          <h3 className="font-weight-bold">Field Note(s)</h3>
          <Button variant="link" className="ms-2 align-self-center" onClick={() => toggleUpdateNotesModal()}>
            <PlusCircle size={16} color="#A7A7A7" />
          </Button>
        </div>
        <div className="d-flex">
          <div className="w-100">
            {!!reportNotes?.length &&
              reportNotes.map((note) => (
                <ProjectNote
                  project={project}
                  photos={photos}
                  setPhotos={handleSetPhotosForViewModal}
                  note={note}
                  key={note.id}
                  photoModalOpen={photoModalOpen}
                  setPhotoModalOpen={togglePhotoModal}
                />
              ))}
          </div>
          <PhotosModal photos={photos} open={photoModalOpen} setOpen={togglePhotoModal} />
        </div>
      </section>
      {!!updatingFieldNotes && (
        <SelectFieldNotesModal
          report={report}
          notes={reportNotes}
          updatingFieldNotes={updatingFieldNotes}
          onHide={() => toggleUpdateNotesModal()}
          handleSaveModalSelections={handleSaveModalSelections}
        />
      )}
    </>
  );
};

export default FieldNotes;
