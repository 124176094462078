import { BoomSelector, FilterSearch, DateRangeSelector } from "component-library";
import useResourceManagement from "../context/useResourceManagement";
import { getMaxDate } from "../../utils/getMaxDate";

const CrewLogFilters = ({
  searchFunction,
  projectsList,
  projectsLoading,
  selectedProject,
  setSelectedProject,
  selectedForeman,
  foremenList,
  setSelectedForeman,
  projectId,
  loading,
  dateRange,
  setDateRange,
}) => {
  const { q } = useResourceManagement();

  return (
    <div className="d-flex justify-content-between mt-3">
      <div className="d-flex">
        <DateRangeSelector
          dateRange={dateRange}
          setDateRange={setDateRange}
          disabled={!!projectsLoading || !!loading}
          maxDate={getMaxDate(dateRange, 14)}
        />
      </div>
      <div className="d-flex">
        <BoomSelector
          selected={selectedForeman}
          setSelected={setSelectedForeman}
          options={foremenList}
          loading={projectsLoading}
          placeholder="Filter by Foreman"
        />
        {!projectId && (
          <BoomSelector
            selected={selectedProject}
            setSelected={setSelectedProject}
            options={projectsList}
            loading={projectsLoading}
            placeholder="Filter by Project"
          />
        )}
        <FilterSearch searchFunction={searchFunction} inline placeholder="Search..." initialValue={q} />
      </div>
    </div>
  );
};

export default CrewLogFilters;
