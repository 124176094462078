import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MinutesInput from "../../../../../_resourceManagement/FieldLogs/FieldLog/MinutesInput";
import styles from "./styles.module.scss";
import useUpdateTimeEntry from "../../../../../_resourceManagement/FieldLogs/hooks/useUpdateTimeEntry";
import Select from "react-select";
import { dropdownStylesWithWidthAndColor, AlertPopup, useAuth } from "component-library";
import { BeatLoader } from "react-spinners";
import PropTypes from "prop-types";

const Entry = ({
  entry,
  workDay,
  timesheet,
  setTimesheet,
  project,
  overAllocatedTimeSheets,
  setOverAllocatedTimeSheets,
  config,
}) => {
  const { userCan } = useAuth();
  const [canEdit, setCanEdit] = useState(false);
  const {
    updateTime,
    updateLaborType,
    updateEquipment,
    updateSecondaryEquipment,
    laborOptions,
    equipmentOptions,
    secondaryEquipmentOptions,
    error,
    loading,
  } = useUpdateTimeEntry({
    entry,
    timesheet,
    setTimesheet,
    overAllocatedTimeSheets,
    setOverAllocatedTimeSheets,
    config,
  });
  const dropdownColor = !!canEdit && !!project?.certified_payroll ? "#fff" : "#d3d3d3";

  useEffect(() => {
    let status;
    if (entry?.task_data?.work_day_status) {
      status = !["Exported", "In Progress"].includes(entry.task_data.work_day_status) ? true : false;
    }
    setCanEdit(status && userCan("payroll-review-time"));
  }, [userCan, entry]);

  return (
    <>
      <td>
        <div className="position-relative">
          {!!loading && (
            <div className="loaderWrapper">
              <BeatLoader size={10} color={"#0071ce"} />
            </div>
          )}
          {!!workDay && (
            <Link
              to={`/field-management/field-logs/${workDay?.id}/foreman/${entry?.task_data?.foreman_id}`}
              className={`${styles.taskName} btn-link`}
            >
              <span className="d-block text-limiter">{entry?.task_data?.task_name}</span>
            </Link>
          )}
          {!!error && <AlertPopup error={error} />}
        </div>
      </td>
      <td>
        {!!equipmentOptions?.length && (
          <Select
            styles={dropdownStylesWithWidthAndColor("auto", dropdownColor)}
            options={equipmentOptions}
            name="equipment_id"
            id="equipmentId"
            className={`${styles.select} flex-grow-1 me-2`}
            onChange={(e) => {
              updateEquipment(e.value);
            }}
            isDisabled={!(!loading && !!project && project.certified_payroll && !!canEdit)}
            value={equipmentOptions.find((e) => e.value === entry.equipment_id) || null}
          />
        )}
      </td>
      <td>
        {!!secondaryEquipmentOptions?.length && (
          <Select
            styles={dropdownStylesWithWidthAndColor("auto", dropdownColor)}
            options={secondaryEquipmentOptions}
            name="secondary_equipment_id"
            id="secondaryEquipmentId"
            className={`${styles.select} flex-grow-1 me-2`}
            onChange={(e) => {
              updateSecondaryEquipment(e.value);
            }}
            isDisabled={!(!loading && !!project && project.certified_payroll && !!canEdit)}
            value={secondaryEquipmentOptions.find((e) => e.value === entry.secondary_equipment_id) || null}
          />
        )}
      </td>
      <td>
        {!!laborOptions?.length && (
          <Select
            styles={dropdownStylesWithWidthAndColor("auto", dropdownColor)}
            options={laborOptions}
            name="labor_type_id"
            id="laborTypeId"
            className={`${styles.select} flex-grow-1 me-2`}
            onChange={(e) => {
              updateLaborType(e.value);
            }}
            isDisabled={!(!loading && !!project && project.certified_payroll && !!canEdit)}
            value={laborOptions.find((l) => l.value === entry.labor_id) || null}
          />
        )}
      </td>
      <td className="text-end">
        <span className="d-flex justify-content-end form-group text-end mb-0">
          <MinutesInput
            minutes={entry.minutes}
            setMinutes={(minutes) => {
              updateTime(minutes);
            }}
            canEdit={!loading && !!canEdit}
          />
        </span>
      </td>
    </>
  );
};

Entry.propTypes = {
  entry: PropTypes.object,
  workDay: PropTypes.object,
  timesheet: PropTypes.object,
  setTimesheet: PropTypes.func,
  project: PropTypes.object,
  overAllocatedTimeSheets: PropTypes.array,
  setOverAllocatedTimeSheets: PropTypes.func,
};

export default Entry;
