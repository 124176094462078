import { useState, useEffect } from "react";
import InteriorPage from "../../components/pageLayout/InteriorPage";
import { Users } from "react-feather";
import Submenu from "../Submenu";
import CrewLogFilters from "./CrewLogFilters";
import useCrewLogs from "./useCrewLogs";
import ReportingConsole from "../ReportingConsole";
import CrewLogListing from "./CrewLogListing";
import Pagination from "../../components/Pagination";
import { Alert } from "react-bootstrap";
import useResourceManagement from "../context/useResourceManagement";
import TwoWeekMessage from "../../_projects/project/FieldManagement/FieldLogs/TwoWeekMessage";

const CrewLogs = ({ projectId }) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const { dateRange, setDateRange, contextInitialLoad } = useResourceManagement();
  const {
    filteredCrewMembers,
    searchCrewMembers,
    loading,
    projectsList,
    projectsLoading,
    selectedProject,
    setSelectedProject,
    q,
    foremenList,
    selectedForeman,
    setSelectedForeman,
    columnDefs,
    consoleData,
    error,
    page,
    setPage,
    total,
  } = useCrewLogs({ initialLoad, projectId, dateRange });

  useEffect(() => {
    if ((!!initialLoad, !contextInitialLoad)) {
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, contextInitialLoad]);

  return (
    <InteriorPage
      error={error}
      navTitle={"Field Management"}
      navIcon={<Users color="#fff" size={14} />}
      pageTitle="Crew Timesheets"
      pageSubtitlePrimaryContent={() => <Submenu />}
    >
      <TwoWeekMessage dateRange={dateRange} />
      <CrewLogFilters
        searchFunction={searchCrewMembers}
        projectsList={projectsList}
        projectsLoading={projectsLoading}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        foremenList={foremenList}
        selectedForeman={selectedForeman}
        setSelectedForeman={setSelectedForeman}
        projectId={projectId}
        loading={loading}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <div className="position-relative">
        <ReportingConsole
          q={q}
          projectId={selectedProject}
          foremanId={selectedForeman}
          consoleData={consoleData}
          loading={loading}
          error={error}
        />
        <CrewLogListing filteredCrewMembers={filteredCrewMembers} columnDefs={columnDefs} loading={loading} />
        <Pagination
          items={filteredCrewMembers}
          itemsPerPage={25}
          currentPage={page}
          setCurrentPage={setPage}
          total={total}
        />
      </div>
    </InteriorPage>
  );
};

export default CrewLogs;
