import Pacing from "../columnRenders/Pacing";

const pacing = {
  headerName: "Pacing",
  field: "over_under",
  resizable: true,
  cellRenderer: Pacing,
  width: 175,
  headerClass: "ag-right-aligned-header",
};

export default pacing;
