import React from "react";
import styles from "./styles.module.scss";
import useResourceManagement from "../context/useResourceManagement";
import FilterBlock from "./FilterBlock";
import { AlertTriangle, AlertCircle, Truck, DollarSign, ArrowUpCircle, Trash2, Clock } from "react-feather";
import { ReactComponent as CalendarX } from "../../assets/CalendarX.svg";
import { ReactComponent as Lunch } from "../../assets/lunch.svg";

const FieldlogConsole = ({ projectId, isProject, consoleData, error, loading }) => {
  const { selectedConsoleFilters, setSelectedConsoleFilters } = useResourceManagement();

  return (
    <div className={`${styles.wrapper}`}>
      <div className="d-flex align-items-center justify-content-between">
        <div className={styles.header}>
          <h2 className={`mb-2 ${styles.consoleTitle}`}>Reporting Console</h2>
        </div>
      </div>

      {!!consoleData && !error && !loading && (
        <div className={styles.consoleRow}>
          <FilterBlock
            Icon={() => <AlertTriangle size={16} color="#fff" />}
            iconColor="#E70000"
            warningColor="#FFF6F6"
            title="Unclaimed"
            displayTitle="Unclaimed"
            timesheetCount={consoleData.unclaimed}
            selectedConsoleFilters={selectedConsoleFilters}
            setSelectedConsoleFilters={setSelectedConsoleFilters}
            isProject={isProject}
            projectId={projectId}
            isFieldlog
          />
          <FilterBlock
            Icon={() => <AlertCircle size={16} color="#fff" />}
            iconColor="#E70000"
            warningColor="#FFF6F6"
            title="Unallocated"
            displayTitle="Unallocated"
            timesheetCount={consoleData.unallocated}
            selectedConsoleFilters={selectedConsoleFilters}
            setSelectedConsoleFilters={setSelectedConsoleFilters}
            isFieldlog
            projectId={projectId}
            isProject={isProject}
          />
          <FilterBlock
            Icon={() => <Truck size={16} color="#fff" />}
            iconColor="#E70000"
            warningColor="#FFF6F6"
            title="Missing Trade"
            displayTitle="Missing Trade"
            timesheetCount={consoleData.missing_trade}
            selectedConsoleFilters={selectedConsoleFilters}
            setSelectedConsoleFilters={setSelectedConsoleFilters}
            isFieldlog
            projectId={projectId}
            isProject={isProject}
          />
          <FilterBlock
            Icon={() => <DollarSign size={16} color="#fff" />}
            iconColor="#FFA400"
            warningColor="#FFF8EB"
            title="Perdiem"
            displayTitle="Per Diem"
            timesheetCount={consoleData.perdiem}
            selectedConsoleFilters={selectedConsoleFilters}
            setSelectedConsoleFilters={setSelectedConsoleFilters}
            isFieldlog
            projectId={projectId}
            isProject={isProject}
          />
          <FilterBlock
            Icon={() => <ArrowUpCircle size={16} color="#fff" />}
            iconColor="#FFA400"
            warningColor="#FFF8EB"
            title="over_threshold"
            displayTitle="Over Threshold"
            timesheetCount={consoleData.over_threshold}
            selectedConsoleFilters={selectedConsoleFilters}
            setSelectedConsoleFilters={setSelectedConsoleFilters}
            isFieldlog
            projectId={projectId}
            isProject={isProject}
          />
          <div className={styles.grid}>
            <FilterBlock
              size="sm"
              Icon={() => <Lunch width={16} height={16} color="#fff" />}
              iconColor="#FFA400"
              warningColor="#FFF8EB"
              title="Lunch"
              displayTitle="Lunch"
              timesheetCount={consoleData.lunch}
              selectedConsoleFilters={selectedConsoleFilters}
              setSelectedConsoleFilters={setSelectedConsoleFilters}
              isFieldlog
              projectId={projectId}
              isProject={isProject}
            />
            <FilterBlock
              size="sm"
              Icon={() => <CalendarX size={16} color="#fff" />}
              iconColor="#FFA400"
              warningColor="#FFF8EB"
              title="Absent"
              displayTitle="Absent"
              timesheetCount={consoleData.absent}
              selectedConsoleFilters={selectedConsoleFilters}
              setSelectedConsoleFilters={setSelectedConsoleFilters}
              isFieldlog
              projectId={projectId}
              isProject={isProject}
            />
            <FilterBlock
              size="sm"
              Icon={() => <Trash2 size={16} color="#fff" />}
              iconColor="#FFA400"
              warningColor="#FFF8EB"
              title="Deleted"
              displayTitle="Deleted"
              timesheetCount={consoleData.deleted}
              selectedConsoleFilters={selectedConsoleFilters}
              setSelectedConsoleFilters={setSelectedConsoleFilters}
              isFieldlog
              projectId={projectId}
              isProject={isProject}
            />
            <FilterBlock
              size="sm"
              Icon={() => <Clock size={16} color="#fff" />}
              iconColor="#FFA400"
              warningColor="#FFF8EB"
              title="Late"
              displayTitle="Late"
              timesheetCount={consoleData.late}
              selectedConsoleFilters={selectedConsoleFilters}
              setSelectedConsoleFilters={setSelectedConsoleFilters}
              isFieldlog
              projectId={projectId}
              isProject={isProject}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FieldlogConsole;
