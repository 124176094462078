import { useState, useEffect } from "react";
import { useApi, bidApi } from "component-library";
import useProject from "../../../hooks/useProject";

const useAddTakeoff = () => {
  const [newMaterials, setNewMaterials] = useState([]);

  const {
    request: addTakeoffRequest,
    data: addTakeoffData,
    error: addTakeoffError,
    loading: addTakeoffLoading,
  } = useApi(bidApi.addTakeoff);

  const { project } = useProject();

  const handleAddTakeoff = () => {
    const newItem = {
      name: `New Takeoff ${!!newMaterials?.length ? newMaterials.length + 1 : 1}`,
      project_id: project.id,
      bid_quantity: 0,
      unit_measure: "",
    };
    addTakeoffRequest(newItem);
  };

  useEffect(() => {
    if (addTakeoffData) {
      setNewMaterials([addTakeoffData.takeoff, ...newMaterials]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTakeoffData]);

  return {
    handleAddTakeoff,
    addTakeoffLoading,
    addTakeoffError,
    newMaterials,
    setNewMaterials,
  };
};

export default useAddTakeoff;
