import React from "react";
import { Button } from "react-bootstrap";
import { Star } from "react-feather";
import styles from "./styles.module.scss";
import useProjectSelection from "../hooks/useProjectSelection";

const Favorite = ({ data }) => {
  const { favoriteProject } = useProjectSelection();

  return (
    <Button
      className={styles.favoriteButton}
      onClick={() => favoriteProject(data)}
      variant="link"
      aria-label={data?.favorited === 1 ? "Remove project from favorites list" : "Add project to favorites list"}
    >
      {data?.favorited === 1 ? <Star size={16} color="#F0C808" fill="#F0C808" /> : <Star size={16} color="#D3D3D3" />}
    </Button>
  );
};

export default Favorite;
