const fieldLogWidths = {
  date: "8rem",
  job_number: "10rem",
  project_name: "25rem",
  foreman: "11.5rem",
  project_managers: "25rem",
  hours: "6.5rem",
  alerts: "11.5rem",
  status: "12rem",
};

export default fieldLogWidths;
