import React from "react";
import styles from "./styles.module.scss";
import { Avatar } from "component-library";
import { ContactPill } from "component-library";

const TimesheetHeader = ({ user }) => {
  return (
    <section className={styles.header}>
      <Avatar hasColor user={user} size="lg" />
      <div className="d-flex align-items-center ms-2">
        <div>
          <div className={styles.userName}>{`${user?.first_name} ${user?.last_name}`}</div>
          {!!user?.hire_date && <div>Hire Date: {user?.hire_date}</div>}
        </div>
        {!!user?.email && <ContactPill data={user.email} isEmail />}
        {!!user?.cell && <ContactPill data={user.phone} isCell />}
        {!!user?.phone && <ContactPill data={user.phone} isPhone />}
      </div>
    </section>
  );
};

export default TimesheetHeader;
