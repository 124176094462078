import React from "react";
import JobAlerts from "./JobAlerts";
import DashboardPage from "./DashboardPage";
import PageHeader from "./PageHeader";

const AlertsPage = () => {
  return (
    <DashboardPage>
      <PageHeader title="Job Alerts" />
      <div className="row mb-4">
        <JobAlerts fullpage />
      </div>
    </DashboardPage>
  );
};

export default AlertsPage;
