import React from "react";
import PropTypes from "prop-types";
import { dropdownStylesWithWidth } from "component-library";
import Select from "react-select";
import styles from "../createBid.module.scss";

const Incentive = ({ item, handleChange, config, focusAdd }) => {
  const incentiveAmountConfig = config.incentive_config.map((item) => ({
    value: item.id,
    label: item.value,
  }));

  const incentiveCustomValue = incentiveAmountConfig.find(
    (item) => item.label.toLowerCase() === "custom"
  ).value;

  return (
    <div className="row py-2">
      <div className="col-md-4">
        <label htmlFor={`incentive_amount_${item.key ? item.key : item.id}`}>
          Incentive
        </label>
        <div className={styles.currencyInput}>
          $
          <input
            id={`incentive_amount_${item.key ? item.key : item.id}`}
            className="form-control"
            name="incentive_amount"
            type="number"
            onChange={(e) => {
              handleChange(
                "milestones",
                item.key,
                "incentive_amount",
                e.target.value
              );
            }}
            onFocus={focusAdd}
            value={!item.incentive_amount ? "" : item.incentive_amount}
          />
        </div>
      </div>
      <div className="col-md-2 d-flex align-items-end">
        <Select
          className="w-100"
          id="source"
          onChange={(e) => {
            handleChange("milestones", item.key, "incentive_type", e.value);
          }}
          options={incentiveAmountConfig}
          styles={dropdownStylesWithWidth(450)}
          value={
            incentiveAmountConfig.find(
              (opt) => opt.value === item.incentive_type
            ) || 1
          }
          aria-label="Incentive type"
        />
      </div>
      {!!item.incentive_type && item.incentive_type === incentiveCustomValue && (
        <div className="col-md-3">
          <label
            htmlFor={`incentive_type_other_${item.key ? item.key : item.id}`}
          >
            Custom
          </label>
          <input
            id={`incentive_type_other_${item.key ? item.key : item.id}`}
            className="form-control"
            name="name"
            onChange={(e) => {
              handleChange(
                "milestones",
                item.key,
                "incentive_type_other",
                e.target.value
              );
            }}
            onFocus={focusAdd}
            value={!item.incentive_type_other ? "" : item.incentive_type_other}
          />
        </div>
      )}

      <div className="col-md-3">
        <label htmlFor={`incentive_cap_${item.key ? item.key : item.id}`}>
          Incentive cap
        </label>
        <div className={styles.currencyInput}>
          $
          <input
            id={`incentive_cap_${item.key ? item.key : item.id}`}
            className="form-control"
            type="number"
            name="incentive_cap"
            onChange={(e) => {
              handleChange(
                "milestones",
                item.key,
                "incentive_cap",
                e.target.value,
                "format",
                "currency"
              );
            }}
            onFocus={focusAdd}
            value={!item.incentive_cap ? "" : item.incentive_cap}
          />
        </div>
      </div>
    </div>
  );
};

Incentive.propTypes = {
  item: PropTypes.object,
  handleChange: PropTypes.func,
  config: PropTypes.object,
  focusAdd: PropTypes.func,
};

export default Incentive;
