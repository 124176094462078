import React, { useState, useEffect } from "react";
import { useApi, reportsApi, useFormControl, dropdownStylesWithWidth } from "component-library";
import { schema, initialData } from "../schema/employeeTimeSummary";
import ToFromFields from "../ToFromFields";
import useForms from "./useForms";
import EmployeeTimeReport from "../AdminReports/EmployeeTimeReport";
import { Button } from "react-bootstrap";
import { DateTime } from "luxon";

const EmployeeTimeSummaryReport = ({ statusOptions, setLoading, setError, setData, setShowExport, urlParams }) => {
  const {
    request: runReportRequest,
    data: runReportData,
    loading: runReportLoading,
    error: runReportError,
  } = useApi(reportsApi.getEmployeeTimeReport);

  const { onSubmit } = useForms({
    runReportRequest,
    runReportData,
    runReportLoading,
    runReportError,
    setLoading,
    setError,
    setData,
  });

  const { handleSubmit, renderTextField, renderSubmitButton, data, setFields, renderSelectField, renderCheckbox } =
    useFormControl({
      schema,
      initialData,
      onSubmit,
    });

  const [gridData, setGridData] = useState();
  const [exportURL, setExportURL] = useState();

  useEffect(() => {
    if (!!runReportData?.gridData) {
      setGridData(runReportData.gridData);
    }
    if (!!runReportData?.url) {
      setExportURL(runReportData.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runReportData]);

  useEffect(() => {
    if (!!urlParams && data) {
      const storedStartDate = new Date(localStorage.getItem("resource_mgmt_start_date"));
      const storedEndDate = new Date(localStorage.getItem("resource_mgmt_end_date"));
      runReportRequest({
        ...data,
        from_date: DateTime.fromJSDate(storedStartDate).toFormat("yyyy-MM-dd"),
        to_date: DateTime.fromJSDate(storedEndDate).toFormat("yyyy-MM-dd"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  return (
    <>
      <form onSubmit={handleSubmit} id="employee_time_summary_report">
        <div className="row">
          <ToFromFields
            renderTextField={renderTextField}
            data={data}
            setFields={setFields}
            useStoredDate={!!urlParams}
          />
        </div>
        <div className="row">
          <div className="col-6">
            {renderSelectField({
              name: "statuses",
              label: "Statuses",
              options: statusOptions,
              styles: dropdownStylesWithWidth,
              emptyOption: "Select...",
              isMulti: true,
              closeMenuOnSelect: false,
            })}
          </div>
        </div>
        <div className="row">
          {renderCheckbox({
            name: "per_diem_only",
            isToggle: true,
            label: "Per Diem Only",
          })}
        </div>
        <div className="d-flex align-items-center justify-content-end">
          {!!exportURL && (
            <Button
              className="form-control-submit med undefined btn btn-primary mb-2 me-2"
              onClick={() => {
                setShowExport(true);
              }}
            >
              Export File
            </Button>
          )}
          <div className="mb-2">{renderSubmitButton("Run Report")}</div>
        </div>
      </form>
      {!!gridData && <EmployeeTimeReport gridData={gridData} urlParams={urlParams} />}
    </>
  );
};

export default EmployeeTimeSummaryReport;
