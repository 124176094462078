import { useEffect, useState } from 'react'
import { useApi, photoLibraryApi, displayDate } from "component-library";
import { DateTime } from "luxon";

function usePhotoHistory({ photoDetails }) {
  const [photoHistory, setPhotoHistory] = useState();

  const {
    request: photoHistoryRequest,
    data: photoHistoryData,
    loading: photoHistoryLoading,
    error: photoHistoryError,
  } = useApi(photoLibraryApi.getPhotoHistory);

  const formatTime = (date) => {
    return DateTime.fromJSDate(new Date(date)).toFormat("hh:mm");
  };

  const formatHistoryData = (history) => {
    let formattedHistory = [];

    history.forEach(historyEvent => {
      const date = displayDate(historyEvent.date)
      const time = formatTime(historyEvent.date)

      const dateExists = formattedHistory.find(item => {
        const processedDate = displayDate(item.date);
        return date === processedDate;
      });

      if (dateExists) {
        formattedHistory = [...formattedHistory, { ...historyEvent, time: time, date: "" }]
      } else {
        formattedHistory = [...formattedHistory, { ...historyEvent, time: time, date: date }]
      }
    });

    return formattedHistory;
  }

  useEffect(() => {
    if (photoDetails?.id) {
      photoHistoryRequest(photoDetails.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoDetails?.id]);

  useEffect(() => {
    if (photoHistoryData?.data) {
      setPhotoHistory(formatHistoryData(photoHistoryData.data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoHistoryData])

  return {
    photoHistoryRequest,
    photoHistoryData,
    photoHistoryLoading,
    photoHistoryError,
    photoHistory,
  }
}

export default usePhotoHistory