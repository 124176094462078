import React from "react";
import styles from "./styles.module.scss";
import gridRowHeights from "../../utils/gridRowHeights";

const RowContent = ({ children, indent = 0 }) => {
  return (
    <div className={styles.rowContentWrapper} style={{ height: gridRowHeights.medium, marginLeft: indent }}>
      {children}
    </div>
  );
};

export default RowContent;
