import React from "react";
import InteriorPage from "../pageLayout/InteriorPage";
import WeatherPage from "../Weather/WeatherPage";
import { Sun } from "react-feather";

const WeatherArchive = () => {
  return (
    <InteriorPage
      navTitle="Weather"
      pageTitle="Archive"
      navIcon={<Sun color="#fff" size={14} />}
    >
      <WeatherPage />
    </InteriorPage>
  );
};

export default WeatherArchive;
