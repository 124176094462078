import { useEffect, useState } from "react";

const useCategoryFilters = (contacts, isContact) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredByCategory, setFilteredByCategory] = useState([]);

  const updateCategory = (newCat) => {
    if (selectedCategories.includes(newCat)) {
      setSelectedCategories(selectedCategories.filter((id) => id !== newCat));
    } else {
      setSelectedCategories([...selectedCategories, newCat]);
    }
  };

  useEffect(() => {
    if (contacts) {
      const filteredItems =
        !!selectedCategories && !!selectedCategories.length
          ? contacts.filter((contact) => {
              let hasCategory = false;
              if (isContact) {
                if (
                  !!contact &&
                  !!contact.company_categories &&
                  !!JSON.parse(contact.company_categories).length
                ) {
                  selectedCategories.forEach((cat) => {
                    if (JSON.parse(contact.company_categories).indexOf(cat) > -1) {
                      hasCategory = true;
                    }
                  });
                }
              } else {
                if (
                  !!contact &&
                  !!contact.company_categories &&
                  !!contact.company_categories.length
                ) {
                  contact.company_categories.forEach((c) => {
                    if (selectedCategories.includes(c)) {
                      hasCategory = true;
                    }
                  });
                }
              }
              return hasCategory;
            })
          : contacts;
      setFilteredByCategory(filteredItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories, contacts]);

  return {
    filteredByCategory,
    updateCategory,
    selectedCategories,
  };
};

export default useCategoryFilters;
