import { Joi } from "component-library";

const schema = {
  password: Joi.saneString().min(8).required().label("Password"),
  password_confirmation: Joi.ref("password"),
};

const initialData = {
  password: "",
  password_confirmation: "",
};

export { schema, initialData };
