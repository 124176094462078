import React, { useMemo } from "react";
import Task from "./Task";
import PropTypes from "prop-types";

const TaskRows = ({ bidItem, toggleSelectedBidItem, selectedTaskBidItems }) => {
  const tasks = useMemo(() => {
    return bidItem.tasks.map((task, index) => {
      return (
        <Task
          toggleSelectedBidItem={toggleSelectedBidItem}
          selectedTaskBidItems={selectedTaskBidItems}
          key={task.id}
          task={task}
          bgColor={index % 2 === 0 ? "light" : "dark"}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidItem, toggleSelectedBidItem]);

  return tasks;
};

TaskRows.propTypes = {
  bidItem: PropTypes.object.isRequired,
  toggleSelectedBidItem: PropTypes.func,
  selectedTaskBidItems: PropTypes.array,
};

export default TaskRows;
