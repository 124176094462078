import { useState } from "react";
import { useAuth } from "component-library";
import getTaskNumber from "./TaskView/Task/getTaskNumber";

const useFieldLogVerification = ({ tasks, status, project, minutesUnassigned, overAllocatedTimeSheets }) => {
  const [errorMessageList, setErrorMessageList] = useState([]);
  const [invalidRequiredQuantities, setInvalidRequiredQuantities] = useState([]);
  const { userCan } = useAuth();

  //STATUS CODES
  //1 === Exported
  //2 === Submitted
  //3 === Rejected
  //4 === In Progress
  //5 === PM Approved

  const rejectDisabled = () => {
    return !project || !status || [3, 4].includes(status) || (status === 1 && !userCan("payroll-review-time"));
  };

  const approveDisabled = () => {
    return (
      !project ||
      !status ||
      (status !== 3 && status !== 2) ||
      minutesUnassigned !== 0 ||
      overAllocatedTimeSheets.length > 0
    );
  };

  const verifySubmit = () => {
    let errorList = [];
    if (parseFloat(minutesUnassigned) !== 0) {
      if (!!overAllocatedTimeSheets?.length && overAllocatedTimeSheets?.length > 0) {
        errorList.push("There are over allocated time sheets.");
      } else {
        errorList.push("Time needs to be assigned to the work day.");
      }
    }
    if (!userCan("payroll-review-time")) {
      errorList.push("You do not have permission to edit this.");
    }
    const quantityErrors = verifyQuantity();

    if (quantityErrors) {
      errorList.push(...quantityErrors);
    }

    setErrorMessageList(errorList);

    return !!errorList.length;
  };

  const verifyQuantity = () => {
    const tempErrors = [];
    if (!!tasks?.length) {
      tasks.forEach((task) => {
        let foundQuantityRequired;
        if (!!task.task.quantity_required) {
          foundQuantityRequired = task.task.name;
        }
        if (foundQuantityRequired && !(parseFloat(task.quantity) > 0)) {
          tempErrors.push(`Quantity Required for ${foundQuantityRequired} / Task No. ${getTaskNumber(task.task)}`);
        }
      });
    }
    return tempErrors;
  };

  const requiredValueValidate = (task, quantity) => {
    if (task.task.quantity_required) {
      const validNumber = !(isNaN(parseFloat(quantity)) || parseFloat(quantity) <= 0);
      return !!validNumber;
    }
    return true;
  };

  return {
    rejectDisabled,
    approveDisabled,
    verifySubmit,
    errorMessageList,
    setErrorMessageList,
    verifyQuantity,
    invalidRequiredQuantities,
    setInvalidRequiredQuantities,
    requiredValueValidate,
  };
};

export default useFieldLogVerification;
