import Phone from "../columnRenders/Phone";

const phone = {
  headerName: "Phone",
  field: "phone",
  maxWidth: 125,
  cellRenderer: Phone,
};

export default phone;
