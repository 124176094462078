import React from "react";
import styles from "../styles.module.scss";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from "recharts";
import Dot from "../StatPieChart/Dot";
import { displayDate } from "component-library";

const PacingChart = ({ stats }) => {
  const formatDate = (date) => {
    return displayDate(date);
  };
  return (
    <section className={`${styles.section} ${styles.hasBg} pb-0`}>
      <h2>Pacing</h2>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center me-3">
          <Dot color="#E70000" /> <span className="ms-2 text-gray3">Actual</span>
        </div>
        <div className="d-flex align-items-center me-3">
          <Dot color="#5DB115" /> <span className="ms-2 text-gray3">Projected</span>
        </div>
      </div>
      {!!stats?.pacing?.length ? (
        <figure className={styles.chartWrapper}>
          <ResponsiveContainer width="100%" height={150}>
            <LineChart data={stats?.pacing} stackOffset="sign">
              <XAxis
                dataKey="date"
                tick={{ fill: "#4F4F4F" }}
                stroke="#D3D3D3"
                axisLine={true}
                tickLine={true}
                tickFormatter={formatDate}
              />
              <YAxis yAxisId="left" tick={{ fill: "#4F4F4F" }} stroke="#D3D3D3" axisLine={true} tickLine={true} />
              <Line
                connectNulls
                yAxisId="left"
                dot={false}
                strokeWidth={3}
                type="monotone"
                dataKey="projected_value"
                stroke="#5DB115"
              />
              <Line
                yAxisId="left"
                dot={false}
                strokeWidth={3}
                type="monotone"
                dataKey="pacing_value"
                stroke="#E70000"
              />
            </LineChart>
          </ResponsiveContainer>
        </figure>
      ) : (
        <>No pacing data available</>
      )}
    </section>
  );
};

export default PacingChart;
