import { useState, useEffect } from "react";
import { useApi, dailyFieldlogsApi } from "component-library";
import PropTypes from "prop-types";

const useEquipment = ({ fieldLog, date, job_number }) => {
  const [equipment, setEquipment] = useState();

  const {
    request: equipmentRequest,
    data: equipmentData,
    error: equipmentError,
    loading: equipmentLoading,
  } = useApi(dailyFieldlogsApi.getEquipment);

  useEffect(() => {
    if (!!date && !!job_number) {
      equipmentRequest({ job_number: job_number, date: date });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldLog, date, job_number]);

  useEffect(() => {
    if (equipmentData?.equipment) {
      setEquipment(equipmentData.equipment);
    } else {
      setEquipment();
    }
  }, [equipmentData?.equipment]);

  return {
    equipment,
    equipmentError,
    equipmentLoading,
  };
};

useEquipment.propTypes = {
  date: PropTypes.string,
  project: PropTypes.object,
};

export default useEquipment;
