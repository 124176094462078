import PM from "../columnRenders/PM";

const project_managers = {
  headerName: "Project Managers",
  field: "project_managers",
  flex: 1,
  minWidth: 300,
  cellRenderer: PM,
};

export default project_managers;
