import React from "react";
import MobileHeader from "../MobileHeader/MobileHeader";
import { useParams } from "react-router-dom";
import { useApi, telematicsApi } from "component-library";
import useInspectionDetails from "../../../hooks/useInspectionDetails";
import Detail from "./Detail";
import PhotoModal from "../../../Modals/PhotoModal";

const InspectionDetail = () => {
  const { inspectionId } = useParams();
  const { data: truckFieldsData, request: truckFieldsRequest } = useApi(telematicsApi.getVehicleInspectionFields);
  const { data: trailerFieldsData, request: trailerFieldsRequest } = useApi(telematicsApi.getVehicleInspectionFields);
  const { data, error, loading, request } = useApi(telematicsApi.getInspectionDataById);

  const {
    truckDefects,
    trailerDefects,
    truckDefectTypes,
    trailerDefectTypes,
    photos,
    selectedPhotos,
    setSelectedPhotos,
  } = useInspectionDetails({
    inspectionData: {
      inspect_id: inspectionId,
    },
    truckFieldsData,
    truckFieldsRequest,
    trailerFieldsData,
    trailerFieldsRequest,
    request,
    data,
  });

  return (
    <>
      <MobileHeader
        backUrl={`${process.env.REACT_APP_URL}page/schedule_contact_info?this_id=${data?.data?.id_user}&type=driver`}
      />
      <Detail
        data={data}
        loading={loading}
        error={error}
        trailerDefectTypes={trailerDefectTypes}
        trailerDefects={trailerDefects}
        truckDefectTypes={truckDefectTypes}
        truckDefects={truckDefects}
        setSelectedPhotos={setSelectedPhotos}
        photos={photos}
      />
      <PhotoModal data={selectedPhotos} onHide={() => setSelectedPhotos()} />
    </>
  );
};

export default InspectionDetail;
