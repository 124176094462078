import { useState } from "react";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import { Users } from "react-feather";
import CrewDetailHeader from "../../../../_resourceManagement/CrewLogs/CrewDetailHeader";
import useCrewLogsDetail from "../../../../_resourceManagement/CrewLogs/useCrewLogsDetail";
import CrewDetailFilters from "../../../../_resourceManagement/CrewLogs/CrewDetailFilters";
import ReportingConsole from "../../../../_resourceManagement/ReportingConsole";
import { Link, useParams } from "react-router-dom";
import useProject from "../../../hooks/useProject";
import { ArrowRight } from "react-feather";
import useResourceManagement from "../../../../_resourceManagement/context/useResourceManagement";
import CrewLogDetailListing from "../../../../_resourceManagement/CrewLogs/CrewLogDetailListing";
import Pagination from "../../../../components/Pagination";
import { Loader } from "component-library";
import useInitialLoader from "../../hooks/useInitialLoader";
import TwoWeekMessage from "./TwoWeekMessage";

const CrewLogDetail = () => {
  const { q } = useResourceManagement();
  const { id: projectId } = useParams();
  const [initialLoad, setInitialLoad] = useState(true);

  const {
    pageTitle,
    loading: projectLoading,
    error: projectError,
    fieldMgmtDateRange,
    setFieldMgmtDateRange,
  } = useProject();

  const {
    loading,
    projectsLoading,
    projectsList,
    selectedForeman,
    setSelectedForeman,
    selectedCrewMember,
    gridData,
    crewId,
    foremenList,
    error,
    consoleData,
    page,
    setPage,
    total,
  } = useCrewLogsDetail({ projectId, dateRange: fieldMgmtDateRange, initialLoad });

  useInitialLoader(initialLoad, setInitialLoad);

  return (
    <InteriorPage
      loading={projectLoading}
      error={projectError || error}
      navTitle={pageTitle}
      navIcon={<Users color="#fff" size={14} />}
      pageTitle={
        <div className="d-flex align-items-center">
          <Link to={`/project/${projectId}/field-management/timesheet-logs`}>Crew Timesheets</Link>
          <ArrowRight className="mx-4" size={12} color="#7B7B7B" />
          Crew Detail
        </div>
      }
    >
      <TwoWeekMessage dateRange={fieldMgmtDateRange} />
      <Loader loading={!!loading} />
      <CrewDetailHeader selectedCrewMember={selectedCrewMember} loading={loading} />
      <CrewDetailFilters
        projectsLoading={projectsLoading}
        projectsList={projectsList}
        selectedForeman={selectedForeman}
        setSelectedForeman={setSelectedForeman}
        foremenList={foremenList}
        loading={loading}
        dateRange={fieldMgmtDateRange}
        setDateRange={setFieldMgmtDateRange}
        projectId={projectId}
      />
      <ReportingConsole
        q={q}
        isProject
        projectId={projectId}
        foremanId={selectedForeman}
        crewId={crewId}
        loading={loading}
        consoleData={consoleData}
        error={error}
      />
      <Loader loading={!!loading} />
      <CrewLogDetailListing gridData={gridData} loading={loading} />
      <Pagination items={gridData} itemsPerPage={25} currentPage={page} setCurrentPage={setPage} total={total} />
    </InteriorPage>
  );
};

export default CrewLogDetail;
