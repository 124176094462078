import React from "react";
import { NavLink, useLocation, useMatch } from "react-router-dom";

const FleetManagementLinks = () => {
  const { pathname } = useLocation();
  const isPOI = useMatch({ path: "/pois/*" });

  return (
    <div className="subNav">
      <NavLink className="subNavLink" to={`/fleet-management/dashboard`}>
        Dashboard
      </NavLink>
      <NavLink className="subNavLink" to={`/fleet-management/operations`}>
        Operations
      </NavLink>
      <NavLink
        className={`subNavLink ${pathname.includes("/trucking/") ? "active" : ""}`}
        to={`/fleet-management/trucking/schedule`}
      >
        Trucking
      </NavLink>
      <NavLink className="subNavLink" to={`/fleet-management/equipment`}>
        Equipment
      </NavLink>
      <NavLink
        className={`subNavLink ${pathname.includes("/configuration/") ? "active" : ""}`}
        to={`/fleet-management/configuration/users`}
      >
        Configuration
      </NavLink>

      <NavLink
        className={`subNavLink ${pathname.includes("/reports/") ? "active" : ""}`}
        to={`/fleet-management/reports/home`}
      >
        Reports
      </NavLink>
      <NavLink className={`tertiaryNavLink ${isPOI ? "active" : ""}`} to={`/fleet-management/pois`}>
        Points of Interest
      </NavLink>
    </div>
  );
};

export default FleetManagementLinks;
