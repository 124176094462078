import React from "react";
import LibraryGrid from "../../../../../../../_projects/project/PhotoLibrary/LibraryGrid";
import styles from "./styles.module.scss";
import { BeatLoader } from "react-spinners";
import { Alert } from "react-bootstrap";
import EmptyAlbum from "../../../../../../../_projects/project/PhotoLibrary/AlbumDetail/EmptyAlbum";

const AlbumDetailOptions = ({
  project,
  albumId,
  selectedPhotoIds,
  updateSelectedPhotos,
  albumPhotosLoading,
  albumDetailsError,
  albumPhotosError,
  albumPhotosList,
  pinnedPhotos,
  todaysPhotos,
  recentPhotos,
  previousPhotos,
  message,
  setMessage,
  setShowModal,
}) => {
  return (
    <section className={`text-gray2 p-0 fullWidthContent`}>
      {!!message && (
        <Alert variant="success" dismissible onClick={() => setMessage("")}>
          {message}
        </Alert>
      )}
      {!!albumId && (
        <div className="position-relative">
          {!!albumPhotosLoading && (
            <div className="loaderWrapper">
              <BeatLoader color="#0071ce" />
            </div>
          )}
          {!!albumDetailsError && <Alert variant="danger">{albumDetailsError}</Alert>}
          {!!albumPhotosError && <Alert variant="danger">{albumPhotosError}</Alert>}
          {!!albumPhotosList?.length ? (
            <>
              {!!pinnedPhotos?.length && (
                <section>
                  <h2 className={styles.sectionTitle}>Pinned</h2>
                  <LibraryGrid
                    project={project}
                    gridSize="large"
                    gridItems={pinnedPhotos}
                    updateSelectedPhotos={updateSelectedPhotos}
                    selectedPhotoIds={selectedPhotoIds}
                  />
                </section>
              )}
              <section>
                <h2 className={styles.sectionTitle}>All Photos - Today</h2>
                <LibraryGrid
                  project={project}
                  gridSize="large"
                  gridItems={todaysPhotos}
                  updateSelectedPhotos={updateSelectedPhotos}
                  selectedPhotoIds={selectedPhotoIds}
                />
              </section>
              <section>
                <h2 className={styles.sectionTitle}>Last 7 Days</h2>
                <LibraryGrid
                  project={project}
                  gridSize="medium"
                  gridItems={recentPhotos}
                  updateSelectedPhotos={updateSelectedPhotos}
                  selectedPhotoIds={selectedPhotoIds}
                />
              </section>
              <section>
                <h2 className={styles.sectionTitle}>Previous Photos</h2>
                <LibraryGrid
                  project={project}
                  gridSize="small"
                  gridItems={previousPhotos}
                  updateSelectedPhotos={updateSelectedPhotos}
                  selectedPhotoIds={selectedPhotoIds}
                />
              </section>
            </>
          ) : (
            <>{!albumPhotosLoading && <EmptyAlbum setShowModal={setShowModal} />}</>
          )}
        </div>
      )}
    </section>
  );
};

export default AlbumDetailOptions;
