import React from "react";
import styles from "./styles.module.scss";
import { ReactComponent as Timesheet } from "./timesheet.svg";

const OnSite = ({ data }) => {
  return (
    <div className={`${styles.userCount} ${data?.on_site === 0 ? styles.noneOnSite : styles.onSite} `}>
      <Timesheet width={12} height={12} fill={data?.on_site === 0 ? "#979797" : "#fff"} />
      {data?.on_site}
    </div>
  );
};

export default OnSite;
