import React from "react";
import { Routes, Route } from "react-router";
import PrivateRoute from "../../../components/PrivateRoute";
import GanttView from "./GanttView";
import CalendarView from "./CalendarView";
import ProvideSchedule from "./ProvideSchedule";
import TaskDetail from "./TaskDetail";
import ScheduleView from "./ScheduleView/ScheduleView";

const ProjectRoutes = () => {

  return (
    <ProvideSchedule>
      <Routes>
        <Route index element={<PrivateRoute Component={<ScheduleView />} />} />
        <Route path="schedule" element={<PrivateRoute Component={<ScheduleView />} />} />
        <Route path="gantt" element={<PrivateRoute Component={<GanttView />} />} />
        <Route path="calendar" element={<PrivateRoute Component={<CalendarView />} />} />
        <Route path="task/:taskId" element={<PrivateRoute Component={<TaskDetail />} />} />
      </Routes>
    </ProvideSchedule>
  );
};

export default ProjectRoutes;
