import React, { useState } from "react";
import PropTypes from "prop-types";

const SiteGeoMetaRow = ({ poly, polygons, setPolygons, setUpdate, loading }) => {
  const [name, setName] = useState(!!poly.name ? poly.name : "");
  const [showNameError, setShowNameError] = useState(false);

  const handleUpdate = (poly) => {
    setPolygons(
      polygons.map((p) => {
        if (poly.id === p.id) {
          if (name !== poly.name && !showNameError) {
            setUpdate(true);
            return {
              ...p,
              name: name,
            };
          } else {
            return p;
          }
        } else {
          return p;
        }
      })
    );
  };

  return (
    <>
      <input
        onChange={({ target: { value } }) => {
          if (
            !polygons.some((polygon) => {
              if (polygon.id === poly.id) {
                return false;
              } else {
                return polygon.name === value;
              }
            })
          ) {
            setName(value);
            setShowNameError(false);
          } else {
            setName(value);
            setShowNameError(true);
          }
        }}
        onBlur={() => {
          handleUpdate(poly);
        }}
        id={`${poly.id}_region_name`}
        name="region_name"
        type="text"
        value={name}
        placeholder={"Region name"}
        className="px-2 me-3"
        maxLength="50"
        disabled={loading}
      />
      {!!showNameError && <label className="text-red">This name is already taken. Choose a different name.</label>}
    </>
  );
};

SiteGeoMetaRow.propTypes = {
  poly: PropTypes.object,
  polygons: PropTypes.array,
  setPolygons: PropTypes.func,
  setUpdate: PropTypes.func,
};

export default SiteGeoMetaRow;
