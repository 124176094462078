import React from "react";
import styles from "./styles.module.scss";
import CrewLogRow from "../../PrintableGrids/rows/CrewLogs/CrewLogRow";
import TimesheetRow from "../../PrintableGrids/rows/Timesheets/TimesheetRow";
import TaskRow from "../../PrintableGrids/rows/Tasks/TaskRow";
import CrewLogHeaders from "../../PrintableGrids/rows/CrewLogs/CrewLogHeaders";
import TimesheetHeaders from "../../PrintableGrids/rows/Timesheets/TimesheetHeaders";
import TaskHeaders from "../../PrintableGrids/rows/Tasks/TaskHeaders";

const PrintableCrewLogs = ({ data, columns }) => {
  return (
    <div className={styles.container}>
      <CrewLogHeaders columns={columns} />
      {data.map((cl) => {
        return (
          <>
            <CrewLogRow crewLog={cl} columns={columns} key={cl.user_id} />
            {!!cl.time_sheets.length && (
              <div className="mt-2 mb-2">
                <TimesheetHeaders isCrewLog />
                {cl.time_sheets.map((timesheet) => {
                  return (
                    <>
                      <TimesheetRow timesheet={timesheet} isCrewLog />
                      {!!timesheet.time_entries.length && (
                        <div className="mt-2 mb-2">
                          <TaskHeaders />
                          {timesheet.time_entries.map((task) => {
                            return <TaskRow task={task} />;
                          })}
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};
export default PrintableCrewLogs;
