import React from "react";
import PropTypes from "prop-types";
import { submittalConfig, useAuth } from "component-library";
import styles from "./styles.module.scss";
import { ChevronDown } from "react-feather";
import "./optionStyles.scss";
import useSubmittals from "../../../_submittals/hooks/useSubmittals";
import { BeatLoader } from "react-spinners";

const StatusValue = ({ data }) => {
  const { config } = useAuth();
  const { submittal, updateLoading } = useSubmittals();

  const options = config.submittal_statuses.map((status) => ({
    label: status.name,
    value: status.id,
    color: submittalConfig.statusColors[status.id],
  }));

  const currentStatus = options.find((status) => data.status_id === status.value);

  return (
    <div className={styles.fakeDropdown}>
      {!!updateLoading && submittal.id === data.id && <BeatLoader size={13} color="#0071CE" />}
      <div className={`statusOption${currentStatus.value}`}>
        <span>{currentStatus.label}</span>
      </div>
      <ChevronDown size="16" color="#717171" />
    </div>
  );
};

StatusValue.propTypes = {
  data: PropTypes.object,
};

export default StatusValue;
