import React from "react";
import styles from "./styles.module.scss";

const ColumnHeader = ({ text = "", width = "5rem", alignment = "left", icon = null }) => {
  return (
    <div className={styles.columnHeader} style={{ width: width, textAlign: alignment }}>
      {text}
      {icon}
    </div>
  );
};

export default ColumnHeader;
