import { useEffect, useState } from "react";
import rolesApi from "../../api/roles";
import { useApi } from "component-library";

const useUserAdmin = () => {
  const [roles, setRoles] = useState();
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();

  const {
    request: rolesRequest,
    data: rolesData,
    error: rolesError,
    loading: rolesLoading,
  } = useApi(rolesApi.getRoles);

  useEffect(() => {
    if (!!rolesLoading) return;
    rolesRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!rolesData?.success && !!rolesData) {
      setRoles(rolesData?.roles);
    }
  }, [rolesData]);

  return {
    rolesError,
    roles,
    error,
    setError,
    successMessage,
    setSuccessMessage,
    setRoles,
  };
};

export default useUserAdmin;
