import React from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import { X } from "react-feather";
import CustomScroll from "react-custom-scroll";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import useAddProjectNote from "./useAddProjectNote";
import { Loader } from "component-library";

const AddProjectNoteModal = ({ notes, setNotes, showModal, onHide, date }) => {
  const { fields, handleSubmit, addCommentLoading, addCommentError } = useAddProjectNote({
    notes,
    setNotes,
    date,
    onHide,
  });

  return (
    <>
      <Modal
        show={!!showModal}
        centered
        onHide={() => onHide}
        animation={false}
        className="text-gray2 p-0 fullWidthContent"
        size="lg"
      >
        <div className="position-relative">
          <Loader loading={!!addCommentLoading} />
          <Modal.Header>
            <div>
              <h3 className="mb-0 xxl">Add Project Note</h3>
            </div>
            <Button className="p-0" variant="link" onClick={onHide} aria-label="Close Add Project Note Modal">
              <X size={25} />
            </Button>
          </Modal.Header>
          <Modal.Body className={`position-relative`}>
            {!!addCommentError && <Alert variant="danger">{addCommentError}</Alert>}
            <CustomScroll heightRelativeToParent="100%">
              <section className={styles.body}>
                <form onSubmit={handleSubmit}>{fields}</form>
              </section>
            </CustomScroll>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

AddProjectNoteModal.propTypes = {
  projectNotes: PropTypes.array,
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
  projectNotesLoading: PropTypes.bool,
  projectNotesError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  deleteNote: PropTypes.func,
  confirmationModalOpen: PropTypes.bool,
  onConfirmDeleteNote: PropTypes.func,
  onCancleDeleteNote: PropTypes.func,
  deleteError: PropTypes.bool,
  deleteLoading: PropTypes.bool,
};

export default AddProjectNoteModal;
