import useProject from "../../../../../hooks/useProject";
import Submenu from "../../Submenu";
import { Briefcase } from "react-feather";
import InteriorPage from "../../../../../../components/pageLayout/InteriorPage";
import useWeeklyReportsList from "../../../../../../_resourceManagement/WeeklyReports/WeeklyReportsList/useWeeklyReportsList";
import { DateRangeSelector, AgGridTable, useAuth } from "component-library";
import styles from "../../../../../../_resourceManagement/WeeklyReports/WeeklyReportsList/styles.module.scss";
import weeklyReportsColumnConfig from "./weeklyReportsColumnConfig";
import gridRowHeights from "../../../../../../utils/gridRowHeights";
import { getMaxDate } from "../../../../../../utils/getMaxDate";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

const WeeklyReportsList = () => {
  const history = useNavigate();
  const { userCan, user } = useAuth();
  const { id } = useParams();
  const { pageTitle, loading: projectLoading } = useProject();
  const { gridData, error, loading, dateRange, setDateRange } = useWeeklyReportsList({
    projectId: id,
  });

  const handleCellClick = ({ data }) => {
    history(
      `/project/${data?.project?.id}/field-management/weekly-reports/${data?.id}/${
        ["sent"].includes(data?.status) || !userCan("weekly-report-edit") ? "review" : "edit"
      }`
    );
  };

  return (
    <InteriorPage
      error={error}
      loading={projectLoading || loading}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle="Field Management"
      pageSubtitlePrimaryContent={() => <Submenu />}
    >
      {!id && <>Global Page (add pm and project filters)</>}
      <div className={styles.datePickerWrapper}>
        <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} maxDate={getMaxDate(dateRange, 14)} />
      </div>
      <AgGridTable
        gridData={gridData}
        gridConfig={weeklyReportsColumnConfig}
        loading={loading}
        rowHeight={gridRowHeights.medium}
        id={`${user.id}_weekly_reports_listing`}
        presetStyle="sortableTable"
        handleCellClick={(data) => handleCellClick(data)}
        fallbackMessage="No reports found."
      />
    </InteriorPage>
  );
};

export default WeeklyReportsList;
