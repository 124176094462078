import React from "react";
import { Button } from "react-bootstrap";
import { PlusCircle } from "react-feather";
import FormSelectLocation from "../../../../../../components/FormSelectLocation";
import LocationControl from "../../../../../../components/Locations/LocationControl";
import useSubcontractors from "./useSubcontractors";
import styles from "./styles.module.scss";
import sectionStyles from "../styles.module.scss";

const Subcontractors = ({ day, updateDateField }) => {
  const {
    modalOpen,
    setModalOpen,
    addingCompany,
    setAddingCompany,
    addingLocation,
    setAddingLocation,
    handleRemoveLocation,
    handleAddLocation,
  } = useSubcontractors({
    day,
    updateDateField,
  });
  return (
    <section className={sectionStyles.fieldSection}>
      <div className="d-flex align-items-center">
        <h3 className={styles.title}>Subcontractors</h3>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-start ">
            {!!day?.subcontractors?.length &&
              day.subcontractors.map((subcontractor) => (
                <div className="d-flex align-items-center me-3" key={subcontractor?.location_id}>
                  <LocationControl
                    title={"Subcontractor"}
                    location={subcontractor?.company ? subcontractor.company : subcontractor}
                    removeLocation={() => {
                      handleRemoveLocation(subcontractor);
                    }}
                  />
                </div>
              ))}
          </div>
          <Button
            variant="link"
            className="btn btn-link"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <PlusCircle color="#A7A7A7" size={16} />
          </Button>
        </div>
        {modalOpen && (
          <FormSelectLocation
            addingCompany={addingCompany}
            addingLocation={addingLocation}
            setAddingLocation={setAddingLocation}
            setAddingCompany={setAddingCompany}
            label={"Subcontractor"}
            show={modalOpen}
            hide={() => setModalOpen(false)}
            addLocation={(location) => {
              handleAddLocation(location);
            }}
          />
        )}
      </div>
    </section>
  );
};

export default Subcontractors;
