import React from "react";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import useProject from "../../hooks/useProject";
import { Calendar } from "react-feather";

const TaskDetail = () => {
  const { pageTitle, PageTag } = useProject();

  return (
    <InteriorPage
      // loading={loading}
      // error={error}
      navTitle={pageTitle}
      navIcon={<Calendar color="#fff" size={14} />}
      pageTitle="Schedule"
      NavContent={PageTag}
    >
      <div>This is a task detail.</div>
    </InteriorPage>
  );
};

export default TaskDetail;
