import TimesheetId from "../../../sharedColumns/columnRenders/TimesheetId";

const id = {
  headerName: "Id",
  field: "timesheet_id",
  width: 80,
  cellRenderer: TimesheetId,
};

export default id;
