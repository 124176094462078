import { useState, useEffect } from "react";
import { useApi, projectApi } from "component-library";
import { useParams } from "react-router-dom";
const useSummary = () => {
  const { id } = useParams();
  const [stats, setStats] = useState();
  const [error, setError] = useState();
  const [dateRange, setDateRange] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);

  const {
    request: statsRequest,
    data: statsData,
    loading: statsLoading,
    error: statsError,
  } = useApi(projectApi.getReportingStats);

  useEffect(() => {
    if (!!id && !statsLoading && !!initialLoad) {
      statsRequest({ project_id: id, from_date: dateRange?.[0], to_date: dateRange?.[1] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!initialLoad && !!id && !statsLoading && (dateRange[1] || !dateRange[0])) {
      statsRequest({ project_id: id, from_date: dateRange?.[0], to_date: dateRange?.[1] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    if (statsData?.stats) {
      setInitialLoad(false);
      setStats(statsData.stats);
    }
  }, [statsData]);

  useEffect(() => {
    const apiError = statsError;
    if (apiError) {
      setError(apiError);
    }
  }, [statsError]);

  return {
    stats,
    statsLoading,
    error,
    dateRange,
    setDateRange,
  };
};

export default useSummary;
