import StatusColor from "../bidColumns/StatusColor";

const color = {
  headerName: "",
  field: "bid_todos",
  width: 32,
  pinned: "left",
  justifyContent: "center",
  cellRenderer: StatusColor,
};

export default color;
