import User from "../columns/User";

const user = {
  headerName: "User",
  field: "user",
  cellRenderer: User,
  suppressMenu: true,
  flex: 1,
  valueGetter: ({ data }) => `${data?.user?.first_name} ${data?.user?.last_name}`,
  filterValueGetter: (params) => `${params?.data?.user?.first_name} ${params?.data?.user?.last_name}`,
  sortable: true,
};

export default user;
