import React, { useEffect, useState } from "react";
import { Users } from "react-feather";
import InteriorPage from "../../components/pageLayout/InteriorPage";
import usePassedComponent from "../../hooks/usePassedComponent";
import ControlBar from "./ControlBar";
import GlobalSecondaryHeader from "./GlobalSecondaryHeader";
import Listing from "./Listing";
import useFieldLogs from "./hooks/useFieldLogs";
import useFieldLogSelection from "../context/useFieldlogSelection";
import { useAuth } from "component-library";
import Submenu from "../Submenu";
import ReportingConsole from "../ReportingConsole";
import useResourceManagement from "../context/useResourceManagement";
import Pagination from "../../components/Pagination";
import TwoWeekMessage from "../../_projects/project/FieldManagement/FieldLogs/TwoWeekMessage";

const FieldLogs = () => {
  const { userCan } = useAuth();
  const { selectedProject, dateRange, setDateRange } = useResourceManagement();
  const [page, setPage] = useState(1);

  const itemsPerPage = 25;

  const { fieldLogs, setFieldLogs, getFieldLogs, loading, error, consoleData, total, viewAll, setViewAll } =
    useFieldLogs();

  const { FieldLogSelection, selected, setFieldLogs: setSelectFieldLogs, selectedLogs } = useFieldLogSelection();

  const updateExportedStatus = () => {
    setFieldLogs(
      fieldLogs.map((fl) =>
        selected.includes(fl.id) ? { ...fl, work_day_status_id: 1, work_day_status_name: "Exported" } : fl
      )
    );
  };

  const updateApproveStatus = () => {
    setFieldLogs(
      fieldLogs.map((fl) =>
        selected.includes(fl.id)
          ? {
              ...fl,
              work_day_status_id: 5,
              work_day_status_name: "PM Approved",
            }
          : fl
      )
    );
  };

  useEffect(() => {
    setSelectFieldLogs(fieldLogs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldLogs]);

  const { BuiltComponent: HeaderSecondary } = usePassedComponent(GlobalSecondaryHeader, [selected, consoleData], {
    selected,
    updateExportedStatus,
    updateApproveStatus,
    selectedLogs,
    consoleData,
  });

  return (
    <InteriorPage
      error={error}
      navTitle={"Field Management"}
      navIcon={<Users color="#fff" size={14} />}
      pageTitle="Field Logs"
      pageSubtitlePrimaryContent={() => <Submenu />}
      pageTitleSecondaryContent={HeaderSecondary}
      pageTitlePrimaryContent={!!userCan("payroll-review-time") && FieldLogSelection}
    >
      <TwoWeekMessage dateRange={dateRange} />
      <ControlBar
        getFieldLogs={getFieldLogs}
        loading={loading}
        projectId={selectedProject}
        page={page}
        setPage={setPage}
        itemsPerPage={itemsPerPage}
        total={total}
        viewAll={viewAll}
        setViewAll={setViewAll}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <ReportingConsole
        projectId={selectedProject}
        isFieldlog
        consoleData={consoleData}
        error={error}
        loading={loading}
      />

      <div className="position-relative">
        <Listing fieldLogs={fieldLogs} loading={loading} isGlobal={true} viewAll={viewAll} />
        <Pagination
          currentPage={page}
          setCurrentPage={setPage}
          total={total}
          itemsPerPage={!!viewAll ? total : itemsPerPage}
          items={fieldLogs}
        />
      </div>
    </InteriorPage>
  );
};

export default FieldLogs;
