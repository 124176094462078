import React from "react";
import PropTypes from "prop-types";
import styles from "../styles.module.scss";
import useEditSchedule from "../../../hooks/useEditSchedule.js";
import { Loader } from "component-library";

const EditSchedule = ({ bidObject, setProject, refreshProject, setIsEditing }) => {
  const { fields, handleSubmit, loading } = useEditSchedule(setProject, refreshProject, bidObject, setIsEditing);

  return (
    <div className="container py-3">
      {!!loading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-12 col-lg-9 order-1 order-lg-0">
            {fields && (
              <form onSubmit={handleSubmit} id="edit-schedule-form">
                <div className={styles.editFormHeader}>
                  <h2 className={`${styles.sectionTitle} ${styles.sectionTitleAdjust}`}>Project Dates</h2>
                </div>

                {fields}
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

EditSchedule.propTypes = {
  setIsEditing: PropTypes.func.isRequired,
};

export default EditSchedule;
