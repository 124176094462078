import React from "react";
import styles from "./styles.module.scss";

const Grabby = () => {
  return (
    <div className={`${styles.grabby} ${styles.entryGrabby}`}>
      <div className={styles.grabbyRow}>
        <span className={styles.grabbyDot}></span>
        <span className={styles.grabbyDot}></span>
      </div>
      <div className={styles.grabbyRow}>
        <span className={styles.grabbyDot}></span>
        <span className={styles.grabbyDot}></span>
      </div>
      <div className={styles.grabbyRow}>
        <span className={styles.grabbyDot}></span>
        <span className={styles.grabbyDot}></span>
      </div>
    </div>
  );
};

export default Grabby;
