import React from "react";
import HourlyBlock from "../../WeeklyReportEdit/ReportDates/DateFields/HourlyWeather/HourlyBlock";
import styles from "./styles.module.scss";

const Weather = ({ day, weather, weatherLoading }) => {
  const todaysWeather = weather?.find((weather) => weather?.date === day?.work_day?.date);

  const formatDisplay = (time) => {
    if (!time) return "";

    const timeArray = time.split(":");
    const hour = timeArray[0];
    let ampm = "am";

    let formattedHour = hour;
    if (hour === "00") {
      formattedHour = "12";
    } else if (hour > 12) {
      formattedHour = hour - 12;
      ampm = "pm";
    }

    const formattedTime = `${formattedHour}${ampm}`;
    return formattedTime;
  };

  const formattedTime = (time) => {
    if (!time) return "";
    const date = new Date(time);
    const formatted = date.toLocaleTimeString([], { hour: "numeric", minute: "numeric", hour12: false });
    return formatted || "";
  };

  const getStartTime = () => {
    let startWeather = formattedTime(day?.start_weather);
    if (!day?.start_weather) {
      startWeather = formattedTime(todaysWeather?.weather[0]?.time_of_reading);
    }
    return startWeather;
  };

  const getEndTime = () => {
    let endWeather = formattedTime(day?.end_weather);
    if (!day?.end_weather) {
      endWeather = formattedTime(todaysWeather?.weather[todaysWeather.weather?.length - 1]?.time_of_reading);
    }
    return endWeather;
  };

  return (
    <>
      {weatherLoading ? (
        <div className={styles.loading}>Loading Weather...</div>
      ) : (
        <>
          {!!todaysWeather?.weather?.length && (
            <>
              <strong className="pe-4">Hourly Weather</strong>
              <span>{`${formatDisplay(getStartTime())} - ${formatDisplay(getEndTime())}`}</span>
              <div className={styles.weather}>
                {todaysWeather?.weather?.map((condition) => (
                  <>
                    {formattedTime(condition?.time_of_reading) >= getStartTime() &&
                      formattedTime(condition?.time_of_reading) <= getEndTime() && (
                        <div key={`${condition?.startTime}-start_time`}>
                          <HourlyBlock conditions={condition} />
                        </div>
                      )}
                  </>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Weather;
