import Project from "../columns/Project";

const project = {
  headerName: "Project",
  field: "project",
  cellRenderer: Project,
  suppressMenu: true,
  sortable: true,
  minWidth: 275,
};

export default project;
