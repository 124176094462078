import React from "react";
import { displayDate } from "component-library";

import { DateTime } from "luxon";
const Submitted = ({ data }) => {
  return (
    <div className="d-flex align-items-center">
      <span className="me-3 text-gray2">{displayDate(data?.date_submitted)}</span>
      <i className="text-gray2">{DateTime.fromJSDate(new Date(data?.date_submitted)).toFormat("t")}</i>
    </div>
  );
};

export default Submitted;
