import { Joi } from "component-library";

const schema = {
  read_low: Joi.any(),
  bid_tracking: Joi.any(),
  bid_documents: Joi.any(),
  job_number: Joi.when("read_low", {
    is: Joi.any().valid(1),
    then: Joi.saneString().required().label("Job Number"),
    otherwise: Joi.saneString().label("Job Number"),
  })
};

const initialData = {
  read_low: 0,
  bid_tracking: [],
  job_number: "",
};

export { schema, initialData };
