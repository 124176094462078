import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { useAuth } from "component-library";
import FolderIcon from "../../../../components/FolderIcon";

const FolderLink = ({ folder }) => {
  const { id } = useParams();
  const { config } = useAuth();

  return (
    <Link
      to={`/project/${id}/documents/folder/${folder}`}
      className={`${styles.folderLink} ${!config?.folders?.find((f) => f.name === folder) && styles.customFolder}`}
    >
      <FolderIcon folder={folder} />
      <div>{folder}</div>
    </Link>
  );
};

export default FolderLink;
