import React from "react";
import Contacts from "../../Contacts/Contacts";
import { Submittal as S, useAuth } from "component-library";
import useSubmittalFunctions from "../../hooks/useSubmittalFunctions";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import { Briefcase } from "react-feather";

const FieldSubmittal = () => {
  const { config, userCan } = useAuth();
  const { loading, error, pageTitle, submittal, projectOwner } = useSubmittalFunctions();
  return (
    <InteriorPage
      loading={loading}
      error={error}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle={"Submittals"}
      pageSubtitlePrimaryContent={() => <div className="lg font-weight-bold mb-0">{submittal.name}</div>}
    >
      <S.SubmittalSpecPlans submittal={submittal} />
      {submittal && (
        <div className="row mb-4">
          <div className="col-lg-12">
            <div className="border-bottom mb-3 pb-3">
              <h5 className="pt-3 text-gray2 lg font-weight-bold">Description</h5>
              <p className="med">{submittal.description}</p>
            </div>

            <div className="border-bottom mb-3 pb-3">
              <h5 className="pt-3 text-gray2 lg font-weight-bold">Contacts</h5>
              <Contacts
                projectOwner={projectOwner}
                contacts={submittal.contacts}
                source={submittal.source}
                submittal={submittal}
              />
            </div>

            <div className="mb-3 pb-3 border-bottom">
              <h5 className="pt-3 text-gray2 lg font-weight-bold">Attachments</h5>
              <S.Attachments
                submittal={submittal}
                attachments={submittal.attachments}
                config={config}
                userCan={userCan}
              />
            </div>
          </div>
        </div>
      )}
    </InteriorPage>
  );
};

export default FieldSubmittal;
