import { useState } from "react";

const useBidTodoValidation = () => {
  const [bidTodoErrors, setBidTodoErrors] = useState({});

  const submitBidTodos = (formData) => {
    setBidTodoErrors({});
    let bidErrors = {};
    if (
      formData.bid_todo_ids.includes(12) ||
      formData.bid_todo_ids.includes(4)
    ) {
      const todo = formData.bid_todos.find(
        (todo) => todo.bid_todo_id === 4 || todo.bid_todo_id === 12
      );

      if (!!!todo.bid_bond_amount_type) {
        bidErrors["bid_bond_amount_type"] = "Bid Bond Amount is required";
      } else if (todo.bid_bond_amount_type === "other") {
        if (!!!todo.bid_bond_amount) {
          bidErrors["bid_bond_amount_type"] = "Bid Bond Amount is required";
        }
      }
    }

    if (formData.bid_todo_ids.includes(1)) {
      const todo = formData.bid_todos.find((todo) => todo.bid_todo_id === 1);
      if (!!!todo.pre_bid_date) {
        bidErrors["pre_bid_date"] = "Pre-bid date is required";
      }
      if (!!!todo.pre_bid_time) {
        bidErrors["pre_bid_time"] = "Pre-bid time is required";
      }

      if (!!!todo.pre_bid_location) {
        bidErrors["pre_bid_location"] = "Pre-bid location is required";
      }
    }
    setBidTodoErrors(bidErrors);
    return bidErrors;
  };

  return {
    bidTodoErrors,
    submitBidTodos,
  };
};

export default useBidTodoValidation;
