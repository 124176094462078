import Name from "../columnRenders/Name";

const name = {
  headerName: "Description",
  field: "bid_item_name",
  cellRenderer: Name,
  flex: 1,
};

export default name;
