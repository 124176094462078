import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import styles from "../styles.module.scss";
import PropTypes from "prop-types";
import AddContact from "../../../../components/Contacts/FormAddContact";
import LocationControl from "../../../../components/Locations/LocationControl";
import ContactControl from "../../../../components/Contacts/ContactControl";
import ContactSubNav from "../../../../components/ContactSubNav";
import ContactMenuItems from "../../ContactMenuItems";
import { ConfirmModal, useAuth } from "component-library";
import EditContactRole from "../../ProjectDirectory/EditContactRole";
import EditContact from "../../../../components/Contacts/EditContactForm";
import CompanyLocationNav from "../../../../components/Company/Locations/CompanyLocationNav";
import AddLocation from "../../../../components/ContactsAdmin/AddLocation";
import stakeHolders from "../../../../utils/stakeholders";

const MainProjectContacts = ({ data, setFields, error, renderLocationField }) => {
  const { config } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [editContactOpen, setEditContactOpen] = useState(false);
  const [editRoleOpen, setEditRoleOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [editingLocation, setEditingLocation] = useState(false);
  const [archiveLocation, setArchiveLocation] = useState();
  const [editedLocation, setEditedLocation] = useState();
  const [editingContact, setEditingContact] = useState();
  const [updatedContact, setUpdatedContact] = useState();
  const [removingLocationConfirm, setRemovingLocationConfirm] = useState(false);
  const [projectContacts, setProjectContacts] = useState([]);

  useEffect(() => {
    if (data?.project_contacts || data?.project_owners) {
      setProjectContacts([...data.project_owners, ...data.project_contacts]);
    }
  }, [data?.project_contacts, data?.project_owners]);

  useEffect(() => {
    if (!!updatedContact) {
      setFields({
        ...data,
        project_contacts: data.project_contacts.map((contact) => {
          if (contact.contact.id === updatedContact.id) {
            return {
              ...contact,
              contact: updatedContact,
            };
          } else {
            return contact;
          }
        }),
        project_owners: data.project_owners.map((contact) => {
          if (contact.contact.id === updatedContact.id) {
            return {
              ...contact,
              contact: updatedContact,
            };
          } else {
            return contact;
          }
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedContact]);

  const addContact = (newContact, role) => {
    setFields({
      ...data,
      project_contacts: [
        ...data.project_contacts,
        {
          ...newContact,
          contact: newContact,
          role: role,
        },
      ],
    });
    setModalOpen(false);
  };

  const removeContact = () => {
    const owner = data.project_owners.find((owner) => owner.id === editingContact.id);
    setFields({
      ...data,
      project_contacts: data.project_contacts.filter((c) => c.id !== editingContact.id),
      project_owners: data.project_owners.filter((c) => c.id !== editingContact.id),
      project_owner_ids: data.project_owner_ids.filter((c) => c !== owner?.contact?.id),
    });
    setConfirmModalOpen(false);
  };

  const removeLocation = () => {
    setFields({
      ...data,
      contract_owner: null,
      contract_owner_id: 0,
    });
    setRemovingLocationConfirm(false);
  };

  const updateContacts = (updatedContacts) => {
    setFields({
      ...data,
      project_contacts: updatedContacts,
    });
  };

  useEffect(() => {
    if (!!editedLocation) {
      setFields({
        ...data,
        contract_owner: {
          id: editedLocation.location_id,
          name: editedLocation.location_name,
          phone: editedLocation.phone,
          address: {
            address1: editedLocation.address1,
            address2: editedLocation.address2,
            city: editedLocation.city,
            state: editedLocation.state,
            zip: editedLocation.zip,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedLocation]);

  const AddContactButton = ({ role }) => (
    <div className="d-flex circle-file-dropzone ms-4">
      <Button
        variant="outline-secondary"
        className="dropzone justify-content-center align-self-center btn btn-outline-secondary thirteen p-1"
        onClick={() => {
          setSelectedRole(role);
          setModalOpen(true);
        }}
      >
        Add New Contact +
      </Button>
    </div>
  );

  return (
    <>
      <h2 className={styles.heading18}>Main Project Contacts</h2>

      <div className="d-flex pb-4 ms-n2 flex-wrap">
        {!!data.contract_owner ? (
          <div className={`ms-2 ${styles.widthAdjust} position-relative`}>
            <LocationControl location={data.contract_owner} />
            <CompanyLocationNav
              location={data.contract_owner}
              setEditingLocation={() => setEditingLocation(data.contract_owner)}
              archiveLocation={archiveLocation}
              setArchiveLocation={setArchiveLocation}
              archived={data.contract_owner.archived}
              canRemove={true}
              setRemovingLocationConfirm={setRemovingLocationConfirm}
            />
          </div>
        ) : (
          <>
            {renderLocationField({
              name: "contract_owner_id",
              location: data.contract_owner,
              locationObjectKey: "contract_owner",
              label: "Contract Owner",
              settingsField: true,
            })}
          </>
        )}

        {!!projectContacts && !!projectContacts.length && (
          <>
            {projectContacts.map(
              (contact) =>
                (stakeHolders.includes(contact?.role?.name) || !contact.role) && (
                  <div key={contact.id} className={`ms-2 ${styles.widthAdjust} position-relative`}>
                    <ContactControl
                      contact={contact.contact}
                      title={contact?.role?.name || "Legacy Engineer"}
                      role={contact?.role}
                      colDisplay={true}
                    />

                    <ContactSubNav>
                      <ContactMenuItems
                        contact={contact}
                        setEditContactOpen={setEditContactOpen}
                        editContactOpen={editContactOpen}
                        setEditingContact={setEditingContact}
                        setEditRoleOpen={setEditRoleOpen}
                        setConfirmModalOpen={setConfirmModalOpen}
                        canRemove={true}
                      />
                    </ContactSubNav>
                  </div>
                )
            )}
          </>
        )}

        <AddContactButton />
      </div>
      {!!editingLocation && (
        <AddLocation
          isAdmin={false}
          company={{
            name: data.contract_owner.company_name,
            id: data.contract_owner.company_id,
          }}
          location={
            !!data.contract_owner && !!data.contract_owner.location_name
              ? {
                  ...data.contract_owner,
                  id: data.contract_owner.location_id,
                  name: data.contract_owner.location_name,
                  phone: data.contract_owner.location_phone,
                  address: {
                    address1: data.contract_owner.address1,
                    address2: data.contract_owner.address2,
                    city: data.contract_owner.city,
                    state: data.contract_owner.state,
                    zip: data.contract_owner.zip,
                  },
                }
              : data.contract_owner
          }
          setLocation={setEditedLocation}
          setAddingLocation={setEditingLocation}
        />
      )}
      {!!editContactOpen && (
        <EditContact
          contact={
            !editingContact.contact.locations
              ? {
                  ...editingContact.contact,
                  company: {
                    location_id: editingContact.location_id,
                    address1: editingContact.address1,
                    address2: editingContact.address2,
                    city: editingContact.city,
                    state: editingContact.state,
                    zip: editingContact.zip,
                  },
                }
              : {
                  ...editingContact.contact,
                  company: {
                    ...editingContact.contact.locations[0],
                    location_id: editingContact.contact.locations[0].id,
                    address1: editingContact.contact.locations[0].address.address1,
                    address2: editingContact.contact.locations[0].address.address2,
                    city: editingContact.contact.locations[0].address.city,
                    state: editingContact.contact.locations[0].address.state,
                    zip: editingContact.contact.locations[0].address.zip,
                  },
                }
          }
          setAddingContact={() => setEditContactOpen(false)}
          setEditedContact={setUpdatedContact}
        />
      )}
      {!!editRoleOpen && (
        <EditContactRole
          contact={editingContact}
          contacts={data.project_contacts}
          setContacts={(updatedContacts) => updateContacts(updatedContacts)}
          setEditingRole={setEditRoleOpen}
          roles={config.roles.filter((role) => stakeHolders.includes(role.name))}
        />
      )}
      {!!confirmModalOpen && (
        <ConfirmModal
          showConfirmation={confirmModalOpen}
          setShowConfirmation={setConfirmModalOpen}
          confirmFunction={() => removeContact()}
          error={error}
          name="Remove Project Contact"
          message={`Are you sure you want to remove ${editingContact.contact.first_name} ${editingContact.contact.last_name} from the project?`}
        />
      )}
      {!!removingLocationConfirm && (
        <ConfirmModal
          showConfirmation={removingLocationConfirm}
          setShowConfirmation={setRemovingLocationConfirm}
          confirmFunction={() => removeLocation()}
          error={error}
          name="Remove Project Source"
          message={`Are you sure you want to remove ${data.contract_owner.company_name}: ${data.contract_owner.name} from the project?`}
        />
      )}
      {modalOpen && (
        <AddContact
          contacts={projectContacts}
          show={modalOpen}
          hide={() => setModalOpen(false)}
          addContact={(newContact, role) => addContact(newContact, role)}
          error={error}
          selectedRole={selectedRole}
          roles={config.roles.filter((role) => stakeHolders.includes(role.name))}
        />
      )}
    </>
  );
};

export default MainProjectContacts;

MainProjectContacts.propTypes = {
  data: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
  error: PropTypes.any,
};
