import React from "react";
import styles from "./styles.module.scss";

const DayStat = ({ textStyle, data, label }) => {
  return (
    <div className={styles.statsWrap}>
      <span className={`${textStyle} ${styles.text}`}>{data}</span>
      <span>{label}</span>
    </div>
  );
};

export default DayStat;
