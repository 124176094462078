import React from "react";
import Chiclet from "./Chiclet";
import StatusItemChart from "./StatusItemChart";
import styles from "./styles.module.scss";
import getJobName from "./getJobName";
import FooterLinkToProject from "./FooterLinkToProject";

const StatusItem = ({ job }) => {
  return (
    <Chiclet>
      <div>
        <h1 className={styles["job-title"]}>{getJobName(job)}</h1>
        {job && job.project_status && (
          <StatusItemChart status={job.project_status} />
        )}
      </div>
      <FooterLinkToProject job={job} />
    </Chiclet>
  );
};

export default StatusItem;
