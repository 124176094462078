import React from "react";
import { FileText } from "react-feather";
import styles from "./styles.module.scss";

const Header = ({ title = "" }) => {
  return (
    <div className={styles.formHeader}>
      <div className={styles.formName}>
        <FileText className="me-2" size="18" color="#0071ce" />
        <div className={styles.nameText}>{title}</div>
      </div>
    </div>
  );
};

export default Header;
