import React, { useState } from "react";
import useSRFRequirement from "./hooks/useSRFRequirement";
import PropTypes from "prop-types";

const SRFRequirement = ({ task, setTodos, setBid, bid }) => {
  const [docLoading, setDocLoading] = useState();
  const {
    fields,
    renderSubmitButton,
    handleSubmit,
  } = useSRFRequirement(bid, task, setTodos, setBid, setDocLoading);

  return (
    <form className="row position-relative" onSubmit={handleSubmit}>
      <div className="col-md-8 position-static">{fields}</div>
      <div className="col-md-4 d-flex flex-column justify-content-end text-end p-3">
        <div className="flex-end">{renderSubmitButton("Mark Complete", !!docLoading)}</div>
      </div>
    </form>
  );
};


SRFRequirement.propTypes = {
  task: PropTypes.object,
  setTodos: PropTypes.func.isRequired,
  setBid: PropTypes.func.isRequired,
};

export default SRFRequirement;
