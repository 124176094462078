import React from "react";
import { BeatLoader } from "react-spinners";
import { Alert } from "react-bootstrap";
import styles from "./styles.module.scss";

const GridWrapper = ({ loading, error, icon, title, children }) => {
  return (
    <>
      {!!error && <Alert variant="danger">{error}</Alert>}
      <section>

      <h2 className={styles.sectionTitle}>
            {icon}
            {title}
          </h2>

      {!!loading ? (
        <div className={styles.loaderWrapper}>
          <BeatLoader color="#0071ce" />
        </div>
      ) : (<>
          {children}
          </>
      )}
      </section>
    </>
  );
};

export default GridWrapper;
