import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { HeaderButton } from "component-library";
import { Archive, ArrowRightCircle, XCircle } from "react-feather";
import BidHeader from "../../_bidManagement/BidHeader";
import ProgressTracker from "../ProgressTracker";
import InteriorPage from "../pageLayout/InteriorPage";
import { Tag } from "react-feather";
import useCloseBid from "../../_bidManagement/hooks/useCloseBid";
import useBid from "../../_bidManagement/hooks/useBid";

const CloseBid = () => {
  const { id } = useParams();
  const [todos, setTodos] = useState([]);
  const [completedTodos, setCompletedTodos] = useState([]);
  const [navTitle, setNavTitle] = useState("");
  const [submitText, setSubmitText] = useState("Archive Bid");
  const [docLoading, setDocLoading] = useState(false);
  const { bid, loading, archived } = useBid();
  const { fields, handleSubmit, formControlData, closeLoading, closeData, error } = useCloseBid(bid, setDocLoading);

  useEffect(() => {
    if (!!bid) {
      setTodos(bid.bid_todos);
      setNavTitle(bid.name);
    }
  }, [bid]);

  useEffect(() => {
    if (!!todos) {
      setCompletedTodos(todos.filter((t) => t.complete === 1));
    }
  }, [todos]);

  useEffect(() => {
    if (!!formControlData) {
      if (!!formControlData.read_low) {
        setSubmitText("Create Project");
      } else {
        setSubmitText("Archive Bid");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formControlData]);

  const CloseHeaderButtons = () => {
    return (
      <>
        {!!bid?.bid_closed ? (
          <div className="d-flex align-items-center">
            {bid?.status === "precon" || bid?.status === "project" ? (
              <HeaderButton
                linkTo={`/project/${id}`}
                text="Go to Project"
                disabled={docLoading}
                icon={<ArrowRightCircle size={16} color="#0071ce" />}
              />
            ) : (
              <HeaderButton
                linkTo={`/bid/${id}`}
                text="Back to Bid"
                disabled={docLoading}
                icon={<ArrowRightCircle size={16} color="#0071ce" />}
              />
            )}
          </div>
        ) : (
          <div className="d-flex align-items-center">
            <HeaderButton
              linkTo={`/bid/${id}`}
              text="Cancel"
              icon={<XCircle size="16" color="#0071ce" />}
              disabled={docLoading}
            />
            <HeaderButton
              formId="close-bid-form"
              text={submitText}
              icon={<Archive size={16} color="#0071ce" />}
              disabled={!error && (!!closeData || !!closeLoading || docLoading)}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <InteriorPage
      loading={loading}
      navTitle={navTitle}
      pageTitle="Close Bid"
      navIcon={<Tag color="#fff" size={14} />}
      pageTitleSecondaryContent={!!bid ? CloseHeaderButtons : null}
    >
      {!!archived || bid?.status === "precon" || bid?.status === "project" ? (
        <div className="d-flex justify-content-center w-100 alert">
          {!!archived ? (
            <p>This bid has already been closed and listed as inactive.</p>
          ) : (
            <p>This bid has already been converted into a project.</p>
          )}
        </div>
      ) : (
        <>
          {!!bid && (
            <>
              {!!todos && !!completedTodos && (
                <div className="unpad-section">
                  <div className="large-form-section">
                    <ProgressTracker
                      completed={completedTodos.length}
                      total={todos.length}
                    />
                  </div>
                </div>
              )}
              <BidHeader noEdit project={bid} />
              <form
                className="rounded pb-2"
                onSubmit={handleSubmit}
                id="close-bid-form"
              >
                {fields}
              </form>
            </>
          )}
        </>
      )}
    </InteriorPage>
  );
};

export default CloseBid;
