import React from "react";
import taskWidths from "../../gridWidths/taskWidths";
import styles from "./styles.module.scss";
import ColumnHeader from "../../ColumnHeader";

function TaskHeaders() {
  return (
    <div className={styles.headerWrapper}>
      <ColumnHeader text="task" width={taskWidths.task} />
      <ColumnHeader text="role" width={taskWidths.role} />
      <ColumnHeader text="equipment" width={taskWidths.equipment} />
      <ColumnHeader text="hours" width={taskWidths.hours} />
    </div>
  );
}

export default TaskHeaders;
