import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BidDetailsForm from "./../../_bidManagement/BidDetailsForm";
import InteriorPage from "../pageLayout/InteriorPage";
import { Tag, ArrowLeftCircle } from "react-feather";
import BidHeaderLinks from "../../_bidManagement/BidHeaderLinks";
import useBid from "../../_bidManagement/hooks/useBid";
import { HeaderButton } from "component-library";

const CreateBidDetails = () => {
  const { id } = useParams();
  const { bid, archived, loading, error } = useBid();
  const [navTitle, setNavTitle] = useState();
  const [docLoading, setDocLoading] = useState(false);

  useEffect(() => {
    if (bid) {
      setNavTitle(`${bid.name} - Edit`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid]);

  const BidHeaderSecondary = () => <BidHeaderLinks bid={!!bid && bid} archived={archived} docLoading={docLoading} />;

  const BackButton = () => {
    return (
      <HeaderButton
        linkTo={`/bid/${id}`}
        icon={<ArrowLeftCircle size="16" color="#0071ce" />}
        text="Back to Bid"
      />
    );
  };

  return (
    <InteriorPage
      navTitle="Bids"
      loading={loading}
      error={error}
      pageTitle={navTitle}
      backLink={`/bid/${id}`}
      navIcon={<Tag color="#fff" size={14} />}
      fillScreen
      pageTitleSecondaryContent={
        !!archived || bid?.status === "precon" || bid?.status === "project" ? BackButton : BidHeaderSecondary
      }
    >
      {!!archived || bid?.status === "precon" || bid?.status === "project" ? (
        <div className="d-flex justify-content-center w-100 alert">
          <h6>You cannot edit inactive bids.</h6>
        </div>
      ) : (
        <>{!!bid && <BidDetailsForm initialBid={bid} setDocLoading={setDocLoading} />}</>
      )}
    </InteriorPage>
  );
};

export default CreateBidDetails;
