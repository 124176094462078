import React from "react";
import styles from "./styles.module.scss";
import { displayDate } from "component-library";
import { DateTime } from "luxon";

const UpdatedDate = ({ data }) => {
  let parseDate = Date.parse(data.updated_at);
  let newDate = new Date(parseDate);
  let formattedTime = DateTime.fromJSDate(new Date(newDate)).toFormat("t");
  return (
    <span className={styles.updatedText}>
      {displayDate(data.updated_at)} {formattedTime}
    </span>
  );
};

export default UpdatedDate;
