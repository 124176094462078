import {
  name,
  company_name,
  company_categories,
  email,
  phone,
} from "./columnDefs";

const columnDefs = [
  name,
  company_name,
  company_categories,
  email,
  phone,
];

export default columnDefs;