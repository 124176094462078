/**
 * parses a currency string and turns it into a float, removing dollar signs and commas
 * @param  {String} currencyString a standard string for currency
 * @return {Number} returns a currency value as a float
 */

const getFloatFromCurrency = (currencyString) => {
  const withoutCommas = currencyString.replace(/,/g, '');
  const without$ = withoutCommas.replace('$', '');
  return parseFloat(without$);
};

export default getFloatFromCurrency;