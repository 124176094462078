import React from "react";
import ModalWrapper from "../ModalWrapper";
import useUploadPhoto from "./useUploadPhoto";
import PropTypes from "prop-types";

const UploadPhotoModal = ({
  project,
  showModal,
  setShowModal,
  albumPhotosList,
  setAlbumPhotosList,
  setMessage,
  photosList,
  setPhotosList,
  refreshLists,
  albumId,
}) => {
  const { headerContent, bodyContent, footerContent, onHide } = useUploadPhoto({
    project,
    albumPhotosList,
    setAlbumPhotosList,
    setShowModal,
    setMessage,
    photosList,
    setPhotosList,
    refreshLists,
    albumId,
    showModal,
  });

  return (
    <ModalWrapper
      showModal={showModal}
      headerContent={headerContent}
      bodyContent={bodyContent}
      footerContent={footerContent}
      onHide={() => onHide()}
    />
  );
};

UploadPhotoModal.propTypes = {
  albumPhotosList: PropTypes.array,
  setAlbumPhotosList: PropTypes.func,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default UploadPhotoModal;
