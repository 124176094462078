import React from "react";
import styles from "./styles.module.scss";
import Select from "react-select";
import { dropdownStylesWithWidthAndColor } from "component-library";
import PropTypes from "prop-types";
import convertMinutesToTime from "../../../../../../../utils/convertMinutesToTime";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const TimeEntry = ({ entry }) => {
  const { id } = useParams();
  const getEquipmentDesc = (equipment) => {
    const equipmentString =
      !!equipment?.display_description && !!equipment?.equip_num
        ? `${equipment?.equip_num}-${equipment?.display_description}`
        : `${equipment?.display_description}`;
    if (equipmentString.length) {
      return equipmentString;
    }
    if (!equipmentString.length && !!equipment?.equip_name) {
      return equipment?.equip_name;
    }
    return "No Equipment";
  };
  return (
    <div className={styles.timeEntry}>
      <div className="d-flex align-items-center">
        <div className={`${styles.entryName} text-limiter`}>
          <Link
            to={`project/${id}/field-management/timesheet/${entry.time_sheet_id}/foreman/${entry.task_data.foreman_id}`}
          >{`${entry?.user?.first_name} ${entry?.user?.last_name}`}</Link>
        </div>
      </div>
      <div className="d-flex flex-grow-1 me-2 align-items-center">
        <Select
          options={[
            {
              label: getEquipmentDesc(entry?.equipment),
            },
          ]}
          styles={dropdownStylesWithWidthAndColor("auto", "#D3D3D3")}
          className={`${styles.select} flex-shrink-1 flex-grow-1 mx-2`}
          defaultValue={{
            label: getEquipmentDesc(entry?.equipment),
          }}
          isDisabled
        />

        {!!entry?.secondary_equipment && (
          <>
            <Select
              options={[
                {
                  label: getEquipmentDesc(entry?.secondary_equipment),
                },
              ]}
              defaultValue={{
                label: getEquipmentDesc(entry?.secondary_equipment),
              }}
              styles={dropdownStylesWithWidthAndColor("auto", "#D3D3D3")}
              className={`${styles.select} flex-shrink-1 flex-grow-1 mx-2`}
              isDisabled
            />
          </>
        )}
        <Select
          options={[
            {
              label: entry?.labor_types?.name,
            },
          ]}
          defaultValue={{
            label: entry?.labor_types?.name,
          }}
          styles={dropdownStylesWithWidthAndColor("auto", "#D3D3D3")}
          className={`${styles.select} flex-shrink-1 flex-grow-1 mx-2`}
          isDisabled
        />
      </div>
      <div className={styles.entryHours}>{convertMinutesToTime(entry.minutes)}</div>
    </div>
  );
};

TimeEntry.propTypes = {
  entry: PropTypes.object,
};

export default TimeEntry;
