import React from "react";
import { Alert } from "react-bootstrap";
import { AgGridTable, useAuth } from "component-library";
import ContactsFilters from "./ContactsFilters";
import useContactsFilters from "./hooks/useContactsFilters";
import companiesAdminListConfig from "../../config/companiesAdminListConfig";
import { useNavigate } from "react-router-dom";
import UnauthorizedCard from "../UnauthorizedCard";

const CompaniesAdmin = ({
  companies,
  error,
  loading,
  successMessage,
  setSuccessMessage,
  setError,
  searchCompanies,
  searchLoading,
}) => {
  const { userCan, user } = useAuth();

  const { filteredByLetter, updateCategory, updateLetter, letter, selectedCategories } = useContactsFilters(
    companies,
    "company_name"
  );

  let history = useNavigate();

  const handleCellClick = ({ data, column }) => {
    if (!["location_phone"].includes(column?.colId)) {
      let url = `/directory/company/${data.company_id}`;
      history(url);
    }
  };

  return (
    <div>
      {userCan("contact-list") ? (
        <div className="position-relative">
          <ContactsFilters
            updateCategory={updateCategory}
            updateLetter={updateLetter}
            letter={letter}
            selectedCategories={selectedCategories}
            searchFunction={searchCompanies}
            searchLoading={searchLoading}
          />

          {!!error && (
            <Alert variant="danger" onClick={() => setError(false)}>
              {error}
            </Alert>
          )}
          {!!successMessage && (
            <Alert variant="success" onClick={() => setSuccessMessage(false)} dismissible>
              {successMessage}
            </Alert>
          )}
          <div className="position-relative">
            <AgGridTable
              gridData={filteredByLetter}
              loading={!!loading || !!searchLoading}
              gridConfig={companiesAdminListConfig}
              handleCellClick={handleCellClick}
              id={`${user.id}_company_admin_listing`}
              presetStyle="sortableTable"
            />
          </div>
        </div>
      ) : (
        <UnauthorizedCard />
      )}
    </div>
  );
};

export default CompaniesAdmin;
