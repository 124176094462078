import React, { useState, useEffect } from "react";
import HourlyBlock from "./HourlyBlock";
import { Play, Octagon } from "react-feather";
import styles from "./styles.module.scss";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import sectionStyles from "../styles.module.scss";

const HourlyWeather = ({ weather, day, updateDateField }) => {
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const formattedTime = (time) => {
    const date = new Date(time);
    const formatted = date.toLocaleTimeString([], { hour: "numeric", minute: "numeric", hour12: false });

    return formatted || "";
  };

  useEffect(() => {
    let startWeather = formattedTime(day?.start_weather);
    if (!day?.start_weather) {
      startWeather = formattedTime(weather?.weather[0]?.time_of_reading);
      updateDateField(day, "start_weather", weather?.weather[0]?.time_of_reading);
    }

    setStartTime(startWeather);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day?.start_weather]);

  useEffect(() => {
    let endWeather = formattedTime(day?.end_weather);
    if (!day?.end_weather) {
      endWeather = formattedTime(weather?.weather[weather?.weather?.length - 1]?.time_of_reading);
      updateDateField(day, "end_weather", weather?.weather[weather?.weather?.length - 1]?.time_of_reading);
    }

    setEndTime(endWeather);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day?.end_weather]);

  return (
    <section className={sectionStyles.fieldSection}>
      <div className={styles.timeSelectWrapper}>
        {!!weather?.weather?.length && (
          <>
            <h3 className={styles.weatherTitle}>Hourly Weather</h3>
            <div className="d-flex align-items-center me-3 form-group">
              <Play color="#259FAD" size={16} className="me-2" />
              <TimePicker
                className={styles.timePickerTest}
                clearIcon={null}
                clockIcon={null}
                disableClock={true}
                format="hh:00:a"
                value={startTime}
                onChange={(value) => {
                  if (!value) return;
                  const valueDate = weather?.weather[0]?.time_of_reading.split(" ");
                  updateDateField(day, "start_weather", `${valueDate[0]} ${value}:00`);
                }}
              />
            </div>
            <div className="d-flex align-items-center form-group ">
              <Octagon color="#FF6700" size={16} className="me-2" />
              <TimePicker
                className={styles.timePicker}
                clearIcon={null}
                clockIcon={null}
                disableClock={true}
                format="hh:00:a"
                value={endTime}
                onChange={(value) => {
                  if (!value) return;
                  const valueDate = weather?.weather[weather?.weather?.length - 1]?.time_of_reading.split(" ");
                  updateDateField(day, "end_weather", `${valueDate[0]} ${value}:00`);
                }}
              />
            </div>
          </>
        )}
      </div>
      {!!weather?.weather?.length ? (
        <div className={styles.hourlyWeatherWrapper}>
          <div className="d-flex align-items-center mx-4">
            {weather.weather.map((condition) => (
              <>
                {formattedTime(condition?.time_of_reading) >= startTime &&
                  formattedTime(condition?.time_of_reading) <= endTime && (
                    <div key={`${condition?.startTime}-start_time`}>
                      <HourlyBlock conditions={condition} />
                    </div>
                  )}
              </>
            ))}
          </div>
        </div>
      ) : (
        <div>No weather conditions found.</div>
      )}
    </section>
  );
};

export default HourlyWeather;
