import React from "react";
import ColumnHeader from "../../ColumnHeader";
import timesheetWidths from "../../gridWidths/timesheetWidths";
import { MessageSquare } from "react-feather";
import styles from "./styles.module.scss";

const TimesheetHeaders = ({ isCrewLog }) => {
  return (
    <div className={styles.headerWrapper}>
      {!!isCrewLog && <ColumnHeader text="date" width={timesheetWidths.date} />}
      <ColumnHeader text="id" width={timesheetWidths.id} />
      {!isCrewLog && <ColumnHeader text="crew member" width={timesheetWidths.crew_member} />}
      {!!isCrewLog && <ColumnHeader text="job #" width={timesheetWidths.job_number} />}
      {!!isCrewLog && <ColumnHeader text="project" width={timesheetWidths.project_name} />}
      {!!isCrewLog && <ColumnHeader text="foreman" width={timesheetWidths.foreman} />}
      <ColumnHeader text="per diem" width={timesheetWidths.per_diem} />
      <ColumnHeader text="clock in" width={timesheetWidths.clock_in} />
      <ColumnHeader text="clock out" width={timesheetWidths.clock_out} />
      <ColumnHeader text="lunch" width={timesheetWidths.lunch} />
      <ColumnHeader text="unallocated" width={timesheetWidths.unallocated} />
      <ColumnHeader text="hours" width={timesheetWidths.hours} />
      <ColumnHeader icon={<MessageSquare size="16" color="#A7A7A7" />} />
    </div>
  );
};

export default TimesheetHeaders;
