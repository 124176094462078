const project_name = {
  headerName: "Project",
  field: "project_name",
  flex: 1,
  minWidth: 220,
  resizable: true,
  cellStyle: {
    color: "#4F4F4F",
    fontWeight: 600,
    textOverflow: `ellipses`,
    whiteSpace: "nowrap",
  },
};

export default project_name;
