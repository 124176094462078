import React, { useEffect, useState } from "react";
import MinutesInput from "../../FieldLogs/FieldLog/MinutesInput";
import { useFormControl, useAuth } from "component-library";
import { Joi } from "component-library";
import { Button } from "react-bootstrap";
import styles from "../styles.module.scss";
import PropTypes from "prop-types";

const useAddDriveTime = ({ timesheet, setTimesheet, close, projectsList }) => {
  const [driveTime, setDriveTime] = useState(0);
  const [canSubmit, setCanSubmit] = useState(false);
  const { user } = useAuth();

  const schema = {
    fromLocate: Joi.number().required().label("From Location"),
    toLocate: Joi.number().required().label("To Location"),
  };

  const initialData = {
    fromLocate: null,
    toLocate: null,
  };

  const onSubmit = async (formData) => {
    setTimesheet({
      ...timesheet,

      travel_times: [
        ...timesheet.travel_times,
        {
          to_project_id: formData.toLocate,
          from_project_id: formData.fromLocate,
          travel_duration: parseFloat(driveTime),
        },
      ],
    });
    close();
  };

  const { handleSubmit, renderSelectField, setFields, data } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  useEffect(() => {
    if (data.fromLocate && data.toLocate && !!driveTime && driveTime > 0) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [data, driveTime]);

  const fields = (
    <>
      <div className="modal-body">
        <div className="d-flex align-items-center form-group">
          <label htmlFor="startTime" className="me-2 mb-0">
            {user.id === timesheet.user.id
              ? "What is your drive time?"
              : `What is ${timesheet.user.first_name}'s drive time?`}
          </label>
          <MinutesInput minutes={driveTime} setMinutes={setDriveTime} canEdit={true} />
        </div>
        <div>
          {renderSelectField({
            name: "toLocate",
            label:
              user.id === timesheet.user.id
                ? "What Project were you driving to?"
                : `What Project was ${timesheet.user.first_name} driving to?`,
            options: projectsList,
          })}
          {renderSelectField({
            name: "fromLocate",
            label:
              user.id === timesheet.user.id
                ? "What Project were you driving from?"
                : `What Project was ${timesheet.user.first_name} driving from?`,
            options: projectsList,
          })}
        </div>
      </div>
      <div className={styles.driveTimeFooter}>
        <Button
          variant="light-gray"
          onClick={() => {
            close();
          }}
        >
          Cancel
        </Button>
        <Button variant="tertiary" onClick={handleSubmit} disabled={!canSubmit}>
          Add Drive Time
        </Button>
      </div>
    </>
  );

  return {
    fields,
    handleSubmit,
    setFields,
  };
};

useAddDriveTime.propTypes = {
  timesheet: PropTypes.object.isRequired,
  setTimesheet: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  projectsList: PropTypes.array.isRequired,
};

export default useAddDriveTime;
