import React from "react";
import PropTypes from "prop-types";
import RefreshImport from "./RefreshImport";
import styles from "../styles.module.scss";

const ProjectHeadInfo = ({ renderTextField, renderCheckbox, formData }) => {
  return (
    <div>
      <h2 className={styles.heading}>Project Information</h2>

      <div className="d-flex flex-row">
        <div style={{ width: 216 }} className="me-3">
          {renderTextField({
            name: "job_number",
            label: "Job Number",
          })}
        </div>
        <div style={{ flex: 1 }}>
          {renderTextField({
            name: "name",
            label: "Project Name",
            autoComplete: "nope",
          })}
        </div>
      </div>

      {renderTextField({
        name: "description",
        label: "Project Description",
      })}

      <RefreshImport renderTextField={renderTextField} formData={formData} />

      <div className="my-3">
        {renderCheckbox({
          name: "certified_payroll",
          label: "Certified Payroll",
        })}
      </div>
      <div className="my-3">
        {renderCheckbox({
          name: "exclude_from_reporting",
          label: "Exclude from Reporting",
        })}
      </div>
      <div className="my-3">
        {renderCheckbox({
          name: "nonbillable",
          label: "Overhead Job",
        })}
      </div>
      <div className="my-3">
        {renderCheckbox({
          name: "force_field_log_notes",
          label: "Require Notes on all Field Log Tasks",
        })}
      </div>
    </div>
  );
};

export default ProjectHeadInfo;

ProjectHeadInfo.propTypes = {
  renderTextField: PropTypes.func.isRequired,
  renderCheckbox: PropTypes.func.isRequired,
};
