import React from "react";
import styles from "./styles.module.scss";
import DailyAccordion from "../components/DailyAccordion";
import CrewMember from "./CrewMember";

const Crew = ({ loading, error, filteredCrew, active, setActive }) => {

  const HeaderContent = () => (
    <h2 className={styles.title}>
      Crew <span>({filteredCrew?.length || 0})</span>
    </h2>
  )

  return (
    <DailyAccordion
      headerContent={<HeaderContent />}
      error={error}
      loading={loading}
      active={active}
      setActive={setActive}
      wrapperStyles={styles.wrapperNoPadding}
    >
      <>
        {!!filteredCrew?.length ? (
          filteredCrew?.map((crewMember, index) => <CrewMember index={index} crewMember={crewMember} />)
        ) : (
          <>{!error && !loading && <div className="ms-2">No crew found</div>}</>
        )}
      </>
    </DailyAccordion>
  )
}

export default Crew