import React from "react";
import { Briefcase } from "react-feather";
import { FilterSearch, AgGridTable, useAuth } from "component-library";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import useProject from "../../hooks/useProject";
import useQuotes from "./useQuotes";
import { Navigate } from "react-router-dom";
import quotesConfig from "../../../config/quotesConfig";
import gridRowHeights from "../../../utils/gridRowHeights";

const Quotes = () => {
  const { pageTitle, project, PageTag } = useProject();
  const { user } = useAuth();

  const { filterQuotes, filteredQuotes, loading, error, AddQuoteButton, createQuoteError, createQuoteData } =
    useQuotes();

  return (
    <InteriorPage
      navTitle={pageTitle}
      pageTitle="Quotes"
      navIcon={<Briefcase color="#fff" size={14} />}
      error={error || createQuoteError}
      loading={loading}
      pageTitleSecondaryContent={AddQuoteButton}
      NavContent={PageTag}
    >
      {!!createQuoteData && !!createQuoteData.quote && (
        <Navigate to={`/project/${project.id}/quotes/${createQuoteData.quote.id}/edit`} />
      )}
      <div className="d-flex justify-content-end align-items-center mb-3">
        <FilterSearch searchFunction={filterQuotes} inline style={{ minWidth: 300 }} />
      </div>
      {!!project && (
        <AgGridTable
          gridData={filteredQuotes.map((quote) => ({
            ...quote,
            supplier_name: !!quote.supplier ? quote.supplier.name : "",
            adjusted_status: !!quote.used && quote.status === 1 ? 5 : quote.status,
          }))}
          gridConfig={quotesConfig}
          rowHeight={gridRowHeights.medium}
          presetStyle="sortableTable"
          navigateTo={`/project/${project.id}/quotes`}
          loading={loading}
          id={`${user.id}_quotes_listing`}
        />
      )}
    </InteriorPage>
  );
};

export default Quotes;
