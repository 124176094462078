import React from "react";
import { useFormControl, useApi, times, bidApi, Loader } from "component-library";
import { schema } from "../../schema/bidMeeting";
import { Alert } from "react-bootstrap";
import useUpdateTodos from "./useUpdateTodos";

const useBidMeeting = ({ task, setTodos, setBid }) => {
  const { request, data: updateData, loading, error } = useApi(bidApi.completeToDo);
  const { dataError, setDataError } = useUpdateTodos(updateData, setTodos, setBid);

  const initialData = {
    pre_bid_location: task.pre_bid_location,
    pre_bid_date: task.pre_bid_date,
    pre_bid_time: task.pre_bid_time,
  };

  const onSubmit = async (formData, setLoading) => {
    setDataError(false);
    try {
      await request({
        project_id: task.project_id,
        todo_id: task.bid_todo.id,
        pre_bid_attendance_required: 1,
        pre_bid_date: formData.pre_bid_date,
        pre_bid_location: formData.pre_bid_location,
        pre_bid_time: formData.pre_bid_time,
        complete: 1,
      });

      setLoading(false);
    } catch (message) {
      setLoading(false);
    }
  };

  const { handleSubmit, renderSubmitButton, renderTextField, renderSelectField, setFields } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const fields = (
    <>
      <Loader loading={!!loading} />
      {renderTextField({
        name: "pre_bid_date",
        label: "Pre Bid Date",
        placeholder: "mm/dd/yyyy",
        type: "date",
      })}
      {renderSelectField({
        name: "pre_bid_time",
        label: "Pre Bid Time",
        options: times,
      })}
      {renderTextField({
        name: "pre_bid_location",
        label: "Pre Bid Location",
      })}
      {!!error && <Alert variant="danger">{error}</Alert>}
      {!!dataError && <Alert variant="danger">{dataError}</Alert>}
    </>
  );
  return {
    schema,
    initialData,
    fields,
    handleSubmit,
    renderSubmitButton,
    setFields,
  };
};

export default useBidMeeting;
