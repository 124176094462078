export const createInitialState = ({ notes, report }) => {
  return {
    selected_notes: notes,
    dateRange: [new Date(report?.start_date?.replace(/-/g, "/")), new Date(report?.end_date?.replace(/-/g, "/"))],
  };
};

export const useSelectFieldNotesModalReducer = (state, action) => {
  switch (action.type) {
    case "update_selected_notes":
      return {
        ...state,
        selected_notes: action?.updatedNotes,
      };
    case "update_date_range":
      return {
        ...state,
        dateRange: !!action.dateRange?.length
          ? [new Date(action.dateRange[0]), action.dateRange[1] ? new Date(action.dateRange[1]) : ""]
          : [],
      };
    default:
      return state;
  }
};
