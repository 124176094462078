import React from "react";
import { Button, Modal } from "react-bootstrap";
import { PlusCircle, X } from "react-feather";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import useAddTaskForm from "./useAddTaskForm";

const AddTaskForm = ({ bidItems, addingTask, setAddingTask, setMessage }) => {
  const { handleSubmit, fields } = useAddTaskForm({ setAddingTask, setMessage, bidItems });

  return (
    <Modal dialogClassName="modal-lg" animation={false} center show={addingTask} onHide={() => setAddingTask(false)}>
      <Modal.Header className="d-flex align-items-center p-0">
        <div className={styles.header}>
          <h2 className={`med mb-0 ${styles.headerText}`}>
            <PlusCircle color="#0071CE" size={16} /> Add New Task
          </h2>
          <Button onClick={() => setAddingTask(false)} variant="link">
            <X color="#D3D3D3" size={24} />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <form onSubmit={handleSubmit}>{fields}</form>
      </Modal.Body>
    </Modal>
  );
};

AddTaskForm.propTypes = {
  addingTask: PropTypes.bool,
  setAddingTask: PropTypes.func,
  setMessage: PropTypes.func,
};

export default AddTaskForm;
