import React from 'react'
import DailyAccordion from '../components/DailyAccordion';
import styles from "./styles.module.scss";
import EquipmentItem from './EquipmentItem';

const Equipment = ({ active, setActive, equipment, equipmentError, equipmentLoading }) => {

  const HeaderContent = () => (
    <>
      <h2 className={styles.title}>
        Equipment <span>({equipment?.length || 0})</span>
      </h2>
    </>
  );

  return (
    <DailyAccordion
      headerContent={<HeaderContent />}
      active={active}
      setActive={setActive}
      loading={equipmentLoading}
      error={equipmentError}
      wrapperStyles={styles.wrapperNoPadding}
    >
      <>
        {!!equipment?.length ? (
          equipment?.map((single, index) => <EquipmentItem index={index} singleEquipment={single} />)
        ) : (
          <>{!equipmentError && !equipmentLoading && <div className="ms-2">No crew found</div>}</>
        )}
      </>
    </DailyAccordion>
  )
}

export default Equipment