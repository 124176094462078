import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import EntryRow from "./EntryRow";
import { displayDateTimeWithOffset } from "component-library";

const Entry = ({ timelineData, entry, getActivityType }) => {
  const [activityType, setActivityType] = useState();
  const [trailer, setTrailer] = useState();

  useEffect(() => {
    if (!!timelineData) {
      setActivityType(getActivityType(entry?.action));
      setTrailer(timelineData.find((data) => data.category === "Trailers" && data.uid === entry.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry]);

  return (
    <div key={`${entry.uid}-${entry.delivery_timestamp}`} className={styles.resultWrapper}>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          {!!activityType && (
            <img
              className="me-2"
              width="16"
              alt={`event status indicator ${activityType?.label}`}
              src={require(`../../../../assets/telematics/activities/${activityType?.type}.png`)}
            />
          )}
          <h2 className={styles.actionTitle}>
            {activityType?.label === "Vehicle Inspection" ? (
              <>{entry?.pre_or_post === 0 ? "Pre-Trip Vehicle Inspection" : "Post-Trip Vehicle Inspection"}</>
            ) : (
              <>{activityType?.label}</>
            )}
          </h2>
        </div>
        {!!entry?.timestamp && (
          <span className={styles.actionTitle}>{displayDateTimeWithOffset(entry.timestamp, "t")}</span>
        )}
      </div>
      {entry?.action === "inspect" ? (
        <>
          <EntryRow equipment={entry} />
          {!!trailer && <EntryRow equipment={trailer} />}
          <div className={styles.inspectionFooter}></div>
        </>
      ) : (
        <EntryRow equipment={entry} />
      )}
    </div>
  );
};

export default Entry;
