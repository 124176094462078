import React from "react";
import PropTypes from "prop-types";
import useEstimateConversion from "../../useEstimateConversion";
import styles from "../styles.module.scss";

const ForcedQuantity = ({ editTasks, item, updateItem }) => {
  const { handleDeactivatedTaskWarning } = useEstimateConversion();
  return (
    <td className="text-center">
      {!!editTasks && item?.entity_type === "task" && (
        <div className="d-flex align-items-center justify-content-center form-group form-check my-0 ">
          <input
            className="form-check-input"
            value={item.quantity_required}
            checked={item.quantity_required}
            id={`${item.id}-quantity_required-toggle`}
            type="checkbox"
            onChange={(val) => {
              updateItem("quantity_required", val.target.checked ? 1 : 0, item.id);
              handleDeactivatedTaskWarning(val.target.checked ? 1 : 0, item.id);
            }}
          />
          <label
            aria-label="item toggle forced quantity state"
            htmlFor={`${item.id}-quantity_required-toggle`}
            className={`${styles.activeCheckbox} form-check-label sm`}
          ></label>
        </div>
      )}
      {!editTasks && item.entity_type === "task" && (
        <div className="d-flex align-items-center justify-content-center form-group form-check my-0 ">
          {!!item.quantity_required && (
            <>
              <input
                className="form-check-input"
                id={`${item.id}-quantity_required-indicator`}
                value={item.quantity_required}
                readOnly
                checked={item.quantity_required}
                type="checkbox"
              />
              <label
                htmlFor={`${item.id}-quantity_required-indicator`}
                className={`${styles.forcedQuantityCheckbox} sm form-check-label`}
                aria-label="forced quantity is active"
              ></label>
            </>
          )}
        </div>
      )}
    </td>
  );
};

ForcedQuantity.propTypes = {
  editTasks: PropTypes.bool,
  item: PropTypes.object,
  updateItem: PropTypes.func,
};

export default ForcedQuantity;
