import React from "react";
import useProject from "../../../hooks/useProject";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import { Briefcase } from "react-feather";
import useTask from "./useTask";
import TaskHeader from "./TaskHeader";
import { Alert } from "react-bootstrap";
import TaskDetailRow from "./TaskDetailRow";
import Foremen from "./Foremen";
import Notes from "./Notes";
import styles from "./styles.module.scss";
import Photos from "./Photos";
import SubTasksTable from "./SubTasksTable";
import Takeoffs from "./Takeoffs";
import Fieldlogs from "./Fieldlogs";
import { Avatar } from "component-library";

const TaskDetail = () => {
  const { task, taskLoading, error } = useTask();
  const { error: projectError, pageTitle, PageTag } = useProject();
  return (
    <InteriorPage
      error={projectError}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle="Project Reporting"
      NavContent={PageTag}
    >
      {!!error && <Alert variant="danger">{error}</Alert>}
      <TaskHeader task={task} />
      {task?.task?.creator_id > 0 && (
        <div className="d-flex align-items-center med p-2 ml-2 mb-5">
          Created By:
          <span className="ms-2">
            <Avatar user={task?.task?.creator} hasColor size="sm" />
          </span>
          <span className="lg font-weight-bold ms-2">{`${task?.task?.creator?.first_name} ${task?.task?.creator?.last_name}`}</span>
        </div>
      )}
      <div className="contentContainer">
        <article className={`${styles.content} mainContent`}>
          <TaskDetailRow task={task} taskLoading={taskLoading} />
          <SubTasksTable />
          <Takeoffs />
          <Fieldlogs />
        </article>
        <aside className={`${styles.sidebar} sidebar`}>
          <Foremen />
          <Notes />
          <Photos />
        </aside>
      </div>
    </InteriorPage>
  );
};

export default TaskDetail;
