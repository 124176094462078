import React from "react";
import { Trash2 } from "react-feather";
import styles from "./styles.module.scss";
import MinutesInput from "../FieldLogs/FieldLog/MinutesInput";
import { Button } from "react-bootstrap";

const Lunch = ({ timesheet, editing, setTimesheet }) => {
  const removeLunch = () => {
    setTimesheet({
      ...timesheet,
      lunch_duration: 0,
    });
  };
  return (
    <>
      {!!parseFloat(timesheet?.lunch_duration) && (
        <div className={`d-flex justify-content-between align-items-center py-2 row ${styles.task}`}>
          <div className={`${styles.name} col`}>Lunch</div>
          <div className="d-flex justify-content-end align-items-center col-md-2">
            {editing && (
              <Button variant="transparent" size="sm" onClick={removeLunch} className="p-0">
                <Trash2 color="#e70000" size={16} className="me-1" />
              </Button>
            )}
            <MinutesInput
              minutes={timesheet?.lunch_duration}
              setMinutes={(minutes) => {
                setTimesheet({
                  ...timesheet,
                  lunch_duration: minutes,
                });
              }}
              canEdit={editing}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Lunch;
