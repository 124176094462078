import React from "react";
import { useAuth } from "component-library";

const Category = ({ data }) => {
  const { config } = useAuth();

  return data.company_categories ? (
    <div className="d-flex flex-row">
      {config?.company_categories.map(
        (single) => parseInt(data.company_categories.replace("[", "").replace("]", "")) === single.id && single.name
      )}
    </div>
  ) : null;
};

export default Category;
