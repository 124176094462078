import NoteCount from "../columnRenders/NoteCount"
import { MessageSquare } from "react-feather";

const HeaderComponent = () => {
  return <MessageSquare size="16" color="#A7A7A7" />;
};

const note_count = {
  headerName: "",
  field: "note_count",
  maxWidth: 50,
  minWidth: 50,
  cellRenderer: NoteCount,
  headerComponent: HeaderComponent,
};

export default note_count;
