import React from "react";
import Lunch from "../../../TimeEntryOptions/Lunch";
import PerDiem from "../../../TimeEntryOptions/PerDiem";
import PTO from "../../../TimeEntryOptions/PTO";
import TimeEntry from "./TimeEntry";
import TimeEntryEditActions from "../../../TimeEntryOptions/TimeEntryEditActions";
import DriveTime from "../../../TimeEntryOptions/DriveTime";
import PropTypes from "prop-types";
import Map from "../../../Map";
import styles from "./styles.module.scss";
import Late from "../../../TimeEntryOptions/Late";
import Absent from "../../../TimeEntryOptions/Absent";

const TimeEntries = ({
  timesheet,
  setTimesheet,
  updateTimesheet,
  config,
  tasks,
  editing,
  projectsList,
  foremanId,
  project,
  overAllocatedTimeSheets,
  setOverAllocatedTimeSheets,
  loading,
  fieldLog,
  setFieldLog,
}) => {
  return (
    <div className="container-fluid">
      <div className="d-flex align-items-start justify-content-between">
        <div className="w-100 me-4">
          {!!timesheet?.time_entries?.length &&
            timesheet.time_entries.map((entry) => (
              <TimeEntry
                key={entry.id}
                entry={entry}
                config={config}
                tasks={tasks}
                editing={editing}
                setTimesheet={setTimesheet}
                timesheet={timesheet}
                foremanId={foremanId}
                project={project}
                overAllocatedTimeSheets={overAllocatedTimeSheets}
                setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
                fieldLog={fieldLog}
                setFieldLog={setFieldLog}
              />
            ))}

          {!!timesheet &&
            !!timesheet?.travel_times?.length &&
            timesheet.travel_times.map((entry) => (
              <DriveTime
                key={entry.id}
                entry={entry}
                updateTimesheet={updateTimesheet}
                setTimesheet={setTimesheet}
                timesheet={timesheet}
                editing={editing}
                projectsList={projectsList}
              />
            ))}
          <Lunch setTimesheet={updateTimesheet} timesheet={timesheet} editing={editing} />
          <PTO setTimesheet={updateTimesheet} timesheet={timesheet} editing={editing} />
          <PerDiem setTimesheet={updateTimesheet} timesheet={timesheet} editing={editing} />
          <Late setTimesheet={updateTimesheet} timesheet={timesheet} editing={editing} />
          <Absent setTimesheet={updateTimesheet} timesheet={timesheet} editing={editing} />
        </div>
        <div className={styles.mapWrapper}>
          <Map timesheet={timesheet} project={project} timesheetLoading={loading} />
        </div>
      </div>

      <TimeEntryEditActions
        setTimesheet={setTimesheet}
        timesheet={timesheet}
        projectsList={projectsList}
        editing={editing}
        updateTimesheet={updateTimesheet}
      />
    </div>
  );
};

TimeEntries.propTypes = {
  timesheet: PropTypes.object,
  setTimesheet: PropTypes.func,
  updateTimesheet: PropTypes.func,
  config: PropTypes.object,
  tasks: PropTypes.array,
  canEdit: PropTypes.bool,
  projectsList: PropTypes.array,
  project: PropTypes.object,
  foremanId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overAllocatedTimeSheets: PropTypes.array,
  setOverAllocatedTimeSheets: PropTypes.func,
};

export default TimeEntries;
