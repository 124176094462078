import React from "react";
import { useApi, reportsApi, useFormControl } from "component-library";
import { schema, initialData } from "../schema/unallocatedTimeSummary";
import ToFromFields from "../ToFromFields";
import useForms from "./useForms";

const UnallocatedTimeSummaryReport = ({ setLoading, setError, setData }) => {
  const {
    request: runReportRequest,
    data: runReportData,
    loading: runReportLoading,
    error: runReportError,
  } = useApi(reportsApi.getUnallocatedTimeReport);

  const { onSubmit } = useForms({
    runReportRequest,
    runReportData,
    runReportLoading,
    runReportError,
    setLoading,
    setError,
    setData,
  });

  const { handleSubmit, renderTextField, renderSubmitButton, data } = useFormControl({
    schema,
    initialData,
    onSubmit,
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <ToFromFields renderTextField={renderTextField} data={data} />
        </div>
        <div className="text-end">{renderSubmitButton("Run Report")}</div>
      </form>
    </>
  );
};

export default UnallocatedTimeSummaryReport;
