import Phone from "../../sharedColumns/columnRenders/Phone";

const phone = {
  headerName: "Phone",
  field: "location_phone",
  width: 124,
  cellRenderer: Phone,
};

export default phone;
