import { useState, useEffect } from "react";
import useResourceManagement from "../../context/useResourceManagement";

const useReportingConsole = () => {
  const { selectedConsoleFilters, setSelectedConsoleFilters } = useResourceManagement();
  const [consoleOpen, setConsoleOpen] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);

  const filterList = [
    "Unclaimed",
    "Unallocated",
    "Missing Trade",
    "Perdiem",
    "over_threshold",
    "Lunch",
    "Absent",
    "Deleted",
    "Late",
  ];

  const handleSelectAllFilters = (value) => {
    let filters = filterList;
    if (parseInt(value) === 1) {
      filters = [];
    }
    setSelectedConsoleFilters(filters);
  };

  useEffect(() => {
    if (!consoleOpen) {
      setSelectedConsoleFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consoleOpen]);

  return {
    consoleOpen,
    setConsoleOpen,
    selectedConsoleFilters,
    setSelectedConsoleFilters,
    handleSelectAllFilters,
    filterList,
    initialLoad,
    setInitialLoad,
  };
};

export default useReportingConsole;
