import PM from "../columns/PM";

const projectManagers = {
  headerName: "Project Manager(s)",
  field: "project_managers",
  cellRenderer: PM,
  autoHeight: true,
  flex: 1,
};

export default projectManagers;
