
import Hours from "../columnRenders/Hours"

const hours = {
  headerName: "Total",
  field: "hours",
  maxWidth: 100,
  cellRenderer: Hours,
};

export default hours;