import React, { useState } from "react";
import styles from "../styles/Login.module.scss";
import FormSelectLocation from "../components/FormSelectLocation";
import { Button } from "react-bootstrap";
import LocationControl from "../components/Locations/LocationControl";
import addButtonStyles from "../styles/AddButtonStyles.module.scss";

const useLocationField = ({ data, setFields, schema, errors }) => {
  const [modalOpen, setModalOpen] = useState();
  const [addingCompany, setAddingCompany] = useState(false);
  const [addingLocation, setAddingLocation] = useState(false);

  const renderLocationField = ({
    location,
    name,
    locationObjectKey,
    label,
    subLabel,
    settingsField = false,
    ...rest
  }) => {
    return (
      <div>
        {!settingsField && (
          <label className="mt-2 med" htmlFor={name}>
            {label}
          </label>
        )}

        {location ? (
          <LocationControl
            location={location}
            removeLocation={() => {
              setFields({ ...data, [name]: 0, [locationObjectKey]: null });
            }}
          />
        ) : (
          <div
            className={`form-group ${errors[name] ? "error" : ""} ${
              schema[name] &&
              schema[name]._flags &&
              schema[name]._flags.presence === "required"
                ? styles.required
                : ""
            }`}
          >
            <div className="light-text sm-med subLabel">{subLabel}</div>
            <Button
              variant="outline-secondary"
              className={`${addButtonStyles.addButton} rounded-circle justify-content-center align-self-center`}
              onClick={() => {
                setModalOpen(true);
              }}
            >
              {!settingsField ? "Select +" : "Select Owner +"}
            </Button>

            {!!errors[name] && (
              <div className="help-block is-error">{errors[name]}</div>
            )}
          </div>
        )}

        {modalOpen && (
          <FormSelectLocation
            addingCompany={addingCompany}
            addingLocation={addingLocation}
            setAddingLocation={setAddingLocation}
            setAddingCompany={setAddingCompany}
            label={label}
            show={modalOpen}
            hide={() => setModalOpen(false)}
            addLocation={(location) => {
              setFields({
                ...data,
                [name]: location.location_id,
                [locationObjectKey]: location,
              });
            }}
          />
        )}
      </div>
    );
  };
  return {
    renderLocationField,
  };
};

export default useLocationField;
