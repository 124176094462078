import { Avatar } from "component-library";
import React from "react";
import { DateTime } from "luxon";
import { Calendar } from "react-feather";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import useProject from "../../../../hooks/useProject";

const UserDetails = ({ user, exifDateTime, uploadDateTime }) => {
  const { project } = useProject();
  return (
    <>
      {!!user && (
        <div className="d-flex align-items-start gap-3">
          <Avatar user={user} hasColor size="xl" />
          <div className="d-flex flex-column align-items-start justify-content-between w-100 h-100 flex-1">
            <div className={styles.userName}>
              {user?.first_name} {user?.last_name}
            </div>
            {exifDateTime && (
              <div className="d-flex flex-row gap-4 align-items-center">
                <Link
                  className="d-flex align-items-center btn btn-link med p-0"
                  to={`/project/${project?.id}/photo-library/photos/${DateTime.fromSQL(
                    exifDateTime?.split(" ")[0]
                  ).toFormat("yyyy-MM-dd")}`}
                >
                  <Calendar size={16} color="#0071ce" className="me-2" />
                  {DateTime.fromSQL(exifDateTime?.split(" ")[0]).toFormat("MM/dd/yyyy")}
                </Link>
                <div className={styles.createdDate}>{DateTime.fromSQL(exifDateTime).toFormat("t")}</div>
              </div>
            )}
            <div className="d-flex flex-row gap-4 align-items-center">
              <div className={styles.createdDateDetail}>
                {`Uploaded ${DateTime.fromSQL(uploadDateTime).toFormat("MM/dd/yyyy hh:mm a")}`}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetails;
