import ActionMenu from "../columnRenders/ActionMenu";

const actionMenu = {
  headerName: "",
  name: "name",
  width: 50,
  cellRenderer: ActionMenu,
  cellClass: "action-column",
};

export default actionMenu;
