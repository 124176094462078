import ArrowLink from "../components/ArrowLink/ArrowLink";

const FooterLinkToProject = ({ job }) => (
  <>
    {!!job.project_id && (
      <ArrowLink
        url={`/project/${job.project_id}/reporting`}
        text="Job Page"
      />
    )}
  </>
);

export default FooterLinkToProject;