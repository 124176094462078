import React from "react";
import styles from "./styles.module.scss";
import FolderLink from "./FolderLink";

const FolderList = ({ folderList }) => {
  return (
    <>
      <h3 className={styles.title}>Folders</h3>
      <div className="d-flex align-items-start flex-wrap">
        {!!folderList?.length && folderList.map((folder) => <FolderLink key={folder} folder={folder} />)}
      </div>
    </>
  );
};

export default FolderList;
