import React from "react";
import styles from "./styles.module.scss";
import SingleEquipment from "./SingleEquipment";
import { Button } from "react-bootstrap";
import { ChevronDown, ChevronRight } from "react-feather";
import { AlertPopup } from "component-library";
import { BeatLoader } from "react-spinners";

const Equipment = ({ active, setActive, equipment, equipmentError, equipmentLoading }) => {

  const headerContent = (
    <>
      <h2 className={styles.title}>
        Equipment <span className="ms-2">({equipment?.length || 0})</span>
      </h2>
      {!!equipmentLoading ? (
        <BeatLoader size={10} color={"#0071ce"} />
      ) : (
        <>
          {!!equipmentError ? (
            <AlertPopup error={equipmentError} />
          ) : (
            <>
              {!!equipment?.length && (
                <>
                  {active ? <ChevronDown size="16" color={"#7B7B7B"} /> : <ChevronRight size="16" color={"#7B7B7B"} />}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );

  return (
    <div className={styles.equipment}>
      {!!equipment?.length ? (
        <Button
          className="d-flex align-items-center justify-content-between w-100 mb-1 text-decoration-none ps-0 pe-2"
          variant="link"
          onClick={() => !!equipment?.length && setActive(!active)}
        >
          {headerContent}
        </Button>
      ) : (
        <div className="d-flex align-items-center justify-content-between w-100 mb-1 text-decoration-none ps-0 pe-2">
          {headerContent}
        </div>
      )}
      {!!active && (
        <div>
          {!!equipment?.length ? (
            <>
              {!equipmentLoading &&
                equipment?.map((equipment, index) => <SingleEquipment index={index} equipment={equipment} />)}
            </>
          ) : (
            <>{!equipmentError && !equipmentLoading && <div className={styles.noResults}>No equipment found</div>}</>
          )}
        </div>
      )}
    </div>
  );
};

export default Equipment;
