import React from "react";
import { ArrowLeftCircle } from "react-feather";
import { Link } from "react-router-dom";

const BackToQuotesList = ({ backLink }) => {

  return (
    <Link className="sm-med d-flex align-items-center" to={backLink}>
      <ArrowLeftCircle size="16" className="me-2" /> Back to Quotes List
    </Link>
  );
};

export default BackToQuotesList;
