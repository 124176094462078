import React from "react";
import styles from "../TaskManagment/styles.module.scss";

const TakeoffsTableHeader = () => {
  return (
    <>
      <thead>
        <tr className={styles.topRowBorder}>
          <th
            className={`
              ${styles.lgColumn} 
              ${styles.tableHeader}
              text-start text-nowrap border-top-0 py-1
            `}
          >
            Code
          </th>
          <th className={`${styles.tableHeader} text-start text-nowrap py-1 border-top-0`}>Material</th>
          <th className={`${styles.medColumn} ${styles.tableHeader} text-start py-1 text-nowrap border-top-0`}>
            Est Qty
          </th>
          <th className={`${styles.lgColumn} ${styles.tableHeader} text-end py-1 text-nowrap border-top-0`}>PM Qty</th>
          <th className={`${styles.medColumn} ${styles.tableHeader} text-end py-1 text-nowrap border-top-0`}>+/-</th>
        </tr>
      </thead>
    </>
  );
};

export default TakeoffsTableHeader;
