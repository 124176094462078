import React, { useState } from "react";
import EstimateConversion from "../EstimateConversion";
import useProject from "../../../hooks/useProject";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import { Briefcase } from "react-feather";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import TaskActions from "../TaskManagment/TaskActions";
import TakeoffActions from "../Takeoffs/TakeoffActions";
import useEstimateConversion from "../useEstimateConversion";
import { PlusCircle } from "react-feather";

const ProjectTasks = () => {
  const [addingTask, setAddingTask] = useState(false);
  const [addingBidItem, setAddingBidItem] = useState(false);

  const { project, loading, error, pageTitle, PageTag } = useProject();

  const { viewTasks, setViewTasks, editTasks, refreshingEstimate, canEdit } = useEstimateConversion();

  const SubHeaderToggles = () => (
    <>
      {!editTasks ? (
        <div role="tablist">
          <Button
            variant="link"
            onClick={() => setViewTasks(false)}
            className={`med px-0 me-4 ${styles.headerToggle} ${!viewTasks && styles.headerToggleActive} ${
              !viewTasks && "lg"
            }`}
            role="tab"
            aria-selected={viewTasks ? "false" : "true"}
          >
            Takeoffs
          </Button>
          <Button
            variant="link"
            onClick={() => setViewTasks(true)}
            className={`med px-0 ${styles.headerToggle} ${!!viewTasks && styles.headerToggleActive} ${
              !!viewTasks && "lg"
            }`}
            role="tab"
            aria-selected={viewTasks ? "true" : "false"}
          >
            Tasks
          </Button>
        </div>
      ) : (
        <h3 className={`lg mb-0 ${styles.editorTitle}`}>Task Editor</h3>
      )}
    </>
  );

  const ActionButtons = () => (
    <>
      {!refreshingEstimate && parseInt(project?.upb_import_status) === 1 ? (
        !!viewTasks ? (
          <TaskActions />
        ) : (
          <TakeoffActions />
        )
      ) : (
        <>
          {parseInt(project?.upb_import_status) !== 1 && (
            <div className="d-flex align-items-center">
              <Button
                variant="link"
                className="med d-flex align-items-center"
                disabled={!canEdit}
                onClick={() => setAddingBidItem(true)}
              >
                <PlusCircle size="16" color={"#0071ce"} className="me-1" />
                Add Bid Item
              </Button>
              <Button
                variant="link"
                className="med d-flex align-items-center"
                disabled={!canEdit}
                onClick={() => setAddingTask(true)}
              >
                <PlusCircle size="16" color={"#0071ce"} className="me-1" />
                Add Task
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <InteriorPage
      loading={loading}
      error={error}
      navTitle={pageTitle}
      NavContent={PageTag}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle="Estimate Conversion"
      pageSubtitlePrimaryContent={SubHeaderToggles}
      pageSubtitleSecondaryContent={ActionButtons}
    >
      <div className="position-relative">
        <EstimateConversion
          setAddingTask={setAddingTask}
          addingTask={addingTask}
          addingBidItem={addingBidItem}
          setAddingBidItem={setAddingBidItem}
        />
      </div>
    </InteriorPage>
  );
};

export default ProjectTasks;
