import React from "react";
import { Alert, Button } from "react-bootstrap";
import PhotosModal from "../../../../../components/PhotosModal";
import styles from "./styles.module.scss";
import useTaskDetailPhotos from "./useTaskDetailPhotos";
import { BeatLoader } from "react-spinners";

const Photos = () => {
  const { photos, photosError, photosLoading, modalOpen, setModalOpen } = useTaskDetailPhotos();
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mt-5 mb-3">
        <div className="d-flex align-items-center">
          <h3 className={styles.photosTitle}>Photos</h3>
          <span className={styles.photoCount}>({photos?.length || 0})</span>
        </div>
      </div>
      {!!photosError && <Alert variant="danger">{photosError}</Alert>}
      {!!photosLoading && <BeatLoader color="#0071CE" size={14} />}
      <div className={styles.photoGrid}>
        {!!photos?.length && (
          <>
            {
              <Button
                onClick={() => setModalOpen(true)}
                className={styles.largeImg}
                style={{ backgroundImage: `url("${photos[0]?.file?.small_thumbnail}")` }}
              />
            }
            {photos.map((photo, index) => (
              <>
                {index > 0 && index <= 5 && (
                  <Button
                    onClick={() => setModalOpen(true)}
                    className={styles.smallImg}
                    key={photo.id}
                    style={{ backgroundImage: `url("${photo?.file?.small_thumbnail}")` }}
                  />
                )}
              </>
            ))}
          </>
        )}
      </div>
      {!!modalOpen && <PhotosModal photos={photos} open={modalOpen} setOpen={setModalOpen} />}
    </div>
  );
};

export default Photos;
