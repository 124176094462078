import React from "react";
import { Accordion, Button } from "react-bootstrap";
import { Eye, EyeOff } from "react-feather";
import Proptypes from "prop-types";
import styles from "./styles.module.scss";

const ToggleAllAccordion = ({ items, activeItems, setActiveItems, Header, Body, itemStyles, idField = "id" }) => {
  const handleToggle = (id) => {
    if (activeItems.includes(id)) {
      setActiveItems(activeItems.filter((item) => item !== id));
    } else {
      setActiveItems([...activeItems, id]);
    }
  };

  const toggleAll = () => {
    if (activeItems.length === items.length) {
      setActiveItems([]);
    } else {
      setActiveItems(items.map((item) => item[idField]));
    }
  };

  return (
    <>
      {!!items?.length && (
        <div>
          <div className={styles.buttonRow}>
            <Button
              onClick={toggleAll}
              type="button"
              aria-label="Toggle All"
              lable="Toggle All"
              variant="link"
              className={styles.toggleAll}
            >
              {activeItems.length !== items.length ? (
                <>
                  View all <Eye size="16" color={"#0071ce"} className={styles.icon} />
                </>
              ) : (
                <>
                  Collapse all <EyeOff size="16" color={"#0071ce"} className={styles.icon} />
                </>
              )}
            </Button>
          </div>
          <div className="mt-2">
            <Accordion
              activeKey={activeItems}
              onSelect={() => !!handleToggle && handleToggle}
              alwaysOpen
              defaultActiveKey={items[0][idField]}
            >
              {items.map((item) => (
                <Accordion.Item eventKey={item[idField]} key={item[idField]} className={itemStyles}>
                  {!!handleToggle ? (
                    <button
                      onClick={() => handleToggle(item[idField])}
                      type="button"
                      aria-label="Toggle Item"
                      lable="Toggle Item"
                      className={styles.toggleItem}
                    >
                      <Header item={item} />
                    </button>
                  ) : (
                    <Header item={item} />
                  )}
                  <Accordion.Collapse eventKey={item[idField]}>
                    <Body item={item} />
                  </Accordion.Collapse>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      )}
    </>
  );
};

ToggleAllAccordion.propTypes = {
  items: Proptypes.array,
  activeItems: Proptypes.array.isRequired,
  setActiveItems: Proptypes.func.isRequired,
  Header: Proptypes.func.isRequired,
  Body: Proptypes.func.isRequired,
  itemStyles: Proptypes.string,
};

export default ToggleAllAccordion;
