import React from "react";
import PropTypes from "prop-types";
import useEstimateConversion from "../../useEstimateConversion";
import styles from "./styles.module.scss";
import useItemPhasing from "../useItemPhasing";
import PhaseColumns from "../PhaseColumns";
import Hidden from "../Columns/Hidden";
import Active from "../Columns/Active";
import UnitMeasure from "../Columns/UnitMeasure";
import Quantity from "../Columns/Quantity";
import ProductionRate from "../Columns/ProductionRate";
import CostCode from "../Columns/CostCode";
import ForcedQuantity from "../Columns/ForcedQuantity";
import TaskName from "../Columns/TaskName";
import EstimatedHours from "../Columns/EstimatedHours";
import LeadingCostCode from "../Columns/LeadingCostCode";
import useTask from "./useTask";

const Task = ({
  task,
  editTasks,
  bidItems,
  setBidItems,
  bidItem,
  fixedColumns,
  indentation = 20,
  bgColor,
  parentOpen = false,
}) => {
  const { toggleOpen, viewPhases, unitsOfMeasure, customControlStyles } = useEstimateConversion();
  const { qtyTotal, phaseIds, getPhaseQty, setPhaseQty, setUpdatePhaseQty, updateError, updateLoading } =
    useItemPhasing({ item: task });
  const { updateLeadingCostCode, updateItem, open } = useTask({ task, bidItems, bidItem, setBidItems, updateError });
  return (
    <>
      <tr
        className={`
          ${bgColor === "white" ? styles.whiteBg : bgColor === "light" ? styles.lightBg : styles.darkBg}
          ${!fixedColumns && qtyTotal > task.bid_quantity ? styles.warningBg : ""}
        `}
      >
        {!!fixedColumns ? (
          <>
            <td
              className={`p-0
              ${styles.emptyTd}`}
            >
              <div className={`${!!fixedColumns ? styles.hasBorder : styles.noBorder}`}>&nbsp;</div>
            </td>
            <TaskName
              indentation={indentation}
              parentOpen={parentOpen}
              open={open}
              editTasks={editTasks}
              item={task}
              toggleOpen={toggleOpen}
              updateItem={updateItem}
            />
            <CostCode item={task} updateItem={updateItem} editTasks={editTasks} />
            <ProductionRate item={task} />
          </>
        ) : (
          <>
            <PhaseColumns
              item={task}
              qtyTotal={qtyTotal}
              phaseIds={phaseIds}
              getPhaseQty={getPhaseQty}
              setPhaseQty={setPhaseQty}
              setUpdatePhaseQty={setUpdatePhaseQty}
              updateLoading={updateLoading}
            />
            <LeadingCostCode
              item={task}
              editTasks={editTasks}
              updateItem={updateItem}
              updateLeadingCostCode={updateLeadingCostCode}
            />
            <Quantity
              item={task}
              editTasks={editTasks}
              viewPhases={viewPhases}
              qtyTotal={qtyTotal}
              updateItem={updateItem}
            />
            <UnitMeasure
              editTasks={editTasks}
              item={task}
              unitsOfMeasure={unitsOfMeasure}
              updateItem={updateItem}
              customControlStyles={customControlStyles}
            />
            <EstimatedHours isSubTask editTasks={editTasks} updateItem={updateItem} item={task} />
            <ForcedQuantity editTasks={editTasks} item={task} updateItem={updateItem} />
            <Active editTasks={editTasks} item={task} updateItem={updateItem} />
            <Hidden editTasks={editTasks} item={task} updateItem={updateItem} />
          </>
        )}
      </tr>
      {!!task?.sub_items?.length &&
        !!open &&
        task.sub_items.map((subtask) => (
          <Task
            key={subtask.id}
            task={subtask}
            editTasks={editTasks}
            bidItems={bidItems}
            setBidItems={setBidItems}
            bidItem={bidItem}
            fixedColumns={fixedColumns}
            phaseIds={phaseIds}
            indentation={indentation + 10}
            bgColor="white"
            parentOpen={open}
          />
        ))}
    </>
  );
};

Task.propTypes = {
  task: PropTypes.object.isRequired,
  editTasks: PropTypes.bool,
  bidItems: PropTypes.array.isRequired,
  setBidItems: PropTypes.func.isRequired,
  bidItem: PropTypes.object.isRequired,
  fixedColumns: PropTypes.bool,
  indentation: PropTypes.number,
  bgColor: PropTypes.string,
  parentOpen: PropTypes.bool,
};

export default Task;
