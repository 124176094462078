import { useEffect, useState } from "react";
import { useApi, purchaseOrdersApi } from "component-library";
import useInterval from "react-useinterval";
import { useParams } from "react-router-dom";

const useProvidePurchaseOrder = () => {
  const { poId } = useParams();
  const [loading, setLoading] = useState(false);
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(false);

  const [purchaseOrder, setPurchaseOrder] = useState();
  const { data, loading: getLoading, error, request } = useApi(purchaseOrdersApi.getPurchaseOrder);
  const {
    data: updateData,
    loading: updateLoading,
    error: updateError,
    request: updateRequest,
  } = useApi(purchaseOrdersApi.updatePurchaseOrder);

  const {
    data: requestSignatureData,
    loading: requestSignatureLoading,
    error: requestSignatureError,
    request: requestSignature,
  } = useApi(purchaseOrdersApi.requestSignature);

  const {
    data: cancelSignatureRequestData,
    loading: cancelSignatureRequestLoading,
    error: cancelSignatureRequestError,
    request: cancelSignatureRequestRequest,
  } = useApi(purchaseOrdersApi.cancelSignatureRequest);

  const {
    data: deleteData,
    loading: deleteLoading,
    request: deleteRequest,
  } = useApi(purchaseOrdersApi.deletePurchaseOrder);

  const { data: statusData, request: statusRequest } = useApi(purchaseOrdersApi.getStatus);

  const updatePurchaseOrder = (po) => {
    updateRequest(po);
  };

  const deletePurchaseOrder = (id) => {
    deleteRequest(id);
  };

  const sendForSignature = () => {
    requestSignature(purchaseOrder.id);
  };

  const cancelSignatureRequest = () => {
    cancelSignatureRequestRequest(purchaseOrder.id);
  };

  useEffect(() => {
    if (!!poId) request(poId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poId]);

  useEffect(() => {
    if (!!data && !!data.purchaseOrder) setPurchaseOrder(data.purchaseOrder);
  }, [data]);

  useEffect(() => {
    if (!!updateData && !!updateData.purchaseOrder) setPurchaseOrder(updateData.purchaseOrder);
  }, [updateData]);

  useEffect(() => {
    if (cancelSignatureRequestData && cancelSignatureRequestData.purchaseOrder) {
      setPurchaseOrder({
        ...purchaseOrder,
        ...cancelSignatureRequestData.purchaseOrder,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelSignatureRequestData]);

  useEffect(() => {
    if (!!updateLoading || !!deleteLoading || !!requestSignatureLoading || !!cancelSignatureRequestLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [updateLoading, deleteLoading, requestSignatureLoading, cancelSignatureRequestLoading]);

  useEffect(() => {
    if (requestSignatureData)
      setPurchaseOrder({
        ...purchaseOrder,
        status: requestSignatureData.status,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestSignatureData]);

  useEffect(() => {
    if (!!purchaseOrder) {
      if (purchaseOrder.status === 2 || purchaseOrder.status === 4) {
        setCheckingStatus(true);
      } else {
        setCheckingStatus(false);
      }
    }
  }, [purchaseOrder]);

  useEffect(() => {
    if (statusData && statusData.status) setPurchaseOrder({ ...purchaseOrder, status: statusData.status[0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusData]);

  const checkStatus = () => {
    statusRequest(purchaseOrder.id);
  };

  useInterval(checkStatus, checkingStatus ? 2000 : null);

  return {
    purchaseOrder,
    error,
    updatePurchaseOrder,
    setPurchaseOrder,
    updateError,
    deletePurchaseOrder,
    deleteData,
    loading,
    setLoading,
    getLoading,
    signatureModalOpen,
    setSignatureModalOpen,
    sendForSignature,
    requestSignatureError,
    cancelSignatureRequest,
    cancelSignatureRequestError,
    setCheckingStatus,
  };
};

export default useProvidePurchaseOrder;
