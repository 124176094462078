import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ConfirmModal, fieldLogsApi, useToasts, useApi, useAuth } from "component-library";
import { ExternalLink } from "react-feather";
import { HeaderButton } from "component-library";

const ListingExport = ({
  selected,
  disabled,
  single,
  project,
  updateExportedStatus,
  selectedLogs,
  disableIfAllApproved,
  consoleData,
  updateOnClose = false,
  approve = false,
  exportConfirm = false,
  verifySubmit,
}) => {
  const { triggerToast } = useToasts();
  const { userCan } = useAuth();
  const [showExport, setShowExport] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const { data, error, request, loading } = useApi(fieldLogsApi.exportFieldLogs);

  const exportFieldLog = () => {
    request({
      field_logs: selected.map((l) => {
        const ids = l.split(":");
        return {
          workday_id: ids[0],
          foreman_id: ids[1],
        };
      }),
      approve: approve ? 1 : 0,
    });
  };

  const getDisabledStatusBasedOnSelectedItems = () => {
    // If all the selected items are status "5" or "Pm approved", disable button
    let allowApproval = false;

    if (selectedLogs?.length) {
      selectedLogs.forEach((log) => {
        if (log.work_day_status_id !== 5) {
          allowApproval = true;
        }
      });
    }
    return allowApproval;
  };
  useEffect(() => {
    if (!!data && data.success && !updateOnClose) {
      updateExportedStatus();
    }

    if (!!data && !!data?.success) {
      if (!approve) {
        triggerToast(
          "Exporting Field Log(s)",
          "Export Report is generating in the background. You'll receive a notification when it's ready."
        );
      } else {
        triggerToast(
          "Selected Field Log Statuses Updated",
          "The statuses for the selected field logs have been updated to 'PM Approved'."
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!!updateOnClose && !showExport && !!data && data.success) {
      updateExportedStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExport]);

  const handleAfterExport = () => {
    if (!!exportConfirm && (consoleData?.unclaimed !== 0 || consoleData?.unallocated !== 0)) {
      setShowConfirm(true);
    } else {
      setShowExport(true);
      exportFieldLog();
    }
  };

  return (
    <>
      {!!userCan("payroll-review-time") && (
        <HeaderButton
          disabled={
            (!!single && !project) ||
            !selected.length ||
            disabled ||
            (disableIfAllApproved && !getDisabledStatusBasedOnSelectedItems())
          }
          onClick={() => {
            if (!!verifySubmit && !verifySubmit()) {
              handleAfterExport();
            }
            if (!verifySubmit) {
              handleAfterExport();
            }
          }}
          icon={
            <ExternalLink
              size="16"
              color={(!!single && !project) || !selected.length || disabled ? "#8f8f8f" : "#0071ce"}
            />
          }
          text={!!approve ? `PM Approve Selected` : `Export ${!!single ? " Field Log" : " Selected"}`}
        />
      )}

      <ConfirmModal
        showConfirmation={showConfirm}
        setShowConfirmation={setShowConfirm}
        confirmFunction={() => {
          setShowExport(true);
          setShowConfirm(false);
          exportFieldLog();
        }}
        loading={loading}
        error={error}
        name="Export Selected Field Logs"
        message="There are unclaimed and/or unallocated timesheets - are you sure you want to proceed?"
      />
    </>
  );
};

ListingExport.propTypes = {
  selected: PropTypes.array,
  disabled: PropTypes.bool,
  single: PropTypes.bool,
};

export default ListingExport;
