import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const ProjectGeoFenceModal = ({
  saveModal,
  setSaveModal,
  setNewRegionName,
  newRegionName,
  savePolygonComplete,
  polygons,
  onCancelPolygon,
}) => {
  const cancelModal = () => {
    savePolygonComplete();
    setSaveModal(false);
  };

  const [displayedName, setDisplayedName] = useState("");
  const [showModalNameError, setShowModalNameError] = useState(false);

  return (
    <Modal
      className="geofence-modal-wrap"
      animation={false}
      centered
      show={!!saveModal}
      onHide={() => {
        cancelModal();
      }}
    >
      <Modal.Header className={styles.moduleHeader} closeButton>
        <h5 className="semibold med">Add New Geofence</h5>
      </Modal.Header>
      <Modal.Body>
        <form
          className="d-flex flex-column"
          onSubmit={(e) => {
            e.preventDefault();
            savePolygonComplete();
            setSaveModal(false);
          }}
        >
          <label className="semibold">Geofence Name</label>
          <input
            name="name"
            type="text"
            required
            maxLength="50"
            value={displayedName}
            onChange={({ target: { value } }) => {
              if (!polygons.some((polygon) => polygon.name === value)) {
                setDisplayedName(value);
                setNewRegionName(value);
                setShowModalNameError(false);
              } else {
                setDisplayedName(value);
                setShowModalNameError(true);
              }
            }}
          />
          <div className="d-flex flex-row mt-3 justify-content-end">
            <button
              className={`btn btn-light-gray ${styles.cancelBtn}`}
              type="button"
              onClick={() => {
                onCancelPolygon();
              }}
            >
              Cancel
            </button>
            <button
              className={`btn btn-tertiary ms-2 ${styles.submitBtn}`}
              type="submit"
              value="Save"
              disabled={showModalNameError}
            >
              Save Geofence
            </button>
          </div>

          {!!showModalNameError && (
            <label className="text-red">This name is already taken. Choose a different name.</label>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};

ProjectGeoFenceModal.propTypes = {
  saveModal: PropTypes.bool,
  setSaveModal: PropTypes.func,
  setNewRegionName: PropTypes.func,
  newRegionName: PropTypes.string,
  savePolygonComplete: PropTypes.func,
};

export default ProjectGeoFenceModal;
