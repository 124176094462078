import NewLocateNumber from "../columnRenders/NewLocateNumber";
import NewLocateNumberValue from "../columnRenders/NewLocateNumberValue";

const newLocateNumber = {
  headerName: "New Locate #",
  field: "newLocateNumber",
  editable: true,
  cellRenderer: NewLocateNumberValue,
  cellEditor: NewLocateNumber,
  maxWidth: 200,
};

export default newLocateNumber;
