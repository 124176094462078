import { useState, useEffect } from "react";
import { schema, initialData as emptySchema } from "./settings";
import { useFormControl, HeaderButton } from "component-library";
import { Save } from "react-feather";
import useSuperAdmin from "../../context/useSuperAdmin";

const useAppSettings = () => {
  const [initialData, setInitialData] = useState(emptySchema);
  const [successMessage, setSuccessMessage] = useState();
  const { tenantSettings, updateTenantSettingsRequest, updateTenantData, setTenantSettings } = useSuperAdmin();

  const onSubmit = async (formData, formLoading) => {
    setSuccessMessage();
    let formattedData = [
      {
        id: tenantSettings?.find((opt) => opt.key === "TenantConfig::time_zone").id,
        value: formData.time_zone,
      },
      {
        id: tenantSettings?.find((opt) => opt.key === "TenantConfig::google_api_key").id,
        value: formData.gmap_key,
      },
      {
        id: tenantSettings?.find((opt) => opt.key === "TenantConfig::accent_color").id,
        value: formData.accent_color,
      },
    ];
    formLoading(true);
    try {
      const result = await updateTenantSettingsRequest({ config: formattedData });
      if (result?.success) {
        formLoading(false);
      }
    } catch (error) {
      formLoading(false);
    }
  };

  const { handleSubmit, renderTextField, renderSelectField, setFields, data } = useFormControl({
    schema: schema,
    onSubmit,
    initialData,
    scrollToTop: true,
  });

  const SaveButton = () => (
    <HeaderButton
      icon={<Save size={16} color="#0071ce" />}
      ariaLabel="Save super admin settings changes"
      formId="saveSuperAdminSettings"
      text="Save App Settings"
      disabled={!tenantSettings}
    />
  );

  const updateTenantSettings = (updatedSettings) => {
    const updatedKeys = updatedSettings.map((updated) => updated.key);
    const settings = tenantSettings.map((current) =>
      updatedKeys.includes(current.key) ? updatedSettings.find((updated) => updated.key === current.key) : current
    );
    if (!!settings) {
      setTenantSettings(settings);
    }
  };

  const handleSetFormData = (settings) => {
    if (settings) {
      const timeZone = settings?.find((opt) => opt.key === "TenantConfig::time_zone");
      const gmap_key = settings?.find((opt) => opt.key === "TenantConfig::google_api_key");
      const accent_color = settings?.find((opt) => opt.key === "TenantConfig::accent_color");

      const initData = {
        time_zone: timeZone?.value,
        gmap_key: gmap_key?.value,
        accent_color: accent_color?.value,
      };
      setInitialData(initData);
      setFields(initData);
    }
  };

  useEffect(() => {
    if (updateTenantData?.config) {
      updateTenantSettings(updateTenantData.config);
      setSuccessMessage("Settings successfully updated.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTenantData]);

  useEffect(() => {
    handleSetFormData(tenantSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantSettings]);

  return {
    successMessage,
    setSuccessMessage,
    handleSubmit,
    SaveButton,
    renderSelectField,
    renderTextField,
    data,
    setFields,
  };
};

export default useAppSettings;
