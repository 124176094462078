import NoteCount from "../columnRenders/NoteCount";
import { ReactComponent as TimesheetNote } from "../../../assets/timesheet-note.svg";

const HeaderComponent = () => {
  return <TimesheetNote size="16" color="#A7A7A7" />;
};

const note_count = {
  headerName: "",
  field: "note_count",
  maxWidth: 50,
  minWidth: 50,
  cellRenderer: NoteCount, 
  headerComponent: HeaderComponent,
};

export default note_count;
