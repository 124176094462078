import React, { useEffect, useState } from "react";
import { Alert, Table } from "react-bootstrap";
import { bidApi, Loader, useApi } from "component-library";
import TakeoffControls from "./TakeoffControls";
import useProject from "../../../hooks/useProject";
import TakeoffsTableHeader from "./TakeoffsTableHeader";
import styles from "../TaskManagment/styles.module.scss";
import Material from "./Material";
import NewMaterial from "./NewMaterial";
import useAddTakeoff from "./useAddTakeoff";

const Takeoffs = () => {
  const { project } = useProject();
  const [takeoffs, setTakeoffs] = useState([]);
  const [filteredTakeoffs, setFilteredTakeoffs] = useState([]);
  const { data, loading, error, request } = useApi(bidApi.getTakeoffs);
  const [newMaterialError, setNewMaterialError] = useState();

  const { handleAddTakeoff, newMaterials, setNewMaterials } = useAddTakeoff();

  useEffect(() => {
    if (!!project) {
      request(project.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (!!data && !!data.takeoffs) {
      setTakeoffs(data.takeoffs);
    }
  }, [data]);

  useEffect(() => {
    if (!!takeoffs) {
      setFilteredTakeoffs(takeoffs);
    }
  }, [takeoffs]);

  const updateTakeoffs = (updated) => {
    if (takeoffs.map((t) => t.id).indexOf(updated.id) > -1) {
      setTakeoffs(takeoffs.map((to) => (to.id === updated.id ? updated : to)));
    } else {
      setTakeoffs([...takeoffs, updated]);
    }
  };

  const filterTakeoffs = (q) => {
    setFilteredTakeoffs(takeoffs.filter((m) => m.name.toLowerCase().indexOf(q.toLowerCase()) > -1));
  };

  return (
    <>
      {!!error && <Alert variant="danger">{error}</Alert>}
      {!!loading ? (
        <Loader />
      ) : (
        <>
          {!!takeoffs && (
            <>
              <TakeoffControls filterTakeoffs={filterTakeoffs} handleAddTakeoff={handleAddTakeoff} />
              {!!newMaterialError && <Alert variant="danger">{newMaterialError}</Alert>}
              <Table responsive striped className={`${styles.taskTable} text-gray2`}>
                <TakeoffsTableHeader />
                <tbody>
                  {!!newMaterials?.length &&
                    newMaterials.map((mat) => (
                      <NewMaterial
                        key={mat.id}
                        newMaterials={newMaterials}
                        setNewMaterials={setNewMaterials}
                        material={mat}
                        setNewMaterialError={setNewMaterialError}
                      />
                    ))}
                  {!!filteredTakeoffs?.length &&
                    filteredTakeoffs.map((to) => (
                      <Material
                        key={to.id}
                        material={to}
                        takeoffs={takeoffs}
                        setTakeoffs={setTakeoffs}
                        updateTakeoffs={updateTakeoffs}
                        setNewMaterialError={setNewMaterialError}
                      />
                    ))}
                </tbody>
              </Table>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Takeoffs;
