import React from "react";
import PropTypes from "prop-types";
import { StyledDatePicker, dropdownStylesWithWidth, formatApiDate } from "component-library";
import Select from "react-select";

const StartEndDate = ({ item, config, handleChange, focusAdd }) => {
  const totalDaysConfg = config.total_days_config.map((item) => ({
    value: item.id,
    label: item.value,
  }));
  return (
    <div className="row">
      <div className="col-md-2">
        <label htmlFor="early-start-date">Early Start Date</label>
        <StyledDatePicker
          id="early-start-date"
          selected={Date.parse(`${item.early_start_date} 00:00`)}
          onChange={(e) => {
            handleChange("milestones", item.key, "early_start_date", formatApiDate(e));
          }}
          maxDate={!!item.late_start_date && new Date(`${item.late_start_date} 00:00`)}
          isClearable
        />
      </div>

      <div className="col-md-2">
        <label htmlFor="late-start-date">Late Start Date</label>
        <StyledDatePicker
          id="late-start-date"
          selected={Date.parse(`${item.late_start_date} 00:00`)}
          onChange={(e) => {
            handleChange("milestones", item.key, "late_start_date", formatApiDate(e));
          }}
          minDate={!!item.early_start_date && new Date(`${item.early_start_date} 00:00`)}
          isClearable
        />
      </div>
      <div className="col-md-2">
        <label htmlFor="completion-date">Completion Date</label>
        <StyledDatePicker
          id="completion-date"
          selected={Date.parse(`${item.completion_date} 00:00`)}
          onChange={(e) => {
            handleChange("milestones", item.key, "completion_date", formatApiDate(e));
          }}
          minDate={!!item.early_start_date && new Date(`${item.early_start_date} 00:00`)}
          isClearable
        />
      </div>
      <div className="col-md-3">
        <label htmlFor={`milestone_total_days_${item.key ? item.key : item.id}`}>Total Days</label>
        <input
          id={`milestone_total_days_${item.key ? item.key : item.id}`}
          className="form-control"
          name="name"
          type="number"
          onChange={(e) => {
            handleChange("milestones", item.key, "total_days", e.target.value);
          }}
          onFocus={focusAdd}
          value={!item.total_days ? "" : item.total_days}
          aria-label="total days"
        />
      </div>
      <div className="col-md-3 d-flex align-items-end">
        <Select
          className="w-100"
          id="source"
          onChange={(e) => {
            handleChange("milestones", item.key, "total_days_type", e.value);
          }}
          options={totalDaysConfg}
          styles={dropdownStylesWithWidth(450)}
          value={totalDaysConfg.find((opt) => opt.value === item.total_days_type) || 1}
          aria-label="working days or calendar days"
        />
      </div>
    </div>
  );
};

StartEndDate.propTypes = {
  item: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  focusAdd: PropTypes.func,
};

export default StartEndDate;
