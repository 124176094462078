import { useEffect } from "react";
import { useApi, projectApi } from "component-library";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import useProjectDocuments from "../../ProjectDocuments/context/useProjectDocuments";

const useDeleteFile = ({ asset }) => {
  const { displayMessage, attachmentsRequest } = useProjectDocuments();
  const { id, assetId } = useParams();
  const history = useNavigate();

  const {
    request: deleteFileRequest,
    data: deleteFileData,
    loading: deleteFileLoading,
    error: deleteFileError,
  } = useApi(projectApi.removeAttachment);

  const handleDeleteFile = () => {
    deleteFileRequest({
      project_id: id,
      attachment_id: assetId,
    });
  };

  useEffect(() => {
    if (!!deleteFileData?.success) {
      attachmentsRequest(id);
      displayMessage({
        title: "File Deleted",
        content: `File deleted: ${asset.file_name}`,
        id: "file-deleted",
        variant: "success",
      });
      history(`/project/${id}/documents`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteFileData]);

  return {
    handleDeleteFile,
    deleteFileLoading,
    deleteFileError,
  };
};

useDeleteFile.propTypes = {
  setShowModal: PropTypes.func,
};

export default useDeleteFile;
