import Date from "../columns/Date";

const date = {
  headerName: "Date",
  field: "note_date",
  maxWidth: 200,
  width: 200,
  cellRenderer: Date,
  suppressMenu: true,
  sortable: true,
};

export default date;
