import React from "react";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import { Briefcase } from "react-feather";
import useProject from "../../../hooks/useProject";
import JobAnalysis from "./JobAnalysis";
import Tabs from "../Tabs";

const Analysis = () => {
  const { pageTitle, PageTag, reporting, reportingLoading, loading, error, reportingError } = useProject();

  return (
    <InteriorPage
      loading={loading || reportingLoading}
      error={error || reportingError}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle="Project Reporting"
      NavContent={PageTag}
      pageSubtitlePrimaryContent={() => <Tabs />}
    >
      <>{!!reporting ? <JobAnalysis job={reporting} /> : <p>Reporting not available. </p>}</>
    </InteriorPage>
  );
};

export default Analysis;
