import React from "react";
import { Alert, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import useModalNote from "./useModalNote";
import PreviousComments from "./PreviousComments";
import AddInput from "./AddInput";
import { Button } from "react-bootstrap";
import { PlusCircle } from "react-feather";
import styles from "./styles.module.scss";
import { Loader, ConfirmModal } from "component-library";

const TaskNotesModal = ({ task, openNotes, setOpenNotes, projectId, handleUpdate, onHide }) => {
  const {
    note,
    setNote,
    commentBeingEdited,
    setCommentBeingEdited,
    handleSubmit,
    editComment,
    deleteComment,
    photos,
    setPhotos,
    comments,
    setComments,
    error,
    loading,
    addCommentLoading,
    adding,
    setAdding,
    canEdit,
    cancelDeleteComment,
    deleteCommentRequest,
    deleteCommentModalOpen,
    deleteCommentError,
    deleteCommentLoading,
  } = useModalNote({ setOpenNotes, task, projectId, handleUpdate });

  return (
    <>
      <ConfirmModal
        showConfirmation={deleteCommentModalOpen}
        setShowConfirmation={cancelDeleteComment}
        confirmFunction={deleteCommentRequest}
        loading={deleteCommentLoading}
        error={deleteCommentError}
        name={"Delete Note"}
        message={"Are you sure you want to delete this note?"}
      />

      {!deleteCommentModalOpen && (
        <Modal
          animation={false}
          show={!!openNotes}
          onHide={() => {
            setOpenNotes();
            !!onHide && onHide();
          }}
          dialogClassName="modal-lg"
          className="text-gray3"
        >
          <div className="position-relative">
            <Loader loading={!!loading} />
            <Modal.Header closeButton className={styles.modalHeader}>
              <div className={`${styles.taskName} xl`}>{task?.task?.name}</div>
            </Modal.Header>
            <Modal.Body>
              {!!error && (
                <Alert variant="danger">{error}</Alert>
              )}
              <div className="d-flex align-items-center pb-2 w-100 border-bottom">
                <h4 className="mb-0 font-weight-bold med">Task Notes</h4>
                {!!canEdit && !adding && !commentBeingEdited && (
                  <Button
                    onClick={() => setAdding(true)}
                    className="ms-2 p-0 d-flex flex-row align-items-center med"
                    variant="link"
                  >
                    <PlusCircle className="me-1" size="18"></PlusCircle>
                    Add
                  </Button>
                )}
              </div>
              {!commentBeingEdited && !!adding && (
                <AddInput note={note} setNote={setNote} setOpen={setOpenNotes} photos={photos} setPhotos={setPhotos} />
              )}
              <PreviousComments
                comments={comments.map((note) => {
                  return {
                    ...note,
                    work_day_task_data: {
                      quantity: task?.quantity,
                      hours: task?.hours,
                      uom_id: parseInt(task?.task?.unit_measure),
                      station: task?.station,
                    },
                  };
                })}
                setComments={setComments}
                deleteComment={deleteComment}
                editComment={editComment}
                setCommentBeingEdited={setCommentBeingEdited}
                commentBeingEdited={commentBeingEdited}
                projectId={projectId}
                handleUpdate={handleUpdate}
                task={task}
                canEdit={canEdit}
              />
            </Modal.Body>
            <Modal.Footer>
              <div>
                {!!adding || !!commentBeingEdited ? (
                  <>
                    <Button
                      onClick={() => {
                        setAdding(false);
                        setCommentBeingEdited();
                      }}
                      className={styles.close}
                      disabled={!!note && !note.length}
                    >
                      Close Edit
                    </Button>
                    {!commentBeingEdited && (
                      <Button
                        variant="tertiary"
                        onClick={handleSubmit}
                        className="med"
                        disabled={!note.length || addCommentLoading}
                      >
                        {addCommentLoading ? "Saving..." : "Submit New Note"}
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      setOpenNotes(false);
                    }}
                    className={styles.close}
                    disabled={!!note && !note.length}
                  >
                    Close
                  </Button>
                )}
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      )}
    </>
  );
};

TaskNotesModal.propTypes = {
  notes: PropTypes.object,
  openNotes: PropTypes.bool,
  setOpenNotes: PropTypes.func,
};

export default TaskNotesModal;
