import { useEffect } from "react";
import { useApi, photoLibraryApi, useToasts } from "component-library";

const useDownloadPhotos = (selectedItems, project) => {
  const { triggerToast } = useToasts();

  const {
    data: downloadData,
    error: downloadError,
    loading: downloadLoading,
    request: downloadRequest,
  } = useApi(photoLibraryApi.downloadPhotos);

  useEffect(() => {
    if (downloadData?.success) {
      triggerToast(
        "Preparing Download",
        "You will recive a link to the zip in your notification shortly.",
        "download-started",
        null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadData]);

  useEffect(() => {
    if (downloadError) {
      triggerToast(
        "Download Failed",
        "An error occured preventing the download from starting.",
        "download-started",
        "danger"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadError]);

  const downloadPhotos = () => {
    const photoIds = selectedItems.filter((item) => item.type === "photo").map((item) => item.id);
    const albumsWithPhotos = selectedItems.filter((item) => item.type === "album" && item.photo_count > 0);
    const albumIDs = albumsWithPhotos.map((item) => item.id);
    if (photoIds.length === 0 && albumIDs.length === 0) {
      triggerToast(
        "Unable to Download",
        "Please select photos or albums with photos to download.",
        "download-started",
        "danger"
      );
      return;
    }
    downloadRequest({ photo_ids: photoIds, album_ids: albumIDs, project_id: project.id });
  };

  return {
    downloadPhotos,
    downloadLoading,
  };
};

export default useDownloadPhotos;
