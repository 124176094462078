import { Avatar } from "component-library";
import React from "react";
import styles from "./styles.module.scss";
import { Edit } from "react-feather";
import useUserAdmin from "../../../_admin/pages/UserAdmin/hooks/useUserAdmin";

const User = ({ data }) => {
  const { setEditUser } = useUserAdmin();
  return (
    <div className={styles.user}>
      <button className={styles.edit} onClick={() => setEditUser(data)}>
        <div className={styles.avatarWrap}>
          <div className={styles.editIconWrap}>
            <Edit size={14} className={styles.editIcon} />
          </div>
          <Avatar user={data} size="xs" hasColor />
        </div>
      </button>
      {data?.first_name} {data?.last_name}
    </div>
  );
};

export default User;
