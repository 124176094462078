import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import { HeaderButton, AgGridTable, useAuth } from "component-library";
import gridRowHeights from "../../../../utils/gridRowHeights";
import poiListingConfig from "../../../../config/poiListingConfig";
import { Alert } from "react-bootstrap";
import { Briefcase } from "react-feather";
import styles from "../styles.module.scss";
import useAllPOIs from "./useAllPOIs";
import Pagination from "../../../../components/Pagination";
import POIFilters from "./POIFilters";

const AllPOIs = () => {
  const { user } = useAuth();
  const {
    gridData,
    page,
    setPage,
    loading,
    error,
    statusFilter,
    total,
    setStatusFilter,
    q,
    setQ,
    statusOptions,
    itemsPerPage,
  } = useAllPOIs();

  const HeaderButtons = () => (
    <div className="d-flex">
      <HeaderButton linkTo={`/fleet-management/pois/add`} />
    </div>
  );

  return (
    <InteriorPage
      navTitle={"Field Management"}
      pageTitle="Points of Interest"
      pageTitleSecondaryContent={HeaderButtons}
      navIcon={<Briefcase color="#fff" size={14} />}
    >
      {error && <Alert variant="danger">{error}</Alert>}
      <div className={styles.gridWrapper}>
        <POIFilters
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          q={q}
          setQ={setQ}
          statusOptions={statusOptions}
          loading={loading}
        />
        <AgGridTable
          gridConfig={poiListingConfig}
          gridData={gridData}
          rowHeight={gridRowHeights.medium}
          loading={loading}
          id={`${user.id}_poi_listing`}
          presetStyle="sortableTable"
          navigateTo={`/fleet-management/pois/edit`}
          navigationParam="id"
          fallbackMessage="No points of interest available."
        />
        {!!gridData?.length && (
          <Pagination
            items={gridData}
            itemsPerPage={itemsPerPage}
            currentPage={page}
            setCurrentPage={setPage}
            total={total}
          />
        )}
      </div>
    </InteriorPage>
  );
};

export default AllPOIs;
