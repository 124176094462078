import React from "react";
import useFormSubmission from "./useFormDetail";
import styles from "./styles.module.scss";
import { Loader } from "component-library";
import Header from "./Header";
import { Alert } from "react-bootstrap";
import useProject from "../../../hooks/useProject";
import { Briefcase } from "react-feather";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import parse from "html-react-parser";

const FormDetail = () => {
  const { submission, error, loading } = useFormSubmission();
  const { pageTitle, PageTag, loading: projectLoading } = useProject();
  let controlHead;
  if (!!submission?.answers)
    controlHead = Object.values(submission.answers).find((answer) => answer.type === "control_head");
  return (
    <InteriorPage
      navTitle={pageTitle}
      pageTitle="Field Management"
      navIcon={<Briefcase color="#fff" size={14} />}
      NavContent={PageTag}
      loading={loading || projectLoading}
    >
      <div className={styles.container}>
        <Loader loading={loading} />
        {!!error && <Alert variant="danger">{error}</Alert>}
        {!!submission?.answers && (
          <>
            {!!controlHead && <Header title={controlHead.text} />}
            <div>
              {Object.values(submission.answers).map((answer, index) => (
                <div key={index}>
                  {(answer.type === "control_fullname" ||
                    answer.type === "control_datetime" ||
                    answer.type === "control_time" ||
                    answer.type === "control_checkbox" ||
                    answer.type === "control_phone") && (
                    <div className={styles.answerBlock}>
                      <div className={styles.subheader}>{answer.text}</div>
                      <span>{answer.prettyFormat}</span>
                    </div>
                  )}
                  {(answer.type === "control_textbox" ||
                    answer.type === "control_radio" ||
                    answer.type === "control_textarea" ||
                    answer.type === "control_dropdown") && (
                    <div className={styles.answerBlock}>
                      <div className={styles.subheader}>{answer.text}</div>
                      <div>{answer.answer}</div>
                    </div>
                  )}
                  {answer.type === "control_address" && (
                    <div className={styles.answerBlock}>
                      <div className={styles.subheader}>{answer.text}</div>
                      <div className={styles.address}>{answer.prettyFormat.split("<br>").join("\n")}</div>
                    </div>
                  )}
                  {answer.type === "control_widget" && answer.cfname === "Take Photo" && (
                    <div className={styles.answerBlock}>
                      <div className={styles.subheader}>{answer.cfname}</div>
                      <img alt="" src={answer.answer} className={styles.image} />
                    </div>
                  )}
                  {answer.type === "control_widget" && answer.cfname === "Dynamic Textbox" && (
                    <div className={styles.answerBlock}>
                      <div className={styles.subheader}>{answer.text}</div>
                      <div className="white-space-pre">{answer.answer}</div>
                    </div>
                  )}
                  {answer.type === "control_signature" && answer?.answer && (
                    <div className={styles.answerBlock}>
                      <div className={styles.subheader}>{answer.text}</div>
                      <img alt="" src={answer.answer} className={styles.signature} />
                    </div>
                  )}
                  {answer.type === "control_matrix" && (
                    <div className={styles.answerBlock}>{parse(answer.prettyFormat)}</div>
                  )}
                  {answer.type === "control_fileupload" && (
                    <>
                      <div className={styles.subheader}>File Uploads</div>
                      <div className={styles.uploadGrid}>
                        {!!answer?.answer?.length &&
                          answer.answer.map((ans, index) => (
                            <div key={index} className={styles.answerBlock}>
                              <img alt="" src={ans} className={styles.image} />
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </InteriorPage>
  );
};

export default FormDetail;
