import React from "react";
import styles from "./styles.module.scss";

const Priority = ({ data }) => {
  let label = !!data.priority && data.priority.name;
  switch (data.priority_id) {
    case 2:
      label = "med";
      break;
    case 1:
      label = "low";
      break;
    case 3:
      label = "high";
      break;
    default:
      break;
  }
  return <div className={`${styles.priority} ${styles[label]}`}>{label}</div>;
};

export default Priority;