import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import useAddContact from "../../hooks/useAddContact";
import ContactSearch from "./ContactSearch";
import { ChevronLeft, X } from "react-feather";
import Select from "react-select";
import PropTypes from "prop-types";
import AddCompanyForm from "../Company/AddCompanyForm";
import AddLocation from "../ContactsAdmin/AddLocation";
import { dropdownStyles, ContactCard } from "component-library";

const AddContact = ({ hide, show, addContact, roles, label = "Contact", contacts }) => {
  const [addNew, setAddNew] = useState(false);
  const [contact, setContact] = useState({});
  const [role, setRole] = useState();
  const {
    handleSubmit,
    fields,
    renderSubmitButton,
    addingCompany,
    setCompany,
    addingLocation,
    setAddingCompany,
    setAddingLocation,
    selectedOption,
  } = useAddContact({
    contact,
    setContact,
    setAddNew,
  });

  const handleAdd = async () => {
    addContact(contact, role);
    setContact({});
    setRole();
    hide();
  };

  const handleChangeRole = ({ value }) => {
    setRole(roles.find((r) => r.id === value));
  };

  let roleOptions;
  if (roles) {
    roleOptions = roles.map((r) => ({
      value: r.id,
      label: r.name,
    }));
  }

  return (
    <Modal
      animation={false}
      show={show}
      onHide={() => {
        setAddNew(false);
        hide();
        setContact({});
      }}
      dialogClassName="modal-lg"
    >
      {addingLocation ? (
        <AddLocation setAddingLocation={setAddingLocation} company={selectedOption} setLocation={setCompany} />
      ) : (
        <>
          {addingCompany ? (
            <AddCompanyForm setCompany={setCompany} setAddingCompany={setAddingCompany} />
          ) : (
            <>
              <Modal.Header>
                <h1 className="mb-0 lg font-weight-bold">
                  {addNew ? "Add New Contact to Address Book" : `Add ${label}`}
                </h1>
                <Button
                  onClick={() => {
                    setAddNew(false);
                    hide();
                    setContact({});
                  }}
                  variant="link"
                  className="me-n3 mt-n1"
                  aria-label="close add contact modal"
                >
                  <X color="#979797" />
                </Button>
              </Modal.Header>
              <Modal.Body>
                {addNew ? (
                  <form onSubmit={handleSubmit}>
                    <Button
                      variant="link"
                      onClick={() => setAddNew(false)}
                      className="p-0 d-flex sm-med align-items-center"
                    >
                      <ChevronLeft size={16} color="#0071ce" /> Search
                    </Button>
                    {fields}
                    <div className="d-flex justify-content-end">{renderSubmitButton("Save Contact")}</div>
                  </form>
                ) : (
                  <>
                    {roles && roles.length && (
                      <div className={`row`}>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label htmlFor="contact-add-contact-role-select">Role</label>
                            <Select
                              select
                              name="role"
                              inputId="contact-add-contact-role-select"
                              onChange={handleChangeRole}
                              options={roleOptions}
                              value={!!role && roleOptions.find((opt) => opt.value === role.id)}
                              placeholder="Select..."
                              className="w-100"
                              styles={dropdownStyles}
                              aria-label="role"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col">
                        <ContactSearch contacts={contacts} setContact={setContact} setAddNew={setAddNew} />
                      </div>
                    </div>
                    <div className="col-4 p-0">
                      {contact && !!Object.keys(contact).length && <ContactCard contact={contact} />}
                    </div>

                    <div className="d-flex justify-content-end">
                      <Button
                        disabled={!Object.keys(contact).length || (roles && !!roles.length && !role)}
                        onClick={handleAdd}
                        className={`btn-tertiary mt-2 med`}
                      >
                        Add Contact
                      </Button>
                    </div>
                  </>
                )}
              </Modal.Body>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

AddContact.propTypes = {
  roles: PropTypes.array,
  show: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  addContact: PropTypes.func.isRequired,
};

export default AddContact;
