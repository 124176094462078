import React from "react";
import styles from "./styles.module.scss";
import { Avatar, ContactPill } from "component-library";

const CrewDetailHeader = ({ selectedCrewMember }) => {
  return (
    <>
      {!!selectedCrewMember && (
        <div className="d-flex mb-3 ms-2">
          <div className="me-3">
            <Avatar size="xxl" user={selectedCrewMember} hasColor />
          </div>

          <div className="d-flex flex-column mb-4">
            <div className="d-flex align-items-end">
              <div className={styles.headerNameText}>
                {selectedCrewMember.last_name}, {selectedCrewMember.first_name}
              </div>
              <div className={styles.roleText}>{selectedCrewMember.contact?.title?.toUpperCase()}</div>
            </div>
            {selectedCrewMember.email && <ContactPill data={selectedCrewMember.email} isEmail />}
            {selectedCrewMember.contact?.phone && <ContactPill data={selectedCrewMember.contact?.phone} isPhone />}
            {selectedCrewMember.contact?.cell_phone && (
              <ContactPill data={selectedCrewMember.contact?.cell_phone} isCell />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CrewDetailHeader;
