import React from "react";
import Indicator from "./Indicator";
import { displayDate } from "component-library";
import styles from "./styles.module.scss";

const ReportDayHeader = ({ item }) => {
  const date = !!item?.work_day?.date ? new Date(item.work_day.date.replace(/-/g, "/")) : null;
  const dayOfWeek = !!date ? date.toLocaleString("default", { weekday: "long" }) : null;

  return (
    <div className={item.working_day_flag ? styles.workingDayHeader : styles.nonWorkingDayHeader}>
      <div className={styles.dayHeaderDate}>
        <Indicator workingDay={item?.working_day_flag} />
        <strong>{dayOfWeek}</strong>
        <div>{displayDate(date)}</div>
      </div>
      <div className={styles.dayHeaderDay}>
        <i className={styles.workingDay}>{item?.working_day_flag ? "Working Day" : "Non-Working Day"}</i>
        <strong className={styles.workingDayStatus}>{parseFloat(item?.working_day_value)}</strong>
      </div>
    </div>
  );
};

export default ReportDayHeader;
