import React from "react";
import { Loader } from "component-library";
import { Modal, Button, Alert } from "react-bootstrap";

const Controls = ({modalOpen, setModalOpen, handleDeleteQuote, deleteQuoteLoading, deleteQuoteError}) => {
  return (
    <>
      {!!modalOpen && (
        <Modal show={true} centered onHide={() => setModalOpen(false)}>
          <div className="position-relative">
            <Loader loading={!!deleteQuoteLoading} />
            <div className="med confirmation-header mt-3">Delete Quote</div>
            <div className="my-4 med confirmation-text">Are you sure you want to delete this quote?</div>
            <div className="d-flex justify-content-end mb-3">
              <Button className="light-text mx-1 med py-1" variant="light-gray" onClick={() => setModalOpen(false)}>
                Cancel
              </Button>
              <Button
                className="light-text mx-1 med py-1"
                variant="orange"
                onClick={() => {
                  handleDeleteQuote();
                }}
              >
                Delete
              </Button>
            </div>
            {!!deleteQuoteError && <Alert variant="danger">{deleteQuoteError}</Alert>}
          </div>
        </Modal>
      )}
    </>
  );
};

export default Controls;
