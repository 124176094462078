import React from "react";
import { Alert } from "react-bootstrap";
import { useFormControl } from "component-library";
import { schema } from "../../../schema/submittal";
import useSubmittals from "../../hooks/useSubmittals";

const useAddNote = ({ submittal }) => {
  const { updateRequest, updateError, updateLoading } = useSubmittals();

  const onSubmit = async (formData, setLoading) => {
    setLoading(true);
    try {
      await updateRequest(formData);
    } catch (message) {
      console.error(message);
      setLoading(false);
    }
    setLoading(false);
  };

  const { handleSubmit, renderSubmitButton, renderTextareaField } = useFormControl({
    schema,
    onSubmit,
    initialData: submittal,
  });

  const fields = (
    <>
      {updateError && <Alert variant="danger">{updateError}</Alert>}
      <div className="row">
        <div className="col">
          {renderTextareaField({
            name: "note",
            label: "Reason for status change",
          })}
        </div>
      </div>
    </>
  );
  return {
    fields,
    handleSubmit,
    renderSubmitButton,
    updateLoading,
  };
};

export default useAddNote;
