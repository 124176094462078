import React from 'react'
import { Avatar } from 'component-library'
const ForemanInput = ({ foreman, handleForemanToggle, selectedForemanIds, name }) => {

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-center">
        <div className={`form-group form-check`}>
          <input
            type="checkbox"
            className="form-check-input"
            name={foreman?.foreman_id}
            id={foreman?.foreman_id}
            onChange={() => handleForemanToggle(foreman)}
            value={selectedForemanIds.includes(foreman.foreman_id)}
            checked={selectedForemanIds.includes(foreman.foreman_id)}
            aria-label="select foreman"
          />
          <label className="form-check-label sm" htmlFor={foreman?.foreman_id}></label>
        </div>
        <div className="me-3">{!!foreman?.foreman && <Avatar user={foreman?.foreman} />}</div>
      </div>
      {name}
    </div>
  )
}

export default ForemanInput