import Folder from "../columnRenders/Folder";

const folder = {
  headerName: "Folder",
  name: "folder",
  cellRenderer: Folder,
  sortable: false,
  flex: 1,
};

export default folder;
