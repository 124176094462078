import React from "react";
import styles from "./styles.module.scss";
import TaskHeader from "./TaskHeader";
import TaskItem from "./TaskItem";
import ColumnHeaders from "./ColumnHeaders";

const PrintableTasks = ({ taskData }) => {
  return (
    <div className={styles.container}>
      <ColumnHeaders />
      {taskData.map((task) => (
        <div key={task.item_id} className="mb-3">
          <TaskHeader task={task} />
          <div>
            {task.tasks.map((taskDetail) => (
              <TaskItem key={taskDetail.task_id} task={taskDetail} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
export default PrintableTasks;
