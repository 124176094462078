import React from "react";
import AlbumHeaders from "../SingleItems/AlbumHeaders";
import SingleAlbum from "../SingleItems/SingleAlbum";
import { BeatLoader } from "react-spinners";
import styles from "../styles.module.scss";

const AlbumsList = ({
  unpinnedAlbumsList,
  handlePin,
  handleDelete,
  params,
  loading,
  selectMode,
  selectedItems,
  setSelectedItems,
}) => {
  return (
    <div className={`${styles.listContainer} mt-5`}>
      <AlbumHeaders />
      {!!loading ? (
        <div className={styles.loaderWrapper}>
          <BeatLoader color="#0071ce" />
        </div>
      ) : (
        <>
          {!!unpinnedAlbumsList?.length ? (
            unpinnedAlbumsList.map((item) => (
              <SingleAlbum
                key={item.id}
                album={item}
                params={params}
                handlePin={handlePin}
                handleDelete={handleDelete}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                selectMode={selectMode}
              />
            ))
          ) : (
            <div className="mb-2">No items found.</div>
          )}
        </>
      )}
    </div>
  );
};

export default AlbumsList;
