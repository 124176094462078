import React, { useState, useEffect } from "react";
import useProjectContacts from "./useProjectContacts";
import styles from "../styles.module.scss";
import { ConfirmModal, ContactCard, Loader, useAuth } from "component-library";
import useProject from "../../../hooks/useProject";
import LetterButton from "../../../../components/LetterButton/LetterButton";
import alphabet from "../../../../utils/alphabet";
import QuickFilters from "./QuickFilters";
import Notice from "../../../../components/Alert";
import AddContact from "../../../../components/Contacts/FormAddContact";
import EditContact from "../../../../components/Contacts/EditContactForm";
import EditContactRole from "../EditContactRole";
import { Alert } from "react-bootstrap";
import ContactMenuItems from "../../ContactMenuItems";
import ContactSubNav from "../../../../components/ContactSubNav";
import PropTypes from "prop-types";

const OtherContacts = ({ addNewOpen, setAddNewOpen }) => {
  const {
    contacts,
    setContacts,
    filteredContacts,
    letter,
    updateLetter,
    addContact,
    removeContact,
    loading,
    error,
    setUpdatedContact,
    editingContact,
    setEditingContact,
    activeQuickFilter,
    setActiveQuickFilter,
  } = useProjectContacts();
  const { project } = useProject();
  const { config } = useAuth();
  const [showNotice, setShowNotice] = useState(false);

  const [editContactOpen, setEditContactOpen] = useState(false);
  const [projectContactIds, setProjectContactIds] = useState();
  const [editRoleOpen, setEditRoleOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    !!contacts && !!contacts.length ? setShowNotice(false) : setShowNotice(true);
  }, [contacts]);

  useEffect(() => {
    if (!!project && !!project.project_contacts && !!project.project_contacts.length) {
      setProjectContactIds(project.project_contacts.map((contact) => contact.contact_id));
    }
  }, [project]);

  return (
    <div className={`${styles.sectionWrap} position-relative`}>
      <Loader loading={!!loading} />
      <div className={`${styles.keyRow} d-flex justify-content-between align-items-center`}>
        <div className="d-flex justify-content-between">
          <QuickFilters activeQuickFilter={activeQuickFilter} setActiveQuickFilter={setActiveQuickFilter} />
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3 mb-4">
        {alphabet.map((l) => (
          <LetterButton key={l} letter={l} updateLetter={updateLetter} active={letter === l} />
        ))}
      </div>
      {!!contacts && !!contacts.length && (
        <>
          {!!error && <Alert variant="danger" message={error} />}
          <div className={styles.grid}>
            {!!filteredContacts &&
              !!filteredContacts.length &&
              filteredContacts.map((contact, index) => (
                <div key={index} className={`${styles.cardWrapper} position-relative`}>
                  <ContactCard cardTitle={!!contact.role && contact.role.name} contact={contact.contact} />

                  <ContactSubNav>
                    <ContactMenuItems
                      contact={contact}
                      removeContact={removeContact}
                      setEditContactOpen={setEditContactOpen}
                      editContactOpen={editContactOpen}
                      setEditingContact={setEditingContact}
                      setEditRoleOpen={setEditRoleOpen}
                      setConfirmModalOpen={setConfirmModalOpen}
                      canRemove={projectContactIds.includes(contact.contact_id) ? true : false}
                    />
                  </ContactSubNav>
                </div>
              ))}
          </div>
        </>
      )}

      {!!showNotice && (
        <Notice
          title="You haven't assigned any contacts"
          message="Add more contacts in the project settings"
          linkText="Review Now"
          linkTo={`/project/${project.id}/settings`}
        />
      )}

      {addNewOpen && (
        <AddContact
          show={addNewOpen}
          hide={() => setAddNewOpen(false)}
          addContact={(contact, role) => {
            addContact(contact, role);
          }}
          roles={config.roles}
          contacts={contacts}
        />
      )}
      {!!editContactOpen && (
        <EditContact
          contact={{
            ...editingContact.contact,
            company: {
              ...editingContact.contact.locations[0],
              location_id: editingContact.contact.locations[0].id,
              address1: editingContact.contact.locations[0].address.address1,
              address2: editingContact.contact.locations[0].address.address2,
              city: editingContact.contact.locations[0].address.city,
              state: editingContact.contact.locations[0].address.state,
              zip: editingContact.contact.locations[0].address.zip,
            },
          }}
          setAddingContact={() => setEditContactOpen(false)}
          setEditedContact={setUpdatedContact}
        />
      )}
      {!!editRoleOpen && (
        <EditContactRole
          contacts={contacts}
          setContacts={setContacts}
          contact={editingContact}
          setEditingRole={setEditRoleOpen}
        />
      )}
      {!!confirmModalOpen && (
        <ConfirmModal
          showConfirmation={confirmModalOpen}
          setShowConfirmation={setConfirmModalOpen}
          confirmFunction={() => {
            removeContact(editingContact.contact_id);
            setConfirmModalOpen(false);
          }}
          loading={loading}
          error={error}
          name="Remove Project Contact"
          message={`Are you sure you want to remove ${editingContact.contact.first_name} ${editingContact.contact.last_name} from the project?`}
        />
      )}
    </div>
  );
};

OtherContacts.propTypes = {
  addNewOpen: PropTypes.bool.isRequired,
  setAddNewOpen: PropTypes.func.isRequired,
};

export default OtherContacts;
