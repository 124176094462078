import React from 'react';
import { Button } from 'react-bootstrap';
import styles from "./styles.module.scss";

const LetterButton = ({ letter, updateLetter, active }) => {
  return (
    <Button
      variant="link"
      onClick={() => updateLetter(letter)}
      className={`${styles.letterButton} ${!!active ? styles.letActive : ''}`}
      aria-label={`filter by ${letter}`}
    >
      {letter}
    </Button>
  );
}

export default LetterButton;