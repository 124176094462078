
const foreman = {
  headerName: "Foreman",
  field: "foreman",
  flex: 1,
  minWidth: 180,
  valueGetter: ({ data }) => (data?.foreman?.name ? data.foreman?.name : "N/A"),
};

export default foreman;
