import React from "react";
import styles from "./styles.module.scss";
import checkReportFS from "../../../../utils/checkReportFS";
import formatGridValue from "../../../../utils/formatGridValue";

const HoursEstRmn = ({ data }) => {
  const getRemainingHours = (estimatedHours, reportedHours) => {
    const hours = parseFloat(estimatedHours) - parseFloat(reportedHours) || 0;
    return formatGridValue(hours);
  };

  const remainingHours = getRemainingHours(
    data?.estimated_hours || 0,
    data?.range?.[0]?.reported_hours || data?.reported_hours
  );

  const estHours = !!data?.estimated_hours ? formatGridValue(data?.estimated_hours) : 0;

  const formattedRemaining = formatGridValue(remainingHours);

  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.reported} ${checkReportFS([data?.estimated_hours, remainingHours], 14) ? "sm" : "med"}`}
      >
        <span className={styles.estimate}>{estHours}</span>/
        <span
          className={`${parseFloat(remainingHours).toFixed(2) < 0 && styles.warn} ${styles.remaining} ${
            checkReportFS([parseFloat(remainingHours).toFixed(2)], 7) ? "sm" : "med"
          }`}
        >
          {formattedRemaining}
        </span>
      </div>
    </div>
  );
};

export default HoursEstRmn;
