import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { Folder, ChevronLeft, ChevronRight } from "react-feather";
import GallerySelector from "../GallerySelector";

const Carousel = ({
  combinedList,
  currentView,
  setCurrentView,
  selectMode,
  selectedItems,
  smallThumbs = false,
  onSelectChange = null,
}) => {
  const thumbRef = useRef();
  const maxVisible = useRef(0);
  const [current, setCurrent] = useState(0);
  const paginationSize = 6;

  const calcMaxVisible = () => {
    const carouselWidth = thumbRef.current.offsetWidth;
    const thumbWidth = smallThumbs ? 60 : 80;
    const maxCount = Math.trunc(carouselWidth / thumbWidth);
    maxVisible.current = maxCount;
  };

  useEffect(() => {
    calcMaxVisible();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const onResize = () => {
    calcMaxVisible();
  };

  useEffect(() => {
    if (currentView >= 0 && currentView < combinedList.length - 1) {
      if (currentView >= current + maxVisible.current) {
        setCurrent(currentView);
      } else if (currentView <= current) {
        setCurrent(currentView > maxVisible.current ? currentView - Math.trunc(maxVisible.current / 2) : 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedList, currentView]);

  const showPreviousPage = () => {
    if (current > paginationSize) {
      setCurrent(current - paginationSize);
    } else {
      setCurrent(0);
    }
  };

  const showNextPage = () => {
    if (combinedList.length > 0) {
      if (current < combinedList.length - 1 - paginationSize) {
        setCurrent(current + paginationSize);
      } else {
        setCurrent(combinedList.length - 1);
      }
    }
  };

  const selectCurrentView = (index) => {
    if (currentView !== index) {
      setCurrentView(index);
      if (onSelectChange) {
        onSelectChange(index);
      }
    }
  };

  const isSelected = (storedItems, data) =>
    storedItems.some((storedItem) =>
      data.photo_count === undefined
        ? storedItem.type === "photo" && storedItem.id === data.id
        : storedItem.type === "album" && storedItem.id === data.id
    );

  return (
    <>
      <div className="d-flex align-items-center">
        <div className={smallThumbs ? styles.navArrowSmall : styles.navArrow} onClick={showPreviousPage}>
          {combinedList.length > 0 && current > 0 ? (
            <ChevronLeft color="#4F4F4F" size={"3rem"} />
          ) : (
            <ChevronLeft color="#D4D4D4" size={"3rem"} />
          )}
        </div>
      </div>
      <div className="flex-grow-1 overflow-hidden" ref={thumbRef}>
        <div
          className={`d-flex flex-row ${smallThumbs ? "gap-1" : "gap-2"}`}
          style={{
            marginLeft: `${(smallThumbs ? -(6 + 0.25) : -(8 + 0.5)) * current}rem`,
            transition: "all 100ms ease-in-out",
            padding: `${smallThumbs ? "0.5rem" : "1rem"}`,
          }}
        >
          {combinedList.map((item, index) => (
            <div
              key={item.id}
              className={`${smallThumbs ? styles.thumbnailContainerSmall : styles.thumbnailContainer} ${currentView === index ? styles.selected : styles.unselected}`}
              onClick={() => {
                selectCurrentView(index);
              }}
            >
              <img
                alt=""
                src={item.photo_count === undefined ? item.abs_url : item.first_photo?.abs_url}
                className={styles.photo}
                loading="lazy"
              />
              {item.photo_count !== undefined && (
                <>
                  <div className="d-flex align-items-center position-absolute w-100 h-100 top-0">
                    <div className={styles.albumIconBack}>
                      <Folder color="#000000" size={"2rem"} />
                    </div>
                  </div>
                  <div className="d-flex align-items-center position-absolute w-100 h-100 top-0">
                    <div className={styles.albumIcon}>
                      <Folder color="#FFFFFF" size={"2rem"} />
                    </div>
                  </div>
                </>
              )}
              {selectMode && isSelected(selectedItems, item) && (
                <div className="d-flex align-items-center justify-content-center position-absolute w-100 h-100 top-0">
                  <GallerySelector selected={true} selectable={false} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className={smallThumbs ? styles.navArrowSmall : styles.navArrow} onClick={showNextPage}>
          {combinedList.length > 0 && current < combinedList.length - 1 ? (
            <ChevronRight color="#4F4F4F" size={"3rem"} />
          ) : (
            <ChevronRight color="#D4D4D4" size={"3rem"} />
          )}
        </div>
      </div>
    </>
  );
};

Carousel.propTypes = {};

export default Carousel;
