import React from "react";
import useProject from "../../hooks/useProject";
import { HeaderButton, FileUpdate, FileHistory, useAuth } from "component-library";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Clock, Briefcase, X, Type, Move, RotateCw, Trash2 } from "react-feather";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import styles from "./styles.module.scss";
import useAssetPage from "./useAssetPage";
import DeleteFileModal from "./DeleteFileModal";
import { BeatLoader } from "react-spinners";
import RenameFileModal from "./RenameFileModal";
import useProjectDocuments from "../ProjectDocuments/context/useProjectDocuments";
import { useParams } from "react-router-dom";
import AssetEditor from "./AssetEditor";

const AssetPage = () => {
  const {
    BreadCrumbs,
    historyModalOpen,
    setHistoryModalOpen,
    asset,
    showDeleteFileModal,
    setShowDeleteFileModal,
    showRenameModal,
    setShowRenameModal,
    showUpdateModal,
    setShowUpdateModal,
    handleUpdateAttachment,
    replacingFile,
    setReplacingFile,
    updateAttachmentLoading,
    updateAttachmentError,
    attachmentsLoading,
    attachmentsError,
    docType,
  } = useAssetPage();

  const history = useNavigate();
  const { folderList } = useProjectDocuments();
  const { project, loading, error, pageTitle } = useProject();
  const { userCan } = useAuth();
  const { id } = useParams();

  const isSpecOrPlans = asset.type === "Specbook" || asset.type === "Construction Plans"

  return (
    <InteriorPage
      loading={loading || attachmentsLoading}
      error={error || updateAttachmentError || attachmentsError}
      navTitle={pageTitle}
      navIcon={<Briefcase size={14} />}
      pageTitle={"Documents"}
      pageSubtitlePrimaryContent={!!project && !!asset && BreadCrumbs}
      fillScreen
    >
      <div className="w-100 position-relative">
        {!!updateAttachmentLoading && (
          <div className="loaderWrapper">
            <BeatLoader color="#0071ce" size={18} />
          </div>
        )}
        <div className="d-flex align-items-center justify-content-end">
          <div className="d-flex align-items-center me-5">
            <HeaderButton
              text="Update File"
              onClick={() => {
                setShowUpdateModal(true);
                setReplacingFile(true);
              }}
              icon={<RotateCw color="#0071ce" size={16} />}
            />
            <HeaderButton
              text="History"
              icon={<Clock color="#0071ce" size={16} />}
              onClick={() => {
                setHistoryModalOpen(true);
              }}
            />
            <HeaderButton
              text="Move File"
              onClick={() => {
                setShowUpdateModal(true);
                setReplacingFile(false);
              }}
              icon={<Move color="#0071ce" size={16} />}
              disabled={isSpecOrPlans}
            />
            <HeaderButton
              text="Rename File"
              onClick={() => setShowRenameModal(true)}
              icon={<Type color="#0071ce" size={16} />}
            />
            {!!asset?.type !== "Construction Plans" && (
              <HeaderButton
                text="Delete File"
                onClick={() => setShowDeleteFileModal(true)}
                icon={<Trash2 color="#0071ce" size={16} />}
              />
            )}
          </div>
          <Button
            onClick={() => {
              if (asset?.folder) {
                history(`/project/${id}/documents/folder/${asset?.folder}`);
              } else {
                history(`/project/${id}/documents`);
              }
            }}
            className={styles.returnButton}
          >
            <X size={24} />
          </Button>
        </div>
        {!!docType && ["word", "cell", "slide"].includes(docType) ? (
          <>
            <AssetEditor asset={asset} documentType={docType} />
          </>
        ) : (
          <>{asset?.abs_url && <iframe title={asset.file_name} src={asset.url} className="file-embed" />}</>
        )}

        {!!asset && <FileHistory file={asset} show={historyModalOpen} hide={() => setHistoryModalOpen(false)} />}
        <DeleteFileModal asset={asset} showModal={showDeleteFileModal} setShowModal={setShowDeleteFileModal} />
        <RenameFileModal asset={asset} showModal={showRenameModal} setShowModal={setShowRenameModal} />
        <FileUpdate
          oldFile={asset}
          show={showUpdateModal}
          hide={setShowUpdateModal}
          updateAttachment={handleUpdateAttachment}
          manageFolder={!isSpecOrPlans}
          replaceFile={replacingFile}
          folderOptions={folderList?.map((folder) => {
            return { label: folder, value: folder };
          })}
          userCan={userCan}
        />
      </div>
    </InteriorPage>
  );
};

export default AssetPage;
