import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import GridItem from "./GridItem";

const LibraryGrid = ({ project, selectedPhotoIds, updateSelectedPhotos, gridItems, gridSize = "small" }) => {
  return (
    <>
      <div
        className={`${
          gridSize === "large"
            ? styles.largeGrid
            : gridSize === "medium"
            ? styles.mediumGrid
            : gridSize === "small"
            ? styles.smallGrid
            : ""
        } ${styles.photoGrid}`}
      >
        {!!gridItems?.length ? (
          gridItems.map((item) => (
            <GridItem
              project={project}
              key={item.id}
              item={item}
              updateSelectedPhotos={updateSelectedPhotos}
              selectedPhotoIds={selectedPhotoIds}
            />
          ))
        ) : (
          <>No items found</>
        )}
      </div>
    </>
  );
};

LibraryGrid.propTypes = {
  gridItems: PropTypes.array,
  gridSize: PropTypes.string,
};

export default LibraryGrid;
