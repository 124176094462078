import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const ContactOptions = ({ contacts, handleSelect, selected, colDisplay }) => {
  return (
    <>
      {contacts.map((option, i) => (
        <tr
          onClick={() => handleSelect(option)}
          key={i}
          className={`${selected === i ? "" : ""} dropdown-option ${!!colDisplay && "d-flex flex-column"}`}
        >
          <td className={`${styles.company} font-weight-bold text-gray2 ${!!colDisplay && styles.columnLine}`}>
            {option.last_name}, {option.first_name}
          </td>
          <td className={`${styles.name} sm-med ${!!colDisplay && styles.columnLine}`}>
            {option.company_name}, {option.location_name}
          </td>
          <td className={`${styles.address} sm-med font-italic ${!!colDisplay && styles.columnLine}`}>
            {`${option.address1}, 
            ${option.city}, 
            ${option.state} 
            ${option.zip}`}
          </td>
        </tr>
      ))}
    </>
  );
};

ContactOptions.propTypes = {
  contacts: PropTypes.array,
  handleSelect: PropTypes.func.isRequired,
  selected: PropTypes.number,
};

export default ContactOptions;
