import { useEffect, useState } from "react";
import { weeklyReportsApi, useApi } from "component-library";
import { DateTime } from "luxon";
import useProject from "../../../_projects/hooks/useProject";

const useWeeklyReportsList = ({ projectId, projectManager }) => {
  const { weeklyReportDateRange } = useProject();
  const [dateRange, setDateRange] = useState(weeklyReportDateRange);
  const [gridData, setGridData] = useState();

  const formatDate = (date) => {
    if (date) {
      return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
    } else return "";
  };

  const {
    request: reportsListRequest,
    data: reportsListData,
    loading,
    error,
  } = useApi(weeklyReportsApi.getWeeklyReportsList);

  useEffect(() => {
    if (!loading && (dateRange.length === 0 || !!dateRange[1])) {
      reportsListRequest({
        project_id: projectId,
        project_manager_id: projectManager,
        from_date: formatDate(dateRange[0]),
        to_date: formatDate(dateRange[1]),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, dateRange, projectManager]);

  useEffect(() => {
    if (!!reportsListData) {
      setGridData(reportsListData.list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsListData]);

  return {
    gridData,
    loading,
    error,
    dateRange,
    setDateRange,
  };
};

export default useWeeklyReportsList;
