import React from "react";
import ProvideLocates from "../../components/LocatesAndPermits/Locates/ProvideLocates";
import LocatesPage from "../../components/LocatesAndPermits/Locates/LocatesPage";

const Locates = () => {
  return (
    <ProvideLocates>
      <LocatesPage />
    </ProvideLocates>
  );
};

export default Locates;
