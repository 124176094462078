import React from "react";
import { Edit3 } from "react-feather";
import DateCard from "./DateCard";
import styles from "../styles.module.scss";
import { useAuth, displayDate } from "component-library";
import PropTypes from "prop-types";
import Alert from "../../../../components/Alert/Alert";
import { Button } from "react-bootstrap";

const Dates = ({ project, setIsEditing }) => {
  const { userCan } = useAuth();
  const canEdit = userCan("project-edit");

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    const dateTime = new Date(date);
    return displayDate(dateTime);
  };

  return (
    <div className={`${styles.section} row py-3`}>
      <div className={`${styles.sectionTitleWrapper} col-12`}>
        <h2 className={`${styles.sectionTitle}`}>Project Dates</h2>
        {canEdit && (
          <Button className={styles.editDatesButton} onClick={() => setIsEditing(true)} aria-label="Edit project dates">
            <Edit3 size={16} color="#A7A7A7" />
          </Button>
        )}
      </div>
      <div className={`${styles.dateRow} col-12`}>
        {!!project && (
          <>
            {!project.early_start_date && !project.late_start_date && !project.completion_date ? (
              <Alert
                title="You haven't set any Project Dates yet"
                dismissable={false}
                onClick={() => setIsEditing(true)}
                onClickText="Add Now"
                message="Add project dates in the project settings, or add now."
              />
            ) : (
              <>
                <DateCard date={formatDate(project.early_start_date)} title="Early Start Date" />
                <DateCard date={formatDate(project.late_start_date)} title="Late Start Date" />
                <DateCard date={formatDate(project.completion_date)} title="Completion Date" />
                <DateCard date={project.working_days} title="Working Days" grid />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

Dates.propTypes = {
  project: PropTypes.object,
  setIsEditing: PropTypes.func,
};

export default Dates;
