import { Joi } from "component-library";

const schema = {
  note: Joi.saneString(),
  files: Joi.array(),
};

const initialData = {
  note: "",
  files: [],
};

export { schema, initialData };
