import { Joi } from "component-library";

const schema = {
  note: Joi.saneString(),
  files: Joi.array(),
  project_id: Joi.number().required().label("Project"),
};

const initialData = {
  note: "",
  project_id: 0,
  files: [],
};

export { schema, initialData };
