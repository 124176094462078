import React, { useState } from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import Select from "react-select";
import { dropdownStylesWithWidthAndColor, Loader, ConfirmModal, useAuth } from "component-library";
import MinutesInput from "../../../../MinutesInput";
import { Alert, Button } from "react-bootstrap";
import { Trash2 } from "react-feather";
import useTimeEntry from "./useTimeEntry";
import { Link } from "react-router-dom";

const TimeEntry = ({
  entry,
  editing,
  setFieldLog,
  fieldLog,
  foremanId,
  setOverAllocatedTimeSheets,
  overAllocatedTimeSheets,
  prevailingWage,
  config,
  projectId,
}) => {
  const {
    error,
    loading,
    handleSelectChange,
    secondaryEquipmentOptions,
    deleteTimeEntry,
    minutes,
    setMinutes,
    removeError,
    equipmentOptions,
    laborOptions,
  } = useTimeEntry({
    fieldLog,
    setFieldLog,
    entry,
    foremanId,
    setOverAllocatedTimeSheets,
    overAllocatedTimeSheets,
    config,
  });

  const { userCan } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      {!!error && <Alert variant="danger">{error}</Alert>}
      <div className={`position-relative ${styles.timeEntry}`}>
        <Loader loading={!!loading} />
        <div className="d-flex align-items-center flex-grow-1">
          <div className={styles.firstColumn}>
            <Link
              to={`/project/${projectId}/field-management/timesheet/${entry.time_sheet_id}/foreman/${foremanId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {entry.user.first_name} {entry.user.last_name}
            </Link>
          </div>
          <div className="d-flex flex-grow-1 me-2 ">
            {!!equipmentOptions?.length && (
              <Select
                options={equipmentOptions}
                name="equipment"
                id="equipmentId"
                styles={dropdownStylesWithWidthAndColor(
                  "auto",
                  prevailingWage !== 1 || !editing || !userCan("payroll-review-time") ? "#d3d3d3" : "#fff"
                )}
                className={`${styles.select} flex-shrink-1 flex-grow-1 mx-2`}
                isDisabled={prevailingWage === 1 && !!userCan("payroll-review-time") && !!editing ? false : true}
                onChange={handleSelectChange("equipment")}
                value={
                  !!equipmentOptions
                    ? equipmentOptions.find((option) => option.value === entry.equipment_id) || null
                    : null
                }
              />
            )}
            {!!secondaryEquipmentOptions?.length && (
              <Select
                options={secondaryEquipmentOptions || []}
                name="secondary_equipment"
                id="secondaryEquipmentId"
                styles={dropdownStylesWithWidthAndColor(
                  "auto",
                  prevailingWage !== 1 || !editing || !userCan("payroll-review-time") ? "#d3d3d3" : "#fff"
                )}
                className={`${styles.select} flex-shrink-1 flex-grow-1 mx-2`}
                isDisabled={prevailingWage === 1 && !!userCan("payroll-review-time") && !!editing ? false : true}
                onChange={handleSelectChange("secondary_equipment")}
                value={
                  !!secondaryEquipmentOptions
                    ? secondaryEquipmentOptions.find((option) => option.value === entry.secondary_equipment_id) || null
                    : null
                }
              />
            )}
            {!!laborOptions?.length && (
              <Select
                options={laborOptions}
                name="labor_type"
                id="laborTypeId"
                styles={dropdownStylesWithWidthAndColor(
                  "auto",
                  !editing || prevailingWage !== 1 || !userCan("payroll-review-time") ? "#d3d3d3" : "#fff"
                )}
                className={`${styles.select} flex-shrink-1 flex-grow-1 mx-2`}
                isDisabled={prevailingWage === 1 && !!userCan("payroll-review-time") && !!editing ? false : true}
                onChange={handleSelectChange("labor")}
                value={!!laborOptions ? laborOptions.find((option) => option?.value === entry?.labor_id) : null}
              />
            )}
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="input-wrap">
            <MinutesInput
              minutes={minutes}
              setMinutes={setMinutes}
              canEdit={!!userCan("payroll-review-time") && !!editing ? true : false}
            />
          </div>
          {!!editing && userCan("payroll-review-time") && (
            <div>
              <Button onClick={() => setModalOpen(true)} variant="link">
                <Trash2 size="20" color="#D3D3D3" />
              </Button>
            </div>
          )}
        </div>
      </div>
      <ConfirmModal
        showConfirmation={modalOpen}
        setShowConfirmation={setModalOpen}
        confirmFunction={deleteTimeEntry}
        loading={loading}
        error={removeError}
        name={`${entry.user.first_name} ${entry.user.last_name}`}
        message="Are you sure you want to remove the time entry for this user?"
      />
    </>
  );
};

TimeEntry.propTypes = {
  entry: PropTypes.object,
  equipment: PropTypes.array,
  editing: PropTypes.bool,
  setFieldLog: PropTypes.func,
  fieldLog: PropTypes.object,
  overAllocatedTimeSheets: PropTypes.array,
  setOverAllocatedTimeSheets: PropTypes.func,
  prevailingWage: PropTypes.any,
  config: PropTypes.object,
};

export default TimeEntry;
