import {
  name,
  source_name,
  spec_section,
  plans_section,
} from "./columnDefs";

const columnDefs = [
  name,
  source_name,
  spec_section,
  plans_section,
];

export default columnDefs;