import { useState, useEffect } from "react";
import { useApi } from "component-library";
import companiesApi from "../../api/companies";
import { useParams } from "react-router-dom";

const useCompany = ({ setCompany }) => {
  const { id } = useParams();

  const { loading, request, data } = useApi(companiesApi.getCompany);
  const [editingLocation, setEditingLocation] = useState(false);
  const [editLocation, setEditLocation] = useState();
  
  const [archivingLocation, setArchivingLocation] = useState();
  const [archiveLocation, setArchiveLocation] = useState();

  const [archiveContact, setArchiveContact] = useState();
  const [archivingContact, setArchivingContact] = useState();
  
  useEffect(() => {
    request(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data && !!data.company) {
      setCompany(data.company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    loading,
    setEditLocation,
    setEditingLocation,
    archivingLocation,
    setArchivingLocation,
    setArchiveLocation,
    archiveLocation,
    editingLocation,
    editLocation,
    archiveContact,
    setArchiveContact,
    archivingContact,
    setArchivingContact,
  };
};

export default useCompany;
