import React from "react";
import { ChevronRight } from "react-feather";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import useDashboard from "./useDashboard";

const JobAlert = ({ alert, fullpage }) => {
  const { idLookup } = useDashboard();

  return (
    <>
      {!!idLookup[alert.job_id] ? (
        <Link
          to={`/project/${idLookup[alert.job_id]}/reporting`}
          className={`
            ${styles["job-alert"]}
            ${styles[`level-${alert.level}`]}
            ${!!fullpage && "flex-row align-items-center"}
          `}
        >
          <div className={styles["job-alert-title"]}>
            {`${alert.job_id} ${alert.description}`}
          </div>
          <div className={styles["job-alert-type"]}>{alert.type}</div>
          <ChevronRight />
        </Link>
      ) : (
        <div
          className={`
            ${styles["job-alert"]}
            ${styles[`level-${alert.level}`]}
            ${!!fullpage && "flex-row align-items-center"}
          `}
        >
          <div className={styles["job-alert-title"]}>
            {`${alert.job_id} ${alert.description}`}
          </div>
          <div className={styles["job-alert-type"]}>{alert.type}</div>
        </div>
      )}
    </>
  );
};

export default JobAlert;
