import formatTime from "../../../utils/formatTime";
import dateTimeComparator from "../../../utils/dateTimeComparator";
import ClockIn from "../../sharedColumns/columnRenders/ClockIn"

const time_in = {
  headerName: "Clock In",
  field: "clock_in",
  maxWidth: 132,
  comparator: dateTimeComparator,
  valueGetter: ({ data }) => (data.clock_in ? formatTime(data.clock_in) : null),
  cellRenderer: ClockIn,
};

export default time_in;
