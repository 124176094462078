import React from "react";
import Chiclet from "./Chiclet";
import styles from "./styles.module.scss";
import CashflowItemChart from "./CashflowItemChart";
import getJobName from "./getJobName";
import FooterLinkToProject from "./FooterLinkToProject";

const CashflowGridItem = ({ job }) => {

  return (
    <Chiclet className="homeChartWrap">
      <div>
        <h3 className={styles["job-title"]}>{getJobName(job)}</h3>
        <CashflowItemChart job={job}></CashflowItemChart>
      </div>
      <FooterLinkToProject job={job} />
    </Chiclet>
  );
};

export default CashflowGridItem;
