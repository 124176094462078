import { useEffect, useState } from "react";
import useProject from "../../hooks/useProject";
import { useApi, quoteApi, useAuth } from "component-library";
import { BeatLoader } from "react-spinners";
import { HeaderButton } from "component-library";

const useQuotes = () => {
  const { project } = useProject();
  const { user } = useAuth();
  const [quotes, setQuotes] = useState([]);
  const [filteredQuotes, setFilteredQuotes] = useState([]);

  const { data, loading, error, request } = useApi(quoteApi.getQuotes);

  const {
    data: createQuoteData,
    loading: createQuoteLoading,
    error: createQuoteError,
    request: createQuoteRequest,
  } = useApi(quoteApi.createQuote);

  const filterQuotes = (q) => {
    setFilteredQuotes(
      quotes.filter(
        (quote) =>
          (quote.supplier && quote.supplier.name.toLowerCase().indexOf(q.toLowerCase()) > -1) ||
          quote.id.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  };

  const handleCreateQuote = () => {
    createQuoteRequest(project.id, user.id);
  };

  const AddQuoteButton = () => (
    <div className="position-relative">
      {!!createQuoteLoading && (
        <div className="loaderWrapper">
          <BeatLoader color={"#0071ce"} size={10} />
        </div>
      )}
      <HeaderButton onClick={handleCreateQuote} text="Add Quote" />
    </div>
  );

  useEffect(() => {
    if (project && project.id) request(project.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (!!data && data.quotes) setQuotes(data.quotes);
  }, [data]);

  useEffect(() => {
    setFilteredQuotes(quotes);
  }, [quotes]);

  return {
    filteredQuotes,
    filterQuotes,
    loading,
    error,
    AddQuoteButton,
    createQuoteError,
    createQuoteData,
  };
};

export default useQuotes;
