import React from "react";
import { Calendar } from "react-feather";
import styles from "./styles.module.scss";
import { displayDate } from "component-library";

const Milestone = ({ milestone }) => {
  return (
    <article className={styles.milestoneWrap}>
      <div className={styles.milestone}>
        <Calendar color="#0071ce" size={16} />
        {!!milestone.early_start_date && (
          <div>
            <time className="light-text med">{displayDate(milestone.early_start_date)}</time>
            <p className="sm light-text text-uppercase">Early Start Date</p>
          </div>
        )}
        <h3 className="med text-break">{milestone.title}</h3>
      </div>
    </article>
  );
};

export default Milestone;
