import React from "react";
import { Routes, Route } from "react-router";
import PrivateRoute from "../components/PrivateRoute";
import SubmittalsListing from "./pages/SubmittalsListing";
import SubmittalDetail from "./pages/SubmittalDetail";
import SubmittalEdit from "./pages/SubmittalEdit";
import QuickAddSubmittals from "./pages/QuickAddSubmittals";
import AddSubmittal from "./pages/AddSubmittal";
import ProvideSubmittals from "./hooks/ProvideSubmittals"

const SubmittalRoutes = () => {
  return (
    <ProvideSubmittals>
      <Routes>
        <Route
          index
          element={<PrivateRoute viewPermission="submittal-list" module="Portal" Component={<SubmittalsListing />} />}
        />
        <Route
          path="submittals"
          element={
            <PrivateRoute viewPermission="submittal-list" module="Submittals" Component={<SubmittalsListing />} />
          }
        />
        <Route
          path=":submittalId"
          element={<PrivateRoute viewPermission="submittal-view" module="Submittals" Component={<SubmittalDetail />} />}
        />
        <Route
          path="add-submittals"
          element={
            <PrivateRoute viewPermission="submittal-create" module="Submittals" Component={<QuickAddSubmittals />} />
          }
        />
        <Route
          path="add-submittal"
          element={<PrivateRoute viewPermission="submittal-create" module="Submittals" Component={<AddSubmittal />} />}
        />
        <Route
          path=":submittalId/edit"
          element={<PrivateRoute viewPermission="submittal-edit" module="Submittals" Component={<SubmittalEdit />} />}
        />
      </Routes>
    </ProvideSubmittals>
  );
};

export default SubmittalRoutes;
