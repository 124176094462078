import Name from "../contactsAdminColumns/Name";

const name = {
  headerName: "Name",
  field: "last_name",
  maxWidth: 225,
  minWidth: 125,
  cellRenderer: Name,
};

export default name;
