import React from "react";
import PropTypes from "prop-types";
import { ContactPill } from "component-library";

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const Contact = ({
  contact,
  contact: { archived, first_name, last_name, contact_type, title, email, phone, cell_phone, company_name, locations },
  noAddress,
  noType,
  colDisplay,
}) => {
  const addr = !!locations && !!locations.length && !!locations[0].address && locations[0].address;
  return (
    <div className={`contact ${colDisplay ? "d-flex flex-column" : "row"}`}>
      <div className={colDisplay ? null : "col-xxl-6 col-12"}>
        <div className={`${!!archived ? "text-gray3" : ""} contact-name`}>{`${first_name} ${last_name}`}</div>
        {!!archived ? (
          <span className="sm text-gray3">(archived)</span>
        ) : (
          <>
            {!noType && contact_type && <div className="sm">{contact_type}</div>}
            {title && <div className="sm">{title}</div>}
            {!noAddress && (
              <>
                {!!locations && !!locations.length ? (
                  <div className="text-uppercase sm contact-address pt-1">{locations[0].company_name[0]}</div>
                ) : (
                  <div className="text-uppercase sm contact-address pt-1">{company_name}</div>
                )}
              </>
            )}
            {!noAddress && !!addr && (
              <div className="text-uppercase sm contact-address">
                <div>{addr.address1}</div>
                {addr.address2 && <div>{addr.address2}</div>}
                <div>{`${addr.city}, ${addr.state} ${addr.zip}`}</div>
              </div>
            )}
            {!noAddress && !addr && (
              <div className="text-uppercase sm contact-address">
                <div>{contact.address1}</div>
                {contact.address2 && <div>{contact.address2}</div>}
                <div>{`${contact.city}, ${contact.state} ${contact.zip}`}</div>
              </div>
            )}
          </>
        )}
      </div>
      <div className={colDisplay ? null : "col-xxl-6 col-12"}>
        <ConditionalWrapper
          condition={noAddress}
          wrapper={(children) => <div className="flex-row-reverse d-flex justify-content-end">{children}</div>}
        >
          {!noAddress && email && <ContactPill data={email} isEmail isArchived={!!archived} />}
          {!noAddress && phone && <ContactPill data={phone} isPhone isArchived={!!archived} />}
        </ConditionalWrapper>
        {cell_phone && <ContactPill data={cell_phone} isCell isArchived={!!archived} />}
      </div>
    </div>
  );
};

Contact.propTypes = {
  contact: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    contact_type: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    cell_phone: PropTypes.string,
    company: PropTypes.array,
    addresses: PropTypes.array,
  }),
  noAddress: PropTypes.bool,
};

export default Contact;
