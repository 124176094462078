import { useState, useEffect } from "react";
import { useApi, taskDetailApi } from "component-library";
import { useParams } from "react-router-dom";

const useForemen = () => {
  const { id, taskId } = useParams();
  const [foremen, setForemen] = useState([]);

  const {
    request: foremenRequest,
    data: foremenData,
    loading: foremenLoading,
    error: foremenError,
  } = useApi(taskDetailApi.getTaskForemen);

  useEffect(() => {
    if (taskId) {
      foremenRequest({ projectId: id, taskId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  useEffect(() => {
    setForemen(foremenData?.foremen || []);
  }, [foremenData]);

  return {
    foremen,
    foremenLoading,
    foremenError,
  };
};

export default useForemen;
