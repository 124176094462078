import { currency } from "component-library";

/**
 * Handles autocalculating the contract amount fields when the idot_proposal_guaranty_amount field is changed no no prior values exist
 * @param  {Array} object object of form field values
 * @param  {Number} value value amount to multiply by the contract amount
 * @param  {Number} value value amount to multiply by the high contract amount

 * @return {Boolean} returns existing form data with possible changes to estimated contract amounts
 */

const autoCompleteContractAmounts = (formData, timesAmount = 10, highAmount = 20) => {
  let data = { ...formData };
  if (
    !!parseFloat(data.idot_proposal_guaranty_amount) &&
    !parseFloat(data.estimated_contract_amount) &&
    !parseFloat(data.estimated_contract_amount_high)
  ) {
    data = {
      ...formData,
      estimated_contract_amount: currency(parseFloat(data.idot_proposal_guaranty_amount) * timesAmount),
      estimated_contract_amount_high: currency(parseFloat(data.idot_proposal_guaranty_amount) * highAmount),
    };
  }

  return data;
};

export default autoCompleteContractAmounts;
