import React from "react";
import { UserPlus } from "react-feather";
import { ReactComponent as Send } from "../../assets/send.svg";
import styles from "./styles.module.scss";
import { HeaderButton } from "component-library";

const AddRFQHeaderButtons = ({ selectedVendorIds, sendRFQ, setAddNewContact }) => {
  return (
    <div className="d-flex align-items-center">
      <HeaderButton
        onClick={() => setAddNewContact(true)}
        icon={<UserPlus color="#0071ce" size={16} />}
        text="Create New Contact"
      />
      <HeaderButton
        onClick={sendRFQ}
        icon={
          <Send style={{ color: !selectedVendorIds?.length ? "#7b7b7b" : "#0071ce" }} className={`${styles.icon}`} />
        }
        text="Send RFQ"
        disabled={!selectedVendorIds?.length}
      />
    </div>
  );
};

export default AddRFQHeaderButtons;
