import React from "react";
import useInitalCreateBid from '../../_bidManagement/hooks/useInitalCreateBid';
import InteriorPage from "../pageLayout/InteriorPage";
import { Tag } from "react-feather";
import CreateBidHeader from "./CreateBidHeader"

const CreateNewBid = () => {
  const { fields, handleSubmit } = useInitalCreateBid();

  return (
    <InteriorPage
      loading={false}
      navTitle="Bids"
      pageTitle="Create New Bid"
      navIcon={<Tag color="#fff" size={14} />}
      pageTitleSecondaryContent={CreateBidHeader}
    >
      <div className="row justify-content-center pt-4">
        <div className="col-8">
          <form onSubmit={handleSubmit} id="create-initial-bid-form">
            {fields}
          </form>
        </div>
      </div>
    </InteriorPage>
  );
};

export default CreateNewBid;
