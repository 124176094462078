import React from "react";
import useUserAdmin from "./hooks/useUserAdmin";
import { Modal } from "react-bootstrap";
import CreateAccount from "./CreateAccount";
import { Alert } from "react-bootstrap";

const CreateUserModal = () => {
  const { addUser, setAddUser, registrationError, registerUser, setRegistrationAlert } = useUserAdmin();
  return (
    <Modal centered animation={false} show={!!addUser} onHide={() => setAddUser(false)}>
      <Modal.Header closeButton closeLabel="close add user modal">
        <h5 className="lg mb-0 med">Add User Account</h5>
      </Modal.Header>
      <Modal.Body>
        {!!registrationError && <Alert variant="danger">{registrationError}</Alert>}
        <CreateAccount registerUser={registerUser} setRegistrationAlert={setRegistrationAlert} />
      </Modal.Body>
    </Modal>
  );
};

export default CreateUserModal;
