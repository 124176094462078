import { displayDate, Loader, useAuth } from "component-library";
import styles from "./styles.module.scss";
import DayStats from "./DayStats";
import ReportDates from "./ReportDates/ReportDates";
import FieldNotes from "./FieldNotes";
import { Alert } from "react-bootstrap";
import UnauthorizedCard from "../../../components/UnauthorizedCard";

const WeeklyReportEditPageContent = ({
  project,
  report,
  loading,
  updateDate,
  weather,
  handleUpdateNotes,
  reportNotes,
  handleRemoveNote,
  message,
  handleUnsetMessage,
  updateWeeklyNotesError,
}) => {
  const { userCan } = useAuth();
  return (
    <>
      {userCan("weekly-report-edit") ? (
        <div className="position-relative">
          <Loader loading={loading} />
          {!!updateWeeklyNotesError && <Alert variant="danger">{updateWeeklyNotesError}</Alert>}
          {!!message && (
            <Alert variant="success" dismissible onClick={() => handleUnsetMessage()}>
              {message}
            </Alert>
          )}
          {!!updateWeeklyNotesError && <Alert variant="danger">{updateWeeklyNotesError}</Alert>}
          <div className="d-flex align-items-center justify-content-between mt-4">
            <div className={styles.reportDateRange}>
              {displayDate(report?.start_date)} - {displayDate(report?.end_date)}
            </div>
            <DayStats report={report} />
          </div>
          <div className={styles.reportWrapper}>
            <div className="w-100">
              <ReportDates report={report} updateDate={updateDate} weather={weather} project={project} />
            </div>
            <FieldNotes
              project={project}
              report={report}
              reportNotes={reportNotes}
              handleUpdateNotes={handleUpdateNotes}
              handleRemoveNote={handleRemoveNote}
            />
          </div>
        </div>
      ) : (
        <UnauthorizedCard />
      )}
    </>
  );
};

export default WeeklyReportEditPageContent;
