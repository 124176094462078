import React from "react";
import { RotateCw, Tag } from "react-feather";
import { Navigate } from "react-router-dom";
import useProject from "../../../hooks/useProject";
import { XCircle, Edit } from "react-feather";
import PropTypes from "prop-types";
import { HeaderButton } from "component-library";
import HeaderPortal from "../../../../components/HeaderPortal";

const QuoteControls = ({ quote, requote, cancelRequote, addPOData, addPurchaseOrder }) => {
  const { project } = useProject();

  return (
    <>
      {!!addPOData && !!addPOData.purchaseOrder && (
        <Navigate to={`/project/${project.id}/purchase-orders/${addPOData.purchaseOrder.id}`} />
      )}
      <HeaderPortal>
        <div className="d-flex align-items-center">
          {quote.status !== 2 && quote.status !== 3 && !quote.po_submitted && (
            <HeaderButton
              className="d-flex align-items-center btn-link link q-link"
              linkTo={`/project/${quote.project_id}/quotes/${quote.id}/edit`}
              icon={<Edit size={16} color="#0071ce" />}
              text="Edit Quote"
            />
          )}
          {quote.status === 3 && !!quote.requote ? (
            <div className="q-cancel-requote-link sm-med border-0">
              Requote in Progress
              <HeaderButton
                onClick={() => cancelRequote()}
                icon={<XCircle size="16" className="ms-2" color="#0071ce" />}
              />
            </div>
          ) : (
            <HeaderButton text="Requote" onClick={() => requote()} icon={<RotateCw size={16} color="#0071ce" />} />
          )}
          <HeaderButton
            disabled={!quote?.supplier || quote.status === 3 ? true : false}
            onClick={() => addPurchaseOrder(quote.id)}
            text="Create Purchase Order"
            icon={<Tag size={16} color={!quote?.supplier || quote.status === 3 ? "#7b7b7b" : "#0071ce"} />}
          />
        </div>
      </HeaderPortal>
    </>
  );
};

QuoteControls.propTypes = {
  quote: PropTypes.object,
  requote: PropTypes.func,
  cancelRequote: PropTypes.func,
  data: PropTypes.object,
  addPurchaseOrder: PropTypes.func,
};

export default QuoteControls;
