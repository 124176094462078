import React from "react";
import styles from "./styles.module.scss";
import CustomScroll from "react-custom-scroll";
import { StyledDatePicker, Loader } from "component-library";
import useDriverTimeline from "../../../hooks/useDriverTimeline";
import { DateTime } from "luxon";
import { Alert, Button } from "react-bootstrap";
import { Calendar } from "react-feather";
import Entry from "./Entry";

const Detail = ({ driverData, timelineRequestData, timelineLoading, timelineError, timelineRequest, userError }) => {
  const { today, selectedTimelineDate, setSelectedTimelineDate, getActivityType, timelineData } = useDriverTimeline({
    driverData,
    timelineRequestData,
    timelineRequest,
  });
  return (
    <div className={styles.mobileWrap}>
      <div className={styles.mobileContent}>
        <div className={styles.subheader}>
          <h1 className={styles.title}>Schedule Timeline</h1>
          {!!driverData?.sched_driver_name && (
            <div className={styles.driverName}>{driverData.sched_driver_name || "N/A"}</div>
          )}
        </div>
        <div className={styles.bodyWrapper}>
          <div>
            <div className="d-flex align-items-center w-100">
              <div className={styles.actionRow}>
                <div className={styles.dateWrapper}>
                  <StyledDatePicker
                    className={styles.white}
                    name="schedule-status"
                    id="schedule_status"
                    selected={selectedTimelineDate}
                    onChange={(date) => setSelectedTimelineDate(date)}
                  />
                </div>
                <div>
                  {DateTime.fromJSDate(selectedTimelineDate).toFormat("yyyy-MM-dd") !==
                    DateTime.fromJSDate(today).toFormat("yyyy-MM-dd") && (
                    <Button
                      onClick={() => setSelectedTimelineDate(today)}
                      className="med d-flex align-items-center px-0 ms-2"
                      variant="link"
                    >
                      <Calendar size={18} /> <span className="ms-1">Today</span>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <CustomScroll heightRelativeToParent="100%">
            {!!userError && <Alert variant="danger">{userError}</Alert>}
            {!!timelineLoading ? (
              <Loader />
            ) : (
              <div className={`${styles.body}`}>
                {!!timelineError && <Alert variant="danger">timelineError</Alert>}
                {!!timelineData?.length ? (
                  <div className="flex-1 w-100">
                    {timelineData.map(
                      (entry, index) =>
                        entry.category !== "Trailers" && (
                          <Entry
                            key={index}
                            timelineData={timelineData}
                            getActivityType={getActivityType}
                            entry={entry}
                          />
                        )
                    )}
                  </div>
                ) : (
                  <div>No timeline results found for driver on selected date</div>
                )}
              </div>
            )}
          </CustomScroll>
        </div>
      </div>
    </div>
  );
};

export default Detail;
