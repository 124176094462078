import { useState, useEffect } from "react";
import useEstimateConversion from "../../useEstimateConversion";
import useItemPhasing from "../useItemPhasing";

const useBidItem = ({ bidItem }) => {
  const [open, setOpen] = useState(false);
  const [totalEstHours, setTotalEstHours] = useState(0);

  const { bidItems, setBidItems, openTasks, setPhasesError } = useEstimateConversion();

  const { qtyTotal, phaseIds, getPhaseQty, setPhaseQty, setUpdatePhaseQty, updateError, updateLoading } =
    useItemPhasing({ item: bidItem });

  const updateItem = (field, value) => {
    setBidItems(
      bidItems.map((bItem) => {
        if (bItem.id === bidItem.id) {
          return {
            ...bItem,
            [field]: value,
          };
        }
        return bItem;
      })
    );
  };

  useEffect(() => {
    if (openTasks?.includes(bidItem.id)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTasks]);

  useEffect(() => {
    if (!!updateError) {
      setPhasesError(updateError);
    } else {
      setPhasesError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateError]);

  const getSubItemEstHours = (item, currentTotal) => {
    let total = currentTotal;
    if (item?.sub_items?.length) {
      const hours = item.sub_items.reduce((acc, curr) => acc + parseFloat(curr.task_estimated_hours), 0);
      total = parseFloat(total) + parseFloat(hours);

      item.sub_items.forEach((subItem) => {
        if (subItem.sub_items.length) {
          total = getSubItemEstHours(subItem, total);
        }
      });
    }
    return total;
  };

  useEffect(() => {
    let total = bidItem.task_estimated_hours;
    if (!!bidItem.sub_items?.length) {
      total = getSubItemEstHours(bidItem, total);
    }

    setTotalEstHours(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidItem]);

  return {
    qtyTotal,
    open,
    updateItem,
    phaseIds,
    getPhaseQty,
    setPhaseQty,
    setUpdatePhaseQty,
    updateLoading,
    totalEstHours,
  };
};

export default useBidItem;
