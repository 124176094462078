import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import styles from "./styles.module.scss";
import useEstimateConversion from "../../useEstimateConversion";
import BidItem from "./BidItem";
import tableStyles from "../../TaskManagment/styles.module.scss";
import Pagination from "../../../../../components/Pagination";
import TaskSearch from "../../TaskManagment/TaskSearch";

const TasksTable = ({
  material,
  toggleSelectedBidItem,
  selectedTaskBidItems,
  setSelectedTaskBidItems,
}) => {
  const { bidItems, itemsPerPage, currentPage, setCurrentPage, totalResults } =
    useEstimateConversion();
  const [allSelected, setAllSelected] = useState(0);
  const [update, setUpdate] = useState(false);

  const handleSelectAll = () => {
    setUpdate(true);
    setAllSelected(!allSelected ? 1 : 0);
  };

  useEffect(() => {
    if (!!update) {
      if (!allSelected) {
        setSelectedTaskBidItems([]);
      } else {
        let allIds = [];
        bidItems.forEach((item) => {
          !!item?.tasks?.length &&
            item.tasks.forEach((task) => {
              if (!selectedTaskBidItems.includes(task.id)) {
                allIds = [...allIds, task.id];
              }
            });
        });
        setSelectedTaskBidItems([...selectedTaskBidItems, ...allIds]);
      }
      setUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelected]);

  useEffect(() => {
    setAllSelected(1);
    if (!!bidItems?.length) {
      bidItems.forEach((item) => {
        item.tasks.forEach((task) => {
          if (!selectedTaskBidItems.includes(task.id)) {
            setAllSelected(0);
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidItems]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="mb-2 text-gray2">Bid Items {`(${selectedTaskBidItems.length})`}</div>
        <div className="mb-2">
          <TaskSearch />
        </div>
      </div>
      <div className={`${styles.taskTableWrapper}`}>
        <Table striped className={tableStyles.taskTable}>
          <thead>
            <tr>
              <th>
                <div className="form-group form-check mx-auto my-0 d-flex justify-content-center">
                  <input
                    id="select-all"
                    name={`${material.name}-${material.id}`}
                    type="checkbox"
                    checked={!!allSelected ? 1 : 0}
                    onChange={() => handleSelectAll()}
                    className={`form-check-input me-0`}
                  />
                  <label
                    className={`${
                      !allSelected ? tableStyles.checklistCheckbox : tableStyles.unSelected
                    } form-check-label`}
                    htmlFor={`${material.name}-${material.id}`}
                  ></label>
                </div>
              </th>
              <th className={`${styles.tasksTableHeader} text-center`}>#</th>
              <th className={styles.tasksTableHeader}>Task</th>
              <th className={styles.tasksTableHeader}>Code</th>
            </tr>
          </thead>
          <tbody>
            {!!bidItems.length &&
              bidItems.map((item, index) => (
                <BidItem
                  allSelected={allSelected}
                  toggleSelectedBidItem={toggleSelectedBidItem}
                  selectedTaskBidItems={selectedTaskBidItems}
                  key={item.id}
                  index={index + 1}
                  bidItem={bidItems.find((bidItem) => bidItem.id === item.id)}
                />
              ))}
          </tbody>
        </Table>
      </div>
      <div className="pt-2">
        <Pagination
          items={bidItems}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          total={totalResults}
        />
      </div>
    </>
  );
};

export default TasksTable;
