import React, { useEffect, useRef, useState } from "react";
import { MoreHorizontal, Edit3, XCircle, PlusCircle } from "react-feather";
import styles from "../../../styles/SingleAttachments.module.scss";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import companyStyles from "../styles/CompanyContact.module.scss";
import contacts from "../../../api/contacts";
import { useApi, useAuth } from "component-library";

const CompanyContactNav = ({
  setCompany,
  contact,
  setEditingContact,
  setArchivingContact,
  setArchiveContact,
  archived = false,
}) => {
  const { userCan } = useAuth();
  const { request, data } = useApi(contacts.restoreContact);
  const [menuActive, setMenuActive] = useState(false);

  const node = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    if (!!data && !!data.success) {
      setCompany(data.contact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClick = (e) => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }
    setMenuActive(false);
  };

  return (
    <>
      <nav className={styles["attachment-navbar"]} ref={node}>
        <div className={`${styles["attachment-toggle"]}`}>
          <Button
            size="sm"
            variant="link"
            onClick={() => {
              setMenuActive(!menuActive);
            }}
            style={{ zIndex: "11" }}
            className={`px-2 pt-2 me-1 ${companyStyles.menuButton}`}
            aria-label="additional options"
          >
            <MoreHorizontal size="20" color="#7b7b7b" />
          </Button>
        </div>
        <div className={`${styles["submenu-wrapper"]} ${!!menuActive ? "submenu-active" : ""}`}>
          <ul className={`p-2 mt-3 list-inline text-start ${styles["submenu"]}`}>
            {userCan("contact-edit") && (
              <>
                {!!archived ? (
                  <li>
                    <Button
                      size="sm"
                      variant="link"
                      className="p-0 mb-2"
                      onClick={() => {
                        request(contact.contact.id);
                      }}
                    >
                      <PlusCircle size="12" className="attachment-icon attachment-download mx-1" />
                      <span className="text-gray">Restore Contact</span>
                    </Button>
                  </li>
                ) : (
                  <>
                    <li>
                      <Button
                        size="sm"
                        variant="link"
                        className="p-0 mb-2"
                        onClick={() => {
                          setEditingContact();
                        }}
                      >
                        <Edit3 size="12" className="attachment-icon attachment-download mx-1" />
                        <span className="text-gray">Edit Contact</span>
                      </Button>
                    </li>
                    <li>
                      <Button
                        size="sm"
                        variant="link"
                        className="p-0 mb-2"
                        onClick={() => {
                          setArchivingContact(true);
                          setArchiveContact();
                        }}
                      >
                        <XCircle size="12" className="attachment-icon attachment-download mx-1" />
                        <span className="text-gray">Archive Contact</span>
                      </Button>
                    </li>
                  </>
                )}
              </>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};

CompanyContactNav.propTypes = {
  setEditContact: PropTypes.func,
};

export default CompanyContactNav;
