import React, { useState } from "react";
import styles from "./styles.module.scss";
import AbsentModal from "./AbsentModal";

const NoteCount = ({ data }) => {
  const [openNote, setOpenNote] = useState();

  const handleCellClicked = () => {
    setOpenNote(data.absent_notes);
  };

  return (
    <>
      {data?.absent_notes ? (
        <div className={styles.linkLight} onClick={() => handleCellClicked()}>
          1
        </div>
      ) : (
        <div>-</div>
      )}
      {!!openNote && <AbsentModal openNote={openNote} setOpenNote={setOpenNote} />}
    </>
  );
};

export default NoteCount;
