import { useState, useEffect } from "react";
import useSiteManagment from "../../../../hooks/useSiteManagment";

const usePermitListing = (id) => {
  const { permits, locatesLoading, locatesError, initialLoading } = useSiteManagment(id);
  const [allPermits, setAllPermits] = useState([]);
  const [filteredPermits, setFilteredPermits] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedPermits, setPaginatedPermits] = useState([]);
  const [expirationEndDate, setExpirationEndDate] = useState(null);
  const [expirationStartDate, setExpirationStartDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const itemsPerPage = 10;

  useEffect(() => {
    if (!!permits) {
      setAllPermits(permits);
    }
  }, [permits]);

  useEffect(() => {
    if (!!allPermits) {
      setFilteredPermits(allPermits);
    }
  }, [allPermits]);

  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentPermits = filteredPermits.slice(indexOfFirstItem, indexOfLastItem);
    setPaginatedPermits(currentPermits);
  }, [currentPage, filteredPermits]);

  useEffect(() => {
    let newFilteredPermits = allPermits;
    newFilteredPermits = allPermits.filter((permit) => {
      return (
        (!!permit.number && permit.number.includes(searchTerm)) ||
        permit.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        permit.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        permit.cost.includes(searchTerm)
      );
    });

    if (startDate && endDate) {
      newFilteredPermits = newFilteredPermits.filter((permit) => {
        return new Date(permit.issue_date) >= new Date(startDate) && new Date(permit.issue_date) <= new Date(endDate);
      });
    }

    if (expirationStartDate && expirationEndDate) {
      newFilteredPermits = newFilteredPermits.filter((permit) => {
        return (
          new Date(permit.expiration) >= new Date(expirationStartDate) &&
          new Date(permit.expiration) <= new Date(expirationEndDate)
        );
      });
    }
    setFilteredPermits(newFilteredPermits);

    setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, startDate, endDate, expirationStartDate, expirationEndDate]);

  return {
    locatesLoading,
    locatesError,
    initialLoading,
    setExpirationEndDate,
    setExpirationStartDate,
    setStartDate,
    setEndDate,
    setSearchTerm,
    setCurrentPage,
    currentPage,
    itemsPerPage,
    allPermits,
    filteredPermits,
    paginatedPermits,
    setPaginatedPermits,
    startDate,
    endDate,
    expirationStartDate,
    expirationEndDate,
  };
};

export default usePermitListing;
