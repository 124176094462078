/**
 * Gets the maximum date for use in the datepicker
 * @param  {Array} dates an array with two js dates from a date range
 * @param  {Number} days maximum amount of days for the range. default is two weeks.
 * @return {String} The new maximum date for the date range
 */

export const getMaxDate = (dates, days = 14) => {
  let maxDate = Date.now();
  if (!!dates?.length) {
    const startDate = new Date(dates[0]);
    maxDate = startDate.setDate(startDate.getDate() + days);
    if (new Date(maxDate) > Date.now()) {
      maxDate = Date.now();
    }
  }

  return maxDate;
};
