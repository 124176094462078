import React from "react";
import ModalWrapper from "../ModalWrapper";
import useDeleteAlbum from "./useDeleteAlbum";
import PropTypes from "prop-types";

const DeleteAlbumModal = ({ showModal, setShowModal, album }) => {
  const { headerContent, bodyContent, footerContent, onHide } = useDeleteAlbum({ album, setShowModal });

  return (
    <ModalWrapper
      showModal={showModal}
      setShowModal={setShowModal}
      headerContent={headerContent}
      bodyContent={bodyContent}
      footerContent={footerContent}
      onHide={() => onHide()}
    />
  );
};

DeleteAlbumModal.propTypes = {
  album: PropTypes.object,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default DeleteAlbumModal;
