import React from "react";
import { XCircle } from "react-feather";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import { ReactComponent as CalendarX } from "../../assets/CalendarX.svg";

const Absent = ({ timesheet, setTimesheet, editing }) => {
  const clearAbsent = () => {
    setTimesheet({
      ...timesheet,
      absent: 0,
    });
  };
  return (
    <>
      {timesheet?.absent === 1 && (
        <div className="row my-3">
          <div className={`${styles.absent}`}>
            <div className={styles.perDiemLabel}>
              <CalendarX color="#E70000" className={styles.icon} />
              {`Absent`}
            </div>
            {!!editing && (
              <Button variant="transparent" size="sm" onClick={clearAbsent} className="p-0">
                <XCircle color="#E70000" size={20} />
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Absent;
