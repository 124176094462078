import React from "react";
import styles from "./styles.module.scss";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import Tabs from "../Tabs";
import StatPieChart from "./StatPieChart/StatPieChart";
import RecentTasks from "./RecentTasks";
import ProjectTasks from "./ProjectTasks/ProjectTasks";
import { Briefcase } from "react-feather";
import { Alert } from "react-bootstrap";
import useSummary from "./useSummary";
import useProject from "../../../hooks/useProject";
import HoursBarChart from "./HoursBarChart/HoursBarChart";
import PacingChart from "./PacingChart/PacingChart";
import { DateRangeSelector, Loader } from "component-library";

const Summary = () => {
  const { stats, statsLoading, error, dateRange, setDateRange } = useSummary();
  const { loading, pageTitle, PageTag, error: projectError } = useProject();

  return (
    <InteriorPage
      loading={loading}
      error={projectError}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle="Project Reporting"
      NavContent={PageTag}
      pageSubtitlePrimaryContent={() => <Tabs />}
    >
      {!!error && <Alert variant="danger">{error}</Alert>}
      <div className="contentContainer mt-5">
        <div className="mainContent">
          <div className={styles.datePickerWrapper}>
            <DateRangeSelector
              className={styles.datePickerInner}
              dateRange={dateRange}
              setDateRange={setDateRange}
              maxDate={Date.now()}
              noDateText="To Date"
              dropdown={true}
              split="-"
            />
          </div>
          <div className={`${styles.content} mb-4 position-relative`}>
            <Loader loading={!!statsLoading} size={16} />
            <div className={styles.sectionWrapper}>
              <PacingChart stats={stats} />
              <HoursBarChart stats={stats} />
              <section className={`${styles.section} ${styles.hasBg}`}>
                <h2 className="mb-4">Working Days</h2>
                <StatPieChart
                  usedLabel="used"
                  estimateLabel="total working days"
                  used={stats?.worked_days || 0}
                  budgeted={stats?.working_days || 1}
                  dispBudgeted={stats?.working_days}
                />
                <div className={`d-flex align-items-center mt-4 ${styles.remainingDaysWrapper}`}>
                  <div className={`${styles.remainingDays}`}>{Math.round(parseInt(stats?.remaining_days || 0))}</div>
                  <div className={styles.subtext}>
                    <span>Working Days</span>
                    <span>Remaining</span>
                  </div>
                </div>
              </section>
              <section className={styles.section}>
                <RecentTasks tasks={stats?.recent_tasks} />
              </section>
            </div>
          </div>
          <div className={styles.tasksContent}>
            <section className="project-summary-tasks">
              <ProjectTasks dateRange={dateRange} setDateRange={setDateRange} />
            </section>
          </div>
        </div>
      </div>
    </InteriorPage>
  );
};

export default Summary;
