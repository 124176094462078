import React from "react";
import { Alert } from "react-bootstrap";
import useAddProjectNote from "../../AddProjectNoteModal/useAddProjectNote";

const AddNote = ({ notes, setNotes, onHide, date, photo, photoDetailsError }) => {
  const { photoFields, handleSubmit, addCommentError } = useAddProjectNote({
    notes,
    setNotes,
    date,
    onHide,
    photo,
  });

  return (
    <div className="mt-3">
      {!!addCommentError && <Alert variant="danger">{addCommentError}</Alert>}
      <form onSubmit={handleSubmit}>{!photoDetailsError && photoFields}</form>
    </div>
  );
};

export default AddNote;
