import React from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import styles from "../../../../styles/Login.module.scss";
import useEditPassword from "../../../hooks/useEditPassword";
import useUserAdmin from "../hooks/useUserAdmin";

const ModalContent = () => {
  const { editUser, setChangePassword, setChangedPasswordMessage } = useUserAdmin();
  const { fields, handleSubmit, renderSubmitButton, passwordUpdateError } = useEditPassword({
    editUser,
    setChangePassword,
    setChangedPasswordMessage,
  });
  return (
    <Modal centered animation={false} show={true} onHide={() => setChangePassword(false)}>
      <Modal.Header closeButton>
        <h5 className="mb-0 med">Set User Password for {`${editUser?.first_name} ${editUser?.last_name}`}</h5>
      </Modal.Header>
      <Modal.Body>
        {!!passwordUpdateError && <Alert variant="danger">{passwordUpdateError}</Alert>}
        <form onSubmit={handleSubmit}>
          <Modal.Body>{fields}</Modal.Body>
          <Modal.Footer>
            <Button variant="light-gray" onClick={() => setChangePassword(false)} className={styles.submit}>
              Cancel
            </Button>
            {renderSubmitButton("Update Password")}
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalContent;
