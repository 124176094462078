import React from "react";
import ProvideUserAdmin from "./hooks/ProvideUserAdmin"
import UserAdminContent from "./UserAdminContent";

const UserAdminPage = () => {
  return (
    <ProvideUserAdmin>
      <UserAdminContent />
    </ProvideUserAdmin>
  );
};

export default UserAdminPage;
