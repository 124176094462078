import Status from "../columnRenders/Status";

const status = {
  headerName: "Status",
  field: "work_day_status_name",
  resizable: true,
  cellRenderer: Status,
};

export default status;
