import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import TakeoffItem from "./TakeoffItem";

const Takeoffs = ({ takeoffs, updateNewLineItems }) => {
  return (
    <>
      <div className="xl">Takeoffs</div>
      {!!takeoffs && !!takeoffs.length ? (
        <Table responsive striped className="sortable-table">
          <thead>
            <tr>
              <th className="checkbox-column"></th>
              <th
                style={{
                  width: "45%",
                }}
              >
                <span>Item</span>
              </th>
              <th className="text-start">
                <span>Estimate</span>
              </th>
              <th className="text-end">
                <span>PM Takeoff</span>
              </th>
              <th className="text-end">
                <span>+/-</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {takeoffs.map((item, index) => (
              <tr key={index}>
                <TakeoffItem item={item} updateNewLineItems={updateNewLineItems} />
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div>There are no takeoffs associated with this project</div>
      )}
    </>
  );
}


Takeoffs.propTypes = {
  takeoffs: PropTypes.array,
  updateNewLineItems: PropTypes.func,
};

export default Takeoffs;
