import React from "react";
import { NavLink } from "react-router-dom";

const Tabs = () => {
  return (
    <nav className="headerTabs">
      <NavLink className="headerTab" to={`/super-admin/modules`}>
        Modules
      </NavLink>
      <NavLink className="headerTab" to={`/super-admin/app-settings`}>
        App Settings
      </NavLink>
      {/* <NavLink className="headerTab" to={`/super-admin/company-details`}>
        Company Details
      </NavLink>
      <NavLink className="headerTab" to={`/super-admin/project-settings`}>
        Project Settings
      </NavLink> */}
    </nav>
  );
};

export default Tabs;
