import { rfqsApi, useApi } from "component-library";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useRFQs from "../useRFQs";

const useCreateRFQ = (selectedVendorIds) => {
  const { id } = useParams();
  const { setRfQs } = useRFQs();
  const [rfqsAdded, setRFQsAdded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const {
    loading: sendLoading,
    error: sendError,
    request: sendRequest,
    data: sendData,
  } = useApi(rfqsApi.createRFQ);

  const sendRFQ = () => {
    setShowConfirmation(true);
  };

  const confirmSendRFQ = () => {
    sendRequest(id, selectedVendorIds);
  };

  useEffect(() => {
    if (!!sendData && !!sendData.rfq) {
      setRfQs(sendData.rfq);
      setRFQsAdded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendData]);

  return {
    rfqsAdded,
    sendLoading,
    sendError,
    sendRFQ,
    confirmSendRFQ,
    showConfirmation,
    setShowConfirmation
  };
};

export default useCreateRFQ;
