import React from "react";
import { Table } from "react-bootstrap";
import ActivePermitsItem from "./ActivePermitsItem";
import styles from "../styles.module.scss";

const ActivePermitsList = ({ permits, setEditPermit }) => {
  return (
    <div className="text-start">
      <div className="lg font-weight-bold">Active Permits</div>
      <Table className={styles.table}>
        <thead className={styles.tableHead}>
          <tr>
            <th style={{ minWidth: "8.5rem" }}>Issue Date</th>
            <th>Permit #</th>
            <th style={{ minWidth: "17.5rem" }}>Description</th>
            <th>Cost</th>
            <th style={{ minWidth: "10rem" }}>Expiration</th>
            <th>Status</th>
            <th>File</th>
          </tr>
        </thead>
        <tbody>
          {permits.map((permit) => (
            <ActivePermitsItem key={permit.id} permit={permit} setEditPermit={setEditPermit} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ActivePermitsList;
