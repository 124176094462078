import React, { useState, useEffect } from "react";
import foremanSummaryReportConfig from "./foremanSummaryReportConfig";
import { AgGridTable, useAuth } from "component-library";
import renderDateColumnDefs from "./columnDefs/renderDateColumnDefs";

const ForemanSummaryGridReport = ({ gridData, formData }) => {
  const { user } = useAuth();
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    if (!!formData && !!gridData) {
      setColumnDefs(renderDateColumnDefs(foremanSummaryReportConfig, formData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  return (
    <AgGridTable
      gridData={gridData}
      gridConfig={columnDefs}
      id={`${user.id}_foreman_summary_report`}
      fieldsToSearch={["foreman", "name", "employee_id"]}
      suppressSizeToFit
      updateColumnDefs
    />
  );
};

export default ForemanSummaryGridReport;
