import React from "react";
import styles from "./styles.module.scss";

const Status = ({ data }) => {
  return (
    <div className="d-flex justify-content-center w-100">
      <div className={styles.circle} style={{ backgroundColor: data?.active ? "#34d27c" : "#7b7b7b" }}></div>
    </div>
  );
};

export default Status;
