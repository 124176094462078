import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import useProject from '../../hooks/useProject';
import styles from './PreconChecklist.module.scss';
import { useApi, projectApi, ConfirmModal } from "component-library";
import { ArrowRightCircle } from 'react-feather';
import PropTypes from "prop-types";

const ProjectLauncher = ({skip}) => {
  const { project, setProject } = useProject();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const { data, request, loading, error } = useApi(
    projectApi.projectLaunch
  );

  const handleLaunch = async (projectId) => {
    await request(projectId);
  };

  useEffect(() => {
    if (data && data.project) {
      setProject({
        ...project,
        status: data.project.status
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  
  return (
    <div className={styles.launcherWrapper}>
      <div>
        <h3 className={`${styles.preconTitle} xl text-gray2 mb-0`}>PreCon Checklist</h3>
        <div className={` d-flex align-items-center text-center mb-3 ${!skip ? "justify-content-between" : undefined}`}>
        <div className="d-flex align-items-center">
          {!!skip ? (
            <p className="text-gray2 mb-0">Complete this checklist to finish converting your bid.</p>
          ) : (
            <p className="text-gray2 mb-0">&#9989; Checklist Complete!</p>
          )}
        </div>
        
        {!!skip && (
          <Button
            variant="link"
            className="sm-med font-weight-bold"
            onClick={() => {
              setConfirmModalOpen(true);
            }}
            disabled={!!loading && true}
          >
            Skip this step <ArrowRightCircle size={16} />
          </Button>
        )}
        </div>
      </div>
      <ConfirmModal
        showConfirmation={confirmModalOpen}
        setShowConfirmation={setConfirmModalOpen}
        confirmFunction={() => {
          handleLaunch(project.id);
        }}
        loading={loading}
        error={error}
        name={"Skipping Setup"}
        message={"Are you sure you want to skip setup?"}
      />
      {!skip && (
        <Button
          variant="tertiary"
          className="med font-weight-bold py-3"
          onClick={() => {
            handleLaunch(project.id);
          }}
          disabled={!!loading && true}
        >
          Close Checklist
        </Button>
      )}
    </div>
  );
}

ProjectLauncher.propTypes = {
  skip: PropTypes.bool,
};

export default ProjectLauncher;