import React from "react";
import styles from "./styles.module.scss";
import Initials from "../../../../../../_fleetManagement/Initials";
import { Link } from "react-router-dom";
import { ArrowRightCircle, PauseCircle } from "react-feather";
import convertMinutesToTime from "../../../../../../utils/convertMinutesToTime";
import { BeatLoader } from "react-spinners";
import { Alert } from "react-bootstrap";

const Equipment = ({ equipmentLoading, equipmentError, equipment }) => {
  return (
    <div className="position-relative">
      {!!equipmentLoading && (
        <div className="loaderWrapper">
          <BeatLoader color="#0071ce" size={16} />
        </div>
      )}
      {!!equipmentError && <Alert variant="danger">{equipmentError}</Alert>}
      {!!equipment?.length ? (
        equipment.map((item) => (
          <>
            <div key={item.id} className={styles.singleEquipment}>
              <div>
                <Initials name={item?.category} size="large" />
              </div>
              <div>
                <div className={styles.equipmentName}>
                  <Link className={styles.equipID} to={`/fleet-management/equipment/details/${item?.equip_no}`}>
                    <span>{item?.equip_no}</span>
                  </Link>
                  <span className="text-limiter">{item?.display_description || item?.name}</span>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-4 text-gray2">
                    <ArrowRightCircle color="#7DDB2D" size={16} /> {convertMinutesToTime(parseInt(item.run_minutes))}hrs
                  </div>
                  <div className="text-gray2">
                    <PauseCircle color="#DCB600" size={16} /> {convertMinutesToTime(parseInt(item.idle_minutes))}
                    hrs
                  </div>
                </div>
              </div>
            </div>
          </>
        ))
      ) : (
        <div className={styles.noResults}>{!equipmentLoading && "No on site equipment to show"}</div>
      )}
    </div>
  );
};

export default Equipment;
