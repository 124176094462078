import React from "react";
import { Table } from "react-bootstrap";
import styles from "./styles.module.scss";
import Entry from "./Entry";
import DriveTime from "../../../../../_resourceManagement/TimeEntryOptions/DriveTime";
import Lunch from "../../../../../_resourceManagement/TimeEntryOptions/Lunch";
import PTO from "../../../../../_resourceManagement/TimeEntryOptions/PTO";
import PerDiem from "../../../../../_resourceManagement/TimeEntryOptions/PerDiem";
import TimeEntryEditActions from "../../../../../_resourceManagement/TimeEntryOptions/TimeEntryEditActions";
import PropTypes from "prop-types";
import Late from "../../../../../_resourceManagement/TimeEntryOptions/Late";
import Absent from "../../../../../_resourceManagement/TimeEntryOptions/Absent";

const TimeEntries = ({
  entries,
  workDay,
  timesheet,
  setTimesheet,
  project,
  overAllocatedTimeSheets,
  setOverAllocatedTimeSheets,
  updateTimesheet,
  projectsList,
  timesheetLocked,
  config,
}) => {
  return (
    <>
      <Table className={styles.table}>
        <thead className={styles.tableHead}>
          <tr>
            <th>Task</th>
            <th>Equipment</th>
            <th>Secondary</th>
            <th>Role</th>
            <th>Hours</th>
          </tr>
        </thead>
        <tbody>
          {!!entries?.length &&
            entries.map((entry) => (
              <tr key={entry.id}>
                <Entry
                  entry={entry}
                  workDay={workDay}
                  timesheet={timesheet}
                  setTimesheet={setTimesheet}
                  project={project}
                  overAllocatedTimeSheets={overAllocatedTimeSheets}
                  setOverAllocatedTimeSheets={setOverAllocatedTimeSheets}
                  config={config}
                />
              </tr>
            ))}
        </tbody>
      </Table>
      {!!timesheet?.travel_times?.length &&
        timesheet.travel_times.map((entry) => (
          <DriveTime
            key={entry.id}
            entry={entry}
            updateTimesheet={updateTimesheet}
            setTimesheet={setTimesheet}
            timesheet={timesheet}
            editing={!timesheetLocked}
            projectsList={projectsList}
          />
        ))}
      <Lunch setTimesheet={updateTimesheet} timesheet={timesheet} editing={!timesheetLocked} />
      <PTO setTimesheet={updateTimesheet} timesheet={timesheet} editing={!timesheetLocked} />
      <PerDiem setTimesheet={updateTimesheet} timesheet={timesheet} editing={!timesheetLocked} />
      <Late setTimesheet={updateTimesheet} timesheet={timesheet} editing={!timesheetLocked} />
      <Absent setTimesheet={updateTimesheet} timesheet={timesheet} editing={!timesheetLocked} />
      <div className="mt-3">
        <TimeEntryEditActions
          setTimesheet={setTimesheet}
          timesheet={timesheet}
          projectsList={projectsList}
          editing={!timesheetLocked}
          updateTimesheet={updateTimesheet}
        />
      </div>
    </>
  );
};

Entry.propTypes = {
  entries: PropTypes.array,
  workDay: PropTypes.object,
  timesheet: PropTypes.object,
  setTimesheet: PropTypes.func,
  project: PropTypes.object,
  overAllocatedTimeSheets: PropTypes.array,
  setOverAllocatedTimeSheets: PropTypes.func,
  updateTimesheet: PropTypes.func,
  projectsList: PropTypes.array,
  timesheetLocked: PropTypes.bool,
};

export default TimeEntries;
