import React from "react";
import styles from "./styles.module.scss";
import checkReportFS from "../../../../utils/checkReportFS";
import formatGridValue from "../../../../utils/formatGridValue";

const Pacing = ({ data }) => {
  const overUnder = formatGridValue(data?.over_under);
  const overUnderPercentage =
    isNaN(data?.over_under_percentage) || parseFloat(data?.over_under_percentage) === 0
      ? 0
      : formatGridValue(data?.over_under_percentage);

  return (
    <div
      className={`${styles.overUnder} ${data?.over_under > 0 && styles.overage} ${overUnder === 0 && styles.zero} ${
        checkReportFS([parseFloat(data?.over_under) === 0 ? 0 : parseFloat(data?.over_under_percentage).toFixed(2)], 14)
          ? "med"
          : "xl"
      }`}
    >
      <span className={styles.percentage}>{overUnder}</span> ({overUnderPercentage}%)
    </div>
  );
};

export default Pacing;
