import React from "react";

const AccentColor = ({ renderTextField }) => {
  return (
    <div className="row">
      <div className="col-6">
        {renderTextField({
          name: "accent_color",
          label: "Site Accent Color",
          placeholder: "Add an accent color here",
        })}
      </div>
    </div>
  );
};

export default AccentColor;
