import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PhaseBadge from "./PhaseBadge";
import { Modal, Button, Alert } from "react-bootstrap";
import { DateTime } from "luxon";
import { X } from "react-feather";
import { getPhaseColor } from "../../../../../config/phaseColors";
import { useApi, bidApi } from "component-library";

const AssignPhase = ({ bidItem, updateItem, setAssignPhase, projectPhases, assignPhase }) => {
  const bidPhases = bidItem.phases.filter((phase) => !!phase.phase && !!phase.phase.id);
  const [selectedPhases, setSelectedPhases] = useState(bidPhases.map((phase) => phase.phase_id));
  const { data, loading, error, request } = useApi(bidApi.addPhasesToUPB);

  const onSubmit = () => {
    request({
      upb_id: bidItem.id,
      phases: selectedPhases.map((phase) => {
        const bidPhase = bidItem.phases.find((p) => p.phase_id === phase);
        return {
          id: phase,
          quantity: !!bidPhase ? bidPhase.quantity : 0,
        };
      }),
    });
  };

  useEffect(() => {
    if (!!data && !!data.upb_item) {
      updateItem("phases", data.upb_item.phases);
    }
    setAssignPhase(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    let selected;
    if (bidItem?.phases?.length) {
      selected = bidPhases.map((phase) => phase.phase_id);
    } else {
      selected = [];
    }
    setSelectedPhases(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidItem?.phases]);

  return (
    <Modal show={assignPhase} onHide={() => setAssignPhase(false)} size="lg" centered>
      <Modal.Header>
        <div className="d-flex justify-content-between w-100 align-items-center">
          <div className="med">Assign Phase</div>
          <Button onClick={() => setAssignPhase(false)} variant="link">
            <X color="#E9E9E9" />
          </Button>
        </div>
      </Modal.Header>

      <Modal.Body className="container">
        {!!error && <Alert variant="danger">{error}</Alert>}
        {!!projectPhases.length &&
          projectPhases.map((phase, index) => (
            <div className="ps-4 row py-2 d-flex align-items-center" key={phase.id}>
              <div className="me-2 d-flex align-items-center">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={selectedPhases.includes(phase.id)}
                  onChange={() => {
                    if (selectedPhases.includes(phase.id)) {
                      setSelectedPhases(selectedPhases.filter((id) => id !== phase.id));
                    } else {
                      setSelectedPhases([...selectedPhases, phase.id]);
                    }
                  }}
                />
              </div>
              <div style={{ minWidth: "8rem" }} className="d-flex">
                <PhaseBadge phaseName={`phase ${index + 1}`} phaseColor={getPhaseColor(phase, projectPhases)} />
              </div>
              <div className="col-11 col-lg-4 px-0">{phase.name}</div>
              <div className="col-sm-3 d-flex col-lg-3 px-0 justify-space-between align-items-center">
                <i className="sm me-2">Start Date: </i>
                {!!phase.start_date && DateTime.fromISO(phase.start_date).toFormat("LL/dd/yy")}
              </div>
              <div className="col-sm-3 d-flex col-lg-3 px-0 justify-space-between align-items-center">
                <i className="sm me-2">End Date: </i>
                {!!phase.end_date && DateTime.fromISO(phase.end_date).toFormat("LL/dd/yy")}
              </div>
            </div>
          ))}

        <div className="row d-flex justify-content-end pt-2">
          <Button onClick={onSubmit} variant="tertiary" disabled={loading}>
            {loading ? "Loading..." : "Confirm"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

AssignPhase.propTypes = {
  bidItem: PropTypes.object.isRequired,
  updateItem: PropTypes.func.isRequired,
  setAssignPhase: PropTypes.func.isRequired,
  projectPhases: PropTypes.array,
};

export default AssignPhase;
