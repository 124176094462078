import { DateTime } from "luxon";

/**
 * gets note dates based off either the "note_date" or the "created at" data and formats it
 * @param  {String} note note object returned from the api
 * @return {String} note date string containing one or more note dates
 */

const getProjectNoteDate = (note) => {
  if (!note?.note_date && !!note?.created_at) {
    return note.created_at;
  } else if (!!note?.note_date) {
    return `${note.note_date} ${DateTime.fromSQL(note?.created_at).toFormat("HH:mm:ss")}`;
  }  else {
    return null
  }
};

export default getProjectNoteDate;
