const contract_owner = {
  headerName: "Location",
  field: "contract_owner",
  width: 172,
  valueGetter: ({data}) => {
    return data.contract_owner?.address?.city ?  data.contract_owner.address.city : null
  },
};

export default contract_owner;
