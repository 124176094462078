import Deactivate from "../columnRenders/Deactivate";
import DeactivateHeader from "../columnRenders/DeactivateHeader";

const deactivate = {
  headerName: "Deactivate",
  headerComponent: DeactivateHeader,
  field: "",
  cellRenderer: Deactivate,
  autoHeight: true,
  width: 80,
};

export default deactivate;
