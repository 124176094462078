import React, {useState} from "react";
import { Toggle } from "component-library";
import ConfirmModal from "./ConfirmModal";

const Controls = ({quote, used, handleDeleteQuote, deleteQuoteLoading, deleteQuoteError, updateQuote}) => {
  const [modalOpen, setModalOpen] = useState();

  return (
    <>
      <div className="d-flex align-items-center">
        <Toggle
          name="used"
          label="Selected Quote"
          checked={!!used ? true : false}
          handleChange={() => {
            updateQuote({
              id: quote.id,
              used: !!used ? 0 : 1,
            });
          }}
        />
        <button className="btn btn-danger font-weight-bold ms-3" onClick={() => setModalOpen(true)}>
          Delete Quote
        </button>
      </div>
      {!!modalOpen && (
        <ConfirmModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          handleDeleteQuote={handleDeleteQuote}
          deleteQuoteLoading={deleteQuoteLoading}
          deleteQuoteError={deleteQuoteError}
        />
      )}
    </>
  );
};

export default Controls;
