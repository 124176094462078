const rfqsListPrint = [
  {
    name: "sent",
    label: "Sent",
    sort: "date",
    width: "15%",
  },
  {
    name: "supplier_name",
    label: "Supplier",
    sort: "alpha",
    isTitle: true,
  },
  {
    name: "email",
    label: "Email",
    sort: "alpha",
  },
  {
    name: "certifications",
    label: "Certifications",
    render: ({ item }) => (
      <>
        {item.company.certifications.map((certification) => (
          <span className="me-3 sm">{certification.name}</span>
        ))}
      </>
    ),
  },
  {
    name: "status_sort",
    label: "Status",
    render: ({ item }) => <span className="med">{item.status.name}</span>,
    sort: "numeric",
  },
];

export default rfqsListPrint;
