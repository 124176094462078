import React from 'react';
import Controls from './Controls';
import LineItems from "../LineItems";
import BackToQuotesList from "../BackToQuotesList";
import PropTypes from "prop-types";

const Requote = ({quote, cancelRequote, sendRequote, setQuote}) => {
  return (
    <div className="requote">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <BackToQuotesList />
        <Controls cancelRequote={cancelRequote} sendRequote={sendRequote} />
      </div>
      <LineItems setQuote={setQuote} quote={quote}/>
    </div>
  );
}


Requote.propTypes = {
  quote: PropTypes.object,
  cancelRequote: PropTypes.func,
  sendRequote: PropTypes.func,
  setQuote: PropTypes.func,
};


export default Requote;