import { useEffect } from "react";
import { DateTime } from "luxon";

const useStoredDateRanges = (dateRange, startDateVariable, endDateVariable) => {
  useEffect(() => {
    if (!!dateRange) {
      const [startDate, endDate] = dateRange;

      if (startDate && endDate) {
        const storedStartDate = localStorage.getItem(startDateVariable);
        const storedEndDate = localStorage.getItem(endDateVariable);

        const matchingStartDate =
          DateTime.fromJSDate(new Date(storedStartDate)).toFormat("yyyy-MM-dd") ===
          DateTime.fromJSDate(new Date(startDate)).toFormat("yyyy-MM-dd");

        const matchingEndDate =
          DateTime.fromJSDate(new Date(storedEndDate)).toFormat("yyyy-MM-dd") ===
          DateTime.fromJSDate(new Date(endDate)).toFormat("yyyy-MM-dd");

        if (!matchingEndDate || !matchingStartDate) {
          localStorage.setItem(startDateVariable, startDate);
          localStorage.setItem(endDateVariable, endDate);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);
};

export default useStoredDateRanges;
