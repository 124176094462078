import React from "react";
import styles from "../../styles.module.scss";
import ColumnHeader from "../../ColumnHeader";
import crewLogWidths from "../../gridWidths/crewLogWidths";

const CrewLogHeaders = ({ columns }) => {
  return (
    <div className={styles.headerWrapper}>
      {columns.map((column) => (
        <ColumnHeader text={column.headerName} width={crewLogWidths[column.field] || "7rem"} />
      ))}
    </div>
  );
};

export default CrewLogHeaders;
