import TimesheetLink from "../columnRenders/TimesheetLink";
import { ExternalLink } from "react-feather";

const HeaderComponent = () => {
  return <ExternalLink size="16" color={"#A7A7A7"} />;
};

const timesheet_link = {
  field: "timesheet_link",
  maxWidth: 50,
  minWidth: 50,
  cellClass: "grid-cell-centered",
  cellRenderer: TimesheetLink,
  headerComponent: HeaderComponent,
};

export default timesheet_link;
