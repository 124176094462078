import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi, projectApi } from "component-library";
import { Badge } from "react-bootstrap";
import useReporting from "./useReporting";
import formatProjectTitle from "../../utils/formatProjectTitle";
import useStoredDateRanges from "../../_resourceManagement/context/useStoredDateRanges";

const useProvideProject = () => {
  const [project, setProject] = useState();
  const [config, setConfig] = useState();
  const [dailyFieldLogLoaded, setDailyFieldLogLoaded] = useState(false);
  const [fieldMgmtDateRange, setFieldMgmtDateRange] = useState([]);
  const [weeklyReportDateRange, setWeeklyReportDateRange] = useState([]);
  const [reportingExpanded, setReportingExpanded] = useState(false);

  const { id } = useParams();
  const { request, data, error, loading } = useApi(projectApi.getProjectDashboard);

  const { request: configRequest, data: configData } = useApi(projectApi.getProjectConfig);

  const getStoredDateRange = () => {
    const storedStartDate = localStorage.getItem(`${id}_field_mgmt_start_date`);
    const storedEndDate = localStorage.getItem(`${id}_field_mgmt_end_date`);
    if (!!storedStartDate && !!storedEndDate) {
      return [storedStartDate, storedEndDate];
    } else {
      return [];
    }
  };

  const handleOnDateBlur = () => {
    if (!!fieldMgmtDateRange[0] && !fieldMgmtDateRange[1]) {
      setFieldMgmtDateRange([fieldMgmtDateRange[0], fieldMgmtDateRange[0]]);
    }
  };

  useStoredDateRanges(fieldMgmtDateRange, `${id}_field_mgmt_start_date`, `${id}_field_mgmt_end_date`);

  useEffect(() => {
    request(id);
    configRequest(id);
    if (!!dailyFieldLogLoaded) {
      setDailyFieldLogLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!!data?.project) {
      setProject(data.project);
    }
  }, [data]);

  useEffect(() => {
    if (!!configData?.config) {
      setConfig(configData.config);
    }
  }, [configData]);

  const refreshProject = async () => {
    await request(id);
  };

  const pageTitle = formatProjectTitle(project, loading, error);

  const pageSubtitle = project ? (!!project?.job_number ? `#${project.job_number}` : "") : "";

  const PageTag = () => (
    <>
      {!!project?.certified_payroll && (
        <Badge pill className="ms-2 pill" bg="redOrange">
          pw
        </Badge>
      )}
    </>
  );

  const { reporting, reportingLoading, reportingError } = useReporting(project);

  return {
    project,
    pageTitle,
    loading,
    error,
    setProject,
    pageSubtitle,
    refreshProject,
    config,
    PageTag,
    reporting,
    reportingLoading,
    reportingError,
    reportingExpanded,
    setReportingExpanded,
    fieldMgmtDateRange,
    setFieldMgmtDateRange,
    getStoredDateRange,
    handleOnDateBlur,
    dailyFieldLogLoaded,
    setDailyFieldLogLoaded,
    weeklyReportDateRange,
    setWeeklyReportDateRange,
  };
};

export default useProvideProject;
