import React, {useState} from "react";
import { Quote as Q } from "component-library";
import styles from "./styles.module.scss";
import { Button } from 'react-bootstrap';
import FormAddContact from "../../../../../components/Contacts/FormAddContact";

const QuoteSupplier = ({quote, updateQuote}) => {
  const [modalOpen, setModalOpen] = useState();

  return (
    <>
      <div className={styles.supplierWrapper}>
        <div className="d-flex align-items-center">
          <h2 className="font-weight-bold med m-0 p-0">Supplier</h2>
          {!!quote.supplier_id && (
            <Button
              onClick={() => {
                setModalOpen(true);
              }}
              className="sm-med"
              variant="link">
              Edit Supplier
            </Button>
          )}
        </div>
        <div className={styles.supplierWrapper}>
          {!!quote.supplier ? (
              <Q.Contacts quote={quote} />
          ) : (
          <Button
            variant="outline-secondary"
            className={`${styles.addStyles} rounded-circle justify-content-center align-self-center mb-3`}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Add New Contact +
          </Button>
          )}
        </div>
      </div>
      {modalOpen && (
        <FormAddContact
          label={"Supplier"}
          show={modalOpen}
          hide={() => setModalOpen(false)}
          addContact={(contact) => {
            updateQuote({
              id: quote.id,
              supplier_contact_id: !!contact.id ? contact.id : contact.contact_id,
              supplier_id: !!contact.locations ? contact.locations[0].id : contact.location_id,
            });
          }}
        />
      )}
    </>
  );
};

export default QuoteSupplier;
