import React, {useState, useEffect} from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useFormControl, dropdownStylesWithWidth, Loader, bidApi, useApi } from "component-library";
import { initialData, schema } from "../../../../../schema/contactRole";
import { Alert } from "react-bootstrap";
import useProject from "../../../../hooks/useProject";

const EditTeamRole = ({ contact, setEditingRole, editRoleOpen }) => {
  const { loading, error, project, setProject } = useProject();
  const [role, setRole] = useState(contact?.cardTitle);

  const { request: updateRequest, error: updateError, data: updateData } = useApi(bidApi.completeBid);

  const roleOptions = [
    {
      title: "Project Manager",
      role_ids: "project_manager_ids",
    },
    {
      title: "Project Coordinator",
      role_ids: "project_coordinator_ids",
    },
    {
      title: "Superintendent",
      role_ids: "project_superintendent_ids",
    },
    {
      title: "Foreman",
      role_ids: "project_foremen_ids",
    },
  ];

  const onSubmit = async (formData, formLoading) => {
    const newRole = roleOptions.find((option) => option.title === formData.role_id);
    if (newRole.title !== role) {
      const currentRole = roleOptions.find((option) => option.title === role);
      const updatedProject = {
        ...project,
        [currentRole.role_ids]: project[currentRole.role_ids].filter((roleId) => roleId !== contact.id),
        [newRole.role_ids]: [...project[newRole.role_ids], contact.id],
      };
      delete updatedProject.dot_categories;

      try {
        const response = await updateRequest(updatedProject);

        if (!response.success) {
          throw new Error("Contact");
        } else {
          setEditingRole(false);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }
    formLoading(false);
  };

  const { handleSubmit, renderSubmitButton, renderSelectField, setFields, data } = useFormControl({
    schema,
    onSubmit,
    initialData: !!role ? { role_id: role } : initialData,
    scrollToTop: false,
  });

  useEffect(() => {
    if (!!updateData) {
      setProject(updateData.project);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    if (!!contact) {
      setRole(contact.cardTitle);
    }
  }, [contact]);

  useEffect(() => {
    if (role) {
      setFields({ ...data, role_id: role });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return (
    <Modal
      dialogClassName="modal-lg"
      centered
      animation={false}
      show={editRoleOpen}
      onHide={() => setEditingRole(false)}
    >
      <>
        <Modal.Header closeButton>
          <h5 className="mb-0 med">
            {!!contact
              ? `Edit Contact ${contact && contact.first_name} ${contact && contact.last_name}`
              : "Update Contact Role"}
          </h5>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="position-relative">
              <Loader loading={!!loading} />
              {(!!error || !!updateError) && <Alert variant="danger">{error || updateError}</Alert>}
              <div className="row">
                <div className="col-lg-4">
                  {renderSelectField({
                    name: "role_id",
                    label: "Role",
                    options: roleOptions.map((ct) => ({
                      value: ct.title,
                      label: ct.title,
                    })),
                    emptyOption: "Select...",
                    isClearable: false,
                    styles: dropdownStylesWithWidth("100%"),
                  })}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="mt-2" onClick={() => setEditingRole(false)}>
              Cancel
            </Button>
            {renderSubmitButton("Update Role")}
          </Modal.Footer>
        </form>
      </>
    </Modal>
  );
};

EditTeamRole.propTypes = {
  contact: PropTypes.object,
  setEditingRole: PropTypes.func.isRequired,
};

export default  EditTeamRole;
