import Certifications from "../addRFQColumns/Certifications";

const certifications = {
  headerName: "Certifications",
  field: "certifications",
  cellRenderer: Certifications,
  maxWidth: 172,
};

export default certifications;
