import React from "react";
import styles from "./styles.module.scss";
import PW from "../CardView/Card/PW";

const ProjectName = ({ data }) => {
  return (
    <div className={styles.projectTitle}>
      <div>
        <PW data={data} />
      </div>
      <div className={styles.name}>{data?.name}</div>
    </div>
  );
}

export default ProjectName;