import { Joi } from "component-library";
// number can be null or a number
const schema = {
  number: Joi.number().allow(null),
  description: Joi.saneString().required(),
  active_date: Joi.date().required(),
  expiration: Joi.date().when("active_date", {
    is: Joi.exist(),
    then: Joi.date().required().greater(Joi.ref("active_date")).messages({
      "date.greater": "Expiration date must be after active date",
    }),
  }),

  status: Joi.saneString().required(),
  type: Joi.saneString().required(),
  sb: Joi.checkbox(),
  sb_required: Joi.saneString(),
  ds: Joi.checkbox(),
  ds_required: Joi.saneString(),
};

const initialData = {
  number: "",
  type: null,
  description: "",
  status: "requested",
  sb: 0,
  sb_required: "",
  ds: 0,
  ds_required: "",
};

export { schema, initialData };
