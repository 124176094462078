const foreman_id = {
  headerName: "Foreman",
  field: "foreman_id",
  width: 172,
  valueGetter: ({ data }) => {
    return data?.foreman_first_name && data?.foreman_last_name
      ? `${data.foreman_first_name} ${data.foreman_last_name}`
      : null;
  },
};

export default foreman_id;
