import React from "react";
import useEligibilityRequirement from "./hooks/useEligibilityRequirement";
import PropTypes from "prop-types";

const Eligibility = ({ task, setTodos, setBid }) => {
  const {
    fields,
    renderSubmitButton,
    handleSubmit,
  } = useEligibilityRequirement(task, setTodos, setBid);

    return (
      <form className="row position-relative" onSubmit={handleSubmit}>
        <div className="col-md-8 position-static">{fields}</div>
        <div className="col-md-4 d-flex flex-column justify-content-end text-end p-3">
          <div className="flex-end">{renderSubmitButton("Mark Complete")}</div>
        </div>
      </form>
    );
};

Eligibility.propTypes = {
  task: PropTypes.object,
  setTodos: PropTypes.func.isRequired,
  setBid: PropTypes.func.isRequired,
};

export default Eligibility;