import React from "react";
import PropTypes from "prop-types";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import { displayDate } from "component-library";

const PhaseBadge = ({ phaseName, phase, phaseColor }) => {
  const popover = (
    <Popover id="phase-details">
      <Popover.Body>
        <div className="text-uppercase light-text sm text-center display-block">{phaseName}</div>
        {!!phase && (
          <div className="text-center display-block">
            {!!phase.start_date && displayDate(phase.start_date) + " to "}
            {!!phase.end_date && displayDate(phase.end_date)}
          </div>
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="d-flex d-flex align-items-center justify-content-center">
      <OverlayTrigger placement="top" overlay={popover} transition={false}>
        <Badge pill className="pt-1 px-2 text-uppercase phase-badge mx-1" bg="" style={{ backgroundColor: phaseColor }}>
          {phaseName}
        </Badge>
      </OverlayTrigger>
    </div>
  );
};

PhaseBadge.propTypes = {
  phaseName: PropTypes.string,
  phaseColor: PropTypes.string,
  phase: PropTypes.object,
};

export default PhaseBadge;
