export const getAttachmentFoldersList = (attachments, defaultFolders) => {
  let folders = [];
  if (!!defaultFolders?.length) {
    defaultFolders.forEach((folder) => {
      if (!folders.includes(folder.name)) {
        folders.push(folder.name);
      }
    });
  }
  if (!!attachments?.length) {
    attachments.forEach((attachment) => {
      if (!folders.includes(attachment.folder) && attachment.folder !== "") {
        folders.push(attachment.folder);
      }
    });
  }

  return folders;
};
