import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const WorkRatePill = ({ workRate }) => {
  return (
    <div className="d-flex align-items-center">
      <div className={`${styles.pill} ${parseFloat(workRate) <= 0 ? styles.noWarn : styles.warn}`}>
        <div>
          {parseFloat(workRate) > 0 && "+"}
          {workRate}
        </div>
      </div>
    </div>
  );
};

WorkRatePill.propTypes = {
  workRate: PropTypes.any,
};

export default WorkRatePill;
