import { useState, useEffect } from "react";

const useFilterBlock = ({ setSelectedConsoleFilters, selectedConsoleFilters, title, timesheetCount }) => {
  const [active, setActive] = useState(false);
  const handleToggle = (value, filterTitle) => {
    if (value === 1) {
      setSelectedConsoleFilters(
        !!selectedConsoleFilters?.length ? [...selectedConsoleFilters, filterTitle] : [filterTitle]
      );
    } else {
      setSelectedConsoleFilters(selectedConsoleFilters.filter((filter) => filter !== filterTitle));
    }
  };

  useEffect(() => {
    if (!!selectedConsoleFilters?.includes(title)) {
      setActive(true);
    } else {
      setActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConsoleFilters]);

  const getActiveColor = (inActiveColor, completeColor, warningColor) => {
    let color = inActiveColor;

    if (timesheetCount === 0) {
      color = completeColor;
    } else {
      color = warningColor;
    }

    return color;
  };

  return {
    active,
    handleToggle,
    getActiveColor,
  };
};

export default useFilterBlock;
