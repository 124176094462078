import React, { useState } from "react";
import { Loader, useAuth } from "component-library";
import styles from "./styles/Company.module.scss";
import { Button } from "react-bootstrap";
import AddCompany from "../ContactsAdmin/AddCompany";
import AddLocation from "../ContactsAdmin/AddLocation";
import ArchiveCompany from "./ArchiveCompany";
import CompanyContacts from "./CompanyContacts";
import Locations from "./Locations";
import { Plus } from "react-feather";
import ArchiveLocation from "./Locations/ArchiveLocation";
import CompanyProjects from "./CompanyProjects";
import ArchiveContact from "./CompanyContacts/ArchiveContact";
import useCompany from "./useCompany";
import EditCompanyContacts from "./EditCompanyContacts";
import { initialData } from "../../schema/contact";

const Company = ({ company, setCompany, editing, setEditing, archiving, setArchiving }) => {
  const { config } = useAuth();

  const {
    loading,
    setEditLocation,
    setEditingLocation,
    archivingLocation,
    setArchivingLocation,
    setArchiveLocation,
    archiveLocation,
    editingLocation,
    editLocation,
    archiveContact,
    setArchiveContact,
    archivingContact,
    setArchivingContact,
  } = useCompany({ company, setCompany });
  const [editingContact, setEditingContact] = useState(false);

  return (
    <>
      {!!loading ? (
        <Loader />
      ) : (
        <>
          {!!company && (
            <>
              <div>
                <div className="d-lg-flex align-items-center justify-content-between mt-3 mb-1">
                  <div>
                    <div className="d-flex align-items-center">
                      <div>
                        <h2 className="xl font-weight-bold mb-0">{company.name}</h2>
                      </div>

                      {!!company.archived ? (
                        <span className="sm-med text-gray3 ms-3">(archived)</span>
                      ) : (
                        <Button
                          variant="link"
                          onClick={() => {
                            setEditLocation();
                            setEditingLocation(true);
                          }}
                        >
                          Add Location <Plus size="10" />
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    {!company.archived && (
                      <div className="d-flex justify-content-end pt-1">
                        <Button className="sm-med mb-1" variant="link" onClick={() => setEditing(true)}>
                          + Add Category
                        </Button>
                      </div>
                    )}
                    <div className="d-flex flex-wrap">
                      {!!company.company_categories &&
                        !!company.company_categories.length &&
                        config.company_categories
                          .filter((cat) => company.company_categories.indexOf(cat.id) > -1)
                          .map((c) => (
                            <div
                              className={`${styles.categoryBubble} ${
                                !!company.archived && styles.archived
                              } font-weight-bold d-flex mb-1 justify-content-center align-items-center`}
                              key={c.id}
                            >
                              {c.name}
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              </div>
              <Locations
                company={company}
                setCompany={setCompany}
                locations={
                  !!company &&
                  !!company.locations &&
                  !!company.locations.length &&
                  company.locations.sort((a, b) => {
                    return a.archived - b.archived;
                  })
                }
                setEditingLocation={setEditingLocation}
                setArchivingLocation={setArchivingLocation}
                setArchiveLocation={setArchiveLocation}
                setEditLocation={setEditLocation}
              />
              <div className="row">
                <div className="col mt-4 d-flex align-items-center">
                  <h4 className="mb-0 lg font-weight-bold">Contacts</h4>
                  {!company.archived && (
                    <Button
                      variant="link"
                      onClick={() => {
                        setEditingContact({ ...initialData, company });
                      }}
                    >
                      Add Contact <Plus size="10" />
                    </Button>
                  )}
                </div>
              </div>
              <div>
                {!!company.locations &&
                  company.locations.map((location) => (
                    <div className={`align-items-start`} key={location.id}>
                      {!!location && !!location.contacts && !!location.contacts.length && (
                        <>
                          <h4 className="med font-weight-bold mb-1">{location.name}</h4>
                          <div className={`${styles.gridWrapper} mb-3`}>
                            <CompanyContacts
                              location={location}
                              contacts={
                                !!location &&
                                !!location.contacts &&
                                !!location.contacts.length &&
                                location.contacts.sort((a, b) => {
                                  return !!a.contact && a.contact.length && a.contact.archived - b.contact.archived;
                                })
                              }
                              setCompany={setCompany}
                              setEditingContact={setEditingContact}
                              setArchivingContact={setArchivingContact}
                              setArchiveContact={setArchiveContact}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  ))}
              </div>
              {!!company.projects && !!company.projects.length && (
                <div>
                  <CompanyProjects
                    currentProjects={company.projects.filter((project) => project.status !== "complete")}
                    pastProjects={company.projects.filter((project) => project.status === "complete")}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}

      {!!editing && <AddCompany company={company} setCompany={setCompany} setAddingCompany={setEditing} />}
      <EditCompanyContacts
        company={company}
        setCompany={setCompany}
        editingContact={editingContact}
        setEditingContact={setEditingContact}
      />
      {!!editingLocation && (
        <AddLocation
          isAdmin={true}
          company={company}
          location={editLocation}
          setLocation={setCompany}
          setAddingLocation={setEditingLocation}
          setCompany={setCompany}
        />
      )}
      {!!archiving && <ArchiveCompany company={company} setArchiving={setArchiving} />}
      {!!archivingContact && (
        <ArchiveContact
          company={company}
          setCompany={setCompany}
          contact={archiveContact}
          setArchiving={setArchivingContact}
        />
      )}
      {!!archivingLocation && (
        <ArchiveLocation
          setArchiveLocation={setArchiveLocation}
          setCompany={setCompany}
          company={company}
          location={archiveLocation}
          archivingLocation={archivingLocation}
          setArchiving={setArchivingLocation}
        />
      )}
    </>
  );
};

export default Company;
