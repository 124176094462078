import { AlertCircle, DollarSign, ArrowUpCircle, Clock, Trash2, Truck, AlertTriangle } from "react-feather";
import { ReactComponent as LunchIcon } from "../assets/lunch.svg";
import { ReactComponent as CalendarX } from "../assets/CalendarX.svg";

const size = 16;

/**
 * Gets the alert icon based off of the report type
 * @param  {String} alert type of alert
 * @return {Object} Returns a styled icon as a React component
 */

const getAlertIcon = (alert) => {
  switch (alert) {
    case "unallocated":
      return <AlertCircle size={size} color={"#E70000"} />;
    case "unclaimed":
      return <AlertTriangle size={size} color={"#E70000"} />;
    case "perdiem":
      return <DollarSign size={size} color={"#E39200"} />;
    case "lunch":
      return <LunchIcon width={size} height={size} color={"#E39200"} />;
    case "over_threshold":
      return <ArrowUpCircle size={size} color={"#E39200"} />;
    case "late":
      return <Clock size={size} color={"#E39200"} />;
    case "deleted":
      return <Trash2 size={size} color={"#E39200"} />;
    case "missing_trade":
      return <Truck size={size} color={"#E70000"} />;
    case "absent":
      return <CalendarX size={size} color={"#E39200"} />;
    default:
      return <AlertCircle size={size} color={"#E70000"} />;
  }
};

export default getAlertIcon;
