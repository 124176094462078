const equipmentConfig = {
  Attachments: { color: "#CE4C00", abbr: "AT" },
  Backhoes: { color: "#D54F94", abbr: "BH" },
  Buckets: { color: "#6DAEF6", abbr: "BU" },
  Bulldozers: { color: "#E0B503", abbr: "BD" },
  Concrete: { color: "#968F8F", abbr: "CO" },
  Cranes: { color: "#7BC73A", abbr: "CR" },
  Crushing: { color: "#85A26D", abbr: "CE" },
  Drills: { color: "#00C3CE", abbr: "DR" },
  Excavators: { color: "#0071CE", abbr: "EX" },
  Lifts: { color: "#7B7B7B", abbr: "LI" },
  Loaders: { color: "#EC892C", abbr: "LD" },
  "Rollers & Compactors": { color: "#8860AB", abbr: "RC" },
  "Tractors & Scrapers": { color: "#8860AB", abbr: "TS" },
  Trailers: { color: "#7086A7", abbr: "TR" },
  Trucks: { color: "#3D909A", abbr: "TK" },
};

export default equipmentConfig;