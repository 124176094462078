import React from "react";
import { Modal, Button } from "react-bootstrap";
import { RiseLoader } from "react-spinners";
import styles from "../styles.module.scss";

const DeleteConfirmation = ({
  id,
  setShowConfirmation,
  deletePurchaseOrder,
  deleteLoading,
}) => (
  <Modal show={true} centered onHide={() => setShowConfirmation(false)}>
    {!!deleteLoading && (
      <div className={styles.loaderWrapper}>
        <RiseLoader color={"#0071ce"} className={styles.loader} />
      </div>
    )}
    <div className="med confirmation-header mt-3">Delete Purchase Order</div>
    <div className="my-4 med confirmation-text">
      Are you sure you want to delete this purchase order?
    </div>
    <div className="d-flex justify-content-end">
      <Button
        className="light-text mx-1 med py-1"
        variant="light-gray"
        onClick={() => setShowConfirmation(false)}
      >
        Cancel
      </Button>
      <Button
        className="light-text mx-1 med py-1"
        variant="orange"
        onClick={() => {
          deletePurchaseOrder(id);
        }}
      >
        Delete
      </Button>
    </div>
  </Modal>
);

export default DeleteConfirmation;
