import { useEffect, useState } from "react";
import { useApi, quoteApi } from "component-library";

const useLineItemUpdate = (quote, setQuote, item, setLineError) => {
  const formatUnitPrice = (value) => {
    let output = value;
    if (output && output.includes(".")) {
      if (
        output.length > output.indexOf(".") + 3 &&
        output[output.indexOf(".") + 3] === "0" &&
        output[output.indexOf(".") + 4] === "0"
      ) {
        output = output.slice(0, output.indexOf(".") + 3);
      }
    }
    return output;
  };

  const [quantity, setQuantity] = useState(!!item ? item.rfq_quantity : "");
  const [partNumber, setPartNumber] = useState(
    !!item ? item.supplier_part_number : ""
  );
  const [unitPrice, setUnitPrice] = useState(
    !!item ? formatUnitPrice(item.base_price) : ""
  );

  const {
    data: updateLineItemData,
    loading: updateLineItemLoading,
    error: updateLineItemError,
    request: updateLineItemRequest,
  } = useApi(quoteApi.updateLineItem);

  const { data: removeLineItemsData, request: removeLineItemsRequest } = useApi(
    quoteApi.removeLineItems
  );

  const handleUpdate = (field, value) => {
    let formatted;
    switch (field) {
      case "base_price":
        formatted = parseFloat(value);
        break;
      case "rfq_quantity":
        formatted = parseInt(value);
        break;
      default:
        formatted = value;
        break;
    }
    if (!formatted) {
      formatted = 0
    }
    updateLineItemRequest({
      id: item.id,
      [field]: formatted,
    });
  };

  const deleteLineItems = (lineItemIds) => {
    removeLineItemsRequest({ line_item_ids: lineItemIds });
  };

  useEffect(() => {
    if (!!removeLineItemsData && !!removeLineItemsData) {
      if (!!quote && quote.status === 2) {
        setQuote({
          ...quote,
          requote: {
            ...quote.requote,
            line_items: quote.requote.line_items.filter((li) => li.id !== removeLineItemsData.items[0]),
          },
        });
      } else {
        setQuote({
          ...quote,
          line_items: quote.line_items.filter((li) => li.id !== removeLineItemsData.items[0]),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeLineItemsData]);

  useEffect(() => {
    if (!!updateLineItemData) {
      if (!!quote && quote.status === 2) {
        setQuote({
          ...quote,
          requote: {
            ...quote.requote,
            line_items: quote.requote.line_items.map((li) =>
              li.id === updateLineItemData.line_item.id ? updateLineItemData.line_item : li
            ),
          },
        });
      } else {
        setQuote({
          ...quote,
          line_items: quote.line_items.map((li) =>
            li.id === updateLineItemData.line_item.id ? updateLineItemData.line_item : li
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLineItemData]);

  useEffect(() => {
    if (!!updateLineItemError) {
      setLineError(updateLineItemError);
      setQuote(quote);
    } else {
      setLineError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLineItemError]);

  useEffect(() => {
    if (item) {
      setQuantity(item.rfq_quantity);
      setPartNumber(item.supplier_part_number);
      setUnitPrice(formatUnitPrice(item.base_price));
    }
  }, [item]);

  return {
    updateLineItemLoading,
    handleUpdate,
    deleteLineItems,
    partNumber,
    setPartNumber,
    quantity,
    setQuantity,
    unitPrice,
    setUnitPrice,
  };
};

export default useLineItemUpdate;
