import { useEffect, useState } from "react";

const useExportLogic = ({ fieldLog, setFieldLog, minutesUnassigned, overAllocatedTimeSheets }) => {
  const [exportDisabled, setExportDisabled] = useState(true);
  const [status, setStatus] = useState();

  useEffect(() => {
    if (
      !!fieldLog &&
      minutesUnassigned === 0 &&
      overAllocatedTimeSheets.length === 0 &&
      (status === 5 || status === 1)
    ) {
      setExportDisabled(false);
    } else {
      setExportDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, fieldLog, minutesUnassigned, overAllocatedTimeSheets]);

  useEffect(() => {
    if (fieldLog && fieldLog.status && fieldLog.status.id !== status) {
      setStatus(fieldLog.status.id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldLog]);

  const updateExportedStatus = () => {
    setFieldLog({ ...fieldLog, status: { id: 1, status: "Exported" } });
  };

  return {
    status,
    setStatus,
    updateExportedStatus,
    exportDisabled,
  };
};

export default useExportLogic;
