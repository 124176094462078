import React from "react";
import LocatesSection from "../LocatesAndPermits/Locates/LocatesSection";
import PermitsSection from "../LocatesAndPermits/Permits/PermitsSection";
import InteriorPage from "../pageLayout/InteriorPage";
import ProjectGeoFence from "../ProjectGeofence/ProjectGeoFence";
import useSiteManagment from "../../hooks/useSiteManagment";
import { useParams } from "react-router";
import { RiseLoader } from "react-spinners";
import useProject from "../../_projects/hooks/useProject";
import { Briefcase } from "react-feather";

const SiteManagement = () => {
  const { id } = useParams();
  const {
    locates,
    permits,
    updateData,
    locatesLoading,
    locatesError,
    setLocates,
    initialLoading,
  } = useSiteManagment(id);

  const { pageTitle, PageTag } = useProject();

  return (
    <InteriorPage 
      error={locatesError}
      pageTitle={"Site Management"}
      loading={initialLoading}
      navTitle={pageTitle}
      navIcon={<Briefcase color="#fff" size={14} />}
      NavContent={PageTag}
    >

      <div className="position-relative">
        {!!locatesLoading && (
          <div className={"loaderWrapper"}>
            <RiseLoader color={"#0071ce"} />
          </div>
        )}

        <ProjectGeoFence />

        <LocatesSection
          locates={locates}
          updateData={updateData}
          setLocates={setLocates}
        />
        <PermitsSection permits={permits} updateData={updateData} />
      </div>

    </InteriorPage>
  );
};

export default SiteManagement;
