import Source from "../columnRenders/Source";

const source = {
  headerName: "Uploaded By",
  name: "source",
  valueGetter: ({ data }) => (data.source ? data.source : null),
  cellRenderer: Source,
  width: 200,
};

export default source;
