import Note from "../columns/Note";

const note = {
  headerName: "",
  field: "note_date",
  cellRenderer: Note,
  flex: 1,
  cellStyle: {
    height: "100%",
    display: "flex ",
    borderTop: "none",
    justifyContent: "flex-start",
    alignItems: "flex-start ",
    padding: 0,
  },
  minHeight: 175,
  autoHeight: true,
};

export default note;
