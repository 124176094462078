import Alerts from "../columnRenders/Alerts";

const alerts = {
  headerName: "Reporting",
  field: "alerts",
  width: 125,
  cellRenderer: Alerts,
};

export default alerts;
