import React from "react";
import sectionStyles from "../styles.module.scss";

const DescriptionOfWork = ({ day, updateDateField }) => {
  return (
    <section className={sectionStyles.fieldSection}>
      <div className="form-group d-flex align-items-start">
        <h3 htmlFor={`${day.id}-description`}>Description of Work</h3>
        <textarea
          id={`${day.id}-description`}
          onChange={(e) => updateDateField(day, "description", e.target.value)}
          className="med form-control"
          value={day.description}
        />
      </div>
    </section>
  );
};

export default DescriptionOfWork;
