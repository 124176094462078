import React, { useEffect, useState, useMemo } from "react";
import { Alert, Button } from "react-bootstrap";
import {
  useFormControl,
  InlineUpload,
  dropdownStylesWithWidth,
  AttachmentLoading,
  handleError,
  useAuth,
} from "component-library";
import { XCircle, Folder } from "react-feather";
import styles from "./styles.module.scss";
import useProjectFileUpload from "../../../hooks/useProjectFileUpload";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import useProjectDocuments from "../context/useProjectDocuments";

const useAddDocument = ({
  setModalOpen,
  projectId,
  fileChosenForUpload,
  setFileChosenForUpload,
  dropdownSelection,
  setDropdownSelection,
}) => {
  const { attachments, setAttachments, folderList } = useProjectDocuments();
  const [loading, setLoading] = useState(false);
  const [docTypes, setDocTypes] = useState();
  const [percentComplete, setPercentComplete] = useState(0);
  const [folderOptions, setFolderOptions] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState({});

  const { config, userCan } = useAuth();

  const { uploadFile, updatedData, error } = useProjectFileUpload({
    bidId: projectId,
    setLoading: setLoading,
    setPercentComplete,
  });

  const onSubmit = async (formData, setLoading) => {
    setLoading(true);

    try {
      await uploadFile(fileChosenForUpload?.file, [dropdownSelection.value], null, selectedFolder.value);
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!!updatedData) {
      setLoading(false);
      setModalOpen(false);
      setAttachments(updatedData.project.attachments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedData]);

  const { handleSubmit, renderSubmitButton } = useFormControl({
    onSubmit,
  });

  const addPendingAttachment = async (file, file_type) => {
    setFileChosenForUpload({ file, file_type });
  };

  const forcedPlansAndSpecs = useMemo(
    //if specbook or construction plans are selected
    () => dropdownSelection?.value === 2 || dropdownSelection?.value === 23,
    [dropdownSelection]
  );

  useEffect(() => {
    const types = config?.file_types.map((t) => {
      return { value: t.id, label: t.name };
    });
    setDocTypes(types)
  }, [config?.file_types]);

  useEffect(() => {
    if (!!folderList) {
      setFolderOptions(
        folderList.map((folder) => {
          return { label: folder, value: folder };
        })
      );
    }
  }, [folderList]);

  useEffect(() => {
    if (forcedPlansAndSpecs) {
      setSelectedFolder(folderOptions.find(opt => opt.value === "Plans and Specs"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownSelection])

  const fields = (
    <div className="position-relative">
      {error && <Alert variant="danger">{error}</Alert>}

      <div className="container">
        <div className="d-flex row align-items-center mb-3">
          <div className="d-flex align-items-center">
            <div className={`${styles.modalFieldTitle}`}>Uploaded File</div>
            <div className="ms-3">
              {loading ? (
                <AttachmentLoading percentComplete={percentComplete} inline />
              ) : Object.keys(fileChosenForUpload || {}).length === 0 ? (
                <InlineUpload uploadText="Select File" addAttachment={addPendingAttachment} />
              ) : (
                <div className={styles.archiveWrap}>
                  <div className="d-flex align-items-center ms-1">
                    <Folder className={`${styles.modalIcons}`} size="16" color="#D3D3D3" />
                    <div className={`${styles.simplifiedTitle} text-break thirteen ms-1`}>
                      <div className="text-primary">{fileChosenForUpload?.file?.name}</div>
                    </div>
                  </div>
                  <Button
                    variant="link"
                    className="d-flex align-content-center justify-content-center p-0"
                    onClick={() => setFileChosenForUpload({})}
                  >
                    <XCircle className={`${styles.removeIcon}`} size={16} color="#d3d3d3" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center mb-3">
          <label htmlFor="document-type-select" className={`me-3 med ${styles.modalFieldTitle}`}>
            Document Type
          </label>
          <Select
            options={docTypes}
            name="document_type"
            inputId="document-type-select"
            placeholder="Select a Document type"
            onChange={(e) => setDropdownSelection(e)}
            styles={dropdownStylesWithWidth(250)}
            aria-label="select a document type"
          />
        </div>
        <div className="d-flex align-items-center">
          <label htmlFor="document_folder_select" className={`med me-3 ${styles.modalFieldTitle}`}>
            Document Folder
          </label>
          {!!userCan("document-folder-create") ? (
            <CreatableSelect
              options={folderOptions}
              name="document_folder_select"
              inputId="document_folder_select"
              placeholder="Select a Document Folder"
              onChange={(e) => setSelectedFolder(e)}
              value={folderOptions.find((item) => item?.value === selectedFolder?.value)}
              styles={dropdownStylesWithWidth(250)}
              aria-label="select a document folder"
              isDisabled={forcedPlansAndSpecs}
            />
          ) : (
            <Select
              options={folderOptions}
              name="document_folder_select"
              inputId="document_folder_select"
              placeholder="Select a Document Folder"
              onChange={(e) => setSelectedFolder(e)}
              value={folderOptions.find((item) => item?.value === selectedFolder?.value)}
              styles={dropdownStylesWithWidth(250)}
              aria-label="select a document folder"
              isDisabled={forcedPlansAndSpecs}
            />
          )}
        </div>
        {!!userCan("document-folder-create") && (
          <p className="page-subtitle sm-med p-3">New folder options can be typed into the document folder field.</p>
        )}
      </div>
    </div>
  );

  return {
    fields,
    handleSubmit,
    renderSubmitButton,
    loading,
    setLoading,
    selectedFolder,
  };
};

export default useAddDocument;
