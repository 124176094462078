import useProject from "../../../_projects/hooks/useProject";

import { costCode, description, qtyRepEst, hoursRepEarned, hrsEstRmn, pacing, percentage, expand } from "./columnDefs";

const useCellConfig = () => {
  const { project } = useProject();

  const handleCellClick = ({ data, column }) => {
    if (["description", "name"].includes(column?.colId)) {
      window.open(`/project/${project?.id}/reporting/task/${data?.task_id}`, "_blank");
    }
  };

  const columnDefs = [costCode, description, qtyRepEst, hoursRepEarned, hrsEstRmn, pacing, percentage, expand];

  const detailGridOptions = {
    groupDefaultExpanded: 1,
    rowSelection: "multiple",
    pagination: false,
    columnDefs: columnDefs,
    rowHeight: 42,
    rowClass: "masterDetailRow",
    onCellClicked: ({ data, column }) => handleCellClick({ data, column }),
    defaultColDef: {
      sortable: true,
    },
    detailRowAutoHeight: true,
  };

  return detailGridOptions;
};

export default useCellConfig;
