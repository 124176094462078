import React, { useEffect, useRef, useState } from "react";
import { MoreHorizontal } from "react-feather";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { useAuth } from "component-library";

const ContactSubNav = ({ children }) => {
  const [menuActive, setMenuActive] = useState(false);
  const node = useRef();
  const { userCan } = useAuth();

  const handleClick = (e) => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }
    setMenuActive(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <>
      {userCan("contact-edit") && (
        <nav className={styles["attachment-navbar"]} ref={node}>
          {!menuActive && (
            <Button
              size="sm"
              variant="link"
              onClick={() => {
                setMenuActive(!menuActive);
              }}
              className="p-0"
              aria-label="additional options"
            >
              <MoreHorizontal size="20" color="#7b7b7b" />
            </Button>
          )}
          <div className={`${styles["submenu-wrapper"]} ${!!menuActive ? "submenu-active" : ""}`}>
            <Button
              size="sm"
              variant="link"
              onClick={() => {
                setMenuActive(!menuActive);
              }}
              className={`p-0 ${styles.menuButton}`}
            >
              <MoreHorizontal size="20" color="#7b7b7b" />
            </Button>
            <ul className={`p-2 mt-3 mb-0 list-inline text-start ${styles.submenu}`}>{children}</ul>
          </div>
        </nav>
      )}
    </>
  );
};

ContactSubNav.propTypes = {
  children: PropTypes.node,
};

export default ContactSubNav;
