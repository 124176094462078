import React from "react";
import styles from "./styles.module.scss";
import Stat from "./Stat";
import { BeatLoader } from "react-spinners";
import { AlertPopup } from "component-library";
import useStats from "./useStats";
import useResourceManagement from "../../../../../../_resourceManagement/context/useResourceManagement";
import useProject from "../../../../../hooks/useProject";
import { Link } from "react-router-dom";

const Stats = () => {
  const { stats, statsLoading, statsError } = useStats();
  const { dateRange } = useResourceManagement();
  const { project } = useProject();

  return (
    <div className={styles.stats}>
      <h2 className={styles.statsTitle}>
        Project Stats
        {!!statsLoading && (
          <span className="d-flex align-items-center">
            <BeatLoader size={10} color={"#0071ce"} />
          </span>
        )}
        {!!statsError && (
          <span className="ms-2">
            <AlertPopup error={statsError} />
          </span>
        )}
      </h2>
      {!!dateRange[0] !== dateRange[1] ? (
        <>
          <div className={styles.statsRow}>
            {!!stats && !statsLoading && !statsError && (
              <>
                <Stat title="Working Days Remaining" color="#0071ce" value={stats.remaining} />
                <Stat
                  title="Over Under"
                  color={stats.over_under <= 0 ? "#6FCB21" : "#E70000"}
                  value={stats.over_under}
                  symbol="%"
                />
                <Stat value={stats.man_hours} title="Man Hours" color="#0071ce" subTitle="Today" size="small" />
                <Stat
                  color={stats.over_under >= 0 ? "#E70000" : "#6FCB21"}
                  value={stats.earned_hours}
                  title="Earned Man Hours"
                  subTitle="Today"
                  size="small"
                />
              </>
            )}
            {(!!statsLoading || !!statsError) && (
              <>
                <Stat title="Working Days Remaining" />
                <Stat title="Over Under" />
                <Stat title="Man Hours" />
                <Stat title="Earned Man Hours" />
              </>
            )}
          </div>
        </>
      ) : (
        <div className={styles.statsRow}>
          <span className={`${styles.multipleText} me-1`}>
            {!!dateRange[0] ? "Multiple Dates Selected" : "No Date Range Selected"}
          </span>{" "}
          -
          <Link className="ms-1 btn-link" to={`/project/${project?.id}/reporting/summary`}>
            View Summary
          </Link>
        </div>
      )}
    </div>
  );
};

export default Stats;
