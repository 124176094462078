import React from "react";
import styles from "./styles.module.scss";

const RowItemContent = ({ children, width }) => {
  return (
    <div className={styles.rowItemContent} style={width ? { width: width } : null}>
      {children}
    </div>
  );
};

export default RowItemContent;
