import Relocate from "../columnRenders/Relocate";

const relocate = {
  headerName: "Relocate",
  field: "relocate",
  cellRenderer: Relocate,
  maxWidth: 112
};

export default relocate;
