// import { ExteriorPage } from "component-library";

const SharePage = ({ children, pageTitle, subtitle, loading, error, fillScreen, navIcon }) => {
  return (
    <></>
    // <ExteriorPage
    //   pageTitle={pageTitle}
    //   subtitle={subtitle}
    //   navIcon={navIcon}
    //   loading={loading}
    //   error={error}
    //   fillScreen={fillScreen}
    // >
    //   {children}
    // </ExteriorPage>
  );
};

export default SharePage;
