import React from "react";
import { Button } from "react-bootstrap";
import { useAuth } from "component-library";
import { X } from "react-feather";
import Contact from "../Contact";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const ContactControl = ({ contact, role, removeContact, title, colDisplay = true }) => {
  const { userCan } = useAuth();

  return (
    <div
      className="light-text p-3 w-100 mb-2 me-4 position-relative"
      style={{
        minWidth: 150,
        backgroundColor: "#F7F7F7",
        borderRadius: "1.2rem",
      }}
    >
      {title && <div className={`text-quaternary ${styles.semibold} sm text-uppercase`}>{title}</div>}
      {userCan("submittal-edit") && removeContact && (
        <Button
          size="sm"
          variant="link"
          onClick={() => removeContact(contact, role)}
          className={styles.close}
          aria-label="remove contact"
        >
          <X size="14" color="#979797" />
        </Button>
      )}

      {!!contact && <Contact contact={contact} colDisplay={colDisplay} />}
    </div>
  );
};

Contact.propTypes = {
  contact: PropTypes.object,
  role: PropTypes.string,
  removeContact: PropTypes.func,
  title: PropTypes.string,
  colDisplay: PropTypes.bool,
};

export default ContactControl;
