import React from "react";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import { Briefcase } from "react-feather";
import usePhotosByDate from "./usePhotosByDate";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import useProject from "../../../hooks/useProject";
import { Eye } from "react-feather";

const PhotosByDate = () => {
  const { project } = useProject();
  const { photos, photoDate, photosLoading, photosError } = usePhotosByDate();
  const { pageTitle, loading, error } = useProject();

  return (
    <InteriorPage
      loading={loading || photosLoading}
      error={error || photosError}
      navTitle={pageTitle}
      pageTitle="Photo Library"
      navIcon={<Briefcase color="#fff" size={14} />}
      pageSubtitlePrimaryContent={() => <>{photoDate}</>}
    >
      <div className="container-fluid">
        <div className="row">
          <div className={styles.grid}>
            {!!photos?.length &&
              photos.map((photo) => (
                <div className={styles.imgWrapper} key={photo.id}>
                  <Link to={`/project/${project.id}/photo-library/photo/${photo.id}`} className="position-relative">
                    <img src={photo?.small_thumbnail || photo?.abs_url} alt="" />
                    <div className={styles.overlay}>
                      <Eye color="#fff" />
                    </div>
                  </Link>
                </div>
              ))}
          </div>
          {!photos?.length && !photosLoading && <>No photos found for this date</>}
        </div>
      </div>
    </InteriorPage>
  );
};

export default PhotosByDate;
