import React from "react";
import RowContent from "../../RowContent";
import RowItemContent from "../../RowItemContent";
import timesheetWidths from "../../gridWidths/timesheetWidths";
import ClockIn from "../../../../config/sharedColumns/columnRenders/ClockIn";
import ClockOut from "../../../../config/sharedColumns/columnRenders/ClockOut";
import convertDecimalHours from "../../../../utils/convertDecimalHours";
import convertMinutesToTime from "../../../../utils/convertMinutesToTime";
import Date from "../../columnRenders/Date";
import ProjectName from "../../columnRenders/ProjectName";

const TimesheetRow = ({ timesheet, isCrewLog = false }) => {
  return (
    <RowContent indent="2.5rem">
      {!!isCrewLog && (
        <RowItemContent width={timesheetWidths.date}>
          <Date date={timesheet.date} />
        </RowItemContent>
      )}
      <RowItemContent width={timesheetWidths.id}>{timesheet.id || timesheet.timesheet_id}</RowItemContent>
      {!isCrewLog && (
        <RowItemContent width={timesheetWidths.crew_member}>
          <>{`${timesheet.first_name} ${timesheet.last_name}`}</>
        </RowItemContent>
      )}
      {!!isCrewLog && <RowItemContent width={timesheetWidths.job_number}>{timesheet.job_number}</RowItemContent>}
      {!!isCrewLog && (
        <RowItemContent width={timesheetWidths.project_name}>
          <ProjectName projectName={timesheet.project_name} />
        </RowItemContent>
      )}
      {!!isCrewLog && <RowItemContent width={timesheetWidths.foreman}>{timesheet.foreman}</RowItemContent>}
      <RowItemContent width={timesheetWidths.per_diem}>{timesheet.per_diem}</RowItemContent>
      <RowItemContent width={timesheetWidths.clock_in}>
        <ClockIn data={timesheet} />
      </RowItemContent>
      <RowItemContent width={timesheetWidths.clock_out}>
        <ClockOut data={timesheet} />
      </RowItemContent>
      <RowItemContent width={timesheetWidths.lunch}>
        {timesheet.lunch ? convertMinutesToTime(timesheet.lunch) : "0:00"}
      </RowItemContent>
      <RowItemContent width={timesheetWidths.unallocated}>{timesheet.unallocated}</RowItemContent>
      <RowItemContent width={timesheetWidths.hours}>{convertDecimalHours(timesheet.hours)}</RowItemContent>
      <RowItemContent>{timesheet.note_count ? timesheet.note_count : "-"}</RowItemContent>
    </RowContent>
  );
};

export default TimesheetRow;
