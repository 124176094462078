import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import LocatesList from "./LocatesList";
import { ChevronRight, ChevronDown } from "react-feather";
import AddNewLocate from "./AddNewLocate";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const LocatesSection = ({ locates, updateData, setLocates }) => {
  const [open, setOpen] = useState(true);
  const [addNew, setAddNew] = useState(false);
  const [editLocate, setEditLocate] = useState(null);
  const [parentLocates, setParentLocates] = useState([]);

  useEffect(() => {
    if (!!locates.length) {
      const parents = locates.filter((locate) => !locate.parent_id);
      setParentLocates(
        parents.filter((locate) => locate.status.toLowerCase() !== "archived")
      );
    }
  }, [locates]);

  const updateLocates = (locates) => {
    setLocates(locates);
    setEditLocate(null);
  };

  return (
    <>
      {addNew && <AddNewLocate close={() => setAddNew(false)} update={updateData} />}
      {!!editLocate && (
        <AddNewLocate
          close={() => setEditLocate(null)}
          update={updateData}
          locate={editLocate}
          setLocates={updateLocates}
        />
      )}
      <div className="locates-section">
        <div className="section-header d-flex flex-row justify-content-between py-3">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="page-title font-weight-bold">Locates</div>
            <Link to="/field-management/locates" className="align-self-center sm ms-2 btn-link" aria-label="view all locates">
              View All
            </Link>
          </div>
          <Button
            className={`${styles.sectionToggleButton} d-flex flex-grow-1 justify-content-end`}
            onClick={() => setOpen(!open)}
            aria-label={!!open ? "Hide locates section toggle" : "Show locates section toggle"}
          >
            {open ? <ChevronDown color="#C4C4C4" size={24} /> : <ChevronRight color="#C4C4C4" size={24} />}
          </Button>
        </div>
        {open && (
          <div className="section-content text-end">
            <Button onClick={() => setAddNew(true)} variant="link" className="" aria-label="add new locate">
              Add New +
            </Button>
            <LocatesList locates={parentLocates} setEditLocate={setEditLocate} setLocates={setLocates} />
          </div>
        )}
      </div>
      <hr />
    </>
  );
};

LocatesSection.propTypes = {
  locates: PropTypes.array.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default LocatesSection;
