import React from "react";
import { dropdownStyles } from "component-library";
import PropTypes from "prop-types";
import Select from "react-select";

const CompanyLocationSelect = ({
  locations,
  setLocations,
  locationOptions,
  error,
  label = "Select company locations",
}) => {
  return (
    <div className={`${!!error ? "error" : undefined} form-group`}>
      <label htmlFor="pre_bid_time">{label}</label>
      <Select
        options={
          !!locationOptions &&
          !!locationOptions.length &&
          locationOptions.map((loc) => {
            return {
              label: loc.name,
              value: loc.id,
            };
          })
        }
        name={"pre_bid_time"}
        className={`w-100 mb-2`}
        inputId="pre_bid_time"
        onChange={(value) => setLocations(value)}
        isClearable={false}
        tabSelectsValue={false}
        styles={dropdownStyles}
        isMulti={true}
        value={locations}
        placeholder="Select Locations..."
      />
      {!!error && <span className="help-block is-error">Required.</span>}
    </div>
  );
};

CompanyLocationSelect.propTypes = {
  company: PropTypes.any,
};

export default CompanyLocationSelect;
