import React from "react";
import { dropdownStyles, useAuth } from "component-library";
import BidTodosForm from "../../../../_bidManagement/BidTodosForm";
import PropTypes from "prop-types";

const Sidebar = ({ renderSelectField, renderCheckbox, renderCheckboxInGroup, data, setFields, errors }) => {
  const { config } = useAuth();
  return (
    <>
      {renderSelectField({
        name: "type_id",
        label: "Project Type",
        options: config.project_types.map((type) => ({
          value: type.id,
          label: type.name,
        })),
        styles: dropdownStyles,
      })}
      {renderSelectField({
        name: "category_id",
        label: "Project Category",
        options: config.project_categories.map((cat) => ({
          value: cat.id,
          label: cat.name,
        })),
        styles: dropdownStyles,
      })}
      {renderSelectField({
        name: "bid_type_id",
        label: "Bid Type",
        options: !!config.bid_types
          ? config.bid_types.map((type) => ({
              value: type.id,
              label: type.name,
            }))
          : [],
        styles: dropdownStyles,
      })}
      <BidTodosForm
        data={data}
        renderCheckbox={renderCheckboxInGroup}
        setFields={setFields}
        errors={errors}
        singleColumn={true}
        horizontal
      />
      <h1 className="form-section-header mt-2">Submittal Requirements</h1>
      {renderCheckbox({
        name: "ais",
        label: "AIS",
      })}
    </>
  );
};

Sidebar.propTypes = {
  renderSelectField: PropTypes.func.isRequired,
  renderCheckbox: PropTypes.func.isRequired,
  renderCheckboxInGroup: PropTypes.func.isRequired,
  data: PropTypes.object,
  setFields: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default Sidebar;
