import { Joi } from "component-library";

const schema = {
  id: Joi.number(),
  project_id: Joi.number(),
  status_id: Joi.number().required().label("Status"),
  name: Joi.saneString().required().label("Title"),
  spec_section: Joi.saneString().label("Section of spec book"),
  description: Joi.saneString().allow(null).label("Description"),
  attachments: Joi.array().label("Attachments"),
  contacts: Joi.array().label("Contacts"),
  bic_id: Joi.number().required().label("BIC"),
  due_date: Joi.saneString().label("due_date"),
  reminder_date: Joi.saneString().label("Reminder Date"),
  priority_id: Joi.number().required().label("Priority"),
  plans_section: Joi.saneString().label("Plans Section"),
  note: Joi.saneString().label("Note"),
  project_owner_id: Joi.number(),
  source_id: Joi.number(),
  categories: Joi.any(),
  spec_location: Joi.any(),
  plans_location: Joi.any(),
  notes: Joi.any(),
  source: Joi.any(),
  requirements: Joi.array(),
  project_owner: Joi.any(),
  status: Joi.any(),
  bic: Joi.any(),
  priority: Joi.any(),
  type: Joi.any(),
  complete_requirements: Joi.any(),
  statusName: Joi.any(),
};

const due = new Date();
due.setDate(due.getDate() + 7)
const remind = new Date();
remind.setDate(remind.getDate() + 6)

const initialData = {
  project_id: undefined,
  status_id: 1,
  name: "",
  spec_section: "",
  description: "",
  attachments: [],
  contacts: [],
  bic_id: 1,
  due_date: due.toISOString(),
  reminder_date: remind.toISOString(),
  priority_id: 2,
  plans_section: "",
  note: "",
  notes: [],
  source_id: undefined,
  requirements: [],
  project_owner: undefined,
  project_owner_id: undefined
};

export { schema, initialData };
