import { useState, useEffect } from "react";
import { useApi, fieldLogsApi, useAuth } from "component-library";
import useResourceManagement from "../context/useResourceManagement";
import crewLogConfig from "../../config/crewLogConfig/crewLogConfig";
import { DateTime } from "luxon";
import convertDecimalHours from "../../utils/convertDecimalHours";

const useCrewLogs = ({ initialLoad, projectId, dateRange }) => {
  const { config } = useAuth();
  const [crewMembers, setCrewMembers] = useState();
  const [consoleData, setConsoleData] = useState();
  const [filteredCrewMembers, setFilteredCrewMembers] = useState();
  const [selectedForeman, setSelectedForeman] = useState();
  const [columnDefs, setColumnDefs] = useState(crewLogConfig);

  const { data, request, loading, error } = useApi(fieldLogsApi.getCrewTimesheets);
  const {
    projectsList,
    projectsLoading,
    selectedConsoleFilters,
    setCrewExpanded,
    q,
    setQ,
    selectedProject,
    setSelectedProject,
  } = useResourceManagement();
  const [foremenList, setForemenList] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const searchCrewMembers = (query) => {
    setQ(query);
  };

  const formatHeaderDate = (inputDate) => DateTime.fromFormat(inputDate, "yyyy-MM-dd").toFormat("LL/dd");

  const isValidDate = (dateString) => {
    const dateObject = new Date(dateString);
    return !isNaN(dateObject.getTime());
  };

  const reformatData = (inputData) => {
    const reformattedData = {};
    for (const key in inputData) {
      if (key === "totals_per_day") {
        const totalsPerDay = inputData[key];
        totalsPerDay.forEach((item) => {
          reformattedData[formatHeaderDate(item.date)] = item.total;
        });
      } else {
        reformattedData[key] = inputData[key];
      }
    }

    return reformattedData;
  };

  const updateRequest = (currentPage = 1) => {
    if (!initialLoad && !loading) {
      let requestParams = {
        project_id: selectedProject || projectId,
        foreman_id: selectedForeman,
        q: q,
        page: currentPage,
      };
      if (!!dateRange[1]) {
        requestParams = {
          ...requestParams,
          from_date: DateTime.fromJSDate(dateRange[0]).toFormat("yyyy-MM-dd"),
          to_date: DateTime.fromJSDate(dateRange[1]).toFormat("yyyy-MM-dd"),
        };
      }

      if (!!selectedConsoleFilters?.length) {
        selectedConsoleFilters.forEach((filter) => {
          requestParams[filter.toLowerCase()] = 1;
        });
      }
      request(requestParams);
    }
  };

  const columnDefHelper = (newData) => {
    const updatedConfig = [...crewLogConfig];
    const newDataArray = Object.entries(newData);
    const phoneIndex = updatedConfig.findIndex((column) => column.field === "phone");
    let insertIndex = phoneIndex + 1;

    for (const [key] of newDataArray) {
      if (isValidDate(key)) {
        updatedConfig.splice(insertIndex, 0, {
          headerName: key,
          field: key,
          width: 75,
          valueGetter: ({ data }) => (data[key] ? convertDecimalHours(data[key]) : "--"),
        });
        insertIndex++;
      }
    }

    return updatedConfig;
  };

  useEffect(() => {
    if (config?.foreman) {
      setForemenList(
        config.foreman.map((foreman) => {
          return {
            label: `${foreman?.first_name} ${foreman?.last_name}`,
            value: foreman.id,
          };
        })
      );
    }
  }, [config.foreman]);

  useEffect(() => {
    if (!!data?.time_sheets) {
      setCrewExpanded(false);
      setCrewMembers(
        data.time_sheets?.time_sheets?.length ? data.time_sheets.time_sheets.map((item) => reformatData(item)) : []
      );
      setConsoleData(data?.time_sheets?.console);
      setTotal(data?.time_sheets?.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (crewMembers?.length) {
      setColumnDefs(columnDefHelper(crewMembers[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, crewMembers]);

  useEffect(() => {
    setFilteredCrewMembers(crewMembers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crewMembers]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      updateRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, q, selectedForeman, selectedConsoleFilters]);

  useEffect(() => {
    updateRequest(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    let requestParams = {
      project_id: selectedProject || projectId,
      foreman_id: selectedForeman,
      q: q,
      page: 1,
    };
    if (!!selectedConsoleFilters?.length) {
      selectedConsoleFilters.forEach((filter) => {
        requestParams[filter.toLowerCase()] = 1;
      });
    }

    if (!dateRange[0] && !initialLoad && !loading) {
      request(requestParams);
      setPage(1);
    }

    if (!!dateRange[1] && !initialLoad && !loading) {
      requestParams = {
        ...requestParams,
        from_date: dateRange[0],
        to_date: dateRange[1],
        q: q,
        page: 1,
      };
      request(requestParams);
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, initialLoad]);

  return {
    projectsList,
    projectsLoading,
    searchCrewMembers,
    crewMembers,
    setCrewMembers,
    filteredCrewMembers,
    setFilteredCrewMembers,
    loading,
    selectedProject,
    setSelectedProject,
    q,
    setQ,
    foremenList,
    selectedForeman,
    setSelectedForeman,
    columnDefs,
    consoleData,
    error,
    page,
    setPage,
    total,
  };
};

export default useCrewLogs;
