import React from "react";
import { Link } from "react-router-dom";
import JobAlert from "./JobAlert";
import styles from "./styles.module.scss";
import useDashboard from "./useDashboard";

const JobAlerts = ({ fullpage }) => {
  const { jobs, alerts } = useDashboard();
  const jobIds = jobs?.map((job) => job.job_id);

  return (
    <section className={!!fullpage ? "col-lg-12" : "col-lg-4"}>
      {!fullpage && (
        <header className={styles["job-alerts-header"]}>
          <h2 className={styles.header}>Job Alerts</h2>
          <Link to="/dashboard/alerts" className="sm-med btn-link" aria-label="view all job alerts">
            View All
          </Link>
        </header>
      )}
      <div className={`${styles["job-alerts"]} ${!!fullpage && "mh-100 pt-0"}`}>
        {alerts?.map((alert) => {
          if (jobIds.indexOf(alert.job_id) > -1) {
            return (
              <JobAlert
                alert={alert}
                key={`${alert.job_id}-${alert.description}-${alert.level}-${alert.type}`}
                fullpage={fullpage}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    </section>
  );
};

export default JobAlerts;
