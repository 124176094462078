import React, { useState } from "react";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import useProject from "../../hooks/useProject";
import ProjectTeam from "./ProjectTeam";
import OtherContacts from "./OtherContacts";
import { Briefcase } from "react-feather";
import { HeaderButton } from "component-library";

const ProjectDirectory = () => {
  const { pageTitle, PageTag, loading, error, project } = useProject();
  const [addNewOpen, setAddNewOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState();

  const AddButton = () => (
    <HeaderButton
      onClick={() => setAddNewOpen(true)}
      ariaLabel="Add New Contact"
    />
  );

  return (
    <InteriorPage
      loading={loading}
      error={error}
      navTitle={pageTitle}
      pageTitle="Directory"
      pageTitleSecondaryContent={AddButton}
      navIcon={<Briefcase color="#fff" size={14} />}
      fillScreen
      NavContent={PageTag}
    >
      {!!project && (
        <div className="container">
          <ProjectTeam />
          <OtherContacts
            addNewOpen={addNewOpen}
            setAddNewOpen={setAddNewOpen}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
          />
        </div>
      )}
    </InteriorPage>
  );
};

export default ProjectDirectory;
