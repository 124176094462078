import React from "react";
import { Trash2 } from "react-feather";
import styles from "./styles.module.scss";
import MinutesInput from "../FieldLogs/FieldLog/MinutesInput";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { dropdownStylesWithWidthAndColor, useApi, timesheetApi, Loader } from "component-library";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";

const DriveTime = ({ entry, timesheet, updateTimesheet, setTimesheet, editing, projectsList }) => {
  const { request: deleteTravelEntry, loading, error } = useApi(timesheetApi.deleteTravelEntry);
  const dropdownColor = editing ? "#fff" : "#d3d3d3";

  const deleteDriveTime = async () => {
    try {
      const result = await deleteTravelEntry(entry.id);
      if (result?.success) {
        const newTimesheet = timesheet.travel_times.filter((travelTime) => travelTime.id !== entry.id);
        setTimesheet({
          ...timesheet,
          adjusted_minutes: parseFloat(timesheet.adjusted_minutes) + parseFloat(entry.travel_duration),
          travel_times: newTimesheet,
        });
      }
    } catch (error) {
      console.log("error");
    }
  };

  const updateFromLocation = (fromId) => {
    const newTravelTimes = timesheet.travel_times.map((travelTime) => {
      if (travelTime.id === entry.id) {
        return {
          to_project_id: travelTime.to_project_id,
          from_project_id: fromId,
          id: entry.id,
          travel_duration: travelTime.travel_duration,
        };
      }
      return travelTime;
    });
    updateTimesheet({
      ...timesheet,
      travel_times: newTravelTimes,
    });
  };

  const updateToLocation = (toId) => {
    const newTravelTimes = timesheet.travel_times.map((travelTime) => {
      if (travelTime.id === entry.id) {
        return {
          to_project_id: toId,
          from_project_id: travelTime.from_project_id,
          id: entry.id,
          travel_duration: travelTime.travel_duration,
        };
      }
      return travelTime;
    });
    updateTimesheet({
      ...timesheet,
      travel_times: newTravelTimes,
    });
  };

  const updateTravelDuration = (travelDuration) => {
    updateTimesheet({
      ...timesheet,
      travel_times: timesheet.travel_times.map((travelTime) =>
        travelTime.id === entry.id ? { ...entry, travel_duration: travelDuration } : travelTime
      ),
    });
  };

  return (
    <>
      <div className={`d-flex justify-content-between align-items-center py-2 row position-relative ${styles.task}`}>
        <Loader loading={!!loading} />
        <div className={`${styles.name} col`}>Drive time</div>
        <div className="col-md-3 d-flex align-items-center">
          <div>From:</div>
          <Select
            styles={dropdownStylesWithWidthAndColor(200, dropdownColor)}
            options={projectsList}
            name="project_id"
            id="projectId"
            className="w-100 d-inline ps-1"
            onChange={(e) => {
              updateFromLocation(e.value);
            }}
            isDisabled={!editing}
            value={projectsList.find((l) => l.value === entry.from_project_id)}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center">
          <div>To:</div>
          <Select
            styles={dropdownStylesWithWidthAndColor(200, dropdownColor)}
            options={projectsList}
            name="project_id"
            id="projectId"
            className="w-100 d-inline ps-1"
            onChange={(e) => {
              updateToLocation(e.value);
            }}
            isDisabled={!editing}
            value={projectsList.find((l) => l.value === entry.to_project_id)}
          />
        </div>

        <div className="d-flex justify-content-end align-items-center col-md-2">
          {editing && (
            <Button variant="transparent" size="sm" onClick={deleteDriveTime} className="p-0">
              <Trash2 color="#e70000" size={16} className="me-1" />
            </Button>
          )}
          <MinutesInput
            minutes={parseInt(entry.travel_duration, 10)}
            setMinutes={(minutes) => {
              updateTravelDuration(minutes);
            }}
            canEdit={editing}
          />
        </div>
      </div>
      {error && <Alert variant="danger">{error}</Alert>}
    </>
  );
};

DriveTime.propTypes = {
  entry: PropTypes.object.isRequired,
  timesheet: PropTypes.object.isRequired,
  setTimesheet: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  projectsList: PropTypes.array.isRequired,
};

export default DriveTime;
