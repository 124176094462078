import React from "react";
import { Pie, PieChart, Cell } from "recharts";
import styles from "./styles.module.scss";

const COLORS = ["#0071CE", "#F5A611", "#345D9D"];

const PerJobItemChart = ({ job: { spend }, lg, compact, colors = COLORS }) => {
  if (!spend) return null;
  let size = [100, 30, 50];
  if (!!lg) size = [150, 40, 70];
  if (!!compact) size = [86, 23, 40];

  const chartData = [{ name: "Spend", value: parseFloat(spend.spend) }];
  if (spend.remaining >= 0)
    chartData.push({ name: "Remaining", value: parseFloat(spend.remaining) });

  return (
    <div className={!!compact ? styles.compact : {}}>
      {!!chartData && (
        <figure
          className={`
            ${styles.chart}
            ${lg ? "" : "mt-1"}
            ${compact ? "mb-0" : ""}
          `}
        >
          <div className={`d-flex align-items-center`}>
            <div className="d-flex align-items-center justify-content-center position-relative">
              <PieChart width={size[0]} height={size[0]}>
                <Pie
                  data={chartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={size[1]}
                  outerRadius={size[2]}
                  z="true"
                  fill="#82ca9d"
                  labelLine={false}
                >
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
              </PieChart>
              <div className={`${styles.totalBase} ${lg ? styles.totalLg : {}}`}>
                <div className={`text-center ${styles.dollarsPerJobCenterLabel}`}>
                  {spend.remaining >= 0 ? "On Budget" : "Over Budget"}
                </div>
              </div>
            </div>
            <figcaption className={!compact ? "mt-3 ms-3" : "ms-3"}>
              <dl className="mb-0">
                <div>
                  <dd className={`${styles.legendDD} ${lg ? "" : styles.small}`} style={{ color: "#345D9D" }}>
                    {`$${new Intl.NumberFormat().format(spend.budgeted)}`}
                  </dd>
                  <dt className={styles.legendDT}>Budgeted Cost</dt>
                </div>
                <div>
                  <dd className={`${styles.legendDD} ${lg ? "" : styles.small}`} style={{ color: "#0071CE" }}>
                    {`$${new Intl.NumberFormat().format(spend.spend)}`}
                  </dd>
                  <dt className={styles.legendDT}>Actual Cost</dt>
                </div>
                {spend.remaining >= 0 ? (
                  <div>
                    <dd className={`${styles.legendDD} ${lg ? "" : styles.small}`} style={{ color: colors[1] }}>
                      {`$${new Intl.NumberFormat().format(spend.remaining)}`}
                    </dd>
                    <dt className={`${styles.legendDT} mb-0`}>Remaining</dt>
                  </div>
                ) : (
                  <div>
                    <dd className={`${styles.legendDD} ${lg ? "" : styles.small}`} style={{ color: "#ff0000" }}>
                      {`$${new Intl.NumberFormat().format(spend.remaining)}`}
                    </dd>
                    <dt className={`${styles.legendDT} mb-0`}>Deficit</dt>
                  </div>
                )}
              </dl>
            </figcaption>
          </div>
        </figure>
      )}
    </div>
  );
};

export default PerJobItemChart;
