/* global google */
import React, { useState, useEffect } from "react";
import { GoogleMap, DrawingManager, StandaloneSearchBox, InfoWindow, Marker } from "@react-google-maps/api";
import SiteGeoMeta from "./SiteGeoMeta";
import { Loader } from "component-library";
import ProjectGeoFenceModal from "./ProjectGeoFenceModal";
import useGeofence from "../../hooks/useGeofence";
import { ChevronRight, ChevronDown } from "react-feather";
import { Alert, Button } from "react-bootstrap";
import { mapStyles } from "../../utils/mapStyles";
import styles from "./styles.module.scss";
import MapSettingsCheck from "../MapSettingsCheck";
import useProject from "../../_projects/hooks/useProject";

const inputStyles = {
  boxSizing: `border-box`,
  border: `1px solid transparent`,
  width: `24rem`,
  height: `3.2rem`,
  padding: `0 1.2rem`,
  borderRadius: `0.3rem`,
  boxShadow: `0 0.2rem 0.6rem rgba(0, 0, 0, 0.3)`,
  fontSize: `1.4rem`,
  outline: `none`,
  textOverflow: `ellipses`,
  position: "absolute",
  left: "0.5rem",
  bottom: "0.5rem",
};

const containerStyle = {
  width: "100%",
  height: "40rem",
};

const center = {
  lat: 41.5874,
  lng: -93.5196,
};

const ProjectGeoFence = () => {
  const { project, refreshProject } = useProject();

  const {
    map,
    polygons,
    setPolygons,
    updateGeofenceLoading,
    updateGeofenceRequest,
    updateGeofenceError,
    updateGeofenceData,
    onLoadMap,
    selectedPolygon,
    setSelectedPolygon,
    isLoaded,
    polyOptions,
    onPolygonComplete,
    savePolygonComplete,
    newRegionName,
    setNewRegionName,
    setSaveModal,
    saveModal,
    onClickMap,
    focusPolygon,
    getSelectedCenter,
    onLoadDrawingManager,
    selectedMarker,
    setSelectedMarker,
    onCancelPolygon,
  } = useGeofence({ project, refreshProject });

  const [open, setOpen] = useState(true);
  const [searchBox, setSearchBox] = useState(null);
  const [placeMarkers, setPlaceMarkers] = useState();

  const onPlacesChanged = () => {
    setPlaceMarkers(searchBox.getPlaces());
  };

  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };

  useEffect(() => {
    setSelectedMarker();
    if (!!placeMarkers && !!placeMarkers.length) {
      let bounds = new google.maps.LatLngBounds();
      placeMarkers.forEach((place) => {
        const ll = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
        bounds.extend(ll);
      });
      map.fitBounds(bounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeMarkers]);

  const onClickMarker = (place) => {
    if (!!place && place.place_id === !!selectedMarker && selectedMarker.place_id) {
      setSelectedMarker();
    } else {
      setSelectedMarker(place);
    }
  };

  return (
    <>
      {!!project && isLoaded ? (
        <>
          <MapSettingsCheck>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onClick={onClickMap}
              onLoad={onLoadMap}
              options={{
                styles: mapStyles,
              }}
            >
              {!!updateGeofenceLoading && (
                <div
                  style={{
                    zIndex: 999,
                    backgroundColor: "rgba(255,255,255, 0.5)",
                  }}
                  className="d-flex justify-center align-center w-100 position-absolute"
                >
                  <Loader />
                </div>
              )}
              {!!placeMarkers &&
                placeMarkers.map((place) => (
                  <Marker
                    position={{
                      lat: parseFloat(place.geometry.location.lat()),
                      lng: parseFloat(place.geometry.location.lng()),
                    }}
                    id={place.place_id}
                    key={place.place_id}
                    onClick={() => onClickMarker(place)}
                  />
                ))}
              {!!selectedMarker && (
                <InfoWindow
                  options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                  onCloseClick={() => {
                    setSelectedMarker();
                  }}
                  position={{
                    lat: parseFloat(selectedMarker.geometry.location.lat()),
                    lng: parseFloat(selectedMarker.geometry.location.lng()),
                  }}
                >
                  <>
                    <div className="px-2 font-weight-bold mb-2">{selectedMarker.name}</div>
                    <div className="px-2 font-weight-bold">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={`https://www.google.com/maps/place/${selectedMarker.formatted_address.replace("", "+")}`}
                      >
                        View on Google Maps
                      </a>
                    </div>
                  </>
                </InfoWindow>
              )}
              {!!selectedPolygon && !!selectedPolygon.polygon && !!selectedPolygon.name && (
                <InfoWindow
                  onCloseClick={() => {
                    setSelectedPolygon({});
                  }}
                  position={getSelectedCenter()}
                >
                  <div className="px-2 font-weight-bold">{selectedPolygon.name}</div>
                </InfoWindow>
              )}
              <StandaloneSearchBox onLoad={onSBLoad} onPlacesChanged={onPlacesChanged}>
                <input type="text" placeholder="Search for places..." style={inputStyles} />
              </StandaloneSearchBox>
              <DrawingManager
                onLoad={onLoadDrawingManager}
                onPolygonComplete={onPolygonComplete}
                options={{
                  drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                  },
                  polygonOptions: {
                    ...polyOptions,
                    fillColor: "#0071ce",
                  },
                }}
              />
            </GoogleMap>
          </MapSettingsCheck>
          {!!updateGeofenceError && <Alert variant="danger">{updateGeofenceError}</Alert>}

          <hr />
          <div className="geofence-section">
            <div className="section-header d-flex flex-row justify-content-between py-3">
              <div className="page-title font-weight-bold">Geofence Zones</div>
              <Button
                className={`${styles.sectionToggleButton} d-flex flex-row align-items-center`}
                onClick={() => setOpen(!open)}
                aria-label={!!open ? "Hide Geofence section toggle" : "Show Geofence section toggle"}
              >
                {open ? <ChevronDown color="#C4C4C4" size={24} /> : <ChevronRight color="#C4C4C4" size={24} />}
              </Button>
            </div>
            {open && (
              <>
                {!!polygons && !!polygons.length && (
                  <div>
                    <div className="lg font-weight-bold mb-2 mt-4">Project Sites:</div>
                    <SiteGeoMeta
                      setPolygons={setPolygons}
                      project={project}
                      polygons={polygons}
                      request={updateGeofenceRequest}
                      loading={updateGeofenceLoading}
                      data={updateGeofenceData}
                      focusPolygon={focusPolygon}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <hr />
          {!!saveModal && (
            <ProjectGeoFenceModal
              saveModal={saveModal}
              setSaveModal={setSaveModal}
              setNewRegionName={setNewRegionName}
              newRegionName={newRegionName}
              savePolygonComplete={savePolygonComplete}
              polygons={polygons}
              onCancelPolygon={onCancelPolygon}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ProjectGeoFence;
