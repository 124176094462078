const total = {
  headerName: "Total",
  field: "total",
  pinned: "right",
  type: "numericColumn",
  width: 115,
  cellStyle: { justifyContent: "flex-end" },
};

export default total;
