import React from "react";
import styles from "./styles.module.scss";
import { Crosshair } from "react-feather";

const Name = ({ data }) => {
  return (
    <div className="d-flex align-items-center">
      <div className={`${styles.subtaskName}`}>{data?.description || data?.name}</div>
      {!!data?.leading_cost_code && (
        <div className="ms-2">
          <Crosshair color="#7B7B7B" size={16} />
        </div>
      )}
    </div>
  );
};

export default Name;
