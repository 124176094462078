import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { initialData, schema } from "../../../../schema/locate";
import { useFormControl, useApi, projectApi } from "component-library";
import useProject from "../../../../_projects/hooks/useProject";
import Relocate from "../Relocate";

const useAddLocate = (close, update, locate, userName, setLocates) => {
  const [error, setError] = useState(false);
  const { project } = useProject();
  const { request, loading } = useApi(projectApi.saveLocate);
  const [maxDate, setMaxDate] = useState();
  const [minDate, setMinDate] = useState();

  const onSubmit = async (formData, setLoading) => {
    setError(false);
    try {
      const { success, responseData } = await request({
        id: locate ? locate.id : null,
        description: formData.description,
        number: formData.number || null,
        status: locate ? formData.status : "active",
        type: formData.type,
        sb: formData.sb,
        ds: formData.ds,
        sb_required: formData.sb_required || 0,
        ds_required: formData.ds_required || 0,
        active_date: formData.active_date,
        expiration: formData.expiration,
        project_id: project.id,
        parent_id: !!locate ? locate.parent_id : 0,
        user: userName,
      });

      if (success) {
        update();
        close();
      } else {
        throw responseData.error;
      }
    } catch (error) {
      setError(error);
      return Promise.reject(error);
    }
    setLoading(false);
  };

  const {
    handleSubmit,
    renderSubmitButton,
    renderTextField,
    renderSelectField,
    renderCheckbox,
    data: formData,
  } = useFormControl({
    schema,
    onSubmit,
    scrollToTop: false,
    initialData: locate || initialData,
  });

  useEffect(() => {
    if (formData.expiration) {
      let date = new Date(formData.expiration);
      setMaxDate(date.setDate(date.getDate() - 1));
    } else {
      setMaxDate();
    }
  }, [formData.expiration]);

  useEffect(() => {
    if (formData.active_date) {
      let date = new Date(formData.active_date);
      setMinDate(date.setDate(date.getDate() + 1));
    } else {
      setMinDate();
    }
  }, [formData.active_date]);

  const fields = (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="row">
        <div className="col-md-8">
          {!!!locate &&
            renderTextField({
              name: "number",
              label: "Locate #",
            })}
          {renderTextField({
            name: "description",
            label: "Locate Description",
          })}

          {!!locate && (
            <>
              <div className="d-flex">
                <div className="flex-grow-1">
                  {locate.status.toLowerCase() === "requested" ? (
                    <>
                      <div>Status</div>
                      <div className="text-capitalize font-italiics">{locate.status}</div>
                    </>
                  ) : (
                    <>
                      {renderSelectField({
                        name: "status",
                        label: "Status",
                        options: [
                          { value: "active", label: "Active" },
                          { value: "expired", label: "Expired" },
                          { value: "relocated", label: "Relocated" },
                          { value: "canceled", label: "Canceled" },
                        ],
                      })}
                    </>
                  )}
                </div>
                <div className="d-flex pt-2 align-items-center">
                  <Relocate locate={locate} label={"Relocate"} setLocates={setLocates} />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="col-md-4">
          {renderTextField({
            name: "active_date",
            label: "Active Date",
            type: "date",
            maxDate: maxDate,
          })}
          {renderTextField({
            name: "expiration",
            label: "Expiration Date",
            type: "date",
            minDate: minDate,
          })}
        </div>

        <div className="col-md-12">
          {renderSelectField({
            name: "type",
            label: "Type",
            options: [
              { value: "New Locate", label: "New Locate" },
              { value: "Dig in Ticket", label: "Dig in Ticket" },
              {
                value: "Joint Meet Requirement",
                label: "Joint Meet Requirement",
              },
              { value: "Non Response", label: "Non Response" },
              { value: "Utility Locate", label: "Utility Locate" },
            ],
          })}
          {renderCheckbox({
            name: "sb",
            label: "Standby",
          })}
          {!!formData &&
            !!formData.sb &&
            renderTextField({
              name: "sb_required",
              label: "Standby Required by:",
              type: "text",
            })}
          {renderCheckbox({
            name: "ds",
            label: "Dig Schedule",
          })}
          {!!formData &&
            !!formData.ds &&
            renderTextField({
              name: "ds_required",
              label: "Dig Schedule Required by:",
              type: "text",
            })}
        </div>
      </div>
    </>
  );

  return {
    fields,
    handleSubmit,
    renderSubmitButton,
    loading,
  };
};

export default useAddLocate;
