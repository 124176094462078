import { useEffect, useReducer } from "react";
import { weeklyReportsApi, useApi } from "component-library";
import { useParams } from "react-router-dom";
import { reducer, createInitialState } from "./useWeeklyReportEditReducer";

const useWeeklyReportsEdit = ({ handleRedirect }) => {
  const [state, dispatch] = useReducer(reducer, createInitialState);
  const { report_id } = useParams();

  const {
    request: reportRequest,
    data: reportData,
    loading: reportLoading,
    error: reportError,
  } = useApi(weeklyReportsApi.getWeeklyReport);

  const { request: weatherRequest, data: weatherData } = useApi(weeklyReportsApi.getWeeklyReportWeather);
  const { request: getWeeklyReportNotesRequest, data: weeklyReportNoteData } = useApi(
    weeklyReportsApi.getWeeklyReportNotes
  );

  const {
    request: updateWeeklyReportNotesRequest,
    data: updateWeeklyNotesData,
    error: updateWeeklyNotesError,
    loading: updateWeeklyNotesLoading,
  } = useApi(weeklyReportsApi.updateWeeklyReportNotes);

  const {
    request: saveReportRowsRequest,
    data: saveReportData,
    loading: saveLoading,
    error: saveError,
  } = useApi(weeklyReportsApi.updateWeeklyReportRows);

  const {
    request: updateReportRequest,
    data: updateReportData,
    loading: updateStatusLoading,
    error: updateStatusError,
  } = useApi(weeklyReportsApi.updateWeeklyReport);

  const updateDate = (reportDate, field, value) => {
    let updatedDate = {
      ...state.report,
      rows: state?.report.rows?.map((day) => {
        if (day.id === reportDate.id) {
          return {
            ...reportDate,
            [field]: value,
            working_day_value:
              field === "working_day_flag" && value === 0
                ? 0
                : field === "working_day_flag" && value === 1
                ? 1
                : field === "working_day_value"
                ? value
                : day.working_day_value,
          };
        } else {
          return day;
        }
      }),
    };

    dispatch({
      type: "update_report",
      report: updatedDate,
    });
  };

  const handleSaveReport = () => {
    let notes = !!state?.notes?.length ? [...state.notes] : [];
    if (!!state?.removed_notes?.length) {
      notes = [...notes, ...state.removed_notes];
    }

    let updatedRows = state?.report?.rows.map((day) => {
      return {
        ...day,
        subcontractors: day?.subcontractors?.length
          ? day.subcontractors.map((subcon) => (!!subcon?.company ? subcon.company.id : subcon.location_id))
          : [],
        photo_ids: day?.files?.length
          ? day.files.map((photo) => {
              return photo?.file ? photo.file.id : photo.id;
            })
          : [],
      };
    });

    updatedRows = cleanupRowData(updatedRows);
    updateWeeklyReportNotesRequest({ notes: !!notes?.length ? notes : [] });
    saveReportRowsRequest({ rows: updatedRows });
  };

  const cleanupRowData = (updatedRows) => {
    const cleanedRows = updatedRows?.map((day) => {
      delete day.files;
      delete day.work_day;
      delete day.contractor;
      return day;
    });

    return cleanedRows;
  };

  const updateReportStatus = async (updatedReport) => {
    const response = await updateReportRequest(updatedReport);
    try {
      if (!response.success) {
        throw new Error("Status update error");
      } else {
        handleSaveReport();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleUpdateNotes = (notes) => {
    dispatch({ type: "update_notes", notes: notes, noteIds: notes.map((note) => note.id) });
  };

  const handleRemoveNote = (notes, removedNote) => {
    dispatch({ type: "remove_note", notes: notes, removedNote: removedNote });
  };

  const getNewPhotosForDate = (day, existingPhotos, newPhotos) => {
    let updatedPhotosList = [];
    const existingPhotoIds = existingPhotos.map((photo) => photo.id);
    newPhotos.forEach((photo) => {
      if (day.work_day.date === photo.note_date) {
        if (!existingPhotoIds.includes(photo.id)) {
          updatedPhotosList.push(photo);
        }
      }
    });
    return updatedPhotosList;
  };

  const getPhotosFromNotes = () => {
    let updatedPhotosList = [];
    state?.notes.forEach((note) => {
      note.files.forEach((file) => {
        updatedPhotosList.push({ ...file.file, note_date: note?.note_date });
      });
    });
    return updatedPhotosList;
  };

  const handleUnsetMessage = () => {
    dispatch({
      type: "set_message",
      message: "",
    });
  };

  useEffect(() => {
    if (reportData?.report) {
      dispatch({
        type: "set_intitial_report",
        report: {
          ...reportData.report,
          rows: reportData.report.rows.map((day) => {
            return {
              ...day,
              files: day.files.map((file) => file.file),
            };
          }),
        },
      });
    }
  }, [reportData?.report]);

  useEffect(() => {
    if (saveReportData?.row) {
      dispatch({
        type: "update_report",
        report: {
          ...state?.report,
          rows: saveReportData.row,
        },
        message: "Weekly report successfully updated",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveReportData?.row]);

  useEffect(() => {
    if (updateWeeklyNotesData?.notes) {
      dispatch({
        type: "update_notes",
        notes: updateWeeklyNotesData.notes.filter((note) => !!note.include_on_report),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateWeeklyNotesData?.notes]);

  useEffect(() => {
    if (!!weatherData?.weather?.length) {
      dispatch({ type: "update_weather", weather: weatherData.weather });
    }
  }, [weatherData?.weather]);

  useEffect(() => {
    if (weeklyReportNoteData?.notes) {
      dispatch({ type: "set_initial_notes", notes: weeklyReportNoteData.notes });
    }
  }, [weeklyReportNoteData?.notes]);

  useEffect(() => {
    if (report_id) {
      reportRequest(report_id);
      getWeeklyReportNotesRequest(report_id);
      weatherRequest(report_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report_id]);

  useEffect(() => {
    if (!!state.processNoteFiles) {
      const notesPhotosList = getPhotosFromNotes();

      const updatedReport = {
        ...state.report,
        rows: state.report.rows.map((day) => {
          const newPhotos = getNewPhotosForDate(day, day.files, notesPhotosList);
          return {
            ...day,
            files: [...day?.files, ...newPhotos],
          };
        }),
      };

      dispatch({
        type: "update_report",
        report: updatedReport,
        message: "Weekly report successfully updated",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.processNoteFiles]);

  useEffect(() => {
    const apiError = saveError || updateStatusError;
    dispatch({
      type: "update_error",
      error: apiError,
    });
  }, [saveError, updateStatusError]);

  useEffect(() => {
    const apiLoading = saveLoading || updateWeeklyNotesLoading || updateStatusLoading;
    dispatch({
      type: "update_loading",
      loading: apiLoading,
    });
  }, [saveLoading, updateWeeklyNotesLoading, updateStatusLoading]);

  useEffect(() => {
    if (
      !!saveReportData?.success &&
      !!updateWeeklyNotesData?.success &&
      !!updateReportData?.success &&
      !saveLoading &&
      !updateStatusLoading &&
      !updateWeeklyNotesLoading
    ) {
      handleRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveReportData, updateWeeklyNotesData, updateReportData]);

  return {
    report: state.report,
    reportNotes: state.notes,
    weather: state.weather,
    loading: state.loading,
    error: state.error,
    message: state.message,
    handleUnsetMessage,
    reportLoading,
    reportError,
    updateDate,
    handleSaveReport,
    updateReportStatus,
    handleUpdateNotes,
    handleRemoveNote,
    updateWeeklyNotesError,
  };
};

export default useWeeklyReportsEdit;
