import React from "react";
import InteriorPage from "../pageLayout/InteriorPage";

const AccountSettings = () => {
  return (
    <InteriorPage
      navTitle="Account Settings"
    >
      <p>Account Settings</p>
    </InteriorPage>
  );
};

export default AccountSettings;
