import dateTimeComparator from "../../../utils/dateTimeComparator";

const completion_date = {
  headerName: "Completion Date",
  field: "completion_date",
  headerClass: "ag-center-aligned-header",
  wrapHeaderText: true,
  width: 112,
  cellStyle: { justifyContent: "center" },
  suppressMenu: true,
  comparator: dateTimeComparator,
};

export default completion_date;
