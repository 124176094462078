import React from "react";
import { useApi, reportsApi, useFormControl } from "component-library";
import { schema, initialData } from "../schema/crossJobSummary";
import ToFromFields from "../ToFromFields";
import useForms from "./useForms";

const CrossJobReport = ({ setLoading, setError, setData }) => {
  const {
    request: runReportRequest,
    data: runReportData,
    loading: runReportLoading,
    error: runReportError,
  } = useApi(reportsApi.getCrossJobReport);

  const { onSubmit } = useForms({
    reportType: "cross_job_report",
    runReportRequest,
    runReportData,
    runReportLoading,
    runReportError,
    setLoading,
    setError,
    setData,
  });

  const { handleSubmit, renderTextField, renderSubmitButton, data, renderCheckbox } = useFormControl({
    schema,
    initialData,
    onSubmit,
  });
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <ToFromFields renderTextField={renderTextField} data={data} />
        </div>
        <div className="row">
          {renderCheckbox({
            name: "include_inactive",
            isToggle: true,
            label: "Include Inactive",
          })}
        </div>
        <div className="text-end">{renderSubmitButton("Run Report")}</div>
      </form>
    </>
  );
};

export default CrossJobReport;
