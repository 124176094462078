import React, { useState, useEffect } from "react";
import InteriorPage from "../../components/pageLayout/InteriorPage";
import { ReactComponent as Truck } from "../../assets/fleet.svg";
import { Alert } from "react-bootstrap";
import useFleetManagementIframes from "../contextIframes/useFleetManagementIframes";
import styles from "./styles.module.scss";

const IframePage = ({ pageTitle, srcName, Submenu }) => {
  const [url, setUrl] = useState();
  const { authKey, userBsuid, usersError, usersLoading, authKeyError, authKeyLoading, companyCode } =
    useFleetManagementIframes();
  useEffect(() => {
    const newUrl = `${process.env.REACT_APP_URL}detb/bbauth?k=${authKey}&cc=${companyCode}&id=${userBsuid}&p=${srcName}`;
    setUrl(newUrl);
  }, [authKey, companyCode, userBsuid, srcName]);

  return (
    <>
      {!!Submenu ? (
        <InteriorPage
          pageTitle={pageTitle}
          navTitle="Fleet Management"
          loading={usersLoading || authKeyLoading}
          error={usersError || authKeyError}
          navIcon={<Truck color="#fff" width="16" />}
          pageSubtitlePrimaryContent={() => !!Submenu && <Submenu />}
          fillScreen
        >
          {!userBsuid && <Alert variant="danger">User BSUID is missing, please follow up with a system admin.</Alert>}
          {!authKey && <Alert variant="danger">Authorization key not found or not recognized</Alert>}
          {!companyCode && <Alert variant="danger">Company code not found</Alert>}
          {!!userBsuid && authKey && companyCode ? (
            <div className="w-100 h-100">
              <iframe className={styles.frame} title="dashboard" src={url} />
            </div>
          ) : (
            <>
              {!usersLoading && !authKeyLoading && <div>Cannot authorize page access without missing credentials.</div>}
            </>
          )}
        </InteriorPage>
      ) : (
        <InteriorPage
          pageTitle={pageTitle}
          navTitle="Fleet Management"
          loading={usersLoading || authKeyLoading}
          error={usersError || authKeyError}
          navIcon={<Truck color="#fff" width="16" />}
          fillScreen
        >
          {!userBsuid && <Alert variant="danger">User BSUID is missing, please follow up with a system admin.</Alert>}
          {!authKey && <Alert variant="danger">Authorization key not found or not recognized</Alert>}
          {!companyCode && <Alert variant="danger">Company code not found</Alert>}
          {!!userBsuid && authKey && companyCode ? (
            <div className="w-100 h-100">
              <iframe className={styles.frame} title="dashboard" src={url} />
            </div>
          ) : (
            <>
              {!usersLoading && !authKeyLoading && <div>Cannot authorize page access without missing credentials.</div>}
            </>
          )}
        </InteriorPage>
      )}
    </>
  );
};

export default IframePage;
