import React from "react";
import styles from "./styles.module.scss";
import { Calendar } from "react-feather";
import { DateTime } from "luxon";

const SubHeader = ({ data, loading }) => {
  return (
    <>
      <div className={styles.subheader}>
        <h2 className={`${styles.subheadTitle} xl mb-0`}>Inspection Reports</h2>
        {!loading && (
          <div className={styles.subheaderDate}>
            <Calendar size={18} color="#0071ce" className="me-1" />
            {DateTime.fromSQL(data?.data?.created_at).toFormat("LLLL dd, yyyy")}
          </div>
        )}
      </div>
    </>
  );
};

export default SubHeader;
