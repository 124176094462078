import React from "react";
import PhotosList from "../../../../../components/PhotoLibrary/ListView/Lists/PhotosList";
import { ReactComponent as Unpinned } from "../../../../../assets/photoUnpinned.svg";
import { Grid } from "react-feather";
import styles from "../styles.module.scss";

const AlbumListView = ({
  pinnedPhotos,
  photosList,
  loading,
  params,
  selectedItems,
  setSelectedItems,
  selectMode,
  handlePin,
  handleDelete,
}) => {
  const GridIcon = () => <Grid color="#4F4F4F" size={"1.6rem"} className="me-2" />;
  const PinIcon = () => <Unpinned className={styles.pinIconList} />;

  return (
    <>
      <div className={`${styles.listContainer} mt-2`}>
        <PhotosList
          photosList={pinnedPhotos}
          title="Pinned Photos"
          TitleIcon={PinIcon}
          handlePin={handlePin}
          handleDelete={handleDelete}
          loading={loading}
          params={params}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectMode={selectMode}
        />
      </div>
      <div className={`${styles.listContainer} mt-5`}>
        <PhotosList
          photosList={photosList}
          title="All Photos"
          TitleIcon={GridIcon}
          handlePin={handlePin}
          handleDelete={handleDelete}
          loading={loading}
          params={params}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectMode={selectMode}
        />
      </div>
    </>
  );
};

export default AlbumListView;
