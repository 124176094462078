import PWPillGrid from "../../../components/PWPillGrid";
import PWPill from "../../../components/PWPill";

const certified_payroll = {
  headerName: "PW",
  field: "certified_payroll",
  width: 72,
  headerComponent: PWPill,
  cellRenderer: PWPillGrid,
};

export default certified_payroll;
