import React, { useEffect, useState } from "react";
import { dropdownStyles } from "component-library";
import timezones from "../../../../../../utils/timezones";

const TimeZone = ({ renderSelectField }) => {
  const [timezoneOptions, setTimezoneOptions] = useState([]);

  useEffect(() => {
    if (timezones) {
      setTimezoneOptions(
        timezones.map((zone) => {
          return { label: zone, value: zone };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezones]);

  return (
    <div className="row">
      <div className="col-6">
        {renderSelectField({
          name: "time_zone",
          label: "Time Zone",
          options: timezoneOptions,
          styles: dropdownStyles,
          isSearchable: true,
        })}
      </div>
    </div>
  );
};

export default TimeZone;
