import React, { useEffect, useState } from 'react';

/**
 * A custom hook that renders a component with specified parameters when the given dependencies change.
 *
 * @param {React.ComponentType} Component - component to be rendered
 * @param {Array} dependencies - array of dependencies to trigger a re-render
 * @param {Object} params - parameters to be passed into rendered component
 * @returns {Object} An object containing the rendered component to be passed into a prop for another component such as an Interior Page
 */

const usePassedComponent = (Component, dependencies = [], params) => {
  const [BuiltComponent, setBuiltComponent] = useState(() => null);

  useEffect(() => {
    setBuiltComponent(() => (
      () => <Component {...params} />
    ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return {BuiltComponent};
}

export default usePassedComponent;