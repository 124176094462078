import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import useAddCompany from "./hooks/useAddCompany";
import { Navigate } from "react-router-dom";

const AddCompany = ({
  company,
  setAddingCompany,
  setCompany,
  singleLocation = false,
  isSearch = false,
}) => {
  const { handleSubmit, fields, renderSubmitButton, redirect } = useAddCompany({
    setCompany,
    initialCompany: company,
    setAddingCompany,
    singleLocation,
    isSearch,
  });

  return (
    <>
      {!!redirect && <Navigate to={redirect} />}
      <Modal dialogClassName="modal-lg" centered animation={false} show={true} onHide={() => setAddingCompany(false)}>
        <Modal.Header closeButton closeLabel={"Close Add New Company Modal"}>
          <h5 className="mb-0 med">{!!company ? `Edit Company ${company && company.name}` : "Add Company"}</h5>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>{fields}</Modal.Body>
          <Modal.Footer>
            <Button className="mt-2" onClick={() => setAddingCompany(false)} aria-label="Cancel adding new company">
              Cancel
            </Button>
            {renderSubmitButton(!!company ? "Save Edits" : "Add Company")}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

AddCompany.propTypes = {
  company: PropTypes.object,
  setAddingCompany: PropTypes.func.isRequired,
  setCompany: PropTypes.func.isRequired,
};

export default AddCompany;
