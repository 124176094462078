import React from "react";
import { Briefcase } from "react-feather";
import { FilterSearch, SortableTable } from "component-library";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import useProject from "../../hooks/useProject";
import BackToPOsLink from "./BackToPOsLink";
import quoteListConfig from "./quoteListConfig";
import useQuotesForPO from "./useQuotesForPO";

const AddPurchaseOrder = () => {
  const { pageTitle, PageTag } = useProject();
  const { filterQuotes, filteredQuotes, loading, error } = useQuotesForPO();

  return (
    <InteriorPage
      navTitle={pageTitle}
      pageTitle="New Purchase Order"
      navIcon={<Briefcase color="#fff" size={14} />}
      error={error}
      loading={loading}
      NavContent={PageTag}
    >
      <BackToPOsLink />
      <div className="mb-3 mt-4">
        <h4 className="med">Search for a quote to create a new purchase order</h4>
        <FilterSearch
          searchFunction={filterQuotes}
          inline
          style={{ minWidth: 300 }}
        />
      </div>
      <SortableTable
        items={filteredQuotes.map((quote) => ({
          ...quote,
          supplier_name: !!quote.supplier && quote.supplier.name,
        }))}
        config={quoteListConfig}
        fallbackSort="supplier_name"
        sticky
      />
    </InteriorPage>
  );
};

export default AddPurchaseOrder;
