import React from "react";
import useAddNote from "./useAddNote";
import PropTypes from "prop-types";

const AddNoteForm = ({ submittal = {} }) => {
  const { handleSubmit, fields, renderSubmitButton, loading } = useAddNote({ submittal });

  return (
    <form onSubmit={handleSubmit}>
      {fields}
      {renderSubmitButton(loading ? "Loading..." : "Save Changes")}
    </form>
  );
};

AddNoteForm.propTypes = {
  submittal: PropTypes.object,
  updateListItem: PropTypes.func.isRequired,
};

export default AddNoteForm;
