import DocName from "../columnRenders/DocName";

const file_name = {
  headerName: "Name",
  name: "file_name",
  flex: 2,
  cellRenderer: DocName,
};

export default file_name;
