import React, { useEffect } from "react";
import useProject from "../../../hooks/useProject";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import { Briefcase, Grid, List, Monitor } from "react-feather";
import usePhotoLibrary from "../context/usePhotoLibrary";
import { DateRangeSelector, FilterSearch, ConfirmModal } from "component-library";
import { Alert } from "react-bootstrap";
import useLibraryView from "./useLibraryView";
import styles from "./styles.module.scss";
import BatchDeleteModal from "../Modals/BatchDeleteModal";
import CreateAlbumModal from "../Modals/CreateAlbumModal";
import UploadPhotoModal from "../Modals/UploadPhotoModal";
import AddPhotoToAlbumModal from "../Modals/AddPhotoToAlbumModal/AddPhotoToAlbumModal";
import HeaderPortal from "../../../../components/HeaderPortal";
import ViewSwitcher from "../../../../components/PhotoLibrary/ViewSwitcher";
import GridView from "../../../../components/PhotoLibrary/GridView";
import ListView from "../../../../components/PhotoLibrary/ListView";
import GalleryView from "../../../../components/PhotoLibrary/GalleryView";
import { ReactComponent as Pinned } from "../../../../assets/photoPinned.svg";
import { ReactComponent as Unpinned } from "../../../../assets/photoUnpinned.svg";
import UserToggler from "../../../../components/PhotoLibrary/UserToggler";
import getPinUnpinMessage from "./getPinUnpinMessage";

const PhotoLibrary = () => {
  const { project, pageTitle, loading, error } = useProject();
  const {
    dateRange,
    setDateRange,
    handleSearch,
    message,
    setMessage,
    setPhotosList,
    photosList,
    albumsList,
    refreshLists,
    handlePin,
    handleDelete,
    photosLoading,
    photosError,
    albumsError,
    albumsLoading,
    q,
    onlyMyPhotos,
    setOnlyMyPhotos,
  } = usePhotoLibrary();

  const {
    activeView,
    setActiveView,
    PhotoActionButtons,
    showCreateAlbumModal,
    setShowCreateAlbumModal,
    showUploadFileModal,
    setShowUploadFileModal,
    selectMode,
    setSelectMode,
    SelectActionButtons,
    ItemSelection,
    setSelectedItems,
    selectedItems,
    showPinUnpinModal,
    setShowPinUnpinModal,
    pinUnpinItems,
    photoBatchPinUnpinLoading,
    albumBatchPinUnpinLoading,
    pinUnpinError,
    setPinUnpinError,
    showDeleteModal,
    setShowDeleteModal,
    deleteItems,
    photoBatchDeleteLoading,
    albumBatchDeleteLoading,
    deleteError,
    setDeleteError,
    showAddToAlbumModal,
    setShowAddToAlbumModal,
    isPinAction,
  } = useLibraryView({ refreshLists });

  useEffect(() => {
    if (!photosLoading && !albumsLoading) {
      // on returning to the page, refresh the lists
      refreshLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showPinUnpinModal) {
      setPinUnpinError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPinUnpinModal]);

  useEffect(() => {
    if (!showDeleteModal) {
      setDeleteError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDeleteModal]);

  const viewOptions = [
    { value: "grid-view", icon: Grid },
    { value: "list-view", icon: List },
    { value: "gallery-view", icon: Monitor },
  ];
  const isGalleryView = activeView === "gallery-view";

  return (
    <InteriorPage
      loading={loading}
      error={error}
      navTitle={pageTitle}
      pageTitle="Photo Library"
      flexContent={isGalleryView}
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitlePrimaryContent={!!selectMode && ItemSelection}
    >
      <div className="container-fluid">
        <div className={`row ${isGalleryView ? "h-100 d-flex flex-column align-items-start" : ""}`}>
          {!!message && (
            <Alert variant="success" onClick={() => setMessage()} dismissible>
              {message}
            </Alert>
          )}
          <header className={styles.header}>
            <DateRangeSelector
              dateRange={dateRange}
              setDateRange={setDateRange}
              maxDate={Date.now()}
              disabled={albumsLoading || photosLoading}
            />
            <div className="d-flex align-items-center">
              <UserToggler
                onlyMyPhotos={onlyMyPhotos}
                setOnlyMyPhotos={setOnlyMyPhotos}
                disabled={photosLoading || albumsLoading}
              />
              <FilterSearch inline searchFunction={handleSearch} disabled={albumsLoading || photosLoading} />
              <ViewSwitcher
                activeColor={"#FFF"}
                deactivateColor={"#D3D3D3"}
                options={viewOptions}
                activeView={activeView}
                setActiveView={setActiveView}
              />
            </div>
          </header>
          {activeView === "grid-view" && (
            <GridView
              project={project}
              photosList={photosList}
              photosError={photosError}
              dateRange={dateRange}
              albumsList={albumsList}
              albumsError={albumsError}
              handlePin={handlePin}
              handleDelete={handleDelete}
              loading={photosLoading || albumsLoading}
              selectMode={selectMode}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          )}
          {activeView === "list-view" && (
            <ListView
              project={project}
              photosList={photosList}
              albumsList={albumsList}
              dateRange={dateRange}
              q={q}
              handlePin={handlePin}
              handleDelete={handleDelete}
              loading={photosLoading || albumsLoading}
              selectMode={selectMode}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          )}
          {isGalleryView && (
            <GalleryView
              project={project}
              photosList={photosList}
              photosError={photosError}
              photosLoading={photosLoading}
              dateRange={dateRange}
              albumsList={albumsList}
              albumsError={albumsError}
              albumsLoading={albumsLoading}
              handlePin={handlePin}
              refreshLists={refreshLists}
              selectMode={selectMode}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          )}
        </div>
        <CreateAlbumModal showModal={showCreateAlbumModal} setShowModal={setShowCreateAlbumModal} />
        <UploadPhotoModal
          project={project}
          showModal={showUploadFileModal}
          setShowModal={setShowUploadFileModal}
          photosList={photosList}
          setPhotosList={setPhotosList}
          setMessage={setMessage}
        />
        <ConfirmModal
          showConfirmation={showPinUnpinModal}
          setShowConfirmation={setShowPinUnpinModal}
          confirmFunction={() => {
            pinUnpinItems(isPinAction, selectedItems);
          }}
          Icon={() => (isPinAction ? <Unpinned className={styles.pinIcon} /> : <Pinned className={styles.pinIcon} />)}
          warningColor={"blue"}
          cancelColor={"blue"}
          confirmColor={"tertiary"}
          loading={photoBatchPinUnpinLoading || albumBatchPinUnpinLoading}
          error={pinUnpinError}
          name={isPinAction ? "Pin Item(s)" : "Unpin Item(s)"}
          message={getPinUnpinMessage(selectedItems, isPinAction)}
          buttonText={isPinAction ? "Pin Item(s)" : "Unpin Item(s)"}
        />
        <BatchDeleteModal
          isAlbumView={false}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          deleteItems={deleteItems}
          deleteError={deleteError}
          photoBatchDeleteLoading={photoBatchDeleteLoading}
          albumBatchDeleteLoading={albumBatchDeleteLoading}
          selectedItems={selectedItems}
        />
        <AddPhotoToAlbumModal
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setSelectMode={setSelectMode}
          showModal={showAddToAlbumModal}
          setShowModal={setShowAddToAlbumModal}
        />
        <HeaderPortal>{selectMode ? <SelectActionButtons /> : <PhotoActionButtons />}</HeaderPortal>
      </div>
    </InteriorPage>
  );
};

export default PhotoLibrary;
