import React from "react";
import useProvideFieldLogSelection from "./useProvideFieldLogSelection";
import fieldLogSelectionContext from "./fieldLogSelectionContext";

function ProvideFieldLogSelection({ children }) {
  const fieldLogSelection = useProvideFieldLogSelection();
  return (
    <fieldLogSelectionContext.Provider value={fieldLogSelection}>
      {children}
    </fieldLogSelectionContext.Provider>
  );
}

export default ProvideFieldLogSelection;
