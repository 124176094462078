import React from "react";
import useProvideProject from "./../_projects/hooks/useProvideProject";
import projectContext from "./../context/projectContext";

function ProvideProject({ children }) {
  const project = useProvideProject();
  return <projectContext.Provider value={project}>{children}</projectContext.Provider>;
}

export default ProvideProject;
