import Name from "../columnRenders/Name";

const last_name = {
  headerName: "Name",
  field: "last_name",
  minWidth: 225,
  flex: 1,
  cellRenderer: Name,
};

export default last_name;
