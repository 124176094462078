import React from "react";
import styles from "./styles.module.scss";
import { ArrowDown, ArrowUp } from "react-feather";
import PropTypes from "prop-types";
import { getWeatherIcon } from "component-library";

const DateRowWeather = ({ conditions }) => {
  if (!conditions?.length) {
    return (
      <>
        <div>No weather data available.</div>
      </>
    );
  }

  const highTemp = conditions?.reduce((acc, curr) => {
    if (acc === null || curr.temperature > acc) {
      return parseInt(curr.temperature);
    } else {
      return acc;
    }
  }, null);

  const lowTemp = conditions?.reduce((acc, curr) => {
    if (acc === null || curr.temperature < acc) {
      return parseInt(curr.temperature);
    } else {
      return acc;
    }
  }, null);

  const precipitation = conditions?.reduce((acc, curr) => {
    return acc + parseFloat(curr.rain_accumulation) + parseFloat(curr.snow_accumulation);
  }, 0);

  return (
    <>
      {!!conditions ? (
        <div className="d-flex align-items-center me-5">
          <span className={`${styles.icon} me-4`}>{getWeatherIcon(conditions[0]?.weather_code, 28)}</span>
          <div className={styles.tempsBlock}>
            <div className={styles.conditions}>
              <div className="text-center">
                <span className={styles.value}>{highTemp}°</span>
                <ArrowUp color="#A7A7A7" size={12} />
                <ArrowDown color="#A7A7A7" size={12} />
                <span className={styles.value}>{lowTemp}°</span>
              </div>
              <div className={styles.description}>{conditions[0]?.current_conditions}</div>
            </div>
            <div className={styles.precip}>
              <div className={styles.value}>{parseFloat(precipitation).toFixed(2)}"</div>
              <div className={styles.description}>Precipitation</div>
            </div>
          </div>
        </div>
      ) : (
        <div>No weather conditions found.</div>
      )}
    </>
  );
};

DateRowWeather.propTypes = {
  conditions: PropTypes.object,
};

export default DateRowWeather;
