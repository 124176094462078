import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const QuickFilters = ({ activeQuickFilter, setActiveQuickFilter }) => {
  const filters = [
    {
      name: "All Contacts",
    },
    {
      name: "Stakeholders",
    },
    {
      name: "Subcontractors",
    },
    {
      name: "Suppliers",
    },
  ];

  const handleFilterUpdate = (type) => {
    if (activeQuickFilter === type) {
      setActiveQuickFilter("All Contacts");
    } else {
      setActiveQuickFilter(type);
    }
  };

  return (
    <div role="tablist">
      {filters.map((type, index) => (
        <Button
          onClick={() => {
            handleFilterUpdate(type.name);
          }}
          key={index}
          variant="link"
          className={`${styles.filterOption} ${
            activeQuickFilter === type.name ? styles.active : undefined
          } p-0 pb-1 me-3`}
          role="tab"
          aria-selected={activeQuickFilter === type.name ? "true" : "false"}
        >
          {type.name}
        </Button>
      ))}
    </div>
  );
};

QuickFilters.propTypes = {
  activeQuickFilter: PropTypes.any,
  setActiveQuickFilter: PropTypes.func.isRequired,
};

export default QuickFilters;
