import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { X } from "react-feather";

const TakeoffItem = ({ item, updateNewLineItems }) => {
  const [status, setStatus] = useState(false);

  useEffect(() => {
    updateNewLineItems(item, status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <>
      <td>
        <div className={`form-group form-check`}>
          <input
            type="checkbox"
            className="form-check-input line-item-check"
            name={item.id}
            id={item.id}
            onChange={() => {
              setStatus(!status);
            }}
            aria-label="select row"
          />
          <label className="form-check-label" htmlFor={item.id}></label>
          <X size="18" color="#fff" />
        </div>
      </td>
      <td className="text-start">{item.name}</td>
      <td className="text-start">
        <span>
          {item.bid_quantity} <span className="text-gray3 sm">{item.rfq_quantity_uom}</span>
        </span>
      </td>
      <td className="text-end">
        {item.pm_quantity} <span className="text-gray3 sm">{item.rfq_quantity_uom}</span>
      </td>
      <td className="text-end">
        {parseInt(item.bid_quantity - item.pm_quantity)} <span className="text-gray3 sm">{item.rfq_quantity_uom}</span>
      </td>
    </>
  );
}

TakeoffItem.propTypes = {
  item: PropTypes.object,
  updateNewLineItems: PropTypes.func
};

export default TakeoffItem;