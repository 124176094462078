import { useState, useEffect } from "react";
import { useApi, dailyFieldlogsApi } from "component-library";
import useDailyFieldLogs from "../context/useDailyFieldLogs";

const useFieldForms = () => {
  const { getRequestParams, updateRequests } = useDailyFieldLogs();
  const [active, setActive] = useState(true);
  const [forms, setForms] = useState([]);

  const {
    request: formsRequest,
    data: formsData,
    error: formsError,
    loading: formsLoading,
  } = useApi(dailyFieldlogsApi.getFieldForms);

  useEffect(() => {
    if (!!updateRequests) {
      const requestParams = getRequestParams();
      formsRequest(requestParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRequests]);

  useEffect(() => {
    if (!!formsData?.submissions?.length) {
      setForms(formsData.submissions);
    } else {
      setForms([]);
    }
  }, [formsData?.submissions]);

  return {
    active,
    setActive,
    setForms,
    formsError,
    formsLoading,
    forms,
  };
};

export default useFieldForms;
