import React from "react";
import ListingExport from "../../../../_resourceManagement/FieldLogs/ListingExport";
import ForemanReminder from "./ForemanReminder";

const HeaderSecondary = ({ selected, updateExportedStatus, isProject = false, verifySubmit }) => {
  return (
    <div className="d-flex align-items-center">
      <ForemanReminder />
      {!isProject && (
        <ListingExport selected={selected} updateExportedStatus={updateExportedStatus} verifySubmit={verifySubmit} />
      )}
    </div>
  );
};

export default HeaderSecondary;
