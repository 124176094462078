import React, { useState, useEffect } from "react";
import { useApi, projectApi, useAuth } from "component-library";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ArrowRight } from "react-feather";
import useProject from "../../hooks/useProject";
import useProjectDocuments from "../ProjectDocuments/context/useProjectDocuments";

const useAssetPage = () => {
  const { id } = useParams();
  const history = useNavigate();
  const { project } = useProject();
  const { user } = useAuth();
  const { assetId } = useParams();
  const { displayMessage, attachments, attachmentsRequest, attachmentsLoading, attachmentsError } =
    useProjectDocuments();

  const {
    request: updateAttachmentRequest,
    data: updateAttachmentData,
    loading: updateAttachmentLoading,
    error: updateAttachmentError,
  } = useApi(projectApi.updateAttachment);

  const {
    request: updateAttachmentInfoRequest,
    data: updateAttachmentInfoData,
    loading: updateAttachmentInfoLoading,
    error: updateAttachmentInfoError,
  } = useApi(projectApi.updateAttachmentInfo);

  const [asset, setAsset] = useState({});
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [replacingFile, setReplacingFile] = useState(false);
  const [updatedFolder, setUpdatedFolder] = useState();

  const BreadCrumbs = () => (
    <div>
      {!!asset?.folder && (
        <>
          <Link to={`/project/${project?.id}/documents/folder/${asset?.folder}`}>{asset?.folder}</Link>
          <ArrowRight size={11} className="mx-3" color="#A7A7A7" />
        </>
      )}
      {asset.file_name}
    </div>
  );

  const handleUpdateAttachment = (replacementFile, oldFileId, folder) => {
    let requestParams;
    if (!!replacementFile) {
      requestParams = {
        user_id: user?.id,
        project_id: project?.id,
        attachment: {
          ...replacementFile,
          file_type: asset.type,
        },
        existing_id: oldFileId || null,
      };
      updateAttachmentRequest(requestParams);
      setUpdatedFolder(folder);
    } else {
      updateAttachmentInfoRequest({ id: asset.id, folder: folder, file_name: asset.file_name });
    }
  };

  const getDocumentType = () => {
    let docType = "";
    if (
      [
        "doc",
        "docm",
        "docx",
        "dot",
        "dotm",
        "dotx",
        "epub",
        "fodt",
        "htm",
        "html",
        "mht",
        "odt",
        "ott",
        "pdf",
        "rtf",
        "txt",
        "djvu",
        "xps",
      ].includes(asset?.extension)
    ) {
      docType = "word";
    } else if (["csv", "fods", "ods", "ots", "xls", "xlsm", "xlsx", "xlt", "xltm", "xltx"].includes(asset?.extension)) {
      docType = "cell";
    } else if (
      ["fodp", "odp", "otp", "pot", "potm", "potx", "pps", "ppsm", "ppsx", "ppt", "pptm", "pptx"].includes(
        asset?.extension
      )
    ) {
      docType = "slide";
    }

    return docType;
  };

  const docType = getDocumentType();

  useEffect(() => {
    if (!!attachments?.length) {
      setAsset(attachments.find((a) => a.id.toString() === assetId.toString()));
    } else {
      setAsset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  useEffect(() => {
    if (!!updateAttachmentInfoData?.success) {
      displayMessage({
        title: "File Updated",
        content: `File: ${asset.file_name} updated completed successfully`,
        id: "file-updated",
        variant: "success",
      });
      attachmentsRequest(id);
      history(`/project/${id}/documents/asset/${updateAttachmentInfoData?.file?.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAttachmentInfoData]);

  useEffect(() => {
    if (!!updateAttachmentData?.success) {
      updateAttachmentInfoRequest({
        id: updateAttachmentData.file_id,
        folder: updatedFolder,
        file_name: asset.file_name,
      });
      setUpdatedFolder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAttachmentData]);

  return {
    BreadCrumbs,
    historyModalOpen,
    setHistoryModalOpen,
    asset,
    showDeleteFileModal,
    setShowDeleteFileModal,
    showRenameModal,
    setShowRenameModal,
    showUpdateModal,
    setShowUpdateModal,
    handleUpdateAttachment,
    replacingFile,
    setReplacingFile,
    updateAttachmentLoading,
    updateAttachmentError,
    updateAttachmentInfoLoading,
    updateAttachmentInfoError,
    attachmentsLoading,
    attachmentsError,
    docType,
  };
};

export default useAssetPage;
