import React from "react";
import { Routes, Route } from "react-router";
import Dashboard from "./Dashboard";
// import Route from "../components/Route";
import ProvideDashboard from "./ProvideDashboard";
import AlertsPage from "./AlertsPage";
import StatusPage from "./StatusPage";
import CashflowPage from "./CashFlowPage";
import ManHoursPage from "./ManHoursPage";
import InvoicingPage from "./InvoicingPage";
import PerJobPage from "./PerJobPage";
import PrivateRoute from "../components/PrivateRoute";

const DashboardRoutes = () => {
  return (
    <ProvideDashboard>
      <Routes>
        <Route index element={<PrivateRoute Component={<Dashboard />} />} />
        <Route path="dashboard" element={<PrivateRoute Component={<Dashboard />} />} />
        <Route path="alerts" element={<PrivateRoute Component={<AlertsPage />} />} />
        <Route path="status" element={<PrivateRoute Component={<StatusPage />} />} />
        <Route path="cashflow" element={<PrivateRoute Component={<CashflowPage />} />} />
        <Route path="man-hours" element={<PrivateRoute Component={<ManHoursPage />} />} />
        <Route path="invoicing" element={<PrivateRoute Component={<InvoicingPage />} />} />
        <Route path="dollars-per-job" element={<PrivateRoute Component={<PerJobPage />} />} />
      </Routes>
    </ProvideDashboard>
  );
};

export default DashboardRoutes;
