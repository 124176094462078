import React, { useEffect, useState } from "react";
import { Briefcase, Save, Send } from "react-feather";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import useWeeklyReportEdit from "./useWeeklyReportEdit";
import { HeaderButton, projectApi, useApi, useAuth } from "component-library";
import Unauthorized from "../../../components/pages/Unauthorized";
import WeeklyReportEditPageContent from "./WeeklyReportEditPageContent";
import { useNavigate, useParams } from "react-router-dom";

const WeeklyReportEdit = () => {
  const history = useNavigate();
  const { report_id } = useParams();
  const handleRedirect = () => {
    history(`/field-management/weekly-reports/${report_id}/review`);
  };

  const {
    request: projectRequest,
    data: projectData,
    loading: projectLoading,
    error: projectError,
  } = useApi(projectApi.getProject);

  const [project, setProject] = useState();

  const { userCan } = useAuth();
  const {
    report,
    loading,
    updateDate,
    weather,
    handleUpdateNotes,
    reportNotes,
    handleRemoveNote,
    message,
    handleUnsetMessage,
    updateWeeklyNotesError,
    reportLoading,
    reportError,
    error,
    handleSaveReport,
    updateReportStatus,
  } = useWeeklyReportEdit({ handleRedirect });

  useEffect(() => {
    if (!!report?.id) {
      projectRequest(report.project_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.id]);

  useEffect(() => {
    if (!!projectData) {
      setProject(projectData?.project);
    }
  }, [projectData]);

  const HeaderButtons = () => (
    <div className="d-flex align-items-center">
      <HeaderButton
        onClick={() => {
          handleSaveReport();
        }}
        ariaLabel="Save current changes to weekly report"
        text="Save Report"
        icon={
          <Save
            size={16}
            color={`${!!reportLoading || !userCan("weekly-report-edit") || loading ? "#4f4f4f" : "#0071ce"}`}
          />
        }
        type="submit"
        disable={!!reportLoading || loading || !userCan("weekly-report-edit")}
      />
      <HeaderButton
        onClick={() => {
          updateReportStatus({
            id: report.id,
            status: "review",
          });
        }}
        type="submit"
        ariaLabel="update status to review"
        text="Review and Send"
        icon={<Send size={16} color={`${loading ? "#4f4f4f" : "#0071ce"}`} />}
        disable={loading || !userCan("weekly-report-edit")}
      />
    </div>
  );
  return (
    <>
      {userCan("weekly-report-edit") ? (
        <InteriorPage
          error={reportError || error || projectError}
          loading={projectLoading || reportLoading}
          navTitle="Field Management"
          navIcon={<Briefcase color="#fff" size={14} />}
          pageTitle="Weekly Report Edit"
          pageSubtitlePrimaryContent={() => "New Weekly Report"}
          pageSubtitleSecondaryContent={HeaderButtons}
        >
          <WeeklyReportEditPageContent
            project={project}
            report={report}
            updateDate={updateDate}
            weather={weather}
            loading={reportLoading}
            handleUpdateNotes={handleUpdateNotes}
            reportNotes={reportNotes}
            handleRemoveNote={handleRemoveNote}
            message={message}
            handleUnsetMessage={handleUnsetMessage}
            updateWeeklyNotesError={updateWeeklyNotesError}
          />
        </InteriorPage>
      ) : (
        <Unauthorized />
      )}
    </>
  );
};

export default WeeklyReportEdit;
