import { useState, useEffect } from "react";
import { useApi, projectApi } from "component-library";

const useSiteManagment = (projectID) => {
  const [locates, setLocates] = useState([]);
  const [permits, setPermits] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);

  const {
    request: locatesRequest,
    data: locatesData,
    error: locatesError,
    loading: locatesLoading,
  } = useApi(projectApi.getLocates);

  useEffect(() => {
    if (projectID) {
      locatesRequest(projectID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectID]);

  useEffect(() => {
    if (!!locatesData && !!locatesData.project) {
      if (!!locatesData.project.locates.length) {
        setLocates(locatesData.project.locates);
      }
      if (!!locatesData.project.permits.length) {
        setPermits(locatesData.project.permits);
      }
      setInitialLoading(false);
    }
  }, [locatesData]);

  const updateData = () => {
    locatesRequest(projectID);
  };

  return {
    locates,
    setLocates,
    permits,
    locatesRequest,
    updateData,
    locatesLoading,
    locatesError,
    initialLoading,
  };
};

export default useSiteManagment;
