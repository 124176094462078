import { useEffect, useState } from "react";
import employeeTimeReportConfig from "./employeeTimeReportConfig";
import styles from "../styles.module.scss";
import { FilterSearch, filterSearchFields, FancyFilters, avatarColors, AgGridTable, useAuth } from "component-library";
import removeDuplicateValues from "../../../utils/removeDuplicateValues";
import useDebounce from "../../../hooks/useDebounce";

const EmployeeTimeReport = ({ gridData }) => {
  const [filteredRowData, setFilteredRowData] = useState(gridData);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filters, setFilters] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const { debounce } = useDebounce();
  const { user } = useAuth();

  const applyFilters = (rowData, filters) => {
    let transformedFilters = [...filters];
    const filtered = rowData.filter((single) =>
      transformedFilters.includes(`${single.first_name} ${single.last_name}`)
    );
    return filtered;
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setFilteredRowData(
      filterSearchFields(gridData, ["first_name", "last_name", "job_name", "job_num", "project_manager"], value)
    );
  };

  useEffect(() => {
    setFilteredRowData(gridData);
    let options = [];
    gridData.forEach((single) => {
      options = [
        ...options,
        {
          abbr: `${single.first_name[0]}${single.last_name[0]}`,
          value: `${single.first_name} ${single.last_name}`,
          color: avatarColors[single.first_name.charAt(0).toLowerCase()],
        },
      ];
    });
    const unique = removeDuplicateValues(options);
    setFilters(
      unique.map((item) => {
        return item.value;
      })
    );
    setFilterOptions(unique);
  }, [gridData]);

  useEffect(() => {
    if (!!gridData) {
      setFilteredRowData(applyFilters(gridData, filters));
    }
    if (!!searchValue) {
      debounce(handleSearch(searchValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData, filters]);

  return (
    <div className={`${styles.gridWrap}`}>
      <div className="d-flex">
        <div className={`${styles.searchFilter}`}>
          <FilterSearch inline searchFunction={handleSearch} />
        </div>
        {!!filterOptions && (
          <FancyFilters
            filters={filters}
            setFilters={setFilters}
            options={filterOptions}
            className="ms-3 mt-1"
            hideTotals
          />
        )}
      </div>
      <AgGridTable
        gridConfig={employeeTimeReportConfig}
        gridData={filteredRowData}
        id={`${user.id}_employee_time_report`}
        suppressSizeToFit
      />
    </div>
  );
};

export default EmployeeTimeReport;
