import React from "react";
import PropTypes from "prop-types";
import styles from "../styles.module.scss";
import { ChevronRight, ChevronDown, Move } from "react-feather";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import useEstimateConversion from "../../useEstimateConversion";
import { User } from "react-feather";
import { Avatar } from "component-library";
import useProject from "../../../../hooks/useProject";
import { Link } from "react-router-dom";

const TaskName = ({ indentation, parentOpen, open, editTasks, item, toggleOpen, updateItem }) => {
  const { project } = useProject();
  const { setMovingTask, movingTask, setTaskToMove } = useEstimateConversion();
  const creatorPopover = (
    <Popover id="popover-trigger-hover-focus" title="Task Creator Popover">
      <div className="d-flex align-items-center med p-2 ml-2 mb-5">
        Created By:
        <span className="ms-2">
          <Avatar user={item?.creator} hasColor size="sm" />
        </span>
        <span className="lg font-weight-bold">{`${item.creator?.first_name} ${item.creator?.last_name}`}</span>
      </div>
    </Popover>
  );
  const taskLinkPopover = (
    <Popover id="popover-trigger-hover-focus" title="Task Creator Popover">
      <div className="d-flex align-items-center med p-2 ml-2">Open link to task detail summary</div>
    </Popover>
  );

  return (
    <td className={!!parentOpen || !!open ? styles.subBorderLeftActive : ""} style={{ paddingLeft: indentation }}>
      <div className="d-flex align-items-center">
        {!!item.sub_items && !!item.sub_items.length ? (
          <div>
            {!!open ? (
              <ChevronDown color={"#7b7b7b"} size={15} onClick={() => toggleOpen(item.id)} className="me-1" />
            ) : (
              <ChevronRight color={"#7b7b7b"} size={15} onClick={() => toggleOpen(item.id)} className="me-1" />
            )}
          </div>
        ) : (
          <span className="me-3">&bull;</span>
        )}
        {!!editTasks ? (
          <div className="d-flex align-items-center justify-content-between w-100">
            <label htmlFor={`${item.id}-task-name`} aria-label="Task Name"></label>
            <input
              id={`${item.id}-task-name`}
              name={`${item.id}-task-name`}
              type="text"
              className="form-control w-75"
              value={item.name}
              onChange={(e) => updateItem("name", e.target.value, item.id)}
              placeholder="Task Name"
            />
            {item?.entity_type === "task" && (
              <Button
                className={`${styles.moveIcon} p-0`}
                variant="link"
                onClick={() => {
                  setMovingTask(!movingTask);
                  setTaskToMove(item);
                }}
              >
                <Move size={14} />
              </Button>
            )}
          </div>
        ) : (
          <span className={`${styles.taskName} d-flex align-items-center`}>
            {item?.entity_type === "task" ? (
              <OverlayTrigger trigger={["hover", "focus"]} overlay={taskLinkPopover}>
                <Link
                  className={styles.taskLink}
                  target="_blank"
                  to={`/project/${project?.id}/reporting/task/${item.id}`}
                >
                  {item.name}
                </Link>
              </OverlayTrigger>
            ) : (
              <>{item.name}</>
            )}

            <OverlayTrigger trigger={["hover", "focus"]} overlay={creatorPopover}>
              <Button className="p-0" variant="link">
                {item.entity_type === "task" && item?.creator_id > 0 ? <User size={18} color="#0071ce" /> : null}
              </Button>
            </OverlayTrigger>
          </span>
        )}
      </div>
    </td>
  );
};

TaskName.propTypes = {
  editTasks: PropTypes.bool,
  item: PropTypes.object,
  updateItem: PropTypes.func,
  unitsOfMeasure: PropTypes.array,
  customControlStyles: PropTypes.any,
};

export default TaskName;
