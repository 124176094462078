import {
  number,
  cost,
  issue_date,
  expiration,
  description,
  status,
} from "./columnDefs";

const columnDefs = [
  number,
  cost,
  issue_date,
  expiration,
  description,
  status,
];

export default columnDefs;