import convertDecimalHours from "../../../utils/convertDecimalHours";

const hours = {
  headerName: "Hours",
  field: "hours",
  maxWidth: 112,
  valueGetter: ({ data }) =>
    data.hours ? convertDecimalHours(data.hours) : "0:00",
};

export default hours;
