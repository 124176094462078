import Name from "../columnRenders/Name";

const name = {
  headerName: "Title",
  field: "name",
  flex: 1,
  minWidth: 300,
  cellRenderer: Name,
  tooltipField: "name",
  tooltipValueGetter: ({ data }) => {
    return data?.name;
  },
};

export default name;
