const costCode = {
  headerName: "Code",
  field: "cost_code",
  resizable: true,
  width: 125,
  cellRenderer: "agGroupCellRenderer",
  headerClass: "ag-center-aligned-header",
};

export default costCode;
