import React from "react";
import ModalWrapper from "../ModalWrapper";
import PropTypes from "prop-types";
import useMultiSelectModal from "./useMultiSelectModal";

const MultiSelectModal = ({ showModal, setShowModal, album, setAlbum }) => {
  const { headerContent, bodyContent, footerContent, onHide } = useMultiSelectModal({ album, setShowModal, setAlbum, showModal });

  return (
    <ModalWrapper
      showModal={showModal}
      setShowModal={setShowModal}
      headerContent={headerContent}
      bodyContent={bodyContent}
      footerContent={footerContent}
      onHide={() => onHide()}
      size="xl"
    />
  );
};

MultiSelectModal.propTypes = {
  album: PropTypes.object,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  setAlbum: PropTypes.func,
};

export default MultiSelectModal;
