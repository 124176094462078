import { useState, useEffect } from "react";
import InteriorPage from "../../../../../components/pageLayout/InteriorPage";
import useProject from "../../../../hooks/useProject";
import useDailyFieldlog from "./useDailyFieldlog";
import { Briefcase } from "react-feather";
import styles from "./styles.module.scss";
import DateSelector from "./DateSelector";
import Weather from "./Weather";
import Milestones from "./Milestones";
import Stats from "./Stats";
import Foremen from "./Foremen";
import Crew from "./Crew";
import Equipment from "./Equipment";
import Tasks from "./Tasks";
import Notes from "./Notes";
import FieldForms from "./FieldForms";
import Submenu from "../Submenu";
import ForemanCrewEquipment from "./ForemanCrewEquipment";
import CollapsibleSection from "../CollapsibleSection";
import useCrew from "./Crew/useCrew";
import useEquipment from "./Equipment/useEquipment";
import useForemen from "./Foremen/useForemen";

const DailyFieldLog = () => {
  const {
    pageTitle,
    PageTag,
    fieldMgmtDateRange,
    setFieldMgmtDateRange,
    loading: projectLoading,
    error: projectError,
  } = useProject();

  // TODO: refactor crew, foreman, and other requests to be in the context or on the top level here, then passed in
  // TODO: refactor the collapsible sections

  const [initialLoad, setInitialLoad] = useState(true);

  const {
    foremen,
    selectedForemenIds,
    setSelectedForemenIds,
    foremenError,
    foremenLoading,
    selectedForemen,
    setSelectedForemen,
    SecondaryButton,
    openDailyReport,
  } = useDailyFieldlog();

  const { active: foremenActive, setActive: setForemenActive, handleForemanToggle } = useForemen({
    selectedForemenIds,
    selectedForemen,
    setSelectedForemen,
    setSelectedForemenIds,
  });

  const { active: crewActive, setActive: setCrewActive, filteredCrew, crewError, crewLoading } = useCrew({
    selectedForemenIds,
    foremen,
  });

  const { active: equipmentActive, setActive: setEquipmentActive, equipment, equipmentError, equipmentLoading } = useEquipment();

  useEffect(() => {
    if (!!initialLoad) {
      if (!fieldMgmtDateRange[1]) {
        setFieldMgmtDateRange([new Date()]);
      }
      setInitialLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad]);

  return (
    <InteriorPage
      navTitle={pageTitle}
      loading={projectLoading}
      error={projectError}
      pageTitle="Field Management"
      navIcon={<Briefcase color="#fff" size={14} />}
      NavContent={PageTag}
      pageSubtitlePrimaryContent={() => <Submenu />}
      pageSubtitleSecondaryContent={SecondaryButton}
    >
      <div className="container-fluid">
        <div className="contentContainer">
          <div className="mainContent">
            <div className="d-flex flex-wrap mb-5">
              <DateSelector />
              <Weather />
            </div>
            <div
              className={`${styles.statsRow} ${!fieldMgmtDateRange[0] || !!fieldMgmtDateRange[1] ? styles.dateRangeSelected : ""
                }`}
            >
              <Milestones />
              <Stats />
            </div>
            {!!openDailyReport &&
              <ForemanCrewEquipment
                selectedForemenIds={selectedForemenIds}
                setSelectedForemenIds={setSelectedForemenIds}
                foremen={foremen}
                foremenError={foremenError}
                foremenLoading={foremenLoading}
                selectedForemen={selectedForemen}
                setSelectedForemen={setSelectedForemen}
                crewActive={crewActive}
                setCrewActive={setCrewActive}
                filteredCrew={filteredCrew}
                crewError={crewError}
                crewLoading={crewLoading}
                handleForemanToggle={handleForemanToggle}
                foremenActive={foremenActive}
                setForemenActive={setForemenActive}
                equipmentActive={equipmentActive}
                setEquipmentActive={setEquipmentActive}
                equipment={equipment}
                equipmentError={equipmentError}
                equipmentLoading={equipmentLoading}
              />
            }
            <Notes />
            <Tasks selectedForemenIds={selectedForemenIds} />
            <FieldForms selectedForemenIds={selectedForemenIds} />
          </div>
          <div className="sidebar large">
            {!openDailyReport ? (
              <>
                <CollapsibleSection title={"Foreman, Crew & Equipment"}>
                  <Foremen
                    selectedForemenIds={selectedForemenIds}
                    setSelectedForemenIds={setSelectedForemenIds}
                    foremen={foremen}
                    foremenError={foremenError}
                    foremenLoading={foremenLoading}
                    selectedForemen={selectedForemen}
                    setSelectedForemen={setSelectedForemen}
                    active={foremenActive}
                    setActive={setForemenActive}
                    handleForemanToggle={handleForemanToggle}
                  />
                  <Crew
                    active={crewActive}
                    setActive={setCrewActive}
                    filteredCrew={filteredCrew}
                    crewError={crewError}
                    crewLoading={crewLoading}
                  />
                  <Equipment
                    active={equipmentActive}
                    setActive={setEquipmentActive}
                    equipment={equipment}
                    equipmentError={equipmentError}
                    equipmentLoading={equipmentLoading}
                  />
                </CollapsibleSection>
              </>
            ) : (
              <>Form will go here</>
            )}
          </div>
        </div>
      </div>
    </InteriorPage>
  );
};

export default DailyFieldLog;
