import React, { useState, useEffect } from "react";
import useLocate from "../../../components/LocatesAndPermits/Locates/hooks/useLocate";
import PropTypes from "prop-types";
import debounceFunc from "../../../hooks/useDebounce";
import styles from "./styles.module.scss";

const { debounce } = debounceFunc();

const NewLocateNumber = ({ data }) => {

  const [locateNumber, setLocateNumber] = useState(!!data.new_number ? data.new_number : "");
  const { locates, setLocates, failedLocates } = useLocate();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!!failedLocates.length) {
      const failedLocate = failedLocates.find((data) => data.number === locateNumber);
      if (!!failedLocate) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }, [failedLocates, locateNumber]);

  useEffect(() => {
    if (!!locateNumber && data.new_number !== locateNumber) {
      debounce(updateLocates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locateNumber]);

  const updateLocates = () => {
    setLocates(
      locates.map((locateObj) => {
        if (locateObj.id === data.id) {
          return {
            ...locateObj,
            new_number: locateNumber,
          };
        }
        return locateObj;
      })
    );
  };

  return (
    <div className={`form-group mb-0 ps-3 ${!!error ? "error" : ""}`}>
      <input
        type="number"
        name="locate_number"
        value={locateNumber}
        onChange={(e) => {
          setLocateNumber(e.target.value);
        }}
        className={styles.fakeInput}
      />
    </div>
  );
};

NewLocateNumber.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NewLocateNumber;
