import { useReducer } from "react";
import { useReportFieldNotesReducer, createInitialState } from "./useReportFieldNotesReducer";

const useReportFieldNotes = ({ handleUpdateNotes, reportNotes, handleRemoveNote }) => {
  const [state, dispatch] = useReducer(useReportFieldNotesReducer, createInitialState);

  const handleNoteDeselected = (selectedValue, removedNote) => {
    let updatedNotes;
    if (selectedValue === 0) {
      updatedNotes = reportNotes.filter((note) => note.id !== removedNote.id);
      handleRemoveNote(updatedNotes, {
        ...removedNote,
        include_on_report: 0,
      });
    }
  };

  const handleSaveModalSelections = (selectedNotes) => {
    handleUpdateNotes(selectedNotes);

    dispatch({ type: "toggle_update_notes_modal" });
  };

  const togglePhotoModal = () => {
    dispatch({ type: "toggle_photo_modal" });
  };

  const toggleUpdateNotesModal = () => {
    dispatch({ type: "toggle_update_notes_modal" });
  };

  const handleSetPhotosForViewModal = (photos) => {
    dispatch({ type: "set_photos_for_modal_viewer", photos });
  };

  return {
    photoModalOpen: state.photoModalOpen,
    photos: state?.photos || [],
    handleSetPhotosForViewModal,
    updatingFieldNotes: state.updatingFieldNotes,
    toggleUpdateNotesModal,
    togglePhotoModal,
    handleNoteDeselected,
    handleSaveModalSelections,
  };
};

export default useReportFieldNotes;
