import React, { useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import useLogin from "../../hooks/useLogin";
import styles from "../../styles/Login.module.scss";
import { ReactComponent as Background } from "../../assets/bg.svg";
import { ReactComponent as Logo } from "../../assets/fullLogo.svg";
import { SiteVersion } from "component-library";

function Login() {
  const { fields, renderSubmitButton, handleSubmit, loggedIn, data } = useLogin();

  useEffect(() => {
    document.title = "Tractics";
  }, []);

  return (
    <>
      {loggedIn ? (
        <Navigate to="/dashboard" />
      ) : (
        <>
          <div className={styles.bgWrapper}>
            <Background className={styles.background} />
          </div>
          <div className={styles["login-form-wrap"]}>
            <Logo className={styles.logo} />
            <form onSubmit={handleSubmit} className={styles.loginForm}>
              {fields}
              <div className={styles.footer}>{renderSubmitButton("Login", !data.email || !data.password)}</div>
              <p className="mt-4 mb-0 text-center">
                <Link to="/forgot-password">Forgot Password</Link>
              </p>
            </form>
          </div>
          <SiteVersion isLoginScreen={true} />
        </>
      )}
    </>
  );
}

export default Login;
