import React from "react";
import Chiclet from "./Chiclet";
import InvoiceChart from "./InvoiceChart";
import getJobName from "./getJobName";
import DashboardPage from "./DashboardPage";
import PageHeader from "./PageHeader";
import useDashboard from "./useDashboard";
import FooterLinkToProject from "./FooterLinkToProject";

const InvoicingPage = () => {
  const { jobs, aggregateStats } = useDashboard();
  return (
    <DashboardPage>
      <PageHeader title="Job Invoicing" />
      {jobs && !!jobs.length && aggregateStats && (
        <>
          {aggregateStats.invoice_totals && (
            <div className="row">
              <div className="col">
                <h2 className="mb-4">All Jobs</h2>
                <InvoiceChart
                  lg
                  invoiceTotals={aggregateStats.invoice_totals}
                />
              </div>
            </div>
          )}

          <div className="row">
            {jobs.map((job) => (
              <div className="col-12 mb-3 pb-1" key={job.job_id}>
                <Chiclet>
                  <div>
                    <h2 className="med semibold">{getJobName(job)}</h2>
                    {job.invoices && (
                      <InvoiceChart invoiceTotals={job.invoices} />
                    )}
                  </div>
                  <FooterLinkToProject job={job} />
                </Chiclet>
              </div>
            ))}
          </div>
        </>
      )}
    </DashboardPage>
  );
};

export default InvoicingPage;
