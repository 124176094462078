import React from "react";
import { Loader, useAuth } from "component-library";
import styles from "./styles.module.scss";

const Wrapper = ({ children }) => {
  const { modules } = useAuth();

  return (
    <>
      {!!modules ? (
        <div className={styles.container}>
          <div className={styles.cardWrapper}>{children}</div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Wrapper;
