import Roles from "../columnRenders/Roles";

const roles = {
  headerName: "Role",
  field: "roles",
  cellRenderer: Roles,
  autoHeight: true,
};

export default roles;
