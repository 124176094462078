import React from 'react';
import { ExternalLink } from 'react-feather';

const PlansLink = ({data}) => {

  const handleRef = (ref) => {
    if (!ref) return;
    ref.onclick = (e) => {
      e.stopPropagation();
      window.open(`${data.plans_location}`, "_blank");
    };
  }

  return data.plans_location ? (
    <div className="btn btn-link" ref={handleRef}>
      <ExternalLink size={18} color="#0071ce" />
    </div>
  ) : null;
}

export default PlansLink;