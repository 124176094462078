import React from "react";
import Select from "react-select";
import ApprovalSelect from "../ApprovalSelect";
import PrioritySelect from "./PrioritySelect";
import { StyledDatePicker, dropdownStyles, dropdownStylesObj, displayDate, useAuth } from "component-library";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

const SubmittalSidebar = ({ submittal, setSubmittal }) => {
  const { userCan, config } = useAuth();
  const handleDateChange = (date, name) => {
    setSubmittal({
      ...submittal,
      [name]: DateTime.fromJSDate(date).toISO(),
    });
  };

  const handleSelectChange =
    (name) =>
    ({ value }) => {
      setSubmittal({ ...submittal, [name]: value });
    };

  const handleChangeApproval =
    (item) =>
    ({ value }) =>
      setSubmittal({ ...submittal, status_id: value });

  const bicOptions = config.submittal_bic.map((opt) => ({
    label: opt.name,
    value: opt.id,
  }));

  return (
    <>
      <form className="row text-end text-lg-left" data-bs-testid="submittal-sidebar">
        <div className="col-lg-12 col-sm-4 col-6">
          <h3 className="pt-3 med text-end text-gray2">Approval Status</h3>
          {userCan("submittal-edit") || userCan("submittal-modify") ? (
            <ApprovalSelect
              item={submittal}
              handleChangeApproval={handleChangeApproval}
              customStyles={dropdownStylesObj}
            />
          ) : (
            <p className="h6 light-text">{submittal.status.name}</p>
          )}
        </div>
        <div className="col-lg-12 col-sm-4 col-6">
          <h3 className="pt-3 med text-end text-gray2">BIC</h3>
          {userCan("submittal-edit") ? (
            <Select
              options={bicOptions}
              name="bic_id"
              id="bic"
              onChange={handleSelectChange("bic_id")}
              value={bicOptions.find((opt) => opt.value === submittal.bic_id)}
              styles={dropdownStyles}
              aria-label="bic options"
            />
          ) : (
            <p className="h6 light-text">{submittal.bic.name}</p>
          )}
        </div>
        <div className="col-lg-12 col-sm-4 col-6">
          <h3 className="pt-3 med text-end text-gray2">Due Date</h3>
          {userCan("submittal-edit") ? (
            <StyledDatePicker
              selected={Date.parse(submittal.due_date)}
              onChange={(date) => handleDateChange(date, "due_date")}
              name="due_date"
            />
          ) : (
            <p className="h6 light-text">{submittal.due_date}</p>
          )}
        </div>
        <div className="col-lg-12 col-sm-4 col-6">
          <h3 className="pt-3 med text-end text-gray2">Reminder Date</h3>
          {userCan("submittal-edit") ? (
            <StyledDatePicker
              selected={Date.parse(submittal.reminder_date)}
              name="reminder_date"
              onChange={(date) => handleDateChange(date, "reminder_date")}
            />
          ) : (
            <p className="h6 light-text">{submittal.reminder_date}</p>
          )}
        </div>
        <div className="col-lg-12 col-sm-4 col-6">
          <h3 className="pt-3 med text-end text-gray2">Priority</h3>
          {userCan("submittal-edit") ? (
            <PrioritySelect
              handleSelectChange={handleSelectChange}
              submittal={submittal}
              customStyles={dropdownStylesObj}
            />
          ) : (
            <p className="h6 light-text">{submittal.priority.name}</p>
          )}
        </div>
        {!!submittal && !!submittal.id && (
          <div className="sm mt-5 text-gray2 text-end col-lg-12 col-sm-4 col-6 font-italic">
            Last Update <br />
            <span className="med">{displayDate(submittal.updated_at)}</span>
          </div>
        )}
      </form>
    </>
  );
};

SubmittalSidebar.propTypes = {
  submittal: PropTypes.object,
  setSubmittal: PropTypes.func.isRequired,
};

export default SubmittalSidebar;
