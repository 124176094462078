import React, { useState, useEffect } from "react";
import { useFormControl, bidApi, useApi, useAuth } from "component-library";
import { schema, initialData } from "../../schema/closeBid";
import { Navigate } from "react-router-dom";
import BidTracking from "./../BidTracking";
import { Alert } from "react-bootstrap";
import { ProjectAttachment } from "../../_projects/ProjectAttachments";
import styles from "../styles/CloseBidForm.module.scss";

const useCloseBid = (bid, setDocLoading) => {
  const { userCan } = useAuth();
  const { error, request: closeBidRequest, data: closeData, loading: closeLoading } = useApi(bidApi.closeBid);

  const [bidDocs, setBidDocs] = useState();

  useEffect(() => {
    if (!!bid?.attachments) setBidDocs(bid.attachments.filter((a) => a.type === "Bid Document"));
  }, [bid]);

  const onSubmit = async (formData, setLoading) => {
    try {
      await closeBidRequest({
        id: bid.id,
        bid_read_low: formData.read_low,
        job_number: formData.job_number,
      });
    } catch (message) {
      setLoading(false);
    }
  };

  const { handleSubmit, renderSubmitButton, renderCheckbox, renderTextField, setFields, data } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const fields = (
    <>
      {!!bid && (
        <div className={styles.mainText}>
          {!!closeData &&
            !!closeData.project &&
            closeData.project.bid_read_low === 1 &&
            closeData.project.bid_closed === 1 && <Navigate to={`/project/${bid.id}`} />}

          {!!closeData &&
            !!closeData.project &&
            closeData.project.bid_read_low === 0 &&
            closeData.project.bid_closed === 1 && <Navigate to="/bids" />}

          {error && <Alert variant="danger">{error}</Alert>}
          <h5 className={`${styles.headerText} med`}>Close Bid</h5>
          <div className="py-2" style={{ maxWidth: 200 }}>
            <div>Read low?</div>
            {renderCheckbox({
              name: "read_low",
              isToggle: true,
              ariaLabel: "Read low?",
            })}
          </div>
          {!!data?.read_low && (
            <>
              <div className="pt-2" style={{ maxWidth: 200 }}>
                {renderTextField({
                  name: "job_number",
                  label: "Job Number",
                })}
              </div>
            </>
          )}
          <div className="py-2 mt-4">
            <h6 className="mb-0 med">Upload Bid Documents Submitted</h6>
          </div>
          <div className="row">
            {!!bidDocs && (
              <div className="col d-flex flex-wrap">
                {bidDocs.map((doc) => (
                  <div key={doc.id}>
                    <ProjectAttachment
                      attachment={doc}
                      setAttachment={setBidDocs}
                      type="Bid Document"
                      projectId={bid.id}
                      canUpload={userCan("bid-create") || userCan("bid-edit")}
                      setDocLoading={setDocLoading}
                      isBid={bid?.status === "bid"}
                    />
                  </div>
                ))}
                <div>
                  <ProjectAttachment
                    setAttachment={setBidDocs}
                    type="Bid Document"
                    projectId={bid.id}
                    canUpload={userCan("bid-create") || userCan("bid-edit")}
                    setDocLoading={setDocLoading}
                    isBid={bid?.status === "bid"}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="py-2 mt-4">
            <h6 className="med">Bid Tracking</h6>
            <div className="light-text sm-med subLabel">Enter competing bids</div>
            <BidTracking bid={bid} readLow={data.read_low} />
          </div>
        </div>
      )}
    </>
  );
  return {
    initialData,
    fields,
    handleSubmit,
    renderSubmitButton,
    setFields,
    formControlData: data,
    closeData,
    closeLoading,
    error,
  };
};

export default useCloseBid;
