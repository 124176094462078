import { useState, useEffect } from "react";
import { fieldLogsApi, useApi, projectApi } from "component-library";
import { useParams } from "react-router-dom";
import adminApi from "../../../api/admin";

const useFieldLogDetail = () => {
  const { fieldLogId, foremanId } = useParams();
  const [crew, setCrew] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [overAllocatedTimeSheets, setOverAllocatedTimeSheets] = useState([]);
  const [minutesUnassigned, setMinutesUnassigned] = useState(0);

  const { request: logRequest, data: logData, loading: logLoading, error: logError } = useApi(fieldLogsApi.getFieldLog);

  const { request: getCrew, data: crewData, loading: crewLoading, error: crewError } = useApi(adminApi.getUsers);

  const [fieldLog, setFieldLog] = useState();
  const [taskView, setTaskView] = useState(true);
  const [editing, setEditing] = useState(false);
  const [projectsList, setProjectsList] = useState([]);

  const { request: projectsRequest, data: projectsData } = useApi(projectApi.getProjectsList);

  useEffect(() => {
    projectsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!projectsData && !!projectsData.projects) {
      setProjectsList(
        projectsData.projects.map((project) => {
          return {
            value: project.id,
            label: project.name,
          };
        })
      );
    }
  }, [projectsData]);

  useEffect(() => {
    if (!!fieldLogId && !!foremanId) {
      logRequest(fieldLogId, foremanId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!logData) {
      setFieldLog(logData.work_day);
    }
  }, [logData]);

  useEffect(() => {
    getCrew();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!crewData) {
      setCrew(crewData.users);
    }
  }, [crewData]);

  useEffect(() => {
    if (!!fieldLog) {
      let newMinutesWorked = 0;
      let travelTimes = 0;
      const timesheets = fieldLog.time_sheets;
      const timeEntries = timesheets.flatMap((timesheet) => timesheet.time_entries);

      if (!!timesheets && !!timesheets.length) {
        const totalMinutes = timesheets.reduce(
          (prevValue, currentValue) => prevValue + parseInt(currentValue.payroll_minutes),
          0
        );
        timesheets.forEach((timesheet) => {
          if (timesheet.travel_times) {
            timesheet.travel_times.forEach((travelTime) => {
              travelTimes += parseInt(travelTime.travel_duration);
            });
          }
        });
        newMinutesWorked = totalMinutes;
      } else {
        newMinutesWorked = 0;
      }

      if (!!timeEntries && !!timeEntries.length) {
        let newHoursAssigned = timeEntries.reduce((prevValue, currentValue) => {
          return currentValue.work_day_task_id !== 0 ? prevValue + parseInt(currentValue.minutes) : prevValue + 0;
        }, 0);
        setMinutesUnassigned(newMinutesWorked - newHoursAssigned - travelTimes);
      } else {
        setMinutesUnassigned(newMinutesWorked - travelTimes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldLog]);

  useEffect(() => {
    if (!!crewError || !!logError) {
      setError(crewError || logError);
    } else {
      setError();
    }
  }, [crewError, logError]);

  useEffect(() => {
    if (!!logLoading) {
      setLoading(logLoading);
    } else {
      setLoading();
    }
  }, [logLoading]);

  return {
    fieldLog,
    setFieldLog,
    taskView,
    setTaskView,
    loading,
    error,
    editing,
    setEditing,
    crew,
    foremanId,
    fieldLogId,
    projectsList,
    overAllocatedTimeSheets,
    setOverAllocatedTimeSheets,
    crewLoading,
    minutesUnassigned,
  };
};

export default useFieldLogDetail;
