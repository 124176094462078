import React from "react";
import { dropdownStylesWithWidth, useAuth } from "component-library";
import { Save } from "react-feather";
import { Button } from "react-bootstrap";
import styles from "./styles.module.scss";
import ContactSearch from "../../../../components/Contacts/ContactSearch";
import EditContactForm from "../../../../components/Contacts/EditContactForm";
import PropTypes from "prop-types";
import useQuickAddSubmittal from "./useQuickAddSubmittal";
import { BeatLoader } from "react-spinners";

const QuickAddSubmittal = ({ submittal, addSubmittal, setIsEdit, isEdit, projectOwner, setPoError }) => {
  const { config } = useAuth();
  const {
    fieldData,
    handleSubmit,
    renderTextField,
    setSource,
    source,
    setAddNew,
    sourceError,
    renderSelectField,
    loading,
    addNew,
  } = useQuickAddSubmittal({ submittal, addSubmittal, setIsEdit, isEdit, projectOwner, setPoError });

  return (
    <>
      {fieldData && (
        <>
          <form onSubmit={handleSubmit} className={`d-table-row ${styles["submittal-row"]}`}>
            <div className={`d-table-cell ${styles["sub-cell"]}`}>
              {renderTextField({
                name: "name",
              })}
            </div>
            <div className={`d-table-cell ${styles["sub-cell"]}`}>
              <ContactSearch
                setContact={setSource}
                contact={source}
                setAddNew={setAddNew}
                placeholder="Search..."
                error={sourceError}
              />
            </div>
            <div className={`d-table-cell ${styles["sub-cell"]}`}>
              {renderTextField({
                name: "spec_section",
              })}
            </div>
            <div className={`d-table-cell ${styles["sub-cell"]}`}>
              {renderTextField({
                name: "plans_section",
              })}
            </div>
            <div className={`d-table-cell ${styles["sub-cell"]}`} style={{ width: 175 }}>
              {renderSelectField({
                name: "requirements",
                isSearchable: false,
                closeMenuOnSelect: false,
                emptyOption: !!fieldData.requirements.length
                  ? `${fieldData.requirements.length} Selected`
                  : "Requirements",
                hideSelectedOptions: false,
                controlShouldRenderValue: false,
                isMulti: true,
                isClearable: false,
                styles: dropdownStylesWithWidth(175),
                options: config.submittal_requirements.map((opt) => ({
                  label: opt.name,
                  value: opt.id,
                })),
              })}
            </div>
            <div className={`d-table-cell ${styles["sub-cell"]}`}>
              {loading ? (
                <BeatLoader color="#0071ce" />
              ) : (
                <Button type="submit" variant="link">
                  <Save size="14" />
                </Button>
              )}
            </div>
            {!!addNew && <EditContactForm setAddingContact={() => setAddNew(false)} setEditedContact={setSource} />}
          </form>
        </>
      )}
    </>
  );
};

QuickAddSubmittal.propTypes = {
  submittal: PropTypes.object,
  addSubmittal: PropTypes.func.isRequired,
  setIsEdit: PropTypes.func,
  isEdit: PropTypes.bool,
  projectOwner: PropTypes.any,
  setPoError: PropTypes.func.isRequired,
};

export default QuickAddSubmittal;
