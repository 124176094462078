import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApi, submittalsApi } from "component-library";
import SubmittalEditForm from "./SubmittalEditForm";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import { Briefcase, Save } from "react-feather";
import useProject from "../../../_projects/hooks/useProject";

const SubmittalEdit = () => {
  const { submittalId } = useParams();
  const { pageTitle, PageTag } = useProject();
  const { request, data, loading, error } = useApi(submittalsApi.getSubmittal);

  useEffect(() => {
    request(submittalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittalId]);

  return (
    <InteriorPage
      loading={loading}
      error={error}
      navTitle={pageTitle}
      pageTitle="Submittals"
      pageSubtitlePrimaryContent={() => (
        <h2 className="lg font-weight-bold mb-0">Edit {!!data && data.submittal && data.submittal.name}</h2>
      )}
      pageSubtitleSecondaryContent={() => (
        <button form="edit-submittal-form" className="d-flex align-items-center btn btn-link med">
          <Save size="14" className="me-1" />
          <span className="text-gray3">Save</span>
        </button>
      )}
      navIcon={<Briefcase color="#fff" size={14} />}
      NavContent={PageTag}
    >
      {data && data.submittal && <SubmittalEditForm submittalData={data.submittal} />}
    </InteriorPage>
  );
};

export default SubmittalEdit;
