import {
  certifications,
  quote_amount,
  quote_id,
  status,
  supplier_name
} from "./columnDefs";

const columnDefs = [
  quote_id,
  supplier_name,
  quote_amount,
  certifications,
  status,
];

export default columnDefs;