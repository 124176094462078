import React, { useEffect } from "react";
import PropTypes from "prop-types";
import getLabel from "../../utils/getLabel";
import { dropdownStyles, useAuth } from "component-library";
import autoCompleteContractAmounts from "../../utils/autoCompleteContractAmounts";

const ProjectInfo = ({ renderSelectField, renderTextField, data, setFields }) => {
  const { config } = useAuth();
  const workingDayOptions = config.working_day_type_config.map((type) => ({
    value: type.id,
    label: type.value,
  }));
  const is3or6 = data.type_id === 3 || data.type_id === 6;

  useEffect(() => {
    const updatedData = autoCompleteContractAmounts(data);
    setFields(updatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.idot_proposal_guaranty_amount]);

  return (
    <>
      <div className="row py-2">
        <div className="col-12">
          <h1 className="form-section-header">Project Info</h1>
        </div>
      </div>
      {is3or6 && (
        <div className="row">
          <div className="col-md-5">
            {renderTextField({
              name: "idot_proposal_guaranty_amount",
              label: `Proposal Guaranty Amount`,
              format: "currency",
            })}
          </div>
        </div>
      )}
      <div className="row py-2">
        <div className="col-md-3">
          {renderTextField({
            name: "owner_project_number",
            label: getLabel("owner_project_number", data.type_id),
          })}
        </div>
        <div className="col-md-4">
          {renderTextField({
            name: "estimated_contract_amount",
            label: `Engineers Est. Contract Amount`,
            format: "currency",
          })}
        </div>
        {is3or6 && (
          <>
            <div className="col-md-4 align-with-label position-relative">
              <div className="range-dash">-</div>
              {renderTextField({
                name: "estimated_contract_amount_high",
                format: "currency",
              })}
            </div>
          </>
        )}
      </div>
      <div className="row py-2">
        <div className="col-md">
          {renderTextField({
            name: "early_start_date",
            label: `Early Start Date`,
            type: "date",
            maxDate: !!data.late_start_date && new Date(data.late_start_date),
          })}
        </div>
        <div className="col-md">
          {renderTextField({
            name: "late_start_date",
            label: `Late Start Date`,
            type: "date",
            minDate: !!data.early_start_date && new Date(data.early_start_date),
          })}
        </div>
        <div className="col-md">
          <div>
            {renderTextField({
              name: "completion_date",
              label: `Completion Date`,
              type: "date",
              minDate: !!data.early_start_date && new Date(data.early_start_date),
            })}
          </div>
        </div>
        <div className="col-md">
          {renderTextField({
            name: "working_days",
            label: `Total Days`,
          })}
        </div>
        <div className="col-md">
          {renderSelectField({
            name: "working_days_type",
            options: workingDayOptions,
            styles: dropdownStyles,
          })}
        </div>
      </div>
    </>
  );
};

export default ProjectInfo;

ProjectInfo.propTypes = {
  data: PropTypes.object.isRequired,
  renderSelectField: PropTypes.func.isRequired,
  renderTextField: PropTypes.func.isRequired,
};
