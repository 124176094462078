import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi, rfqsApi } from "component-library";
import useBid from "../hooks/useBid";

const useProvideRFQs = () => {
  const [rfqs, setRfQs] = useState([]);
  const { id } = useParams();
  const { request, data, error, loading } = useApi(
    rfqsApi.getRFQs
  );
  const { bid } = useBid();

  useEffect(() => {
    request(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data && data.rfqs) {
      setRfQs(data.rfqs);
    }
  }, [data]);

  return {
    loading,
    error,
    rfqs,
    setRfQs,
    bid,
  };
};

export default useProvideRFQs;
