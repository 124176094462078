import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import {
  bidApi,
  useApi,
  SingleAttachment,
  displayDate,
  displayTime,
  currency,
  Loader,
  useAuth,
} from "component-library";
import PropTypes from "prop-types";

const CompletedTask = ({ task, setTodos, bid }) => {
  const { request, data, loading } = useApi(bidApi.completeToDo);
  const { userCan } = useAuth();

  useEffect(() => {
    if (data && data.project) {
      setTodos(data.project.bid_todos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = async () => {
    await request({
      project_id: task.project_id,
      todo_id: task.bid_todo.id,
      complete: 0,
    });
  };

  return (
    <div className="row py-2 position-relative">
      <Loader loading={!!loading} />
      <div className="col-md-9 py-1">
        {task.bid_todo.id === 1 && (
          <>
            <div className="med">Location: {task.pre_bid_location}</div>
            <div className="med">Date: {displayDate(task.pre_bid_date)}</div>
            <div className="med">Time: {displayTime(task.pre_bid_time)}</div>
            <div className="med">Mandatory: {task.pre_bid_attendance_required ? "true" : "false"}</div>
          </>
        )}
        {task.bid_todo_id === 13 && (
          <>
            <div className="med">Eligibility Request: {task.eligibility_request ? "True" : "False"}</div>
            <div className="med">Subcontract Amount: ${task.eligibility_subcontract_amount}</div>
            <div className="med">Subcontract Woh: ${task.eligibility_woh}</div>
          </>
        )}
        {task.bid_todo.id === 14 && (
          <>
            <div className="med">Download DBE: {task.download_dbe ? "True" : "False"}</div>
            <div className="med">Download EBSX: {task.download_ebsx ? "True" : "False"}</div>
          </>
        )}
        {(task.bid_todo_id === 4 || task.bid_todo_id === 12) && (
          <>
            {task.bid_bond_amount_type === "other" ? (
              <div className="med">Amount: {parseFloat(task.bid_bond_amount).toFixed(2)}%</div>
            ) : (
              <div className="med">Amount: {parseFloat(task.bid_bond_amount_type).toFixed(2)}%</div>
            )}
            <div className="med">Subcontracted Amount: {currency(task.bid_bond_subcontracted_amount)}</div>
            <div className="med">Comment: {task.bid_bond_comments}</div>
            <div className="med">Date: {displayDate(task.bid_bond_date)}</div>
          </>
        )}
        {task.bid_todo.id === 8 && <div className="med">SRF Number: {task.srf_number}</div>}

        {task.file_id && bid.attachments.find((t) => t.id === task.file_id) ? (
          <SingleAttachment
            attachment={bid.attachments.find((t) => t.id === task.file_id)}
            canUpload={userCan("bid-edit")}
          />
        ) : null}
        {task.bid_todo.preloaded_document && (
          <a className="sm-med" href={task.bid_todo.document_url}>
            {task.bid_todo.preloaded_document}
          </a>
        )}
      </div>
      <div className="col-md-3 d-flex flex-column-reverse text-end p-3">
        <div className="flex-end">
          <Button onClick={onSubmit} className="" variant="tertiary">
            Re-Open Task
          </Button>
        </div>
      </div>
    </div>
  );
};

CompletedTask.propTypes = {
  task: PropTypes.object,
  setTodos: PropTypes.func,
  bid: PropTypes.object,
};

export default CompletedTask;
