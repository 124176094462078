import React from "react";
import { Routes, Route } from "react-router";
import PrivateRoute from "../../../components/PrivateRoute";
import Quotes from "./Quotes";
import Quote from "./Quote";
import QuoteEdit from "./Quote/QuoteEdit";
import QuoteAddLineItems from "./Quote/QuoteAddLineItems";

const QuoteRoutes = () => {
  return (
    <Routes>
      <Route index element={<PrivateRoute module="Portal" Component={<Quotes />} />} />
      <Route path="quotes" element={<PrivateRoute module="Portal" Component={<Quotes />} />} />
      <Route path=":quoteId" element={<PrivateRoute module="Portal" Component={<Quote />} />} />
      <Route
        path=":quoteId/add-line-items"
        element={<PrivateRoute module="Portal" Component={<QuoteAddLineItems />} />}
      />
      <Route path=":quoteId/edit" element={<PrivateRoute module="Portal" Component={<QuoteEdit />} />} />
    </Routes>
  );
};

export default QuoteRoutes;
