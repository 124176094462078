import React from "react";
import styles from "../../styles/Login.module.scss";
import { ReactComponent as Background } from "../../assets/bg.svg";
import { ResetPasswordForm, useAuth } from "component-library";
import { Navigate } from "react-router-dom";

const ResetPassword = () => {
  const { resetPassword } = useAuth();

  return (
    <>
      {window?.location?.href.includes("?token=") && window?.location?.href.includes("&email=") ? (
        <>
          <div className={styles.bgWrapper}>
            <Background className={styles.background} />
          </div>
          <ResetPasswordForm resetPassword={resetPassword} />
        </>
      ) : (
        <Navigate to={`/forgot-password`} />
      )}
    </>
  );
};

export default ResetPassword;
