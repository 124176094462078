import { endpoints, client } from "component-library";
const companies = {
  createCompany: (company) => client.post(endpoints.secure.CREATE_COMPANY, company),
  getCompanies: () => client.get(endpoints.secure.GET_COMPANIES),
  getCompany: (id) => client.get(`${endpoints.secure.GET_COMPANY}${id}`),
  updateCompany: (company) => client.post(endpoints.secure.UPDATE_COMPANY, company),
  searchCompanies: (q) => client.post(endpoints.secure.SEARCH_COMPANIES, { q }),
  searchLocations: ({ q, page }) =>
    client.post(endpoints.secure.SEARCH_LOCATIONS, {
      q,
      page,
    }),
  archiveCompany: (company_id) => client.get(endpoints.secure.ARCHIVE_COMPANY(company_id)),
  restoreCompany: (company_id) => client.get(endpoints.secure.RESTORE_COMPANY(company_id)),

  archiveLocation: (location_id) => client.get(endpoints.secure.ARCHIVE_LOCATION(location_id)),
  restoreLocation: (location_id) => client.get(endpoints.secure.RESTORE_LOCATION(location_id)),
  createLocation: (location) => client.post(endpoints.secure.CREATE_LOCATION, location),
  updateLocation: (location) => client.post(endpoints.secure.UPDATE_LOCATION, location),
};

export default companies;
