import React from "react";
import { Button } from "react-bootstrap";
import { RotateCcw } from "react-feather";
import useEstimateConversion from "../useEstimateConversion";

const TaskActions = () => {
  const { importable, refreshEstimate, refreshingEstimate, canEdit } = useEstimateConversion();

  return (
    <div className="task-actions align-items-center d-flex justify-content-end">
      {!refreshingEstimate && !!importable && (
        <Button
          variant="link"
          onClick={() => refreshEstimate()}
          className={`d-flex justify-content-center align-items-center med text-gray2`}
          disabled={!canEdit}
        >
          <RotateCcw className="me-1" size={14} color={!canEdit ? "#6c757d" : "#0071ce"} />
          Refresh Estimate
        </Button>
      )}
    </div>
  );
};

export default TaskActions;
