import React from "react";
import HistoryItem from "./HistoryItem";
import PropTypes from "prop-types";

const LocateHistory = ({ locate }) => {
  return (
    <>
      {locate && (
        <>
          <div className="med font-weight-bold">History</div>
          {locate.history.map((history) => (
            <HistoryItem history={history} locate={locate} key={history.id} />
          ))}
        </>
      )}
    </>
  );
};

LocateHistory.propTypes = {
  locate: PropTypes.object,
};

export default LocateHistory;
