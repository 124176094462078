import React, {useEffect} from "react";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import { HeaderButton, useFormControl, useApi, geofenceApi } from "component-library";
import { ArrowLeftCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Joi } from "component-library";
import { Save } from "react-feather";
import { Alert } from "react-bootstrap";
import { dropdownStyles } from "component-library";
import geofenceTypeOptions from "./geofenceTypeOptions";

const AddPOI = () => {
  const history = useNavigate();

  const schema = {
    name: Joi.saneString().required().label("POI Name"),
    geofence_type: Joi.saneString().label("Geofence Type"),
    active: Joi.number().required().label("Active"),
  };

  const initialData = {
    name: "",
    geofence_type: "",
    active: 1,
  };

  const { error, request, data: createData } = useApi(geofenceApi.createPOI);

  const onSubmit = (formData) => {
    request(formData);
  };

  const { handleSubmit, renderTextField, renderSelectField } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  useEffect(() => {
    if (!!createData?.poi) {
      history(`/fleet-management/pois/edit/${createData.poi.id}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData])
  

  const HeaderButtons = () => (
    <div className="d-flex">
      <HeaderButton icon={<Save size={16} color="#0071ce" />} formId="add_poi" text="Add POI" />
      <HeaderButton
        icon={<ArrowLeftCircle size={16} color="#0071ce" />}
        text="Back to Listing"
        linkTo={`/fleet-management/pois`}
      />
    </div>
  );

  return (
    <InteriorPage
      navTitle={"Field Management"}
      pageTitle="Points of Interest"
      pageTitleSecondaryContent={HeaderButtons}
    >
      <div className="row justify-content-center pt-4">
        {!!error && <Alert variant="danger">{error}</Alert>}
        <div className="col-6">
          <Alert variant="success" dismissible>Add geofences on the edit page after adding your new point of interest.</Alert>
          <form onSubmit={handleSubmit} id="add_poi">
            <div className="row pb-2">
              <div className="col-md-9">
                {renderTextField({
                  name: "name",
                  label: "POI Name",
                  autoFocus: true,
                })}
              </div>
            </div>
            <div className="row pb-2">
              <div className="col-md-6 sm">
                {renderSelectField({
                  name: "geofence_type",
                  label: "Geofence Type",
                  options: geofenceTypeOptions,
                  styles: dropdownStyles,
                })}
              </div>
            </div>
          </form>
        </div>
      </div>
    </InteriorPage>
  );
};

export default AddPOI;
