import React from "react";
import usePinnedList from "../hooks/usePinnedList";
import GridWrapper from "./SingleItems/GridWrapper";
import LibraryGrid from "./SingleItems/LibraryGrid";
import { ReactComponent as Unpinned } from "../../../assets/photoUnpinned.svg";
import { Folder, Grid } from "react-feather";
import styles from "./styles.module.scss";

const GridView = ({
  project,
  photosList,
  photosError,
  dateRange,
  albumsList,
  albumsError,
  loading,
  handlePin,
  handleDelete,
  selectedItems,
  setSelectedItems,
  selectMode,
}) => {
  const { pinnedList } = usePinnedList({
    photosList,
    albumsList,
    dateRange,
  });

  return (
    <div className="position-relative d-flex flex-column gap-5 mt-2">
      {/* pinned list */}
      <GridWrapper
        icon={<Unpinned className={styles.pinIcon} />}
        title="Pinned Photos and Albums"
        loading={loading}
        error={photosError || albumsError}
      >
        <LibraryGrid
          project={project}
          gridItems={pinnedList}
          gridSize="large"
          handlePin={handlePin}
          handleDelete={handleDelete}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectMode={selectMode}
        />
      </GridWrapper>

      {/* albums list */}
      <GridWrapper
        icon={<Folder color="#4F4F4F" size={"1.6rem"} />}
        title="Albums"
        loading={loading}
        error={albumsError}
      >
        <LibraryGrid
          project={project}
          gridItems={albumsList}
          gridSize="large"
          handlePin={handlePin}
          handleDelete={handleDelete}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectMode={selectMode}
        />
      </GridWrapper>

      {/* photos list */}
      <GridWrapper
        icon={<Grid color="#4F4F4F" size={"1.6rem"} />}
        title="All Photos"
        loading={loading}
        error={photosError}
      >
        <LibraryGrid
          project={project}
          gridItems={photosList}
          gridSize="large"
          handlePin={handlePin}
          handleDelete={handleDelete}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectMode={selectMode}
        />
      </GridWrapper>
    </div>
  );
};

export default GridView;
