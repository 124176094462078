import { useState, useEffect } from "react";
import { useAuth } from "component-library";
import { useDrag } from "react-dnd";

const useTimesheet = ({ timesheet, status, editing }) => {
  const { userCan } = useAuth();
  const [unassignedHours, setUnassignedHours] = useState();
  const [canDrag, setCanDrag] = useState();
  const [active, setActive] = useState(false);

  const [{ isDragging }, dragRef] = useDrag({
    type: "unallocated hours",
    item: { timesheet: timesheet, unassignedHours: unassignedHours },
    canDrag: canDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (!!timesheet) {
      const entry_time = timesheet.time_entries.reduce((acc, curr) => {
        return acc + parseInt(curr.minutes, 10);
      }, 0);
      setUnassignedHours(parseInt(timesheet.adjusted_minutes, 10) - entry_time);
    }
  }, [timesheet]);

  useEffect(() => {
    if (unassignedHours > 0) {
      setActive(true);
    }
  }, [unassignedHours]);
  useEffect(() => {
    setCanDrag(!!editing && unassignedHours > 0 && ![1, 4].includes(status) && !!userCan("payroll-review-time"));
  }, [unassignedHours, status, userCan, editing]);

  return {
    active,
    setActive,
    unassignedHours,
    isDragging,
    dragRef,
    canDrag,
  };
};

export default useTimesheet;
