import React from "react";
import { Alert } from "react-bootstrap";
import { initialData } from "../../schema/bid";
import BidTodosForm from "./../BidTodosForm";
import SpecBooksAndPlans from "./../createBid/SpecBooksAndPlans";
import SpecAndPlansLocation from "./../createBid/SpecAndPlansLocation";
import Contacts from "./../createBid/Contacts";
import BidInfo from "./../createBid/BidInfo";
import Milestones from "./../createBid/Milestones";
import ProjectInfo from "./../createBid/ProjectInfo";
import PlanRoom from "./../createBid/PlanRoom";
import ProjectDetails from "./../createBid/ProjectDetails";
import NoticeToBidders from "./../createBid/NoticeToBidders";
import useSaveBidEdits from "./useSaveBidEdits";
import useProjectAttachments from "../../_projects/hooks/useProjectAttachments";

const useCreateBid = (bidObject = initialData, setDocLoading) => {
  const {
    error,
    handleSubmit,
    renderSubmitButton,
    loading,
    renderTextField,
    renderCheckbox,
    renderSelectField,
    renderFieldRowRepeater,
    renderCheckboxInGroup,
    renderRadioGroup,
    bidTodoErrors,
    renderLocationField,
    renderContactsListField,
    data,
    setFields,
  } = useSaveBidEdits({ bidObject, isBid: true });

  const { plansFile, specFile, setSpecFile, setPlansFile, attachmentError, bidderFile, setBidderFile } =
    useProjectAttachments({ bidObject });

  const fields = (
    <>
      {error && (
        <div className="mt-3 px-2">
          <Alert variant="danger">{error}</Alert>
        </div>
      )}
      {attachmentError && (
        <div className="mt-3 px-2">
          <Alert variant="danger">Attachment Error: {attachmentError}</Alert>
        </div>
      )}
      <div className="large-form-section">
        <SpecAndPlansLocation renderTextField={renderTextField} />
        <SpecBooksAndPlans
          specFile={specFile}
          plansFile={plansFile}
          setSpecFile={setSpecFile}
          setPlansFile={setPlansFile}
          projectId={bidObject.id}
          setDocLoading={setDocLoading}
          project={bidObject}
        />
        <PlanRoom renderTextField={renderTextField} />
      </div>
      <div className="large-form-section">
        <BidInfo renderSelectField={renderSelectField} renderTextField={renderTextField} data={data} />
        <NoticeToBidders
          attachment={bidderFile}
          setAttachment={setBidderFile}
          type="Notice to Bidders"
          projectId={bidObject.id}
          typeId={data.type_id}
          setDocLoading={setDocLoading}
          project={bidObject}
        />
      </div>
      <div className="large-form-section">
        <Contacts
          renderLocationField={renderLocationField}
          renderContactsListField={renderContactsListField}
          data={data}
        />
      </div>
      <div className="large-form-section">
        <ProjectInfo
          renderSelectField={renderSelectField}
          renderTextField={renderTextField}
          renderRadioGroup={renderRadioGroup}
          data={data}
          setFields={setFields}
        />
        <ProjectDetails
          renderSelectField={renderSelectField}
          renderCheckbox={renderCheckbox}
          renderTextField={renderTextField}
          data={data}
          setFields={setFields}
        />
      </div>
      <div className="large-form-section">
        <Milestones renderFieldRowRepeater={renderFieldRowRepeater} setFields={setFields} data={data} />
      </div>
      <div className="large-form-section">
        <BidTodosForm data={data} renderCheckbox={renderCheckboxInGroup} setFields={setFields} errors={bidTodoErrors} />
      </div>
    </>
  );

  return {
    initialData,
    fields,
    handleSubmit,
    renderSubmitButton,
    data,
    setFields,
    loading,
  };
};

export default useCreateBid;
