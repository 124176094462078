import React from "react";
import { Edit3, RefreshCcw, XCircle } from "react-feather";
import { Button } from "react-bootstrap";
import { useAuth } from "component-library";
import PropTypes from "prop-types";

const ContactMenuItems = ({
  contact,
  setEditContactOpen,
  setEditingContact,
  canRemove,
  setEditRoleOpen,
  setConfirmModalOpen,
  canEdit = true,
}) => {
  const { userCan } = useAuth();

  return (
    <>
      {userCan("contact-edit") && (
        <>
          {!!canRemove && contact?.role && (
            <li>
              <Button
                size="sm-med"
                variant="link"
                className="p-0 mb-2"
                onClick={() => {
                  setEditingContact(contact);
                  setEditRoleOpen(true);
                }}
              >
                <RefreshCcw size="12" className="attachment-icon attachment-download me-2" />
                <span className="text-gray3">Change Role</span>
              </Button>
            </li>
          )}
          {!!canEdit && (
            <li>
              <Button
                size="sm-med"
                variant="link"
                className="p-0 mb-2"
                onClick={() => {
                  setEditingContact(contact);
                  setEditContactOpen(true);
                }}
              >
                <Edit3 size="12" className="attachment-icon attachment-download me-2" />
                <span className="text-gray3">Edit Contact</span>
              </Button>
            </li>
          )}
          {!!canRemove && (
            <li>
              <Button
                size="sm-med"
                variant="link"
                className="p-0 mb-2"
                onClick={() => {
                  setEditingContact(contact);
                  setConfirmModalOpen(true);
                }}
              >
                <XCircle size="12" className="attachment-icon attachment-download me-2" />
                <span className="text-gray3">Remove From Project</span>
              </Button>
            </li>
          )}
          {/* <li>
            <Button
              size="sm-med"
              variant="link"
              className="p-0 mb-2"
              // onClick={() => {
              //   setEditingContact(contact);
              //   console.log("invite to portal");
              // }}
            >
              <UserPlus
                size="12"
                className="attachment-icon attachment-download me-2"
              />
              <span className="text-gray3">Invite to Portal</span>
            </Button>
          </li> */}
        </>
      )}
    </>
  );
};

ContactMenuItems.propTypes = {
  contact: PropTypes.object.isRequired,
  setEditContactOpen: PropTypes.func.isRequired,
  setEditingContact: PropTypes.func.isRequired,
  canRemove: PropTypes.bool,
  setEditRoleOpen: PropTypes.func.isRequired,
  setConfirmModalOpen: PropTypes.func.isRequired,
};

export default ContactMenuItems;
