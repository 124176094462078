import { Joi } from "component-library";

const schema = {
  albums: Joi.array().label("Album Title"),
  new_album_name: Joi.saneString().label("New Album"),
};

const initialData = {
  albums: [],
  new_album_name: "",
};

export { schema, initialData };
