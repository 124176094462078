import { useEffect, useState } from "react";
import { useApi, projectApi, formatApiDate, useAuth } from "component-library";

const useProjectsMap = () => {
  const { user } = useAuth();
  const { loading: projectsLoading, request, error: projectsError, data } = useApi(projectApi.getProjectsWithGeofences);
  const [projects, setProjects] = useState([]);
  const [geofenceLocations, setGeofenceLocations] = useState();
  const [loading, setLoading] = useState(false);

  const handleUserSearch = (q) => {
    request({
      user_id: user.id,
      q: q,
      date: formatApiDate(new Date()),
    });
  };

  useEffect(() => {
    request({
      user_id: user.id,
      q: "",
      date: formatApiDate(new Date()),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      if (!!data?.projects?.length) {
        setProjects(data.projects);
      } else {
        setProjects([]);
      }
    }
  }, [data]);

  useEffect(() => {
    let geofences = [];
    if (projects?.length) {
      geofences = projects.reduce((acc, curr) => {
        return [
          ...acc,
          ...curr.geofences.map((geo) => {
            return {
              ...geo,
              project: curr,
              color: curr?.favorited === 1 ? "#F0C808" : geo.color,
              geofence: geo.polygons,
            };
          }),
        ];
      }, []);
    }
    setGeofenceLocations(geofences);
  }, [projects]);

  useEffect(() => {
    if (!!projectsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [projectsLoading]);

  return {
    projects,
    loading,
    projectsError,
    geofenceLocations,
    setGeofenceLocations,
    handleUserSearch,
  };
};

export default useProjectsMap;
