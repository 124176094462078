import React from "react";
import { AgGridTable, useAuth } from "component-library";
import weatherListConfig from "../../config/weatherListConfig";
import gridRowHeights from "../../utils/gridRowHeights";

const WeatherHourlyGrid = ({ gridData, loading }) => {
  const { user } = useAuth();

  return (
    <div className="d-flex w-100">
      <AgGridTable
        gridData={gridData}
        gridConfig={weatherListConfig}
        loading={loading}
        rowHeight={gridRowHeights.medium}
        id={`${user.id}_weather_hourly_grid`}
        presetStyle="sortableTable"
      />
    </div>
  );
};

export default WeatherHourlyGrid;
