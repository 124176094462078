import React from "react";
import useAddLocate from "./hooks/useAddLocate";
import { Modal } from "react-bootstrap";
import LocateHistory from "./LocateHistory";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { useApi, projectApi, useAuth } from "component-library";

const AddNewLocate = ({ close, update, locate, setLocates }) => {
  const { user } = useAuth();
  const userName = `${user.first_name} ${user.last_name}`;

  const { fields, handleSubmit, renderSubmitButton, loading } = useAddLocate(
    close,
    update,
    locate,
    userName,
    setLocates
  );

  const { request, loading: saveLocateLoading } = useApi(projectApi.saveLocate);

  const archiveLocate = async () => {
    const { success } = await request({
      id: locate.id,
      number: locate.number || null,
      description: locate.description || null,
      type: locate.type || null,
      sb: locate.sb || 0,
      ds: locate.ds || 0,
      sb_required: locate.sb_required || 0,
      ds_required: locate.ds_required || 0,
      active_date: locate.active_date || null,
      expiration: locate.expiration || null,
      project_id: locate.project_id || null,
      parent_id: locate.parent_id || 0,
      status: "archived",
      user: userName,
    });
    if (success) {
      update();
      close();
    }
  };

  return (
    <Modal
      show={true}
      onHide={() => {
        close();
      }}
    >
      <Modal.Header
        closeButton
        closeLabel={
          !!locate
            ? !!locate.number
              ? `Close Locate #${locate.number} Modal`
              : "Close Locate Modal"
            : "Close Add New Locate Modal"
        }
      >
        <div className="mb-0 med">
          {!!locate ? `Locate #${!!locate.number ? locate.number : ""}` : "Add New Locate"}
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {fields}
          {!!locate && <LocateHistory locate={locate} />}
          <div className="text-end">
            {!!locate && (
              <Button variant="light-gray" onClick={archiveLocate} className="mt-2 me-3 font-weight-bold">
                {saveLocateLoading ? "Archiving" : "Archive Locate"}
              </Button>
            )}
            {renderSubmitButton("Save Locate", !!loading)}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

AddNewLocate.propTypes = {
  close: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  locate: PropTypes.object,
};

export default AddNewLocate;
