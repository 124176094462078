import React, { useState } from "react";
import InteriorPage from "../../../components/pageLayout/InteriorPage";
import HeaderButtons from "./HeaderButtons";
import ProjectSettingsForm from "./ProjectSettingsForm";
import useProject from "../../hooks/useProject";
import { Briefcase } from "react-feather";
import ArchiveModal from "./ArchiveModal";
import { useApi, projectApi, Loader } from "component-library";

const ProjectSettings = () => {
  const { pageTitle, PageTag, loading, error, project, setProject, refreshProject } = useProject();

  const {
    data: archiveData,
    request: archiveRequest,
    error: archiveError,
    loading: archiveLoading,
  } = useApi(projectApi.archiveProject);

  const [canSubmit, setCanSubmit] = useState();
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);

  const HeaderButtonsComponent = () => (
    <HeaderButtons canSubmit={canSubmit} setArchiveModalVisible={setArchiveModalVisible} />
  );

  return (
    <InteriorPage
      loading={loading}
      error={error}
      navTitle={pageTitle}
      pageTitle="Project Settings"
      fillScreen
      navIcon={<Briefcase color="#fff" size={14} />}
      NavContent={PageTag}
      pageTitleSecondaryContent={HeaderButtonsComponent}
    >
      {!!project && (
        <>
          {!!archiveData ? (
            <Loader />
          ) : (
            <ProjectSettingsForm
              initialBid={project}
              setProject={setProject}
              refreshProject={refreshProject}
              setCanSubmit={setCanSubmit}
              archiveModalVisible={archiveModalVisible}
              setArchiveModalVisible={setArchiveModalVisible}
            />
          )}

          <ArchiveModal
            archiveModalVisible={archiveModalVisible}
            setArchiveModalVisible={setArchiveModalVisible}
            data={archiveData}
            loading={archiveLoading}
            error={archiveError}
            request={archiveRequest}
          />
        </>
      )}
    </InteriorPage>
  );
};

export default ProjectSettings;
