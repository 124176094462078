import React from "react";
import useCreateBid from "./hooks/useCreateBid";
import BidSidebar from "./BidSidebar";

const BidDetailsForm = ({ initialBid, setDocLoading }) => {
  const { fields, handleSubmit, setFields, data } = useCreateBid(initialBid, setDocLoading);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-9 order-1 order-lg-0">
          <form onSubmit={handleSubmit} id="bid-details-form">
            {fields}
          </form>
        </div>
        <div className="col-12 col-lg-3 order-0 order-lg-1 fixed-sidebar border-start-lg ps-4-lg">
          <BidSidebar bid={data} setBid={setFields} />
        </div>
      </div>
    </div>
  );
};

export default BidDetailsForm;
