import React, { useEffect, useState } from "react";
import styles from "../PreconChecklist.module.scss";
import { useFormControl } from "component-library";
import { Alert } from "react-bootstrap";
import { Loader } from "component-library";

const ChecklistForm = ({ options, setListValues, listValues, onSubmit, onBlur, loading, error }) => {
  const setValuesArray = (values) => {
    setListValues(values);
    setLoaded(false);
  };

  const { handleSubmit, renderSubmitButton, renderCheckboxInGroup } = useFormControl({
    onSubmit,
  });

  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    if (options && listValues && onBlur && !loaded) {
      onBlur();
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  return (
    <div className="position-relative">
      <Loader loading={loading} />
      {!!error && <Alert variant="danger">{error}</Alert>}
      <form onSubmit={handleSubmit} className={styles.dotCatForm}>
        {!!options &&
          Object.keys(options).map((title) => (
            <div className={styles.dropdownContainer} key={title}>
              <div className="font-weight-bold">{title}</div>
              <div className={styles.groupContainer}>
                {options[title].map((cat) => (
                  <div key={cat.id} className={styles.checkboxContainer}>
                    {renderCheckboxInGroup({
                      groupName: "dot_categories",
                      name: cat.id,
                      label: cat.label,
                      setValuesArray,
                      valuesArray: listValues,
                    })}
                  </div>
                ))}
              </div>
            </div>
          ))}
        {!!onSubmit && <div className="p-3 d-flex w-100 justify-content-end">{renderSubmitButton("Save")}</div>}
      </form>
    </div>
  );
};

export default ChecklistForm;
