import React from "react";
import { useFormControl, Loader, useApi, bidApi } from "component-library";
import { schema, initialData } from "../../schema/eligibility";
import { Alert } from "react-bootstrap";
import useUpdateTodos from "./useUpdateTodos";

const useEligibilityRequirement = (task, setTodos, setBid) => {
  const { request, data: updateData, loading, error } = useApi(bidApi.completeToDo);

  const { dataError, setDataError } = useUpdateTodos(updateData, setTodos, setBid);

  const onSubmit = async (formData, setLoading) => {
    setLoading(true);
    try {
      setDataError(false);
      await request({
        project_id: task.project_id,
        todo_id: task.bid_todo.id,
        complete: 1,
        ...formData,
      });
      setLoading(false);
    } catch (message) {
      setLoading(false);
    }
  };

  const { handleSubmit, renderSubmitButton, renderTextField, renderCheckbox, setFields } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const fields = (
    <>
      <Loader loading={!!loading} />
      {renderTextField({
        name: "eligibility_subcontract_amount",
        label: "Subcontract Amount",
      })}
      {renderTextField({
        name: "eligibility_woh",
        label: "WOH",
      })}
      {renderCheckbox({
        name: "eligibility_request",
        label: "Submit eligibility request on bid",
      })}
      <p>
        <a className="btn-link" href="https://www.bidx.com" target="_blank" rel="noreferrer">
          Bidx
        </a>
      </p>
      {!!error && <Alert variant="danger">{error}</Alert>}
      {!!dataError && <Alert variant="danger">Something went wrong. Please try again.</Alert>}
    </>
  );
  return {
    schema,
    initialData,
    fields,
    handleSubmit,
    renderSubmitButton,
    setFields,
  };
};

export default useEligibilityRequirement;
