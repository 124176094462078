import { useState, useEffect } from "react";
import { useApi, dailyFieldlogsApi } from "component-library";
import PropTypes from "prop-types";
import useDailyFieldLogs from "../context/useDailyFieldLogs";

const useNotes = () => {
  const { getRequestParams, updateRequests } = useDailyFieldLogs();
  const [active, setActive] = useState(true);
  const [notes, setNotes] = useState();
  const [addingNote, setAddingNote] = useState(false);

  const {
    request: notesRequest,
    data: notesData,
    error: notesError,
    loading: notesLoading,
  } = useApi(dailyFieldlogsApi.getNotes);

  useEffect(() => {
    if (!!updateRequests) {
      const requestParams = getRequestParams();
      notesRequest(requestParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRequests]);

  useEffect(() => {
    if (notesData?.comments) {
      setNotes(notesData.comments);
    } else {
      setNotes();
    }
  }, [notesData?.comments]);

  return {
    active,
    setActive,
    notes,
    setNotes,
    addingNote,
    setAddingNote,
    notesError,
    notesLoading,
  };
};

useNotes.propTypes = {
  selectedForemenIds: PropTypes.array,
};

export default useNotes;
