import { useEffect, useState } from "react";
import useProject from "../../hooks/useProject";
import { useApi, quoteApi } from "component-library";

const useQuotesForPO = () => {
  const { project } = useProject();
  const [quotes, setQuotes] = useState([]);
  const [filteredQuotes, setFilteredQuotes] = useState([]);

  const { data, loading, error, request } = useApi(quoteApi.getQuotesForPO);

  const filterQuotes = (q) => {
    setFilteredQuotes(
      quotes.filter(
        (quote) =>
          quote.supplier.company.name.toLowerCase().indexOf(q.toLowerCase()) >
            -1 ||
          quote.id.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  };

  useEffect(() => {
    if (project && project.id) request(project.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (!!data && data.quotes) setQuotes(data.quotes);
  }, [data]);

  useEffect(() => {
    setFilteredQuotes(quotes);
  }, [quotes]);

  return {
    filteredQuotes,
    filterQuotes,
    loading,
    error,
  };
};

export default useQuotesForPO;
