import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Table, Alert } from "react-bootstrap";
import LineItem from "./LineItem";
import { currency } from "component-library";
import { Link } from "react-router-dom";
import { Plus } from "react-feather";

const LineItems = ({ quote, hasFooter = true, setQuote }) => {
  const [lineError, setLineError] = useState(false);
  const [editTotal, setEditTotal] = useState(!!quote ? quote.amount : 0);
  const [originalTotal, setOriginalTotal] = useState(!!quote ? quote.amount : 0);

  useEffect(() => {
    let newTotal = quote.line_items.reduce((previousScore, currentScore) => previousScore + currentScore.ext_price, 0);
    setOriginalTotal(newTotal);

    if (quote.status === 2) {
      newTotal = quote.requote.line_items.reduce((previousScore, currentScore) => previousScore + currentScore.ext_price, 0)
    }
    setEditTotal(newTotal)
  }, [quote]);

  return (
    <>
      <div className="xxl">Line Items</div>
      {!!lineError && (
        <Alert variant="danger" dismissible onClick={() => setLineError()}>
          {lineError}
        </Alert>
      )}
      <Table responsive className="sortable-table">
        {!!quote && quote.status !== 2 ? (
          <thead>
            <tr>
              <th>
                <span>PART #</span>
              </th>
              <th
                style={{
                  width: "45%",
                }}
              >
                <span>Item</span>
              </th>
              <th className="text-end">
                <span>Qty</span>
              </th>
              <th></th>
              <th>
                <span>Unit Price</span>
              </th>
              <th className="text-end">
                <span>Ext Price</span>
              </th>
            </tr>
          </thead>
        ) : (
          <thead>
            <tr>
              <th
                style={{
                  width: "45%",
                }}
              >
                <span>Item</span>
              </th>
              <th className="text-end">
                <span>Qty</span>
              </th>
              <th></th>
              <th>
                <span>Est Delivery</span>
              </th>
              <th className="text-end">
                <span>Price</span>
              </th>
            </tr>
          </thead>
        )}
        <tbody>
          <>
            {!!quote && !!quote.line_items && !!quote.line_items.length && (
              <>
                {quote.status === 2
                  ? !!quote &&
                    !!quote.requote &&
                    !!quote.requote.line_items.length &&
                    quote.requote.line_items.map((item, index) => (
                      <tr className="position-relative" key={index}>
                        <LineItem
                          quote={quote}
                          quoteItem={quote.line_items.find((i) => i.takeoff_id === item.takeoff_id)}
                          item={item}
                          setQuote={setQuote}
                          setLineError={setLineError}
                        />
                      </tr>
                    ))
                  : !!quote &&
                    !!quote.line_items &&
                    !!quote.line_items.length &&
                    quote.line_items.map((item, index) => (
                      <tr className="position-relative" key={index}>
                        <LineItem
                          quote={quote}
                          quoteItem={quote.line_items.find((i) => i.takeoff_id === item.takeoff_id)}
                          item={item}
                          setQuote={setQuote}
                          setLineError={setLineError}
                        />
                      </tr>
                    ))}
              </>
            )}
          </>
        </tbody>
        {!!hasFooter && (
          <tfoot className="text-gray2 q-section">
            <tr>
              <td className="bg-transparent" colSpan={!!quote && quote.status !== 2 ? 2 : null}>
                <Link to={`/project/${quote.project_id}/quotes/${quote.id}/add-line-items`}>
                  Add Line Item(s)
                  <Plus size="12" />
                </Link>
              </td>
              <td colSpan={!!quote && quote.status !== 2 ? 4 : 4} className="bg-transparent">
                <div className="q-sumDisplay">
                  <span className="q-label">
                    {quote.status !== 2 ? (
                      <>
                        <span className="pe-2">Total</span> {currency(editTotal)}
                      </>
                    ) : (
                      <>
                        <span className="pe-2">{originalTotal !== editTotal ? "Previous Total" : "Total"}</span>
                        {currency(quote.amount)}
                      </>
                    )}
                  </span>
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </Table>
    </>
  );
}

LineItems.propTypes = {
  quote: PropTypes.object,
  hasFooter: PropTypes.bool,
  setQuote: PropTypes.func,
};

export default LineItems;
