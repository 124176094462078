import React from "react";
import { UserCheck } from "react-feather";
import InteriorPage from "../pageLayout/InteriorPage";

const VendorManagement = () => {
  return (
    <InteriorPage
      navTitle="Vendor Management"
      navIcon={<UserCheck color="#fff" size={14} />}
    >
      <p>Vendor Management page.</p>
    </InteriorPage>
  );
};

export default VendorManagement;
