import React from "react";
import ManHoursGrid from "./ManHoursGrid.js";
import PageHeader from "./PageHeader.js";
import DashboardPage from "./DashboardPage.js";
import useDashboard from "./useDashboard.js";

const ManHoursPage = () => {
  const { jobs } = useDashboard();
  return (
    <DashboardPage>
      <PageHeader title="Man Hours" />
      <div className="row">
        <ManHoursGrid jobs={jobs} />
      </div>
    </DashboardPage>
  );
};

export default ManHoursPage;
