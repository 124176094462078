import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import styles from "./styles.module.scss";

const ScoreCard = ({
  job: {
    scorecard: { summary, detail },
  },
}) => {
  const [showModal, setShowModal] = useState();
  const [subset, setSubset] = useState();

  const groupedScorecard = detail.reduce(
    (entryMap, e) =>
      entryMap.set(e.description, [...(entryMap.get(e.description) || []), e]),
    new Map()
  );

  return (
    <section className="col mb-4 mt-3">
      <h2 className={styles.header}>Scorecard</h2>
      <Table striped hover>
        <thead>
          <tr>
            <th>Description</th>
            <th>Budgeted Cost</th>
            <th>Actual Cost</th>
          </tr>
        </thead>
        <tbody>
          {summary.map((entry) => (
            <tr
              key={entry.description}
              onClick={() => {
                setSubset(entry.description);
                setShowModal(true);
              }}
              className="link"
            >
              <td>{entry.description}</td>
              <td>{`${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(parseFloat(entry.budgeted))}`}</td>
              <td>{`${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(parseFloat(entry.amount))}`}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{!!subset && subset}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped hover>
            <thead>
              <tr>
                <th>Date Booked</th>
                <th>Amount</th>
                <th>Employee Id</th>
                <th>Transaction Id</th>
              </tr>
            </thead>
            <tbody>
              {!!subset &&
                !!groupedScorecard.get(subset) &&
                groupedScorecard.get(subset).map((entry, i) => (
                  <tr
                    key={`${entry.date_booked}${entry.amount}${entry.description}${entry.retainage}${i}`}
                  >
                    <td>
                      {Intl.DateTimeFormat("en").format(
                        new Date(entry.date_booked)
                      )}
                    </td>
                    <td>{`${new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(parseFloat(entry.amount))}`}</td>
                    <td>{entry.employee_id}</td>
                    <td>{entry.transaction_id}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default ScoreCard;
