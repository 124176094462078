import { useState, useEffect } from "react";
import useProjectFileUpload from "../hooks/useProjectFileUpload";
import { useApi, projectApi, useToasts, useAuth } from "component-library";

const useProjectAttachment = ({
  fileName,
  projectId,
  todoId,
  setDocLoading,
  type,
  setAttachment,
  isSingle,
  setFileId,
  refreshAttachments,
}) => {
  const { config } = useAuth();
  const { triggerToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [percentComplete, setPercentComplete] = useState(0);
  const [fileError, setFileError] = useState(false);

  const {
    request: apiRemoveAttachment,
    data: removeAttachmentData,
    loading: removeFileLoading,
    error: removeFileError,
  } = useApi(projectApi.removeAttachment);

  const {
    request: updateAttachmentInfoRequest,
    data: updateAttachmentInfoData,
    loading: updateAttachmentInfoLoading,
    error: updateAttachmentInfoError,
  } = useApi(projectApi.updateAttachmentInfo);

  const handleMoveFile = async (replacementFile, oldFileId, type, folder) => {
    updateAttachmentInfoRequest({ id: oldFileId, folder: folder, file_name: fileName });
  };

  const updateAttachment = async (file, parentId) => {
    uploadFile(file, type, parentId);
  };

  const addAttachment = async (file, file_type) => {
    uploadFile(file, file_type);
  };

  const removeAttachment = async (file) => {
    handleSetLoading(true);
    setPercentComplete(0);
    await apiRemoveAttachment({
      project_id: projectId,
      attachment_id: file.id,
      todo_id: todoId,
    });
    handleSetLoading(false);
  };

  const handleSetLoading = (val) => {
    setLoading(val);
    if (typeof setDocLoading === "function") {
      setDocLoading(val);
    }
  };

  const { uploadFile, updatedData, error } = useProjectFileUpload({
    bidId: projectId,
    setLoading: handleSetLoading,
    setPercentComplete,
    todoId,
  });

  useEffect(() => {
    if (updatedData) {
      if (isSingle) {
        setAttachment(updatedData.project.attachments.find((attachment) => attachment.type === type));
      } else {
        setAttachment(updatedData.project.attachments.filter((attachment) => attachment.type === type));
      }
      if (setFileId) setFileId(updatedData.file_id);
      if (!!setDocLoading) setDocLoading(false);
      if (!!refreshAttachments) refreshAttachments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedData]);

  useEffect(() => {
    if (removeAttachmentData && removeAttachmentData.project) {
      triggerToast("File Deleted", `File successfully deleted:`, "file-delete-success", "success");

      if (isSingle) {
        setAttachment(removeAttachmentData.project.attachments.find((attachment) => attachment.type === type));
      } else {
        setAttachment(removeAttachmentData.project.attachments.filter((attachment) => attachment.type === type));
      }
      if (setFileId) setFileId(0);
      if (!!setDocLoading) setDocLoading(false);
      if (!!refreshAttachments) refreshAttachments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeAttachmentData]);

  useEffect(() => {
    if (!!error && typeof error === "number") {
      removeAttachment({ id: error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!!removeFileError || !!error) {
      setFileError(removeFileError || error);
    } else {
      setFileError();
    }
  }, [removeFileError, error]);

  useEffect(() => {
    if (!!updateAttachmentInfoData?.success) {
      triggerToast(
        "File Folder Updated",
        `${updateAttachmentInfoData?.file?.file_name} folder updated to ${updateAttachmentInfoData?.file?.folder}`,
        "attachment-move-success",
        "success"
      );
      if (!!refreshAttachments) refreshAttachments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAttachmentInfoData]);

  return {
    config,
    loading,
    setLoading,
    percentComplete,
    setPercentComplete,
    handleSetLoading,
    removeAttachment,
    updateAttachment,
    addAttachment,
    handleMoveFile,
    removeFileLoading,
    fileError,
    removeAttachmentData,
    updateAttachmentInfoLoading,
    updateAttachmentInfoError,
  };
};

export default useProjectAttachment;
