import React from "react";

const Address = ({ data }) => {
  return data.address1 ? (
    <div>
      {data.address1}{data.address2 && `, ${data.address2}`}
    </div>
  ) : null;
};

export default Address;
