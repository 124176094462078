import React, { useState } from "react";
import { ChevronDown, ChevronRight } from "react-feather";
import { Collapse } from "react-bootstrap";
import styles from "./styles.module.scss";
import { ReactComponent as UnSelectedNote } from "../../../../../assets/unselectedNote.svg";

const CollapsibleSection = ({ title, children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header} onClick={handleCollapse}>
        <div className={styles.title}>{title}</div>
        <div className="d-flex align-items-center gap-3">
          <div className={styles.allSection}>
            <div className={styles.allText}>All</div>
            <UnSelectedNote color="#A7A7A7" size={16} />
          </div>
          {collapsed ? <ChevronRight size="16" color={"#000000"} /> : <ChevronDown size="16" color={"#000000"} />}
        </div>
      </div>
      <Collapse in={!collapsed}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default CollapsibleSection;
