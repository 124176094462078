import { useEffect, useState } from "react";
import { projectApi, useApi } from "component-library";
import { useParams } from "react-router-dom";

const useOnSiteCrew = () => {
  const [onSiteCrew, setOnSiteCrew] = useState([]);
  const { id } = useParams();
  const {
    request,
    data,
    loading: crewLoading,
    error: crewError,
  } = useApi(projectApi.getOnSiteCrew);

  useEffect(() => {
    if (!!id) {
      request(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!!data && !!data.users) {
      setOnSiteCrew(data.users);
    }
  }, [data]);
  return {
    onSiteCrew,
    crewLoading,
    crewError,
  };
};

export default useOnSiteCrew;
