import Filename from "../Columns/Filename";

const filename = {
  headerName: "Form",
  field: "form_name",
  resizable: true,
  flex: 1,
  cellRenderer: Filename,
};

export default filename;
