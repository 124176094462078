import React from 'react'
import styles from "./styles.module.scss";
import { ChevronDown, ChevronRight } from 'react-feather'
import { BeatLoader } from 'react-spinners';
import { Alert, Collapse } from 'react-bootstrap';

const DailyAccordion = ({
  headerContent,
  active,
  setActive,
  loading,
  error,
  wrapperStyles = styles.wrapper,
  zebraStripped,
  children,
}) => {

  const handleCollapse = () => {
    setActive(!active);
  };

  return (
    <div className={`${wrapperStyles} ${zebraStripped ? styles.zebrify : ""}`}>
      <div className={styles.headerWrapper}>
        {headerContent}
        <div className={`ms-2 me-2`} onClick={handleCollapse}>
          {active ? <ChevronRight color="#000" size="15" /> : <ChevronDown color="#000" size="15" />}
        </div>
      </div>
      <Collapse in={!active}>
        <div>
          {!!error && <Alert variant="danger">{error}</Alert>}
          {!!loading && (
            <div className="d-flex justify-content-center mt-5">
              <BeatLoader color="#0017CE" />
            </div>
          )}
          {!loading && <>{children}</>}
        </div>
      </Collapse>
    </div>
  )
};

export default DailyAccordion;