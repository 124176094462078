import React from "react";
import styles from "./styles.module.scss";
import fieldlogStatusColors from "../../../../../../../utils/fieldlogStatusColors";

const Status = ({ value }) => {
  return (
    <div className={styles.typeWrap}>
      <div className={`${styles.status}`} style={{ backgroundColor: fieldlogStatusColors[value] }}>
        {value}
      </div>
    </div>
  );
};

export default Status;
