import React from "react";
import ProjectListItem from "./ProjectListItem";
import CustomScroll from "react-custom-scroll";
import styles from "./styles.module.scss";

const ProjectsList = ({ projects }) => {
  return (
    <section className={`${styles.list} col-4`}>
      <CustomScroll heightRelativeToParent="100%">
        <div>
          {!!projects?.length ? (
            projects.map((project) => <ProjectListItem key={project.id} project={project} />)
          ) : (
            <div className="font-weight-bold p-4">No project results.</div>
          )}
        </div>
      </CustomScroll>
    </section>
  );
};

export default ProjectsList;
