import { useState, useEffect } from "react";
import adminApi from "./../../api/admin";
import { useApi, useFormControl } from "component-library";
import { schema, initialData } from "./../../schema/resetPassword";
import { Alert } from "react-bootstrap";

const useEditPassword = ({ editUser, setChangePassword, setChangedPasswordMessage }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const {
    request: passwordUpdateRequest,
    data: passwordUpdateData,
    error: passwordUpdateError,
  } = useApi(adminApi.updateUser);

  const onSubmit = async (data, setLoading) => {
    setLoading(true);
    const processedFields = { ...data, id: editUser?.id, email: editUser?.email };
    try {
      const result = await passwordUpdateRequest(processedFields);
      if (result?.success) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const { handleSubmit, renderSubmitButton, renderTextField, setFields } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  useEffect(() => {
    if (passwordUpdateData) {
      setChangePassword(false);
      setChangedPasswordMessage("User password successfully reset");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordUpdateData]);

  const fields = (
    <>
      {passwordUpdateError && <Alert variant="danger">{passwordUpdateError}</Alert>}
      <div className="row">
        <div className="col-md-6">
          {renderTextField({
            name: "password",
            label: "Password",
            type: "password",
            showPassword: showPassword,
            setShowPassword: setShowPassword,
            hasIcon: true,
          })}
        </div>
        <div className="col-md-6">
          {renderTextField({
            name: "password_confirmation",
            label: "Confirm Password",
            type: "password",
            showPassword: showPasswordConfirmation,
            setShowPassword: setShowPasswordConfirmation,
            hasIcon: true,
          })}
        </div>
      </div>
    </>
  );

  return {
    renderSubmitButton,
    setFields,
    fields,
    passwordUpdateError,
    handleSubmit,
  };
};

export default useEditPassword;
