import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { MessageCircle, ChevronDown, ChevronRight, Trash2 } from "react-feather";
import { Button } from "react-bootstrap";
import TaskNotesModal from "../../../../../../components/TaskNotesModal/TaskNotesModal";
import PropTypes from "prop-types";
import convertMinutesToTime from "../../../../../../utils/convertMinutesToTime";
import { useApi, fieldLogsApi, ConfirmModal, useAuth } from "component-library";
import getTaskNumber from "../getTaskNumber";

const TaskHeader = ({
  task,
  taskOpen,
  fieldLog,
  setFieldLog,
  foremanId,
  editing,
  projectId,
  entries,
  toggleTasksOpen,
}) => {
  const [taskHours, setTaskHours] = useState();
  const [openNotes, setOpenNotes] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const { userCan } = useAuth();
  const {
    request: removeRequest,
    data: removeData,
    loading: removeLoading,
    error: removeError,
  } = useApi(fieldLogsApi.removeFieldLogTask);

  const toggleNotes = (e) => {
    e.stopPropagation();
    setOpenNotes(true);
  };

  const handleRemoveTask = (e) => {
    e.stopPropagation();
    removeRequest(task.id, fieldLog.id, foremanId);
  };

  useEffect(() => {
    if (!!removeData) {
      setFieldLog({
        ...fieldLog,
        work_day_tasks: fieldLog.work_day_tasks.filter((t) => t.id !== task.id),
        time_sheets: fieldLog.time_sheets.map((sheet) => {
          return {
            ...sheet,
            time_entries: sheet.time_entries.filter((e) => e.work_day_task_id !== task.id),
          };
        }),
      });
      setOpenConfirmModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeData]);

  useEffect(() => {
    if (!!entries && !!entries.length) {
      setTaskHours(entries.reduce((acc, curr) => acc + parseInt(curr.minutes), 0));
    } else {
      setTaskHours(0);
    }
  }, [entries]);

  const handleUpdate = (comments) => {
    if (fieldLog && task) {
      setFieldLog({
        ...fieldLog,
        work_day_tasks: fieldLog.work_day_tasks.map((t) => {
          if (t.id === task.id) {
            return {
              ...t,
              task_comments: comments,
            };
          } else {
            return t;
          }
        }),
      });
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          toggleTasksOpen(task.id);
        }}
        variant="link"
        className={`${styles.task} ${!!taskOpen ? styles.taskOpen : undefined} position-relative px-3 py-3`}
        aria-label={!!taskOpen ? `collapse ${task?.task?.name} task` : `expand ${task?.task?.name} task`}
      >
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <div className="d-flex align-items-center">
              <h1 className={`med mb-0 ${styles.taskName}`}>{task?.task?.name}</h1>
              {!!editing && userCan("payroll-review-time") && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenConfirmModal(true);
                  }}
                  variant="link"
                  className="ms-1"
                  aria-label={`delete ${task?.task?.name}`}
                >
                  <Trash2 size="20" color="#D3D3D3" />
                </Button>
              )}
            </div>
            <div className="sm-med">
              {`${getTaskNumber(task?.task)} `}
              {!!task?.phase ? task.phase.name : "Unscheduled"}
            </div>
          </div>
          <div className={styles.taskRightContent}>
            <Button onClick={(e) => toggleNotes(e)} variant="link" className="med text-gray3">
              <MessageCircle className="me-1" size={16} color={"#0071ce"} />
              {task?.task_comments?.length || 0} Notes
            </Button>
            <span className={`font-weight-bold xl ms-2 me-2 ${styles.taskTime}`}>
              {convertMinutesToTime(taskHours)}
            </span>
            {!!taskOpen ? <ChevronDown size={20} color={"#7b7b7b"} /> : <ChevronRight size={20} color={"#7b7b7b"} />}
          </div>
        </div>
      </Button>
      <TaskNotesModal
        task={task}
        setOpenNotes={setOpenNotes}
        openNotes={openNotes}
        projectId={projectId}
        handleUpdate={handleUpdate}
      />
      <ConfirmModal
        showConfirmation={openConfirmModal}
        setShowConfirmation={setOpenConfirmModal}
        confirmFunction={handleRemoveTask}
        loading={removeLoading}
        error={removeError}
        name={!!task ? task.task?.name : ""}
        message="Are you sure you want to remove this task?"
      />
    </>
  );
};

TaskHeader.propTypes = {
  task: PropTypes.object,
  taskOpen: PropTypes.bool,
  setTaskOpen: PropTypes.func,
  setFieldLog: PropTypes.func,
  fieldLog: PropTypes.object,
  foremanId: PropTypes.string,
  editing: PropTypes.bool,
};

export default TaskHeader;
