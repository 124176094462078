import React from "react";
import useDashboard from "./useDashboard";
import styles from "./styles.module.scss";
import Chiclet from "./Chiclet";
import JobStatusChart from "./JobStatusChart";
import ManHoursItemChart from "./ManHoursItemChart";
import CashflowChart from "./CashflowChart";
import InvoiceChart from "./InvoiceChart";
import DollarsPerJobOverallChart from "./DollarsPerJobOverallChart";
import DashboardPage from "./DashboardPage";
import ProjectsMap from "./ProjectsMap/ProjectsMap";
import ArrowLink from "../components/ArrowLink/ArrowLink";

const Dashboard = () => {
  const { aggregateStats } = useDashboard();

  return (
    <div>
      <DashboardPage>
        <div className="row mb-5">
          <ProjectsMap />
        </div>
        <div className="row mb-5">
          <div className="col-lg-4 mb-3">
            {!!aggregateStats && aggregateStats.project_status && (
              <Chiclet>
                <JobStatusChart />
              </Chiclet>
            )}
          </div>
          <div className="col-lg-4 mb-3">
            <Chiclet>
              <div>
                <h2 className={styles.header}>Man Hours</h2>
                <ManHoursItemChart job={aggregateStats} colors={["#0071CE", "#b2b6be"]} isLarge compact />
              </div>
              <ArrowLink url="/dashboard/man-hours" label="view man hours by job" />
            </Chiclet>
          </div>
        </div>
        {!!aggregateStats && aggregateStats.cashflow && (
          <div className="row mb-5">
            <div className="col">
              <CashflowChart cashflow={aggregateStats.cashflow} isAggregate onDashboard />
            </div>
          </div>
        )}
        <div className="row pb-3">
          <div className="col-lg-7 mb-3 d-flex">
            {aggregateStats && (
              <Chiclet className="homeChartWrap tall flex-grow-1 d-flex flex-column justify-content-between">
                <div>
                  <h2 className={styles.header}>Current AR</h2>
                  <InvoiceChart invoiceTotals={aggregateStats.invoices} />
                </div>
                <ArrowLink url="/dashboard/invoicing" label="view accounts receivable by job" />
              </Chiclet>
            )}
          </div>
          {aggregateStats && (
            <div className="col-lg-5 mb-3">
              <DollarsPerJobOverallChart />
            </div>
          )}
        </div>
      </DashboardPage>
    </div>
  );
};

export default Dashboard;
