import React from "react";
import { Tag } from "react-feather";
import InteriorPage from "../../../../components/pageLayout/InteriorPage";
import useQuote from "./useQuote";
import { RiseLoader } from "react-spinners";
import useProject from "../../../hooks/useProject";
import QuoteDetail from "./QuoteDetail";
import Requote from "./Requote";

const Quote = () => {
  const { pageTitle, project, PageTag } = useProject();
  const {
    error,
    quote,
    setQuote,
    cancelRequote,
    sendRequote,
    getLoading,
    loading,
    setLoading,
    addPOData,
    addPurchaseOrder,
    requote,
  } = useQuote();

  return (
    <InteriorPage
      loading={getLoading}
      error={error}
      navTitle={pageTitle}
      navIcon={<Tag color="#fff" size={14} />}
      pageTitle={`Quote ${!!quote ? quote.id : ""}`}
      NavContent={PageTag}
    >
      <div className="position-relative">
        {loading && (
          <div className="loaderWrapper">
            <RiseLoader color={"#0071ce"} />
          </div>
        )}
        {!!quote && (
          <>
            {quote.status === 2 && !!quote.requote ? (
              <Requote
                quote={quote}
                requote={requote}
                cancelRequote={cancelRequote}
                sendRequote={sendRequote}
                setQuote={setQuote}
              />
            ) : (
              <QuoteDetail
                addPurchaseOrder={addPurchaseOrder}
                quote={quote}
                setQuote={setQuote}
                setLoading={setLoading}
                addPOData={addPOData}
                requote={requote}
                cancelRequote={cancelRequote}
                backLink={`/project/${!!project && project.id}/quotes`}
              />
            )}
          </>
        )}
      </div>
    </InteriorPage>
  );
};

export default Quote;
