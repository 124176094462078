import {
  job_number,
  name,
  project_manager,
  onsite,
  category,
  favorite,
} from "./columnDefs";

const columnDefs = [
  job_number,
  name,
  project_manager,
  onsite,
  category,
  favorite,
];

export default columnDefs;