import { useState, useEffect } from "react";
import { useApi, taskDetailApi } from "component-library";
import { useParams } from "react-router-dom";

const useTakeoffs = () => {
  const { id, taskId } = useParams();
  const [takeoffs, setTakeoffs] = useState([]);

  const {
    request: takeoffsRequest,
    data: takeoffsData,
    loading: takeoffsLoading,
    error: takeoffsError,
  } = useApi(taskDetailApi.getTaskTakeoffs);

  useEffect(() => {
    if (taskId) {
      takeoffsRequest({ projectId: id, taskId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  useEffect(() => {
    setTakeoffs(takeoffsData?.takeoffs || []);
  }, [takeoffsData]);

  return {
    takeoffs,
    takeoffsLoading,
    takeoffsError,
  };
};

export default useTakeoffs;
