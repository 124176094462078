import { useState, useEffect } from "react";

const useInspectionModal = ({
  inspectionData,
  truckFieldsData,
  truckFieldsRequest,
  trailerFieldsData,
  trailerFieldsRequest,
  request,
  data,
}) => {
  const [truckDefects, setTruckDefects] = useState([]);
  const [trailerDefects, setTrailerDefects] = useState([]);
  const [truckDefectTypes, setTruckDefectTypes] = useState();
  const [trailerDefectTypes, setTrailerDefectTypes] = useState();
  const [photos, setPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState();
  const [selectedEquipment, setSelectedEquipment] = useState();

  useEffect(() => {
    if (data?.data?.photo_url_01) {
      setPhotos([
        data.data.photo_url_01,
        data?.data?.photo_url_02,
        data?.data?.photo_url_03,
        data?.data?.photo_url_04,
        data?.data?.photo_url_05,
        data?.data?.photo_url_06,
        data?.data?.photo_url_07,
        data?.data?.photo_url_08,
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (inspectionData?.inspect_id) {
      request(inspectionData.inspect_id);
      truckFieldsRequest("truck_checkboxes");
      trailerFieldsRequest("trailer_checkboxes");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionData?.inspect_id]);

  useEffect(() => {
    if (data?.data?.truck_checklist) {
      setTruckDefects(JSON.parse(data?.data?.truck_checklist));
    }
    if (data?.data?.truck_checklist) {
      setTrailerDefects(JSON.parse(data?.data?.trailer_checklist));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.truck_checklist]);

  useEffect(() => {
    if (truckFieldsData?.data) {
      setTruckDefectTypes(Object.entries(truckFieldsData.data).map((defect) => defect));
    }
  }, [truckFieldsData]);

  useEffect(() => {
    if (trailerFieldsData) {
      setTrailerDefectTypes(Object.entries(trailerFieldsData.data).map((defect) => defect));
    }
  }, [trailerFieldsData]);

  return {
    truckDefects,
    trailerDefects,
    truckDefectTypes,
    trailerDefectTypes,
    photos,
    selectedPhotos,
    setSelectedPhotos,
    selectedEquipment,
    setSelectedEquipment,
  };
};

export default useInspectionModal;
