import React, { useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { Edit3 } from "react-feather";
import QuickAddSubmittal from "./QuickAddSubmittal";
import styles from "./styles.module.scss";
import { useAuth } from "component-library";
import PropTypes from "prop-types";

const SubmittalRow = ({ submittal, updateSubmittal, projectOwner }) => {
  const { config } = useAuth();
  const [edit, setEdit] = useState(false);
  return edit ? (
    <QuickAddSubmittal
      submittal={submittal}
      addSubmittal={(sub) => {
        updateSubmittal(sub);
      }}
      projectOwner={projectOwner}
      setIsEdit={setEdit}
      isEdit
    />
  ) : (
    <div className={`d-table-row ${styles["submittal-row"]}`}>
      <div className={`d-table-cell ${styles["sub-cell"]}`}>
        <b>{submittal.name}</b>
      </div>
      <div className={`d-table-cell ${styles["sub-cell"]}`}>
        {submittal.source ? `${submittal.source.first_name} ${submittal.source.last_name}` : ""}
      </div>
      <div className={`d-table-cell ${styles["sub-cell"]}`}>{submittal.spec_section}</div>
      <div className={`d-table-cell ${styles["sub-cell"]}`}>{submittal.plans_section}</div>
      <div className={`d-table-cell ${styles["sub-cell"]}`}>
        {submittal.requirements &&
          submittal.requirements.map((req, i) => {
            return i < 2 ? (
              <Badge pill bg="light" className="light-text text-uppercase" key={req}>
                {config.submittal_requirements.find((r) => r.id === req).name}
              </Badge>
            ) : null;
          })}
        {!!submittal.requirements && submittal.requirements.length > 2 && (
          <span className="sm light-text">{` +${submittal.requirements.length - 2}...`}</span>
        )}
      </div>
      <div className={`d-table-cell ${styles["sub-cell"]}`}>
        <Button variant="link" size="sm" color="#8f8f8f" onClick={() => setEdit(true)}>
          <Edit3 size="14" color="#c4c4c4" />
        </Button>
      </div>
    </div>
  );
};

SubmittalRow.propTypes = {
  submittal: PropTypes.object,
  updateSubmittal: PropTypes.func.isRequired,
  projectOwner: PropTypes.object,
};

export default SubmittalRow;
