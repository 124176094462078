import React, { useEffect, useState } from "react";
import { AgGridTable, FilterSearch, useAuth } from "component-library";
import InteriorPage from "../../components/pageLayout/InteriorPage";
import ListHeaderButtons from "./ListHeaderButtons";
import useRFQs from "./useRFQs";
import { Briefcase } from "react-feather";
import rfqListConfig from "../../config/rfqListConfig";
import gridRowHeights from "../../utils/gridRowHeights";

const RFQsList = () => {
  const { rfqs, loading, error, bid } = useRFQs();
  const [filteredRFQs, setFilteredRFQs] = useState([]);
  const { user } = useAuth();

  const filterRFQs = (q) => {
    setFilteredRFQs(
      rfqs.filter(
        (rfq) =>
          rfq.company.name.toLowerCase().indexOf(q.toLowerCase()) > -1 ||
          (!!rfq.quote && rfq.quote.id.toString().toLowerCase().indexOf(q.toLowerCase()) > -1)
      )
    );
  };

  useEffect(() => {
    setFilteredRFQs(rfqs);
  }, [rfqs]);

  return (
    <InteriorPage
      navTitle={(!!bid && bid.name) || ""}
      pageTitle="RFQs"
      navIcon={<Briefcase color="#fff" size={14} />}
      error={error}
      loading={loading}
      pageTitleSecondaryContent={ListHeaderButtons}
      titleTag="Bids — Quotes"
    >
      <>
        <div className="d-flex align-items-center mb-3">
          <FilterSearch searchFunction={filterRFQs} inline style={{ minWidth: 300 }} placeholder="Search RFQs..." />
        </div>
        <AgGridTable
          gridData={filteredRFQs.map((rfq) => ({
            ...rfq,
            supplier_name: rfq.company?.name,
            certifications: rfq.company?.certifications,
            quote_id: rfq.quote?.id,
            quote_amount: rfq.quote?.amount,
          }))}
          gridConfig={rfqListConfig}
          loading={loading}
          rowHeight={gridRowHeights.medium}
          id={`${user.id}_rfq_listing`}
          presetStyle="sortableTable"
          fallbackMessage="An RFQ has not been generated for this bid."
        />
      </>
    </InteriorPage>
  );
};

export default RFQsList;
