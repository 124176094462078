import Status from "../columnRenders/Status";

const status = {
  headerName: "Status",
  field: "work_day_status_name",
  width: 148,
  cellRenderer: Status,
};

export default status;
