import React from "react";
import { PlusCircle, MinusCircle } from "react-feather";
import { Button } from "react-bootstrap";

const ListToggle = ({ listActive, setListActive }) => {
  return (
    <Button variant="link" onClick={() => setListActive(!listActive)}>
      {listActive ? (
        <MinusCircle size="24" color={"#0071ce"} />
      ) : (
        <PlusCircle size="24" color={"#0071ce"} />
      )}
    </Button>
  );
};

export default ListToggle;
