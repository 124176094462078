import Status from "../columnRenders/Status";

const status = {
  headerName: "Status",
  field: "active",
  maxWidth: 200,
  headerClass: "ag-center-aligned-header",
  suppressMenu: true,
  cellRenderer: Status
};

export default status;
